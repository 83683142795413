import { useEffect, useState } from 'react';

/**
 * Custom hook that check if reactElement is visible or not on the user screen
 * @param {rectElement} refElement
 * @param {string} visibleElementFrom values similar to the CSS margin. e.g: "10px 20px 30px 40px" = "top right bottom left"
 * @returns {bool} isLastElementVisible
 */
const useInfiniteScroll = ({ refElement, visibleElementFrom }) => {
  const [isLastElementVisible, setIsLastElementVisible] = useState(false);
  const [intersectionNumber, setIntersectionNumber] = useState(0);

  const callback = (entries) => {
    const entriesAux = entries[0];

    if (entriesAux.isIntersecting) {
      setIsLastElementVisible(entriesAux.isIntersecting);
      setIntersectionNumber((counter) => counter + 1);
    } else {
      setIsLastElementVisible(false);
    }
  };

  useEffect(() => {
    if (refElement) {
      const options = {
        // root:,
        rootMargin: visibleElementFrom,
        threshold: 0,
      };

      const observer = new IntersectionObserver(callback, options);
      observer.observe(refElement);

      return () => {
        observer.unobserve(refElement);
        observer.disconnect();
      };
    }
  }, [refElement]);

  return { isLastElementVisible, intersectionNumber };
};

export default useInfiniteScroll;
