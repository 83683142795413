import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useDashboardProviders = () => {
  const getDashboardInterventionsProvider = ({ paginate = 0, page, name }) => {
    const response = axios({
      method: 'GET',
      url: '/interventions',
      params: {
        paginate,
        page,
        name: name || undefined,
      },
    });
    return trackPromise(response);
  };

  return {
    getDashboardInterventionsProvider,
  };
};
export default useDashboardProviders;
