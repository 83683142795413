import useHelpers from '../../../../helpers';

const useAdminUsersSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const filterDataAdminUserSelector = createSelector(
    (state) => state.filterDataAdminUsers,
    (filterDataAdminUsers) => filterDataAdminUsers
  );
  return { filterDataAdminUserSelector };
};

export default useAdminUsersSelectors;
