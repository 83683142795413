import React, { lazy } from 'react';

import useViews from '../../../../../../../..';

//Assets
import imageFakeCard from '../../../../../../../../../assets/img/fake-card-example.jpg';
import useControllers from '../../../../../../../../../controllers';
import _ from 'lodash';

//Styles
const StyledBoxImages2col1 = lazy(() =>
  import('../../../../../../../../../styles/boxes/boxes.styles').then(
    (mod) => ({
      default: mod.StyledBoxImages2col1,
    })
  )
);

const StyledDivider = lazy(() =>
  import('../../../../../../../../../styles/formViews/formViews.styles').then(
    (mod) => ({
      default: mod.StyledDivider,
    })
  )
);

const Capital = () => {
  const { useComponents } = useViews();

  const { useTypographies, useImages } = useComponents();

  const { TypographyComponent } = useTypographies();
  const { Image } = useImages();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminKnowProjectDetailIntervention } =
    useAdminInterventionsDashboardControllers();
  const { abcTrajectory } = useAdminKnowProjectDetailIntervention();
  return (
    <>
      {_.map(abcTrajectory, (cTajectory, idx) => {
        if (cTajectory.type === 'C')
          return (
            <section key={`abcTrajectory-${idx}`}>
              <TypographyComponent
                text={cTajectory.name}
                variant="h3"
                className="font-bold text-primary mb-2 mt-4"
              />

              <TypographyComponent
                text={cTajectory.description}
                variant="p"
                className="mb-12"
              />

              <StyledBoxImages2col1>
                <Image
                  srcImage={cTajectory.image.url || imageFakeCard}
                  altImage="imageport"
                  titleImage="imageport"
                />
              </StyledBoxImages2col1>
              <StyledDivider />
            </section>
          );
      })}
    </>
  );
};

export default Capital;
