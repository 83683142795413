//Packages
import React, { lazy } from 'react';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';
import useStrings from 'strings';

//Styles
const StyledGroup = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const FormMessage = (props) => {
  const { control, errors, register, optionsCommunities } = props;

  const { useComponents } = useViews();

  const { useTypographies, useInputs } = useComponents();

  const { TypographyComponent, TypographyWithTooltip } = useTypographies();

  const { InputForm, Select, TextArea } = useInputs();

  const { useConstants } = useStrings();
  const { LIST_OF_CHANNELS_FOR_SEND_MESSAGES } = useConstants();

  return (
    <>
      <TypographyWithTooltip
        text="Objetivo del mensaje*"
        variant="p"
        textTooltip="Objetivo del mensaje*"
        className="text-neutral-900  items-center"
        classNameDiv="pb-2"
        idTooltip="obj_messaje"
        applyFontBold
      />

      <StyledGroup className="items-center mb-2" col2>
        <InputForm
          type="text"
          name="objective"
          placeholder="Ingresa el objetivo del mensaje"
          label=""
          className="w-full"
          {...register('objective')}
          errors={errors}
        />
      </StyledGroup>
      <StyledGroup noSpace>
        <div>
          <TypographyWithTooltip
            text="Canal de comunicación recomendado*"
            variant="p"
            textTooltip="Canal de comunicación recomendado*"
            className="text-neutral-900"
            idTooltip="canal_messaje"
            classNameDiv="pb-2"
            applyFontBold
          />

          <Select
            name="channel"
            id="channel"
            placeholder="Selecciona el canal de comunicación"
            control={control}
            options={LIST_OF_CHANNELS_FOR_SEND_MESSAGES}
            label=""
            errors={errors}
          />
        </div>
        <div>
          <TypographyWithTooltip
            text="Destino del mensaje"
            variant="p"
            textTooltip="Destino del mensaje"
            className="text-neutral-900"
            idTooltip="dest_messaje"
            classNameDiv="pb-2"
            applyFontBold
          />

          <Select
            name="communities"
            id="communities"
            placeholder="Selecciona el destino"
            control={control}
            options={optionsCommunities}
            label=""
            errors={errors}
            multiple
          />
        </div>
      </StyledGroup>

      <TypographyWithTooltip
        text="Recomendaciones al enviar el mensaje"
        variant="p"
        textTooltip="Recomendaciones al enviar el mensaje"
        className="text-neutral-900"
        idTooltip="recommend_messaje"
        classNameDiv="pb-2"
        applyFontBold
      />

      <StyledGroup className="items-center mb-2" col2>
        <InputForm
          type="text"
          name="recommendations"
          placeholder="Ingresa las recomendaciones..."
          label=""
          className="w-full"
          {...register('recommendations')}
          errors={errors}
        />
      </StyledGroup>

      <StyledGroup>
        <TypographyComponent
          text="Redactar mensaje"
          variant="h3"
          className="font-bold text-primary mt-6"
        />

        <TextArea
          control={control}
          name="message"
          placeholder="Mensaje"
          className="w-full col-span-2"
          label=""
          errors={errors}
        />
      </StyledGroup>
    </>
  );
};

FormMessage.propTypes = {
  optionsCommunities: PropTypes.array.isRequired,
  control: PropTypes.object,
  errors: PropTypes.object,
  register: PropTypes.func,
};

FormMessage.defaultProps = {
  control: {},
  errors: {},
  register: () => {},
};

export default FormMessage;
