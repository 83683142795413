const useAdminCommunitiesInitialStates = () => {
  const inititalStateAdminCommunitiesList = [];
  const initialStateAdminCommunitySelected = {
    name: '',
    population_number: '',
    city: '',
    language: '',
    general_comments: '',
    reading_level: {
      id: '',
      name: '',
    },
    has_internet: {
      id: '',
      name: '',
    },
    device_access: {
      id: '',
      name: '',
    },
    communication_medias: [],
    community_image: {
      id: '',
      url: '',
    },
    multimedias: [],
    projectName: '',
  };

  return {
    inititalStateAdminCommunitiesList,
    initialStateAdminCommunitySelected,
  };
};

export default useAdminCommunitiesInitialStates;
