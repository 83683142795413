//Packages
import React from 'react';
import { Switch, useRouteMatch, Redirect } from 'react-router-dom';

//Hooks
import useNavigation from '../../../../../../..';
import useViews from '../../../../../../../../views';
import useAdminDashboardProjectsCommunitiesRouters from './routers';

const AdminProjectsRouterDashboardCommunities = () => {
  const { path } = useRouteMatch();

  const { useRoutes } = useNavigation();
  const { PrivateRoute } = useRoutes();

  //Screens
  const { useScreens } = useViews();
  const {
    AdminDashboardCreateCommunities,
    AdminDashboardEditCommunities,
    AdminDashboardRootCommunities,
  } = useScreens();

  //Routers
  const { AdminProjectsRouterDashboardCommunitiesContacts } =
    useAdminDashboardProjectsCommunitiesRouters();

  return (
    <Switch>
      <PrivateRoute
        exact
        path={`${path}`}
        component={AdminDashboardRootCommunities}
        redirect="/"
      />
      <PrivateRoute
        exact
        path={`${path}/create`}
        component={AdminDashboardCreateCommunities}
        redirect="/"
      />
      <PrivateRoute
        exact
        path={`${path}/edit/:community_id`}
        component={AdminDashboardEditCommunities}
        redirect="/"
      />
      <PrivateRoute
        path={`${path}/:community_id/contacts`}
        component={AdminProjectsRouterDashboardCommunitiesContacts}
        redirect="/"
      />

      <Redirect to={`${path}`} />
    </Switch>
  );
};

export default AdminProjectsRouterDashboardCommunities;
