const useAdminDevelopmentInterventionCreateSketchTypes = () => {
  const GET_CREATE_SKETCH_FOR_INTERVENTION =
    'GET_CREATE_SKETCH_FOR_INTERVENTION';
  const UPDATE_CREATE_SKETCH_FOR_INTERVENTION =
    'UPDATE_CREATE_SKETCH_FOR_INTERVENTION';

  const GET_PILOT_PLAN_INTERVENTION = 'GET_PILOT_PLAN_INTERVENTION';
  const UPDATE_PILOT_PLAN_INTERVENTION = 'UPDATE_PILOT_PLAN_INTERVENTION';
  const CREATE_PILOT_PLAN_ACTIVITY_INTERVENTION =
    'CREATE_PILOT_PLAN_ACTIVITY_INTERVENTION';
  const DELETE_PILOT_PLAN_ACTIVITY_INTERVENTION =
    'DELETE_PILOT_PLAN_ACTIVITY_INTERVENTION';

  const GET_PROGRAM_PILOT_INTERVENTION = 'GET_PROGRAM_PILOT_INTERVENTION';
  const UPDATE_PROGRAM_PILOT_INTERVENTION = 'UPDATE_PROGRAM_PILOT_INTERVENTION';
  const CALL_AGENT_PROGRAM_PILOT_INTERVENTION =
    'CALL_AGENT_PROGRAM_PILOT_INTERVENTION';

  const GET_EVALUATE_PILOT_INTERVENTION = 'GET_EVALUATE_PILOT_INTERVENTION';
  const UPDATE_EVALUATE_PILOT_INTERVENTION =
    'UPDATE_EVALUATE_PILOT_INTERVENTION';
  const UPLOAD_FILE_EVALUATE_PILOT_INTERVENTION =
    'UPLOAD_FILE_EVALUATE_PILOT_INTERVENTION';
  const DELETE_FILE_EVALUATE_PILOT_INTERVENTION =
    'DELETE_FILE_EVALUATE_PILOT_INTERVENTION';

  return {
    GET_CREATE_SKETCH_FOR_INTERVENTION,
    UPDATE_CREATE_SKETCH_FOR_INTERVENTION,
    GET_PILOT_PLAN_INTERVENTION,
    UPDATE_PILOT_PLAN_INTERVENTION,
    CREATE_PILOT_PLAN_ACTIVITY_INTERVENTION,
    DELETE_PILOT_PLAN_ACTIVITY_INTERVENTION,
    GET_PROGRAM_PILOT_INTERVENTION,
    UPDATE_PROGRAM_PILOT_INTERVENTION,
    CALL_AGENT_PROGRAM_PILOT_INTERVENTION,
    GET_EVALUATE_PILOT_INTERVENTION,
    UPDATE_EVALUATE_PILOT_INTERVENTION,
    UPLOAD_FILE_EVALUATE_PILOT_INTERVENTION,
    DELETE_FILE_EVALUATE_PILOT_INTERVENTION,
  };
};

export default useAdminDevelopmentInterventionCreateSketchTypes;
