//Packages
import React, { lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemHeading,
  AccordionItemState,
} from 'react-accessible-accordion';
import _ from 'lodash';

//Hooks
import useViews from 'views';

//Components
import Quantitative from '../../../../components/Alphanumeric/Quantitative';
import Qualitative from '../../../../components/Alphanumeric/Qualitative';
import Multimedia from '../../../../components/Multimedia';

//Styles
const StyledBackButton = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);

const StyledTopDetail = lazy(() =>
  import('./Detail.styles').then((mod) => ({
    default: mod.StyledTopDetail,
  }))
);

const Detail = (props) => {
  const {
    setActiveItem,
    selectedMessage,
    handleResetSelectedDetailMessage,
    onFormatMessageAboutCommunities,
    getMonthAndDay,
    setDataCollectionInformationAnswersSelected,
    dataCollectionInformationAnswersSelected,
    handleChangePageAnswers,
    handleDownloadFileFromCollectionInformation,
  } = props;

  const { useComponents } = useViews();
  const { useTypographies, useCards } = useComponents();
  const { TypographyComponent } = useTypographies();
  const { CardNotification } = useCards();

  useEffect(() => {
    return () => {
      handleResetSelectedDetailMessage();
    };
  }, []);

  return (
    <>
      <StyledTopDetail>
        <StyledBackButton
          className="pl-1"
          onClick={() => {
            setDataCollectionInformationAnswersSelected({});
            setActiveItem((prevValue) => ({
              ...prevValue,
              idSelectedMessage: '',
              active: 0,
            }));
          }}
        />
      </StyledTopDetail>
      <TypographyComponent
        text="Solicitud de envío de mensaje"
        variant="h2"
        className="font-bold text-primary mt-4"
      />
      <p className="text-neutral-800 mb-6 mr-1">
        Fecha solicitud:{' '}
        <strong>
          {' '}
          {getMonthAndDay({ date: selectedMessage.created_at })}{' '}
        </strong>
      </p>
      <div className="mb-4">
        <TypographyComponent
          text={
            <>
              <span className="font-bold text-primary inline-block mr-1">
                Objetivo del mensaje:
              </span>
              {selectedMessage?.objective}
            </>
          }
          variant="p"
        />
      </div>
      {selectedMessage?.gi_type?.name && (
        <div className="mb-4">
          <TypographyComponent
            text={
              <>
                <span className="font-bold text-primary inline-block mr-1">
                  Tipo de recolección:
                </span>
                {selectedMessage?.gi_type?.name}
              </>
            }
            variant="p"
          />
        </div>
      )}
      {selectedMessage?.gi_type?.id === 'a' && (
        <div className="mb-4">
          <TypographyComponent
            text={
              <>
                <span className="font-bold text-primary inline-block mr-1">
                  Tipo de información a recolectar:
                </span>
                {selectedMessage?.type_information?.name}
              </>
            }
            variant="p"
          />
        </div>
      )}
      {(selectedMessage?.type?.id === 'gi' ||
        selectedMessage?.type?.id === 'd') && (
        <div className="mb-4">
          <TypographyComponent
            text={
              <>
                <span className="font-bold text-primary inline-block mr-1">
                  Canal de comunicación recomendado:
                </span>
                {selectedMessage?.channel?.name}
              </>
            }
            variant="p"
          />
        </div>
      )}
      <div className="mb-4">
        <TypographyComponent
          text={
            <>
              <span className="font-bold text-primary inline-block mr-1">
                Destino del mensaje:
              </span>
              {onFormatMessageAboutCommunities({ message: selectedMessage })}
            </>
          }
          variant="p"
        />
      </div>
      <div className="mb-4">
        <TypographyComponent
          text={
            <>
              <span className="font-bold text-primary inline-block mr-1">
                {selectedMessage.type?.id === 'gi'
                  ? 'Recomendaciones para realizar la recolección de información:'
                  : 'Recomendaciones al enviar el mensaje:'}
              </span>
              {selectedMessage?.recommendations}
            </>
          }
          variant="p"
        />
      </div>
      {((selectedMessage.state?.id !== 's' &&
        selectedMessage?.type?.id === 'gi') ||
        selectedMessage?.type?.id === 'd') && (
        <div className="mb-4">
          <TypographyComponent
            text={
              <>
                <span className="font-bold text-primary inline-block mr-1">
                  {selectedMessage.type?.id === 'gi' ? 'Pregunta:' : 'Mensaje:'}
                </span>
                {_.split(selectedMessage.message, '\n1')?.[0]}
              </>
            }
            variant="p"
          />
        </div>
      )}

      <CardNotification
        className="w-full mx-auto py-9 px-4 mt-16"
        title={
          <TypographyComponent
            text={
              selectedMessage.state?.id === 's'
                ? 'Solicitud aprobada'
                : selectedMessage.state?.id === 'r'
                ? 'Solicitud Rechazada'
                : selectedMessage.state?.id === 'ip'
                ? 'Solicitud en progreso'
                : selectedMessage.state?.id === 'c'
                ? 'Solicitud cancelada'
                : 'Solicitud pendiente por revisar'
            }
            variant="h2"
            className={`${
              selectedMessage.state?.id === 's'
                ? 'text-success-700 icon-check-circle'
                : selectedMessage.state?.id === 'r'
                ? 'text-error icon-close-circle'
                : selectedMessage.state?.id === 'ip'
                ? 'text-primary icon-reload'
                : selectedMessage.state?.id === 'c'
                ? 'text-primary icon-watch'
                : 'text-warning icon-watch'
            } font-bold text-center`}
          />
        }
        content={
          <>
            <TypographyComponent
              text={
                selectedMessage.state?.id !== 's' &&
                selectedMessage.state?.id !== 'r' &&
                selectedMessage.state?.id !== 'ip' &&
                selectedMessage.state?.id !== 'c' &&
                'En este momento el mensaje esta en manos del socio ejecutor. Recibirás una notificación en el momento en que el mensaje haya sido aprobado, o se requieran cambios.'
              }
              variant="p"
              className="text-center mt-4"
            />
            {selectedMessage.state?.id === 'r' && (
              <Accordion
                allowZeroExpanded
                preExpanded={[0]}
                className="mt-4 bg-neutral-300"
              >
                <AccordionItem
                  key={0}
                  uuid={0}
                  className="rounded border border-error px-6 py-6"
                >
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="flex items-center justify-between space-x-4">
                        <TypographyComponent
                          text="Motivo de rechazo"
                          variant="h4"
                          className="text-error font-bold"
                        />
                        <AccordionItemState>
                          {({ expanded }) => (
                            <div
                              className={`icon-arrow-down text-neutral-950 text-xxs ${
                                expanded && 'transform rotate-180'
                              }`}
                            />
                          )}
                        </AccordionItemState>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <TypographyComponent
                      text={selectedMessage.rejected_cause}
                      variant="p"
                      className="text-neutral-950 mt-4"
                    />
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            )}
          </>
        }
      />

      {selectedMessage.state?.id === 's' &&
        selectedMessage.type?.id === 'd' && (
          <Quantitative dataCollectionInformationSelected={selectedMessage} />
        )}

      {selectedMessage.state?.id === 's' && selectedMessage.type?.id === 'gi' && (
        <div className="border rounded mt-4 bg-neutral-400 px-8 py-6">
          <TypographyComponent
            text="Seguimiento a la recolección de información"
            variant="h3"
            className="font-bold text-primary mt-4"
          />

          <TypographyComponent
            text="Tu mensaje ya fue enviado, estamos a la espera de las respuestas de la comunidad."
            variant="p"
            className="text-neutral-900 mt-4"
          />

          <div className="mb-4 mt-8">
            <TypographyComponent
              text={
                <>
                  <span className="font-bold text-primary block mr-1">
                    {selectedMessage.gi_type.id === 'm'
                      ? 'Pregunta o mensaje'
                      : 'Pregunta'}
                  </span>
                  <span className="font-bold text-lg">
                    {_.split(selectedMessage.message, '\n1')?.[0]}
                  </span>
                </>
              }
              variant="p"
            />
          </div>

          {selectedMessage.gi_type?.id !== 'm' ? (
            <>
              {selectedMessage.type_information?.id === 'qn' ? (
                <Quantitative
                  dataCollectionInformationSelected={selectedMessage}
                  dataCollectionInformationAnswersSelected={
                    dataCollectionInformationAnswersSelected
                  }
                />
              ) : (
                <Qualitative
                  totalAnswers={Number(selectedMessage.result?.replied)}
                  dataCollectionInformationAnswersSelected={
                    dataCollectionInformationAnswersSelected
                  }
                  handleChangePageAnswers={handleChangePageAnswers}
                />
              )}
            </>
          ) : (
            <Multimedia
              totalAnswers={Number(selectedMessage.result?.replied)}
              dataCollectionInformationAnswersSelected={
                dataCollectionInformationAnswersSelected
              }
              handleChangePageAnswers={handleChangePageAnswers}
              handleDownloadFileFromCollectionInformation={({
                fileId,
                nameFile,
                extension,
              }) =>
                handleDownloadFileFromCollectionInformation({
                  fileId,
                  nameFile,
                  messageRequestId: selectedMessage.id,
                  extension,
                })
              }
            />
          )}
        </div>
      )}
    </>
  );
};

Detail.propTypes = {
  setActiveItem: PropTypes.func.isRequired,
  selectedMessage: PropTypes.object,
  handleResetSelectedDetailMessage: PropTypes.func,
  onFormatMessageAboutCommunities: PropTypes.func,
  getMonthAndDay: PropTypes.func,
  setDataCollectionInformationAnswersSelected: PropTypes.func,
  handleDownloadFileFromCollectionInformation: PropTypes.func,
  dataCollectionInformationAnswersSelected: PropTypes.object,
  handleChangePageAnswers: PropTypes.func,
};

Detail.defaultProps = {
  selectedMessage: {},
  handleResetSelectedDetailMessage: () => {},
  onFormatMessageAboutCommunities: () => {},
  getMonthAndDay: () => {},
  setDataCollectionInformationAnswersSelected: () => {},
  handleDownloadFileFromCollectionInformation: () => {},
  dataCollectionInformationAnswersSelected: {},
  handleChangePageAnswers: () => {},
};

export default Detail;
