//Packages
import { useEffect, useReducer, useRef, useState } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

//Hooks
import useApi from 'api';
import useModels from 'models';
import useControllers from 'controllers';

const useUser = (contact = false) => {
  const history = useHistory();
  const { useActions } = useApi();
  const { useUserActions, dispatch, useAdminActions } = useActions();
  const { actDeleteUser, actSearchUser, actGetUser } = useUserActions();
  const { useAdminUsersActions } = useAdminActions();
  const { actSetFilterDataUser, actResetAllFilterDataUser } =
    useAdminUsersActions();

  const { useGeneralHooks } = useControllers();
  const { useIsFilterEmpty } = useGeneralHooks();
  const { isFilterEmpty } = useIsFilterEmpty();

  const [orderBy, setOrderBy] = useState({
    name: true,
    last_name: true,
    country: true,
    department: true,
    city: true,
    role: true,
    email: true,
    phone: true,
    gender: true,
  });

  const reducerContacts = (state, action) => {
    return _.map(action.payload, (row) => row.original?.id);
  };

  const [contactsSelected, dispatchContacts] = useReducer(reducerContacts, []);
  const { useSelectors } = useModels();
  const { useSelector, useUserSelectors, useAdminSelectors } = useSelectors();
  const { useAdminUsersSelectors } = useAdminSelectors();
  const { filterDataAdminUserSelector } = useAdminUsersSelectors();
  const dataFilter = useSelector(filterDataAdminUserSelector);
  const { usersSelector } = useUserSelectors();
  const users = useSelector(usersSelector);
  const [textSearch, setTextSearch] = useState(dataFilter.search);
  const [allUsers, setAllUsers] = useState(false);
  const firstRender = useRef(true);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (textSearch.length >= 3) {
        setAllUsers(true);
        dispatch(actSetFilterDataUser({ key: 'search', value: textSearch }));
        dispatch(
          actSearchUser(
            {
              ...dataFilter,
              search: textSearch,
            },
            contact ? 'contacts' : 'users'
          )
        );
      } else {
        if (allUsers || firstRender.current) {
          setTimeout(() => {
            setAllUsers(false);
            firstRender.current = false;
            dispatch(actSetFilterDataUser({ key: 'search', value: '' }));
            dispatch(
              actSearchUser(
                {
                  ...dataFilter,
                  search: undefined,
                  community_id: '',
                },
                contact ? 'contacts' : 'users'
              )
            );
          }, 500);
        }
      }
    }
    return () => {
      isMounted = false;
    };
  }, [textSearch]);

  useEffect(() => {
    return () => {
      dispatch(actResetAllFilterDataUser());
    };
  }, []);

  const [dataUser, setDataUser] = useState(users);
  useEffect(() => {
    setDataUser((prevState) => {
      if (_.isEqual(prevState, users)) {
        return prevState;
      } else {
        return users;
      }
    });
  }, [users]);

  const deleteUser = (id) => {
    dispatch(
      actDeleteUser(id, () => {
        getUsersPage(currentPage - 1, false);
      })
    );
  };

  const getUsersPage = (page, updatePage = true) => {
    if (updatePage) {
      setCurrentPage(page + 1);
    }
    dispatch(
      actSearchUser(
        { ...dataFilter, page: page + 1 },
        contact ? 'contacts' : 'users'
      )
    );
  };

  const getUser = (id) => {
    dispatch(actGetUser(id));
  };
  const getUserContactList = () => {
    dispatchContacts(contactsSelected);
  };

  const handleOrderBy = ({ orderByKey }) => {
    dispatch(
      actSearchUser(
        {
          ...dataFilter,
          orderByKey,
          orderByValue: orderBy[orderByKey] ? 'desc' : 'asc',
        },
        'users',
        (res, resAux) => {
          setCurrentPage(resAux.meta?.current_page);
        }
      )
    );
    setOrderBy((prevState) => ({
      ...prevState,
      [orderByKey]: !prevState[orderByKey],
    }));
    dispatch(actSetFilterDataUser({ key: 'orderByKey', value: orderByKey }));
    dispatch(
      actSetFilterDataUser({
        key: 'orderByValue',
        value: orderBy[orderByKey] ? 'desc' : 'asc',
      })
    );
  };
  return {
    isFilterEmpty,
    history,
    users: dataUser,
    deleteUser,
    setTextSearch,
    defaultValueTextSearch: dataFilter.search,
    getUsersPage,
    getUser,
    getUserContactList,
    handleOrderBy,
    current_page: currentPage,
    dispatchContacts,
    orderBy,
  };
};
export default useUser;
