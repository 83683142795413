//Packages
import React, { Suspense, lazy } from 'react';
import _ from 'lodash';
import { Controller } from 'react-hook-form';

//Assets
import plusIcon from 'assets/icons/plus.svg';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
const StyledBackButton = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);

const StyledGroup = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledForm = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledForm,
  }))
);

const StyledContentButtons = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const StyledContentButtonsModal = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const StyledWrapperCommunity = lazy(() =>
  import('../Dashboard.styles').then((mod) => ({
    default: mod.StyledWrapperCommunity,
  }))
);

const StyledCardCommunity = lazy(() =>
  import('../Dashboard.styles').then((mod) => ({
    default: mod.StyledCardCommunity,
  }))
);

const AdminDashboardDocumentation = () => {
  const { useComponents } = useViews();
  const {
    useInputs,
    useButtons,
    useTypographies,
    useImages,
    useBoxes,
    useModals,
    useLoaders,
  } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { InputFile } = useInputs();
  const { ButtonComponent, ButtonAddActivity } = useButtons();
  const { TypographyComponent } = useTypographies();
  const { Image } = useImages();
  const { Box } = useBoxes();
  const { DefaultModal } = useModals();

  const { useScreenHooks, useGeneralHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminDocumentationDashboard } = useAdminDashboardProjectsHooks();
  const {
    handleSubmit,
    errors,
    control,
    show,
    showDeleteDocumentationModal,
    handleDeleteDocumentationModal,
    handleGoBack,
    handleCancelDocumentation,
    handleAddDocumentation,
    listDocumentationByProject,
    cleanFileInputs,
    handleCreateDocumentation,
    handleDeleteDocumentation,
    setDocumentationId,
    handlePreviewOrDownloadFile,
  } = useAdminDocumentationDashboard();
  const { useSelectLayoutByRole } = useGeneralHooks();
  const { LayoutSelected, props } = useSelectLayoutByRole();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <LayoutSelected {...props}>
        <StyledBackButton className="mt-3" onClick={handleGoBack}>
          Regresar
        </StyledBackButton>
        <Box className="bg-neutral-200 w-11/12 mx-auto max-w-screen-lg lg:px-16 lg:py-12 px-6 py-8 lg:my-8">
          <>
            <TypographyComponent
              text="Documentación"
              variant="h2"
              className="font-bold text-primary"
              applyFontBold
            />
            <TypographyComponent
              text="Hay algún documento necesario para conocer mejor este proyecto?"
              variant="p"
              className="font-book text-neutral-950 mb-10"
            />

            {!show && (
              <StyledWrapperCommunity
                className={
                  !show
                    ? 'visible opacity-100 h-auto'
                    : 'invisible opacity-0 transition-all duration-100 h-0'
                }
              >
                {listDocumentationByProject.length > 0 &&
                  _.map(listDocumentationByProject, (documentation) => {
                    return (
                      <StyledCardCommunity
                        key={`documentation-${documentation.id}`}
                      >
                        <TypographyComponent
                          variant="p"
                          text={documentation.name}
                          className="flex-grow text-primary font-bold cursor-pointer"
                          onClick={() => {
                            handlePreviewOrDownloadFile({
                              documentationId: documentation.id,
                              fileType: documentation.extension,
                              urlToPreview: documentation.url,
                              fileName: documentation.name,
                            });
                          }}
                        />
                        <StyledContentButtons>
                          <ButtonComponent
                            text=""
                            className="text-primary-100"
                            variant="white"
                            icon={
                              <div className="icon-view text-primary h-10"></div>
                            }
                            onPress={() => {
                              handlePreviewOrDownloadFile({
                                documentationId: documentation.id,
                                fileType: documentation.extension,
                                urlToPreview: documentation.url,
                                fileName: documentation.name,
                              });
                            }}
                          />
                          <ButtonComponent
                            text=""
                            className="icon-delete"
                            onPress={() => {
                              handleDeleteDocumentationModal();
                              setDocumentationId(documentation.id);
                            }}
                          />
                        </StyledContentButtons>
                      </StyledCardCommunity>
                    );
                  })}
              </StyledWrapperCommunity>
            )}

            {listDocumentationByProject.length < 15 && (
              <ButtonAddActivity
                open={show}
                text="Agregar nuevo documento"
                icon={
                  <Image
                    srcImage={plusIcon}
                    altImage="icon-plus"
                    titleImage="icon-plus"
                    className="w-6 mr-2"
                  />
                }
                onPress={handleAddDocumentation}
              />
            )}

            <StyledForm
              className={`border p-6 rounded   ${
                show
                  ? 'visible opacity-100 h-auto'
                  : 'invisible opacity-0 transition-all duration-100 h-0'
              }`}
            >
              <TypographyComponent
                text={
                  <label>
                    <span className="text-neutral-800 ml-2 font-light">
                      Formato de archivo permitido JPG, PNG, JPEG, XLS, DOCX,
                      PDF, MP4, MOV, QT
                    </span>
                  </label>
                }
                variant="p"
                className="text-neutral-950 mb-10 w-full"
              />
              <Controller
                control={control}
                name="file"
                render={({ field: { onChange, name } }) => {
                  return (
                    <InputFile
                      name={name}
                      onChange={onChange}
                      className="w-full mb-14"
                      errors={errors}
                      cleanFileInputs={cleanFileInputs}
                    />
                  );
                }}
              />

              <StyledGroup flex className="justify-end space-x-4">
                <ButtonComponent
                  text="Cancelar"
                  mode="outline"
                  onPress={handleCancelDocumentation}
                />
                <ButtonComponent
                  text={'Agregar información complementaria'}
                  className="mr-0 ml-auto mt-16"
                  onPress={handleSubmit(handleCreateDocumentation)}
                />
              </StyledGroup>
            </StyledForm>
          </>
        </Box>
        <DefaultModal
          isActive={showDeleteDocumentationModal}
          classNameContent="max-w-screen-md w-11/12"
          handleClose={handleDeleteDocumentationModal}
        >
          <TypographyComponent
            text="Eliminar documentación"
            variant="h3"
            className="font-bold text-primary mb-1"
          />
          <StyledContentButtonsModal>
            <ButtonComponent
              variant="primary"
              text="Cancelar"
              className="mx-auto w-full lg:w-auto lg:mx-2 "
              mode="outline"
              onPress={handleDeleteDocumentationModal}
            />
            <ButtonComponent
              variant="primary"
              text="Aceptar"
              className="mx-auto w-full lg:w-auto  lg:mx-2"
              onPress={handleDeleteDocumentation}
            />
          </StyledContentButtonsModal>
        </DefaultModal>
      </LayoutSelected>
    </Suspense>
  );
};

export default AdminDashboardDocumentation;
