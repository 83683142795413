//packages
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';

//Hooks
import useStrings from 'strings';
import useHelpers from 'helpers';
import useApi from 'api';
import useModels from 'models';
import useControllers from 'controllers';

const useAdminEditInterventionsDashboard = () => {
  const history = useHistory();
  const { project_id: projectId, intervention_id: interventionId } =
    useParams();
  const { state } = useLocation();
  const dataInterventionRef = useRef(false);

  const [showTextArea, setShowTextArea] = useState(false);
  const [validateChecboxes, setValidateChecboxes] = useState(false);
  const [showModalUser, setShowModalUser] = useState(false);

  const [optionsObjetives] = useState([
    { label: 'Virtual', value: 'v' },
    { label: 'Presencial', value: 'p' },
    { label: 'Virtual y Presencial', value: 'pv' },
  ]);

  const [optionsTypes] = useState([
    { label: 'Activar', value: 'activate' },
    { label: 'Inspirar', value: 'inspire' },
    { label: 'Sostener', value: 'hold' },
  ]);

  const [optionsTypesValues] = useState({
    Activar: 'activate',
    Inspirar: 'inspire',
    Sostener: 'hold',
  });

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const {
    REQUIRED_FIELD,
    FILE_TYPE,
    FILE_SIZE,
    MAX_LENGTH,
    MIN_DATE_EQUALS,
    ARRAY_MIN_1_LENGTH,
  } = useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const {
    getFileValidations,
    formatDayWithTwoDigits,
    formatMonthWithTwoDigits,
  } = useQuickFunctions();
  const { SUPPORTED_FORMATS_ONLY_IMAGES, MAX_SIZE } = getFileValidations();

  const { useGeneralHooks } = useControllers();
  const { useToggleModal } = useGeneralHooks();
  const [showAssignArtistModal, handleShowAssignArtistModal] = useToggleModal();

  const schemaCreateIntervention = yup.object({
    name: yup.string().required(REQUIRED_FIELD),
    image: yup
      .mixed()
      .nullable()
      .test('fileType', FILE_TYPE, (value) => {
        if (value) {
          return SUPPORTED_FORMATS_ONLY_IMAGES.includes(value.type);
        }
        return true;
      })
      .test('fileSize', FILE_SIZE, (value) => {
        if (value) {
          return value.size <= MAX_SIZE;
        }
        return true;
      }),
    description: yup.string().required(REQUIRED_FIELD).max(40000, MAX_LENGTH),
    type: yup.object().required(REQUIRED_FIELD).nullable(),
    objetive: yup.object().required(REQUIRED_FIELD).nullable(),
    leading_group: yup.string().required(REQUIRED_FIELD),
    allied_group: yup.string().required(REQUIRED_FIELD),
    definition_behavior: yup
      .array()
      .required(REQUIRED_FIELD)
      .nullable()
      .test('values', ARRAY_MIN_1_LENGTH, (values) => {
        const res = values.every((dBehavior) => dBehavior === false);
        return !res;
      }),
    other_definition: showTextArea
      ? yup.string().required(REQUIRED_FIELD)
      : yup.string(),
    start_date: yup
      .date()
      .nullable()
      .default(null)
      .transform((curr, orig) => (orig === '' ? null : curr)),
    end_date: yup
      .date()
      .nullable()
      .default(null)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when('start_date', {
        is: (start_date) => {
          if (start_date) {
            return true;
          }
          return false;
        },
        then: yup.date().required(REQUIRED_FIELD).nullable(),
      })
      .when('start_date', (start_date, yup) => {
        if (start_date) {
          return yup.min(start_date, MIN_DATE_EQUALS);
        }
      }),
    user_id: yup.object().required(REQUIRED_FIELD).nullable(),
    communities: yup.array().required(REQUIRED_FIELD).nullable(),
  });

  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    trigger,
    setError,
  } = useForm({
    resolver: yupResolver(schemaCreateIntervention),
    mode: 'onChange',
  });
  const selectedArtist = watch('user_id');
  const definitionBehaviorWatched = watch('definition_behavior[]');
  const startDateSelected = watch('start_date');

  const { useActions } = useApi();
  const { dispatch, useAdminActions, useUserActions } = useActions();
  const { actResetArtistListInfiniteScroll, actGetUser } = useUserActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions, useAdminCommunitiesActions } =
    useAdminProjectsActions();
  const { actGetCommunitiesByProject } = useAdminCommunitiesActions();
  const { useAdminInterventionsRootActions } = useAdminInterventionsActions();
  const {
    actGetInterventionDetailByProject,
    actGetDefinitionBehaviors,
    actUpdateInterventionByProject,
    actResetInterventionDetailByProject,
  } = useAdminInterventionsRootActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors, useUserSelectors } = useSelectors();
  const { usersSelector } = useUserSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors, useAdminCommunitiesSelectors } =
    useAdminProjectsSelectors();
  const { getInterventionDetailToEditSelector, getInterventionsSelector } =
    useAdminInterventionsSelectors();
  const { definition_behavior: definition_behaviors } = useSelector(
    getInterventionsSelector
  );
  const { user: userInfo } = useSelector(usersSelector);

  const dataIntervention = useSelector(getInterventionDetailToEditSelector);
  const { communitiesListSelector } = useAdminCommunitiesSelectors();
  const communitiesListAux = useSelector(communitiesListSelector);

  useEffect(() => {
    dispatch(actGetCommunitiesByProject({ projectId }));
    dispatch(actGetDefinitionBehaviors());
    dispatch(actGetInterventionDetailByProject({ projectId, interventionId }));
  }, []);

  useEffect(() => {
    if (dataIntervention.name && !dataInterventionRef.current) {
      dataInterventionRef.current = true;
      setValue('name', dataIntervention.name);
      setValue(
        'communities',
        _.map(dataIntervention.communities, (community) => ({
          label: community.name,
          value: community.id,
        }))
      );
      setValue('description', dataIntervention.description);
      setValue('leading_group', dataIntervention.leading_group);

      const definitionBehaviorsAux = _.map(
        definition_behaviors,
        (dBehaviors) => {
          return _.find(
            dataIntervention.definition_behaviors,
            (dBehaviorsAux) => {
              return dBehaviorsAux.id === dBehaviors.id;
            }
          )
            ? true
            : false;
        }
      );

      setValue('definition_behavior', definitionBehaviorsAux);
      setShowTextArea(definitionBehaviorsAux[3]);

      setValue('allied_group', dataIntervention.allied_group);
      setValue('user_id', dataIntervention.artist);
      dataIntervention.other_definition &&
        setValue('other_definition', dataIntervention.other_definition);
      setValue('type', {
        label: dataIntervention.type?.name,
        value: dataIntervention.type?.id,
      });
      setValue('objetive', {
        label: dataIntervention.objetive,
        value: optionsTypesValues[dataIntervention.objetive],
      });
      dataIntervention.start_date &&
        setValue('start_date', dataIntervention.start_date.slice(0, 10));
      dataIntervention.end_date &&
        setValue('end_date', dataIntervention.end_date.slice(0, 10));
    }
  }, [dataIntervention]);

  useEffect(() => {
    if (startDateSelected) {
      trigger('end_date');
    }
  }, [startDateSelected]);

  useEffect(() => {
    return () => {
      dispatch(actResetArtistListInfiniteScroll());
    };
  }, []);

  useEffect(() => {
    const res = definitionBehaviorWatched?.every(
      (communication) => communication === false
    );
    if (res) {
      setError('definition_behavior', {
        type: 'manual',
        message: ARRAY_MIN_1_LENGTH,
      });
    } else {
      clearErrors('definition_behavior');
    }
  }, [validateChecboxes]);

  const communitiesList = useMemo(() => {
    return _.map(communitiesListAux, (community) => {
      return { label: community.name, value: community.id };
    });
  }, [communitiesListAux]);

  const handleGoBack = () => {
    history.push(`/admin/projects/dashboard/${projectId}/interventions`, {
      pageFromIntervention: state?.pageFromIntervention,
    });
    dispatch(actResetInterventionDetailByProject());
  };

  const onSuccessUpdateInterventionByProject = () => {
    history.goBack();
    dispatch(actResetInterventionDetailByProject());
  };

  const handleUpdateInterventionByProject = (data) => {
    const {
      communities,
      start_date,
      end_date,
      definition_behavior,
      ...resData
    } = data;
    const communitiesAux = _.map(communities, (community) => community.value);
    const startDateAux =
      start_date &&
      `${start_date.getUTCFullYear()}-${formatMonthWithTwoDigits({
        month: start_date.getUTCMonth(),
      })}-${formatDayWithTwoDigits({ day: start_date.getUTCDate() })}`;
    const endDateAux = end_date
      ? `${end_date.getUTCFullYear()}-${formatMonthWithTwoDigits({
          month: end_date.getUTCMonth(),
        })}-${formatDayWithTwoDigits({ day: end_date.getUTCDate() })}`
      : undefined;
    const definitionBehaviorAux = _.filter(
      _.map(definition_behavior, (dBehavior, index) =>
        dBehavior ? index + 1 : -1
      ),
      (dBehavior) => dBehavior !== -1
    );

    dispatch(
      actUpdateInterventionByProject(
        {
          projectId,
          interventionId,
          ...resData,
          communities: communitiesAux,
          start_date: startDateAux,
          end_date: endDateAux,
          definition_behavior: definitionBehaviorAux,
        },
        onSuccessUpdateInterventionByProject
      )
    );
  };

  const handleResetInputFile = () => {
    setValue('image', '');
    clearErrors('image');
  };

  const handleOpenModalSelectArtist = (e) => {
    e.preventDefault();
    if (dataIntervention.state.id !== 'ip') {
      handleShowAssignArtistModal();
    }
  };

  const handleSetSelectedArtist = (artistId) => {
    setValue('user_id', artistId);
    trigger('user_id');
  };

  const setDefaultCheckedDefinitionBehaviors = useCallback(
    (behaviorId) => {
      const res = _.find(
        dataIntervention.definition_behaviors,
        (dBehavior) => dBehavior.id === parseInt(behaviorId)
      );
      return res;
    },
    [dataIntervention]
  );

  const handleShowTextArea = (e) => {
    if (e.target.value === '4') {
      setShowTextArea(!showTextArea);
      !e.target.checked && setValue('other_definition', '');
    }
  };

  const handleViewProfileArtist = (e) => {
    e.preventDefault();
    dispatch(
      actGetUser(selectedArtist.id, () => {
        setShowModalUser(true);
      })
    );
  };

  return {
    control,
    errors,
    register,
    handleSubmit,
    handleGoBack,
    handleUpdateInterventionByProject,
    communitiesList,
    dataIntervention,
    handleResetInputFile,
    handleOpenModalSelectArtist,
    showAssignArtistModal,
    handleShowAssignArtistModal,
    optionsObjetives,
    optionsTypes,
    handleSetSelectedArtist,
    selectedArtist,
    optionsTypesValues,
    definition_behaviors,
    setDefaultCheckedDefinitionBehaviors,
    handleShowTextArea,
    showTextArea,
    setValidateChecboxes,
    definitionBehaviorWatched,
    handleViewProfileArtist,
    showModalUser,
    setShowModalUser,
    userInfo,
  };
};

export default useAdminEditInterventionsDashboard;
