import useApi from '../../../../api';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useControllers from '../../../index';
import useStrings from '../../../../strings';
import useHelpers from '../../../../helpers';

const useRecoverPasswordScreenController = () => {
  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const {
    EMAIL_NOT_VALID,
    REQUIRED_FIELD,
    PASSWORD_NOT_EQUAL,
    PASSWORD_VALIDATION,
  } = useFormsTypes();
  const schemaForgotPassword = Yup.object({
    email: Yup.string().email(EMAIL_NOT_VALID).required(REQUIRED_FIELD),
  });

  const { useQuickFunctions } = useHelpers();
  const { regexValidations } = useQuickFunctions();
  const match = regexValidations({ regex: 'password' });

  const schemaResetPassword = Yup.object({
    password: Yup.string()
      .matches(match, PASSWORD_VALIDATION)
      .required(REQUIRED_FIELD),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref('password'), null],
      PASSWORD_NOT_EQUAL
    ),
  });
  const schemaChangePassword = Yup.object({
    current_password: Yup.string().required(REQUIRED_FIELD),
    password: Yup.string()
      .matches(match, PASSWORD_VALIDATION)
      .required(REQUIRED_FIELD),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref('password'), null],
      PASSWORD_NOT_EQUAL
    ),
  });
  const resetPasswordForm = useForm({
    resolver: yupResolver(schemaResetPassword),
  });
  const forgotPasswordForm = useForm({
    resolver: yupResolver(schemaForgotPassword),
  });
  const chagePasswordForm = useForm({
    resolver: yupResolver(schemaChangePassword),
  });
  const [token, setToken] = useState('');
  const history = useHistory();
  const { useActions } = useApi();
  const { useAuthActions, useUserActions, dispatch } = useActions();
  const { useRecoverPasswordActions } = useAuthActions();
  const { actForgotPassword, actResetPassword } = useRecoverPasswordActions();
  const { actChangePassword } = useUserActions();
  const { useGeneralHooks } = useControllers();
  const { useConsole } = useGeneralHooks();
  const { viewConsole } = useConsole();

  const forgotPassword = () => {
    const { email } = forgotPasswordForm.getValues();
    dispatch(
      actForgotPassword(
        { email },
        (message) => {
          viewConsole([message, 'message'], 'log');
          forgotPasswordForm.reset();
        },
        (e) => {
          viewConsole([e.message, 'error'], 'error');
        }
      )
    );
  };

  const resetPassword = () => {
    const { password, password_confirmation } = resetPasswordForm.getValues();
    dispatch(
      actResetPassword(
        {
          password,
          password_confirmation,
          token,
        },
        () => {
          resetPasswordForm.reset();
          history.push('/login');
        }
      )
    );
  };
  const changePassword = () => {
    const { password, password_confirmation, current_password } =
      chagePasswordForm.getValues();
    dispatch(
      actChangePassword(
        {
          password,
          password_confirmation,
          current_password,
        },
        () => {
          chagePasswordForm.reset();
          history.push('/login');
        },
        ({ response }) => {
          chagePasswordForm.setError('current_password', {
            type: 'manual',
            message: response.data.errors.current_password,
          });
        }
      )
    );
  };

  return {
    token,
    setToken,
    forgotPasswordForm,
    resetPasswordForm,
    forgotPassword,
    resetPassword,
    changePassword,
    chagePasswordForm,
  };
};
export default useRecoverPasswordScreenController;
