const useUserTypes = () => {
  const DATA_USER = 'DATA_USER';
  const DATA_UPDATE_PROFILE_USER = 'DATA_UPDATE_PROFILE_USER';
  const DELETE_DATA_UPDATE_PROFILE_USER = 'DELETE_DATA_UPDATE_PROFILE_USER';
  const RESET_DATA_UPDATE_PROFILE_USER = 'RESET_DATA_UPDATE_PROFILE_USER';
  const UPDATE_USER = 'UPDATE_USER';
  const UPDATE_AVATAR_USER = 'UPDATE_AVATAR_USER';
  const EDIT_USER = 'EDIT_USER';
  const CREATE_USER = 'CREATE_USER';
  const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
  const GET_USERS = 'GET_USERS';
  const DELETE_USER = 'DELETE_USER';
  const SEARCH_USER = 'SEARCH_USER';
  const GET_USER = 'GET_USER';
  const GET_ARTISTS_INFINITE_SCROLL = 'GET_ARTISTS_INFINITE_SCROLL';
  const RESET_ARTISTS_INFINITE_SCROLL = 'RESET_ARTISTS_INFINITE_SCROLL';
  const GET_FAVORITE_USERS = 'GET_FAVORITE_USERS';
  const ADD_USER_FAVORITE = 'ADD_USER_FAVORITE';
  const DELETE_USER_FAVORITE = 'DELETE_USER_FAVORITE';

  return {
    DATA_USER,
    DATA_UPDATE_PROFILE_USER,
    DELETE_DATA_UPDATE_PROFILE_USER,
    RESET_DATA_UPDATE_PROFILE_USER,
    UPDATE_USER,
    UPDATE_AVATAR_USER,
    EDIT_USER,
    CREATE_USER,
    CHANGE_PASSWORD,
    GET_USERS,
    DELETE_USER,
    SEARCH_USER,
    GET_USER,
    GET_ARTISTS_INFINITE_SCROLL,
    RESET_ARTISTS_INFINITE_SCROLL,
    GET_FAVORITE_USERS,
    ADD_USER_FAVORITE,
    DELETE_USER_FAVORITE,
  };
};

export default useUserTypes;
