const useModalsInitialStates = () => {
  const initialStateModalUpdateProfilePhoto = {
    showModal: false,
    blobImage: '',
    name: '',
    type: '',
  };
  return { initialStateModalUpdateProfilePhoto };
};

export default useModalsInitialStates;
