import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

const StyledGroup = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const FormAspects = (props) => {
  const { setViewDefine } = props;
  const { useComponents } = useViews();

  const { useTypographies, useInputs, useButtons } = useComponents();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { InputForm } = useInputs();
  const { ButtonComponent } = useButtons();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsDevelopmentIntervention } =
    useAdminViewInterventionsControllers();
  const { useDefineInterventionInterventions } =
    useAdminViewInterventionsDevelopmentIntervention();
  const { useArtistDefineInterventionInterventionsControllers } =
    useDefineInterventionInterventions();
  const { useArtistDefineInterventionKeyFactors } =
    useArtistDefineInterventionInterventionsControllers();
  const {
    define_intervention,
    updateKeyFactors,
    register,
    handleSubmit,
    errors,
  } = useArtistDefineInterventionKeyFactors();

  const disabledEditMode = _.includes(
    ['iv', 'c'],
    define_intervention.state.id
  );

  return (
    <>
      <TypographyWithTooltip
        text="Aspectos clave del proyecto*"
        variant="h3"
        textTooltip="Aspectos clave del proyecto*"
        className="text-primary"
        idTooltip="aspects_project"
        applyFontBold
      />

      <TypographyComponent
        text="A partir de la información compartida contigo en el plan creativo, identifica 3 aspectos claves sobre los cuales se va a enfocar tu intervención."
        variant="p"
        className="text-neutral-950 mt-2 mb-6"
      />

      <form className="">
        <StyledGroup col2 flex className="mb-6 flex-col">
          <InputForm
            name="key_factor_one"
            label=""
            type="text"
            className="full"
            placeholder="Ingresa un aspecto clave del proyecto"
            {...register('key_factor_one')}
            errors={errors}
            disabled={disabledEditMode}
          />
        </StyledGroup>
        <StyledGroup col2 flex className="mb-6 flex-col">
          <InputForm
            name="key_factor_two"
            label=""
            type="text"
            className="full"
            placeholder="Ingresa un aspecto clave del proyecto"
            {...register('key_factor_two')}
            errors={errors}
            disabled={disabledEditMode}
          />
        </StyledGroup>
        <StyledGroup col2 flex className="mb-6 flex-col">
          <InputForm
            name="key_factor_three"
            label=""
            type="text"
            className="full"
            placeholder="Ingresa un aspecto clave del proyecto"
            {...register('key_factor_three')}
            errors={errors}
            disabled={disabledEditMode}
          />
        </StyledGroup>
      </form>
      {errors['minTwoKeyFactors'] && (
        <p name="reading_level" className="text-sm mt-2 text-warning-600">
          {errors['minTwoKeyFactors'].message}
        </p>
      )}

      <div className="flex items-center space-x-2 mt-10 justify-center">
        <ButtonComponent
          text="Siguiente"
          onPress={handleSubmit((data) => {
            updateKeyFactors(data, setViewDefine);
          })}
        />
      </div>
    </>
  );
};

FormAspects.propTypes = {
  setViewDefine: PropTypes.func,
};

export default FormAspects;
