import useHelpers from '../../../../helpers';
import useStrings from '../../../../strings';
import useInitialStates from '../../../initialStates';

const useSpecialitiesReducers = () => {
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  //Initial States
  const { useGeneralInitialStates } = useInitialStates();
  const { useSpecialitiesInitialStates } = useGeneralInitialStates();
  const { initialStateSpecialities } = useSpecialitiesInitialStates();

  //Strings
  const { useGeneralTypes } = useStrings();
  const { useSpecialitiesTypes } = useGeneralTypes();
  const { GET_SPECIALITIES } = useSpecialitiesTypes();

  const specialities = createReducer(initialStateSpecialities, {
    [GET_SPECIALITIES](state, action) {
      const { result, entities } = action.payload;
      return { ...state, result, entities };
    },
  });

  return { specialities };
};

export default useSpecialitiesReducers;
