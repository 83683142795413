//Packages
import _ from 'lodash';
import { useMountedLayoutEffect } from 'react-table';

//Packages
import { useEffect, useState } from 'react';

const useTableCustom = ({
  setDataSelected,
  selectedRowIds,
  setOrderData,
  columns,
  data,
}) => {
  const [orderBy, setOrderBy] = useState({});

  useEffect(() => {
    _.map(columns, (column) =>
      setOrderBy((prevState) => ({ ...prevState, [column.accessor]: true }))
    );
  }, [columns]);

  useMountedLayoutEffect(() => {
    const selectedIds = Object.keys(selectedRowIds);
    const selectedRowsData = _.map(selectedIds, (x) => data[x]);
    setDataSelected(selectedRowsData);
  }, [selectedRowIds]);

  const handleDataToOrder = ({ orderByKey }) => {
    setOrderData({
      orderByKey,
      orderByValue: orderBy[orderByKey] ? 'desc' : 'asc',
    });
    setOrderBy((prevState) => ({
      ...prevState,
      [orderByKey]: !prevState[orderByKey],
    }));
  };

  return { handleDataToOrder, orderBy };
};

export default useTableCustom;
