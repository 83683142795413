// Packages
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { overStylesInputs, focusStylesInputs } from '../Inputs.styles';
import useGeneralStyles from 'styles/generalStyles.styles';
const { mixStatesFormStyles } = useGeneralStyles();

// Assets
import imageCalendar from 'assets/img/img-intervention-calendar.svg';

export const StyledInput = styled.input`
  ${tw` bg-neutral-300 text-neutral-950 border border-neutral-600 rounded py-3 px-3 appearance-none w-full`}
  ::placeholder {
    ${tw` text-neutral-700 `}
  }
  &:hover {
    ${(props) => overStylesInputs(props)}
  }

  &:focus {
    ${(props) => focusStylesInputs(props)}
  }

  ${(props) => props.disabled && tw`bg-neutral text-neutral-700`}

  ${(props) =>
    props.type === 'date' &&
    `
    &::-webkit-calendar-picker-indicator {
        -webkit-appearance: none;
        background-image: url(${imageCalendar});
        width: 22px;
        height: 22px;
    }
  `}

  ${(props) => props.type === 'date' && tw` appearance-none cursor-pointer`}
 
  ${(props) =>
    props.icon &&
    `background-image:url(${props.icon}); background-size:20px;background-repeat: no-repeat;
    background-position: 98% center;`}
 

  ${(props) =>
    mixStatesFormStyles(
      {
        errors: props.errors,
        success: props.success,
      },
      'border'
    )}
`;

export const StyledIconPass = styled.div.attrs((props) => ({
  className: `${!props.showPassword ? `icon-eye-off` : `icon-eye`} `,
}))`
  ${tw` text-primary absolute right-0 top-1/2 mr-4 `}
  transform: translate(0, -50%);
`;

export const StyledWrapperInputIcon = styled.div.attrs((props) => ({
  className: `
      ${props.hasIcon && props.errors && 'icon-warning-circle-out text-error'}
      ${
        props.hasIcon && props.success && 'icon-check-circle-out text-success'
      }`,
}))`
  ${tw` relative w-full flex items-center`}

  &:before {
    ${tw` absolute right-0 top-1/2 mr-4 `}
    transform: translate(0, -50%);
  }
`;
