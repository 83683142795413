// Packages
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import useGeneralStyles from '../../../styles/generalStyles.styles';

const { mixStatesFormStyles } = useGeneralStyles();

export const StyledInputWrapper = styled.div.attrs(() => ({}))`
  ${tw` relative flex flex-col `}

  .StyledDefaultLabel,
  .StyledHelperLabel {
    ${(props) =>
      mixStatesFormStyles(
        {
          errors: props.errors,
          success: props.success,
        },
        'text'
      )}
  }
`;

export const overStylesInputs = (props) => {
  const stylesOverDefault = tw`border-primary appearance-none`;
  const stylesHoverStates = mixStatesFormStyles(
    { errors: props.errors, success: props.success },
    'border'
  );
  return [stylesOverDefault, stylesHoverStates];
};

export const focusStylesInputs = (props) => {
  const stylesFocusinitialOut = 'outline: 3px solid var(--primary-100);';
  const stylesFocusDefault = tw`appearance-none`;
  const stylesFocusStates = mixStatesFormStyles(
    { errors: props.errors, success: props.success },
    'outline'
  );

  return [stylesFocusinitialOut, stylesFocusDefault, stylesFocusStates];
};
