//Hooks
import useCountriesSelectors from './countries';
import useLanguagesSelectors from './languages';
import useLegalDataSelectors from './legalData';
import useModalsSelectors from './modals';
import useSpecialitiesSelectors from './specialities';
import useColorsSelectors from './Colors';
import useAccessibilitySelectors from './accessibility';
import useSidebarSelectors from './sidebar';
import useNotificationSelectors from './notification';
import useTabSelectedSelectors from './tabSelected';
import useIsFilterEmptySelectors from './isFilterEmpty';

const useGeneralSelectors = () => {
  return {
    useCountriesSelectors,
    useLegalDataSelectors,
    useLanguagesSelectors,
    useModalsSelectors,
    useSpecialitiesSelectors,
    useColorsSelectors,
    useAccessibilitySelectors,
    useSidebarSelectors,
    useNotificationSelectors,
    useTabSelectedSelectors,
    useIsFilterEmptySelectors,
  };
};

export default useGeneralSelectors;
