import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const StyledModal = styled.div.attrs(() => ({
  className: 'StyledModal',
}))`
  ${tw` overflow-y-scroll items-start pt-8 pb-8 lg:pb-0 lg:pt-0 fixed w-full h-full top-0 z-50 left-0 flex lg:items-center justify-center opacity-0 pointer-events-none`}
  transition: all 1s;
  ${(props) => props.isActive && tw`opacity-100 pointer-events-auto`}
  ${(props) => (props.isGeneralModal ? tw`z-max` : tw`z-50`)}
  ${(props) =>
    props.isMobileScreenModal &&
    tw`items-center overflow-auto overflow-x-hidden`}
`;

export const StyledModalContent = styled.div.attrs(() => ({
  className: 'StyledModalContent',
}))`
  ${(props) => props.isMobileScreenModal && tw`mx-6 text-center`}
  ${tw` relative rounded-lg bg-neutral-50 px-7 lg:px-10 py-8 lg:py-14 max-h-screen`}
`;

export const StyledBackDrop = styled.div.attrs(() => ({
  className: 'StyledBackDrop',
}))`
  ${tw` fixed w-full h-full top-0 left-0 backdrop-filter backdrop-blur-sm opacity-0 pointer-events-none`}
  background: rgb(0 0 0 / 50%);
  backdrop-filter: blur(2px);
  transition: all 1s;
  ${(props) => props.isActive && tw`opacity-100 pointer-events-auto`}
  z-index:45;
`;

export const StyledButtonClose = styled.button.attrs(() => ({
  className: 'StyledButtonClose icon-close',
}))`
  ${tw` text-primary absolute top-0 right-0 mr-4 lg:mr-8 lg:mt-4 mt-2`}
`;
