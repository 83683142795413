import useAdminViewInterventionsCommunication from './communication';
import useAdminViewInterventionsCollectionInformation from './communication/collectionInformation';
import useAdminViewInterventionsDevelopmentIntervention from './developmentIntervention';

const useAdminViewInterventionsControllers = () => {
  return {
    useAdminViewInterventionsCommunication,
    useAdminViewInterventionsCollectionInformation,
    useAdminViewInterventionsDevelopmentIntervention,
  };
};

export default useAdminViewInterventionsControllers;
