import useAdminSpreadIntervention from './admin';
import useArtistSpreadIntervention from './artist';

const useViewDevelopmentInterventionSpreadIntervention = () => {
  return {
    useAdminSpreadIntervention,
    useArtistSpreadIntervention
  };
};

export default useViewDevelopmentInterventionSpreadIntervention;
