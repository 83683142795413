//Packages
import React, { lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useStrings from 'strings';

//Assets
import infoIcon from 'assets/icons/info.svg';

//Styles
const StyledGroup = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledContentButtons = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const RequestAccept = (props) => {
  const {
    setActiveDeny,
    setExpandAll,
    activeDeny,
    formAcceptMessage,
    onFormatDefaultValueCommunities,
    selectedMessage,
    optionsCommunities,
    optionsContactLists,
    minDateToSelect,
    handleAcceptMessageAdmin,
    numberOptions,
    setValidateOptionsRules,
    handleAddOptions,
    handleDeleteOption,
    handleOnChangeOptions,
  } = props;
  const { useComponents } = useViews();

  const { useTypographies, useInputs, useButtons, useLabels, useImages } =
    useComponents();
  const { DefaultLabel } = useLabels();
  const { Image } = useImages();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { TextArea, Select, CheckBox, InputForm } = useInputs();
  const { ButtonComponent } = useButtons();

  const { useConstants } = useStrings();
  const {
    LIST_OF_CHANNELS_FOR_SEND_MESSAGES,
    LIST_OF_OPTIONS_TO_DESTINATIONS,
    LIST_OF_TYPE_OF_COLLECTION,
    LIST_OF_TYPE_OF_INFORMATION_COLLECTION,
  } = useConstants();

  const {
    control,
    setValue,
    formState: { errors },
    watch,
    handleSubmit,
    register,
    reset,
  } = formAcceptMessage;

  useEffect(() => {
    setValue('typeDest', {
      label: 'Comunidades',
      value: 'comunidad',
    });
    setTimeout(() => {
      setValue(
        'communities',
        onFormatDefaultValueCommunities({
          listOfCommunities: selectedMessage.communities,
        })
      );
    }, 200);
  }, []);

  return (
    <>
      <TypographyComponent
        text="Crear nuevo mensaje"
        variant="h2"
        className="text-primary mt-6 font-bold border-t pt-4"
      />
      <TypographyComponent
        text="Para comunicarte con la comunidad asegurate de llenar la siguiente información. Recuerda que cada caracter cuenta, se lo mas conciso posible!"
        variant="p"
        className="text-neutral-950 mt-2 mb-4"
      />
      {watch('type')?.value === 'gi' && (
        <StyledGroup noSpace className="mt-4">
          <div>
            <TypographyWithTooltip
              text="Tipo de recolección*"
              variant="p"
              textTooltip="Tipo de recolección*"
              idTooltip="gi_type"
              className="text-neutral-950"
            />
            <Select
              name="gi_type"
              id="gi_type"
              placeholder="Seleccionar"
              options={LIST_OF_TYPE_OF_COLLECTION}
              label=""
              control={control}
              errors={errors}
            />
          </div>
          {watch('gi_type')?.value === 'a' && (
            <div>
              <TypographyWithTooltip
                text="Tipo de información a recolectar*"
                variant="p"
                textTooltip="Tipo de información a recolectar*"
                idTooltip="type_information"
                className="text-neutral-950"
              />
              <Select
                name="type_information"
                id="type_information"
                placeholder="Seleccionar"
                options={LIST_OF_TYPE_OF_INFORMATION_COLLECTION}
                label=""
                control={control}
                errors={errors}
              />
            </div>
          )}
        </StyledGroup>
      )}
      <StyledGroup noSpace className="mt-4">
        <div>
          <TypographyWithTooltip
            text="Canal de comunicación*"
            variant="p"
            textTooltip="Canal de comunicación*"
            idTooltip="canal"
            className="text-neutral-950"
          />
          <Select
            name="channel"
            id="canal"
            placeholder="Seleccionar"
            options={
              watch('gi_type')?.value === 'a' ||
              selectedMessage.type?.id === 'd'
                ? LIST_OF_CHANNELS_FOR_SEND_MESSAGES
                : [{ label: 'WhatsApp', value: 'ws' }]
            }
            label=""
            control={control}
            errors={errors}
            defaultValue={{
              label: selectedMessage.channel.name,
              value: selectedMessage.channel.id,
            }}
          />
        </div>
        <div>
          <TypographyWithTooltip
            text="Tipo de destino*"
            variant="p"
            textTooltip="Tipo de destino"
            idTooltip="dest"
            className="text-neutral-950"
          />

          <Select
            name="typeDest"
            id="typeDest"
            placeholder="Selecciona el tipo de Destino"
            className="mt-2"
            options={LIST_OF_OPTIONS_TO_DESTINATIONS}
            label=""
            control={control}
            errors={errors}
            defaultValue={{ label: 'Comunidades', value: 'comunidad' }}
          />
        </div>
      </StyledGroup>

      <Select
        name="communities"
        id="commun"
        placeholder="Selecciona el destino del mensaje"
        className="mt-2"
        options={
          watch('typeDest')?.value === 'comunidad'
            ? optionsCommunities
            : optionsContactLists
        }
        label="Destino"
        control={control}
        errors={errors}
        defaultValue={onFormatDefaultValueCommunities({
          listOfCommunities: selectedMessage.communities,
        })}
        disabled={!watch('typeDest')?.value}
        multiple
      />

      <StyledGroup className="mt-4">
        <Controller
          control={control}
          name="date_checkbox"
          render={({ field: { name, onChange } }) => {
            return (
              <CheckBox
                name={name}
                label="Establecer una fecha para envío de mensaje"
                className="mx-auto mt-6"
                onChange={onChange}
                errors={errors}
              />
            );
          }}
        />
      </StyledGroup>
      {watch('date_checkbox') && (
        <StyledGroup flex className="flex-col pb-4 ">
          <div className="flex items-center mb-2 mt-4">
            <DefaultLabel>Fecha de envío</DefaultLabel>
            <a data-tip="Fecha de envío" data-for="Grupo protagonista">
              <Image
                className="w-6 hidden lg:block ml-2"
                srcImage={infoIcon}
                altImage="info"
                titleImage="info"
              />
            </a>
            <ReactTooltip id="Grupo protagonista" />
          </div>
          <InputForm
            name="send_date"
            label=""
            type="datetime-local"
            min={minDateToSelect}
            className="w-4/12"
            placeholder="Selecciona la fecha de inicio"
            {...register('send_date')}
            errors={errors}
          />
        </StyledGroup>
      )}

      <StyledGroup>
        {watch('gi_type')?.value === 'a' ? (
          <>
            <TypographyWithTooltip
              text="Pregunta"
              variant="h3"
              textTooltip="pregunta"
              idTooltip="message"
              className="font-bold text-primary mt-6"
            />
            <InputForm
              name="message"
              label=""
              type="text"
              placeholder="Ingresa la pregunta"
              className="w-full col-span-2"
              errors={errors}
              control={control}
              defaultValue={selectedMessage?.message}
            />
          </>
        ) : (
          <>
            {watch('type')?.value === 'gi' ? (
              <TypographyWithTooltip
                text="Pregunta o mensaje"
                variant="h3"
                textTooltip="pregunta"
                idTooltip="message"
                className="font-bold text-primary mt-6"
              />
            ) : (
              <TypographyComponent
                text="Redactar mensaje"
                variant="h3"
                className="text-primary mt-4 font-bold"
              />
            )}

            <TextArea
              control={control}
              name="message"
              placeholder="Mensaje"
              className="w-full col-span-2"
              label=""
              errors={errors}
            />
          </>
        )}
      </StyledGroup>

      {watch('type_information')?.value === 'qn' &&
        watch('gi_type')?.value === 'a' && (
          <div className="flex items-end justify-between space-x-6 ">
            <div className="flex-1">
              {_.map(numberOptions, (option, index) => {
                return (
                  <div className="flex items-center space-x-6" key={option.id}>
                    <TypographyComponent
                      variant="p"
                      text={`Opción ${index + 1}`}
                      className="text-primary font-bold flex-none"
                    />
                    <InputForm
                      name={`options[${index}]`}
                      label=""
                      type="text"
                      placeholder={`Ingresa la opción ${index + 1}`}
                      className="flex-1"
                      errors={errors}
                      control={control}
                      onChange={(event) => {
                        handleOnChangeOptions({
                          optionIndex: option.id,
                          optionValue: event.target.value,
                        });
                        setValidateOptionsRules((prevValue) => !prevValue);
                      }}
                    />
                    <StyledContentButtons>
                      <ButtonComponent
                        text=""
                        className="bg-transparent text-tertiary"
                        onPress={(event) => {
                          handleDeleteOption({
                            event,
                            optionId: index,
                          });
                        }}
                        icon={<div className={`icon-delete `}></div>}
                      />
                    </StyledContentButtons>
                  </div>
                );
              })}
            </div>
            <ButtonComponent
              variant="primary"
              mode="outline"
              text="Agregar otra opción"
              className="mb-2"
              icon={<div className="icon-close transform rotate-45" />}
              disabled={numberOptions.length === 10}
              onPress={handleAddOptions}
            />
          </div>
        )}

      {errors['options'] && (
        <p className="text-sm mt-2 text-error">{errors['options'].message}</p>
      )}

      <div className="flex items-center justify-between space-x-6">
        <ButtonComponent
          variant="primary"
          mode="outline"
          className="mt-4 shadow-none border-none"
          text="Cancelar"
          onPress={(e) => {
            e.preventDefault();
            reset();
            setActiveDeny({ ...activeDeny, deny: false, accept: false });
            setExpandAll(true);
          }}
        />

        <ButtonComponent
          variant="primary"
          className="mt-4"
          text="Enviar mensaje"
          icon={<div className="icon-send text-lg h-6 flex"></div>}
          onPress={handleSubmit((data) =>
            handleAcceptMessageAdmin({
              data,
              state: 'ip',
              formToUse: formAcceptMessage,
              dontHaveModalConfirmation: true,
              isArtistRequest: true,
            })
          )}
        />
      </div>
    </>
  );
};

RequestAccept.propTypes = {
  setActiveDeny: PropTypes.func,
  setExpandAll: PropTypes.func,
  activeDeny: PropTypes.object,
  formAcceptMessage: PropTypes.object,
  onFormatDefaultValueCommunities: PropTypes.func,
  selectedMessage: PropTypes.object,
  optionsCommunities: PropTypes.array,
  optionsContactLists: PropTypes.array,
  minDateToSelect: PropTypes.string,
  handleAcceptMessageAdmin: PropTypes.func,
  numberOptions: PropTypes.array,
  setValidateOptionsRules: PropTypes.func,
  handleAddOptions: PropTypes.func,
  handleDeleteOption: PropTypes.func,
  handleOnChangeOptions: PropTypes.func,
};

RequestAccept.defaultProps = {
  setActiveDeny: () => {},
  setExpandAll: () => {},
  activeDeny: {},
  formAcceptMessage: {},
  onFormatDefaultValueCommunities: () => {},
  selectedMessage: {},
  optionsCommunities: [],
  optionsContactLists: [],
  minDateToSelect: '',
  handleAcceptMessageAdmin: () => {},
  numberOptions: [],
  setValidateOptionsRules: () => {},
  handleAddOptions: () => {},
  handleDeleteOption: () => {},
  handleOnChangeOptions: () => {},
};

export default RequestAccept;
