//Packages
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useAdminInterventionsRootProviders = () => {
  const createIntervertionById = ({ formData, projectId }) => {
    const response = axios({
      method: 'POST',
      headers: {
        'content-type': 'multipart/form-data',
      },
      url: `/projects/${projectId}/interventions`,
      data: formData,
    });
    return trackPromise(response);
  };

  const getDefinitionBehaviors = () => {
    const response = axios({
      method: 'GET',
      url: '/commons/definition-behaviors',
    });
    return trackPromise(response);
  };

  const getInterventionsByProject = ({ projectId, paginate, page, search }) => {
    const response = axios({
      method: 'GET',
      url: `/projects/${projectId}/interventions`,
      params: {
        paginate,
        page,
        name: search,
      },
    });
    return trackPromise(response);
  };

  const deleteInterventionByProject = ({ projectId, interventionId }) => {
    const response = axios({
      method: 'DELETE',
      url: `/projects/${projectId}/interventions/${interventionId}`,
    });
    return trackPromise(response);
  };

  const updateInterventionByProject = ({
    formData,
    projectId,
    interventionId,
  }) => {
    const response = axios({
      method: 'POST',
      url: `/projects/${projectId}/interventions/${interventionId}`,
      data: formData,
    });
    return trackPromise(response);
  };

  const getInterventionDetailByProject = ({ projectId, interventionId }) => {
    const response = axios({
      method: 'GET',
      url: `/projects/${projectId}/interventions/${interventionId}`,
    });
    return trackPromise(response);
  };

  const getInterventionsForEnablingProvider = ({
    state,
    name,
    country,
    department,
    project,
    objetive,
    type,
    page,
  }) => {
    const response = axios({
      method: 'GET',
      url: `/interventions`,
      params: {
        state,
        name: name || undefined,
        country_id: country || undefined,
        department_id: department || undefined,
        project_id: project || undefined,
        objective: objetive || undefined,
        type: type || undefined,
        page: page || undefined,
      },
    });
    return trackPromise(response);
  };

  const getInterventionProvider = ({ intervention_id }) => {
    const response = axios({
      method: 'GET',
      url: `/interventions/${intervention_id}`,
    });
    return trackPromise(response);
  };

  const updateInterventionProvider = ({ interventionId, data }) => {
    const response = axios({
      method: 'PATCH',
      url: `/interventions/${interventionId}`,
      data,
    });
    return trackPromise(response);
  };

  return {
    createIntervertionById,
    getDefinitionBehaviors,
    getInterventionsByProject,
    deleteInterventionByProject,
    updateInterventionByProject,
    getInterventionDetailByProject,
    getInterventionProvider,
    updateInterventionProvider,
    getInterventionsForEnablingProvider,
  };
};

export default useAdminInterventionsRootProviders;
