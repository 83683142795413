//Packages
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';
import useHelpers from 'helpers';

const Qualitative = (props) => {
  const {
    totalAnswers,
    dataCollectionInformationAnswersSelected,
    handleChangePageAnswers,
  } = props;

  const { useComponents } = useViews();

  const { useTypographies, useBadges, usePagers } = useComponents();

  const { DefaultBadge } = useBadges();
  const { TypographyComponent } = useTypographies();
  const { Pager } = usePagers();

  const { useQuickFunctions } = useHelpers();
  const { getYearMonthDayFromDate } = useQuickFunctions();

  return (
    <>
      <DefaultBadge
        type="light"
        label={`${totalAnswers} ${
          totalAnswers === 1 ? 'respuesta' : 'respuestas'
        }`}
      />

      {dataCollectionInformationAnswersSelected.data?.length > 0 && (
        <ul className="mt-8 space-y-2">
          {_.map(
            dataCollectionInformationAnswersSelected.data,
            (answer, idx) => (
              <li
                key={`answers-${idx}`}
                className="rounded bg-neutral-400 px-6 py-4"
              >
                <TypographyComponent
                  text={answer.response}
                  variant="p"
                  className="text-neutral-950 mt-0 "
                />
                <TypographyComponent
                  text={getYearMonthDayFromDate({
                    dateInString: answer.reply_date,
                    format: 'dmyTime',
                  })}
                  variant="h5"
                  className="text-neutral-800 mt-0 "
                />
              </li>
            )
          )}
        </ul>
      )}

      <Pager
        pageCount={
          Math.ceil(
            dataCollectionInformationAnswersSelected.meta?.total /
              dataCollectionInformationAnswersSelected.meta?.per_page
          ) || 1
        }
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        previousLabel={Boolean(
          dataCollectionInformationAnswersSelected.links?.prev
        )}
        nextLabel={Boolean(
          dataCollectionInformationAnswersSelected.links?.next
        )}
        className="flex items-center lg:justify-end justify-center my-6"
        onPageChange={({ selected }) => handleChangePageAnswers(selected)}
      />

      {/* <ButtonComponent
        text="Descargar respuestas"
        className=" flex items-center mt-12 ml-auto"
        icon={<div className="icon-download text-base"></div>}
        onPress={() => {}}
      /> */}
    </>
  );
};

Qualitative.propTypes = {
  totalAnswers: PropTypes.number,
  dataCollectionInformationAnswersSelected: PropTypes.object,
  handleChangePageAnswers: PropTypes.func,
};

Qualitative.defaultProps = {
  totalAnswers: 0,
  dataCollectionInformationAnswersSelected: {},
  handleChangePageAnswers: () => {},
};

export default Qualitative;
