//Packages
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useAdminDevelopmentSpreadInterventionProviders = () => {
  const getSpreadInterventionProvider = ({ interventionId }) => {
    const response = axios({
      method: 'GET',
      url: `/interventions/${interventionId}/final-process`,
    });
    return trackPromise(response);
  };

  const updateSpreadInterventionProvider = ({
    interventionId,
    spreadId,
    data,
  }) => {
    const response = axios({
      method: 'PATCH',
      url: `/interventions/${interventionId}/final-process/${spreadId}`,
      data,
    });
    return trackPromise(response);
  };

  const uploadFileSpreadInterventionProvider = ({
    interventionId,
    spreadId,
    data,
  }) => {
    const response = axios({
      method: 'POST',
      url: `/interventions/${interventionId}/final-process/${spreadId}/file`,
      data,
    });
    return trackPromise(response);
  };

  const deleteFileSpreadInterventionProvider = ({
    interventionId,
    spreadId,
    fileId,
  }) => {
    const response = axios({
      method: 'DELETE',
      url: `/interventions/${interventionId}/final-process/${spreadId}/file/${fileId}`,
    });
    return trackPromise(response);
  };

  return {
    getSpreadInterventionProvider,
    updateSpreadInterventionProvider,
    uploadFileSpreadInterventionProvider,
    deleteFileSpreadInterventionProvider,
  };
};

export default useAdminDevelopmentSpreadInterventionProviders;
