//Packages
import { useEffect, useState } from 'react';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const useInputRichText = ({
  setRawContentRichText = () => {},
  defaultValue,
  maxLength,
  setErrors,
}) => {
  const [editorData, setEditorData] = useState(() => EditorState.createEmpty());
  const [errorInputRichText, setErrorInputRichText] = useState('');

  const onEditorStateChange = (editorState) => {
    setEditorData(editorState);
  };

  useEffect(() => {
    setRawContentRichText({
      ...convertToRaw(editorData.getCurrentContent()),
      plainText: editorData.getCurrentContent().getPlainText(),
      htmlContent: draftToHtml(convertToRaw(editorData.getCurrentContent())),
    });
  }, [editorData]);

  useEffect(() => {
    if (defaultValue?.blocks) {
      setEditorData(
        EditorState.createWithContent(convertFromRaw(defaultValue))
      );
    } else {
      setEditorData(EditorState.createEmpty());
    }
  }, [defaultValue]);

  useEffect(() => {
    if (
      maxLength &&
      editorData.getCurrentContent().getPlainText().length > maxLength
    ) {
      setErrorInputRichText(`Máximo ${maxLength} caracteres`);
    } else {
      setErrorInputRichText('');
    }
  }, [editorData]);

  useEffect(() => {
    setErrors(Boolean(errorInputRichText));
  }, [errorInputRichText]);

  return { editorData, setEditorData, onEditorStateChange, errorInputRichText };
};

export default useInputRichText;
