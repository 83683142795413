//Packages
import { useState } from 'react';

//Hooks
import useApi from 'api';
import useModels from 'models';

const useCollectInformationMessages = () => {
  const [
    dataCollectionInformationAnswersSelected,
    setDataCollectionInformationAnswersSelected,
  ] = useState({ options: [], data: [], meta: {}, links: {} });

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();
  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const { useAdminDevelopmentInterventionKnowCommunityActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const { actGetMessageAnswersDeepenKnowledgeScheduleInfo } =
    useAdminDevelopmentInterventionKnowCommunityActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionsCommunicationMessagesSelector } =
    useAdminInterventionsSelectors();
  const { selectedMessage: selectedMessageAux } = useSelector(
    getInterventionsCommunicationMessagesSelector
  );

  const handleGetDetailResponseCollectionMessage = ({
    messageId,
    onSuccess,
    selectedMessage,
  }) => {
    dispatch(
      actGetMessageAnswersDeepenKnowledgeScheduleInfo(
        {
          message_request_id: messageId,
        },
        (response) => {
          const { data, links, meta } = response;

          if (
            selectedMessage.gi_type?.id === 'a' &&
            selectedMessage?.type_information?.id === 'qn'
          ) {
            setDataCollectionInformationAnswersSelected((prevValue) => ({
              ...prevValue,
              options: data.options,
            }));
          } else {
            setDataCollectionInformationAnswersSelected((prevValue) => ({
              ...prevValue,
              data,
              links,
              meta,
            }));
          }
          onSuccess && onSuccess();
        }
      )
    );
  };

  const handleChangePageAnswers = (selected) => {
    dispatch(
      actGetMessageAnswersDeepenKnowledgeScheduleInfo(
        {
          message_request_id: selectedMessageAux.id,
          page: selected + 1,
        },
        (response) => {
          const { data, links, meta } = response;

          selectedMessageAux.gi_type?.id === 'm' ||
            (selectedMessageAux.gi_type?.id === 'a' &&
              selectedMessageAux?.type_information?.id !== 'qn' &&
              setDataCollectionInformationAnswersSelected((prevValue) => ({
                ...prevValue,
                data,
                links,
                meta,
              })));
        }
      )
    );
  };

  return {
    handleChangePageAnswers,
    dataCollectionInformationAnswersSelected,
    setDataCollectionInformationAnswersSelected,
    handleGetDetailResponseCollectionMessage,
  };
};

export default useCollectInformationMessages;
