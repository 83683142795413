//Packages
import React from 'react';
import { Switch, useRouteMatch, Redirect } from 'react-router-dom';
import useNavigation from '../../..';

//Hooks
import useViews from '../../../../views';

//Screens
const { useScreens } = useViews();
const { ContactRoot, CreateContactScreen, EditContactScreen } = useScreens();

const AdminContactRouter = () => {
  const { path } = useRouteMatch();

  const { useRoutes } = useNavigation();
  const { PrivateRoute } = useRoutes();

  return (
    <Switch>
      <PrivateRoute
        exact
        path={`${path}/create`}
        component={CreateContactScreen}
        redirect="/"
      />
      <PrivateRoute
        exact
        path={`${path}/edit/:id`}
        component={EditContactScreen}
        redirect="/"
      />
      <PrivateRoute
        exact
        path={`${path}`}
        component={ContactRoot}
        redirect="/"
      />
      <Redirect to={`${path}`} />
    </Switch>
  );
};

export default AdminContactRouter;
