//Packages
import React, { Suspense, lazy } from 'react';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
const StyledBackButton = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);

const StyledForm = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledForm,
  }))
);

const StyledGroup = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledDivider = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledDivider,
  }))
);

const AdminDashboardCommunitiesContactsCreate = () => {
  const { useLayouts, useComponents } = useViews();
  const { AdminLayout } = useLayouts();
  const { useButtons, useInputs, useTypographies, useBoxes, useLoaders } =
    useComponents();
  const { DefaultLoader } = useLoaders();
  const { ButtonComponent } = useButtons();
  const { InputForm, Select, Radio } = useInputs();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { Box } = useBoxes();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminCommunitiesDashboardHooks } =
    useAdminDashboardProjectsHooks();
  const { useAdminContactsCommunitiesDashboardHooks } =
    useAdminCommunitiesDashboardHooks();
  const { useAdminCreateContactsCommunitiesDashboard } =
    useAdminContactsCommunitiesDashboardHooks();
  const {
    createUserForm,
    createUser,
    history,
    optionsIndicatives,
    OPTIONS_ROLES_WITHOUT_LOGIN,
  } = useAdminCreateContactsCommunitiesDashboard();

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = createUserForm;

  return (
    <Suspense fallback={<DefaultLoader />}>
      <AdminLayout>
        <>
          <StyledBackButton className="mt-3" onClick={history.goBack}>
            Regresar
          </StyledBackButton>
          <Box className="bg-neutral-200 w-11/12 mx-auto max-w-screen-lg lg:px-16 lg:py-12 px-6 py-8 lg:my-8">
            <>
              <TypographyComponent
                text="Crear nuevo contacto"
                variant="h2"
                className="font-bold text-primary mb-4"
              />
              <TypographyComponent
                text="Antes de añadir un usuario asegúrate de tener autorización de esta persona para ser añadida a la lista de contactos."
                variant="p"
                className="font-book text-neutral-950 mb-10"
              />

              <StyledForm onSubmit={handleSubmit(createUser)}>
                <StyledGroup className="items-center">
                  <Select
                    name="role"
                    control={control}
                    options={OPTIONS_ROLES_WITHOUT_LOGIN}
                    label="Rol*"
                    placeholder="Selecciona un rol"
                    errors={errors}
                  />

                  <TypographyWithTooltip
                    text=""
                    variant="p"
                    textTooltip="Rol del contacto"
                    idTooltip="role"
                    className="text-neutral-950"
                  />
                </StyledGroup>

                <StyledDivider />

                <StyledGroup noSpace>
                  <InputForm
                    type="text"
                    name="name"
                    placeholder="Ingresa el nombre del usuario"
                    label="Nombre*"
                    className="w-full"
                    errors={errors}
                    {...register('name')}
                  />
                  <InputForm
                    type="text"
                    name="last_name"
                    placeholder="Ingresa el apellido del usuario"
                    label="Apellido*"
                    className="w-full"
                    errors={errors}
                    {...register('last_name')}
                  />
                </StyledGroup>

                <StyledGroup noSpace>
                  <InputForm
                    type="text"
                    name="email"
                    placeholder="Ingresa el correo electrónico del usuario"
                    label="Correo electrónico"
                    className="w-full"
                    errors={errors}
                    {...register('email')}
                  />
                  <StyledGroup
                    col2
                    className="items-end flex space-x-4 lg:space-x-3 "
                  >
                    <Select
                      name="code"
                      id="code"
                      className="lg:w-7/12"
                      control={control}
                      options={optionsIndicatives}
                      label="Teléfono*"
                      placeholder="Seleccione*"
                      errors={errors}
                    />
                    <InputForm
                      type="number"
                      name="number_phone"
                      placeholder="Número de teléfono"
                      className="w-full"
                      errors={errors}
                      {...register('number_phone')}
                    />
                  </StyledGroup>
                </StyledGroup>

                <StyledGroup noSpace>
                  <InputForm
                    type="text"
                    name="gender"
                    placeholder="Ingresa el género del usuario"
                    label="Género"
                    className="w-full"
                    errors={errors}
                    {...register('gender')}
                  />

                  <div>
                    <TypographyComponent
                      text="¿Cuenta con WhatsApp?"
                      variant="p"
                      className="font-bold text-neutral-950 mb-4"
                    />
                    <StyledGroup noSpace flex>
                      <Radio
                        name="has_whatsapp"
                        value={true}
                        label="Sí"
                        errors={errors}
                        {...register('has_whatsapp')}
                        className="mr-8"
                      />
                      <Radio
                        name="has_whatsapp"
                        value={false}
                        label="No"
                        errors={errors}
                        {...register('has_whatsapp')}
                      />
                    </StyledGroup>
                  </div>
                </StyledGroup>

                <div>
                  <ButtonComponent
                    variant="primary"
                    className="mx-auto mt-10"
                    text="Crear usuario"
                    onPress={() => {
                      handleSubmit(createUser);
                    }}
                  />
                </div>
              </StyledForm>
            </>
          </Box>
        </>
      </AdminLayout>
    </Suspense>
  );
};

export default AdminDashboardCommunitiesContactsCreate;
