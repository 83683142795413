//Packages
import React, { Suspense, lazy, useEffect } from 'react';
import PropTypes from 'prop-types';

//Assets
import imageFacebook from 'assets/icons/facebook.svg';
import imageTwitter from 'assets/icons/twitter.svg';
import imageInstagram from 'assets/icons/instagram.svg';
import imageYoutube from 'assets/icons/youtube.svg';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
const StyledForm = lazy(() =>
  import('../../Register.styles').then((mod) => ({
    default: mod.StyledForm,
  }))
);

const StyledGroup = lazy(() =>
  import('../../Register.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledContentTitle = lazy(() =>
  import('../../Register.styles').then((mod) => ({
    default: mod.StyledContentTitle,
  }))
);

const Partner = (props) => {
  const { role } = props;
  //layout- components
  const { useComponents } = useViews();
  const { useInputs, useButtons, useImages, useLabels, useLoaders } =
    useComponents();
  const { DefaultLoader } = useLoaders();
  const { InputForm, Select, TextArea } = useInputs();
  const { ButtonComponent } = useButtons();
  const { Image } = useImages();
  const { DefaultLabel } = useLabels();
  const { useScreenHooks } = useControllers();
  const { useAuth } = useScreenHooks();
  const { useRegister } = useAuth();
  const {
    formRegister,
    optionsLanguages,
    optionsCountries,
    optionsDepartments,
    optionsCities,
    optionsIndicatives,
    preRegister,
    setRole,
  } = useRegister();
  useEffect(() => {
    setRole(role);
  }, [role]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    watch,
  } = formRegister;

  return (
    <Suspense fallback={<DefaultLoader />}>
      <>
        <StyledForm onSubmit={handleSubmit(preRegister)}>
          <StyledGroup>
            <InputForm
              type="text"
              name="organization"
              placeholder="Ingresa tu organización"
              label="Organización*"
              className="w-full mt-4"
              errors={errors}
              {...register('organization')}
            />
            <InputForm
              type="text"
              name="name"
              placeholder="Ingresa tu nombre*"
              label="Nombre*"
              className="w-full"
              errors={errors}
              {...register('name')}
            />
            <InputForm
              type="text"
              name="last_name"
              placeholder="Ingresa tu apellido"
              label="Apellido*"
              className="w-full"
              errors={errors}
              {...register('last_name')}
            />
            <InputForm
              type="email"
              name="email"
              placeholder="Ingresa tu correo electrónico"
              label="Correo electrónico*"
              className="w-full"
              errors={errors}
              {...register('email')}
            />
            <Select
              name="country"
              id="country"
              placeholder="Selecciona tu país"
              control={control}
              options={optionsCountries}
              label="País*"
              errors={errors}
            />
            <Select
              name="department"
              id="department"
              placeholder="Selecciona tu Dpto./Estado"
              control={control}
              options={optionsDepartments}
              label="Departamento o Estado*"
              errors={errors}
              disabled={!watch('country')?.value}
            />

            <Select
              name="city"
              id="city"
              placeholder="Selecciona tu Cdad./Mpio."
              control={control}
              options={optionsCities}
              label="Ciudad o Municipio*"
              errors={errors}
              disabled={!watch('department')?.value}
            />

            <StyledGroup
              col2
              className="items-end flex space-x-4 lg:space-x-3 "
            >
              <Select
                name="phone_code"
                id="phone_code"
                placeholder="Seleccione"
                className="w-1/3 lg:w-7/12"
                control={control}
                options={optionsIndicatives}
                label="Teléfono*"
                errors={errors}
              />
              <InputForm
                type="number"
                name="phone"
                placeholder="Ingresa tu número de teléfono"
                className="w-2/3 lg:w-full"
                errors={errors}
                {...register('phone')}
              />
            </StyledGroup>

            <InputForm
              type="text"
              name="gender"
              placeholder="Ingresa tu género"
              label="Género"
              className="w-full"
              errors={errors}
              {...register('gender')}
            />
            <Select
              name="language"
              id="language"
              control={control}
              placeholder="Selecciona tu idioma"
              options={optionsLanguages}
              label="Idioma*"
              errors={errors}
            />
          </StyledGroup>

          <StyledGroup>
            <InputForm
              control={control}
              type="text"
              name="website"
              placeholder="Ingresa la dirección URL de tu sitio web"
              label="Sitio web"
              className="w-full mt-4"
              errors={errors}
            />

            <div>
              <StyledContentTitle>
                <Image
                  altImage="twiter"
                  titleImage="twiter"
                  srcImage={imageTwitter}
                  className="w-5 h-5 mb-1 mr-2"
                />
                <DefaultLabel>Twiter</DefaultLabel>
              </StyledContentTitle>

              <InputForm
                control={control}
                type="text"
                name="tw"
                placeholder="Ingresa tu perfil de Twitter"
                className="w-full"
                errors={errors}
              />
            </div>

            <div>
              <StyledContentTitle>
                <Image
                  altImage="facebook"
                  titleImage="facebook"
                  srcImage={imageFacebook}
                  className="w-5 h-5 mb-1 mr-2"
                />
                <DefaultLabel>Facebook</DefaultLabel>
              </StyledContentTitle>

              <InputForm
                control={control}
                type="text"
                name="fb"
                placeholder="Ingresa tu perfil de Facebook"
                className="w-full"
                errors={errors}
              />
            </div>

            <div>
              <StyledContentTitle>
                <Image
                  altImage="instagram"
                  titleImage="instagram"
                  srcImage={imageInstagram}
                  className="w-5 h-5 mb-1 mr-2"
                />
                <DefaultLabel>Instagram</DefaultLabel>
              </StyledContentTitle>

              <InputForm
                control={control}
                type="text"
                name="inst"
                placeholder="Ingresa tu perfil de Instagram"
                className="w-full"
                errors={errors}
              />
            </div>
            <div>
              <StyledContentTitle>
                <Image
                  altImage="youtube"
                  titleImage="youtube"
                  srcImage={imageYoutube}
                  className="w-5 h-5 mb-1 mr-2"
                />
                <DefaultLabel>Youtube</DefaultLabel>
              </StyledContentTitle>

              <InputForm
                control={control}
                type="text"
                name="yt"
                placeholder="Ingresa tu canal de Youtube"
                className="w-full"
                errors={errors}
              />
            </div>
            <InputForm
              control={control}
              type="text"
              name="other"
              placeholder="Ingresa la URL de tu red social"
              label="Otra"
              className="w-full"
              errors={errors}
            />
          </StyledGroup>

          <StyledGroup>
            <TextArea
              control={control}
              name="inclusion_question"
              placeholder="¿Cómo contribuye tu práctica para fortalecer los mecanismos de inclusión? (género, grupo marginalizado, clase social)"
              className="w-full col-span-2"
              label="¿Cómo contribuye tu práctica para fortalecer los mecanismos de inclusión? (género, grupo marginalizado, clase social)"
              errors={errors}
            />
          </StyledGroup>

          <StyledGroup>
            <TextArea
              control={control}
              name="interest_onedrop_question"
              placeholder="¿Por qué estás interesado en colaborar con One Drop?"
              className="w-full col-span-2"
              label="¿Por qué estás interesado en colaborar con One Drop?"
              errors={errors}
            />
          </StyledGroup>

          <StyledGroup>
            <TextArea
              control={control}
              name="interest_art_question"
              placeholder="¿Por qué estás interesado en el arte social para el cambio de comportamiento?"
              className="w-full col-span-2"
              label="¿Por qué estás interesado en el arte social para el cambio de comportamiento?"
              errors={errors}
            />
          </StyledGroup>

          <StyledGroup>
            <TextArea
              control={control}
              name="pre_experiences_question"
              placeholder="¿Cuentas con experiencias previas con diferentes actores? (Gobierno, Comunidades, artistas, Instituciones escolares, organizaciones internacionales, instituciones de salud, actores del sector del agua, instituciones culturales, etc.)"
              className="w-full col-span-2"
              label="¿Cuentas con experiencias previas con diferentes actores? (Gobierno, Comunidades, artistas, Instituciones escolares, organizaciones internacionales, instituciones de salud, actores del sector del agua, instituciones culturales, etc.)"
              errors={errors}
            />
          </StyledGroup>

          <ButtonComponent
            variant="primary"
            text="Enviar formulario de registro"
            onPress={() => {
              handleSubmit(preRegister);
            }}
          />
        </StyledForm>
      </>
    </Suspense>
  );
};
Partner.propTypes = {
  role: PropTypes.object,
};

export default Partner;
