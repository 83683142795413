//Hooks
import useControllers from '../..';
import useApi from '../../../api';
import useHelpers from '../../../helpers';

const useUploadProfilePhoto = ({ onSuccess } = {}) => {
  const { useQuickFunctions } = useHelpers();
  const { urltoFile } = useQuickFunctions();

  const { useActions } = useApi();
  const { dispatch, useUserActions } = useActions();
  const { actUpdateAvatar } = useUserActions();

  const { useGeneralHooks } = useControllers();
  const { useModalUploadProfilePhoto, useCropImage } = useGeneralHooks();
  const {
    showModal,
    blobImage,
    name,
    type,
    handleCloseModal: handleCloseCropperModal,
  } = useModalUploadProfilePhoto();
  const {
    crop,
    zoom,
    rotation,
    imageForCropper,
    onCropComplete,
    setZoom,
    setCrop,
    setRotation,
    saveCroppedImage,
  } = useCropImage({ imageForCropper: blobImage });

  const onSuccessUpdateAvatarCropped = async ({ croppedImageBlob }) => {
    const file = await urltoFile({
      url: croppedImageBlob,
      fileName: name,
      mimeType: type,
    });

    if (onSuccess) {
      onSuccess({ file, croppedImageBlob });
    } else {
      const formData = new FormData();
      formData.append('avatar', file);
      dispatch(actUpdateAvatar(formData));
    }
    handleCloseCropperModal();
  };

  const handleUpdateAvatarCropped = () => {
    saveCroppedImage(onSuccessUpdateAvatarCropped);
  };

  return {
    setZoom,
    setCrop,
    setRotation,
    onCropComplete,
    crop,
    zoom,
    rotation,
    imageForCropper,

    handleUpdateAvatarCropped,

    showModal,
    handleCloseCropperModal,
  };
};

export default useUploadProfilePhoto;
