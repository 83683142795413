import useApi from '../../..';
import useStrings from '../../../../strings';

const useColorsActions = () => {
  const { useProviders } = useApi();
  const { useGeneralProviders } = useProviders();
  const { useColorsProviders } = useGeneralProviders();
  const { getColorsProvider } = useColorsProviders();

  const { useGeneralTypes } = useStrings();
  const { useColorsTypes } = useGeneralTypes();
  const { GET_COLORS } = useColorsTypes();

  const actGetColors = (onSuccess, onError) => async (dispatch) => {
    try {
      const res = await getColorsProvider();
      dispatch({ type: GET_COLORS, payload: res.data?.data });
      onSuccess && onSuccess(res);
    } catch (error) {
      onError && onError(error);
    }
  };

  return { actGetColors };
};

export default useColorsActions;
