//Packages
import React from 'react';
import { Switch, useRouteMatch, Redirect } from 'react-router-dom';
import useNavigation from '../..';

//Hooks
import useViews from '../../../views';

//Screens
const { useScreens } = useViews();
const { Dashboard, Profile } = useScreens();

const DashboardRouter = () => {
  const { path } = useRouteMatch();

  const { useRoutes } = useNavigation();
  const { PrivateRoute } = useRoutes();

  return (
    <Switch>
      <PrivateRoute exact path={path} component={Dashboard} redirect="/" />
      <PrivateRoute
        exact
        path={`${path}/profile`}
        component={Profile}
        redirect="/"
      />
      <Redirect to={path} />
    </Switch>
  );
};

export default DashboardRouter;
