//Packages
import React, { Suspense, lazy } from 'react';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

import plusIcon from 'assets/icons/plus.svg';

//Hooks
import useControllers from 'controllers';
import useViews from 'views';

//Styles
const StyledBackButton = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);

const StyledGroup = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledForm = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledForm,
  }))
);

const StyledContentButtons = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const StyledContentButtonsModal = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const StyledCollapseDetailContent = lazy(() =>
  import('styles/collapse/collapse.styles').then((mod) => ({
    default: mod.StyledCollapseDetailContent,
  }))
);

const StyledMultimedia = lazy(() =>
  import('styles/collapse/collapse.styles').then((mod) => ({
    default: mod.StyledMultimedia,
  }))
);

const SttledContentInformation = lazy(() =>
  import('styles/collapse/collapse.styles').then((mod) => ({
    default: mod.SttledContentInformation,
  }))
);

const StyledContentCols = lazy(() =>
  import('styles/collapse/collapse.styles').then((mod) => ({
    default: mod.StyledContentCols,
  }))
);

const StyledWrapperCommunity = lazy(() =>
  import('../Dashboard.styles').then((mod) => ({
    default: mod.StyledWrapperCommunity,
  }))
);

const StyledCardCommunity = lazy(() =>
  import('../Dashboard.styles').then((mod) => ({
    default: mod.StyledCardCommunity,
  }))
);

const AdminDashboardFestivitiesAndTraditions = () => {
  const { useComponents } = useViews();
  const {
    useInputs,
    useButtons,
    useTypographies,
    useImages,
    useModals,
    useBoxes,
    useLoaders,
  } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { InputForm, TextArea } = useInputs();
  const { ButtonComponent, ButtonAddActivity } = useButtons();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { Image, UploadImagePreview } = useImages();
  const { DefaultModal } = useModals();
  const { Box } = useBoxes();

  const { useScreenHooks, useGeneralHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminSocioCulturalInfoDashboard } =
    useAdminDashboardProjectsHooks();
  const { useSelectLayoutByRole } = useGeneralHooks();
  const { LayoutSelected, props } = useSelectLayoutByRole();

  const {
    register,
    errors,
    handleSubmit,
    control,
    handleRegisterSocialDynamic,
    handleResetInputFile,
    handleAddSocialDynamic,
    socialDynamicData,
    handleEditSocioCulturalInfo,
    handleUpdateSocialDynamic,
    handleSelectDefaultImage,
    handleCancelSocialDynamic,
    show,
    cleanFileInputs,
    showDeleteSocialDynamicModal,
    handleDeleteSocialDynamicModal,
    setSocioCulturalInfoId,
    listSocioCulturalInfo,
    handleDeleteSocialDynamic,
    handleSelectTypeFile,
    handleGoBack,
  } = useAdminSocioCulturalInfoDashboard();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <LayoutSelected {...props}>
        <StyledBackButton className="mt-3" onClick={handleGoBack}>
          Regresar
        </StyledBackButton>

        <Box className="bg-neutral-200 w-11/12 mx-auto max-w-screen-lg lg:px-16 lg:py-12 px-6 py-8 lg:my-8">
          <>
            <StyledGroup flex className="items-center mb-4">
              <TypographyComponent
                text="Información sociocultural"
                variant="h2"
                className="font-bold text-primary"
              />
              <TypographyWithTooltip
                text=""
                variant="p"
                textTooltip="Información sociocultural"
                idTooltip="role"
                className="text-neutral-950"
              />
            </StyledGroup>
            <TypographyComponent
              text="Añade toda la información recolectada durante la investigación formativa para que el artista pueda conocer mejor a la comunidad."
              variant="p"
              className="font-book text-neutral-950 mb-8"
            />

            <StyledWrapperCommunity
              className={
                !show
                  ? 'visible opacity-100 h-auto'
                  : 'invisible opacity-0 transition-all duration-100 h-0'
              }
            >
              <Accordion allowZeroExpanded>
                {listSocioCulturalInfo.length > 0 &&
                  _.map(listSocioCulturalInfo, (socioCulturalInfo, index) => {
                    return (
                      <AccordionItem key={`socioCulturalItem-${index}`}>
                        <StyledCardCommunity
                          key={`festivity- ${socioCulturalInfo.id}`}
                        >
                          <TypographyComponent
                            variant="p"
                            text={socioCulturalInfo.name}
                            className="flex-grow text-primary font-bold"
                          />
                          <StyledContentButtons>
                            <AccordionItemState>
                              {({ expanded }) =>
                                expanded ? (
                                  ''
                                ) : (
                                  <AccordionItemButton>
                                    <ButtonComponent
                                      text=""
                                      className="text-primary-100"
                                      variant="white"
                                      icon={
                                        <div className="icon-view text-primary h-10" />
                                      }
                                      onPress={() => {}}
                                    />
                                  </AccordionItemButton>
                                )
                              }
                            </AccordionItemState>

                            <ButtonComponent
                              text=""
                              className="icon-edit text-primary-100"
                              variant="white"
                              onPress={() => {
                                handleEditSocioCulturalInfo({
                                  socioCulturalInfo,
                                });
                                setSocioCulturalInfoId(socioCulturalInfo.id);
                              }}
                            />
                            <ButtonComponent
                              text=""
                              className="icon-delete"
                              onPress={() => {
                                handleDeleteSocialDynamicModal();
                                setSocioCulturalInfoId(socioCulturalInfo.id);
                              }}
                            />
                          </StyledContentButtons>
                        </StyledCardCommunity>
                        <AccordionItemPanel>
                          <StyledCollapseDetailContent>
                            <StyledContentCols>
                              {socioCulturalInfo.multimedias.length > 0 && (
                                <StyledMultimedia>
                                  {_.map(
                                    socioCulturalInfo.multimedias,
                                    (multimedia, index) => (
                                      <Image
                                        key={index}
                                        srcImage={multimedia.url}
                                        altImage="multimedia"
                                        titleImage="multimedia"
                                      />
                                    )
                                  )}
                                </StyledMultimedia>
                              )}

                              <SttledContentInformation>
                                <TypographyComponent
                                  variant="h3"
                                  text={socioCulturalInfo.name}
                                  className="flex-grow text-primary font-bold mt-4"
                                />
                                <TypographyComponent
                                  variant="p"
                                  text={socioCulturalInfo.description}
                                  className="mt-4"
                                />
                              </SttledContentInformation>
                            </StyledContentCols>

                            <AccordionItemButton>
                              <ButtonComponent
                                text="Cerrar vista previa"
                                className="mt-6"
                                variant="primary"
                                onPress={() => {}}
                              />
                            </AccordionItemButton>
                          </StyledCollapseDetailContent>
                        </AccordionItemPanel>
                      </AccordionItem>
                    );
                  })}
              </Accordion>
            </StyledWrapperCommunity>

            <ButtonAddActivity
              open={show}
              text="Agregar nueva información sociocultural"
              icon={
                <Image
                  srcImage={plusIcon}
                  altImage="icon-plus"
                  titleImage="icon-plus"
                  className="w-6 mr-2"
                />
              }
              onPress={handleAddSocialDynamic}
            />

            {show && (
              <StyledForm border className="h-auto">
                <InputForm
                  name="name"
                  label="Título"
                  placeholder="Ingresar nombre de la dinámica social"
                  errors={errors}
                  {...register('name')}
                  defaultValue={socialDynamicData?.name}
                />
                <TextArea
                  name="description"
                  placeholder="Ingresa la descripción de la dinámica social"
                  className="w-full col-span-2"
                  label="Descripción"
                  errors={errors}
                  {...register('description')}
                  defaultValue={socialDynamicData?.description}
                />

                <TypographyComponent
                  text={
                    <label>
                      <span className="font-bold">
                        Agregar imágenes de la dinámica social
                      </span>
                      <span className="text-neutral-800 ml-2 font-light">
                        Formato de archivo permitido JPG,PNG,JPEG,MP4,MOV
                      </span>
                    </label>
                  }
                  variant="p"
                  className="text-neutral-950 mb-10 w-full"
                />

                <StyledGroup flex className="space-x-4">
                  <Controller
                    control={control}
                    name="main"
                    render={({ field: { onChange, name } }) => {
                      return (
                        <UploadImagePreview
                          name={name}
                          onChange={onChange}
                          errors={errors}
                          resetInput={() => {
                            handleResetInputFile({ typeMultimedia: 'main' });
                          }}
                          srcDefaultImage={handleSelectDefaultImage('main')}
                          cleanFileInputs={cleanFileInputs}
                          isAVideo={handleSelectTypeFile('main')}
                        />
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name="first"
                    render={({ field: { onChange, name } }) => {
                      return (
                        <UploadImagePreview
                          name={name}
                          onChange={onChange}
                          errors={errors}
                          resetInput={() => {
                            handleResetInputFile({ typeMultimedia: 'first' });
                          }}
                          srcDefaultImage={handleSelectDefaultImage('first')}
                          cleanFileInputs={cleanFileInputs}
                          isAVideo={handleSelectTypeFile('first')}
                        />
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name="second"
                    render={({ field: { onChange, name } }) => {
                      return (
                        <UploadImagePreview
                          name={name}
                          onChange={onChange}
                          errors={errors}
                          resetInput={() => {
                            handleResetInputFile({ typeMultimedia: 'second' });
                          }}
                          srcDefaultImage={handleSelectDefaultImage('second')}
                          cleanFileInputs={cleanFileInputs}
                          isAVideo={handleSelectTypeFile('second')}
                        />
                      );
                    }}
                  />
                </StyledGroup>

                <StyledGroup flex className="justify-end space-x-4 pt-6">
                  <ButtonComponent
                    text="Cancelar"
                    mode="outline"
                    onPress={handleCancelSocialDynamic}
                  />
                  <ButtonComponent
                    text={
                      socialDynamicData
                        ? 'Guardar Cambios'
                        : 'Agregar información sociocultural'
                    }
                    onPress={handleSubmit(
                      socialDynamicData
                        ? handleUpdateSocialDynamic
                        : handleRegisterSocialDynamic
                    )}
                  />
                </StyledGroup>
              </StyledForm>
            )}
          </>
        </Box>

        <DefaultModal
          isActive={showDeleteSocialDynamicModal}
          classNameContent="max-w-screen-md w-11/12"
          handleClose={handleDeleteSocialDynamicModal}
        >
          <TypographyComponent
            text="Eliminar información sociocultural"
            variant="h3"
            className="font-bold text-primary mb-1"
          />
          <StyledContentButtonsModal>
            <ButtonComponent
              variant="primary"
              text="Cancelar"
              className="mx-auto w-full lg:w-auto lg:mx-2 "
              mode="outline"
              onPress={handleDeleteSocialDynamicModal}
            />
            <ButtonComponent
              variant="primary"
              text="Eliminar"
              className="mx-auto w-full lg:w-auto  lg:mx-2"
              onPress={handleDeleteSocialDynamic}
            />
          </StyledContentButtonsModal>
        </DefaultModal>
      </LayoutSelected>
    </Suspense>
  );
};

export default AdminDashboardFestivitiesAndTraditions;
