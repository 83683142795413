//Packages
import React, { Suspense, lazy, useState } from 'react';
import _ from 'lodash';

//hooks
import useViews from 'views';
import useHomeComponents from './Components';
import useControllers from 'controllers';

//Styles
const StyledContentItems = lazy(() =>
  import('./Home.styles').then((mod) => ({
    default: mod.StyledContentItems,
  }))
);
const StyledFilterOpenMob = lazy(() =>
  import('./Home.styles').then((mod) => ({
    default: mod.StyledFilterOpenMob,
  }))
);
const StyledButtonOpenFilter = lazy(() =>
  import('./Home.styles').then((mod) => ({
    default: mod.StyledButtonOpenFilter,
  }))
);

const Home = () => {
  const { useLayouts, useComponents } = useViews();
  const { MainLayout, ColumTwoLayout } = useLayouts();

  const { useCards, usePagers, useBoxes, useTypographies, useLoaders } =
    useComponents();

  const { DefaultLoader } = useLoaders();

  const { BottomHome, Filters, Slider } = useHomeComponents();

  const { TypographyComponent } = useTypographies();

  const { Box } = useBoxes();

  const { CardIntervention, CardNotification, CardEmptyState } = useCards();
  const { Pager } = usePagers();

  const [openFilter, setOpenFilter] = useState(false);
  const { useScreenHooks } = useControllers();
  const { useHome } = useScreenHooks();
  const { useRootHome } = useHome();
  const {
    interventions,
    getInterventionsPage,
    sliders,
    bottomText,
    dataHome,
    interventionsList,
    handleOpenIntervention,
    isFilterEmpty,
  } = useRootHome();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <MainLayout hasDecorationMap col2>
        <Slider sliders={sliders} />
        <ColumTwoLayout
          topPart={
            <StyledFilterOpenMob>
              <TypographyComponent
                className="font-bold text-neutral-900 text-lg"
                text="Nuestras Intervenciones"
                variant="h2"
              />
              <TypographyComponent
                className="font-bold text-secondary mb-2 text-xs"
                text={
                  <>
                    <span className="text-primary">Arte Social</span> para el
                    cambio de comportamiento
                  </>
                }
                variant="p"
              />
              <StyledButtonOpenFilter
                onClick={() => {
                  setOpenFilter(!openFilter);
                }}
                className={`${openFilter ? 'icon-close' : 'icon-filter'}`}
              />
            </StyledFilterOpenMob>
          }
          rightPart={
            <Box
              className={`w-full bg-neutral-200 px-8 lg:px-14 lg:py-10  space-y-3 overflow-hidden transition-all duration-700 ${
                openFilter
                  ? 'max-h-screen py-10 lg:max-h-screen'
                  : 'max-h-0 py-0 lg:max-h-screen'
              }`}
            >
              <Filters
                isHomeWithoutInterventions={
                  !interventionsList.length && !isFilterEmpty
                }
              />
            </Box>
          }
          leftPart={
            <>
              <div className="hidden lg:block">
                <TypographyComponent
                  className="font-bold text-primary text-lg"
                  text="¡Inspírate de nuestras intervenciones!"
                  variant="h2"
                />
                <TypographyComponent
                  className="font-bold text-secondary mb-10 text-xs"
                  text={
                    <>
                      <span className="text-primary">Arte Social</span> para el
                      cambio de comportamiento
                    </>
                  }
                  variant="p"
                />
              </div>
              {interventionsList.length > 0 ? (
                <>
                  <StyledContentItems>
                    {_.map(interventionsList, (item, idx) => (
                      <CardIntervention
                        key={`interventionItem-${idx}`}
                        className="w-full cursor-pointer"
                        image={item.image}
                        title={item.title}
                        location={item.location}
                        projectName={`Proyecto: ${item?.project?.name}`}
                        typeList={item.typeList}
                        description={item.description}
                        onClick={() => {
                          handleOpenIntervention(item.id);
                        }}
                      />
                    ))}
                  </StyledContentItems>

                  <Pager
                    pageCount={
                      Math.ceil(
                        interventions?.meta?.total /
                          interventions?.meta?.per_page
                      ) || 1
                    }
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    previousLabel={Boolean(interventions?.links?.prev)}
                    nextLabel={Boolean(interventions?.links?.next)}
                    className="flex items-center lg:justify-end justify-center my-16"
                    onPageChange={({ selected }) =>
                      getInterventionsPage(selected)
                    }
                  />
                </>
              ) : isFilterEmpty ? (
                <CardEmptyState
                  className="w-8/12 mx-auto mt-16"
                  title={
                    'No hay resultados, utiliza nuevos criterios de búsqueda'
                  }
                />
              ) : (
                <CardNotification
                  className="w-5/12 mx-auto py-9 px-4 mt-16"
                  title={
                    <TypographyComponent
                      text="Intervenciones en proceso"
                      variant="h2"
                      className="font-bold text-primary icon-watch text-center"
                    />
                  }
                />
              )}
            </>
          }
          classNameRigh="2xl:w-1/5 xl:w-24p lg:w-3/12"
          classNameLeft="2xl:w-4/5 xl:w-76p lg:w-9/12 lg:px-10 px-5"
        />
        <BottomHome
          bottomText={bottomText}
          artist={dataHome.artist}
          executingPartner={dataHome.executing_partner}
        />
      </MainLayout>
    </Suspense>
  );
};

export default Home;
