import useControllers from 'controllers';
import useStrings from 'strings';
import useApi from 'api';

const useAdminInterventionsRootActions = () => {
  const { useAdminTypes } = useStrings();
  const { useAdminProjectsTypes } = useAdminTypes();
  const { useAdminInterventionsTypes } = useAdminProjectsTypes();
  const { useAdminInterventionsRootTypes } = useAdminInterventionsTypes();
  const {
    CREATE_INTERVENTION,
    GET_DEFINITION_BEHAVIORS,
    GET_INTERVENTIONS_BY_PROJECT,
    DELETE_INTERVENTION_BY_PROJECT,
    UPDATE_INTERVENTION_BY_PROJECT,
    GET_INTERVENTION_DETAIL_BY_PROJECT,
    RESET_INTERVENTION_DETAIL_BY_PROJECT,
    SET_FILTER_INTERVENTION_DETAIL_BY_PROJECT,
    RESET_FILTER_INTERVENTION_DETAIL_BY_PROJECT,
    RESET_INTERVENTION_SELECTED,
    SET_INTERVEN_DEFINITION_ID,
    RESET_INTERVEN_DEFINITION_ID,
    GET_INTERVENTIONS_FOR_ENABLING,
    UPDATE_INTERVENTION_INFO_FINAL_PROCESS,
    UPDATE_INTERVENTAION_ENABLE_VISIBILITY,
    UPDATE_INTERVENTION_INFO_FILE,
    GET_INTERVENTION_INFO_FILE,
    SET_CUSTOM_INTERVENTION_STEP,
  } = useAdminInterventionsRootTypes();

  const { useGeneralHooks } = useControllers();
  const { useConsole } = useGeneralHooks();
  const { viewConsole } = useConsole();

  const { useProviders } = useApi();
  const { useAdminProviders } = useProviders();
  const { useAdminProjectsProviders } = useAdminProviders();
  const { useAdminInterventionsProviders } = useAdminProjectsProviders();
  const {
    useAdminInterventionsRootProviders,
    useAdminViewInterventionProviders,
  } = useAdminInterventionsProviders();
  const {
    createIntervertionById,
    getDefinitionBehaviors,
    getInterventionsByProject,
    deleteInterventionByProject,
    updateInterventionByProject,
    getInterventionDetailByProject,
    getInterventionProvider,
    updateInterventionProvider,
    getInterventionsForEnablingProvider,
  } = useAdminInterventionsRootProviders();
  const { useAdminViewInterventionDevelopmentInterventionProviders } =
    useAdminViewInterventionProviders();
  const { useAdminDevelopmentSpreadInterventionProviders } =
    useAdminViewInterventionDevelopmentInterventionProviders();
  const {
    updateSpreadInterventionProvider,
    uploadFileSpreadInterventionProvider,
    downloadFileSpreadInterventionProvider,
  } = useAdminDevelopmentSpreadInterventionProviders();

  const actCreateInterventionsById =
    (
      {
        name,
        type,
        description,
        allied_group,
        leading_group,
        objetive,
        definition_behavior,
        image,
        projectId,
        other_definition,
        user_id,
        communities,
        start_date,
        end_date,
      },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const bodyFormData = new FormData();
        bodyFormData.append('name', name);
        bodyFormData.append('type', type.value);
        bodyFormData.append('description', description);
        bodyFormData.append('allied_group', allied_group);
        bodyFormData.append('leading_group', leading_group);
        bodyFormData.append('objetive', objetive.value);
        definition_behavior.forEach((definition) => {
          bodyFormData.append('definition_behaviors[]', definition);
        });
        image && bodyFormData.append('image', image);
        other_definition &&
          bodyFormData.append('other_definition', other_definition);
        bodyFormData.append('user_id', user_id.id);
        communities.forEach((community) => {
          bodyFormData.append('communities[]', community);
        });
        start_date && bodyFormData.append('start_date', start_date);
        end_date && bodyFormData.append('end_date', end_date);

        const res = await createIntervertionById({
          formData: bodyFormData,
          projectId,
        });
        dispatch({ type: CREATE_INTERVENTION, payload: res.data?.data });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actGetDefinitionBehaviors =
    (onSuccess, onError) => async (dispatch) => {
      try {
        const res = await getDefinitionBehaviors();
        dispatch({ type: GET_DEFINITION_BEHAVIORS, payload: res.data?.data });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actGetInterventionsByProject =
    ({ projectId, page, search }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await getInterventionsByProject({
          projectId,
          page,
          search,
        });
        dispatch({
          type: GET_INTERVENTIONS_BY_PROJECT,
          payload: res.data,
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actDeleteInterventionByProject =
    ({ projectId, interventionId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await deleteInterventionByProject({
          projectId,
          interventionId,
        });
        dispatch({
          type: DELETE_INTERVENTION_BY_PROJECT,
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actUpdateInterventionByProject =
    (
      {
        name,
        type,
        description,
        allied_group,
        leading_group,
        objetive,
        definition_behavior,
        image,
        other_definition,
        user_id,
        communities,
        start_date,
        end_date,
        projectId,
        interventionId,
      },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const bodyFormData = new FormData();
        bodyFormData.append('name', name);
        bodyFormData.append('type', type.value);
        bodyFormData.append('description', description);
        bodyFormData.append('allied_group', allied_group);
        bodyFormData.append('leading_group', leading_group);
        bodyFormData.append('objetive', objetive.value);
        definition_behavior.forEach((definition) => {
          bodyFormData.append('definition_behaviors[]', definition);
        });
        (image || image === '') && bodyFormData.append('image', image);
        other_definition &&
          bodyFormData.append('other_definition', other_definition);
        bodyFormData.append('user_id', user_id.id);
        communities.forEach((community) => {
          bodyFormData.append('communities[]', community);
        });
        start_date && bodyFormData.append('start_date', start_date);
        end_date && bodyFormData.append('end_date', end_date);
        bodyFormData.append('_method', 'patch');
        const res = await updateInterventionByProject({
          projectId,
          interventionId,
          formData: bodyFormData,
        });
        dispatch({
          type: UPDATE_INTERVENTION_BY_PROJECT,
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actGetInterventionDetailByProject =
    ({ projectId, interventionId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await getInterventionDetailByProject({
          projectId,
          interventionId,
        });
        dispatch({
          type: GET_INTERVENTION_DETAIL_BY_PROJECT,
          payload: res.data?.data,
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actResetInterventionDetailByProject = () => (dispatch) => {
    dispatch({ type: RESET_INTERVENTION_DETAIL_BY_PROJECT });
  };

  const actResetInterventionSelected = (onSuccess) => async (dispatch) => {
    await dispatch({ type: RESET_INTERVENTION_SELECTED });
    onSuccess && onSuccess();
  };

  const actSetFilterInterventionDetailByProject =
    ({ key, value }) =>
    (dispatch) => {
      dispatch({
        type: SET_FILTER_INTERVENTION_DETAIL_BY_PROJECT,
        payload: { key, value },
      });
    };

  const actResetFilterInterventionDetailByProject = () => (dispatch) => {
    dispatch({
      type: RESET_FILTER_INTERVENTION_DETAIL_BY_PROJECT,
    });
  };

  const actGetIntervention =
    ({ intervention_id }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await getInterventionProvider({
          intervention_id,
        });
        dispatch({
          type: GET_INTERVENTION_DETAIL_BY_PROJECT,
          payload: response.data?.data,
        });
        onSuccess && onSuccess(response);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actUpdateIntervention =
    ({ interventionId, data }, onSuccess, onError) =>
    async () => {
      try {
        const res = await updateInterventionProvider({
          interventionId,
          data,
        });
        onSuccess && onSuccess(res.data);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actSetInterventionDefinitionId =
    ({ id }) =>
    (dispatch) => {
      dispatch({
        type: SET_INTERVEN_DEFINITION_ID,
        payload: id,
      });
    };

  const actResetInterventionDefinitionId = () => (dispatch) => {
    dispatch({
      type: RESET_INTERVEN_DEFINITION_ID,
    });
  };

  const actGetInterventionsForEnabling =
    (
      { state, name, country, department, project, objetive, type, page },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const response = await getInterventionsForEnablingProvider({
          state,
          name,
          country,
          department,
          project,
          objetive,
          type,
          page,
        });
        dispatch({
          type: GET_INTERVENTIONS_FOR_ENABLING,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actUpdateInterventionInfoFinalProcess =
    ({ interventionId, finalProcess, formData }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await updateSpreadInterventionProvider({
          interventionId,
          spreadId: finalProcess,
          data: formData,
        });
        dispatch({
          type: UPDATE_INTERVENTION_INFO_FINAL_PROCESS,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actUpdateInterventionEnableVisibility =
    ({ interventionId, data }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await updateInterventionProvider({
          interventionId,
          data,
        });
        dispatch({
          type: UPDATE_INTERVENTAION_ENABLE_VISIBILITY,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actUpdateInterventionInfoFile =
    ({ interventionId, finalProcess, formData }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await uploadFileSpreadInterventionProvider({
          interventionId,
          spreadId: finalProcess,
          data: formData,
        });
        dispatch({
          type: UPDATE_INTERVENTION_INFO_FILE,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actGetInterventionInfoFile =
    ({ interventionId, finalProcess, fileId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await downloadFileSpreadInterventionProvider({
          interventionId,
          spreadId: finalProcess,
          fileId,
        });
        dispatch({
          type: GET_INTERVENTION_INFO_FILE,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actSetCustomInterventionStep =
    ({ step }) =>
    (dispatch) => {
      dispatch({
        type: SET_CUSTOM_INTERVENTION_STEP,
        payload: step,
      });
    };

  return {
    actCreateInterventionsById,
    actGetDefinitionBehaviors,
    actGetInterventionsByProject,
    actDeleteInterventionByProject,
    actUpdateInterventionByProject,
    actGetInterventionDetailByProject,
    actResetInterventionDetailByProject,
    actSetFilterInterventionDetailByProject,
    actResetFilterInterventionDetailByProject,
    actGetIntervention,
    actUpdateIntervention,
    actResetInterventionSelected,
    actSetInterventionDefinitionId,
    actResetInterventionDefinitionId,
    actGetInterventionsForEnabling,
    actUpdateInterventionInfoFinalProcess,
    actUpdateInterventionEnableVisibility,
    actUpdateInterventionInfoFile,
    actGetInterventionInfoFile,
    actSetCustomInterventionStep,
  };
};

export default useAdminInterventionsRootActions;
