const useAdminSpreadInterventionTypes = () => {
  const GET_SPREAD_INTERVENTION =
    'GET_SPREAD_INTERVENTION';
  const UPDATE_SPREAD_INTERVENTION =
    'UPDATE_SPREAD_INTERVENTION';
  const UPLOAD_FILE_SPREAD_INTERVENTION =
    'UPLOAD_FILE_SPREAD_INTERVENTION';
  const DELETE_FILE_SPREAD_INTERVENTION =
    'DELETE_FILE_SPREAD_INTERVENTION';

  return {
    GET_SPREAD_INTERVENTION,
    UPDATE_SPREAD_INTERVENTION,
    UPLOAD_FILE_SPREAD_INTERVENTION,
    DELETE_FILE_SPREAD_INTERVENTION,
  };
};

export default useAdminSpreadInterventionTypes;
