import useModels from '../../../models';

const useGetUserProfile = () => {
  const { useSelectors } = useModels();
  const { useUserSelectors, useSelector } = useSelectors();
  const { userSelector } = useUserSelectors();
  const dataUser = useSelector(userSelector);
  return dataUser;
};

export default useGetUserProfile;
