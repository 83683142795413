//Packages
import React, { Suspense, lazy } from 'react';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

//Assets
import plusIcon from 'assets/icons/plus.svg';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

const StyledBackButton = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);

const StyledGroup = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledForm = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledForm,
  }))
);

const StyledContentButtons = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const StyledContentButtonsModal = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const StyledCollapseDetailContent = lazy(() =>
  import('styles/collapse/collapse.styles').then((mod) => ({
    default: mod.StyledCollapseDetailContent,
  }))
);

const StyledMultimedia = lazy(() =>
  import('styles/collapse/collapse.styles').then((mod) => ({
    default: mod.StyledMultimedia,
  }))
);

const SttledContentInformation = lazy(() =>
  import('styles/collapse/collapse.styles').then((mod) => ({
    default: mod.SttledContentInformation,
  }))
);

const StyledContentCols = lazy(() =>
  import('styles/collapse/collapse.styles').then((mod) => ({
    default: mod.StyledContentCols,
  }))
);

const StyledWrapperCommunity = lazy(() =>
  import('../Dashboard.styles').then((mod) => ({
    default: mod.StyledWrapperCommunity,
  }))
);

const StyledCardCommunity = lazy(() =>
  import('../Dashboard.styles').then((mod) => ({
    default: mod.StyledCardCommunity,
  }))
);

const AdminDashboardAdditionalInfo = () => {
  const { useComponents } = useViews();
  const {
    useInputs,
    useButtons,
    useTypographies,
    useImages,
    useBoxes,
    useModals,
    useLoaders,
  } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { TextArea } = useInputs();
  const { ButtonComponent, ButtonAddActivity } = useButtons();
  const { TypographyComponent } = useTypographies();
  const { Image, UploadImagePreview } = useImages();
  const { Box } = useBoxes();
  const { DefaultModal } = useModals();

  const { useScreenHooks, useGeneralHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminAdditionalInfoDashboard } = useAdminDashboardProjectsHooks();
  const {
    handleGoBack,
    show,
    showDeleteAdditionalInfoModal,
    handleDeleteAdditionalInfoModal,
    additionalInfo,
    handleAddAdditionalInfo,
    errors,
    register,
    control,
    handleSubmit,
    handleRegisterAdditionalInfo,
    handleCancelAdditionalInfo,
    handleDeleteAdditionalInfo,
    cleanFileInputs,
    handleResetInputFile,
    handleSelectTypeFile,
    handleSelectDefaultImage,
    handleEditAdditionalInfo,
    handleUpdateAdditionalInfo,
    additionalInfoData,

    showViewAditionalInfoModal,
    handleViewAditionalInfoModal,
  } = useAdminAdditionalInfoDashboard();
  const { useSelectLayoutByRole } = useGeneralHooks();
  const { LayoutSelected, props } = useSelectLayoutByRole();
  return (
    <Suspense fallback={<DefaultLoader />}>
      <LayoutSelected {...props}>
        <StyledBackButton className="mt-3" onClick={handleGoBack}>
          Regresar
        </StyledBackButton>
        <Box className="bg-neutral-200 w-11/12 mx-auto max-w-screen-lg lg:px-16 lg:py-12 px-6 py-8 lg:my-8">
          <>
            <TypographyComponent
              text="Información complementaria"
              variant="h2"
              className="font-bold text-primary"
              applyFontBold
            />

            <TypographyComponent
              text="Utiliza este espacio para cualquier información importante que no haya sido compartida en las sesiones anteriores.              "
              variant="p"
              className="font-book text-neutral-950 mb-10"
            />

            {!show && (
              <StyledWrapperCommunity
                className={
                  !show
                    ? 'visible opacity-100 h-auto'
                    : 'invisible opacity-0 transition-all duration-100 h-0'
                }
              >
                <Accordion allowZeroExpanded>
                  {additionalInfo.text && (
                    <AccordionItem>
                      <StyledCardCommunity>
                        <TypographyComponent
                          variant="p"
                          text={additionalInfo.text}
                          className="flex-grow text-primary font-bold truncate "
                        />
                        <StyledContentButtons>
                          <AccordionItemState>
                            {({ expanded }) =>
                              expanded ? (
                                ''
                              ) : (
                                <AccordionItemButton>
                                  <ButtonComponent
                                    text=""
                                    className="text-primary-100"
                                    variant="white"
                                    icon={
                                      <div className="icon-view text-primary h-10" />
                                    }
                                    onPress={() => {}}
                                  />
                                </AccordionItemButton>
                              )
                            }
                          </AccordionItemState>
                          <ButtonComponent
                            text=""
                            className="icon-edit text-primary-100"
                            variant="white"
                            onPress={handleEditAdditionalInfo}
                          />
                          <ButtonComponent
                            text=""
                            className="icon-delete"
                            onPress={handleDeleteAdditionalInfoModal}
                          />
                        </StyledContentButtons>
                      </StyledCardCommunity>
                      <AccordionItemPanel>
                        <StyledCollapseDetailContent>
                          <StyledContentCols>
                            {additionalInfo.multimedias.length > 0 && (
                              <StyledMultimedia>
                                {_.map(
                                  additionalInfo.multimedias,
                                  (multimedia, index) => (
                                    <Image
                                      key={`media-${index}`}
                                      srcImage={multimedia.url}
                                      altImage="multimedia"
                                      titleImage="multimedia"
                                    />
                                  )
                                )}
                              </StyledMultimedia>
                            )}

                            <SttledContentInformation>
                              <TypographyComponent
                                variant="p"
                                text={additionalInfo.text}
                                className="mt-4"
                              />
                            </SttledContentInformation>
                          </StyledContentCols>

                          <AccordionItemButton>
                            <ButtonComponent
                              text="Cerrar vista previa"
                              className="mt-6"
                              variant="primary"
                              onPress={() => {}}
                            />
                          </AccordionItemButton>
                        </StyledCollapseDetailContent>
                      </AccordionItemPanel>
                    </AccordionItem>
                  )}
                </Accordion>
              </StyledWrapperCommunity>
            )}

            {!additionalInfo.text && (
              <ButtonAddActivity
                open={show}
                text="Agregar información complementaria"
                icon={
                  <Image
                    srcImage={plusIcon}
                    altImage="icon-plus"
                    titleImage="icon-plus"
                    className="w-6 mr-2"
                  />
                }
                onPress={handleAddAdditionalInfo}
              />
            )}

            <StyledForm
              className={`border p-6 rounded   ${
                show
                  ? 'visible opacity-100 h-auto'
                  : 'invisible opacity-0 transition-all duration-100 h-0'
              }`}
            >
              <TextArea
                name="text"
                placeholder="Agregar información complementaria"
                className="w-full col-span-2"
                errors={errors}
                {...register('text')}
              />

              <TypographyComponent
                text={
                  <label>
                    <span className="font-bold">Agregar imágenes o videos</span>
                    <span className="text-neutral-800 ml-2 font-light">
                      Formato de archivo permitido JPG,PNG,JPEG,MP4,MOV
                    </span>
                  </label>
                }
                variant="p"
                className="text-neutral-950 mb-10 w-full"
              />

              <StyledGroup flex className="space-x-4">
                <Controller
                  control={control}
                  name="main"
                  render={({ field: { onChange, name } }) => {
                    return (
                      <UploadImagePreview
                        name={name}
                        onChange={onChange}
                        errors={errors}
                        resetInput={() => {
                          handleResetInputFile({ typeMultimedia: 'main' });
                        }}
                        cleanFileInputs={cleanFileInputs}
                        isAVideo={handleSelectTypeFile('main')}
                        srcDefaultImage={handleSelectDefaultImage('main')}
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="first"
                  render={({ field: { onChange, name } }) => {
                    return (
                      <UploadImagePreview
                        name={name}
                        onChange={onChange}
                        errors={errors}
                        resetInput={() => {
                          handleResetInputFile({ typeMultimedia: 'first' });
                        }}
                        cleanFileInputs={cleanFileInputs}
                        isAVideo={handleSelectTypeFile('first')}
                        srcDefaultImage={handleSelectDefaultImage('first')}
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="second"
                  render={({ field: { onChange, name } }) => {
                    return (
                      <UploadImagePreview
                        name={name}
                        onChange={onChange}
                        errors={errors}
                        resetInput={() => {
                          handleResetInputFile({ typeMultimedia: 'second' });
                        }}
                        cleanFileInputs={cleanFileInputs}
                        isAVideo={handleSelectTypeFile('second')}
                        srcDefaultImage={handleSelectDefaultImage('second')}
                      />
                    );
                  }}
                />
              </StyledGroup>
              <StyledGroup flex className="justify-end space-x-4 pt-6">
                <ButtonComponent
                  text="Cancelar"
                  mode="outline"
                  onPress={handleCancelAdditionalInfo}
                />
                <ButtonComponent
                  text={
                    additionalInfoData?.text
                      ? 'Guardar Cambios'
                      : 'Agregar información complementaria'
                  }
                  className="mr-0 ml-auto mt-16"
                  onPress={handleSubmit(
                    additionalInfoData?.text
                      ? handleUpdateAdditionalInfo
                      : handleRegisterAdditionalInfo
                  )}
                />
              </StyledGroup>
            </StyledForm>

            <DefaultModal
              isActive={showDeleteAdditionalInfoModal}
              classNameContent="max-w-screen-md w-11/12"
              handleClose={handleDeleteAdditionalInfoModal}
            >
              <TypographyComponent
                text="Eliminar información complementaria                "
                variant="h3"
                className="font-bold text-primary mb-1"
              />
              <StyledContentButtonsModal>
                <ButtonComponent
                  variant="primary"
                  text="Cancelar"
                  className="mx-auto w-full lg:w-auto lg:mx-2 "
                  mode="outline"
                  onPress={handleDeleteAdditionalInfoModal}
                />
                <ButtonComponent
                  variant="primary"
                  text="Aceptar"
                  className="mx-auto w-full lg:w-auto  lg:mx-2"
                  onPress={handleDeleteAdditionalInfo}
                />
              </StyledContentButtonsModal>
            </DefaultModal>
            <DefaultModal
              isActive={showViewAditionalInfoModal}
              classNameContent="max-w-xl w-11/12"
              handleClose={handleViewAditionalInfoModal}
            >
              <TypographyComponent
                text={'Información complementaria'}
                variant="h4"
                className="font-bold text-primary mb-2"
              />
              <TypographyComponent
                text={additionalInfo?.text}
                variant="p"
                className="text-neutral-950  mb-1"
              />
              <StyledContentButtonsModal justifyStart>
                {_.map(additionalInfo?.multimedias, (multimedia, idx) => (
                  <Image
                    key={`additionalInfo-${idx}`}
                    srcImage={multimedia?.url}
                    altImage={additionalInfo?.name}
                    titleImage={additionalInfo?.name}
                    className="w-36 h-22 object-cover mr-4 rounded-md"
                  />
                ))}
              </StyledContentButtonsModal>
            </DefaultModal>
          </>
        </Box>
      </LayoutSelected>
    </Suspense>
  );
};

export default AdminDashboardAdditionalInfo;
