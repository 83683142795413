//Packege
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useInterventionsDetailHomeProvider = () => {
  const getInterventionById = ({ intervention_id }) => {
    const response = axios({
      method: 'GET',
      url: `/home/interventions/${intervention_id}`,
    });
    return trackPromise(response);
  };
  return { getInterventionById };
};
export default useInterventionsDetailHomeProvider;
