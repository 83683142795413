//Packages
import React, { lazy } from 'react';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';
import useHelpers from 'helpers';

//Styles
const StyledButtonsActions = lazy(() =>
  import(
    '../../../../../../../../../../../../styles/buttons/buttons.styles'
  ).then((mod) => ({
    default: mod.StyledButtonsActions,
  }))
);

const StyledWrapperCommunity = lazy(() =>
  import('../../../../../../../Dashboard.styles').then((mod) => ({
    default: mod.StyledWrapperCommunity,
  }))
);

const StyledCardCommunity = lazy(() =>
  import('../../../../../../../Dashboard.styles').then((mod) => ({
    default: mod.StyledCardCommunity,
  }))
);

const StyledContentButtonsModal = lazy(() =>
  import(
    '../../../../../../../../../../../../styles/tableViews/tableViews.styles'
  ).then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const StyledGroup = lazy(() =>
  import(
    '../../../../../../../../../../../../styles/formViews/formViews.styles'
  ).then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const SketchInterventions = () => {
  const { useComponents } = useViews();
  const { useTypographies, useButtons, useModals, useInputs, useCards } =
    useComponents();
  const { CardNotification } = useCards();
  const { TypographyComponent } = useTypographies();
  const { ButtonComponent } = useButtons();
  const { DefaultModal } = useModals();
  const { CheckBox, TextArea, InputFile, InputForm } = useInputs();

  const { useQuickFunctions } = useHelpers();
  const { getDocumentName } = useQuickFunctions();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsDevelopmentIntervention } =
    useAdminViewInterventionsControllers();
  const { useViewDevelopmentInterventionCreateSketch } =
    useAdminViewInterventionsDevelopmentIntervention();
  const { useViewDevelopmentInterventionCreateSketchAdmin } =
    useViewDevelopmentInterventionCreateSketch();
  const { useAdminCreateSketchInterventions } =
    useViewDevelopmentInterventionCreateSketchAdmin();
  const {
    createSketch,
    control,
    register,
    errors,
    watch,
    isValid,
    handleSubmit,
    openFile,
    downloadFile,
    updateCreateSketchStepOneIntervention,
    showModalApprove,
    showModalReject,
    setShowModalApprove,
    setShowModalReject,
    controlReject,
    watchReject,
    handleSubmitReject,
    errorsReject,
    registerReject,
    updateRejectSketchStepOneIntervention,
  } = useAdminCreateSketchInterventions();

  return (
    <>
      {_.includes(['ip', 'r', 'c', 'iv'], createSketch?.state?.id) && (
        <CardNotification
          className="w-5/12 mx-auto py-9 px-4 mt-16"
          title={
            <TypographyComponent
              text={createSketch?.state?.name}
              variant="h2"
              className="font-bold text-primary icon-watch text-center"
            />
          }
        />
      )}
      <TypographyComponent
        text="Boceto de la intervención"
        variant="h2"
        className="font-bold text-primary mt-6"
      />
      <TypographyComponent
        text="Haz click en iniciar etapa para que el artista pueda empezar a crear!"
        variant="p"
        className="text-neutral-950 mt-2"
      />
      <TypographyComponent
        text="En esta etapa, el artista podrá compartir su primer borrador."
        variant="p"
        className="text-neutral-950 mt-2"
      />

      {createSketch?.state?.id === 'p' && (
        <>
          <Controller
            control={control}
            name="date_limit_checkbox"
            render={({ field: { name, onChange } }) => {
              return (
                <CheckBox
                  name={name}
                  label="Asignar fecha limite para recibir boceto de la intervención por parte del artista"
                  className="mx-auto mt-6"
                  onChange={onChange}
                  errors={errors}
                />
              );
            }}
          />
          {watch('date_limit_checkbox') && (
            <InputForm
              name="date_limit"
              label=""
              type="date"
              placeholder="Selecciona la fecha limite"
              className="mt-8 w-2/12"
              errors={errors}
              {...register('date_limit')}
            />
          )}

          <ButtonComponent
            text="Iniciar etapa"
            className="mx-auto mt-8"
            onPress={handleSubmit(updateCreateSketchStepOneIntervention)}
            disabled={!isValid}
          />
        </>
      )}
      {createSketch?.sketch_file?.url && createSketch?.state?.id !== 'r' && (
        <>
          <div className="border rounded mt-8 px-6 pb-8 bg-neutral-300">
            <StyledWrapperCommunity nonemb>
              <StyledCardCommunity>
                <TypographyComponent
                  variant="p"
                  text={getDocumentName({
                    file: createSketch?.sketch_file,
                    withExtension: true,
                  })}
                  className="flex-grow text-primary font-bold cursor-pointer"
                />
                <StyledButtonsActions className="flex items-center">
                  <ButtonComponent
                    text=""
                    className="icon-view"
                    onPress={(event) =>
                      openFile({
                        event,
                        urlFile: createSketch?.sketch_file?.url,
                      })
                    }
                  />
                  <ButtonComponent
                    text=""
                    icon={
                      <div className="icon-download text-primary text-lg" />
                    }
                    onPress={(event) =>
                      downloadFile({
                        event,
                        idFile: createSketch?.sketch_file?.id,
                        nameFile: getDocumentName({
                          file: createSketch?.sketch_file,
                          withExtension: true,
                        }),
                      })
                    }
                  />
                </StyledButtonsActions>
              </StyledCardCommunity>
            </StyledWrapperCommunity>
          </div>
          {createSketch?.state?.id !== 'c' && (
            <div className="flex items-center space-x-2 mt-10 mb-6 justify-center">
              <ButtonComponent
                text="Rechazar boceto"
                mode="outline"
                onPress={() => {
                  setShowModalReject(true);
                }}
              />
              <ButtonComponent
                text="Aprobar boceto"
                onPress={() => {
                  setShowModalApprove(true);
                }}
              />
            </div>
          )}
        </>
      )}
      <DefaultModal
        isActive={showModalReject}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={() => {
          setShowModalReject(false);
        }}
      >
        <TypographyComponent
          text={'Rechazar boceto de la intervención '}
          variant="h3"
          className="font-bold text-primary mb-1"
        />

        <form>
          <StyledGroup col2 className="mb-4 ">
            <TextArea
              name="rejected_reason"
              placeholder="Escribe los ajustes que debe realizar el artista al boceto de la intervención"
              className="w-full col-span-2"
              label="Escribe los ajustes que debe realizar el artista al boceto de la intervención"
              errors={errorsReject}
              {...registerReject('rejected_reason')}
            />
          </StyledGroup>
          <div className="mb-4">
            <Controller
              control={controlReject}
              name="rejected_reason_file"
              render={({ field: { onChange, name } }) => {
                return (
                  <InputFile
                    name={name}
                    accept={'.pdf'}
                    title={
                      <div className="flex items-center">
                        <div className="icon-paper-clip text-primary text-xl font-bold h-10" />
                        <TypographyComponent
                          text="Adjuntar archivo"
                          variant="p"
                          className={`text-primary font-bold ${
                            errorsReject[name] && 'text-error'
                          }`}
                        />
                      </div>
                    }
                    onChange={onChange}
                    className={`w-full mt-2 bg-none mb-4 ${
                      errorsReject[name] && 'border-error'
                    }`}
                    errors={errorsReject}
                  />
                );
              }}
            />
          </div>

          <StyledGroup flex noSpace className="mb-4 flex-col">
            <Controller
              control={controlReject}
              name="date_limit_checkbox_two"
              render={({ field: { name, onChange } }) => {
                return (
                  <CheckBox
                    name={name}
                    label="Asignar fecha limite para recibir los ajustes en la definición por parte del artista"
                    className="mt-6"
                    onChange={onChange}
                    errors={errorsReject}
                  />
                );
              }}
            />
            {watchReject('date_limit_checkbox_two') && (
              <InputForm
                name="date_r"
                label=""
                type="date"
                placeholder="Selecciona la fecha limite"
                className="pt-2 "
                errors={errorsReject}
                {...registerReject('date_r')}
              />
            )}
          </StyledGroup>
        </form>
        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto lg:mx-2 "
            mode="outline"
            onPress={() => setShowModalReject(false)}
          />
          <ButtonComponent
            variant="primary"
            text="Enviar respuesta al artista"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={handleSubmitReject((data) => {
              updateRejectSketchStepOneIntervention({
                formData: data,
                state: 'r',
              });
            })}
          />
        </StyledContentButtonsModal>
      </DefaultModal>

      <DefaultModal
        isActive={showModalApprove}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={() => {
          setShowModalApprove(false);
        }}
      >
        <TypographyComponent
          text={'Aprobar boceto de la intervención'}
          variant="h3"
          className="font-bold text-primary mb-1"
        />

        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto lg:mx-2 "
            mode="outline"
            onPress={() => setShowModalApprove(false)}
          />
          <ButtonComponent
            variant="primary"
            text="Aceptar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={handleSubmit((data) => {
              updateRejectSketchStepOneIntervention({
                formData: data,
                state: 'c',
              });
            })}
          />
        </StyledContentButtonsModal>
      </DefaultModal>
    </>
  );
};

export default SketchInterventions;
