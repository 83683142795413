//Hooks
import useControllers from 'controllers';
import useStrings from 'strings';

const useAdminContentInterventionsActions = () => {
  const { useAdminTypes } = useStrings();
  const { useAdminContentInterventionsTypes } = useAdminTypes();
  const {
    SET_FILTER_ABOUT_HOME_INTERVENTIONS,
    RESET_FILTER_ABOUT_HOME_INTERVENTIONS,
  } = useAdminContentInterventionsTypes();

  const { useGeneralHooks } = useControllers();
  const { useConsole } = useGeneralHooks();
  const { viewConsole } = useConsole();

  const actSetDataFilterAdminHomeInterventions =
    ({ objectKey, objectValue }, onSuccess, onError) =>
    (dispatch) => {
      try {
        dispatch({
          type: SET_FILTER_ABOUT_HOME_INTERVENTIONS,
          payload: { objectKey, objectValue },
        });
        onSuccess && onSuccess();
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actResetDataFilterAdminHomeInterventions =
    (onSuccess, onError) => (dispatch) => {
      try {
        dispatch({
          type: RESET_FILTER_ABOUT_HOME_INTERVENTIONS,
        });
        onSuccess && onSuccess();
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  return {
    actSetDataFilterAdminHomeInterventions,
    actResetDataFilterAdminHomeInterventions,
  };
};

export default useAdminContentInterventionsActions;
