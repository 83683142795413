//Hooks
import useProviders from '../../../providers';
import useStrings from 'strings';

const useHomeActions = () => {
  const { useAdminTypes } = useStrings();
  const { useAdminHomeType, useAdminProjectsTypes } = useAdminTypes();
  const {
    GET_CONFIG_HOME,
    STORE_CONFIG_HOME,
    UPDATE_CONFIG_HOME,
    GET_INTERVENTIONS_HOME,
    GET_INTERVENTIONS_INFO_FOR_EDIT,
    RESET_INTERVENTION_INFO_FOR_EDIT,
  } = useAdminHomeType();

  const { useAdminProjectsRootTypes } = useAdminProjectsTypes();
  const { GET_PROJECTS } = useAdminProjectsRootTypes();

  const { useAdminProviders } = useProviders();
  const { useHomeProviders } = useAdminProviders();
  const {
    storeConfigHomeProvider,
    getConfigHomeProvider,
    updateConfigHomeProvider,
    getInterventionsProvider,
    getProjectsHomeProvider,
    getInterventionInfoForEditProvider,
  } = useHomeProviders();
  const actStoreHomeConfig =
    ({ object }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await storeConfigHomeProvider({ object });
        dispatch({ type: STORE_CONFIG_HOME, payload: response.data?.data });
        onSuccess && onSuccess(response);
      } catch (error) {
        onError && onError(error);
      }
    };
  const actGetHomeConfig = (onSuccess, onError) => async (dispatch) => {
    try {
      const response = await getConfigHomeProvider();
      dispatch({ type: GET_CONFIG_HOME, payload: response.data?.data });
      onSuccess && onSuccess(response);
    } catch (error) {
      onError && onError(error);
    }
  };
  const actUpdateHomeConfig =
    (
      {
        bt_title,
        bt_subtitle,
        bt_text,
        artist_name,
        artist_image,
        slider,
        executing_partner_name,
        executing_partner_image,
      },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const bodyFormData = new FormData();
        bt_title && bodyFormData.append('bt_title', bt_title);
        bt_subtitle && bodyFormData.append('bt_subtitle', bt_subtitle);
        bt_text && bodyFormData.append('bt_text', bt_text);
        (artist_name || artist_name === '') &&
          bodyFormData.append('artist_name', artist_name);
        artist_image && bodyFormData.append('artist_image', artist_image);
        (executing_partner_name || executing_partner_name === '') &&
          bodyFormData.append('executing_partner_name', executing_partner_name);
        executing_partner_image &&
          bodyFormData.append(
            'executing_partner_image',
            executing_partner_image
          );
        slider &&
          slider.forEach((element) => {
            bodyFormData.append('slider[]', element);
          });

        bodyFormData.append('_method', 'patch');

        const response = await updateConfigHomeProvider({
          formData: bodyFormData,
        });
        dispatch({ type: UPDATE_CONFIG_HOME, payload: response.data?.data });
        onSuccess && onSuccess(response);
      } catch (error) {
        onError && onError(error);
      }
    };
  const actGetHomeInterventions =
    (
      {
        country_id,
        department_id,
        project_id,
        objective,
        type,
        paginate = true,
        page,
        name,
      },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const response = await getInterventionsProvider({
          country_id,
          department_id,
          objective,
          type,
          paginate,
          project_id,
          page,
          name,
        });
        dispatch({ type: GET_INTERVENTIONS_HOME, payload: response.data });
        onSuccess && onSuccess(response);
      } catch (error) {
        onError && onError(error);
      }
    };
  const actGetProjectsHome =
    ({ paginate, page }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await getProjectsHomeProvider({ paginate, page });
        dispatch({
          type: GET_PROJECTS,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (error) {
        onError && onError(error);
      }
    };

  const actGetInterventionsInfoForEdit =
    ({ interventionId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await getInterventionInfoForEditProvider({
          interventionId,
        });
        dispatch({
          type: GET_INTERVENTIONS_INFO_FOR_EDIT,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (error) {
        onError && onError(error);
      }
    };

  const actResetInterventionsInfoForEdit = () => async (dispatch) => {
    dispatch({
      type: RESET_INTERVENTION_INFO_FOR_EDIT,
    });
  };

  return {
    actStoreHomeConfig,
    actGetHomeConfig,
    actUpdateHomeConfig,
    actGetHomeInterventions,
    actGetProjectsHome,
    actGetInterventionsInfoForEdit,
    actResetInterventionsInfoForEdit,
  };
};
export default useHomeActions;
