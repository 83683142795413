//Packages
import React, { lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useStrings from 'strings';

//Components
import FormMessage from './components/FormMessage';
import FormRequest from './components/FormRequest';

//Styles
const StyledForm = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledForm,
  }))
);

const StyledGroup = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledContentButtonsModal = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const Detail = (props) => {
  const {
    setActiveItem,
    selectedMessage,
    formDiffusionMessage,
    optionsCommunities,
    onFormatDefaultValueCommunities,
    handleResetSelectedDetailMessage,
    handleAcceptMessageAdmin,
    activeConfirmModal,
    setActiveConfirmModal,
    setValidateOptionsRules,
    handleAddOptions,
    numberOptions,
    setNumberOptions,
    handleDeleteOption,
    handleOnChangeOptions,
  } = props;

  const { useComponents } = useViews();

  const { useTypographies, useInputs, useButtons, useModals } = useComponents();

  const { TypographyComponent, TypographyWithTooltip } = useTypographies();

  const { Select } = useInputs();

  const { ButtonComponent } = useButtons();
  const { DefaultModal } = useModals();

  const { useConstants } = useStrings();
  const { LIST_OF_TYPES_OF_MESSAGES } = useConstants();

  const {
    setValue,
    control,
    register,
    formState: { errors },
    getValues,
    handleSubmit,
    watch,
    reset,
  } = formDiffusionMessage;

  const requestTypeSelected = watch('type')?.value;

  useEffect(() => {
    if (selectedMessage?.type?.id) {
      setValue('type', {
        label: selectedMessage.type.name,
        value: selectedMessage.type.id,
      });
      setValue('objective', selectedMessage.objective);
      setValue('recommendations', selectedMessage.recommendations);
      selectedMessage.channel?.id &&
        setValue('channel', {
          label: selectedMessage.channel.name,
          value: selectedMessage.channel.id,
        });
      setValue(
        'communities',
        onFormatDefaultValueCommunities({
          listOfCommunities: selectedMessage.communities,
        })
      );
      setValue('message', _.split(selectedMessage.message, '\n1')?.[0]);
      selectedMessage.gi_type?.id &&
        setValue('gi_type', {
          label: selectedMessage.gi_type.name,
          value: selectedMessage.gi_type.id,
        });
      selectedMessage.type_information?.id &&
        setValue('type_information', {
          label: selectedMessage.type_information.name,
          value: selectedMessage.type_information.id,
        });
      if (selectedMessage.options?.length > 0) {
        const draftOptions = _.map(selectedMessage.options, (option, index) => {
          return { id: Date.now() + index, value: option.text };
        });
        const draftOptionsAux = _.map(
          selectedMessage.options,
          (option) => option.text
        );
        setNumberOptions(draftOptions);
        setValue('options', draftOptionsAux);
      } else {
        setNumberOptions([{ id: Date.now(), value: '' }]);
      }
    }
  }, [selectedMessage?.id]);

  useEffect(() => {
    return () => {
      reset();
      handleResetSelectedDetailMessage();
    };
  }, []);

  return (
    <>
      <TypographyComponent
        text="Crear nueva solicitud"
        variant="h2"
        className="font-bold text-primary mt-4"
      />

      <StyledForm className="pb-32">
        <StyledGroup noSpace>
          <div>
            <TypographyWithTooltip
              text="Tipo de solicitud"
              variant="p"
              textTooltip="Tipo de solicitud"
              idTooltip="type"
              className="text-neutral-950"
            />
            <Select
              name="type"
              id="type"
              placeholder="Selecciona el tipo de solicitud"
              control={control}
              options={LIST_OF_TYPES_OF_MESSAGES}
              label=""
              errors={errors}
            />
          </div>
        </StyledGroup>

        {requestTypeSelected === 'd' ? (
          <FormMessage
            control={control}
            errors={errors}
            register={register}
            optionsCommunities={optionsCommunities}
          />
        ) : requestTypeSelected === 'gi' ? (
          <FormRequest
            control={control}
            errors={errors}
            register={register}
            optionsCommunities={optionsCommunities}
            watch={watch}
            setValidateOptionsRules={setValidateOptionsRules}
            handleAddOptions={handleAddOptions}
            numberOptions={numberOptions}
            handleDeleteOption={handleDeleteOption}
            handleOnChangeOptions={handleOnChangeOptions}
          />
        ) : (
          <></>
        )}

        {requestTypeSelected && (
          <div className="flex justify-between">
            <ButtonComponent
              text="Cancelar"
              className="border-none shadow-none"
              onPress={(e) => {
                e.preventDefault();
                setActiveItem((prevValue) => ({
                  ...prevValue,
                  action: '',
                  active: 0,
                  idSelectedMessage: '',
                }));
              }}
              mode="outline"
              variant="primary"
            />
            <div className="flex items-center space-x-2">
              <ButtonComponent
                text="Guardar cambios "
                mode="outline"
                onPress={(e) => {
                  e.preventDefault();
                  handleAcceptMessageAdmin({
                    data: getValues(),
                    state: 'd',
                    isSaveDraftMessage: true,
                  });
                }}
              />
              <ButtonComponent
                text="Enviar solicitud"
                icon={<div className="icon-send text-lg  h-6 flex"></div>}
                onPress={handleSubmit((data) =>
                  handleAcceptMessageAdmin({ data, state: 'p' })
                )}
              />
            </div>
          </div>
        )}
      </StyledForm>
      <DefaultModal
        isActive={activeConfirmModal}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={setActiveConfirmModal}
      >
        <TypographyComponent
          text="Enviar solicitud de mensaje"
          variant="h3"
          className="font-bold text-primary mb-1"
        />

        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto lg:mx-2 "
            mode="outline"
            onPress={setActiveConfirmModal}
          />
          <ButtonComponent
            variant="primary"
            text="Aceptar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={handleSubmit((data) =>
              handleAcceptMessageAdmin({
                data,
                state: 'p',
                fromModalConfirmation: true,
              })
            )}
          />
        </StyledContentButtonsModal>
      </DefaultModal>
    </>
  );
};

Detail.propTypes = {
  setActiveItem: PropTypes.func.isRequired,
  selectedMessage: PropTypes.object,
  formDiffusionMessage: PropTypes.object,
  optionsCommunities: PropTypes.array,
  onFormatDefaultValueCommunities: PropTypes.func,
  handleResetSelectedDetailMessage: PropTypes.func,
  handleAcceptMessageAdmin: PropTypes.func,
  activeConfirmModal: PropTypes.bool,
  setActiveConfirmModal: PropTypes.func,
  setValidateOptionsRules: PropTypes.func,
  handleAddOptions: PropTypes.func,
  numberOptions: PropTypes.array,
  setNumberOptions: PropTypes.func,
  handleDeleteOption: PropTypes.func,
  handleOnChangeOptions: PropTypes.func,
};

Detail.defaultProps = {
  selectedMessage: {},
  formDiffusionMessage: {},
  optionsCommunities: [],
  onFormatDefaultValueCommunities: () => {},
  handleResetSelectedDetailMessage: () => {},
  handleAcceptMessageAdmin: () => {},
  activeConfirmModal: false,
  setActiveConfirmModal: () => {},
  setValidateOptionsRules: () => {},
  handleAddOptions: () => {},
  numberOptions: [],
  setNumberOptions: () => {},
  handleDeleteOption: () => {},
  handleOnChangeOptions: () => {},
};

export default Detail;
