import useAdminInterventionsRootInitialStates from './root';
import useAdminViewInterventionsCommunicationInitialStates from './view/communication';

const useAdminInterventionsInitialStates = () => {
  return {
    useAdminInterventionsRootInitialStates,
    useAdminViewInterventionsCommunicationInitialStates,
  };
};

export default useAdminInterventionsInitialStates;
