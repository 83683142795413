import React, { Suspense, lazy } from 'react';
import _ from 'lodash';

// Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Assets
import imageFakeCard from 'assets/img/fake-card-example.jpg';

//Styles
const StyledBackButton = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);

const StyledDivider = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledDivider,
  }))
);

const StyledBoxImages2col1 = lazy(() =>
  import('styles/boxes/boxes.styles').then((mod) => ({
    default: mod.StyledBoxImages2col1,
  }))
);

const AdminDashboardViewInterventionsSocialInfo = () => {
  const { useComponents } = useViews();

  const { useTypographies, useBoxes, useImages, useLoaders } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { TypographyComponent } = useTypographies();
  const { Box } = useBoxes();
  const { Image } = useImages();

  const { useGeneralHooks, useScreenHooks } = useControllers();
  const { useSelectLayoutByRole } = useGeneralHooks();
  const { LayoutSelected, props } = useSelectLayoutByRole();

  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminKnowProjectDetailIntervention } =
    useAdminInterventionsDashboardControllers();
  const { socioCulturalInfo, goBack } = useAdminKnowProjectDetailIntervention();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <LayoutSelected {...props}>
        <StyledBackButton className="mt-3" onClick={goBack}>
          Regresar
        </StyledBackButton>
        <Box className="bg-neutral-200 w-12/12 mx-auto max-w-screen-2xl lg:px-16 lg:py-12 px-6 py-8 lg:my-8">
          <>
            <TypographyComponent
              text={'Información sociocultural'}
              variant="h2"
              className="font-bold text-primary mb-5"
            />
            <TypographyComponent
              text={
                'Incluye aquí la información que se recolecto durante el disgnóstico sociocultural en la fase de diseño del proyecto.'
              }
              variant="p"
              className="mb-12"
            />
            {_.map(socioCulturalInfo, (SCI, idx) => {
              return (
                <section key={`socioCulturalItem-${idx}`}>
                  <TypographyComponent
                    text={SCI.name}
                    variant="h3"
                    className="font-bold text-primary mb-2"
                  />

                  <TypographyComponent
                    text={SCI.description}
                    variant="p"
                    className="mb-12"
                  />
                  {SCI.multimedias.length !== 0 && (
                    <StyledBoxImages2col1>
                      {_.map(SCI.multimedias, (multimedia, idx) => {
                        return (
                          <Image
                            key={`multimedia-${idx}`}
                            srcImage={multimedia.url || imageFakeCard}
                            altImage="imageport"
                            titleImage="imageport"
                          />
                        );
                      })}
                    </StyledBoxImages2col1>
                  )}

                  <StyledDivider />
                </section>
              );
            })}
          </>
        </Box>
      </LayoutSelected>
    </Suspense>
  );
};

export default AdminDashboardViewInterventionsSocialInfo;
