//Packages
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Components
import MeansForm from '../MeansForm';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemState,
} from 'react-accessible-accordion';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
import { StyledContentButtons } from 'styles/tableViews/tableViews.styles';

const MeansList = () => {
  const { useComponents } = useViews();
  const { useButtons, useTypographies } = useComponents();
  const { ButtonComponent } = useButtons();
  const { TypographyComponent } = useTypographies();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsDevelopmentIntervention } =
    useAdminViewInterventionsControllers();
  const { useDefineInterventionInterventions } =
    useAdminViewInterventionsDevelopmentIntervention();
  const { useArtistDefineInterventionInterventionsControllers } =
    useDefineInterventionInterventions();
  const { useArtistDefineInterventionLogisticsMeans } =
    useArtistDefineInterventionInterventionsControllers();
  const {
    define_intervention,
    showCreateAndEditLogisticsMeans,
    listOfLogisticsMeans,
    formLogisticsMeans,
    handleVisiblityCreateAndEditLogisticsMeans,
    handleCancelCreateOrEditLogistic,
    handleCreateOrEditLogisticMean,
    handleDeleteLogisticMean,
    onSelectLogisticMeanForEdit,
    logisticMeanDataToEdit,
    isLogisticMeanEditButtonDisabled,
  } = useArtistDefineInterventionLogisticsMeans();

  const disabledEditMode = _.includes(
    ['iv', 'c'],
    define_intervention.state.id
  );

  return (
    <>
      {!showCreateAndEditLogisticsMeans && (
        <>
          <Accordion allowZeroExpanded>
            {listOfLogisticsMeans.length > 0 &&
              _.map(listOfLogisticsMeans, (resource, index) => {
                return (
                  <div
                    className="border rounded py-2 px-6 mt-2 bg-white"
                    key={index}
                  >
                    <AccordionItem>
                      <div className="flex items-center justify-between">
                        <TypographyComponent
                          variant="p"
                          text={resource.describe}
                          className="flex-grow text-primary font-bold"
                        />
                        <StyledContentButtons>
                          <ButtonComponent
                            text=""
                            className={`icon-edit text-primary-100 ${
                              disabledEditMode && 'icon-disabled'
                            }`}
                            variant="white"
                            onPress={() => {
                              !disabledEditMode &&
                                onSelectLogisticMeanForEdit({
                                  data: resource,
                                });
                            }}
                          />
                          <ButtonComponent
                            text=""
                            className={`icon-delete ${
                              disabledEditMode && 'icon-disabled'
                            }`}
                            onPress={() => {
                              !disabledEditMode &&
                                handleDeleteLogisticMean({
                                  logisticMeanId: resource.id,
                                });
                            }}
                          />
                          <AccordionItemState>
                            {({ expanded }) => (
                              <AccordionItemButton>
                                <ButtonComponent
                                  text=""
                                  className=""
                                  variant="white"
                                  icon={
                                    <div
                                      className={`icon-arrow-down text-xxs text-neutral-950 ml-4 ${
                                        expanded && 'transform rotate-180'
                                      }`}
                                    />
                                  }
                                  onPress={() => {
                                    if (!expanded) {
                                      onSelectLogisticMeanForEdit({
                                        data: resource,
                                      });
                                    } else {
                                      handleCancelCreateOrEditLogistic();
                                    }
                                  }}
                                />
                              </AccordionItemButton>
                            )}
                          </AccordionItemState>
                        </StyledContentButtons>
                      </div>
                    </AccordionItem>
                  </div>
                );
              })}
          </Accordion>
          <ButtonComponent
            text="Crer nuevo recurso"
            icon={<div className="icon-close transform rotate-45 text-sm" />}
            onPress={handleVisiblityCreateAndEditLogisticsMeans}
            variant="primary"
            mode="outline"
            className={'mt-4 border-none shadow-none px-0'}
            disabled={disabledEditMode}
          />
        </>
      )}

      {showCreateAndEditLogisticsMeans && (
        <div className="border rounded py-8 px-6 mt-4 bg-white">
          <MeansForm
            formLogisticsMeans={formLogisticsMeans}
            handleCancelCreateOrEditLogistic={handleCancelCreateOrEditLogistic}
            handleCreateOrEditLogisticMean={handleCreateOrEditLogisticMean}
            logisticMeanDataToEdit={logisticMeanDataToEdit}
            isLogisticMeanEditButtonDisabled={isLogisticMeanEditButtonDisabled}
            disabledEditMode={disabledEditMode}
          />
        </div>
      )}
    </>
  );
};

MeansList.propTypes = {
  setViewActive: PropTypes.func,
};

export default MeansList;
