const useAdminUsersInitialStates = () => {
  const inititalStateAdminUsersFilter = {
    search: '',
    country_id: '',
    department_id: '',
    city_id: '',
    roles: [],
    community_id: '',
    paginate: true,
    orderByKey: '',
    orderByValue: '',
    page: 1,
    contact_list_id: '',
    has_whatsapp: '',
    name: '',
    gender: '',
    language: '',
  };
  return { inititalStateAdminUsersFilter };
};

export default useAdminUsersInitialStates;
