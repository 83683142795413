const useAdminAdditionalInfoTypes = () => {
  const GET_ADDITIONAL_INFO = 'GET_ADDITIONAL_INFO';
  const CREATE_ADDITIONAL_INFO = 'CREATE_ADDITIONAL_INFO';
  const DELETE_ADDITIONAL_INFO = 'DELETE_ADDITIONAL_INFO';
  const UPDATE_ADDITIONAL_INFO = 'UPDATE_ADDITIONAL_INFO';
  return {
    CREATE_ADDITIONAL_INFO,
    DELETE_ADDITIONAL_INFO,
    UPDATE_ADDITIONAL_INFO,
    GET_ADDITIONAL_INFO,
  };
};

export default useAdminAdditionalInfoTypes;
