//Packages
import { useDispatch } from 'react-redux';
import useAdminActions from './admin';

//Hooks
import useAuthActions from './auth';
import useDashboardActions from './dashboard';
import useGeneralActions from './general';
import useUserActions from './user';

const useActions = () => {
  const dispatch = useDispatch();
  return {
    dispatch,
    useAuthActions,
    useUserActions,
    useGeneralActions,
    useAdminActions,
    useDashboardActions,
  };
};

export default useActions;
