import useAdminCreateContactsCommunitiesDashboard from './create';
import useAdminEditContactsCommunitiesDashboard from './edit';
import useAdminRootContactsCommunitiesDashboard from './root';
import useAdminRootContactsListCommunitiesDashboard from './contactList';

const useAdminContactsCommunitiesDashboardHooks = () => {
  return {
    useAdminCreateContactsCommunitiesDashboard,
    useAdminEditContactsCommunitiesDashboard,
    useAdminRootContactsCommunitiesDashboard,
    useAdminRootContactsListCommunitiesDashboard,
  };
};

export default useAdminContactsCommunitiesDashboardHooks;
