//Packages
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from 'lodash';

//Hooks
import useStrings from '../../../../../strings';
import useApi from '../../../../../api';
import useModels from '../../../../../models';

const useAdminEditLabels = ({ setActiveModalEdit, idLabel }) => {
  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { REQUIRED_FIELD } = useFormsTypes();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminLabelsActions } = useAdminActions();
  const { actUpdateLabels } = useAdminLabelsActions();

  const { useSelectors } = useModels();
  const { useSelector, useGeneralSelectors } = useSelectors();
  const { useColorsSelectors } = useGeneralSelectors();
  const { colorsSelector } = useColorsSelectors();
  const colors = useSelector(colorsSelector);

  const schemaCreateLabels = yup.object({
    name: yup.string().required(REQUIRED_FIELD),
    color: yup.object().required(REQUIRED_FIELD).typeError(REQUIRED_FIELD),
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaCreateLabels),
    mode: 'onChange',
  });

  const optionsColors = useMemo(
    () =>
      _.map(colors, (colors) => {
        return {
          colors,
        };
      }),
    [colors]
  );

  const onSuccessHandleEditLabels = () => {
    reset({
      name: '',
      color: null,
    });
    setActiveModalEdit({ active: false });
  };

  const handleUpdateLabels = (data) => {
    const { name, color } = data;

    dispatch(
      actUpdateLabels(
        { labelId: idLabel, name: name, color: color.value },
        onSuccessHandleEditLabels,
        onErrorCreateLabels
      )
    );
  };

  const handleCancelEdit = (e) => {
    e.preventDefault();
    setActiveModalEdit({ active: false });
    reset({
      name: '',
      color: null,
    });
  };

  const onErrorCreateLabels = (error) => {
    const message = error.response?.data?.errors?.name;

    setError('name', {
      type: 'manual',
      message,
    });
  };

  return {
    control,
    errors,
    register,
    handleSubmit,

    handleUpdateLabels,

    optionsColors,
    handleCancelEdit,
    setValue,
    colors,
  };
};

export default useAdminEditLabels;
