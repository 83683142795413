//Hooks
import useLoginProviders from './login';
import useRecoverPasswordProvider from './recoverPassword';
import useLogoutProviders from './logout';
import usePreRegisterProviders from './pre-register';

const useAuthProviders = () => {
  return {
    useLoginProviders,
    useLogoutProviders,
    useRecoverPasswordProvider,
    usePreRegisterProviders,
  };
};

export default useAuthProviders;
