//Packages
import React, { lazy } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';
import useHelpers from 'helpers';

//Styles
const StyledCardMultimedia = lazy(() =>
  import('./Multimedia.styles').then((mod) => ({
    default: mod.StyledCardMultimedia,
  }))
);
const StyledButtonDownload = lazy(() =>
  import('./Multimedia.styles').then((mod) => ({
    default: mod.StyledButtonDownload,
  }))
);

const Multimedia = (props) => {
  const {
    totalAnswers,
    dataCollectionInformationAnswersSelected,
    handleChangePageAnswers,
    handleDownloadFileFromCollectionInformation,
  } = props;

  const { useComponents } = useViews();

  const { useTypographies, useBadges, usePagers, useImages } = useComponents();

  const { DefaultBadge } = useBadges();
  const { TypographyComponent } = useTypographies();
  const { Pager } = usePagers();

  const { Image } = useImages();

  const { useQuickFunctions } = useHelpers();
  const { getYearMonthDayFromDate } = useQuickFunctions();

  return (
    <>
      <DefaultBadge
        type="light"
        label={`${totalAnswers} ${
          totalAnswers === 1 ? 'respuesta' : 'respuestas'
        }`}
      />

      {dataCollectionInformationAnswersSelected.data?.length > 0 && (
        <ul className="mt-8 grid grid-cols-3 gap-x-4 gap-y-12">
          {_.map(
            dataCollectionInformationAnswersSelected.data,
            (answer, idx) => (
              <StyledCardMultimedia key={`answers-${idx}`}>
                <Image
                  className="w-full"
                  srcImage={answer.file?.url}
                  altImage={'image'}
                />
                <div className="px-4 py-2 flex items-center justify-between">
                  <TypographyComponent
                    text={getYearMonthDayFromDate({
                      dateInString: answer.reply_date,
                      format: 'dmyTime',
                    })}
                    variant="p"
                    className="text-neutral-800 mt-0 "
                  />
                  <StyledButtonDownload
                    onClick={() => {
                      handleDownloadFileFromCollectionInformation({
                        fileId: answer.file?.id,
                        nameFile: idx + 1,
                        extension: answer.file.url
                          .split('/')
                          .at(-1)
                          .split('.')[1],
                      });
                    }}
                  >
                    <div className="icon-download text-lg text-primary" />
                  </StyledButtonDownload>
                </div>
              </StyledCardMultimedia>
            )
          )}
        </ul>
      )}

      <Pager
        pageCount={
          Math.ceil(
            dataCollectionInformationAnswersSelected.meta?.total /
              dataCollectionInformationAnswersSelected.meta?.per_page
          ) || 1
        }
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        previousLabel={Boolean(
          dataCollectionInformationAnswersSelected.links?.prev
        )}
        nextLabel={Boolean(
          dataCollectionInformationAnswersSelected.links?.next
        )}
        className="flex items-center lg:justify-end justify-center my-6"
        onPageChange={({ selected }) => handleChangePageAnswers(selected)}
      />
    </>
  );
};

Multimedia.propTypes = {
  totalAnswers: PropTypes.number,
  dataCollectionInformationAnswersSelected: PropTypes.object,
  handleChangePageAnswers: PropTypes.func,
  handleDownloadFileFromCollectionInformation: PropTypes.func,
};

Multimedia.defaultProps = {
  totalAnswers: 0,
  dataCollectionInformationAnswersSelected: {},
  handleChangePageAnswers: () => {},
  handleDownloadFileFromCollectionInformation: () => {},
};

export default Multimedia;
