import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';

// Hooks
import useApi from 'api';
import useModels from 'models';
import useStrings from 'strings';
import useHelpers from 'helpers';
import useControllers from 'controllers';

const useAdminSpreadIntervention = () => {
  const { intervention_id: interventionId } = useParams();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions, useAdminInterventionsRootActions } =
    useAdminInterventionsActions();
  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const { useAdminSpreadInterventionActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const { actUpdateIntervention } = useAdminInterventionsRootActions();
  const {
    actGetSpreadIntervention,
    actUpdateSpreadIntervention,
    actUploadFileSpreadIntervention,
    actDeleteFileSpreadIntervention,
  } = useAdminSpreadInterventionActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionsSelector } = useAdminInterventionsSelectors();
  const { spreadIntervention } = useSelector(getInterventionsSelector);
  const { id: spreadId } = spreadIntervention;

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const {
    REQUIRED_FIELD,
    MAX_LENGTH_100,
    MAX_LENGTH_500,
    FILE_TYPE,
    FILE_SIZE_5MB,
    MIN_GOALS,
    MIN_ONE_IMAGE_OR_VIDEO,
  } = useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const { getFileValidations, updateItemInArray } = useQuickFunctions();
  const { SUPPORTED_FORMATS, MAX_SIZE_5MB } = getFileValidations();

  const { useGeneralHooks } = useControllers();
  const { useToggleModal } = useGeneralHooks();
  const [openModalApprove, toggleModalApprove] = useToggleModal();
  const [showModalDeleteGoal, toggleModalDeleteGoal] = useToggleModal();

  const [goals, setGoals] = useState([]);
  const [editingGoal, setEditingGoal] = useState(undefined);
  const goalIndexRef = useRef(0);

  const schemaGoal = yup.object({
    goal: yup
      .string()
      .typeError(REQUIRED_FIELD)
      .max(100, MAX_LENGTH_100)
      .required(REQUIRED_FIELD),
  });

  const schemaSpreadIntervention = yup.object({
    goal:
      goals.length === 0
        ? yup.string().required(MIN_GOALS)
        : yup.string().notRequired(),
    suggestions: yup.string().max(500, MAX_LENGTH_500).required(REQUIRED_FIELD),
    communityLiked: yup
      .string()
      .max(500, MAX_LENGTH_500)
      .required(REQUIRED_FIELD),
    communitySuggestions: yup.string().max(500, MAX_LENGTH_500).notRequired(),
    additionalInfo: yup.string().max(500, MAX_LENGTH_500).notRequired(),
    media:
      spreadIntervention.files.length === 0
        ? yup
            .mixed()
            .nullable()
            .required(MIN_ONE_IMAGE_OR_VIDEO)
            .test('fileType', FILE_TYPE, (value) => {
              if (value) {
                return SUPPORTED_FORMATS.includes(value.type);
              }
              return true;
            })
            .test('fileSize', FILE_SIZE_5MB, (value) => {
              if (value) {
                return value.size <= MAX_SIZE_5MB;
              }
              return true;
            })
        : yup
            .mixed()
            .nullable()
            .notRequired()
            .test('fileType', FILE_TYPE, (value) => {
              if (value) {
                return SUPPORTED_FORMATS.includes(value.type);
              }
              return true;
            })
            .test('fileSize', FILE_SIZE_5MB, (value) => {
              if (value) {
                return value.size <= MAX_SIZE_5MB;
              }
              return true;
            }),
    publishIntervention: yup.boolean(),
  });

  const formGoals = useForm({
    resolver: yupResolver(schemaGoal),
    mode: 'onChange',
  });

  const formSpreadIntervention = useForm({
    resolver: yupResolver(schemaSpreadIntervention),
    mode: 'onChange',
  });

  useEffect(() => {
    if (spreadIntervention?.state?.id !== 'c') {
      dispatch(
        actGetSpreadIntervention({
          interventionId,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (spreadIntervention?.state?.id === 'c') {
      setGoals(spreadIntervention.goals ?? []);
      formSpreadIntervention.reset({
        suggestions: spreadIntervention.recommendations,
        communityLiked: spreadIntervention.tastes,
        communitySuggestions: spreadIntervention.suggestions,
        additionalInfo: spreadIntervention.information,
      });
    }
  }, [spreadIntervention.goals]);

  useEffect(() => {
    const file = formSpreadIntervention.watch('media');
    if (
      file &&
      SUPPORTED_FORMATS.includes(file.type) &&
      file?.size <= MAX_SIZE_5MB
    ) {
      uploadFile({ fieldToWatch: 'media' });
      formSpreadIntervention.setValue('media', undefined);
    }
  }, [formSpreadIntervention.watch('media')]);

  const saveGoal = (data) => {
    formSpreadIntervention.clearErrors('goal');
    formGoals.reset({ goal: '' });
    if (editingGoal?.id) {
      setGoals(
        updateItemInArray({
          arrayData: goals,
          itemToUpdate: { ...editingGoal, text: `${data.goal}` },
        })
      );
      setEditingGoal(undefined);
    } else {
      const goalId = goalIndexRef.current + 1;
      goalIndexRef.current = goalId;
      setGoals([
        ...goals,
        {
          id: goalId,
          text: `${data.goal}`,
        },
      ]);
    }
  };

  const editGoal = (e, goal) => {
    e.preventDefault();
    setEditingGoal(goal);
    formGoals.reset({ goal: goal.text });
  };

  const deleteGoal = (e, goal) => {
    e.preventDefault();
    toggleModalDeleteGoal();
    setGoals(_.filter(goals, (g) => g.id !== goal.id));
  };

  const uploadFile = ({ fieldToWatch }) => {
    const data = new FormData();
    data.append('file', formSpreadIntervention.watch(`${fieldToWatch}`));
    dispatch(
      actUploadFileSpreadIntervention({
        interventionId,
        spreadId,
        data,
      })
    );
  };

  const deleteFile = (file) => {
    dispatch(
      actDeleteFileSpreadIntervention({
        interventionId,
        spreadId,
        fileId: file.id,
      })
    );
  };

  const updateSpreadIntervention = (data) => {
    toggleModalApprove();
    const dataToSend = {
      goals: _.map(goals, (goalValue) => goalValue.text),
      recommendations: data.suggestions,
      tastes: data.communityLiked,
      state: 'c',
    };
    if (data.communitySuggestions) {
      dataToSend.suggestions = data.communitySuggestions;
    }
    if (data.additionalInfo) {
      dataToSend.information = data.additionalInfo;
    }

    dispatch(
      actUpdateSpreadIntervention(
        {
          interventionId,
          spreadId,
          data: dataToSend,
        },
        () => {
          if (data.publishIntervention) {
            dispatch(
              actUpdateIntervention({
                interventionId,
                data: { visible_in_front: true },
              })
            );
          }
        }
      )
    );
  };

  return {
    spreadIntervention,
    formGoals,
    formSpreadIntervention,
    goals,
    editingGoal,
    showModalDeleteGoal,
    toggleModalDeleteGoal,
    openModalApprove,
    toggleModalApprove,
    stepCompleted: spreadIntervention?.state?.id === 'c',
    saveGoal,
    editGoal,
    deleteGoal,
    deleteFile,
    updateSpreadIntervention,
  };
};

export default useAdminSpreadIntervention;
