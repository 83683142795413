//Packages
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//Hooks
import useApi from 'api';
import useStrings from 'strings';
import useModels from 'models';
import useControllers from 'controllers';
import useHelpers from 'helpers';

const useAdminEditContactsCommunitiesDashboard = () => {
  const history = useHistory();
  const { contact_id } = useParams();
  const { useActions } = useApi();
  const { dispatch, useUserActions } = useActions();
  const { actUserEdit } = useUserActions();
  const { useMessagesTypes, useConstants } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const {
    EMAIL_NOT_VALID,
    REQUIRED_FIELD,
    NUMBER_NOT_VALID,
    MAX_LENGTH_PHONE,
    MIN_LENGTH_PHONE,
    ACCEPTS_ONLY_TEXT,
  } = useFormsTypes();
  const { OPTIONS_ROLES_WITHOUT_LOGIN } = useConstants();

  const { useSelectors } = useModels();
  const { useSelector, useUserSelectors } = useSelectors();
  const { usersSelector } = useUserSelectors();

  const users = useSelector(usersSelector);
  const { useQuickFunctions } = useHelpers();
  const { regexValidations } = useQuickFunctions();
  const regexNumber = regexValidations({ regex: 'number' });
  const regexText = regexValidations({ regex: 'text' });

  const schemaEditContact = Yup.object({
    role: Yup.object().required(REQUIRED_FIELD),
    name: Yup.string()
      .required(REQUIRED_FIELD)
      .matches(regexText, ACCEPTS_ONLY_TEXT),
    last_name: Yup.string()
      .required(REQUIRED_FIELD)
      .matches(regexText, ACCEPTS_ONLY_TEXT),
    email: Yup.string().email(EMAIL_NOT_VALID).nullable(),
    code: Yup.object().required(REQUIRED_FIELD),
    number_phone: Yup.string()
      .required(REQUIRED_FIELD)
      .matches(regexNumber, NUMBER_NOT_VALID)
      .max(15, MAX_LENGTH_PHONE)
      .min(8, MIN_LENGTH_PHONE),
    has_whatsapp: Yup.boolean().required(REQUIRED_FIELD),
    gender: Yup.string().notRequired(),
  });

  const selectUser = (id) => {
    return _.filter(users.data, (u) => `${u.id}` === `${id}`)[0];
  };

  const defaultValues = useMemo(() => {
    const user = selectUser(contact_id);
    return {
      name: user.name,
      last_name: user.last_name,
      number_phone: user.phone.number,
      email: user.email,
      code: {
        label: user.phone.code.code,
        value: user.phone.code.id,
      },
      role: {
        label: user.role.name,
        value: user.role.id,
      },
      has_whatsapp: user.has_whatsapp,
      gender: user.gender ?? '',
    };
  }, []);

  const editUserForm = useForm({
    resolver: yupResolver(schemaEditContact),
    mode: 'onChange',
    defaultValues,
  });

  const { useGeneralHooks } = useControllers();
  const { useWhereLive } = useGeneralHooks();
  const { optionsIndicatives } = useWhereLive({ callEndpointCountries: false });

  const editUser = (user, e) => {
    e.preventDefault();
    user.id = contact_id;
    user.role = user.role.value;
    user.phone_code = user.code.value;
    user.phone = user.number_phone;
    delete user.number_phone;
    dispatch(
      actUserEdit(
        { user },
        () => {
          editUserForm.reset();
          history.goBack();
        },
        (e) => {
          const { email, phone, gender } = e.response.data.errors;

          email &&
            editUserForm.setError('email', {
              type: 'manual',
              message: email[0],
            });
          phone &&
            editUserForm.setError('number_phone', {
              type: 'manual',
              message: phone[0],
            });
          gender &&
            editUserForm.setError('gender', {
              type: 'manual',
              message: `${gender[0]}`.replaceAll('gender', 'género'),
            });
        }
      )
    );
  };

  return {
    history,
    selectUser,
    optionsIndicatives,
    editUserForm,
    editUser,
    defaultValues,
    OPTIONS_ROLES_WITHOUT_LOGIN,
  };
};

export default useAdminEditContactsCommunitiesDashboard;
