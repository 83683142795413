//Packages
import React, { Suspense, lazy } from 'react';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';
import useLabelsComponents from '../Components';

//Assets
import arrowDown from 'assets/icons/arrow-down-table.svg';

//Styles
const StyledTopFilters = lazy(() =>
  import('../../Admin.styles').then((mod) => ({
    default: mod.StyledTopFilters,
  }))
);

const StyledFilters = lazy(() =>
  import('../../Admin.styles').then((mod) => ({
    default: mod.StyledFilters,
  }))
);

const StyledInputSearchContent = lazy(() =>
  import('../../Admin.styles').then((mod) => ({
    default: mod.StyledInputSearchContent,
  }))
);

const StyledContentTable = lazy(() =>
  import('../../Admin.styles').then((mod) => ({
    default: mod.StyledContentTable,
  }))
);

const StyledContentButtons = lazy(() =>
  import('../../Admin.styles').then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const StyledColorLabel = lazy(() =>
  import('../../Admin.styles').then((mod) => ({
    default: mod.StyledColorLabel,
  }))
);

const StyledContentButtonsModal = lazy(() =>
  import('../../Admin.styles').then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const StyledTableTitle = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledTableTitle,
  }))
);

const AdminRootLabels = () => {
  const { useLayouts, useComponents } = useViews();
  const { Create, Edit } = useLabelsComponents();
  const { AdminLayout } = useLayouts();
  const {
    useButtons,
    useSideBars,
    useTables,
    useInputs,
    useTypographies,
    useImages,
    useModals,
    useCards,
    useLoaders,
  } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { CardEmptyState } = useCards();
  const { AdminSideBar } = useSideBars();
  const { ButtonComponent } = useButtons();
  const { Table, TableHead, TableRow, TableHeadCell, TableCell, TableBody } =
    useTables();
  const { InputSearch } = useInputs();
  const { TypographyComponent } = useTypographies();
  const { Image } = useImages();
  const { DefaultModal } = useModals();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminLabels } = useAdmin();
  const { useAdminRootLabels } = useAdminLabels();
  const {
    isFilterEmpty,
    activeModalDelete,
    setActiveModalDelete,
    activeModalCreate,
    setActiveModalCreate,
    activeModalEdit,
    setActiveModalEdit,
    labelslist,
    handleDeleteLabels,
    idLabel,
    setIdLabel,
    textSearch,
    setTextSearch,
    handleOrderBy,
    orderBy,
  } = useAdminRootLabels();

  const tableHeader = [
    { header: 'Nombre etiqueta', orderByKey: 'name' },
    { header: 'Color', orderByKey: 'color' },
    { header: '' },
  ];

  return (
    <Suspense fallback={<DefaultLoader />}>
      <AdminLayout rightPart={<AdminSideBar />}>
        <StyledTopFilters>
          <ButtonComponent
            text="Crear nueva etiqueta"
            icon={<i className="icon-add-tag text-lg" />}
            onPress={() => {
              setActiveModalCreate({ active: true });
            }}
          />
          {!(!isFilterEmpty && labelslist.length === 0) && (
            <StyledFilters className="relative">
              <StyledInputSearchContent>
                <InputSearch text={textSearch} onDebounce={setTextSearch} />
              </StyledInputSearchContent>
            </StyledFilters>
          )}
        </StyledTopFilters>

        <StyledContentTable>
          <Table className="w-full">
            <TableHead className="border-b">
              <TableRow>
                {_.map(tableHeader, (item, idx) => (
                  <TableHeadCell
                    className={`text-left py-4 px-5 ${
                      !item.noneOrder && labelslist.length
                        ? 'cursor-pointer'
                        : 'cursor-not-allowed'
                    }`}
                    key={`label-head-${idx}`}
                    onClick={() => {
                      !item.noneOrder &&
                        labelslist.length &&
                        handleOrderBy({ orderByKey: item.orderByKey });
                    }}
                  >
                    {item.header !== '' && (
                      <StyledTableTitle>
                        <TypographyComponent
                          text={item.header}
                          variant="small"
                        />
                        {!item.noneOrder && (
                          <Image
                            srcImage={arrowDown}
                            className={`ml-2 ${
                              !orderBy[item.orderByKey] &&
                              labelslist.length &&
                              'order-arrow cursor-pointer'
                            }`}
                          />
                        )}
                      </StyledTableTitle>
                    )}
                  </TableHeadCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {labelslist.length > 0 ? (
                _.map(labelslist, (labels, id) => (
                  <TableRow key={`rowlabels-${id}`}>
                    <TableCell className="py-2 px-5">
                      {labels.labels?.name}
                    </TableCell>
                    <TableCell className="py-2 text-center">
                      <StyledColorLabel
                        className="ml-8"
                        color={labels.labels?.color}
                      />
                    </TableCell>

                    <TableCell className="py-2">
                      <StyledContentButtons className="justify-end pr-8">
                        <ButtonComponent
                          text=""
                          className="icon-edit"
                          onPress={() => {
                            setActiveModalEdit({
                              active: true,
                              name: labels.labels?.name,
                              color: labels.labels?.color,
                            });
                            setIdLabel(labels.labels?.id);
                          }}
                        />
                        <ButtonComponent
                          text=""
                          className="icon-delete"
                          onPress={() => {
                            setActiveModalDelete({
                              active: true,
                              labels: labels.labels?.id,
                            });
                          }}
                        />
                      </StyledContentButtons>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <CardEmptyState
                  className="w-8/12 mx-auto mt-16"
                  title={
                    isFilterEmpty
                      ? 'No hay resultados, utiliza nuevos criterios de búsqueda'
                      : 'No se han encontrado etiquetas ¡Crea la primera!'
                  }
                  body='Utiliza las etiquetas para segmentar mejor tu lista de contactos. Las etiquetas te permitirán mandar mensajes a un grupo específico de la comunidad. Por ejemplo, puedes crear una etiqueta para "madres de familia"
                  Dale click a "crear nueva etiqueta" para comenzar'
                  button={
                    !isFilterEmpty ? (
                      <ButtonComponent
                        className="mx-auto"
                        text="Crear nueva etiqueta"
                        icon={<i className="icon-add-tag text-lg" />}
                        onPress={() => {
                          setActiveModalCreate({ active: true });
                        }}
                      />
                    ) : (
                      <></>
                    )
                  }
                />
              )}
            </TableBody>
          </Table>
        </StyledContentTable>

        {/* Delete modal */}
        <DefaultModal
          isActive={activeModalDelete.active}
          classNameContent="max-w-screen-md w-11/12"
          handleClose={() => {
            setActiveModalDelete({ active: false });
          }}
        >
          <TypographyComponent
            text="¿Deseas eliminar la etiqueta?"
            variant="h3"
            className="font-bold text-primary mb-1"
          />
          <TypographyComponent
            text="Esta acción es definitiva. Estas seguro que deseas eliminar la etiqueta?            "
            variant="p"
            className="text-neutral-950  mb-1"
          />
          <StyledContentButtonsModal>
            <ButtonComponent
              variant="primary"
              text="Cancelar"
              className="mx-auto w-full lg:w-auto lg:mx-2 "
              mode="outline"
              onPress={() => {
                setActiveModalDelete({ active: false });
              }}
            />
            <ButtonComponent
              variant="primary"
              text="Eliminar"
              className="mx-auto w-full lg:w-auto  lg:mx-2"
              onPress={() => {
                handleDeleteLabels(activeModalDelete.labels);
                setActiveModalDelete({ active: false });
              }}
            />
          </StyledContentButtonsModal>
        </DefaultModal>

        <DefaultModal
          isActive={activeModalCreate.active}
          classNameContent="max-w-screen-md w-11/12"
          handleClose={() => {
            setActiveModalCreate({ active: false });
          }}
        >
          <Create setActiveModalCreate={setActiveModalCreate} />
        </DefaultModal>

        <DefaultModal
          isActive={activeModalEdit.active}
          classNameContent="max-w-screen-md w-11/12"
          handleClose={() => {
            setActiveModalEdit({ active: false });
          }}
        >
          <Edit
            setActiveModalEdit={setActiveModalEdit}
            activeModalEdit={activeModalEdit}
            idLabel={idLabel}
          />
        </DefaultModal>
      </AdminLayout>
    </Suspense>
  );
};

export default AdminRootLabels;
