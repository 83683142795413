import useCountriesInitialStates from './countries';
import useLanguagesInitialStates from './languages';
import useLegalDataInitialStates from './legalData';
import useModalsInitialStates from './modals';
import useSpecialitiesInitialStates from './specialities';
import useColorsInitialStates from './Colors';
import useAccessibilityInitialStates from './accessibility';
import useSidebarInitialStates from './sidebar';
import useNotificationInitialStates from './notification';
import useTabSelectedInitialStates from './tabSelected';
import useIsFilterEmpty from './isFilterEmpty';

const useGeneralInitialStates = () => {
  return {
    useCountriesInitialStates,
    useLegalDataInitialStates,
    useModalsInitialStates,
    useSpecialitiesInitialStates,
    useLanguagesInitialStates,
    useColorsInitialStates,
    useAccessibilityInitialStates,
    useSidebarInitialStates,
    useNotificationInitialStates,
    useTabSelectedInitialStates,
    useIsFilterEmpty,
  };
};

export default useGeneralInitialStates;
