const useAdminLabelsTypes = () => {
  const CREATE_LABELS = 'CREATE_LABELS';
  const GET_LABELS = 'GET_LABELS';
  const DELETE_LABELS = 'DELETE_LABELS';
  const UPDATE_LABELS = 'UPDATE_LABELS';
  const ASSING_LABEL_TO_CONTACTS = 'ASSING_LABEL_TO_CONTACTS';
  const ASSING_LABELS_TO_CONTACT = 'ASSING_LABELS_TO_CONTACT';
  const UNASSING_LABEL_TO_CONTACTS = 'UNASSING_LABEL_TO_CONTACTS';

  return {
    CREATE_LABELS,
    GET_LABELS,
    DELETE_LABELS,
    UPDATE_LABELS,
    ASSING_LABEL_TO_CONTACTS,
    ASSING_LABELS_TO_CONTACT,
    UNASSING_LABEL_TO_CONTACTS,
  };
};

export default useAdminLabelsTypes;
