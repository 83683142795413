import { combineReducers } from 'redux';
import useAuthReducers from './auth';
import useUserReducers from './user';
import useGeneralReducers from './general';
import useTestReducers from './test';
import useAdminReducers from './admin';
import useDashboardReducers from './dashboard';

const useReducers = () => {
  const { auth } = useAuthReducers();
  const { user, updateUser, users, artistUsersInfiniteScroll } =
    useUserReducers();
  const { test } = useTestReducers();
  const {
    useCountriesReducers,
    useLegalDataReducers,
    useLanguagesReducers,
    useModalsReducers,
    useSpecialitiesReducers,
    useColorsReducers,
    useAccessibilityReducers,
    useSidebarReducers,
    useNotificationsReducers,
    useTabSelectedReducers,
    useIsFilterEmptyReducers,
  } = useGeneralReducers();
  const { isFilterEmpty } = useIsFilterEmptyReducers();
  const accessibilitiesData = useAccessibilityReducers();
  const legalData = useLegalDataReducers();
  const languagesData = useLanguagesReducers();
  const modalsData = useModalsReducers();
  const specialitiesData = useSpecialitiesReducers();
  const { countries } = useCountriesReducers();
  const { notifications } = useNotificationsReducers();
  const { tabSelected, subTabSelected } = useTabSelectedReducers();
  const {
    useAdminUsersReducers,
    useAdminProjectsReducers,
    useAdminLabelsReducers,
    useContactListReducer,
    useAdminHomeReducer,
    useAdminContentInterventions,
  } = useAdminReducers();
  const { filterAdminInterventions } = useAdminContentInterventions();
  const adminUsersData = useAdminUsersReducers();
  const adminLabelData = useAdminLabelsReducers();
  const {
    useAdminCommunitiesReducers,
    useAdminProjectsRootReducers,
    useAdminInterventionsReducers,
  } = useAdminProjectsReducers();
  const adminCommunitiesData = useAdminCommunitiesReducers();
  const adminProjectsData = useAdminProjectsRootReducers();
  const { homeReducer } = useAdminHomeReducer();

  const {
    useAdminInterventionsRootReducers,
    useAdminViewInterventionsReducers,
  } = useAdminInterventionsReducers();
  const {
    interventions,
    interventionDefinitionIdSelected,
    formDiffusionDefinitionIntoIntervention,
  } = useAdminInterventionsRootReducers();
  const { useAdminViewInterventionsCommunicationReducers } =
    useAdminViewInterventionsReducers();
  const { interventionCommunicationMessages } =
    useAdminViewInterventionsCommunicationReducers();

  const contactList = useContactListReducer();
  const { colors } = useColorsReducers();
  const { showMenuCollapsed } = useSidebarReducers();
  const { artistInterventions } = useDashboardReducers();
  return combineReducers({
    auth,
    user,
    updateUser,
    countries,
    test,
    users,
    colors,
    contactList,
    showMenuCollapsed,
    interventions,
    interventionDefinitionIdSelected,
    formDiffusionDefinitionIntoIntervention,
    interventionCommunicationMessages,
    notifications,
    tabSelected,
    subTabSelected,
    homeReducer,
    ...legalData,
    ...languagesData,
    ...modalsData,
    ...specialitiesData,
    ...accessibilitiesData,
    ...adminUsersData,
    ...adminLabelData,
    ...adminCommunitiesData,
    ...adminProjectsData,
    artistUsersInfiniteScroll,
    artistInterventions,
    isFilterEmpty,
    filterAdminInterventions,
  });
};

export default useReducers;
