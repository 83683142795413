import React, { lazy } from 'react';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';

//Styles
const StyledGroup = lazy(() =>
  import(
    '../../../../../../../../../../../../styles/formViews/formViews.styles'
  ).then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const FormPilot = (props) => {
  const { control } = props

  const { useComponents } = useViews();
  const { useInputs, useLabels } = useComponents();
  const { Select, InputForm, InputHoursMinutes } = useInputs();
  const { DefaultLabel } = useLabels();

  return (
    <form>
      <StyledGroup col2 className="mb-4">
        <Select
          name="community"
          id="communities"
          placeholder="Selecciona una comunidad"
          control={control}
          label="¿En qué comunidad vas a realizar el piloto?*"
          className="w-full"
          disabled
        />
      </StyledGroup>

      <StyledGroup col2 className="mb-4" noSpace>
        <Select
          name="modality"
          id="communities"
          placeholder="Selecciona la modalidaad"
          control={control}
          label="Modalidad*"
          className="w-full"
          disabled
        />
        <InputForm
          name="date"
          label="Fecha*"
          control={control}
          type="date"
          placeholder="Fecha*"
          className="w-full"
          disabled
        />
      </StyledGroup>

      <StyledGroup col2 className="mb-4" noSpace>
        <div className="w-full">
          <DefaultLabel>Horario*</DefaultLabel>
          <div className="flex space-x-8">
            <InputHoursMinutes
              name="start_time"
              placeholder='Hora inicio'
              className="w-full"
              control={control}
              disabled
            />
            <InputHoursMinutes
              name="end_time"
              placeholder='Hora fin'
              className="w-full"
              control={control}
              disabled
            />
          </div>
        </div>
        <InputForm
          name="participants"
          label="Número de participantes*"
          type="text"
          control={control}
          className="w-full"
          disabled
        />
      </StyledGroup>
    </form>
  );
};

FormPilot.propTypes = {
  control: PropTypes.object.isRequired
}

export default FormPilot;
