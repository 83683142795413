import useAdminContactsCommunitiesDashboardHooks from './contacts';
import useAdminCreateCommunitiesDashboard from './create';
import useAdminEditCommunitiesDashboard from './edit';
import useAdminRootCommunitiesDashboard from './root';

const useAdminCommunitiesDashboardHooks = () => {
  return {
    useAdminCreateCommunitiesDashboard,
    useAdminEditCommunitiesDashboard,
    useAdminRootCommunitiesDashboard,
    useAdminContactsCommunitiesDashboardHooks,
  };
};

export default useAdminCommunitiesDashboardHooks;
