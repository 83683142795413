import useHelpers from '../../../../helpers';

const useAdminHomeSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();
  const getInterventionsHomeSelector = createSelector(
    (state) => state.homeReducer.interventions,
    (home) => home
  );

  const getHomeContentSelector = createSelector(
    (state) => state.homeReducer,
    (home) => {
      return home;
    }
  );

  const getSliderToShowSelector = createSelector(
    (state) => state.homeReducer,
    (home) => home.sliderToShow
  );

  const getInterventionSelectedToEdit = createSelector(
    (state) => state.homeReducer,
    (home) => home.interventionSelectedToEdit
  );

  return {
    getInterventionsHomeSelector,
    getHomeContentSelector,
    getSliderToShowSelector,
    getInterventionSelectedToEdit,
  };
};
export default useAdminHomeSelectors;
