//Packages
import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Components
import DropDownFilter from './components/DropDownFilter/DropDownFilter';

//Assets
import arrowDown from 'assets/icons/arrow-down-table.svg';

//Styles
const StyledTopFilters = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledTopFilters,
  }))
);

const StyledFilters = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledFilters,
  }))
);

const StyledContentButtons = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const StyledContentTable = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentTable,
  }))
);

const StyledContentButtonsModal = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const StyledTableTitle = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledTableTitle,
  }))
);

const List = (props) => {
  const {
    activeItem,
    setActiveItem,
    messagesList,
    deleteMessage,
    activeDeleteModal,
    setActiveDeleteModal,
    handleChangePage,
    dataForPagination,
    activeDropDown,
    handleOpenMenu,
    dataTableHeaderArtistMessagesRef,
    handleDataToOrder,
    orderBy,
    handleResetFilter,
    handleFilter,
    formFilterMessages,
    handleGetDetailMessages,
    optionsCommunities,
  } = props;
  const { useComponents } = useViews();
  const {
    useButtons,
    useModals,
    useTypographies,
    useImages,
    useTables,
    usePagers,
    useBadges,
    useCards,
  } = useComponents();
  const { ButtonComponent } = useButtons();
  const { Table, TableCell, TableHead, TableBody, TableRow, TableHeadCell } =
    useTables();
  const { TypographyComponent } = useTypographies();
  const { Image } = useImages();
  const { Pager } = usePagers();
  const { DefaultBadge } = useBadges();
  const { DefaultModal } = useModals();
  const { CardEmptyState } = useCards();

  const { useGeneralHooks } = useControllers();
  const { useIsFilterEmpty } = useGeneralHooks();
  const { isFilterEmpty } = useIsFilterEmpty();

  return (
    <>
      <StyledTopFilters>
        <ButtonComponent
          text="Crear nueva solicitud"
          icon={<div className="icon-close transform rotate-45 text-sm" />}
          onPress={() => {
            setActiveItem((prevValue) => ({
              ...prevValue,
              active: 1,
            }));
          }}
        />
        {!(!isFilterEmpty && messagesList?.length === 0) && (
          <StyledFilters className="relative">
            <ButtonComponent
              text="Filtros"
              mode="outline"
              className="icon-filter flex items-center space-x-2"
              onPress={handleOpenMenu}
            />

            <DropDownFilter
              open={activeDropDown}
              handleOpenMenu={handleOpenMenu}
              handleResetFilter={handleResetFilter}
              handleFilter={handleFilter}
              formFilterMessages={formFilterMessages}
              optionsCommunities={optionsCommunities}
            />
          </StyledFilters>
        )}
      </StyledTopFilters>

      <StyledContentTable minHeight="17">
        <Table className="w-full">
          <TableHead>
            <TableRow>
              {_.map(dataTableHeaderArtistMessagesRef.current, (item, idx) => (
                <TableHeadCell
                  className={`text-left py-4 px-2 ${
                    !item.noneOrder && messagesList.length
                      ? 'cursor-pointer'
                      : 'cursor-not-allowed'
                  }`}
                  key={`headList-${idx}`}
                  onClick={() => {
                    item.orderByKey &&
                      messagesList.length &&
                      handleDataToOrder({ orderByKey: item.orderByKey });
                  }}
                >
                  {item.header !== '' && (
                    <StyledTableTitle>
                      <TypographyComponent text={item.header} variant="small" />
                      {!item.noneOrder && (
                        <Image
                          srcImage={arrowDown}
                          className={`ml-2 ${
                            !orderBy[item.orderByKey] &&
                            messagesList.length &&
                            'order-arrow cursor-pointer'
                          }`}
                        />
                      )}
                    </StyledTableTitle>
                  )}
                </TableHeadCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            <>
              {_.map(messagesList, (message, idx) => (
                <TableRow
                  key={`tableRowReqMessage-${idx}`}
                  onClick={() => {
                    handleGetDetailMessages(
                      {
                        messageId: message.id,
                        isCollectionMessage: message.typeMessage === 'gi',
                      },
                      () => {
                        setActiveItem((prevValue) => ({
                          ...prevValue,
                          active: 2,
                        }));
                      }
                    );
                  }}
                  className="cursor-pointer"
                >
                  <TableCell className="py-2">
                    <p>{message.type}</p>
                  </TableCell>
                  <TableCell className="py-2">
                    <p>{message.to}</p>
                  </TableCell>
                  <TableCell className="py-2">{message.requestDate}</TableCell>
                  <TableCell className="py-2">
                    <DefaultBadge
                      label={message.state.name}
                      type="light"
                      variant={message.colorBadgeStatusMessage}
                    />
                  </TableCell>

                  <TableCell className="py-2">
                    <StyledContentButtons>
                      {(message.state.id === 'p' ||
                        message.state.id === 'r') && (
                        <ButtonComponent
                          text=""
                          className=""
                          onPress={(e) => {
                            e.stopPropagation();
                            setActiveItem((prevValue) => ({
                              ...prevValue,
                              action: 'delete',
                              idSelectedMessage: message.id,
                            }));
                            setActiveDeleteModal();
                          }}
                          disabled={
                            message.state.id !== 'p' && message.state.id !== 'r'
                          }
                          icon={
                            <div
                              className={`icon-delete ${
                                message.state === 'in_procces' &&
                                'text-neutral-600'
                              }`}
                            />
                          }
                        />
                      )}
                    </StyledContentButtons>
                  </TableCell>
                </TableRow>
              ))}
            </>
          </TableBody>
        </Table>
        {messagesList.length === 0 && (
          <CardEmptyState
            className="w-8/12 mx-auto mt-16"
            title={
              isFilterEmpty
                ? 'No hay resultados, utiliza nuevos criterios de búsqueda'
                : 'No se han encontrado solicitudes ¡Crea la primera!'
            }
            body="Este espacio es para fortalecer lazos con la comunidad durante algunas etapas de tu intervención! aquí puedes recolectar nueva información de la comunidad o enviar recordatorios. Todas las solicitudes deben de pasar un proceso de validación por el socio ejecutor."
            button={
              !isFilterEmpty ? (
                <ButtonComponent
                  className="mx-auto"
                  text="Crear nueva solicitud"
                  icon={
                    <div className="icon-close transform rotate-45 text-sm" />
                  }
                  onPress={() => {
                    setActiveItem((prevValue) => ({
                      ...prevValue,
                      active: 1,
                    }));
                  }}
                />
              ) : (
                <></>
              )
            }
          />
        )}
        <Pager
          pageCount={
            Math.ceil(dataForPagination?.total / dataForPagination?.per_page) ??
            1
          }
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          previousLabel={Boolean(dataForPagination?.prev)}
          nextLabel={Boolean(dataForPagination?.next)}
          className="flex items-center lg:justify-end justify-center my-6"
          onPageChange={({ selected }) => handleChangePage(selected)}
          forcePage={dataForPagination.current_page - 1}
        />
      </StyledContentTable>

      <DefaultModal
        isActive={activeDeleteModal}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={setActiveDeleteModal}
      >
        <TypographyComponent
          text="Eliminar mensaje"
          variant="h3"
          className="font-bold text-primary mb-1"
        />
        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto lg:mx-2 "
            mode="outline"
            onPress={setActiveDeleteModal}
          />
          <ButtonComponent
            variant="primary"
            text="Eliminar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={() => {
              deleteMessage({ message_id: activeItem?.idSelectedMessage });
            }}
          />
        </StyledContentButtonsModal>
      </DefaultModal>
    </>
  );
};

List.propTypes = {
  activeItem: PropTypes.object.isRequired,
  setActiveItem: PropTypes.func.isRequired,
  deleteMessage: PropTypes.func,
  handleChangePage: PropTypes.func,
  setActiveDeleteModal: PropTypes.func,
  handleOpenMenu: PropTypes.func,
  handleDataToOrder: PropTypes.func,
  handleResetFilter: PropTypes.func,
  handleFilter: PropTypes.func,
  messagesList: PropTypes.array,
  activeDeleteModal: PropTypes.bool,
  activeDropDown: PropTypes.bool,
  dataForPagination: PropTypes.object,
  dataTableHeaderArtistMessagesRef: PropTypes.object,
  orderBy: PropTypes.object,
  formFilterMessages: PropTypes.object,
  handleGetDetailMessages: PropTypes.func,
  optionsCommunities: PropTypes.array,
};

List.defaultProps = {
  deleteMessage: () => {},
  handleChangePage: () => {},
  setActiveDeleteModal: () => {},
  handleOpenMenu: () => {},
  handleDataToOrder: () => {},
  handleResetFilter: () => {},
  handleFilter: () => {},
  messagesList: [],
  activeDeleteModal: false,
  activeDropDown: false,
  dataForPagination: {},
  dataTableHeaderArtistMessagesRef: {},
  orderBy: {},
  formFilterMessages: {},
  handleGetDetailMessages: () => {},
  optionsCommunities: [],
};

export default List;
