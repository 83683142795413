import useViewDevelopmentInterventionCreateSketch from './createSketch';
import useDefineInterventionInterventions from './defineIntervention';
import useAdminViewInterventionsGeneralStepperAndTabsInterventions from './generalStepperAndTabsIntevention';
import useViewDevelopmentInterventionExecuteIntervention from './executeIntervention';
import useViewDevelopmentInterventionSpreadIntervention from './spreadIntervention';

const useAdminViewInterventionsDevelopmentIntervention = () => {
  return {
    useAdminViewInterventionsGeneralStepperAndTabsInterventions,
    useViewDevelopmentInterventionCreateSketch,
    useDefineInterventionInterventions,
    useViewDevelopmentInterventionExecuteIntervention,
    useViewDevelopmentInterventionSpreadIntervention
  };
};

export default useAdminViewInterventionsDevelopmentIntervention;
