//Packages
import { useMemo, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';

//Hooks
import useApi from 'api';
import useStrings from 'strings';
import useControllers from 'controllers';
import useModels from 'models';

const useArtistDefineInterventionActivity = () => {
  const [isEditModeInActivity, setIsEditModeInActivity] = useState({
    id: '',
    name: '',
    moment: '',
    justification: '',
  });

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();
  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const { useAdminDevelopmentInterventionDefineInterventionActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const {
    actCreateActivityForIntervention,
    actDeleteActivityForIntervention,
    actEditActivityForIntervention,
  } = useAdminDevelopmentInterventionDefineInterventionActions();

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { REQUIRED_FIELD, MAX_LENGTH_100, MAX_LENGTH_500 } = useFormsTypes();

  const { useGeneralHooks } = useControllers();
  const { useToggleModal } = useGeneralHooks();
  const [showCreateAndEditActivity, handleVisiblityCreateAndEditActivity] =
    useToggleModal();

  const { useSelectors } = useModels();
  const { useAdminSelectors, useSelector } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionsSelector, interventionDefinitionIdSelectedSelector } =
    useAdminInterventionsSelectors();
  const { define_intervention } = useSelector(getInterventionsSelector);
  const { id: interventionIdToDeleteEdit } = useSelector(
    interventionDefinitionIdSelectedSelector
  );

  const schemaDefineActivityForIntervention = yup.object({
    name: yup.string().required(REQUIRED_FIELD).max(100, MAX_LENGTH_100),
    moment: yup.object().required(REQUIRED_FIELD).nullable(),
    justification: yup
      .string()
      .required(REQUIRED_FIELD)
      .max(500, MAX_LENGTH_500),
  });

  const formActivity = useForm({
    resolver: yupResolver(schemaDefineActivityForIntervention),
    mode: 'onChange',
  });

  const listOfActivities = useMemo(() => {
    const response = _.find(
      define_intervention.definition_interventions,
      (definition) =>
        parseInt(definition.id) === parseInt(interventionIdToDeleteEdit)
    );
    return response?.activities || [];
  }, [
    define_intervention.definition_interventions,
    interventionIdToDeleteEdit,
  ]);

  const isActivityEditButtonDisabled = useMemo(() => {
    if (isEditModeInActivity.id && formActivity.getValues()?.name) {
      const objectActivityEditMode = {
        name: isEditModeInActivity.name.trim(),
        moment: isEditModeInActivity.moment.id,
        justification: isEditModeInActivity.justification.trim(),
      };
      const objectCurrentActivityEditMode = {
        name: formActivity.getValues().name.trim(),
        moment: formActivity.getValues().moment.value,
        justification: formActivity.getValues().justification.trim(),
      };

      return _.isEqual(objectActivityEditMode, objectCurrentActivityEditMode);
    }
    return false;
  }, [
    formActivity.watch('name'),
    formActivity.watch('moment'),
    formActivity.watch('justification'),
    isEditModeInActivity.id,
  ]);

  const handleResetActivityDataToEdit = () =>
    setIsEditModeInActivity({
      id: '',
      name: '',
      moment: '',
      justification: '',
    });

  const handleCreateActivity = (data) => {
    const activityFormData = new FormData();
    activityFormData.append('name', data.name);
    activityFormData.append('moment', data.moment.value);
    activityFormData.append('justification', data.justification);
    activityFormData.append(
      '_method',
      isEditModeInActivity.id ? 'PATCH' : 'POST'
    );

    if (isEditModeInActivity.id) {
      dispatch(
        actEditActivityForIntervention(
          {
            definition: define_intervention.id,
            definition_intervention: interventionIdToDeleteEdit,
            activityId: isEditModeInActivity.id,
            formData: activityFormData,
          },
          () => {
            formActivity.reset({ name: '', moment: '', justification: '' });
            handleVisiblityCreateAndEditActivity();
            handleResetActivityDataToEdit();
          }
        )
      );
    } else {
      dispatch(
        actCreateActivityForIntervention(
          {
            definition: define_intervention.id,
            definition_intervention: interventionIdToDeleteEdit,
            formData: activityFormData,
          },
          () => {
            formActivity.reset({ name: '', moment: '', justification: '' });
            handleVisiblityCreateAndEditActivity();
            handleResetActivityDataToEdit();
          }
        )
      );
    }
  };

  const handleDeleteActivity = ({ activityId }) => {
    dispatch(
      actDeleteActivityForIntervention({
        definition: define_intervention.id,
        definition_intervention: interventionIdToDeleteEdit,
        activityId,
      })
    );
  };

  const onSelectActivityForEdit = ({ data }) => {
    handleVisiblityCreateAndEditActivity();
    setIsEditModeInActivity(data);
    formActivity.setValue('name', data.name);
    formActivity.setValue('moment', {
      value: data.moment.id,
      label: data.moment.name,
    });
    formActivity.setValue('justification', data.justification);
  };

  return {
    define_intervention,
    handleCreateActivity,
    formActivity,
    listOfActivities,
    handleDeleteActivity,
    onSelectActivityForEdit,
    isEditModeInActivity,
    handleResetActivityDataToEdit,
    isActivityEditButtonDisabled,

    showCreateAndEditActivity,
    handleVisiblityCreateAndEditActivity,
  };
};

export default useArtistDefineInterventionActivity;
