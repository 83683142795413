const useDashboardInitialStates = () => {
  const initialStateArtistInterventions = {
    data: [],
    meta: {
      current_page: '',
      last_page: '',
      total: '',
      per_page: '',
    },
    links: {
      next: '',
      prev: '',
    },
    page: 1,
  };
  return { initialStateArtistInterventions };
};

export default useDashboardInitialStates;
