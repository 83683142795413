import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';

// Assets
import iconTimer from 'assets/icons/watch-outline.svg';

const InputHoursMinutes = (props) => {
  const {
    name,
    errors,
    label,
    placeholder,
    className,
    hintLabel,
    defaultValue,
    control,
    disabled,
    onChange,
  } = props;

  const { useComponents } = useViews();
  const { useInputs } = useComponents();
  const { InputForm } = useInputs();

  const [valueInputHourMinute, setValueInputHourMinute] = useState(null);

  const inputRef = useRef();

  useEffect(() => {
    const timeOut = setTimeout(() => {
      handleAddZerous({ currentValue: valueInputHourMinute });
    }, 750);

    if (
      valueInputHourMinute === null ||
      valueInputHourMinute?.length === 5 ||
      !valueInputHourMinute
    ) {
      clearTimeout(timeOut);
    }

    return () => {
      clearTimeout(timeOut);
    };
  }, [valueInputHourMinute]);

  useEffect(() => {
    if (onChange && valueInputHourMinute !== null) {
      onChange(valueInputHourMinute);
    }
  }, [valueInputHourMinute]);

  const handleNewValue = (event) => {
    const currentValue = event.target.value;

    const pattern = new RegExp('^[0-9,:]+$');
    const isValid = pattern.test(currentValue);

    if (isValid) {
      if (currentValue.length > 2) {
        const stringInArray = currentValue.split('');

        if (stringInArray.includes(':')) {
          const stringInArrayAux = currentValue.split(':');
          if (!stringInArrayAux[1]) {
            setValueInputHourMinute(stringInArrayAux[0]);
          } else {
            if (parseInt(stringInArrayAux[1]) > 59) {
              setValueInputHourMinute(`${stringInArrayAux[0]}:00`);
            } else {
              setValueInputHourMinute(
                `${stringInArrayAux[0]}:${stringInArrayAux[1]}`
              );
            }
          }
        } else {
          setValueInputHourMinute(
            `${stringInArray[0]}${stringInArray[1]}:${stringInArray[2]}`
          );
        }
      } else {
        setValueInputHourMinute(currentValue);
      }
    } else {
      setValueInputHourMinute('');
      inputRef.current.value = '';
    }
  };

  const handleAddZerous = ({ currentValue }) => {
    const stringInArrayAux = currentValue.split(':');
    if (stringInArrayAux[0] && stringInArrayAux[0].length === 1) {
      if (stringInArrayAux[1]) {
        setValueInputHourMinute(
          `0${stringInArrayAux[0]}:${stringInArrayAux[1]}`
        );
      } else {
        setValueInputHourMinute(`0${stringInArrayAux[0]}`);
      }
    }
    if (stringInArrayAux[0] && stringInArrayAux[0].length === 2) {
      setValueInputHourMinute(`${stringInArrayAux[0]}:00`);
    }
    if (stringInArrayAux[1] && stringInArrayAux[1].length === 1) {
      setValueInputHourMinute(`${stringInArrayAux[0]}:0${stringInArrayAux[1]}`);
    }
  };

  return (
    <InputForm
      type="text"
      className={`${className && className}`}
      name={name}
      value={valueInputHourMinute}
      placeholder={placeholder}
      onChange={handleNewValue}
      maxLength={'5'}
      errors={errors}
      label={label}
      hintLabel={hintLabel}
      defaultValue={defaultValue}
      control={control}
      disabled={disabled}
      icon={iconTimer}
      ref={inputRef}
    />
  );
};

InputHoursMinutes.propTypes = {
  name: PropTypes.string,
  errors: PropTypes.object,
  label: PropTypes.string,
  className: PropTypes.string,
  hintLabel: PropTypes.string,
  defaultValue: PropTypes.string,
  control: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

InputHoursMinutes.defaultProps = {
  name: '',
  errors: {},
  label: '',
  className: '',
  hintLabel: '',
  defaultValue: '',
  control: '',
  disabled: false,
  onChange: () => {},
  placeholder: 'hh:mm',
};

export default InputHoursMinutes;
