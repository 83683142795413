//Hooks
import useStrings from '../../../../../../../../strings';
import useProviders from '../../../../../../../providers';

const useAdminDevelopmentInterventionKnowCommunityActions = () => {
  const { useAdminProviders } = useProviders();
  const { useAdminProjectsProviders } = useAdminProviders();
  const { useAdminInterventionsProviders } = useAdminProjectsProviders();
  const { useAdminViewInterventionProviders } =
    useAdminInterventionsProviders();
  const { useAdminViewInterventionDevelopmentInterventionProviders } =
    useAdminViewInterventionProviders();
  const { useAdminDevelopmentInterventionKnowCommunityProviders } =
    useAdminViewInterventionDevelopmentInterventionProviders();
  const {
    knowCommunityInterventionProvider,
    updateKnowCommunityInterventionProvider,
    deleteKnowCommunityInterventionProvider,
    updateKnowCommunityFileInterventionProvider,
    getKnowCommunityFileInterventionProvider,
    deleteKnowCommunityFileInterventionProvider,
    storeDeepenKnowledgeCommunityInterventionProvider,
    getDeepenKnowledgeCommunityInterventionProvider,
    assignChangeAgentsDeepenKnowledgeInterventionProvider,
    getDeepenKnowledgeCommunityFileInterventionProvider,
    updateActivityDeepenKnowledgeCommunityInterventionProvider,
    getDeepenKnowledgeScheduleInfoProvider,
    updateDeepenKnowledgeScheduleInfoProvider,
    updateMessageDeepenKnowledgeInterventionProvider,
    sendMessageDeepenKnowledgeInterventionProvider,
    getMessageAnswersDeepenKnowledgeScheduleInfoProvider,
  } = useAdminDevelopmentInterventionKnowCommunityProviders();

  const { useAdminTypes } = useStrings();
  const { useAdminProjectsTypes } = useAdminTypes();
  const { useAdminInterventionsTypes } = useAdminProjectsTypes();
  const { useAdminViewInterventionsTypes } = useAdminInterventionsTypes();
  const { useAdminViewInterventionsDevelopmentInterventionTypes } =
    useAdminViewInterventionsTypes();
  const { useAdminDevelopmentInterventionKnowCommunityTypes } =
    useAdminViewInterventionsDevelopmentInterventionTypes();
  const {
    UPDATE_KNOW_COMMUNITY_INTERVENTION,
    DELETE_KNOW_COMMUNITY_INTERVENTION,
    KNOW_COMMUNITY_INTERVENTION,
    UPDATE_KNOW_COMMUNITY_FILE_INTERVENTION,
    DELETE_KNOW_COMMUNITY_FILE_INTERVENTION,
    KNOW_COMMUNITY_FILE_INTERVENTION,
    GET_DEEPEN_KNOWLEDGE_COMMUNITY_INTERVENTION,
    STORE_DEEPEN_KNOWLEDGE_COMMUNITY_INTERVENTION,
    ASSIGN_CHANGE_AGENTS_TO_DEEPEN_KNOWLEDGE_COMMUNITY_INTERVENTION,
    GET_FILE_KNOWLEDGE_COMMUNITY_INTERVENTION,
    UPDATE_ACTIVITY_KNOWLEDGE_COMMUNITY_INTERVENTION,
    GET_DEEPEN_KNOWLEDGE_SCHEDULE_INFO,
    UPDATE_DEEPEN_KNOWLEDGE_SCHEDULE_INFO,
    UPDATE_MESSAGE_DEEPEN_KNOWLEDGE_INTERVENTION,
    SET_TO_SEND_MESSAGE_DEEPEN_KNOWLEDGE_INTERVENTION,
  } = useAdminDevelopmentInterventionKnowCommunityTypes();

  const actKnowCommunityIntervention =
    ({ intervention_id }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await knowCommunityInterventionProvider({
          intervention_id,
        });
        dispatch({ type: KNOW_COMMUNITY_INTERVENTION, payload: response.data });
        onSuccess && onSuccess(response.data);
      } catch (error) {
        onError && onError(error);
      }
    };

  const actUpdateKnowCommunityIntervention =
    ({ intervention_id, community_id, data }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await updateKnowCommunityInterventionProvider({
          intervention_id,
          community_id,
          data,
        });
        dispatch({
          type: UPDATE_KNOW_COMMUNITY_INTERVENTION,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (error) {
        onError && onError(error);
      }
    };

  const actDeleteKnowCommunityIntervention =
    ({ intervention_id, community_id }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await deleteKnowCommunityInterventionProvider({
          intervention_id,
          community_id,
        });
        dispatch({ type: DELETE_KNOW_COMMUNITY_INTERVENTION, payload: '' });
        onSuccess && onSuccess(response);
      } catch (error) {
        onError && onError(error);
      }
    };

  const actUpdateKnowCommunityFileIntervention =
    ({ intervention_id, community_id, data }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await updateKnowCommunityFileInterventionProvider({
          intervention_id,
          community_id,
          data,
        });
        dispatch({
          type: UPDATE_KNOW_COMMUNITY_FILE_INTERVENTION,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (error) {
        onError && onError(error);
      }
    };

  const actGetKnowCommunityFileIntervention =
    ({ intervention_id, community_id, file_id }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await getKnowCommunityFileInterventionProvider({
          intervention_id,
          community_id,
          file_id,
        });
        dispatch({ type: KNOW_COMMUNITY_FILE_INTERVENTION, payload: '' });
        onSuccess && onSuccess(response);
      } catch (error) {
        onError && onError(error);
      }
    };

  const actDeleteKnowCommunityFileIntervention =
    ({ intervention_id, community_id, file_id }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await deleteKnowCommunityFileInterventionProvider({
          intervention_id,
          community_id,
          file_id,
        });
        dispatch({
          type: DELETE_KNOW_COMMUNITY_FILE_INTERVENTION,
          payload: file_id,
        });
        onSuccess && onSuccess(response);
      } catch (error) {
        onError && onError(error);
      }
    };

  const actStoreDeepenKnowledgeCommunityIntervention =
    ({ intervention_id, deepen_knowledge_id, data }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response =
          await storeDeepenKnowledgeCommunityInterventionProvider({
            intervention_id,
            deepen_knowledge_id,
            data,
          });
        dispatch({
          type: STORE_DEEPEN_KNOWLEDGE_COMMUNITY_INTERVENTION,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (error) {
        onError && onError(error);
      }
    };

  const actGetDeepenKnowledgeCommunityIntervention =
    ({ intervention_id }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await getDeepenKnowledgeCommunityInterventionProvider({
          intervention_id,
        });
        dispatch({
          type: GET_DEEPEN_KNOWLEDGE_COMMUNITY_INTERVENTION,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (error) {
        onError && onError(error);
      }
    };

  const actAssignChangeAgentsDeepenKnowledgeIntervention =
    ({ deepen_knowledge_id, schedule_lifting_id, data }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response =
          await assignChangeAgentsDeepenKnowledgeInterventionProvider({
            deepen_knowledge_id,
            schedule_lifting_id,
            data,
          });
        dispatch({
          type: ASSIGN_CHANGE_AGENTS_TO_DEEPEN_KNOWLEDGE_COMMUNITY_INTERVENTION,
          payload: response.data,
        });
        onSuccess && onSuccess(response.data);
      } catch (e) {
        onError && onError(e);
      }
    };

  const actGetDeepenKnowledgeCommunityFileIntervention =
    ({ intervention_id, deepen_knowledge_id, file_id }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response =
          await getDeepenKnowledgeCommunityFileInterventionProvider({
            intervention_id,
            deepen_knowledge_id,
            file_id,
          });
        onSuccess && onSuccess(response);
        dispatch({
          type: GET_FILE_KNOWLEDGE_COMMUNITY_INTERVENTION,
        });
      } catch (error) {
        onError && onError(error);
      }
    };
  const actUpdateActivityDeepenKnowledgeCommunityIntervention =
    (
      { intervention_id, deepen_knowledge_id, activity_id, data },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const response =
          await updateActivityDeepenKnowledgeCommunityInterventionProvider({
            intervention_id,
            deepen_knowledge_id,
            activity_id,
            data,
          });
        dispatch({
          type: UPDATE_ACTIVITY_KNOWLEDGE_COMMUNITY_INTERVENTION,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (error) {
        onError && onError(error);
      }
    };

  const actGetDeepenKnowledgeScheduleInfo =
    ({ intervention_id, deepen_knowledge_id }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await getDeepenKnowledgeScheduleInfoProvider({
          intervention_id,
          deepen_knowledge_id,
        });
        dispatch({
          type: GET_DEEPEN_KNOWLEDGE_SCHEDULE_INFO,
          payload: response.data,
        });
        onSuccess && onSuccess(response.data);
      } catch (error) {
        onError && onError(error);
      }
    };

  const actUpdateDeepenKnowledgeScheduleInfo =
    (
      { intervention_id, deepen_knowledge_id, schedule_lifting_id, data },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const response = await updateDeepenKnowledgeScheduleInfoProvider({
          intervention_id,
          deepen_knowledge_id,
          schedule_lifting_id,
          data,
        });
        dispatch({
          type: UPDATE_DEEPEN_KNOWLEDGE_SCHEDULE_INFO,
          payload: response.data,
        });
        onSuccess && onSuccess(response.data);
      } catch (error) {
        onError && onError(error);
      }
    };

  const actUpdateMessageDeepenKnowledgeIntervention =
    (
      { intervention_id, deepen_knowledge_id, message_id, data },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const response = await updateMessageDeepenKnowledgeInterventionProvider(
          {
            intervention_id,
            deepen_knowledge_id,
            message_id,
            data,
          }
        );
        dispatch({
          type: UPDATE_MESSAGE_DEEPEN_KNOWLEDGE_INTERVENTION,
          payload: response.data,
        });
        onSuccess && onSuccess(response.data);
      } catch (error) {
        onError && onError(error);
      }
    };

  const actSendMessageDeepenKnowledgeIntervention =
    ({ deepen_knowledge_id, message_id, data }, onSuccess, onError) =>
    async () => {
      try {
        const response = await sendMessageDeepenKnowledgeInterventionProvider({
          deepen_knowledge_id,
          message_id,
          data,
        });
        onSuccess && onSuccess(response.data);
      } catch (error) {
        onError && onError(error);
      }
    };

  const actSetToSendMessageDeepenKnowledgeIntervention =
    ({ message }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        onSuccess && onSuccess();
        dispatch({
          type: SET_TO_SEND_MESSAGE_DEEPEN_KNOWLEDGE_INTERVENTION,
          payload: message,
        });
      } catch (error) {
        onError && onError(error);
      }
    };

  const actGetMessageAnswersDeepenKnowledgeScheduleInfo =
    ({ message_request_id, page }, onSuccess, onError) =>
    async () => {
      try {
        const response =
          await getMessageAnswersDeepenKnowledgeScheduleInfoProvider({
            message_request_id,
            page,
          });
        onSuccess && onSuccess(response.data);
      } catch (error) {
        onError && onError(error);
      }
    };

  return {
    actKnowCommunityIntervention,
    actUpdateKnowCommunityIntervention,
    actDeleteKnowCommunityIntervention,
    actUpdateKnowCommunityFileIntervention,
    actGetKnowCommunityFileIntervention,
    actDeleteKnowCommunityFileIntervention,
    actStoreDeepenKnowledgeCommunityIntervention,
    actGetDeepenKnowledgeCommunityIntervention,
    actAssignChangeAgentsDeepenKnowledgeIntervention,
    actGetDeepenKnowledgeCommunityFileIntervention,
    actUpdateActivityDeepenKnowledgeCommunityIntervention,
    actGetDeepenKnowledgeScheduleInfo,
    actUpdateDeepenKnowledgeScheduleInfo,
    actUpdateMessageDeepenKnowledgeIntervention,
    actSendMessageDeepenKnowledgeIntervention,
    actSetToSendMessageDeepenKnowledgeIntervention,
    actGetMessageAnswersDeepenKnowledgeScheduleInfo,
  };
};

export default useAdminDevelopmentInterventionKnowCommunityActions;
