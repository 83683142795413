//Packages
import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';

//Hooks
import useModels from '../../../../../../../models';
import useApi from '../../../../../../../api';
import useControllers from '../../../../../..';
import useHelpers from '../../../../../../../helpers';

const useAdminSelectArtistInterventionsDashboard = ({
  artistSelectedByDefault,
  setSelectedArtist,
  handleCloseModal,
  favoritesTab,
}) => {
  const [textSearch, setTextSearch] = useState('');
  const [artistSelected, setArtistSelected] = useState(null);
  const [errorSelectedArtist, setErrorSelectedArtist] = useState(false);
  const [itemsListRef, setItemsListRef] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModalUser, setShowModalUser] = useState(false);

  const { useActions } = useApi();
  const { dispatch, useUserActions } = useActions();
  const {
    actSearchUser,
    actGetUser,
    actGetFavoriteUsers,
    actSaveUserAsFavorite,
    actSaveUserAsNotFavorite,
  } = useUserActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors, useUserSelectors } = useSelectors();
  const { usersSelector } = useUserSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getArtistsUsersInfiniteScrollSelector } =
    useAdminInterventionsSelectors();
  const { data: artistList, meta: usersArtistListMeta } = useSelector(
    getArtistsUsersInfiniteScrollSelector
  );
  const { user: userInfo, favorites } = useSelector(usersSelector);

  const usersArtistList = favoritesTab ? favorites : artistList;

  const { useGeneralHooks } = useControllers();
  const { useInfiniteScroll } = useGeneralHooks();
  const { isLastElementVisible } = useInfiniteScroll({
    refElement: itemsListRef,
    visibleElementFrom: '100%',
  });

  const { useQuickFunctions } = useHelpers();
  const { showCityDepartmentAndCountry } = useQuickFunctions();

  useEffect(() => {
    if (artistSelectedByDefault && !artistSelected) {
      const artistSelected = _.filter(
        usersArtistList,
        (user) => `${user.id}` === `${artistSelectedByDefault}`
      );
      setArtistSelected(artistSelected[0]);
    }
  }, [usersArtistList]);

  useEffect(() => {
    if (isLastElementVisible && usersArtistListMeta.last_page !== currentPage) {
      dispatch(
        actSearchUser(
          {
            page: currentPage + 1,
            infiniteScroll: true,
            roles: [{ value: 'ar' }],
          },
          'users',
          () => {
            setCurrentPage(currentPage + 1);
          }
        )
      );
    }
  }, [isLastElementVisible]);

  useEffect(() => {
    if (favoritesTab) {
      dispatch(actGetFavoriteUsers());
    } else {
      dispatch(
        actSearchUser({
          infiniteScroll: true,
          roles: [{ value: 'ar' }],
          isArtistSelection: true,
        })
      );
    }
  }, [favoritesTab]);

  const artistsUsersFiltered = useMemo(() => {
    if (textSearch.length >= 3) {
      return _.filter(usersArtistList, (artist) => {
        return `${artist.name} ${artist.last_name}`
          .toLowerCase()
          .includes(textSearch.toLowerCase());
      });
    } else {
      return [...usersArtistList];
    }
  }, [textSearch, usersArtistList]);

  const handleSelectArtist = (e) => {
    if (errorSelectedArtist) {
      setErrorSelectedArtist((prevState) => !prevState);
    }
    const artistSelected = _.filter(
      usersArtistList,
      (user) => user.id === parseInt(e.target.value)
    );
    setArtistSelected(artistSelected[0]);
    setSelectedArtist(artistSelected[0]);
  };

  const handleConfirmSelectedArtist = () => {
    if (artistSelected) {
      setSelectedArtist(artistSelected);
      handleCloseModal();
    } else {
      setErrorSelectedArtist(true);
    }
  };

  const handleShowUserProfile = ({ userId }) => {
    dispatch(
      actGetUser(userId, () => {
        setShowModalUser(true);
      })
    );
  };

  const handleToggleArtistFavorite = ({ artist }) => {
    const { id: userId, favorite } = artist;
    if (favorite === 'false') {
      dispatch(actSaveUserAsFavorite({ userId }));
    } else {
      dispatch(actSaveUserAsNotFavorite({ userId }));
    }
  };

  return {
    textSearch,
    setTextSearch,
    artistSelected,
    errorSelectedArtist,
    setItemsListRef,
    artistsUsersFiltered,
    setShowModalUser,
    showModalUser,
    userInfo,
    handleSelectArtist,
    handleShowUserProfile,
    handleConfirmSelectedArtist,
    showCityDepartmentAndCountry,
    handleToggleArtistFavorite,
  };
};

export default useAdminSelectArtistInterventionsDashboard;
