//Packages
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Components
import DeepenKnowledgeCommunitiesArtist from '../../Artist/KnowCommunities/DeepenKnowledgeCommunities';
import DeepenKnowledgeCommunitiesAdmin from '../../Admin/KnowCommunities/DeepenKnowCommunities';
import KnowCommunitiesArtist from '../../Artist/KnowCommunities/KnowCommunities';
import KnowCommunitiesAdmin from '../../Admin/KnowCommunities';
import ProgramKnowCommunitiesAdmin from '../../Admin/KnowCommunities/ProgramKnowCommunities';
import ProgramKnowCommunitiesArtist from '../../Artist/KnowCommunities/ProgramKnowCommunities';

const StepperKnowCommunities = (props) => {
  const { permissions } = props;

  const { useComponents } = useViews();
  const { useStepper } = useComponents();
  const { Stepper, Step } = useStepper();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminKnowCommunityInterventionsSteep } =
    useAdminInterventionsDashboardControllers();
  const {
    currentPage,
    setCurrentPage,
    deepen_knowledge,
    deepen_execution,
    know_community,
    actualPhase,
  } = useAdminKnowCommunityInterventionsSteep();

  return (
    <>
      {know_community.com_deepen ? (
        <Stepper
          currentStep={currentPage}
          setCurrentStep={setCurrentPage}
          variant="line"
          isSubStepper
          conetor
        >
          <Step
            label={'Conocer comunidades de la intervención'}
            stepStatusName={know_community?.state.name}
            stepStatusIdForColor={know_community.state.id}
            actualPhase={actualPhase}
          >
            {currentPage === 0 && (
              <>
                {!permissions && (
                  <KnowCommunitiesArtist setCurrentPage={setCurrentPage} />
                )}
                {permissions && <KnowCommunitiesAdmin />}
              </>
            )}
          </Step>
          <Step
            label={'Profundizar en el conocimiento de la comunidad'}
            stepStatusName={deepen_knowledge?.state.name}
            stepStatusIdForColor={deepen_knowledge?.state.id}
            actualPhase={actualPhase}
          >
            {currentPage === 1 && (
              <>
                {!permissions && (
                  <DeepenKnowledgeCommunitiesArtist
                    setCurrentPage={setCurrentPage}
                  />
                )}
                {permissions && (
                  <DeepenKnowledgeCommunitiesAdmin
                    viewActive={currentPage}
                    setViewActive={setCurrentPage}
                  />
                )}
              </>
            )}
          </Step>
          <Step
            label={'Ejecución de la profundización'}
            stepStatusName={
              _.includes(['cl', 'r'], deepen_knowledge?.state.id)
                ? deepen_knowledge?.state.name
                : actualPhase >= 4
                ? know_community?.state.name
                : deepen_execution?.state.name
            }
            stepStatusIdForColor={
              _.includes(['cl', 'r'], deepen_knowledge?.state.id)
                ? deepen_knowledge?.state.id
                : actualPhase >= 4
                ? know_community?.state.id
                : deepen_execution?.state.id
            }
            actualPhase={actualPhase}
          >
            {currentPage === 2 && (
              <>
                {permissions && <ProgramKnowCommunitiesAdmin />}
                {!permissions && <ProgramKnowCommunitiesArtist />}
              </>
            )}
          </Step>
        </Stepper>
      ) : (
        <>
          {!permissions && (
            <KnowCommunitiesArtist setCurrentPage={setCurrentPage} />
          )}
          {permissions && <KnowCommunitiesAdmin />}
        </>
      )}
    </>
  );
};
StepperKnowCommunities.propTypes = {
  permissions: PropTypes.bool.isRequired,
};
export default StepperKnowCommunities;
