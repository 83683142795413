const useContactListTypes = () => {
  const GET_CONTACT_LISTS = 'GET_CONTACT_LISTS';
  const GET_CONTACT_LIST = 'GET_CONTACT_LIST';
  const STORE_CONTACT_LIST = 'STORE_CONTACT_LIST';
  const UPDATE_CONTACT_LIST = 'UPDATE_CONTACT_LIST';
  const DELETE_CONTACT_LIST = 'DELETE_CONTACT_LIST';
  const ADD_CONTACT_TO_CONTACT_LIST = 'ADD_CONTACT_TO_CONTACT_LIST';
  const REMOVE_CONTACT_TO_CONTACT_LIST = 'REMOVE_CONTACT_TO_CONTACT_LIST';
  const SEARCH_CONTACTS_IN_LIST = 'SEARCH_CONTACTS_IN_LIST';
  const RESET_DATA_CONTACT_LIST_SELECTED = 'RESET_DATA_CONTACT_LIST_SELECTED';
  return {
    GET_CONTACT_LISTS,
    GET_CONTACT_LIST,
    STORE_CONTACT_LIST,
    UPDATE_CONTACT_LIST,
    DELETE_CONTACT_LIST,
    ADD_CONTACT_TO_CONTACT_LIST,
    REMOVE_CONTACT_TO_CONTACT_LIST,
    SEARCH_CONTACTS_IN_LIST,
    RESET_DATA_CONTACT_LIST_SELECTED,
  };
};
export default useContactListTypes;
