//Hooks
import useHelpers from '../../../../../helpers';

const useAdminProjectsRootSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const filterDataAdminUserSelector = createSelector(
    (state) => state.filterDataAdminUsers,
    (filterDataAdminUsers) => filterDataAdminUsers
  );

  const projectsListSelector = createSelector(
    (state) => state.projectsList,
    (projectsList) => {
      return { projectsList, numberProjects: projectsList.data.length };
    }
  );

  const projectFilterSelector = createSelector(
    (state) => state.projectFilter,
    (projectFilter) => projectFilter
  );

  const projectSelectedSelector = createSelector(
    (state) => state.projectSelected,
    (projectSelected) => projectSelected
  );

  const projectSelectedTotalStatisticsSelector = createSelector(
    (state) => state.projectSelected,
    (projectSelected) => {
      const {
        dashboard: { interventions, communities_members },
      } = projectSelected;
      return {
        totalActiveInterventions:
          interventions.active.inspire +
          interventions.active.activate +
          interventions.active.hold,
        totalCompletedInterventions:
          interventions.completed.inspire +
          interventions.completed.activate +
          interventions.completed.hold,
        totalContactCommunities:
          communities_members.changed_agent + communities_members.community,
      };
    }
  );

  return {
    filterDataAdminUserSelector,
    projectsListSelector,
    projectFilterSelector,
    projectSelectedSelector,
    projectSelectedTotalStatisticsSelector,
  };
};

export default useAdminProjectsRootSelectors;
