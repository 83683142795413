//Packages
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';

//Hooks
import useHelpers from 'helpers';
import useStrings from 'strings';
import useApi from 'api';
import useModels from 'models';
import useControllers from 'controllers';

const useAdminEditCommunitiesDashboard = () => {
  const { community_id: communityId, project_id: projectId } = useParams();
  const history = useHistory();
  const [communityData, setCommunityData] = useState(null);
  const [validateChecboxes, setValidateChecboxes] = useState(false);

  const { useActions } = useApi();
  const { dispatch, useAdminActions, useGeneralActions } = useActions();
  const { useAccessibilityActions } = useGeneralActions();
  const { actGetAccessibilities } = useAccessibilityActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminCommunitiesActions } = useAdminProjectsActions();
  const { actGetCommunityDetailById, actUpdateCommunityById } =
    useAdminCommunitiesActions();

  const { useSelectors } = useModels();
  const { useSelector, useGeneralSelectors, useAdminSelectors } =
    useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminProjectsRootSelectors } = useAdminProjectsSelectors();
  const { projectSelectedSelector } = useAdminProjectsRootSelectors();
  const projectData = useSelector(projectSelectedSelector);
  const { useAccessibilitySelectors } = useGeneralSelectors();
  const { accessibilitiesSelector } = useAccessibilitySelectors();
  const accessibilitiesList = useSelector(accessibilitiesSelector);

  const { useGeneralHooks } = useControllers();
  const { useWhereLive } = useGeneralHooks();
  const { optionsLanguages } = useWhereLive({ callEndpointCountries: false });

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const {
    REQUIRED_FIELD,
    FILE_SIZE,
    FILE_TYPE,
    NUMBER_NOT_VALID,
    ARRAY_MIN_1_LENGTH,
    MAX_LENGTH,
    POSITIVE_NUMBER,
    INTEGER_NUMBER,
    MAX_1M_POPULATION_NUMBER,
  } = useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const { getFileValidations, getMimeTypeFromMedia } = useQuickFunctions();
  const { MAX_SIZE, SUPPORTED_FORMATS_ONLY_IMAGES, SUPPORTED_FORMATS } =
    getFileValidations();

  const schemaEditCommunity = yup.object({
    name: yup.string().required(REQUIRED_FIELD),
    general_comments: yup.string().nullable().max(40000, MAX_LENGTH),
    language: yup.object().required(REQUIRED_FIELD),
    reading_level: yup.string().required(REQUIRED_FIELD).nullable(),
    has_internet: yup.string().required(REQUIRED_FIELD).nullable(),
    image: yup
      .mixed()
      .nullable()
      .test('fileType', FILE_TYPE, (value) => {
        if (value) {
          return SUPPORTED_FORMATS_ONLY_IMAGES.includes(value.type);
        }
        return true;
      })
      .test('fileSize', FILE_SIZE, (value) => {
        if (value) {
          return value.size <= MAX_SIZE;
        }
        return true;
      }),
    population_number: yup
      .number(NUMBER_NOT_VALID)
      .positive(POSITIVE_NUMBER)
      .integer(INTEGER_NUMBER)
      .max(1000000, MAX_1M_POPULATION_NUMBER)
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value))
      .notRequired(),
    communication_medias: yup
      .array()
      .nullable()
      .required(REQUIRED_FIELD)
      .test('values', ARRAY_MIN_1_LENGTH, (values) => {
        const res = values.every((communication) => communication === false);
        return !res;
      }),
    device_access: yup.string().required(REQUIRED_FIELD).nullable(),
    main: yup
      .mixed()
      .nullable()
      .test('fileType', FILE_TYPE, (value) => {
        if (value) {
          return SUPPORTED_FORMATS.includes(value.type);
        }
        return true;
      })
      .test('fileSize', FILE_SIZE, (value) => {
        if (value) {
          return value.size <= MAX_SIZE;
        }
        return true;
      }),
    first: yup
      .mixed()
      .nullable()
      .test('fileType', FILE_TYPE, (value) => {
        if (value) {
          return SUPPORTED_FORMATS.includes(value.type);
        }
        return true;
      })
      .test('fileSize', FILE_SIZE, (value) => {
        if (value) {
          return value.size <= MAX_SIZE;
        }
        return true;
      }),
    second: yup
      .mixed()
      .nullable()
      .test('fileType', FILE_TYPE, (value) => {
        if (value) {
          return SUPPORTED_FORMATS.includes(value.type);
        }
        return true;
      })
      .test('fileSize', FILE_SIZE, (value) => {
        if (value) {
          return value.size <= MAX_SIZE;
        }
        return true;
      }),
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    watch,
    setError,
  } = useForm({
    resolver: yupResolver(schemaEditCommunity),
    mode: 'onChange',
  });

  const communicationMediasWatched = watch('communication_medias[]');

  useEffect(() => {
    const res = communicationMediasWatched?.every(
      (communication) => communication === false
    );
    if (res) {
      setError('communication_medias', {
        type: 'manual',
        message: ARRAY_MIN_1_LENGTH,
      });
    } else {
      clearErrors('communication_medias');
    }
  }, [validateChecboxes]);

  useEffect(() => {
    dispatch(
      actGetCommunityDetailById(
        { communityId, projectId },
        onSuccessActGetCommunityDetailById
      )
    );
  }, []);

  useEffect(() => {
    if (accessibilitiesList.length === 0) {
      dispatch(actGetAccessibilities());
    }
  }, []);

  const onSuccessActGetCommunityDetailById = (res) => {
    const { data } = res.data;
    setCommunityData(data);
    setValue('name', data.name);
    data.population_number &&
      setValue('population_number', data.population_number);
    setValue('language', {
      label: data?.language,
      value: data?.language,
    });
    setValue('reading_level', data.reading_level.id);
    setValue('has_internet', data.has_internet.id);
    setValue('device_access', data.device_access.id);
    setValue('general_comments', data.general_comments);
    setValue('communication_medias', data.communication_medias);

    const communication = _.map(accessibilitiesList, (accessibility) => {
      return !!_.find(data?.communication_medias, (accessibilityAux) => {
        return accessibilityAux.id === accessibility.id;
      });
    });
    setValue('communication_medias', communication);
  };

  const handleGoBack = () => history.goBack();

  const onSuccessHandleEditCommunity = () => {
    history.goBack();
  };

  const handleEditCommunity = (data) => {
    const accessibilityAux = _.map(
      data.communication_medias,
      (accessibility, idx) => {
        return accessibility ? idx + 1 : false;
      }
    ).filter((accessibility) => accessibility !== false);
    delete data.communication_medias;
    dispatch(
      actUpdateCommunityById(
        {
          communityId,
          projectId,
          ...data,
          population_number: data.population_number
            ? data.population_number
            : '',
          communication_medias: accessibilityAux,
        },
        onSuccessHandleEditCommunity
      )
    );
  };

  const handleDefaultCheckedAccessibility = ({ accessibilityId }) => {
    const existsDefaultChecked = _.find(
      communityData?.communication_medias,
      (accessibility) => {
        return accessibility.id === accessibilityId;
      }
    );

    return Boolean(existsDefaultChecked);
  };

  const handleResetInputFile = ({ inputFileName }) => {
    setValue(inputFileName, '');
    clearErrors(inputFileName);
  };

  const handleSelectDefaultImage = ({ typeMultimedia }) => {
    const defaultImage = _.find(
      communityData?.multimedias,
      (multimedia) => multimedia.type === typeMultimedia
    );

    return defaultImage?.url ?? '';
  };

  const handleSelectTypeFile = ({ typeMultimedia }) => {
    const defaultImage = _.find(
      communityData?.multimedias,
      (multimedia) => multimedia.type === typeMultimedia
    );

    return getMimeTypeFromMedia({ urlFile: defaultImage?.url }) || 'image';
  };

  return {
    control,
    register,
    handleSubmit,
    errors,
    communityData,
    handleGoBack,
    handleEditCommunity,
    accessibilitiesList,
    optionsLanguages,
    projectData,
    handleDefaultCheckedAccessibility,
    handleResetInputFile,
    handleSelectDefaultImage,
    handleSelectTypeFile,
    setValidateChecboxes,
  };
};

export default useAdminEditCommunitiesDashboard;
