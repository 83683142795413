import AsingPlan from './AsingPlan';
import KnowProject from './KnowProject';
import KnowCommunities from './KnowCommunities';
import DefineIntervention from './DefineIntervention';
import useStepsSketchInterventionAdmin from './SketchIntervention';
import FinishIntervention from './FinishIntervention';

const useStepperAdmin = () => {
  const {
    PilotEvaluate,
    PilotIntervention,
    PilotProgram,
    Sketchinterventions,
  } = useStepsSketchInterventionAdmin();
  return {
    AsingPlan,
    KnowProject,
    KnowCommunities,
    DefineIntervention,
    PilotEvaluate,
    PilotIntervention,
    PilotProgram,
    Sketchinterventions,
    FinishIntervention,
  };
};

export default useStepperAdmin;
