const useConsole = () => {
  /**
   * General hook for use any type JS console
   * @param {string} type oneOf: [log, warn, error, info, assert, _exception, dir, dirxml, group, groupCollapsed,
    groupEnd, profile, profileEnd, table, time, timeEnd, timeStamp, trace, clear, count, countReset, debug]
   * @param {Array} consoleArguments contains all (any type variable) that you want print on console
   * @returns {Function} Function that runs like any type console
   */
  const viewConsole = (consoleArguments = ['Hola Mundo'], type = 'log') => {
    if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
      // eslint-disable-next-line
      console[type](...consoleArguments);
    }
  };
  return {
    viewConsole,
  };
};
export default useConsole;
