import AdminCreateProjects from './Create/Create';
import useAdminProjectsDashboardScreens from './Dashboard';
import AdminEditProjects from './Edit/Edit';
import AdminRootProjects from './Root';

const useProjectsAdminScreens = () => {
  const adminDashboardProjectsScreens = useAdminProjectsDashboardScreens();
  return {
    AdminRootProjects,
    AdminEditProjects,
    AdminCreateProjects,
    ...adminDashboardProjectsScreens,
  };
};

export default useProjectsAdminScreens;
