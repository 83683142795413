//Hooks
import useApi from '../../../..';
import useControllers from '../../../../../controllers';
import useStrings from '../../../../../strings';

const useAdminProjectsRootActions = () => {
  const { useAdminTypes } = useStrings();
  const { useAdminProjectsTypes } = useAdminTypes();
  const { useAdminProjectsRootTypes } = useAdminProjectsTypes();
  const {
    PROJECT_CREATED,
    GET_PROJECTS,
    DELETE_PROJECT,
    GET_PROJECT_ID,
    UPDATE_PROJECT_ID,
    SET_DATA_PROJECT_FILTER,
    RESET_DATA_PROJECT_FILTER,
    RESET_DATA_PROJECT_FILTER_WITHOUT_NAME,
    SET_PAGE_GET_PROJECTS,
  } = useAdminProjectsRootTypes();

  const { useGeneralHooks } = useControllers();
  const { useConsole } = useGeneralHooks();
  const { viewConsole } = useConsole();

  const { useProviders } = useApi();
  const { useAdminProviders } = useProviders();
  const { useAdminProjectsProviders } = useAdminProviders();
  const { useAdminProjectsRootProviders } = useAdminProjectsProviders();
  const {
    createProject,
    getProjectsList,
    deleteProjectById,
    getProjectById,
    updateProjectById,
  } = useAdminProjectsRootProviders();

  const actCreateProject =
    (
      {
        name,
        description,
        population_number,
        languages,
        city_id,
        project_manager_id,
        social_expert_id,
        executing_partner_id,
        image,
      },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const bodyFormData = new FormData();
        bodyFormData.append('name', name);
        bodyFormData.append('description', description);
        bodyFormData.append('population_number', population_number);
        bodyFormData.append('city_id', city_id.value);
        languages &&
          languages.length > 0 &&
          languages.forEach((language) => {
            return bodyFormData.append(`languages[]`, language.value);
          });
        project_manager_id &&
          bodyFormData.append('project_manager_id', project_manager_id.value);
        social_expert_id &&
          bodyFormData.append('social_expert_id', social_expert_id.value);
        executing_partner_id &&
          bodyFormData.append(
            'executing_partner_id',
            executing_partner_id.value
          );
        image && bodyFormData.append('image', image);

        const res = await createProject({ formData: bodyFormData });
        dispatch({ type: PROJECT_CREATED });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actGetProjects =
    (
      {
        paginate,
        page,
        name,
        country_id,
        department_id,
        city_id,
        project_manager_id,
        social_expert_id,
        executing_partner_id,
        owner_id,
        orderByKey,
        orderByValue,
      } = {},
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const res = await getProjectsList({
          paginate,
          page,
          name,
          country_id,
          department_id,
          city_id,
          project_manager_id,
          social_expert_id,
          executing_partner_id,
          owner_id,
          orderByKey,
          orderByValue,
        });
        dispatch({ type: GET_PROJECTS, payload: res.data });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actSetPageGetProjects =
    ({ page }) =>
    (dispatch) => {
      dispatch({ type: SET_PAGE_GET_PROJECTS, payload: { page } });
    };

  const actDeleteProject =
    ({ projectId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await deleteProjectById({ projectId });
        dispatch({ type: DELETE_PROJECT });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actGetProjectById =
    ({ projectId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await getProjectById({ projectId });
        dispatch({ type: GET_PROJECT_ID, payload: res.data?.data });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actUpdateProjectById =
    (
      {
        projectId,
        name,
        description,
        population_number,
        languages,
        city_id,
        project_manager_id,
        social_expert_id,
        executing_partner_id,
        image,
      },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const bodyFormData = new FormData();
        bodyFormData.append('name', name);
        bodyFormData.append('description', description);
        bodyFormData.append('population_number', population_number);
        bodyFormData.append('city_id', city_id.value);
        languages &&
          languages.length > 0 &&
          languages.forEach((language) => {
            return bodyFormData.append(`languages[]`, language.value);
          });
        project_manager_id &&
          bodyFormData.append('project_manager_id', project_manager_id.value);
        social_expert_id &&
          bodyFormData.append('social_expert_id', social_expert_id.value);
        executing_partner_id &&
          bodyFormData.append(
            'executing_partner_id',
            executing_partner_id.value
          );
        (image || image === '') && bodyFormData.append('image', image);
        bodyFormData.append('_method', 'patch');

        const res = await updateProjectById({
          projectId,
          formData: bodyFormData,
        });
        dispatch({ type: UPDATE_PROJECT_ID });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actSetDataProjectFilter =
    ({ key, value }) =>
    (dispatch) => {
      dispatch({ type: SET_DATA_PROJECT_FILTER, payload: { key, value } });
    };

  const actResetDataProjectFilter = () => (dispatch) => {
    dispatch({ type: RESET_DATA_PROJECT_FILTER });
  };

  const actResetDataProjectFilterWithoutName = () => (dispatch) => {
    dispatch({ type: RESET_DATA_PROJECT_FILTER_WITHOUT_NAME });
  };

  return {
    actCreateProject,
    actGetProjects,
    actDeleteProject,
    actGetProjectById,
    actUpdateProjectById,
    actSetDataProjectFilter,
    actResetDataProjectFilter,
    actResetDataProjectFilterWithoutName,
    actSetPageGetProjects,
  };
};

export default useAdminProjectsRootActions;
