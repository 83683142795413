import React from 'react';
import PropTypes from 'prop-types';

//Styles
import { StyledTypography } from './TypographyComponents.styles';

const TypographyComponent = (props) => {
  const { variant, text, className, onClick } = props;
  return (
    <StyledTypography variant={variant} className={className} onClick={onClick}>
      {text}
    </StyledTypography>
  );
};
TypographyComponent.propTypes = {
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'span',
    'label',
    'small',
  ]).isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

TypographyComponent.defaultProps = {
  className: '',
  onClick: () => {},
};
export default React.memo(TypographyComponent);
