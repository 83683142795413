import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useSpecialitiesProviders = () => {
  const getSpecialities = ({ paginate }) => {
    const response = axios({
      method: 'GET',
      url: '/specialties',
      params: { paginate },
    });
    return trackPromise(response);
  };
  return { getSpecialities };
};
export default useSpecialitiesProviders;
