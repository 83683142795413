import React, { lazy } from 'react';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';
import useHelpers from 'helpers';

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

const StyledContentButtons = lazy(() =>
  import(
    '../../../../../../../../../../../../styles/tableViews/tableViews.styles'
  ).then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const StyledButtonsActions = lazy(() =>
  import(
    '../../../../../../../../../../../../styles/buttons/buttons.styles'
  ).then((mod) => ({
    default: mod.StyledButtonsActions,
  }))
);

const StyledDivider = lazy(() =>
  import(
    '../../../../../../../../../../../../styles/formViews/formViews.styles'
  ).then((mod) => ({
    default: mod.StyledDivider,
  }))
);

const StyledContentButtonsModal = lazy(() =>
  import(
    '../../../../../../../../../../../../styles/tableViews/tableViews.styles'
  ).then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const MaterializeConvocatoryArtist = () => {
  const { useComponents } = useViews();
  const {
    useTypographies,
    useButtons,
    useInputs,
    useModals,
    useCards,
    useBadges,
    useCollapses,
  } = useComponents();
  const { CardNotification } = useCards();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { ButtonComponent } = useButtons();
  const { InputFile } = useInputs();
  const { DefaultModal } = useModals();
  const { DefaultBadge } = useBadges();
  const { DefaultCollapse } = useCollapses();

  const { useQuickFunctions } = useHelpers();
  const { getDocumentName, getYearMonthDayFromDate } = useQuickFunctions();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsDevelopmentIntervention } =
    useAdminViewInterventionsControllers();
  const { useViewDevelopmentInterventionExecuteIntervention } =
    useAdminViewInterventionsDevelopmentIntervention();
  const { useViewDevelopmentExecuteInterventionArtist } =
    useViewDevelopmentInterventionExecuteIntervention();
  const { useArtistExecuteMaterializeIntervention } =
    useViewDevelopmentExecuteInterventionArtist();
  const {
    CONVOCATION_METHODS,
    executeMaterialize,
    textForLimitDate,
    formExecuteMaterialize,
    openModalApprove,
    setOpenModalApprove,
    canEditForm,
    openFile,
    downloadFile,
    deleteFile,
    updateEvaluatePilotIntervention,
  } = useArtistExecuteMaterializeIntervention();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = formExecuteMaterialize;

  if (executeMaterialize.state.id === 'p') {
    return (
      <CardNotification
        className="w-6/12 mx-auto py-9 px-4 mt-4"
        title={
          <TypographyComponent
            text={executeMaterialize.state.name}
            variant="h2"
            className="font-bold text-primary icon-watch text-center"
          />
        }
      />
    );
  }

  return (
    <>
      {_.includes(['c', 'iv', 'ip'], executeMaterialize.state.id) && (
        <CardNotification
          className="w-6/12 mx-auto py-9 px-4 mt-4"
          title={
            <TypographyComponent
              text={executeMaterialize.state.name}
              variant="h2"
              className="font-bold text-primary icon-watch text-center"
            />
          }
        />
      )}
      {executeMaterialize.rejected_reasons.length > 0 && (
        <DefaultCollapse
          title={
            <TypographyWithTooltip
              text="Recomendaciones del Socio Ejecutor"
              variant="h3"
              textTooltip="Recomendaciones del Socio Ejecutor"
              className="text-primary"
              idTooltip="recomendations"
              applyFontBold
            />
          }
          titleTitleCollapsed=""
          elementCollapsed={
            <div className="icon-arrow-down text-xxs text-neutral-950 cursor-pointer" />
          }
          elementUncollapsed={
            <div className="icon-arrow-down text-xxs text-neutral-950 transform rotate-180 cursor-pointer"></div>
          }
          className="border border-error py-6 rounded pl-8 pr-10 mb-8 bg-neutral-300"
          classNameTitle="w-full flex justify-between"
          withUseState
        >
          {_.map(executeMaterialize.rejected_reasons, (reason) => {
            const date = getYearMonthDayFromDate({
              dateInString: reason.created_at,
              format: 'dmyShort',
            });
            const time = getYearMonthDayFromDate({
              dateInString: reason.created_at,
              format: 'dmyTime',
            }).split('-')[1];
            return (
              <div
                className="bg-white rounded-lg border p-6 mb-4"
                key={`reason-${reason.id}`}
              >
                <TypographyComponent
                  variant="p"
                  className="text-neutral-800 mb-4"
                  text={`${date} -${time}`}
                />
                <TypographyComponent
                  variant="p"
                  className="text-neutral-950"
                  text={reason.rejected_reason}
                />
              </div>
            );
          })}
        </DefaultCollapse>
      )}

      <TypographyComponent
        text="Materializar convocatoria"
        variant="h2"
        className="text-primary mt-2 font-bold"
      />
      <TypographyComponent
        text="Entremos en acción! llego la hora de preparar la convacatoria. Aquí te recordamos lo que definiste para las actividades de convocatoria."
        variant="p"
        className="text-neutral-950 mt-4 mb-4"
      />

      <DefaultBadge
        label={`${
          executeMaterialize.rejected_reasons.length > 0
            ? 'Fecha limite para enviar ajustes en material de la convocatoria:'
            : 'Fecha limite para enviar material de la convocatoria:'
        } ${textForLimitDate.text}`}
        variant={textForLimitDate.colorBadge}
        type="light"
        className="mt-6"
      />

      <Accordion allowZeroExpanded>
        <div className="rounded mt-8 bg-neutral-400 border-2">
          <AccordionItem>
            <div className="flex items-center justify-between py-2 px-6">
              <TypographyComponent
                variant="h3"
                text="Definición de la convocatoria"
                className="flex-grow text-primary font-bold my-4"
              />
              <StyledContentButtons>
                <AccordionItemState>
                  {({ expanded }) => (
                    <AccordionItemButton>
                      <div
                        className={`icon-arrow-down text-xxs text-neutral-900 ${
                          expanded && 'transform rotate-180'
                        }`}
                      />
                    </AccordionItemButton>
                  )}
                </AccordionItemState>
              </StyledContentButtons>
            </div>
            <AccordionItemPanel className="pb-4 pt-0 px-6">
              <TypographyComponent
                text={
                  <label>
                    <span className="text-primary font-bold">
                      Métodos definidos:{' '}
                    </span>
                    {_.map(
                      executeMaterialize.definition.convocation_method,
                      (m) => CONVOCATION_METHODS[m]
                    ).join(', ')}
                  </label>
                }
                variant="p"
                className="text-neutral-950 mt-2"
              />
              <TypographyComponent
                text={
                  <label>
                    <span className="text-primary font-bold">
                      Descripción de la convocatoria:{' '}
                    </span>
                    {executeMaterialize.definition.convocation_description}
                  </label>
                }
                variant="p"
                className="text-neutral-950 mt-2 rounded"
              />
              <div className="border px-4 py-4 mt-4">
                <div className="bg-white py-2 px-4 flex justify-between rounded-sm items-center border">
                  <TypographyComponent
                    variant="p"
                    text={getDocumentName({
                      file: executeMaterialize.definition.convocation_file,
                      withExtension: true,
                    })}
                    className="flex-grow text-primary font-bold"
                  />
                  <StyledButtonsActions className="flex items-center">
                    <ButtonComponent
                      text=""
                      className="icon-view"
                      onPress={(e) =>
                        openFile({
                          e,
                          file: executeMaterialize.definition.convocation_file,
                        })
                      }
                    />
                    <ButtonComponent
                      text=""
                      icon={
                        <div className="icon-download text-primary text-lg" />
                      }
                      onPress={(e) =>
                        downloadFile({
                          e,
                          file: executeMaterialize.definition.convocation_file,
                        })
                      }
                    />
                  </StyledButtonsActions>
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        </div>
      </Accordion>

      <StyledDivider />

      <TypographyComponent
        text="Material de apoyo*"
        variant="h3"
        className="text-primary mt-2 font-bold"
      />
      <TypographyComponent
        text='Compártenos toda la información y los archivos necesarios para poder producir el material de apoyo. Recuerda revisar el documento "definición de la convocatoria" que fue aprobado por el socio'
        variant="p"
        className="text-neutral-950 mt-2 mb-4"
      />
      <div>
        {executeMaterialize.files?.length > 0 &&
          _.map(executeMaterialize.files, (file) => (
            <div
              className="bg-white py-2 px-4 flex justify-between rounded-sm items-center"
              key={`file-${file.id}`}
            >
              <TypographyComponent
                variant="p"
                text={getDocumentName({ file, withExtension: true })}
                className="flex-grow text-primary font-bold"
              />
              <StyledButtonsActions className="flex items-center">
                <ButtonComponent
                  text=""
                  className="icon-view"
                  onPress={(e) => openFile({ e, file })}
                />
                {canEditForm && (
                  <ButtonComponent
                    text=""
                    className="icon-delete"
                    onPress={(e) => deleteFile({ e, file })}
                  />
                )}
                {!canEditForm && (
                  <ButtonComponent
                    text=""
                    icon={
                      <div className="icon-download text-primary text-lg" />
                    }
                    onPress={(e) => downloadFile({ e, file })}
                  />
                )}
              </StyledButtonsActions>
            </div>
          ))}
        {canEditForm && (
          <div className="border p-6 mt-4 rounded-lg">
            {executeMaterialize.files?.length < 10 && (
              <Controller
                control={control}
                name="file"
                render={({ field: { onChange, name } }) => {
                  return (
                    <InputFile
                      name={name}
                      accept={'.pdf'}
                      requiredName={
                        <div className="flex items-center">
                          <div className="icon-paper-clip text-primary text-xl font-bold h-10" />
                          <TypographyComponent
                            text="Adjuntar archivo"
                            variant="p"
                            className={`text-primary font-bold ${
                              errors[name] && 'text-error'
                            }`}
                          />
                        </div>
                      }
                      onChange={onChange}
                      className={`w-full mt-2 bg-none mb-4 ${
                        errors[name] && 'border-error'
                      }`}
                      errors={errors}
                    />
                  );
                }}
              />
            )}
          </div>
        )}
      </div>
      {canEditForm && (
        <>
          <div className="flex items-center mt-10 justify-center">
            <ButtonComponent
              text="Enviar al socio ejecutor"
              onPress={handleSubmit(() => setOpenModalApprove(true))}
            />
          </div>

          <DefaultModal
            isActive={openModalApprove}
            classNameContent="max-w-screen-md w-11/12"
            handleClose={() => {
              setOpenModalApprove(false);
            }}
          >
            <TypographyComponent
              text="Enviar material de la convocatoria al Socio Ejecutor"
              variant="h3"
              className="font-bold text-primary mb-1"
            />

            <StyledContentButtonsModal>
              <ButtonComponent
                variant="primary"
                text="Cancelar"
                className="mx-auto w-full lg:w-auto lg:mx-2 "
                mode="outline"
                onPress={() => setOpenModalApprove(false)}
              />
              <ButtonComponent
                variant="primary"
                text="Aceptar"
                className="mx-auto w-full lg:w-auto lg:mx-2"
                onPress={updateEvaluatePilotIntervention}
              />
            </StyledContentButtonsModal>
          </DefaultModal>
        </>
      )}
    </>
  );
};

export default MaterializeConvocatoryArtist;
