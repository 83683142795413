import React from 'react';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

// Components
import Activities from '../../Admin/KnowCommunities/components/Activities';
import MessageAnswers from '../../Admin/KnowCommunities/components/MessageAnswers';

import { StyledContentButtonsModal } from 'styles/tableViews/tableViews.styles';
import { StyledDivider } from 'styles/formViews/formViews.styles';

const ProgramKnowCommunities = () => {
  const { useComponents } = useViews();

  const { useTypographies, useButtons, useCards, useModals } = useComponents();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { CardNotification } = useCards();
  const { ButtonComponent } = useButtons();
  const { DefaultModal } = useModals();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useDeepenKnowledgeCommunity } =
    useAdminInterventionsDashboardControllers();
  const { useAdminDeepenKnowledgeScheduleInfo } = useDeepenKnowledgeCommunity();
  const {
    deepen_execution,
    activities,
    messages,
    confirmationModalFinishOpen,
    openConfirmationModalFinish,
    closeConfirmationModalFinish,
    handleFinishDeepenKnowledge,
  } = useAdminDeepenKnowledgeScheduleInfo();

  return (
    <>
      <CardNotification
        className="w-5/12 mx-auto py-9 px-4 mt-16"
        title={
          <TypographyComponent
            text={deepen_execution?.state?.name}
            variant="h2"
            className="font-bold text-primary icon-watch text-center"
          />
        }
      />
      {_.includes(['sc', 'c'], deepen_execution?.state?.id) && (
        <>
          <StyledDivider />
          <TypographyComponent
            text="Ejecución de la profundización"
            variant="h2"
            className="text-primary mt-2 font-bold"
          />
          <TypographyComponent
            text="Incluye aquí los detalles para tu plan de profundización."
            variant="p"
            className="text-neutral-950 mt-2 mb-8"
          />

          <TypographyWithTooltip
            text="Plan de profundización"
            variant="h3"
            textTooltip="Plan de profundización "
            className="text-primary"
            idTooltip="plan"
            applyFontBold
          />
          <TypographyComponent
            text={
              <label className="text-primary">
                <strong>Comunidades en las que vas a profundizar:</strong>
                <span className="text-neutral-950 ml-1 ">
                  {_.map(deepen_execution?.communities, (c) => c.name).join(
                    ', '
                  )}
                </span>
              </label>
            }
            variant="p"
            className="text-neutral-950 mt-2 mb-4"
          />
          <TypographyComponent
            text={
              <label className="text-primary">
                <strong>¿Qué quieres descubrir?</strong>
                <span className="text-neutral-950 ml-1 ">
                  {deepen_execution.what_to_discover}
                </span>
              </label>
            }
            variant="p"
            className="text-neutral-950 mt-2 mb-4"
          />
          <TypographyComponent
            text={
              <label className="text-primary">
                <strong>Modalidad:</strong>
                <span className="text-neutral-950 ml-1 ">
                  {deepen_execution.deepening_modality?.name}{' '}
                  {deepen_execution.deepening_modality.id === 'r' &&
                    `- ${deepen_execution.deepen_type?.name}`}
                </span>
              </label>
            }
            variant="p"
            className="text-neutral-950 mt-2 mb-10"
          />

          {_.filter(activities, (a) => a.state.id === 'c' && a.date).length >
            0 && (
            <Activities
              activities={_.filter(
                activities,
                (a) => a.state.id === 'c' && a.date
              )}
            />
          )}
          {_.filter(messages, (m) => m.messageRequest).length > 0 && (
            <MessageAnswers
              messages={_.filter(messages, (m) => m.messageRequest)}
              communities={deepen_execution?.communities}
            />
          )}

          {deepen_execution?.state?.id === 'sc' && (
            <div className="flex items-center space-x-2 mt-10 justify-center">
              <ButtonComponent
                text="Finalizar profundización y avanzar a la siguiente etapa"
                onPress={openConfirmationModalFinish}
              />
            </div>
          )}

          <DefaultModal
            isActive={confirmationModalFinishOpen}
            classNameContent="max-w-screen-md w-11/12"
            handleClose={closeConfirmationModalFinish}
          >
            <TypographyComponent
              text={'Finalizar profundización'}
              variant="h3"
              className="font-bold text-primary mb-1"
            />

            <StyledContentButtonsModal>
              <ButtonComponent
                variant="primary"
                text="Cancelar"
                className="mx-auto w-full lg:w-auto lg:mx-2 "
                mode="outline"
                onPress={closeConfirmationModalFinish}
              />
              <ButtonComponent
                variant="primary"
                text="Aceptar"
                className="mx-auto w-full lg:w-auto  lg:mx-2"
                onPress={() => handleFinishDeepenKnowledge({ state: 'c' })}
              />
            </StyledContentButtonsModal>
          </DefaultModal>
        </>
      )}
    </>
  );
};

export default ProgramKnowCommunities;
