//Packages
import React, { lazy, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemHeading,
  AccordionItemState,
} from 'react-accessible-accordion';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useHelpers from 'helpers';

//Components
import RequestDeny from './components/RequestDeny';
import RequestAccept from './components/RequestAccept';
import Queantitative from '../../../../components/Alphanumeric/Quantitative';
import Qualitative from '../../../../components/Alphanumeric/Qualitative';
import Multimedia from '../../../../components/Multimedia';

//Styles
const StyledGroup = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledBodyTab = lazy(() =>
  import('styles/tabs/tabs.styles').then((mod) => ({
    default: mod.StyledBodyTab,
  }))
);

const StyledBackButton = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);
import { StyledTopDetail } from '../../../../Artist/components/Messages/Detail/Detail.styles';

//Assets
import iconArrowCollapsed from 'assets/img/ArrowCollapsed.svg';

const Detail = (props) => {
  const {
    setActiveItem,
    handleResetSelectedDetailMessage,
    selectedMessage,
    onFormatDefaultValueCommunities,
    optionsCommunities,
    formAcceptMessage,
    optionsContactLists,
    minDateToSelect,
    handleAcceptMessageAdmin,
    formDenyMessage,
    handleRejectMessageAsAdmin,
    setNumberOptions,
    numberOptions,
    setValidateOptionsRules,
    handleAddOptions,
    handleDeleteOption,
    handleOnChangeOptions,
    dataCollectionInformationAnswersSelected,
    handleChangePageAnswers,
    onFormatMessageAboutCommunities,
    handleDownloadFileFromCollectionInformation,
  } = props;

  const { useComponents } = useViews();
  const { useTypographies, useInputs, useButtons, useImages, useBadges } =
    useComponents();

  const { TypographyComponent } = useTypographies();
  const { Select, InputForm, TextArea } = useInputs();
  const { ButtonComponent } = useButtons();
  const { Image } = useImages();
  const { DefaultBadge } = useBadges();

  const [activeDeny, setActiveDeny] = useState({ deny: false, accept: false });
  const [isExpandAll, setExpandAll] = useState(true);

  const { useQuickFunctions } = useHelpers();
  const { getYearMonthDayFromDate } = useQuickFunctions();

  const handleOnChange = () => {
    setExpandAll(undefined);
  };

  const { control, setValue, reset } = formAcceptMessage;

  useEffect(() => {
    setValue('type', {
      label: selectedMessage?.type?.name,
      value: selectedMessage?.type?.id,
    });

    setValue('channel', {
      label: selectedMessage?.channel?.name,
      value: selectedMessage?.channel?.id,
    });

    setValue(
      'communities',
      onFormatDefaultValueCommunities({
        listOfCommunities: selectedMessage?.communities,
      })
    );

    setValue('message', _.split(selectedMessage.message, '\n1')?.[0]);
    setValue('objetive', selectedMessage.objetive);
    setValue('recommendations', selectedMessage.recommendations);

    selectedMessage.gi_type?.id &&
      setValue('gi_type', {
        label: selectedMessage.gi_type.name,
        value: selectedMessage.gi_type.id,
      });
    selectedMessage.type_information?.id &&
      setValue('type_information', {
        label: selectedMessage.type_information.name,
        value: selectedMessage.type_information.id,
      });

    if (selectedMessage.options?.length > 0) {
      const requestArtistOptions = _.map(
        selectedMessage.options,
        (option, idx) => {
          return { id: idx + 1, value: option.text };
        }
      );
      const requestArtistOptionsAux = _.map(
        selectedMessage.options,
        (option) => option.text
      );
      setNumberOptions(requestArtistOptions);
      setValue('options', requestArtistOptionsAux);
    } else {
      setNumberOptions([{ id: Date.now(), value: '' }]);
    }
  }, [selectedMessage?.id]);

  useEffect(() => {
    return () => {
      reset();
      handleResetSelectedDetailMessage();
    };
  }, []);

  useEffect(() => {
    (selectedMessage?.state?.id === 'r' ||
      selectedMessage?.state?.id === 's') &&
      setExpandAll(false);
  }, [selectedMessage]);

  return (
    <>
      <StyledTopDetail>
        <StyledBackButton
          className="pl-1"
          onClick={() => {
            formAcceptMessage.reset();
            formDenyMessage.reset();
            setActiveItem((prevValue) => ({
              ...prevValue,
              idSelectedMessage: '',
              active: 0,
            }));
          }}
        />
      </StyledTopDetail>
      <Accordion allowZeroExpanded preExpanded={[0]} onChange={handleOnChange}>
        <AccordionItem key={0} uuid={0} dangerouslySetExpanded={isExpandAll}>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div className="flex items-center justify-start mt-6 space-x-4">
                <TypographyComponent
                  text="Solicitud del artista"
                  variant="h2"
                  className="text-primary font-bold"
                />
                <AccordionItemState>
                  {({ expanded }) => (
                    <Image
                      srcImage={iconArrowCollapsed}
                      className={expanded ? 'transform rotate-180' : ''}
                    />
                  )}
                </AccordionItemState>
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <TypographyComponent
              text="El artista desea mandar esta comunicación a la comunidad. Toma el tiempo de revisar este mensaje y asegúrate de que cumpla con las normas de comunicación del programa.
              Tienes la opción de aceptar y enviar el mensaje o rechazar la solicitud y pedir cambios."
              variant="p"
              className="text-neutral-950 mt-2 mb-4"
            />
            <form className="mt-4 space-y-4">
              <StyledGroup>
                <Select
                  name="type"
                  id="type"
                  placeholder="Seleccionar"
                  defaultValue={{
                    label: selectedMessage?.type?.name,
                    value: selectedMessage?.type?.id,
                  }}
                  label="Tipo de solicitud "
                  control={control}
                  disabled
                />
              </StyledGroup>
              <StyledGroup>
                <InputForm
                  name="objetive"
                  label="Objetivo del mensaje"
                  type="text"
                  className="w-full col-span-2"
                  defaultValue={selectedMessage?.objective}
                  disabled
                />
              </StyledGroup>
              {selectedMessage.type?.id === 'gi' && (
                <StyledGroup noSpace className="mt-4">
                  <div>
                    <Select
                      name="gi_type"
                      id="gi_type"
                      placeholder="Seleccionar"
                      defaultValue={{
                        label: selectedMessage?.gi_type?.name,
                        value: selectedMessage?.gi_type?.id,
                      }}
                      label="Tipo de recolección"
                      control={control}
                      disabled
                    />
                  </div>
                  {selectedMessage.gi_type?.id === 'a' && (
                    <div>
                      <Select
                        name="type_information"
                        id="type_information"
                        placeholder="Seleccionar"
                        defaultValue={{
                          label: selectedMessage?.type_information?.name,
                          value: selectedMessage?.type_information?.id,
                        }}
                        label="Tipo de información a recolectar"
                        control={control}
                        disabled
                      />
                    </div>
                  )}
                </StyledGroup>
              )}
              <StyledGroup noSpace>
                <Select
                  name="channel"
                  id="canal"
                  placeholder="Seleccionar"
                  label="Canal de comunicación recomendado"
                  control={control}
                  defaultValue={{
                    label: selectedMessage?.channel?.name,
                    value: selectedMessage?.channel?.id,
                  }}
                  disabled
                />
                <Select
                  name="communities"
                  id="desti"
                  placeholder="Seleccionar"
                  label="Destino del mensaje"
                  control={control}
                  options={optionsCommunities}
                  defaultValue={onFormatDefaultValueCommunities({
                    listOfCommunities: selectedMessage?.communities,
                  })}
                  disabled
                  multiple
                />
              </StyledGroup>
              <StyledGroup>
                <InputForm
                  name="recommendations"
                  label="Recomendaciones al enviar el mensaje"
                  type="text"
                  placeholder="Recomendaciones al enviar el mensaje"
                  className="w-full col-span-2"
                  defaultValue={selectedMessage?.recommendations}
                  disabled
                />
              </StyledGroup>
              <StyledGroup>
                <TextArea
                  name="message"
                  className="w-full col-span-2"
                  label={`${
                    selectedMessage.gi_type?.id === 'a' ||
                    selectedMessage.type?.id === 'd'
                      ? 'Mensaje'
                      : 'Pregunta o mensaje'
                  }`}
                  defaultValue={_.split(selectedMessage.message, '\n1')?.[0]}
                  disabled
                />
              </StyledGroup>
              {selectedMessage.type_information?.id === 'qn' &&
                selectedMessage.gi_type?.id === 'a' && (
                  <div className="flex items-center justify-between space-x-6 ">
                    <div className="flex-1">
                      {_.map(selectedMessage.options, (option, index) => {
                        return (
                          <div
                            className="flex items-center space-x-6"
                            key={option.id}
                          >
                            <TypographyComponent
                              variant="p"
                              text={`Opción ${index + 1}`}
                              className="text-primary font-bold flex-none"
                            />
                            <InputForm
                              key={`options[${index}]`}
                              name={`options[${index}]`}
                              label=""
                              type="text"
                              placeholder={`Ingresa la opción ${index + 1}`}
                              className="flex-1"
                              defaultValue={option.text}
                              disabled
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              {!activeDeny.deny && !activeDeny.accept && (
                <div className="flex items-center justify-center space-x-6">
                  {selectedMessage?.state?.id === 'p' && (
                    <>
                      <AccordionItemButton>
                        <ButtonComponent
                          variant="primary"
                          mode="outline"
                          className={`mt-4 shadow-none ${
                            selectedMessage?.state?.id !== 'p' &&
                            'cursor-not-allowed'
                          }`}
                          text="Rechazar solicitud"
                          onPress={(e) => {
                            e.preventDefault();
                            setActiveDeny({
                              ...activeDeny,
                              deny: true,
                              accept: false,
                            });
                            setExpandAll(false);
                          }}
                          disabled={selectedMessage?.state?.id !== 'p'}
                        />
                      </AccordionItemButton>

                      <ButtonComponent
                        variant="primary"
                        className={`mt-4 ${
                          selectedMessage?.state?.id !== 'p' &&
                          'cursor-not-allowed'
                        }`}
                        text="Aceptar solicitud"
                        onPress={(e) => {
                          e.preventDefault();
                          setActiveDeny({
                            ...activeDeny,
                            deny: false,
                            accept: true,
                          });
                          setExpandAll(false);
                        }}
                        disabled={selectedMessage?.state?.id !== 'p'}
                      />
                    </>
                  )}
                </div>
              )}
            </form>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>

      {(selectedMessage?.state?.id === 'r' ||
        selectedMessage?.state?.id === 's') && (
        <>
          <div className="mb-2 border-t pt-4 mt-4">
            <TypographyComponent
              text={
                <div className="flex items-center">
                  <span className="font-bold text-primary inline-block mr-2">
                    Mensaje
                  </span>
                  <DefaultBadge
                    label={
                      selectedMessage?.state?.id === 'r'
                        ? 'Rechazado'
                        : 'Enviado'
                    }
                    type="light"
                    variant={
                      selectedMessage?.state?.id === 'r' ? 'error' : 'success'
                    }
                  />
                </div>
              }
              variant="h2"
            />
          </div>
          <p className="text-neutral-800 mb-10">
            Fecha de {selectedMessage?.state?.id === 'r' ? 'rechazo' : 'envío'}{' '}
            del mensaje:
            <strong>
              {' '}
              {getYearMonthDayFromDate({
                dateInString:
                  selectedMessage?.state?.id === 'r'
                    ? selectedMessage?.rejected_date
                    : selectedMessage?.sended_date,
                format: 'dmyText',
              })}{' '}
            </strong>
          </p>
        </>
      )}

      {selectedMessage?.state?.id === 'r' && (
        <div className="mb-4">
          <TypographyComponent
            text={
              <>
                <span className="font-bold text-primary inline-block mr-1">
                  Motivo del rechazo:
                </span>
                {selectedMessage?.rejected_cause}
              </>
            }
            variant="p"
          />
        </div>
      )}

      {selectedMessage?.state?.id === 's' && (
        <>
          <div className="mb-4">
            <TypographyComponent
              text={
                <>
                  <span className="font-bold text-primary inline-block mr-1">
                    Tipo de solicitud:
                  </span>
                  {selectedMessage?.type?.name}
                </>
              }
              variant="p"
            />
          </div>

          <div className="mb-4">
            <TypographyComponent
              text={
                <>
                  <span className="font-bold text-primary inline-block mr-1">
                    Objetivo del mensaje:
                  </span>
                  {selectedMessage?.objective}
                </>
              }
              variant="p"
            />
          </div>
          {selectedMessage?.gi_type?.name && (
            <div className="mb-4">
              <TypographyComponent
                text={
                  <>
                    <span className="font-bold text-primary inline-block mr-1">
                      Tipo de recolección:
                    </span>
                    {selectedMessage?.gi_type?.name}
                  </>
                }
                variant="p"
              />
            </div>
          )}
          {selectedMessage?.gi_type?.id === 'a' && (
            <div className="mb-4">
              <TypographyComponent
                text={
                  <>
                    <span className="font-bold text-primary inline-block mr-1">
                      Tipo de información a recolectar:
                    </span>
                    {selectedMessage?.type_information?.name}
                  </>
                }
                variant="p"
              />
            </div>
          )}
          {selectedMessage?.type_information?.id !== 'qn' && (
            <div className="mb-4">
              <TypographyComponent
                text={
                  <>
                    <span className="font-bold text-primary inline-block mr-1">
                      Canal de comunicación:
                    </span>
                    {selectedMessage?.channel?.name}
                  </>
                }
                variant="p"
              />
            </div>
          )}

          <div className="mb-4">
            <TypographyComponent
              text={
                <>
                  <span className="font-bold text-primary inline-block mr-1">
                    Destino del mensaje:
                  </span>
                  {onFormatMessageAboutCommunities({
                    message: selectedMessage,
                  })}
                </>
              }
              variant="p"
            />
          </div>

          {selectedMessage.type.id !== 'gi' ? (
            <>
              <div className="mb-4">
                <TypographyComponent
                  text={
                    <>
                      <span className="font-bold text-primary inline-block mr-1">
                        Mensaje:
                      </span>
                      {selectedMessage.message}
                    </>
                  }
                  variant="p"
                />
              </div>

              {selectedMessage.state?.id === 's' && (
                <Queantitative
                  dataCollectionInformationSelected={selectedMessage}
                />
              )}
            </>
          ) : (
            <div className="border rounded mt-4 bg-neutral-400 px-8 py-6">
              <TypographyComponent
                text="Seguimiento a la recolección de información"
                variant="h3"
                className="font-bold text-primary mt-4 "
              />

              <TypographyComponent
                text="Tu mensaje ya fue enviado, estamos a la espera de las respuestas de la comunidad."
                variant="p"
                className="text-neutral-900  mt-4 "
              />

              <div className="mb-4 mt-8">
                <TypographyComponent
                  text={
                    <>
                      <span className="font-bold text-primary block mr-1 ">
                        {selectedMessage.gi_type.id === 'm'
                          ? 'Pregunta o mensaje'
                          : 'Pregunta'}
                      </span>
                      <span className="font-bold text-lg">
                        {_.split(selectedMessage.message, '\n1')?.[0]}
                      </span>
                    </>
                  }
                  variant="p"
                />
              </div>

              {selectedMessage.gi_type?.id !== 'm' ? (
                <>
                  {selectedMessage.type_information?.id === 'qn' ? (
                    <Queantitative
                      dataCollectionInformationSelected={selectedMessage}
                      dataCollectionInformationAnswersSelected={
                        dataCollectionInformationAnswersSelected
                      }
                    />
                  ) : (
                    <Qualitative
                      totalAnswers={Number(selectedMessage.result?.replied)}
                      dataCollectionInformationAnswersSelected={
                        dataCollectionInformationAnswersSelected
                      }
                      handleChangePageAnswers={handleChangePageAnswers}
                    />
                  )}
                </>
              ) : (
                <Multimedia
                  totalAnswers={Number(selectedMessage.result?.replied)}
                  dataCollectionInformationAnswersSelected={
                    dataCollectionInformationAnswersSelected
                  }
                  handleChangePageAnswers={handleChangePageAnswers}
                  handleDownloadFileFromCollectionInformation={({
                    fileId,
                    nameFile,
                    extension,
                  }) =>
                    handleDownloadFileFromCollectionInformation({
                      fileId,
                      nameFile,
                      messageRequestId: selectedMessage.id,
                      extension,
                    })
                  }
                />
              )}
            </div>
          )}
        </>
      )}

      <StyledBodyTab active={activeDeny.deny}>
        {activeDeny.deny && (
          <RequestDeny
            setActiveDeny={setActiveDeny}
            setExpandAll={setExpandAll}
            activeDeny={activeDeny}
            formDenyMessage={formDenyMessage}
            handleRejectMessageAsAdmin={handleRejectMessageAsAdmin}
          />
        )}
      </StyledBodyTab>
      <StyledBodyTab active={activeDeny.accept}>
        {activeDeny.accept && (
          <RequestAccept
            formAcceptMessage={formAcceptMessage}
            setActiveDeny={setActiveDeny}
            setExpandAll={setExpandAll}
            activeDeny={activeDeny}
            onFormatDefaultValueCommunities={onFormatDefaultValueCommunities}
            selectedMessage={selectedMessage}
            optionsCommunities={optionsCommunities}
            optionsContactLists={optionsContactLists}
            minDateToSelect={minDateToSelect}
            handleAcceptMessageAdmin={handleAcceptMessageAdmin}
            numberOptions={numberOptions}
            setValidateOptionsRules={setValidateOptionsRules}
            handleAddOptions={handleAddOptions}
            handleDeleteOption={handleDeleteOption}
            handleOnChangeOptions={handleOnChangeOptions}
          />
        )}
      </StyledBodyTab>
    </>
  );
};

Detail.propTypes = {
  setActiveItem: PropTypes.func.isRequired,
  handleResetSelectedDetailMessage: PropTypes.func,
  selectedMessage: PropTypes.object,
  onFormatDefaultValueCommunities: PropTypes.func,
  formAcceptMessage: PropTypes.object,
  formDenyMessage: PropTypes.object,
  optionsCommunities: PropTypes.array,
  optionsContactLists: PropTypes.array,
  minDateToSelect: PropTypes.string,
  handleAcceptMessageAdmin: PropTypes.func,
  handleRejectMessageAsAdmin: PropTypes.func,
  setNumberOptions: PropTypes.func,
  numberOptions: PropTypes.array,
  setValidateOptionsRules: PropTypes.func,
  handleAddOptions: PropTypes.func,
  handleDeleteOption: PropTypes.func,
  handleOnChangeOptions: PropTypes.func,
  dataCollectionInformationAnswersSelected: PropTypes.object,
  handleChangePageAnswers: PropTypes.func,
  onFormatMessageAboutCommunities: PropTypes.func,
  handleDownloadFileFromCollectionInformation: PropTypes.func,
};

Detail.defaultProps = {
  handleResetSelectedDetailMessage: () => {},
  selectedMessage: {},
  onFormatDefaultValueCommunities: () => {},
  formAcceptMessage: {},
  formDenyMessage: {},
  optionsCommunities: [],
  optionsContactLists: [],
  minDateToSelect: '',
  handleAcceptMessageAdmin: () => {},
  handleRejectMessageAsAdmin: () => {},
  setNumberOptions: () => {},
  numberOptions: [],
  setValidateOptionsRules: () => {},
  handleAddOptions: () => {},
  handleDeleteOption: () => {},
  handleOnChangeOptions: () => {},
  dataCollectionInformationAnswersSelected: {},
  handleChangePageAnswers: () => {},
  onFormatMessageAboutCommunities: () => {},
  handleDownloadFileFromCollectionInformation: () => {},
};

export default Detail;
