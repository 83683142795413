//Packages
import React, { lazy } from 'react';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';

//Styles
const StyledGroup = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const RequestDeny = (props) => {
  const {
    setActiveDeny,
    setExpandAll,
    activeDeny,
    formDenyMessage,
    handleRejectMessageAsAdmin,
  } = props;
  const { useComponents } = useViews();

  const { useTypographies, useInputs, useButtons } = useComponents();

  const { TypographyComponent } = useTypographies();
  const { TextArea } = useInputs();
  const { ButtonComponent } = useButtons();

  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
  } = formDenyMessage;

  return (
    <>
      <TypographyComponent
        text="Rechazar solicitud"
        variant="h2"
        className="text-primary mt-6 font-bold border-t pt-4"
      />
      <TypographyComponent
        text="Escribe los comentarios necesarios para que el solicitante pueda adaptar su mensaje, o dejale saber si no lo consideras necesario.        "
        variant="p"
        className="text-neutral-950 mt-2 mb-4"
      />
      <StyledGroup>
        <TextArea
          name="rejected_cause"
          placeholder="Razones para rechazar la solicitud"
          className="w-full col-span-2"
          label=""
          errors={errors}
          {...register('rejected_cause')}
        />
      </StyledGroup>
      <div className="flex items-center justify-between space-x-6">
        <ButtonComponent
          variant="primary"
          mode="outline"
          className="mt-4 shadow-none border-none"
          text="Cancelar"
          onPress={(e) => {
            reset();
            e.preventDefault();
            setActiveDeny({ ...activeDeny, deny: false, accept: false });
            setExpandAll(true);
          }}
        />

        <ButtonComponent
          variant="primary"
          className="mt-4"
          text="Enviar comentarios al artista"
          icon={<div className="icon-send text-lg h-6 flex" />}
          onPress={handleSubmit(handleRejectMessageAsAdmin)}
        />
      </div>
    </>
  );
};

RequestDeny.propTypes = {
  setActiveDeny: PropTypes.func,
  setExpandAll: PropTypes.func,
  activeDeny: PropTypes.object,
  formDenyMessage: PropTypes.object,
  handleRejectMessageAsAdmin: PropTypes.func,
};

RequestDeny.defaultProps = {
  setActiveDeny: () => {},
  setExpandAll: () => {},
  activeDeny: {},
  formDenyMessage: {},
  handleRejectMessageAsAdmin: () => {},
};

export default RequestDeny;
