import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useAdminFestivitiesAndTraditionsProviders = () => {
  const getFestivityAndTraditions = ({ projectId }) => {
    const response = axios({
      method: 'GET',
      url: `/projects/${projectId}/festivities`,
    });
    return trackPromise(response);
  };

  const createFestivityAndTraditions = ({ formData, projectId }) => {
    const response = axios({
      method: 'POST',
      headers: {
        'content-type': 'multipart/form-data',
      },
      url: `/projects/${projectId}/festivities`,
      data: formData,
    });
    return trackPromise(response);
  };

  const updateFestivityAndTraditions = ({
    formData,
    projectId,
    festivityId,
  }) => {
    const response = axios({
      method: 'POST',
      url: `/projects/${projectId}/festivities/${festivityId}`,
      data: formData,
    });
    return trackPromise(response);
  };

  const deleteFestivityAndTraditions = ({ projectId, festivityId }) => {
    const response = axios({
      method: 'DELETE',
      url: `/projects/${projectId}/festivities/${festivityId}`,
    });
    return trackPromise(response);
  };

  return {
    getFestivityAndTraditions,
    createFestivityAndTraditions,
    updateFestivityAndTraditions,
    deleteFestivityAndTraditions,
  };
};

export default useAdminFestivitiesAndTraditionsProviders;
