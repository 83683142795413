//Hooks
import useStrings from '../../../../strings';

const useModalsActions = () => {
  //strings
  const { useGeneralTypes } = useStrings();
  const { useModalsTypes } = useGeneralTypes();
  const { HIDE_MODAL_UPLOAD_PROFILE_PHOTO, OPEN_MODAL_UPLOAD_PROFILE_PHOTO } =
    useModalsTypes();

  const actShowModalUploadProfilePhoto =
    ({ blobImage, name, type }) =>
    (dispatch) => {
      dispatch({
        type: OPEN_MODAL_UPLOAD_PROFILE_PHOTO,
        payload: { blobImage, name, type },
      });
    };

  const actHideModalUploadProfilePhoto = () => (dispatch) => {
    dispatch({
      type: HIDE_MODAL_UPLOAD_PROFILE_PHOTO,
    });
  };

  return {
    actShowModalUploadProfilePhoto,
    actHideModalUploadProfilePhoto,
  };
};
export default useModalsActions;
