//Packages
import React, { Suspense, useState } from 'react';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Components
import DropDownFilter from './components/DropDownFilter';

//Styles
import {
  StyledTopFilters,
  StyledFilters,
  StyledInputSearchContent,
  StyledContentButtons,
  StyledContentButtonsModal,
} from 'styles/tableViews/tableViews.styles';

const ContactRoot = () => {
  const { useComponents } = useViews();
  const {
    useButtons,
    useModals,
    useTypographies,
    useInputs,
    useTables,
    useLoaders,
  } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { InputSearch } = useInputs();
  const { ButtonComponent } = useButtons();
  const { DefaultModal } = useModals();
  const { TypographyComponent } = useTypographies();
  const { TableCustom } = useTables();

  const [activeModal, setActiveModal] = useState({ active: false });
  const [isOnFocusTextSearch, setIsOnFocusTextSearch] = useState(false);
  const [activeDropDown, setActiveDropDown] = useState(false);

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useUserAdmin } = useAdmin();
  const { useUser } = useUserAdmin();
  const {
    isFilterEmpty,
    history,
    users,
    deleteUser,
    setTextSearch,
    getUsersPage,
    defaultValueTextSearch,
    current_page,
    dispatchContacts,
    handleOrderBy,
  } = useUser(true);

  const data = React.useMemo(() => {
    return [
      ..._.map(users.data, (user) => {
        return {
          id: user.id,
          name: user.name,
          last_name: user.last_name,
          email: user.email,
          role: user.role_name,
          country_name: user.country_name,
          departament_name: user.departament_name,
          city_name: user.city_name,
          community: user.community?.name,
          phone_complete: user.phone_complete,
          gender: user.gender,

          whatsapp: user.has_whatsapp ? 'Sí' : 'No',
          col14: (
            <StyledContentButtons>
              <ButtonComponent
                text=""
                className="icon-edit"
                onPress={() => {
                  history.push(`/admin/contacts/edit/${user.id}`);
                }}
              />
              <ButtonComponent
                text=""
                className="icon-delete"
                onPress={() => {
                  setActiveModal({ active: true, user: user.id });
                }}
              />
            </StyledContentButtons>
          ),
        };
      }),
    ];
  }, [current_page, users.data]);

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Nombre',
        accessor: 'name',
        sticky: 'left',
        width: 150,
        orderByKey: 'name',
      },
      {
        Header: 'Apellido',
        accessor: 'last_name',
        sticky: 'left',
        width: 120,
        orderByKey: 'last_name',
      },
      {
        Header: 'Correo electrónico',
        accessor: 'email',
        width: 250,
        orderByKey: 'email',
      },
      {
        Header: 'Rol',
        accessor: 'role',
        orderByKey: 'role',
      },
      {
        Header: 'País',
        accessor: 'country_name',
        orderByKey: 'country',
      },
      {
        Header: 'Depto/Estado',
        accessor: 'departament_name',
        orderByKey: 'departament',
      },
      {
        Header: 'Cdad/Mpio',
        accessor: 'city_name',
        width: 150,
        orderByKey: 'city',
      },
      {
        Header: 'Comunidad',
        accessor: 'community',
        width: 250,
        orderByKey: 'community',
      },
      {
        Header: 'Teléfono',
        accessor: 'phone_complete',
        width: 200,
        orderByKey: 'phone',
      },
      {
        Header: 'Género',
        accessor: 'gender',
        orderByKey: 'gender',
      },

      {
        Header: 'WhatsApp',
        accessor: 'whatsapp',
        width: 150,
        orderByKey: 'whatsapp',
        noneOrder: true,
      },
      {
        Header: '',
        accessor: 'col14',
        sticky: 'right',
        width: 100,
      },
    ];
  }, []);

  const handleOpenMenu = () => {
    setActiveDropDown(!activeDropDown);
  };

  return (
    <Suspense fallback={<DefaultLoader />}>
      <>
        <StyledTopFilters className="pt-6">
          <ButtonComponent
            text="Crear nuevo contacto"
            icon={<div className="icon-close transform rotate-45 text-sm" />}
            onPress={() => {
              history.push('/admin/contacts/create');
            }}
          />
          {!(!isFilterEmpty && users.data?.length === 0) && (
            <StyledFilters className="relative">
              <ButtonComponent
                text="Filtros"
                mode="outline"
                className="icon-filter flex items-center space-x-2"
                onPress={handleOpenMenu}
              />
              <StyledInputSearchContent>
                <InputSearch
                  text={defaultValueTextSearch}
                  onDebounce={setTextSearch}
                  handlerIsOnFocus={setIsOnFocusTextSearch}
                />
              </StyledInputSearchContent>
              <DropDownFilter
                open={activeDropDown}
                isOnFocusTextSearch={isOnFocusTextSearch}
                handleOpenMenu={handleOpenMenu}
              />
            </StyledFilters>
          )}
        </StyledTopFilters>
        <TableCustom
          columns={columns}
          data={data}
          meta={users.meta}
          pagination={getUsersPage}
          setDataSelected={(data) =>
            dispatchContacts({ payload: _.map(data, (row) => row.id) })
          }
          setOrderData={handleOrderBy}
          forcePage={current_page - 1}
          buttonEmptyState={
            !isFilterEmpty ? (
              <ButtonComponent
                className="mx-auto"
                text="Crear nuevo contacto"
                icon={
                  <div className="icon-close transform rotate-45 text-sm" />
                }
                onPress={() => {
                  history.push('/admin/contacts/create');
                }}
              />
            ) : (
              <></>
            )
          }
          titleEmptyState="No se han encontrado contactos ¡Crea el primero!"
        />
      </>

      <DefaultModal
        isActive={activeModal.active}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={() => {
          setActiveModal({ active: false });
        }}
      >
        <TypographyComponent
          text="Eliminar usuario"
          variant="h3"
          className="font-bold text-primary mb-1"
        />
        <TypographyComponent
          text="Esta acción es definitiva. Presiona ELIMINAR para continuar"
          variant="p"
          className="text-neutral-950  mb-1"
        />
        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto lg:mx-2 "
            mode="outline"
            onPress={() => {
              setActiveModal({ active: false });
            }}
          />
          <ButtonComponent
            variant="primary"
            text="Eliminar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={() => {
              deleteUser(activeModal.user);
              setActiveModal({ active: false });
            }}
          />
        </StyledContentButtonsModal>
      </DefaultModal>
    </Suspense>
  );
};

export default ContactRoot;
