//Packages
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';

//Hooks
import useStrings from 'strings';
import useHelpers from 'helpers';
import useControllers from 'controllers';
import useApi from 'api';

const useAdminCreateProject = () => {
  const history = useHistory();

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const {
    REQUIRED_FIELD,
    FILE_SIZE,
    FILE_TYPE,
    NUMBER_NOT_VALID,
    ARRAY_MIN_1_LENGTH,
    ARRAY_MAX_3_LENGTH,
    MAX_LENGTH,
    MAX_10M_POPULATION_NUMBER,
    POSITIVE_NUMBER,
    INTEGER_NUMBER,
  } = useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const { getFileValidations } = useQuickFunctions();
  const { MAX_SIZE, SUPPORTED_FORMATS_ONLY_IMAGES } = getFileValidations();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminProjectsRootActions } = useAdminProjectsActions();
  const { actCreateProject } = useAdminProjectsRootActions();

  const schemaCreateCommunity = yup.object({
    name: yup.string().required(REQUIRED_FIELD),
    description: yup.string().required(REQUIRED_FIELD).max(40000, MAX_LENGTH),
    country: yup.object().required(REQUIRED_FIELD),
    department: yup.object().required(REQUIRED_FIELD).nullable(),
    city_id: yup.object().required(REQUIRED_FIELD).nullable(),
    population_number: yup
      .number(NUMBER_NOT_VALID)
      .typeError(REQUIRED_FIELD)
      .positive(POSITIVE_NUMBER)
      .integer(INTEGER_NUMBER)
      .max(10000000, MAX_10M_POPULATION_NUMBER)
      .required(REQUIRED_FIELD),
    languages: yup
      .array()
      .of(yup.object())
      .min(1, ARRAY_MIN_1_LENGTH)
      .max(3, ARRAY_MAX_3_LENGTH)
      .required(REQUIRED_FIELD),
    image: yup
      .mixed()
      .nullable()
      .test('fileType', FILE_TYPE, (value) => {
        if (value) {
          return SUPPORTED_FORMATS_ONLY_IMAGES.includes(value.type);
        }
        return true;
      })
      .test('fileSize', FILE_SIZE, (value) => {
        if (value) {
          return value.size <= MAX_SIZE;
        }
        return true;
      }),
    project_manager_id: yup.object().nullable(),
    social_expert_id: yup.object().nullable(),
    executing_partner_id: yup.object().nullable(),
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schemaCreateCommunity),
    mode: 'onChange',
  });

  const countrySelected = watch('country');
  const departmentSelected = watch('department');

  const { useGeneralHooks } = useControllers();
  const { useWhereLive, useGetOptionsList } = useGeneralHooks();
  const {
    optionsCountries,
    optionsDepartments,
    optionsCities,
    optionsLanguages,
  } = useWhereLive({
    countrySelected,
    departmentSelected,
    languageWithId: true,
  });
  const { optionsAdminUsers, optionsPartnerUsers } = useGetOptionsList({
    callEndpointUsers: true,
  });

  useEffect(() => {
    setValue('city_id', null);
  }, [departmentSelected]);

  useEffect(() => {
    setValue('department', null);
    setValue('city_id', null);
  }, [countrySelected]);

  const onSuccessHandleRegisterProject = () => {
    history.push('/admin/projects');
  };

  const handleRegisterProject = (data) => {
    delete data.country;
    delete data.department;
    dispatch(actCreateProject(data, onSuccessHandleRegisterProject));
  };

  const goBack = () => history.goBack();

  return {
    errors,
    control,
    register,
    handleSubmit,
    handleRegisterProject,
    optionsCountries,
    optionsDepartments,
    optionsCities,
    optionsLanguages,
    optionsAdminUsers,
    optionsPartnerUsers,
    countrySelected,
    departmentSelected,
    goBack,
  };
};

export default useAdminCreateProject;
