import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';

// Styles
import {
  StyledWrapperCommunity,
  StyledCardCommunity,
} from '../../../../../../../../../Dashboard.styles';
import { StyledButtonsActions } from 'styles/buttons/buttons.styles';

const CriteriaEvaluation = (props) => {
  const {
    control,
    errors,
    deleteCriteriaFile,
    openCriteriaFile,
    downloadCriteriaFile,
    canEditForm,
    watchForm,
    currentFile,
  } = props;

  const { useComponents } = useViews();
  const { useTypographies, useButtons, useInputs } = useComponents();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { ButtonComponent } = useButtons();
  const { InputFile } = useInputs();

  return (
    <>
      <TypographyWithTooltip
        text="Criterios de evaluación*"
        variant="h3"
        textTooltip="Criterios de evaluación"
        className="text-primary mt-8"
        idTooltip="creiterios"
        applyFontBold
      />

      <TypographyComponent
        text="El socio ejecutor te ha compatido los criterios claves para tener en cuenta en el momento de diseño y politaje de la intervención. Estos te ayudaron a..."
        variant="p"
        className="text-neutral-950 mt-2"
      />

      <TypographyComponent
        text="1. Diseñar el piloto"
        variant="p"
        className="text-neutral-950 mt-2"
      />
      <TypographyComponent
        text="2. Realizar tu propia evaluación del piloto"
        variant="p"
        className="text-neutral-950 mt-2"
      />
      <TypographyComponent
        text="3. Recibir retroalimentación del socio ejecutor para tener en cuenta al momento de ajustar el diseño."
        variant="p"
        className="text-neutral-950 mt-2"
      />

      {canEditForm && (!watchForm('file') || errors['file']) && (
        <Controller
          control={control}
          name="file"
          render={({ field: { onChange, name } }) => {
            return (
              <InputFile
                name={name}
                accept={'.pdf'}
                title={
                  <div className="flex items-center">
                    <div
                      className={`icon-paper-clip text-primary text-xl font-bold h-10 ${
                        errors[name] && 'text-error'
                      }`}
                    />
                    <TypographyComponent
                      text="Adjuntar archivo"
                      variant="p"
                      className={`text-primary font-bold ${
                        errors[name] && 'text-error'
                      }`}
                    />
                  </div>
                }
                onChange={onChange}
                className={`w-full mt-8 bg-none mb-4 ${
                  errors[name] && 'border-error'
                }`}
                errors={errors}
              />
            );
          }}
        />
      )}

      {(!canEditForm ||
        (watchForm('file') && !errors['file']) ||
        currentFile?.id) && (
        <div className="border rounded mt-8 px-6 pb-8 bg-neutral-300">
          <StyledWrapperCommunity nonemb>
            <StyledCardCommunity>
              <TypographyComponent
                variant="p"
                text="Criterios de evaluación del piloto.pdf"
                className="flex-grow text-primary font-bold cursor-pointer"
              />
              <StyledButtonsActions className="flex items-center">
                <ButtonComponent
                  text=""
                  className="icon-view"
                  onPress={(e) =>
                    openCriteriaFile({ e, watchFile: watchForm('file') })
                  }
                />
                {(!currentFile?.id || watchForm('file')) && (
                  <ButtonComponent
                    text=""
                    className="icon-delete"
                    variant="white"
                    onPress={deleteCriteriaFile}
                  />
                )}
                {currentFile?.id && !watchForm('file') && (
                  <ButtonComponent
                    text=""
                    icon={
                      <div className="icon-download text-primary text-lg" />
                    }
                    onPress={downloadCriteriaFile}
                  />
                )}
              </StyledButtonsActions>
            </StyledCardCommunity>
          </StyledWrapperCommunity>
        </div>
      )}
    </>
  );
};

CriteriaEvaluation.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  hasFile: PropTypes.bool,
  canEditForm: PropTypes.bool,
  currentFile: PropTypes.object,
  deleteCriteriaFile: PropTypes.func,
  openCriteriaFile: PropTypes.func,
  downloadCriteriaFile: PropTypes.func,
  watchForm: PropTypes.func,
};

export default CriteriaEvaluation;
