//Packages
import { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams, useHistory } from 'react-router';
import _ from 'lodash';

//Hooks
import useApi from 'api';
import useModels from 'models';
import useHelpers from 'helpers';
import useStrings from 'strings';
import useControllers from 'controllers';

const useAdminEditInformationIntervention = () => {
  const { id } = useParams();
  const history = useHistory();

  const [goalsArray, setGoalsArray] = useState([]);
  const [filesArray] = useState([
    { id: '', urlFromBackend: '' },
    { id: '', urlFromBackend: '' },
    { id: '', urlFromBackend: '' },
    { id: '', urlFromBackend: '' },
  ]);

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useHomeActions, useAdminProjectsActions } = useAdminActions();
  const { actGetInterventionsInfoForEdit, actResetInterventionsInfoForEdit } =
    useHomeActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminInterventionsRootActions, useAdminViewInterventionsActions } =
    useAdminInterventionsActions();
  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const { useAdminSpreadInterventionActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const { actUploadFileSpreadIntervention, actDeleteFileSpreadIntervention } =
    useAdminSpreadInterventionActions();
  const { actUpdateInterventionInfoFinalProcess } =
    useAdminInterventionsRootActions();

  const { useGeneralHooks } = useControllers();
  const { useToggleModal } = useGeneralHooks();
  const [openModalAtLestOneImage, setOpenModalAtLestOneImage] =
    useToggleModal();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminHomeSelectors } = useAdminSelectors();
  const { getInterventionSelectedToEdit } = useAdminHomeSelectors();

  const interventionSelectedToEdit = useSelector(getInterventionSelectedToEdit);

  const filesFromBackend =
    interventionSelectedToEdit?.final_process?.files.filter(
      (file) => file.url.slice(-4) !== '.pdf'
    );

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const {
    REQUIRED_FIELD,
    FILE_TYPE,
    MAX_LENGHT_100,
    MIN_GOALS,
    MIN_ONE_IMAGE_OR_VIDEO,
    FILE_SIZE_5MB,
  } = useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const { getFileValidations } = useQuickFunctions();
  const { MAX_SIZE_5MB, SUPPORTED_FORMATS } = getFileValidations();

  const filesArrayToShow = useMemo(() => {
    return filesFromBackend.length > 0
      ? _.map(filesArray, (value, index) => {
          return filesFromBackend[index]
            ? {
                id: filesFromBackend[index]?.id,
                urlFromBackend: filesFromBackend[index]?.url,
              }
            : value;
        })
      : filesArray;
  }, [filesFromBackend]);

  useEffect(() => {
    dispatch(actGetInterventionsInfoForEdit({ interventionId: id }));

    return () => {
      dispatch(actResetInterventionsInfoForEdit());
    };
  }, []);

  useEffect(() => {
    setGoalsArray(
      interventionSelectedToEdit?.final_process?.goals
        ? interventionSelectedToEdit?.final_process?.goals.map(
            (goal, index) => ({
              value: goal,
              id: Date.now() + index,
              isEditActive: false,
            })
          )
        : []
    );
  }, [interventionSelectedToEdit.final_process?.goals]);

  const schemaEditIntervention = yup.object({
    goals: yup.array().required(REQUIRED_FIELD).min(1, MIN_GOALS),
    recommendations: yup.string().required(REQUIRED_FIELD),
    tastes: yup.string().required(REQUIRED_FIELD),
    suggestions: yup.string().required(REQUIRED_FIELD),
    information: yup.string().nullable(),
    files: yup
      .array()
      .of(
        yup
          .mixed()
          .nullable()
          .test('fileType', FILE_TYPE, (value) => {
            if (value) {
              return SUPPORTED_FORMATS.includes(value.type);
            }
            return true;
          })
          .test('fileSize', FILE_SIZE_5MB, (value) => {
            if (value) {
              return value.size <= MAX_SIZE_5MB;
            }
            return true;
          })
      )
      .test('minOneItem', MIN_ONE_IMAGE_OR_VIDEO, (value) => {
        return value?.length > 0 || !_.every(watchFiles, (value) => !value);
      }),
  });

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
    watch,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    resolver: yupResolver(schemaEditIntervention),
    mode: 'onChange',
  });

  const watchFiles = watch('files');

  useEffect(() => {
    if (goalsArray.length > 0) {
      const goalsArrayAux = _.map(goalsArray, (goalsValue) => goalsValue.value);
      const goalsArrayEveryEmpty = _.every(
        goalsArrayAux,
        (goalsValue) => !goalsValue
      );

      if (goalsArrayEveryEmpty) {
        setError('goals', { type: 'manual', message: MIN_GOALS });
      } else {
        clearErrors('goals');
      }
    }
  }, [goalsArray]);

  useEffect(() => {
    if (interventionSelectedToEdit.id) {
      reset({
        goals: interventionSelectedToEdit.final_process.goals,
        recommendations:
          interventionSelectedToEdit?.final_process?.recommendations,
        tastes: interventionSelectedToEdit?.final_process?.tastes,
        suggestions: interventionSelectedToEdit?.final_process?.suggestions,
        information: interventionSelectedToEdit?.final_process?.information,
      });
    }
  }, [interventionSelectedToEdit.id]);

  const schemaAddNewGoal = yup.object({
    goalsTwo: yup.string().max(100, MAX_LENGHT_100).required(REQUIRED_FIELD),
  });

  const {
    handleSubmit: handleSubmitGoals,
    control: controlGoals,
    formState: { errors: errorsGoal },
    register: registerGoal,
    watch: watchGoal,
    setValue: setValueGoal,
  } = useForm({
    resolver: yupResolver(schemaAddNewGoal),
    mode: 'onChange',
  });

  const updateInterventionInfoFinalProcess = (data) => {
    const dataToSend = {
      goals: _.map(goalsArray, (goalsValue) => goalsValue.value),
      recommendations: data.recommendations,
      tastes: data.tastes,
      suggestions: data.suggestions,
      information: data.information,
    };

    dispatch(
      actUpdateInterventionInfoFinalProcess(
        {
          interventionId: interventionSelectedToEdit?.id,
          finalProcess: interventionSelectedToEdit?.final_process?.id,
          formData: dataToSend,
        },
        () => {
          history.push('/admin/interventions');
        }
      )
    );
  };

  const deleteGoal = ({ e, goal, arrayIndex }) => {
    e.preventDefault();

    const goalsArrayFiltered = _.filter(goalsArray, (g) => g.id !== goal.id);
    setGoalsArray(goalsArrayFiltered);

    setValue(
      'goals',
      _.filter(watch('goals'), (goalValue, index) => index != arrayIndex)
    );
  };

  const handleGoToInterventionsPage = () => {
    history.push(`admin/interventions`);
  };

  const addNewGoal = (data) => {
    const { goalsTwo } = data;
    setGoalsArray([...goalsArray, { value: goalsTwo, id: Date.now() }]);
    setValueGoal('goalsTwo', '');
    setValue('goals', [...watch('goals'), goalsTwo]);
  };

  const uploadFile = ({ file }) => {
    const data = new FormData();
    data.append('file', file);
    dispatch(
      actUploadFileSpreadIntervention(
        {
          interventionId: id,
          spreadId: interventionSelectedToEdit?.final_process?.id,
          data,
        },
        () => clearErrors('files')
      )
    );
  };

  const deleteFile = (file) => {
    if (
      _.filter(filesArrayToShow, (file) => file.urlFromBackend).length === 1
    ) {
      setOpenModalAtLestOneImage();
    } else {
      dispatch(
        actDeleteFileSpreadIntervention({
          interventionId: id,
          spreadId: interventionSelectedToEdit?.final_process?.id,
          fileId: file.id,
        })
      );
    }
  };

  const handleActiveEditButton = ({ arrayIndex }) => {
    const goalsArrayAux = _.map(goalsArray, (goal, index) => {
      if (arrayIndex === index) {
        setValueGoal('goalsTwo', goal.value);
        return { ...goal, isEditActive: true };
      } else {
        return { ...goal, isEditActive: false };
      }
    });

    setGoalsArray(goalsArrayAux);
  };

  const anyFieldisEditMode = _.find(goalsArray, (goal) => goal.isEditActive);

  const handleSaveGoal = ({ data, arrayIndex }) => {
    const { goalsTwo } = data;

    const goalsArrayAux = _.map(goalsArray, (goal, index) => {
      return arrayIndex === index
        ? { ...goal, value: goalsTwo, isEditActive: false }
        : goal;
    });

    setGoalsArray(goalsArrayAux);
    setValueGoal('goalsTwo', '');
    setValue('goals', [...watch('goals'), goalsTwo]);
  };

  return {
    //data
    interventionSelectedToEdit,
    filesArrayToShow,
    anyFieldisEditMode,
    openModalAtLestOneImage,
    setOpenModalAtLestOneImage,

    //functions
    addNewGoal,
    deleteGoal,
    updateInterventionInfoFinalProcess,
    handleGoToInterventionsPage,
    uploadFile,
    deleteFile,
    handleActiveEditButton,
    handleSaveGoal,

    //rhf
    register,
    control,
    errors,
    goalsArray,
    handleSubmit,
    watchFiles,

    //rhf-goals
    handleSubmitGoals,
    controlGoals,
    errorsGoal,
    registerGoal,
    watchGoal,
  };
};

export default useAdminEditInformationIntervention;
