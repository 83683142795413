import useAdminDefineInterventionInterventions from './admin';
import useArtistDefineInterventionInterventionsControllers from './artist';

const useDefineInterventionInterventions = () => {
  return {
    useAdminDefineInterventionInterventions,
    useArtistDefineInterventionInterventionsControllers,
  };
};

export default useDefineInterventionInterventions;
