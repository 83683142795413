//Packages
import React, { Suspense, lazy } from 'react';

//Assets
import infoIcon from 'assets/img/fake-card-example.jpg';
import iconLocation from 'assets/icons/MapPin.svg';
import imgUserFake from 'assets/img/fakeUserPhotoSociio.jpg';
import iconCollapsed from 'assets/img/ArrowCollapsed.svg';

//Hooks
import useControllers from 'controllers';
import useViews from 'views';

//Styles
const StyledBackButton = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);

const StyledGeneralInformation = lazy(() =>
  import('styles/projects/dashboard.styles').then((mod) => ({
    default: mod.StyledGeneralInformation,
  }))
);

const StyledDashboardInformation = lazy(() =>
  import('styles/projects/dashboard.styles').then((mod) => ({
    default: mod.StyledDashboardInformation,
  }))
);

const StyledDashboardWrapperBadges = lazy(() =>
  import('styles/projects/dashboard.styles').then((mod) => ({
    default: mod.StyledDashboardWrapperBadges,
  }))
);

const StyledWrapperUsers = lazy(() =>
  import('styles/projects/dashboard.styles').then((mod) => ({
    default: mod.StyledWrapperUsers,
  }))
);
const StyledOtherConfigs = lazy(() =>
  import('styles/projects/dashboard.styles').then((mod) => ({
    default: mod.StyledOtherConfigs,
  }))
);

const StylesListStadistics = lazy(() =>
  import('./Root.styles').then((mod) => ({
    default: mod.StylesListStadistics,
  }))
);

const StylesListStadistic = lazy(() =>
  import('./Root.styles').then((mod) => ({
    default: mod.StylesListStadistic,
  }))
);

const AdminDashboardProject = () => {
  const { useComponents } = useViews();
  const {
    useButtons,
    useTypographies,
    useBoxes,
    useImages,
    useBadges,
    useCards,
    useCollapses,
    useLoaders,
  } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { ButtonComponent } = useButtons();
  const { TypographyComponent } = useTypographies();
  const { Box } = useBoxes();
  const { Image } = useImages();
  const { DefaultBadge } = useBadges();
  const { CardUser, CardStadistics } = useCards();
  const { DefaultCollapse } = useCollapses();

  const { useScreenHooks, useGeneralHooks } = useControllers();
  const { useSelectLayoutByRole } = useGeneralHooks();
  const { LayoutSelected, props } = useSelectLayoutByRole();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminRootDashboard } = useAdminDashboardProjectsHooks();
  const {
    handleGoBack,
    projectData,
    projecDataStatistics,
    handleGoToCommunities,
    handleGoToSocioCulturalInfo,
    handleGoToFestivitiesAndTraditions,
    handleGoToAbcTrajectory,
    handleGoRecommendations,
    handleGoAdditionalInfo,
    convertThounsandsSeparator,
    handleGoToDocumentation,
    handleGoToInterventions,
    convertArrayToString,
    showCityDepartmentAndCountry,
  } = useAdminRootDashboard();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <LayoutSelected {...props}>
        <>
          <StyledBackButton className="mt-3" onClick={handleGoBack}>
            Regresar
          </StyledBackButton>
          <Box className="bg-neutral-200 w-12/12 mx-auto lg:px-16 xl:px-12 2xl:px-16 lg:py-12 px-6 py-8 lg:my-8">
            <>
              <StyledGeneralInformation>
                <Image
                  className="rounded-2xl image-project"
                  srcImage={projectData?.image?.url || infoIcon}
                  altImage="info"
                  titleImage="info"
                />
                <StyledDashboardInformation>
                  <TypographyComponent
                    text={projectData?.name}
                    variant="h2"
                    className="font-bold text-primary mb-1"
                  />
                  <TypographyComponent
                    className="text-secondary flex"
                    variant="h5"
                    text={
                      <>
                        {' '}
                        <Image
                          srcImage={iconLocation}
                          altImage="location"
                          className="location-icon"
                        />
                        {showCityDepartmentAndCountry({
                          city: projectData?.city,
                          format: 'cityDepartmentCountry',
                        })}
                      </>
                    }
                  />
                  <StyledDashboardWrapperBadges>
                    <DefaultBadge
                      label={`Población: ${convertThounsandsSeparator(
                        projectData?.population_number
                      )} ${
                        projectData?.population_number === 1
                          ? 'habitante'
                          : 'habitantes'
                      }`}
                    />
                    <DefaultBadge
                      label={convertArrayToString({
                        arrayToConvert: projectData?.languages,
                      })}
                    />
                  </StyledDashboardWrapperBadges>

                  <StyledWrapperUsers>
                    {projectData?.project_manager && (
                      <CardUser
                        image={
                          projectData?.project_manager?.avatar || imgUserFake
                        }
                        name={`${projectData?.project_manager.name} ${projectData?.project_manager.last_name}`}
                        rol="Project Manager"
                        className="mb-2 xl:w-4/3 2xl:w-auto "
                        location={showCityDepartmentAndCountry({
                          city: projectData?.project_manager.city,
                        })}
                      />
                    )}
                    {projectData?.social_expert && (
                      <CardUser
                        image={
                          projectData?.social_expert?.avatar || imgUserFake
                        }
                        name={`${projectData?.social_expert.name} ${projectData?.social_expert.last_name}`}
                        rol="Experto ASCC"
                        className="ml-8 mr-8 mb-2 xl:w-4/3 2xl:w-auto"
                        location={showCityDepartmentAndCountry({
                          city: projectData?.social_expert.city,
                        })}
                      />
                    )}
                    {projectData?.executing_partner && (
                      <CardUser
                        image={
                          projectData?.executing_partner?.avatar || imgUserFake
                        }
                        name={`${projectData?.executing_partner.name} ${projectData?.executing_partner.last_name}`}
                        rol="Socio Ejecutor"
                        className="mb-2 xl:w-4/3 2xl:w-auto"
                        location={showCityDepartmentAndCountry({
                          city: projectData?.executing_partner.city,
                        })}
                      />
                    )}
                  </StyledWrapperUsers>

                  <TypographyComponent
                    text="Descripción del proyecto"
                    variant="p"
                    className="font-bold text-primary mb-1"
                  />
                  <TypographyComponent
                    text={projectData?.description}
                    variant="p"
                    className="text-black mb-1"
                  />
                </StyledDashboardInformation>
              </StyledGeneralInformation>

              <StyledOtherConfigs>
                <ButtonComponent
                  variant="primary"
                  className="w-full"
                  text="Comunidades"
                  buttonTypeSecondary
                  icon={<i className="text-lg icon-comunities" />}
                  onPress={handleGoToCommunities}
                />
                <ButtonComponent
                  variant="primary"
                  className="w-full"
                  text="Información sociocultural"
                  buttonTypeSecondary
                  icon={<i className="text-lg icon-assignment_ind" />}
                  onPress={handleGoToSocioCulturalInfo}
                />
                <ButtonComponent
                  variant="primary"
                  className="w-full"
                  text="Fiestas y tradiciones"
                  buttonTypeSecondary
                  icon={<i className="text-lg icon-parties mr-4" />}
                  onPress={handleGoToFestivitiesAndTraditions}
                />
                <ButtonComponent
                  variant="primary"
                  className="w-full"
                  text="Trayectoria ABC"
                  buttonTypeSecondary
                  icon={<i className="text-lg icon-folder-trayec" />}
                  onPress={handleGoToAbcTrajectory}
                />
                <ButtonComponent
                  variant="primary"
                  className="w-full"
                  text="Recomendaciones"
                  buttonTypeSecondary
                  icon={<i className="text-lg icon-recomendations" />}
                  onPress={handleGoRecommendations}
                />
                <ButtonComponent
                  variant="primary"
                  className="w-full"
                  text="Información complementaria"
                  buttonTypeSecondary
                  icon={<i className="text-lg icon-info-top" />}
                  onPress={handleGoAdditionalInfo}
                />
                <ButtonComponent
                  variant="primary"
                  className="w-full"
                  text="Documentación"
                  buttonTypeSecondary
                  icon={<i className="text-lg icon-library-books" />}
                  onPress={handleGoToDocumentation}
                />
                <ButtonComponent
                  variant="secondary"
                  className="w-full"
                  text="Intervenciones"
                  buttonTypeSecondary
                  icon={<i className="text-lg icon-art" />}
                  onPress={handleGoToInterventions}
                />
              </StyledOtherConfigs>
            </>
          </Box>
          <DefaultCollapse
            title={
              <TypographyComponent
                text="Estadísticas del proyecto"
                variant="h4"
                className="font-bold text-primary cursor-pointer"
              />
            }
            titleTitleCollapsed=""
            iconCollapsed={iconCollapsed}
            className="mt-10"
            withUseState
          >
            <StylesListStadistics>
              <TypographyComponent
                text="Intervenciones"
                variant="h5"
                className="text-primary mt-10 mb-8"
              />
              <StylesListStadistic>
                <CardStadistics
                  value={projecDataStatistics.totalActiveInterventions.toString()}
                  title="Intervenciones activas"
                  description="Por objetivo:"
                  typeList={[
                    {
                      name: `${projectData.dashboard.interventions.active.activate} Activar`,
                    },
                    {
                      name: `${projectData.dashboard.interventions.active.hold} Sostener`,
                    },
                    {
                      name: `${projectData.dashboard.interventions.active.inspire} Inspirar`,
                    },
                  ]}
                />
                <CardStadistics
                  value={`${projecDataStatistics.totalCompletedInterventions}`}
                  variant="finish"
                  title="Intervenciones finalizadas"
                  description="Por objetivo:"
                  typeList={[
                    {
                      name: `${projectData.dashboard.interventions.completed.activate} Activar`,
                    },
                    {
                      name: `${projectData.dashboard.interventions.completed.hold} Sostener`,
                    },
                    {
                      name: `${projectData.dashboard.interventions.completed.inspire} Inspirar`,
                    },
                  ]}
                />
              </StylesListStadistic>
              <TypographyComponent
                text="Comunidades"
                variant="h5"
                className="text-primary mt-10 mb-8"
              />
              <StylesListStadistic>
                <CardStadistics
                  value={projectData?.dashboard?.communities.toString()}
                  title="Comunidades asociadas"
                />
                <CardStadistics
                  value={projecDataStatistics.totalContactCommunities.toString()}
                  title="Contactos en las comunidades"
                  typeList={[
                    {
                      name: `${projectData.dashboard.communities_members.community} miembros de la comunidad`,
                    },
                    {
                      name: `${projectData.dashboard.communities_members.changed_agent} Agentes de cambio`,
                    },
                  ]}
                />
              </StylesListStadistic>
            </StylesListStadistics>
          </DefaultCollapse>
        </>
      </LayoutSelected>
    </Suspense>
  );
};

export default AdminDashboardProject;
