//Hooks
import useApi from '../../../api';
import useModels from '../../../models';

const useModalUploadProfilePhoto = () => {
  const { useSelectors } = useModels();
  const { useSelector, useGeneralSelectors } = useSelectors();
  const { useModalsSelectors } = useGeneralSelectors();
  const { showModalUpdatePhotoSelector } = useModalsSelectors();
  const { showModal, blobImage, name, type } = useSelector(
    showModalUpdatePhotoSelector
  );

  const { useActions } = useApi();
  const { dispatch, useGeneralActions } = useActions();
  const { useModalsActions } = useGeneralActions();
  const { actShowModalUploadProfilePhoto, actHideModalUploadProfilePhoto } =
    useModalsActions();

  const handleOpenModal = ({ blobImage, name, type }) =>
    dispatch(actShowModalUploadProfilePhoto({ blobImage, name, type }));

  const handleCloseModal = () => dispatch(actHideModalUploadProfilePhoto());

  return {
    showModal,
    blobImage,
    name,
    type,
    handleOpenModal,
    handleCloseModal,
  };
};

export default useModalUploadProfilePhoto;
