//Packages
import React, { Suspense, lazy } from 'react';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';
import useHelpers from 'helpers';

//Styles
const StyledGroup = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);
const StyledButtonsActions = lazy(() =>
  import('styles/buttons/buttons.styles').then((mod) => ({
    default: mod.StyledButtonsActions,
  }))
);
const StyledContentButtonsModal = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const AdminEditIntervention = () => {
  const { useLayouts, useComponents } = useViews();

  const { AdminLayout } = useLayouts();
  const {
    useTypographies,
    useInputs,
    useButtons,
    useImages,
    useBoxes,
    useLabels,
    useModals,
    useLoaders,
  } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { DefaultLabel } = useLabels();

  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { InputForm, TextArea } = useInputs();
  const { ButtonComponent } = useButtons();
  const { UploadImagePreview } = useImages();
  const { Box } = useBoxes();
  const { DefaultModal } = useModals();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminContentManagementInterventionsControllers } = useAdmin();
  const { useAdminEditInformationIntervention } =
    useAdminContentManagementInterventionsControllers();
  const {
    updateInterventionInfoFinalProcess,
    handleSubmit,
    handleGoToInterventionsPage,
    control,
    errors,
    register,
    goalsArray,

    errorsGoal,
    handleSubmitGoals,
    addNewGoal,
    registerGoal,
    deleteGoal,
    deleteFile,
    filesArrayToShow,
    uploadFile,
    watchFiles,
    handleActiveEditButton,
    anyFieldisEditMode,
    handleSaveGoal,
    controlGoals,
    openModalAtLestOneImage,
    setOpenModalAtLestOneImage,
  } = useAdminEditInformationIntervention();

  const { useQuickFunctions } = useHelpers();
  const { getMimeTypeFromMedia } = useQuickFunctions();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <AdminLayout>
        <form>
          <Box className="bg-neutral-200 w-11/12 mx-auto max-w-screen-lg lg:px-16 lg:py-12 px-6 py-8 lg:my-8">
            <>
              <TypographyComponent
                className="font-bold text-primary"
                variant="h2"
                text="Editar intervención"
              />

              <TypographyWithTooltip
                text="Visión general"
                variant="h3"
                textTooltip="Modalidad de profundización"
                className="text-primary mt-4"
                idTooltip="general_vision"
                applyFontBold
              />

              <DefaultLabel>
                {'¿Cuáles fueron los logros de esta intervención?*'}
              </DefaultLabel>

              {goalsArray.length > 0 &&
                _.map(goalsArray, (goal, index) => (
                  <div key={`goal-${goal.id ?? index}`}>
                    {goal.isEditActive ? (
                      <StyledGroup col-2 className="mb-4 mt-4" noSpace>
                        <div className="flex items-end w-full col-span-2">
                          <InputForm
                            name="goalsTwo"
                            type="text"
                            placeholder="Ingresa el logro alcanzado"
                            className="flex-1"
                            defaultValue={goal?.value?.text ?? goal?.value}
                            control={controlGoals}
                            errors={errorsGoal}
                          />
                          <ButtonComponent
                            variant="primary"
                            mode="outline"
                            text="Guardar logro"
                            className="mb-1 ml-8"
                            onPress={handleSubmitGoals((data) => {
                              handleSaveGoal({ data, arrayIndex: index });
                            })}
                          />
                        </div>
                      </StyledGroup>
                    ) : (
                      <div className="bg-white py-2 px-4 flex justify-between rounded-sm items-center mt-4">
                        <TypographyComponent
                          variant="p"
                          text={goal.value?.text ?? goal?.value}
                          className="flex-grow"
                        />
                        <StyledButtonsActions className="flex items-center">
                          <ButtonComponent
                            text=""
                            className="icon-edit text-primary-100"
                            onPress={() => {
                              handleActiveEditButton({ arrayIndex: index });
                            }}
                          />
                          <ButtonComponent
                            text=""
                            className="icon-delete"
                            onPress={(e) =>
                              deleteGoal({ e, goal, arrayIndex: index })
                            }
                          />
                        </StyledButtonsActions>
                      </div>
                    )}
                  </div>
                ))}

              {!anyFieldisEditMode && (
                <StyledGroup col-2 className="mb-4 mt-4" noSpace>
                  <div className="flex items-end w-full col-span-2">
                    <InputForm
                      name="goalsTwo"
                      type="text"
                      placeholder="Ingresa el logro alcanzado"
                      className="flex-1"
                      {...registerGoal('goalsTwo')}
                      errors={errorsGoal}
                    />
                    <ButtonComponent
                      variant="primary"
                      mode="outline"
                      text="Agregar nuevo logro"
                      className="mb-1 ml-8"
                      icon={
                        <div className="icon-close transform  rotate-45"></div>
                      }
                      onPress={handleSubmitGoals(addNewGoal)}
                    />
                  </div>
                </StyledGroup>
              )}

              {errors['goals'] && (
                <p className="text-sm mt-2 text-error">
                  {errors['goals'].message}
                </p>
              )}

              <StyledGroup col-2 className="mb-4 mt-4" noSpace>
                <TextArea
                  name="recommendations"
                  placeholder="Escribe tus recomendaciones"
                  className="w-full col-span-2"
                  label="¿Qué recomendaciones le darías a otros artistas que vayan a realizar una intervención similar?*"
                  {...register('recommendations')}
                  errors={errors}
                />
              </StyledGroup>

              <TypographyWithTooltip
                text="Visión de la comunidad"
                variant="h3"
                textTooltip="Visión de la comunidad"
                className="text-primary mt-4"
                idTooltip="communitie_vision"
                applyFontBold
              />

              <StyledGroup col-2 className="mb-4 mt-4" noSpace>
                <TextArea
                  name="tastes"
                  placeholder="Escribe la opinión de la comunidad"
                  className="w-full col-span-2"
                  label="¿Qué fue lo que más le gustó a la comunidad?*"
                  {...register('tastes')}
                  errors={errors}
                />
              </StyledGroup>

              <StyledGroup col-2 className="mb-4 mt-4" noSpace>
                <TextArea
                  name="suggestions"
                  placeholder="Escribe la opinión de la comunidad"
                  className="w-full col-span-2"
                  label="¿Qué fue lo que menos le gustó a la comunidad?"
                  {...register('suggestions')}
                  errors={errors}
                />
              </StyledGroup>

              <TypographyWithTooltip
                text="Información Complementaria"
                variant="h3"
                textTooltip="Información Complementaria"
                className="text-primary mt-4"
                idTooltip="complementary_vision"
                applyFontBold
              />

              <StyledGroup col-2 className="mb-4 mt-4" noSpace>
                <TextArea
                  name="information"
                  placeholder="Información Complementaria"
                  className="w-full col-span-2"
                  {...register('information')}
                  errors={errors}
                />
              </StyledGroup>

              <TypographyWithTooltip
                text="Contenido Multimedia"
                variant="h3"
                textTooltip="Contenido Multimedia"
                className="text-primary mt-4"
                idTooltip="complementary_vision"
                applyFontBold
              />

              <div className="mt-4">
                <TypographyComponent
                  className="font-bold text-neutral-950 mb-2"
                  variant="p"
                  text="Agregar imágenes o videos"
                />
                <StyledGroup flex>
                  <div className="flex items-center relative space-x-4 w-full">
                    {_.map(filesArrayToShow, (file, index) => (
                      <Controller
                        key={`image-${index}-${file.id}`}
                        control={control}
                        name={`files[${index}]`}
                        render={({ field: { onChange, name } }) => {
                          return (
                            <UploadImagePreview
                              name={name}
                              onChange={(file) => {
                                onChange(file);
                                uploadFile({ file });
                              }}
                              errors={errors}
                              resetInput={() => {
                                deleteFile(file);
                              }}
                              srcDefaultImage={file.urlFromBackend}
                              isAVideo={getMimeTypeFromMedia({
                                urlFile: file.urlFromBackend,
                              })}
                              showEditButton={
                                watchFiles?.[index]
                                  ? !watchFiles[index]
                                  : !file.urlFromBackend
                              }
                              removeMedia={
                                !(
                                  _.filter(
                                    filesArrayToShow,
                                    (file) => file.urlFromBackend
                                  ).length === 1
                                )
                              }
                              putMedia={false}
                            />
                          );
                        }}
                      />
                    ))}
                  </div>
                </StyledGroup>
                {errors['files'] && (
                  <p className="text-sm mt-2 text-error">
                    {errors['files'].message}
                  </p>
                )}
              </div>

              <div className="flex items-center mt-12 space-x-2 justify-center">
                <ButtonComponent
                  variant="primary"
                  mode="outline"
                  text="Cancelar"
                  onPress={() => handleGoToInterventionsPage()}
                />
                <ButtonComponent
                  text="Guardar cambios"
                  onPress={handleSubmit(updateInterventionInfoFinalProcess)}
                />
              </div>
            </>
          </Box>
        </form>
      </AdminLayout>
      <DefaultModal
        isActive={openModalAtLestOneImage}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={setOpenModalAtLestOneImage}
      >
        <TypographyComponent
          text="Debe existir al menos una imagen o video"
          variant="h3"
          className="font-bold text-primary mb-1 text-center"
        />
        <TypographyComponent
          text="Para borrar la imagen actual, debes primero subir otra y luego borrar la que deseas"
          variant="p"
          className="text-neutral-950 mb-1 text-center"
        />
        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Aceptar"
            className="mx-auto w-full lg:w-auto lg:mx-2"
            onPress={setOpenModalAtLestOneImage}
          />
        </StyledContentButtonsModal>
      </DefaultModal>
    </Suspense>
  );
};

export default AdminEditIntervention;
