//Packages
import React, { lazy } from 'react';

//Components
import List from '../List';
import Detail from '../Detail';

//Hooks
import useControllers from 'controllers';

//Styles
const StyledBodyTab = lazy(() =>
  import('styles/tabs/tabs.styles').then((mod) => ({
    default: mod.StyledBodyTab,
  }))
);

const Drafts = () => {
  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsCommunication } =
    useAdminViewInterventionsControllers();
  const { useAdminViewInterventionsCommunicationDiffusion } =
    useAdminViewInterventionsCommunication();
  const {
    activeItem,
    setActiveItem,

    //functions
    handleDataToOrder,
    handleChangePage,
    deleteMessage,
    onFormatDefaultValueCommunities,
    handleAcceptMessageAdmin,
    handleFilter,
    handleResetFilter,
    handleResetSelectedDetailMessage,
    handleGetDetailMessages,
    setValidateOptionsRules,
    handleAddOptions,
    setNumberOptions,
    handleDeleteOption,
    handleOnChangeOptions,

    //data
    dataTableHeaderArtistDraftMessagesRef,
    orderBy,
    dataForPagination,
    messagesList,
    optionsCommunities,
    optionsContactLists,
    selectedMessage,
    numberOptions,

    //Modals/toogles
    handleOpenMenu,
    activeDropDown,
    activeDeleteModal,
    setActiveDeleteModal,
    activeConfirmModal,
    setActiveConfirmModal,

    //rhf
    formFilterMessages,
    formAcceptMessage,
  } = useAdminViewInterventionsCommunicationDiffusion({
    draftOrNoDraft: 'd',
    formToValidateOptions: 'formAcceptMessage',
  });

  return (
    <div className="px-4">
      {activeItem.active === 0 && (
        <StyledBodyTab active={activeItem.active === 0}>
          <List
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            dataTableHeaderArtistDraftMessagesRef={
              dataTableHeaderArtistDraftMessagesRef
            }
            activeDropDown={activeDropDown}
            handleOpenMenu={handleOpenMenu}
            handleDataToOrder={handleDataToOrder}
            orderBy={orderBy}
            messagesList={messagesList}
            dataForPagination={dataForPagination}
            handleChangePage={handleChangePage}
            deleteMessage={deleteMessage}
            activeDeleteModal={activeDeleteModal}
            setActiveDeleteModal={setActiveDeleteModal}
            handleFilter={handleFilter}
            handleResetFilter={handleResetFilter}
            optionsCommunities={optionsCommunities}
            optionsContactLists={optionsContactLists}
            formFilterMessages={formFilterMessages}
            handleGetDetailMessages={handleGetDetailMessages}
          />
        </StyledBodyTab>
      )}

      {activeItem.active === 1 && (
        <StyledBodyTab active={activeItem.active === 1}>
          <Detail
            setActiveItem={setActiveItem}
            selectedMessage={selectedMessage}
            formAcceptMessage={formAcceptMessage}
            onFormatDefaultValueCommunities={onFormatDefaultValueCommunities}
            handleResetSelectedDetailMessage={handleResetSelectedDetailMessage}
            handleAcceptMessageAdmin={handleAcceptMessageAdmin}
            activeConfirmModal={activeConfirmModal}
            setActiveConfirmModal={setActiveConfirmModal}
            optionsCommunities={optionsCommunities}
            optionsContactLists={optionsContactLists}
            setValidateOptionsRules={setValidateOptionsRules}
            handleAddOptions={handleAddOptions}
            setNumberOptions={setNumberOptions}
            numberOptions={numberOptions}
            handleDeleteOption={handleDeleteOption}
            handleOnChangeOptions={handleOnChangeOptions}
          />
        </StyledBodyTab>
      )}
    </div>
  );
};

export default Drafts;
