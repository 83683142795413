const useUserInitialStates = () => {
  const initialStateUser = {
    email: '',
    name: '',
    last_name: '',
    avatar: '',
    phone: {
      code: {
        id: '',
        iso_code: '',
        code: '',
      },
      number: '',
    },
    role: {
      id: '',
      name: '',
    },
    city: {
      id: '',
      name: '',
      department: {
        id: '',
        name: '',
        country: {
          id: '',
          code: '',
          name: '',
        },
      },
    },
    language: '',
    gender: '',
    organization: '',
    position: '',
    about_me_title: '',
    about_me_body: '',
    intervention_type: { id: '', name: '' },
    website: '',
    specialties: [],
    networks: [],
    training_to_others: '',
    specialitiesIndexed: {},
    networksIndexed: {},
    group: '',
  };
  const initialStateUpdateUser = {};
  const initialStateUsers = {
    data: [],
    meta: {},
    user: {},
    favorites: [],
  };
  const initialStateArtistUsersInifiniteScroll = {
    data: [],
    meta: {
      current_page: '',
      last_page: '',
      total: '',
      per_page: '',
    },
    links: {
      next: '',
      prev: '',
    },
  };
  return {
    initialStateUser,
    initialStateUpdateUser,
    initialStateUsers,
    initialStateArtistUsersInifiniteScroll,
  };
};

export default useUserInitialStates;
