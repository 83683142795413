import PilotProgramArtist from './PilotEjecution';
import PilotEvaluateArtist from './PilotEvaluate';
import PilotInterventionArtist from './PilotIntervention';
import SketchInterventionArtist from './SketchIntervention';

const useStepsSketchInterventionArtist = () => {
  return {
    PilotEjecutionArtist: PilotProgramArtist,
    PilotEvaluateArtist,
    PilotInterventionArtist,
    SketchInterventionArtist,
  };
};

export default useStepsSketchInterventionArtist;
