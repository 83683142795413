import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';

//Hooks
import useControllers from '../../../index';
import useApi from '../../../../api';
import useStrings from '../../../../strings';
import useHelpers from '../../../../helpers';

const useRegister = () => {
  const history = useHistory();
  const [role, setRole] = useState();

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const {
    URL_NOT_VALID,
    EMAIL_NOT_VALID,
    REQUIRED_FIELD,
    POSITIVE_NUMBER,
    ACCEPTS_ONLY_TEXT,
  } = useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const { regexValidations } = useQuickFunctions();
  const regexUrl = regexValidations({ regex: 'url' });
  const regexText = regexValidations({ regex: 'text' });

  const validate_url = yup
    .string()
    .matches(regexUrl, { message: URL_NOT_VALID, excludeEmptyString: true });
  const registerArtistSchema = yup.object({
    name: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(regexText, ACCEPTS_ONLY_TEXT),
    last_name: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(regexText, ACCEPTS_ONLY_TEXT),
    group: yup.string().required(REQUIRED_FIELD),
    email: yup.string().email(EMAIL_NOT_VALID).required(REQUIRED_FIELD),
    country: yup.object().required(REQUIRED_FIELD),
    department: yup.object().required(REQUIRED_FIELD),
    city: yup.object().required(REQUIRED_FIELD),
    phone_code: yup.object().required(REQUIRED_FIELD),
    phone: yup
      .number()
      .typeError(REQUIRED_FIELD)
      .positive(POSITIVE_NUMBER)
      .required(REQUIRED_FIELD),
    gender: yup.string(),
    language: yup.object().required(REQUIRED_FIELD),
    website: validate_url,
    training_to_others: yup.boolean().required(REQUIRED_FIELD),
    specialties: yup.string().required(REQUIRED_FIELD),
    tw: validate_url,
    fb: validate_url,
    inst: validate_url,
    yt: validate_url,
    other: validate_url,
    inclusion_question: yup.string(),
    interest_onedrop_question: yup.string(),
    interest_art_question: yup.string(),
  });
  const registerPartnerSchema = yup.object({
    name: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(regexText, ACCEPTS_ONLY_TEXT),
    last_name: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(regexText, ACCEPTS_ONLY_TEXT),
    organization: yup.string().required(REQUIRED_FIELD),
    email: yup.string().email(EMAIL_NOT_VALID).required(REQUIRED_FIELD),
    country: yup.object().required(REQUIRED_FIELD),
    department: yup.object().required(REQUIRED_FIELD),
    city: yup.object().required(REQUIRED_FIELD),
    phone_code: yup.object().required(REQUIRED_FIELD),
    phone: yup.number().typeError(REQUIRED_FIELD).required(REQUIRED_FIELD),
    gender: yup.string(),
    language: yup.object().required(REQUIRED_FIELD),
    website: validate_url,
    tw: validate_url,
    fb: validate_url,
    inst: validate_url,
    yt: validate_url,
    other: validate_url,
    inclusion_question: yup.string(),
    interest_onedrop_question: yup.string(),
    interest_art_question: yup.string(),
  });
  const formRegister = useForm({
    resolver: yupResolver(
      role?.value === 'ar' ? registerArtistSchema : registerPartnerSchema
    ),
    mode: 'onChange',
  });

  useEffect(() => {
    if (formRegister.watch('country')?.value) {
      formRegister.setValue('department', '');
      formRegister.setValue('city', '');
    }
  }, [formRegister.watch('country')]);

  useEffect(() => {
    if (formRegister.watch('department')?.value) {
      formRegister.setValue('city', '');
    }
  }, [formRegister.watch('department')]);

  const { useGeneralHooks } = useControllers();
  const { useWhereLive } = useGeneralHooks();
  const {
    optionsIndicatives,
    optionsCities,
    optionsDepartments,
    optionsCountries,
    optionsLanguages,
  } = useWhereLive({
    countrySelected: formRegister.watch('country'),
    departmentSelected: formRegister.watch('department'),
  });
  const { useActions } = useApi();
  const { dispatch, useAuthActions } = useActions();
  const { usePreRegisterActions } = useAuthActions();
  const { actPreRegister } = usePreRegisterActions();
  const preRegister = (user, e) => {
    e.preventDefault();
    user.role = role.value;
    user.city_id = user.city.value;
    user.networks = [
      user.fb !== ''
        ? {
            type: 'fcb',
            url: user.fb,
          }
        : undefined,
      user.tw !== ''
        ? {
            type: 'tw',
            url: user.tw,
          }
        : undefined,
      user.inst !== ''
        ? {
            type: 'int',
            url: user.inst,
          }
        : undefined,
      user.yt !== ''
        ? {
            type: 'you',
            url: user.yt,
          }
        : undefined,
      user.other !== ''
        ? {
            type: 'oth',
            url: user.other,
          }
        : undefined,
    ].filter((value) => value != null);
    user.language = user.language.value;
    user.phone_code = user.phone_code.value;
    user.website = user.website === '' ? undefined : user.website;
    delete user.fb;
    delete user.other;
    delete user.inst;
    delete user.yt;
    delete user.tw;
    delete user.city;
    delete user.department;
    delete user.country;
    dispatch(
      actPreRegister(
        user,
        () => {
          history.push('/');
        },
        (e) => {
          const { email, phone } = e.response.data.errors;
          email &&
            formRegister.setError('email', {
              type: 'manual',
              message: email,
            });
          phone &&
            formRegister.setError('phone', {
              type: 'manual',
              message: phone,
            });
        }
      )
    );
  };
  return {
    formRegister,
    preRegister,
    optionsIndicatives,
    optionsCities,
    optionsDepartments,
    optionsCountries,
    optionsLanguages,
    setRole,
  };
};
export default useRegister;
