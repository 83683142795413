//Hooks
import useApi from '../../..';
import useStrings from '../../../../strings';

const useAccessibilityActions = () => {
  const { useProviders } = useApi();
  const { useGeneralProviders } = useProviders();
  const { useAccessibilityProviders } = useGeneralProviders();
  const { getAccessibilities } = useAccessibilityProviders();

  //Strings
  const { useGeneralTypes } = useStrings();
  const { useAccessibilityTypes } = useGeneralTypes();
  const { GET_ACCESSIBILITIES } = useAccessibilityTypes();

  const actGetAccessibilities = (onSuccess, onError) => async (dispatch) => {
    try {
      const res = await getAccessibilities();
      dispatch({ type: GET_ACCESSIBILITIES, payload: res.data?.data });
      onSuccess && onSuccess(res);
    } catch (error) {
      onError && onError(error);
    }
  };
  return { actGetAccessibilities };
};

export default useAccessibilityActions;
