import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import useViews from '../../../../../../../../../../../..';

const StyledContentButtonsModal = lazy(() =>
  import(
    '../../../../../../../../../../../../../styles/tableViews/tableViews.styles'
  ).then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const ModalAccept = (props) => {
  const { setViewActive, setApprove, handleClose } = props;
  const { useComponents } = useViews();

  const { useTypographies, useButtons } = useComponents();
  const { TypographyComponent } = useTypographies();
  const { ButtonComponent } = useButtons();

  return (
    <>
      <TypographyComponent
        text="Aprobar profundización"
        variant="h3"
        className="font-bold text-primary mb-1"
      />

      <StyledContentButtonsModal>
        <ButtonComponent
          variant="primary"
          text="Cancelar"
          className="mx-auto w-full lg:w-auto lg:mx-2 "
          mode="outline"
          onPress={handleClose}
        />
        <ButtonComponent
          variant="primary"
          text="Aceptar"
          className="mx-auto w-full lg:w-auto  lg:mx-2"
          onPress={() => {
            setApprove();
            setViewActive(3);
          }}
        />
      </StyledContentButtonsModal>
    </>
  );
};

ModalAccept.propTypes = {
  setViewActive: PropTypes.func.isRequired,
  setApprove: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ModalAccept;
