const useAdminCommunitiesTypes = () => {
  const GET_COMMUNITIES = 'GET_COMMUNITIES';
  const COMMUNITY_CREATED = 'COMMUNITY_CREATED';
  const SELECTED_COMMUNITY_DATA = 'SELECTED_COMMUNITY_DATA';
  const DELETE_COMMUNITY_BY_ID = 'DELETE_COMMUNITY_BY_ID';
  const UPDATE_COMMUNITY = 'UPDATE_COMMUNITY';

  return {
    GET_COMMUNITIES,
    COMMUNITY_CREATED,
    SELECTED_COMMUNITY_DATA,
    DELETE_COMMUNITY_BY_ID,
    UPDATE_COMMUNITY,
  };
};

export default useAdminCommunitiesTypes;
