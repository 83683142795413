//Packages
import React, { Suspense, lazy } from 'react';
import _ from 'lodash';
//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
const StyledBackButton = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);

const StyledButtonsActions = lazy(() =>
  import('styles/buttons/buttons.styles').then((mod) => ({
    default: mod.StyledButtonsActions,
  }))
);

const StyledWrapperCommunity = lazy(() =>
  import('../../../Dashboard/Dashboard.styles').then((mod) => ({
    default: mod.StyledWrapperCommunity,
  }))
);

const StyledCardCommunity = lazy(() =>
  import('../../../Dashboard/Dashboard.styles').then((mod) => ({
    default: mod.StyledCardCommunity,
  }))
);

const AdminDashboardViewInterventionsDocumentation = () => {
  const { useComponents } = useViews();
  const { useButtons, useTypographies, useBoxes, useLoaders } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { ButtonComponent } = useButtons();
  const { TypographyComponent } = useTypographies();

  const { Box } = useBoxes();

  const { useGeneralHooks, useScreenHooks } = useControllers();

  const { useSelectLayoutByRole } = useGeneralHooks();
  const { LayoutSelected, props } = useSelectLayoutByRole();

  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminKnowProjectDetailIntervention } =
    useAdminInterventionsDashboardControllers();
  const { documentation, goBack, preViewFile, downloadFileDocumentation } =
    useAdminKnowProjectDetailIntervention();
  return (
    <Suspense fallback={<DefaultLoader />}>
      <LayoutSelected {...props}>
        <StyledBackButton
          className="mt-3"
          onClick={() => {
            goBack();
          }}
        >
          Regresar
        </StyledBackButton>
        <Box className="bg-neutral-200 w-12/12 mx-auto max-w-screen-2xl lg:px-16 lg:py-12 px-6 py-8 lg:my-8">
          <>
            <TypographyComponent
              text="Documentación"
              variant="h2"
              className="font-bold text-primary"
            />

            <TypographyComponent
              text="Comparte todos los documentos necesarios para un mejor entendimiento del proyecto. Ej. Diagnóstico socio cultural, informa de la región del proyecto sobre ASH, investigación de la cadena de valor, entre otros."
              variant="p"
              className="font-book text-neutral-950 mb-10"
            />

            <StyledWrapperCommunity>
              {_.map(documentation, (doc, idx) => {
                return (
                  <StyledCardCommunity key={`documentationItem-${idx}`}>
                    <TypographyComponent
                      variant="p"
                      text={doc.name}
                      className="flex-grow text-primary font-bold cursor-pointer"
                    />
                    <StyledButtonsActions className="flex items-center">
                      <ButtonComponent
                        text=""
                        className="icon-view"
                        onPress={() => {
                          preViewFile({ url: doc.url });
                        }}
                      />
                      <ButtonComponent
                        text=""
                        className="icon-download"
                        onPress={() => {
                          downloadFileDocumentation(doc);
                        }}
                      />
                    </StyledButtonsActions>
                  </StyledCardCommunity>
                );
              })}
            </StyledWrapperCommunity>
          </>
        </Box>
      </LayoutSelected>
    </Suspense>
  );
};

export default AdminDashboardViewInterventionsDocumentation;
