//Packages
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

//Hooks
import useApi from '../../../../api';
import useStrings from '../../../../strings';

const useLogin = () => {
  const history = useHistory();
  const [showWithErrorTextPassword, setShowWithErrorTextPassword] =
    useState(false);

  const { useActions } = useApi();
  const { dispatch, useAuthActions } = useActions();
  const { useLoginActions } = useAuthActions();
  const { actLogin } = useLoginActions();

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { REQUIRED_FIELD, EMAIL_NOT_VALID } = useFormsTypes();

  // Schemas - validation;
  const loginSchema = yup.object({
    email: yup.string().email(EMAIL_NOT_VALID).required(REQUIRED_FIELD),
    password: yup.string().required(REQUIRED_FIELD),
  });

  const {
    handleSubmit,
    register,
    formState: { isValid, errors },
    setError,
    clearErrors,
  } = useForm({ mode: 'onChange', resolver: yupResolver(loginSchema) });

  useEffect(() => {
    if (!errors.email?.type) {
      clearErrors('password', '');
      setShowWithErrorTextPassword(false);
    }
  }, [errors.email?.type]);

  const onSuccessLogin = () => {
    history.replace('/');
  };
  const onErrorLogin = (error) => {
    const { message } = error.response.data;
    if (error.response.status === 403) {
      setError('email', {
        type: 'manual',
        message,
      });
      setError('password', {
        type: 'manual',
        message,
      });
      setShowWithErrorTextPassword(true);
    }
  };

  const handleLogin = (data) => {
    const { email, password } = data;
    dispatch(actLogin({ email, password }, onSuccessLogin, onErrorLogin));
  };

  return {
    handleSubmit,
    register,
    errors,
    isValid,
    handleLogin,
    showWithErrorTextPassword,
  };
};

export default useLogin;
