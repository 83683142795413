//Hooks
import useHelpers from '../../../helpers';
import useStrings from '../../../strings';
import useInitialStates from '../../initialStates';

const useDashboardReducers = () => {
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  const { useDashboardTypes, useAuthTypes } = useStrings();
  const { LOGOUT } = useAuthTypes();
  const { GET_INTERVENTIONS_AS_ARTIST, SET_PAGE_INTERVENTIONS_AS_ARTIST } =
    useDashboardTypes();

  const { useDashboardInitialStates } = useInitialStates();
  const { initialStateArtistInterventions } = useDashboardInitialStates();

  const artistInterventions = createReducer(initialStateArtistInterventions, {
    [GET_INTERVENTIONS_AS_ARTIST](state, action) {
      const { data, meta, links } = action.payload;
      return {
        ...state,
        data,
        meta: meta
          ? {
              current_page: meta.current_page,
              last_page: meta.last_page,
              total: meta.total,
              per_page: meta.per_page,
            }
          : {},
        links: links
          ? {
              prev: links.prev,
              next: links.next,
            }
          : {},
      };
    },
    [SET_PAGE_INTERVENTIONS_AS_ARTIST](state, action) {
      const { page } = action.payload;
      return {
        ...state,
        page,
      };
    },
    [LOGOUT]() {
      return initialStateArtistInterventions;
    },
  });

  return { artistInterventions };
};

export default useDashboardReducers;
