//Hooks
import useHelpers from '../../../../../helpers';

const useAdminAdditionalInfoSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const listAdditionalInfoSelector = createSelector(
    (state) => state.projectSelected,
    (projectSelected) => projectSelected.additional_info
  );

  return { listAdditionalInfoSelector };
};

export default useAdminAdditionalInfoSelectors;
