//Packages
import { useEffect, useState } from 'react';

//Hooks
import useModels from 'models';

const useSketchStepper = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [actualStep, setActualStep] = useState(0);

  const { useSelectors } = useModels();
  const { useAdminSelectors, useSelector } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionsSelector, getInterventionDetailToEditSelector } =
    useAdminInterventionsSelectors();
  const { actual_phase } = useSelector(getInterventionDetailToEditSelector);
  const { createSketch, pilotPlan, programPilot, evaluatePilot } = useSelector(
    getInterventionsSelector
  );

  useEffect(() => {
    getActualPhase();
  }, [actual_phase]);

  const getActualPhase = () => {
    const phaseActual = actual_phase.id ?? '0';
    const phase = phaseActual.split('.');
    setActualStep(phase[1] ? parseInt(phase[1]) : 0);
    setCurrentPage(phase[1] ? parseInt(phase[1]) : 0);
  };

  return {
    actualStep,
    createSketch,
    pilotPlan,
    programPilot,
    evaluatePilot,
    currentPage,
    setCurrentPage,
    actualPhase: Number(actual_phase?.id),
  };
};

export default useSketchStepper;
