//Packages
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

//Hooks
import useStrings from 'strings';
import useApi from 'api';
import useModels from 'models';
import useControllers from 'controllers';
import useHelpers from 'helpers';

const useCreateUser = (contact = false) => {
  const history = useHistory();

  const { useActions } = useApi();
  const { useUserActions, dispatch, useGeneralActions, useAdminActions } =
    useActions();
  const { actUserCreate } = useUserActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminCommunitiesActions } = useAdminProjectsActions();
  const { actGetCommunities } = useAdminCommunitiesActions();
  const { useCountriesActions } = useGeneralActions();
  const { actGetCountries } = useCountriesActions();

  const { useMessagesTypes, useConstants } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const {
    EMAIL_NOT_VALID,
    REQUIRED_FIELD,
    NUMBER_NOT_VALID,
    MAX_LENGTH_PHONE,
    MIN_LENGTH_PHONE,
    ACCEPTS_ONLY_TEXT,
  } = useFormsTypes();
  const { OPTIONS_ROLES_WITH_LOGIN, OPTIONS_ROLES_WITHOUT_LOGIN } =
    useConstants();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminCommunitiesSelectors } = useAdminProjectsSelectors();
  const { communitiesListSelector } = useAdminCommunitiesSelectors();
  const communitiesList = useSelector(communitiesListSelector);

  const { useQuickFunctions } = useHelpers();
  const { regexValidations } = useQuickFunctions();
  const regexNumber = regexValidations({ regex: 'number' });
  const regexText = regexValidations({ regex: 'text' });

  const schemaCreateUser = yup.object({
    role: yup.object().required(REQUIRED_FIELD),
    name: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(regexText, ACCEPTS_ONLY_TEXT),
    last_name: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(regexText, ACCEPTS_ONLY_TEXT),
    email: yup.string().email(EMAIL_NOT_VALID).required(REQUIRED_FIELD),
    city: yup.object().required(REQUIRED_FIELD).typeError(REQUIRED_FIELD),
    country: yup.object().required(REQUIRED_FIELD).typeError(REQUIRED_FIELD),
    department: yup.object().required(REQUIRED_FIELD).typeError(REQUIRED_FIELD),
    code: yup.object().required(REQUIRED_FIELD),
    language: yup.object().required(REQUIRED_FIELD),
    number_phone: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(regexNumber, NUMBER_NOT_VALID)
      .max(15, MAX_LENGTH_PHONE)
      .min(8, MIN_LENGTH_PHONE),
  });

  const schemaCreateContact = yup.object({
    role: yup.object().required(REQUIRED_FIELD),
    name: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(regexText, ACCEPTS_ONLY_TEXT),
    last_name: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(regexText, ACCEPTS_ONLY_TEXT),
    email: yup.string().email(EMAIL_NOT_VALID).nullable(),
    code: yup.object().required(REQUIRED_FIELD),
    number_phone: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(regexNumber, NUMBER_NOT_VALID)
      .max(15, MAX_LENGTH_PHONE)
      .min(8, MIN_LENGTH_PHONE),
    has_whatsapp: yup.string().required(REQUIRED_FIELD),
    gender: yup.string(),
    community_id: yup.object().required(REQUIRED_FIELD),
  });

  const createUserForm = useForm({
    resolver: yupResolver(contact ? schemaCreateContact : schemaCreateUser),
    mode: 'onChange',
  });

  const countrySelected = createUserForm.watch('country');
  const departmentSelected = createUserForm.watch('department');

  const { useGeneralHooks } = useControllers();
  const { useWhereLive } = useGeneralHooks();
  const {
    optionsCities,
    optionsCountries,
    optionsDepartments,
    optionsIndicatives,
    optionsLanguages,
  } = useWhereLive({ countrySelected, departmentSelected });

  useEffect(() => {
    dispatch(
      actGetCountries({
        paginate: 0,
        append: ['departmentsRelation.citiesRelation'],
      })
    );
    dispatch(actGetCommunities());
  }, []);

  useEffect(() => {
    createUserForm.setValue('department', null);
    createUserForm.setValue('city', null);
  }, [countrySelected]);

  useEffect(() => {
    createUserForm.setValue('city', null);
  }, [departmentSelected]);

  const createUser = (user, e) => {
    e.preventDefault();
    user.role = user.role.value;
    user.phone_code = user.code.value;

    user.phone = user.number_phone;

    if (user.role === 'co' || user.role === 'ca') {
      user.community_id = user.community_id.value;
      user.has_whatsapp = user.has_whatsapp === 'yes';
    } else {
      user.language = user.language.value;
      user.country = user.country.value;
      user.city_id = user.city.value;
    }
    delete user.number_phone;
    dispatch(
      actUserCreate(
        { user },
        () => {
          createUserForm.reset();
          history.goBack();
        },
        (e) => {
          const { email, phone, community_id, name, last_name } =
            e.response.data.errors;
          name &&
            createUserForm.setError('name', {
              type: 'manual',
              message: name,
            });
          last_name &&
            createUserForm.setError('last_name', {
              type: 'manual',
              message: last_name,
            });
          email &&
            createUserForm.setError('email', {
              type: 'manual',
              message: email,
            });
          phone &&
            createUserForm.setError('number_phone', {
              type: 'manual',
              message: phone,
            });
          if (user.role === 'co' || user.role === 'ca') {
            community_id &&
              createUserForm.setError('community_id', {
                type: 'manual',
                message: community_id,
              });
          }
        }
      )
    );
  };

  return {
    createUserForm,
    createUser,
    history,
    optionsCountries,
    optionsDepartments,
    optionsCities,
    optionsIndicatives,
    countrySelected,
    departmentSelected,
    optionsLanguages,
    communitiesList,
    OPTIONS_ROLES_WITH_LOGIN,
    OPTIONS_ROLES_WITHOUT_LOGIN,
  };
};

export default useCreateUser;
