const useAdminRecommendationsTypes = () => {
  const GET_RECOMMENDATIONS = 'GET_RECOMMENDATIONS';
  const CREATE_RECOMMENDATIONS = 'CREATE_RECOMMENDATIONS';
  const UPDATE_RECOMMENDATIONS = 'UPDATE_RECOMMENDATIONS';
  const DELETE_RECOMMENDATION = 'DELETE_RECOMMENDATION';

  return {
    CREATE_RECOMMENDATIONS,
    GET_RECOMMENDATIONS,
    UPDATE_RECOMMENDATIONS,
    DELETE_RECOMMENDATION,
  };
};

export default useAdminRecommendationsTypes;
