//Packages
import { useEffect, useMemo, useRef, useState } from 'react';
import { convertToFormData } from 'buildformdata-rmonterrosa';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import _ from 'lodash';

//Hooks
import useApi from 'api';
import useModels from 'models';
import useStrings from 'strings';
import useHelpers from 'helpers';
import useControllers from 'controllers';

const crudActivityStatusMessage = {
  add: 'creada',
  update: 'actualizada',
  delete: 'eliminada',
};

const crudMessageStatusMessage = {
  add: 'creado',
  update: 'actualizado',
  delete: 'eliminado',
};

const useAdminDeepenKnowledgeCommunityInterventions = () => {
  const { useActions } = useApi();
  const [showError, setShowError] = useState({
    show: false,
    message: '',
  });
  const [denyApproveKnowledge, setDenyApproveKnowledge] = useState({
    deny: false,
    approve: false,
  });
  const [messages, setMessages] = useState([]);
  const [activities, setActivities] = useState([]);
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();

  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const { useAdminDevelopmentInterventionKnowCommunityActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const {
    actStoreDeepenKnowledgeCommunityIntervention,
    actGetDeepenKnowledgeCommunityIntervention,
    actGetDeepenKnowledgeCommunityFileIntervention,
  } = useAdminDevelopmentInterventionKnowCommunityActions();

  const { intervention_id } = useParams();

  const { useSelectors } = useModels();
  const { useAdminSelectors, useSelector } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionsSelector, getInterventionDetailToEditSelector } =
    useAdminInterventionsSelectors();
  const { communities } = useSelector(getInterventionDetailToEditSelector);
  const { deepen_knowledge } = useSelector(getInterventionsSelector);

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { REQUIRED_FIELD, MAX_LENGTH_500, FILE_SIZE_5MB } = useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const { downloadFileFromBackend, getFileValidations } = useQuickFunctions();
  const { MAX_SIZE_5MB } = getFileValidations();

  const { useGeneralHooks } = useControllers();
  const { useToggleModal } = useGeneralHooks();
  const [crudActivityModalOpen, toggleCrudActivityModal] = useToggleModal();

  const activityMessage = useRef({
    type: '',
    message: '',
  });

  const schemaDeepenKnowledgeCommunityIntervention = yup.object({
    methodology_file: yup
      .mixed()
      .required(REQUIRED_FIELD)
      .test('fileSize', FILE_SIZE_5MB, (value) => {
        if (value) {
          return value.size <= MAX_SIZE_5MB;
        }
        return true;
      }),
    communities: yup
      .array()
      .required(REQUIRED_FIELD)
      .min(1, 'Debe existir al menos una comunidad'),
    what_to_discover: yup
      .string()
      .max(500, MAX_LENGTH_500)
      .required(REQUIRED_FIELD),
    deepening_modality: yup.object().required(REQUIRED_FIELD),
    deepen_type: yup.object().required(REQUIRED_FIELD),
    description: yup.string().when('deepening_modality.value', {
      is: 'r',
      then: yup.string().when('deepen_type.value', {
        is: 'async',
        then: yup.string().max(500, MAX_LENGTH_500).required(REQUIRED_FIELD),
      }),
      otherwise: yup.string().max(500, MAX_LENGTH_500).notRequired(),
    }),
    state: yup.string(),
  });

  const schemaDisplayDataDeepenKnowledgeCommunityIntervention = yup.object({
    methodology_file: yup.mixed().required(REQUIRED_FIELD),
    communities: yup.array().required(REQUIRED_FIELD),
    what_to_discover: yup
      .string()
      .max(500, MAX_LENGTH_500)
      .required(REQUIRED_FIELD),
    deepening_modality: yup.object().required(REQUIRED_FIELD),
    deepen_type: yup.object().required(REQUIRED_FIELD),
    state: yup.string(),
  });

  const schemaRejectKnowledge = yup.object({
    rejected_reason: yup.string().required(REQUIRED_FIELD),
  });

  const formRejectKnowledge = useForm({
    resolver: yupResolver(schemaRejectKnowledge),
    mode: 'onChange',
  });

  const formDeepenKnowledge = useForm({
    resolver: yupResolver(schemaDeepenKnowledgeCommunityIntervention),
    mode: 'onChange',
    defaultValues: {
      deepen_type: {
        label: 'Sincrónica',
        value: 'sync',
      },
    },
  });

  const formDisplayDataDeepenKnowledge = useForm({
    resolver: yupResolver(
      schemaDisplayDataDeepenKnowledgeCommunityIntervention
    ),
    mode: 'onChange',
  });

  useEffect(() => {
    if (deepen_knowledge.id && deepen_knowledge.what_to_discover) {
      formDisplayDataDeepenKnowledge.reset({
        communities: _.map(deepen_knowledge.communities, (community) => ({
          label: community.name,
          value: community.id,
        })),
        description: deepen_knowledge.activities[0]?.description,
        what_to_discover: deepen_knowledge.what_to_discover,
        deepening_modality: {
          label: deepen_knowledge.deepening_modality.name,
          value: deepen_knowledge.deepening_modality.id,
        },
        deepen_type: {
          label: deepen_knowledge.deepen_type?.name,
          value: deepen_knowledge.deepen_type?.id,
        },
      });
    }
  }, [deepen_knowledge]);

  const optionsCommunities = useMemo(() => {
    return _.map(communities, (community) => ({
      label: community.name,
      value: community.id,
    }));
  }, [communities]);

  const handleActivities = ({ activities, crudStatus = '' }) => {
    setActivities([...activities]);
    activityMessage.current = {
      type: 'Actividad',
      message: crudActivityStatusMessage[crudStatus],
    };
    crudStatus && toggleCrudActivityModal();
  };

  const handleMessages = (messages, crudStatus = '') => {
    setMessages([...messages]);
    activityMessage.current = {
      type: 'Mensaje',
      message: crudMessageStatusMessage[crudStatus],
    };
    crudStatus && toggleCrudActivityModal();
  };

  const storeDeepenKnowledgeComunnityIntervention = (data) => {
    if (
      (activities.length < 1 && data.deepen_type.value !== 'async') ||
      JSON.stringify(activities[0]) === JSON.stringify({ expanded: true })
    ) {
      setShowError({
        message: 'Debe contener al menos una actividad',
        show: true,
      });
      return;
    }
    if (
      messages.length < 1 ||
      JSON.stringify(messages[0]) === JSON.stringify({ expanded: true })
    ) {
      setShowError({
        message: 'Debe contener al menos un mensaje',
        show: true,
      });
      return;
    }
    data.messages = _.map(messages, (m) => {
      const message = { ...m };
      message.type_information = message.type_information?.value;
      message.options = _.map(message.options, ({ value }) => value);
      return message;
    });

    data.activities = _.map(activities, (a) => {
      const activity = { ...a };
      if (activity.community_place_id) {
        activity.community_place_id = activity.community_place_id.value;
      }
      if (activity.modality_type) {
        activity.modality_type = activity.modality_type.value;
      }
      return activity;
    });
    if (activities.length < 1) {
      data.activities = [
        {
          description: data.description,
          state: 'c',
        },
      ];
      delete data.description;
    }
    data.communities = _.map(data.communities, (element) => element.value);
    data.deepening_modality = data.deepening_modality.value;
    data.deepen_type = data.deepen_type.value;
    data.state = 'ip';
    data._method = 'PATCH';
    const dataForm = convertToFormData(data);
    if (!deepen_knowledge.id) {
      dispatch(
        actGetDeepenKnowledgeCommunityIntervention(
          { intervention_id },
          (response) => {
            dispatch(
              actStoreDeepenKnowledgeCommunityIntervention({
                data: dataForm,
                intervention_id,
                deepen_knowledge_id: response.data.id,
              })
            );
          }
        )
      );
    } else {
      dispatch(
        actStoreDeepenKnowledgeCommunityIntervention({
          data: dataForm,
          intervention_id,
          deepen_knowledge_id: deepen_knowledge.id,
        })
      );
    }
  };

  const cancelDeepenKnowledge = () => {
    dispatch(
      actStoreDeepenKnowledgeCommunityIntervention({
        data: {
          state: 'cl',
          _method: 'PATCH',
        },
        intervention_id,
        deepen_knowledge_id: deepen_knowledge.id,
      })
    );
  };
  const preViewPDFDeepenKnowCommunity = (e) => {
    e.preventDefault();
    window.open(deepen_knowledge.methodology_file.url, '_blank');
  };
  const downloadPDFDeepenKnowCommunity = (e) => {
    e.preventDefault();
    dispatch(
      actGetDeepenKnowledgeCommunityFileIntervention(
        {
          intervention_id,
          deepen_knowledge_id: deepen_knowledge.id,
          file_id: deepen_knowledge.methodology_file.id,
        },
        (response) => {
          downloadFileFromBackend({
            response,
            nameFile: `Metodología de la profundización`,
          });
        }
      )
    );
  };
  const approveRejectDeepenKnowledge = (state) => {
    const data = {
      state,
      _method: 'PATCH',
    };
    if (state === 'r') {
      data.rejected_reason = formRejectKnowledge.getValues('rejected_reason');
    }
    dispatch(
      actStoreDeepenKnowledgeCommunityIntervention({
        data,
        intervention_id,
        deepen_knowledge_id: deepen_knowledge.id,
      })
    );
  };

  return {
    formDeepenKnowledge,
    deepen_knowledge,
    storeDeepenKnowledgeComunnityIntervention,
    optionsCommunities,
    cancelDeepenKnowledge,
    showError,
    setShowError,
    messages,
    handleMessages,
    activities,
    handleActivities,
    crudActivityStatus: activityMessage.current,
    crudActivityModalOpen,
    toggleCrudActivityModal,
    formDisplayDataDeepenKnowledge,
    preViewPDFDeepenKnowCommunity,
    downloadPDFDeepenKnowCommunity,
    denyApproveKnowledge,
    setDenyApproveKnowledge,
    approveRejectDeepenKnowledge,
    formRejectKnowledge,
    actualPhase: Number(deepen_knowledge?.intervention?.actual_phase?.id),
    infoScheduled: Boolean(
      deepen_knowledge?.state?.id && deepen_knowledge.state.id !== 'p'
    ),
  };
};

export default useAdminDeepenKnowledgeCommunityInterventions;
