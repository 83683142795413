//Packages
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo } from 'react';

//Hooks
import useApi from 'api';
import useControllers from 'controllers';
import useModels from 'models';

const useFilterForCompletedInterventions = ({
  setInterventionsCompleted,
  setActiveDropDown,
}) => {
  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions, useAdminContentInterventionsActions } =
    useAdminActions();
  const { useAdminInterventionsActions, useAdminProjectsRootActions } =
    useAdminProjectsActions();
  const { useAdminInterventionsRootActions } = useAdminInterventionsActions();
  const { actGetInterventionsForEnabling } = useAdminInterventionsRootActions();
  const { actGetProjects } = useAdminProjectsRootActions();

  const { actSetDataFilterAdminHomeInterventions } =
    useAdminContentInterventionsActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminProjectsSelectors, useAdminContentInterventionsSelectors } =
    useAdminSelectors();
  const { getFilterAdminHomeInterventionsSelector } =
    useAdminContentInterventionsSelectors();
  const { useAdminProjectsRootSelectors } = useAdminProjectsSelectors();
  const { projectsListSelector } = useAdminProjectsRootSelectors();
  const { projectsList } = useSelector(projectsListSelector);
  const dataFilter = useSelector(getFilterAdminHomeInterventionsSelector);

  const optionsProjects = useMemo(
    () =>
      _.map(projectsList?.data, (project) => {
        return {
          label: project.name,
          value: project.id,
        };
      }),
    [projectsList]
  );

  useEffect(() => {
    dispatch(actGetProjects({ paginate: 0 }));
  }, []);

  const schemaFilterCompletedInterventions = yup.object({
    country: yup.object().nullable(),
    department: yup.object().nullable(),
    project: yup.object().nullable(),
    objetive: yup.object().nullable(),
    type: yup.object().nullable(),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    setValue,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schemaFilterCompletedInterventions),
    defaultValues: dataFilter,
  });

  const countrySelected = watch('country');
  const departmentSelected = watch('department');

  const { useGeneralHooks } = useControllers();
  const { useWhereLive } = useGeneralHooks();
  const { optionsCountries, optionsDepartments } = useWhereLive({
    countrySelected,
    departmentSelected,
  });

  useEffect(() => {
    setValue('department', null);
    setValue('city', null);
  }, [countrySelected]);

  useEffect(() => {
    setValue('city', null);
  }, [departmentSelected]);

  const handleFilterCompletedInterventions = (data) => {
    const { country, department, project, objetive, type } = data;
    dispatch(
      actGetInterventionsForEnabling(
        {
          ...dataFilter,
          state: 'c',
          country: country && country.value,
          department: department && department.value,
          project: project && project.value,
          objetive: objetive && objetive.value,
          type: type && type.value,
        },
        (response) => {
          const { data, links, meta } = response.data;
          setInterventionsCompleted(() => ({
            data,
            links,
            meta,
          }));
          setActiveDropDown(false);
        }
      )
    );
    const keys = Object.keys(data);
    const values = Object.values(data);

    _.forEach(values, (filterElement, index) => {
      dispatch(
        actSetDataFilterAdminHomeInterventions({
          objectKey: keys[index],
          objectValue: filterElement,
        })
      );
    });
  };

  const handleResetFilterProject = (e) => {
    e.preventDefault();

    const dataToResetFilter = {
      country: '',
      department: '',
      project: '',
      objetive: '',
      type: '',
    };

    reset(dataToResetFilter);

    dispatch(
      actGetInterventionsForEnabling(
        {
          ...dataFilter,
          state: 'c',
          country: '',
          department: '',
          project: '',
          objetive: '',
          type: '',
        },
        (response) => {
          const { data, links, meta } = response.data;
          setInterventionsCompleted(() => ({
            data,
            links,
            meta,
          }));
          setActiveDropDown(false);
        }
      )
    );
    const keys = Object.keys(dataToResetFilter);
    const values = Object.values(dataToResetFilter);

    _.forEach(values, (filterElement, index) => {
      dispatch(
        actSetDataFilterAdminHomeInterventions({
          objectKey: keys[index],
          objectValue: filterElement,
        })
      );
    });
  };

  return {
    handleSubmit,
    errors,
    control,
    handleFilterCompletedInterventions,
    handleResetFilterProject,
    optionsCountries,
    optionsDepartments,
    optionsProjects,
    countrySelected,
  };
};

export default useFilterForCompletedInterventions;
