//Hooks
import useControllers from 'controllers';
import useStrings from 'strings';
import useApi from 'api';

const useAdminDevelopmentExecuteInterventionActions = () => {
  const { useProviders } = useApi()
  const { useAdminProviders } = useProviders();
  const { useAdminProjectsProviders } = useAdminProviders();
  const { useAdminInterventionsProviders } = useAdminProjectsProviders();
  const { useAdminViewInterventionProviders } =
    useAdminInterventionsProviders();
  const { useAdminViewInterventionDevelopmentInterventionProviders } =
    useAdminViewInterventionProviders();
  const { useAdminDevelopmentExecuteInterventionProviders } =
    useAdminViewInterventionDevelopmentInterventionProviders();
  const {
    getMaterializeInterventionProvider,
    updateMaterializeInterventionProvider,
    uploadFileMaterializeInterventionProvider,
    downloadFileMaterializeInterventionProvider,
    deleteFileMaterializeInterventionProvider,
    getExecuteInterventionProvider,
    updateExecuteInterventionProvider,
    createEventExecuteInterventionProvider,
    updateEventExecuteInterventionProvider,
    deleteEventExecuteInterventionProvider,
    completeMilestoneEventExecuteInterventionProvider,
    getEvaluateInterventionProvider,
    updateEvaluateInterventionProvider,
    uploadFileEvaluateInterventionProvider,
    downloadFileEvaluateInterventionProvider,
    deleteFileEvaluateInterventionProvider
  } = useAdminDevelopmentExecuteInterventionProviders();

  const { useAdminTypes } = useStrings();
  const { useAdminProjectsTypes } = useAdminTypes();
  const { useAdminInterventionsTypes } = useAdminProjectsTypes();
  const { useAdminViewInterventionsTypes } = useAdminInterventionsTypes();
  const { useAdminViewInterventionsDevelopmentInterventionTypes } =
    useAdminViewInterventionsTypes();
  const { useAdminDevelopmentExecuteInterventionTypes } =
    useAdminViewInterventionsDevelopmentInterventionTypes();
  const {
    GET_EXECUTE_MATERIALIZE_INTERVENTION,
    UPDATE_EXECUTE_MATERIALIZE_INTERVENTION,
    UPLOAD_FILE_EXECUTE_MATERIALIZE_INTERVENTION,
    DELETE_FILE_EXECUTE_MATERIALIZE_INTERVENTION,
    GET_EXECUTE_INTERVENTION,
    UPDATE_EXECUTE_INTERVENTION,
    CREATE_EVENT_EXECUTE_INTERVENTION,
    DELETE_EVENT_EXECUTE_INTERVENTION,
    START_EVENT_EXECUTE_INTERVENTION,
    COMPLETE_MILESTONE_EVENT_EXECUTE_INTERVENTION,
    GET_EXECUTE_EVALUATE_INTERVENTION,
    UPDATE_EXECUTE_EVALUATE_INTERVENTION,
    UPLOAD_FILE_EXECUTE_EVALUATE_INTERVENTION,
    DELETE_FILE_EXECUTE_EVALUATE_INTERVENTION,
  } = useAdminDevelopmentExecuteInterventionTypes();

  const { useGeneralHooks } = useControllers();
  const { useConsole } = useGeneralHooks();
  const { viewConsole } = useConsole();

  const actGetMaterializeIntervention =
    ({ interventionId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await getMaterializeInterventionProvider({
          interventionId,
        });
        dispatch({
          type: GET_EXECUTE_MATERIALIZE_INTERVENTION,
          payload: res.data,
        });
        onSuccess && onSuccess(res);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actUpdateMaterializeIntervention =
    ({ interventionId, materializeId, data }, onSuccess, onError) =>
      async (dispatch) => {
        try {
          const res = await updateMaterializeInterventionProvider({
            interventionId,
            materializeId,
            data
          });
          dispatch({
            type: UPDATE_EXECUTE_MATERIALIZE_INTERVENTION,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } catch (e) {
          viewConsole([e], 'error');
          onError && onError(e);
        }
      };

  const actUploadFileMaterializeIntervention =
    ({ interventionId, materializeId, data }, onSuccess, onError) =>
      async (dispatch) => {
        try {
          const res = await uploadFileMaterializeInterventionProvider({
            interventionId,
            materializeId,
            data
          });
          dispatch({
            type: UPLOAD_FILE_EXECUTE_MATERIALIZE_INTERVENTION,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } catch (e) {
          viewConsole([e], 'error');
          onError && onError(e);
        }
      };

  const actDownloadFileMaterializeIntervention =
    ({ interventionId, materializeId, fileId }, onSuccess, onError) =>
      async () => {
        try {
          const res = await downloadFileMaterializeInterventionProvider({
            interventionId,
            materializeId,
            fileId
          });
          onSuccess && onSuccess(res);
        } catch (e) {
          viewConsole([e], 'error');
          onError && onError(e);
        }
      };

  const actDeleteFileMaterializeIntervention =
    ({ interventionId, materializeId, fileId }, onSuccess, onError) =>
      async (dispatch) => {
        try {
          const res = await deleteFileMaterializeInterventionProvider({
            interventionId,
            materializeId,
            fileId
          });
          dispatch({
            type: DELETE_FILE_EXECUTE_MATERIALIZE_INTERVENTION,
            payload: fileId,
          });
          onSuccess && onSuccess(res.data);
        } catch (e) {
          viewConsole([e], 'error');
          onError && onError(e);
        }
      };

  const actGetExecuteIntervention =
    ({ interventionId }, onSuccess, onError) =>
      async (dispatch) => {
        try {
          const res = await getExecuteInterventionProvider({
            interventionId,
          });
          dispatch({
            type: GET_EXECUTE_INTERVENTION,
            payload: res.data,
          });
          onSuccess && onSuccess(res);
        } catch (e) {
          viewConsole([e], 'error');
          onError && onError(e);
        }
      };

  const actUpdateExecuteIntervention =
    ({ interventionId, executeId, data }, onSuccess, onError) =>
      async (dispatch) => {
        try {
          const res = await updateExecuteInterventionProvider({
            interventionId,
            executeId,
            data
          });
          dispatch({
            type: UPDATE_EXECUTE_INTERVENTION,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } catch (e) {
          viewConsole([e], 'error');
          onError && onError(e);
        }
      };

  const actCreateEventExecuteIntervention =
    ({ interventionId, executeId, data }, onSuccess, onError) =>
      async (dispatch) => {
        try {
          const res = await createEventExecuteInterventionProvider({
            interventionId,
            executeId,
            data
          });
          dispatch({
            type: CREATE_EVENT_EXECUTE_INTERVENTION,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } catch (e) {
          viewConsole([e], 'error');
          onError && onError(e);
        }
      };

  const actUpdateEventExecuteIntervention =
    ({ interventionId, executeId, eventId, data }, onSuccess, onError) =>
      async (dispatch) => {
        try {
          const res = await updateEventExecuteInterventionProvider({
            interventionId,
            executeId,
            eventId,
            data
          });
          dispatch({
            type: CREATE_EVENT_EXECUTE_INTERVENTION,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } catch (e) {
          viewConsole([e], 'error');
          onError && onError(e);
        }
      };

  const actDeleteEventExecuteIntervention =
    ({ interventionId, executeId, eventId }, onSuccess, onError) =>
      async (dispatch) => {
        try {
          const res = await deleteEventExecuteInterventionProvider({
            interventionId,
            executeId,
            eventId,
          });
          dispatch({
            type: DELETE_EVENT_EXECUTE_INTERVENTION,
            payload: eventId,
          });
          onSuccess && onSuccess(res.data);
        } catch (e) {
          viewConsole([e], 'error');
          onError && onError(e);
        }
      };

  const actStartEventExecuteIntervention =
    ({ interventionId, executeId, eventId, data }, onSuccess, onError) =>
      async (dispatch) => {
        try {
          const res = await updateEventExecuteInterventionProvider({
            interventionId,
            executeId,
            eventId,
            data,
          });
          dispatch({
            type: START_EVENT_EXECUTE_INTERVENTION,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } catch (e) {
          viewConsole([e], 'error');
          onError && onError(e);
        }
      };

  const actCompleteMilestoneEventExecuteIntervention =
    ({ executeId, eventId, milestoneId, data }, onSuccess, onError) =>
      async (dispatch) => {
        try {
          const res = await completeMilestoneEventExecuteInterventionProvider({
            executeId,
            eventId,
            milestoneId,
            data
          });
          dispatch({
            type: COMPLETE_MILESTONE_EVENT_EXECUTE_INTERVENTION,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } catch (e) {
          viewConsole([e], 'error');
          onError && onError(e);
        }
      };

  const actGetEvaluateIntervention =
    ({ interventionId }, onSuccess, onError) =>
      async (dispatch) => {
        try {
          const res = await getEvaluateInterventionProvider({
            interventionId,
          });
          dispatch({
            type: GET_EXECUTE_EVALUATE_INTERVENTION,
            payload: res.data,
          });
          onSuccess && onSuccess(res);
        } catch (e) {
          viewConsole([e], 'error');
          onError && onError(e);
        }
      };

  const actUpdateEvaluateIntervention =
    ({ interventionId, evaluateId, data }, onSuccess, onError) =>
      async (dispatch) => {
        try {
          const res = await updateEvaluateInterventionProvider({
            interventionId,
            evaluateId,
            data
          });
          dispatch({
            type: UPDATE_EXECUTE_EVALUATE_INTERVENTION,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } catch (e) {
          viewConsole([e], 'error');
          onError && onError(e);
        }
      };

  const actUploadFileEvaluateIntervention =
    ({ interventionId, evaluateId, data }, onSuccess, onError) =>
      async (dispatch) => {
        try {
          const res = await uploadFileEvaluateInterventionProvider({
            interventionId,
            evaluateId,
            data
          });
          dispatch({
            type: UPLOAD_FILE_EXECUTE_EVALUATE_INTERVENTION,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } catch (e) {
          viewConsole([e], 'error');
          onError && onError(e);
        }
      };

  const actDownloadFileEvaluateIntervention =
    ({ interventionId, evaluateId, fileId }, onSuccess, onError) =>
      async () => {
        try {
          const res = await downloadFileEvaluateInterventionProvider({
            interventionId,
            evaluateId,
            fileId
          });
          onSuccess && onSuccess(res);
        } catch (e) {
          viewConsole([e], 'error');
          onError && onError(e);
        }
      };

  const actDeleteFileEvaluateIntervention =
    ({ interventionId, evaluateId, fileId }, onSuccess, onError) =>
      async (dispatch) => {
        try {
          const res = await deleteFileEvaluateInterventionProvider({
            interventionId,
            evaluateId,
            fileId
          });
          dispatch({
            type: DELETE_FILE_EXECUTE_EVALUATE_INTERVENTION,
            payload: fileId,
          });
          onSuccess && onSuccess(res.data);
        } catch (e) {
          viewConsole([e], 'error');
          onError && onError(e);
        }
      };

  return {
    actGetMaterializeIntervention,
    actUpdateMaterializeIntervention,
    actUploadFileMaterializeIntervention,
    actDownloadFileMaterializeIntervention,
    actDeleteFileMaterializeIntervention,
    actGetExecuteIntervention,
    actUpdateExecuteIntervention,
    actCreateEventExecuteIntervention,
    actUpdateEventExecuteIntervention,
    actDeleteEventExecuteIntervention,
    actStartEventExecuteIntervention,
    actCompleteMilestoneEventExecuteIntervention,
    actGetEvaluateIntervention,
    actUpdateEvaluateIntervention,
    actUploadFileEvaluateIntervention,
    actDownloadFileEvaluateIntervention,
    actDeleteFileEvaluateIntervention
  };
};

export default useAdminDevelopmentExecuteInterventionActions;
