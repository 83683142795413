//Packages
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import qs from 'qs';

const useAdminViewInterventionCommunicationProviders = () => {
  const getMessagesInterventionProvider = ({
    intervention_id,
    draftOrNoDraft,
    search,
    orderByKey,
    orderByValue,
    state,
    created_at,
    request_sended_date,
    sended_date,
    communityReceivers,
    contactListReceivers,
    type,
    page,
    channel,
    gi_type,
    isMessagesOfArtist = '',
  }) => {
    let paramsDestination = {};

    if (
      contactListReceivers &&
      contactListReceivers.length > 0 &&
      communityReceivers &&
      communityReceivers.length > 0
    ) {
      paramsDestination = {
        communityOrContactListReceivers: {
          contactLists: contactListReceivers,
          communities: communityReceivers,
        },
      };
    }

    const response = axios({
      method: 'GET',
      url: `/interventions/${intervention_id}/message-request/${draftOrNoDraft}/${isMessagesOfArtist}`,
      params: {
        ...paramsDestination,
        page,
        search: search || undefined,
        created_at: created_at || undefined,
        request_sended_date: request_sended_date || undefined,
        sended_date: sended_date || undefined,
        state: state || undefined,
        orderBy: orderByKey ? { [orderByKey]: orderByValue } : undefined,
        communityReceivers:
          contactListReceivers && contactListReceivers.length > 0
            ? undefined
            : communityReceivers || undefined,
        contactListReceivers:
          communityReceivers && communityReceivers.length > 0
            ? undefined
            : contactListReceivers || undefined,
        type: type || undefined,
        channel: channel || undefined,
        gi_type: gi_type || undefined,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return trackPromise(response);
  };

  const getMessageInterventionProvider = ({ intervention_id, message_id }) => {
    const response = axios({
      method: 'GET',
      url: `/interventions/${intervention_id}/message-request/${message_id}`,
    });
    return trackPromise(response);
  };

  const sendMessagesInterventionProvider = ({ intervention_id, data }) => {
    const response = axios({
      method: 'POST',
      url: `/interventions/${intervention_id}/message-request/`,
      data,
    });
    return trackPromise(response);
  };

  const deleteMessageInterventionProvider = ({
    intervention_id,
    message_id,
  }) => {
    const response = axios({
      method: 'DELETE',
      url: `/interventions/${intervention_id}/message-request/${message_id}`,
    });
    return trackPromise(response);
  };

  const rejectAcceptRequestMessageAsAdminProvider = ({
    interventionId,
    messageId,
    rejected_cause,
    state,
    channel,
    type,
    communities,
    contactLists,
    send_date,
    recommendations,
    objective,
    message,
    options,
    gi_type,
    type_information,
  }) => {
    const response = axios({
      method: 'PATCH',
      url: `/interventions/${interventionId}/message-request/${messageId}`,
      data: {
        state,
        rejected_cause,
        channel,
        type,
        communities,
        contactLists,
        send_date,
        recommendations,
        objective,
        message,
        options: options?.length > 0 ? options : undefined,
        gi_type,
        type_information,
      },
    });
    return trackPromise(response);
  };

  const downloadFileOfCollectionMessagesInterventionProvider = ({
    message_request_id,
    fileId,
  }) => {
    const response = axios({
      method: 'GET',
      responseType: 'arraybuffer',
      url: `/message-request/${message_request_id}/responses/file/${fileId}`,
    });
    return trackPromise(response);
  };

  return {
    getMessagesInterventionProvider,
    sendMessagesInterventionProvider,
    getMessageInterventionProvider,
    deleteMessageInterventionProvider,
    rejectAcceptRequestMessageAsAdminProvider,
    downloadFileOfCollectionMessagesInterventionProvider,
  };
};

export default useAdminViewInterventionCommunicationProviders;
