import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemState,
  AccordionItemPanel,
} from 'react-accessible-accordion';

//Hooks
import useViews from 'views';
import useHelpers from 'helpers';
import useStrings from 'strings';

// Components
import FormActivity from './components/FormActivitie';

// Styles
import { StyledContentButtons } from 'styles/tableViews/tableViews.styles';
import { StyledButtonsActions } from 'styles/buttons/buttons.styles';
import { StyledDivider } from 'styles/formViews/formViews.styles';
import {
  StyledWrapperCommunity,
  StyledCardCommunity,
} from '../../../../../../../../../Dashboard.styles';

const Activities = (props) => {
  const {
    setActiveStep,
    activities,
    savePlanPilotActivity,
    deletePlanPilotActivity,
    controlForm,
    errorsForm,
    openConfirmationModal,
    canEditForm,
    deleteToolsFile,
    watchForm,
    currentFile,
    openToolsFile,
    downloadToolsFile,
  } = props;
  const { useComponents } = useViews();
  const { useTypographies, useButtons, useBadges, useInputs } = useComponents();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { ButtonComponent } = useButtons();
  const { DefaultBadge } = useBadges();
  const { InputFile } = useInputs();

  const [activeNewActivity, setActiveNewActivity] = useState(false);
  const [activityExpanded, setActivityExpanded] = useState();

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { REQUIRED_FIELD, MAX_LENGTH_100 } = useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const { getDurationTimeInMinutes } = useQuickFunctions();

  const totalPilotDuration = _.reduce(
    activities,
    (sum, act) => sum + getDurationTimeInMinutes({ duration: act.duration }),
    0
  );

  const schemaPlanPilotActivity = yup.object({
    id: yup.number().nullable(),
    name: yup.string().required(REQUIRED_FIELD).max(100, MAX_LENGTH_100),
    duration: yup
      .string()
      .required(REQUIRED_FIELD)
      .test('maxHour', 'El tiempo maximo permitido es 04:59', (value) => {
        return value && parseInt(value.split(':')[0]) < 5;
      }),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schemaPlanPilotActivity),
    mode: 'onChange',
  });

  const openFormNewActivity = (e) => {
    e.preventDefault();
    reset({});
    setActivityExpanded({});
    setActiveNewActivity(true);
  };

  const cancelActivity = (e) => {
    e && e.preventDefault();
    setActiveNewActivity(false);
    setActivityExpanded({});
    reset({});
  };

  const saveActivity = (data) => {
    const durationParts = data.duration.split(':');
    savePlanPilotActivity({
      activity: {
        ...data,
        duration: `${`0${durationParts[0]}`.slice(-2)}:${
          durationParts[1] ?? ''
        }00`.slice(0, 5),
      },
    });
    setActiveNewActivity(false);
    setActivityExpanded({});
    reset({});
  };

  const editActivity = (e, activity) => {
    e && e.preventDefault();
    setActiveNewActivity(false);
    setActivityExpanded(activity);
    reset({ ...activity });
  };

  return (
    <div className="my-6">
      <div className="flex items-center mb-4">
        <TypographyWithTooltip
          text="Actividades del piloto*"
          variant="h3"
          textTooltip="Actividades del piloto"
          className="text-primary"
          idTooltip="criterios"
          applyFontBold
        />

        {activities?.length > 0 && (
          <DefaultBadge
            label={`Duración total del piloto: ${totalPilotDuration} minutos`}
            type="light"
            className="ml-4"
          />
        )}
      </div>

      <Accordion allowZeroExpanded>
        {activities?.length > 0 &&
          _.map(activities, (activity) => {
            return (
              <div className="mt-2 bg-white" key={`activity-${activity.id}`}>
                <AccordionItem
                  dangerouslySetExpanded={activityExpanded?.id === activity.id}
                >
                  <div className="flex items-center justify-between px-6 py-2">
                    <div className="flex items-center mb-2">
                      <TypographyComponent
                        variant="p"
                        text={activity.name}
                        className="flex-grow text-primary font-bold"
                      />
                      <DefaultBadge
                        label={`Duración: ${getDurationTimeInMinutes({
                          duration: activity.duration,
                        })} minutos`}
                        type="light"
                        className="ml-4"
                      />
                    </div>

                    <StyledContentButtons>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton>
                            <ButtonComponent
                              text=""
                              className={canEditForm ? 'icon-edit' : ''}
                              icon={
                                canEditForm ? (
                                  <></>
                                ) : (
                                  <div
                                    className={`icon-arrow-down text-neutral-900 text-xxs ${
                                      expanded && 'transform rotate-180'
                                    }`}
                                  />
                                )
                              }
                              variant="white"
                              onPress={(e) =>
                                expanded
                                  ? cancelActivity(e)
                                  : editActivity(e, activity)
                              }
                            />
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                      {canEditForm && (
                        <ButtonComponent
                          text=""
                          className="icon-delete"
                          variant="white"
                          onPress={() => deletePlanPilotActivity(activity)}
                        />
                      )}
                    </StyledContentButtons>
                  </div>
                  <AccordionItemPanel>
                    <FormActivity
                      control={control}
                      errors={errors}
                      cancelActivity={cancelActivity}
                      handleSubmit={handleSubmit}
                      saveActivity={saveActivity}
                      canEditForm={canEditForm}
                    />
                  </AccordionItemPanel>
                </AccordionItem>
              </div>
            );
          })}
      </Accordion>

      {canEditForm && !activeNewActivity && (
        <>
          <ButtonComponent
            variant="primary"
            mode="outline"
            text="Crear nueva actividad"
            className="mb-2 border-none shadow-none mt-6"
            icon={<div className="icon-close transform rotate-45" />}
            onPress={openFormNewActivity}
          />
        </>
      )}

      {activeNewActivity && (
        <FormActivity
          control={control}
          errors={errors}
          cancelActivity={cancelActivity}
          handleSubmit={handleSubmit}
          saveActivity={saveActivity}
          canEditForm={canEditForm}
        />
      )}
      <StyledDivider />
      <TypographyWithTooltip
        text="Herramientas para el piloto*"
        variant="h3"
        textTooltip="Herramientas para el piloto"
        className="text-primary"
        idTooltip="creiterios"
        applyFontBold
      />

      <TypographyComponent
        text="Comparte un documento que especifique todos los recursos y herramientas necesarios para realizar el piloto"
        variant="p"
        className="text-neutral-950 mt-2"
      />

      {canEditForm && (!watchForm('toolFile') || errorsForm['toolFile']) && (
        <Controller
          control={controlForm}
          name="toolFile"
          render={({ field: { onChange, name } }) => {
            return (
              <InputFile
                name={name}
                accept={'.pdf'}
                title={
                  <div className="flex items-center">
                    <div className="icon-paper-clip text-primary text-xl font-bold h-10" />
                    <TypographyComponent
                      text="Adjuntar archivo"
                      variant="p"
                      className={`text-primary font-bold ${
                        errorsForm[name] && 'text-error'
                      }`}
                    />
                  </div>
                }
                onChange={onChange}
                className={`w-full mt-8 bg-none mb-4 ${
                  errorsForm[name] && 'border-error'
                }`}
                errors={errorsForm}
              />
            );
          }}
        />
      )}

      {(!canEditForm ||
        (watchForm('toolFile') && !errorsForm['toolFile']) ||
        currentFile?.id) && (
        <div className="border rounded mt-8 px-6 pb-8 bg-neutral-300">
          <StyledWrapperCommunity nonemb>
            <StyledCardCommunity>
              <TypographyComponent
                variant="p"
                text="Herramientas.pdf"
                className="flex-grow text-primary font-bold cursor-pointer"
              />
              <StyledButtonsActions className="flex items-center">
                <ButtonComponent
                  text=""
                  className="icon-view"
                  onPress={(e) =>
                    openToolsFile({ e, watchFile: watchForm('toolFile') })
                  }
                />
                {(!currentFile?.id || watchForm('toolFile')) && (
                  <ButtonComponent
                    text=""
                    className="icon-delete"
                    onPress={deleteToolsFile}
                  />
                )}
                {currentFile?.id && !watchForm('toolFile') && (
                  <ButtonComponent
                    text=""
                    icon={
                      <div className="icon-download text-primary text-lg" />
                    }
                    onPress={downloadToolsFile}
                  />
                )}
              </StyledButtonsActions>
            </StyledCardCommunity>
          </StyledWrapperCommunity>
        </div>
      )}

      <div className="flex items-center space-x-2 mt-10 mb-6 justify-center">
        <ButtonComponent
          text="Anterior"
          mode="outline"
          onPress={() => setActiveStep(1)}
        />
        {canEditForm && (
          <ButtonComponent
            text="Validar piloto"
            onPress={openConfirmationModal}
          />
        )}
      </div>
    </div>
  );
};

Activities.propTypes = {
  savePlanPilotActivity: PropTypes.func.isRequired,
  deletePlanPilotActivity: PropTypes.func.isRequired,
  openConfirmationModal: PropTypes.func.isRequired,
  watchForm: PropTypes.func,
  deleteToolsFile: PropTypes.func.isRequired,
  openToolsFile: PropTypes.func.isRequired,
  downloadToolsFile: PropTypes.func.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  controlForm: PropTypes.object.isRequired,
  errorsForm: PropTypes.object.isRequired,
  activities: PropTypes.array,
  canEditForm: PropTypes.bool,
  currentFile: PropTypes.object,
};

export default Activities;
