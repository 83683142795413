import React from 'react';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';
import useHelpers from 'helpers';

//Styles
import { StyledButtonsActions } from 'styles/buttons/buttons.styles';
import {
  StyledWrapperCommunity,
  StyledCardCommunity,
} from '../../../../../../../Dashboard.styles';
import { StyledContentButtonsModal } from 'styles/tableViews/tableViews.styles';
import { StyledDivider } from 'styles/formViews/formViews.styles';

const PilotEvaluate = () => {
  const { useComponents } = useViews();
  const { useTypographies, useButtons, useModals, useInputs, useCards } =
    useComponents();

  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { ButtonComponent } = useButtons();
  const { DefaultModal } = useModals();
  const { TextArea, InputFile } = useInputs();
  const { CardNotification } = useCards();

  const { useQuickFunctions } = useHelpers();
  const { getDocumentName } = useQuickFunctions();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsDevelopmentIntervention } =
    useAdminViewInterventionsControllers();
  const { useViewDevelopmentInterventionCreateSketch } =
    useAdminViewInterventionsDevelopmentIntervention();
  const { useViewDevelopmentInterventionCreateSketchAdmin } =
    useViewDevelopmentInterventionCreateSketch();
  const { useAdminAndArtistEvaluatePilotIntervention } =
    useViewDevelopmentInterventionCreateSketchAdmin();
  const {
    evaluatePilot,
    formEvaluatePilot,
    showModalApprove,
    setShowModalApprove,
    stepCompleted,
    openFileEvaluatePilotIntervention,
    downloadFileEvaluatePilotIntervention,
    deleteFileEvaluatePilotIntervention,
    updateEvaluatePilotIntervention,
  } = useAdminAndArtistEvaluatePilotIntervention();

  const {
    handleSubmit,
    control,
    register,
    watch,
    formState: { errors },
  } = formEvaluatePilot;

  return (
    <>
      {(stepCompleted ||
        _.includes(['p', 'ip', 'ir'], evaluatePilot.state?.id)) && (
        <CardNotification
          className="w-5/12 mx-auto py-9 px-4 mt-16"
          title={
            <TypographyComponent
              text={evaluatePilot.state?.name}
              variant="h2"
              className="font-bold text-primary icon-watch text-center"
            />
          }
        />
      )}
      <TypographyComponent
        text="Evaluar piloto de la intervención"
        variant="h2"
        className="font-bold text-primary mt-6"
      />
      <TypographyComponent
        text='Llego el momento de hacer retroalimentación de este proceso, a partir del documento  "criterios de evaluación  del piloto" compartido con tigo anteriormente'
        variant="p"
        className="text-neutral-950 mt-2"
      />

      {evaluatePilot.state?.id !== 'ip' && (
        <>
          <TypographyWithTooltip
            text="Evaluación del artista*"
            variant="h3"
            textTooltip="Evaluación del artista"
            className="text-primary  "
            idTooltip="creiterios"
            applyFontBold
            classNameDiv=" mt-8"
          />
          <TextArea
            name="artist"
            placeholder="Textarea placeholder"
            className="w-full col-span-2"
            label=""
            {...register('artist')}
            errors={errors}
            disabled
          />
          <TypographyWithTooltip
            text="Evaluación de la comunidad*"
            variant="h3"
            textTooltip="Evaluación de la comunidad"
            className="text-primary"
            idTooltip="creiterios"
            applyFontBold
            classNameDiv=" mt-8"
          />
          <TextArea
            name="community"
            placeholder="Textarea placeholder"
            className="w-full col-span-2"
            label=""
            {...register('community')}
            errors={errors}
            disabled
          />
          <TypographyWithTooltip
            text="Documentos"
            variant="h3"
            textTooltip='Adjunta aquí el documento "criterios de evaluacion" diligenciado'
            className="text-primary"
            idTooltip="documents"
            applyFontBold
            classNameDiv=" mt-8"
          />
          {evaluatePilot.documents?.length > 0 && (
            <StyledWrapperCommunity nonemb>
              {_.map(evaluatePilot.documents, (file) => {
                return (
                  <StyledCardCommunity key={`document-${file.id}`}>
                    <TypographyComponent
                      variant="p"
                      text={getDocumentName({ file, withExtension: true })}
                      className="flex-grow text-primary font-bold "
                    />
                    <StyledButtonsActions className="flex items-center">
                      <ButtonComponent
                        text=""
                        className="icon-view"
                        onPress={(e) => {
                          openFileEvaluatePilotIntervention({ e, file });
                        }}
                      />
                      <ButtonComponent
                        text=""
                        icon={
                          <div className="icon-download text-primary text-lg" />
                        }
                        onPress={(e) => {
                          downloadFileEvaluatePilotIntervention({ e, file });
                        }}
                      />
                    </StyledButtonsActions>
                  </StyledCardCommunity>
                );
              })}
            </StyledWrapperCommunity>
          )}
          <StyledDivider />

          <TypographyComponent
            text="Evaluación del Socio ejecutor*"
            variant="h3"
            className="font-bold text-primary "
          />
          <TypographyComponent
            text="Comparte tus observaciones sobre la participación y reacción de la comunidad en cuanto al piloto."
            variant="p"
            className="text-neutral-950 mt-2 mb-4"
          />
          <TextArea
            name="partner"
            placeholder="Escribe tu evaluación del piloto"
            className="w-full col-span-2 mb-6"
            label=""
            {...register('partner')}
            errors={errors}
            disabled={stepCompleted}
          />

          <TypographyComponent
            text="Adjuntar documentos*"
            variant="h3"
            className="font-bold text-primary "
          />
          <TypographyComponent
            text='Adjunta aquí el documento "criterios de evaluacion" diligenciado'
            variant="p"
            className="text-neutral-950 mt-2 mb-4"
          />
          {((watch('fileAdmin') && !errors['fileAdmin']) ||
            evaluatePilot.document_partner?.id) && (
            <StyledWrapperCommunity nonemb>
              <StyledCardCommunity>
                <TypographyComponent
                  variant="p"
                  text={
                    watch('fileAdmin')
                      ? watch('fileAdmin').name
                      : getDocumentName({
                          file: evaluatePilot.document_partner,
                          withExtension: true,
                        })
                  }
                  className="flex-grow text-primary font-bold cursor-pointer"
                />
                <StyledButtonsActions className="flex items-center">
                  <ButtonComponent
                    text=""
                    className="icon-view"
                    onPress={(e) => {
                      openFileEvaluatePilotIntervention({
                        e,
                        file: evaluatePilot.document_partner,
                        watchFile: watch('fileAdmin'),
                      });
                    }}
                  />
                  {!stepCompleted && (
                    <ButtonComponent
                      text=""
                      className="icon-delete"
                      onPress={(e) => {
                        deleteFileEvaluatePilotIntervention({
                          e,
                          fieldToDelete: 'fileAdmin',
                        });
                      }}
                    />
                  )}
                  {stepCompleted && (
                    <ButtonComponent
                      text=""
                      icon={
                        <div className="icon-download text-primary text-lg" />
                      }
                      onPress={(e) => {
                        downloadFileEvaluatePilotIntervention({
                          e,
                          file: evaluatePilot.document_partner,
                        });
                      }}
                    />
                  )}
                </StyledButtonsActions>
              </StyledCardCommunity>
            </StyledWrapperCommunity>
          )}
          {(!watch('fileAdmin') || errors['fileAdmin']) &&
            !evaluatePilot.document_partner?.id && (
              <Controller
                control={control}
                name="fileAdmin"
                render={({ field: { onChange, name } }) => {
                  return (
                    <InputFile
                      name={name}
                      accept={'.pdf'}
                      title={
                        <div className="flex items-center">
                          <div className="icon-paper-clip text-primary text-xl font-bold h-10" />
                          <TypographyComponent
                            text="Adjuntar archivo"
                            variant="p"
                            className={`text-primary font-bold ${
                              errors[name] && 'text-error'
                            }`}
                          />
                        </div>
                      }
                      onChange={onChange}
                      className={`w-full mt-2 bg-none mb-4 ${
                        errors[name] && 'border-error'
                      }`}
                      errors={errors}
                    />
                  );
                }}
              />
            )}

          {!stepCompleted && (
            <>
              <div className="flex items-center space-x-2 mt-10 mb-6 justify-center">
                <ButtonComponent
                  text="Finalizar y avanzar a la siguiente etapa"
                  onPress={handleSubmit(() => setShowModalApprove(true))}
                />
              </div>

              <DefaultModal
                isActive={showModalApprove}
                classNameContent="max-w-screen-md w-11/12"
                handleClose={() => {
                  setShowModalApprove(false);
                }}
              >
                <TypographyComponent
                  text={'Enviar evaluación del piloto al artista'}
                  variant="h3"
                  className="font-bold text-primary mb-1"
                />

                <StyledContentButtonsModal>
                  <ButtonComponent
                    variant="primary"
                    text="Cancelar"
                    className="mx-auto w-full lg:w-auto lg:mx-2 "
                    mode="outline"
                    onPress={() => setShowModalApprove(false)}
                  />
                  <ButtonComponent
                    variant="primary"
                    text="Aceptar"
                    className="mx-auto w-full lg:w-auto  lg:mx-2"
                    onPress={handleSubmit(updateEvaluatePilotIntervention)}
                  />
                </StyledContentButtonsModal>
              </DefaultModal>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PilotEvaluate;
