import React, { Suspense, lazy } from 'react';
import ReactTooltip from 'react-tooltip';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

// Hooks
import useViews from 'views';
import useControllers from 'controllers';

// Components
import Artists from '../Artists';

//Assets
import infoIcon from 'assets/icons/info.svg';

//Styles
const StyledBackButton = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);

const StyledGroup = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledForm = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledForm,
  }))
);

const StyledDivider = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledDivider,
  }))
);

const StyledContentButtons = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const StyledComposedInput = lazy(() =>
  import('../Edit/Edit.styles').then((mod) => ({
    default: mod.StyledComposedInput,
  }))
);

const StyledValueArtist = lazy(() =>
  import('../Edit/Edit.styles').then((mod) => ({
    default: mod.StyledValueArtist,
  }))
);

const AdminDashboardCreateInterventions = () => {
  const { useComponents } = useViews();

  const {
    useInputs,
    useButtons,
    useTypographies,
    useImages,
    useBoxes,
    useLabels,
    useModals,
    useLoaders,
  } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { InputForm, Select, TextArea, CheckBox } = useInputs();
  const { ButtonComponent } = useButtons();
  const { TypographyComponent } = useTypographies();
  const { Image, UploadImagePreview } = useImages();
  const { Box } = useBoxes();
  const { DefaultLabel } = useLabels();
  const { DefaultModal, UserInfoModal } = useModals();

  const { useGeneralHooks } = useControllers();
  const { useSelectLayoutByRole } = useGeneralHooks();
  const { LayoutSelected, props } = useSelectLayoutByRole();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminCreateInterventionsDashboard } =
    useAdminInterventionsDashboardControllers();
  const {
    control,
    errors,
    handleGoBack,
    handleResetInputFile,
    handleRegisterIntervention,
    handleSubmit,
    register,
    optionsTypes,
    optionsObjetives,
    definition_behaviors,
    showTextArea,
    handleOnChange,
    showAssignArtistModal,
    handleShowAssignArtistModal,
    handleOpenModalSelectArtist,
    handleSetSelectedArtist,
    selectedArtist,
    communitiesList,
    handleViewProfileArtist,
    showModalUser,
    setShowModalUser,
    userInfo,
  } = useAdminCreateInterventionsDashboard();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <LayoutSelected {...props}>
        <StyledBackButton className="mt-3" onClick={handleGoBack}>
          Regresar
        </StyledBackButton>

        <Box className="bg-neutral-200 w-11/12 mx-auto max-w-screen-lg lg:px-16 lg:py-12 px-6 py-8 lg:my-8">
          <>
            <StyledGroup flex className="items-center mb-4">
              <TypographyComponent
                text="Crear una intervención"
                variant="h2"
                className="font-bold text-primary"
              />
              <a data-tip="New user" data-for="New user">
                <Image
                  className="w-6 hidden lg:block ml-4"
                  srcImage={infoIcon}
                  altImage="info"
                  titleImage="info"
                />
              </a>
              <ReactTooltip id="Crear una intervención" />
            </StyledGroup>

            <StyledForm>
              <InputForm
                name="name"
                label="Nombre de la intervención*"
                placeholder="Ingresa el nombre de la intervención"
                errors={errors}
                {...register('name')}
              />

              <StyledDivider />

              <TypographyComponent
                text={
                  <label>
                    <span className="font-bold">
                      Agregar imagen de portada de la intervención
                    </span>
                    <span className="text-neutral-800 ml-2 font-light">
                      Formato de archivo permitido JPG, PNG, JPEG
                    </span>
                  </label>
                }
                variant="p"
                className="text-neutral-950 mb-10 w-full"
              />

              <StyledGroup flex>
                <div>
                  <Controller
                    control={control}
                    name="image"
                    render={({ field: { onChange, name } }) => {
                      return (
                        <UploadImagePreview
                          name={name}
                          onChange={onChange}
                          errors={errors}
                          resetInput={() => {
                            handleResetInputFile({ inputFileName: 'image' });
                          }}
                          srcPlaceholderOnlyImage
                        />
                      );
                    }}
                  />
                </div>
              </StyledGroup>

              <StyledGroup noSpace>
                <Select
                  name="communities"
                  id="communities"
                  control={control}
                  options={communitiesList}
                  label="Comunidad(es) de la intervención*"
                  placeholder="Selecciona la(s) comunidad(es) de la intervención"
                  errors={errors}
                  multiple
                />
                <div>
                  <DefaultLabel>Artista*</DefaultLabel>
                  {selectedArtist ? (
                    <StyledComposedInput>
                      <StyledValueArtist>
                        {selectedArtist.name} {selectedArtist.last_name}
                        <StyledContentButtons>
                          <ButtonComponent
                            text=""
                            className="icon-view text-primary-100"
                            variant="primary"
                            onPress={handleViewProfileArtist}
                          />
                          <ButtonComponent
                            text=""
                            className="icon-edit text-primary-100"
                            variant="primary"
                            onPress={handleOpenModalSelectArtist}
                          />
                        </StyledContentButtons>
                      </StyledValueArtist>
                    </StyledComposedInput>
                  ) : (
                    <ButtonComponent
                      text="Seleccionar artista"
                      className="mx-auto w-full"
                      mode="outline"
                      onPress={handleOpenModalSelectArtist}
                    />
                  )}
                  {errors['user_id'] && (
                    <p className="text-sm mt-2 text-error">
                      {errors['user_id'].message}
                    </p>
                  )}
                </div>
              </StyledGroup>

              <TextArea
                name="description"
                placeholder="Escribe aquí la descripción"
                className="w-full col-span-2 pb-2"
                label="Descripción de la intervención*"
                errors={errors}
                {...register('description')}
              />

              <StyledGroup noSpace>
                <Select
                  name="objetive"
                  id="objetive"
                  control={control}
                  label="Objetivo de la intervención*"
                  placeholder="Selecciona el objetivo de la intervención"
                  options={optionsTypes}
                  errors={errors}
                />
                <Select
                  name="type"
                  id="type"
                  control={control}
                  label="Modalidad*"
                  placeholder="Selecciona la modalidad de la intervención"
                  options={optionsObjetives}
                  errors={errors}
                />
              </StyledGroup>
              <StyledGroup noSpace>
                <InputForm
                  name="start_date"
                  label="Fecha de inicio"
                  type="date"
                  placeholder="Selecciona la fecha de inicio"
                  errors={errors}
                  {...register('start_date')}
                />
                <InputForm
                  name="end_date"
                  label="Fecha final"
                  type="date"
                  placeholder="Selecciona la fecha final"
                  errors={errors}
                  {...register('end_date')}
                />
              </StyledGroup>
              <StyledGroup flex noSpace className="flex-col pb-4 ">
                <TypographyComponent
                  text="Definición y profundización del comportamiento"
                  variant="h3"
                  className="font-bold text-neutral-900 mt-6 mb-2"
                />
              </StyledGroup>

              <section>
                <TypographyComponent
                  text="¿Qué comportamiento se movilizará en esta región por medio de la intervención?"
                  variant="p"
                  className="font-bold text-neutral-950 w-full"
                />
                {_.map(definition_behaviors, (check) => {
                  return (
                    <CheckBox
                      key={`Accessility-${check.id}`}
                      className="mt-4"
                      name="definition_behaviors"
                      label={check.name}
                      value={`${check.id}`}
                      {...register('definition_behavior')}
                      onChange={handleOnChange}
                      errors={errors}
                      dontShowErrorMessage
                    />
                  );
                })}
                {showTextArea && (
                  <TextArea
                    name="other_definition"
                    placeholder="Escribe qué otro comportamiento se movilizará"
                    className="w-full col-span-2 pb-2 -pt-4 mt-6"
                    errors={errors}
                    {...register('other_definition')}
                  />
                )}
                {errors['definition_behavior'] && (
                  <p className="text-sm mt-2 text-error">
                    {errors['definition_behavior'].message}
                  </p>
                )}
              </section>

              <StyledGroup flex className="items-start mt-4 flex-col">
                <div className="flex items-center mb-2 mt-4">
                  <DefaultLabel>Grupo protagonista</DefaultLabel>
                  <a
                    data-tip="Grupo protagonista"
                    data-for="Grupo protagonista"
                  >
                    <Image
                      className="w-6 hidden lg:block ml-2"
                      srcImage={infoIcon}
                      altImage="info"
                      titleImage="info"
                    />
                  </a>
                  <ReactTooltip id="Grupo protagonista" />
                </div>
                <TextArea
                  name="leading_group"
                  placeholder="Escribe aquí el grupo protagonista"
                  className="w-full col-span-2 pb-2 -pt-4"
                  errors={errors}
                  {...register('leading_group')}
                />
              </StyledGroup>

              <StyledGroup flex className="items-start mt-4 flex-col">
                <div className="flex items-center mb-2 mt-2">
                  <DefaultLabel>Grupo Aliado</DefaultLabel>
                  <a data-tip="Grupo Aliado" data-for="Grupo Aliado">
                    <Image
                      className="w-6 hidden lg:block ml-2"
                      srcImage={infoIcon}
                      altImage="info"
                      titleImage="info"
                    />
                  </a>
                  <ReactTooltip id="Grupo Aliado" />
                </div>
                <TextArea
                  name="allied_group"
                  placeholder="Escribe aquí el grupo aliado"
                  className="w-full col-span-2 pb-2 -pt-4"
                  errors={errors}
                  {...register('allied_group')}
                />
              </StyledGroup>

              <ButtonComponent
                text="Crear intervención"
                className="mx-auto"
                onPress={handleSubmit(handleRegisterIntervention)}
              />
            </StyledForm>
          </>
        </Box>
      </LayoutSelected>
      {showAssignArtistModal && (
        <DefaultModal
          isActive={showAssignArtistModal}
          classNameContent="max-w-screen-sm lg:w-5/12 xl:w-4/12"
          handleClose={handleShowAssignArtistModal}
        >
          <TypographyComponent
            text="Selección de artista"
            variant="h3"
            className="font-bold text-primary mb-1"
          />

          <Artists
            artistSelectedByDefault={selectedArtist?.id}
            setSelectedArtist={handleSetSelectedArtist}
            handleCloseModal={handleShowAssignArtistModal}
          />
        </DefaultModal>
      )}
      {showModalUser && (
        <UserInfoModal
          viewUser={showModalUser}
          setViewUser={setShowModalUser}
          user={userInfo}
        />
      )}
    </Suspense>
  );
};

export default AdminDashboardCreateInterventions;
