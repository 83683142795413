//Hooks
import useApi from '../../../..';
import useControllers from '../../../../../controllers';
import useStrings from '../../../../../strings';

const useAdminRecommendationsActions = () => {
  const { useAdminTypes } = useStrings();
  const { useAdminProjectsTypes } = useAdminTypes();
  const { useAdminRecommendationsTypes } = useAdminProjectsTypes();
  const {
    CREATE_RECOMMENDATIONS,
    GET_RECOMMENDATIONS,
    UPDATE_RECOMMENDATIONS,
    DELETE_RECOMMENDATION,
  } = useAdminRecommendationsTypes();

  const { useProviders } = useApi();
  const { useAdminProviders } = useProviders();
  const { useAdminProjectsProviders } = useAdminProviders();
  const { useAdminRecommendationsProviders } = useAdminProjectsProviders();
  const {
    createRecommendations,
    getRecommendations,
    updateRecommendations,
    deleteRecommendations,
  } = useAdminRecommendationsProviders();

  const { useGeneralHooks } = useControllers();
  const { useConsole } = useGeneralHooks();
  const { viewConsole } = useConsole();

  const actGetRecommendations =
    ({ projectId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await getRecommendations({
          projectId,
        });
        dispatch({
          type: GET_RECOMMENDATIONS,
          payload: res.data?.data.recommendations,
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actCreateRecommendations =
    ({ recommendation, projectId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const bodyFormData = new FormData();
        bodyFormData.append('recommendation', recommendation);

        const res = await createRecommendations({
          formData: bodyFormData,
          projectId,
        });
        dispatch({ type: CREATE_RECOMMENDATIONS, payload: res.data?.data });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actUpdateRecommendations =
    ({ recommendation, projectId, recommendationId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const bodyFormData = new FormData();
        bodyFormData.append('recommendation', recommendation);
        bodyFormData.append('_method', 'patch');

        const res = await updateRecommendations({
          formData: bodyFormData,
          projectId,
          recommendationId,
        });
        dispatch({
          type: UPDATE_RECOMMENDATIONS,
          payload: { recommendationId, data: res.data?.data },
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actDeleteRecommendations =
    ({ projectId, recommendationId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await deleteRecommendations({
          projectId,
          recommendationId,
        });
        dispatch({
          type: DELETE_RECOMMENDATION,
          payload: { recommendationId },
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  return {
    actGetRecommendations,
    actCreateRecommendations,
    actUpdateRecommendations,
    actDeleteRecommendations,
  };
};

export default useAdminRecommendationsActions;
