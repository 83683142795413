//Packages
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useAdminDevelopmentInterventionDefineInterventionProviders = () => {
  const defineInterventionProvider = ({ intervention_id }) => {
    const response = axios({
      method: 'GET',
      url: `/interventions/${intervention_id}/definitions`,
    });
    return trackPromise(response);
  };
  const updateDefineInterventionProvider = ({
    intervention_id,
    phase_id,
    data,
  }) => {
    const response = axios({
      method: 'PATCH',
      url: `/interventions/${intervention_id}/definitions/${phase_id}`,
      data,
    });
    return trackPromise(response);
  };
  const updateDefineInterventionArtistProvider = ({
    intervention_id,
    phase_id,
    key_factors = [],
    formData,
  }) => {
    const response = axios({
      method: 'POST',
      url: `/interventions/${intervention_id}/definitions/${phase_id}`,
      data:
        key_factors.length === 0 ? formData : { key_factors, _method: 'PATCH' },
    });
    return trackPromise(response);
  };
  const addInterventionDefinitionProvider = ({
    intervention_id,
    definition_id,
    formData,
    definitionIdToEdit,
  }) => {
    const response = axios({
      method: 'POST',
      url: definitionIdToEdit
        ? `/interventions/${intervention_id}/definitions/${definition_id}/definition-intervention/${definitionIdToEdit}`
        : `/interventions/${intervention_id}/definitions/${definition_id}/definition-intervention`,
      data: formData,
    });
    return trackPromise(response);
  };

  const deleteInterventionByIdProvider = ({
    intervention_id,
    definition_id,
    definitionIdToDelete,
  }) => {
    const response = axios({
      method: 'DELETE',
      url: `/interventions/${intervention_id}/definitions/${definition_id}/definition-intervention/${definitionIdToDelete}`,
    });
    return trackPromise(response);
  };

  const createActivityInInterventionProvider = ({
    definition,
    definition_intervention,
    formData,
  }) => {
    const response = axios({
      method: 'POST',
      url: `/definitions/${definition}/definition-intervention/${definition_intervention}/activity`,
      data: formData,
    });
    return trackPromise(response);
  };

  const deleteActivityInInterventionProvider = ({
    definition,
    definition_intervention,
    activityId,
  }) => {
    const response = axios({
      method: 'DELETE',
      url: `/definitions/${definition}/definition-intervention/${definition_intervention}/activity/${activityId}`,
    });
    return trackPromise(response);
  };

  const editActivityInInterventionProvider = ({
    definition,
    definition_intervention,
    formData,
    activityId,
  }) => {
    const response = axios({
      method: 'POST',
      url: `/definitions/${definition}/definition-intervention/${definition_intervention}/activity/${activityId}`,
      data: formData,
    });
    return trackPromise(response);
  };

  const createLogisticMeanIntoInterventionProvider = ({
    definition,
    definition_intervention,
    describe,
    type,
  }) => {
    const response = axios({
      method: 'POST',
      url: `/definitions/${definition}/definition-intervention/${definition_intervention}/resource`,
      data: { describe, type },
    });
    return trackPromise(response);
  };

  const deleteLogisticMeanIntoInterventionProvider = ({
    definition,
    definition_intervention,
    logisticMeanId,
  }) => {
    const response = axios({
      method: 'DELETE',
      url: `/definitions/${definition}/definition-intervention/${definition_intervention}/resource/${logisticMeanId}`,
    });
    return trackPromise(response);
  };

  const editLogisticMeanIntoInterventionProvider = ({
    definition,
    definition_intervention,
    describe,
    type,
    logisticMeanId,
  }) => {
    const response = axios({
      method: 'POST',
      url: `/definitions/${definition}/definition-intervention/${definition_intervention}/resource/${logisticMeanId}`,
      data: { describe, type, _method: 'PATCH' },
    });
    return trackPromise(response);
  };

  const createAssignedTasksForInterventionProvider = ({
    interventionId,
    definitionId,
    name,
    description,
  }) => {
    const response = axios({
      method: 'POST',
      url: `/interventions/${interventionId}/definitions/${definitionId}/convocation-task`,
      data: {
        name,
        description,
      },
    });
    return trackPromise(response);
  };

  const editDiffusionActivitiesForInterventionProvider = ({
    interventionId,
    definitionId,
    taskId,
    name,
    description,
  }) => {
    const response = axios({
      method: 'PATCH',
      url: `/interventions/${interventionId}/definitions/${definitionId}/convocation-task/${taskId}`,
      data: { name, description },
    });
    return trackPromise(response);
  };

  const deleteDiffusionActivitiesForInterventionProvider = ({
    interventionId,
    definitionId,
    taskId,
  }) => {
    const response = axios({
      method: 'DELETE',
      url: `/interventions/${interventionId}/definitions/${definitionId}/convocation-task/${taskId}`,
    });
    return trackPromise(response);
  };

  const downloadDifusionFileInterventionProvider = ({
    intervention,
    definition,
    file,
  }) => {
    const response = axios({
      method: 'GET',
      responseType: 'arraybuffer',
      url: `/interventions/${intervention}/definitions/${definition}/file/${file}`,
    });
    return trackPromise(response);
  };

  return {
    defineInterventionProvider,
    updateDefineInterventionProvider,
    updateDefineInterventionArtistProvider,
    addInterventionDefinitionProvider,
    deleteInterventionByIdProvider,
    createActivityInInterventionProvider,
    deleteActivityInInterventionProvider,
    editActivityInInterventionProvider,
    createLogisticMeanIntoInterventionProvider,
    deleteLogisticMeanIntoInterventionProvider,
    editLogisticMeanIntoInterventionProvider,
    downloadDifusionFileInterventionProvider,
    createAssignedTasksForInterventionProvider,
    editDiffusionActivitiesForInterventionProvider,
    deleteDiffusionActivitiesForInterventionProvider,
  };
};

export default useAdminDevelopmentInterventionDefineInterventionProviders;
