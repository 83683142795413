import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const StyledTopFilters = styled.div.attrs(() => ({
  className: 'StyledTopFilters',
}))`
  ${tw` flex items-center justify-between`}
  ${(props) => props.justifyEnd && tw`justify-end `}
`;

export const StyledFilters = styled.div.attrs(() => ({
  className: 'StyledFilters',
}))`
  ${tw` flex items-start justify-end space-x-4`}
  .icon-filter {
    &:before {
      ${tw` mr-3`}
    }
  }
`;

export const StyledInputSearchContent = styled.div.attrs(() => ({
  className: 'StyledInputSearchContent',
}))`
  ${tw` relative `}
  .icon-search {
    &:before {
      ${tw` absolute top-0 left-0 z-10 text-neutral-700 text-lg mt-3 ml-4`}
    }
    input {
      ${tw` pl-12 w-80`}
    }
    .inputFormstyles__StyledWrapperInputIcon-sc-1kaxq5a-2 {
      &:before {
        ${tw` hidden`}
      }
    }
  }
  ${(props) =>
    props.fullWidth &&
    `
  .icon-search {
    input {
      width:100%;
    }
  }
  `}
`;

export const StyledContentTable = styled.div.attrs(() => ({
  className: 'StyledContentTable',
}))`
  ${tw` w-full mt-5`}

  ${(props) => props.minHeight && ` min-height: ${props.minHeight}rem; `}
`;

export const StyledContentButtons = styled.div.attrs(() => ({
  className: 'StyledContentButtons',
}))`
  ${tw` flex items-center`}
  button {
    ${tw` bg-transparent text-tertiary shadow-none border-none hover:bg-transparent px-0 py-0`}
    font-size:1.7142857142857142rem;
    &.icon-edit {
      font-size: 2.5rem;
      height: 28px;
      ${tw`  text-primary w-8`}
      &:before {
        ${tw`  h-full`}
      }
      &.icon-edit-disabled {
        ${tw` text-neutral-700`}
      }
    }

    &.icon-heart-empty {
      ${tw` text-neutral-700`}
    }
    &.icon-heart {
      ${tw` text-primary`}
    }
    &.icon-view {
      ${tw`  text-primary`}
    }
    &.icon-disabled {
      ${tw` text-neutral-700 cursor-default`}
    }
  }
`;

export const StyledContentButtonsModal = styled.div.attrs(() => ({
  className: 'StyledContentButtonsModal',
}))`
  ${tw` flex justify-center items-center mt-8`}

  ${(props) => props.justifyStart && tw`justify-start `}
`;

export const StyledBackButton = styled.button.attrs(() => ({
  className: 'StyledBackButton icon-arrow',
}))`
  ${tw` font-bold text-primary`}
  &:before {
    ${tw` text-secondary lg:mr-4 mr-2`}
    transform: rotate(180deg);
    font-weight: 700;
  }
`;

export const StyledTableTitle = styled.div.attrs(() => ({
  className: 'StyledTableTitle',
}))`
  ${tw`flex`}

  .order-arrow {
    transform: rotate(180deg);
  }
`;

export const StyledContentTableStiky = styled.div.attrs(() => ({
  className: 'StyledContentTableStiky',
}))`
  .table-sticky {
    ${tw`scrollbar-thin scrollbar-thumb-neutral-700 scrollbar-thumb-rounded`}
    .tr {
      :last-child {
        .td {
          ${tw`border-b-0`}
        }
      }
      :nth-child(even) {
        .td {
          ${tw`bg-neutral-300`}
        }
      }
    }

    .th,
    .td {
      ${tw`bg-white overflow-hidden p-8 pt-4 pb-2`}

      :last-child {
        ${tw`border-r-0`}
      }
    }

    &.sticky-table {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        width: fit-content;
      }

      .header {
        top: 0;
        ${tw`border-b`}
      }

      .footer {
        bottom: 0;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }
    }
  }
`;
