import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import qs from 'qs';

const useAdminProjectsRootProviders = () => {
  const getProjectsList = ({
    paginate,
    page = 1,
    name,
    country_id,
    department_id,
    city_id,
    project_manager_id,
    social_expert_id,
    executing_partner_id,
    owner_id,
    orderByKey,
    orderByValue,
  }) => {
    const response = axios({
      method: 'GET',
      url: '/projects',
      params: {
        paginate,
        page,
        name: name || undefined,
        country_id,
        department_id,
        city_id,
        project_manager_id,
        social_expert_id,
        executing_partner_id,
        owner_id,
        orderBy: orderByKey ? { [orderByKey]: orderByValue } : undefined,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return trackPromise(response);
  };

  const getProjectById = ({ projectId }) => {
    const response = axios({
      method: 'GET',
      url: `/projects/${projectId}`,
    });
    return trackPromise(response);
  };

  const createProject = ({ formData }) => {
    const response = axios({
      method: 'POST',
      headers: {
        'content-type': 'multipart/form-data',
        'alert-dialog': 'hidden',
      },
      url: '/projects',
      data: formData,
    });

    return trackPromise(response);
  };

  const updateProjectById = ({ projectId, formData }) => {
    const response = axios({
      method: 'POST',
      headers: {
        'content-type': 'multipart/form-data',
        'alert-dialog': 'hidden',
      },
      url: `/projects/${projectId}`,
      data: formData,
    });

    return trackPromise(response);
  };

  const deleteProjectById = ({ projectId }) => {
    const response = axios({
      method: 'DELETE',
      url: `/projects/${projectId}`,
    });

    return trackPromise(response);
  };

  return {
    getProjectsList,
    createProject,
    getProjectById,
    updateProjectById,
    deleteProjectById,
  };
};

export default useAdminProjectsRootProviders;
