//Packages
import React, { Suspense, lazy } from 'react';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
const StyledBackButton = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);

const StyledGroup = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledForm = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledForm,
  }))
);

const StyledDivider = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledDivider,
  }))
);

const AdminDashboardCreateCommunities = () => {
  const { useComponents } = useViews();
  const {
    useInputs,
    useButtons,
    useTypographies,
    useImages,
    useBoxes,
    useLoaders,
  } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { InputForm, Select, Radio, TextArea, CheckBox } = useInputs();
  const { ButtonComponent } = useButtons();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { UploadImagePreview } = useImages();
  const { Box } = useBoxes();

  const { useScreenHooks, useGeneralHooks } = useControllers();
  const { useSelectLayoutByRole } = useGeneralHooks();
  const { LayoutSelected, props } = useSelectLayoutByRole();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminCommunitiesDashboardHooks } =
    useAdminDashboardProjectsHooks();
  const { useAdminCreateCommunitiesDashboard } =
    useAdminCommunitiesDashboardHooks();
  const {
    control,
    errors,
    register,
    handleSubmit,
    optionsLanguages,
    handleRegisterCommunity,
    handleResetInputFile,
    handleGoBack,
    projectData,
    accessibilitiesList,
  } = useAdminCreateCommunitiesDashboard();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <LayoutSelected {...props}>
        <StyledBackButton className="mt-3" onClick={handleGoBack}>
          Regresar
        </StyledBackButton>

        <Box className="bg-neutral-200 w-11/12 mx-auto max-w-screen-lg lg:px-16 lg:py-12 px-6 py-8 lg:my-8">
          <>
            <StyledGroup flex className="items-center mb-4">
              <TypographyComponent
                text="Crear una comunidad"
                variant="h2"
                className="font-bold text-primary"
              />
              <TypographyWithTooltip
                text=""
                variant="p"
                textTooltip="Crear una comunidad"
                idTooltip="role"
                className="text-neutral-950"
              />
            </StyledGroup>

            <StyledForm>
              <InputForm
                name="name"
                label="Nombre de la comunidad*"
                placeholder="Ingresa el nombre de la comunidad"
                errors={errors}
                {...register('name')}
              />

              <StyledDivider />

              <TypographyComponent
                text={
                  <label>
                    <span className="font-bold">
                      Agregar imagen de portada de la comunidad
                    </span>
                    <span className="text-neutral-800 ml-2 font-light">
                      Formato de archivo permitido JPG,PNG,JPEG
                    </span>
                  </label>
                }
                variant="p"
                className="text-neutral-950 mb-10 w-full"
              />

              <StyledGroup flex>
                <div>
                  <Controller
                    control={control}
                    name="image"
                    render={({ field: { onChange, name } }) => {
                      return (
                        <UploadImagePreview
                          name={name}
                          onChange={onChange}
                          errors={errors}
                          resetInput={() => {
                            handleResetInputFile({ inputFileName: 'image' });
                          }}
                          srcPlaceholderOnlyImage
                        />
                      );
                    }}
                  />
                </div>
              </StyledGroup>

              <StyledGroup noSpace>
                <Select
                  name="country"
                  id="country"
                  control={control}
                  label="País*"
                  placeholder="Selecciona tu país*"
                  errors={errors}
                  defaultValue={{
                    label: projectData.city.department.country.name,
                    value: projectData.city.department.country.id,
                  }}
                  disabled
                />
                <Select
                  name="department"
                  id="department"
                  control={control}
                  label="Departamento o Estado*"
                  placeholder="Selecciona el Dpto./Estado"
                  errors={errors}
                  defaultValue={{
                    label: projectData.city.department.name,
                    value: projectData.city.department.id,
                  }}
                  disabled
                />
              </StyledGroup>
              <StyledGroup noSpace>
                <Select
                  name="city_id"
                  id="city"
                  control={control}
                  label="Ciudad o Municipio*"
                  placeholder="Selecciona tu ciudad*"
                  errors={errors}
                  defaultValue={{
                    label: projectData.city.name,
                    value: projectData.city.id,
                  }}
                  disabled
                />
                <InputForm
                  name="population_number"
                  label="Población"
                  type="number"
                  placeholder="Ingresa la población"
                  errors={errors}
                  {...register('population_number')}
                />
              </StyledGroup>
              <StyledGroup noSpace>
                <Select
                  name="language"
                  id="language"
                  control={control}
                  options={optionsLanguages}
                  label="Idioma*"
                  placeholder="Selecciona tu idioma"
                  errors={errors}
                />
              </StyledGroup>
              <TypographyComponent
                text="¿Cuál es el nivel de alfabetización de la comunidad?"
                variant="p"
                className="font-bold text-neutral-950 mb-10 w-full"
              />
              <Radio
                name="reading_level"
                label="Alto"
                value="high"
                {...register('reading_level')}
                errors={errors}
              />
              <Radio
                name="reading_level"
                label="Medio"
                value="mid"
                {...register('reading_level')}
                errors={errors}
              />
              <Radio
                name="reading_level"
                label="Bajo"
                value="low"
                {...register('reading_level')}
                errors={errors}
              />
              {errors['reading_level'] && (
                <p name="reading_level" className="text-sm mt-2 text-error">
                  {errors['reading_level'].message}
                </p>
              )}
              <TypographyComponent
                text="¿Cuál es el nivel de acceso a internet?"
                variant="p"
                className="font-bold text-neutral-950 mb-10 w-full"
              />
              <Radio
                name="has_internet"
                label="Alto"
                value="high"
                {...register('has_internet')}
                errors={errors}
              />
              <Radio
                name="has_internet"
                label="Medio"
                value="mid"
                {...register('has_internet')}
                errors={errors}
              />
              <Radio
                name="has_internet"
                label="Bajo"
                value="low"
                {...register('has_internet')}
                errors={errors}
              />
              {errors['has_internet'] && (
                <p name="has_internet" className="text-sm mt-2 text-error">
                  {errors['has_internet'].message}
                </p>
              )}
              <TypographyComponent
                text="¿Cuál es el nivel de acceso a dispositivos?"
                variant="p"
                className="font-bold text-neutral-950 mb-10 w-full"
              />
              <Radio
                name="device_access"
                label="Alto"
                value="high"
                {...register('device_access')}
                errors={errors}
              />
              <Radio
                name="device_access"
                label="Medio"
                value="mid"
                {...register('device_access')}
                errors={errors}
              />
              <Radio
                name="device_access"
                label="Bajo"
                value="low"
                {...register('device_access')}
                errors={errors}
              />
              {errors['device_access'] && (
                <p className="text-sm mt-2 text-error">
                  {errors['device_access'].message}
                </p>
              )}
              <section>
                <TypographyComponent
                  text="Medios de comunicación e información"
                  variant="p"
                  className="font-bold text-neutral-950 w-full"
                />
                {_.map(accessibilitiesList, (accessibility) => {
                  return (
                    <CheckBox
                      key={`Accessility-${accessibility.id}`}
                      className="mt-4"
                      name="communication_medias"
                      label={accessibility.name}
                      value={`${accessibility.id}`}
                      errors={errors}
                      {...register('communication_medias')}
                      dontShowErrorMessage
                    />
                  );
                })}
              </section>
              {errors['communication_medias'] && (
                <p className="text-sm mt-2 text-error">
                  {errors['communication_medias'].message}
                </p>
              )}

              <TextArea
                name="general_comments"
                placeholder="Agregar comentarios acerca de los medios de comunicación e información"
                className="w-full col-span-2 pt-4 pb-2"
                label="Comentarios acerca de los medios de comunicación e información"
                errors={errors}
                {...register('general_comments')}
              />

              <TypographyComponent
                text={
                  <label>
                    <span className="font-bold">Agregar imágenes o videos</span>
                    <span className="text-neutral-800 ml-2 font-light">
                      Formato de archivo permitido JPG, PNG, JPEG, MP4, MOV
                    </span>
                  </label>
                }
                variant="p"
                className="text-neutral-950 mb-10 w-full"
              />

              <StyledGroup flex className="space-x-4 pb-8">
                <div>
                  <Controller
                    control={control}
                    name="main"
                    render={({ field: { onChange, name } }) => {
                      return (
                        <UploadImagePreview
                          name={name}
                          onChange={onChange}
                          errors={errors}
                          resetInput={() => {
                            handleResetInputFile({ inputFileName: 'main' });
                          }}
                        />
                      );
                    }}
                  />
                </div>
                <div>
                  <Controller
                    control={control}
                    name="first"
                    render={({ field: { onChange, name } }) => {
                      return (
                        <UploadImagePreview
                          name={name}
                          onChange={onChange}
                          errors={errors}
                          resetInput={() => {
                            handleResetInputFile({ inputFileName: 'first' });
                          }}
                        />
                      );
                    }}
                  />
                </div>
                <div>
                  <Controller
                    control={control}
                    name="second"
                    render={({ field: { onChange, name } }) => {
                      return (
                        <UploadImagePreview
                          name={name}
                          onChange={onChange}
                          errors={errors}
                          resetInput={() => {
                            handleResetInputFile({ inputFileName: 'second' });
                          }}
                        />
                      );
                    }}
                  />
                </div>
              </StyledGroup>

              <ButtonComponent
                text="Crear comunidad"
                className="mx-auto"
                onPress={handleSubmit(handleRegisterCommunity)}
              />
            </StyledForm>
          </>
        </Box>
      </LayoutSelected>
    </Suspense>
  );
};

export default AdminDashboardCreateCommunities;
