//Packages
import React from 'react';
import { Switch, useRouteMatch, Redirect } from 'react-router-dom';
import useNavigation from '../../..';

//Hooks
import useViews from '../../../../views';

//Screens
const { useScreens } = useViews();
const { AdminRootLabels } = useScreens();

const AdminLabelsRouter = () => {
  const { path } = useRouteMatch();

  const { useRoutes } = useNavigation();
  const { PrivateRoute } = useRoutes();

  return (
    <Switch>
      <PrivateRoute
        exact
        path={`${path}`}
        component={AdminRootLabels}
        redirect="/"
      />
      <Redirect to={`${path}`} />
    </Switch>
  );
};

export default AdminLabelsRouter;
