//Packages
import React, { lazy, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useHelpers from 'helpers';
import useControllers from 'controllers';

//Components
import DropDownFilter from './components/DropDownFilter/DropDownFilter';

//Assets
import arrowDown from 'assets/icons/arrow-down-table.svg';

//Styles
const StyledTopFilters = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledTopFilters,
  }))
);

const StyledFilters = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledFilters,
  }))
);

const StyledContentTable = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentTable,
  }))
);

const StyledContentButtonsModal = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const StyledTableTitle = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledTableTitle,
  }))
);

const List = (props) => {
  const {
    orderBy,
    setActiveItem,
    collectionMessagesList,
    dataForPagination,
    handleChangePage,
    handleDataToOrder,
    formFilterCollectionInformation,
    optionsCommunities,
    optionsContactLists,
    handleFilter,
    showFilterDropDown,
    handleVisibilityFilterDropDown,
    handleResetFilter,
  } = props;
  const { useComponents } = useViews();
  const {
    useButtons,
    useModals,
    useTypographies,
    useImages,
    useTables,
    usePagers,
    useBadges,
    useCards,
  } = useComponents();

  const { useGeneralHooks } = useControllers();
  const { useIsFilterEmpty } = useGeneralHooks();
  const { isFilterEmpty } = useIsFilterEmpty();

  const { useQuickFunctions } = useHelpers();
  const { dateExists } = useQuickFunctions();

  const { ButtonComponent } = useButtons();
  const { Table, TableCell, TableHead, TableBody, TableRow, TableHeadCell } =
    useTables();
  const { DefaultBadge } = useBadges();
  const { TypographyComponent } = useTypographies();
  const { Image } = useImages();
  const { Pager } = usePagers();
  const { CardEmptyState } = useCards();

  const { DefaultModal } = useModals();

  const [activeDeleteModal, setActiveDeleteModal] = useState({ active: false });

  const tableHeader = [
    { header: 'Canal de comunicación', orderByKey: 'channel' },
    { header: 'Destino ', orderByKey: 'communityReceivers', noneOrder: true },
    { header: 'Tipo de recolección', orderByKey: 'gi_type' },
    { header: 'Estado', orderByKey: 'type', noneOrder: true },
    { header: 'Fecha de envío', orderByKey: 'sended_date' },
  ];

  return (
    <>
      <StyledTopFilters>
        <ButtonComponent
          text="Nueva recolección de información"
          icon={<div className="icon-close transform rotate-45 text-sm"></div>}
          onPress={() => {
            setActiveItem((prevValue) => ({ ...prevValue, active: 1 }));
          }}
        />
        {!(!isFilterEmpty && collectionMessagesList?.length === 0) && (
          <StyledFilters className="relative">
            <ButtonComponent
              text="Filtros"
              mode="outline"
              className="icon-filter flex items-center space-x-2"
              onPress={handleVisibilityFilterDropDown}
            />

            <DropDownFilter
              open={showFilterDropDown}
              handleOpenMenu={handleVisibilityFilterDropDown}
              formFilterCollectionInformation={formFilterCollectionInformation}
              optionsCommunities={optionsCommunities}
              optionsContactLists={optionsContactLists}
              handleFilter={handleFilter}
              handleResetFilter={handleResetFilter}
            />
          </StyledFilters>
        )}
      </StyledTopFilters>

      <StyledContentTable minHeight="19">
        <Table className="w-full">
          <TableHead>
            <TableRow>
              {_.map(tableHeader, (item, idx) => (
                <TableHeadCell
                  className={`text-left py-4 px-2 ${
                    !item.noneOrder && collectionMessagesList.length
                      ? 'cursor-pointer'
                      : 'cursor-not-allowed'
                  }`}
                  key={`headList-${idx}`}
                  onClick={() => {
                    {
                      !item.noneOrder &&
                        collectionMessagesList.length &&
                        handleDataToOrder({ orderByKey: item.orderByKey });
                    }
                  }}
                >
                  {item.header !== '' && (
                    <StyledTableTitle>
                      <TypographyComponent text={item.header} variant="small" />
                      {!item.noneOrder && (
                        <Image
                          srcImage={arrowDown}
                          className={`ml-2 ${
                            !orderBy[item.orderByKey] &&
                            collectionMessagesList.length &&
                            'order-arrow cursor-pointer'
                          }`}
                        />
                      )}
                    </StyledTableTitle>
                  )}
                </TableHeadCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody className="relative">
            <>
              {collectionMessagesList.length > 0 &&
                _.map(collectionMessagesList, (collection, idx) => (
                  <TableRow
                    key={`tableRowSendedMessage-${idx}`}
                    onClick={() => {
                      setActiveItem({
                        active: 2,
                        idCollectionInformation: collection,
                      });
                    }}
                    className="cursor-pointer"
                  >
                    <TableCell className="py-2 px-4">
                      <p>{collection.channel}</p>
                    </TableCell>
                    <TableCell className="py-2 px-4">
                      <p>{collection.to}</p>
                    </TableCell>
                    <TableCell className="py-2 px-4">
                      <p className="truncate 2xl:w-96 xl:w-60">
                        {collection.typeRecol}
                      </p>
                    </TableCell>
                    <TableCell className="py-2">
                      <DefaultBadge
                        label={collection.state.name}
                        type="light"
                        variant={collection.colorBadgeStatusMessage}
                      />
                    </TableCell>
                    <TableCell className="py-2 2xl:px-4 xl:px-0">
                      {dateExists({
                        sendedDate: collection.sendedDate,
                        suggestedDate: collection.suggestedDate,
                        statusIdMessage: collection.state.id,
                      })}
                    </TableCell>
                  </TableRow>
                ))}
            </>
          </TableBody>
        </Table>
        {collectionMessagesList.length === 0 && (
          <CardEmptyState
            className="w-8/12 mx-auto mt-16"
            title={
              isFilterEmpty
                ? 'No hay resultados, utiliza nuevos criterios de búsqueda'
                : 'No se han encontrado mensajes de recolección de información ¡Crea el primero!'
            }
            body="Este espacio es para mantener la comunicación con la comunidad, para recolectar información, realizar recordatorios o enviar invitaciones! "
            button={
              !isFilterEmpty ? (
                <ButtonComponent
                  className="mx-auto"
                  text="Nueva recolección de información"
                  icon={
                    <div className="icon-close transform rotate-45 text-sm" />
                  }
                  onPress={() => {
                    setActiveItem((prevValue) => ({ ...prevValue, active: 1 }));
                  }}
                />
              ) : (
                <></>
              )
            }
          />
        )}
        <Pager
          pageCount={
            Math.ceil(
              dataForPagination.meta.total / dataForPagination.meta.per_page
            ) || 1
          }
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          previousLabel={Boolean(dataForPagination.links.prev)}
          nextLabel={Boolean(dataForPagination.links.next)}
          className="flex items-center lg:justify-end justify-center my-6"
          onPageChange={({ selected }) => handleChangePage(selected)}
          forcePage={dataForPagination.meta.current_page - 1}
        />
      </StyledContentTable>

      <DefaultModal
        isActive={activeDeleteModal.active}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={() => {
          setActiveDeleteModal({ active: false });
        }}
      >
        <TypographyComponent
          text="Eliminar mensaje"
          variant="h3"
          className="font-bold text-primary mb-1"
        />
        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto lg:mx-2 "
            mode="outline"
            onPress={() => {
              setActiveDeleteModal({ active: false });
            }}
          />
          <ButtonComponent
            variant="primary"
            text="Eliminar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={() => {}}
          />
        </StyledContentButtonsModal>
      </DefaultModal>
    </>
  );
};

List.propTypes = {
  setActiveItem: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleDataToOrder: PropTypes.func,
  handleFilter: PropTypes.func,
  handleResetFilter: PropTypes.func,
  collectionMessagesList: PropTypes.array,
  optionsCommunities: PropTypes.array,
  optionsContactLists: PropTypes.array,
  dataForPagination: PropTypes.object,
  orderBy: PropTypes.object,
  formFilterCollectionInformation: PropTypes.object,
  showFilterDropDown: PropTypes.bool,
  handleVisibilityFilterDropDown: PropTypes.func,
};

List.defaultProps = {
  setActiveItem: () => {},
  handleChangePage: () => {},
  handleDataToOrder: () => {},
  handleFilter: () => {},
  handleResetFilter: () => {},
  collectionMessagesList: [],
  optionsCommunities: [],
  optionsContactLists: [],
  dataForPagination: {},
  orderBy: {},
  formFilterCollectionInformation: {},
  showFilterDropDown: false,
  handleVisibilityFilterDropDown: () => {},
};

export default List;
