//Packages
import React, { Suspense } from 'react';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
import {
  StyledForm,
  StyledGroup,
  StyledBackButton,
  StyledDivider,
} from '../Users.styles';

const AdminCreateUsers = () => {
  const { useLayouts, useComponents } = useViews();
  const { AdminLayout } = useLayouts();
  const { useButtons, useInputs, useTypographies, useBoxes, useLoaders } =
    useComponents();
  const { DefaultLoader } = useLoaders();
  const { ButtonComponent } = useButtons();
  const { InputForm, Select } = useInputs();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { Box } = useBoxes();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useUserAdmin } = useAdmin();
  const { useCreateUser } = useUserAdmin();
  const {
    createUserForm,
    createUser,
    history,
    optionsCountries,
    optionsDepartments,
    optionsLanguages,
    optionsCities,
    optionsIndicatives,
    countrySelected,
    departmentSelected,
    OPTIONS_ROLES_WITH_LOGIN,
  } = useCreateUser();

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = createUserForm;

  return (
    <Suspense fallback={<DefaultLoader />}>
      <AdminLayout>
        <>
          <StyledBackButton
            className="mt-3"
            onClick={() => {
              history.goBack();
            }}
          >
            Regresar
          </StyledBackButton>
          <Box className="bg-neutral-200 w-11/12 mx-auto max-w-screen-lg lg:px-16 lg:py-12 px-6 py-8 lg:my-8">
            <>
              <TypographyComponent
                text="Crear nuevo usuario"
                variant="h2"
                className="font-bold text-primary mb-6"
              />

              <StyledForm onSubmit={handleSubmit(createUser)}>
                <StyledGroup className="items-center">
                  <Select
                    name="role"
                    control={control}
                    options={OPTIONS_ROLES_WITH_LOGIN}
                    label="Rol*"
                    placeholder="Selecciona"
                    errors={errors}
                  />
                  <TypographyWithTooltip
                    text=""
                    variant="p"
                    textTooltip="Rol del usuario"
                    idTooltip="role"
                    className="text-neutral-950"
                  />
                </StyledGroup>

                <StyledDivider />

                <StyledGroup noSpace>
                  <InputForm
                    type="text"
                    name="name"
                    placeholder="Ingresa tu nombre"
                    label="Nombre*"
                    className="w-full"
                    errors={errors}
                    {...register('name')}
                  />
                  <InputForm
                    type="text"
                    name="last_name"
                    placeholder="Ingresa tu apellido"
                    label="Apellido*"
                    className="w-full"
                    errors={errors}
                    {...register('last_name')}
                  />
                </StyledGroup>

                <StyledGroup noSpace>
                  <InputForm
                    type="text"
                    name="email"
                    placeholder="Ingresa tu correo electrónico"
                    label="Correo electrónico*"
                    className="w-full"
                    errors={errors}
                    {...register('email')}
                  />
                  <Select
                    name="country"
                    id="country"
                    control={control}
                    options={optionsCountries}
                    label="País*"
                    placeholder="Selecciona tu país"
                    errors={errors}
                  />
                </StyledGroup>
                <StyledGroup noSpace>
                  <Select
                    name="department"
                    id="department"
                    control={control}
                    options={optionsDepartments}
                    label="Departamento o Estado*"
                    placeholder="Selecciona el Dpto./Estado"
                    errors={errors}
                    disabled={!countrySelected}
                  />
                  <Select
                    name="city"
                    id="city"
                    control={control}
                    options={optionsCities}
                    label="Ciudad o Municipio*"
                    placeholder="Selecciona tu ciudad"
                    errors={errors}
                    disabled={!departmentSelected}
                  />
                </StyledGroup>
                <StyledGroup noSpace>
                  <Select
                    name="language"
                    id="language"
                    control={control}
                    options={optionsLanguages}
                    label="Idioma*"
                    placeholder="Selecciona el idioma"
                    errors={errors}
                  />
                  <StyledGroup
                    col2
                    className="items-end flex space-x-4 lg:space-x-3 "
                  >
                    <Select
                      name="code"
                      id="code"
                      className="lg:w-7/12"
                      control={control}
                      options={optionsIndicatives}
                      label="Teléfono*"
                      placeholder="Selecciona"
                      errors={errors}
                    />
                    <InputForm
                      type="number"
                      name="number_phone"
                      placeholder="Número de teléfono"
                      className="w-full"
                      errors={errors}
                      {...register('number_phone')}
                    />
                  </StyledGroup>
                </StyledGroup>

                <div>
                  <ButtonComponent
                    variant="primary"
                    className="mx-auto mt-10"
                    text="Crear usuario"
                    onPress={() => {
                      handleSubmit(createUser);
                    }}
                  />
                </div>
              </StyledForm>
            </>
          </Box>
        </>
      </AdminLayout>
    </Suspense>
  );
};

export default AdminCreateUsers;
