//Packages
import React, { lazy } from 'react';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';
import useStrings from 'strings';

//Styles
const StyledGroup = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const FormMessage = (props) => {
  const { control, errors, register, optionsCommunities } = props;
  const { useComponents } = useViews();

  const { useTypographies, useInputs } = useComponents();

  const { TypographyComponent, TypographyWithTooltip } = useTypographies();

  const { InputForm, Select, TextArea } = useInputs();

  const { useConstants } = useStrings();
  const { LIST_OF_CHANNELS_FOR_SEND_MESSAGES } = useConstants();

  return (
    <>
      <div className="flex items-center mb-2 mt-4">
        <TypographyWithTooltip
          text="Objetivo del mensaje"
          variant="p"
          textTooltip="Grupo protagonista"
          idTooltip="recoomemd"
          className="text-neutral-950"
        />
      </div>
      <StyledGroup className="items-center mb-2" col2>
        <InputForm
          type="text"
          name="objective"
          placeholder="Ingresa el objetivo del mensaje"
          label=""
          className="w-full"
          {...register('objective')}
          errors={errors}
        />
      </StyledGroup>
      <StyledGroup noSpace>
        <div>
          <div className="flex items-center mb-2 mt-4">
            <TypographyWithTooltip
              text="Canal de comunicación recomendado*"
              variant="p"
              textTooltip="Canal de comunicación recomendado*"
              idTooltip="prot"
              className="text-neutral-950"
            />
          </div>

          <Select
            name="channel"
            id="channel"
            placeholder="Selecciona el canal de comunicación"
            control={control}
            options={LIST_OF_CHANNELS_FOR_SEND_MESSAGES}
            label=""
            errors={errors}
          />
        </div>
        <div>
          <div className="flex items-center mb-2 mt-4">
            <TypographyWithTooltip
              text="Destino"
              variant="p"
              textTooltip="Destino"
              idTooltip="dest"
              className="text-neutral-950"
            />
          </div>

          <Select
            name="communities"
            id="communities"
            placeholder="Selecciona el destino"
            control={control}
            options={optionsCommunities}
            label=""
            errors={errors}
            multiple
          />
        </div>
      </StyledGroup>

      <div className="flex items-center mb-2 mt-4">
        <TypographyWithTooltip
          text="Recomendaciones al enviar el mensaje"
          variant="p"
          textTooltip="Recomendaciones al enviar el mensaje"
          idTooltip="recoomemd"
          className="text-neutral-950"
        />
      </div>
      <StyledGroup className="items-center mb-2" col2>
        <InputForm
          type="text"
          name="recommendations"
          placeholder="Ingresa las recomendaciones..."
          label=""
          className="w-full"
          {...register('recommendations')}
          errors={errors}
        />
      </StyledGroup>

      <StyledGroup>
        <TypographyComponent
          text="Redactar mensaje"
          variant="h3"
          className="font-bold text-primary mt-6"
        />

        <TextArea
          control={control}
          name="message"
          placeholder="Mensaje"
          className="w-full col-span-2"
          label=""
          errors={errors}
        />
      </StyledGroup>
    </>
  );
};

FormMessage.propTypes = {
  optionsCommunities: PropTypes.array.isRequired,
  control: PropTypes.object,
  errors: PropTypes.object,
  register: PropTypes.func,
  watch: PropTypes.func,
};

FormMessage.propTypes = {
  control: {},
  errors: {},
  register: () => {},
  watch: () => {},
};

export default FormMessage;
