import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

//Hooks
import useViews from 'views';
import useHelpers from 'helpers';

const StyledContentButtons = lazy(() =>
  import(
    '../../../../../../../../../../../../../../styles/tableViews/tableViews.styles'
  ).then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const StyledGroup = lazy(() =>
  import(
    '../../../../../../../../../../../../../../styles/formViews/formViews.styles'
  ).then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const Activities = (props) => {
  const { activities } = props
  const { useComponents } = useViews();
  const { useTypographies, useButtons, useInputs, useBadges } = useComponents();

  const { TypographyComponent } = useTypographies();
  const { ButtonComponent } = useButtons();
  const { InputHoursMinutes, InputForm } = useInputs();
  const { DefaultBadge } = useBadges();

  const { useQuickFunctions } = useHelpers()
  const { getDurationTimeInMinutes } = useQuickFunctions()

  return (
    <div className="my-6">
      <Accordion allowZeroExpanded>
        {activities?.length > 0 &&
          _.map(activities, (activity) => {
            return (
              <div className="  mt-2 bg-white" key={`activity-${activity.id}`}>
                <AccordionItem>
                  <div className="flex items-center justify-between px-6 py-2">
                    <div className="flex items-center">
                      <TypographyComponent
                        variant="p"
                        text={activity.name}
                        className="flex-grow text-primary font-bold"
                      />
                      <DefaultBadge
                        label={`Duración: ${getDurationTimeInMinutes({ duration: activity.duration })} minutos`}
                        type="light"
                        className="ml-4"
                      />
                    </div>

                    <StyledContentButtons>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton>
                            <ButtonComponent
                              text=""
                              className=""
                              variant="white"
                              icon={
                                <div
                                  className={`icon-arrow-down text-neutral-900 text-xxs ${
                                    expanded && 'transform rotate-180'
                                  }`}
                                />
                              }
                              onPress={() => {}}
                            />
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </StyledContentButtons>
                  </div>
                  <AccordionItemPanel>
                    <form className="bg-neutral-200 px-10 border pt-6">
                      <StyledGroup col2 className="mb-4" noSpace>
                        <InputForm
                          name="date"
                          label="Nombre de la actividad*"
                          type="text"
                          defaultValue={activity.name}
                          className="w-full"
                          disabled
                        />
                        <InputHoursMinutes
                          name="duration"
                          className="w-full"
                          defaultValue={`${activity.duration}`.slice(0, 5)}
                          label="Duración*"
                          disabled
                        />
                      </StyledGroup>
                    </form>
                  </AccordionItemPanel>
                </AccordionItem>
              </div>
            );
          })}
      </Accordion>
    </div>
  );
};

Activities.propTypes = {
  activities: PropTypes.array
}

export default Activities;
