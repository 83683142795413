//Packages
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

//Assets
import imageFakeCard from 'assets/img/fake-card-example.jpg';

//Hooks
import useApi from 'api';
import useModels from 'models';
import useControllers from 'controllers';

const useRootHome = () => {
  const { useActions } = useApi();
  const { dispatch, useGeneralActions, useAdminActions } = useActions();
  const { useLegalDataActions } = useGeneralActions();
  const { actLegalData } = useLegalDataActions();
  const { useHomeActions } = useAdminActions();
  const { actGetHomeInterventions, actGetHomeConfig } = useHomeActions();
  const history = useHistory();

  const { useGeneralHooks } = useControllers();
  const { useIsFilterEmpty } = useGeneralHooks();
  const { isFilterEmpty } = useIsFilterEmpty();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminHomeSelectors } = useAdminSelectors();
  const { getInterventionsHomeSelector, getHomeContentSelector } =
    useAdminHomeSelectors();
  const interventions = useSelector(getInterventionsHomeSelector);
  const dataHome = useSelector(getHomeContentSelector);

  const sliders = useMemo(
    () =>
      _.map(dataHome.sliders, (slider) => {
        const dataLikeObject = JSON.parse(slider.text);

        return { title: dataLikeObject.htmlContent };
      }),
    [dataHome]
  );

  const bottomText = useMemo(() => {
    if (dataHome.bt_title) {
      const { bt_title, bt_subtitle, bt_text } = dataHome;
      return {
        bt_title: JSON.parse(bt_title),
        bt_subtitle: JSON.parse(bt_subtitle),
        bt_text: JSON.parse(bt_text),
      };
    }
    return {};
  }, [dataHome]);

  const interventionsList = useMemo(
    () =>
      _.map(interventions?.data, (intervention) => ({
        id: intervention.id,
        image: intervention.image?.url || imageFakeCard,
        title: intervention.name,
        location: `${intervention.project?.city?.department?.name}, ${intervention.project?.city?.department?.country?.name}`,
        typeList: [
          { name: intervention.objetive, type: 'primary' },
          { name: intervention.type.name, type: 'secondary' },
        ],
        project: intervention.project,
        description: intervention.description,
        visibleInFront: intervention.visible_in_front,
      })),
    [interventions]
  );

  useEffect(() => {
    getInterventionsHome();
    getLegalData();
    dispatch(actGetHomeConfig());
  }, []);
  const getInterventionsHome = () => {
    dispatch(actGetHomeInterventions({}));
  };
  const getInterventionsPage = (page) => {
    dispatch(actGetHomeInterventions({ page: page + 1 }));
  };
  const getLegalData = () => {
    dispatch(actLegalData());
  };

  const handleOpenIntervention = (id) => {
    history.push(`/intervention/${id}`);
  };

  return {
    isFilterEmpty,
    interventions,
    getInterventionsPage,
    dataHome,
    sliders,
    bottomText,
    interventionsList,
    handleOpenIntervention,
  };
};

export default useRootHome;
