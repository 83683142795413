import React, { Suspense, lazy } from 'react';
import _ from 'lodash';

//Controllers
import useViews from 'views';
import useControllers from 'controllers';

//Assets
import imageIntervention from 'assets/img/interventionDetail.jpg';
import imagePin from 'assets/icons/MapPin.svg';
import imageFakeCard from 'assets/img/fake-card-example.jpg';

//Styles
const StyledWrapperContent = lazy(() =>
  import('./InterventionDetail.styles').then((mod) => ({
    default: mod.StyledWrapperContent,
  }))
);
const StyledContentTypes = lazy(() =>
  import('./InterventionDetail.styles').then((mod) => ({
    default: mod.StyledContentTypes,
  }))
);

const StyledListSucces = lazy(() =>
  import('./InterventionDetail.styles').then((mod) => ({
    default: mod.StyledListSucces,
  }))
);

const StyledListSuccesItem = lazy(() =>
  import('./InterventionDetail.styles').then((mod) => ({
    default: mod.StyledListSuccesItem,
  }))
);
const StyledWarpperMultimedia = lazy(() =>
  import('./InterventionDetail.styles').then((mod) => ({
    default: mod.StyledWarpperMultimedia,
  }))
);

/* const StyledInterventions = lazy(() =>
  import('./InterventionDetail.styles').then((mod) => ({
    default: mod.StyledInterventions,
  }))
); */

const StyledWrapperUsers = lazy(() =>
  import('../../../styles/projects/dashboard.styles').then((mod) => ({
    default: mod.StyledWrapperUsers,
  }))
);

const InterventionDetail = () => {
  const { useLayouts, useComponents } = useViews();
  const { MainLayout } = useLayouts();
  const {
    useImages,
    useTypographies,
    useBadges,
    useCards,
    useButtons,
    useLoaders,
  } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { Image } = useImages();
  const { TypographyComponent } = useTypographies();
  const { DefaultBadge } = useBadges();
  const { CardUser /* , CardIntervention */ } = useCards();
  const { ButtonComponent } = useButtons();

  const { useScreenHooks } = useControllers();
  const { useInterventionDetail } = useScreenHooks();
  const { intervention, handleGoHome } = useInterventionDetail();

  const projectManager = intervention?.project?.project_manager;
  const executingPartner = intervention?.project?.executing_partner;

  return (
    <Suspense fallback={<DefaultLoader />}>
      <MainLayout whiteHeader={true}>
        <Image
          srcImage={imageIntervention}
          altImage="intervention"
          titleImage="intervention"
          className="absolute top-0 left-0 w-full"
        />

        <ButtonComponent
          className=" shadow-none border-none ml-14 mt-80"
          text="Volver al home"
          mode="outline"
          onPress={() => {
            handleGoHome();
          }}
        />

        <StyledWrapperContent>
          <TypographyComponent
            variant="h1"
            text={`${intervention?.name}`}
            className="text-primary font-bold leading-10 mb-2"
          />
          <TypographyComponent
            className="text-secondary flex"
            variant="h5"
            text={
              <>
                <Image
                  srcImage={imagePin}
                  altImage="location"
                  titleImage="location"
                  className="location-icon"
                />
                {`${intervention?.project?.city?.name} -
                  ${intervention?.project?.city?.department?.country?.name}`}
              </>
            }
          />

          <StyledContentTypes>
            <DefaultBadge
              type="light"
              className="mr-2"
              variant={`primary`}
              label={`Objetivo: ${intervention?.objetive}`}
            />
            <DefaultBadge
              type="light"
              className="mr-2"
              variant={`secondary`}
              label={`Modalidad: ${intervention?.type?.name}`}
            />
          </StyledContentTypes>
          <TypographyComponent
            variant="h2"
            text={`Proyecto: ${intervention?.project?.name}`}
            className="text-primary font-bold"
          />

          <TypographyComponent
            variant="h3"
            text="Encargados de la planeación y ejecución"
            className="text-primary font-bold mt-4"
          />

          <StyledWrapperUsers className="space-x-4">
            {executingPartner !== undefined && (
              <CardUser
                image={executingPartner.avatar}
                name={`${executingPartner.name} ${executingPartner.last_name}`}
                rol={executingPartner.role?.name}
                location={`${executingPartner.city.name}, ${executingPartner.city?.department?.country?.name} `}
              />
            )}

            {projectManager !== undefined && (
              <CardUser
                image={projectManager.avatar}
                name={`${projectManager.name} ${projectManager.last_name}`}
                rol={projectManager.role?.name}
                location={`${projectManager.city.name}, ${projectManager.city?.department?.country?.name} `}
              />
            )}
          </StyledWrapperUsers>

          <TypographyComponent
            variant="h3"
            text="Descripción de la intervención"
            className="text-primary font-bold mt-14"
          />
          <TypographyComponent
            variant="p"
            text={`${intervention?.description}`}
            className="text-neutral-950 mt-4"
          />

          {intervention?.final_process?.goals?.length > 0 && (
            <>
              <TypographyComponent
                variant="h3"
                text="Logros de la intervención"
                className="text-primary font-bold mt-14"
              />
              <StyledListSucces>
                {_.map(intervention.final_process.goals, (item, key) => {
                  return (
                    <StyledListSuccesItem key={`goal-${key}`}>
                      {item?.text ?? item}
                    </StyledListSuccesItem>
                  );
                })}
              </StyledListSucces>
            </>
          )}

          <TypographyComponent
            variant="h3"
            text="Recomendaciones para otros artistas"
            className="text-primary font-bold mt-14"
          />
          <TypographyComponent
            variant="p"
            text={`${intervention?.final_process?.recommendations}`}
            className="text-neutral-950 mt-4"
          />

          {intervention?.final_process?.files?.length > 0 && (
            <>
              <TypographyComponent
                variant="h3"
                text="Material audiovisual"
                className="text-primary font-bold mt-14"
              />

              <StyledWarpperMultimedia className="mt-4">
                {_.map(
                  intervention?.final_process?.files,
                  (multimedia, idx) => {
                    return (
                      <Image
                        key={`additionalInfoMultimedia-${idx}`}
                        srcImage={multimedia.url || imageFakeCard}
                        altImage="imageport"
                        titleImage="imageport"
                      />
                    );
                  }
                )}
              </StyledWarpperMultimedia>
            </>
          )}

          {/* <TypographyComponent
            variant="h3"
            text="Intervenciones relacionadas"
            className="text-primary font-bold mt-14"
          />

             <StyledInterventions>
            {_.map(interventions, (intervention, idx) => {
              return (
                <CardIntervention
                  key={`interventionItem-${idx}`}
                  className="w-full "
                  image={imageFakeCard}
                  title={intervention.name}
                  project="Proyecto: Lazos de agua"
                  location={'Chocó, Colombia'}
                  typeList={[{ name: 'Activar', type: 'primary' }]}
                  description={
                    'Credibly reintermediate client focused growth strategies with functional initiatives.'
                  }
                  onClick={() => {}}
                />
              );
            })}
          </StyledInterventions> */}
        </StyledWrapperContent>
      </MainLayout>
    </Suspense>
  );
};

export default InterventionDetail;
