import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';

// Hooks
import useModels from 'models';
import useHelpers from 'helpers';
import useApi from 'api';
import useStrings from 'strings';

const useAdminPlanPilotIntervention = () => {
  const { intervention_id: interventionId } = useParams();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();
  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const { useAdminDevelopmentInterventionCreateSketchActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const {
    actGetPilotPlanIntervention,
    actUpdatePilotPlanIntervention,
    actDownloadPilotPlanCriteriaFileIntervention,
  } = useAdminDevelopmentInterventionCreateSketchActions();

  const { useSelectors } = useModels();
  const { useAdminSelectors, useSelector } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionsSelector } = useAdminInterventionsSelectors();
  const { pilotPlan } = useSelector(getInterventionsSelector);
  const { id: pilotPlanId } = pilotPlan;

  const { useQuickFunctions } = useHelpers();
  const {
    downloadFileFromBackend,
    getDurationTimeInMinutes,
    setDefaultValueInputDateTimeWithTimeLocalZone,
  } = useQuickFunctions();

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { REQUIRED_FIELD, MAX_LENGTH_500 } = useFormsTypes();

  const [showModalReject, setShowModalReject] = useState(false);
  const [showModalApprove, setShowModalApprove] = useState(false);

  const schemaFormRejectPilot = yup.object({
    rejected_reason: yup
      .string()
      .max(500, MAX_LENGTH_500)
      .required(REQUIRED_FIELD),
  });

  const { control, reset } = useForm({
    mode: 'onChange',
  });

  const formRejectPilot = useForm({
    resolver: yupResolver(schemaFormRejectPilot),
    mode: 'onChange',
  });

  useEffect(() => {
    if (pilotPlan.state?.id !== 'c') {
      dispatch(
        actGetPilotPlanIntervention({
          interventionId,
        })
      );
    }
  }, []);

  useEffect(() => {
    reset({
      community: {
        value: pilotPlan.community?.id,
        label: pilotPlan.community?.name,
      },
      modality: {
        value: pilotPlan.modality?.id,
        label: pilotPlan.modality?.name,
      },
      date: pilotPlan.date,
      start_time: pilotPlan.start_time
        ? setDefaultValueInputDateTimeWithTimeLocalZone({
            dateInUTC: `2022-05-05T${pilotPlan.start_time}`,
          })
            .split('.')[0]
            .split('T')[1]
            .slice(0, 5)
        : '',
      end_time: pilotPlan.end_time
        ? setDefaultValueInputDateTimeWithTimeLocalZone({
            dateInUTC: `2022-05-05T${pilotPlan.end_time}`,
          })
            .split('.')[0]
            .split('T')[1]
            .slice(0, 5)
        : '',
      participants: pilotPlan.participants,
    });
  }, [pilotPlan]);

  const openFile = ({ e, file }) => {
    e && e.preventDefault();
    window.open(file.url);
  };

  const downloadFile = ({ e, file, nameFile }) => {
    e && e.preventDefault();
    dispatch(
      actDownloadPilotPlanCriteriaFileIntervention(
        {
          interventionId,
          pilotPlanId,
          fileId: file.id,
        },
        (response) => {
          downloadFileFromBackend({ response, nameFile });
        }
      )
    );
  };

  const updatePilotPlanIntervention = (data) => {
    setShowModalReject(false);
    setShowModalApprove(false);
    dispatch(
      actUpdatePilotPlanIntervention({
        interventionId,
        pilotPlanId,
        data: { ...data, _method: 'patch' },
      })
    );
  };

  return {
    pilotPlan,
    planPilotActivities: pilotPlan?.activities,
    formRejectPilot,
    control,
    showModalReject,
    setShowModalReject,
    showModalApprove,
    setShowModalApprove,
    totalPilotDuration: _.reduce(
      pilotPlan?.activities,
      (sum, act) => sum + getDurationTimeInMinutes({ duration: act.duration }),
      0
    ),
    openFile,
    downloadFile,
    updatePilotPlanIntervention,
  };
};

export default useAdminPlanPilotIntervention;
