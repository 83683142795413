//Hooks
import { useEffect, useRef, useState } from 'react';

const useInputFile = ({ onChange, cleanFileInputs, cleanFileOnSave = false }) => {
  const inputRef = useRef();

  const [documentName, setDocumentName] = useState('');

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = null;
    }
    setDocumentName('');
  }, [cleanFileInputs]);

  const handleInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      event.target.value = null
      !cleanFileOnSave && setDocumentName(file.name);
      onChange && onChange(file);
    }
  };

  return { handleInputChange, documentName, inputRef };
};

export default useInputFile;
