//Packages
import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Hooks
import useControllers from 'controllers';
import useViews from 'views';

//Components
import DropDownFilter from './components/DropDownFilter/DropDownFilter';

//Assets
import arrowDown from 'assets/icons/arrow-down-table.svg';

//Styles
const StyledTopFilters = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledTopFilters,
  }))
);

const StyledFilters = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledFilters,
  }))
);

const StyledContentButtons = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const StyledContentTable = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentTable,
  }))
);

const StyledContentButtonsModal = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const StyledTableTitle = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledTableTitle,
  }))
);

const List = (props) => {
  const {
    activeItem,
    setActiveItem,
    dataTableHeaderArtistDraftMessagesRef,
    activeDropDown,
    handleOpenMenu,
    handleDataToOrder,
    orderBy,
    messagesList,
    dataForPagination,
    handleChangePage,
    deleteMessage,
    activeDeleteModal,
    setActiveDeleteModal,
    handleFilter,
    handleResetFilter,
    optionsCommunities,
    optionsContactLists,
    formFilterMessages,
    handleGetDetailMessages,
  } = props;
  const { useComponents } = useViews();

  const {
    useButtons,
    useModals,
    useTypographies,
    useImages,
    useTables,
    usePagers,
    useCards,
  } = useComponents();

  const { CardEmptyState } = useCards();
  const { ButtonComponent } = useButtons();
  const { Table, TableCell, TableHead, TableBody, TableRow, TableHeadCell } =
    useTables();
  const { TypographyComponent } = useTypographies();
  const { Image } = useImages();
  const { Pager } = usePagers();
  const { DefaultModal } = useModals();

  const { useGeneralHooks } = useControllers();
  const { useIsFilterEmpty } = useGeneralHooks();
  const { isFilterEmpty } = useIsFilterEmpty();

  return (
    <>
      {!(!isFilterEmpty && messagesList?.length === 0) && (
        <StyledTopFilters justifyEnd>
          <StyledFilters className="relative">
            <ButtonComponent
              text="Filtros"
              mode="outline"
              className="icon-filter flex items-center space-x-2"
              onPress={handleOpenMenu}
            />

            <DropDownFilter
              open={activeDropDown}
              handleOpenMenu={handleOpenMenu}
              handleFilter={handleFilter}
              handleResetFilter={handleResetFilter}
              optionsCommunities={optionsCommunities}
              optionsContactLists={optionsContactLists}
              formFilterMessages={formFilterMessages}
            />
          </StyledFilters>
        </StyledTopFilters>
      )}

      <StyledContentTable minHeight="22">
        <Table className="w-full">
          <TableHead>
            <TableRow>
              {_.map(
                dataTableHeaderArtistDraftMessagesRef.current,
                (item, idx) => (
                  <TableHeadCell
                    className={`text-left py-4 px-2 ${
                      !item.noneOrder && messagesList.length
                        ? 'cursor-pointer'
                        : 'cursor-not-allowed'
                    }`}
                    key={`headList-${idx}`}
                    onClick={() => {
                      item.orderByKey &&
                        messagesList.length &&
                        handleDataToOrder({ orderByKey: item.orderByKey });
                    }}
                  >
                    {item.header !== '' && (
                      <StyledTableTitle>
                        <TypographyComponent
                          text={item.header}
                          variant="small"
                        />
                        {!item.noneOrder && (
                          <Image
                            srcImage={arrowDown}
                            className={`ml-2 ${
                              !orderBy[item.orderByKey] &&
                              messagesList.length &&
                              'order-arrow cursor-pointer'
                            }`}
                          />
                        )}
                      </StyledTableTitle>
                    )}
                  </TableHeadCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody className="relative">
            <>
              {messagesList.length > 0 &&
                _.map(messagesList, (message, idx) => (
                  <TableRow
                    key={idx}
                    onClick={() => {
                      handleGetDetailMessages({ messageId: message.id }, () => {
                        setActiveItem((prevValue) => ({
                          ...prevValue,
                          active: 1,
                        }));
                      });
                    }}
                    className="cursor-pointer"
                  >
                    <TableCell className="py-2">
                      <p>{message.type}</p>
                    </TableCell>
                    <TableCell className="py-2">
                      <p>{message.channel}</p>
                    </TableCell>
                    <TableCell className="py-2">
                      <p className="truncate 2xl:w-96 xl:w-60">{message.to}</p>
                    </TableCell>

                    <TableCell className="py-2">{message.date}</TableCell>

                    <TableCell className="py-2">
                      <StyledContentButtons>
                        <ButtonComponent
                          text=""
                          className=""
                          onPress={(event) => {
                            event.stopPropagation();
                            setActiveItem((prevValue) => ({
                              ...prevValue,
                              action: 'delete',
                              idSelectedMessage: message.id,
                            }));
                            setActiveDeleteModal();
                          }}
                          icon={<div className={`icon-delete `}></div>}
                        />
                      </StyledContentButtons>
                    </TableCell>
                  </TableRow>
                ))}
            </>
          </TableBody>
        </Table>
        {messagesList.length === 0 && (
          <CardEmptyState
            className="w-8/12 mx-auto mt-16"
            title={
              isFilterEmpty
                ? 'No hay resultados, utiliza nuevos criterios de búsqueda'
                : 'No se han encontrado borradores'
            }
            body=""
          />
        )}
        <Pager
          pageCount={Math.ceil(
            dataForPagination?.total / dataForPagination?.per_page
          )}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          previousLabel={Boolean(dataForPagination?.prev)}
          nextLabel={Boolean(dataForPagination?.next)}
          className="flex items-center lg:justify-end justify-center my-6"
          onPageChange={({ selected }) => handleChangePage(selected)}
          forcePage={dataForPagination.current_page - 1}
        />
      </StyledContentTable>

      <DefaultModal
        isActive={activeDeleteModal}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={setActiveDeleteModal}
      >
        <TypographyComponent
          text="Eliminar borrador "
          variant="h3"
          className="font-bold text-primary mb-1"
        />
        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto lg:mx-2 "
            mode="outline"
            onPress={setActiveDeleteModal}
          />
          <ButtonComponent
            variant="primary"
            text="Aceptar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={() => {
              deleteMessage({ message_id: activeItem?.idSelectedMessage });
            }}
          />
        </StyledContentButtonsModal>
      </DefaultModal>
    </>
  );
};

List.propTypes = {
  activeItem: PropTypes.object.isRequired,
  setActiveItem: PropTypes.func.isRequired,
  dataTableHeaderArtistDraftMessagesRef: PropTypes.object,
  activeDropDown: PropTypes.bool,
  handleOpenMenu: PropTypes.func,
  handleDataToOrder: PropTypes.func,
  handleChangePage: PropTypes.func,
  deleteMessage: PropTypes.func,
  orderBy: PropTypes.object,
  dataForPagination: PropTypes.object,
  messagesList: PropTypes.array,
  activeDeleteModal: PropTypes.bool,
  setActiveDeleteModal: PropTypes.func,
  handleFilter: PropTypes.func,
  handleResetFilter: PropTypes.func,
  optionsCommunities: PropTypes.array,
  optionsContactLists: PropTypes.array,
  formFilterMessages: PropTypes.object,
  handleGetDetailMessages: PropTypes.func,
};

List.defaultProps = {
  dataTableHeaderArtistDraftMessagesRef: {},
  activeDropDown: false,
  handleOpenMenu: () => {},
  handleDataToOrder: () => {},
  handleChangePage: () => {},
  deleteMessage: () => {},
  orderBy: {},
  dataForPagination: {},
  messagesList: [],
  activeDeleteModal: false,
  setActiveDeleteModal: () => {},
  handleFilter: () => {},
  handleResetFilter: () => {},
  optionsCommunities: [],
  optionsContactLists: [],
  formFilterMessages: {},
  handleGetDetailMessages: () => {},
};

export default List;
