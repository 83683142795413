//Hooks
import useHelpers from '../../../../../../../helpers';
import useStrings from '../../../../../../../strings';
import useInitialStates from '../../../../../../initialStates';

const useAdminViewInterventionsCommunicationReducers = () => {
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  const { useAdminInitialStates } = useInitialStates();
  const { useAdminProjectsInitialStates } = useAdminInitialStates();
  const { useAdminInterventionsInitialStates } =
    useAdminProjectsInitialStates();
  const { useAdminViewInterventionsCommunicationInitialStates } =
    useAdminInterventionsInitialStates();
  const { initialStateCommunicationMessages } =
    useAdminViewInterventionsCommunicationInitialStates();

  const { useAdminTypes } = useStrings();
  const { useAdminProjectsTypes } = useAdminTypes();
  const { useAdminInterventionsTypes } = useAdminProjectsTypes();
  const { useAdminViewInterventionsTypes } = useAdminInterventionsTypes();
  const { useAdminViewInterventionsCommunicationTypes } =
    useAdminViewInterventionsTypes();
  const {
    GET_LIST_OF_MESSAGES_INTERVENTION,
    GET_SELECTED_MESSAGE_INTERVENTION,
    SEND_MESSAGES_INTERVENTION,
    DELETE_MESSAGE_INTERVENTION,
    SET_FILTER_LIST_OF_MESSAGES_INTERVENTION,
    RESET_FILTER_LIST_OF_MESSAGES_INTERVENTION,
    RESET_SELECTED_MESSAGE_INTERVENTION,
  } = useAdminViewInterventionsCommunicationTypes();

  const interventionCommunicationMessages = createReducer(
    initialStateCommunicationMessages,
    {
      [GET_LIST_OF_MESSAGES_INTERVENTION](state, action) {
        return { ...state, listOfMessages: action.payload };
      },
      [GET_SELECTED_MESSAGE_INTERVENTION](state, action) {
        return { ...state, selectedMessage: action.payload.data };
      },
      [SEND_MESSAGES_INTERVENTION](state) {
        return state;
      },
      [DELETE_MESSAGE_INTERVENTION](state) {
        return {
          ...state,
        };
      },
      [SET_FILTER_LIST_OF_MESSAGES_INTERVENTION](state, action) {
        const { key, value } = action.payload;
        return {
          ...state,
          filter: {
            ...state.filter,
            [key]: value,
          },
        };
      },
      [RESET_FILTER_LIST_OF_MESSAGES_INTERVENTION](state) {
        return {
          ...state,
          filter: { ...initialStateCommunicationMessages.filter },
        };
      },
      [RESET_SELECTED_MESSAGE_INTERVENTION](state) {
        return {
          ...state,
          selectedMessage: {
            ...initialStateCommunicationMessages.selectedMessage,
          },
        };
      },
    }
  );

  return { interventionCommunicationMessages };
};

export default useAdminViewInterventionsCommunicationReducers;
