const useAdminContentInterventionsInitialStates = () => {
  const initialStateFilterAdminInterventions = {
    country: '',
    department: '',
    project: '',
    objetive: '',
    type: '',
    name: '',
  };
  return { initialStateFilterAdminInterventions };
};

export default useAdminContentInterventionsInitialStates;
