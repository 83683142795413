// Packages
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { overStylesInputs, focusStylesInputs } from '../Inputs.styles';
import useGeneralStyles from '../../../../styles/generalStyles.styles';
const { mixStatesFormStyles } = useGeneralStyles();

export const StyledWarpperSelect = styled.div.attrs(() => ({}))`
  ${tw` relative flex `}

  .react-select__multi-value__remove {
    ${(props) => !props.isClearable && tw`hidden`}
  }
`;

export const StyledIconDown = styled.span.attrs(() => ({
  className: 'icon-arrow-down',
}))`
  ${tw` text-xxs text-primary absolute right-0 top-2/4 mr-8 `}
  transform: translate(0,-50%);
`;

export const StyledContenSelect = styled.div.attrs(() => ({}))`
  .react-select {
    ${tw` w-full`}
    &__multi-value {
      ${tw` bg-white px-2 border border-neutral-600 rounded`}
      &__label {
        line-height: 12px;
        padding: 5px 0 5px 0 !important;
        ${tw` text-neutral-950 `}
      }
    }
    &__control {
      ${tw` bg-neutral-300 text-neutral-950 border border-neutral-600 rounded py-3 px-3 appearance-none pr-10 w-full shadow-none`}

      ${(props) => props.disabled && tw`bg-neutral`}

      ${(props) =>
        mixStatesFormStyles(
          {
            errors: props.errors,
            success: props.success,
          },
          'border'
        )}

      &:hover {
        ${(props) => overStylesInputs(props)}
      }
      &--is-focused {
        ${(props) => focusStylesInputs(props)}
      }
      &--menu-is-open {
        outline: 3px solid var(--primary-100);
        ${tw` border-primary appearance-none`}
        ${(props) => props.errors && tw`border-error`}
        ${(props) => props.success && tw`border-success`}
      }
    }

    &__input-container {
      ${tw` p-0 m-0`}
    }

    &__placeholder {
      ${tw` text-neutral-700 `}
    }

    &select__single-value {
      ${tw` text-neutral-950`}
    }

    &__value {
      &-container {
        ${tw` p-0 m-0`}
      }
    }
    &__option--is-focused {
      ${tw` bg-primary-100`}
    }
    &__option--is-selected {
      ${tw` bg-primary`}
    }
    &__menu {
      ${tw` m-0 border-t-0 rounded-t-none`}
      &-list {
        ${tw` p-0`}
      }
    }
  }
`;
