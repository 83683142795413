import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const StyledForm = styled.form.attrs(() => ({
  className: 'StyledForm',
}))`
  ${tw` space-y-4`}

  ${(props) => props.border && tw`border border-neutral-600 p-6 rounded-md`}
  ${(props) => props.borderNone && tw`border-0 `}
`;

export const StyledGroup = styled.section.attrs(() => ({
  className: 'StyledGroup',
}))`
  ${tw` space-y-3 lg:space-y-4 lg:grid lg:grid-cols-2 lg:gap-x-12 w-full`}

  ${(props) => props.col2 && tw`lg:gap-x-4 lg:flex lg:!mt-0`}
  ${(props) => props.noSpace && tw`lg:space-y-0`}
  ${(props) => props.flex && tw`lg:flex lg:gap-x-0 lg:space-y-0`}
  ${(props) => props.hidden && tw`hidden lg:hidden`}
`;

export const StyledBackButton = styled.button.attrs(() => ({
  className: 'StyledBackButton icon-arrow',
}))`
  ${tw` font-bold text-primary`}
  &:before {
    ${tw` text-secondary lg:mr-4 mr-2`}
    transform: rotate(180deg);
    font-weight: 700;
  }
`;

export const StyledDivider = styled.div.attrs(() => ({
  className: 'StyledDivider',
}))`
  ${tw`w-full my-12 border border-neutral-600 block`}
  ${(props) => props.spacexs && tw`my-4`}
`;

export const StyledContentButtons = styled.div.attrs(() => ({
  className: 'StyledContentButtons',
}))`
  ${tw` flex items-center`}
  button {
    ${tw` bg-transparent text-tertiary shadow-none border-none hover:bg-transparent px-0 py-0`}
    font-size:1.7142857142857142rem;
    &.icon-edit {
      font-size: 2.5rem;
      height: 28px;
      ${tw`  text-primary w-8`}
      &:before {
        ${tw`  h-full`}
      }
    }
  }
`;
