//Packages
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Controller } from 'react-hook-form';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

// Styles
import {
  StyledWarpperSelect,
  StyledContenSelect,
  StyledIconDown,
} from './Select.styles';
import { StyledInputWrapper } from '../Inputs.styles';

const CustomSelect = (props) => {
  const {
    name,
    errors,
    label,
    className,
    hintLabel,
    disabled,
    options,
    control,
    multiple,
    placeholder,
    id,
    rules,
    onChange,
    defaultValue,
    isClearable,
  } = props;

  const { useComponents } = useViews();
  const { useLabels } = useComponents();
  const { DefaultLabel, HelperLabel } = useLabels();

  const { useComponentHooks } = useControllers();
  const { useInputs } = useComponentHooks();
  const { useInputForm } = useInputs();
  const {
    handleIsBlur,
    handleIsFocus,
    errorInput,
    successInput,
    handleFilled,
  } = useInputForm({
    errors,
    name,
  });

  return (
    <StyledContenSelect
      success={successInput}
      errors={errorInput}
      className={`${className}`}
    >
      <StyledInputWrapper errors={errorInput} success={successInput}>
        {label && <DefaultLabel>{label}</DefaultLabel>}
        <StyledWarpperSelect isClearable={isClearable}>
          <Controller
            control={control}
            name={name}
            instanceId={id}
            valueName={'selectedValue'}
            onBlur={handleIsBlur}
            onFocus={handleIsFocus}
            onInput={handleFilled}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field: { name, onChange: onChangeSelect, value } }) => {
              return (
                <Select
                  isDisabled={disabled}
                  onChange={(e) => {
                    onChangeSelect(e);
                    onChange({ data: e, inputName: name });
                  }}
                  name={name}
                  value={value}
                  options={options}
                  classNamePrefix="react-select"
                  className="react-select"
                  placeholder={placeholder}
                  isMulti={multiple}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => <StyledIconDown />,
                  }}
                />
              );
            }}
          />
        </StyledWarpperSelect>
        {hintLabel && errorInput ? (
          <HelperLabel>{errors[name]?.message}</HelperLabel>
        ) : errorInput ? (
          <HelperLabel>{errors[name].message}</HelperLabel>
        ) : (
          <HelperLabel>{hintLabel}</HelperLabel>
        )}
      </StyledInputWrapper>
    </StyledContenSelect>
  );
};
CustomSelect.propTypes = {
  rules: PropTypes.object,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
  hintLabel: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  errors: PropTypes.object,
  options: PropTypes.array,
  multiple: PropTypes.bool,
  control: PropTypes.any,
  placeholder: PropTypes.string,
  id: PropTypes.any,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  isClearable: PropTypes.bool,
};
CustomSelect.defaultProps = {
  onChange: () => {},
  label: '',
  className: '',
  hintLabel: '',
  disabled: false,
  required: false,
  options: [],
  multiple: false,
  rules: {},
  placeholder: '',
  errors: {},
  isClearable: true,
};
export default CustomSelect;
