import useFilterForCompletedInterventions from './filter';
import useAdminContentManagementInterventionsRoot from './root';
import useAdminEditInformationIntervention from './edit';

const useAdminContentManagementInterventionsControllers = () => {
  return {
    useAdminContentManagementInterventionsRoot,
    useFilterForCompletedInterventions,
    useAdminEditInformationIntervention,
  };
};

export default useAdminContentManagementInterventionsControllers;
