import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { useEffect, useState } from 'react';

// Hooks
import useModels from 'models';
import useApi from 'api';

const useArtistProgramPilotIntervention = () => {
  const { intervention_id: interventionId } = useParams();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();
  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const { useAdminDevelopmentInterventionCreateSketchActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const {
    actGetProgramPilotIntervention,
    actUpdateProgramPilotIntervention,
  } = useAdminDevelopmentInterventionCreateSketchActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors, useUserSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionsSelector } = useAdminInterventionsSelectors();
  const { usersSelector } = useUserSelectors();
  const { programPilot } = useSelector(getInterventionsSelector);
  const { id: programPilotId, pilotPlan } = programPilot;
  const { data: agents } = useSelector(usersSelector);

  const [showModalApprove, setShowModalApprove] = useState(false);

  useEffect(() => {
    if (programPilot.state?.id !== 'c') {
      dispatch(actGetProgramPilotIntervention({
        interventionId,
      }));
    }
  }, [])

  const updateProgramPilotIntervention = () => {
    setShowModalApprove(false)
    dispatch(actUpdateProgramPilotIntervention({
      interventionId,
      programPilotId,
      data: { state: 'c' }
    }))
  }

  return {
    programPilot,
    pilotPlan,
    agents,
    showModalApprove,
    setShowModalApprove,
    updateProgramPilotIntervention
  }
}

export default useArtistProgramPilotIntervention