//Packages
import React, { lazy, useEffect } from 'react';

//Components
import List from '../List';
import Create from '../Create';
import Detail from '../Detail';

//Hooks
import useControllers from 'controllers';

//Styles
const StyledBodyTab = lazy(() =>
  import('styles/tabs/tabs.styles').then((mod) => ({
    default: mod.StyledBodyTab,
  }))
);

const Messages = () => {
  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsCommunication } =
    useAdminViewInterventionsControllers();
  const { useAdminViewInterventionsCommunicationDiffusion } =
    useAdminViewInterventionsCommunication();
  const {
    activeItem,
    setActiveItem,

    //functions
    handleDataToOrder,
    handleChangePage,
    dateExists,
    handleFilter,
    handleResetFilter,
    sendMessage,
    sendMessageDraft,
    handleCancelSendMessage,
    handleResetSelectedDetailMessage,
    getMonthAndDay,
    onFormatMessageAboutCommunities,
    handleGetDetailMessages,

    //data
    fromInterventionMessage,
    dataTableHeaderAdminMessagesRef,
    orderBy,
    dataForPagination,
    messagesList,
    optionsCommunities,
    optionsContactLists,
    minDateToSelect,
    selectedMessage,

    //Modals/toogles
    handleOpenMenu,
    activeDropDown,
    activeDeleteModal,
    setActiveDeleteModal,
    activeConfirmModal,
    setActiveConfirmModal,
    activeAtLeastItemDraft,
    setActiveAtLeastItemDraft,

    //rhf
    formFilterMessages,
    formAcceptMessage,
  } = useAdminViewInterventionsCommunicationDiffusion({
    difussionOrGetInformation: 'd',
  });

  useEffect(() => {
    if (fromInterventionMessage) {
      setActiveItem({ active: 1 });
    }
  }, [fromInterventionMessage]);

  return (
    <div className="px-4">
      {activeItem.active === 0 && (
        <StyledBodyTab active={activeItem.active === 0}>
          <List
            setActiveItem={setActiveItem}
            dataTableHeaderAdminMessagesRef={dataTableHeaderAdminMessagesRef}
            handleOpenMenu={handleOpenMenu}
            activeDropDown={activeDropDown}
            handleDataToOrder={handleDataToOrder}
            orderBy={orderBy}
            dataForPagination={dataForPagination}
            handleChangePage={handleChangePage}
            messagesList={messagesList}
            dateExists={dateExists}
            activeDeleteModal={activeDeleteModal}
            setActiveDeleteModal={setActiveDeleteModal}
            formFilterMessages={formFilterMessages}
            optionsCommunities={optionsCommunities}
            optionsContactLists={optionsContactLists}
            handleFilter={handleFilter}
            handleResetFilter={handleResetFilter}
            handleGetDetailMessages={handleGetDetailMessages}
          />
        </StyledBodyTab>
      )}
      {activeItem.active === 1 && (
        <StyledBodyTab active={activeItem.active === 1}>
          <Create
            formAcceptMessage={formAcceptMessage}
            fromInterventionMessage={fromInterventionMessage}
            optionsCommunities={optionsCommunities}
            optionsContactLists={optionsContactLists}
            minDateToSelect={minDateToSelect}
            activeConfirmModal={activeConfirmModal}
            setActiveConfirmModal={setActiveConfirmModal}
            sendMessage={sendMessage}
            activeAtLeastItemDraft={activeAtLeastItemDraft}
            setActiveAtLeastItemDraft={setActiveAtLeastItemDraft}
            sendMessageDraft={sendMessageDraft}
            handleCancelSendMessage={handleCancelSendMessage}
          />
        </StyledBodyTab>
      )}
      {activeItem.active === 2 && (
        <StyledBodyTab active={activeItem.active === 2}>
          <Detail
            setActiveItem={setActiveItem}
            handleResetSelectedDetailMessage={handleResetSelectedDetailMessage}
            selectedMessage={selectedMessage}
            dateExists={dateExists}
            getMonthAndDay={getMonthAndDay}
            onFormatMessageAboutCommunities={onFormatMessageAboutCommunities}
          />
        </StyledBodyTab>
      )}
    </div>
  );
};

export default Messages;
