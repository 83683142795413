import useAdminDevelopmentInterventionCreativePlanProviders from './creativePlan';
import useAdminDevelopmentInterventionKnowCommunityProviders from './knowCommunity';
import useAdminDevelopmentInterventionKnowProjectProviders from './knowProject';
import useAdminDevelopmentInterventionDefineInterventionProviders from './defineIntervention';
import useAdminDevelopmentInterventionCreateSketchProviders from './createSketch';
import useAdminDevelopmentExecuteInterventionProviders from './executeIntervention';
import useAdminDevelopmentSpreadInterventionProviders from './spreadIntervention';

const useAdminViewInterventionDevelopmentInterventionProviders = () => {
  return {
    useAdminDevelopmentInterventionCreativePlanProviders,
    useAdminDevelopmentInterventionKnowProjectProviders,
    useAdminDevelopmentInterventionKnowCommunityProviders,
    useAdminDevelopmentInterventionDefineInterventionProviders,
    useAdminDevelopmentInterventionCreateSketchProviders,
    useAdminDevelopmentExecuteInterventionProviders,
    useAdminDevelopmentSpreadInterventionProviders
  };
};

export default useAdminViewInterventionDevelopmentInterventionProviders;
