//Packages
import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Assets
import imagePin from '../../../../../../../../../assets/icons/MapPin.svg';

//Hooks
import useHelpers from '../../../../../../../../../helpers';
import useViews from '../../../../../../../..';

const StyledWarpperBadges = lazy(() =>
  import(
    '../../../../../../../../../styles/interventions/interventions.styles'
  ).then((mod) => ({
    default: mod.StyledWarpperBadges,
  }))
);

const StylesContentModal = lazy(() =>
  import('./DetailModal.styles').then((mod) => ({
    default: mod.StylesContentModal,
  }))
);

const StyledListComminuties = lazy(() =>
  import('./DetailModal.styles').then((mod) => ({
    default: mod.StyledListComminuties,
  }))
);

const StyledListComminutie = lazy(() =>
  import('./DetailModal.styles').then((mod) => ({
    default: mod.StyledListComminutie,
  }))
);

const StyledComminutie = lazy(() =>
  import('./DetailModal.styles').then((mod) => ({
    default: mod.StyledComminutie,
  }))
);

const DetailModal = (props) => {
  const { projectData } = props;

  const { useComponents } = useViews();

  const { useImages, useTypographies, useBadges, useCards } = useComponents();

  const { Image } = useImages();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { DefaultBadge } = useBadges();
  const { CardUser } = useCards();

  const { useQuickFunctions } = useHelpers();
  const { showCityDepartmentAndCountry } = useQuickFunctions();

  return (
    <StylesContentModal>
      <TypographyComponent
        text={projectData?.name}
        variant="h2"
        className="font-bold text-primary mr-4"
      />
      <TypographyComponent
        className="text-neutral-800 flex"
        variant="h5"
        text={
          <>
            <Image
              srcImage={imagePin}
              altImage="location"
              titleImage="location"
              className="location-icon"
            />
            {showCityDepartmentAndCountry({
              city: projectData?.project?.city,
              format: 'cityDepartmentCountry',
            })}
          </>
        }
      />
      <TypographyComponent
        className="text-neutral-800 flex ml-1 mt-1"
        variant="h5"
        text={`Proyecto:${projectData?.project?.name}`}
      />
      <StyledWarpperBadges>
        <DefaultBadge
          type="light"
          variant="primary"
          label={`Modalidad: ${projectData?.type?.name}`}
        />
        <DefaultBadge
          type="light"
          variant="primary"
          label={`Objetivo: ${projectData?.objetive}`}
        />
      </StyledWarpperBadges>
      <TypographyComponent
        text="Descripción de la intervención"
        variant="p"
        className="font-bold text-primary mr-4 mt-6"
      />
      <TypographyComponent
        text={projectData?.description}
        variant="h5"
        className="text-neutral-950 mr-4 mt-2"
      />
      <TypographyComponent
        text="Artista"
        variant="p"
        className="font-bold text-primary mr-4 mt-6"
      />
      <CardUser
        name={`${projectData?.artist?.name} ${projectData?.artist?.last_name}`}
        location={showCityDepartmentAndCountry({
          city: projectData?.artist?.city,
        })}
        image={projectData?.artist?.avatar}
        className="w-5/12"
      />
      <TypographyComponent
        text="Comunidades"
        variant="p"
        className="font-bold text-primary mr-4 mt-6"
      />
      <StyledListComminuties>
        {_.map(projectData?.communities, (comminitie, idx) => {
          return (
            <StyledListComminutie key={`community-${idx}`}>
              <StyledComminutie>{comminitie.name}</StyledComminutie>
            </StyledListComminutie>
          );
        })}
      </StyledListComminuties>

      <TypographyComponent
        text="Comportamiento se movilizarán en esta región por medio de la intervención"
        variant="p"
        className="font-bold text-primary mr-4 mt-6"
      />
      <StyledListComminuties>
        {_.map(projectData?.definition_behaviors, (behaviour, idx) => {
          return (
            <StyledListComminutie key={`behavior-${idx}`}>
              {behaviour.id !== 4 ? (
                <TypographyComponent
                  text={behaviour.name}
                  variant="h5"
                  className=""
                />
              ) : (
                <TypographyComponent
                  text={projectData.other_definition}
                  variant="h5"
                  className=""
                />
              )}
            </StyledListComminutie>
          );
        })}
      </StyledListComminuties>

      <TypographyWithTooltip
        selectedContainer="div"
        text="Grupo protagonista"
        variant="p"
        textTooltip="Grupo protagonista"
        idTooltip="grupoProtagonista"
        classNameImage="ml-0 mt-5"
        className="mr-4 mt-6 text-sm"
        applyFontBold
      />

      <TypographyComponent
        text={projectData?.leading_group}
        variant="h5"
        className=""
      />

      <TypographyWithTooltip
        selectedContainer="div"
        text="Grupo Aliado"
        variant="p"
        textTooltip="Grupo Aliado"
        idTooltip="grupoAliado"
        classNameImage="ml-0 mt-5"
        className="mr-4 mt-6 text-sm"
        applyFontBold
      />

      <TypographyComponent
        text={projectData?.allied_group}
        variant="h5"
        className=""
      />
    </StylesContentModal>
  );
};

DetailModal.propTypes = {
  projectData: PropTypes.object.isRequired,
};

export default DetailModal;
