//Packages
import React from 'react';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Hooks
import useStepperArtist from '../../Artist';
import useStepperAdmin from '../../Admin';

const StepperSketchIntervention = (props) => {
  const { permissions } = props;

  const { useComponents } = useViews();
  const { useStepper } = useComponents();
  const { Stepper, Step } = useStepper();

  const {
    PilotEjecutionArtist,
    PilotEvaluateArtist,
    PilotInterventionArtist,
    SketchInterventionArtist,
  } = useStepperArtist();

  const {
    PilotProgram,
    PilotIntervention,
    PilotEvaluate,
    Sketchinterventions,
  } = useStepperAdmin();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsDevelopmentIntervention } =
    useAdminViewInterventionsControllers();
  const { useViewDevelopmentInterventionCreateSketch } =
    useAdminViewInterventionsDevelopmentIntervention();
  const { useSketchStepper } = useViewDevelopmentInterventionCreateSketch();
  const {
    createSketch,
    pilotPlan,
    programPilot,
    evaluatePilot,
    currentPage,
    setCurrentPage,
    actualPhase
  } = useSketchStepper();

  return (
    <Stepper
      currentStep={currentPage}
      conetor={true}
      setCurrentStep={setCurrentPage}
      variant="line"
      isSubStepper
    >
      <Step
        label={`${
          permissions
            ? 'Boceto de la intervención'
            : 'Crear boceto de la intervención'
        }`}
        stepStatusIdForColor={createSketch?.state.id}
        stepStatusName={createSketch?.state.name}
        variant={'line'}
        actualPhase={actualPhase}
      >
        {currentPage === 0 && (
          <>
            {permissions ? (
              <Sketchinterventions />
            ) : (
              <SketchInterventionArtist />
            )}
          </>
        )}
      </Step>
      <Step
        label={`${
          permissions ? 'Piloto de la intervención' : 'Planear piloto'
        }`}
        stepStatusIdForColor={
          actualPhase >= 6
            ? createSketch?.state.id
            : pilotPlan?.state?.id
        }
        stepStatusName={
          actualPhase >= 6
            ? createSketch?.state.name
            : pilotPlan?.state?.name
        }
        actualPhase={actualPhase}
      >
        {currentPage === 1 && (
          <>
            {permissions ? <PilotIntervention /> : <PilotInterventionArtist />}
          </>
        )}
      </Step>
      <Step
        label={`${permissions ? 'Programar piloto' : 'Ejecutar piloto'}`}
        stepStatusIdForColor={
          actualPhase >= 6
            ? createSketch?.state.id
            : programPilot?.state.id
        }
        stepStatusName={
          actualPhase >= 6
            ? createSketch?.state.name
            : programPilot?.state.name
        }
        actualPhase={actualPhase}
      >
        {currentPage === 2 && (
          <>{permissions ? <PilotProgram /> : <PilotEjecutionArtist />}</>
        )}
      </Step>
      <Step
        label={'Evaluar piloto'}
        stepStatusIdForColor={
          actualPhase >= 6
            ? createSketch?.state.id
            : evaluatePilot?.state.id
        }
        stepStatusName={
          actualPhase >= 6
            ? createSketch?.state.name
            : evaluatePilot?.state.name
        }
        actualPhase={actualPhase}
      >
        {currentPage === 3 && (
          <>{permissions ? <PilotEvaluate /> : <PilotEvaluateArtist />}</>
        )}
      </Step>
    </Stepper>
  );
};

StepperSketchIntervention.propTypes = {
  permissions: PropTypes.bool.isRequired,
};

export default StepperSketchIntervention;
