import useProviders from '../../../providers';
import useStrings from '../../../../strings';
import useGeneralHooks from '../../../../controllers/generalHooks';

const useNotificationActions = () => {
  //providers
  const { useGeneralProviders } = useProviders();
  const { useNotificationProviders } = useGeneralProviders();
  const {
    getNotificationProvider,
    deleteAllNotificationsProvider,
    deleteNotificationProvider,
    updateNotificationProvider,
  } = useNotificationProviders();
  // console
  const { useConsole } = useGeneralHooks();
  const { viewConsole } = useConsole();
  //strings
  const { useGeneralTypes } = useStrings();
  const { useNotificationTypes } = useGeneralTypes();
  const {
    GET_NOTIFICATION,
    DELETE_ALL_NOTIFICATIONS,
    DELETE_NOTIFICATION,
    UPDATE_NOTIFICATION,
  } = useNotificationTypes();
  const actGetNotification =
    ({ page, paginate, infinity_scroll = false }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await getNotificationProvider({ page, paginate });
        dispatch({
          type: GET_NOTIFICATION,
          payload: { ...response.data, infinity_scroll },
        });
        onSuccess && onSuccess();
      } catch (e) {
        viewConsole([e], 'warn');
        onError && onError();
      }
    };
  const actDeleteAllNotification = (onSuccess, onError) => async (dispatch) => {
    try {
      const response = await deleteAllNotificationsProvider();
      dispatch({
        type: DELETE_ALL_NOTIFICATIONS,
        payload: response.data,
      });
      onSuccess && onSuccess();
    } catch (e) {
      viewConsole([e], 'warn');
      onError && onError();
    }
  };
  const actDeleteNotification =
    ({ id }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await deleteNotificationProvider({ id });
        dispatch({
          type: DELETE_NOTIFICATION,
          payload: response.data,
        });
        onSuccess && onSuccess();
      } catch (e) {
        viewConsole([e], 'warn');
        onError && onError();
      }
    };

  const actUpdateNotification =
    ({ id }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        await updateNotificationProvider({ id });
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { id },
        });
        onSuccess && onSuccess();
      } catch (e) {
        viewConsole([e], 'warn');
        onError && onError();
      }
    };
  return {
    actGetNotification,
    actDeleteAllNotification,
    actDeleteNotification,
    actUpdateNotification,
  };
};
export default useNotificationActions;
