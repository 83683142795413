//Packages
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

//Hooks
import useControllers from '../../..';
import useApi from '../../../../api';
import useModels from '../../../../models';

const useHeaderProfile = () => {
  const history = useHistory();
  const location = useLocation();

  const { useActions } = useApi();
  const { useAuthActions, dispatch, useDashboardActions, useAdminActions } =
    useActions();
  const { useLogoutActions } = useAuthActions();
  const { actLogout } = useLogoutActions();
  const { actGetInterventionsAsArtist } = useDashboardActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminProjectsRootActions } = useAdminProjectsActions();
  const { actGetProjects } = useAdminProjectsRootActions();

  const { useGeneralHooks } = useControllers();
  const { useGetUserProfile } = useGeneralHooks();
  const {
    name,
    last_name,
    avatar,
    role: { id: roleId, name: roleName },
  } = useGetUserProfile();

  const { useSelectors } = useModels();
  const { useSelector, useDashboardSelectors, useAdminSelectors } =
    useSelectors();
  const { artistInterventionsSelector } = useDashboardSelectors();
  const { numberInterventions } = useSelector(artistInterventionsSelector);

  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminProjectsRootSelectors } = useAdminProjectsSelectors();
  const { projectsListSelector } = useAdminProjectsRootSelectors();
  const { numberProjects } = useSelector(projectsListSelector);

  const links = [
    {
      name: 'Mi perfil',
      onClick: () => {
        history.push('/dashboard/profile');
      },
      roles: ['ar', 'ad', 'ep'],
    },
    {
      name: roleId === 'ar' ? 'Mis intervenciones' : 'Mis proyectos',
      notification: roleId === 'ar' ? numberInterventions : numberProjects,
      roles: ['ar', 'ep'],
      onClick: () => {
        history.push('/dashboard');
      },
    },
    {
      name: 'Ajustes de la cuenta',
      onClick: () => {
        history.push('/change-password');
      },
      roles: ['ar', 'ad', 'ep'],
    },
    {
      name: 'Panel de administración',
      onClick: () => {
        history.push('/admin/users');
      },
      roles: ['ad'],
    },
    {
      name: 'Salir',
      onClick: () => {
        dispatch(actLogout());
      },
      roles: ['ar', 'ad', 'ep'],
    },
  ];
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (roleId === 'ar' && location.pathname === '/') {
      dispatch(actGetInterventionsAsArtist({ paginate: 0 }));
    } else if (roleId === 'ep' && location.pathname === '/') {
      dispatch(actGetProjects({ paginate: 0 }));
    }
  }, [roleId, location.pathname]);

  const handleToggleMenu = () => {
    setActive(!active);
  };

  return {
    links,
    user: [`${name} ${last_name}`, roleName, avatar],
    active,
    handleToggleMenu,
  };
};

export default useHeaderProfile;
