import AdminProjectsRouterDashboardCommunities from './Communities';
import AdminProjectsRouterDashboardInterventions from './Interventions';

const useAdminDashboardProjectsRouters = () => {
  return {
    AdminProjectsRouterDashboardCommunities,
    AdminProjectsRouterDashboardInterventions,
  };
};

export default useAdminDashboardProjectsRouters;
