import useExecuteInterventionStepper from './executeInterventionStepper';
import useViewDevelopmentExecuteInterventionAdmin from './admin';
import useViewDevelopmentExecuteInterventionArtist from './artist';

const useViewDevelopmentInterventionExecuteIntervention = () => {
  return {
    useExecuteInterventionStepper,
    useViewDevelopmentExecuteInterventionAdmin,
    useViewDevelopmentExecuteInterventionArtist
  };
};

export default useViewDevelopmentInterventionExecuteIntervention;
