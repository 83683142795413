//Packages
import React, { lazy } from 'react';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
const StyledBoxGray = lazy(() =>
  import('styles/boxes/boxes.styles').then((mod) => ({
    default: mod.StyledBoxGray,
  }))
);

const StyledContentButtons = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const StyledContentButtonsModal = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const StyledWrapperContent = lazy(() =>
  import('../../Root.styles').then((mod) => ({
    default: mod.StyledWrapperContent,
  }))
);

const Section1Slides = () => {
  const { useComponents } = useViews();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminHome } = useAdmin();
  const { useAdminHomeContent } = useAdminHome();
  const {
    handleSetDataFromRichText,
    handleCreateSlide,
    handleEditSlide,
    handleDeleteSlide,
    showModalDelete,
    setShowModalDelete,
    showModal,
    setShowModal,
    handleCancelClose,
    dataHome,
    setIndexArray,
    setPlainTextForPreview,
    defaultValueSlider,
    showPreviewElementInHome,
    errorSliders,
    setErrorSliders,
  } = useAdminHomeContent();

  const { useTypographies, useButtons, useModals, useInputs } = useComponents();
  const { TypographyComponent } = useTypographies();
  const { ButtonComponent } = useButtons();
  const { DefaultModal } = useModals();
  const { InputRichText } = useInputs();

  return (
    <>
      {dataHome.sliders.length > 0 &&
        _.map(dataHome.sliders, (slide, idx) => (
          <StyledBoxGray key={`sliders-${idx}`} className="mb-2">
            <StyledWrapperContent>
              <TypographyComponent
                variant="p"
                text={setPlainTextForPreview({ sliderLikeString: slide.text })}
                className="flex-grow text-primary font-bold "
                onClick={() => {}}
              />
              <StyledContentButtons>
                <ButtonComponent
                  text=""
                  className="icon-view cursor-pointer"
                  onPress={() => {
                    showPreviewElementInHome({
                      section: 'sliders',
                      slideToShow: idx,
                    });
                  }}
                />
                <ButtonComponent
                  text=""
                  className="icon-edit cursor-pointer"
                  onPress={() => {
                    setIndexArray(idx);
                    setShowModal((prevValue) => ({
                      ...prevValue,
                      active: true,
                      mode: 'edit',
                    }));
                  }}
                />
                <ButtonComponent
                  text=""
                  className="icon-delete"
                  onPress={() => {
                    if (dataHome.sliders.length === 1) {
                      setErrorSliders({
                        status: true,
                        message: 'Debe existir mínimo 1 slider',
                      });
                    } else {
                      setShowModalDelete(true);
                      setIndexArray(idx);
                    }
                  }}
                />
              </StyledContentButtons>
            </StyledWrapperContent>
          </StyledBoxGray>
        ))}

      {dataHome.sliders.length < 3 && (
        <ButtonComponent
          text="Crear nuevo slide"
          mode="outline"
          icon={<div className="icon-close transform rotate-45 text-xs" />}
          className="w-full mt-4"
          onPress={() => {
            setIndexArray(null);
            setShowModal({ active: true, mode: 'new' });
          }}
        />
      )}

      <DefaultModal
        isActive={errorSliders.status}
        handleClose={() =>
          setErrorSliders((prev) => ({ ...prev, status: false }))
        }
        isHideAutomatic
        time={4000}
      >
        <TypographyComponent variant="p" text={errorSliders.message} />
        <ButtonComponent
          text="Aceptar"
          className="mx-auto mt-10"
          onPress={() =>
            setErrorSliders((prev) => ({ ...prev, status: false }))
          }
        />
      </DefaultModal>

      <DefaultModal
        isActive={showModal.active}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={handleCancelClose}
      >
        <TypographyComponent
          text={showModal.mode === 'edit' ? 'Editar slide' : 'Nuevo slide'}
          variant="h3"
          className="font-bold text-primary mb-2"
        />
        {showModal.active && (
          <InputRichText
            setRawContentRichText={handleSetDataFromRichText}
            defaultValue={defaultValueSlider}
            maxLength={140}
          />
        )}

        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto lg:mx-2 "
            mode="outline"
            onPress={handleCancelClose}
          />
          <ButtonComponent
            variant="primary"
            text={showModal.mode === 'edit' ? 'Guardar cambios' : 'Crear slide'}
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={
              showModal.mode === 'edit' ? handleEditSlide : handleCreateSlide
            }
          />
        </StyledContentButtonsModal>
      </DefaultModal>

      <DefaultModal
        isActive={showModalDelete}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={() => {
          setShowModalDelete(false);
        }}
      >
        <TypographyComponent
          text={'Eliminar slide'}
          variant="h3"
          className="font-bold text-primary mb-2"
        />

        <TypographyComponent
          text="Esta acción es definitiva, y dejará el inicio con un texto de menos para la rotación"
          variant="p"
          className="font-book text-neutral-950 mb-10"
        />

        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto lg:mx-2 "
            mode="outline"
            onPress={() => {
              setShowModalDelete(false);
            }}
          />
          <ButtonComponent
            variant="primary"
            text="Aceptar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={handleDeleteSlide}
          />
        </StyledContentButtonsModal>
      </DefaultModal>
    </>
  );
};

export default Section1Slides;
