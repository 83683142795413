//Hooks
import useAdminHomeContent from './content';
import useAdminHomeRoot from './root';

const useAdminHome = () => {
  return {
    useAdminHomeContent,
    useAdminHomeRoot,
  };
};

export default useAdminHome;
