//Packages
import _ from 'lodash';
import { useEffect, useMemo } from 'react';

//Hooks
import useApi from 'api';
import useModels from 'models';

const useWhereLive = ({
  countrySelected = '',
  departmentSelected = '',
  languageWithId = false,
  callEndpointCountries = true,
  callEndpointLanguagues = true,
}) => {
  //Actions
  const { useActions } = useApi();
  const { dispatch, useGeneralActions } = useActions();
  const { useCountriesActions, useLanguagesActions } = useGeneralActions();
  const { actGetCountries } = useCountriesActions();
  const { actGetLanguages } = useLanguagesActions();

  //Models
  const { useSelectors } = useModels();
  const { useSelector, useGeneralSelectors } = useSelectors();
  const { useCountriesSelectors, useLanguagesSelectors } =
    useGeneralSelectors();
  const { countriesSelector } = useCountriesSelectors();
  const { languagesSelector } = useLanguagesSelectors();

  const { result: resultCountries, entities: entitiesCountries } =
    useSelector(countriesSelector);
  const languages = useSelector(languagesSelector);

  const optionsLanguages = useMemo(
    () =>
      _.map(languages, (language) => {
        return {
          label: language.name,
          value: languageWithId ? language.id : language.name,
        };
      }),
    [languages]
  );

  const optionsCountries = useMemo(
    () =>
      _.map(resultCountries, (country) => {
        return {
          label: entitiesCountries.countries[country].name,
          value: country,
        };
      }),
    [resultCountries]
  );

  const optionsDepartments = useMemo(() => {
    if (entitiesCountries?.countries && countrySelected) {
      return _.map(
        entitiesCountries?.countries[countrySelected?.value]?.departments,
        (department) => {
          return {
            label: entitiesCountries.departments[department].name,
            value: department,
          };
        }
      );
    }
  }, [entitiesCountries, countrySelected]);

  const optionsCities = useMemo(() => {
    if (entitiesCountries?.countries && departmentSelected) {
      return _.map(
        entitiesCountries?.departments[departmentSelected?.value]?.cities,
        (city) => {
          return {
            label: entitiesCountries.cities[city].name,
            value: city,
          };
        }
      );
    }
  }, [entitiesCountries, departmentSelected]);

  const optionsIndicatives = useMemo(
    () =>
      _.map(resultCountries, (country) => {
        return {
          label: entitiesCountries?.countries[country].code,
          value: country,
        };
      }),
    [resultCountries]
  );

  useEffect(() => {
    if (callEndpointCountries) {
      dispatch(
        actGetCountries({
          paginate: 0,
          append: ['departmentsRelation.citiesRelation'],
        })
      );
    }
    callEndpointLanguagues && dispatch(actGetLanguages({ paginate: 0 }));
  }, []);

  return {
    optionsCountries,
    optionsDepartments,
    optionsCities,
    optionsIndicatives,
    optionsLanguages,
  };
};

export default useWhereLive;
