import TypographyWithTooltip from './TypographyWithTooltip/TypographyWithTooltip';
import TypographyComponent from './DefaultTypography';

const useTypographies = () => {
  return {
    TypographyComponent,
    TypographyWithTooltip,
  };
};
export default useTypographies;
