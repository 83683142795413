import useAdminExecuteMaterializeIntervention from './executeMaterialize';
import useAdminExecuteIntervention from './executeIntervention';

const useViewDevelopmentExecuteInterventionAdmin = () => {
  return {
    useAdminExecuteMaterializeIntervention,
    useAdminExecuteIntervention
  };
};

export default useViewDevelopmentExecuteInterventionAdmin;
