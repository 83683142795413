//Hooks

import useControllers from '../..';

const usePublicRoute = () => {
  //Controllers
  const { useGeneralHooks } = useControllers();
  const { useIsAuth } = useGeneralHooks();
  const { isAuth } = useIsAuth();

  const permission = !isAuth;

  return { permission, props: {} };
};

export default usePublicRoute;
