//Packages
import React, { Suspense, lazy } from 'react';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
const StyledBackButton = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);

const StyledGroup = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledForm = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledForm,
  }))
);

const StyledDivider = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledDivider,
  }))
);

const AdminDashboardEditCommunities = () => {
  const { useComponents } = useViews();
  const {
    useInputs,
    useButtons,
    useTypographies,
    useImages,
    useBoxes,
    useLoaders,
  } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { InputForm, Select, Radio, TextArea, CheckBox } = useInputs();
  const { ButtonComponent } = useButtons();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { UploadImagePreview } = useImages();
  const { Box } = useBoxes();

  const { useScreenHooks, useGeneralHooks } = useControllers();
  const { useSelectLayoutByRole } = useGeneralHooks();
  const { LayoutSelected, props } = useSelectLayoutByRole();

  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminCommunitiesDashboardHooks } =
    useAdminDashboardProjectsHooks();
  const { useAdminEditCommunitiesDashboard } =
    useAdminCommunitiesDashboardHooks();
  const {
    control,
    errors,
    register,
    handleSubmit,
    handleGoBack,
    handleEditCommunity,
    communityData,
    accessibilitiesList,
    optionsLanguages,
    projectData,
    handleDefaultCheckedAccessibility,
    handleResetInputFile,
    handleSelectDefaultImage,
    handleSelectTypeFile,
    setValidateChecboxes,
  } = useAdminEditCommunitiesDashboard();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <LayoutSelected {...props}>
        <StyledBackButton className="mt-3" onClick={handleGoBack}>
          Regresar
        </StyledBackButton>

        <Box className="bg-neutral-200 w-11/12 mx-auto max-w-screen-lg lg:px-16 lg:py-12 px-6 py-8 lg:my-8">
          <>
            <TypographyWithTooltip
              selectedContainer="styledComponent"
              text="Editar comunidad"
              variant="h2"
              textTooltip="Editar comunidad"
              idTooltip="editCommunity"
              classNameImage="ml-4"
              applyFontBold
            />

            <StyledForm>
              <InputForm
                name="name"
                label="Nombre de la comunidad*"
                placeholder="Ingresa el nombre de la comunidad"
                errors={errors}
                {...register('name')}
                defaultValue={communityData?.name}
              />

              <StyledDivider />

              <TypographyComponent
                text={
                  <label>
                    <span className="font-bold">
                      Agregar imagen de portada de la comunidad
                    </span>
                    <span className="text-neutral-800 ml-2 font-light">
                      Formato de archivo permitido JPG,PNG,JPEG
                    </span>
                  </label>
                }
                variant="p"
                className="text-neutral-950 mb-10 w-full"
              />
              <StyledGroup flex>
                <div>
                  <Controller
                    control={control}
                    name="image"
                    render={({ field: { onChange, name } }) => {
                      return (
                        <UploadImagePreview
                          name={name}
                          onChange={onChange}
                          errors={errors}
                          srcDefaultImage={communityData?.community_image?.url}
                          resetInput={() => {
                            handleResetInputFile({ inputFileName: 'image' });
                          }}
                          srcPlaceholderOnlyImage
                        />
                      );
                    }}
                  />
                </div>
              </StyledGroup>

              <StyledGroup noSpace>
                <Select
                  name="country"
                  id="country"
                  control={control}
                  label="País*"
                  placeholder="Selecciona tu país*"
                  errors={errors}
                  defaultValue={{
                    label: projectData.city.department.country.name,
                    value: projectData.city.department.country.id,
                  }}
                  disabled
                />
                <Select
                  name="department"
                  id="department"
                  control={control}
                  label="Departamento o Estado*"
                  placeholder="Selecciona el Dpto./Estado"
                  errors={errors}
                  defaultValue={{
                    label: projectData.city.department.name,
                    value: projectData.city.department.id,
                  }}
                  disabled
                />
              </StyledGroup>
              <StyledGroup noSpace>
                <Select
                  name="city_id"
                  id="city"
                  control={control}
                  label="Ciudad o Municipio*"
                  placeholder="Selecciona tu ciudad*"
                  errors={errors}
                  defaultValue={{
                    label: projectData.city.name,
                    value: projectData.city.id,
                  }}
                  disabled
                />
                <InputForm
                  type="number"
                  name="population_number"
                  label="Población"
                  placeholder="Ingresa la población"
                  errors={errors}
                  {...register('population_number')}
                />
              </StyledGroup>
              <StyledGroup noSpace>
                <Select
                  name="language"
                  id="language"
                  control={control}
                  options={optionsLanguages}
                  label="Idioma*"
                  placeholder="Selecciona tu idioma"
                  errors={errors}
                  defaultValue={{
                    label: communityData?.language,
                    value: communityData?.language,
                  }}
                />
              </StyledGroup>
              <TypographyComponent
                text="¿Cuál es el nivel de alfabetización de la comunidad?"
                variant="p"
                className="font-bold text-neutral-950 mb-10 w-full"
              />
              <Radio
                name="reading_level"
                label="Alto"
                value="high"
                {...register('reading_level')}
                errors={errors}
                checked={communityData?.reading_level.id === 'high'}
              />
              <Radio
                name="reading_level"
                label="Medio"
                value="mid"
                {...register('reading_level')}
                errors={errors}
                checked={communityData?.reading_level.id === 'mid'}
              />
              <Radio
                name="reading_level"
                label="Bajo"
                value="low"
                {...register('reading_level')}
                errors={errors}
                checked={communityData?.reading_level.id === 'low'}
              />
              {errors['reading_level'] && (
                <p name="reading_level" className="text-sm mt-2 text-error">
                  {errors['reading_level'].message}
                </p>
              )}
              <TypographyComponent
                text="¿La comunidad cuenta con acceso a internet?"
                variant="p"
                className="font-bold text-neutral-950 mb-10 w-full"
              />
              <Radio
                name="has_internet"
                label="Alto"
                value="high"
                {...register('has_internet')}
                errors={errors}
                checked={communityData?.has_internet.id === 'high'}
              />
              <Radio
                name="has_internet"
                label="Medio"
                value="mid"
                {...register('has_internet')}
                errors={errors}
                checked={communityData?.has_internet.id === 'mid'}
              />
              <Radio
                name="has_internet"
                label="Bajo"
                value="low"
                {...register('has_internet')}
                errors={errors}
                checked={communityData?.has_internet.id === 'low'}
              />
              {errors['has_internet'] && (
                <p name="has_internet" className="text-sm mt-2 text-error">
                  {errors['has_internet'].message}
                </p>
              )}
              <TypographyComponent
                text="¿Cuál es el nivel de acceso a dispositivos?"
                variant="p"
                className="font-bold text-neutral-950 mb-10 w-full"
              />
              <Radio
                name="device_access"
                label="Alto"
                value="high"
                {...register('device_access')}
                errors={errors}
                checked={communityData?.device_access.id === 'high'}
              />
              <Radio
                name="device_access"
                label="Medio"
                value="mid"
                {...register('device_access')}
                errors={errors}
                checked={communityData?.device_access.id === 'mid'}
              />
              <Radio
                name="device_access"
                label="Bajo"
                value="low"
                {...register('device_access')}
                errors={errors}
                checked={communityData?.device_access.id === 'low'}
              />
              {errors['device_access'] && (
                <p className="text-sm mt-2 text-error">
                  {errors['device_access'].message}
                </p>
              )}
              {communityData && (
                <section>
                  <TypographyComponent
                    text="Medios de comunicación e información"
                    variant="p"
                    className="font-bold text-neutral-950 w-full"
                  />
                  {_.map(accessibilitiesList, (accessibility) => {
                    return (
                      <Controller
                        key={`Accessility-${accessibility.id}`}
                        control={control}
                        name={`communication_medias[${accessibility.id - 1}]`}
                        defaultValue={handleDefaultCheckedAccessibility({
                          accessibilityId: accessibility.id,
                        })}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <CheckBox
                              className="mt-4"
                              name={'communication_medias'}
                              label={accessibility.name}
                              value={`${accessibility.id}`}
                              checked={value}
                              onChange={(e) => {
                                onChange(e.target.checked);
                                setValidateChecboxes((prevState) => !prevState);
                              }}
                              errors={errors}
                              dontShowErrorMessage
                            />
                          );
                        }}
                      />
                    );
                  })}
                </section>
              )}
              {errors['communication_medias'] && (
                <p className="text-sm mt-2 text-error">
                  {errors['communication_medias'].message}
                </p>
              )}

              <TextArea
                name="general_comments"
                placeholder="Agregar comentarios acerca de los medios de comunicación e información"
                className="w-full col-span-2 pt-4 pb-2"
                label="Comentarios acerca de los medios de comunicación e información"
                errors={errors}
                {...register('general_comments')}
                defaultValue={communityData?.general_comments}
              />

              <TypographyComponent
                text={
                  <label>
                    <span className="font-bold">Agregar imágenes o videos</span>
                    <span className="text-neutral-800 ml-2 font-light">
                      Formato de archivo permitido JPG,PNG, JPEG, MP4, MOV
                    </span>
                  </label>
                }
                variant="p"
                className="text-neutral-950 mb-10 w-full"
              />

              <StyledGroup flex className="space-x-4 pb-8">
                <div>
                  <Controller
                    control={control}
                    name="main"
                    render={({ field: { onChange, name } }) => {
                      return (
                        <UploadImagePreview
                          name={name}
                          onChange={onChange}
                          errors={errors}
                          resetInput={() => {
                            handleResetInputFile({ inputFileName: 'main' });
                          }}
                          srcDefaultImage={handleSelectDefaultImage({
                            typeMultimedia: 'main',
                          })}
                          isAVideo={handleSelectTypeFile({
                            typeMultimedia: 'main',
                          })}
                        />
                      );
                    }}
                  />
                </div>
                <div>
                  <Controller
                    control={control}
                    name="first"
                    render={({ field: { onChange, name } }) => {
                      return (
                        <UploadImagePreview
                          name={name}
                          onChange={onChange}
                          errors={errors}
                          resetInput={() => {
                            handleResetInputFile({ inputFileName: 'first' });
                          }}
                          srcDefaultImage={handleSelectDefaultImage({
                            typeMultimedia: 'first',
                          })}
                          isAVideo={handleSelectTypeFile({
                            typeMultimedia: 'first',
                          })}
                        />
                      );
                    }}
                  />
                </div>
                <div>
                  <Controller
                    control={control}
                    name="second"
                    render={({ field: { onChange, name } }) => {
                      return (
                        <UploadImagePreview
                          name={name}
                          onChange={onChange}
                          errors={errors}
                          resetInput={() => {
                            handleResetInputFile({ inputFileName: 'second' });
                          }}
                          srcDefaultImage={handleSelectDefaultImage({
                            typeMultimedia: 'second',
                          })}
                          isAVideo={handleSelectTypeFile({
                            typeMultimedia: 'second',
                          })}
                        />
                      );
                    }}
                  />
                </div>
              </StyledGroup>

              <ButtonComponent
                text="Guardar cambios"
                className="mx-auto"
                onPress={handleSubmit(handleEditCommunity)}
              />
            </StyledForm>
          </>
        </Box>
      </LayoutSelected>
    </Suspense>
  );
};

export default AdminDashboardEditCommunities;
