//Packages
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';

//Hooks
import useStrings from '../../../../../../../strings';
import useApi from '../../../../../../../api';
import useHelpers from '../../../../../../../helpers';
import useControllers from '../../../../../..';
import useModels from '../../../../../../../models';

const useAdminCreateCommunitiesDashboard = () => {
  const history = useHistory();
  const { project_id: projectId } = useParams();
  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const {
    REQUIRED_FIELD,
    NUMBER_NOT_VALID,
    FILE_SIZE,
    FILE_TYPE,
    ARRAY_MIN_1_LENGTH,
    MAX_LENGTH,
    POSITIVE_NUMBER,
    INTEGER_NUMBER,
    MAX_1M_POPULATION_NUMBER,
  } = useFormsTypes();

  const { useActions } = useApi();
  const { dispatch, useAdminActions, useGeneralActions } = useActions();
  const { useAccessibilityActions } = useGeneralActions();
  const { actGetAccessibilities } = useAccessibilityActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminCommunitiesActions } = useAdminProjectsActions();
  const { actCreateCommunityByProject } = useAdminCommunitiesActions();

  const { useQuickFunctions } = useHelpers();
  const { getFileValidations } = useQuickFunctions();
  const { MAX_SIZE, SUPPORTED_FORMATS_ONLY_IMAGES, SUPPORTED_FORMATS } =
    getFileValidations();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors, useGeneralSelectors } =
    useSelectors();
  const { useAccessibilitySelectors } = useGeneralSelectors();
  const { accessibilitiesSelector } = useAccessibilitySelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminProjectsRootSelectors } = useAdminProjectsSelectors();
  const { projectSelectedSelector } = useAdminProjectsRootSelectors();
  const projectData = useSelector(projectSelectedSelector);
  const accessibilitiesList = useSelector(accessibilitiesSelector);

  const schemaCreateCommunity = yup.object({
    name: yup.string().required(REQUIRED_FIELD),
    general_comments: yup.string().nullable().max(40000, MAX_LENGTH),
    language: yup.object().required(REQUIRED_FIELD),
    reading_level: yup.string().required(REQUIRED_FIELD).nullable(),
    has_internet: yup.string().required(REQUIRED_FIELD).nullable(),
    image: yup
      .mixed()
      .nullable()
      .test('fileType', FILE_TYPE, (value) => {
        if (value) {
          return SUPPORTED_FORMATS_ONLY_IMAGES.includes(value.type);
        }
        return true;
      })
      .test('fileSize', FILE_SIZE, (value) => {
        if (value) {
          return value.size <= MAX_SIZE;
        }
        return true;
      }),
    population_number: yup
      .number(NUMBER_NOT_VALID)
      .positive(POSITIVE_NUMBER)
      .integer(INTEGER_NUMBER)
      .max(1000000, MAX_1M_POPULATION_NUMBER)
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value))
      .notRequired(),
    communication_medias: yup
      .array()
      .nullable()
      .min(1, ARRAY_MIN_1_LENGTH)
      .required(REQUIRED_FIELD),
    device_access: yup.string().required(REQUIRED_FIELD).nullable(),
    main: yup
      .mixed()
      .nullable()
      .test('fileType', FILE_TYPE, (value) => {
        if (value) {
          return SUPPORTED_FORMATS.includes(value.type);
        }
        return true;
      })
      .test('fileSize', FILE_SIZE, (value) => {
        if (value) {
          return value.size <= MAX_SIZE;
        }
        return true;
      }),
    first: yup
      .mixed()
      .nullable()
      .test('fileType', FILE_TYPE, (value) => {
        if (value) {
          return SUPPORTED_FORMATS.includes(value.type);
        }
        return true;
      })
      .test('fileSize', FILE_SIZE, (value) => {
        if (value) {
          return value.size <= MAX_SIZE;
        }
        return true;
      }),
    second: yup
      .mixed()
      .nullable()
      .test('fileType', FILE_TYPE, (value) => {
        if (value) {
          return SUPPORTED_FORMATS.includes(value.type);
        }
        return true;
      })
      .test('fileSize', FILE_SIZE, (value) => {
        if (value) {
          return value.size <= MAX_SIZE;
        }
        return true;
      }),
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schemaCreateCommunity),
    mode: 'onChange',
  });

  const { useGeneralHooks } = useControllers();
  const { useWhereLive } = useGeneralHooks();
  const { optionsLanguages } = useWhereLive({ callEndpointCountries: false });

  useEffect(() => {
    if (accessibilitiesList.length === 0) {
      dispatch(actGetAccessibilities());
    }
  }, []);

  const onSuccessHandleRegisterCommunity = () => {
    history.goBack();
    reset();
  };

  const handleRegisterCommunity = (data) => {
    dispatch(
      actCreateCommunityByProject(
        { ...data, projectId },
        onSuccessHandleRegisterCommunity
      )
    );
  };

  const handleResetInputFile = ({ inputFileName }) => {
    setValue(inputFileName, null);
    clearErrors(inputFileName);
  };

  const handleGoBack = () => history.goBack();

  return {
    control,
    errors,
    register,
    handleSubmit,

    optionsLanguages,
    handleRegisterCommunity,
    handleResetInputFile,
    handleGoBack,
    projectData,
    accessibilitiesList,
  };
};

export default useAdminCreateCommunitiesDashboard;
