import React, { lazy, useState } from 'react';
import { Controller } from 'react-hook-form';
//Hooks
import useViews from '../../../../../../../../../../..';

import useControllers from 'controllers';
import _ from 'lodash';

//Styles
const StyledButtonsActions = lazy(() =>
  import(
    '../../../../../../../../../../../../styles/buttons/buttons.styles'
  ).then((mod) => ({
    default: mod.StyledButtonsActions,
  }))
);

const StyledCardCommunity = lazy(() =>
  import('../../../../../../../Dashboard.styles').then((mod) => ({
    default: mod.StyledCardCommunity,
  }))
);

const StyledContentButtonsModal = lazy(() =>
  import(
    '../../../../../../../../../../../../styles/tableViews/tableViews.styles'
  ).then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const SketchinterventionsArtist = () => {
  const { useComponents } = useViews();
  const {
    useTypographies,
    useButtons,
    useModals,
    useInputs,
    useCollapses,
    useBadges,
    useCards,
  } = useComponents();

  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { CardNotification } = useCards();
  const { ButtonComponent } = useButtons();
  const { DefaultModal } = useModals();
  const { InputFile } = useInputs();
  const { DefaultCollapse } = useCollapses();
  const { DefaultBadge } = useBadges();

  const [showModalAprove, setShowModalAprove] = useState(false);

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsDevelopmentIntervention } =
    useAdminViewInterventionsControllers();
  const { useViewDevelopmentInterventionCreateSketch } =
    useAdminViewInterventionsDevelopmentIntervention();
  const { useViewDevelopmentInterventionCreateSketchArtist } =
    useViewDevelopmentInterventionCreateSketch();
  const { useArtistCreateSketchInterventions } =
    useViewDevelopmentInterventionCreateSketchArtist();
  const {
    textForLimitDate,
    createSketch,
    handleSubmit,
    watch,
    openFile,
    control,
    errors,
    updateCreateSketchStepOneFileIntervention,
    handleDeleteFileSelected,
    downloadFile,
    getYearMonthDayFromDate,
  } = useArtistCreateSketchInterventions();

  return (
    <>
      {_.includes(['iv', 'p', 'c', 'ip'], createSketch.state.id) && (
        <CardNotification
          className="w-5/12 mx-auto py-9 px-4 mt-16"
          title={
            <TypographyComponent
              text={createSketch.state.name}
              variant="h2"
              className="font-bold text-primary icon-watch text-center"
            />
          }
        />
      )}
      {createSketch.rejected_reasons.length !== 0 && (
        <>
          <br />
          <DefaultCollapse
            title={
              <TypographyWithTooltip
                text="Recomendaciones del Socio Ejecutor"
                variant="h3"
                textTooltip="Recomendaciones del Socio Ejecutor"
                className="text-primary"
                idTooltip="recomendations"
                applyFontBold
              />
            }
            titleTitleCollapsed=""
            elementCollapsed={
              <div className="icon-arrow-down text-xxs text-neutral-950 cursor-pointer" />
            }
            elementUncollapsed={
              <div className="icon-arrow-down text-xxs text-neutral-950 transform rotate-180 cursor-pointer" />
            }
            className="border border-error py-6 rounded pl-8 pr-10 mb-8 bg-neutral-300"
            classNameTitle="w-full flex justify-between"
            withUseState
          >
            <div className="overflow-y-auto mt-4 max-h-96">
              {_.map(createSketch.rejected_reasons, (reason, idx) => {
                const date = getYearMonthDayFromDate({
                  dateInString: reason.created_at,
                  format: 'dmyTime',
                });
                return (
                  <div
                    className="bg-white rounded-lg border p-6 mb-4"
                    key={`reason-${idx}`}
                  >
                    <TypographyComponent
                      variant="p"
                      className="text-neutral-800 mb-4"
                      text={date}
                    />
                    <TypographyComponent
                      variant="p"
                      className="text-neutral-950"
                      text={reason.rejected_reason}
                    />

                    <StyledCardCommunity>
                      <TypographyComponent
                        variant="p"
                        text={
                          createSketch.rejected_reasons[idx]
                            ?.rejected_reason_file?.url
                        }
                        className="flex-grow text-primary font-bold cursor-pointer"
                      />
                      <StyledButtonsActions className="flex items-center">
                        <ButtonComponent
                          text=""
                          className="icon-view"
                          onPress={(event) =>
                            openFile({
                              event,
                              urlFile:
                                createSketch.rejected_reasons[idx]
                                  ?.rejected_reason_file?.url,
                            })
                          }
                        />
                        <ButtonComponent
                          text=""
                          icon={
                            <div className="icon-download text-primary text-lg" />
                          }
                          onPress={(event) =>
                            downloadFile({
                              event,
                              idFile:
                                createSketch?.rejected_reasons[idx]
                                  ?.rejected_reason_file?.id,
                              nameFile:
                                createSketch?.rejected_reasons[idx]
                                  ?.rejected_reason_file?.url,
                            })
                          }
                        />
                      </StyledButtonsActions>
                    </StyledCardCommunity>
                  </div>
                );
              })}
            </div>
          </DefaultCollapse>
        </>
      )}

      <TypographyComponent
        text="Crear boceto de la intervención"
        variant="h2"
        className="font-bold text-primary mt-6"
      />
      <TypographyComponent
        text="Comparte tu primer borrador tomando en cuenta las recomendaciones del socio ejecutor."
        variant="p"
        className="text-neutral-950 mt-2"
      />
      <TypographyComponent
        text="Este paso contiene 4 subetapas."
        variant="p"
        className="text-neutral-950 mt-2"
      />

      {!_.includes(['iv', 'p'], createSketch.state.id) && (
        <>
          <DefaultBadge
            label={`Fecha limite para enviar boceto de la intervención: ${textForLimitDate?.text}`}
            variant={textForLimitDate?.colorBadge}
            type="light"
            className="mt-2"
          />

          {(!watch('sketch_file') && !createSketch.sketch_file?.url) ||
          errors?.sketch_file?.message ||
          (createSketch?.rejected_reasons.length > 0 &&
            !watch('sketch_file') &&
            createSketch.state.id !== 'c') ? (
            <>
              <Controller
                control={control}
                name="sketch_file"
                render={({ field: { onChange, name } }) => {
                  return (
                    <InputFile
                      name={name}
                      accept={'.pdf'}
                      title={
                        <div className="flex items-center">
                          <div
                            className={`icon-paper-clip text-primary text-xl font-bold h-10 ${
                              errors['sketch_file'] && 'text-error'
                            }`}
                          />
                          <TypographyComponent
                            text="Adjuntar archivo"
                            variant="p"
                            className={`text-primary font-bold ${
                              errors['sketch_file'] && 'text-error'
                            }`}
                          />
                        </div>
                      }
                      onChange={onChange}
                      className={`w-full mt-2 bg-none mb-4 ${
                        errors['sketch_file'] && 'border-error'
                      }`}
                      errors={errors}
                    />
                  );
                }}
              />
            </>
          ) : (
            <div className="bg-white py-2 px-4 flex justify-between rounded-sm items-center mt-10">
              <TypographyComponent
                variant="p"
                text={
                  createSketch.sketch_file?.url && createSketch.state.id !== 'r'
                    ? createSketch.sketch_file?.url
                    : watch('sketch_file')?.name
                }
                className="flex-grow text-primary font-bold"
              />
              <StyledButtonsActions className="flex items-center">
                <ButtonComponent
                  text=""
                  className="icon-view"
                  onPress={(event) =>
                    openFile({
                      event,
                      urlFile: watch('sketch_file'),
                      fileType: 'sketch',
                    })
                  }
                />
                {!_.includes(['iv', 'c'], createSketch.state?.id) && (
                  <ButtonComponent
                    text=""
                    className="icon-delete"
                    onPress={(event) => {
                      handleDeleteFileSelected({
                        event,
                        property: 'sketch_file',
                      });
                    }}
                  />
                )}
              </StyledButtonsActions>
            </div>
          )}
          {createSketch.state.id !== 'c' && (
            <div className="flex items-center space-x-2 mt-10 mb-6 justify-center">
              <ButtonComponent
                text="Validar boceto"
                disabled={!watch('sketch_file')}
                onPress={() => {
                  setShowModalAprove(true);
                }}
              />
            </div>
          )}
        </>
      )}

      <DefaultModal
        isActive={showModalAprove}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={() => {
          setShowModalAprove(false);
        }}
      >
        <TypographyComponent
          text={'Validar boceto de la intervención'}
          variant="h3"
          className="font-bold text-primary mb-1"
        />

        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto lg:mx-2 "
            mode="outline"
            onPress={() => setShowModalAprove(false)}
          />
          <ButtonComponent
            variant="primary"
            text="Aceptar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={handleSubmit((data) => {
              updateCreateSketchStepOneFileIntervention(
                data,
                setShowModalAprove
              );
            })}
          />
        </StyledContentButtonsModal>
      </DefaultModal>
    </>
  );
};

export default SketchinterventionsArtist;
