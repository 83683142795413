//Packages
import { useRef, useState } from 'react';
import { useEffect } from 'react';

//Hooks
import useControllers from '../..';

const useMakeAncla = ({ scrollToNameSection }) => {
  const [validateScrollTo, setValidateScrollTo] = useState(false);
  const elementToMakeAnclaRef = useRef(null);

  const { useGeneralHooks } = useControllers();
  const { useQueryParams } = useGeneralHooks();
  const queryParams = useQueryParams();
  const scrollNameForParams = queryParams.get('scroll');

  const scrollToRef = (ref) => {
    if (ref.current) {
      if (ref.current.offsetTop === 0) {
        setValidateScrollTo(!validateScrollTo);
      } else {
        window.scrollTo(0, ref.current?.offsetTop);
      }
    }
  };

  useEffect(() => {
    if (scrollNameForParams === scrollToNameSection) {
      setTimeout(() => {
        scrollToRef(elementToMakeAnclaRef);
      }, 1500);
    }
  }, [validateScrollTo, elementToMakeAnclaRef, scrollNameForParams]);

  return { elementToMakeAnclaRef };
};

export default useMakeAncla;
