//Packages
import { normalize, schema } from 'normalizr';

//Hooks
import useApi from '../../..';
import useControllers from '../../../../controllers';
import useStrings from '../../../../strings';

const useLoginActions = () => {
  const { useProviders } = useApi();
  const { useAuthProviders } = useProviders();
  const { useLoginProviders } = useAuthProviders();
  const { login } = useLoginProviders();

  const { useGeneralHooks } = useControllers();
  const { useConsole } = useGeneralHooks();
  const { viewConsole } = useConsole();

  const { useAuthTypes, useUserTypes } = useStrings();
  const { DATA_USER } = useUserTypes();
  const { LOGIN } = useAuthTypes();

  const actLogin =
    ({ email, password } = {}, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await login({ email, password });
        dispatch({ type: LOGIN, payload: res.data });
        //Networks indexed
        const network = new schema.Entity('networks', undefined, {
          idAttribute: (value) =>
            value.type.id ? `${value.type.id}` : value.id,
        });

        const networks = [network];

        const normalizeDataNetworks = normalize(
          res.data?.user?.networks || [],
          networks
        );

        dispatch({
          type: DATA_USER,
          payload: {
            ...res.data?.user,
            networksIndexed: normalizeDataNetworks.entities?.networks,
          },
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  return { actLogin };
};

export default useLoginActions;
