import { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import _ from 'lodash';

// Hooks
import useStrings from 'strings';
import useModels from 'models';
import useHelpers from 'helpers';
import useApi from 'api';

const useArtistPlanPilotIntervention = () => {
  const { intervention_id: interventionId } = useParams();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();
  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const { useAdminDevelopmentInterventionCreateSketchActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const {
    actGetPilotPlanIntervention,
    actUpdatePilotPlanIntervention,
    actDownloadPilotPlanCriteriaFileIntervention,
    actCreatePilotPlanActivityIntervention,
    actUpdatePilotPlanActivityIntervention,
    actDeletePilotPlanActivityIntervention,
  } = useAdminDevelopmentInterventionCreateSketchActions();

  const { useSelectors } = useModels();
  const { useAdminSelectors, useSelector } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionDetailToEditSelector, getInterventionsSelector } =
    useAdminInterventionsSelectors();
  const { communities } = useSelector(getInterventionDetailToEditSelector);
  const { pilotPlan } = useSelector(getInterventionsSelector);
  const { id: pilotPlanId } = pilotPlan;

  const { useMessagesTypes, useConstants } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { OPTIONS_MODALITY } = useConstants();
  const {
    NUMBER_NOT_VALID,
    REQUIRED_FIELD,
    FILE_TYPE,
    DATE_GREATER_THAN_TODAY,
    POSITIVE_NUMBER,
    INTEGER_NUMBER,
    END_TIME_LATER_THAN_START_TIME,
    FILE_SIZE_5MB,
  } = useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const {
    isSelectedDateGreaterThanTodayDate,
    getTimeInISOFormat,
    downloadFileFromBackend,
    setDefaultValueInputDateTimeWithTimeLocalZone,
    getFileValidations,
  } = useQuickFunctions();
  const { MAX_SIZE_5MB } = getFileValidations();

  const [activeStep, setActiveStep] = useState(1);
  const [showModalApprove, setShowModalApprove] = useState(false);
  const [showModalNoActivities, setShowModalNoActivities] = useState(false);

  const schemaPlanPilot = yup.object({
    file: !pilotPlan.criteria_file?.id
      ? yup
          .mixed()
          .required(REQUIRED_FIELD)
          .test('fileType', FILE_TYPE, (value) => {
            if (value) {
              return 'application/pdf' === value.type;
            }
            return true;
          })
          .test('fileSize', FILE_SIZE_5MB, (value) => {
            if (value) {
              return value.size <= MAX_SIZE_5MB;
            }
            return true;
          })
      : yup
          .mixed()
          .test('fileType', FILE_TYPE, (value) => {
            if (value) {
              return 'application/pdf' === value.type;
            }
            return true;
          })
          .test('fileSize', FILE_SIZE_5MB, (value) => {
            if (value) {
              return value.size <= MAX_SIZE_5MB;
            }
            return true;
          })
          .notRequired(),
    community: yup.object().required(REQUIRED_FIELD),
    modality: yup.object().required(REQUIRED_FIELD),
    date: yup
      .date()
      .default(null)
      .nullable()
      .required(REQUIRED_FIELD)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .test('dateValidation', DATE_GREATER_THAN_TODAY, (value) => {
        return value
          ? !_.includes(['iv', 'c'], pilotPlan.state?.id)
            ? isSelectedDateGreaterThanTodayDate({ selectedDate: value })
            : true
          : true;
      }),
    start_time: yup.string().required(REQUIRED_FIELD),
    end_time: yup
      .string()
      .required(REQUIRED_FIELD)
      .when('start_time', (start_time, yup) => {
        if (start_time) {
          return yup.test(
            'dateValidation',
            END_TIME_LATER_THAN_START_TIME,
            (value) =>
              Number(`${value}`.replace(':', '')) >
              Number(`${start_time}`.replace(':', ''))
          );
        }
      }),
    participants: yup
      .number(NUMBER_NOT_VALID)
      .typeError(REQUIRED_FIELD)
      .positive(POSITIVE_NUMBER)
      .integer(INTEGER_NUMBER)
      .required(REQUIRED_FIELD),
    toolFile:
      activeStep === 2 && !pilotPlan.tool_file?.id
        ? yup
            .mixed()
            .required(REQUIRED_FIELD)
            .test('fileType', FILE_TYPE, (value) => {
              if (value) {
                return 'application/pdf' === value.type;
              }
              return true;
            })
            .test('fileSize', FILE_SIZE_5MB, (value) => {
              if (value) {
                return value.size <= MAX_SIZE_5MB;
              }
              return true;
            })
        : yup
            .mixed()
            .test('fileType', FILE_TYPE, (value) => {
              if (value) {
                return 'application/pdf' === value.type;
              }
              return true;
            })
            .test('fileSize', FILE_SIZE_5MB, (value) => {
              if (value) {
                return value.size <= MAX_SIZE_5MB;
              }
              return true;
            })
            .notRequired(),
  });

  const planPilotForm = useForm({
    resolver: yupResolver(schemaPlanPilot),
    mode: 'onChange',
  });

  const optionsCommunities = useMemo(() => {
    return _.map(communities, ({ name, id }) => ({
      label: name,
      value: id,
    }));
  }, [communities]);

  useEffect(() => {
    if (pilotPlan.state?.id !== 'c') {
      dispatch(
        actGetPilotPlanIntervention({
          interventionId,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (pilotPlan.date_r || pilotPlan.date_iv) {
      planPilotForm.reset({
        community: {
          value: pilotPlan.community?.id,
          label: pilotPlan.community?.name,
        },
        modality: {
          value: pilotPlan.modality?.id,
          label: pilotPlan.modality?.name,
        },
        date: pilotPlan.date,
        start_time: pilotPlan.start_time
          ? setDefaultValueInputDateTimeWithTimeLocalZone({
              dateInUTC: `2022-05-05T${pilotPlan.start_time}`,
            })
              .split('.')[0]
              .split('T')[1]
              .slice(0, 5)
          : '',
        end_time: pilotPlan.start_time
          ? setDefaultValueInputDateTimeWithTimeLocalZone({
              dateInUTC: `2022-05-05T${pilotPlan.end_time}`,
            })
              .split('.')[0]
              .split('T')[1]
              .slice(0, 5)
          : '',
        participants: pilotPlan.participants,
      });
    }
  }, [pilotPlan.date_r, pilotPlan.date_iv]);

  const openFile = ({ e, file, watchFile }) => {
    e && e.preventDefault();
    if (watchFile) {
      const blobUrl = URL.createObjectURL(watchFile);
      window.open(blobUrl);
    } else {
      window.open(file.url);
    }
  };

  const downloadFile = ({ e, file, nameFile }) => {
    e && e.preventDefault();
    dispatch(
      actDownloadPilotPlanCriteriaFileIntervention(
        {
          interventionId,
          pilotPlanId,
          fileId: file.id,
        },
        (response) => {
          downloadFileFromBackend({ response, nameFile });
        }
      )
    );
  };

  const deleteFile = ({ e, file }) => {
    e && e.preventDefault();
    planPilotForm.setValue(`${file}`, undefined);
  };

  const savePlanPilotActivity = ({ activity }) => {
    if (activity.id) {
      dispatch(
        actUpdatePilotPlanActivityIntervention({
          interventionId,
          pilotPlanId,
          activityId: activity.id,
          data: activity,
        })
      );
    } else {
      dispatch(
        actCreatePilotPlanActivityIntervention({
          interventionId,
          pilotPlanId,
          data: activity,
        })
      );
    }
  };

  const deletePlanPilotActivity = (activity) => {
    dispatch(
      actDeletePilotPlanActivityIntervention({
        interventionId,
        pilotPlanId,
        activityId: activity.id,
      })
    );
  };

  const updatePilotPlanIntervention = (data) => {
    const bodyFormData = new FormData();
    data.file && bodyFormData.append('criteria_file', data.file);
    data.toolFile && bodyFormData.append('tool_file', data.toolFile);
    bodyFormData.append('community_id', data.community.value);
    bodyFormData.append('modality', data.modality.value);
    bodyFormData.append(
      'date',
      setDefaultValueInputDateTimeWithTimeLocalZone({
        dateInUTC: data.date,
      }).split('T')[0]
    );
    bodyFormData.append(
      'start_time',
      getTimeInISOFormat({ time: `2022-05-05T${data.start_time}` })
    );
    bodyFormData.append(
      'end_time',
      getTimeInISOFormat({ time: `2022-05-05T${data.end_time}` })
    );
    bodyFormData.append('participants', data.participants);
    bodyFormData.append('state', 'iv');
    bodyFormData.append('_method', 'patch');

    dispatch(
      actUpdatePilotPlanIntervention(
        {
          interventionId,
          pilotPlanId,
          data: bodyFormData,
        },
        () => setShowModalApprove(false),
        () => setShowModalApprove(false)
      )
    );
  };

  return {
    OPTIONS_MODALITY,
    pilotPlan,
    activeStep,
    setActiveStep,
    planPilotForm,
    optionsCommunities,
    planPilotActivities: pilotPlan?.activities,
    showModalApprove,
    setShowModalApprove,
    canEditForm: !_.includes(['iv', 'c'], pilotPlan.state?.id),
    showModalNoActivities,
    setShowModalNoActivities,
    openFile,
    downloadFile,
    deleteFile,
    savePlanPilotActivity,
    deletePlanPilotActivity,
    updatePilotPlanIntervention,
  };
};

export default useArtistPlanPilotIntervention;
