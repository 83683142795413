//Packages
import React, { Suspense, lazy } from 'react';

//Hooks
import useViews from 'views';
import useProfileComponents from './components';
import useControllers from 'controllers';

//Styles
const StyledContentDashboard = lazy(() =>
  import('./Dashboard.styles').then((mod) => ({
    default: mod.StyledContentDashboard,
  }))
);
const StylesTopSection = lazy(() =>
  import('./Dashboard.styles').then((mod) => ({
    default: mod.StylesTopSection,
  }))
);
const StyledInputSearchContent = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledInputSearchContent,
  }))
);

const Dashboard = () => {
  const { useLayouts, useComponents } = useViews();
  const { MainLayout, ColumTwoLayout } = useLayouts();
  const { useBoxes, useTabs, useInputs, useLoaders } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { Box } = useBoxes();
  const { Tabs, TabLink } = useTabs();
  const { InputSearch } = useInputs();

  const {
    AdminProfile,
    ArtistProfile,
    PartnerProfile,
    TabMyProjects,
    TabMyInterventions,
  } = useProfileComponents();

  const { useScreenHooks } = useControllers();
  const { useDashboardControllers } = useScreenHooks();
  const { useDashboard } = useDashboardControllers();
  const {
    roleId,
    tabValue,
    handleSelectTab,
    handleGetValueTextSearch,
    textSearchValue,
  } = useDashboard();

  const tabSelected = {
    0:
      roleId === 'ar' ? (
        <TabMyInterventions
          tabValue={tabValue}
          textSearchValue={textSearchValue}
        />
      ) : (
        <TabMyProjects textSearchValueAux={textSearchValue} />
      ),
  };

  return (
    <Suspense fallback={<DefaultLoader />}>
      <MainLayout hasDecorationMap col2>
        <StyledContentDashboard>
          <ColumTwoLayout
            topPart={<></>}
            rightPart={
              <Box
                className={`w-full bg-primary px-8 lg:px-10 lg:py-10 transition-all duration-700 flex flex-col`}
              >
                {roleId === 'ad' ? (
                  <AdminProfile />
                ) : roleId === 'ar' ? (
                  <ArtistProfile />
                ) : (
                  <PartnerProfile />
                )}
              </Box>
            }
            leftPart={
              <>
                <StylesTopSection>
                  <Tabs
                    selected={tabValue}
                    onSelect={handleSelectTab}
                    className="w-full lg:w-9/12 mb-5 lg:mb-0 whitespace-nowrap overflow-auto lg:whitespace-normal"
                  >
                    <TabLink
                      label={
                        roleId === 'ar' ? 'Mis intervenciones' : 'Mis proyectos'
                      }
                      tab={0}
                    />
                    {/* <TabLink label="Mis favoritos" tab={1} />
                    <TabLink
                      label={
                        roleId === 'ar'
                          ? 'Comunidad de Artistas'
                          : 'Comunidad de Socios ejecutores'
                      }
                      tab={2}
                    /> */}
                  </Tabs>
                  <StyledInputSearchContent
                    fullWidth
                    className="w-full lg:w-3/12"
                  >
                    <InputSearch
                      // text={textSearch}
                      onDebounce={handleGetValueTextSearch}
                    />
                  </StyledInputSearchContent>
                </StylesTopSection>
                {tabSelected[tabValue]}
              </>
            }
            classNameRigh="2xl:w-1/5 xl:w-3/12 lg:w-3/12"
            classNameLeft="2xl:w-4/5 xl:w-9/12 lg:w-9/12 lg:px-10 px-5 bg-white lg:bg-transparent"
          />
        </StyledContentDashboard>
      </MainLayout>
    </Suspense>
  );
};

export default Dashboard;
