import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const StyledButtonsActions = styled.div.attrs(() => ({
  className: 'StyledButtonsActions',
}))`
  button {
    ${tw` bg-transparent text-tertiary shadow-none border-none hover:bg-transparent px-0 py-0 `}
    font-size:1.7142857142857142rem;
    &.icon-edit {
      font-size: 2.5rem;
      height: 26px;
      ${tw`  text-primary w-8`}
      &:before {
        ${tw`  h-full`}
      }
    }
    &.icon-view,
    &.icon-download {
      ${tw`  text-primary `}
    }
    &.icon-disabled {
      ${tw` text-neutral-700 cursor-default`}
    }
  }
`;
