//Packages
import React, { Suspense, lazy } from 'react';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

const StyledForm = lazy(() =>
  import('../../Profile.styles').then((mod) => ({
    default: mod.StyledForm,
  }))
);

const StyledGroup = lazy(() =>
  import('../../Profile.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);
const StyledPhotocontent = lazy(() =>
  import('../../Profile.styles').then((mod) => ({
    default: mod.StyledPhotocontent,
  }))
);

const Admin = () => {
  //layout- components
  const { useComponents } = useViews();
  const { useTypographies, useInputs, useButtons, useImages, useLoaders } =
    useComponents();
  const { DefaultLoader } = useLoaders();
  const { TypographyComponent } = useTypographies();
  const { InputForm, Select } = useInputs();
  const { ButtonComponent } = useButtons();
  const { UploadImage } = useImages();

  const { useScreenHooks } = useControllers();
  const { useProfile } = useScreenHooks();
  const {
    userInfo,
    errors,
    control,
    handleSubmit,
    handleUpdateDataUser,
    handleUpdateDataUserOnSelect,
    handleUpdateProfile,
    handleUpdateAvatar,
    optionsCountries,
    optionsDepartments,
    optionsCities,
    optionsIndicatives,
    optionsLanguages,
    countrySelected,
  } = useProfile();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <>
        <TypographyComponent
          text="Editar Perfil"
          variant="h2"
          className="font-bold text-primary mb-4 text-lg"
        />
        <StyledPhotocontent>
          <UploadImage
            classNameImage="w-28 h-28 rounded-full object-cover"
            className="w-28 h-28 rounded-full"
            imageDefault={userInfo.avatar}
            handleChangeImage={handleUpdateAvatar}
          />
        </StyledPhotocontent>
        <StyledForm>
          <StyledGroup>
            <TypographyComponent
              text="Información básica"
              variant="h3"
              className="font-bold text-primary  col-span-2 mb-1"
            />
            <TypographyComponent
              text="Las cosas cambian, y para mantenernos en contacto es importante que no lo dejes saber! Gracias por tomarte el tiempo de actualizar tus datos."
              variant="p"
              className="text-neutral-950 mb-4 col-span-2"
            />
            <InputForm
              control={control}
              type="text"
              name="name"
              placeholder="Nombre"
              label="Nombre*"
              className="w-full"
              errors={errors}
              onChange={handleUpdateDataUser}
            />
            <InputForm
              control={control}
              type="text"
              name="last_name"
              placeholder="Apellido*"
              label="Apellido*"
              className="w-full"
              errors={errors}
              onChange={handleUpdateDataUser}
            />
            <InputForm
              control={control}
              type="email"
              name="email"
              placeholder="Ingresa tu correo electrónico"
              label="Correo electrónico*"
              className="w-full"
              errors={errors}
              disabled
            />
            <Select
              name="country"
              id="select-example-label"
              placeholder="Selecciona tu país"
              control={control}
              options={optionsCountries}
              label="País*"
              errors={errors}
              onChange={handleUpdateDataUserOnSelect}
            />
            <Select
              name="department"
              id="city_id"
              placeholder="Selecciona tu departamento"
              control={control}
              options={optionsDepartments}
              label="Departamento o Estado*"
              errors={errors}
              disabled={!countrySelected}
              onChange={handleUpdateDataUserOnSelect}
            />
            <Select
              name="city_id"
              id="city_id"
              placeholder="Selecciona tu ciudad"
              control={control}
              options={optionsCities}
              label="Ciudad o Municipio*"
              errors={errors}
              disabled={!countrySelected}
              onChange={handleUpdateDataUserOnSelect}
            />
            <StyledGroup
              col2
              className="items-end flex space-x-4 lg:space-x-3 "
            >
              <Select
                name="phone_code"
                id="phone_code"
                placeholder="Selecciona su indicativo"
                className="w-1/3 lg:w-7/12"
                control={control}
                options={optionsIndicatives}
                label="Teléfono*"
                errors={errors}
                onChange={handleUpdateDataUserOnSelect}
              />
              <InputForm
                control={control}
                type="number"
                name="phone"
                placeholder="Ingresa tu número de teléfono"
                className="w-2/3 lg:w-full"
                errors={errors}
                onChange={handleUpdateDataUser}
              />
            </StyledGroup>
            <InputForm
              control={control}
              type="text"
              name="gender"
              placeholder="Ingresa tu identidad de género"
              label="Género"
              className="w-full"
              errors={errors}
              onChange={handleUpdateDataUser}
            />
            <Select
              name="language"
              id="language"
              control={control}
              options={optionsLanguages}
              label="Idioma*"
              placeholder="Selecciona tu idioma"
              errors={errors}
              onChange={handleUpdateDataUserOnSelect}
            />
          </StyledGroup>

          <ButtonComponent
            variant="primary"
            text="Guardar"
            onPress={handleSubmit(handleUpdateProfile)}
          />
        </StyledForm>
      </>
    </Suspense>
  );
};

export default Admin;
