//Packages
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
import {
  StyledDropDownFilter,
  StyledWrapperFilter,
  StyledWarpperButtonFilters,
} from 'styles/filters/filters.styles';

const DropDownFilter = (props) => {
  const { className, open, isOnFocusTextSearch, handleOpenMenu } = props;

  const { useComponents } = useViews();
  const { useInputs, useButtons } = useComponents();
  const { Select } = useInputs();
  const { ButtonComponent } = useButtons();
  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useUserAdmin } = useAdmin();
  const { useFilterUser } = useUserAdmin();

  const {
    control,
    optionsCountries,
    optionsDepartments,
    optionsCities,
    optionsLanguages,
    countrySelected,
    departmentSelected,
    handleResetFilter,
    communitiesList,
    handleSubmit,
    applyFilter,
    LIST_OF_OPTIONS_HAS_WHATSAPP,
    OPTIONS_ROLES_WITHOUT_LOGIN,
  } = useFilterUser({
    activeDropDown: open,
    isOnFocusTextSearch,
    handleOpenMenu,
    contact: 'contacts',
  });

  return (
    <StyledDropDownFilter className={className} open={open}>
      <StyledWrapperFilter>
        <Select
          name="roles"
          id="role"
          placeholder="Seleccionar"
          control={control}
          options={OPTIONS_ROLES_WITHOUT_LOGIN}
          label="Rol"
        />
        <Select
          name="country_id"
          id="country_id"
          placeholder="Seleccionar"
          control={control}
          options={optionsCountries}
          label="País"
        />
      </StyledWrapperFilter>
      <StyledWrapperFilter>
        <Select
          name="department_id"
          id="department_id"
          placeholder="Seleccionar"
          control={control}
          options={optionsDepartments}
          label="Departamento o Estado"
          disabled={!countrySelected}
        />
        <Select
          name="city_id"
          id="city_id"
          placeholder="Seleccionar"
          control={control}
          options={optionsCities}
          label="Ciudad o Municipio"
          disabled={!departmentSelected}
        />
      </StyledWrapperFilter>
      <StyledWrapperFilter>
        <Select
          name="community_id"
          id="community_id"
          placeholder="Seleccionar"
          control={control}
          options={_.map(communitiesList, (community) => ({
            label: community.name,
            value: community.id,
          }))}
          label="Comunidad"
        />
        <Select
          name="language"
          id="language"
          placeholder="Seleccionar"
          control={control}
          options={optionsLanguages}
          label="Idioma"
        />
      </StyledWrapperFilter>
      <StyledWrapperFilter>
        <Select
          name="has_whatsapp"
          id="has_whatsapp"
          placeholder="Seleccionar"
          control={control}
          options={LIST_OF_OPTIONS_HAS_WHATSAPP}
          label="WhastApp"
        />
      </StyledWrapperFilter>
      <StyledWarpperButtonFilters>
        <ButtonComponent
          variant="primary"
          mode="outline"
          className="mt-4 border-none shadow-none"
          text="Limpiar filtros"
          onPress={handleResetFilter}
        />
        <ButtonComponent
          variant="primary"
          className="mt-4"
          text="Aplicar filtros"
          onPress={handleSubmit(applyFilter)}
        />
      </StyledWarpperButtonFilters>
    </StyledDropDownFilter>
  );
};

DropDownFilter.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  isOnFocusTextSearch: PropTypes.bool,
  handleOpenMenu: PropTypes.func,
};

DropDownFilter.defaultProps = {
  className: '',
  open: false,
  isOnFocusTextSearch: false,
  handleOpenMenu: () => {},
};

export default DropDownFilter;
