import _ from 'lodash';

import useHelpers from '../../../../helpers';
import useStrings from '../../../../strings';
import useInitialStates from '../../../initialStates';

const useNotificationsReducers = () => {
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  const { useGeneralTypes } = useStrings();
  const { useNotificationTypes } = useGeneralTypes();
  const {
    GET_NOTIFICATION,
    UPDATE_NOTIFICATION,
    DELETE_ALL_NOTIFICATIONS,
    DELETE_NOTIFICATION,
  } = useNotificationTypes();

  const { useGeneralInitialStates } = useInitialStates();
  const { useNotificationInitialStates } = useGeneralInitialStates();
  const { initialStateNotification } = useNotificationInitialStates();

  const notifications = createReducer(initialStateNotification, {
    [GET_NOTIFICATION](state, action) {
      if (action.payload.infinity_scroll) {
        return {
          ...state,
          meta: action.payload.meta,
          links: action.payload.links,
          data: [...state.data, ...action.payload.data],
        };
      } else {
        return {
          ...state,
          ...action.payload,
        };
      }
    },
    [UPDATE_NOTIFICATION](state, action) {
      return {
        ...state,
        data: _.map(state.data, (notification) => {
          if (notification.id == action.payload.id) {
            return {
              ...notification,
              readed: true,
            };
          } else {
            return notification;
          }
        }),
        meta: { ...state.meta, not_readed: state.meta.not_readed - 1 },
      };
    },
    [DELETE_ALL_NOTIFICATIONS](state) {
      return {
        ...state,
        data: [],
        meta: { ...state.meta, not_readed: 0 },
      };
    },
    [DELETE_NOTIFICATION](state) {
      return {
        ...state,
      };
    },
  });

  return { notifications };
};

export default useNotificationsReducers;
