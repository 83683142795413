import useRecoverPasswordRoutesController from './recoverPassword';
import usePrivateRoute from './privateRoute';
import usePublicRoute from './publicRoute';
import useProtectedRoute from './protected';

const useRoutesHooks = () => {
  return {
    useRecoverPasswordRoutesController,
    usePrivateRoute,
    usePublicRoute,
    useProtectedRoute,
  };
};

export default useRoutesHooks;
