import MaterializeConvocatoryArtist from './MaterializeConvocatory';
import EjecuteInterventionArtist from './EjecuteIntervention';
import EvaluateArtist from './Evaluate';

const useEjecutionStepsArtist = () => {
  return {
    MaterializeConvocatoryArtist,
    EjecuteInterventionArtist,
    EvaluateArtist,
  };
};
export default useEjecutionStepsArtist;
