//Packages
import { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';

//Hooks
import useApi from 'api';
import useModels from 'models';
import useControllers from 'controllers';

const useAdminContentManagementInterventionsRoot = () => {
  const { useGeneralHooks } = useControllers();
  const { useIsFilterEmpty } = useGeneralHooks();
  const { isFilterEmpty } = useIsFilterEmpty();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions, useAdminContentInterventionsActions } =
    useAdminActions();
  const {
    actSetDataFilterAdminHomeInterventions,
    actResetDataFilterAdminHomeInterventions,
  } = useAdminContentInterventionsActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminInterventionsRootActions } = useAdminInterventionsActions();
  const {
    actGetInterventionsForEnabling,
    actUpdateInterventionEnableVisibility,
  } = useAdminInterventionsRootActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminContentInterventionsSelectors } = useAdminSelectors();
  const { getFilterAdminHomeInterventionsSelector } =
    useAdminContentInterventionsSelectors();
  const dataFilter = useSelector(getFilterAdminHomeInterventionsSelector);

  const [textSearch, setTextSearch] = useState('');

  const textSearchRef = useRef(null);

  const history = useHistory();

  const { pathname } = useLocation();

  const [interventionsCompleted, setInterventionsCompleted] = useState({
    data: [],
    links: {},
    meta: {},
  });

  useEffect(() => {
    dispatch(
      actGetInterventionsForEnabling({ state: 'c' }, (response) => {
        const { data, links, meta } = response.data;
        setInterventionsCompleted(() => ({
          data,
          links,
          meta,
        }));
      })
    );
    dispatch(actResetDataFilterAdminHomeInterventions());
  }, []);

  useEffect(() => {
    if (textSearch.length >= 3) {
      dispatch(
        actGetInterventionsForEnabling(
          { ...dataFilter, state: 'c', name: textSearch },
          (response) => {
            const { data, links, meta } = response.data;
            setInterventionsCompleted(() => ({
              data,
              links,
              meta,
            }));
          }
        )
      );
      dispatch(
        actSetDataFilterAdminHomeInterventions({
          objectKey: 'name',
          objectValue: textSearch,
        })
      );
      textSearchRef.current = true;
    } else {
      if (textSearchRef.current) {
        dispatch(
          actGetInterventionsForEnabling(
            {
              ...dataFilter,
              name: '',
              state: 'c',
            },
            (response) => {
              const { data, links, meta } = response.data;
              setInterventionsCompleted(() => ({
                data,
                links,
                meta,
              }));
            }
          )
        );
        dispatch(
          actSetDataFilterAdminHomeInterventions({
            objectKey: 'name',
            objectValue: '',
          })
        );
        textSearchRef.current = false;
      }
    }
  }, [textSearch]);

  const handleChangePage = (page) => {
    dispatch(
      actGetInterventionsForEnabling(
        { state: 'c', page: page + 1 },
        (response) => {
          const { data, links, meta } = response.data;
          setInterventionsCompleted(() => ({
            data,
            links,
            meta,
          }));
        }
      )
    );
  };

  const handleGoToEditIntervention = ({ interventionId }) => {
    history.push(`${pathname}/edit/${interventionId}`);
  };

  const updateInterventionEnableVisibility = ({ data, interventionId }) => {
    dispatch(
      actUpdateInterventionEnableVisibility({
        interventionId,
        data: {
          visible_in_front: data,
        },
      })
    );
  };

  return {
    isFilterEmpty,
    handleChangePage,
    handleGoToEditIntervention,
    interventionsCompleted,
    updateInterventionEnableVisibility,
    textSearch,
    setTextSearch,
    setInterventionsCompleted,
  };
};

export default useAdminContentManagementInterventionsRoot;
