//Hooks
import useHelpers from '../../../../../helpers';

const useAdminInterventionsSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const getInterventionsSelector = createSelector(
    (state) => state.interventions,
    (interventions) => interventions
  );

  const getInterventionsListSelector = createSelector(
    (state) => state.interventions,
    (interventions) => interventions.interventionsList
  );

  const getArtistsUsersInfiniteScrollSelector = createSelector(
    (state) => state.artistUsersInfiniteScroll,
    (artistUsersInfiniteScroll) => artistUsersInfiniteScroll
  );

  const getInterventionDetailToEditSelector = createSelector(
    (state) => state.interventions,
    (interventions) => interventions.interventionSelectedToEdit
  );

  const getFilterInterventionDetailSelector = createSelector(
    (state) => state.interventions,
    (interventions) => interventions.filter
  );

  const getProjectInterventionSelector = createSelector(
    (state) => state.interventions,
    (interventions) => interventions.know_project.project
  );

  const getActualPhaseOfInterventionsSelector = createSelector(
    (state) => state.interventions,
    (interventions) =>
      Math.floor(
        Number(interventions?.interventionSelectedToEdit?.actual_phase?.id) - 1
      )
  );

  const getProjectInterventionCreativePlanSelector = createSelector(
    (state) => state.interventions,
    (interventions) => interventions.plan_creative
  );

  const getInterventionsCommunicationMessagesSelector = createSelector(
    (state) => state.interventionCommunicationMessages,
    (interventionCommunicationMessages) => interventionCommunicationMessages
  );

  const interventionDefinitionIdSelectedSelector = createSelector(
    (state) => state.interventionDefinitionIdSelected,
    (interventionDefinitionIdSelected) => interventionDefinitionIdSelected
  );

  const formDiffusionDefinitionIntoInterventionSelector = createSelector(
    (state) => state.formDiffusionDefinitionIntoIntervention,
    (formDiffusionDefinitionIntoIntervention) =>
      formDiffusionDefinitionIntoIntervention
  );

  const getToSendMessageInterventionSelector = createSelector(
    (state) => state.interventions,
    (interventions) => interventions.toSendMessage
  );

  return {
    getInterventionsSelector,
    getInterventionsListSelector,
    getArtistsUsersInfiniteScrollSelector,
    getInterventionDetailToEditSelector,
    getFilterInterventionDetailSelector,
    getProjectInterventionSelector,
    getActualPhaseOfInterventionsSelector,
    getProjectInterventionCreativePlanSelector,
    getInterventionsCommunicationMessagesSelector,
    interventionDefinitionIdSelectedSelector,
    formDiffusionDefinitionIntoInterventionSelector,
    getToSendMessageInterventionSelector
  };
};

export default useAdminInterventionsSelectors;
