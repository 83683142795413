//Hooks

import useAdminCommunitiesReducers from './communities';
import useAdminProjectsRootReducers from './projectsRoot';
import useAdminInterventionsReducers from './interventions';

const useAdminProjectsReducers = () => {
  return {
    useAdminCommunitiesReducers,
    useAdminProjectsRootReducers,
    useAdminInterventionsReducers,
  };
};

export default useAdminProjectsReducers;
