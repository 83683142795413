//Packages
import { useEffect, useState } from 'react';

const useInputForm = ({
  errors,
  name,
  handlerIsOnFocus,
  checked,
  isCheckbox,
  isRadioButton,
}) => {
  const [isOnFocus, setIsOnFocus] = useState(false);
  const [isOnBlur, setIsOnBlur] = useState(true);
  const [filled, setFilled] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const successInput = !!(filled && !errors?.[name]);
  const errorInput = !!errors?.[name.split('[')[0]];

  const handleIsFocus = () => {
    handlerIsOnFocus && handlerIsOnFocus(true);
    setIsOnFocus(true);
    setIsOnBlur(false);
  };

  const handleIsBlur = () => {
    setIsOnBlur(true);
    setIsOnFocus(false);
    handlerIsOnFocus && handlerIsOnFocus(false);
  };

  useEffect(() => {
    if (isCheckbox || isRadioButton) {
      setFilled(checked);
    }
  }, [checked]);

  const handleFilled = (e) =>
    setFilled(isCheckbox ? e.target.checked : e.target.value);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Checbox2
  // const [checkedAux, setCheckedAux] = useState(checked);
  // useEffect(() => {
  //   setCheckedAux(checked);
  // }, [checked]);
  // const handleFilledAux = () => setCheckedAux(!checkedAux);
  // const successInputAux = !!(checkedAux && !errors[name]);

  return {
    handleIsFocus,
    handleIsBlur,
    handleFilled,
    handleShowPassword,
    isOnFocus,
    isOnBlur,
    isFilled: !!filled,
    successInput,
    errorInput,
    showPassword,
    // handleFilledAux,
    // successInputAux,
    // checkedAux,
  };
};

export default useInputForm;
