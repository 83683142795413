//Packages
import React, { lazy, useMemo } from 'react';
import { PieChart, Pie, Legend, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Hooks
import useViews from 'views';

//Components
import CustomLegend from './components/CustomLegend';
import useStrings from 'strings';

//Styles
const StyledContentChart = lazy(() =>
  import('./Queantitative.styles').then((mod) => ({
    default: mod.StyledContentChart,
  }))
);

const Queantitative = (props) => {
  const {
    dataCollectionInformationSelected,
    dataCollectionInformationAnswersSelected,
  } = props;

  const { useComponents } = useViews();
  const { useTypographies } = useComponents();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();

  const { useConstants } = useStrings();
  const { DATA_CHART_COLORS } = useConstants();

  const dataChart = useMemo(() => {
    return dataCollectionInformationSelected?.type?.id === 'gi'
      ? _.map(
          dataCollectionInformationAnswersSelected.options,
          ({ option, responses }, idx) => ({
            name: option?.text,
            value:
              (responses / dataCollectionInformationSelected?.result?.replied) *
              1000,
            fill: DATA_CHART_COLORS[idx % DATA_CHART_COLORS.length],
            answers: responses,
          })
        )
      : [
          {
            name: 'Mensajes recibidos',
            value:
              dataCollectionInformationSelected?.result?.sended /
              dataCollectionInformationSelected?.result?.total,
            fill: '#2E4361',
            answers: dataCollectionInformationSelected?.result?.sended,
          },
          {
            name: 'Mensajes sin recibir',
            value:
              dataCollectionInformationSelected?.result?.not_sended /
              dataCollectionInformationSelected?.result?.total,
            fill: '#91D0D0',
            answers: dataCollectionInformationSelected?.result?.not_sended,
          },
        ];
  }, [dataCollectionInformationAnswersSelected.options]);

  const style = {
    top: '35%',
    left: '100%',
    transform: 'translate(0, -50%)',
    lineHeight: '24px',
    width: '200%',
  };

  return (
    <>
      <div className="flex justify-start items-start mt-4">
        <div className="border-r-2 px-4">
          <TypographyComponent
            text="Mensajes enviados"
            variant="p"
            className="text-neutral-950 mt-0 "
          />
          <TypographyComponent
            text={`${
              dataCollectionInformationSelected?.type?.id === 'gi'
                ? dataCollectionInformationSelected?.result?.sended
                : dataCollectionInformationSelected?.result?.total
            }`}
            variant="h2"
            className="text-primary font-bold "
          />
        </div>
        <div className="border-r-2 px-4">
          <TypographyComponent
            text={
              dataCollectionInformationSelected?.type?.id === 'gi'
                ? 'Respuestas recibidas'
                : 'Mensajes recibidos'
            }
            variant="p"
            className="text-neutral-950 mt-0 "
          />
          <TypographyComponent
            text={`${
              dataCollectionInformationSelected?.type?.id === 'gi'
                ? dataCollectionInformationSelected?.result?.replied
                : dataCollectionInformationSelected?.result?.sended
            }`}
            variant="h2"
            className="text-primary font-bold"
          />
        </div>
        {dataCollectionInformationSelected.type.id === 'gi' ? (
          <div className="px-4">
            <TypographyComponent
              text="Canal de comunicación"
              variant="p"
              className="text-neutral-950 mt-0 "
            />
            <TypographyComponent
              text={dataCollectionInformationSelected?.channel?.name}
              variant="h2"
              className="text-primary font-bold"
            />
          </div>
        ) : (
          <div className="border-r-2 px-4">
            <TypographyWithTooltip
              text="Mensajes no recibidos"
              variant="p"
              textTooltip="Mensajes no recibidos"
              idTooltip="communication_channel"
              className="text-neutral-950 mt-0 "
            />
            <TypographyComponent
              text={`${dataCollectionInformationSelected?.result?.not_sended}`}
              variant="h2"
              className="text-primary font-bold"
            />
          </div>
        )}
      </div>

      {((Number(dataCollectionInformationSelected?.result?.replied) > 0 &&
        dataCollectionInformationSelected.type.id === 'gi') ||
        (Number(dataCollectionInformationSelected?.result?.sended) > 0 &&
          dataCollectionInformationSelected.type.id === 'd') ||
        (Number(dataCollectionInformationSelected?.result?.not_sended) > 0 &&
          dataCollectionInformationSelected.type.id !== 'gi')) && (
        <StyledContentChart>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                dataKey="value"
                isAnimationActive
                data={dataChart}
                cx="159"
                cy="159"
                outerRadius={100}
                fill="#8884d8"
              />
              <Legend
                wrapperStyle={style}
                content={(payload) => (
                  <CustomLegend
                    payload={payload}
                    typeOfMessage={dataCollectionInformationSelected?.type?.id}
                  />
                )}
              />
            </PieChart>
          </ResponsiveContainer>
        </StyledContentChart>
      )}

      {/* TODO */}
      {/* <ButtonComponent
        text="Descargar resultado"
        className=" flex items-center mt-12 ml-auto"
        icon={<div className="icon-download text-base"></div>}
        onPress={() => {}}
      /> */}
    </>
  );
};

Queantitative.propTypes = {
  dataCollectionInformationSelected: PropTypes.object,
  dataCollectionInformationAnswersSelected: PropTypes.object,
};

Queantitative.defaultProps = {
  dataCollectionInformationSelected: {},
  dataCollectionInformationAnswersSelected: {},
};

export default Queantitative;
