//Packages
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

//Hooks
import useControllers from 'controllers';
import useApi from 'api';
import useModels from 'models';

const useAdminRootInterventionsDashboard = () => {
  const history = useHistory();
  const { project_id: projectId } = useParams();
  const { pathname, state } = useLocation();

  const [textSearch, setTextSearch] = useState('');
  const [interventionSelected, setInterventionSelected] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const textSearchRef = useRef(null);

  const { useGeneralHooks } = useControllers();
  const { useToggleModal, useIsFilterEmpty } = useGeneralHooks();
  const [showDeleteModal, handleDeleteModal] = useToggleModal();
  const { isFilterEmpty } = useIsFilterEmpty();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminInterventionsRootActions } = useAdminInterventionsActions();
  const {
    actGetInterventionsByProject,
    actDeleteInterventionByProject,
    actSetFilterInterventionDetailByProject,
    actResetFilterInterventionDetailByProject,
  } = useAdminInterventionsRootActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionsListSelector, getFilterInterventionDetailSelector } =
    useAdminInterventionsSelectors();
  const {
    data: interventionsList,
    meta: interventionsMeta,
    links: interventionsLinks,
  } = useSelector(getInterventionsListSelector);
  const { page: currentPageAux, search } = useSelector(
    getFilterInterventionDetailSelector
  );

  useEffect(() => {
    if (textSearch.length >= 3) {
      dispatch(
        actGetInterventionsByProject(
          {
            projectId,
            search: textSearch,
          },
          () => {
            setCurrentPage(1);
          }
        )
      );
      dispatch(
        actSetFilterInterventionDetailByProject({
          key: 'search',
          value: textSearch,
        })
      );
      textSearchRef.current = true;
    } else {
      if (textSearchRef.current) {
        setCurrentPage(currentPageAux);
        dispatch(
          actGetInterventionsByProject({
            projectId,
            page: currentPageAux,
          })
        );
        textSearchRef.current = false;
        dispatch(
          actSetFilterInterventionDetailByProject({
            key: 'search',
            value: '',
          })
        );
      }
    }
  }, [textSearch]);

  useEffect(() => {
    return () => {
      dispatch(actResetFilterInterventionDetailByProject());
    };
  }, []);

  const handleGoToCreateIntervention = () => history.push(`${pathname}/create`);

  const handleGoToEditIntervention = ({ interventionId }) =>
    history.push(`${pathname}/edit/${interventionId}`, {
      pageFromIntervention: currentPage,
    });

  const handleGoBack = () =>
    history.push(`/admin/projects/dashboard/${projectId}`);

  useEffect(() => {
    dispatch(
      actGetInterventionsByProject({
        projectId,
        page: state?.pageFromIntervention,
      })
    );
    if (state?.pageFromIntervention) {
      setCurrentPage(state?.pageFromIntervention);
    }
  }, []);

  const onSuccessHandleDeleteInterventionByProject = () => {
    dispatch(
      actGetInterventionsByProject({
        projectId,
        page: currentPage,
        search,
      })
    );
    handleDeleteModal();
  };

  const handleDeleteInterventionByProject = () => {
    dispatch(
      actDeleteInterventionByProject(
        { interventionId: interventionSelected, projectId },
        onSuccessHandleDeleteInterventionByProject,
        () => {
          handleDeleteModal();
        }
      )
    );
  };

  const handleChangePage = ({ selected }) => {
    dispatch(
      actGetInterventionsByProject({ projectId, page: selected + 1, search })
    );
    setCurrentPage(selected + 1);
    dispatch(
      actSetFilterInterventionDetailByProject({
        key: 'page',
        value: selected + 1,
      })
    );
  };

  const handleGoToDashboardIntervention = ({ interventionId }) => {
    history.push(`${pathname}/${interventionId}`, {
      pageFromIntervention: currentPage,
    });
  };

  return {
    isFilterEmpty,
    handleGoToCreateIntervention,
    textSearch,
    setTextSearch,
    showDeleteModal,
    handleDeleteModal,
    handleGoBack,
    interventionsList,
    interventionsMeta,
    interventionsLinks,
    handleDeleteInterventionByProject,
    handleChangePage,
    currentPage,
    setInterventionSelected,
    handleGoToEditIntervention,
    handleGoToDashboardIntervention,
  };
};

export default useAdminRootInterventionsDashboard;
