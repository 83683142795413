//Packages
import _ from 'lodash';
import dayjs from 'dayjs';

//Hooks
import useStrings from 'strings';

const useQuickFunctions = () => {
  const { useConstants } = useStrings();
  const { ONE_DAY_IN_MILLISECONDS, MONTHS_OF_THE_YEAR } = useConstants();

  const scrollTop = ({ x, y }) => {
    window.scrollTo(x, y);
  };

  /**
   *
   * @param {*} url - The url can be one of these (http url, dataURL, blobURL)
   * @param {*} fileName - The File name
   * @param {*} mimeType - The type of your file, example: 'image/jpeg'
   * @return {Promise}
   */
  const urltoFile = ({ url, fileName, mimeType }) => {
    const response = fetch(url)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], fileName, { type: mimeType }));
    return response;
  };

  const getFileValidations = () => {
    const SUPPORTED_FORMATS = [
      'image/jpg',
      'image/jpeg',
      'image/png',
      'video/mp4',
      'video/quicktime',
      'video/ogg',
    ];
    const SUPPORTED_FORMATS_ONLY_IMAGES = [
      'image/jpg',
      'image/jpeg',
      'image/png',
    ];

    const SUPPORTED_FORMATS_ONLY_VIDEOS = [
      'video/mp4',
      'video/quicktime',
      'video/ogg',
    ];

    const ALL_SUPPORTED_FORMATS = [
      'image/jpg',
      'image/jpeg',
      'image/png',
      'video/mp4',
      'video/quicktime',
      'video/ogg',
      'application/pdf',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];

    const SUPPORTED_FORMATS_WITH_PREVIEW = [
      'mp4',
      'mov',
      'qt',
      'ogv',
      'png',
      'jpg',
      'jpeg',
      'pdf',
    ];

    const SUPPORTED_FORMATS_ONLY_VIDEOS_PREVIEW = ['mp4', 'mov', 'qt', 'ogv'];
    const MAX_SIZE = 5000000; //Esto está en Bytes
    const MAX_SIZE_5MB = 5000000; //Esto está en Bytes
    const MAX_SIZE_10MB = 10000000; //Esto está en Bytes
    const MAX_SIZE_20MB = 20000000; //Esto está en Bytes
    const MAX_SIZE_30MB = 30000000; //Esto está en Bytes

    return {
      SUPPORTED_FORMATS,
      MAX_SIZE,
      MAX_SIZE_5MB,
      MAX_SIZE_10MB,
      MAX_SIZE_20MB,
      MAX_SIZE_30MB,
      SUPPORTED_FORMATS_ONLY_IMAGES,
      SUPPORTED_FORMATS_ONLY_VIDEOS,
      ALL_SUPPORTED_FORMATS,
      SUPPORTED_FORMATS_WITH_PREVIEW,
      SUPPORTED_FORMATS_ONLY_VIDEOS_PREVIEW,
    };
  };

  const getMimeTypeFromMedia = ({ urlFile }) => {
    const selectMimeType = {
      mp4: 'video/mp4',
      mov: 'video/mp4',
      qt: 'video/mp4',
      ogv: 'video/ogg',
    };

    let mimeType = '';
    if (urlFile) {
      let formatArray = urlFile.split('.');
      if (formatArray?.length > 0) {
        mimeType = formatArray[formatArray.length - 1];
      }
    }

    return selectMimeType[mimeType] ?? '';
  };

  const regexValidations = ({ regex }) => {
    const selectRegexValidations = {
      // eslint-disable-next-line no-useless-escape
      url: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
      number: /^[0-9]+$/,
      password:
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      text: /^[-'"aA-zZ\s\u00C0-\u00FF]+$/,
      duration: /([0-9]{2}):([0-9]{2})/g,
    };

    return selectRegexValidations[regex];
  };

  /**
   *
   * @param {Number} number
   * @returns A number with thounsandsSeparator
   */
  const convertThounsandsSeparator = (number) => {
    const numberToString = Number(number ?? 0).toString();
    if (numberToString.length > 3) {
      const numberArray = numberToString.split('').reverse();

      const result = _.map(numberArray, (number, index) => {
        if ((index + 1) % 3 === 0 && numberArray[index + 1]) {
          return `.${number}`;
        } else {
          return number;
        }
      });

      return result.reverse().join('');
    }

    return number ?? 0;
  };

  const addZeroToBeginning = (numberInString) => {
    return numberInString.padStart(2, '0');
  };

  const formatMonthWithTwoDigits = ({ month }) => {
    return month < 9 ? addZeroToBeginning(`${month + 1}`) : month + 1;
  };

  const formatDayWithTwoDigits = ({ day }) => {
    return day <= 9 ? addZeroToBeginning(`${day}`) : day;
  };

  /**
   *
   * @param {Date} selectedDate
   * @returns True if the selected date is greater than the today's date. This function excludes the hours, minutes and seconds
   */
  const isSelectedDateGreaterThanTodayDate = ({ selectedDate }) => {
    const todayDate = new Date();
    const todayDateInMillisenconds = todayDate.setHours(0, 0, 0, 0);

    const selectedDateInMilliseconds = selectedDate.getTime();

    return (
      selectedDateInMilliseconds - todayDateInMillisenconds >=
      ONE_DAY_IN_MILLISECONDS
    );
  };

  /**
   * @param {string} format dmy = return date DD/MM/YYYY - amd = return date YYYY-MM-DD
   * @returns return a formated date
   */

  const getYearMonthDayFromDate = ({ dateInString, format = 'dmy' }) => {
    const dateAux = new Date(Date.parse(dateInString));
    const hours = dateAux.getHours();

    const seletecFormatDate = {
      dmy: `${formatDayWithTwoDigits({
        day: dateAux.getUTCDate(),
      })}/${formatMonthWithTwoDigits({
        month: dateAux.getUTCMonth(),
      })}/${dateAux.getUTCFullYear()}`,
      ymd: `${dateAux.getUTCFullYear()}-${formatMonthWithTwoDigits({
        month: dateAux.getUTCMonth(),
      })}-${formatDayWithTwoDigits({
        day: dateAux.getUTCDate(),
      })}`,
      dmyText: `${formatDayWithTwoDigits({
        day: dateAux.getUTCDate(),
      })} de ${
        MONTHS_OF_THE_YEAR[dateAux.getUTCMonth()]
      } de ${dateAux.getUTCFullYear()}`,
      dmyTime: `${formatDayWithTwoDigits({
        day: dateAux.getUTCDate(),
      })} de ${
        MONTHS_OF_THE_YEAR[dateAux.getUTCMonth()]
      } de ${dateAux.getUTCFullYear()} - ${formatDayWithTwoDigits({
        day: dateAux.getHours(),
      })}:${formatDayWithTwoDigits({
        day: dateAux.getMinutes(),
      })} `,
      dmyShort: `${MONTHS_OF_THE_YEAR[dateAux.getUTCMonth()]?.substring(
        0,
        3
      )} ${formatDayWithTwoDigits({
        day: dateAux.getUTCDate(),
      })}, ${dateAux.getUTCFullYear()}`,
      time: `${formatDayWithTwoDigits({
        day: hours > 12 ? hours % 12 : hours,
      })}:${formatDayWithTwoDigits({ day: dateAux.getMinutes() })} ${
        hours >= 12 ? 'pm' : 'am'
      }`,
    };

    return seletecFormatDate[format];
  };

  const convertArrayToString = ({ arrayToConvert, showHelperText = true }) => {
    return `${
      arrayToConvert?.length === 1
        ? `${showHelperText ? 'Idioma: ' : ''}${arrayToConvert[0].name}`
        : `${showHelperText ? 'Idiomas: ' : ''}${_.map(
            arrayToConvert,
            (arrayData) => arrayData.name
          ).join(', ')}`
    }`;
  };

  const showCityDepartmentAndCountry = ({
    city,
    format = 'cityCountry',
    textDepartment = 'department',
  }) => {
    const selectedFormat = {
      cityCountry: `${city?.name}, ${city?.[textDepartment]?.country?.name}`,
      cityDepartmentCountry: `${city?.name}, ${city?.[textDepartment]?.name} - ${city?.[textDepartment]?.country?.name}`,
      countryCity: `${city?.[textDepartment]?.country?.name}, ${city?.name}`,
    };
    return selectedFormat[format];
  };

  const downloadFileFromBackend = ({ response, nameFile, extension = '' }) => {
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: response.headers?.['content-type'] ?? 'application/pdf',
      })
    );
    var link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `${nameFile}.${extension ? extension : 'pdf'}`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getUTCDateOfTomorrow = () => {
    const currentDateInMilliseconds = Date.now() + ONE_DAY_IN_MILLISECONDS;
    const dateInString = new Date(currentDateInMilliseconds);
    const dateInISO = dateInString.toISOString();

    return dateInISO.slice(0, 16);
  };

  /**
   * @param {string} date - The date that will be converted to ISO 6801
   * @return {string} A date with ISO 6801
   */
  const convertDateToISO = ({ date }) => {
    const dateInIso = dayjs(date).format(); //ISO 6801
    const deleteTheLast = [...dateInIso].reverse().join('').replace(':', '');
    return [...deleteTheLast].reverse().join('');
  };

  const setDefaultValueInputDateTimeWithTimeLocalZone = ({ dateInUTC }) => {
    const date = new Date(dateInUTC); //Here get us, the date with local time zone
    const differenceInMinutes = date.getTimezoneOffset(); //TimeZone in minutes
    const differenceInMilliseconds = differenceInMinutes * 60000;
    const dateInMillisenconds = Date.parse(date);
    const dateToShow = new Date(
      dateInMillisenconds - differenceInMilliseconds
    ).toISOString();

    return dateToShow;
  };

  const getTimeInISOFormat = ({ time }) => {
    const dateInIso = convertDateToISO({ date: time });
    return dateInIso.split('T')[1];
  };

  const getLocalZoneTime = ({ timeInUTC }) => {
    const dateInUTC = `2022-05-05T${timeInUTC}`;
    const localDateTime = setDefaultValueInputDateTimeWithTimeLocalZone({
      dateInUTC,
    });
    const dateTimeToFormat = `2022-05-05T${
      localDateTime.split('.')[0].split('T')[1]
    }`;
    return getYearMonthDayFromDate({
      dateInString: dateTimeToFormat,
      format: 'time',
    });
  };

  const getDurationTimeInMinutes = ({ duration }) => {
    const durationParts = duration.split(':');
    return Number(durationParts[0] || 0) * 60 + Number(durationParts[1] || 0);
  };

  const updateItemInArray = ({ arrayData, itemToUpdate }) => {
    const currentData = [...arrayData];
    const itemPosition = currentData.findIndex(
      (item) => `${item.id}` === `${itemToUpdate.id}`
    );
    if (itemPosition > -1) {
      currentData.splice(itemPosition, 1, itemToUpdate);
      return currentData;
    }
    return [...currentData, itemToUpdate];
  };

  const onFormatMessageAboutCommunities = ({
    message,
    selectedIntervention,
    contactsLists,
  }) => {
    if (
      selectedIntervention?.communities?.length ===
        message?.communities?.length &&
      selectedIntervention?.communities?.length > 1
    ) {
      return 'Todas las comunidades';
    }

    if (
      contactsLists?.data?.length === message?.contactLists?.length &&
      contactsLists?.data?.length > 1
    ) {
      return 'Todas las listas';
    }

    return convertArrayToString({
      arrayToConvert:
        message?.contactLists?.length > 0
          ? message?.contactLists
          : message?.communities,

      showHelperText: false,
    });
  };

  const dateExists = ({ sendedDate, suggestedDate, statusIdMessage = 'p' }) => {
    if (sendedDate) {
      return sendedDate;
    } else if (!sendedDate && suggestedDate) {
      return `Se enviará el ${suggestedDate}`;
    } else if (!sendedDate && !suggestedDate && statusIdMessage === 'c') {
      return 'No hay fecha de envio';
    } else if (!sendedDate && !suggestedDate) {
      return `Pendiente de envio`;
    }
  };

  const getDocumentName = ({ file, withExtension = false }) => {
    if (file?.url) {
      const documentUrlLastPart = file.url.split('/').at(-1);
      const extension = `.${documentUrlLastPart.split('.')[1]}`;
      return `${documentUrlLastPart.split('.')[0]}${
        withExtension ? extension : ''
      }`;
    }
    return '';
  };

  return {
    scrollTop,
    urltoFile,
    getFileValidations,
    regexValidations,
    convertThounsandsSeparator,
    addZeroToBeginning,
    formatMonthWithTwoDigits,
    formatDayWithTwoDigits,
    isSelectedDateGreaterThanTodayDate,
    getYearMonthDayFromDate,
    convertArrayToString,
    showCityDepartmentAndCountry,
    getMimeTypeFromMedia,
    downloadFileFromBackend,
    getUTCDateOfTomorrow,
    convertDateToISO,
    setDefaultValueInputDateTimeWithTimeLocalZone,
    updateItemInArray,
    onFormatMessageAboutCommunities,
    dateExists,
    getTimeInISOFormat,
    getDurationTimeInMinutes,
    getLocalZoneTime,
    getDocumentName,
  };
};

export default useQuickFunctions;
