import { useState, useRef, useEffect } from 'react';

const useSlickSlider = ({
  currentSlide,
  customSettings,
  currentSlideProps,
}) => {
  const initialSettings = {
    dots: false,
    infinite: true,
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      setCurrentSlideState(next);
      getPropsFromCurrentSlide(next);
    },
  };

  const slider = useRef(null);
  const [settings, setSettings] = useState(initialSettings);
  const [currentSlideState, setCurrentSlideState] = useState(currentSlide || 0);

  const getPropsFromCurrentSlide = (currentSlideState) => {
    const {
      current: {
        props: { children },
      },
    } = slider;
    const currentProps = children?.[0]?.[currentSlideState]?.props;
    currentSlideProps(currentProps);
  };

  useEffect(() => {
    setSettings({
      ...initialSettings,
      ...customSettings,
      initialSlide: parseInt(currentSlideState),
    });
  }, [customSettings, currentSlideState]);

  useEffect(() => {
    if (slider.current && parseInt(currentSlide) > 0) {
      slider.current.slickGoTo(parseInt(currentSlide));
    }
  }, [slider, currentSlide]);

  return { slider, settings };
};

export default useSlickSlider;
