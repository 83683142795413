const useCountriesInitialStates = () => {
  const initialStateCountries = {
    entities: {
      countries: {},
      cities: {},
      departments: {},
    },
    result: [],
  };

  return { initialStateCountries };
};

export default useCountriesInitialStates;
