//Packages
import React from 'react';
import { Switch, useRouteMatch, Redirect } from 'react-router-dom';

//Hooks
import useViews from '../../../../../../../../../../views';
import useNavigation from '../../../../../../../../..';

//Screens
const { useScreens } = useViews();
const {
  AdminDashboardCommunitiesContactsCreate,
  AdminDashboardCommunitiesContactsEdit,
  AdminDashboardCommunitiesContactsRoot,
} = useScreens();

const AdminProjectsRouterDashboardCommunitiesContacts = () => {
  const { path } = useRouteMatch();

  const { useRoutes } = useNavigation();
  const { PrivateRoute } = useRoutes();

  return (
    <Switch>
      <PrivateRoute
        exact
        path={`${path}/create`}
        component={AdminDashboardCommunitiesContactsCreate}
        redirect="/"
      />
      <PrivateRoute
        exact
        path={`${path}/edit/:contact_id`}
        component={AdminDashboardCommunitiesContactsEdit}
        redirect="/"
      />
      <PrivateRoute
        exact
        path={`${path}`}
        component={AdminDashboardCommunitiesContactsRoot}
        redirect="/"
      />
      <Redirect to={`${path}`} />
    </Switch>
  );
};

export default AdminProjectsRouterDashboardCommunitiesContacts;
