import { persistStore } from 'redux-persist';

//Hooks
import useApi from '../../..';
import useStrings from '../../../../strings';

const useLogoutActions = () => {
  const { useProviders } = useApi();
  const { useAuthProviders } = useProviders();
  const { useLogoutProviders } = useAuthProviders();
  const { logout } = useLogoutProviders();

  //Strings
  const { useAuthTypes } = useStrings();
  const { LOGOUT } = useAuthTypes();

  const actLogout = (onSuccess, onError) => async (dispatch) => {
    try {
      const res = await logout();
      dispatch({ type: LOGOUT });
      persistStore.dispatch({ type: LOGOUT });
      localStorage.clear();
      onSuccess && onSuccess(res);
    } catch (error) {
      onError && onError(error);
    }
  };
  return { actLogout };
};

export default useLogoutActions;
