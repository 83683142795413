import useAdminViewInterventionsCommunicationActions from './communication';
import useAdminViewInterventionDevelopmentInterventionActions from './developmentIntervention';

const useAdminViewInterventionsActions = () => {
  return {
    useAdminViewInterventionsCommunicationActions,
    useAdminViewInterventionDevelopmentInterventionActions,
  };
};

export default useAdminViewInterventionsActions;
