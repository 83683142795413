//Packages
import { useEffect, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//Hooks
import useApi from 'api';
import useModels from 'models';
import useStrings from 'strings';
import useHelpers from 'helpers';
import useControllers from 'controllers';

const useAdminKnowProjectIntervention = () => {
  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions, useAdminRecommendationsActions } =
    useAdminProjectsActions();
  const { useAdminViewInterventionsActions, useAdminInterventionsRootActions } =
    useAdminInterventionsActions();
  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const { actSetCustomInterventionStep } = useAdminInterventionsRootActions();
  const { useAdminDevelopmentInterventionKnowProjectActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const {
    actKnowProjectIntervention,
    actCreateKnowProjectIntervention,
    actKnowProjectStateIntervention,
  } = useAdminDevelopmentInterventionKnowProjectActions();
  const { actGetRecommendations } = useAdminRecommendationsActions();

  const { pathname } = useLocation();
  const { intervention_id, project_id } = useParams();
  const { push: goTo } = useHistory();

  const { useSelectors } = useModels();
  const { useAdminSelectors, useSelector } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors, useAdminRecommendationsSelectors } =
    useAdminProjectsSelectors();
  const { getInterventionsSelector, getProjectInterventionSelector } =
    useAdminInterventionsSelectors();
  const { listRecommendationsByProjectSelector } =
    useAdminRecommendationsSelectors();
  const { know_project } = useSelector(getInterventionsSelector);
  const recommendationsList = useSelector(listRecommendationsByProjectSelector);
  const projectData = useSelector(getProjectInterventionSelector);

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { REQUIRED_FIELD, DATE_GREATER_THAN_TODAY } = useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const {
    formatMonthWithTwoDigits,
    formatDayWithTwoDigits,
    isSelectedDateGreaterThanTodayDate,
    convertThounsandsSeparator,
    convertArrayToString,
    showCityDepartmentAndCountry,
  } = useQuickFunctions();

  const { useGeneralHooks } = useControllers();
  const { useGetDate } = useGeneralHooks();
  const { setTextExpiredDate } = useGetDate();

  const textForLimitDate = useMemo(() => {
    if (know_project?.date_limit) {
      const { isExpired, text } = setTextExpiredDate({
        expiredDate: know_project?.date_limit,
      });

      return {
        text,
        colorBadge: isExpired ? 'error' : 'warning',
      };
    }

    return {
      text: 'No hay fecha limite',
      colorBadge: 'warning',
    };
  }, [know_project.date_limit]);

  const schemaCreateKnowProjectIntervention = yup.object({
    date_limit_checkbox: yup.boolean(),
    date_limit: yup.date().when('date_limit_checkbox', {
      is: true,
      then: yup
        .date()
        .required(REQUIRED_FIELD)
        .test('dateValidation', DATE_GREATER_THAN_TODAY, (value) => {
          return value
            ? isSelectedDateGreaterThanTodayDate({ selectedDate: value })
            : true;
        }),
      otherwise: yup
        .date()
        .notRequired()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr)),
    }),
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    resolver: yupResolver(schemaCreateKnowProjectIntervention),
    mode: 'onChange',
  });

  useEffect(() => {
    if (know_project.state?.id !== 'c') {
      dispatch(
        actKnowProjectStateIntervention(
          { intervention_id },
          onSuccessActKnoProjectIntervention
        )
      );
    }
  }, [know_project.state?.id]);

  const onSuccessActKnoProjectIntervention = () => {
    dispatch(actKnowProjectIntervention({ intervention_id }));
    dispatch(actGetRecommendations({ projectId: project_id }));
  };

  const createKnowProject = (data) => {
    if (data.date_limit && data.date_limit_checkbox) {
      const date_limit = `${data.date_limit.getUTCFullYear()}-${formatMonthWithTwoDigits(
        {
          month: data.date_limit.getUTCMonth(),
        }
      )}-${formatDayWithTwoDigits({ day: data.date_limit.getUTCDate() })}`;
      data.date_limit = date_limit;
    } else {
      delete data.date_limit;
      delete data.date_limit_checkbox;
    }
    data.state = 'ip';
    dispatch(
      actCreateKnowProjectIntervention({
        intervention_id,
        know_project: know_project.id,
        data,
      })
    );
  };

  const handleGoToPath = ({ path }) => {
    dispatch(actSetCustomInterventionStep({ step: 2 }));
    goTo(`${pathname}/${path}`);
  };

  const updateStateKnowProject = () => {
    dispatch(
      actCreateKnowProjectIntervention({
        intervention_id,
        know_project: know_project.id,
        data: { state: 'c' },
      })
    );
  };

  return {
    know_project,
    control,
    register,
    handleSubmit,
    watch,
    errors,
    isValid,
    createKnowProject,
    handleGoToPath,
    recommendationsList,
    updateStateKnowProject,
    projectData,
    convertThounsandsSeparator,
    convertArrayToString,
    showCityDepartmentAndCountry,
    textForLimitDate,
  };
};

export default useAdminKnowProjectIntervention;
