import useHelpers from '../../../helpers';
import useStrings from '../../../strings';
import useInitialStates from '../../initialStates';

const useAuthReducers = () => {
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  const { useAuthInitialStates } = useInitialStates();
  const { initialStateAuth } = useAuthInitialStates();

  const { useAuthTypes } = useStrings();
  const { LOGIN, LOGOUT } = useAuthTypes();

  const auth = createReducer(initialStateAuth, {
    [LOGIN](state, action) {
      const { auth_token } = action.payload;
      return { ...state, auth_token };
    },
    [LOGOUT]() {
      return initialStateAuth;
    },
  });

  return { auth };
};

export default useAuthReducers;
