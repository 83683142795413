import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const StyledIcon = styled.div`
  ${tw` `}
`;

export const StyledButtonActivity = styled.button.attrs(() => ({
  className: "StyledButtonActivity"
}))`
  ${tw`flex justify-center items-center font-bold text-base text-primary`}

  ${(props) => !props.text && props.icon && tw`px-3`}

  ${(props) =>
    props.open
      ? tw` hidden`
      : tw` flex`}

  ${StyledIcon} {
    ${(props) => props.text && props.icon && tw`mr-2`}
  }
  
`;
