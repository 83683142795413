//Packages
import { useState } from 'react';
import * as yup from 'yup';

//Hooks
import useControllers from 'controllers';
import useHelpers from 'helpers';
import useStrings from 'strings';

const useSelectFormValidations = () => {
  //Strings
  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const {
    REQUIRED_FIELD,
    EMAIL_NOT_VALID,
    NUMBER_NOT_VALID,
    URL_NOT_VALID,
    MAX_LENGTH_PHONE,
    ACCEPTS_ONLY_TEXT,
    MIN_LENGTH_PHONE,
  } = useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const { regexValidations } = useQuickFunctions();
  const regexText = regexValidations({ regex: 'text' });
  const regexNumber = regexValidations({ regex: 'number' });
  const regexUrl = regexValidations({ regex: 'url' });

  //Schemas Yup (validations)
  const artistSchemaValidation = yup
    .object({
      name: yup
        .string()
        .required(REQUIRED_FIELD)
        .matches(regexText, ACCEPTS_ONLY_TEXT),
      last_name: yup
        .string()
        .required(REQUIRED_FIELD)
        .matches(regexText, ACCEPTS_ONLY_TEXT),
      email: yup.string().email(EMAIL_NOT_VALID).required(REQUIRED_FIELD),
      gender: yup.string().nullable(),
      country: yup.object().required(REQUIRED_FIELD),
      department: yup.object().required(REQUIRED_FIELD).nullable(),
      city_id: yup.object().required(REQUIRED_FIELD).nullable(),
      phone_code: yup.object().required(REQUIRED_FIELD),
      phone: yup
        .string()
        .required(REQUIRED_FIELD)
        .matches(regexNumber, NUMBER_NOT_VALID)
        .max(15, MAX_LENGTH_PHONE)
        .min(8, MIN_LENGTH_PHONE),

      fcb: yup.string().matches(regexUrl, {
        message: URL_NOT_VALID,
        excludeEmptyString: true,
      }),
      tw: yup.string().matches(regexUrl, {
        message: URL_NOT_VALID,
        excludeEmptyString: true,
      }),
      lin: yup.string().matches(regexUrl, {
        message: URL_NOT_VALID,
        excludeEmptyString: true,
      }),
      you: yup.string().matches(regexUrl, {
        message: URL_NOT_VALID,
        excludeEmptyString: true,
      }),
      oth: yup.string().matches(regexUrl, {
        message: URL_NOT_VALID,
        excludeEmptyString: true,
      }),
      int: yup.string().matches(regexUrl, {
        message: URL_NOT_VALID,
        excludeEmptyString: true,
      }),

      website: yup
        .string()
        .matches(regexUrl, {
          message: URL_NOT_VALID,
          excludeEmptyString: true,
        })
        .nullable(),
      intervention_type: yup.object().required(REQUIRED_FIELD),
      training_to_others: yup.string().nullable(),
      about_me_title: yup.string().required(REQUIRED_FIELD).nullable(),
      about_me_body: yup.string().required(REQUIRED_FIELD).nullable(),
      specialties: yup.array().min(1, 'Debe existir al menos una experiencia'),
      group: yup.string().nullable(),
    })
    .required();

  const adminSchemaValidation = yup
    .object({
      name: yup
        .string()
        .required(REQUIRED_FIELD)
        .matches(regexText, ACCEPTS_ONLY_TEXT),
      last_name: yup
        .string()
        .required(REQUIRED_FIELD)
        .matches(regexText, ACCEPTS_ONLY_TEXT),
      email: yup.string().email(EMAIL_NOT_VALID).required(REQUIRED_FIELD),
      gender: yup.string().nullable(),
      country: yup.object().required(REQUIRED_FIELD),
      department: yup.object().required(REQUIRED_FIELD).nullable(),
      city_id: yup.object().required(REQUIRED_FIELD).nullable(),
      phone_code: yup.object().required(REQUIRED_FIELD),
      phone: yup
        .string()
        .required(REQUIRED_FIELD)
        .matches(regexNumber, NUMBER_NOT_VALID)
        .max(15, MAX_LENGTH_PHONE)
        .min(8, MIN_LENGTH_PHONE),
    })
    .required();

  const partnerSchemaValidation = yup
    .object({
      name: yup
        .string()
        .required(REQUIRED_FIELD)
        .matches(regexText, ACCEPTS_ONLY_TEXT),
      last_name: yup
        .string()
        .required(REQUIRED_FIELD)
        .matches(regexText, ACCEPTS_ONLY_TEXT),
      email: yup.string().email(EMAIL_NOT_VALID).required(REQUIRED_FIELD),
      gender: yup.string().nullable(),
      country: yup.object().required(REQUIRED_FIELD),
      department: yup.object().required(REQUIRED_FIELD).nullable(),
      city_id: yup.object().required(REQUIRED_FIELD).nullable(),
      phone_code: yup.object().required(REQUIRED_FIELD),
      phone: yup
        .string()
        .required(REQUIRED_FIELD)
        .matches(regexNumber, NUMBER_NOT_VALID)
        .max(15, MAX_LENGTH_PHONE)
        .min(8, MIN_LENGTH_PHONE),
      organization: yup.string().required(REQUIRED_FIELD),
      position: yup.string().required(REQUIRED_FIELD),
      website: yup
        .string()
        .required(REQUIRED_FIELD)
        .matches(regexUrl, URL_NOT_VALID),
      about_me_title: yup.string().required(REQUIRED_FIELD).nullable(),
      about_me_body: yup.string().required(REQUIRED_FIELD).nullable(),
    })
    .required();

  const [selectValidationSchema] = useState({
    ad: adminSchemaValidation,
    ar: artistSchemaValidation,
    ep: partnerSchemaValidation,
  });

  const { useGeneralHooks } = useControllers();
  const { useGetUserProfile } = useGeneralHooks();
  const {
    name,
    last_name,
    email,
    gender,
    role: { id: roleId },
    city,
    phone,
    language,
    organization,
    position,
    website,
    about_me_title,
    about_me_body,
    intervention_type,
    training_to_others,
    networksIndexed,
    group,
  } = useGetUserProfile();

  const adminDefaultValues = {
    name,
    last_name,
    email,
    gender: gender ?? '',
    country: {
      label: `${city.department.country.name}`,
      value: city.department.country.id,
    },
    department: {
      label: `${city.department.name}`,
      value: city.department.id,
    },
    city_id: { label: `${city.name}`, value: city.id },
    phone_code: { label: `${phone.code?.code}`, value: phone.code?.id },
    phone: phone.number,
    language: { label: language, value: language },
  };

  const artistDefaultValues = {
    name,
    last_name,
    email,
    gender: gender ?? '',
    country: {
      label: `${city.department.country.name}`,
      value: city.department.country.id,
    },
    department: {
      label: `${city.department.name}`,
      value: city.department.id,
    },
    city_id: { label: `${city.name}`, value: city.id },
    phone_code: { label: `${phone.code?.code}`, value: phone.code?.id },
    phone: phone.number,
    language: { label: language, value: language },
    fcb:
      networksIndexed && networksIndexed['fcb']
        ? networksIndexed['fcb'].url
        : '',
    tw:
      networksIndexed && networksIndexed['tw'] ? networksIndexed['tw'].url : '',
    lin:
      networksIndexed && networksIndexed['lin']
        ? networksIndexed['lin'].url
        : '',
    you:
      networksIndexed && networksIndexed['you']
        ? networksIndexed['you'].url
        : '',
    oth:
      networksIndexed && networksIndexed['oth']
        ? networksIndexed['oth'].url
        : '',
    int:
      networksIndexed && networksIndexed['int']
        ? networksIndexed['int'].url
        : '',
    website: website ?? '',
    intervention_type: {
      label: intervention_type?.name ?? '',
      value: intervention_type?.id ?? '',
    },
    training_to_others: training_to_others ?? '',
    about_me_title,
    about_me_body,
    group,
  };

  const partnerDefaultValues = {
    name,
    last_name,
    email,
    gender: gender ?? '',
    country: {
      label: `${city.department.country.name}`,
      value: city.department.country.id,
    },
    department: {
      label: `${city.department.name}`,
      value: city.department.id,
    },
    city_id: { label: `${city.name}`, value: city.id },
    phone_code: { label: `${phone.code?.code}`, value: phone.code?.id },
    phone: phone.number,
    language: { label: language, value: language },
    organization,
    position,
    website,
    about_me_title,
    about_me_body,
  };

  const selectDefaultValues = {
    ad: adminDefaultValues,
    ar: artistDefaultValues,
    ep: partnerDefaultValues,
  };

  return {
    defaultValues: selectDefaultValues[roleId],
    validationSchema: selectValidationSchema[roleId],
  };
};

export default useSelectFormValidations;
