//Hooks
import useQueryParams from './queryparams';
import useConsole from './console';
import useIsAuth from './isAuth';
import useGetUserProfile from './getUserProfile';
import useGetScreenSize from './getScreenSize';
import useCollapseAccordion from './collapseAccordion';
import useCropImage from './cropImage';
import useToggleModal from './toggleModal';
import useModalUploadProfilePhoto from './modalUploadProfilePhoto';
import useUploadProfilePhoto from './uploadProfilePhoto';
import useDebaunced from './debounced';
import useWhereLive from './whereLive';
import useSelectLayoutByRole from './selectLayoutByRole';
import useInfiniteScroll from './infiniteScroll';
import useGetDate from './getDate';
import useGetOptionsList from './getOptionsList';
import useMakeAncla from './makeAncla';
import useFilter from './filter';
import useCollectInformationMessages from './collectInformationMessages';
import useIsFilterEmpty from './isFilterEmpty';

const useGeneralHooks = () => {
  return {
    useQueryParams,
    useConsole,
    useIsAuth,
    useGetUserProfile,
    useGetScreenSize,
    useCollapseAccordion,
    useCropImage,
    useToggleModal,
    useModalUploadProfilePhoto,
    useUploadProfilePhoto,
    useDebaunced,
    useWhereLive,
    useSelectLayoutByRole,
    useInfiniteScroll,
    useGetDate,
    useGetOptionsList,
    useMakeAncla,
    useFilter,
    useCollectInformationMessages,
    useIsFilterEmpty,
  };
};

export default useGeneralHooks;
