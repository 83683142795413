//Packages
import React, { Suspense } from 'react';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';
import useProfileComponents from './components';

const Profile = () => {
  const { useLayouts, useComponents } = useViews();
  const { MainLayout } = useLayouts();
  const { useBoxes, useModals, useLoaders } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { Box } = useBoxes();
  const { ImageCropperModal } = useModals();

  const { useGeneralHooks } = useControllers();
  const { useGetUserProfile, useUploadProfilePhoto } = useGeneralHooks();
  const {
    role: { id: roleId },
  } = useGetUserProfile();
  const {
    setZoom,
    setCrop,
    setRotation,
    onCropComplete,
    crop,
    rotation,
    zoom,
    imageForCropper,
    handleUpdateAvatarCropped,
    showModal,
    handleCloseCropperModal,
  } = useUploadProfilePhoto();

  const { Admin, Artist, Partner } = useProfileComponents();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <MainLayout hasDecorationMap>
        <Box className="bg-neutral-200 w-11/12 mx-auto max-w-screen-lg lg:px-16 lg:py-12 px-6 py-8 lg:my-8 relative">
          {roleId === 'ad' ? (
            <Admin />
          ) : roleId === 'ar' ? (
            <Artist />
          ) : (
            <Partner />
          )}
        </Box>

        <ImageCropperModal
          showModalImageCropper={showModal}
          handleCloseCropperModal={handleCloseCropperModal}
          setZoom={setZoom}
          setCrop={setCrop}
          setRotation={setRotation}
          onCropComplete={onCropComplete}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          imageForCropper={imageForCropper}
          handleUpdateAvatarCropped={handleUpdateAvatarCropped}
        />
      </MainLayout>
    </Suspense>
  );
};

export default Profile;
