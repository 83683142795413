//Packages
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

//Hooks
import useApi from '../../../../../api';

const useAdminHomeRoot = () => {
  const history = useHistory();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useHomeActions } = useAdminActions();
  const { actGetHomeConfig } = useHomeActions();

  useEffect(() => {
    dispatch(actGetHomeConfig());
  }, []);

  const handleGoBack = () => history.goBack();

  return { handleGoBack };
};

export default useAdminHomeRoot;
