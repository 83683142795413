//Packages
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

//Hooks
import useConfig from './config';
import useViews from './views';
import useNavigation from './navigation';
import useWrappers from './wrappers';

const App = () => {
  // Config
  const { useStoreConfig } = useConfig();
  const { store, persistor } = useStoreConfig();

  // Views
  const { useScreens, useComponents } = useViews();
  const { useLoaders, useModals } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { GeneralModal } = useModals();

  const {
    Home,
    Login,
    Register,
    RecoverPassword,
    TermsAndConditions,
    // PrivacyPolicies,
    AboutRoles,
    ChangePassword,
    InterventionDetail,
  } = useScreens();

  //Routes & Routers
  const { useRoutes, useRouters } = useNavigation();
  const { DashboardRouter, AdminRouter } = useRouters();
  const { PublicRoute, RecoverPasswordRoute, PrivateRoute } = useRoutes();

  //Wrappers
  const { ScrollTop } = useWrappers();

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <DefaultLoader loaderForSuspense={false} />

        <Router>
          <GeneralModal store={store} />
          <ScrollTop>
            {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}
            <Switch>
              <Route exact path="/" component={Home} />
              <Route
                exact
                path="/intervention/:interventionId"
                component={InterventionDetail}
              />
              <PublicRoute exact path="/login" component={Login} redirect="/" />
              <PublicRoute exact path="/register" component={Register} />
              <PrivateRoute
                exact
                path="/change-password"
                component={ChangePassword}
                redirect={'/login'}
              />

              <RecoverPasswordRoute
                exact
                path="/recover-password"
                component={RecoverPassword}
              />
              <Route path="/dashboard" component={DashboardRouter} />
              <Route
                path="/terms-and-conditions"
                component={TermsAndConditions}
              />
              {/* <Route path="/privacy-policies" component={PrivacyPolicies} /> */}
              <Route path="/about-roles" component={AboutRoles} />

              <PrivateRoute
                path="/admin"
                component={AdminRouter}
                redirect={'/'}
              />
              <Redirect to="/" />
            </Switch>
          </ScrollTop>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
