import React from 'react';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

const FinishInterventionArtist = () => {
  const { useComponents } = useViews();
  const { useTypographies, useCards } = useComponents();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { CardNotification } = useCards();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsDevelopmentIntervention } =
    useAdminViewInterventionsControllers();
  const { useViewDevelopmentInterventionSpreadIntervention } =
    useAdminViewInterventionsDevelopmentIntervention();
  const { useAdminSpreadIntervention } =
    useViewDevelopmentInterventionSpreadIntervention();
  const { spreadIntervention, stepCompleted } = useAdminSpreadIntervention();
  const { intervention } = spreadIntervention;

  return (
    <>
      <CardNotification
        className="w-5/12 mx-auto py-9 px-4 mt-16"
        title={
          <TypographyComponent
            text={spreadIntervention.state?.name}
            variant="h2"
            className="font-bold text-primary icon-watch text-center"
          />
        }
        content={
          <TypographyComponent
            text={`${
              stepCompleted
                ? 'El socio ejecutor se comunicará contigo pronto '
                : 'El socio ejecutor se comunicará contigo pronto'
            }`}
            variant="p"
            className="text-neutral-950 mt-4 mb-4 text-center"
          />
        }
      />
      <TypographyWithTooltip
        text={
          stepCompleted ? 'Intervención finalizada' : 'Finalizar intervención'
        }
        variant="h2"
        textTooltip={
          stepCompleted ? 'Intervención finalizada' : 'Finalizar intervención'
        }
        idTooltip="knowCreativePlan"
        applyFontBold
      />
      <TypographyComponent
        text={`${
          stepCompleted
            ? 'Felicidades! has completado tu intervención, y ahora hace parte de nuestra biblioteca de intervenciones.'
            : 'Ahora todo esta en manos del socio ejecutor para dar final a este maravilloso proceso!.'
        }`}
        variant="p"
        className="text-neutral-950 mt-4 mb-4"
      />
      {intervention?.visible_in_front && (
        <TypographyComponent
          text={
            <>
              Puedes consultar la publicación de la intervención en el siguiente
              enlace
              <br />
              <a
                className="text-primary font-bold"
                href={`${window.location.origin}/intervention/${intervention.id}`}
              >
                {window.location.origin}/intervention/{intervention.id}
              </a>
            </>
          }
          variant="p"
          className="text-neutral-950 mt-4 mb-4"
        />
      )}
    </>
  );
};

export default FinishInterventionArtist;
