//Packages
import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

//Hooks
import useControllers from 'controllers';
import useViews from 'views';

// Styles
import {
  StyledInput,
  StyledIconPass,
  StyledWrapperInputIcon,
} from './inputForm.styles';
import { StyledInputWrapper } from '../Inputs.styles';

// eslint-disable-next-line react/display-name
const InputForm = React.forwardRef((props, ref) => {
  const {
    name,
    type,
    errors,
    onChange,
    control,
    placeholder,
    label,
    className,
    hintLabel,
    maxLength,
    min,
    disabled,
    autoComplete,
    isPassword,
    defaultValue,
    handlerIsOnFocus,
    icon,
    showWithErrorTextPassword,
    value,
  } = props;

  const { useComponents } = useViews();
  const { useLabels } = useComponents();
  const { DefaultLabel, HelperLabel } = useLabels();

  const { useComponentHooks } = useControllers();
  const { useInputs } = useComponentHooks();
  const { useInputForm } = useInputs();
  const {
    handleIsBlur,
    handleIsFocus,
    handleFilled,
    handleShowPassword,
    errorInput,
    successInput,
    showPassword,
  } = useInputForm({
    errors,
    name,
    handlerIsOnFocus,
  });

  const existValue = () => {
    return value ? { value } : {};
  };

  return (
    <div className={`${className}`}>
      <StyledInputWrapper errors={errorInput} success={successInput}>
        {label && <DefaultLabel>{label}</DefaultLabel>}
        <StyledWrapperInputIcon
          hasIcon={type !== 'date' && type !== 'datetime-local' && !icon}
          errors={errorInput}
          success={successInput}
        >
          {control ? (
            <Controller
              name={name}
              control={control}
              defaultValue={defaultValue}
              render={({
                field: {
                  onChange: onChangeInput,
                  name,
                  value: valueController,
                },
              }) => {
                return (
                  <StyledInput
                    name={name}
                    type={
                      isPassword ? (showPassword ? 'text' : 'password') : type
                    }
                    onChange={(e) => {
                      onChangeInput(e?.target?.value);
                      onChange(e);
                    }}
                    onBlur={handleIsBlur}
                    onFocus={handleIsFocus}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    min={min}
                    disabled={disabled}
                    autoComplete={autoComplete}
                    onInput={handleFilled}
                    errors={errorInput}
                    success={successInput}
                    defaultValue={valueController}
                    {...existValue()}
                    icon={icon}
                    ref={ref}
                  />
                );
              }}
            />
          ) : (
            <StyledInput
              name={name}
              type={isPassword ? (showPassword ? 'text' : 'password') : type}
              onChange={onChange}
              ref={ref}
              onBlur={handleIsBlur}
              onFocus={handleIsFocus}
              placeholder={placeholder}
              maxLength={maxLength}
              min={min}
              disabled={disabled}
              autoComplete={autoComplete}
              onInput={handleFilled}
              errors={errorInput}
              success={successInput}
              defaultValue={defaultValue}
              {...existValue()}
              icon={icon}
            />
          )}

          {isPassword && (
            <StyledIconPass
              showPassword={showPassword}
              onClick={handleShowPassword}
            />
          )}
        </StyledWrapperInputIcon>

        {hintLabel && errorInput && !showWithErrorTextPassword ? (
          <HelperLabel>{errors[name]?.message}</HelperLabel>
        ) : errorInput && !showWithErrorTextPassword ? (
          <HelperLabel>{errors[name]?.message}</HelperLabel>
        ) : (
          !showWithErrorTextPassword && <HelperLabel>{hintLabel}</HelperLabel>
        )}
      </StyledInputWrapper>
    </div>
  );
});

InputForm.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  hintLabel: PropTypes.string,
  maxLength: PropTypes.string,
  min: PropTypes.string,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  required: PropTypes.bool,
  errors: PropTypes.object,
  isPassword: PropTypes.bool,
  defaultValue: PropTypes.string,
  control: PropTypes.any,
  handlerIsOnFocus: PropTypes.func,
  showWithErrorTextPassword: PropTypes.bool,
  value: PropTypes.string,
  icon: PropTypes.string,
};

InputForm.defaultProps = {
  type: 'text',
  placeholder: '',
  onChange: () => {},
  label: '',
  className: '',
  hintLabel: '',
  maxLength: '',
  min: '0',
  disabled: false,
  autoComplete: 'on',
  required: false,
  isPassword: false,
  errors: {},
  defaultValue: '',
  control: '',
  handlerIsOnFocus: () => {},
  showWithErrorTextPassword: false,
  value: '',
};

export default InputForm;
