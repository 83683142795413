import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Hooks
import useViews from 'views';

// Components
import ModalAgents from './components/ModalAgents/ModalAgents';
import DetailUser from './components/DetailUser/DetailUser';

// Styles
import {
  StylesAgentsConten as StyledAgentsContent,
  StyledListSelectedAgents,
  StyledListSelectedAgentsItem,
  StyledDetailInformation,
} from './Agents.styles';

const Agents = (props) => {
  const {
    agents,
    selectedAgents,
    communities,
    canCallAgent,
    getAgentsByCommunity,
    handleAssignChangeAgent,
  } = props;

  const { useComponents } = useViews();
  const { useButtons, useModals, useBadges } = useComponents();
  const { ButtonComponent } = useButtons();
  const { DefaultModal } = useModals();
  const { DefaultBadge } = useBadges();

  const [activeModalSelectAgent, setActiveModalSelectAgent] = useState(false);

  return (
    <StyledAgentsContent>
      <StyledListSelectedAgents>
        {_.map(selectedAgents, (agent, idx) => {
          const { state, user } = agent;
          return (
            <StyledListSelectedAgentsItem key={idx}>
              {user.name} {user.last_name}
              <DefaultBadge
                type="light"
                variant={
                  state.id === 'nc'
                    ? 'warning'
                    : state.id === 'c'
                    ? 'success'
                    : 'error'
                }
                label={state.state}
                className="ml-2"
              />
              <StyledDetailInformation>
                <DetailUser user={user} />
              </StyledDetailInformation>
            </StyledListSelectedAgentsItem>
          );
        })}
      </StyledListSelectedAgents>
      {canCallAgent && (
        <ButtonComponent
          variant="primary"
          mode="outline"
          text="Convocar agente de cambio"
          className="mb-2 border-none shadow-none"
          icon={<div className="icon-close transform rotate-45" />}
          onPress={() => {
            setActiveModalSelectAgent(true);
          }}
        />
      )}
      {activeModalSelectAgent && (
        <DefaultModal
          isActive={activeModalSelectAgent}
          classNameContent="max-w-screen-md w-11/12"
          handleClose={() => setActiveModalSelectAgent(false)}
        >
          <ModalAgents
            agentsData={agents}
            selectedAgents={selectedAgents}
            communities={_.map(communities, ({ id, name }) => ({
              value: id,
              label: name,
            }))}
            getAgentsByCommunity={getAgentsByCommunity}
            handleAssignChangeAgent={handleAssignChangeAgent}
            closeModal={() => setActiveModalSelectAgent(false)}
          />
        </DefaultModal>
      )}
    </StyledAgentsContent>
  );
};

Agents.propTypes = {
  agents: PropTypes.array,
  selectedAgents: PropTypes.array,
  communities: PropTypes.array,
  canCallAgent: PropTypes.bool,
  getAgentsByCommunity: PropTypes.func,
  handleAssignChangeAgent: PropTypes.func,
};

export default Agents;
