const useContacListStates = () => {
  const initialStateContactList = {
    contacts: [],
    contact: {},
    contactsList: [],
    contactList: {
      id: '',
      name: '',
      user: {},
      users: [],
    },
    contactsLists: {
      data: [],
    },
  };
  return { initialStateContactList };
};

export default useContacListStates;
