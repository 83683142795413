//Packages
import { useEffect, useMemo } from 'react';
import _ from 'lodash';

//Hooks
import useModels from '../../../models';
import useApi from '../../../api';

const useGetOptionsList = ({ callEndpointUsers = false } = {}) => {
  const { useSelectors } = useModels();
  const { useSelector, useUserSelectors } = useSelectors();
  const { usersSelector } = useUserSelectors();
  const usersList = useSelector(usersSelector);

  const { useActions } = useApi();
  const { dispatch, useUserActions } = useActions();
  const { actSearchUser } = useUserActions();

  useEffect(() => {
    if (callEndpointUsers) {
      dispatch(
        actSearchUser({
          paginate: 0,
          roles: [{ value: 'ad' }, { value: 'ep' }],
        })
      );
    }
  }, []);

  const optionsAdminUsers = useMemo(
    () =>
      _.map(
        _.filter(usersList.data, (user) => user.role.id === 'ad'),
        (user) => {
          return { label: `${user.name} ${user.last_name}`, value: user.id };
        }
      ),
    [usersList]
  );

  const optionsPartnerUsers = useMemo(
    () =>
      _.map(
        _.filter(usersList.data, (user) => user.role.id === 'ep'),
        (user) => {
          return { label: `${user.name} ${user.last_name}`, value: user.id };
        }
      ),
    [usersList]
  );

  return { optionsAdminUsers, optionsPartnerUsers };
};

export default useGetOptionsList;
