//Packages
import React, { lazy } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
const StyledContentButtonsModal = lazy(() =>
  import(
    '../../../../../../../../../../../styles/tableViews/tableViews.styles'
  ).then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);
const StyledButtonsActions = lazy(() =>
  import('../../../../../../../../../../../styles/buttons/buttons.styles').then(
    (mod) => ({
      default: mod.StyledButtonsActions,
    })
  )
);

const StyledWrapperCommunity = lazy(() =>
  import('../../../../../../../Dashboard/Dashboard.styles').then((mod) => ({
    default: mod.StyledWrapperCommunity,
  }))
);

const StyledCardCommunity = lazy(() =>
  import('../../../../../../../Dashboard/Dashboard.styles').then((mod) => ({
    default: mod.StyledCardCommunity,
  }))
);

const StyledContentButtons = lazy(() =>
  import('../KnowProject/KnowProject.styles').then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const ArtistKnowCommunities = (props) => {
  const { setCurrentPage } = props;
  const { useComponents } = useViews();

  const { useTypographies, useButtons, useBadges, useModals, useCards } =
    useComponents();
  const { TypographyComponent } = useTypographies();
  const { ButtonComponent } = useButtons();
  const { DefaultBadge } = useBadges();
  const { DefaultModal } = useModals();
  const { CardNotification } = useCards();

  const { useScreenHooks } = useControllers();

  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminKnowCommunityInterventions } =
    useAdminInterventionsDashboardControllers();
  const {
    know_community,
    preViewPDFKnowCommunity,
    downloadFileKnowCommunity,
    updateStateKnowProject,
    textForLimitDate,
    showProfCommunitie,
    setShowProfCommunitie,
    deepenKnowledge,
  } = useAdminKnowCommunityInterventions();

  return (
    <>
      <CardNotification
        className="w-5/12 mx-auto py-9 px-4 mt-16"
        title={
          <TypographyComponent
            text={know_community?.state?.name || ''}
            variant="h2"
            className="font-bold text-primary icon-watch text-center"
          />
        }
      />

      <TypographyComponent
        text="Conocer comunidades de la intervención"
        variant="h2"
        className="font-bold text-primary"
      />

      <TypographyComponent
        text="Te invitamos a explorar información sobre las comunidades con las cuales vas a colaborar."
        variant="p"
        className="text-neutral-950 mt-2"
      />

      <TypographyComponent
        text="Dale click para descargar la información."
        variant="p"
        className="text-neutral-950 mt-2"
      />

      <>
        {know_community.date_limit && (
          <>
            <TypographyComponent
              text="OJO! Recuerda que hay una fecha límite para completar este proceso"
              variant="p"
              className="text-neutral-950 mt-2"
            />
            <DefaultBadge
              label={`Fecha limite para conocer plan proyecto: ${textForLimitDate.text}`}
              variant={textForLimitDate.colorBadge}
              type="light"
              className="mt-6 text-warning bg-warning-50"
            />
          </>
        )}
        {know_community?.state?.id !== 'p' && (
          <div className="border rounded mt-8 px-6 pb-2 bg-neutral-300">
            <StyledWrapperCommunity nonemb>
              {_.map(know_community.files, (doc, idx) => {
                return (
                  <StyledCardCommunity key={`documents${idx}`}>
                    <TypographyComponent
                      variant="p"
                      text={doc.url || ''}
                      className="flex-grow text-primary font-bold cursor-pointer"
                    />
                    <StyledButtonsActions className="flex items-center">
                      <ButtonComponent
                        text=""
                        className="icon-view"
                        onPress={() => {
                          preViewPDFKnowCommunity(doc.url);
                        }}
                      />
                      <ButtonComponent
                        text=""
                        className="icon-download"
                        onPress={() => {
                          downloadFileKnowCommunity(doc.id);
                        }}
                      />
                    </StyledButtonsActions>
                  </StyledCardCommunity>
                );
              })}
            </StyledWrapperCommunity>
          </div>
        )}
        {!_.includes(['p', 'c'], know_community?.state?.id) && (
          <StyledContentButtons>
            {know_community.com_deepen && (
              <ButtonComponent
                text="Conoce mejor la comunidad"
                mode="outline"
                onPress={() => {
                  setShowProfCommunitie(true);
                }}
              />
            )}
            {know_community.state.id !== 'c' && (
              <ButtonComponent
                text="Siguiente etapa"
                onPress={updateStateKnowProject}
              />
            )}
          </StyledContentButtons>
        )}

        <DefaultModal
          isActive={showProfCommunitie}
          classNameContent="max-w-screen-md w-11/12"
          handleClose={() => {
            setShowProfCommunitie(false);
          }}
        >
          <TypographyComponent
            text="Profundizar en el conocimiento de las comunidades"
            variant="h3"
            className="font-bold text-primary mb-1"
          />

          <StyledContentButtonsModal>
            <ButtonComponent
              variant="primary"
              text="Cancelar"
              className="mx-auto w-full lg:w-auto lg:mx-2 "
              mode="outline"
              onPress={() => {
                setShowProfCommunitie(false);
              }}
            />
            <ButtonComponent
              variant="primary"
              text="Aceptar"
              className="mx-auto w-full lg:w-auto  lg:mx-2"
              onPress={() => {
                setShowProfCommunitie(false);
                setCurrentPage(1);
                deepenKnowledge();
              }}
            />
          </StyledContentButtonsModal>
        </DefaultModal>
      </>
    </>
  );
};
ArtistKnowCommunities.propTypes = {
  setCurrentPage: PropTypes.func.isRequired,
};
export default ArtistKnowCommunities;
