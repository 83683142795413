import React, { Suspense, lazy } from 'react';
import _ from 'lodash';

import useViews from 'views';
import useControllers from 'controllers';

//Assets
import imageFakeCard from 'assets/img/fake-card-example.jpg';

//Styles
const StyledBackButton = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);

const StyledBoxImages2col1 = lazy(() =>
  import('styles/boxes/boxes.styles').then((mod) => ({
    default: mod.StyledBoxImages2col1,
  }))
);

const AdminDashboardViewInterventionsAditionalInfo = () => {
  const { useComponents } = useViews();

  const { useTypographies, useBoxes, useImages, useLoaders } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { TypographyComponent } = useTypographies();
  const { Box } = useBoxes();
  const { Image } = useImages();

  const { useGeneralHooks, useScreenHooks } = useControllers();
  const { useSelectLayoutByRole } = useGeneralHooks();
  const { LayoutSelected, props } = useSelectLayoutByRole();

  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminKnowProjectDetailIntervention } =
    useAdminInterventionsDashboardControllers();
  const { aditionalInfo, goBack } = useAdminKnowProjectDetailIntervention();
  return (
    <Suspense fallback={<DefaultLoader />}>
      <LayoutSelected {...props}>
        <StyledBackButton
          className="mt-3"
          onClick={() => {
            goBack();
          }}
        >
          Regresar
        </StyledBackButton>
        <Box className="bg-neutral-200 w-12/12 mx-auto max-w-screen-2xl lg:px-16 lg:py-12 px-6 py-8 lg:my-8">
          <>
            <TypographyComponent
              text={'Información complementaria'}
              variant="h2"
              className="font-bold text-primary mb-5"
            />
            <TypographyComponent
              text={aditionalInfo.text}
              variant="p"
              className="mb-12"
            />
            {aditionalInfo.multimedias.length !== 0 && (
              <StyledBoxImages2col1>
                {_.map(aditionalInfo.multimedias, (multimedia, idx) => {
                  return (
                    <Image
                      key={`additionalInfoMultimedia-${idx}`}
                      srcImage={multimedia.url || imageFakeCard}
                      altImage="imageport"
                      titleImage="imageport"
                    />
                  );
                })}
              </StyledBoxImages2col1>
            )}
          </>
        </Box>
      </LayoutSelected>
    </Suspense>
  );
};

export default AdminDashboardViewInterventionsAditionalInfo;
