//Packages
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

//Views
import DefineIntervention from './components/DefineIntervention';
import SpreadIntervention from './components/SpreadIntervention';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';
import useHelpers from 'helpers';

const InterventionDefine = () => {
  const [firstOrLastView, setFirstOrLastView] = useState(1);
  const viewChangedReady = useRef(false);

  const { useComponents } = useViews();

  const { useTypographies, useCollapses } = useComponents();
  const { TypographyWithTooltip, TypographyComponent } = useTypographies();
  const { DefaultCollapse } = useCollapses();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsDevelopmentIntervention } =
    useAdminViewInterventionsControllers();
  const { useDefineInterventionInterventions } =
    useAdminViewInterventionsDevelopmentIntervention();
  const { useAdminDefineInterventionInterventions } =
    useDefineInterventionInterventions();
  const { define_intervention } = useAdminDefineInterventionInterventions();

  const { useQuickFunctions } = useHelpers();
  const { getYearMonthDayFromDate } = useQuickFunctions();

  useEffect(() => {
    if (
      define_intervention.convocation_method.length > 0 &&
      !viewChangedReady.current
    ) {
      viewChangedReady.current = true;
      setFirstOrLastView(2);
    }
  }, [define_intervention.convocation_method]);

  return (
    <>
      {define_intervention.rejected_reasons &&
        define_intervention.rejected_reasons.length > 0 && (
          <DefaultCollapse
            title={
              <TypographyWithTooltip
                text="Recomendaciones del Socio Ejecutor"
                variant="h3"
                textTooltip="Recomendaciones del Socio Ejecutor"
                className="text-primary"
                idTooltip="recomendations"
                applyFontBold
              />
            }
            titleTitleCollapsed=""
            elementCollapsed={
              <div className="icon-arrow-down text-xxs text-neutral-950 cursor-pointer" />
            }
            elementUncollapsed={
              <div className="icon-arrow-down text-xxs text-neutral-950 transform rotate-180 cursor-pointer" />
            }
            className="border border-error py-6 rounded pl-8 pr-10 mb-8 bg-neutral-300"
            classNameTitle="w-full flex justify-between"
            withUseState
          >
            <div className="overflow-y-auto mt-4 max-h-96">
              {_.map(define_intervention.rejected_reasons, (reason, idx) => {
                const date = getYearMonthDayFromDate({
                  dateInString: reason.created_at,
                  format: 'dmyTime',
                });
                return (
                  <div
                    className="bg-white rounded-lg border p-6 mb-4"
                    key={`reason-${idx}`}
                  >
                    <TypographyComponent
                      variant="p"
                      className="text-neutral-800 mb-4"
                      text={date}
                    />
                    <TypographyComponent
                      variant="p"
                      className="text-neutral-950"
                      text={reason.rejected_reason}
                    />
                  </div>
                );
              })}
            </div>
          </DefaultCollapse>
        )}
      {firstOrLastView === 1 && (
        <DefineIntervention setFirstOrLastView={setFirstOrLastView} />
      )}

      {firstOrLastView === 2 && (
        <SpreadIntervention setFirstOrLastView={setFirstOrLastView} />
      )}
    </>
  );
};

InterventionDefine.propTypes = {
  setViewActive: PropTypes.func,
};

export default InterventionDefine;
