//Packages
import React from 'react';
import { Switch, useRouteMatch, Redirect } from 'react-router-dom';
import useNavigation from '../..';

//Routers
import AdminUsersRouter from './AdminUsers';
import AdminLabelsRouter from './AdminLabels';
import AdminProjectsRouter from './AdminProjects';
import AdminContactRouter from './Contacts';
import AdminHomeRouter from './AdminHome';
import AdminInterventionsRouter from './AdminInterventions';

const AdminRouter = () => {
  const { path } = useRouteMatch();

  const { useRoutes } = useNavigation();
  const { PrivateRoute, ProtectedRoute } = useRoutes();

  return (
    <Switch>
      <ProtectedRoute
        path={`${path}/users`}
        component={AdminUsersRouter}
        redirect="/"
      />
      <ProtectedRoute
        path={`${path}/labels`}
        component={AdminLabelsRouter}
        redirect="/"
      />
      <ProtectedRoute
        path={`${path}/interventions`}
        component={AdminInterventionsRouter}
        redirect="/"
      />

      <PrivateRoute
        path={`${path}/projects`}
        component={AdminProjectsRouter}
        redirect="/"
      />
      <ProtectedRoute
        path={`${path}/contacts`}
        redirect={'/'}
        component={AdminContactRouter}
      />
      <ProtectedRoute
        path={`${path}/home`}
        redirect={'/'}
        component={AdminHomeRouter}
      />
      <Redirect to="/" />
    </Switch>
  );
};

export default AdminRouter;
