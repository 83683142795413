import useStrings from 'strings';

const useAdminUsersActions = () => {
  const { useAdminTypes } = useStrings();
  const { useAdminUsersTypes } = useAdminTypes();
  const {
    ADD_DATA_FILTER_ADMIN_USERS,
    SAVE_DATA_FILTER_ADMIN_USERS,
    RESET_DATA_FILTER_ADMIN_USERS,
    RESET_ALL_DATA_FILTER_ADMIN_USERS_CONTACTS,
  } = useAdminUsersTypes();

  const actSetFilterDataUser =
    ({ key, value }) =>
    (dispatch) => {
      dispatch({ type: ADD_DATA_FILTER_ADMIN_USERS, payload: { key, value } });
    };

  const actSaveFilterDataUser =
    ({ data }) =>
    (dispatch) => {
      dispatch({ type: SAVE_DATA_FILTER_ADMIN_USERS, payload: data });
    };

  const actResetFilterDataUser =
    (no_reset = {}) =>
    (dispatch) => {
      dispatch({ type: RESET_DATA_FILTER_ADMIN_USERS, payload: no_reset });
    };

  const actResetAllFilterDataUser = () => (dispatch) => {
    dispatch({ type: RESET_ALL_DATA_FILTER_ADMIN_USERS_CONTACTS });
  };

  return {
    actSetFilterDataUser,
    actSaveFilterDataUser,
    actResetFilterDataUser,
    actResetAllFilterDataUser,
  };
};

export default useAdminUsersActions;
