//Packages
import React, { useState } from 'react';
import _ from 'lodash';
import { Controller } from 'react-hook-form';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Components
import MessageDeepenKnowledge from './components/Messages';
import Activities from './components/Activities/Activities';
import Methodology from '../../Admin/KnowCommunities/components/Methodology/Methodology';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import FormMessages from '../../Admin/KnowCommunities/components/FormMessages/FormMessages';
import FormPlanActivity from '../../Admin/KnowCommunities/components/FormPlanActivitie/FormPlanActivitie';

//Styles
import { StyledGroup, StyledDivider } from 'styles/formViews/formViews.styles';
import {
  StyledContentButtonsModal,
  StyledContentButtons,
} from 'styles/tableViews/tableViews.styles';
import { StyledCardCommunity } from '../../../../../../Dashboard.styles';

const ArtistDeepenKnowledgeCommunities = () => {
  const { useComponents } = useViews();
  const {
    useTypographies,
    useInputs,
    useButtons,
    useModals,
    useCards,
    useBadges,
  } = useComponents();
  const { DefaultModal } = useModals();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { TextArea, Select, InputFile } = useInputs();
  const { ButtonComponent } = useButtons();
  const { CardNotification } = useCards();
  const { DefaultBadge } = useBadges();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useDeepenKnowledgeCommunity } =
    useAdminInterventionsDashboardControllers();
  const { useAdminDeepenKnowledgeCommunityInterventions } =
    useDeepenKnowledgeCommunity();
  const {
    preViewPDFDeepenKnowCommunity,
    downloadPDFDeepenKnowCommunity,
    storeDeepenKnowledgeComunnityIntervention,
    optionsCommunities,
    formDeepenKnowledge,
    formDisplayDataDeepenKnowledge,
    cancelDeepenKnowledge,
    showError,
    setShowError,
    activities,
    handleActivities,
    messages,
    handleMessages,
    deepen_knowledge,
    actualPhase,
    infoScheduled,
    crudActivityStatus,
    crudActivityModalOpen,
    toggleCrudActivityModal,
    validateDiffusion,
  } = useAdminDeepenKnowledgeCommunityInterventions();

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = infoScheduled ? formDisplayDataDeepenKnowledge : formDeepenKnowledge;

  const [activeRemote, setActiveRemote] = useState('');

  const handleChangeModality = ({ data }) => {
    setActiveRemote(data.value);
  };

  return (
    <>
      {deepen_knowledge?.state?.id !== 'p' && (
        <CardNotification
          className="w-5/12 mx-auto py-9 px-4 mt-16"
          title={
            <TypographyComponent
              text={
                actualPhase > 3.1 ? 'Completado' : deepen_knowledge?.state?.name
              }
              variant="h2"
              className="font-bold text-primary icon-watch text-center"
            />
          }
        />
      )}
      <TypographyComponent
        text="Conoce mejor la comunidad"
        variant="h2"
        className="text-primary mt-2 font-bold"
      />
      <TypographyComponent
        text="El socio de ejecución abrio esa opción para ti por si necesitas profundizar el conocimiento sobre las comunidades con las que vas a colaborar. Esto es con el proposito de que tu intervención sea más éxitosa!"
        variant="p"
        className="text-neutral-950 mt-2 mb-4"
      />
      {!_.includes(['cl', 'r'], deepen_knowledge?.state?.id) && (
        <>
          <TypographyComponent
            text="Tómate el tiempo para contestar las siguientes preguntas con precisión.        "
            variant="p"
            className="text-neutral-950 mt-2 mb-4"
          />
          <form className="">
            <StyledGroup col2 className="mb-4">
              <Select
                multiple
                name="communities"
                id="communities"
                placeholder="Selecciona la o las comunidades"
                control={control}
                options={optionsCommunities}
                errors={errors}
                label="¿En cuál o cuáles de las comunidades quieres profundizar?*"
                className="w-full"
                disabled={infoScheduled}
              />
            </StyledGroup>

            <StyledGroup col2 className="mb-4">
              <div className="w-full col-span-2">
                <TypographyWithTooltip
                  text="¿Qué quieres descubrir de la o las comunidades seleccionadas?*"
                  variant="p"
                  textTooltip="¿Qué quieres descubrir de la o las comunidades seleccionadas?*"
                  className={`text-neutral-900 ${
                    errors['what_to_discover'] && 'text-error'
                  }`}
                  idTooltip="know-discover"
                  applyFontBold
                />
                <TextArea
                  control={control}
                  name="what_to_discover"
                  placeholder="Me gustaría descubrir..."
                  className="w-full col-span-2"
                  label=""
                  errors={errors}
                  disabled={infoScheduled}
                />
              </div>
            </StyledGroup>

            {!infoScheduled && (
              <>
                <TypographyComponent
                  text="Metodología de la profundización*"
                  variant="h2"
                  className="text-primary mt-4 font-bold"
                />
                <TypographyComponent
                  text="Adjunta un archivo PDF con la explicación detallada del plan de profundización que deseas implementar para un mejor conocimiento de la comunidad."
                  variant="p"
                  className="text-neutral-950 mt-2 mb-4"
                />
                <Controller
                  control={control}
                  name="methodology_file"
                  render={({ field: { onChange, name } }) => {
                    return (
                      <InputFile
                        name={name}
                        accept={'.pdf'}
                        title={
                          <div className="flex items-center">
                            <div
                              className={`icon-paper-clip text-primary text-xl font-bold h-10 ${
                                errors['methodology_file'] && 'text-error'
                              }`}
                            />
                            <TypographyComponent
                              text="Adjuntar archivo"
                              variant="p"
                              className={`text-primary font-bold ${
                                errors['methodology_file'] && 'text-error'
                              }`}
                            />
                          </div>
                        }
                        onChange={onChange}
                        className={`w-full mt-2 bg-none mb-4 ${
                          errors['methodology_file'] && 'border-error'
                        }`}
                        errors={errors}
                        disabled={infoScheduled}
                      />
                    );
                  }}
                />
              </>
            )}
            {infoScheduled && (
              <Methodology
                preViewPDFDeepenKnowCommunity={preViewPDFDeepenKnowCommunity}
                downloadPDFDeepenKnowCommunity={downloadPDFDeepenKnowCommunity}
              />
            )}

            <StyledGroup col2 flex className="mb-4 flex-col">
              <TypographyWithTooltip
                text="Modalidad de profundización*"
                variant="p"
                textTooltip="Modalidad de profundización"
                className={`text-neutral-900 ${
                  errors['deepening_modality'] && 'text-error'
                }`}
                idTooltip="know-cpmmunitie"
                applyFontBold
              />
              <Select
                name="deepening_modality"
                id="deepening_modality"
                placeholder="Selecciona modalidad de profundización "
                control={control}
                options={[
                  {
                    label: 'Presencial',
                    value: 'f',
                  },
                  {
                    label: 'Remota',
                    value: 'r',
                  },
                  {
                    label: 'Híbrida',
                    value: 'h',
                  },
                ]}
                onChange={handleChangeModality}
                errors={errors}
                label=""
                disabled={infoScheduled}
              />
            </StyledGroup>
            {(activeRemote === 'r' ||
              (infoScheduled &&
                deepen_knowledge.deepening_modality.id === 'r')) && (
              <StyledGroup col2 flex className="mb-4 mt-6 flex-col">
                <TypographyWithTooltip
                  text="Tipo de profundización remota* "
                  variant="p"
                  textTooltip="Tipo de profundización remota"
                  className="text-neutral-900"
                  idTooltip="deepen_type"
                  applyFontBold
                />
                <Select
                  name="deepen_type"
                  id="deepen_type"
                  placeholder="Tipo de profundización remota* "
                  control={control}
                  options={[
                    {
                      label: 'Sincrónica',
                      value: 'sync',
                    },
                    {
                      label: 'Asincrónica',
                      value: 'async',
                    },
                  ]}
                  errors={errors}
                  label=""
                  disabled={infoScheduled}
                />
              </StyledGroup>
            )}
          </form>
          {watch('deepening_modality')?.value && (
            <>
              {watch('deepen_type')?.value === 'sync' &&
                activeRemote === 'r' && <StyledDivider spacexs />}
              {!infoScheduled && (
                <Activities
                  deepeingModality={activeRemote}
                  activities={activities}
                  setActivities={handleActivities}
                  deepenType={watch('deepen_type')?.value}
                />
              )}
              {infoScheduled &&
                (deepen_knowledge?.deepening_modality?.id !== 'r' ||
                  deepen_knowledge?.deepen_type?.id === 'sync') && (
                  <>
                    <TypographyComponent
                      text="Actividades"
                      variant="h3"
                      className="text-primary mt-6 font-bold"
                    />

                    <TypographyComponent
                      text="Llena los siguientes campos con los detalles necesarios para completar tus actividades de profundización con la comunidad."
                      variant="p"
                      className="text-neutral-950 mt-2 mb-4"
                    />
                    <Accordion allowZeroExpanded>
                      {deepen_knowledge.activities.length > 0 &&
                        _.map(
                          deepen_knowledge.activities,
                          (activity, index) => {
                            return (
                              <AccordionItem
                                key={index}
                                uuid={`actvitie${index}`}
                                dangerouslySetExpanded={activity.expanded}
                              >
                                <StyledCardCommunity key={`activity-${index}`}>
                                  <div className="flex items-center">
                                    <TypographyComponent
                                      variant="p"
                                      text={activity.name}
                                      className="text-primary font-bold"
                                    />
                                    {(actualPhase > 3.1 ||
                                      _.includes(
                                        ['c', 'sc'],
                                        deepen_knowledge?.state?.id
                                      )) && (
                                      <DefaultBadge
                                        type="light"
                                        variant={`${
                                          activity.state.id === 'c'
                                            ? 'success'
                                            : activity.state.id === 'r'
                                            ? 'error'
                                            : 'warning'
                                        }`}
                                        label={activity.state.name}
                                        className="ml-2"
                                      />
                                    )}
                                  </div>

                                  <StyledContentButtons>
                                    <AccordionItemState>
                                      {({ expanded }) => (
                                        <AccordionItemButton>
                                          <ButtonComponent
                                            text=""
                                            icon={
                                              <div
                                                className={`icon-arrow-down text-neutral-900 text-xxs ${
                                                  expanded &&
                                                  'transform rotate-180'
                                                }`}
                                              />
                                            }
                                            className=""
                                            variant="white"
                                            onPress={() => {}}
                                          />
                                        </AccordionItemButton>
                                      )}
                                    </AccordionItemState>
                                  </StyledContentButtons>
                                </StyledCardCommunity>
                                <AccordionItemPanel>
                                  <div className="border rounded py-8 px-6 mt-4">
                                    <form className="px-4">
                                      <FormPlanActivity
                                        activity={activity}
                                        disabledForm
                                      />
                                    </form>
                                  </div>
                                </AccordionItemPanel>
                              </AccordionItem>
                            );
                          }
                        )}
                    </Accordion>
                  </>
                )}
              {((watch('deepen_type')?.value === 'async' &&
                activeRemote === 'r') ||
                (infoScheduled &&
                  deepen_knowledge?.deepening_modality?.id === 'r' &&
                  deepen_knowledge?.deepen_type?.id !== 'sync')) && (
                <StyledGroup col2 className="mb-4">
                  <TextArea
                    control={control}
                    name="description"
                    placeholder="Descripción de la actividad"
                    className="w-full col-span-2"
                    label="Descripción de la actividad"
                    errors={errors}
                    disabled={infoScheduled}
                  />
                </StyledGroup>
              )}
              <StyledDivider spacexs />
              {!infoScheduled && (
                <MessageDeepenKnowledge
                  messages={messages}
                  setMessages={handleMessages}
                  typeForm={activeRemote}
                  deepenType={watch('deepen_type')?.value}
                />
              )}
              {infoScheduled && (
                <>
                  <TypographyComponent
                    text={
                      !validateDiffusion
                        ? 'Mensajes de difusión'
                        : 'Mensaje de recolección de información'
                    }
                    variant="h3"
                    className="text-primary mt-6 font-bold"
                  />
                  <TypographyComponent
                    text={
                      !validateDiffusion
                        ? 'Si escogiste el método presencial, remota asincrónica o hibrida, tendrás que escribir un mensaje de difusión. Ten en cuenta que los mensajes se cobran por caracteres, es importante ser conciso en la comunicación. Este mensaje debe ser aprobado por el socio ejecutor antes de enviarse a la comunidad.'
                        : 'Si escogiste el método remota asincrónica, tendrás que escribir un mensaje de recolección. Ten en cuenta que los mensajes se cobran por caracteres, es importante ser conciso en la comunicación. Este mensaje debe ser aprobado por el socio ejecutor antes de enviarse a la comunidad.'
                    }
                    variant="p"
                    className="text-neutral-950 mt-2 mb-4"
                  />
                  <Accordion allowZeroExpanded>
                    {deepen_knowledge.messages.length > 0 &&
                      _.map(deepen_knowledge.messages, (message, index) => {
                        return (
                          <AccordionItem
                            key={`message${index}`}
                            uuid={`message${index}`}
                            dangerouslySetExpanded={message.expanded}
                          >
                            <StyledCardCommunity key={`message-${index}`}>
                              <div className="flex items-center">
                                <TypographyComponent
                                  variant="p"
                                  text={message.description}
                                  className="flex-grow text-primary font-bold"
                                />
                                {(actualPhase > 3.1 ||
                                  _.includes(
                                    ['c', 'sc'],
                                    deepen_knowledge?.state?.id
                                  )) && (
                                  <DefaultBadge
                                    type="light"
                                    variant={`${
                                      message.state.id === 's'
                                        ? 'success'
                                        : message.state.id === 'r'
                                        ? 'error'
                                        : 'warning'
                                    }`}
                                    label={
                                      message.state.state || message.state.name
                                    }
                                    className="ml-2"
                                  />
                                )}
                              </div>
                              <StyledContentButtons>
                                <AccordionItemState>
                                  {({ expanded }) => (
                                    <AccordionItemButton>
                                      <ButtonComponent
                                        text=""
                                        icon={
                                          <div
                                            className={`icon-arrow-down text-neutral-900 text-xxs ${
                                              expanded && 'transform rotate-180'
                                            }`}
                                          />
                                        }
                                        className=""
                                        variant="white"
                                        onPress={() => {}}
                                      />
                                    </AccordionItemButton>
                                  )}
                                </AccordionItemState>
                              </StyledContentButtons>
                            </StyledCardCommunity>
                            <AccordionItemPanel>
                              <div className="border rounded py-8 px-6 mt-4">
                                <form className="px-4">
                                  <FormMessages
                                    defaultValueTypeProfundization={
                                      deepen_knowledge.deepen_type
                                    }
                                    program={false}
                                    message={message}
                                  />
                                </form>
                              </div>
                            </AccordionItemPanel>
                          </AccordionItem>
                        );
                      })}
                  </Accordion>
                </>
              )}
            </>
          )}

          {!infoScheduled && (
            <div className="flex items-center space-x-2 mt-10 mb-6 justify-center">
              <ButtonComponent
                text="Cancelar profundización"
                mode="outline"
                onPress={() =>
                  setShowError({
                    show: true,
                    message: '¿Deseas cancelar la profundización?',
                    action: true,
                  })
                }
              />
              <ButtonComponent
                text="Enviar al Socio Ejecutor"
                onPress={handleSubmit(
                  storeDeepenKnowledgeComunnityIntervention
                )}
              />
            </div>
          )}
          <DefaultModal
            isActive={showError.show}
            classNameContent="max-w-screen-md w-11/12"
            handleClose={() => {
              setShowError({
                show: false,
                message: '',
              });
            }}
          >
            <TypographyComponent
              text={showError.message}
              variant="h3"
              className="font-bold text-primary mb-1"
            />

            <StyledContentButtonsModal>
              <ButtonComponent
                variant="primary"
                text="Aceptar"
                className="mx-auto w-full lg:w-auto  lg:mx-2"
                onPress={() => {
                  if (showError.action) {
                    cancelDeepenKnowledge();
                  }
                  setShowError({
                    show: false,
                    message: '',
                  });
                }}
              />
            </StyledContentButtonsModal>
          </DefaultModal>

          <DefaultModal
            isActive={crudActivityModalOpen}
            handleClose={toggleCrudActivityModal}
            hasCloseButton={false}
            isHideAutomatic
          >
            <TypographyComponent
              variant="h3"
              className="text-primary font-bold whitespace-pre-wrap"
              text={`${crudActivityStatus.type} ${crudActivityStatus.message} correctamente`}
            />

            <StyledContentButtonsModal>
              <ButtonComponent
                variant="primary"
                text="Aceptar"
                className="mx-auto w-full lg:w-auto  lg:mx-2"
                onPress={toggleCrudActivityModal}
              />
            </StyledContentButtonsModal>
          </DefaultModal>
        </>
      )}
    </>
  );
};

export default ArtistDeepenKnowledgeCommunities;
