//Packages
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useAdminDevelopmentInterventionKnowCommunityProviders = () => {
  const knowCommunityInterventionProvider = ({ intervention_id }) => {
    const response = axios({
      method: 'GET',
      url: `/interventions/${intervention_id}/know-community/`,
    });
    return trackPromise(response);
  };

  const updateKnowCommunityInterventionProvider = ({
    intervention_id,
    community_id,
    data,
  }) => {
    const response = axios({
      method: 'PATCH',
      url: `/interventions/${intervention_id}/know-community/${community_id}`,
      data,
    });
    return trackPromise(response);
  };

  const deleteKnowCommunityInterventionProvider = ({
    intervention_id,
    community_id,
  }) => {
    const response = axios({
      method: 'DELETE',
      url: `/interventions/${intervention_id}/know-community/${community_id}`,
    });
    return trackPromise(response);
  };

  const updateKnowCommunityFileInterventionProvider = ({
    intervention_id,
    community_id,
    data,
  }) => {
    const response = axios({
      method: 'POST',
      url: `/interventions/${intervention_id}/know-community/${community_id}/file`,
      data,
    });
    return trackPromise(response);
  };

  const getKnowCommunityFileInterventionProvider = ({
    intervention_id,
    community_id,
    file_id,
  }) => {
    const response = axios({
      method: 'GET',
      responseType: 'arraybuffer',
      url: `/interventions/${intervention_id}/know-community/${community_id}/file/${file_id}`,
    });
    return trackPromise(response);
  };

  const deleteKnowCommunityFileInterventionProvider = ({
    intervention_id,
    community_id,
    file_id,
  }) => {
    const response = axios({
      method: 'DELETE',
      url: `/interventions/${intervention_id}/know-community/${community_id}/file/${file_id}`,
    });
    return trackPromise(response);
  };

  const storeDeepenKnowledgeCommunityInterventionProvider = ({
    data,
    intervention_id,
    deepen_knowledge_id,
  }) => {
    const response = axios({
      method: 'POST',
      url: `/interventions/${intervention_id}/deepen-knowledge/${deepen_knowledge_id}`,
      data,
    });
    return trackPromise(response);
  };

  const getDeepenKnowledgeCommunityInterventionProvider = ({
    intervention_id,
  }) => {
    const response = axios({
      method: 'GET',
      url: `/interventions/${intervention_id}/deepen-knowledge`,
    });
    return trackPromise(response);
  };

  const assignChangeAgentsDeepenKnowledgeInterventionProvider = ({
    deepen_knowledge_id,
    schedule_lifting_id,
    data
  }) => {
    const response = axios({
      method: 'POST',
      url: `/deepen-knowledge/${deepen_knowledge_id}/schedule-lifting/${schedule_lifting_id}/agent`,
      data
    });
    return trackPromise(response);
  };

  const getDeepenKnowledgeCommunityFileInterventionProvider = ({
    intervention_id,
    deepen_knowledge_id,
    file_id,
  }) => {
    const response = axios({
      method: 'GET',
      responseType: 'arraybuffer',
      url: `/interventions/${intervention_id}/deepen-knowledge/${deepen_knowledge_id}/file/${file_id}`,
    });
    return trackPromise(response);
  };

  const updateActivityDeepenKnowledgeCommunityInterventionProvider = ({
    intervention_id,
    deepen_knowledge_id,
    activity_id,
    data,
  }) => {
    const response = axios({
      method: 'PATCH',
      url: `/interventions/${intervention_id}/deepen-knowledge/${deepen_knowledge_id}/activity/${activity_id}`,
      data,
    });
    return trackPromise(response);
  };

  const getDeepenKnowledgeScheduleInfoProvider = ({ intervention_id, deepen_knowledge_id }) => {
    const response = axios({
      method: 'GET',
      url: `/interventions/${intervention_id}/deepen-knowledge/${deepen_knowledge_id}/schedule-lifting`,
    });
    return trackPromise(response);
  };

  const updateDeepenKnowledgeScheduleInfoProvider = ({
    intervention_id,
    deepen_knowledge_id,
    schedule_lifting_id,
    data
  }) => {
    const response = axios({
      method: 'PATCH',
      url: `/interventions/${intervention_id}/deepen-knowledge/${deepen_knowledge_id}/schedule-lifting/${schedule_lifting_id}`,
      data
    });
    return trackPromise(response);
  };

  const updateMessageDeepenKnowledgeInterventionProvider = ({
    intervention_id,
    deepen_knowledge_id,
    message_id,
    data,
  }) => {
    const response = axios({
      method: 'PATCH',
      url: `/interventions/${intervention_id}/deepen-knowledge/${deepen_knowledge_id}/message/${message_id}`,
      data,
    });
    return trackPromise(response);
  };

  const sendMessageDeepenKnowledgeInterventionProvider = ({
      deepen_knowledge_id,
      message_id,
      data,
    }) => {
    const response = axios({
      method: 'POST',
      url: `/deepen-knowledge/${deepen_knowledge_id}/message/${message_id}/message-request`,
      data,
    });
    return trackPromise(response);
  };

  const getMessageAnswersDeepenKnowledgeScheduleInfoProvider = ({ message_request_id, page }) => {
    const response = axios({
      method: 'GET',
      url: `/message-request/${message_request_id}/responses`,
      params: { page }
    });
    return trackPromise(response);
  };

  return {
    knowCommunityInterventionProvider,
    updateKnowCommunityInterventionProvider,
    deleteKnowCommunityInterventionProvider,
    updateKnowCommunityFileInterventionProvider,
    getKnowCommunityFileInterventionProvider,
    deleteKnowCommunityFileInterventionProvider,
    storeDeepenKnowledgeCommunityInterventionProvider,
    getDeepenKnowledgeCommunityInterventionProvider,
    assignChangeAgentsDeepenKnowledgeInterventionProvider,
    getDeepenKnowledgeCommunityFileInterventionProvider,
    updateActivityDeepenKnowledgeCommunityInterventionProvider,
    getDeepenKnowledgeScheduleInfoProvider,
    updateDeepenKnowledgeScheduleInfoProvider,
    updateMessageDeepenKnowledgeInterventionProvider,
    sendMessageDeepenKnowledgeInterventionProvider,
    getMessageAnswersDeepenKnowledgeScheduleInfoProvider
  };
};

export default useAdminDevelopmentInterventionKnowCommunityProviders;
