import React from 'react';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

// Styles
import {
  StyledDropDownFilter,
  StyledWrapperFilter,
  StyledWarpperButtonFilters,
} from 'styles/filters/filters.styles';

const DropDownFilter = (props) => {
  const { className, open, setInterventionsCompleted, setActiveDropDown } =
    props;

  const { useComponents } = useViews();
  const { useInputs, useButtons } = useComponents();
  const { Select } = useInputs();
  const { ButtonComponent } = useButtons();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminContentManagementInterventionsControllers } = useAdmin();
  const { useFilterForCompletedInterventions } =
    useAdminContentManagementInterventionsControllers();
  const {
    errors,
    handleFilterCompletedInterventions,
    control,
    handleSubmit,
    handleResetFilterProject,
    optionsCountries,
    optionsDepartments,
    optionsProjects,
    countrySelected,
  } = useFilterForCompletedInterventions({
    setInterventionsCompleted,
    setActiveDropDown,
  });

  return (
    <StyledDropDownFilter className={className} open={open}>
      <StyledWrapperFilter>
        <Select
          name="country"
          id="country"
          placeholder="Seleccionar"
          control={control}
          options={optionsCountries}
          label="País"
          errors={errors}
        />
        <Select
          name="department"
          id="department"
          placeholder="Seleccionar"
          control={control}
          options={optionsDepartments}
          label="Departamento o Estado"
          errors={errors}
          disabled={!countrySelected}
        />
        <Select
          name="project"
          id="project"
          placeholder="Seleccionar"
          control={control}
          options={optionsProjects}
          label="Proyecto"
          errors={errors}
        />
        <Select
          name="objetive"
          id="objetive"
          placeholder="Seleccionar"
          control={control}
          options={[
            { label: 'Activar', value: 'activate' },
            { label: 'Inspirar', value: 'inspire' },
            { label: 'Sostener', value: 'hold' },
          ]}
          label="Objetivo"
          errors={errors}
        />
        <Select
          name="type"
          id="type"
          placeholder="Seleccionar"
          control={control}
          options={[
            { label: 'Virtual', value: 'v' },
            { label: 'Presencial', value: 'p' },
            { label: 'Virtual y Presencial', value: 'pv' },
          ]}
          label="Modalidad"
        />
      </StyledWrapperFilter>
      <StyledWarpperButtonFilters>
        <ButtonComponent
          variant="primary"
          mode="outline"
          className="mt-4 border-none shadow-none"
          text="Limpiar filtros"
          onPress={handleResetFilterProject}
        />
        <ButtonComponent
          variant="primary"
          className="mt-4"
          text="Aplicar filtros"
          onPress={handleSubmit((data) =>
            handleFilterCompletedInterventions(data)
          )}
        />
      </StyledWarpperButtonFilters>
    </StyledDropDownFilter>
  );
};

DropDownFilter.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  isOnFocusTextSearch: PropTypes.bool,
  handleOpenMenu: PropTypes.func,
  setInterventionsCompleted: PropTypes.func,
  setActiveDropDown: PropTypes.func,
};

DropDownFilter.defaultProps = {
  className: '',
  open: false,
  isOnFocusTextSearch: false,
  handleOpenMenu: () => {},
};

export default DropDownFilter;
