import React, { lazy } from 'react';
import PropTypes from 'prop-types';

//Hooks
import useViews from '../../../../../../../../../../../..';

const StyledButtonsActions = lazy(() =>
  import(
    '../../../../../../../../../../../../../styles/buttons/buttons.styles'
  ).then((mod) => ({
    default: mod.StyledButtonsActions,
  }))
);

const StyledWrapperCommunity = lazy(() =>
  import('../../../../../../../../Dashboard.styles').then((mod) => ({
    default: mod.StyledWrapperCommunity,
  }))
);

const StyledCardCommunity = lazy(() =>
  import('../../../../../../../../Dashboard.styles').then((mod) => ({
    default: mod.StyledCardCommunity,
  }))
);

const Methodology = (props) => {
  const { preViewPDFDeepenKnowCommunity, downloadPDFDeepenKnowCommunity } =
    props;
  const { useComponents } = useViews();

  const { useButtons, useTypographies } = useComponents();

  const { ButtonComponent } = useButtons();
  const { TypographyComponent } = useTypographies();

  return (
    <>
      <TypographyComponent
        text="Metodología de la profundización"
        variant="h3"
        className="text-primary mt-4 font-bold"
      />
      <TypographyComponent
        text="Adjunta un archivo PDF con la explicación detallada del plan de profundización que deseas implementar para un mejor conocimiento de la comunidad."
        variant="p"
        className="text-neutral-950 mt-2 mb-4"
      />

      <div className="border rounded mt-8 px-6 pb-0 bg-neutral-300 mb-8">
        <StyledWrapperCommunity nonemb>
          <StyledCardCommunity className="mb-8">
            <TypographyComponent
              variant="p"
              text={'Metodología de la profundización.pdf'}
              className="flex-grow text-primary font-bold"
            />
            <StyledButtonsActions className="flex items-center">
              <ButtonComponent
                text=""
                className="icon-view"
                onPress={preViewPDFDeepenKnowCommunity}
              />
              <ButtonComponent
                text=""
                className="icon-download"
                onPress={downloadPDFDeepenKnowCommunity}
              />
            </StyledButtonsActions>
          </StyledCardCommunity>
        </StyledWrapperCommunity>
      </div>
    </>
  );
};

Methodology.propTypes = {
  preViewPDFDeepenKnowCommunity: PropTypes.func.isRequired,
  downloadPDFDeepenKnowCommunity: PropTypes.func.isRequired,
};

export default Methodology;
