//Packages
import React, { Suspense, lazy } from 'react';
import _ from 'lodash';

//Assets
import imageFakeCard from 'assets/img/fake-card-example.jpg';

//Hooks
import useControllers from 'controllers';
import useViews from 'views';

//Styles
const StyledBackButton = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);

const StyledTopFilters = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledTopFilters,
  }))
);

const StyledFilters = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledFilters,
  }))
);

const StyledInputSearchContent = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledInputSearchContent,
  }))
);

const StyledContentButtonsModal = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const StyledWrapperCardCommmunities = lazy(() =>
  import('./Root.styles').then((mod) => ({
    default: mod.StyledWrapperCardCommmunities,
  }))
);

const AdminDashboardRootCommunities = () => {
  const { useComponents } = useViews();
  const {
    useButtons,
    useBoxes,
    useCards,
    useInputs,
    useModals,
    useTypographies,
    useLoaders,
  } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { ButtonComponent } = useButtons();
  const { Box } = useBoxes();
  const { CardCommunity, CardEmptyState } = useCards();
  const { InputSearch } = useInputs();
  const { DefaultModal, DetailCommunityModal } = useModals();
  const { TypographyComponent } = useTypographies();

  const { useScreenHooks, useGeneralHooks } = useControllers();
  const { useSelectLayoutByRole } = useGeneralHooks();
  const { LayoutSelected, props, userData } = useSelectLayoutByRole();

  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminCommunitiesDashboardHooks } =
    useAdminDashboardProjectsHooks();
  const { useAdminRootCommunitiesDashboard } =
    useAdminCommunitiesDashboardHooks();
  const {
    isFilterEmpty,
    handleGoToCreateCommunity,
    textSearch,
    setTextSearch,
    isVisibleDeleteCommunityModal,
    handleStateDeleteCommunityModal,
    handleGoBack,
    communitiesList,
    handleGoToContacts,
    convertThounsandsSeparator,
    handleGoToEditCommunity,
    handleDeleteCommunityById,
    setCommunityId,
    handleDetailCommunitie,
    openModalDetailCommunitie,
    setOpenModalDetailCommunitie,
    communitySelected,
    showCityDepartmentAndCountry,
  } = useAdminRootCommunitiesDashboard();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <LayoutSelected {...props}>
        <>
          <StyledBackButton className="mt-3 mb-8" onClick={handleGoBack}>
            Regresar
          </StyledBackButton>
          <StyledTopFilters>
            <ButtonComponent
              text="Crear nueva comunidad"
              icon={<div className="icon-close transform rotate-45 text-xs" />}
              onPress={handleGoToCreateCommunity}
            />
            {!(!isFilterEmpty && communitiesList?.length === 0) && (
              <StyledFilters className="relative">
                <StyledInputSearchContent>
                  <InputSearch text={textSearch} onDebounce={setTextSearch} />
                </StyledInputSearchContent>
              </StyledFilters>
            )}
          </StyledTopFilters>{' '}
          <Box
            className={`${
              userData.role.id === 'ep'
                ? 'bg-transparent lg:py-12 shadow-none'
                : 'bg-neutral-200 w-12/12 mx-auto lg:px-10 lg:py-12 px-6 py-8 lg:my-8'
            }`}
          >
            <>
              <StyledWrapperCardCommmunities>
                {communitiesList.length > 0 &&
                  _.map(communitiesList, (community, idx) => {
                    return (
                      <CardCommunity
                        key={`card-community-${idx}`}
                        image={community?.community_image?.url || imageFakeCard}
                        title={community.name}
                        location={showCityDepartmentAndCountry({
                          city: community?.city,
                          format: 'cityDepartmentCountry',
                          textDepartment: 'deparmatment',
                        })}
                        badgesInformation={[
                          {
                            name: `${convertThounsandsSeparator(
                              community.population_number
                            )} ${
                              community?.population_number === 1
                                ? 'habitante'
                                : 'habitantes'
                            }`,
                          },
                          { name: `Idioma: ${community.language}` },
                        ]}
                        onDelete={() => {
                          handleStateDeleteCommunityModal();
                          setCommunityId(community.id);
                        }}
                        onContactView={() => {
                          handleGoToContacts({ communityId: community.id });
                        }}
                        onEdit={() => {
                          handleGoToEditCommunity({
                            communityId: community.id,
                          });
                        }}
                        onViewCommunitie={() => {
                          handleDetailCommunitie({ communityId: community.id });
                        }}
                      />
                    );
                  })}
              </StyledWrapperCardCommmunities>
              {communitiesList.length === 0 && (
                <CardEmptyState
                  className="w-8/12 mx-auto mt-16"
                  title={
                    isFilterEmpty
                      ? 'No hay resultados, utiliza nuevos criterios de búsqueda'
                      : 'No se han encontrado comunidades ¡Crea la primera!'
                  }
                  button={
                    !isFilterEmpty ? (
                      <ButtonComponent
                        className="mx-auto"
                        text="Crear nueva comunidad"
                        icon={
                          <div className="icon-close transform rotate-45 text-xs" />
                        }
                        onPress={handleGoToCreateCommunity}
                      />
                    ) : (
                      <></>
                    )
                  }
                />
              )}
            </>
          </Box>
        </>

        <DefaultModal
          isActive={isVisibleDeleteCommunityModal}
          classNameContent="max-w-screen-md w-11/12"
          handleClose={handleStateDeleteCommunityModal}
        >
          <TypographyComponent
            text="Eliminar Comunidad"
            variant="h3"
            className="font-bold text-primary mb-1"
          />
          <StyledContentButtonsModal>
            <ButtonComponent
              variant="primary"
              text="Cancelar"
              className="mx-auto w-full lg:w-auto lg:mx-2 "
              mode="outline"
              onPress={handleStateDeleteCommunityModal}
            />
            <ButtonComponent
              variant="primary"
              text="Aceptar"
              className="mx-auto w-full lg:w-auto  lg:mx-2"
              onPress={handleDeleteCommunityById}
            />
          </StyledContentButtonsModal>
        </DefaultModal>

        <DetailCommunityModal
          isActive={openModalDetailCommunitie}
          classNameContent="max-w-screen-md w-11/12"
          handleClose={setOpenModalDetailCommunitie}
          communityData={communitySelected}
        />
      </LayoutSelected>
    </Suspense>
  );
};

export default AdminDashboardRootCommunities;
