//Packages
import React, { lazy } from 'react';

//Hooks
import useViews from '../../../../../../../../../..';
import useControllers from '../../../../../../../../../../../controllers';

//Styles
const StyledCardCommunity = lazy(() =>
  import('../../../../../../Dashboard.styles').then((mod) => ({
    default: mod.StyledCardCommunity,
  }))
);

const StyledContentBorder = lazy(() =>
  import('../../../../../../Dashboard.styles').then((mod) => ({
    default: mod.StyledContentBorder,
  }))
);

const StyledButtonsActions = lazy(() =>
  import('../../../../../../../../../../../styles/buttons/buttons.styles').then(
    (mod) => ({
      default: mod.StyledButtonsActions,
    })
  )
);

const ArtistAsingPlan = () => {
  const { useComponents } = useViews();

  const { useTypographies, useButtons, useBadges, useCards } = useComponents();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { CardNotification } = useCards();

  const { ButtonComponent } = useButtons();
  const { DefaultBadge } = useBadges();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminCreativePlanIntervention } =
    useAdminInterventionsDashboardControllers();
  const {
    planCreative,
    dowloadCreativePlanFile,
    preViewPDFCreativePlan,
    updateCreativePlan,
    textForLimitDate,
  } = useAdminCreativePlanIntervention();

  return (
    <>
      <CardNotification
        className="w-5/12 mx-auto py-9 px-4 mt-16"
        title={
          <TypographyComponent
            text={planCreative.state.name}
            variant="h2"
            className="font-bold text-primary icon-watch text-center"
          />
        }
      />
      <TypographyWithTooltip
        text="Conoce el plan creativo"
        variant="h2"
        textTooltip="Conoce el plan creativo"
        idTooltip="knowCreativePlan"
        applyFontBold
      />
      <TypographyComponent
        text="Te invitamos a explorar el plan creativo para el diseño de tu intervención. Déscargalo, para tenerlo presente durante todo el proceso."
        variant="p"
        className="text-neutral-950 mt-2"
      />

      {(planCreative?.state?.id === 'c' ||
        planCreative?.state?.id === 'ip') && (
        <>
          {textForLimitDate?.text !== 'No hay fecha limite' && (
            <TypographyComponent
              text="OJO! Recuerda que hay una fecha límite para completar este proceso"
              variant="p"
              className="text-neutral-950 mt-2"
            />
          )}

          <DefaultBadge
            label={`Fecha limite para conocer plan creativo: ${textForLimitDate?.text}`}
            variant={textForLimitDate?.colorBadge}
            type="light"
            className="mt-6"
          />
          <StyledContentBorder className="mt-6">
            <StyledCardCommunity>
              <TypographyComponent
                variant="p"
                text={planCreative?.file?.url}
                className="flex-grow text-primary font-bold"
              />
              <StyledButtonsActions className="flex items-center">
                <ButtonComponent
                  text=""
                  className="icon-view"
                  onPress={preViewPDFCreativePlan}
                />
                <ButtonComponent
                  text=""
                  className="icon-download"
                  onPress={dowloadCreativePlanFile}
                />
              </StyledButtonsActions>
            </StyledCardCommunity>
          </StyledContentBorder>
        </>
      )}
      {planCreative?.state?.id !== 'c' && planCreative?.state?.id !== 'p' && (
        <ButtonComponent
          text="Siguiente etapa"
          className="mx-auto mt-8"
          onPress={updateCreativePlan}
        />
      )}
    </>
  );
};

export default ArtistAsingPlan;
