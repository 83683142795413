//Hooks
import useHelpers from '../../../../helpers';

const useAccessibilitySelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const accessibilitiesSelector = createSelector(
    (state) => state.accessibilities,
    (accessibilities) => accessibilities
  );
  return { accessibilitiesSelector };
};

export default useAccessibilitySelectors;
