//Hooks
import useHelpers from '../../../helpers';

const useDashboardSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const artistInterventionsSelector = createSelector(
    (state) => state.artistInterventions,
    (artistInterventions) => {
      return {
        artistInterventions: artistInterventions?.data,
        numberInterventions: artistInterventions.meta?.total,
        meta: artistInterventions?.meta,
        links: artistInterventions?.links,
        page: artistInterventions?.page,
      };
    }
  );

  return { artistInterventionsSelector };
};

export default useDashboardSelectors;
