//Packages
import React, { lazy } from 'react';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Assets
import iconLocation from 'assets/icons/MapPin.svg';
import imgFakeProject from 'assets/img/img-about-role-socio.png';
import imgUserFake from 'assets/img/fakeUserPhotoSociio.jpg';

//Styles
const StyledDashboardWrapperBadges = lazy(() =>
  import(
    '../../../../../../../../../../../styles/projects/dashboard.styles'
  ).then((mod) => ({
    default: mod.StyledDashboardWrapperBadges,
  }))
);

const StyledWrapperUsers = lazy(() =>
  import(
    '../../../../../../../../../../../styles/projects/dashboard.styles'
  ).then((mod) => ({
    default: mod.StyledWrapperUsers,
  }))
);

const StyledOtherConfigs = lazy(() =>
  import(
    '../../../../../../../../../../../styles/projects/dashboard.styles'
  ).then((mod) => ({
    default: mod.StyledOtherConfigs,
  }))
);

const StyledContentButtons = lazy(() =>
  import('./KnowProject.styles').then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const StyledListRecomendation = lazy(() =>
  import('./KnowProject.styles').then((mod) => ({
    default: mod.StyledListRecomendation,
  }))
);

const StyledListRecomendationItem = lazy(() =>
  import('./KnowProject.styles').then((mod) => ({
    default: mod.StyledListRecomendationItem,
  }))
);

const ArtistKnowProject = () => {
  const { useComponents } = useViews();

  const { useTypographies, useButtons, useCards, useBadges, useImages } =
    useComponents();
  const { TypographyComponent } = useTypographies();
  const { ButtonComponent } = useButtons();
  const { CardUser, CardNotification } = useCards();
  const { DefaultBadge } = useBadges();
  const { Image } = useImages();
  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminKnowProjectIntervention } =
    useAdminInterventionsDashboardControllers();
  const {
    projectData,
    know_project,
    handleGoToPath,
    recommendationsList,
    updateStateKnowProject,
    onError,
    convertThounsandsSeparator,
    convertArrayToString,
    showCityDepartmentAndCountry,
    textForLimitDate,
  } = useAdminKnowProjectIntervention();

  return (
    <>
      <>
        <CardNotification
          className="w-5/12 mx-auto py-9 px-4 mt-16"
          title={
            <TypographyComponent
              text={onError ? 'Pendiente' : know_project?.state?.name}
              variant="h2"
              className="font-bold text-primary icon-watch text-center"
            />
          }
        />
      </>

      <TypographyComponent
        text="Conoce el proyecto"
        variant="h2"
        className="text-primary mt-2 font-bold"
      />

      <TypographyComponent
        text="Te invitamos a explorar información sobre el proyecto para que te inspires para el diseño de la intervención."
        variant="p"
        className="text-neutral-950 mt-2"
      />

      {know_project?.state?.id !== 'p' && (
        <>
          <DefaultBadge
            label={`Fecha limite para conocer el proyecto: ${textForLimitDate?.text}`}
            variant={textForLimitDate?.colorBadge}
            type="light"
            className="mt-6"
          />
          <Image
            className="rounded-2xl w-full mt-8 mb-10 h-96 object-center object-cover"
            srcImage={imgFakeProject}
            altImage="info"
            titleImage="info"
          />
          <TypographyComponent
            text={projectData?.name ?? ''}
            variant="h2"
            className="font-bold text-primary mb-1"
          />
          <TypographyComponent
            className="text-secondary flex"
            variant="h5"
            text={
              <>
                {' '}
                <Image
                  srcImage={iconLocation}
                  altImage="location"
                  className="location-icon"
                />
                {showCityDepartmentAndCountry({
                  city: projectData?.city,
                  format: 'cityDepartmentCountry',
                })}
              </>
            }
          />

          <StyledDashboardWrapperBadges>
            <DefaultBadge
              label={`Población: ${
                projectData?.population_number &&
                convertThounsandsSeparator(projectData.population_number)
              } ${
                Number(projectData?.population_number) === 1
                  ? 'habitante'
                  : 'habitantes'
              }`}
            />
            <DefaultBadge
              label={convertArrayToString({
                arrayToConvert: projectData?.languages || [],
              })}
            />
          </StyledDashboardWrapperBadges>

          <StyledWrapperUsers>
            {projectData?.project_manager && (
              <CardUser
                image={projectData.project_manager.avatar || imgUserFake}
                name={`${projectData.project_manager.name} ${projectData.project_manager.last_name}`}
                rol="Project manager"
                className="mb-2 "
                location={showCityDepartmentAndCountry({
                  city: projectData.project_manager.city,
                })}
              />
            )}
            {projectData?.social_expert && (
              <CardUser
                image={projectData.social_expert.avatar || imgUserFake}
                name={`${projectData.social_expert.name} ${projectData.social_expert.last_name}`}
                rol="Experto ASCC"
                className="mb-2 ml-4"
                location={showCityDepartmentAndCountry({
                  city: projectData?.social_expert.city,
                })}
              />
            )}
            {projectData?.executing_partner && (
              <CardUser
                image={projectData.executing_partner.avatar || imgUserFake}
                name={`${projectData.executing_partner.name} ${projectData.executing_partner.last_name}`}
                rol="Socio Ejecutor"
                className="mb-2 ml-4"
                location={showCityDepartmentAndCountry({
                  city: projectData?.executing_partner.city,
                })}
              />
            )}
          </StyledWrapperUsers>

          <TypographyComponent
            text={projectData?.description ?? ''}
            variant="p"
            className="text-neutral-950 mt-2"
          />

          <TypographyComponent
            text={
              'Te presentamos nuestro proyecto, has click en los diferentes botones para explorar cada tema.'
            }
            variant="p"
            className="text-neutral-950 mt-2"
          />

          <StyledOtherConfigs col3>
            <ButtonComponent
              variant="primary"
              className="w-full"
              text="Comunidades"
              buttonTypeSecondary
              icon={<i className="text-lg icon-comunities" />}
              onPress={() => {
                handleGoToPath({ path: 'communities' });
              }}
            />
            <ButtonComponent
              variant="primary"
              className="w-full"
              text="Información sociocultural"
              buttonTypeSecondary
              icon={<i className="text-lg icon-assignment_ind" />}
              onPress={() => {
                handleGoToPath({ path: 'socio-cultural-info' });
              }}
            />
            <ButtonComponent
              variant="primary"
              className="w-full"
              text="Fiestas y tradiciones"
              buttonTypeSecondary
              icon={<i className="text-lg icon-parties mr-4" />}
              onPress={() => {
                handleGoToPath({ path: 'festivities' });
              }}
            />
            <ButtonComponent
              variant="primary"
              className="w-full"
              text="Trayectoria ABC"
              buttonTypeSecondary
              icon={<i className="text-lg icon-folder-trayec" />}
              onPress={() => {
                handleGoToPath({ path: 'abc-trajectory' });
              }}
            />

            <ButtonComponent
              variant="primary"
              className="w-full"
              text="Información complementaria"
              buttonTypeSecondary
              icon={<i className="text-lg icon-info-top" />}
              onPress={() => {
                handleGoToPath({ path: 'additional-info' });
              }}
            />
            <ButtonComponent
              variant="primary"
              className="w-full"
              text="Documentación"
              buttonTypeSecondary
              icon={<i className="text-lg icon-library-books" />}
              onPress={() => {
                handleGoToPath({ path: 'documentation' });
              }}
            />
          </StyledOtherConfigs>
          <div className="border-t h-2 mt-16 mb-10" />

          <TypographyComponent
            text="Recomendaciones para colaboraciones exitosas"
            variant="h2"
            className="font-bold text-primary"
          />

          {recommendationsList.length > 0 && (
            <StyledListRecomendation>
              {_.map(recommendationsList, ({ recommendation }, idx) => (
                <StyledListRecomendationItem key={`recommendationItem-${idx}`}>
                  {recommendation}
                </StyledListRecomendationItem>
              ))}
            </StyledListRecomendation>
          )}
        </>
      )}
      {!_.includes(['p', 'c'], know_project?.state?.id) && (
        <StyledContentButtons>
          <ButtonComponent
            text="Siguiente etapa"
            onPress={updateStateKnowProject}
          />
        </StyledContentButtons>
      )}
    </>
  );
};

export default ArtistKnowProject;
