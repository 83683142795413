import useHelpers from '../../../../helpers';

const useSpecialitiesSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const specialitiesSelector = createSelector(
    (state) => state.specialities,
    (specialities) => specialities
  );

  return { specialitiesSelector };
};

export default useSpecialitiesSelectors;
