//Hooks
import useHelpers from '../../../../../helpers';

const useAdminAbcTrajectorySelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const abcTrajectoriesListSelector = createSelector(
    (state) => state.projectSelected,
    (projectSelected) => projectSelected.trajectories
  );

  return { abcTrajectoriesListSelector };
};

export default useAdminAbcTrajectorySelectors;
