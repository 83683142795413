//Hooks
import useStrings from '../../../../strings';

const useSidebarActions = () => {
  const { useGeneralTypes } = useStrings();
  const { useSidebarTypes } = useGeneralTypes();
  const { OPEN_MENU_COLLAPSED } = useSidebarTypes();

  const actShowMenuCollapsed =
    ({ collapsed, clienteHei }) =>
    (dispatch) => {
      dispatch({
        type: OPEN_MENU_COLLAPSED,
        payload: { collapsed, clienteHei },
      });
    };

  return { actShowMenuCollapsed };
};

export default useSidebarActions;
