import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';
import useHelpers from 'helpers';
import useControllers from 'controllers';

// Styles
import { StyledInputSearchContent } from 'styles/tableViews/tableViews.styles';
import { StyledDivider } from 'styles/formViews/formViews.styles';
import {
  StyledContentButtons,
  StyledContentButtonsModal,
} from 'styles/tableViews/tableViews.styles';
import { StyledAgentsList, StyledItem } from '../../Agents.styles';

const ModalAgents = (props) => {
  const {
    agentsData,
    selectedAgents,
    communities,
    getAgentsByCommunity,
    handleAssignChangeAgent,
    closeModal,
  } = props;

  const { useComponents } = useViews();
  const { useButtons, useTypographies, useInputs, useCards, useModals } =
    useComponents();
  const { ButtonComponent } = useButtons();
  const { TypographyComponent } = useTypographies();
  const { InputSearch, Select, Radio } = useInputs();
  const { CardUser } = useCards();
  const { UserInfoModal, DefaultModal } = useModals();

  const { useQuickFunctions } = useHelpers();
  const { updateItemInArray } = useQuickFunctions();

  const { useGeneralHooks } = useControllers();
  const { useToggleModal } = useGeneralHooks();

  const [, setIsOnFocusTextSearch] = useState(false);
  const [textSearch, setTextSearch] = useState('');
  const [agentInfoModalOpen, toggleAgentInfoModal] = useToggleModal();
  const [agentModalConfirmationOpen, toggleAgentModalConfirmation] =
    useToggleModal();
  const agentInfoRef = useRef();

  const handleOnFocus = (isOnFocus) => {
    setIsOnFocusTextSearch(isOnFocus);
  };

  const { control, watch, reset } = useForm({
    mode: 'onChange',
  });

  const [agents, setAgents] = useState(agentsData);
  const availableAgents = agents.filter(
    (a) => !selectedAgents.some((sa) => sa.user.id === a.id)
  );

  useEffect(() => {
    setAgents(agentsData);
  }, [agentsData]);

  useEffect(() => {
    if (watch('communities') && getAgentsByCommunity) {
      getAgentsByCommunity({
        full_name: textSearch,
        community_id: watch('communities'),
      });
    }
  }, [textSearch, watch('communities')]);

  const handleShowAgentInfo = (agent) => {
    toggleAgentInfoModal();
    agentInfoRef.current = agent;
  };

  const handleSelectAgent = (agent) => {
    setAgents(
      updateItemInArray({
        arrayData: agentsData,
        itemToUpdate: { ...agent, selected: true },
      })
    );
  };

  const submitData = () => {
    reset({ communities: null });
    toggleAgentModalConfirmation();
    closeModal();
    handleAssignChangeAgent(agents.find((a) => a.selected));
  };

  return (
    <>
      <TypographyComponent
        text="Selección de Agente de cambio"
        variant="h3"
        className="font-bold text-primary mb-1"
      />
      <TypographyComponent
        text="Cras bibendum nulla eu mollis tempus. Vestibulum in dolor et arcu malesuada maximus ac at justo. Ut dignissim maximus ornare. Integer tincidunt erat sed sapien dictum, a malesuada nunc lobortis."
        variant="p"
        className="text-neutral-950  mb-4"
      />
      <Select
        name="communities"
        id="communities"
        control={control}
        options={communities}
        label="Comunidad"
        placeholder="Selecciona la comunidad"
        className="w-full"
      />

      <StyledDivider spacexs />

      {watch('communities') && (
        <StyledInputSearchContent fullWidth>
          <InputSearch
            text={textSearch}
            onDebounce={setTextSearch}
            handlerIsOnFocus={handleOnFocus}
          />
        </StyledInputSearchContent>
      )}

      <StyledAgentsList>
        {watch('communities') && agents.length === 0 && (
          <>No hay agentes de cambio en esta comunidad</>
        )}
        {watch('communities') &&
          agents.length > 0 &&
          availableAgents.length === 0 && (
            <>Todos los agentes de cambio en esta comunidad fueron convocados</>
          )}
        {watch('communities') &&
          agents.length > 0 &&
          _.map(availableAgents, (agent) => {
            return (
              <StyledItem key={`agent-${agent.id}`}>
                <CardUser
                  name={`${agent?.name} ${agent?.last_name}`}
                  location={`${agent.city.name}, ${agent.city.department.country.name}`}
                  className="relative"
                >
                  <Radio
                    name="reading_level"
                    label=""
                    value={`${agent.id}`}
                    onChange={() => handleSelectAgent(agent)}
                    className="absolute left-0 ml-2"
                    checked={agent.selected}
                  />
                  <StyledContentButtons>
                    <ButtonComponent
                      text=""
                      className="icon-view text-primary-100"
                      variant="primary"
                      onPress={() => handleShowAgentInfo(agent)}
                    />
                  </StyledContentButtons>
                </CardUser>
              </StyledItem>
            );
          })}
      </StyledAgentsList>
      <ButtonComponent
        text="Convocar Agente de cambio "
        className="mx-auto mt-8"
        onPress={toggleAgentModalConfirmation}
        disabled={!agents?.some((a) => a.selected)}
      />
      <UserInfoModal
        viewUser={agentInfoModalOpen}
        setViewUser={toggleAgentInfoModal}
        user={{
          ...agentInfoRef.current,
          name: `${agentInfoRef.current?.name} ${agentInfoRef.current?.last_name}`,
        }}
      />

      <DefaultModal
        isActive={agentModalConfirmationOpen}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={toggleAgentModalConfirmation}
      >
        <TypographyComponent
          text="¿Estás seguro de que deseas convocar al agente de cambio?"
          variant="h3"
          className="font-bold text-primary mb-1"
        />
        <TypographyComponent
          text={`${agents.find((a) => a.selected)?.name} ${
            agents.find((a) => a.selected)?.last_name
          } recibirá un mensaje de invitación a la convocatoria si das clic en Aceptar`}
          variant="p"
          className="text-neutral-950  mb-1"
        />

        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Volver"
            className="mx-auto w-full lg:w-auto lg:mx-2 "
            mode="outline"
            onPress={toggleAgentModalConfirmation}
          />
          <ButtonComponent
            variant="primary"
            text="Aceptar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={submitData}
          />
        </StyledContentButtonsModal>
      </DefaultModal>
    </>
  );
};

ModalAgents.propTypes = {
  agentsData: PropTypes.array,
  selectedAgents: PropTypes.array,
  communities: PropTypes.array,
  getAgentsByCommunity: PropTypes.func,
  handleAssignChangeAgent: PropTypes.func,
  closeModal: PropTypes.func,
};

export default ModalAgents;
