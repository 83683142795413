//Packages
import React from 'react';
import PropTypes from 'prop-types';

//Component
import GeneralRoute from '../General';

//Hooks
import useControllers from '../../../controllers';

const PublicRoute = (props) => {
  const { component, redirect, message, ...rest } = props;

  const { useRoutesHooks } = useControllers();
  const { usePublicRoute } = useRoutesHooks();
  const { props: properties, permission } = usePublicRoute();

  return (
    <GeneralRoute
      component={component}
      redirect={redirect}
      message={message}
      properties={properties}
      permission={permission}
      {...rest}
    />
  );
};

PublicRoute.defaultProps = {
  redirect: null,
  message: null,
};
PublicRoute.propTypes = {
  redirect: PropTypes.string,
  message: PropTypes.element,
  component: PropTypes.elementType.isRequired,
};
export default PublicRoute;
