import React, { lazy } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

// Hooks
import useViews from 'views';

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

// Components
import ExecutionInterventionComments from '../../../../../Shared/EjecutionInterventionComments';

const StyledContentButtons = lazy(() =>
  import(
    '../../../../../../../../../../../../../../styles/tableViews/tableViews.styles'
  ).then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const StyledDivider = lazy(() =>
  import(
    '../../../../../../../../../../../../../../styles/formViews/formViews.styles'
  ).then((mod) => ({
    default: mod.StyledDivider,
  }))
);

const Advance = (props) => {
  const { events } = props;

  const { useComponents } = useViews();
  const { useTypographies, useButtons, useBadges } = useComponents();
  const { TypographyComponent } = useTypographies();
  const { ButtonComponent } = useButtons();
  const { DefaultBadge } = useBadges();

  return (
    <>
      <TypographyComponent
        text="Programación"
        variant="h3"
        className="text-primary mt-6 font-bold"
      />

      <Accordion allowZeroExpanded>
        {events.length > 0 &&
          _.map(events, (event) => {
            return (
              <div
                className="rounded mt-2 bg-neutral-400 border-2 py-4"
                key={`event-${event.id}`}
              >
                <AccordionItem>
                  <div className="flex items-center justify-between  py-2 px-6">
                    <div className="flex items-center">
                      <TypographyComponent
                        variant="h3"
                        text={event.definition_intervention?.name}
                        className="flex-grow text-primary font-bold"
                      />

                      <StyledContentButtons>
                        <AccordionItemState>
                          {({ expanded }) => (
                            <AccordionItemButton>
                              <ButtonComponent
                                text=""
                                className=""
                                variant="white"
                                icon={
                                  <div
                                    className={`icon-arrow-down text-xxs text-neutral-950 ml-4 ${
                                      expanded && 'transform rotate-180'
                                    }`}
                                  />
                                }
                                onPress={() => {}}
                              />
                            </AccordionItemButton>
                          )}
                        </AccordionItemState>
                      </StyledContentButtons>
                    </div>
                    {event.state.id === 'c' && (
                      <DefaultBadge
                        type="light"
                        label="Evento completado"
                        variant="success"
                      />
                    )}
                  </div>
                  <AccordionItemPanel className="py-4  px-6">
                    {_.map(event.milestones, (milestone) => (
                      <div
                        className="flex items-center justify-between bg-white px-6 py-2 mb-2 rounded border"
                        key={`milestone-${milestone.id}`}
                      >
                        <TypographyComponent
                          variant="h3"
                          text={milestone.type.name}
                          className="flex-grow text-primary font-bold"
                        />
                        {milestone.state.id === 'c' ? (
                          <div className="icon-check-circle text-success text-2xl" />
                        ) : (
                          <DefaultBadge
                            type="light"
                            label={milestone.state.name}
                            variant={
                              milestone.state.id === 'p'
                                ? 'secondary'
                                : 'warning'
                            }
                          />
                        )}
                      </div>
                    ))}
                  </AccordionItemPanel>
                </AccordionItem>
              </div>
            );
          })}
      </Accordion>
      {1 > 2 && (
        <>
          <StyledDivider />
          <ExecutionInterventionComments />
        </>
      )}
    </>
  );
};

Advance.propTypes = {
  events: PropTypes.array,
};

export default Advance;
