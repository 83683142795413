import useHelpers from '../../../../helpers';
import useStrings from '../../../../strings';
import useInitialStates from '../../../initialStates';

const useModalsReducers = () => {
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  //Initial States
  const { useGeneralInitialStates } = useInitialStates();
  const { useModalsInitialStates } = useGeneralInitialStates();
  const { initialStateModalUpdateProfilePhoto } = useModalsInitialStates();

  //Strings
  const { useGeneralTypes } = useStrings();
  const { useModalsTypes } = useGeneralTypes();
  const { HIDE_MODAL_UPLOAD_PROFILE_PHOTO, OPEN_MODAL_UPLOAD_PROFILE_PHOTO } =
    useModalsTypes();

  const showModalUpdateProfilePhoto = createReducer(
    initialStateModalUpdateProfilePhoto,
    {
      [OPEN_MODAL_UPLOAD_PROFILE_PHOTO](state, action) {
        const { blobImage, name, type } = action.payload;
        return { ...state, showModal: true, blobImage, name, type };
      },
      [HIDE_MODAL_UPLOAD_PROFILE_PHOTO](state) {
        return { ...state, showModal: false };
      },
    }
  );

  return { showModalUpdateProfilePhoto };
};

export default useModalsReducers;
