import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useRecoverPasswordProvider = () => {
  const resetPasswordProvider = (reset_password) => {
    const response = axios({
      method: 'POST',
      url: '/auth/reset-password',
      data: reset_password,
    });

    return trackPromise(response);
  };
  const validateTokenProvider = ({ token }) => {
    const response = axios({
      method: 'POST',
      url: '/auth/validate-token',
      data: { token },
    });

    return trackPromise(response);
  };
  const forgotPasswordProvider = ({ email }) => {
    const response = axios({
      method: 'POST',
      url: '/auth/forgot-password',
      data: { email },
    });

    return trackPromise(response);
  };
  return {
    resetPasswordProvider,
    validateTokenProvider,
    forgotPasswordProvider,
  };
};
export default useRecoverPasswordProvider;
