//Hooks
import useStrings from 'strings';

const useIsFilterEmptyActions = () => {
  //Strings
  const { useGeneralTypes } = useStrings();
  const { useIsFilterEmptyTypes } = useGeneralTypes();
  const { IS_FILTER_EMPTY_FALSE, IS_FILTER_EMPTY_TRUE } =
    useIsFilterEmptyTypes();

  const actSetFilterEmpty = (onSuccess, onError) => async (dispatch) => {
    try {
      dispatch({ type: IS_FILTER_EMPTY_TRUE });
      onSuccess && onSuccess();
    } catch (error) {
      onError && onError(error);
    }
  };

  const actResetFilterEmpty = (onSuccess, onError) => async (dispatch) => {
    try {
      dispatch({ type: IS_FILTER_EMPTY_FALSE });
      onSuccess && onSuccess();
    } catch (error) {
      onError && onError(error);
    }
  };

  return { actSetFilterEmpty, actResetFilterEmpty };
};

export default useIsFilterEmptyActions;
