import React from 'react';

const Table = React.lazy(() => import('./Table'));
const TableBody = React.lazy(() => import('./TableBody'));
const TableHead = React.lazy(() => import('./TableHead'));
const TableRow = React.lazy(() => import('./TableRow'));
const TableHeadCell = React.lazy(() => import('./TableHeadCell'));
const TableCell = React.lazy(() => import('./TableCell'));
const TableCustom = React.lazy(() => import('./TableCustom/Table'));

const useTables = () => {
  return {
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableHeadCell,
    TableCell,
    TableCustom,
  };
};
export default useTables;
