//Packages
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

//Hooks
import useStrings from 'strings';
import useHelpers from 'helpers';
import useApi from 'api';
import useControllers from 'controllers';

const useAdminEditProject = () => {
  const history = useHistory();
  const { project_id: currentProjectId } = useParams();

  const [projectData, setProjectData] = useState(null);

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const {
    REQUIRED_FIELD,
    FILE_SIZE,
    FILE_TYPE,
    NUMBER_NOT_VALID,
    ARRAY_MIN_1_LENGTH,
    ARRAY_MAX_3_LENGTH,
    MAX_LENGTH,
    POSITIVE_NUMBER,
    INTEGER_NUMBER,
    MAX_10M_POPULATION_NUMBER,
  } = useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const { getFileValidations } = useQuickFunctions();
  const { MAX_SIZE, SUPPORTED_FORMATS_ONLY_IMAGES } = getFileValidations();

  const { useActions } = useApi();
  const { dispatch, useAdminActions, useUserActions } = useActions();
  const { actSearchUser } = useUserActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminProjectsRootActions } = useAdminProjectsActions();
  const { actGetProjectById, actUpdateProjectById } =
    useAdminProjectsRootActions();

  const schemaCreateCommunity = yup.object({
    name: yup.string().required(REQUIRED_FIELD),
    description: yup.string().required(REQUIRED_FIELD).max(40000, MAX_LENGTH),
    country: yup.object().required(REQUIRED_FIELD),
    department: yup.object().required(REQUIRED_FIELD).nullable(),
    city_id: yup.object().required(REQUIRED_FIELD).nullable(),
    population_number: yup
      .number(NUMBER_NOT_VALID)
      .typeError(REQUIRED_FIELD)
      .positive(POSITIVE_NUMBER)
      .integer(INTEGER_NUMBER)
      .max(10000000, MAX_10M_POPULATION_NUMBER)
      .required(REQUIRED_FIELD),
    languages: yup
      .array()
      .of(yup.object())
      .min(1, ARRAY_MIN_1_LENGTH)
      .max(3, ARRAY_MAX_3_LENGTH)
      .required(REQUIRED_FIELD),
    image: yup
      .mixed()
      .nullable()
      .test('fileType', FILE_TYPE, (value) => {
        if (value) {
          return SUPPORTED_FORMATS_ONLY_IMAGES.includes(value.type);
        }
        return true;
      })
      .test('fileSize', FILE_SIZE, (value) => {
        if (value) {
          return value.size <= MAX_SIZE;
        }
        return true;
      }),
    project_manager_id: yup.object().nullable(),
    social_expert_id: yup.object().nullable(),
    executing_partner_id: yup.object().nullable(),
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schemaCreateCommunity),
    mode: 'onChange',
  });

  const countrySelected = watch('country');
  const departmentSelected = watch('department');

  const { useGeneralHooks } = useControllers();
  const { useWhereLive, useGetOptionsList } = useGeneralHooks();
  const {
    optionsCountries,
    optionsCities,
    optionsDepartments,
    optionsLanguages,
  } = useWhereLive({
    countrySelected,
    departmentSelected,
    languageWithId: true,
  });
  const { optionsAdminUsers, optionsPartnerUsers } = useGetOptionsList();

  useEffect(() => {
    dispatch(
      actGetProjectById(
        { projectId: currentProjectId },
        onSuccessGetProjectById
      )
    );
  }, []);

  useEffect(() => {
    dispatch(
      actSearchUser({ paginate: 0, roles: [{ value: 'ad' }, { value: 'ep' }] })
    );
  }, []);

  const onSuccessGetProjectById = (res) => {
    const { data } = res.data;
    setProjectData(res.data.data);
    setValue('name', data.name);
    setValue('description', data.description);
    setValue('country', {
      label: data.city.department.country.name,
      value: data.city.department.country.id,
    });
    setValue('department', {
      label: data.city.department.name,
      value: data.city.department.id,
    });
    setValue('city_id', {
      label: data.city.name,
      value: data.city.id,
    });
    setValue('population_number', data.population_number);
    setValue(
      'languages',
      _.map(data.languages, (language) => {
        return { label: language.name, value: language.id };
      })
    );
    setValue(
      'project_manager_id',
      data?.project_manager?.name && {
        label: `${data?.project_manager?.name} ${data?.project_manager?.last_name}`,
        value: data?.project_manager?.id,
      }
    );

    setValue(
      'social_expert_id',
      data.social_expert?.name && {
        label: `${data.social_expert?.name} ${data.social_expert?.last_name}`,
        value: data.social_expert?.id,
      }
    );

    setValue(
      'executing_partner_id',
      data.executing_partner?.name && {
        label: `${data.executing_partner?.name} ${data.executing_partner?.last_name}`,
        value: data.executing_partner?.id,
      }
    );
  };

  const handleChangeValueSelect = ({ inputName }) => {
    if (inputName === 'department') {
      setValue('city_id', null);
    }
    if (inputName === 'country') {
      setValue('department', null);
      setValue('city_id', null);
    }
  };

  const goBack = () => history.push('/admin/projects');

  const onSuccessHandleUpdateProjectById = () => {
    goBack();
  };

  const handleUpdateProjectById = (data) => {
    delete data.country;
    delete data.department;
    dispatch(
      actUpdateProjectById(
        { projectId: currentProjectId, ...data },
        onSuccessHandleUpdateProjectById
      )
    );
  };

  const handleResetInputFile = () => {
    setValue('image', '');
    clearErrors('image');
  };

  return {
    control,
    register,
    handleSubmit,
    errors,
    goBack,
    projectData,
    handleUpdateProjectById,
    optionsCountries,
    optionsCities,
    optionsDepartments,
    optionsLanguages,
    countrySelected,
    departmentSelected,
    handleChangeValueSelect,
    optionsAdminUsers,
    optionsPartnerUsers,
    handleResetInputFile,
  };
};

export default useAdminEditProject;
