//Hooks
import useModels from '../../../models';

const useIsAuth = () => {
  //Models
  const { useSelectors } = useModels();
  const { useSelector, useAuthSelectors } = useSelectors();
  const { authSelector } = useAuthSelectors();
  const { auth_token } = useSelector(authSelector);
  return { isAuth: !!auth_token };
};

export default useIsAuth;
