const useAdminProjectsRootTypes = () => {
  const PROJECT_CREATED = 'PROJECT_CREATED';
  const GET_PROJECTS = 'GET_PROJECTS';
  const SET_PAGE_GET_PROJECTS = 'SET_PAGE_GET_PROJECTS';
  const DELETE_PROJECT = 'DELETE_PROJECT';
  const GET_PROJECT_ID = 'GET_PROJECT_ID';
  const UPDATE_PROJECT_ID = 'UPDATE_PROJECT_ID';
  const SET_DATA_PROJECT_FILTER = 'SET_DATA_PROJECT_FILTER';
  const RESET_DATA_PROJECT_FILTER = 'RESET_DATA_PROJECT_FILTER';
  const RESET_DATA_PROJECT_FILTER_WITHOUT_NAME =
    'RESET_DATA_PROJECT_FILTER_WITHOUT_NAME';

  return {
    PROJECT_CREATED,
    GET_PROJECTS,
    DELETE_PROJECT,
    GET_PROJECT_ID,
    UPDATE_PROJECT_ID,
    SET_DATA_PROJECT_FILTER,
    RESET_DATA_PROJECT_FILTER,
    RESET_DATA_PROJECT_FILTER_WITHOUT_NAME,
    SET_PAGE_GET_PROJECTS,
  };
};

export default useAdminProjectsRootTypes;
