import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Hooks
import useViews from '../../../../../../../../../../../..';

const StyledGroup = lazy(() =>
  import(
    '../../../../../../../../../../../../../styles/formViews/formViews.styles'
  ).then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const Aspects = (props) => {
  const { keyFactors } = props;

  const { useComponents } = useViews();

  const { useTypographies, useInputs } = useComponents();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { InputForm } = useInputs();

  return (
    <>
      <TypographyWithTooltip
        text="Aspectos clave de la comunidad*"
        variant="h3"
        textTooltip="Aspectos clave de la comunidad"
        className="text-primary mt-4"
        idTooltip="aspects_project"
        applyFontBold
      />

      <TypographyComponent
        text="A partir de las informaciones claves del proyecto, las comunidades y el comportamiento, identifica máximo 3 aspectos sobre los cuales vas a diseñar tu intervención."
        variant="p"
        className="text-neutral-950 mt-4 mb-6"
      />

      <StyledGroup col2 flex className="mb-6 flex-col">
        {_.map(keyFactors, (element, idx) => (
          <InputForm
            key={`keyFactor-${idx}`}
            name="opt1"
            label=""
            type="text"
            className="full"
            placeholder="Input placeholder"
            defaultValue={element}
            disabled
          />
        ))}
      </StyledGroup>
    </>
  );
};

Aspects.propTypes = {
  keyFactors: PropTypes.array,
};

export default Aspects;
