import useHelpers from '../../../../helpers';
import useStrings from '../../../../strings';
import useInitialStates from '../../../initialStates';

const useLegalDataReducers = () => {
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  const { useGeneralTypes } = useStrings();
  const { useLegalDataTypes } = useGeneralTypes();
  const { LEGAL_DATA } = useLegalDataTypes();

  const { useGeneralInitialStates } = useInitialStates();
  const { useLegalDataInitialStates } = useGeneralInitialStates();
  const { initialStateLegalData } = useLegalDataInitialStates();

  const legalData = createReducer(initialStateLegalData, {
    [LEGAL_DATA](state, action) {
      const { terms, privacy } = action.payload;
      return {
        ...state,
        privacyPolicies: privacy,
        termsAndConditions: terms,
      };
    },
  });

  return { legalData };
};

export default useLegalDataReducers;
