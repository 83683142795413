import React from 'react';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';
import useHelpers from 'helpers';

//Styles
import { StyledContentButtonsModal } from 'styles/tableViews/tableViews.styles';
import { StyledDivider } from 'styles/formViews/formViews.styles';
import { StyledButtonsActions } from 'styles/buttons/buttons.styles';
import {
  StyledWrapperCommunity,
  StyledCardCommunity,
} from '../../../../../../../../Dashboard/Dashboard.styles';

const PilotEvaluateArtist = () => {
  const { useComponents } = useViews();
  const {
    useTypographies,
    useButtons,
    useModals,
    useInputs,
    useCards,
    useCollapses,
  } = useComponents();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { ButtonComponent } = useButtons();
  const { DefaultModal } = useModals();
  const { TextArea, InputFile } = useInputs();
  const { CardNotification } = useCards();
  const { DefaultCollapse } = useCollapses();

  const { useQuickFunctions } = useHelpers();
  const { getDocumentName, getYearMonthDayFromDate } = useQuickFunctions();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsDevelopmentIntervention } =
    useAdminViewInterventionsControllers();
  const { useViewDevelopmentInterventionCreateSketch } =
    useAdminViewInterventionsDevelopmentIntervention();
  const { useViewDevelopmentInterventionCreateSketchAdmin } =
    useViewDevelopmentInterventionCreateSketch();
  const { useAdminAndArtistEvaluatePilotIntervention } =
    useViewDevelopmentInterventionCreateSketchAdmin();
  const {
    evaluatePilot,
    formEvaluatePilot,
    showModalApprove,
    setShowModalApprove,
    stepCompleted,
    openFileEvaluatePilotIntervention,
    downloadFileEvaluatePilotIntervention,
    deleteFileEvaluatePilotIntervention,
    updateEvaluatePilotIntervention,
  } = useAdminAndArtistEvaluatePilotIntervention();

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = formEvaluatePilot;

  return (
    <>
      {(stepCompleted ||
        _.includes(['p', 'ip', 'ir'], evaluatePilot.state?.id)) && (
        <CardNotification
          className="w-5/12 mx-auto py-9 px-4 mt-16"
          title={
            <TypographyComponent
              text={evaluatePilot.state?.name}
              variant="h2"
              className="font-bold text-primary icon-watch text-center"
            />
          }
        />
      )}
      <TypographyComponent
        text="Evaluar piloto de la intervención"
        variant="h2"
        className="font-bold text-primary mt-6"
      />
      <TypographyComponent
        text='Llego el momento de hacer retroalimentación de este proceso, a partir del documento  "criterios de evaluación  del piloto" compartido contigo anteriormente.'
        variant="p"
        className="text-neutral-950 mt-2"
      />
      <TypographyComponent
        text="Recuerda que esta evaluación debe ser completada por ti, por el socio ejecutor, por la comunidad."
        variant="p"
        className="text-neutral-950 mt-2"
      />

      {evaluatePilot.partner && (
        <>
          <br />
          <DefaultCollapse
            title={
              <div className="flex flex-col">
                <TypographyWithTooltip
                  text="Evaluación del Socio Ejecutor"
                  variant="h3"
                  textTooltip="Evaluación del Socio Ejecutor"
                  className="text-primary"
                  idTooltip="recomendations"
                  applyFontBold
                />
                <TypographyComponent
                  variant="p"
                  className="text-neutral-950"
                  text={
                    'EL socio ejecutor te comparte sus observaciones sobre el piloto para que tengas en cuenta para el ajuste de tu intervención'
                  }
                />
              </div>
            }
            titleTitleCollapsed=""
            elementCollapsed={
              <div className="icon-arrow-down text-xxs text-neutral-950 cursor-pointer" />
            }
            elementUncollapsed={
              <div className="icon-arrow-down text-xxs text-neutral-950 transform rotate-180 cursor-pointer" />
            }
            className="border border-error py-6 rounded pl-8 pr-10 mb-8 bg-neutral-300"
            classNameTitle="w-full flex justify-between"
            withUseState
          >
            <div className="bg-white rounded-lg border p-6 mb-4">
              <TypographyComponent
                variant="p"
                className="text-neutral-800 mb-4"
                text={`${getYearMonthDayFromDate({
                  dateInString: evaluatePilot.date_c,
                  format: 'dmyShort',
                })} - ${
                  getYearMonthDayFromDate({
                    dateInString: evaluatePilot.date_c,
                    format: 'dmyTime',
                  }).split('-')[1]
                }`}
              />
              <TypographyComponent
                variant="p"
                className="text-neutral-950"
                text={evaluatePilot.partner}
              />
              <StyledCardCommunity darkBg>
                <TypographyComponent
                  variant="p"
                  text={getDocumentName({
                    file: evaluatePilot.document_partner,
                    withExtension: true,
                  })}
                  className="flex-grow text-primary font-bold cursor-pointer"
                />
                <StyledButtonsActions className="flex items-center">
                  <ButtonComponent
                    text=""
                    className="icon-view"
                    onPress={(e) => {
                      openFileEvaluatePilotIntervention({
                        e,
                        file: evaluatePilot.document_partner,
                      });
                    }}
                  />
                  <ButtonComponent
                    text=""
                    icon={
                      <div className="icon-download text-primary text-lg" />
                    }
                    onPress={(e) => {
                      downloadFileEvaluatePilotIntervention({
                        e,
                        file: evaluatePilot.document_partner,
                      });
                    }}
                  />
                </StyledButtonsActions>
              </StyledCardCommunity>
            </div>
          </DefaultCollapse>
        </>
      )}

      <TypographyWithTooltip
        text="Evaluación del artista*"
        variant="h3"
        textTooltip="Evaluación del artista"
        className="text-primary "
        idTooltip="creiterios"
        applyFontBold
        classNameDiv=" mt-8"
      />
      <TypographyComponent
        text="Enumera los aspectos claves de tu evaluación del piloto "
        variant="p"
        className="text-left mb-4"
      />
      <TextArea
        name="artist"
        placeholder="Escribe tu evaluación del piloto"
        className="w-full col-span-2"
        label=""
        {...register('artist')}
        errors={errors}
        disabled={stepCompleted}
      />
      <TypographyWithTooltip
        text="Evaluación de la comunidad*"
        variant="h3"
        textTooltip="Evaluación de la comunidad"
        className="text-primary"
        idTooltip="creiterios"
        applyFontBold
        classNameDiv=" mt-8"
      />
      <TypographyComponent
        text="Enumera los comentarios y observaciones principales de la comunidad sobre el  piloto "
        variant="p"
        className="text-left mb-4"
      />
      <TextArea
        name="community"
        placeholder="Escribe la evaluación de la comunidad del piloto"
        className="w-full col-span-2"
        label=""
        {...register('community')}
        errors={errors}
        disabled={stepCompleted}
      />

      <StyledDivider />

      <TypographyWithTooltip
        text={
          evaluatePilot.documents?.length
            ? 'Documentos'
            : 'Adjuntar documentos*'
        }
        variant="h3"
        textTooltip="Adjuntar documentos"
        className="text-primary"
        idTooltip="socio"
        applyFontBold
      />
      <TypographyComponent
        text='Adjunta el documento "criterios de evaluación del piloto" con tus aportes y los de la comunidad '
        variant="p"
        className="text-left mb-4"
      />

      {evaluatePilot.documents?.length > 0 && (
        <StyledWrapperCommunity nonemb>
          {_.map(evaluatePilot.documents, (file) => {
            return (
              <StyledCardCommunity key={`document-${file.id}`}>
                <TypographyComponent
                  variant="p"
                  text={getDocumentName({ file, withExtension: true })}
                  className="flex-grow text-primary font-bold "
                />
                <StyledButtonsActions className="flex items-center">
                  <ButtonComponent
                    text=""
                    className="icon-view"
                    onPress={(e) => {
                      openFileEvaluatePilotIntervention({ e, file });
                    }}
                  />
                  {!stepCompleted && (
                    <ButtonComponent
                      text=""
                      className="icon-delete"
                      onPress={(e) => {
                        deleteFileEvaluatePilotIntervention({ e, file });
                      }}
                    />
                  )}
                  {stepCompleted && (
                    <ButtonComponent
                      text=""
                      icon={
                        <div className="icon-download text-primary text-lg" />
                      }
                      onPress={(e) => {
                        downloadFileEvaluatePilotIntervention({ e, file });
                      }}
                    />
                  )}
                </StyledButtonsActions>
              </StyledCardCommunity>
            );
          })}
        </StyledWrapperCommunity>
      )}

      {!stepCompleted && evaluatePilot.documents?.length < 5 && (
        <Controller
          control={control}
          name="fileArtist"
          render={({ field: { onChange, name } }) => {
            return (
              <InputFile
                name={name}
                accept={'.pdf'}
                title={
                  <div className="flex items-center">
                    <div
                      className={`icon-paper-clip text-primary text-xl font-bold h-10 ${
                        errors[name] && 'text-error'
                      }`}
                    />
                    <TypographyComponent
                      text="Adjuntar archivo"
                      variant="p"
                      className={`text-primary font-bold ${
                        errors[name] && 'text-error'
                      }`}
                    />
                  </div>
                }
                onChange={onChange}
                className={`w-full mt-2 bg-none mb-4 ${
                  errors[name] && 'border-error'
                }`}
                errors={errors}
                cleanFileOnSave
              />
            );
          }}
        />
      )}
      {!stepCompleted && (
        <>
          <div className="flex items-center space-x-2 mt-10 mb-6 justify-center">
            <ButtonComponent
              text="Enviar al socio ejecutor"
              onPress={handleSubmit(() => setShowModalApprove(true))}
            />
          </div>

          <DefaultModal
            isActive={showModalApprove}
            classNameContent="max-w-screen-md w-11/12"
            handleClose={() => {
              setShowModalApprove(false);
            }}
          >
            <TypographyComponent
              text={'Enviar evaluación del piloto'}
              variant="h3"
              className="font-bold text-primary mb-1"
            />

            <StyledContentButtonsModal>
              <ButtonComponent
                variant="primary"
                text="Cancelar"
                className="mx-auto w-full lg:w-auto lg:mx-2 "
                mode="outline"
                onPress={() => setShowModalApprove(false)}
              />
              <ButtonComponent
                variant="primary"
                text="Aceptar"
                className="mx-auto w-full lg:w-auto  lg:mx-2"
                onPress={handleSubmit(updateEvaluatePilotIntervention)}
              />
            </StyledContentButtonsModal>
          </DefaultModal>
        </>
      )}
    </>
  );
};

export default PilotEvaluateArtist;
