//Packages
import React, { Suspense, lazy, useRef } from 'react';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

// Components
import useTabsTrayectoria from './components';

//Styles
const StyledBackButton = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);

const StyledBodyTab = lazy(() =>
  import('styles/tabs/tabs.styles').then((mod) => ({
    default: mod.StyledBodyTab,
  }))
);

const StyledBodyTabsTrayectoria = lazy(() =>
  import('./AbcTrajectory.styles').then((mod) => ({
    default: mod.StyledBodyTabsTrayectoria,
  }))
);

const AdminDashboardAbcTrajectory = () => {
  const { useComponents } = useViews();
  const { useTypographies, useBoxes, useTabs, useLoaders } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { Box } = useBoxes();
  const { Tabs, TabLink } = useTabs();

  const { InfraestructureA, BehaviorChangeB, Capital } = useTabsTrayectoria();

  const abcTrajectorySelected = useRef({
    0: { component: <InfraestructureA type="A" />, tabValue: 0 },
    1: { component: <BehaviorChangeB type="B" />, tabValue: 1 },
    2: { component: <Capital type="C" />, tabValue: 2 },
  });

  const { useScreenHooks, useGeneralHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminAbcTrajectoryDashboardHooks } =
    useAdminDashboardProjectsHooks();
  const { useAdminAbcTrajectoryDashboardRoot } =
    useAdminAbcTrajectoryDashboardHooks();
  const { handleGoBack, tabValue, handleSelectTab } =
    useAdminAbcTrajectoryDashboardRoot();
  const { useSelectLayoutByRole } = useGeneralHooks();
  const { LayoutSelected, props } = useSelectLayoutByRole();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <LayoutSelected {...props}>
        <StyledBackButton className="mt-3" onClick={handleGoBack}>
          Regresar
        </StyledBackButton>
        <Box className="bg-neutral-200 w-11/12 mx-auto max-w-screen-lg lg:px-16 lg:py-12 px-6 py-8 lg:my-8">
          <>
            <TypographyWithTooltip
              selectedContainer="styledComponent"
              text="Trayectoria ABC"
              variant="h2"
              textTooltip="DEFINIR TRAYECTORIA ABC"
              idTooltip="trayectoriaAbc"
              classNameImage="ml-4"
              applyFontBold
            />

            <TypographyComponent
              text="Para una mejor sinergia entre los tres componentes, añade aquí lo que hay para destacar o lo que ha sucedido en cada uno de ellos hasta ahora."
              variant="p"
              className="font-book text-neutral-950 mb-10"
            />

            <Tabs
              selected={tabValue}
              onSelect={handleSelectTab}
              className="w-full lg:w-9/12 mb-5 lg:mb-0 whitespace-nowrap overflow-auto lg:whitespace-normal"
            >
              <TabLink label="Infraestructura (A)" tab={0} />
              <TabLink label="Cambio de comportamiento (B)" tab={1} />
              <TabLink label="Capital (C)" tab={2} />
            </Tabs>

            <StyledBodyTabsTrayectoria>
              <StyledBodyTab
                active={
                  tabValue === abcTrajectorySelected.current[tabValue].tabValue
                }
              >
                {abcTrajectorySelected.current[tabValue].component}
              </StyledBodyTab>
            </StyledBodyTabsTrayectoria>
          </>
        </Box>
      </LayoutSelected>
    </Suspense>
  );
};

export default AdminDashboardAbcTrajectory;
