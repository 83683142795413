import useAdminDeepenKnowledgeCommunityInterventions from './root';
import useAdminDeepenKnowledgeMessagesCommunityInterventions from './message';
import useAdminDeepenKnowledgeActivityCommunityInterventions from './activity';
import useAdminDeepenKnowledgeScheduleInfo from './scheduleInfo';
import useAdminDeepenKnowledgeMessagesResults from './messageResults';

const useDeepenKnowledgeCommunity = () => {
  return {
    useAdminDeepenKnowledgeCommunityInterventions,
    useAdminDeepenKnowledgeMessagesCommunityInterventions,
    useAdminDeepenKnowledgeActivityCommunityInterventions,
    useAdminDeepenKnowledgeScheduleInfo,
    useAdminDeepenKnowledgeMessagesResults
  };
};
export default useDeepenKnowledgeCommunity;
