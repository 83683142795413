import _ from 'lodash';

import useHelpers from '../../../helpers';
import useStrings from '../../../strings';
import useInitialStates from '../../initialStates';

const useUserReducers = () => {
  const { useCreateReducer, useQuickFunctions } = useHelpers();
  const { createReducer } = useCreateReducer();
  const { updateItemInArray } = useQuickFunctions();

  const { useUserInitialStates } = useInitialStates();
  const {
    initialStateUser,
    initialStateUpdateUser,
    initialStateUsers,
    initialStateArtistUsersInifiniteScroll,
  } = useUserInitialStates();

  const { useUserTypes, useAuthTypes, useAdminTypes } = useStrings();

  const { LOGOUT } = useAuthTypes();
  const { useAdminLabelsTypes, useContactListTypes } = useAdminTypes();
  const {
    // ASSING_LABELS_TO_CONTACT,
    UNASSING_LABEL_TO_CONTACTS,
    ASSING_LABEL_TO_CONTACTS,
  } = useAdminLabelsTypes();
  const { REMOVE_CONTACT_TO_CONTACT_LIST } = useContactListTypes();
  const {
    DATA_USER,
    DATA_UPDATE_PROFILE_USER,
    DELETE_DATA_UPDATE_PROFILE_USER,
    RESET_DATA_UPDATE_PROFILE_USER,
    UPDATE_AVATAR_USER,
    EDIT_USER,
    GET_USERS,
    DELETE_USER,
    SEARCH_USER,
    GET_USER,
    GET_ARTISTS_INFINITE_SCROLL,
    RESET_ARTISTS_INFINITE_SCROLL,
    GET_FAVORITE_USERS,
    ADD_USER_FAVORITE,
    DELETE_USER_FAVORITE,
  } = useUserTypes();

  const manageUsers = (state, action) => {
    const users = {
      ...state,
      data: [],
      meta: {},
    };
    users.meta = {
      ...action.payload?.meta,
      prev: action.payload?.links?.prev,
      next: action.payload?.links?.next,
    };

    users.data = _.map(action.payload.data, (user) => ({
      ...user,
      phone_complete: `${user.phone.code.code} ${user.phone.number}`,
      country_name: `${user.city.department.country.name}`,
      city_name: `${user.city.name}`,
      departament_name: ` ${user.city.department.name}`,
      role_name: user.role.name,
    }));
    return users;
  };

  const user = createReducer(initialStateUser, {
    [DATA_USER](state, action) {
      const {
        email,
        name,
        last_name,
        avatar,
        phone,
        role,
        city,
        language,
        gender,
        organization,
        position,
        about_me_title,
        about_me_body,
        intervention_type,
        website,
        specialties,
        networks,
        training_to_others,
        specialitiesIndexed,
        networksIndexed,
        group,
      } = action.payload;
      const { code, number } = phone;
      const { id: idCode, iso_code, code: codeAux } = code;
      const { id, name: roleName } = role;
      const { name: cityName, id: cityId, department } = city;
      const { name: departmentName, id: departmentId, country } = department;
      const { name: contryName, id: countryId, code: countryCode } = country;
      return {
        ...state,
        email,
        name,
        last_name,
        avatar,
        phone: {
          code: {
            id: idCode,
            iso_code,
            code: codeAux,
          },
          number,
        },
        role: { id, name: roleName },
        city: {
          id: cityId,
          name: cityName,
          department: {
            id: departmentId,
            name: departmentName,
            country: {
              id: countryId,
              code: countryCode,
              name: contryName,
            },
          },
        },
        language,
        gender,
        organization,
        position,
        about_me_title,
        about_me_body,
        intervention_type,
        website,
        specialties,
        networks,
        training_to_others,
        specialitiesIndexed,
        networksIndexed,
        group,
      };
    },
    [UPDATE_AVATAR_USER](state, action) {
      const { avatar } = action.payload;
      return {
        ...state,
        avatar,
      };
    },
    [LOGOUT]() {
      return initialStateUser;
    },
  });

  const updateUser = createReducer(initialStateUpdateUser, {
    [DATA_UPDATE_PROFILE_USER](state, action) {
      return { ...state, ...action.payload };
    },
    [DELETE_DATA_UPDATE_PROFILE_USER](state, action) {
      return { ...action.payload };
    },
    [RESET_DATA_UPDATE_PROFILE_USER]() {
      return initialStateUpdateUser;
    },
  });

  const users = createReducer(initialStateUsers, {
    [GET_USERS](state, action) {
      return manageUsers(state, action);
    },
    [GET_USER](state, action) {
      return {
        ...state,
        user: action.payload.data,
      };
    },
    [DELETE_USER](state, action) {
      return {
        ...state,
        data: _.filter(state.data, (user) => user.id !== action.payload),
      };
    },
    [REMOVE_CONTACT_TO_CONTACT_LIST](state, action) {
      return {
        ...state,
        data: _.filter(state.data, (user) => user.id !== action.payload[0]),
      };
    },
    [SEARCH_USER](state, action) {
      return manageUsers(state, action);
    },
    [EDIT_USER](state, action) {
      const user = action.payload.data;
      const users = _.filter(state.data, (u) => u.id !== user.id);

      const new_user = {
        ...user,
        phone_complete: `${user.phone.code.code} ${user.phone.number}`,
        country_name: `${user.city.department.country.name}`,
        city_name: `${user.city.name}`,
        departament_name: ` ${user.city.department.name}`,
        role_name: user.role.name,
      };

      return { ...state, data: [...users, new_user] };
    },
    [ASSING_LABEL_TO_CONTACTS](state, action) {
      delete action.payload.tag.data.users;
      return {
        ...state,
        data: _.map(state.data, (user) => {
          if (action.payload.users.includes(user.id)) {
            return {
              ...user,
              tags: [...user.tags, action.payload.tag.data],
            };
          } else {
            return user;
          }
        }),
      };
    },
    [UNASSING_LABEL_TO_CONTACTS](state, action) {
      return {
        ...state,
        data: _.map(state.data, (user) => {
          if (user.id == action.payload.user) {
            return {
              ...user,
              tags: _.filter(user.tags, (tag) => tag.id != action.payload.tag),
            };
          } else {
            return user;
          }
        }),
      };
    },
    [GET_FAVORITE_USERS](state, action) {
      return {
        ...state,
        favorites: [...action.payload.data]
      }
    },
    [DELETE_USER_FAVORITE](state, action) {
      return {
        ...state,
        favorites: [...state.favorites].filter(u => u.id !== action.payload)
      }
    },
  });

  const artistUsersInfiniteScroll = createReducer(
    initialStateArtistUsersInifiniteScroll,
    {
      [GET_ARTISTS_INFINITE_SCROLL](state, action) {
        const currentData = action.payload.isArtistSelection ? [] : [...state.data]
        return {
          ...state,
          data: [...currentData, ...action.payload.data],
          meta: {
            current_page: action.payload?.meta.current_page,
            last_page: action.payload?.meta.last_page,
            total: action.payload?.meta.total,
            per_page: action.payload?.meta.per_page,
          },
          links: {
            prev: action.payload?.links?.prev,
            next: action.payload?.links?.next,
          },
        };
      },
      [ADD_USER_FAVORITE](state, action) {
        const user = [...state.data].find(u => u.id === action.payload)
        return {
          ...state,
          data: updateItemInArray({
            arrayData: state.data,
            itemToUpdate: {...user, favorite: true}
          })
        };
      },
      [DELETE_USER_FAVORITE](state, action) {
        const user = [...state.data].find(u => u.id === action.payload)
        return {
          ...state,
          data: updateItemInArray({
            arrayData: state.data,
            itemToUpdate: {...user, favorite: 'false'}
          })
        };
      },
      [RESET_ARTISTS_INFINITE_SCROLL]() {
        return initialStateArtistUsersInifiniteScroll;
      },
      [LOGOUT]() {
        return initialStateArtistUsersInifiniteScroll;
      },
    }
  );
  return { user, updateUser, users, artistUsersInfiniteScroll };
};

export default useUserReducers;
