//Packages
import React, { lazy, useState } from 'react';
import { useTable, useBlockLayout, useSortBy } from 'react-table';
import { useSticky } from 'react-table-sticky';
import _ from 'lodash';
import PropTypes from 'prop-types';

//Components
import DropDownFilter from './components/DropDownFilter/DropDownFilter';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Assets
import arrowDown from 'assets/icons/arrow-down-table.svg';

//Styles
const StyledTopFilters = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledTopFilters,
  }))
);

const StyledFilters = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledFilters,
  }))
);

const StyledInputSearchContent = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledInputSearchContent,
  }))
);

const StyledContentTableStiky = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentTableStiky,
  }))
);

const StyledContentButtons = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const StyledContentButtonsModal = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const StyledTableTitle = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledTableTitle,
  }))
);

const StyledWrapperContactTable = lazy(() =>
  import('./ListDetail.styles').then((mod) => ({
    default: mod.StyledWrapperContactTable,
  }))
);

const StyledWrapperTags = lazy(() =>
  import('./ListDetail.styles').then((mod) => ({
    default: mod.StyledWrapperTags,
  }))
);

const StyledBadgeTag = lazy(() =>
  import('./ListDetail.styles').then((mod) => ({
    default: mod.StyledBadgeTag,
  }))
);

const StyledWrapperButtonList = lazy(() =>
  import('./ListDetail.styles').then((mod) => ({
    default: mod.StyledWrapperButtonList,
  }))
);

const ListDetail = (props) => {
  const { contactList, setActiveTab } = props;
  const { useComponents } = useViews();

  const {
    useButtons,
    useInputs,
    useImages,
    useBadges,
    useModals,
    useTypographies,
    useCards,
  } = useComponents();

  const { CardEmptyState } = useCards();
  const { ButtonComponent } = useButtons();
  const { InputSearch } = useInputs();
  const { Image } = useImages();
  const { ActionBadge } = useBadges();
  const { DefaultModal } = useModals();
  const { TypographyComponent } = useTypographies();

  const [isOnFocusTextSearch, setIsOnFocusTextSearch] = useState(false);
  const [activeDropDown, setActiveDropDown] = useState(false);

  const [activeModalDelete, setActiveModalDelete] = useState({ active: false });

  const handleOpenMenu = () => {
    setActiveDropDown(!activeDropDown);
  };

  const handleOnFocus = (isOnFocus) => {
    setIsOnFocusTextSearch(isOnFocus);
  };

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminCommunitiesDashboardHooks } =
    useAdminDashboardProjectsHooks();
  const { useAdminContactsCommunitiesDashboardHooks } =
    useAdminCommunitiesDashboardHooks();
  const { useAdminRootContactsListCommunitiesDashboard } =
    useAdminContactsCommunitiesDashboardHooks();
  const {
    isFilterEmpty,
    users,
    deleteContact,
    defaultValueTextSearch,
    setTextSearch,
    handleOrderBy,
    orderBy,
  } = useAdminRootContactsListCommunitiesDashboard({ list_id: contactList.id });

  const data = React.useMemo(
    () => [
      ..._.map(users, (user) => {
        return {
          name: user.name,
          last_name: user.last_name,
          email: user.email,
          role: user.role?.name,
          gender: user.gender,
          phone_complete: `${user.phone?.code.code} ${user.phone?.number}`,
          whatsapp: user.has_whatsapp ? 'Sí' : 'No',
          tags: user.tags,
          col14: (
            <StyledContentButtons>
              <ButtonComponent
                text=""
                className="icon-delete"
                onPress={() => {
                  setActiveModalDelete({ active: true, user: user.id });
                }}
              />
            </StyledContentButtons>
          ),
        };
      }),
    ],
    [users]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nombre',
        accessor: 'name',
        sticky: 'left',
        width: 150,
        orderByKey: 'name',
      },
      {
        Header: 'Apellido',
        accessor: 'last_name',
        sticky: 'left',
        width: 120,
        orderByKey: 'last_name',
      },
      {
        Header: 'Correo electrónico',
        accessor: 'email',
        width: 320,
        orderByKey: 'email',
      },
      {
        Header: 'Rol',
        accessor: 'role',
        orderByKey: 'role',
      },
      {
        Header: 'Género',
        accessor: 'gender',
        orderByKey: 'gender',
      },
      {
        Header: 'Teléfono',
        accessor: 'phone_complete',
        width: 160,
        orderByKey: 'phone',
      },

      {
        Header: 'WhatsApp',
        accessor: 'whatsapp',
        width: 140,
        noneOrder: true,
      },
      {
        Header: 'Etiquetas',
        width: 400,
        noneOrder: true,
        accessor: (data) => (
          <StyledWrapperTags>
            {_.map(data.tags, (item, key) => (
              <StyledBadgeTag key={`tag-${key}`} bgColor={item.color}>
                <ActionBadge
                  label={item.name}
                  type="solid"
                  onDelete={() => {}}
                />
              </StyledBadgeTag>
            ))}
          </StyledWrapperTags>
        ),
      },
      {
        Header: '',
        accessor: 'col14',
        sticky: 'right',
        width: 100,
      },
    ],
    [users]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useBlockLayout,
      useSticky,
      useSortBy
    );

  return (
    <StyledWrapperContactTable>
      <StyledTopFilters>
        <StyledWrapperButtonList className="items-center">
          <TypographyComponent
            text={contactList.title}
            variant="h3"
            className="font-bold text-primary leading-4"
          />
          <ButtonComponent
            text="Ver todas las listas"
            onPress={() => {
              setActiveTab({ active: 1, contactList: {} });
            }}
          />
        </StyledWrapperButtonList>

        {!(!isFilterEmpty && rows?.length === 0) && (
          <StyledFilters className="relative">
            <ButtonComponent
              text="Filtros"
              mode="outline"
              className="icon-filter flex items-center space-x-2"
              onPress={handleOpenMenu}
            />
            <StyledInputSearchContent>
              <InputSearch
                text={defaultValueTextSearch}
                onDebounce={setTextSearch}
                handlerIsOnFocus={handleOnFocus}
              />
            </StyledInputSearchContent>
            <DropDownFilter
              open={activeDropDown}
              isOnFocusTextSearch={isOnFocusTextSearch}
              list_id={`${contactList.id}`}
              handleOpenMenu={handleOpenMenu}
            />
          </StyledFilters>
        )}
      </StyledTopFilters>

      <StyledContentTableStiky>
        <div
          {...getTableProps()}
          className="table-sticky sticky-table mt-12 pb-4"
        >
          <div className="header">
            {_.map(headerGroups, (headerGroup, idx) => (
              <div
                {...headerGroup.getHeaderGroupProps()}
                className="tr"
                key={`header-group-${idx}`}
              >
                {_.map(headerGroup.headers, (column, idx) => (
                  <div
                    {...column.getHeaderProps()}
                    className="th text-primary font-bold py-2"
                    key={`column-${idx}`}
                  >
                    <StyledTableTitle
                      className={`${
                        !column.noneOrder && data?.length
                          ? 'cursor-pointer'
                          : 'cursor-not-allowed'
                      }`}
                      onClick={() => {
                        !column.noneOrder &&
                          data?.length &&
                          handleOrderBy({
                            orderByKey: column.orderByKey,
                            isContactList: false,
                          });
                      }}
                    >
                      <span>{column.render('Header')}</span>
                      {column.render('Header') && !column?.noneOrder && (
                        <Image
                          srcImage={arrowDown}
                          className={`ml-4 ${
                            !orderBy[column.orderByKey] &&
                            data?.length &&
                            'order-arrow cursor-pointer'
                          }`}
                          altImage="arrow"
                          titleImage="arrow"
                        />
                      )}
                    </StyledTableTitle>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="body">
            {_.map(rows, (row, idx) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className="tr" key={`row-${idx}`}>
                  {_.map(row.cells, (cell, idx) => (
                    <div
                      {...cell.getCellProps()}
                      className={`td`}
                      key={`row-cells-${idx}`}
                    >
                      {cell.render('Cell')}
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
        {!rows.length ? (
          <CardEmptyState
            className="w-8/12 mx-auto mt-16"
            title={
              isFilterEmpty
                ? 'No hay resultados, utiliza nuevos criterios de búsqueda'
                : 'No se han encontrado contactos dentro de la lista de contactos'
            }
          />
        ) : null}
      </StyledContentTableStiky>

      <DefaultModal
        isActive={activeModalDelete.active}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={() => {
          setActiveModalDelete({ active: false });
        }}
      >
        <TypographyComponent
          text="Eliminar contacto de la lista"
          variant="h3"
          className="font-bold text-primary mb-1"
        />
        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto lg:mx-2 "
            mode="outline"
            onPress={() => {
              setActiveModalDelete({ active: false });
            }}
          />
          <ButtonComponent
            variant="primary"
            text="Aceptar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={() => {
              setActiveModalDelete({ active: false });
              deleteContact(contactList.id, activeModalDelete.user);
            }}
          />
        </StyledContentButtonsModal>
      </DefaultModal>
    </StyledWrapperContactTable>
  );
};
ListDetail.propTypes = {
  contactList: PropTypes.object,
  setActiveTab: PropTypes.func,
};
export default ListDetail;
