const useAdminDevelopmentInterventionKnowProjectTypes = () => {
  const KNOW_PROJECT_INTERVENTION = 'KNOW_PROJECT_INTERVENTION';
  const CREATE_KNOW_PROJECT_INTERVENTION = 'CREATE_KNOW_PROJECT_INTERVENTION';
  const KNOW_PROJECT_STATE_INTERVENTION = 'KNOW_PROJECT_STATE_INTERVENTION';
  return {
    KNOW_PROJECT_INTERVENTION,
    CREATE_KNOW_PROJECT_INTERVENTION,
    KNOW_PROJECT_STATE_INTERVENTION,
  };
};

export default useAdminDevelopmentInterventionKnowProjectTypes;
