//Hooks
import useHelpers from '../../../../../helpers';

const useAdminCommunitiesSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const communitiesListSelector = createSelector(
    (state) => state.communitiesList,
    (communitiesList) => communitiesList
  );

  const communitySelectedSelector = createSelector(
    (state) => state.communitySelected,
    (communitySelected) => communitySelected
  );

  return { communitiesListSelector, communitySelectedSelector };
};

export default useAdminCommunitiesSelectors;
