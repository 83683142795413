//Packages
import React, { Suspense, useState } from 'react';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';

// Components
import All from './components/All';

const Artists = (props) => {
  const { setSelectedArtist, handleCloseModal, artistSelectedByDefault } =
    props;

  const { useComponents } = useViews();
  const { useTabs, useLoaders } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { Tabs, TabLink } = useTabs();
  const [tabValue, setTabValue] = useState(0);

  return (
    <Suspense fallback={<DefaultLoader />}>
      <Tabs
        selected={tabValue}
        onSelect={setTabValue}
        className="w-full lg:w-9/12 mb-5 mt-6 lg:mb-0 whitespace-nowrap overflow-auto lg:whitespace-normal"
      >
        <TabLink label="Todos los artistas" tab={0} />
        <TabLink label="Favoritos" tab={1} />
      </Tabs>

      <All
        setSelectedArtist={setSelectedArtist}
        handleCloseModal={handleCloseModal}
        artistSelectedByDefault={artistSelectedByDefault}
        favoritesTab={tabValue === 1}
      />
    </Suspense>
  );
};

Artists.propTypes = {
  setSelectedArtist: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  artistSelectedByDefault: PropTypes.number,
};

Artists.defaultProps = {
  artistSelectedByDefault: 0,
};

export default Artists;
