//Packages
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

//Hooks
import useControllers from '../../..';
import useApi from '../../../../api';
import useModels from '../../../../models';
import useHelpers from '../../../../helpers';

//Assets
import imageFakeCard from '../../../../assets/img/fake-card-example.jpg';

const useDashboardTabInterventions = ({ tabValue, textSearchValue }) => {
  const history = useHistory();
  const [currentPageSearch, setCurrentPageSearch] = useState(1);

  const { useGeneralHooks } = useControllers();
  const { useGetUserProfile } = useGeneralHooks();
  const {
    role: { id: roleId },
  } = useGetUserProfile();

  const { useActions } = useApi();
  const { dispatch, useDashboardActions } = useActions();
  const {
    actGetInterventionsAsArtist,
    actSetCurrentPageInterventionsAsArtist,
  } = useDashboardActions();

  const { useSelectors } = useModels();
  const { useSelector, useDashboardSelectors } = useSelectors();
  const { artistInterventionsSelector } = useDashboardSelectors();
  const {
    artistInterventions: interventionsList,
    meta: metaArtist,
    links: linksArtist,
    page: currentPage,
  } = useSelector(artistInterventionsSelector);

  const { useQuickFunctions } = useHelpers();
  const { showCityDepartmentAndCountry } = useQuickFunctions();

  useEffect(() => {
    if (tabValue === 0 && roleId === 'ar') {
      if (textSearchValue) {
        dispatch(
          actGetInterventionsAsArtist({
            paginate: 1,
            page: currentPageSearch,
            name: textSearchValue,
          })
        );
      } else {
        dispatch(
          actGetInterventionsAsArtist({
            paginate: 1,
            page: currentPage,
          })
        );
        setCurrentPageSearch(1);
      }
    }
  }, [tabValue, textSearchValue]);

  const handleGoToDetailIntervention = ({ projectId, interventionId }) => {
    history.push(
      `/admin/projects/dashboard/${projectId}/interventions/${interventionId}`
    );
  };

  const handleChangePage = ({ selected }) => {
    dispatch(
      actGetInterventionsAsArtist({
        paginate: 1,
        page: selected + 1,
        name: textSearchValue,
      })
    );
    if (textSearchValue) {
      setCurrentPageSearch(selected + 1);
    } else {
      dispatch(
        actSetCurrentPageInterventionsAsArtist({
          page: selected + 1,
        })
      );
      setCurrentPageSearch(1);
    }
  };

  return {
    handleGoToDetailIntervention,
    interventionsList: interventionsList.filter((i) => Boolean(i.project)),
    imageFakeCard,
    showCityDepartmentAndCountry,
    metaArtist,
    linksArtist,
    handleChangePage,
    currentPage,
    currentPageSearch,
  };
};

export default useDashboardTabInterventions;
