import _ from 'lodash';
import { useForm } from 'react-hook-form';

const useFilter = ({ yupSchema, propsForm }) => {
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = useForm({
    ...propsForm,
    resolver: yupSchema,
    mode: 'onChange',
  });

  const transformData = (data) => {
    const dataObjectValues = Object.values(data);
    const dataObjectKeys = Object.keys(data);
    const dataAux = _.reduce(
      dataObjectValues,
      (prevValue, currentValue, index) => {
        if (currentValue) {
          return {
            ...prevValue,
            [dataObjectKeys[index]]:
              typeof currentValue === 'object'
                ? currentValue?.value
                : currentValue,
          };
        }
        return prevValue;
      },
      {
        country_id: null,
        department_id: null,
        city_id: null,
      }
    );

    return dataAux;
  };

  const handleCleanFilter = () => {
    reset({});
  };

  return {
    //rhf
    control,
    watch,
    setValue,
    handleSubmit,
    dirtyFields,
    reset,

    //methods
    handleCleanFilter,
    transformData,
  };
};

export default useFilter;
