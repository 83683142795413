import React, { lazy } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

//Hooks
import useViews from 'views';

// Components
import Activities from './components/Activities';
import Means from './components/Means';

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

const StyledContentButtons = lazy(() =>
  import(
    '../../../../../../../../../../../../../styles/tableViews/tableViews.styles'
  ).then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const Sessions = (props) => {
  const { activities } = props;
  const { useComponents } = useViews();

  const { useTypographies } = useComponents();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();

  return (
    <>
      <TypographyWithTooltip
        text="Intervenciones*"
        variant="h3"
        textTooltip="Intervenciones"
        className="text-primary mt-4"
        idTooltip="aspects_project"
        applyFontBold
      />

      <TypographyComponent
        text="Dale un nombre creativo a cada una de las intervenciones. y adjunta una descripción detallada de las actividades y los medios logísticos necesarios para llevar a cabo cada una de tus intervenciones"
        variant="p"
        className="text-neutral-950 mt-4 mb-6"
      />

      <Accordion allowZeroExpanded>
        {activities.length > 0 &&
          _.map(activities, (activity) => {
            return (
              <div className="rounded mt-2" key={`activity-${activity.id}`}>
                <AccordionItem>
                  <div className="flex items-center justify-between bg-white py-2 px-6">
                    <TypographyComponent
                      variant="p"
                      text={activity.name}
                      className="flex-grow text-primary font-bold"
                    />
                    <StyledContentButtons>
                      <AccordionItemState>
                        {({ expanded }) =>
                          expanded ? (
                            <AccordionItemButton>
                              <div className="icon-arrow-down text-xxs text-neutral-900 transform rotate-180" />
                            </AccordionItemButton>
                          ) : (
                            <AccordionItemButton>
                              <div className="icon-arrow-down text-xxs text-neutral-900" />
                            </AccordionItemButton>
                          )
                        }
                      </AccordionItemState>
                    </StyledContentButtons>
                  </div>
                  <AccordionItemPanel className="py-4 px-6 border rounded">
                    <TypographyWithTooltip
                      text="Actividades de la sesión*"
                      variant="h3"
                      textTooltip="Sesiones de la intervención"
                      className="text-primary mt-4"
                      idTooltip="aspects_project"
                      applyFontBold
                    />

                    <TypographyComponent
                      text='Revisa cada una de las actividades propuestas para asegurarte que corresponda al plan creativo. En caso de ser necesario, es importante mandar ajustes o comentarios. Para lo anterior, dale click a "rechazar" para tener la opción de escribir tus recomendaciones.'
                      variant="p"
                      className="text-neutral-950 mt-4 mb-6"
                    />
                    <Activities activity={activity.activities} />

                    <TypographyWithTooltip
                      text="Medios logísticos de la sesión"
                      variant="h3"
                      textTooltip="Medios logísticos de la sesión"
                      className="text-primary mt-6"
                      idTooltip="aspects_project"
                      applyFontBold
                    />

                    <TypographyComponent
                      text="Asegúrate que los medios logísticos propuestos conectan con las actividades detalladas anteriormente. Si tienes comentarios o ajustes, agrégalo(s) a la lista de recomendación(es) del artista al rechazar la actividad. Si todo esta bien, puedes aceptar para seguir adelante."
                      variant="p"
                      className="text-neutral-950 mt-4 mb-6"
                    />

                    <Means resources={activity.resources} />
                  </AccordionItemPanel>
                </AccordionItem>
              </div>
            );
          })}
      </Accordion>
    </>
  );
};

Sessions.propTypes = {
  activities: PropTypes.array,
};

export default Sessions;
