//Packages
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';

//Hooks
import useApi from '../../../../../../../api';
import useStrings from '../../../../../../../strings';
import useHelpers from '../../../../../../../helpers';
import useModels from '../../../../../../../models';
import useControllers from '../../../../../..';

const useAdminCreativePlanIntervention = () => {
  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();
  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const { useAdminDevelopmentInterventionCreativePlanActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const {
    actUpdateCreativePlanIntervention,
    actGetCreativePlanIntervention,
    actDownloadFileCreativePlanIntervention,
  } = useAdminDevelopmentInterventionCreativePlanActions();

  const { useGeneralHooks } = useControllers();
  const { useGetDate } = useGeneralHooks();
  const { setTextExpiredDate } = useGetDate();

  const { intervention_id } = useParams();

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { REQUIRED_FIELD, DATE_GREATER_THAN_TODAY, FILE_SIZE_20MB } =
    useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const {
    isSelectedDateGreaterThanTodayDate,
    downloadFileFromBackend,
    getYearMonthDayFromDate,
    getFileValidations,
  } = useQuickFunctions();

  const { MAX_SIZE_20MB } = getFileValidations();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getProjectInterventionCreativePlanSelector } =
    useAdminInterventionsSelectors();
  const planCreative = useSelector(getProjectInterventionCreativePlanSelector);

  useEffect(() => {
    if (planCreative?.state?.id !== 'c') {
      handleGetCreativePlanIntervention();
    }
  }, [planCreative?.state?.id]);

  const textForLimitDate = useMemo(() => {
    if (planCreative?.date_limit) {
      const { isExpired, text } = setTextExpiredDate({
        expiredDate: planCreative?.date_limit,
      });

      return {
        text,
        colorBadge: isExpired ? 'error' : 'warning',
      };
    }

    return {
      text: 'No hay fecha limite',
      colorBadge: 'warning',
    };
  }, [planCreative.date_limit]);

  const schemaCreateCreativePlanIntervention = yup.object({
    file: yup
      .mixed()
      .required(REQUIRED_FIELD)
      .test('fileSize', FILE_SIZE_20MB, (value) => {
        if (value) {
          return value.size <= MAX_SIZE_20MB;
        }
        return true;
      }),
    date_limit_checkbox: yup.boolean(),
    date_limit: yup.date().when('date_limit_checkbox', {
      is: true,
      then: yup
        .date()
        .required(REQUIRED_FIELD)
        .test('dateValidation', DATE_GREATER_THAN_TODAY, (value) => {
          return value
            ? isSelectedDateGreaterThanTodayDate({ selectedDate: value })
            : true;
        }),
      otherwise: yup
        .date()
        .nullable()
        .default(null)
        .transform((curr, orig) => (orig === '' ? null : curr)),
    }),
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    resolver: yupResolver(schemaCreateCreativePlanIntervention),
    mode: 'onChange',
  });

  const handleGetCreativePlanIntervention = () => {
    dispatch(actGetCreativePlanIntervention({ intervention_id }));
  };

  const createCreativePlan = (dataForm) => {
    const data = new FormData();
    data.append('file', dataForm.file);
    data.append('state', 'ip');
    data.append('_method', 'PATCH');
    if (dataForm.date_limit && dataForm.date_limit_checkbox) {
      data.append(
        'date_limit',
        getYearMonthDayFromDate({
          dateInString: dataForm.date_limit,
          format: 'ymd',
        })
      );
    }
    dispatch(
      actUpdateCreativePlanIntervention({
        intervention_id,
        creative_plan_id: planCreative.id,
        data,
      })
    );
  };

  const updateCreativePlan = () => {
    const data = new FormData();
    data.append('state', 'c');
    data.append('_method', 'PATCH');
    dispatch(
      actUpdateCreativePlanIntervention({
        intervention_id,
        creative_plan_id: planCreative.id,
        data,
      })
    );
  };

  const dowloadCreativePlanFile = () => {
    dispatch(
      actDownloadFileCreativePlanIntervention(
        {
          intervention_id,
          creative_plan_id: planCreative.id,
          file: planCreative.file.id,
        },
        (response) => {
          downloadFileFromBackend({
            response,
            nameFile: `PlanCreativo-${planCreative?.file?.url}`,
          });
        }
      )
    );
  };

  const preViewPDFCreativePlan = () => {
    window.open(planCreative.file.url, '_blank');
  };

  return {
    control,
    createCreativePlan,
    register,
    errors,
    planCreative,
    dowloadCreativePlanFile,
    preViewPDFCreativePlan,
    updateCreativePlan,
    isValid,
    watch,
    handleSubmit,
    textForLimitDate,
  };
};
export default useAdminCreativePlanIntervention;
