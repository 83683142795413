import React, { lazy, useEffect } from 'react';

//Hooks
import useViews from 'views';
import useModels from 'models';
import useApi from 'api';
import useUsersMessagesScreens from './components/Messages';
import useUsersDraftsScreens from './components/Drafts';

//Styles
const StyledBoxGray = lazy(() =>
  import('styles/boxes/boxes.styles').then((mod) => ({
    default: mod.StyledBoxGray,
  }))
);
const StyledBoxContent = lazy(() =>
  import('styles/boxes/boxes.styles').then((mod) => ({
    default: mod.StyledBoxContent,
  }))
);

const StyledBodyTab = lazy(() =>
  import('styles/tabs/tabs.styles').then((mod) => ({
    default: mod.StyledBodyTab,
  }))
);

const ComunicationArtist = () => {
  const { useComponents } = useViews();

  const { useTabs } = useComponents();

  const { Tabs, TabLink } = useTabs();

  const { useActions } = useApi();
  const { dispatch, useGeneralActions } = useActions();
  const { useTabSelectedActions } = useGeneralActions();
  const { actResetSubTabSelected, actSetSubTabSelected } =
    useTabSelectedActions();

  const { useSelectors } = useModels();
  const { useSelector, useGeneralSelectors } = useSelectors();
  const { useTabSelectedSelectors } = useGeneralSelectors();
  const { subTabSelectedSelector } = useTabSelectedSelectors();
  const subTabValue = useSelector(subTabSelectedSelector);

  const handleSelectTab = (tabIdx) =>
    dispatch(actSetSubTabSelected({ tabValue: tabIdx }));

  const { Messages } = useUsersMessagesScreens();
  const { Drafts } = useUsersDraftsScreens();

  useEffect(() => {
    return () => dispatch(actResetSubTabSelected());
  }, []);

  return (
    <StyledBoxContent className="w-full bg-neutral-200 rounded-lg flex">
      <StyledBoxGray className="w-3/12 border-r">
        <Tabs
          selected={subTabValue}
          onSelect={handleSelectTab}
          className="w-full mb-5 mt-6 lg:mb-0 whitespace-nowrap overflow-auto lg:whitespace-normal flex-col"
          variant="vertical"
        >
          <TabLink
            label={
              <div className="flex items-center">
                <div className="icon-forward-inbox text-lg mr-1"/>
                Solicitudes
              </div>
            }
            variant="vertical"
            tab={0}
          />
          <TabLink
            label={
              <div className="flex items-center">
                <div className="icon-drafts text-lg mr-1"/>Borradores
              </div>
            }
            variant="vertical"
            tab={1}
          />
        </Tabs>
      </StyledBoxGray>
      <div className="w-9/12 px-6 py-6">
        {subTabValue === 0 && (
          <StyledBodyTab active={subTabValue === 0}>
            <Messages />
          </StyledBodyTab>
        )}
        {subTabValue === 1 && (
          <StyledBodyTab active={subTabValue === 1}>
            <Drafts />
          </StyledBodyTab>
        )}
      </div>
    </StyledBoxContent>
  );
};

export default ComunicationArtist;
