import useProjectsAdminScreens from './Projects';
import useUsersAdminScreens from './Users';
import useContactsScreen from './Contacts';
import useLabelsAdminScreens from './Labels';
import useInterventionsAdminScreens from './Interventions';

import useAdminHomeScreens from './Home';

const useAdminScreens = () => {
  return {
    useUsersAdminScreens,
    useProjectsAdminScreens,
    useContactsScreen,
    useLabelsAdminScreens,
    useAdminHomeScreens,
    useInterventionsAdminScreens,
  };
};

export default useAdminScreens;
