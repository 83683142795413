import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Controller } from 'react-hook-form';

// Hooks
import useViews from 'views';
import useControllers from 'controllers';

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import { StyledGroup } from 'styles/formViews/formViews.styles';

// Assets
import plusIcon from 'assets/icons/plus.svg';
import IconAlarm from 'assets/img/alarm.svg';

// Styles
import {
  StyledContentButtons,
  StyledContentButtonsModal,
} from 'styles/tableViews/tableViews.styles';
import {
  StyledWrapperCommunity,
  StyledCardCommunity,
} from '../../../../../../../../Dashboard.styles';

const Activities = (props) => {
  const { deepeingModality, deepenType, activities, setActivities } = props;

  const { useComponents } = useViews();
  const { useTypographies, useButtons, useImages, useModals, useInputs } =
    useComponents();
  const { DefaultModal } = useModals();
  const { TypographyComponent } = useTypographies();
  const { ButtonComponent } = useButtons();
  const { Image } = useImages();
  const { InputForm, TextArea, Select, InputHoursMinutes } = useInputs();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useDeepenKnowledgeCommunity } =
    useAdminInterventionsDashboardControllers();
  const { useAdminDeepenKnowledgeActivityCommunityInterventions } =
    useDeepenKnowledgeCommunity();
  const {
    typeFormActive,
    handleAddActivity,
    formActivity: {
      control,
      register,
      formState: { errors },
      handleSubmit,
    },
    handleDeleteActivity,
    handleChangeTypeForm,
    editActivity,
    communitiesOption,
    minDateToSelect,
    showModal,
    saveActivities,
    setShowModal,
    closeForm,
    editButton,
  } = useAdminDeepenKnowledgeActivityCommunityInterventions({
    activities,
    setActivities,
    deepeingModality,
    deepenType,
  });

  return (
    <>
      {(deepenType === 'sync' || deepeingModality !== 'r') && (
        <>
          <TypographyComponent
            text="Actividades"
            variant="h2"
            className="text-primary mt-4 font-bold"
          />
          <TypographyComponent
            text="Llena los siguientes campos con los detalles necesarios para completar tus actividades de profundización con la comunidad."
            variant="p"
            className="text-neutral-950 mt-2 mb-4"
          />

          <StyledWrapperCommunity
            className={
              activities.length !== 0
                ? 'visible opacity-100 h-auto'
                : 'invisible opacity-0 transition-all duration-100 h-0'
            }
          >
            <Accordion allowZeroExpanded>
              {activities.length > 0 &&
                _.map(activities, (activity, index) => {
                  return (
                    <AccordionItem
                      key={`message${index}`}
                      className="bg-white"
                      uuid={`message${index}`}
                      dangerouslySetExpanded={activity.expanded}
                    >
                      {!editButton && (
                        <StyledCardCommunity key={`message-${index}`}>
                          <TypographyComponent
                            variant="p"
                            text={activity?.name || ''}
                            className="flex-grow text-primary font-bold"
                          />
                          <StyledContentButtons>
                            <AccordionItemState>
                              {({ expanded }) =>
                                expanded ? (
                                  ''
                                ) : (
                                  <AccordionItemButton>
                                    <ButtonComponent
                                      text=""
                                      className="icon-edit text-primary-100"
                                      variant="white"
                                      onPress={() => {
                                        editActivity(index);
                                      }}
                                    />
                                  </AccordionItemButton>
                                )
                              }
                            </AccordionItemState>
                            <ButtonComponent
                              text=""
                              className="icon-delete"
                              onPress={(e) => {
                                setShowModal({ show: true, e, index });
                              }}
                            />
                          </StyledContentButtons>
                        </StyledCardCommunity>
                      )}
                      <AccordionItemPanel>
                        <div className="border rounded py-8 px-6 mt-4">
                          <form className="px-4">
                            {deepeingModality === 'h' && (
                              <StyledGroup col2 flex className="mb-4 flex-col">
                                <Select
                                  name="modality_type"
                                  id="modality_type"
                                  placeholder="Tipo de profundización* "
                                  control={control}
                                  options={[
                                    {
                                      label: 'Presencial',
                                      value: 'f',
                                    },
                                    {
                                      label: 'Remota',
                                      value: 'r',
                                    },
                                  ]}
                                  onChange={handleChangeTypeForm}
                                  errors={errors}
                                  label="Modalidad de la actividad* "
                                />
                              </StyledGroup>
                            )}

                            <StyledGroup col2 flex className="mb-4 flex-col">
                              <InputForm
                                name="name"
                                label="Nombre de la actividad*"
                                placeholder="Ingresa el nombre de la actividad"
                                className="w-full"
                                errors={errors}
                                control={control}
                                autoComplete="off"
                              />
                            </StyledGroup>

                            <StyledGroup className="mb-4" noSpace>
                              <Controller
                                control={control}
                                name="date"
                                render={({ field: { name, onChange } }) => {
                                  return (
                                    <InputForm
                                      name={name}
                                      label="Fecha y Hora*"
                                      type="datetime-local"
                                      placeholder="Seleccione la fecha y la hora"
                                      className="w-full"
                                      errors={errors}
                                      min={minDateToSelect}
                                      onChange={onChange}
                                    />
                                  );
                                }}
                              />
                              <Controller
                                name={'duration'}
                                control={control}
                                render={({ field: { onChange, name } }) => (
                                  <InputHoursMinutes
                                    name={name}
                                    label="Duracion*"
                                    type="time"
                                    placeholder="hh:mm"
                                    className="w-full"
                                    errors={errors}
                                    onChange={onChange}
                                  />
                                )}
                              />
                            </StyledGroup>

                            <StyledGroup col2 flex className="mb-4 flex-col">
                              {typeFormActive === 'f' ||
                              deepeingModality === 'f' ? (
                                <Select
                                  name="community_place_id"
                                  id="community_place_id"
                                  placeholder="Selecciona una comunidad "
                                  control={control}
                                  options={communitiesOption}
                                  errors={errors}
                                  label="Comunidad donde se realizará la actividad*"
                                />
                              ) : (
                                (typeFormActive === 'r' ||
                                  deepeingModality === 'r') && (
                                  <InputForm
                                    name="platform"
                                    label="Plataforma*"
                                    type="text"
                                    icon={IconAlarm}
                                    placeholder="Ej. Google meet"
                                    className="w-full"
                                    errors={errors}
                                    {...register('platform')}
                                  />
                                )
                              )}
                            </StyledGroup>

                            <StyledGroup className="mb-4" noSpace>
                              <TextArea
                                control={control}
                                name="description"
                                placeholder="Describe la actividad..."
                                className="w-full "
                                label=" Descripción de la actividad* "
                                errors={errors}
                              />
                              <TextArea
                                control={control}
                                name="resources"
                                placeholder="Define los recursos requeridos..."
                                className="w-full "
                                label="Recursos requeridos*"
                                errors={errors}
                              />
                            </StyledGroup>
                            <StyledGroup col2 className="mb-4">
                              <TextArea
                                control={control}
                                name="community_support"
                                placeholder="Describe el apoyo que requieres..."
                                className="w-full col-span-2"
                                label="¿Necesito del apoyo de una o más personas de la comunidad?*"
                                errors={errors}
                              />
                            </StyledGroup>
                            <div className="flex items-center justify-end space-x-4">
                              <ButtonComponent
                                mode="outline"
                                text="Cancelar"
                                onPress={(e) => closeForm(index, e)}
                              />
                              <ButtonComponent
                                text="Guardar actividad"
                                onPress={handleSubmit((data) => {
                                  saveActivities(data, index);
                                })}
                              />
                            </div>
                          </form>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  );
                })}
            </Accordion>
          </StyledWrapperCommunity>

          {/*{open && <div className="border rounded py-8 px-6 mt-4">{children}</div>}*/}
          {!editButton && activities.length < 5 && (
            <ButtonComponent
              text="Crear nueva actividad"
              mode="outline"
              className="mt-4 border-none shadow-none"
              icon={
                <Image
                  srcImage={plusIcon}
                  altImage="icon-plus"
                  titleImage="icon-plus"
                  className="w-6 mr-2"
                />
              }
              onPress={handleAddActivity}
            />
          )}
          <DefaultModal
            isActive={showModal.show}
            classNameContent="max-w-screen-md w-11/12"
            handleClose={() => {
              setShowModal({ show: false });
            }}
          >
            <TypographyComponent
              text={'Eliminar Actividad'}
              variant="h3"
              className="font-bold text-primary mb-1"
            />
            <StyledContentButtonsModal>
              <ButtonComponent
                variant="primary"
                text="Cancelar"
                className="mx-auto w-full lg:w-auto lg:mx-2 "
                mode="outline"
                onPress={() => {
                  setShowModal({ show: false });
                }}
              />
              <ButtonComponent
                variant="primary"
                text="Aceptar"
                className="mx-auto w-full lg:w-auto  lg:mx-2"
                onPress={() => {
                  handleDeleteActivity(showModal.e, showModal.index);
                  setShowModal({ show: false });
                }}
              />
            </StyledContentButtonsModal>
          </DefaultModal>
        </>
      )}
    </>
  );
};

Activities.propTypes = {
  deepeingModality: PropTypes.string.isRequired,
  deepenType: PropTypes.string.isRequired,
  activities: PropTypes.array.isRequired,
  setActivities: PropTypes.func.isRequired,
};

export default Activities;
