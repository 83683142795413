import React, { lazy } from 'react';
import PropTypes from 'prop-types';

const StyledCardNotitication = lazy(() =>
  import('./CardNotification.styles').then((mod) => ({
    default: mod.StyledCardNotitication,
  }))
);

const CardNotification = (props) => {
  const { title, content, className } = props;
  return (
    <StyledCardNotitication className={className}>
      {title}
      {content && content}
    </StyledCardNotitication>
  );
};

CardNotification.propTypes = {
  className: PropTypes.string,
  title: PropTypes.element,
  content: PropTypes.element,
};

export default CardNotification;
