//Hooks
import useControllers from 'controllers';
import useStrings from 'strings';
import useApi from 'api';

const useAdminSpreadInterventionActions = () => {
  const { useProviders } = useApi();
  const { useAdminProviders } = useProviders();
  const { useAdminProjectsProviders } = useAdminProviders();
  const { useAdminInterventionsProviders } = useAdminProjectsProviders();
  const { useAdminViewInterventionProviders } =
    useAdminInterventionsProviders();
  const { useAdminViewInterventionDevelopmentInterventionProviders } =
    useAdminViewInterventionProviders();
  const { useAdminDevelopmentSpreadInterventionProviders } =
    useAdminViewInterventionDevelopmentInterventionProviders();
  const {
    getSpreadInterventionProvider,
    updateSpreadInterventionProvider,
    uploadFileSpreadInterventionProvider,
    deleteFileSpreadInterventionProvider,
  } = useAdminDevelopmentSpreadInterventionProviders();

  const { useAdminTypes } = useStrings();
  const { useAdminProjectsTypes } = useAdminTypes();
  const { useAdminInterventionsTypes } = useAdminProjectsTypes();
  const { useAdminViewInterventionsTypes } = useAdminInterventionsTypes();
  const { useAdminViewInterventionsDevelopmentInterventionTypes } =
    useAdminViewInterventionsTypes();
  const { useAdminSpreadInterventionTypes } =
    useAdminViewInterventionsDevelopmentInterventionTypes();
  const {
    GET_SPREAD_INTERVENTION,
    UPDATE_SPREAD_INTERVENTION,
    UPLOAD_FILE_SPREAD_INTERVENTION,
    DELETE_FILE_SPREAD_INTERVENTION,
  } = useAdminSpreadInterventionTypes();

  const { useGeneralHooks } = useControllers();
  const { useConsole } = useGeneralHooks();
  const { viewConsole } = useConsole();

  const actGetSpreadIntervention =
    ({ interventionId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await getSpreadInterventionProvider({
          interventionId,
        });
        dispatch({
          type: GET_SPREAD_INTERVENTION,
          payload: res.data,
        });
        onSuccess && onSuccess(res);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actUpdateSpreadIntervention =
    ({ interventionId, spreadId, data }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await updateSpreadInterventionProvider({
          interventionId,
          spreadId,
          data,
        });
        dispatch({
          type: UPDATE_SPREAD_INTERVENTION,
          payload: res.data,
        });
        onSuccess && onSuccess(res);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actUploadFileSpreadIntervention =
    ({ interventionId, spreadId, data }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await uploadFileSpreadInterventionProvider({
          interventionId,
          spreadId,
          data,
        });
        dispatch({
          type: UPLOAD_FILE_SPREAD_INTERVENTION,
          payload: res.data,
        });
        onSuccess && onSuccess(res.data);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actDeleteFileSpreadIntervention =
    ({ interventionId, spreadId, fileId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await deleteFileSpreadInterventionProvider({
          interventionId,
          spreadId,
          fileId,
        });
        dispatch({
          type: DELETE_FILE_SPREAD_INTERVENTION,
          payload: fileId,
        });
        onSuccess && onSuccess(res.data);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  return {
    actGetSpreadIntervention,
    actUpdateSpreadIntervention,
    actUploadFileSpreadIntervention,
    actDeleteFileSpreadIntervention,
  };
};

export default useAdminSpreadInterventionActions;
