import useAdminProjectsTypes from './projects';
import useAdminUsersTypes from './users';
import useContactListTypes from './contactList';
import useAdminLabelsTypes from './labels';
import useAdminHomeType from './home';
import useAdminContentInterventionsTypes from './interventions';

const useAdminTypes = () => {
  return {
    useAdminUsersTypes,
    useAdminProjectsTypes,
    useContactListTypes,
    useAdminLabelsTypes,
    useAdminHomeType,
    useAdminContentInterventionsTypes,
  };
};

export default useAdminTypes;
