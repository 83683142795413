//Hooks
import useHelpers from 'helpers';

const useAdminContentInterventionsSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const getFilterAdminHomeInterventionsSelector = createSelector(
    (state) => state.filterAdminInterventions,
    (filterAdminInterventions) => filterAdminInterventions
  );

  return { getFilterAdminHomeInterventionsSelector };
};

export default useAdminContentInterventionsSelectors;
