import ArtistAsingPlan from './AsingPlan';
import ArtistKnowProject from './KnowProject';
import ArtistKnowCommunities from './KnowCommunities';
import InterventionDefine from './InterventionDefine';
import FinishInterventionArtist from './FinishIntervention';

//Hooks
import useStepsSketchInterventionArtist from './SketchIntervention';

const useStepperArtist = () => {
  const {
    PilotEjecutionArtist,
    PilotEvaluateArtist,
    PilotInterventionArtist,
    SketchInterventionArtist,
  } = useStepsSketchInterventionArtist();
  return {
    ArtistAsingPlan,
    ArtistKnowProject,
    ArtistKnowCommunities,
    InterventionDefine,
    FinishInterventionArtist,
    //SketchIntervention
    PilotEjecutionArtist,
    PilotEvaluateArtist,
    PilotInterventionArtist,
    SketchInterventionArtist,
  };
};

export default useStepperArtist;
