import React from 'react';
import PropTypes from 'prop-types';

// Styles
import { StyledButtonActivity, StyledIcon } from './ButtonAddActivity.styles';

const ButtonAddActivity = (props) => {
  const { text, icon, size, onPress, className, open } = props;

  return (
    <StyledButtonActivity
      className={className}
      onClick={onPress}
      text={text}
      icon={icon}
      size={size}
      open={open}
    >
      {icon && <StyledIcon>{icon}</StyledIcon>}
      {text && text}
    </StyledButtonActivity>
  );
};

ButtonAddActivity.defaultProps = {
  size: 'default',
  className: 'StyledButtonActivity',
  open: true,
};

ButtonAddActivity.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.element,
  onPress: PropTypes.func.isRequired,
  className: PropTypes.string,
  size: PropTypes.string,
  open: PropTypes.bool,
};
export default ButtonAddActivity;
