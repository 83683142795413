import useProviders from '../../../providers';
import useControllers from '../../../../controllers';

const useHomeInterventionsDetailActions = () => {
  const { useAdminProviders } = useProviders();
  const { useInterventionsDetailHomeProvider } = useAdminProviders();
  const { getInterventionById } = useInterventionsDetailHomeProvider();

  const { useGeneralHooks } = useControllers();
  const { useConsole } = useGeneralHooks();
  const { viewConsole } = useConsole();

  const actGetIntervention =
    ({ intervention_id }, onSuccess, onError) =>
    async () => {
      try {
        const response = await getInterventionById({
          intervention_id,
        });

        onSuccess && onSuccess(response);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  return { actGetIntervention };
};

export default useHomeInterventionsDetailActions;
