import useAdminProjectsActions from './projects';
import useAdminUsersActions from './users';
import useAdminLabelsActions from './labels';
import useContactListActions from './contactList';
import useHomeActions from './home';
import useAdminContentInterventionsActions from './interventions';
import useHomeInterventionsDetailActions from './InterventionsDetail';

const useAdminActions = () => {
  return {
    useAdminUsersActions,
    useAdminProjectsActions,
    useContactListActions,
    useAdminLabelsActions,
    useHomeActions,
    useAdminContentInterventionsActions,
    useHomeInterventionsDetailActions,
  };
};

export default useAdminActions;
