//Packages
import React from 'react';
import { Controller } from 'react-hook-form';

//Hooks
import useViews from '../../../../../../../../../..';
import useControllers from '../../../../../../../../../../../controllers';

const KnowProject = () => {
  const { useComponents } = useViews();
  const { useTypographies, useInputs, useButtons, useCards } = useComponents();
  const { TypographyComponent } = useTypographies();
  const { CheckBox, InputForm } = useInputs();
  const { ButtonComponent } = useButtons();
  const { CardNotification } = useCards();
  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminKnowProjectIntervention } =
    useAdminInterventionsDashboardControllers();
  const {
    know_project,
    control,
    register,
    handleSubmit,
    watch,
    errors,
    isValid,
    createKnowProject,
  } = useAdminKnowProjectIntervention();

  return (
    <>
      {(know_project?.state?.id === 'c' ||
        know_project?.state?.id === 'ip') && (
        <CardNotification
          className="w-5/12 mx-auto py-9 px-4 mt-16"
          title={
            <TypographyComponent
              text={know_project?.state?.name}
              variant="h2"
              className="font-bold text-primary icon-watch text-center"
            />
          }
        />
      )}
      <TypographyComponent
        text="Conocer proyecto"
        variant="h2"
        className="font-bold text-primary"
      />

      <TypographyComponent
        text="Al dar inicio a esta etapa, el artista tendrá acceso a toda la información que entraste cuando se creo el proyecto.        "
        variant="p"
        className="text-neutral-950 mt-2"
      />
      {know_project?.state?.id !== 'c' && know_project?.state?.id !== 'ip' && (
        <>
          <TypographyComponent
            text="Para asignar una fecha límite para la revisión de este documento por parte del artista has click en el recuadro inferior        "
            variant="p"
            className="text-neutral-950 mt-2"
          />
          <Controller
            control={control}
            name="date_limit_checkbox"
            render={({ field: { name, onChange } }) => {
              return (
                <CheckBox
                  name={name}
                  label="Asignar fecha limite para conocer el proyecto por parte del artista"
                  className="mx-auto mt-6"
                  onChange={onChange}
                  errors={errors}
                />
              );
            }}
          />
          {watch('date_limit_checkbox') && (
            <InputForm
              name="date_limit"
              label=""
              type="date"
              placeholder="Selecciona la fecha limite"
              className="mt-8 w-2/12"
              errors={errors}
              {...register('date_limit')}
            />
          )}
          <ButtonComponent
            text="Iniciar etapa"
            className="mx-auto mt-8"
            onPress={handleSubmit(createKnowProject)}
            disabled={!isValid}
          />
        </>
      )}
    </>
  );
};

export default KnowProject;
