// Packages
import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const StyledIcon = styled.div`
  ${tw` `}
`;

export const StyledButton = styled.button.attrs((props) => ({
  className: `
    ${
      props.mode !== 'outline'
        ? props.buttonTypeSecondary
          ? `bg-${props.variant}-100 text-${props.variant} border border-${
              props.variant
            }-200 hover:text-${props.variant}-contrast hover:bg-${
              props.variant
            } ${
              props.disabled &&
              'bg-neutral-300 text-neutral-700 hover:bg-neutral-300 border border-neutral-400'
            } `
          : `bg-${props.variant} text-primary-contrast hover:bg-${
              props.variant
            }-600 ${
              props.disabled &&
              'bg-neutral-300 text-neutral-700 hover:bg-neutral-300 border border-neutral-400'
            } `
        : `bg-transparent text-${props.variant} border-2 border-${
            props.variant
          } hover:bg-transparent focus:border ${
            props.disabled && 'border-neutral-700  text-neutral-700'
          }`
    }
    ${props.className} ${props.disabled && 'cursor-default'}`,
}))`
  ${tw`flex justify-center items-center font-bold px-6 py-3 rounded-lg text-base`}

  ${(props) => props.buttonTypeSecondary && tw`px-10 py-8`}


  box-shadow: 0px 2px 4px rgb(0 0 0 / 8%);

  ${(props) =>
    props.buttonTypeSecondary &&
    `box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);`}

  &:focus {
    outline: 3px solid
      var(
        --${(props) => {
            return props.variant;
          }}-100
      );
  }

  ${(props) => !props.text && props.icon && tw`px-3`}

  ${StyledIcon} {
    ${(props) => props.text && props.icon && tw`mr-2`}
    img {
      ${(props) => props.disabled && `filter: contrast(0.5);`}
    }
  }
`;
