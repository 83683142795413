//Packages
import React, { Suspense, lazy, useState } from 'react';

//Assets
import imgeFakeintervention from 'assets/img/fake-card-example.jpg';
import imagePin from 'assets/icons/MapPin.svg';

//Components
import DevelopmentIntervention from './components/DevelopmentIntervention';
import Comunication from './components/Comunication';
import DetailModal from './components/DetailModal';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';
import useHelpers from 'helpers';

//Styles
const StyledBackButton = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);

const StyledWrapperDataIntervention = lazy(() =>
  import('styles/interventions/interventions.styles').then((mod) => ({
    default: mod.StyledWrapperDataIntervention,
  }))
);

const StyledWrapperText = lazy(() =>
  import('styles/interventions/interventions.styles').then((mod) => ({
    default: mod.StyledWrapperText,
  }))
);

const StyledWarpperBadges = lazy(() =>
  import('styles/interventions/interventions.styles').then((mod) => ({
    default: mod.StyledWarpperBadges,
  }))
);
const StyledWarpperTitle = lazy(() =>
  import('styles/interventions/interventions.styles').then((mod) => ({
    default: mod.StyledWarpperTitle,
  }))
);
const StyledWrapperContent = lazy(() =>
  import('styles/interventions/interventions.styles').then((mod) => ({
    default: mod.StyledWrapperContent,
  }))
);

const StyledBodyTab = lazy(() =>
  import('styles/tabs/tabs.styles').then((mod) => ({
    default: mod.StyledBodyTab,
  }))
);

const AdminDashboardViewInterventions = () => {
  const { useComponents } = useViews();

  const {
    useImages,
    useTypographies,
    useBadges,
    useButtons,
    useTabs,
    useModals,
    useLoaders,
  } = useComponents();
  const { DefaultLoader } = useLoaders();

  const { Image } = useImages();
  const { TypographyComponent } = useTypographies();
  const { DefaultBadge } = useBadges();
  const { ButtonComponent } = useButtons();
  const { Tabs, TabLink } = useTabs();
  const { DefaultModal } = useModals();

  const { useGeneralHooks, useScreenHooks } = useControllers();
  const { useSelectLayoutByRole } = useGeneralHooks();
  const { LayoutSelected, props } = useSelectLayoutByRole();

  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsDevelopmentIntervention } =
    useAdminViewInterventionsControllers();
  const { useAdminViewInterventionsGeneralStepperAndTabsInterventions } =
    useAdminViewInterventionsDevelopmentIntervention();
  const { handleGoBack, dataIntervention, tabValue, handleSelectTab } =
    useAdminViewInterventionsGeneralStepperAndTabsInterventions();

  const [activeModal, setActiveModal] = useState({ active: false });

  const { useQuickFunctions } = useHelpers();
  const { showCityDepartmentAndCountry } = useQuickFunctions();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <LayoutSelected {...props}>
        <StyledBackButton className="mt-3" onClick={handleGoBack}>
          Regresar
        </StyledBackButton>

        <StyledWrapperDataIntervention>
          <Image
            srcImage={dataIntervention?.image?.url || imgeFakeintervention}
            altImage="intervention-name"
            titleImage="intervention-name"
            className="w-32 h-32 rounded-xl mr-6"
          />
          <StyledWrapperText>
            <StyledWarpperTitle>
              <TypographyComponent
                text={dataIntervention?.name}
                variant="h2"
                className="font-bold text-primary mr-4"
              />
              <ButtonComponent
                text="Ver detalle"
                className="leading-4 button-detail"
                mode="outline"
                onPress={() => {
                  setActiveModal({ active: true });
                }}
              />
            </StyledWarpperTitle>

            <TypographyComponent
              className="text-neutral-800 flex"
              variant="h5"
              text={
                <>
                  <Image
                    srcImage={imagePin}
                    altImage="location"
                    titleImage="location"
                    className="location-icon"
                  />
                  {showCityDepartmentAndCountry({
                    city: dataIntervention?.project?.city,
                    format: 'cityDepartmentCountry',
                  })}
                </>
              }
            />
            <TypographyComponent
              className="text-neutral-800 flex ml-1 mt-1"
              variant="h5"
              text={`Proyecto: ${dataIntervention?.project?.name}`}
            />
            <StyledWarpperBadges>
              <DefaultBadge
                type="light"
                variant="primary"
                label={`Modalidad: ${dataIntervention?.type?.name}`}
              />
              <DefaultBadge
                type="light"
                variant="primary"
                label={`Objetivo: ${dataIntervention?.objetive}`}
              />
            </StyledWarpperBadges>
          </StyledWrapperText>
        </StyledWrapperDataIntervention>

        <StyledWrapperContent>
          <Tabs
            selected={tabValue}
            onSelect={handleSelectTab}
            className="w-full lg:w-9/12 mb-5 mt-6 lg:mb-0 whitespace-nowrap overflow-auto lg:whitespace-normal"
          >
            <TabLink label="Desarrollo de la intervención" tab={0} />
            {/* <TabLink label="Indicadores" tab={1} /> */}
            <TabLink label="Comunicación con la comunidad" tab={2} />
          </Tabs>

          {tabValue === 0 && (
            <StyledBodyTab active={tabValue === 0}>
              <DevelopmentIntervention />
            </StyledBodyTab>
          )}
          {/* {tabValue === 1 && (
            <StyledBodyTab active={tabValue === 1}>Indicadores</StyledBodyTab>
          )} */}
          {tabValue === 2 && (
            <StyledBodyTab active={tabValue === 2}>
              <Comunication />
            </StyledBodyTab>
          )}
        </StyledWrapperContent>

        <DefaultModal
          isActive={activeModal.active}
          classNameContent="max-w-screen-md w-11/12"
          handleClose={() => {
            setActiveModal({ active: false });
          }}
        >
          <DetailModal projectData={dataIntervention} />
        </DefaultModal>
      </LayoutSelected>
    </Suspense>
  );
};

export default AdminDashboardViewInterventions;
