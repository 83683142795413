//Packages
import React, { lazy } from 'react';

//Components
import List from '../List';
import Create from '../Create';
import Detail from '../Detail';

//Hooks
import useControllers from 'controllers';

//Styles
const StyledBodyTab = lazy(() =>
  import('styles/tabs/tabs.styles').then((mod) => ({
    default: mod.StyledBodyTab,
  }))
);

const Messages = () => {
  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsCommunication } =
    useAdminViewInterventionsControllers();
  const { useAdminViewInterventionsCommunicationDiffusion } =
    useAdminViewInterventionsCommunication();
  const {
    activeItem,
    setActiveItem,

    //functions
    deleteMessage,
    handleChangePage,
    handleDataToOrder,
    handleResetFilter,
    handleFilter,
    sendMessage,
    sendMessageDraft,
    handleResetSelectedDetailMessage,
    onFormatMessageAboutCommunities,
    getMonthAndDay,
    handleGetDetailMessages,
    handleAddOptions,
    setValidateOptionsRules,
    setDataCollectionInformationAnswersSelected,
    handleChangePageAnswers,
    handleDeleteOption,
    handleOnChangeOptions,
    handleDownloadFileFromCollectionInformation,

    //Modals/toogles
    activeDropDown,
    handleOpenMenu,
    activeDeleteModal,
    setActiveDeleteModal,
    activeConfirmModal,
    setActiveConfirmModal,
    activeAtLeastItemDraft,
    setActiveAtLeastItemDraft,

    //data
    messagesList,
    dataForPagination,
    orderBy,
    dataTableHeaderArtistMessagesRef,
    optionsCommunities,
    selectedMessage,
    numberOptions,
    dataCollectionInformationAnswersSelected,

    //rhf
    formFilterMessages,
    formDiffusionMessage,
  } = useAdminViewInterventionsCommunicationDiffusion();

  return (
    <div className="px-4">
      {activeItem.active === 0 && (
        <StyledBodyTab active={activeItem.active === 0}>
          <List
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            messagesList={messagesList}
            deleteMessage={deleteMessage}
            activeDeleteModal={activeDeleteModal}
            setActiveDeleteModal={setActiveDeleteModal}
            handleChangePage={handleChangePage}
            dataForPagination={dataForPagination}
            activeDropDown={activeDropDown}
            handleOpenMenu={handleOpenMenu}
            dataTableHeaderArtistMessagesRef={dataTableHeaderArtistMessagesRef}
            handleDataToOrder={handleDataToOrder}
            orderBy={orderBy}
            handleResetFilter={handleResetFilter}
            handleFilter={handleFilter}
            formFilterMessages={formFilterMessages}
            handleGetDetailMessages={handleGetDetailMessages}
            optionsCommunities={optionsCommunities}
          />
        </StyledBodyTab>
      )}
      {activeItem.active === 1 && (
        <StyledBodyTab active={activeItem.active === 1}>
          <Create
            setActiveItem={setActiveItem}
            sendMessage={sendMessage}
            sendMessageDraft={sendMessageDraft}
            activeConfirmModal={activeConfirmModal}
            setActiveConfirmModal={setActiveConfirmModal}
            formDiffusionMessage={formDiffusionMessage}
            optionsCommunities={optionsCommunities}
            activeAtLeastItemDraft={activeAtLeastItemDraft}
            setActiveAtLeastItemDraft={setActiveAtLeastItemDraft}
            handleAddOptions={handleAddOptions}
            setValidateOptionsRules={setValidateOptionsRules}
            numberOptions={numberOptions}
            handleDeleteOption={handleDeleteOption}
            handleOnChangeOptions={handleOnChangeOptions}
          />
        </StyledBodyTab>
      )}
      {activeItem.active === 2 && (
        <StyledBodyTab active={activeItem.active === 2}>
          <Detail
            setActiveItem={setActiveItem}
            selectedMessage={selectedMessage}
            handleResetSelectedDetailMessage={handleResetSelectedDetailMessage}
            onFormatMessageAboutCommunities={onFormatMessageAboutCommunities}
            getMonthAndDay={getMonthAndDay}
            setDataCollectionInformationAnswersSelected={
              setDataCollectionInformationAnswersSelected
            }
            dataCollectionInformationAnswersSelected={
              dataCollectionInformationAnswersSelected
            }
            handleChangePageAnswers={handleChangePageAnswers}
            handleDownloadFileFromCollectionInformation={
              handleDownloadFileFromCollectionInformation
            }
          />
        </StyledBodyTab>
      )}
    </div>
  );
};

export default Messages;
