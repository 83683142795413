//Hooks
import useStrings from '../../../../../../../../strings';
import useProviders from '../../../../../../../providers';

const useAdminDevelopmentInterventionKnowProjectActions = () => {
  const { useAdminProviders } = useProviders();
  const { useAdminProjectsProviders } = useAdminProviders();
  const { useAdminInterventionsProviders } = useAdminProjectsProviders();
  const { useAdminViewInterventionProviders } =
    useAdminInterventionsProviders();
  const { useAdminViewInterventionDevelopmentInterventionProviders } =
    useAdminViewInterventionProviders();
  const { useAdminDevelopmentInterventionKnowProjectProviders } =
    useAdminViewInterventionDevelopmentInterventionProviders();
  const {
    createKnowProjectInterventionProvider,
    knowProjectInterventionProvider,
    knowProjectStateInterventionProvider,
  } = useAdminDevelopmentInterventionKnowProjectProviders();

  const { useAdminTypes } = useStrings();
  const { useAdminProjectsTypes } = useAdminTypes();
  const { useAdminInterventionsTypes } = useAdminProjectsTypes();
  const { useAdminViewInterventionsTypes } = useAdminInterventionsTypes();
  const { useAdminViewInterventionsDevelopmentInterventionTypes } =
    useAdminViewInterventionsTypes();
  const { useAdminDevelopmentInterventionKnowProjectTypes } =
    useAdminViewInterventionsDevelopmentInterventionTypes();
  const {
    CREATE_KNOW_PROJECT_INTERVENTION,
    KNOW_PROJECT_INTERVENTION,
    KNOW_PROJECT_STATE_INTERVENTION,
  } = useAdminDevelopmentInterventionKnowProjectTypes();

  const actKnowProjectStateIntervention =
    ({ intervention_id }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await knowProjectStateInterventionProvider({
          intervention_id,
        });
        dispatch({
          type: KNOW_PROJECT_STATE_INTERVENTION,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (e) {
        onError && onError(e);
      }
    };
  const actCreateKnowProjectIntervention =
    ({ intervention_id, know_project, data }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await createKnowProjectInterventionProvider({
          intervention_id,
          know_project,
          data,
        });
        dispatch({
          type: CREATE_KNOW_PROJECT_INTERVENTION,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (e) {
        onError && onError(e);
      }
    };
  const actKnowProjectIntervention =
    ({ intervention_id }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await knowProjectInterventionProvider({
          intervention_id,
        });
        dispatch({
          type: KNOW_PROJECT_INTERVENTION,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (e) {
        onError && onError(e);
      }
    };
  return {
    actKnowProjectIntervention,
    actCreateKnowProjectIntervention,
    actKnowProjectStateIntervention,
  };
};

export default useAdminDevelopmentInterventionKnowProjectActions;
