import useHelpers from '../../../../helpers';

const useLanguagesSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const languagesSelector = createSelector(
    (state) => state.languages,
    (languages) => languages
  );
  return { languagesSelector };
};

export default useLanguagesSelectors;
