import React from 'react';

//Hooks
import useControllers from 'controllers';
import useViews from 'views';

//Form Pilot
import FormPilot from '../FormPilot';
import Agents from '../../KnowCommunities/components/Agents/Agents';

//Styles
import { StyledContentButtonsModal } from 'styles/tableViews/tableViews.styles';
import { StyledGroup } from 'styles/formViews/formViews.styles';
import { StyledDivider } from 'styles/formViews/formViews.styles';

const PilotProgram = () => {
  const { useComponents } = useViews();
  const { useTypographies, useButtons, useModals, useInputs, useCards } =
    useComponents();

  const { TypographyComponent } = useTypographies();
  const { ButtonComponent } = useButtons();
  const { DefaultModal } = useModals();
  const { InputForm } = useInputs();
  const { CardNotification } = useCards();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsDevelopmentIntervention } =
    useAdminViewInterventionsControllers();
  const { useViewDevelopmentInterventionCreateSketch } =
    useAdminViewInterventionsDevelopmentIntervention();
  const { useViewDevelopmentInterventionCreateSketchAdmin } =
    useViewDevelopmentInterventionCreateSketch();
  const { useAdminProgramPilotIntervention } =
    useViewDevelopmentInterventionCreateSketchAdmin();
  const {
    programPilot,
    pilotPlan,
    agents,
    handleSubmit,
    control,
    errors,
    showModalApprove,
    setShowModalApprove,
    stepCompleted,
    getChangeAgents,
    assignChangeAgent,
    updateProgramPilotIntervention,
  } = useAdminProgramPilotIntervention();

  return (
    <>
      {stepCompleted && (
        <CardNotification
          className="w-5/12 mx-auto py-9 px-4 mt-16"
          title={
            <TypographyComponent
              text={programPilot.state?.name}
              variant="h2"
              className="font-bold text-primary icon-watch text-center"
            />
          }
        />
      )}
      <TypographyComponent
        text="Programar piloto de la intervención"
        variant="h2"
        className="font-bold text-primary mt-6"
      />

      <TypographyComponent
        text="Agentes de cambio"
        variant="h3"
        className="font-bold text-primary mt-6"
      />

      <Agents
        agents={agents}
        selectedAgents={programPilot.changeAgents}
        communities={[pilotPlan.community]}
        getAgentsByCommunity={getChangeAgents}
        handleAssignChangeAgent={assignChangeAgent}
        canCallAgent={!stepCompleted}
      />

      <StyledDivider />
      <FormPilot control={control} />
      <StyledGroup col2 className="mb-4" noSpace>
        <InputForm
          name="place"
          label="Lugar*"
          type="text"
          placeholder="Ingresa un lugar"
          className="w-full"
          control={control}
          errors={errors}
          success={!stepCompleted}
          disabled={stepCompleted}
        />
      </StyledGroup>

      {!stepCompleted && (
        <>
          <div className="flex items-center space-x-2 mt-10 mb-6 justify-center">
            <ButtonComponent
              text="Enviar programación al artista"
              onPress={handleSubmit(() => setShowModalApprove(true))}
            />
          </div>

          <DefaultModal
            isActive={showModalApprove}
            classNameContent="max-w-screen-md w-11/12"
            handleClose={() => {
              setShowModalApprove(false);
            }}
          >
            <TypographyComponent
              text={'Enviar programación del piloto al artista'}
              variant="h3"
              className="font-bold text-primary mb-1"
            />

            <StyledContentButtonsModal>
              <ButtonComponent
                variant="primary"
                text="Cancelar"
                className="mx-auto w-full lg:w-auto lg:mx-2 "
                mode="outline"
                onPress={() => setShowModalApprove(false)}
              />
              <ButtonComponent
                variant="primary"
                text="Aceptar"
                className="mx-auto w-full lg:w-auto  lg:mx-2"
                onPress={handleSubmit(updateProgramPilotIntervention)}
              />
            </StyledContentButtonsModal>
          </DefaultModal>
        </>
      )}
    </>
  );
};

export default PilotProgram;
