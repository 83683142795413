const useAdminInterventionsRootTypes = () => {
  const CREATE_INTERVENTION = 'CREATE_INTERVENTION';
  const GET_DEFINITION_BEHAVIORS = 'GET_DEFINITION_BEHAVIORS';
  const GET_INTERVENTIONS_BY_PROJECT = 'GET_INTERVENTIONS_BY_PROJECT';
  const DELETE_INTERVENTION_BY_PROJECT = 'DELETE_INTERVENTION_BY_PROJECT';
  const UPDATE_INTERVENTION_BY_PROJECT = 'UPDATE_INTERVENTION_BY_PROJECT';
  const GET_INTERVENTION_DETAIL_BY_PROJECT =
    'GET_INTERVENTION_DETAIL_BY_PROJECT';
  const RESET_INTERVENTION_DETAIL_BY_PROJECT =
    'RESET_INTERVENTION_DETAIL_BY_PROJECT';
  const SET_FILTER_INTERVENTION_DETAIL_BY_PROJECT =
    'SET_FILTER_INTERVENTION_DETAIL_BY_PROJECT';
  const RESET_FILTER_INTERVENTION_DETAIL_BY_PROJECT =
    'RESET_FILTER_INTERVENTION_DETAIL_BY_PROJECT';
  const RESET_INTERVENTION_SELECTED = 'RESET_INTERVENTION_SELECTED';
  const SET_INTERVEN_DEFINITION_ID = 'SET_INTERVEN_DEFINITION_ID';
  const RESET_INTERVEN_DEFINITION_ID = 'RESET_INTERVEN_DEFINITION_ID';
  const GET_INTERVENTIONS_FOR_ENABLING = 'GET_INTERVENTIONS_FOR_ENABLING';
  const UPDATE_INTERVENTION_INFO_FINAL_PROCESS =
    'UPDATE_INTERVENTION_INFO_FINAL_PROCESS';
  const UPDATE_INTERVENTAION_ENABLE_VISIBILITY =
    'UPDATE_INTERVENTAION_ENABLE_VISIBILITY';
  const UPDATE_INTERVENTION_INFO_FILE = 'UPDATE_INTERVENTION_INFO_FILE';
  const GET_INTERVENTION_INFO_FILE = 'GET_INTERVENTION_INFO_FILE';
  const SET_CUSTOM_INTERVENTION_STEP = 'SET_CUSTOM_INTERVENTION_STEP';

  return {
    CREATE_INTERVENTION,
    GET_DEFINITION_BEHAVIORS,
    GET_INTERVENTIONS_BY_PROJECT,
    DELETE_INTERVENTION_BY_PROJECT,
    UPDATE_INTERVENTION_BY_PROJECT,
    GET_INTERVENTION_DETAIL_BY_PROJECT,
    RESET_INTERVENTION_DETAIL_BY_PROJECT,
    SET_FILTER_INTERVENTION_DETAIL_BY_PROJECT,
    RESET_FILTER_INTERVENTION_DETAIL_BY_PROJECT,
    RESET_INTERVENTION_SELECTED,
    SET_INTERVEN_DEFINITION_ID,
    RESET_INTERVEN_DEFINITION_ID,
    GET_INTERVENTIONS_FOR_ENABLING,
    UPDATE_INTERVENTION_INFO_FINAL_PROCESS,
    UPDATE_INTERVENTAION_ENABLE_VISIBILITY,
    UPDATE_INTERVENTION_INFO_FILE,
    GET_INTERVENTION_INFO_FILE,
    SET_CUSTOM_INTERVENTION_STEP,
  };
};

export default useAdminInterventionsRootTypes;
