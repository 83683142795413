import React, { lazy, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useHelpers from '../../../../helpers';

const StyledImage = lazy(() =>
  import('./Image.styles').then((mod) => ({
    default: mod.StyledImage,
  }))
);
const StyledPreviewVideo = lazy(() =>
  import('./Image.styles').then((mod) => ({
    default: mod.StyledPreviewVideo,
  }))
);
const Image = (props) => {
  const { srcImage, altImage, titleImage, variant, className, onClick } = props;
  const [format, setFormat] = useState(null);
  const { useQuickFunctions } = useHelpers();
  const { getFileValidations, getMimeTypeFromMedia } = useQuickFunctions();
  const { SUPPORTED_FORMATS_ONLY_VIDEOS } = getFileValidations();
  useEffect(() => {
    if (srcImage) {
      setFormat(getMimeTypeFromMedia({ urlFile: srcImage }));
    }
  }, []);
  return (
    <>
      {SUPPORTED_FORMATS_ONLY_VIDEOS.includes(format) ? (
        <StyledPreviewVideo
          className="w-full h-full border border-neutral-600 rounded-md object-cover object-center"
          controls
        >
          <source src={srcImage} type={format} />
        </StyledPreviewVideo>
      ) : (
        <StyledImage
          src={srcImage}
          alt={altImage}
          title={titleImage}
          variant={variant}
          className={className}
          onClick={onClick}
        />
      )}
    </>
  );
};

Image.propTypes = {
  srcImage: PropTypes.string.isRequired,
  altImage: PropTypes.string,
  titleImage: PropTypes.string,
  variant: PropTypes.oneOf(['normal', 'rounded']),
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Image.defaultProps = {
  variant: 'normal',
  className: '',
  onClick: () => {},
};

export default Image;
