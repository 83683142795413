const useAdminHomeType = () => {
  const STORE_CONFIG_HOME = 'STORE_CONFIG_HOME';
  const UPDATE_CONFIG_HOME = 'UPDATE_CONFIG_HOME';
  const GET_CONFIG_HOME = 'GET_CONFIG_HOME';
  const GET_INTERVENTIONS_HOME = 'GET_INTERVENTIONS_HOME';
  const GET_INTERVENTIONS_INFO_FOR_EDIT = 'GET_INTERVENTIONS_INFO_FOR_EDIT';
  const RESET_INTERVENTION_INFO_FOR_EDIT = 'RESET_INTERVENTION_INFO_FOR_EDIT';

  return {
    GET_CONFIG_HOME,
    UPDATE_CONFIG_HOME,
    STORE_CONFIG_HOME,
    GET_INTERVENTIONS_HOME,
    GET_INTERVENTIONS_INFO_FOR_EDIT,
    RESET_INTERVENTION_INFO_FOR_EDIT,
  };
};
export default useAdminHomeType;
