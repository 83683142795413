//Hooks
import useHelpers from '../../../../../helpers';

const useAdminDocumentationSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const listDocumentationByProjectSelector = createSelector(
    (state) => state.projectSelected,
    (projectSelected) => projectSelected.annexes
  );
  return { listDocumentationByProjectSelector };
};

export default useAdminDocumentationSelectors;
