// Hooks
import useButtons from './Buttons';
import useTypographies from './Typographies';
import useInputs from './Inputs';
import useLabels from './Labels';
import useBadges from './Badges';
import useHeaders from './Headers';
import useFooters from './Footers';
import useImages from './Images';
import useBoxes from './Boxes';
import useLinks from './Links';
import useCards from './Cards';
import useSliders from './Sliders';
import usePagers from './Pagers';
import useTabs from './Tabs';
import useLoaders from './Loaders';
import useModals from './Modals';
import useTables from './Tables';
import useCollapses from './Collapses';
import useSideBars from './SideBars';
import useStepper from './Stepper';

const useComponents = () => {
  return {
    useTypographies,
    useButtons,
    useInputs,
    useLabels,
    useBadges,
    useHeaders,
    useFooters,
    useImages,
    useBoxes,
    useLinks,
    useCards,
    useSliders,
    usePagers,
    useTabs,
    useLoaders,
    useModals,
    useTables,
    useCollapses,
    useSideBars,
    useStepper,
  };
};

export default useComponents;
