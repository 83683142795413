import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const StyledGeneralInformation = styled.section.attrs(() => ({
  className: 'StyledGeneralInformation',
}))`
  ${tw` flex `}
  .image-project {
    width: 22.5rem;
    height: 22.5rem;
    max-width: 315px;
  }
`;

export const StyledDashboardInformation = styled.div.attrs(() => ({
  className: 'StyledDashboardInformation',
}))`
  ${tw` ml-8`}
`;

export const StyledDashboardWrapperBadges = styled.div.attrs(() => ({
  className: 'StyledDashboardWrapperBadges',
}))`
  ${tw` flex space-x-2 mt-2`}
`;

export const StyledWrapperUsers = styled.div.attrs(() => ({
  className: 'StyledWrapperUsers',
}))`
  ${tw` flex mt-6 mb-4 flex-wrap `}
  .StyledCardUser {
    max-width: 330px;
    .StyledInformation {
      ${tw` xl:w-4/6 2xl:w-auto`}
      label {
        ${tw` truncate w-full`}
      }
    }
  }
`;

export const StyledOtherConfigs = styled.section.attrs(() => ({
  className: 'StyledOtherConfigs',
}))`
  ${tw` grid grid-cols-4 gap-x-10 gap-y-6 mt-12 mb-4  `}
  ${(props) => props.col3 && tw`grid-cols-3 `}
`;
