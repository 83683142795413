const useAdminFestivitiesAndTraditionsTypes = () => {
  const GET_FESTIVITIES_AND_TRADITIONS = 'GET_FESTIVITIES_AND_TRADITIONS';
  const CREATE_FESTIVITIES_AND_TRADITIONS = 'CREATE_FESTIVITIES_AND_TRADITIONS';
  const DELETE_FESTIVITIES_AND_TRADITIONS = 'DELETE_FESTIVITIES_AND_TRADITIONS';
  const UPDATE_FESTIVITIES_AND_TRADITIONS = 'UPDATE_FESTIVITIES_AND_TRADITIONS';
  return {
    GET_FESTIVITIES_AND_TRADITIONS,
    CREATE_FESTIVITIES_AND_TRADITIONS,
    DELETE_FESTIVITIES_AND_TRADITIONS,
    UPDATE_FESTIVITIES_AND_TRADITIONS,
  };
};

export default useAdminFestivitiesAndTraditionsTypes;
