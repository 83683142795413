import useAuth from './auth';
import useHome from './home';
import useProfile from './profile';
import useLegalData from './legalData';
import useAdmin from './admin';
import useDashboardControllers from './dashboard';
import useInterventionDetail from './InterventionDetail';

const useScreenHooks = () => {
  return {
    useHome,
    useAuth,
    useProfile,
    useLegalData,
    useAdmin,
    useDashboardControllers,
    useInterventionDetail,
  };
};

export default useScreenHooks;
