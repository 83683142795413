import useHelpers from '../../../../helpers';

const useLegalDataSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const privacyPoliciesSelector = createSelector(
    (state) => state.legalData.privacyPolicies,

    (auth) => auth
  );
  const termsAndConditionsSelector = createSelector(
    (state) => state.legalData.termsAndConditions,
    (auth) => auth
  );
  return { privacyPoliciesSelector, termsAndConditionsSelector };
};

export default useLegalDataSelectors;
