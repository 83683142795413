//Hooks
import useCountriesReducers from './countries';
import useLanguagesReducers from './languages';
import useLegalDataReducers from './legalData';
import useModalsReducers from './modals';
import useSpecialitiesReducers from './specialities';
import useColorsReducers from './colors';
import useAccessibilityReducers from './accessibility';
import useSidebarReducers from './sidebar';
import useNotificationsReducers from './notification';
import useTabSelectedReducers from './tabSelected';
import useIsFilterEmptyReducers from './isFilterEmpty';

const useGeneralReducers = () => {
  return {
    useCountriesReducers,
    useLegalDataReducers,
    useLanguagesReducers,
    useModalsReducers,
    useSpecialitiesReducers,
    useColorsReducers,
    useAccessibilityReducers,
    useSidebarReducers,
    useNotificationsReducers,
    useTabSelectedReducers,
    useIsFilterEmptyReducers,
  };
};

export default useGeneralReducers;
