import React from 'react';

//Components
import InputSearch from './InputSearch';
import InputForm from './InputForm';
import Select from './Select';
import InputFile from './InputFile';
import InputHoursMinutes from './InputHoursMinutes';
import TextArea from './TextArea';

//Lazy components
const CheckBox = React.lazy(() => import('./CheckBox'));
const Radio = React.lazy(() => import('./Radio'));
const InputRichText = React.lazy(() => import('./InputRichText'));
const DefaultSwitch = React.lazy(() => import('./Switch'));

const useInputs = () => {
  return {
    InputForm,
    TextArea,
    CheckBox,
    Select,
    Radio,
    InputSearch,
    InputFile,
    InputRichText,
    DefaultSwitch,
    InputHoursMinutes,
  };
};

export default useInputs;
