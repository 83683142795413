// Hooks
import useInputsStyles from './inputs';

const useGeneralStyles = () => {
  const mixStatesFormStyles = (states, prop) => {
    const { [prop]: funcStyleInput } = useInputsStyles();
    const response = funcStyleInput(states);

    return response ?? '';
  };

  return {
    mixStatesFormStyles,
  };
};
export default useGeneralStyles;
