//Packages
import React from 'react';
import { Switch, useRouteMatch, Redirect } from 'react-router-dom';
import useNavigation from '../../..';

//Hooks
import useViews from '../../../../views';

//Screens
const { useScreens } = useViews();
const { AdminRootUsers, AdminCreateUsers, AdminEditUsers } = useScreens();

const AdminUsersRouter = () => {
  const { path } = useRouteMatch();

  const { useRoutes } = useNavigation();
  const { PrivateRoute } = useRoutes();

  return (
    <Switch>
      <PrivateRoute
        exact
        path={`${path}/create`}
        component={AdminCreateUsers}
        redirect="/"
      />
      <PrivateRoute
        exact
        path={`${path}/edit/:id`}
        component={AdminEditUsers}
        redirect="/"
      />
      <PrivateRoute
        exact
        path={`${path}`}
        component={AdminRootUsers}
        redirect="/"
      />
      <Redirect to={`${path}`} />
    </Switch>
  );
};

export default AdminUsersRouter;
