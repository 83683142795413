const useAdminContentInterventionsTypes = () => {
  const SET_FILTER_ABOUT_HOME_INTERVENTIONS =
    'SET_FILTER_ABOUT_HOME_INTERVENTIONS';
  const RESET_FILTER_ABOUT_HOME_INTERVENTIONS =
    'RESET_FILTER_ABOUT_HOME_INTERVENTIONS';

  return {
    SET_FILTER_ABOUT_HOME_INTERVENTIONS,
    RESET_FILTER_ABOUT_HOME_INTERVENTIONS,
  };
};

export default useAdminContentInterventionsTypes;
