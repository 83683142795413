import useAdminInterventionsRootTypes from './root';
import useAdminViewInterventionsTypes from './view';

const useAdminInterventionsTypes = () => {
  return {
    useAdminViewInterventionsTypes,
    useAdminInterventionsRootTypes,
  };
};

export default useAdminInterventionsTypes;
