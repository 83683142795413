import React, { lazy, useEffect } from 'react';
import _ from 'lodash';
import { useForm } from 'react-hook-form';

//Hooks
import useControllers from 'controllers';
import useViews from 'views';

import FormPlanActivity from './components/FormPlanActivitie/FormPlanActivitie';
import Agents from './components/Agents/Agents';
import Methodology from './components/Methodology/Methodology';
import FormMessages from './components/FormMessages/FormMessages';
import Activities from './components/Activities';
import MessageAnswers from './components/MessageAnswers';
import {
  StyledContentButtonsModal,
  StyledContentButtons,
} from 'styles/tableViews/tableViews.styles';
import { StyledGroup, StyledDivider } from 'styles/formViews/formViews.styles';

const StyledWrapperCommunity = lazy(() =>
  import('../../../../../../Dashboard.styles').then((mod) => ({
    default: mod.StyledWrapperCommunity,
  }))
);

const StyledCardCommunity = lazy(() =>
  import('../../../../../../Dashboard.styles').then((mod) => ({
    default: mod.StyledCardCommunity,
  }))
);

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

const ProgramKnowCommunities = () => {
  const { useComponents } = useViews();

  const {
    useTypographies,
    useInputs,
    useButtons,
    useBadges,
    useModals,
    useCards,
  } = useComponents();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { TextArea, Select } = useInputs();
  const { ButtonComponent } = useButtons();
  const { DefaultModal } = useModals();
  const { CardNotification } = useCards();

  const { DefaultBadge } = useBadges();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useDeepenKnowledgeCommunity } =
    useAdminInterventionsDashboardControllers();
  const { useAdminDeepenKnowledgeScheduleInfo } = useDeepenKnowledgeCommunity();
  const {
    deepen_execution,
    agents,
    activities,
    messages,
    confirmationModalActivityOpen,
    confirmationModalMessageOpen,
    confirmationModalFinishOpen,
    selectedActivityState,
    selectedMessageState,
    getAgentsByCommunity,
    assignChangeAgent,
    preViewPDFDeepenKnowCommunity,
    downloadPDFDeepenKnowCommunity,
    handleConfirmationModalActivity,
    handleConfirmationModalMessage,
    handleUpdateActivity,
    handleUpdateMessage,
    goToSendMessage,
    handleFinishDeepenKnowledge,
    openConfirmationModalFinish,
    closeConfirmationModalFinish,
  } = useAdminDeepenKnowledgeScheduleInfo();

  const {
    control,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    reset({
      deepening_modality: {
        label: deepen_execution.deepening_modality?.name,
        value: deepen_execution.deepening_modality?.id,
      },
    });
  }, [deepen_execution]);

  return (
    <>
      {_.includes(['c', 'sc'], deepen_execution.state.id) && (
        <CardNotification
          className="w-5/12 mx-auto py-9 px-4 mt-16"
          title={
            <TypographyComponent
              text={deepen_execution?.state?.name}
              variant="h2"
              className="font-bold text-primary icon-watch text-center"
            />
          }
          content={
            <TypographyComponent
              text={`${
                _.includes(['sc'], deepen_execution.state.id)
                  ? 'Listos para entrar en acción!'
                  : ''
              } `}
              variant="p"
              className="text-neutral-950 mt-2 mb-4 text-center"
            />
          }
        />
      )}
      <TypographyComponent
        text="Ejecución de la profundización"
        variant="h2"
        className="text-primary mt-2 font-bold"
      />
      <TypographyComponent
        text="Ya estamos listos para implementar tu actividad de profundización."
        variant="p"
        className="text-neutral-950 mt-2 mb-4"
      />
      {!_.includes(['c', 'sc'], deepen_execution.state.id) && (
        <>
          <TypographyComponent
            text="Agentes de cambio"
            variant="h3"
            className="text-primary mt-8 font-bold"
          />
          <TypographyComponent
            text="Identifica quiénes serán tu mayor apoyo (agentes de cambio)"
            variant="p"
            className="text-neutral-950 mt-2 mb-4"
          />

          <Agents
            agents={agents}
            selectedAgents={deepen_execution?.change_agents}
            communities={deepen_execution?.communities}
            getAgentsByCommunity={getAgentsByCommunity}
            handleAssignChangeAgent={assignChangeAgent}
            canCallAgent={!_.includes(['c', 'sc'], deepen_execution.state.id)}
          />

          <form className="">
            <Methodology
              preViewPDFDeepenKnowCommunity={preViewPDFDeepenKnowCommunity}
              downloadPDFDeepenKnowCommunity={downloadPDFDeepenKnowCommunity}
            />

            <StyledGroup col2 flex className="mb-4 flex-col">
              <TypographyComponent
                text="Modalidad de profundización*"
                variant="p"
                className="text-neutral-900 font-bold"
              />
              <Select
                name="deepening_modality"
                id="deepening_modality"
                placeholder="Modalidad de profundización*"
                control={control}
                disabled
                defaultValue={{
                  label: deepen_execution.deepening_modality?.name,
                  value: deepen_execution.deepening_modality?.id,
                }}
                label=""
              />
            </StyledGroup>
          </form>

          {deepen_execution.deepening_modality?.id === 'r' && (
            <StyledGroup col2 flex className="mb-4 flex-col">
              <TypographyWithTooltip
                text="Tipo de profundización remota"
                variant="p"
                textTooltip="Tipo de profundización remota"
                className="text-neutral-900"
                idTooltip="know-typePrf"
                applyFontBold
              />
              <Select
                name="deepening_prof"
                id="deepening_prof"
                placeholder="Modalidad de profundización*"
                control={control}
                defaultValue={{
                  label: deepen_execution.deepen_type?.name,
                  value: deepen_execution.deepen_type?.id,
                }}
                disabled
                label=""
              />
            </StyledGroup>
          )}

          {deepen_execution?.deepening_modality?.id === 'r' &&
            deepen_execution?.deepen_type?.id !== 'sync' && (
              <StyledGroup col2 className="mb-4">
                <TextArea
                  control={control}
                  name="acompanamiento"
                  placeholder="Me gustaría descubrir..."
                  className="w-full col-span-2"
                  label="Descripción de la actividad*          "
                  defaultValue={deepen_execution.what_to_discover}
                  errors={errors}
                  disabled
                  hasController
                />
              </StyledGroup>
            )}

          {!(
            deepen_execution?.deepening_modality?.id === 'r' &&
            deepen_execution?.deepen_type?.id === 'async'
          ) && (
            <>
              <StyledDivider spacexs />

              <TypographyComponent
                text="Actividades"
                variant="h3"
                className="text-primary mt-6 font-bold"
              />

              <TypographyComponent
                text="Cuántas y cuáles actividaades se harán para esta profundización? Agraga una actividad por linea."
                variant="p"
                className="text-neutral-950 mt-2 mb-4"
              />

              <StyledWrapperCommunity
                className={
                  activities.length !== 0
                    ? 'visible opacity-100 h-auto'
                    : 'invisible opacity-0 transition-all duration-100 h-0'
                }
              >
                <Accordion allowZeroExpanded>
                  {activities.length > 0 &&
                    _.map(activities, (activity, index) => {
                      return (
                        <AccordionItem
                          key={index}
                          uuid={`actvitie${index}`}
                          dangerouslySetExpanded={activity.expanded}
                        >
                          <StyledCardCommunity key={`actvitie- ${index}`}>
                            <div className="flex items-center">
                              <TypographyComponent
                                variant="p"
                                text={activity.name}
                                className="text-primary font-bold"
                              />
                              <DefaultBadge
                                type="light"
                                variant={`${
                                  activity.state.id === 'c'
                                    ? 'success'
                                    : activity.state.id === 'r'
                                    ? 'error'
                                    : 'warning'
                                }`}
                                label={activity.state.name}
                                className="ml-2"
                              />
                            </div>

                            <StyledContentButtons>
                              <AccordionItemState>
                                {({ expanded }) => (
                                  <AccordionItemButton>
                                    <ButtonComponent
                                      text=""
                                      icon={
                                        <div
                                          className={`icon-arrow-down text-neutral-900 text-xxs ${
                                            expanded && 'transform rotate-180'
                                          }`}
                                        />
                                      }
                                      className=""
                                      variant="white"
                                      onPress={() => {}}
                                    />
                                  </AccordionItemButton>
                                )}
                              </AccordionItemState>
                            </StyledContentButtons>
                          </StyledCardCommunity>
                          <AccordionItemPanel>
                            <div className="border rounded py-8 px-6 mt-4">
                              <form className="px-4">
                                <FormPlanActivity
                                  activity={activity}
                                  isAdmin={true}
                                  deepenType={
                                    deepen_execution?.deepen_type?.id ?? ''
                                  }
                                  disabledForm
                                  handleConfirmActivity={({
                                    e,
                                    activity,
                                    place,
                                  }) =>
                                    handleConfirmationModalActivity({
                                      e,
                                      activity,
                                      state: 'c',
                                      place,
                                      open: true,
                                    })
                                  }
                                  handleRejectActivity={({ e, activity }) =>
                                    handleConfirmationModalActivity({
                                      e,
                                      activity,
                                      state: 'r',
                                      open: true,
                                    })
                                  }
                                />
                              </form>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                      );
                    })}
                </Accordion>
              </StyledWrapperCommunity>
            </>
          )}
          <StyledDivider spacexs />
          <TypographyComponent
            text="Mensajes de difusión"
            variant="h3"
            className="text-primary mt-6 font-bold"
          />
          <TypographyComponent
            text="Escribe la propuesta de mensaje para enviar, ten en cuenta la importancia de usar pocos caracteres, se lo mas conciso posible. Gracias!"
            variant="p"
            className="text-neutral-950 mt-2 mb-4"
          />
          <TypographyComponent
            text="Recuerda que este mensaje será enviado los agentes de cambio que invites a hacer parte de este proceso!"
            variant="p"
            className="text-neutral-950 mt-2 mb-4"
          />

          <StyledWrapperCommunity
            className={
              messages.length !== 0
                ? 'visible opacity-100 h-auto'
                : 'invisible opacity-0 transition-all duration-100 h-0'
            }
          >
            <Accordion allowZeroExpanded>
              {messages.length > 0 &&
                _.map(messages, (message, index) => {
                  return (
                    <AccordionItem
                      key={`message${index}`}
                      uuid={`message${index}`}
                      dangerouslySetExpanded={message.expanded}
                    >
                      <StyledCardCommunity key={`message-${index}`}>
                        <div className="flex items-center">
                          <TypographyComponent
                            variant="p"
                            text={message.description}
                            className="text-primary font-bold"
                          />
                          <DefaultBadge
                            type="light"
                            variant={`${
                              message.state.id === 's'
                                ? 'success'
                                : message.state.id === 'r'
                                ? 'error'
                                : 'warning'
                            }`}
                            label={message.state.state || message.state.name}
                            className="ml-2"
                          />
                        </div>
                        <StyledContentButtons>
                          <AccordionItemState>
                            {({ expanded }) => (
                              <AccordionItemButton>
                                <ButtonComponent
                                  text=""
                                  icon={
                                    <div
                                      className={`icon-arrow-down text-neutral-900 text-xxs ${
                                        expanded && 'transform rotate-180'
                                      }`}
                                    />
                                  }
                                  className=""
                                  variant="white"
                                  onPress={() => {}}
                                />
                              </AccordionItemButton>
                            )}
                          </AccordionItemState>
                        </StyledContentButtons>
                      </StyledCardCommunity>
                      <AccordionItemPanel>
                        <div className="border rounded py-8 px-6 mt-4">
                          <form className="px-4">
                            <FormMessages
                              program={message.state.id === 'p'}
                              defaultValueTypeProfundization={
                                deepen_execution.deepen_type
                              }
                              message={message}
                              handleConfirmMessage={(e, message) =>
                                goToSendMessage({ message })
                              }
                              handleRejectMessage={(e, message) =>
                                handleConfirmationModalMessage({
                                  e,
                                  message,
                                  state: 'r',
                                  open: true,
                                })
                              }
                            />
                          </form>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  );
                })}
            </Accordion>
          </StyledWrapperCommunity>

          <DefaultModal
            isActive={confirmationModalActivityOpen}
            classNameContent="max-w-screen-md w-11/12"
            handleClose={() => {
              handleConfirmationModalActivity({ open: false });
            }}
          >
            <TypographyComponent
              text={`${
                selectedActivityState === 'c' ? 'Confirmar' : 'Rechazar'
              } actividad`}
              variant="h3"
              className="font-bold text-primary mb-1"
            />

            <StyledContentButtonsModal>
              <ButtonComponent
                variant="primary"
                text="Cancelar"
                className="mx-auto w-full lg:w-auto lg:mx-2 "
                mode="outline"
                onPress={() => {
                  handleConfirmationModalActivity({ open: false });
                }}
              />
              <ButtonComponent
                variant="primary"
                text={selectedActivityState === 'c' ? 'Confirmar' : 'Rechazar'}
                className="mx-auto w-full lg:w-auto  lg:mx-2"
                onPress={handleUpdateActivity}
              />
            </StyledContentButtonsModal>
          </DefaultModal>

          <DefaultModal
            isActive={confirmationModalMessageOpen}
            classNameContent="max-w-screen-md w-11/12"
            handleClose={() => {
              handleConfirmationModalMessage({ open: false });
            }}
          >
            <TypographyComponent
              text={`${
                selectedMessageState === 'c' ? 'Confirmar' : 'Rechazar'
              } mensaje`}
              variant="h3"
              className="font-bold text-primary mb-1"
            />

            <StyledContentButtonsModal>
              <ButtonComponent
                variant="primary"
                text="Cancelar"
                className="mx-auto w-full lg:w-auto lg:mx-2 "
                mode="outline"
                onPress={() => {
                  handleConfirmationModalMessage({ open: false });
                }}
              />
              <ButtonComponent
                variant="primary"
                text={selectedMessageState === 'c' ? 'Confirmar' : 'Rechazar'}
                className="mx-auto w-full lg:w-auto  lg:mx-2"
                onPress={handleUpdateMessage}
              />
            </StyledContentButtonsModal>
          </DefaultModal>

          <DefaultModal
            isActive={confirmationModalFinishOpen}
            classNameContent="max-w-screen-md w-11/12"
            handleClose={closeConfirmationModalFinish}
          >
            <TypographyComponent
              text={'Enviar resultados al artista'}
              variant="h3"
              className="font-bold text-primary mb-1"
            />

            <StyledContentButtonsModal>
              <ButtonComponent
                variant="primary"
                text="Cancelar"
                className="mx-auto w-full lg:w-auto lg:mx-2 "
                mode="outline"
                onPress={closeConfirmationModalFinish}
              />
              <ButtonComponent
                variant="primary"
                text="Aceptar"
                className="mx-auto w-full lg:w-auto  lg:mx-2"
                onPress={() => handleFinishDeepenKnowledge({ state: 'sc' })}
              />
            </StyledContentButtonsModal>
          </DefaultModal>

          <div className="flex items-center space-x-2 mt-10 justify-center">
            <ButtonComponent
              text="Finalizar"
              onPress={openConfirmationModalFinish}
            />
          </div>
        </>
      )}

      {_.includes(['c', 'sc'], deepen_execution.state.id) && (
        <>
          <StyledDivider />

          <TypographyWithTooltip
            text="Plan de profundización"
            variant="h3"
            textTooltip="Plan de profundización"
            className="text-primary"
            idTooltip="plan"
            applyFontBold
          />
          <TypographyComponent
            text={
              <label className="text-primary">
                <strong>Comunidades en las que vas a profundizar:</strong>
                <span className="text-neutral-950 ml-1">
                  {_.map(deepen_execution?.communities, (c) => c.name).join(
                    ', '
                  )}
                </span>
              </label>
            }
            variant="p"
            className="text-neutral-950 mt-2 mb-4"
          />
          <TypographyComponent
            text={
              <label className="text-primary">
                <strong>¿Qué quieres descubrir?</strong>
                <span className="text-neutral-950 ml-1">
                  {deepen_execution.what_to_discover}
                </span>
              </label>
            }
            variant="p"
            className="text-neutral-950 mt-2 mb-4"
          />
          <TypographyComponent
            text={
              <label className="text-primary">
                <strong>Modalidad:</strong>
                <span className="text-neutral-950 ml-1">
                  {deepen_execution.deepening_modality?.name}{' '}
                  {deepen_execution.deepening_modality.id === 'r' &&
                    `- ${deepen_execution.deepen_type?.name}`}
                </span>
              </label>
            }
            variant="p"
            className="text-neutral-950 mt-2 mb-4"
          />
          {_.filter(activities, (a) => a.state.id === 'c' && a.date).length >
            0 && (
            <Activities
              activities={_.filter(
                activities,
                (a) => a.state.id === 'c' && a.date
              )}
            />
          )}
          {_.filter(messages, (m) => m.messageRequest).length > 0 && (
            <MessageAnswers
              messages={_.filter(messages, (m) => m.messageRequest)}
              communities={deepen_execution?.communities}
            />
          )}
        </>
      )}
    </>
  );
};

export default ProgramKnowCommunities;
