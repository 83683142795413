//Packages
import React, { Suspense, lazy } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

//Assets
import imageUser from 'assets/img/fakeUserPhotoSociio.jpg';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
const StyledInputSearchContent = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledInputSearchContent,
  }))
);

const StyledContentButtons = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const StyledList = lazy(() =>
  import('../componets.styles').then((mod) => ({
    default: mod.StyledList,
  }))
);

const StyledListItem = lazy(() =>
  import('../componets.styles').then((mod) => ({
    default: mod.StyledListItem,
  }))
);

const All = (props) => {
  const {
    setSelectedArtist,
    handleCloseModal,
    artistSelectedByDefault,
    favoritesTab,
  } = props;

  const { useComponents } = useViews();
  const { useButtons, useInputs, useCards, useModals, useLoaders } =
    useComponents();
  const { DefaultLoader } = useLoaders();
  const { InputSearch, Radio } = useInputs();
  const { ButtonComponent } = useButtons();
  const { CardUser } = useCards();
  const { UserInfoModal } = useModals();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminSelectArtistInterventionsDashboard } =
    useAdminInterventionsDashboardControllers();
  const {
    textSearch,
    setTextSearch,
    artistSelected,
    errorSelectedArtist,
    setItemsListRef,
    artistsUsersFiltered,
    setShowModalUser,
    showModalUser,
    userInfo,
    handleSelectArtist,
    handleShowUserProfile,
    handleConfirmSelectedArtist,
    showCityDepartmentAndCountry,
    handleToggleArtistFavorite,
  } = useAdminSelectArtistInterventionsDashboard({
    artistSelectedByDefault,
    setSelectedArtist,
    handleCloseModal,
    favoritesTab,
  });

  return (
    <Suspense fallback={<DefaultLoader />}>
      <>
        <StyledInputSearchContent fullWidth className="w-full mt-6">
          <InputSearch
            text={textSearch}
            onDebounce={setTextSearch}
            placeholder="Buscar artista"
          />
        </StyledInputSearchContent>
        <StyledList>
          {artistsUsersFiltered.length > 0 &&
            _.map(artistsUsersFiltered, (artist, idx) => {
              const { id, name, last_name, avatar, city, favorite } = artist;
              if (idx + 1 === artistsUsersFiltered.length) {
                return (
                  <StyledListItem key={`artist-${id}`}>
                    <div
                      ref={(ref) => {
                        setItemsListRef(ref);
                      }}
                    >
                      <CardUser
                        image={avatar || imageUser}
                        name={`${name} ${last_name}`}
                        location={showCityDepartmentAndCountry({
                          city,
                        })}
                        className="relative"
                      >
                        <Radio
                          name="reading_level"
                          label=""
                          value={`${id}`}
                          onChange={handleSelectArtist}
                          className="absolute left-0 ml-2"
                          checked={
                            artistSelectedByDefault
                              ? artistSelectedByDefault === id
                              : artistSelected?.id === id
                          }
                        />
                        <StyledContentButtons>
                          <ButtonComponent
                            text=""
                            className="icon-view text-primary-100"
                            variant="primary"
                            onPress={() => {
                              handleShowUserProfile({ userId: id });
                            }}
                          />
                          <ButtonComponent
                            text=""
                            className={
                              favorite === 'false'
                                ? 'icon-heart-empty'
                                : 'icon-heart'
                            }
                            onPress={() =>
                              handleToggleArtistFavorite({ artist })
                            }
                          />
                        </StyledContentButtons>
                      </CardUser>
                    </div>
                  </StyledListItem>
                );
              } else {
                return (
                  <StyledListItem key={`artist-${id}`}>
                    <CardUser
                      image={avatar || imageUser}
                      name={`${name} ${last_name}`}
                      location={showCityDepartmentAndCountry({
                        city,
                      })}
                      className="relative"
                    >
                      <Radio
                        name="reading_level"
                        label=""
                        value={`${id}`}
                        onChange={handleSelectArtist}
                        className="absolute left-0 ml-2"
                        checked={
                          artistSelectedByDefault
                            ? artistSelectedByDefault === id
                            : artistSelected?.id === id
                        }
                      />
                      <StyledContentButtons>
                        <ButtonComponent
                          text=""
                          className="icon-view text-primary-100"
                          variant="primary"
                          onPress={() => {
                            handleShowUserProfile({ userId: id });
                          }}
                        />
                        <ButtonComponent
                          text=""
                          className={
                            favorite === 'false'
                              ? 'icon-heart-empty'
                              : 'icon-heart'
                          }
                          onPress={() => handleToggleArtistFavorite({ artist })}
                        />
                      </StyledContentButtons>
                    </CardUser>
                  </StyledListItem>
                );
              }
            })}
          {artistsUsersFiltered.length === 0 && favoritesTab && !textSearch && (
            <>No has seleccionado artistas favoritos</>
          )}
          {artistsUsersFiltered.length === 0 && textSearch && (
            <>No hay artistas que coincidan con la búsqueda</>
          )}
        </StyledList>
        <ButtonComponent
          text="Seleccionar artista"
          className="mx-auto mt-8"
          onPress={handleConfirmSelectedArtist}
          disabled={!artistSelected}
        />
        {errorSelectedArtist && (
          <p className="text-sm mt-2 text-error">Debe seleccionar un artista</p>
        )}
      </>
      <UserInfoModal
        viewUser={showModalUser}
        setViewUser={setShowModalUser}
        user={userInfo}
      />
    </Suspense>
  );
};

All.propTypes = {
  setSelectedArtist: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  artistSelectedByDefault: PropTypes.number,
  favoritesTab: PropTypes.bool,
};

All.defaultProps = {
  artistSelectedByDefault: 0,
};

export default All;
