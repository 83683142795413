import useArtistCreateSketchInterventions from './createSketchRoot';
import useArtistPlanPilotIntervention from './planPilot';
import useArtistProgramPilotIntervention from './programPilot';

const useViewDevelopmentInterventionCreateSketchArtist = () => {
  return {
    useArtistCreateSketchInterventions,
    useArtistPlanPilotIntervention,
    useArtistProgramPilotIntervention
  };
};

export default useViewDevelopmentInterventionCreateSketchArtist;
