//Packages
import React, { Suspense, lazy } from 'react';
import _ from 'lodash';

//Assets
import imageFacebook from 'assets/icons/facebook.svg';
import imageTwitter from 'assets/icons/twitter.svg';
import imageInstagram from 'assets/icons/instagram.svg';
import imageYoutube from 'assets/icons/youtube.svg';
import imageLinkedin from 'assets/icons/linkedin.svg';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Components
import AddExperience from './AddExperience';

//Styles
const StyledForm = lazy(() =>
  import('../../Profile.styles').then((mod) => ({
    default: mod.StyledForm,
  }))
);

const StyledGroup = lazy(() =>
  import('../../Profile.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);
const StyledPhotocontent = lazy(() =>
  import('../../Profile.styles').then((mod) => ({
    default: mod.StyledPhotocontent,
  }))
);
const StyledContentSpecial = lazy(() =>
  import('../../Profile.styles').then((mod) => ({
    default: mod.StyledContentSpecial,
  }))
);

const StyledContentTitle = lazy(() =>
  import('../../Profile.styles').then((mod) => ({
    default: mod.StyledContentTitle,
  }))
);

const Profile = () => {
  //layout- components
  const { useComponents } = useViews();
  const {
    useTypographies,
    useInputs,
    useButtons,
    useImages,
    useLabels,
    useBadges,
    useModals,
    useLoaders,
  } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { TypographyComponent } = useTypographies();
  const { InputForm, Select, TextArea } = useInputs();
  const { ButtonComponent } = useButtons();
  const { UploadImage, Image } = useImages();
  const { DefaultLabel } = useLabels();
  const { ActionBadge } = useBadges();
  const { DefaultModal } = useModals();

  const { useScreenHooks } = useControllers();
  const { useProfile } = useScreenHooks();
  const {
    openModalAddExperience,
    toogleModalAddExperience,
    handleToogleSpeciality,
    handleCloseNewSpecialities,
    openModalDiscardChanges,
    toogleModalDiscardChanges,
    handleDiscardChanges,
    handleSaveNewSpecialities,
    handleDeleteSpecificSpeciality,
    // errorSpecialities,
    errors,
    control,
    userInfo,
    handleSubmit,
    handleUpdateProfile,
    handleUpdateDataUser,
    handleUpdateDataUserOnSelect,
    handleUpdateAvatar,
    optionsCountries,
    optionsDepartments,
    optionsCities,
    optionsIndicatives,
    optionsLanguages,
    optionsInterventions,
    optionsSpecialitiesModal,
    optionsSpecialities,
    countrySelected,
  } = useProfile();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <>
        <TypographyComponent
          text="Editar Perfil"
          variant="h2"
          className="font-bold text-primary mb-4 text-lg"
        />
        <StyledPhotocontent>
          <UploadImage
            classNameImage="w-28 h-28 rounded-full object-cover"
            className="w-28 h-28 rounded-full"
            imageDefault={userInfo.avatar}
            handleChangeImage={handleUpdateAvatar}
          />
        </StyledPhotocontent>

        <StyledForm>
          <StyledGroup>
            <TypographyComponent
              text="Información básica"
              variant="h3"
              className="font-bold text-primary  col-span-2 mb-1"
            />

            <InputForm
              control={control}
              type="text"
              name="name"
              placeholder="Nombre"
              label="Nombre*"
              className="w-full"
              errors={errors}
              onChange={handleUpdateDataUser}
            />
            <InputForm
              control={control}
              type="text"
              name="last_name"
              placeholder="Apellido"
              label="Apellido*"
              className="w-full"
              errors={errors}
              onChange={handleUpdateDataUser}
            />
            <InputForm
              control={control}
              type="email"
              name="email"
              placeholder="Ingresa tu correo electrónico"
              label="Correo electrónico*"
              className="w-full"
              errors={errors}
              disabled
            />
            <Select
              name="country"
              id="select-example-label"
              placeholder="Selecciona tu país"
              control={control}
              options={optionsCountries}
              label="País*"
              errors={errors}
              onChange={handleUpdateDataUserOnSelect}
            />
            <Select
              name="department"
              id="city_id"
              placeholder="Selecciona tu departamento"
              control={control}
              options={optionsDepartments}
              label="Departamento o Estado*"
              errors={errors}
              disabled={!countrySelected}
              onChange={handleUpdateDataUserOnSelect}
            />
            <Select
              name="city_id"
              id="city_id"
              placeholder="Selecciona tu ciudad"
              control={control}
              options={optionsCities}
              label="Ciudad o Municipio*"
              errors={errors}
              disabled={!countrySelected}
              onChange={handleUpdateDataUserOnSelect}
            />

            <StyledGroup
              col2
              className="items-end flex space-x-4 lg:space-x-3 "
            >
              <Select
                name="phone_code"
                id="phone_code"
                placeholder="Selecciona su indicativo"
                className="w-1/3 lg:w-7/12"
                control={control}
                options={optionsIndicatives}
                label="Teléfono*"
                errors={errors}
                onChange={handleUpdateDataUserOnSelect}
              />
              <InputForm
                control={control}
                type="number"
                name="phone"
                placeholder="Ingresa tu número de teléfono"
                className="w-2/3 lg:w-full"
                errors={errors}
                onChange={handleUpdateDataUser}
              />
            </StyledGroup>

            <InputForm
              control={control}
              type="text"
              name="gender"
              placeholder="Ingresa tu identidad de género"
              label="Género"
              className="w-full"
              errors={errors}
              onChange={handleUpdateDataUser}
            />
            <Select
              name="language"
              id="language"
              control={control}
              placeholder="Selecciona tu idioma"
              options={optionsLanguages}
              label="Idioma*"
              errors={errors}
              onChange={handleUpdateDataUserOnSelect}
            />
            <InputForm
              control={control}
              type="text"
              name="group"
              placeholder="Ingresa el nombre de tu grupo artístico"
              label="Grupo artistico al que perteneces"
              className="w-full"
              errors={errors}
              onChange={handleUpdateDataUser}
            />
          </StyledGroup>

          <StyledGroup>
            <TypographyComponent
              text="Redes sociales"
              variant="h3"
              className="font-bold text-primary col-span-2 mb-1"
            />

            <div>
              <StyledContentTitle>
                <Image
                  altImage="facebook"
                  titleImage="facebook"
                  srcImage={imageFacebook}
                  className="w-5 h-5 mb-1 mr-2"
                />
                <DefaultLabel>Facebook</DefaultLabel>
              </StyledContentTitle>

              <InputForm
                control={control}
                type="text"
                name="fcb"
                placeholder="Ingresa tu perfil de Facebook"
                className="w-full"
                errors={errors}
              />
            </div>
            <div>
              <StyledContentTitle>
                <Image
                  altImage="twiter"
                  titleImage="twiter"
                  srcImage={imageTwitter}
                  className="w-5 h-5 mb-1 mr-2"
                />
                <DefaultLabel>Twiter</DefaultLabel>
              </StyledContentTitle>

              <InputForm
                control={control}
                type="text"
                name="tw"
                placeholder="Ingresa tu perfil de Twitter"
                className="w-full"
                errors={errors}
              />
            </div>

            <div>
              <StyledContentTitle>
                <Image
                  altImage="instagram"
                  titleImage="instagram"
                  srcImage={imageInstagram}
                  className="w-5 h-5 mb-1 mr-2"
                />
                <DefaultLabel>Instagram</DefaultLabel>
              </StyledContentTitle>

              <InputForm
                control={control}
                type="text"
                name="int"
                placeholder="Ingresa tu perfil de Instagram"
                className="w-full"
                errors={errors}
              />
            </div>
            <div>
              <StyledContentTitle>
                <Image
                  altImage="youtube"
                  titleImage="youtube"
                  srcImage={imageYoutube}
                  className="w-5 h-5 mb-1 mr-2"
                />
                <DefaultLabel>Youtube</DefaultLabel>
              </StyledContentTitle>

              <InputForm
                control={control}
                type="text"
                name="you"
                placeholder="Ingresa tu canal de Youtube"
                className="w-full"
                errors={errors}
              />
            </div>
            <div>
              <StyledContentTitle>
                <Image
                  altImage="youtube"
                  titleImage="youtube"
                  srcImage={imageLinkedin}
                  className="w-5 h-5 mb-1 mr-2"
                />
                <DefaultLabel>LinkedIn</DefaultLabel>
              </StyledContentTitle>

              <InputForm
                control={control}
                type="text"
                name="lin"
                placeholder="Ingresa tu perfil de LinkedIn"
                className="w-full"
                errors={errors}
              />
            </div>
            <div>
              <StyledContentTitle>
                <DefaultLabel>Otra</DefaultLabel>
              </StyledContentTitle>

              <InputForm
                control={control}
                type="text"
                name="oth"
                placeholder="Ingresa la URL de tu red social"
                className="w-full"
                errors={errors}
              />
            </div>
          </StyledGroup>

          <StyledGroup>
            <TypographyComponent
              text="Información profesional"
              variant="h3"
              className="font-bold text-primary col-span-2 mb-1"
            />
            <TypographyComponent
              text="Selecciona las areas artísticas donde tienes mayor experiencia."
              variant="p"
              className=" text-neutral-950 mb-4 col-span-2"
            />
          </StyledGroup>

          <StyledGroup noSpace>
            <DefaultLabel>Tipos de experiencia artística*</DefaultLabel>
            <StyledContentSpecial>
              {_.map(optionsSpecialities, (speciality) => {
                return (
                  <ActionBadge
                    key={`specialityItem-${speciality.id}`}
                    label={speciality.name}
                    onDelete={(e) => {
                      e.preventDefault();
                      handleDeleteSpecificSpeciality({
                        idSpeciality: speciality.id,
                      });
                    }}
                  />
                );
              })}

              <ButtonComponent
                variant="primary"
                mode="outline"
                text="Agregar nuevo tipo de experiencia"
                icon={<div className="icon-close transform  rotate-45"></div>}
                onPress={(e) => {
                  e.preventDefault();
                  toogleModalAddExperience();
                }}
              />
            </StyledContentSpecial>
            {errors['specialties'] && (
              <p name="specialties" className="text-sm mt-2 text-error">
                {errors['specialties'].message}
              </p>
            )}
          </StyledGroup>

          <StyledGroup>
            <InputForm
              control={control}
              type="text"
              name="website"
              placeholder="Ingresa la URL de tu sitio web"
              label="Sitio web"
              className="w-full mt-4"
              errors={errors}
              onChange={handleUpdateDataUser}
            />

            <Select
              name="intervention_type"
              id="intervention-type"
              placeholder="Ingresa una intervención"
              control={control}
              options={optionsInterventions}
              label="Tipo de intervención preferido*"
              errors={errors}
              className="w-full"
              onChange={handleUpdateDataUserOnSelect}
            />

            <TextArea
              control={control}
              name="training_to_others"
              placeholder="¿Que tipo de apoyo puedes ofrecer a otros artistas?"
              className="w-full col-span-2"
              label="¿Que tipo de apoyo puedes ofrecer a otros artistas?"
              errors={errors}
              onChange={handleUpdateDataUser}
            />
          </StyledGroup>

          <StyledGroup>
            <TypographyComponent
              text="Acerca de tu trabajo"
              variant="h3"
              className="font-bold text-primary col-span-2 mb-1"
            />
            <TypographyComponent
              text="Incluye tu título dentro de la organización y una brave descripción de tus responsabilidades/trabajo"
              variant="p"
              className="font-bold text-neutral-950 mb-4 col-span-2"
            />

            <InputForm
              control={control}
              type="text"
              name="about_me_title"
              placeholder="Ingresa un título"
              className="w-full col-span-2 mb-4"
              label="Título*"
              errors={errors}
              onChange={handleUpdateDataUser}
            />
            <TextArea
              control={control}
              name="about_me_body"
              placeholder="Acerca de mí"
              className="w-full col-span-2"
              label="Descripción*"
              errors={errors}
              onChange={handleUpdateDataUser}
            />
          </StyledGroup>

          <ButtonComponent
            variant="primary"
            text="Guardar"
            onPress={handleSubmit(handleUpdateProfile)}
          />
        </StyledForm>
        <DefaultModal
          isActive={openModalAddExperience}
          classNameContent="max-w-screen-sm w-11/12"
          handleClose={handleCloseNewSpecialities}
        >
          <AddExperience
            optionsSpecialities={optionsSpecialitiesModal}
            handleToogleSpeciality={handleToogleSpeciality}
            openModalDiscardChanges={openModalDiscardChanges}
            handleCloseModalDiscardChanges={toogleModalDiscardChanges}
            handleDiscardChanges={handleDiscardChanges}
            handleSaveNewSpecialities={handleSaveNewSpecialities}
          />
        </DefaultModal>
      </>
    </Suspense>
  );
};

export default Profile;
