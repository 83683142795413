import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import _ from 'lodash';

// Hooks
import useApi from 'api';
import useModels from 'models';
import useStrings from 'strings';

const useArtistExecuteIntervention = () => {
  const { intervention_id: interventionId } = useParams();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();
  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const {
    useAdminDevelopmentExecuteInterventionActions,
  } = useAdminViewInterventionDevelopmentInterventionActions();
  const {
    actGetExecuteIntervention,
    actUpdateExecuteIntervention,
    actStartEventExecuteIntervention,
    actCompleteMilestoneEventExecuteIntervention,
  } = useAdminDevelopmentExecuteInterventionActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionsSelector } = useAdminInterventionsSelectors();
  const { executeIntervention } = useSelector(getInterventionsSelector);
  const { id: executeId } = executeIntervention;

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { REQUIRED_FIELD, MAX_LENGTH_100, MAX_LENGTH_500 } = useFormsTypes();

  const [openModalCompleteMilestone, setOpenModalCompleteMilestone] = useState(false);
  const [openModalApprove, setOpenModalApprove] = useState(false);
  const milestoneRef = useRef({})

  const schemaExecuteInterventionComment = yup.object({
    title: yup
      .string()
      .typeError(REQUIRED_FIELD)
      .max(100, MAX_LENGTH_100)
      .required(REQUIRED_FIELD),
    description: yup
      .string()
      .typeError(REQUIRED_FIELD)
      .max(500, MAX_LENGTH_500)
      .required(REQUIRED_FIELD)
  });

  const formComment = useForm({
    resolver: yupResolver(schemaExecuteInterventionComment),
    mode: 'onChange',
  });

  useEffect(() => {
    if (executeIntervention.state?.id !== 'c') {
      dispatch(actGetExecuteIntervention({
        interventionId,
      }));
    }
  }, [])

  const startEvent = ({ event }) => {
    dispatch(actStartEventExecuteIntervention({
      interventionId,
      executeId,
      eventId: event.id,
      data: { state: 'ip' }
    }))
  }

  const openCompleteMilestoneConfirmation = ({ event, milestone }) => {
    milestoneRef.current = { event, milestone }
    setOpenModalCompleteMilestone(true)
  }

  const completeMilestoneEvent = () => {
    setOpenModalCompleteMilestone(false)
    const { event, milestone } = milestoneRef.current
    dispatch(actCompleteMilestoneEventExecuteIntervention({
      executeId,
      eventId: event.id,
      milestoneId: milestone.id,
      data: { state: 'c' }
    }))
  }

  const updateExecuteIntervention = () => {
    setOpenModalApprove(false)
    dispatch(actUpdateExecuteIntervention({
      interventionId,
      executeId,
      data: { state: 'c' }
    }))
  }

  return {
    executeIntervention,
    formComment,
    openModalCompleteMilestone,
    setOpenModalCompleteMilestone,
    openModalApprove,
    setOpenModalApprove,
    canFinishStep: _.every(executeIntervention.events, (e) => e.state.id === 'c'),
    startEvent,
    openCompleteMilestoneConfirmation,
    completeMilestoneEvent,
    updateExecuteIntervention
  }
}

export default useArtistExecuteIntervention