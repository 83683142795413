import React, { lazy } from 'react';

//Hooks
import useViews from '../../../../../../../../../../../..';
import PropTypes from 'prop-types';

const StyledGroup = lazy(() =>
  import(
    '../../../../../../../../../../../../../styles/formViews/formViews.styles'
  ).then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledContentButtonsModal = lazy(() =>
  import(
    '../../../../../../../../../../../../../styles/tableViews/tableViews.styles'
  ).then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const ModalDeny = (props) => {
  const { setViewActive, setDeny, formRejectKnowledge, handleClose } = props;
  const { useComponents } = useViews();

  const { useInputs, useTypographies, useButtons } = useComponents();
  const { TextArea } = useInputs();
  const { TypographyComponent } = useTypographies();
  const { ButtonComponent } = useButtons();

  const {
    control,

    formState: { errors },
    handleSubmit,
  } = formRejectKnowledge;

  return (
    <>
      <TypographyComponent
        text="Rechazar profundización"
        variant="h3"
        className="font-bold text-primary mb-1"
      />

      <StyledGroup className="mb-4 mt-4" noSpace>
        <TextArea
          control={control}
          name="rejected_reason"
          placeholder="Describe la actividad..."
          className="w-full col-span-2 "
          label="Escribe los motivos por los cuales no es posible realizar la profundización"
          errors={errors}
          hasController
        />
      </StyledGroup>

      <StyledContentButtonsModal>
        <ButtonComponent
          variant="primary"
          text="Cancelar"
          className="mx-auto w-full lg:w-auto lg:mx-2 "
          mode="outline"
          onPress={handleClose}
        />
        <ButtonComponent
          variant="primary"
          text="Enviar respuesta al artista"
          className="mx-auto w-full lg:w-auto  lg:mx-2"
          onPress={handleSubmit(() => {
            setDeny();
            setViewActive(3);
          })}
        />
      </StyledContentButtonsModal>
    </>
  );
};
ModalDeny.propTypes = {
  setViewActive: PropTypes.func.isRequired,
  setDeny: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  formRejectKnowledge: PropTypes.object.isRequired,
};
export default ModalDeny;
