import useHelpers from 'helpers';
import useStrings from 'strings';
import useInitialStates from '../../../initialStates';

const useIsFilterEmptyReducers = () => {
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  //Initial States
  const { useGeneralInitialStates } = useInitialStates();
  const { useIsFilterEmpty } = useGeneralInitialStates();
  const { initialStateIsFilterEmpty } = useIsFilterEmpty();

  //Strings
  const { useGeneralTypes } = useStrings();
  const { useIsFilterEmptyTypes } = useGeneralTypes();
  const { IS_FILTER_EMPTY_FALSE, IS_FILTER_EMPTY_TRUE } =
    useIsFilterEmptyTypes();

  const isFilterEmpty = createReducer(initialStateIsFilterEmpty, {
    [IS_FILTER_EMPTY_TRUE]() {
      return true;
    },
    [IS_FILTER_EMPTY_FALSE]() {
      return false;
    },
  });

  return { isFilterEmpty };
};

export default useIsFilterEmptyReducers;
