const useAuthTypes = () => {
  const LOGIN = 'LOGIN';
  const LOGOUT = 'LOGOUT';
  const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
  const RESET_PASSWORD = 'RESET_PASSWORD';
  const VALIDATE_TOKEN = 'VALIDATE_TOKEN';
  const PRE_REGISTER = 'PRE_REGISTER';
  return {
    LOGIN,
    LOGOUT,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    VALIDATE_TOKEN,
    PRE_REGISTER,
  };
};
export default useAuthTypes;
