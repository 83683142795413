//Packages
import React, { Suspense, lazy } from 'react';
import { Controller } from 'react-hook-form';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
const StyledForm = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledForm,
  }))
);

const StyledGroup = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledBackButton = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);

const AdminCreateProjects = () => {
  const { useLayouts, useComponents } = useViews();
  const { AdminLayout } = useLayouts();
  const {
    useButtons,
    useInputs,
    useTypographies,
    useBoxes,
    useImages,
    useLabels,
    useLoaders,
  } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { ButtonComponent } = useButtons();
  const { InputForm, Select, TextArea } = useInputs();
  const { TypographyComponent } = useTypographies();
  const { Box } = useBoxes();
  const { UploadImagePreview } = useImages();
  const { DefaultLabel } = useLabels();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminCreateProject } = useAdminProjects();
  const {
    errors,
    control,
    register,
    handleSubmit,
    handleRegisterProject,
    optionsCountries,
    optionsDepartments,
    optionsCities,
    optionsLanguages,
    optionsAdminUsers,
    optionsPartnerUsers,
    countrySelected,
    departmentSelected,
    goBack,
  } = useAdminCreateProject();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <AdminLayout>
        <>
          <StyledBackButton className="mt-3" onClick={goBack}>
            Regresar
          </StyledBackButton>
          <Box className="bg-neutral-200 w-11/12 mx-auto max-w-screen-lg lg:px-16 lg:py-12 px-6 py-8 lg:my-8">
            <>
              <TypographyComponent
                text="Crear nuevo proyecto"
                variant="h2"
                className="font-bold text-primary space-x-4 mb-4"
              />
              <TypographyComponent
                text="En hora buena! muchos éxitos con esta nueva aventura."
                variant="p"
                className="font-book text-neutral-950 mb-10"
              />
              <StyledForm>
                <StyledGroup className="items-center mb-2" col2>
                  <InputForm
                    type="text"
                    name="name"
                    placeholder="Ingresa el nombre del proyecto "
                    label="Nombre del proyecto*"
                    className="w-full"
                    errors={errors}
                    {...register('name')}
                  />
                </StyledGroup>

                <StyledGroup className="items-center" col2>
                  <TextArea
                    name="description"
                    placeholder="Agrega la descripción del proyecto"
                    className="w-full col-span-2"
                    label="Agregar descripción del proyecto*"
                    errors={errors}
                    {...register('description')}
                  />
                </StyledGroup>

                <StyledGroup>
                  <div className="col-span-2">
                    <div className="flex items-center w-full">
                      <DefaultLabel>
                        Agregar imagen de portada del proyecto
                      </DefaultLabel>
                      <TypographyComponent
                        text="Formato de archivo permitido JPG,PNG,JPEG"
                        variant="p"
                        className="text-neutral-800 ml-2"
                      />
                    </div>

                    <Controller
                      control={control}
                      name="image"
                      render={({ field: { onChange, name } }) => {
                        return (
                          <UploadImagePreview
                            name={name}
                            onChange={onChange}
                            errors={errors}
                            srcPlaceholderOnlyImage
                          />
                        );
                      }}
                    />
                  </div>
                </StyledGroup>

                <StyledGroup noSpace>
                  <Select
                    name="country"
                    id="country"
                    placeholder="Selecciona un país*"
                    control={control}
                    options={optionsCountries}
                    label="País*"
                    errors={errors}
                  />
                  <Select
                    name="department"
                    id="department"
                    placeholder="Selecciona un departamento o estado*"
                    control={control}
                    options={optionsDepartments}
                    label="Departamento o estado*"
                    errors={errors}
                    disabled={!countrySelected}
                  />
                </StyledGroup>
                <StyledGroup noSpace>
                  <Select
                    name="city_id"
                    id="city_id"
                    placeholder="Selecciona una ciudad o municipio*"
                    control={control}
                    options={optionsCities}
                    label="Ciudad o municipio*"
                    errors={errors}
                    disabled={!departmentSelected}
                  />
                  <InputForm
                    type="number"
                    name="population_number"
                    placeholder="Ingresa la población"
                    label="Población*"
                    className="w-full"
                    errors={errors}
                    {...register('population_number')}
                  />
                </StyledGroup>

                <StyledGroup>
                  <Select
                    name="languages"
                    id="languages"
                    placeholder="Selecciona los idiomas"
                    control={control}
                    options={optionsLanguages}
                    label="Idioma(s)*"
                    multiple
                    errors={errors}
                  />
                </StyledGroup>

                <StyledGroup>
                  <TypographyComponent
                    text="Asignar Project Manager, ASCC Expert y Socio Ejecutor"
                    variant="h3"
                    className="font-bold text-primary col-span-2 mb-1"
                  />
                </StyledGroup>

                <StyledGroup noSpace>
                  <Select
                    name="project_manager_id"
                    id="project_manager_id"
                    placeholder="Selecciona un Project Manager"
                    control={control}
                    options={optionsAdminUsers}
                    label="Project Manager"
                    errors={errors}
                  />
                  <Select
                    name="social_expert_id"
                    id="social_expert_id"
                    placeholder="Selecciona un ASCC Expert"
                    control={control}
                    options={optionsAdminUsers}
                    label="ASCC Expert"
                    errors={errors}
                  />
                </StyledGroup>

                <StyledGroup noSpace>
                  <Select
                    name="executing_partner_id"
                    id="executing_partner_id"
                    placeholder="Selecciona un socio ejecutor"
                    control={control}
                    options={optionsPartnerUsers}
                    label="Socio Ejecutor"
                    errors={errors}
                  />
                </StyledGroup>

                <div>
                  <ButtonComponent
                    variant="primary"
                    className="mx-auto mt-10"
                    text="Crear proyecto"
                    onPress={handleSubmit(handleRegisterProject)}
                  />
                </div>
              </StyledForm>
            </>
          </Box>
        </>
      </AdminLayout>
    </Suspense>
  );
};

export default AdminCreateProjects;
