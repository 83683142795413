import RecoverPasswordRoute from './RecoverPasswordRoute';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import ProtectedRoute from './Protected';

const useRoutes = () => {
  return { RecoverPasswordRoute, PrivateRoute, PublicRoute, ProtectedRoute };
};

export default useRoutes;
