//Packages
import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

//Hooks
import useApi from 'api';
import useHelpers from 'helpers';
import useModels from 'models';
import useControllers from 'controllers';

const useAdminKnowProjectDetailIntervention = () => {
  const { project_id } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const {
    useAdminAdditionalInfoActions,
    useAdminCommunitiesActions,
    useAdminDocumentationActions,
    useAdminFestivitiesAndTraditionsActions,
    useAdminRecommendationsActions,
    useAdminSocioCulturalInfoActions,
  } = useAdminProjectsActions();
  const { actGetAdditionalInfo } = useAdminAdditionalInfoActions();
  const { actGetCommunitiesByProject, actGetCommunityDetailById } =
    useAdminCommunitiesActions();
  const { actGetDocumentationByProject, actDownloadDocumentationByProject } =
    useAdminDocumentationActions();
  const { actGetFestivityAndTraditions } =
    useAdminFestivitiesAndTraditionsActions();
  const { actGetRecommendations } = useAdminRecommendationsActions();
  const { actGetSocioCulturalInfo } = useAdminSocioCulturalInfoActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const {
    useAdminAdditionalInfoSelectors,
    useAdminAbcTrajectorySelectors,
    useAdminCommunitiesSelectors,
    useAdminFestivitiesAndTraditionsSelectors,
    useAdminDocumentationSelectors,
    useAdminRecommendationsSelectors,
    useAdminSocioCulturalInfoSelectors,
  } = useAdminProjectsSelectors();

  const { communitiesListSelector, communitySelectedSelector } =
    useAdminCommunitiesSelectors();
  const { abcTrajectoriesListSelector } = useAdminAbcTrajectorySelectors();
  const { listAdditionalInfoSelector } = useAdminAdditionalInfoSelectors();
  const { listFestivitiesAndTraditionsSelector } =
    useAdminFestivitiesAndTraditionsSelectors();
  const { listDocumentationByProjectSelector } =
    useAdminDocumentationSelectors();
  const { listRecommendationsByProjectSelector } =
    useAdminRecommendationsSelectors();
  const { listSocioCulturalInfoByProjectSelector } =
    useAdminSocioCulturalInfoSelectors();

  const abcTrajectory = useSelector(abcTrajectoriesListSelector);
  const aditionalInfo = useSelector(listAdditionalInfoSelector);
  const communities = useSelector(communitiesListSelector);
  const festivities = useSelector(listFestivitiesAndTraditionsSelector);
  const documentation = useSelector(listDocumentationByProjectSelector);
  const recommendation = useSelector(listRecommendationsByProjectSelector);
  const socioCulturalInfo = useSelector(listSocioCulturalInfoByProjectSelector);
  const communitySelected = useSelector(communitySelectedSelector);

  const { useQuickFunctions } = useHelpers();
  const { convertThounsandsSeparator, showCityDepartmentAndCountry } =
    useQuickFunctions();

  const { useGeneralHooks } = useControllers();
  const { useToggleModal } = useGeneralHooks();
  const [openModalDetailCommunitie, setOpenModalDetailCommunitie] =
    useToggleModal();

  useEffect(() => {
    fetchDataProjectDetailIntervention();
  }, [pathname]);

  const handleDetailCommunitie = ({ communityId }) => {
    dispatch(
      actGetCommunityDetailById(
        { projectId: project_id, communityId },
        onSuccessGetCommunityDetailById
      )
    );
  };

  const onSuccessGetCommunityDetailById = () => setOpenModalDetailCommunitie();

  const fetchDataProjectDetailIntervention = () => {
    let type = pathname.split('/');
    type = type[type.length - 1];
    switch (type) {
      case 'communities':
        dispatch(actGetCommunitiesByProject({ projectId: project_id }));
        break;
      case 'socio-cultural-info':
        dispatch(actGetSocioCulturalInfo({ projectId: project_id }));
        break;
      case 'festivities':
        dispatch(actGetFestivityAndTraditions({ projectId: project_id }));
        break;
      case 'abc-trajectory':
        break;
      case 'additional-info':
        dispatch(actGetAdditionalInfo({ projectId: project_id }));
        break;
      case 'documentation':
        dispatch(actGetDocumentationByProject({ projectId: project_id }));
        break;
      case 'recommendations':
        dispatch(actGetRecommendations({ projectId: project_id }));
        break;
      default:
        break;
    }
  };

  const preViewFile = ({ url }) => {
    window.open(url, '_blank');
  };

  const goBack = () => history.goBack();

  const downloadFileDocumentation = (documentation) => {
    dispatch(
      actDownloadDocumentationByProject(
        {
          projectId: project_id,
          documentationId: documentation.id,
        },
        (response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/pdf' })
          );
          var link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `PlanCreativo-${documentation.name}.pdf`
          );
          document.body.appendChild(link);
          link.click();
        }
      )
    );
  };
  return {
    abcTrajectory,
    aditionalInfo,
    communities,
    festivities,
    documentation,
    recommendation,
    socioCulturalInfo,
    goBack,
    preViewFile,
    downloadFileDocumentation,
    openModalDetailCommunitie,
    setOpenModalDetailCommunitie,
    handleDetailCommunitie,
    convertThounsandsSeparator,
    communitySelected,
    showCityDepartmentAndCountry,
  };
};
export default useAdminKnowProjectDetailIntervention;
