//Packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';

//Components
import Quantitative from '../../../../components/Alphanumeric/Quantitative';

//Styles
import { StyledBackButton } from 'styles/formViews/formViews.styles';

const Detail = (props) => {
  const {
    setActiveItem,
    handleResetSelectedDetailMessage,
    selectedMessage,
    dateExists,
    getMonthAndDay,
    onFormatMessageAboutCommunities,
  } = props;

  const { useComponents } = useViews();

  const { useTypographies } = useComponents();

  const { TypographyComponent } = useTypographies();

  useEffect(() => {
    return () => {
      handleResetSelectedDetailMessage();
    };
  }, []);

  return (
    <>
      <div className="flex items-center">
        <StyledBackButton
          className="pl-1"
          onClick={() => {
            setActiveItem((prevValue) => ({
              ...prevValue,
              idSelectedMessage: '',
              active: 0,
            }));
          }}
        />
      </div>
      <TypographyComponent
        text="Mensaje"
        variant="h2"
        className="font-bold text-primary mt-4 mr-1"
      />
      <p className="text-neutral-800 mb-12">
        Fecha de envío del mensaje:{' '}
        <strong>
          {dateExists({
            sendedDate:
              selectedMessage.sended_date &&
              getMonthAndDay({
                date: selectedMessage.sended_date,
              }),
            suggestedDate:
              selectedMessage.send_date &&
              getMonthAndDay({ date: selectedMessage.send_date }),
            statusIdMessage: selectedMessage.state?.id,
          })}{' '}
        </strong>
      </p>
      <div className="mb-4">
        <TypographyComponent
          text={
            <>
              <span className="font-bold text-primary inline-block mr-1">
                Canal de comunicación:
              </span>
              {selectedMessage?.channel?.name}
            </>
          }
          variant="p"
        />
      </div>
      <div className="mb-4">
        <TypographyComponent
          text={
            <>
              <span className="font-bold text-primary inline-block mr-1">
                Destino:
              </span>
              {onFormatMessageAboutCommunities({ message: selectedMessage })}
            </>
          }
          variant="p"
        />
      </div>
      <div className="mb-4">
        <TypographyComponent
          text={
            <>
              <span className="font-bold text-primary inline-block mr-1">
                Mensaje:
              </span>
              {selectedMessage?.message}
            </>
          }
          variant="p"
        />
      </div>

      <div className="hidden bg-error-50" />

      {selectedMessage.state?.id === 's' && (
        <Quantitative dataCollectionInformationSelected={selectedMessage} />
      )}
    </>
  );
};

Detail.propTypes = {
  setActiveItem: PropTypes.func.isRequired,
  handleResetSelectedDetailMessage: PropTypes.func,
  selectedMessage: PropTypes.object,
  dateExists: PropTypes.func,
  getMonthAndDay: PropTypes.func,
  onFormatMessageAboutCommunities: PropTypes.func,
};

Detail.defaultProps = {
  handleResetSelectedDetailMessage: () => {},
  selectedMessage: {},
  dateExists: () => {},
  getMonthAndDay: () => {},
  onFormatMessageAboutCommunities: () => {},
};

export default Detail;
