const useAdminDevelopmentExecuteInterventionTypes = () => {
  const GET_EXECUTE_MATERIALIZE_INTERVENTION =
    'GET_EXECUTE_MATERIALIZE_INTERVENTION';
  const UPDATE_EXECUTE_MATERIALIZE_INTERVENTION =
    'UPDATE_EXECUTE_MATERIALIZE_INTERVENTION';
  const UPLOAD_FILE_EXECUTE_MATERIALIZE_INTERVENTION =
    'UPLOAD_FILE_EXECUTE_MATERIALIZE_INTERVENTION';
  const DELETE_FILE_EXECUTE_MATERIALIZE_INTERVENTION =
    'DELETE_FILE_EXECUTE_MATERIALIZE_INTERVENTION';

  const GET_EXECUTE_INTERVENTION =
    'GET_EXECUTE_INTERVENTION';
  const UPDATE_EXECUTE_INTERVENTION =
    'UPDATE_EXECUTE_INTERVENTION';
  const CREATE_EVENT_EXECUTE_INTERVENTION =
    'CREATE_EVENT_EXECUTE_INTERVENTION';
  const DELETE_EVENT_EXECUTE_INTERVENTION =
    'DELETE_EVENT_EXECUTE_INTERVENTION';
  const START_EVENT_EXECUTE_INTERVENTION =
    'START_EVENT_EXECUTE_INTERVENTION';
  const COMPLETE_MILESTONE_EVENT_EXECUTE_INTERVENTION =
    'COMPLETE_MILESTONE_EVENT_EXECUTE_INTERVENTION';

  const GET_EXECUTE_EVALUATE_INTERVENTION =
    'GET_EXECUTE_EVALUATE_INTERVENTION';
  const UPDATE_EXECUTE_EVALUATE_INTERVENTION =
    'UPDATE_EXECUTE_EVALUATE_INTERVENTION';
  const UPLOAD_FILE_EXECUTE_EVALUATE_INTERVENTION =
    'UPLOAD_FILE_EXECUTE_EVALUATE_INTERVENTION';
  const DELETE_FILE_EXECUTE_EVALUATE_INTERVENTION =
    'DELETE_FILE_EXECUTE_EVALUATE_INTERVENTION';

  return {
    GET_EXECUTE_MATERIALIZE_INTERVENTION,
    UPDATE_EXECUTE_MATERIALIZE_INTERVENTION,
    UPLOAD_FILE_EXECUTE_MATERIALIZE_INTERVENTION,
    DELETE_FILE_EXECUTE_MATERIALIZE_INTERVENTION,
    GET_EXECUTE_INTERVENTION,
    UPDATE_EXECUTE_INTERVENTION,
    CREATE_EVENT_EXECUTE_INTERVENTION,
    DELETE_EVENT_EXECUTE_INTERVENTION,
    START_EVENT_EXECUTE_INTERVENTION,
    COMPLETE_MILESTONE_EVENT_EXECUTE_INTERVENTION,
    GET_EXECUTE_EVALUATE_INTERVENTION,
    UPDATE_EXECUTE_EVALUATE_INTERVENTION,
    UPLOAD_FILE_EXECUTE_EVALUATE_INTERVENTION,
    DELETE_FILE_EXECUTE_EVALUATE_INTERVENTION,
  };
};

export default useAdminDevelopmentExecuteInterventionTypes;
