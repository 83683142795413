//Packages
import React from 'react';
import { Switch, useRouteMatch, Redirect } from 'react-router-dom';

//Hooks
import useNavigation from '../../../../../../..';
import useViews from '../../../../../../../../views';

const AdminProjectsRouterDashboardInterventions = () => {
  const { path } = useRouteMatch();

  const { useRoutes } = useNavigation();
  const { ProtectedRoute } = useRoutes();

  //Screens
  const { useScreens } = useViews();
  const {
    AdminDashboardRootInterventions,
    AdminDashboardEditInterventions,
    AdminDashboardCreateInterventions,
    AdminDashboardViewInterventions,
    AdminDashboardViewInterventionsCommunities,
    AdminDashboardViewInterventionsSocialInfo,
    AdminDashboardViewInterventionsFestivities,
    AdminDashboardViewInterventionsTrayectory,
    AdminDashboardViewInterventionsAditionalInfo,
    AdminDashboardViewInterventionsDocumentation,
  } = useScreens();

  return (
    <Switch>
      <ProtectedRoute
        exact
        path={`${path}`}
        component={AdminDashboardRootInterventions}
        redirect="/"
        role={['ad', 'ep']}
      />
      <ProtectedRoute
        exact
        path={`${path}/create`}
        component={AdminDashboardCreateInterventions}
        redirect="/"
        role={['ad', 'ep']}
      />

      <ProtectedRoute
        exact
        path={`${path}/edit/:intervention_id`}
        component={AdminDashboardEditInterventions}
        redirect="/"
        role={['ad', 'ep']}
      />
      <ProtectedRoute
        exact
        path={`${path}/:intervention_id`}
        component={AdminDashboardViewInterventions}
        redirect="/"
        role={['ad', 'ep', 'ar']}
      />
      <ProtectedRoute
        exact
        path={`${path}/:intervention_id/communities`}
        component={AdminDashboardViewInterventionsCommunities}
        redirect="/"
        role={['ar']}
      />
      <ProtectedRoute
        exact
        path={`${path}/:intervention_id/socio-cultural-info`}
        component={AdminDashboardViewInterventionsSocialInfo}
        redirect="/"
        role={['ar']}
      />
      <ProtectedRoute
        exact
        path={`${path}/:intervention_id/festivities`}
        component={AdminDashboardViewInterventionsFestivities}
        redirect="/"
        role={['ar']}
      />
      <ProtectedRoute
        exact
        path={`${path}/:intervention_id/abc-trajectory`}
        component={AdminDashboardViewInterventionsTrayectory}
        redirect="/"
        role={['ar']}
      />
      <ProtectedRoute
        exact
        path={`${path}/:intervention_id/additional-info`}
        component={AdminDashboardViewInterventionsAditionalInfo}
        redirect="/"
        role={['ar']}
      />
      <ProtectedRoute
        exact
        path={`${path}/:intervention_id/documentation`}
        component={AdminDashboardViewInterventionsDocumentation}
        redirect="/"
        role={['ar']}
      />

      <Redirect to={`${path}`} />
    </Switch>
  );
};

export default AdminProjectsRouterDashboardInterventions;
