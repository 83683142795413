import useCountriesTypes from './countries';
import useLanguagesTypes from './languages';
import useLegalDataTypes from './legalData';
import useModalsTypes from './modals';
import useSpecialitiesTypes from './specialities';
import useColorsTypes from './colors';
import useAccessibilityTypes from './accessibility';
import useSidebarTypes from './sidebar';
import useNotificationTypes from './notifications';
import useTabSelectedTypes from './tabSelected';
import useIsFilterEmptyTypes from './isFilterEmpty';

const useGeneralTypes = () => {
  return {
    useCountriesTypes,
    useLegalDataTypes,
    useLanguagesTypes,
    useModalsTypes,
    useSpecialitiesTypes,
    useColorsTypes,
    useAccessibilityTypes,
    useSidebarTypes,
    useNotificationTypes,
    useTabSelectedTypes,
    useIsFilterEmptyTypes,
  };
};

export default useGeneralTypes;
