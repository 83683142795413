import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const StyledWrapperContactTable = styled.div.attrs(() => ({
  className: 'StyledWrapperContactTable',
}))`
  ${tw` py-8 px-4`}
  min-height: 450px;
`;

export const StyledWrapperTags = styled.div.attrs(() => ({
  className: 'StyledWrapperTags',
}))`
  ${tw` flex  flex-wrap`}
`;

export const StyledBadgeTag = styled.div.attrs(() => ({
  className: 'StyledBadgeTag',
}))`
  .StyledActionBadge {
    ${tw` !py-2 !rounded-full !px-3 !text-sm mr-3 mb-3 `}
    ${(props) => props.bgColor && `background-color:${props.bgColor}`}
  }
  .StyledDeleteBadge {
    ${tw` text-xs `}
  }
`;

export const StyledButtonAddTag = styled.button.attrs(() => ({
  className: 'StyledButtonAddTag',
}))`
  ${tw` rounded-full border-2 border-primary w-6 h-6 flex items-center justify-center mr-3 opacity-50 mt-0.5`}
  &:before {
    margin-top: 0.1rem;
  }
`;

export const StyledWrapperButtonList = styled.div.attrs(() => ({
  className: 'StyledWrapperButtonList',
}))`
  ${tw` flex space-x-4`}
  .button-none {
    ${tw` bg-transparent text-primary shadow-none `}
  }
`;

export const StyledWrapperListDropDown = styled.div.attrs(() => ({
  className: 'StyledWrapperListDropDown',
}))`
  ${tw` relative`}
`;
