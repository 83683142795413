//Packages
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//Hooks
import useApi from 'api';
import useModels from 'models';
import useStrings from 'strings';
import useHelpers from 'helpers';

const useAdminCreateSketchInterventions = () => {
  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();
  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const { useAdminDevelopmentInterventionCreateSketchActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const {
    actGetCreateSketchProjectIntervention,
    actUpdateCreateSketchProjectIntervention,
    actDownloadCreateSketchProjectIntervention,
  } = useAdminDevelopmentInterventionCreateSketchActions();

  const { useSelectors } = useModels();
  const { useAdminSelectors, useSelector } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionsSelector } = useAdminInterventionsSelectors();
  const { createSketch } = useSelector(getInterventionsSelector);

  const { intervention_id } = useParams();

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { REQUIRED_FIELD, DATE_GREATER_THAN_TODAY, FILE_SIZE } =
    useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const {
    isSelectedDateGreaterThanTodayDate,
    getYearMonthDayFromDate,
    downloadFileFromBackend,
    getFileValidations,
  } = useQuickFunctions();
  const { MAX_SIZE } = getFileValidations();

  const [showModalReject, setShowModalReject] = useState(false);
  const [showModalApprove, setShowModalApprove] = useState(false);

  useEffect(() => {
    if (createSketch.state?.id !== 'c') {
      dispatch(actGetCreateSketchProjectIntervention({ intervention_id }));
    }
  }, []);

  const schemaStepOneCreateSketch = yup.object({
    date_limit_checkbox: yup.boolean(),
    date_limit: yup.date().when('date_limit_checkbox', {
      is: true,
      then: yup
        .date()
        .required(REQUIRED_FIELD)
        .test('dateValidation', DATE_GREATER_THAN_TODAY, (value) => {
          return value
            ? isSelectedDateGreaterThanTodayDate({ selectedDate: value })
            : true;
        }),
      otherwise: yup
        .date()
        .notRequired()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr)),
    }),
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    resolver: yupResolver(schemaStepOneCreateSketch),
    mode: 'onChange',
  });

  const updateCreateSketchStepOneIntervention = (formData) => {
    let dataToSend = { state: 'ip' };
    formData.date_limit_checkbox &&
      formData.date_limit &&
      (dataToSend.date_limit = getYearMonthDayFromDate({
        dateInString: formData.date_limit,
        format: 'ymd',
      }));

    dispatch(
      actUpdateCreateSketchProjectIntervention({
        interventionId: intervention_id,
        sketchId: createSketch.id,
        data: dataToSend,
      })
    );
  };

  const openFile = ({ event, urlFile }) => {
    event && event.preventDefault();
    window.open(urlFile);
  };

  const downloadFile = ({ event, nameFile }) => {
    event && event.preventDefault();
    dispatch(
      actDownloadCreateSketchProjectIntervention(
        {
          interventionId: intervention_id,
          sketchId: createSketch.id,
          fileId: createSketch.sketch_file.id,
        },
        (response) => {
          downloadFileFromBackend({ response, nameFile });
        }
      )
    );
  };

  const schemaStepOneRejectSketch = yup.object({
    rejected_reason: yup.string().required(REQUIRED_FIELD),
    rejected_reason_file: yup
      .mixed()
      .required(REQUIRED_FIELD)
      .test('fileSize', FILE_SIZE, (value) => {
        if (value) {
          return value.size <= MAX_SIZE;
        }
        return true;
      }),
    date_limit_checkbox_two: yup.boolean(),
    date_r: yup.date().when('date_limit_checkbox_two', {
      is: true,
      then: yup
        .date()
        .required(REQUIRED_FIELD)
        .typeError(REQUIRED_FIELD)
        .test('dateValidation', DATE_GREATER_THAN_TODAY, (value) => {
          return value
            ? isSelectedDateGreaterThanTodayDate({ selectedDate: value })
            : true;
        }),
      otherwise: yup
        .date()
        .notRequired()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr)),
    }),
  });

  const {
    control: controlReject,
    register: registerReject,
    handleSubmit: handleSubmitReject,
    formState: { errors: errorsReject, isValid: isValidReject },
    watch: watchReject,
  } = useForm({
    resolver: yupResolver(schemaStepOneRejectSketch),
    mode: 'onChange',
  });

  const updateRejectSketchStepOneIntervention = ({ formData, state }) => {
    const bodyFormData = new FormData();

    formData.date_limit_checkbox_two &&
      formData.date_r &&
      ((formData.date_r = getYearMonthDayFromDate({
        dateInString: formData.date_r,
        format: 'ymd',
      })),
      bodyFormData.append('date_limit', formData.date_r));

    if (state === 'r') {
      bodyFormData.append('rejected_reason', formData.rejected_reason);
      bodyFormData.append(
        'rejected_reason_file',
        formData.rejected_reason_file
      );
    }

    bodyFormData.append('state', state);
    bodyFormData.append('_method', 'PATCH');

    dispatch(
      actUpdateCreateSketchProjectIntervention(
        {
          interventionId: intervention_id,
          sketchId: createSketch.id,
          data: bodyFormData,
        },
        state === 'c'
          ? () => setShowModalApprove(false)
          : () => setShowModalReject(false)
      )
    );
  };

  return {
    createSketch,
    control,
    register,
    handleSubmit,
    errors,
    watch,
    isValid,
    updateCreateSketchStepOneIntervention,
    openFile,
    downloadFile,

    showModalReject,
    showModalApprove,
    setShowModalApprove,
    setShowModalReject,

    controlReject,
    registerReject,
    handleSubmitReject,
    errorsReject,
    isValidReject,
    watchReject,
    updateRejectSketchStepOneIntervention,
  };
};

export default useAdminCreateSketchInterventions;
