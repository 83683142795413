import { useEffect, useState } from 'react';

const useDebaunced = (input = '', time = 500) => {
  const [debouce, setDebounce] = useState(input);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounce(input);
    }, time);
    return () => {
      clearTimeout(timeout);
    };
  }, [input]);
  return { debouce };
};
export default useDebaunced;
