//Packages
import React, { Suspense } from 'react';

//Assets
import iconArrowCollapsed from 'assets/img/ArrowCollapsed.svg';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
import { StyledBackButton } from 'styles/formViews/formViews.styles';
import { StyledBoxWhite } from 'styles/boxes/boxes.styles';

//Components Sections
import Section1Slides from './componentes/Section1Slides';
import Section3Belive from './componentes/Section3Belive';

const AdminRootHome = () => {
  const { useLayouts, useComponents } = useViews();

  const { AdminLayout } = useLayouts();
  const { useBoxes, useTypographies, useCollapses, useLoaders } =
    useComponents();
  const { DefaultLoader } = useLoaders();
  const { Box } = useBoxes();
  const { TypographyComponent } = useTypographies();
  const { DefaultCollapse } = useCollapses();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminHome } = useAdmin();
  const { useAdminHomeRoot } = useAdminHome();
  const { handleGoBack } = useAdminHomeRoot();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <AdminLayout>
        <StyledBackButton className="mt-3" onClick={handleGoBack}>
          Regresar
        </StyledBackButton>
        <Box className="bg-neutral-200 w-11/12 mx-auto max-w-screen-lg lg:px-16 lg:py-12 px-6 py-8 lg:my-8">
          <>
            <TypographyComponent
              text="Gestión de contenidos - home"
              variant="h2"
              className="font-bold text-primary mb-4"
            />
            <TypographyComponent
              text="Sección 1: Aquí puedes editar el texto que aparece en las diapositivas de la página de inicio. Esta sección tiene un máximo de 3 slides."
              variant="p"
              className="font-book text-neutral-950 mb-4"
            />
            <TypographyComponent
              text="Sección 2: Esta es la información del baner inferior 'creemos juntos'"
              variant="p"
              className="font-book text-neutral-950 mb-10"
            />
            <StyledBoxWhite>
              <DefaultCollapse
                title={
                  <TypographyComponent
                    text="Sección 1 - Slides"
                    variant="h4"
                    className="font-bold text-primary"
                  />
                }
                iconCollapsed={iconArrowCollapsed}
                className="mb-4"
                classNameTitle="justify-between mb-2"
                withUseState
              >
                <Section1Slides />
              </DefaultCollapse>
              <DefaultCollapse
                title={
                  <TypographyComponent
                    text="Sección 2 - Creémos juntos"
                    variant="h4"
                    className="font-bold text-primary"
                  />
                }
                iconCollapsed={iconArrowCollapsed}
                classNameTitle="justify-between mb-2"
                withUseState
              >
                <Section3Belive />
              </DefaultCollapse>
            </StyledBoxWhite>
          </>
        </Box>
      </AdminLayout>
    </Suspense>
  );
};

export default AdminRootHome;
