import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

//Hooks
import useControllers from 'controllers';
import useViews from 'views';

const InputSearch = (props) => {
  const { text, onDebounce, handlerIsOnFocus, disabled, placeholder } = props;
  const { useComponents } = useViews();
  const { useInputs } = useComponents();
  const { InputForm } = useInputs();
  const [textSearch, setTextSearch] = useState(text);
  const [isInputFocus, setIsInputFocus] = useState(false);
  const { useGeneralHooks } = useControllers();
  const { useDebaunced } = useGeneralHooks();
  const { debouce } = useDebaunced(textSearch);

  useEffect(() => {
    isInputFocus && onDebounce(debouce);
  }, [debouce]);

  return (
    <InputForm
      type="text"
      className="icon-search"
      name="search"
      placeholder={placeholder}
      defaultValue={textSearch}
      onChange={(e) => setTextSearch(e.target.value)}
      handlerIsOnFocus={(valor) => {
        setIsInputFocus(valor);
        handlerIsOnFocus(valor);
      }}
      disabled={disabled}
    />
  );
};
InputSearch.propTypes = {
  onDebounce: PropTypes.func.isRequired,
  text: PropTypes.string,
  handlerIsOnFocus: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};
InputSearch.defaultProps = {
  text: '',
  handlerIsOnFocus: () => {},
  disabled: false,
  placeholder: 'Buscar',
};
export default InputSearch;
