//Packages
import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Components
import Queantitative from '../../../../components/Alphanumeric/Quantitative';
import Qualitative from '../../../../components/Alphanumeric/Qualitative';
import Multimedia from '../../../../components/Multimedia';

//Hooks
import useViews from 'views';
import useHelpers from 'helpers';

//Styles
const StyledBackButton = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);

const StyledDivider = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledDivider,
  }))
);

const Detail = (props) => {
  const {
    activeItem,
    setActiveItem,
    dataCollectionInformationSelected,
    dataCollectionInformationAnswersSelected,
    handleChangePageAnswers,
    handleDownloadFileFromCollectionInformation,
  } = props;

  const { useQuickFunctions } = useHelpers();
  const { getYearMonthDayFromDate } = useQuickFunctions();

  const { useComponents } = useViews();

  const { useTypographies } = useComponents();

  const { TypographyComponent } = useTypographies();

  return (
    <>
      <div className="flex items-center">
        <StyledBackButton
          className="pl-1"
          onClick={() => {
            setActiveItem({ idCollectionInformation: '', active: 0 });
          }}
        />
      </div>
      <TypographyComponent
        text="Recolección de información"
        variant="h3"
        className="font-bold text-primary mt-4 "
      />
      <p className="text-neutral-800 mb-12">
        Fecha de envío del mensaje:
        <strong className="ml-2">
          {getYearMonthDayFromDate({
            dateInString:
              dataCollectionInformationSelected.send_date ||
              dataCollectionInformationSelected.created_at,
            format: 'dmyText',
          })}
        </strong>
      </p>
      <div className="mb-4">
        <TypographyComponent
          text={
            <>
              <span className="font-bold text-primary inline-block mr-1">
                Tipo de recolección:{' '}
              </span>
              {dataCollectionInformationSelected.gi_type?.name}
            </>
          }
          variant="p"
        />
      </div>

      {dataCollectionInformationSelected.gi_type?.id !== 'm' && (
        <div className="mb-4">
          <TypographyComponent
            text={
              <>
                <span className="font-bold text-primary inline-block mr-1">
                  Tipo de información a recolectar:
                </span>
                {dataCollectionInformationSelected?.type_information?.name}
              </>
            }
            variant="p"
          />
        </div>
      )}
      {dataCollectionInformationSelected.type_information?.id !== 'qn' && (
        <div className="mb-4">
          <TypographyComponent
            text={
              <>
                <span className="font-bold text-primary inline-block mr-1">
                  Canal de comunicación:
                </span>
                {dataCollectionInformationSelected?.channel?.name}
              </>
            }
            variant="p"
          />
        </div>
      )}

      <div className="mb-4">
        <TypographyComponent
          text={
            <>
              <span className="font-bold text-primary inline-block mr-1">
                Destino:
              </span>
              {activeItem.idCollectionInformation?.to}
            </>
          }
          variant="p"
        />
      </div>

      <StyledDivider />

      <TypographyComponent
        text="Seguimiento a la recolección de información"
        variant="h3"
        className="font-bold text-primary mt-4 "
      />

      <TypographyComponent
        text="Estamos en espera de respuestas por parte de la comunidad"
        variant="p"
        className="text-neutral-900  mt-4 "
      />

      <div className="mb-4 mt-8">
        <TypographyComponent
          text={
            <>
              <span className="font-bold text-primary block mr-1 ">
                {dataCollectionInformationSelected.gi_type?.id === 'm'
                  ? 'Pregunta o mensaje'
                  : 'Pregunta'}
              </span>
              <span className="font-bold text-lg">
                {_.split(dataCollectionInformationSelected.message, '\n1')?.[0]}
              </span>
            </>
          }
          variant="p"
        />
      </div>

      {dataCollectionInformationSelected.gi_type?.id !== 'm' ? (
        <>
          {dataCollectionInformationSelected.type_information?.id === 'qn' ? (
            <Queantitative
              dataCollectionInformationSelected={
                dataCollectionInformationSelected
              }
              dataCollectionInformationAnswersSelected={
                dataCollectionInformationAnswersSelected
              }
            />
          ) : (
            <Qualitative
              totalAnswers={Number(
                dataCollectionInformationSelected.result?.replied
              )}
              dataCollectionInformationAnswersSelected={
                dataCollectionInformationAnswersSelected
              }
              handleChangePageAnswers={handleChangePageAnswers}
            />
          )}
        </>
      ) : (
        <Multimedia
          totalAnswers={Number(
            dataCollectionInformationSelected.result?.replied
          )}
          dataCollectionInformationAnswersSelected={
            dataCollectionInformationAnswersSelected
          }
          handleChangePageAnswers={handleChangePageAnswers}
          handleDownloadFileFromCollectionInformation={({
            fileId,
            nameFile,
            extension,
          }) =>
            handleDownloadFileFromCollectionInformation({
              fileId,
              nameFile,
              extension,
            })
          }
        />
      )}
    </>
  );
};

Detail.propTypes = {
  setActiveItem: PropTypes.func,
  handleChangePageAnswers: PropTypes.func,
  handleDownloadFileFromCollectionInformation: PropTypes.func,
  activeItem: PropTypes.object,
  dataCollectionInformationSelected: PropTypes.object,
  dataCollectionInformationAnswersSelected: PropTypes.object,
};

Detail.defaultProps = {
  setActiveItem: () => {},
  handleChangePageAnswers: () => {},
  handleDownloadFileFromCollectionInformation: () => {},
  activeItem: {},
  dataCollectionInformationSelected: {},
  dataCollectionInformationAnswersSelected: {},
};

export default Detail;
