import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import _ from 'lodash';

// Hooks
import useViews from 'views';
import useControllers from 'controllers';

// Assets
import plusIcon from 'assets/icons/plus.svg';

// Styles
import { StyledGroup } from 'styles/formViews/formViews.styles';
import {
  StyledContentButtons,
  StyledContentButtonsModal,
} from 'styles/tableViews/tableViews.styles';
import {
  StyledWrapperCommunity,
  StyledCardCommunity,
} from '../../../../../../../../Dashboard.styles';

const MessageDeepenKnowledge = (props) => {
  const { typeForm, deepenType, messages, setMessages } = props;
  const { useComponents } = useViews();
  const { useTypographies, useInputs, useButtons, useImages, useModals } =
    useComponents();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { InputForm, TextArea, Select } = useInputs();
  const { ButtonComponent } = useButtons();
  const { Image } = useImages();
  const { DefaultModal } = useModals();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useDeepenKnowledgeCommunity } =
    useAdminInterventionsDashboardControllers();
  const { useAdminDeepenKnowledgeMessagesCommunityInterventions } =
    useDeepenKnowledgeCommunity();
  const {
    SEND_MESSAGE_CHANNEL_OPTIONS,
    LIST_OF_TYPE_OF_INFORMATION_COLLECTION,
    formMessage: {
      control,
      formState: { errors },
      handleSubmit,
      register,
      watch,
    },
    saveMessage,
    deleteMessage,
    handleAddMessage,
    answerOptions,
    viewButton,
    editMessage,
    cancelForm,
    editViewButton,
    validateDiffusion,
    showModal,
    setShowModal,
    handleAddOptions,
    handleOnChangeOptions,
    deleteOption,
    setValidateOptionsRules,
  } = useAdminDeepenKnowledgeMessagesCommunityInterventions({
    typeForm,
    deepenType,
    messages,
    setMessages,
  });

  return (
    <>
      <TypographyComponent
        text={
          !validateDiffusion
            ? 'Mensajes de difusión'
            : 'Mensaje de recolección de información'
        }
        variant="h2"
        className="text-primary mt-6 font-bold"
      />
      <TypographyComponent
        text={
          !validateDiffusion
            ? 'Si escogiste el método presencial, remota asincrónica o hibrida, tendrás que escribir un mensaje de difusión. Ten en cuenta que los mensajes se cobran por caracteres, es importante ser conciso en la comunicación. Este mensaje debe ser aprobado por el socio ejecutor antes de enviarse a la comunidad.'
            : 'Si escogiste el método remota asincrónica, tendrás que escribir un mensaje de recolección. Ten en cuenta que los mensajes se cobran por caracteres, es importante ser conciso en la comunicación. Este mensaje debe ser aprobado por el socio ejecutor antes de enviarse a la comunidad.'
        }
        variant="p"
        className="text-neutral-950 mt-2 mb-4"
      />

      <StyledWrapperCommunity
        className={
          messages.length !== 0
            ? 'visible opacity-100 h-auto'
            : 'invisible opacity-0 transition-all duration-100 h-0'
        }
      >
        <Accordion allowZeroExpanded>
          <>
            {messages.length > 0 &&
              _.map(messages, (message, index) => {
                return (
                  <AccordionItem
                    key={index}
                    uuid={`message-${index}`}
                    dangerouslySetExpanded={message.expanded}
                  >
                    {editViewButton && viewButton && (
                      <StyledCardCommunity py0={!message.description}>
                        <TypographyComponent
                          variant="p"
                          text={message?.description || ''}
                          className="flex-grow text-primary font-bold"
                        />

                        <StyledContentButtons>
                          <AccordionItemState>
                            {({ expanded }) =>
                              expanded ? (
                                ''
                              ) : (
                                <AccordionItemButton>
                                  <ButtonComponent
                                    text=""
                                    className="icon-edit text-primary-100"
                                    variant="white"
                                    onPress={() => {
                                      editMessage(index);
                                    }}
                                  />
                                </AccordionItemButton>
                              )
                            }
                          </AccordionItemState>

                          <ButtonComponent
                            text=""
                            className="icon-delete"
                            onPress={() => {
                              setShowModal({ show: true, index });
                            }}
                          />
                        </StyledContentButtons>
                      </StyledCardCommunity>
                    )}
                    <AccordionItemPanel>
                      <div className="border rounded py-8 px-6 mt-4">
                        <form className="px-4">
                          <StyledGroup col-2 className="mb-4 mt-4" noSpace>
                            <InputForm
                              name="description"
                              label="Descripción del mensaje*"
                              type="text"
                              placeholder="Ingresa una descripción para el mensaje"
                              className="w-full col-span-2"
                              errors={errors}
                              control={control}
                            />
                          </StyledGroup>
                          <StyledGroup col2 className="mb-4" noSpace>
                            {validateDiffusion && (
                              <Select
                                name="type_information"
                                id="type_information"
                                placeholder="Seleccione el tipo de informacion*"
                                control={control}
                                options={LIST_OF_TYPE_OF_INFORMATION_COLLECTION}
                                label="Tipo de informacion a recolectar*"
                                className="w-full"
                                errors={errors}
                              />
                            )}
                            <Select
                              name="channel"
                              id="channel"
                              placeholder="Selecciona el canal de comunicación"
                              control={control}
                              options={SEND_MESSAGE_CHANNEL_OPTIONS}
                              label="Canal de comunicación recomendado*"
                              className="w-full"
                              errors={errors}
                            />
                          </StyledGroup>
                          {!validateDiffusion && (
                            <StyledGroup col2 className="mb-4">
                              <TextArea
                                control={control}
                                name="body"
                                placeholder="Que apoyo quisiera recibir..."
                                className="w-full col-span-2"
                                label="Escribe el mensaje que recibirá la comunidad*"
                                errors={errors}
                              />
                            </StyledGroup>
                          )}

                          {validateDiffusion && (
                            <>
                              <TypographyWithTooltip
                                text="Pregunta"
                                variant="p"
                                textTooltip="Pregunta"
                                idTooltip="know-ask"
                                className="text-neutral-900"
                                applyFontBold
                              />
                              <StyledGroup col2 className="mb-4">
                                <InputForm
                                  name="body"
                                  label=""
                                  type="text"
                                  placeholder="Ingresa la pregunta"
                                  className="w-full col-span-2"
                                  errors={errors}
                                  {...register('body')}
                                />
                              </StyledGroup>
                              {watch('type_information')?.value === 'qn' && (
                                <>
                                  {_.map(answerOptions, (option, idx) => {
                                    return (
                                      <div
                                        className="flex items-center space-x-6"
                                        key={option.id}
                                      >
                                        <TypographyComponent
                                          variant="p"
                                          text={`Opción ${idx + 1}`}
                                          className="text-primary font-bold flex-none"
                                        />
                                        <InputForm
                                          name={`options[${idx}]`}
                                          label=""
                                          type="text"
                                          placeholder={`Ingresa la opción ${
                                            idx + 1
                                          }`}
                                          className="flex-1"
                                          errors={errors}
                                          control={control}
                                          value={option.value}
                                          onChange={(event) => {
                                            handleOnChangeOptions({
                                              optionIndex: option.id,
                                              optionValue: event.target.value,
                                            });
                                            setValidateOptionsRules(
                                              (prevState) => !prevState
                                            );
                                          }}
                                        />
                                        <StyledContentButtons>
                                          <ButtonComponent
                                            text=""
                                            className="bg-transparent text-tertiary"
                                            onPress={(e) => {
                                              deleteOption({
                                                e,
                                                optionId: idx,
                                              });
                                            }}
                                            icon={
                                              <div className={`icon-delete`} />
                                            }
                                          />
                                        </StyledContentButtons>
                                      </div>
                                    );
                                  })}
                                  <ButtonComponent
                                    variant="primary"
                                    mode="outline"
                                    text="Agregar otra opción"
                                    className="mb-2"
                                    icon={
                                      <div className="icon-close transform rotate-45" />
                                    }
                                    onPress={handleAddOptions}
                                    disabled={answerOptions.length === 10}
                                  />
                                </>
                              )}
                              {errors['options'] && (
                                <p className="text-sm mt-2 text-error">
                                  {errors['options'].message}
                                </p>
                              )}
                            </>
                          )}

                          <div className="flex justify-end items-center space-x-4 pb-8">
                            <AccordionItemButton>
                              <ButtonComponent
                                text="Cancelar"
                                className="mt-6"
                                variant="primary"
                                mode="outline"
                                onPress={(e) => {
                                  e.preventDefault();
                                  cancelForm(index);
                                }}
                              />
                            </AccordionItemButton>
                            <ButtonComponent
                              text="Guardar mensaje"
                              className="mt-6"
                              variant="primary"
                              onPress={handleSubmit((data) => {
                                saveMessage(data, index);
                              })}
                            />
                          </div>
                        </form>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                );
              })}
          </>
        </Accordion>
      </StyledWrapperCommunity>
      {viewButton && messages.length < 5 && (
        <ButtonComponent
          text="Agregar mensaje"
          mode="outline"
          className="mt-4 border-none shadow-none"
          icon={
            <Image
              srcImage={plusIcon}
              altImage="icon-plus"
              titleImage="icon-plus"
              className="w-6 mr-2"
            />
          }
          onPress={handleAddMessage}
        />
      )}
      <DefaultModal
        isActive={showModal.show}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={() => {
          setShowModal({ show: false });
        }}
      >
        <TypographyComponent
          text={showModal.message || 'Eliminar Mensaje'}
          variant="h3"
          className="font-bold text-primary mb-1"
        />
        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto lg:mx-2 "
            mode="outline"
            onPress={() => {
              setShowModal({ show: false });
            }}
          />
          <ButtonComponent
            variant="primary"
            text="Aceptar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={() => {
              if (!showModal.message) {
                deleteMessage(showModal.index);
              }
              setShowModal({ show: false });
            }}
          />
        </StyledContentButtonsModal>
      </DefaultModal>
    </>
  );
};
MessageDeepenKnowledge.propTypes = {
  typeForm: PropTypes.string.isRequired,
  deepenType: PropTypes.string.isRequired,
  messages: PropTypes.array.isRequired,
  setMessages: PropTypes.func.isRequired,
};
export default MessageDeepenKnowledge;
