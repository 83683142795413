import React from 'react';

const Footer = React.lazy(() => import('./Footer'));
const FooterLink = React.lazy(() => import('./FooterLink'));
const FooterNav = React.lazy(() => import('./FooterNav'));
const FooterCopyRight = React.lazy(() => import('./FooterCopyRight'));
const FooterPowerBy = React.lazy(() => import('./FooterPowerBy'));

const useFooters = () => {
  return { Footer, FooterLink, FooterNav, FooterCopyRight, FooterPowerBy };
};

export default useFooters;
