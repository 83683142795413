//Packages
import React, { Suspense, useState } from 'react';
import _ from 'lodash';

//Assets
import iconUser from 'assets/icons/user.svg';
import imageUser from 'assets/img/fakeUserPhotoSociio.jpg';
import arrowDown from 'assets/icons/arrow-down-table.svg';

//Components
import DropDownFilter from './components/DropDownFilter';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
import {
  StyledTopFilters,
  StyledFilters,
  StyledInputSearchContent,
  StyledContentTable,
  StyledContentButtons,
  StyledContentButtonsModal,
} from './Root.styles';
import { StyledTableTitle } from 'styles/tableViews/tableViews.styles';

const AdminRootUsers = () => {
  const { useLayouts, useComponents } = useViews();
  const { AdminLayout } = useLayouts();
  const {
    useButtons,
    useImages,
    useTables,
    usePagers,
    useModals,
    useTypographies,
    useInputs,
    useCards,
    useLoaders,
  } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { CardEmptyState } = useCards();
  const { InputSearch } = useInputs();
  const { ButtonComponent } = useButtons();
  const { Image } = useImages();
  const { Pager } = usePagers();
  const { DefaultModal, UserInfoModal } = useModals();
  const { TypographyComponent } = useTypographies();
  const { Table, TableHead, TableRow, TableHeadCell, TableCell, TableBody } =
    useTables();

  const [activeModal, setActiveModal] = useState({ active: false });
  const [viewUser, setViewUser] = useState(false);
  const [isOnFocusTextSearch, setIsOnFocusTextSearch] = useState(false);
  const [activeDropDown, setActiveDropDown] = useState(false);

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useUserAdmin } = useAdmin();
  const { useUser } = useUserAdmin();
  const {
    isFilterEmpty,
    history,
    users,
    deleteUser,
    setTextSearch,
    getUsersPage,
    getUser,
    defaultValueTextSearch,
    handleOrderBy,
    orderBy,
    current_page,
  } = useUser();

  const tableHeader = [
    { header: '' },
    { header: 'Nombre ', orderByKey: 'name' },
    { header: 'Apellido', orderByKey: 'last_name' },
    { header: 'Correo electrónico', orderByKey: 'email' },
    { header: 'Rol', orderByKey: 'role' },
    { header: 'País', orderByKey: 'country' },
    { header: 'Depto/Estado', orderByKey: 'department' },
    { header: 'Cdad/Mpio', orderByKey: 'city' },
    { header: 'Teléfono', orderByKey: 'phone' },
    { header: '' },
  ];

  const toggleFilters = () => {
    setActiveDropDown(!activeDropDown);
  };

  return (
    <Suspense fallback={<DefaultLoader />}>
      <AdminLayout>
        <>
          <StyledTopFilters>
            <ButtonComponent
              text="Crear nuevo usuario"
              icon={
                <Image
                  srcImage={iconUser}
                  altImage="icon-user"
                  titleImage="icon-user"
                />
              }
              onPress={() => {
                history.push('/admin/users/create');
              }}
            />
            {!(!isFilterEmpty && users.data?.length === 0) && (
              <StyledFilters className="relative">
                <ButtonComponent
                  text="Filtros"
                  mode="outline"
                  className="icon-filter flex items-center space-x-2"
                  onPress={toggleFilters}
                />
                <StyledInputSearchContent>
                  <InputSearch
                    text={defaultValueTextSearch}
                    onDebounce={setTextSearch}
                    handlerIsOnFocus={(isOnFocus) =>
                      setIsOnFocusTextSearch(isOnFocus)
                    }
                  />
                </StyledInputSearchContent>
                <DropDownFilter
                  open={activeDropDown}
                  isOnFocusTextSearch={isOnFocusTextSearch}
                  handleOpenMenu={toggleFilters}
                />
              </StyledFilters>
            )}
          </StyledTopFilters>

          <StyledContentTable>
            <Table className="w-full relative">
              <TableHead>
                <TableRow>
                  {_.map(tableHeader, (item, idx) => (
                    <TableHeadCell
                      className={`text-left py-4 px-2 ${
                        !item.noneOrder && users.data?.length
                          ? 'cursor-pointer'
                          : 'cursor-not-allowed'
                      }`}
                      key={`tableHeader-${idx}`}
                      onClick={() => {
                        !item.noneOrder &&
                          users.data?.length &&
                          handleOrderBy({ orderByKey: item.orderByKey });
                      }}
                    >
                      {item.header !== '' && (
                        <StyledTableTitle>
                          <TypographyComponent
                            text={item.header}
                            variant="small"
                          />
                          {!item.noneOrder && (
                            <Image
                              srcImage={arrowDown}
                              className={`ml-2 ${
                                !orderBy[item.orderByKey] &&
                                users.data?.length &&
                                'order-arrow cursor-pointer'
                              }`}
                            />
                          )}
                        </StyledTableTitle>
                      )}
                    </TableHeadCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                <>
                  {users.data?.length > 0 &&
                    _.map(users.data, (user, idx) => (
                      <TableRow key={`tableRow-${idx}`}>
                        <TableCell className="py-2 px-5">
                          <Image
                            className="w-7 h-7 rounded-full mx-auto image-user "
                            srcImage={user.avatar || imageUser}
                          />
                        </TableCell>
                        <TableCell className="py-2">{user.name}</TableCell>
                        <TableCell className="py-2">{user.last_name}</TableCell>
                        <TableCell className="py-2">{user.email}</TableCell>
                        <TableCell className="py-2">{user.role_name}</TableCell>
                        <TableCell className="py-2">
                          {user.country_name}
                        </TableCell>
                        <TableCell className="py-2">
                          {user.departament_name}
                        </TableCell>
                        <TableCell className="py-2">{user.city_name}</TableCell>
                        <TableCell className="py-2">
                          {user.phone_complete}
                        </TableCell>
                        <TableCell className="py-2">
                          <StyledContentButtons>
                            <ButtonComponent
                              text=""
                              className="icon-view text-primary-100"
                              variant="tertiary"
                              onPress={() => {
                                getUser(user.id);
                                setViewUser(true);
                              }}
                            />
                            <ButtonComponent
                              text=""
                              className="icon-edit"
                              onPress={() => {
                                history.push(`/admin/users/edit/${user.id}`);
                              }}
                            />
                            <ButtonComponent
                              text=""
                              className="icon-delete"
                              onPress={() => {
                                setActiveModal({ active: true, user: user.id });
                              }}
                            />
                          </StyledContentButtons>
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              </TableBody>
            </Table>

            {users.data?.length === 0 && (
              <CardEmptyState
                className="w-8/12 mx-auto mt-16"
                title={
                  isFilterEmpty
                    ? 'No hay resultados, utiliza nuevos criterios de búsqueda'
                    : 'No se han encontrado usuarios ¡Crea el primero!'
                }
                button={
                  !isFilterEmpty ? (
                    <ButtonComponent
                      className="mx-auto"
                      text="Crear nuevo usuario"
                      icon={
                        <Image
                          srcImage={iconUser}
                          altImage="icon-user"
                          titleImage="icon-user"
                        />
                      }
                      onPress={() => {
                        history.push('/admin/users/create');
                      }}
                    />
                  ) : (
                    <></>
                  )
                }
              />
            )}
            <Pager
              pageCount={Math.ceil(users.meta.total / users.meta.per_page) || 1}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              previousLabel={Boolean(users.meta.prev)}
              nextLabel={Boolean(users.meta.next)}
              className="flex items-center lg:justify-end justify-center my-6"
              onPageChange={({ selected }) => getUsersPage(selected)}
              forcePage={current_page - 1}
            />
          </StyledContentTable>
        </>
      </AdminLayout>
      <DefaultModal
        isActive={activeModal.active}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={() => {
          setActiveModal({ active: false });
        }}
      >
        <TypographyComponent
          text="Eliminar usuario"
          variant="h3"
          className="font-bold text-primary mb-1"
        />
        <TypographyComponent
          text="Si estas seguro que deseas eliminar este usuario, presiona ELIMINAR. Si no, todavía no es tarde para cambiar de opinión, tienes la opción de CANCELAR"
          variant="p"
          className="text-neutral-950  mb-1"
        />
        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto lg:mx-2 "
            mode="outline"
            onPress={() => {
              setActiveModal({ active: false });
            }}
          />
          <ButtonComponent
            variant="primary"
            text="Eliminar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={() => {
              deleteUser(activeModal.user);
              setActiveModal({ active: false });
            }}
          />
        </StyledContentButtonsModal>
      </DefaultModal>

      <UserInfoModal
        viewUser={viewUser}
        setViewUser={setViewUser}
        user={users?.user}
      />
    </Suspense>
  );
};

export default AdminRootUsers;
