//Hooks
import useStrings from '../../../../strings';

const useTabSelectedActions = () => {
  const { useGeneralTypes } = useStrings();
  const { useTabSelectedTypes } = useGeneralTypes();
  const {
    SET_TAB_SELECTED,
    RESET_TAB_SELECTED,
    RESET_SUB_TAB_SELECTED,
    SET_SUB_TAB_SELECTED,
  } = useTabSelectedTypes();

  const actSetTabSelected =
    ({ tabValue }) =>
    (dispatch) => {
      dispatch({
        type: SET_TAB_SELECTED,
        payload: tabValue,
      });
    };

  const actResetTabSelected = () => (dispatch) => {
    dispatch({
      type: RESET_TAB_SELECTED,
    });
  };

  const actSetSubTabSelected =
    ({ tabValue }) =>
    (dispatch) => {
      dispatch({
        type: SET_SUB_TAB_SELECTED,
        payload: tabValue,
      });
    };

  const actResetSubTabSelected = () => (dispatch) => {
    dispatch({
      type: RESET_SUB_TAB_SELECTED,
    });
  };

  return {
    actSetTabSelected,
    actResetTabSelected,
    actSetSubTabSelected,
    actResetSubTabSelected,
  };
};

export default useTabSelectedActions;
