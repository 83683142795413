//Packages
import useHelpers from 'helpers';
import React, { lazy } from 'react';
import { Controller } from 'react-hook-form';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
const StyledButtonsActions = lazy(() =>
  import('../../../../../../../../../../../styles/buttons/buttons.styles').then(
    (mod) => ({
      default: mod.StyledButtonsActions,
    })
  )
);

const StyledWrapperCommunity = lazy(() =>
  import('../../../../../../Dashboard.styles').then((mod) => ({
    default: mod.StyledWrapperCommunity,
  }))
);

const StyledCardCommunity = lazy(() =>
  import('../../../../../../Dashboard.styles').then((mod) => ({
    default: mod.StyledCardCommunity,
  }))
);

const AsingPlan = () => {
  const { useComponents } = useViews();

  const { useTypographies, useInputs, useButtons, useCards } = useComponents();
  const { TypographyComponent } = useTypographies();
  const { CheckBox, InputFile, InputForm } = useInputs();
  const { ButtonComponent } = useButtons();
  const { CardNotification } = useCards();

  const { useQuickFunctions } = useHelpers();
  const { getDocumentName } = useQuickFunctions();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminCreativePlanIntervention } =
    useAdminInterventionsDashboardControllers();
  const {
    control,
    createCreativePlan,
    register,
    errors,
    watch,
    handleSubmit,
    isValid,
    planCreative,

    preViewPDFCreativePlan,
    dowloadCreativePlanFile,
  } = useAdminCreativePlanIntervention();

  return (
    <>
      {(planCreative?.state?.id === 'c' ||
        planCreative?.state?.id === 'ip') && (
        <CardNotification
          className="w-5/12 mx-auto py-9 px-4 mt-16"
          title={
            <TypographyComponent
              text={planCreative?.state?.name}
              variant="h2"
              className="font-bold text-primary icon-watch text-center"
            />
          }
        />
      )}
      <TypographyComponent
        text="Asignar plan creativo"
        variant="h2"
        className="font-bold text-primary"
      />
      <TypographyComponent
        text="Adjunta el documento del Plan Creativo asignado al artista de esta intervención  "
        variant="p"
        className="text-neutral-950 mt-2"
      />

      {planCreative?.state?.id === 'c' || planCreative?.state?.id === 'ip' ? (
        <>
          <div className="border rounded mt-8 px-6 pb-8 bg-neutral-300">
            <StyledWrapperCommunity nonemb>
              <StyledCardCommunity>
                <TypographyComponent
                  variant="p"
                  text={getDocumentName({
                    file: planCreative?.file,
                    withExtension: true,
                  })}
                  className="flex-grow text-primary font-bold cursor-pointer"
                />
                <StyledButtonsActions className="flex items-center">
                  <ButtonComponent
                    text=""
                    className="icon-view"
                    onPress={preViewPDFCreativePlan}
                  />
                  <ButtonComponent
                    text=""
                    icon={
                      <div className="icon-download text-primary text-lg" />
                    }
                    onPress={dowloadCreativePlanFile}
                  />
                </StyledButtonsActions>
              </StyledCardCommunity>
            </StyledWrapperCommunity>
          </div>
        </>
      ) : (
        <>
          <TypographyComponent
            text="Para asignar una fecha límite para la revisión de este documento por parte del artista has click en el recuadro inferior        "
            variant="p"
            className="text-neutral-950 mt-2"
          />

          <Controller
            control={control}
            name="date_limit_checkbox"
            render={({ field: { name, onChange } }) => {
              return (
                <CheckBox
                  name={name}
                  label="Asignar fecha limite para conocer el plan creativo por parte del artista"
                  className="mx-auto mt-6"
                  onChange={onChange}
                  errors={errors}
                />
              );
            }}
          />
          {watch('date_limit_checkbox') && (
            <InputForm
              name="date_limit"
              label=""
              type="date"
              placeholder="Selecciona la fecha limite"
              className="mt-8 w-2/12"
              errors={errors}
              {...register('date_limit')}
            />
          )}
          <Controller
            control={control}
            name="file"
            render={({ field: { onChange, name } }) => {
              return (
                <InputFile
                  name={name}
                  accept={'.pdf'}
                  title={
                    <div className="flex items-center">
                      <div className="icon-paper-clip text-primary text-xl font-bold h-10" />
                      <TypographyComponent
                        text="Adjuntar plan creativo"
                        variant="p"
                        className="text-primary font-bold"
                      />
                    </div>
                  }
                  onChange={onChange}
                  className="w-full mt-8"
                  errors={errors}
                />
              );
            }}
          />

          <ButtonComponent
            text="Enviar plan creativo"
            className="mx-auto mt-8"
            onPress={handleSubmit(createCreativePlan)}
            disabled={!isValid}
          />
        </>
      )}
    </>
  );
};

export default AsingPlan;
