//Packages
import _ from 'lodash';
import React, { Suspense, lazy, useState, useMemo } from 'react';

//Assets
import DropDownFilter from './components/DropDownFilter';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
const StyledTopFilters = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledTopFilters,
  }))
);

const StyledFilters = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledFilters,
  }))
);

const StyledInputSearchContent = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledInputSearchContent,
  }))
);

const StyledContentButtons = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const StyledContentButtonsModal = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const AdminRootProjects = () => {
  const { useLayouts, useComponents } = useViews();
  const { AdminLayout } = useLayouts();
  const {
    useButtons,
    useTables,
    useModals,
    useTypographies,
    useInputs,
    useLoaders,
  } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { InputSearch } = useInputs();
  const { ButtonComponent } = useButtons();
  const { DefaultModal } = useModals();
  const { TypographyComponent } = useTypographies();
  const { TableCustom } = useTables();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminRootProject } = useAdminProjects();
  const {
    isFilterEmpty,
    handleCreateProject,
    projectsListAux,
    handleChangePageProjects,
    showModalDeleteProject,
    handleChangeModalDeleteProject,
    handleDeleteProject,
    setProjectSelected,
    goToEditProject,
    textSearch,
    setTextSearch,
    handleChangeStateMenu,
    activeDropDown,
    handleOrderBy,
    // orderBy,
    handleGoToDashboardById,
    links,
    meta,
    currentPage,
    handleSetFirstPage,
  } = useAdminRootProject();

  const [isOnFocusTextSearch, setIsOnFocusTextSearch] = useState(false);

  const tableHeader = useMemo(() => {
    return [
      { Header: 'Nombre del proyecto ', orderByKey: 'name', accessor: 'name' },
      { Header: 'Creador', orderByKey: 'owner', accessor: 'owner' },
      {
        Header: 'País',
        orderByKey: 'country',
        accessor: 'country',
      },
      {
        Header: 'Depto/Estado',
        orderByKey: 'department',
        accessor: 'department',
        width: 180,
      },
      { Header: 'Cdad/Mpio', orderByKey: 'city', accessor: 'city' },
      {
        Header: 'Project manager',
        orderByKey: 'project_manager',
        accessor: 'project_manager',
        width: 211,
      },
      {
        Header: 'ASCC Expert',
        orderByKey: 'social_expert',
        accessor: 'social_expert',
      },
      {
        Header: 'Socio Ejecutor',
        orderByKey: 'executing_partner',
        accessor: 'executing_partner',
      },
      { Header: '', accessor: 'colOptions' },
    ];
  }, []);

  const projectsList = useMemo(() => {
    return _.map(projectsListAux, (project) => {
      return {
        id: project.id,
        name: project.name,
        owner: `${project?.owner?.name} ${project?.owner?.last_name}`,
        country: project?.city?.department?.country?.name,
        department: project?.city?.department?.name,
        city: project?.city?.name,
        project_manager:
          project.project_manager?.name &&
          `${project.project_manager?.name} ${project?.project_manager?.last_name}`,
        social_expert:
          project.social_expert?.name &&
          `${project.social_expert?.name} ${project?.social_expert?.last_name}`,
        executing_partner:
          project.executing_partner?.name &&
          `${project.executing_partner?.name} ${project?.executing_partner?.last_name}`,
        colOptions: (
          <StyledContentButtons>
            <ButtonComponent
              text=""
              className="icon-view text-primary-100"
              variant="primary"
              onPress={() => {
                handleGoToDashboardById({ projectId: project.id });
              }}
            />
            <ButtonComponent
              text=""
              className="icon-edit"
              onPress={() => {
                goToEditProject({ projectId: project.id });
              }}
            />
            <ButtonComponent
              text=""
              className="icon-delete"
              onPress={() => {
                handleChangeModalDeleteProject();
                setProjectSelected(project.id);
              }}
            />
          </StyledContentButtons>
        ),
      };
    });
  }, [currentPage, projectsListAux]);

  return (
    <Suspense fallback={<DefaultLoader />}>
      <AdminLayout>
        <>
          <StyledTopFilters>
            <ButtonComponent
              text="Crear nuevo proyecto"
              icon={<i className="icon-book text-lg" />}
              onPress={handleCreateProject}
            />
            {!(!isFilterEmpty && projectsList?.length === 0) && (
              <StyledFilters className="relative">
                <ButtonComponent
                  text="Filtros"
                  mode="outline"
                  className="icon-filter flex items-center space-x-2"
                  onPress={handleChangeStateMenu}
                />
                <StyledInputSearchContent>
                  <InputSearch
                    text={textSearch}
                    onDebounce={setTextSearch}
                    handlerIsOnFocus={(isOnFocus) =>
                      setIsOnFocusTextSearch(isOnFocus)
                    }
                  />
                </StyledInputSearchContent>
                <DropDownFilter
                  open={activeDropDown}
                  isOnFocusTextSearch={isOnFocusTextSearch}
                  handleChangeStateMenu={handleChangeStateMenu}
                  setCurrentPage={handleSetFirstPage}
                />
              </StyledFilters>
            )}
          </StyledTopFilters>

          <TableCustom
            columns={tableHeader}
            data={projectsList}
            meta={{ ...links, ...meta }}
            pagination={handleChangePageProjects}
            forcePage={currentPage - 1}
            setOrderData={handleOrderBy}
            buttonEmptyState={
              !isFilterEmpty ? (
                <ButtonComponent
                  className="mx-auto"
                  text="Crear nuevo proyecto"
                  icon={<i className="icon-book text-lg" />}
                  onPress={handleCreateProject}
                />
              ) : (
                <></>
              )
            }
            titleEmptyState="No se han encontrado proyectos ¡Crea el primero!"
          />
        </>
      </AdminLayout>

      <DefaultModal
        isActive={showModalDeleteProject}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={handleChangeModalDeleteProject}
      >
        <TypographyComponent
          text="Eliminar Proyecto"
          variant="h3"
          className="font-bold text-primary mb-1"
        />
        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto lg:mx-2 "
            mode="outline"
            onPress={handleChangeModalDeleteProject}
          />
          <ButtonComponent
            variant="primary"
            text="Aceptar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={handleDeleteProject}
          />
        </StyledContentButtonsModal>
      </DefaultModal>
    </Suspense>
  );
};

export default AdminRootProjects;
