//Packages
import React from 'react';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
import {
  StyledDropDownFilter,
  StyledWrapperFilter,
  StyledWarpperButtonFilters,
} from 'styles/filters/filters.styles';

const DropDownFilter = (props) => {
  const { className, open, handleChangeStateMenu, setCurrentPage } = props;

  const { useComponents } = useViews();
  const { useInputs, useButtons } = useComponents();
  const { Select } = useInputs();
  const { ButtonComponent } = useButtons();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminFilterProject } = useAdminProjects();
  const {
    control,
    errors,
    handleSubmit,
    optionsCountries,
    optionsCities,
    optionsDepartments,
    optionsAdminUsers,
    optionsPartnerUsers,
    handleFilterProject,
    handleResetFilterProject,
    countrySelected,
    departmentSelected,
    handleSetValueFilter,
  } = useAdminFilterProject({ handleChangeStateMenu, setCurrentPage });

  return (
    <StyledDropDownFilter className={className} open={open}>
      <StyledWrapperFilter>
        <Select
          name="owner_id"
          id="owner_id"
          placeholder="Seleccionar"
          control={control}
          options={optionsAdminUsers}
          label="Creador"
          errors={errors}
          onChange={handleSetValueFilter}
        />
        <Select
          name="country_id"
          id="country_id"
          placeholder="Seleccionar"
          control={control}
          options={optionsCountries}
          label="País"
          errors={errors}
          onChange={handleSetValueFilter}
        />
      </StyledWrapperFilter>
      <StyledWrapperFilter>
        <Select
          name="department_id"
          id="department_id"
          placeholder="Seleccionar"
          control={control}
          options={optionsDepartments}
          label="Departamento/Estado"
          errors={errors}
          disabled={!countrySelected}
          onChange={handleSetValueFilter}
        />
        <Select
          name="city_id"
          id="city_id"
          placeholder="Seleccionar"
          control={control}
          options={optionsCities}
          label="Ciudad/Municipio"
          errors={errors}
          disabled={!departmentSelected}
          onChange={handleSetValueFilter}
        />
        <Select
          name="project_manager_id"
          id="project_manager_id"
          placeholder="Seleccionar"
          control={control}
          options={optionsAdminUsers}
          label="Project Manager"
          errors={errors}
          onChange={handleSetValueFilter}
        />
        <Select
          name="social_expert_id"
          id="social_expert_id"
          placeholder="Seleccionar"
          control={control}
          options={optionsAdminUsers}
          label="ASCC expert"
          errors={errors}
          onChange={handleSetValueFilter}
        />
        <Select
          name="executing_partner_id"
          id="executing_partner_id"
          placeholder="Seleccionar"
          control={control}
          options={optionsPartnerUsers}
          label="Socio Ejecutor"
          errors={errors}
          onChange={handleSetValueFilter}
        />
      </StyledWrapperFilter>
      <StyledWarpperButtonFilters>
        <ButtonComponent
          variant="primary"
          mode="outline"
          className="mt-4 border-none shadow-none"
          text="Limpiar filtros"
          onPress={handleResetFilterProject}
        />
        <ButtonComponent
          variant="primary"
          className="mt-4"
          text="Aplicar filtros"
          onPress={handleSubmit(handleFilterProject)}
        />
      </StyledWarpperButtonFilters>
    </StyledDropDownFilter>
  );
};

DropDownFilter.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  isOnFocusTextSearch: PropTypes.bool,
  handleChangeStateMenu: PropTypes.func,
  setCurrentPage: PropTypes.func,
};

DropDownFilter.defaultProps = {
  className: '',
  open: false,
  isOnFocusTextSearch: false,
  handleChangeStateMenu: () => {},
  setCurrentPage: () => {},
};

export default DropDownFilter;
