import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useNotificationProviders = () => {
  const getNotificationProvider = ({ page, paginate }) => {
    const response = axios({
      method: 'GET',
      url: '/notifications',
      params: { page, paginate },
    });
    return trackPromise(response);
  };
  const deleteAllNotificationsProvider = () => {
    const response = axios({
      method: 'DELETE',
      url: '/notifications',
    });
    return trackPromise(response);
  };
  const deleteNotificationProvider = ({ id }) => {
    const response = axios({
      method: 'DELETE',
      url: `/notifications/${id}`,
    });
    return trackPromise(response);
  };
  const updateNotificationProvider = ({ id }) => {
    const response = axios({
      method: 'PATCH',
      url: `/notifications/${id}`,
      data: { readed: 1 },
    });
    return trackPromise(response);
  };
  return {
    getNotificationProvider,
    deleteAllNotificationsProvider,
    deleteNotificationProvider,
    updateNotificationProvider,
  };
};
export default useNotificationProviders;
