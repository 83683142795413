import AdminDashboardCreateInterventions from './Create';
import AdminDashboardEditInterventions from './Edit';
import AdminDashboardRootInterventions from './Root';
import AdminDashboardViewInterventions from './View';
import AdminDashboardViewInterventionsCommunities from './Communities';
import AdminDashboardViewInterventionsSocialInfo from './SocioCulturalInfo';
import AdminDashboardViewInterventionsFestivities from './FestivitiesAndTraditions';
import AdminDashboardViewInterventionsTrayectory from './AbcTrajectory';
import AdminDashboardViewInterventionsAditionalInfo from './AditionalInfo';
import AdminDashboardViewInterventionsDocumentation from './Documentation';

const useAdminProjectsDashboardInterventionsScreens = () => {
  return {
    AdminDashboardRootInterventions,
    AdminDashboardEditInterventions,
    AdminDashboardCreateInterventions,
    AdminDashboardViewInterventions,
    AdminDashboardViewInterventionsCommunities,
    AdminDashboardViewInterventionsSocialInfo,
    AdminDashboardViewInterventionsFestivities,
    AdminDashboardViewInterventionsTrayectory,
    AdminDashboardViewInterventionsAditionalInfo,
    AdminDashboardViewInterventionsDocumentation,
  };
};

export default useAdminProjectsDashboardInterventionsScreens;
