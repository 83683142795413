import React, { Suspense, lazy, useMemo } from 'react';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Assets
import imageFakeCard from 'assets/img/fake-card-example.jpg';

//Styles
const StyledBackButton = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);

const StyledBoxGrid3 = lazy(() =>
  import('styles/boxes/boxes.styles').then((mod) => ({
    default: mod.StyledBoxGrid3,
  }))
);

const AdminDashboardViewInterventionsCommunities = () => {
  const { useComponents } = useViews();

  const { useTypographies, useBoxes, useCards, useModals, useLoaders } =
    useComponents();
  const { DefaultLoader } = useLoaders();
  const { TypographyComponent } = useTypographies();
  const { Box } = useBoxes();
  const { CardCommunity } = useCards();
  const { DetailCommunityModal } = useModals();

  const { useGeneralHooks, useScreenHooks } = useControllers();
  const { useSelectLayoutByRole } = useGeneralHooks();
  const { LayoutSelected, props } = useSelectLayoutByRole();

  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminKnowProjectDetailIntervention } =
    useAdminInterventionsDashboardControllers();
  const {
    communities,
    goBack,
    openModalDetailCommunitie,
    setOpenModalDetailCommunitie,
    handleDetailCommunitie,
    convertThounsandsSeparator,
    communitySelected,
    showCityDepartmentAndCountry,
  } = useAdminKnowProjectDetailIntervention();
  const dataCommunitie = useMemo(
    () =>
      _.map(communities, (community) => {
        return {
          id: community.id,
          title: community.name,
          language: community.language,
          city: community.city,
          population_number: community.population_number,
          community_image: community.community_image,
        };
      }),
    [communities]
  );
  return (
    <Suspense fallback={<DefaultLoader />}>
      <LayoutSelected {...props}>
        <StyledBackButton
          className="mt-3"
          onClick={() => {
            goBack();
          }}
        >
          Regresar
        </StyledBackButton>
        <Box className="bg-neutral-200 w-12/12 mx-auto max-w-screen-2xl lg:px-16 lg:py-12 px-6 py-8 lg:my-8">
          <>
            <TypographyComponent
              text={'Comunidades del proyecto'}
              variant="h2"
              className="font-bold text-primary mb-5"
            />
            <TypographyComponent
              text={
                'Aquí puedes encontrar los detalles de cada una de las comunidades del proyecto. Dale click a cada comunidad para conocerla mejor.'
              }
              variant="p"
              className="mb-12"
            />

            <StyledBoxGrid3>
              {dataCommunitie.length > 0 &&
                _.map(dataCommunitie, (community, idx) => {
                  return (
                    <CardCommunity
                      key={`card-community-${idx}`}
                      hasContacts={false}
                      hasEdit={false}
                      hasDelete={false}
                      image={community?.community_image?.url || imageFakeCard}
                      title={community.title}
                      location={showCityDepartmentAndCountry({
                        city: community?.city,
                        textDepartment: 'deparmatment',
                        format: 'cityDepartmentCountry',
                      })}
                      badgesInformation={[
                        {
                          name: `${convertThounsandsSeparator(
                            community.population_number
                          )} ${
                            community?.population_number == 1
                              ? 'habitante'
                              : 'habitantes'
                          }`,
                        },
                        { name: `Idioma: ${community.language}` },
                      ]}
                      onViewCommunitie={() => {
                        handleDetailCommunitie({ communityId: community.id });
                      }}
                    />
                  );
                })}
            </StyledBoxGrid3>
          </>
        </Box>
      </LayoutSelected>

      <DetailCommunityModal
        isActive={openModalDetailCommunitie}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={setOpenModalDetailCommunitie}
        communityData={communitySelected}
      />
    </Suspense>
  );
};

export default AdminDashboardViewInterventionsCommunities;
