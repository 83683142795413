const useNotificationTypes = () => {
  const GET_NOTIFICATION = 'GET_NOTIFICATION';
  const DELETE_ALL_NOTIFICATIONS = 'DELETE_ALL_NOTIFICATIONS';
  const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
  const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
  return {
    GET_NOTIFICATION,
    DELETE_ALL_NOTIFICATIONS,
    DELETE_NOTIFICATION,
    UPDATE_NOTIFICATION,
  };
};
export default useNotificationTypes;
