import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useAdminRecommendationsProviders = () => {
  const getRecommendations = ({ projectId }) => {
    const response = axios({
      method: 'GET',
      url: `/projects/${projectId}/recommendations`,
    });

    return trackPromise(response);
  };

  const createRecommendations = ({ formData, projectId }) => {
    const response = axios({
      method: 'POST',
      headers: {
        'content-type': 'multipart/form-data',
      },
      url: `/projects/${projectId}/recommendations`,
      data: formData,
    });
    return trackPromise(response);
  };

  const updateRecommendations = ({ formData, recommendationId, projectId }) => {
    const response = axios({
      method: 'POST',
      url: `/projects/${projectId}/recommendations/${recommendationId}`,
      data: formData,
    });
    return trackPromise(response);
  };

  const deleteRecommendations = ({ projectId, recommendationId }) => {
    const response = axios({
      method: 'DELETE',
      url: `/projects/${projectId}/recommendations/${recommendationId}`,
    });
    return trackPromise(response);
  };

  return {
    getRecommendations,
    createRecommendations,
    updateRecommendations,
    deleteRecommendations,
  };
};

export default useAdminRecommendationsProviders;
