//Packages
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';

//Hooks
import useStrings from 'strings';
import useHelpers from 'helpers';
import useModels from 'models';
import useControllers from 'controllers';
import useApi from 'api';

const useAdminFestivitiesAndTraditionsDashboard = () => {
  const { project_id: projectId } = useParams();
  const history = useHistory();

  const [optionsCategories] = useState([
    { label: 'Festividad', value: 'festivity' },
    { label: 'Tradición', value: 'tradition' },
  ]);

  const [festivityId, setFestivityId] = useState(null);
  const [festivitySelectedData, setFestivitySelectedData] = useState(null);
  const [cleanFileInputs, setCleanFileInputs] = useState(false);
  const [viewCommunityFestivity, setViewCommunityFestivity] = useState(null);

  const [show, setShow] = useState(false);

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { REQUIRED_FIELD, FILE_TYPE, FILE_SIZE, MAX_LENGTH } = useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const { getFileValidations, getMimeTypeFromMedia } = useQuickFunctions();
  const { MAX_SIZE, SUPPORTED_FORMATS } = getFileValidations();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminFestivitiesAndTraditionsActions } = useAdminProjectsActions();
  const {
    actGetFestivityAndTraditions,
    actCreateCommunityFestivity,
    actDeleteCommunityFestivity,
    actUpdateCommunityFestivity,
  } = useAdminFestivitiesAndTraditionsActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminFestivitiesAndTraditionsSelectors } =
    useAdminProjectsSelectors();
  const { listFestivitiesAndTraditionsSelector } =
    useAdminFestivitiesAndTraditionsSelectors();
  const listProjectFestivity = useSelector(
    listFestivitiesAndTraditionsSelector
  );

  const { useGeneralHooks } = useControllers();
  const { useToggleModal } = useGeneralHooks();
  const [showDeleteFestivityModal, handleDeleteFestivityModal] =
    useToggleModal();
  const [showViewFestivityModal, handleViewFestivityModal] = useToggleModal();

  const schemaCreateCommunityFestivity = yup.object({
    name: yup.string().required(REQUIRED_FIELD),
    description: yup.string().required(REQUIRED_FIELD).max(40000, MAX_LENGTH),
    category: yup.object().required(REQUIRED_FIELD).nullable(),
    main: yup
      .mixed()
      .test('fileType', FILE_TYPE, (value) => {
        if (value) {
          return SUPPORTED_FORMATS.includes(value.type);
        }
        return true;
      })
      .test('fileSize', FILE_SIZE, (value) => {
        if (value) {
          return value.size <= MAX_SIZE;
        }
        return true;
      })
      .nullable()
      .notRequired(),

    first: yup
      .mixed()
      .nullable()
      .notRequired()
      .test('fileType', FILE_TYPE, (value) => {
        if (value) {
          return SUPPORTED_FORMATS.includes(value.type);
        }
        return true;
      })
      .test('fileSize', FILE_SIZE, (value) => {
        if (value) {
          return value.size <= MAX_SIZE;
        }
        return true;
      }),
    second: yup
      .mixed()
      .nullable()
      .notRequired()
      .test('fileType', FILE_TYPE, (value) => {
        if (value) {
          return SUPPORTED_FORMATS.includes(value.type);
        }
        return true;
      })
      .test('fileSize', FILE_SIZE, (value) => {
        if (value) {
          return value.size <= MAX_SIZE;
        }
        return true;
      }),
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schemaCreateCommunityFestivity),
    mode: 'onChange',
  });

  useEffect(() => {
    dispatch(actGetFestivityAndTraditions({ projectId }));
  }, []);

  const onSuccessCommunityFestivity = () => {
    setValue('name', '');
    setValue('description', '');
    setValue('category', null);
    setCleanFileInputs((prevState) => !prevState);
    setFestivitySelectedData(null);
    setShow((prevState) => !prevState);
  };

  const handleRegisterCommunityFestivity = (data) => {
    dispatch(
      actCreateCommunityFestivity(
        { ...data, projectId },
        onSuccessCommunityFestivity
      )
    );
  };

  const handleUpdateCommunityFestivity = (data) => {
    dispatch(
      actUpdateCommunityFestivity(
        { ...data, festivityId, projectId },
        onSuccessCommunityFestivity
      )
    );
  };

  const handleSelectDefaultImage = ({ typeMultimedia }) => {
    const defaultImage = _.find(
      festivitySelectedData?.multimedias,
      (multimedia) => multimedia.type === typeMultimedia
    );

    return defaultImage?.url ?? '';
  };

  const handleSelectTypeFile = (typeMultimedia) => {
    const defaultImage = _.find(
      festivitySelectedData?.multimedias,
      (multimedia) => multimedia.type === typeMultimedia
    );

    return getMimeTypeFromMedia({ urlFile: defaultImage?.url }) || 'image';
  };

  const handleAddCommunityFestivity = () => {
    setValue('name', '');
    setValue('description', '');
    setValue('category', null);
    setCleanFileInputs((prevState) => !prevState);
    setShow((prevState) => !prevState);
    setFestivitySelectedData(null);
  };

  const handleEditCommunityFestivity = ({ communityFestivity }) => {
    setValue('name', communityFestivity.name);
    setValue('description', communityFestivity.description);
    setValue('category', {
      label: communityFestivity.category.name,
      value: communityFestivity.category.id,
    });
    setFestivitySelectedData(communityFestivity);
    setShow(true);
    setCleanFileInputs((prevState) => !prevState);
  };

  const handleCancelCommunityFestivity = (e) => {
    e.preventDefault();
    reset({});
    setFestivitySelectedData(null);
    setCleanFileInputs((prevState) => !prevState);
    setShow((prevState) => !prevState);
  };

  const handleResetInputFile = ({ typeMultimedia }) => {
    setValue(typeMultimedia, '');
    clearErrors(typeMultimedia);
  };

  const handleDeleteCommunityFestivity = () => {
    dispatch(
      actDeleteCommunityFestivity({ festivityId, projectId }, () => {
        handleDeleteFestivityModal();
      })
    );
  };

  const handleGoBack = () =>
    history.push(`/admin/projects/dashboard/${projectId}`);

  const handleviewViewCommunityFestivity = (festivity) => {
    setViewCommunityFestivity(festivity);
  };

  return {
    control,
    register,
    handleSubmit,
    errors,
    listProjectFestivity,
    optionsCategories,
    handleRegisterCommunityFestivity,
    handleUpdateCommunityFestivity,
    show,
    showDeleteFestivityModal,
    handleDeleteFestivityModal,
    setFestivityId,
    festivitySelectedData,
    handleResetInputFile,
    cleanFileInputs,
    handleSelectDefaultImage,

    handleAddCommunityFestivity,
    handleCancelCommunityFestivity,
    handleDeleteCommunityFestivity,
    handleEditCommunityFestivity,
    handleSelectTypeFile,
    handleGoBack,

    showViewFestivityModal,
    handleViewFestivityModal,
    handleviewViewCommunityFestivity,
    viewCommunityFestivity,
  };
};

export default useAdminFestivitiesAndTraditionsDashboard;
