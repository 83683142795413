//Packages
import { useRef, useState } from 'react';
import useControllers from '../../..';

const useHomeSliders = ({ scrollToNameSection }) => {
  const [setHeadingSize] = useState({
    h1: 'lg:text-2xl',
    h2: 'lg:text-xl',
    h3: 'lg:text-lg',
    h4: 'lg:text-base',
    h5: 'lg:text-sm',
    h6: 'lg:text-xs',
    p: 'lg:text-base',
  });

  const [setHeadingSizeMobile] = useState({
    h1: 'text-xl',
    h2: 'text-lg',
    h3: 'text-base',
    h4: 'text-sm',
    h5: 'text-xs',
    h6: 'text-xs',
    p: 'text-base',
  });

  const { useGeneralHooks } = useControllers();
  const { useMakeAncla, useQueryParams } = useGeneralHooks();
  const slideToShowParams = useQueryParams();
  const slideToShowParamsRef = useRef(slideToShowParams.get('slideToShow'));

  const { elementToMakeAnclaRef } = useMakeAncla({ scrollToNameSection });

  const handleSetClassNameForHeading = (sliderRef) => {
    if (sliderRef) {
      sliderRef?.firstElementChild?.classList &&
        sliderRef.firstElementChild.classList.add(
          setHeadingSize[sliderRef.firstElementChild.tagName.toLowerCase()],
          setHeadingSizeMobile[
            sliderRef.firstElementChild.tagName.toLowerCase()
          ],
          'leading-tight'
        );
    }
  };

  return {
    handleSetClassNameForHeading,
    slideToShow: parseInt(slideToShowParamsRef.current),

    //makeAncla
    elementToMakeAnclaRef,
  };
};

export default useHomeSliders;
