//Packages
import React, { lazy } from 'react';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';
import useStrings from 'strings';

//Components
import FormMessage from './components/FormMessage';
import FormRequest from './components/FormRequest';

//Styles
const StyledForm = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledForm,
  }))
);

const StyledGroup = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledContentButtonsModal = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const Create = (props) => {
  const {
    setActiveItem,
    sendMessage,
    sendMessageDraft,
    activeConfirmModal,
    setActiveConfirmModal,
    formDiffusionMessage,
    optionsCommunities,
    activeAtLeastItemDraft,
    setActiveAtLeastItemDraft,
    handleAddOptions,
    setValidateOptionsRules,
    numberOptions,
    handleDeleteOption,
    handleOnChangeOptions,
  } = props;

  const { useComponents } = useViews();

  const { useTypographies, useInputs, useButtons, useModals } = useComponents();

  const { TypographyComponent, TypographyWithTooltip } = useTypographies();

  const { Select } = useInputs();

  const { ButtonComponent } = useButtons();
  const { DefaultModal } = useModals();

  const { useConstants } = useStrings();
  const { LIST_OF_TYPES_OF_MESSAGES } = useConstants();

  const {
    control,
    formState: { errors },
    register,
    getValues,
    handleSubmit,
    watch,
    reset,
  } = formDiffusionMessage;

  const requestTypeSelected = watch('type')?.value;

  return (
    <>
      <TypographyComponent
        text="Crear nueva solicitud"
        variant="h2"
        className="font-bold text-primary mt-4"
      />

      <TypographyComponent
        text="Completa toda la información necesaria para comunicarte con la comunidad. MUY IMPORTANTE! si eliges enviar un SMS al redactar el mensaje cuida la cantidad de caracteres que utilizas!
        Has click en enviar solicitud para que el socio ejecutor valide tu mensaje.
        Siempre tienes la opción de guardarlo como borrador para terminar más tarde."
        variant="p"
        className="font-book text-neutral-950 mb-10"
      />

      <StyledForm className="pb-32">
        <StyledGroup noSpace>
          <div>
            <TypographyWithTooltip
              text="Tipo de solicitud"
              variant="p"
              textTooltip="Tipo de solicitud"
              className="text-neutral-900"
              classNameDiv="pb-2"
              idTooltip="type"
              applyFontBold
            />

            <Select
              name="type"
              id="type"
              placeholder="Selecciona el tipo de solicitud"
              control={control}
              options={LIST_OF_TYPES_OF_MESSAGES}
              label=""
              errors={errors}
            />
          </div>
        </StyledGroup>

        {requestTypeSelected === 'd' ? (
          <FormMessage
            control={control}
            errors={errors}
            register={register}
            optionsCommunities={optionsCommunities}
          />
        ) : requestTypeSelected === 'gi' ? (
          <FormRequest
            formDiffusionMessage={formDiffusionMessage}
            optionsCommunities={optionsCommunities}
            handleAddOptions={handleAddOptions}
            setValidateOptionsRules={setValidateOptionsRules}
            numberOptions={numberOptions}
            errors={errors}
            register={register}
            watch={watch}
            control={control}
            handleDeleteOption={handleDeleteOption}
            handleOnChangeOptions={handleOnChangeOptions}
          />
        ) : (
          <></>
        )}

        <div className="flex justify-between">
          <ButtonComponent
            text="Cancelar"
            className="border-none shadow-none"
            onPress={(e) => {
              e.preventDefault();
              reset();
              setActiveItem((prevValue) => ({ ...prevValue, active: 0 }));
            }}
            mode="outline"
            variant="primary"
          />
          {requestTypeSelected && (
            <div className="flex items-center space-x-2">
              <ButtonComponent
                text="Guardar como borrador "
                mode="outline"
                onPress={(e) => {
                  e.preventDefault();
                  sendMessageDraft({ data: getValues() });
                }}
              />
              <ButtonComponent
                text="Enviar solicitud"
                icon={<div className="icon-send text-lg  h-6 flex" />}
                onPress={handleSubmit((data) => {
                  sendMessage({ data, fromModalConfirmation: false });
                })}
              />
            </div>
          )}
        </div>
      </StyledForm>
      <DefaultModal
        isActive={activeConfirmModal}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={setActiveConfirmModal}
      >
        <TypographyComponent
          text="Enviar solicitud al Socio Ejecutor"
          variant="h3"
          className="font-bold text-primary mb-1"
        />
        <TypographyComponent
          text="Has click en aceptar para enviar tu solicitud de mensaje al socio ejecutor."
          variant="p"
          className="text-neutral-950  mb-1"
        />
        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto lg:mx-2 "
            mode="outline"
            onPress={setActiveConfirmModal}
          />
          <ButtonComponent
            variant="primary"
            text="Aceptar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={handleSubmit((data) =>
              sendMessage({ data, fromModalConfirmation: true })
            )}
          />
        </StyledContentButtonsModal>
      </DefaultModal>
      <DefaultModal
        isActive={activeAtLeastItemDraft}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={setActiveAtLeastItemDraft}
      >
        <TypographyComponent
          text="Debe existir al menos un campo diligenciado"
          variant="h3"
          className="font-bold text-primary mb-1 text-center"
        />
        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Aceptar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={setActiveAtLeastItemDraft}
          />
        </StyledContentButtonsModal>
      </DefaultModal>
    </>
  );
};

Create.propTypes = {
  setActiveItem: PropTypes.func.isRequired,
  sendMessage: PropTypes.func,
  sendMessageDraft: PropTypes.func,
  activeConfirmModal: PropTypes.bool,
  setActiveConfirmModal: PropTypes.func,
  formDiffusionMessage: PropTypes.object,
  optionsCommunities: PropTypes.array,
  activeAtLeastItemDraft: PropTypes.bool,
  setActiveAtLeastItemDraft: PropTypes.func,
  handleAddOptions: PropTypes.func,
  setValidateOptionsRules: PropTypes.func,
  numberOptions: PropTypes.array,
  handleDeleteOption: PropTypes.func,
  handleOnChangeOptions: PropTypes.func,
};

Create.defaultProps = {
  sendMessage: () => {},
  sendMessageDraft: () => {},
  activeConfirmModal: false,
  setActiveConfirmModal: () => {},
  formDiffusionMessage: {},
  optionsCommunities: [],
  activeAtLeastItemDraft: false,
  setActiveAtLeastItemDraft: () => {},
  handleAddOptions: () => {},
  setValidateOptionsRules: () => {},
  numberOptions: [],
  handleDeleteOption: () => {},
  handleOnChangeOptions: () => {},
};

export default Create;
