//Packages
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

//Hooks
import useApi from 'api';
import useModels from 'models';
import useControllers from 'controllers';

const useAdminRootProject = () => {
  const history = useHistory();

  const [projectSelected, setProjectSelected] = useState(null);
  const [textSearch, setTextSearch] = useState('');
  const [activeDropDown, setActiveDropDown] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const textSearchRef = useRef(null);

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminProjectsRootActions } = useAdminProjectsActions();
  const {
    actGetProjects,
    actDeleteProject,
    actSetDataProjectFilter,
    actResetDataProjectFilter,
  } = useAdminProjectsRootActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminProjectsRootSelectors } = useAdminProjectsSelectors();
  const { projectsListSelector, projectFilterSelector } =
    useAdminProjectsRootSelectors();
  const dataFilter = useSelector(projectFilterSelector);
  const currentPageAux = dataFilter.page;
  const { projectsList } = useSelector(projectsListSelector);
  const { data: projectsListAux, meta, links } = projectsList;
  const { total: projectsListTotal } = meta;

  const { useGeneralHooks } = useControllers();
  const { useToggleModal, useIsFilterEmpty } = useGeneralHooks();
  const { isFilterEmpty } = useIsFilterEmpty();

  const [showModalDeleteProject, handleChangeModalDeleteProject] =
    useToggleModal();

  useEffect(() => {
    return () => dispatch(actResetDataProjectFilter());
  }, []);

  useEffect(() => {
    dispatch(actGetProjects({ page: currentPage }));
  }, []);

  const handleSetFirstPage = ({ page } = {}) => {
    setCurrentPage(page || 1);
  };

  useEffect(() => {
    if (textSearch.length >= 3) {
      const { restDataFilter } = dataFilter;
      dispatch(
        actGetProjects({ ...restDataFilter, name: textSearch }, () => {
          handleSetFirstPage();
        })
      );
      dispatch(actSetDataProjectFilter({ key: 'name', value: textSearch }));
      textSearchRef.current = true;
    } else {
      if (textSearchRef.current) {
        setCurrentPage(currentPageAux);
        dispatch(
          actGetProjects({ ...dataFilter, page: currentPageAux, name: '' })
        );
        dispatch(actSetDataProjectFilter({ key: 'name', value: '' }));
        textSearchRef.current = false;
      }
    }
  }, [textSearch]);

  const handleCreateProject = () => history.push('/admin/projects/create');

  const handleChangePageProjects = (selected) => {
    setCurrentPage(selected + 1);
    dispatch(actGetProjects({ ...dataFilter, page: selected + 1 }));
    dispatch(actSetDataProjectFilter({ key: 'page', value: selected + 1 }));
  };

  const onSuccessHandleDeleteProject = () => {
    handleChangeModalDeleteProject();
    dispatch(actGetProjects({ page: currentPage }));
  };

  const handleDeleteProject = () => {
    dispatch(
      actDeleteProject(
        { projectId: projectSelected },
        onSuccessHandleDeleteProject
      )
    );
  };

  const goToEditProject = ({ projectId }) =>
    history.push(`/admin/projects/edit/${projectId}`, { currentPage });

  const handleChangeStateMenu = () => {
    setActiveDropDown(!activeDropDown);
  };

  const handleOrderBy = ({ orderByKey, orderByValue }) => {
    dispatch(
      actGetProjects(
        {
          ...dataFilter,
          page: 1,
          orderByKey,
          orderByValue,
        },
        (res) => {
          setCurrentPage(res?.data?.meta?.current_page);
        }
      )
    );
    dispatch(actSetDataProjectFilter({ key: 'orderByKey', value: orderByKey }));
    dispatch(
      actSetDataProjectFilter({
        key: 'orderByValue',
        value: orderByValue,
      })
    );
  };

  const handleGoToDashboardById = ({ projectId }) =>
    history.push(`/admin/projects/dashboard/${projectId}`);

  return {
    isFilterEmpty,
    handleCreateProject,
    projectsListAux,
    projectsListTotal,
    handleChangePageProjects,
    showModalDeleteProject,
    handleChangeModalDeleteProject,
    handleDeleteProject,
    setProjectSelected,
    goToEditProject,
    textSearch,
    setTextSearch,
    handleChangeStateMenu,
    activeDropDown,
    handleOrderBy,
    handleGoToDashboardById,
    links,
    meta,
    currentPage,
    handleSetFirstPage,
  };
};

export default useAdminRootProject;
