//Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

//Assets
import infoIcon from 'assets/icons/info.svg';

//Hooks
import useViews from 'views';

//Styles
import { StyledGroup } from 'styles/formViews/formViews.styles';

const TypographyWithTooltip = (props) => {
  const {
    text,
    variant,
    className,
    textTooltip,
    idTooltip,
    selectedContainer,
    classNameImage,
    classNameDiv,
    applyFontBold,
  } = props;

  const { useComponents } = useViews();
  const { useTypographies, useImages } = useComponents();
  const { Image } = useImages();
  const { TypographyComponent } = useTypographies();

  const [switchSelectedContainer] = useState({
    div: {
      tag: 'div',
      props: { className: `flex items-center ${classNameDiv}` },
    },
    styledComponent: {
      tag: StyledGroup,
      props: { flex: true, className: 'mb-4 items-center' },
    },
  });

  const [Container] = useState({
    tag: switchSelectedContainer[selectedContainer]?.tag,
    props: switchSelectedContainer[selectedContainer]?.props,
  });

  return (
    <Container.tag {...Container.props}>
      <TypographyComponent
        text={text}
        variant={variant}
        className={`${applyFontBold && 'font-bold'} text-primary ${className}`}
      />
      <a data-tip={textTooltip} data-for={idTooltip}>
        <Image
          className={`w-6 hidden lg:block ml-2 ${classNameImage}`}
          srcImage={infoIcon}
          altImage="info"
          titleImage="info"
        />
      </a>
      <ReactTooltip id={idTooltip} />
    </Container.tag>
  );
};

TypographyWithTooltip.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span'])
    .isRequired,
  textTooltip: PropTypes.string.isRequired,
  className: PropTypes.string,
  idTooltip: PropTypes.string,
  selectedContainer: PropTypes.oneOf(['div', 'styledComponent']),
  classNameImage: PropTypes.string,
  classNameDiv: PropTypes.string,
  applyFontBold: PropTypes.bool,
};

TypographyWithTooltip.defaultProps = {
  className: '',
  idTooltip: '',
  selectedContainer: 'div',
  applyFontBold: false,
};

export default TypographyWithTooltip;
