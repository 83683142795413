import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

// Hooks
import useModels from 'models';
import useApi from 'api';
import useHelpers from 'helpers';

const useAdminDeepenKnowledgeScheduleInfo = () => {
  const { useActions } = useApi();
  const { dispatch, useUserActions, useAdminActions, useGeneralActions } =
    useActions();
  const { useTabSelectedActions } = useGeneralActions();
  const { actSetTabSelected } = useTabSelectedActions();
  const { actSearchUser } = useUserActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();
  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const { useAdminDevelopmentInterventionKnowCommunityActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const {
    actGetDeepenKnowledgeScheduleInfo,
    actGetDeepenKnowledgeCommunityIntervention,
    actGetDeepenKnowledgeCommunityFileIntervention,
    actUpdateActivityDeepenKnowledgeCommunityIntervention,
    actUpdateMessageDeepenKnowledgeIntervention,
    actUpdateDeepenKnowledgeScheduleInfo,
    actAssignChangeAgentsDeepenKnowledgeIntervention,
    actSetToSendMessageDeepenKnowledgeIntervention,
  } = useAdminDevelopmentInterventionKnowCommunityActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors, useUserSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionsSelector } = useAdminInterventionsSelectors();
  const { deepen_execution } = useSelector(getInterventionsSelector);
  const { usersSelector } = useUserSelectors();
  const { data: agents } = useSelector(usersSelector);

  const { useQuickFunctions } = useHelpers();
  const { downloadFileFromBackend } = useQuickFunctions();

  const { intervention_id } = useParams();

  const activityRef = useRef({ activity: null, state: null });
  const messageRef = useRef({ message: null, state: null });

  const [confirmationModalActivityOpen, setConfirmatioModalActivityOpen] =
    useState(false);
  const [confirmationModalMessageOpen, setConfirmatioModalMessageOpen] =
    useState(false);
  const [confirmationModalFinishOpen, setConfirmatioModalFinishOpen] =
    useState(false);

  useEffect(() => {
    dispatch(
      actGetDeepenKnowledgeCommunityIntervention(
        { intervention_id },
        (response) => {
          dispatch(
            actGetDeepenKnowledgeScheduleInfo({
              intervention_id,
              deepen_knowledge_id: response.data.data.id,
            })
          );
        }
      )
    );
  }, []);

  const getAgentsByCommunity = (filter) => {
    dispatch(
      actSearchUser(
        {
          ...filter,
          roles: [{ value: 'ca' }],
        },
        'contacts'
      )
    );
  };

  const assignChangeAgent = (agent) => {
    dispatch(
      actAssignChangeAgentsDeepenKnowledgeIntervention({
        deepen_knowledge_id: deepen_execution.id,
        schedule_lifting_id: deepen_execution.id,
        data: { user_id: agent.id },
      })
    );
  };

  const preViewPDFDeepenKnowCommunity = (e) => {
    e.preventDefault();
    window.open(deepen_execution.methodology_file.url, '_blank');
  };

  const downloadPDFDeepenKnowCommunity = (e) => {
    e.preventDefault();
    dispatch(
      actGetDeepenKnowledgeCommunityFileIntervention(
        {
          intervention_id,
          deepen_knowledge_id: deepen_execution.id,
          file_id: deepen_execution.methodology_file.id,
        },
        (response) => {
          downloadFileFromBackend({
            response,
            nameFile: `Metodología de la profundización`,
          });
        }
      )
    );
  };

  const handleConfirmationModalActivity = ({
    e = null,
    activity = null,
    place = null,
    state = null,
    open,
  }) => {
    e && e.preventDefault();
    if (activity) activityRef.current.activity = activity;
    if (state) activityRef.current.state = state;
    if (place) activityRef.current.place = place;
    setConfirmatioModalActivityOpen(open);
  };

  const handleConfirmationModalMessage = ({
    e = null,
    message = null,
    state = null,
    open,
  }) => {
    e && e.preventDefault();
    if (message) messageRef.current.message = message;
    if (state) messageRef.current.state = state;
    setConfirmatioModalMessageOpen(open);
  };

  const handleUpdateActivity = (e) => {
    e.preventDefault();
    if (activityRef.current.activity && activityRef.current.state) {
      handleConfirmationModalActivity({ open: false });
      const dataResult =
        activityRef.current.state === 'c'
          ? {
              state: activityRef.current.state,
              place: activityRef.current.place,
            }
          : { state: activityRef.current.state };

      dispatch(
        actUpdateActivityDeepenKnowledgeCommunityIntervention({
          intervention_id,
          deepen_knowledge_id: deepen_execution.id,
          activity_id: activityRef.current.activity.id,
          data: dataResult,
        })
      );
    }
  };

  const handleUpdateMessage = (e) => {
    e.preventDefault();
    if (messageRef.current.message && messageRef.current.state) {
      handleConfirmationModalMessage({ open: false });
      dispatch(
        actUpdateMessageDeepenKnowledgeIntervention({
          intervention_id,
          deepen_knowledge_id: deepen_execution.id,
          message_id: messageRef.current.message.id,
          data: { state: messageRef.current.state },
        })
      );
    }
  };

  const goToSendMessage = ({ message }) => {
    dispatch(actSetTabSelected({ tabValue: 2 }));
    dispatch(
      actSetToSendMessageDeepenKnowledgeIntervention({
        message: {
          ...message,
          deepen_knowledge_id: deepen_execution.id,
        },
      })
    );
  };

  const openConfirmationModalFinish = () => setConfirmatioModalFinishOpen(true);

  const closeConfirmationModalFinish = () =>
    setConfirmatioModalFinishOpen(false);

  const handleFinishDeepenKnowledge = ({ state }) => {
    closeConfirmationModalFinish();
    dispatch(
      actUpdateDeepenKnowledgeScheduleInfo({
        intervention_id,
        deepen_knowledge_id: deepen_execution.id,
        schedule_lifting_id: deepen_execution.id,
        data: { state },
      })
    );
  };

  return {
    deepen_execution,
    agents,
    activities: deepen_execution.activities,
    messages: deepen_execution.messages,
    confirmationModalActivityOpen,
    confirmationModalMessageOpen,
    confirmationModalFinishOpen,
    selectedActivityState: activityRef.current?.state,
    selectedMessageState: messageRef.current?.state,
    getAgentsByCommunity,
    assignChangeAgent,
    preViewPDFDeepenKnowCommunity,
    downloadPDFDeepenKnowCommunity,
    handleConfirmationModalActivity,
    handleConfirmationModalMessage,
    handleUpdateActivity,
    handleUpdateMessage,
    goToSendMessage,
    handleFinishDeepenKnowledge,
    openConfirmationModalFinish,
    closeConfirmationModalFinish,
  };
};

export default useAdminDeepenKnowledgeScheduleInfo;
