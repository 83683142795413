//Packages
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useAdminProjectsDocumentationProviders = () => {
  const getDocumentationByProject = ({ projectId }) => {
    const response = axios({
      method: 'GET',
      url: `/projects/${projectId}/annexes`,
    });
    return trackPromise(response);
  };

  const createDocumentationByProject = ({ formData, projectId }) => {
    const response = axios({
      method: 'POST',
      headers: {
        'content-type': 'multipart/form-data',
      },
      url: `/projects/${projectId}/annexes`,
      data: formData,
    });
    return trackPromise(response);
  };

  const deleteDocumentationByProject = ({ projectId, documentationId }) => {
    const response = axios({
      method: 'DELETE',
      url: `/projects/${projectId}/annexes/${documentationId}`,
    });
    return trackPromise(response);
  };

  const downloadDocumentationByProject = ({ projectId, documentationId }) => {
    const response = axios({
      method: 'GET',
      responseType: 'arraybuffer',
      url: `/projects/${projectId}/annexes/${documentationId}`,
    });
    return trackPromise(response);
  };

  return {
    getDocumentationByProject,
    createDocumentationByProject,
    deleteDocumentationByProject,
    downloadDocumentationByProject,
  };
};

export default useAdminProjectsDocumentationProviders;
