import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const StyledTopFilters = styled.div.attrs(() => ({
  className: 'StyledTopFilters',
}))`
  ${tw` flex items-center justify-between`}
`;

export const StyledFilters = styled.div.attrs(() => ({
  className: 'StyledFilters',
}))`
  ${tw` flex items-start justify-end space-x-4`}
  .icon-filter {
    &:before {
      ${tw` mr-3`}
    }
  }
`;

export const StyledInputSearchContent = styled.div.attrs(() => ({
  className: 'StyledInputSearchContent',
}))`
  ${tw` relative `}
  .icon-search {
    &:before {
      ${tw` absolute top-0 left-0 z-10 text-neutral-700 text-lg mt-3 ml-4`}
    }
    input {
      ${tw` pl-12 w-80`}
    }
    .inputFormstyles__StyledWrapperInputIcon-sc-1kaxq5a-2 {
      &:before {
        ${tw` hidden`}
      }
    }
  }
`;

export const StyledContentTable = styled.div.attrs(() => ({
  className: 'StyledContentTable',
}))`
  ${tw` w-full mt-5`}
`;

export const StyledContentButtons = styled.div.attrs(() => ({
  className: 'StyledContentButtons',
}))`
  ${tw` flex items-center`}
  button {
    ${tw` bg-transparent text-tertiary shadow-none border-none hover:bg-transparent px-0 py-0`}
    font-size:1.7142857142857142rem;
    &.icon-view {
      ${tw`  text-primary `}
    }
    &.icon-edit {
      font-size: 2.5rem;
      height: 28px;
      ${tw`  text-primary w-8`}
      &:before {
        ${tw`  h-full`}
      }
    }
  }
`;

export const StyledContentButtonsModal = styled.div.attrs(() => ({
  className: 'StyledContentButtonsModal',
}))`
  ${tw` flex justify-center items-center mt-8`}
`;

export const StyledSocialNetworks = styled.div.attrs(() => ({
  className: 'StyledSocialNetworks',
}))`
  ${tw`  space-x-4 flex  `}
`;
