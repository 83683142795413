//Packages
import React, { lazy } from 'react';
import _ from 'lodash';
import { Controller } from 'react-hook-form';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Assets
import imageFakePhoto from 'assets/icons/avatar.svg';

//Styles
const StyledBoxGray = lazy(() =>
  import('../../../../../../../styles/boxes/boxes.styles').then((mod) => ({
    default: mod.StyledBoxGray,
  }))
);

const StyledContentButtons = lazy(() =>
  import('../../../../../../../styles/tableViews/tableViews.styles').then(
    (mod) => ({
      default: mod.StyledContentButtons,
    })
  )
);

const StyledContentButtonsModal = lazy(() =>
  import('../../../../../../../styles/tableViews/tableViews.styles').then(
    (mod) => ({
      default: mod.StyledContentButtonsModal,
    })
  )
);

const StyledWrapperContent = lazy(() =>
  import('../../Root.styles').then((mod) => ({
    default: mod.StyledWrapperContent,
  }))
);

const Section3Belive = () => {
  const { useComponents } = useViews();
  const { useTypographies, useButtons, useModals, useInputs, useImages } =
    useComponents();
  const { UploadImage } = useImages();
  const { TypographyComponent } = useTypographies();
  const { ButtonComponent } = useButtons();
  const { DefaultModal, ImageCropperModal } = useModals();
  const { InputRichText, InputForm } = useInputs();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminHome } = useAdmin();
  const { useAdminHomeContent } = useAdminHome();
  const {
    setShowModal,
    showModal,
    handleCancelClose,

    defaultValues,
    data3Secction,
    handleSetDataFromRichText,
    handleUpdateDescription,
    handleUpdateArtistPartner,
    showUploadProfilePhoto,
    imageSelectedFromImageCropped,
    sectionBelieveHasErrors,
    setSectionBelieveHasErrors,

    errors,
    register,
    control,
    handleSubmit,

    handleUpdateAvatar,

    setZoom,
    setCrop,
    setRotation,
    onCropComplete,
    crop,
    rotation,
    zoom,
    imageForCropper,
    handleUpdateAvatarCropped,
    showModalImageCropper,
    handleCloseCropperModal,
    setShowModalImageCropperAux,
    showPreviewElementInHome,
  } = useAdminHomeContent();

  return (
    <>
      {_.map(data3Secction.current, (data, idx) => (
        <StyledBoxGray key={`section3-${idx}`} className="mb-2">
          <StyledWrapperContent>
            <TypographyComponent
              variant="p"
              text={data.title}
              className="flex-grow text-primary font-bold"
              onClick={() => {}}
            />
            <StyledContentButtons>
              <ButtonComponent
                text=""
                className="icon-view  cursor-pointer"
                onPress={() => {
                  showPreviewElementInHome({ section: 'information' });
                }}
              />
              <ButtonComponent
                text=""
                className="icon-edit  cursor-pointer"
                onPress={() => {
                  setShowModal({
                    active: true,
                    mode: data.section,
                    maxLength: data.maxLength,
                  });
                }}
              />
            </StyledContentButtons>
          </StyledWrapperContent>
        </StyledBoxGray>
      ))}

      <DefaultModal
        isActive={showModal.active}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={handleCancelClose}
      >
        <form>
          <TypographyComponent
            text={`Editar ${showModal.mode}`}
            variant="h3"
            className="font-bold text-primary mb-2"
          />
          {showUploadProfilePhoto && (
            <Controller
              control={control}
              name="image"
              render={({ field: { onChange, name } }) => {
                return (
                  <UploadImage
                    classNameImage="w-28 h-28 rounded-full object-cover"
                    className="w-28 h-28 rounded-full mb-5"
                    imageDefault={
                      imageSelectedFromImageCropped ||
                      defaultValues?.image?.url ||
                      imageFakePhoto
                    }
                    handleChangeImage={(data) => {
                      handleUpdateAvatar(data);
                      onChange(data.target.files[0]);
                    }}
                    name={name}
                    errors={errors}
                  />
                );
              }}
            />
          )}
          {!showUploadProfilePhoto && (
            <InputRichText
              defaultValue={defaultValues}
              setRawContentRichText={handleSetDataFromRichText}
              maxLength={showModal.maxLength}
              setErrors={setSectionBelieveHasErrors}
            />
          )}
          {showUploadProfilePhoto && (
            <InputForm
              type="text"
              name="name"
              placeholder="Nombre"
              label="Nombre"
              className="w-full"
              errors={errors}
              defaultValue={defaultValues?.name}
              {...register('name')}
            />
          )}
          <StyledContentButtonsModal>
            <ButtonComponent
              variant="primary"
              text="Cancelar"
              className="mx-auto w-full lg:w-auto lg:mx-2 "
              mode="outline"
              onPress={handleCancelClose}
            />
            <ButtonComponent
              variant="primary"
              text="Guardar cambios"
              className="mx-auto w-full lg:w-auto  lg:mx-2"
              onPress={
                showUploadProfilePhoto
                  ? handleSubmit(handleUpdateArtistPartner)
                  : handleUpdateDescription
              }
              disabled={sectionBelieveHasErrors}
            />
          </StyledContentButtonsModal>
        </form>
      </DefaultModal>

      <ImageCropperModal
        showModalImageCropper={showModalImageCropper}
        handleCloseCropperModal={() => {
          setShowModalImageCropperAux(false);
          handleCloseCropperModal();
        }}
        setZoom={setZoom}
        setCrop={setCrop}
        setRotation={setRotation}
        onCropComplete={onCropComplete}
        crop={crop}
        rotation={rotation}
        zoom={zoom}
        imageForCropper={imageForCropper}
        handleUpdateAvatarCropped={handleUpdateAvatarCropped}
      />
    </>
  );
};

export default Section3Belive;
