// Packages
import styled from 'styled-components/macro';
import tw from 'twin.macro';
import { overStylesInputs, focusStylesInputs } from '../Inputs.styles';
import useGeneralStyles from '../../../../styles/generalStyles.styles';
const { mixStatesFormStyles } = useGeneralStyles();

export const StyledTextArea = styled.textarea`
  ${tw` bg-neutral-300 text-neutral-950 border border-neutral-600 rounded py-3 px-3 appearance-none`}
  min-height: 80px;
  ::placeholder {
    ${tw` text-neutral-700 `}
  }
  &:hover {
    ${(props) => overStylesInputs(props)}
  }

  &:focus {
    ${(props) => focusStylesInputs(props)}
  }
  ${(props) => props.disabled && tw`bg-neutral`}

  ${(props) =>
    mixStatesFormStyles(
      {
        errors: props.errors,
        success: props.success,
      },
      'border'
    )}
`;
