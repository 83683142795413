//Packages
import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useStrings from 'strings';

//Styles
const StyledGroup = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledContentButtons = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const FormRequest = (props) => {
  const {
    optionsCommunities,
    handleAddOptions,
    setValidateOptionsRules,
    numberOptions,
    errors,
    register,
    watch,
    control,
    handleDeleteOption,
    handleOnChangeOptions,
  } = props;

  const { useComponents } = useViews();
  const { useTypographies, useInputs, useButtons } = useComponents();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { InputForm, Select, TextArea } = useInputs();
  const { ButtonComponent } = useButtons();

  const { useConstants } = useStrings();
  const {
    LIST_OF_CHANNELS_FOR_SEND_MESSAGES,
    LIST_OF_TYPE_OF_COLLECTION,
    LIST_OF_TYPE_OF_INFORMATION_COLLECTION,
  } = useConstants();

  return (
    <>
      <TypographyWithTooltip
        text="Objetivo de la recolección de información *"
        variant="p"
        textTooltip="Objetivo de la recolección de información *"
        className="text-neutral-900"
        idTooltip="obj_type_messaje"
        classNameDiv="pb-2"
        applyFontBold
      />

      <StyledGroup className="items-center mb-2" col2>
        <InputForm
          type="text"
          name="objective"
          placeholder="Ingresa el objetivo de la recolección de información"
          label=""
          className="w-full"
          {...register('objective')}
          errors={errors}
        />
      </StyledGroup>
      <StyledGroup noSpace className="mt-4">
        <div>
          <TypographyWithTooltip
            text="Tipo de recolección*"
            variant="p"
            textTooltip="Tipo de recolección*"
            idTooltip="gi_type"
            className="text-neutral-950"
            classNameDiv="pb-2"
            applyFontBold
          />
          <Select
            name="gi_type"
            id="gi_type"
            placeholder="Seleccionar"
            options={LIST_OF_TYPE_OF_COLLECTION}
            label=""
            control={control}
            errors={errors}
          />
        </div>
        {watch('gi_type')?.value === 'a' && (
          <div>
            <TypographyWithTooltip
              text="Tipo de información a recolectar*"
              variant="p"
              textTooltip="Tipo de información a recolectar*"
              idTooltip="type_information"
              className="text-neutral-950"
              classNameDiv="pb-2"
              applyFontBold
            />
            <Select
              name="type_information"
              id="type_information"
              placeholder="Seleccionar"
              options={LIST_OF_TYPE_OF_INFORMATION_COLLECTION}
              label=""
              control={control}
              errors={errors}
            />
          </div>
        )}
      </StyledGroup>
      <StyledGroup noSpace>
        <div>
          <TypographyWithTooltip
            text="Canal de comunicación recomendado*"
            variant="p"
            textTooltip="Canal de comunicación recomendado*"
            className="text-neutral-900"
            idTooltip="canal_type_messaje"
            classNameDiv="pb-2"
            applyFontBold
          />

          <Select
            name="channel"
            id="channel"
            placeholder="Selecciona el canal de comunicación"
            control={control}
            options={
              watch('gi_type')?.value === 'a'
                ? LIST_OF_CHANNELS_FOR_SEND_MESSAGES
                : [{ label: 'WhatsApp', value: 'ws' }]
            }
            label=""
            errors={errors}
          />
        </div>
        <div>
          <TypographyWithTooltip
            text="Destino*"
            variant="p"
            textTooltip="Destino*"
            className="text-neutral-900"
            idTooltip="dest_type_messaje"
            classNameDiv="pb-2"
            applyFontBold
          />

          <Select
            name="communities"
            id="communities"
            placeholder="Selecciona el destino"
            control={control}
            options={optionsCommunities}
            label=""
            errors={errors}
            multiple
          />
        </div>
      </StyledGroup>

      <TypographyWithTooltip
        text="Recomendaciones para realizar la recolección de información"
        variant="p"
        textTooltip="Recomendaciones para realizar la recolección de información"
        className="text-neutral-900"
        idTooltip="recomend_type_messaje"
        classNameDiv="pb-2"
        applyFontBold
      />

      <StyledGroup className="items-center mb-2" col2>
        <InputForm
          type="text"
          name="recommendations"
          placeholder="Ingresa las recomendaciones..."
          label=""
          className="w-full"
          {...register('recommendations')}
          errors={errors}
        />
      </StyledGroup>

      <StyledGroup flex className="flex-col pt-4 ">
        {watch('gi_type')?.value === 'a' ? (
          <>
            <TypographyWithTooltip
              text="Pregunta"
              variant="h3"
              textTooltip="pregunta"
              idTooltip="message"
              className="font-bold text-primary mt-6"
            />
            <InputForm
              name="message"
              label=""
              type="text"
              placeholder="Ingresa la pregunta"
              className="w-full col-span-2"
              errors={errors}
              control={control}
            />
          </>
        ) : (
          <>
            <TypographyWithTooltip
              text="Pregunta o mensaje"
              variant="h3"
              textTooltip="pregunta"
              idTooltip="message"
              className="font-bold text-primary "
              classNameDiv="pb-2"
            />

            <TextArea
              control={control}
              name="message"
              placeholder="Mensaje"
              className="w-full col-span-2"
              label=""
              errors={errors}
            />
          </>
        )}
      </StyledGroup>

      {watch('type_information')?.value === 'qn' &&
        watch('gi_type')?.value === 'a' && (
          <div className="flex items-end justify-between space-x-6 ">
            <div className="flex-1">
              {_.map(numberOptions, (option, index) => {
                return (
                  <div className="flex items-center space-x-6" key={option.id}>
                    <TypographyComponent
                      variant="p"
                      text={`Opción ${index + 1}`}
                      className="text-primary font-bold flex-none"
                    />
                    <InputForm
                      name={`options[${index}]`}
                      label=""
                      type="text"
                      placeholder={`Ingresa la opción ${index + 1}`}
                      className="flex-1"
                      errors={errors}
                      control={control}
                      value={option.value}
                      onChange={(event) => {
                        handleOnChangeOptions({
                          optionIndex: option.id,
                          optionValue: event.target.value,
                        });
                        setValidateOptionsRules((prevValue) => !prevValue);
                      }}
                    />
                    <StyledContentButtons>
                      <ButtonComponent
                        text=""
                        className="bg-transparent text-tertiary"
                        onPress={(event) => {
                          handleDeleteOption({
                            event,
                            optionId: index,
                          });
                        }}
                        icon={<div className={`icon-delete `} />}
                      />
                    </StyledContentButtons>
                  </div>
                );
              })}
            </div>
            <ButtonComponent
              variant="primary"
              mode="outline"
              text="Agregar otra opción"
              className="mb-2"
              icon={<div className="icon-close transform rotate-45" />}
              disabled={numberOptions.length === 10}
              onPress={handleAddOptions}
            />
          </div>
        )}

      {errors['options'] && (
        <p className="text-sm mt-2 text-error">{errors['options'].message}</p>
      )}
    </>
  );
};

FormRequest.propTypes = {
  optionsCommunities: PropTypes.array.isRequired,
  handleAddOptions: PropTypes.func,
  setValidateOptionsRules: PropTypes.func,
  numberOptions: PropTypes.array,
  errors: PropTypes.object,
  register: PropTypes.func,
  watch: PropTypes.func,
  control: PropTypes.any,
  handleDeleteOption: PropTypes.func,
  handleOnChangeOptions: PropTypes.func,
};

FormRequest.defaultProps = {
  handleAddOptions: () => {},
  setValidateOptionsRules: () => {},
  numberOptions: [],
  errors: {},
  register: () => {},
  watch: () => {},
  control: null,
  handleDeleteOption: () => {},
  handleOnChangeOptions: () => {},
};

export default FormRequest;
