import React from 'react';
import PropTypes from 'prop-types';

//Styles
import { StyledButton, StyledIcon } from './ButtonComponent.styles';

const ButtonComponent = (props) => {
  const {
    buttonTypeSecondary,
    text,
    variant,
    mode,
    icon,
    size,
    onPress,
    className,
    disabled,
  } = props;

  return (
    <StyledButton
      className={className}
      onClick={onPress}
      variant={variant}
      mode={mode}
      size={size}
      icon={icon}
      text={text}
      disabled={disabled}
      buttonTypeSecondary={buttonTypeSecondary}
    >
      {icon && <StyledIcon>{icon && icon}</StyledIcon>}
      {text && text}
    </StyledButton>
  );
};
ButtonComponent.defaultProps = {
  size: 'default',
  variant: 'primary',
  mode: '',
  className: 'StyledButton',
  disabled: false,
  buttonTypeSecondary: false,
};
ButtonComponent.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.element,
  onPress: PropTypes.func.isRequired,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'quaternary',
    'neutral',
    'error',
    'success',
    'warning',
    'info',
    'white',
    'blue',
  ]),
  mode: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  buttonTypeSecondary: PropTypes.bool,
};
export default ButtonComponent;
