const useAdminSocioCulturalInfoTypes = () => {
  const GET_SOCIO_CULTURAL_INFO = 'GET_SOCIO_CULTURAL_INFO';
  const CREATE_SOCIO_CULTURAL_INFO = 'CREATE_SOCIO_CULTURAL_INFO';
  const DELETE_SOCIO_CULTURAL_INFO = 'DELETE_SOCIO_CULTURAL_INFO';
  const UPDATE_SOCIO_CULTURAL_INFO = 'UPDATE_SOCIO_CULTURAL_INFO';
  return {
    CREATE_SOCIO_CULTURAL_INFO,
    DELETE_SOCIO_CULTURAL_INFO,
    UPDATE_SOCIO_CULTURAL_INFO,
    GET_SOCIO_CULTURAL_INFO,
  };
};

export default useAdminSocioCulturalInfoTypes;
