import React from 'react';

import CardEmptyState from './CardEmptyState';
import CardUser from './CardUser';
import CardNotification from './CardNotification';

const CardIntervention = React.lazy(() => import('./CardIntervention'));
const CardHistory = React.lazy(() => import('./CardHistory'));
const CardInformation = React.lazy(() => import('./CardInformation'));
const CardCommunity = React.lazy(() => import('./CardCommunity'));
const CardStadistics = React.lazy(() => import('./CardStadistics'));

const useCards = () => {
  return {
    CardIntervention,
    CardHistory,
    CardInformation,
    CardCommunity,
    CardUser,
    CardStadistics,
    CardNotification,
    CardEmptyState,
  };
};
export default useCards;
