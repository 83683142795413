//Packages
import React from 'react';
import { Switch, useRouteMatch, Redirect } from 'react-router-dom';

//Hooks
import useNavigation from '../../../../..';
import useViews from '../../../../../../views';
import useAdminDashboardProjectsRouters from './routers';

const AdminProjectsRouterDashboard = () => {
  const { path } = useRouteMatch();

  const { useRoutes } = useNavigation();
  const { ProtectedRoute, PrivateRoute } = useRoutes();

  //Screens
  const { useScreens } = useViews();
  const {
    AdminDashboardProject,
    AdminDashboardAbcTrajectory,
    AdminDashboardAdditionalInfo,
    AdminDashboardFestivitiesAndTraditions,
    AdminDashboardRecommendations,
    AdminDashboardSocioCulturalInfo,
    AdminProjectsDashboardDocumentation,
  } = useScreens();

  //Routers
  const {
    AdminProjectsRouterDashboardCommunities,
    AdminProjectsRouterDashboardInterventions,
  } = useAdminDashboardProjectsRouters();

  return (
    <Switch>
      <ProtectedRoute
        exact
        path={`${path}/:project_id`}
        component={AdminDashboardProject}
        redirect="/"
        role={['ad', 'ep']}
      />
      <ProtectedRoute
        path={`${path}/:project_id/communities`}
        component={AdminProjectsRouterDashboardCommunities}
        redirect="/"
        role={['ad', 'ep']}
      />
      <ProtectedRoute
        exact
        path={`${path}/:project_id/festivities`}
        component={AdminDashboardFestivitiesAndTraditions}
        redirect="/"
        role={['ad', 'ep']}
      />
      <ProtectedRoute
        exact
        path={`${path}/:project_id/additional-info`}
        component={AdminDashboardAdditionalInfo}
        redirect="/"
        role={['ad', 'ep']}
      />
      <ProtectedRoute
        exact
        path={`${path}/:project_id/recommendations`}
        component={AdminDashboardRecommendations}
        redirect="/"
        role={['ad', 'ep']}
      />
      <ProtectedRoute
        exact
        path={`${path}/:project_id/abc-trajectory`}
        component={AdminDashboardAbcTrajectory}
        redirect="/"
        role={['ad', 'ep']}
      />
      <ProtectedRoute
        exact
        path={`${path}/:project_id/socio-cultural-info`}
        component={AdminDashboardSocioCulturalInfo}
        redirect="/"
        role={['ad', 'ep']}
      />
      <ProtectedRoute
        exact
        path={`${path}/:project_id/documentation`}
        component={AdminProjectsDashboardDocumentation}
        redirect="/"
        role={['ad', 'ep']}
      />
      <PrivateRoute
        path={`${path}/:project_id/interventions`}
        component={AdminProjectsRouterDashboardInterventions}
        redirect="/"
        role={['ad', 'ep']}
      />
      <Redirect to="/admin/projects" />
    </Switch>
  );
};

export default AdminProjectsRouterDashboard;
