import React, { lazy, useEffect } from 'react';

//Hooks
import useViews from 'views';
import useApi from 'api';
import useModels from 'models';
import useUsersRequestScreens from './components/ArtistRequest';
import useUsersMessagesScreens from './components/Messages';
import useUsersDraftsScreens from './components/Drafts';
import useUsersCollectionInfoScreens from './components/CollectionInformation';

//Styles
const StyledBoxGray = lazy(() =>
  import('styles/boxes/boxes.styles').then((mod) => ({
    default: mod.StyledBoxGray,
  }))
);
const StyledBoxContent = lazy(() =>
  import('styles/boxes/boxes.styles').then((mod) => ({
    default: mod.StyledBoxContent,
  }))
);

const StyledBodyTab = lazy(() =>
  import('styles/tabs/tabs.styles').then((mod) => ({
    default: mod.StyledBodyTab,
  }))
);

const CommunicationAdmin = () => {
  const { useComponents } = useViews();
  const { useTabs } = useComponents();
  const { Tabs, TabLink } = useTabs();

  const { Request } = useUsersRequestScreens();
  const { Drafts } = useUsersDraftsScreens();
  const { Messages } = useUsersMessagesScreens();
  const { CollectionInformation } = useUsersCollectionInfoScreens();

  const { useActions } = useApi();
  const { dispatch, useAdminActions, useGeneralActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();
  const {
    useAdminViewInterventionsCommunicationActions,
    useAdminViewInterventionDevelopmentInterventionActions,
  } = useAdminViewInterventionsActions();
  const { actResetFilterListOfMessages } =
    useAdminViewInterventionsCommunicationActions();
  const { useAdminDevelopmentInterventionKnowCommunityActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const { actSetToSendMessageDeepenKnowledgeIntervention } =
    useAdminDevelopmentInterventionKnowCommunityActions();

  const { useTabSelectedActions } = useGeneralActions();
  const { actSetSubTabSelected, actResetSubTabSelected } =
    useTabSelectedActions();

  const { useSelectors } = useModels();
  const { useSelector, useGeneralSelectors, useAdminSelectors } =
    useSelectors();
  const { useTabSelectedSelectors } = useGeneralSelectors();
  const { subTabSelectedSelector } = useTabSelectedSelectors();
  const subTabValue = useSelector(subTabSelectedSelector);
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getToSendMessageInterventionSelector } =
    useAdminInterventionsSelectors();
  const toSendMessage = useSelector(getToSendMessageInterventionSelector);

  const handleSelectTab = (tabIdx) =>
    dispatch(actSetSubTabSelected({ tabValue: tabIdx }));

  useEffect(() => {
    if (toSendMessage.id) {
      toSendMessage.type.id === 'gi' && handleSelectTab(1);
    }
    return () => {
      dispatch(actResetSubTabSelected());
      dispatch(actSetToSendMessageDeepenKnowledgeIntervention({ message: {} }));
    };
  }, []);

  return (
    <StyledBoxContent className="w-full bg-neutral-200 rounded-lg flex">
      <StyledBoxGray className="2xl:w-1/5 lg:w-3/12 border-r">
        <Tabs
          selected={subTabValue}
          onSelect={(tabIdx) => {
            dispatch(actResetFilterListOfMessages());
            handleSelectTab(tabIdx);
          }}
          className="w-full mb-5 mt-6 lg:mb-0 whitespace-nowrap overflow-auto lg:whitespace-normal flex-col"
          variant="vertical"
        >
          <TabLink
            label={
              <div className="flex items-center text-left">
                <div className="icon-chat text-lg mr-1" />
                Mensajes
              </div>
            }
            variant="vertical"
            tab={0}
          />
          <TabLink
            label={
              <div className="flex items-center text-left">
                <div className="icon-leaderboard text-lg mr-1" />
                Recolección de información
              </div>
            }
            variant="vertical"
            tab={1}
          />
          <TabLink
            label={
              <div className="flex items-center text-left">
                <div className="icon-drafts text-lg mr-1" />
                Borradores
              </div>
            }
            variant="vertical"
            tab={2}
          />
          <TabLink
            label={
              <div className="flex items-center text-left">
                <div className="icon-unread-email text-lg mr-1" />
                Solicitudes del artista
              </div>
            }
            variant="vertical"
            tab={3}
          />
        </Tabs>
      </StyledBoxGray>
      <div className="2xl:w-4/5 lg:w-9/12  px-6 py-6">
        {subTabValue === 0 && (
          <StyledBodyTab active={subTabValue === 0}>
            <Messages />
          </StyledBodyTab>
        )}
        {subTabValue === 1 && (
          <StyledBodyTab active={subTabValue === 1}>
            <CollectionInformation />
          </StyledBodyTab>
        )}

        {subTabValue === 2 && (
          <StyledBodyTab active={subTabValue === 2}>
            <Drafts />
          </StyledBodyTab>
        )}
        {subTabValue === 3 && (
          <StyledBodyTab active={subTabValue === 3}>
            <Request />
          </StyledBodyTab>
        )}
      </div>
    </StyledBoxContent>
  );
};

export default CommunicationAdmin;
