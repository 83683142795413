//Packages
import React, { lazy } from 'react';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
const StyledButtonsActions = lazy(() =>
  import('../../../../../../../../../../../styles/buttons/buttons.styles').then(
    (mod) => ({
      default: mod.StyledButtonsActions,
    })
  )
);

const StyledWrapperCommunity = lazy(() =>
  import('../../../../../../../Dashboard/Dashboard.styles').then((mod) => ({
    default: mod.StyledWrapperCommunity,
  }))
);

const StyledCardCommunity = lazy(() =>
  import('../../../../../../../Dashboard/Dashboard.styles').then((mod) => ({
    default: mod.StyledCardCommunity,
  }))
);

const KnowCommunities = () => {
  const { useComponents } = useViews();

  const { useTypographies, useInputs, useButtons, useCards } = useComponents();
  const { TypographyComponent } = useTypographies();
  const { CheckBox, InputFile, DefaultSwitch, InputForm } = useInputs();
  const { ButtonComponent } = useButtons();
  const { CardNotification } = useCards();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminKnowCommunityInterventions } =
    useAdminInterventionsDashboardControllers();
  const {
    control,
    errors,
    watch,
    register,
    deleteFileArrayInForm,
    downloadFileKnowCommunity,
    handleSubmit,
    isValid,
    know_community,
    updateKnowCommunities,
    preViewPDFKnowCommunity,
  } = useAdminKnowCommunityInterventions();

  return (
    <>
      {_.includes(['ip', 'c'], know_community.state.id) && (
        <CardNotification
          className="w-5/12 mx-auto py-9 px-4 mt-16"
          title={
            <TypographyComponent
              text={know_community?.state?.name}
              variant="h2"
              className="font-bold text-primary icon-watch text-center"
            />
          }
        />
      )}
      <TypographyComponent
        text="Conocer comunidades de la intervención"
        variant="h2"
        className="font-bold text-primary"
      />
      <TypographyComponent
        text="Al dar inicio a esta etapa, el artista tendrá acceso a toda la información que entraste cuando se creo la(s) comunidad(es) para su intervención."
        variant="p"
        className="text-neutral-950 mt-2"
      />

      <TypographyComponent
        text="Te invitamos a adjuntar cualquier documento PDF que pueda ayudar al artista a conocer mejor las comunidades."
        variant="p"
        className="text-neutral-950 mt-2"
      />

      {know_community.state.id === 'p' && (
        <>
          <TypographyComponent
            text="Para asignar una fecha límite para la revisión de este documento por parte del artista has click en el recuadro inferior        "
            variant="p"
            className="text-neutral-950 mt-2"
          />

          <Controller
            control={control}
            name="date_limit_checkbox"
            render={({ field: { name, onChange } }) => {
              return (
                <CheckBox
                  name={name}
                  label="Asignar fecha limite para consultar la información de las comunidades de la intervención por parte del artista"
                  className="mx-auto mt-6"
                  onChange={onChange}
                />
              );
            }}
          />
        </>
      )}
      {watch('date_limit_checkbox') && (
        <InputForm
          name="date_limit"
          label=""
          type="date"
          placeholder="Selecciona la fecha limite"
          className="mt-8 w-2/12"
          errors={errors}
          {...register('date_limit')}
        />
      )}
      <div className="border rounded mt-8 px-6 pb-2 bg-neutral-300">
        <StyledWrapperCommunity nonemb>
          {_.map(know_community.files, (doc) => {
            return (
              <StyledCardCommunity key={doc.id}>
                <TypographyComponent
                  variant="p"
                  text={doc.url}
                  className="flex-grow text-primary font-bold cursor-pointer"
                />
                <StyledButtonsActions className="flex items-center">
                  <ButtonComponent
                    text=""
                    className="icon-view"
                    onPress={() => {
                      preViewPDFKnowCommunity(doc.url);
                    }}
                  />
                  {know_community.state.id === 'p' && (
                    <ButtonComponent
                      text=""
                      className="icon-delete"
                      onPress={() => {
                        deleteFileArrayInForm(doc.id);
                      }}
                    />
                  )}
                  {know_community.state.id !== 'p' && (
                    <ButtonComponent
                      text=""
                      icon={
                        <div className="icon-download text-primary text-lg" />
                      }
                      onPress={() => downloadFileKnowCommunity(doc.id)}
                    />
                  )}
                </StyledButtonsActions>
              </StyledCardCommunity>
            );
          })}
        </StyledWrapperCommunity>

        {know_community.state.id === 'p' && (
          <Controller
            control={control}
            name="file"
            render={({ field: { onChange, name } }) => {
              return (
                <InputFile
                  name={name}
                  accept={'.pdf'}
                  requiredName={
                    <div className="flex items-center">
                      <div className="icon-paper-clip text-primary text-xl font-bold h-10" />
                      <TypographyComponent
                        text="Adjuntar archivo"
                        variant="p"
                        className="text-primary font-bold"
                      />
                    </div>
                  }
                  onChange={onChange}
                  className="w-full mt-2 border-none bg-none"
                  errors={errors}
                  disabled={know_community?.files?.length >= 7}
                />
              );
            }}
          />
        )}
      </div>

      {know_community.state.id === 'p' && (
        <>
          <TypographyComponent
            text="Profundización en el conocimiento de las comunidades"
            variant="h3"
            className="font-bold text-primary mt-8"
          />
          <TypographyComponent
            text="Este proceso permite al artista profundizar en el conocimiento de las comunidades para el diseño de su intervención. Al habilitar esta opción el artista debe presentar una propuesta de profundización para ser revisado y validado."
            variant="p"
            className="text-neutral-950 mt-2"
          />
          <div className="flex items-center mt-6 space-x-2">
            <Controller
              control={control}
              name="com_deepen"
              render={({ field: { onChange, name } }) => {
                return (
                  <DefaultSwitch
                    name={name}
                    onChange={onChange}
                    errors={errors}
                  />
                );
              }}
            />
            <p>
              Habilitar profundización en el conocimiento de las comunidades de
              la intervención por parte del artista
            </p>
          </div>
        </>
      )}

      {know_community.state.id === 'p' && (
        <ButtonComponent
          text="Iniciar etapa"
          className="mx-auto mt-12"
          onPress={handleSubmit(updateKnowCommunities)}
          disabled={!isValid}
        />
      )}
    </>
  );
};

export default KnowCommunities;
