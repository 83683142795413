import React from 'react';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

// Components
import ProgramEvents from './components/ProgramEvents';
import Advance from './components/Advance';

// Styles
import { StyledContentButtonsModal } from 'styles/tableViews/tableViews.styles';

const ExecuteIntervention = () => {
  const { useComponents } = useViews();
  const { useTypographies, useButtons, useModals, useCards } = useComponents();
  const { TypographyComponent } = useTypographies();
  const { ButtonComponent } = useButtons();
  const { DefaultModal } = useModals();
  const { CardNotification } = useCards();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsDevelopmentIntervention } =
    useAdminViewInterventionsControllers();
  const { useViewDevelopmentInterventionExecuteIntervention } =
    useAdminViewInterventionsDevelopmentIntervention();
  const { useViewDevelopmentExecuteInterventionAdmin } =
    useViewDevelopmentInterventionExecuteIntervention();
  const { useAdminExecuteIntervention } =
    useViewDevelopmentExecuteInterventionAdmin();
  const {
    executeIntervention,
    formEvent,
    showModalNoEvents,
    setShowModalNoEvents,
    openModalApprove,
    setOpenModalApprove,
    interventionOptions,
    communityOptions,
    canEdit,
    saveEvent,
    deleteEvent,
    updateExecuteIntervention,
  } = useAdminExecuteIntervention();

  return (
    <>
      {_.includes(['c', 'ip'], executeIntervention.state.id) && (
        <CardNotification
          className="w-6/12 mx-auto py-9 px-4 mt-4"
          title={
            <TypographyComponent
              text={executeIntervention.state.name}
              variant="h2"
              className="font-bold text-primary icon-watch text-center"
            />
          }
        />
      )}
      <TypographyComponent
        text="Ejecutar intervención"
        variant="h2"
        className="text-primary mt-2 font-bold"
      />
      <TypographyComponent
        text="Toda la preparación previa a la implementacón ha sido completada, es hora de pasar a la acción!"
        variant="p"
        className="text-neutral-950 mt-4 mb-4"
      />

      {executeIntervention.state.id === 'p' ? (
        <ProgramEvents
          events={executeIntervention.events}
          formEvent={formEvent}
          saveEvent={saveEvent}
          deleteEvent={deleteEvent}
          communityOptions={communityOptions}
          interventionOptions={interventionOptions}
          canEdit={canEdit}
        />
      ) : (
        <Advance events={executeIntervention.events} />
      )}

      {canEdit && (
        <>
          <div className="flex items-center space-x-2 mt-10 justify-center">
            <ButtonComponent
              text="Enviar programación al artista"
              onPress={() =>
                executeIntervention.events.length > 0
                  ? setOpenModalApprove(true)
                  : setShowModalNoEvents(true)
              }
            />
          </div>

          <DefaultModal
            isActive={showModalNoEvents}
            classNameContent="max-w-screen-md w-11/12"
            handleClose={() => setShowModalNoEvents(false)}
          >
            <TypographyComponent
              variant="h3"
              className="text-primary font-bold whitespace-pre-wrap"
              text={'Debe existir al menos un evento'}
            />

            <StyledContentButtonsModal>
              <ButtonComponent
                variant="primary"
                text="Aceptar"
                className="mx-auto w-full lg:w-auto lg:mx-2"
                onPress={() => setShowModalNoEvents(false)}
              />
            </StyledContentButtonsModal>
          </DefaultModal>

          <DefaultModal
            isActive={openModalApprove}
            classNameContent="max-w-screen-md w-11/12"
            handleClose={() => {
              setOpenModalApprove(false);
            }}
          >
            <TypographyComponent
              text="Enviar programación al artista"
              variant="h3"
              className="font-bold text-primary mb-1"
            />

            <StyledContentButtonsModal>
              <ButtonComponent
                variant="primary"
                text="Cancelar"
                className="mx-auto w-full lg:w-auto lg:mx-2 "
                mode="outline"
                onPress={() => setOpenModalApprove(false)}
              />
              <ButtonComponent
                variant="primary"
                text="Aceptar"
                className="mx-auto w-full lg:w-auto lg:mx-2"
                onPress={updateExecuteIntervention}
              />
            </StyledContentButtonsModal>
          </DefaultModal>
        </>
      )}
    </>
  );
};

export default ExecuteIntervention;
