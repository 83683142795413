//Packages
import React from 'react';

//Components
import DefaultModal from './DefaultModal';
import GeneralModal from './GeneralModal';
import UserInfoModal from './UserInfoModal';

//Lazy components
const ImageCropperModal = React.lazy(() => import('./ImageCropperModal'));
const DetailCommunityModal = React.lazy(() => import('./DetailCommunityModal'));

const useModals = () => {
  return {
    DefaultModal,
    UserInfoModal,
    ImageCropperModal,
    GeneralModal,
    DetailCommunityModal,
  };
};
export default useModals;
