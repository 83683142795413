//Packages
import React from 'react';
import { Switch, useRouteMatch, Redirect } from 'react-router-dom';

//Hooks
import useViews from 'views';
import useNavigation from 'navigation';

//Screens
const { useScreens } = useViews();
const { AdminRootInterventions, AdminEditIntervention } = useScreens();

const AdminInterventionsRouter = () => {
  const { path } = useRouteMatch();

  const { useRoutes } = useNavigation();
  const { PrivateRoute } = useRoutes();

  return (
    <Switch>
      <PrivateRoute
        exact
        path={`${path}`}
        component={AdminRootInterventions}
        redirect="/"
      />
      <PrivateRoute
        exact
        path={`${path}/edit/:id`}
        component={AdminEditIntervention}
        redirect="/"
      />
      <Redirect to={`${path}`} />
    </Switch>
  );
};

export default AdminInterventionsRouter;
