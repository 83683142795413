//Packages
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//Hooks
import useApi from 'api';
import useControllers from 'controllers';
import useModels from 'models';
import useStrings from 'strings';
import useHelpers from 'helpers';

const useArtistCreateSketchInterventions = () => {
  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();
  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const { useAdminDevelopmentInterventionCreateSketchActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const {
    actGetCreateSketchProjectIntervention,
    actUpdateCreateSketchProjectIntervention,
    actDownloadCreateSketchProjectIntervention,
  } = useAdminDevelopmentInterventionCreateSketchActions();

  const { intervention_id } = useParams();

  const { useSelectors } = useModels();
  const { useAdminSelectors, useSelector } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionsSelector } = useAdminInterventionsSelectors();
  const { createSketch } = useSelector(getInterventionsSelector);

  const { useGeneralHooks } = useControllers();
  const { useGetDate } = useGeneralHooks();
  const { setTextExpiredDate } = useGetDate();

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { REQUIRED_FIELD, FILE_SIZE_30MB, FILE_TYPE } = useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const {
    getFileValidations,
    downloadFileFromBackend,
    getYearMonthDayFromDate,
  } = useQuickFunctions();
  const { MAX_SIZE_30MB } = getFileValidations();

  useEffect(() => {
    if (createSketch.state?.id !== 'c') {
      dispatch(actGetCreateSketchProjectIntervention({ intervention_id }));
    }
  }, []);

  const textForLimitDate = useMemo(() => {
    if (createSketch?.date_limit) {
      const { isExpired, text } = setTextExpiredDate({
        expiredDate: createSketch?.date_limit,
      });

      return {
        text,
        colorBadge: isExpired ? 'error' : 'warning',
      };
    }

    return {
      text: 'No hay fecha limite',
      colorBadge: 'warning',
    };
  }, [createSketch.date_limit]);

  const schemaStepOneCreateSketchFile = yup.object({
    sketch_file: yup
      .mixed()
      .required(REQUIRED_FIELD)
      .test('fileType', FILE_TYPE, (value) => {
        if (value) {
          return 'application/pdf' === value.type;
        }
        return true;
      })
      .test('fileSize', FILE_SIZE_30MB, (value) => {
        if (value) {
          return value.size <= MAX_SIZE_30MB;
        }
        return true;
      }),
  });

  const {
    handleSubmit,
    watch,
    control,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schemaStepOneCreateSketchFile),
    mode: 'onChange',
  });

  const updateCreateSketchStepOneFileIntervention = (
    data,
    setShowModalAprove
  ) => {
    const bodyFormData = new FormData();
    bodyFormData.append('sketch_file', data.sketch_file);
    bodyFormData.append('state', 'iv');
    bodyFormData.append('_method', 'PATCH');
    dispatch(
      actUpdateCreateSketchProjectIntervention(
        {
          interventionId: intervention_id,
          sketchId: createSketch.id,
          data: bodyFormData,
        },
        () => {
          setShowModalAprove(false);
        }
      )
    );
  };

  const openFile = ({ event, urlFile, fileType }) => {
    const blobUrl =
      fileType === 'sketch' ? URL.createObjectURL(urlFile) : urlFile;
    event && event.preventDefault();
    window.open(blobUrl);
  };

  const downloadFile = ({ event, idFile, nameFile }) => {
    event && event.preventDefault();
    dispatch(
      actDownloadCreateSketchProjectIntervention(
        {
          interventionId: intervention_id,
          sketchId: createSketch.id,
          fileId: idFile,
        },
        (response) => {
          downloadFileFromBackend({ response, nameFile });
        }
      )
    );
  };

  const handleDeleteFileSelected = ({ event }) => {
    event && event.preventDefault();
    setValue('sketch_file', null);
  };

  return {
    textForLimitDate,
    createSketch,
    updateCreateSketchStepOneFileIntervention,
    handleSubmit,
    watch,
    openFile,
    downloadFile,
    control,
    register,
    errors,
    handleDeleteFileSelected,
    getYearMonthDayFromDate,
  };
};

export default useArtistCreateSketchInterventions;
