//Packages
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

//Hooks
import useViews from 'views';

//Components
import ActivitieList from './components/Activities/List';
import MeansList from './components/Means/List';

//Styles
import {
  StyledContentButtons,
  StyledContentButtonsModal,
} from 'styles/tableViews/tableViews.styles';

const List = (props) => {
  const {
    listOfInterventionsCreated,
    handleOpenDeleteModal,
    handleOpenFormEditInterven,
    isOpenDeleteModal,
    handleDeleteIntervention,
    handleSelectAnInterventionActivityToCreate,
    interventionIdToDeleteEdit,
    handleResetInterventionIdToDeleteEdit,
    isAllDisabled,
  } = props;
  const { useComponents } = useViews();

  const { useTypographies, useButtons, useModals } = useComponents();
  const { DefaultModal } = useModals();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { ButtonComponent } = useButtons();

  return (
    <>
      <Accordion
        allowZeroExpanded
        preExpanded={[
          listOfInterventionsCreated[
            listOfInterventionsCreated.length - 1
          ].id.toString(),
        ]}
      >
        {listOfInterventionsCreated.length > 0 &&
          _.map(listOfInterventionsCreated, (intervention, index) => {
            return (
              <div className="mt-2 bg-white" key={`itemIntnternv-${index}`}>
                <AccordionItem uuid={intervention.id.toString()}>
                  <div className="flex items-center justify-between px-6 py-2">
                    <TypographyComponent
                      variant="p"
                      text={intervention.name}
                      className="flex-grow text-primary font-bold"
                    />
                    <StyledContentButtons>
                      {/* <AccordionItemButton> */}
                      <ButtonComponent
                        text=""
                        className={`icon-edit ${
                          isAllDisabled && 'icon-disabled'
                        }`}
                        variant="white"
                        onPress={() => {
                          !isAllDisabled &&
                            handleOpenFormEditInterven({
                              interventionId: intervention.id,
                            });
                        }}
                      />
                      <ButtonComponent
                        text=""
                        className={`icon-delete ${
                          isAllDisabled && 'icon-disabled'
                        }`}
                        onPress={() => {
                          !isAllDisabled &&
                            handleOpenDeleteModal({
                              interventionId: intervention.id,
                            });
                        }}
                      />
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton>
                            <ButtonComponent
                              text=""
                              className=""
                              variant="white"
                              icon={
                                <div
                                  className={`icon-arrow-down text-xxs text-neutral-950 ml-4 ${
                                    expanded && 'transform rotate-180'
                                  }`}
                                />
                              }
                              onPress={() => {
                                !expanded
                                  ? handleSelectAnInterventionActivityToCreate({
                                      interventionId: intervention.id,
                                    })
                                  : handleResetInterventionIdToDeleteEdit();
                              }}
                            />
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </StyledContentButtons>
                  </div>
                  <AccordionItemPanel>
                    <div className="bg-neutral-200 px-10 border">
                      <TypographyWithTooltip
                        text="Actividades de la intervención*"
                        variant="h3"
                        textTooltip="Actividades de la intervención*"
                        className="text-primary mt-6"
                        idTooltip="artist_actitivitie_sesion"
                        applyFontBold
                      />

                      <TypographyComponent
                        text=' Dale click a "+crear nueva actividad" para continuar '
                        variant="p"
                        className="text-neutral-950 mt-2 mb-6"
                      />
                      {interventionIdToDeleteEdit && (
                        <ActivitieList
                          handleId={handleResetInterventionIdToDeleteEdit}
                        />
                      )}

                      <TypographyWithTooltip
                        text="Recursos necesarios"
                        variant="h3"
                        textTooltip="Medios logísticos de la intervención* "
                        className="text-primary mt-6"
                        idTooltip="artist_means_sesion"
                        applyFontBold
                      />
                      <TypographyComponent
                        text='Dale click a "+crear nuevo recurso", para añadir la lista de recursos necesarios para una intervención exitosa.'
                        variant="p"
                        className="text-neutral-950 mt-2 mb-6"
                      />

                      {interventionIdToDeleteEdit && <MeansList />}
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </div>
            );
          })}
      </Accordion>

      <DefaultModal
        isActive={isOpenDeleteModal}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={handleOpenDeleteModal}
      >
        <TypographyComponent
          text="Eliminar intervención"
          variant="h3"
          className="font-bold text-primary mb-1"
        />

        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto lg:mx-2 "
            mode="outline"
            onPress={handleOpenDeleteModal}
          />
          <ButtonComponent
            variant="primary"
            text="Eliminar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={handleDeleteIntervention}
          />
        </StyledContentButtonsModal>
      </DefaultModal>
    </>
  );
};

List.propTypes = {
  listOfInterventionsCreated: PropTypes.array.isRequired,
  handleOpenDeleteModal: PropTypes.func,
  handleOpenFormEditInterven: PropTypes.func,
  isOpenDeleteModal: PropTypes.bool,
  handleDeleteIntervention: PropTypes.func,
  handleSelectAnInterventionActivityToCreate: PropTypes.func,
  handleResetInterventionIdToDeleteEdit: PropTypes.func,
  interventionIdToDeleteEdit: PropTypes.string,
  isAllDisabled: PropTypes.bool,
};

List.defaultProps = {
  handleOpenDeleteModal: () => {},
  handleOpenFormEditInterven: () => {},
  handleDeleteIntervention: () => {},
  handleSelectAnInterventionActivityToCreate: () => {},
  handleResetInterventionIdToDeleteEdit: () => {},
  isOpenDeleteModal: false,
  isAllDisabled: false,
  interventionIdToDeleteEdit: '',
};

export default List;
