import AdminDashboardCommunitiesContactsCreate from './Create';
import AdminDashboardCommunitiesContactsEdit from './Edit';
import AdminDashboardCommunitiesContactsRoot from './Root';

const useAdminProjectsDashboardCommunitiesContactsScreens = () => {
  return {
    AdminDashboardCommunitiesContactsRoot,
    AdminDashboardCommunitiesContactsEdit,
    AdminDashboardCommunitiesContactsCreate,
  };
};

export default useAdminProjectsDashboardCommunitiesContactsScreens;
