//Packages
import React from 'react';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Components
import useStepperAdmin from './Admin';
import useStepperArtist from './Artist';

//Components-substeppers
import StepperKnowCommunities from './SubSteppers/StepperKnowCommunities';
import StepperSketchIntervention from './SubSteppers/StepperSketchIntervention';
import StepperEjecutionIntervention from './SubSteppers/StepperEjecutionIntervention';

const DevelopmentIntervention = () => {
  const { AsingPlan, KnowProject, DefineIntervention, FinishIntervention } =
    useStepperAdmin();
  const { ArtistAsingPlan, ArtistKnowProject, InterventionDefine, FinishInterventionArtist } =
    useStepperArtist();

  const { useComponents } = useViews();

  const { useStepper } = useComponents();
  const { Stepper, Step } = useStepper();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsDevelopmentIntervention } =
    useAdminViewInterventionsControllers();
  const { useAdminViewInterventionsGeneralStepperAndTabsInterventions } =
    useAdminViewInterventionsDevelopmentIntervention();
  const {
    currentStep,
    permissions,
    handleGoToStepperSelected,
    actualPhaseIntervention,
    handleSetStatusNameOrColorStepper,
  } = useAdminViewInterventionsGeneralStepperAndTabsInterventions({
    isDevelopmentIntervention: true,
  });

  return (
    <Stepper
      currentStep={currentStep.current}
      conetor={true}
      setCurrentStep={handleGoToStepperSelected}
    >
      <Step
        label={permissions ? 'Asignar plan creativo' : 'Conocer plan creativo'}
        actualPhase={actualPhaseIntervention}
        stepStatusName={
          handleSetStatusNameOrColorStepper({ stepNumber: 0 }).statusName
        }
        stepStatusIdForColor={
          handleSetStatusNameOrColorStepper({ stepNumber: 0 }).statusColor
        }
      >
        {currentStep.current === 0 && (
          <>
            {permissions && <AsingPlan />}
            {!permissions && <ArtistAsingPlan />}
          </>
        )}

      </Step>

      <Step
        label={'Conocer proyecto'}
        actualPhase={actualPhaseIntervention}
        stepStatusName={
          handleSetStatusNameOrColorStepper({ stepNumber: 1 }).statusName
        }
        stepStatusIdForColor={
          handleSetStatusNameOrColorStepper({ stepNumber: 1 }).statusColor
        }
      >
        {currentStep.current === 1 && (
          <>
            {permissions && <KnowProject />}
            {!permissions && <ArtistKnowProject />}
          </>
        )}

      </Step>
      <Step
        label="Conocer comunidades"
        actualPhase={actualPhaseIntervention}
        stepStatusName={
          handleSetStatusNameOrColorStepper({ stepNumber: 2 }).statusName
        }
        stepStatusIdForColor={
          handleSetStatusNameOrColorStepper({ stepNumber: 2 }).statusColor
        }
      >
        {currentStep.current === 2 && (
          <StepperKnowCommunities permissions={permissions} />
        )}
      </Step>
      <Step
        label="Definir intervención"
        actualPhase={actualPhaseIntervention}
        stepStatusName={
          handleSetStatusNameOrColorStepper({ stepNumber: 3 }).statusName
        }
        stepStatusIdForColor={
          handleSetStatusNameOrColorStepper({ stepNumber: 3 }).statusColor
        }
      >
        {currentStep.current === 3 && (
          <>
            {permissions && <DefineIntervention />}
            {!permissions && <InterventionDefine />}
          </>
        )}
      </Step>
      <Step
        label="Crear boceto"
        actualPhase={actualPhaseIntervention}
        stepStatusName={
          handleSetStatusNameOrColorStepper({ stepNumber: 4 }).statusName
        }
        stepStatusIdForColor={
          handleSetStatusNameOrColorStepper({ stepNumber: 4 }).statusColor
        }
      >
        {currentStep.current === 4 && (
          <StepperSketchIntervention permissions={permissions} />
        )}
      </Step>
      <Step
        label="Ejecutar intervención"
        actualPhase={actualPhaseIntervention}
        stepStatusName={
          handleSetStatusNameOrColorStepper({ stepNumber: 5 }).statusName
        }
        stepStatusIdForColor={
          handleSetStatusNameOrColorStepper({ stepNumber: 5 }).statusColor
        }
      >
        {currentStep.current === 5 && (
          <StepperEjecutionIntervention permissions={permissions} />
        )}
      </Step>
      <Step
        label="Difundir intervención"
        actualPhase={actualPhaseIntervention}
        stepStatusName={
          handleSetStatusNameOrColorStepper({ stepNumber: 6 }).statusName
        }
        stepStatusIdForColor={
          handleSetStatusNameOrColorStepper({ stepNumber: 6 }).statusColor
        }
      >
        {currentStep.current === 6 && (
          <>
            {permissions && <FinishIntervention />}
            {!permissions && <FinishInterventionArtist />}
          </>
        )}
      </Step>
    </Stepper>
  );
};

export default DevelopmentIntervention;
