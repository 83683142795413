import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const StyledForm = styled.form.attrs(() => ({
  className: 'StyledForm',
}))`
  ${tw` space-y-4`}

  ${(props) => props.border && tw`border border-neutral-600 p-6 rounded-md`}
`;

export const StyledGroup = styled.section.attrs(() => ({
  className: 'StyledGroup',
}))`
  ${tw` space-y-3 lg:space-y-4 lg:grid lg:grid-cols-2 lg:gap-x-12 w-full`}

  ${(props) => props.col2 && tw`lg:gap-x-4 lg:flex lg:!mt-0`}
  ${(props) => props.noSpace && tw`lg:space-y-0`}
  ${(props) => props.flex && tw`lg:flex lg:gap-x-0 lg:space-y-0`}
`;

export const StyledBackButton = styled.button.attrs(() => ({
  className: 'StyledBackButton icon-arrow',
}))`
  ${tw` font-bold text-primary`}
  &:before {
    ${tw` text-secondary lg:mr-4 mr-2`}
    transform: rotate(180deg);
    font-weight: 700;
  }
`;

export const StyledDivider = styled.div.attrs(() => ({
  className: "StyledDivider"
}))`
  ${tw `w-full my-12 border border-neutral-600 block`}
`
