//Packages
import { useLayoutEffect, useState } from 'react';

const useGetScreenSize = () => {
  const [sizeScreen, setSizeScreen] = useState({ x: 0, y: 0 });

  useLayoutEffect(() => {
    const handleResize = () => {
      setSizeScreen({ x: window.innerWidth, y: window.innerHeight });
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return { sizeScreen };
};

export default useGetScreenSize;
