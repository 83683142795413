import useCountriesProviders from './countries';
import useLanguagesProviders from './languages';
import useLegalDataProviders from './legalData';
import useSpecialitiesProviders from './specialities';
import useColorsProviders from './colors';
import useAccessibilityProviders from './accessibility';
import useNotificationProviders from './notification';

const useGeneralProviders = () => {
  return {
    useCountriesProviders,
    useLegalDataProviders,
    useLanguagesProviders,
    useSpecialitiesProviders,
    useColorsProviders,
    useAccessibilityProviders,
    useNotificationProviders,
  };
};

export default useGeneralProviders;
