import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useCountriesProviders = () => {
  const getCountries = ({ paginate, append } = {}) => {
    const response = axios({
      type: 'GET',
      url: '/countries',
      params: {
        paginate,
        append,
      },
    });
    return trackPromise(response);
  };
  return { getCountries };
};

export default useCountriesProviders;
