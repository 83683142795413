import useArtistExecuteMaterializeIntervention from './executeMaterialize';
import useArtistExecuteIntervention from './executeIntervention';

const useViewDevelopmentExecuteInterventionArtist = () => {
  return {
    useArtistExecuteMaterializeIntervention,
    useArtistExecuteIntervention
  };
};

export default useViewDevelopmentExecuteInterventionArtist;
