import React, { lazy } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

//Hooks
import useViews from 'views';
import useStrings from 'strings';

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

const StyledContentButtons = lazy(() =>
  import(
    '../../../../../../../../../../../../../../../styles/tableViews/tableViews.styles'
  ).then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const StyledGroup = lazy(() =>
  import(
    '../../../../../../../../../../../../../../../styles/formViews/formViews.styles'
  ).then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const Activities = (props) => {
  const { activity } = props;
  const { useComponents } = useViews();

  const { useTypographies, useInputs } = useComponents();

  const { TypographyComponent } = useTypographies();
  const { TextArea, Select, InputForm } = useInputs();

  const { useConstants } = useStrings();
  const { MOMENT_VALUES } = useConstants();

  const { control } = useForm();

  return (
    <>
      <Accordion allowZeroExpanded>
        {activity.length > 0 &&
          _.map(activity, (activity, index) => {
            return (
              <div className="rounded mt-2" key={index}>
                <AccordionItem>
                  <div className="flex items-center justify-between bg-white py-2 px-6">
                    <TypographyComponent
                      variant="p"
                      text={activity.name}
                      className="flex-grow text-primary font-bold"
                    />
                    <StyledContentButtons>
                      <AccordionItemState>
                        {({ expanded }) =>
                          expanded ? (
                            <AccordionItemButton>
                              <div className="icon-arrow-down text-xxs text-neutral-900 transform rotate-180"/>
                            </AccordionItemButton>
                          ) : (
                            <AccordionItemButton>
                              <div className="icon-arrow-down text-xxs text-neutral-900"/>
                            </AccordionItemButton>
                          )
                        }
                      </AccordionItemState>
                    </StyledContentButtons>
                  </div>
                  <AccordionItemPanel className="py-4 border rounded px-4">
                    <StyledGroup noSpace className="mb-4 flex-col">
                      <InputForm
                        name="name"
                        label="Nombre de la actividad*"
                        type="text"
                        className="full"
                        defaultValue={activity.name}
                        disabled
                      />
                      <Select
                        name={`time-${index}`}
                        id="time"
                        placeholder="Momento*"
                        defaultValue={{
                          label: activity.moment.name,
                          value: activity.moment.id,
                        }}
                        options={MOMENT_VALUES}
                        label="Momento*"
                        disabled
                        control={control}
                      />
                    </StyledGroup>
                    <StyledGroup col2 flex className="mb-4 flex-col">
                      <TextArea
                        name="why"
                        className="w-full col-span-2"
                        label="Justificación de la actividad*"
                        defaultValue={activity.justification}
                        disabled
                      />
                    </StyledGroup>
                  </AccordionItemPanel>
                </AccordionItem>
              </div>
            );
          })}
      </Accordion>
    </>
  );
};

Activities.propTypes = {
  activity: PropTypes.array,
};

export default Activities;
