import React, { lazy, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Hooks
import useViews from '../../../../../../../../../../..';
import useControllers from '../../../../../../../../../../../../controllers';

const StyledInputSearchContent = lazy(() =>
  import(
    '../../../../../../../../../../../../styles/tableViews/tableViews.styles'
  ).then((mod) => ({
    default: mod.StyledInputSearchContent,
  }))
);

const StyledDropDownFilterTag = lazy(() =>
  import('./DropDownFilterTags.styles').then((mod) => ({
    default: mod.StyledDropDownFilterTag,
  }))
);

const StyledListTags = lazy(() =>
  import('./DropDownFilterTags.styles').then((mod) => ({
    default: mod.StyledListTags,
  }))
);

const StyledListTag = lazy(() =>
  import('./DropDownFilterTags.styles').then((mod) => ({
    default: mod.StyledListTag,
  }))
);

const DropDownFilterTags = (props) => {
  const {
    open,
    positionDropDownTags,
    user,
    users,
    setActiveDropDownFilterTagsAll,
    handleStateDropDownTagsByRow,
    setResetAllCheckboxes,
  } = props;

  const { useComponents } = useViews();
  const { useInputs } = useComponents();
  const { InputSearch } = useInputs();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminLabels } = useAdmin();
  const { useAdminRootLabels } = useAdminLabels();
  const { labelslist, assignLabelToContacts } = useAdminRootLabels({
    setResetAllCheckboxes,
  });
  const [setIsOnFocusTextSearch] = useState(false);

  const handleOnFocus = (isOnFocus) => {
    setIsOnFocusTextSearch(isOnFocus);
  };

  const [textSearch, setTextSearch] = useState('');
  const tags = useMemo(
    () =>
      _.map(labelslist, ({ labels }) => ({
        title: labels.name,
        color: labels.color,
        id: labels.id,
      })),
    [labelslist]
  );

  return (
    <StyledDropDownFilterTag
      open={open}
      positionDropDownTags={positionDropDownTags[0]}
    >
      <StyledInputSearchContent>
        <InputSearch
          text={textSearch}
          onDebounce={setTextSearch}
          handlerIsOnFocus={handleOnFocus}
        />
      </StyledInputSearchContent>
      <StyledListTags>
        {_.map(tags, (tag, idx) => {
          return (
            <StyledListTag
              key={`list-tag-${idx}`}
              color={tag.color}
              onClick={() => {
                assignLabelToContacts(
                  {
                    tag: tag.id,
                    users: user ? [user] : users,
                  },
                  setActiveDropDownFilterTagsAll,
                  handleStateDropDownTagsByRow
                );
              }}
            >
              {tag.title}
            </StyledListTag>
          );
        })}
      </StyledListTags>
    </StyledDropDownFilterTag>
  );
};

DropDownFilterTags.propTypes = {
  open: PropTypes.bool.isRequired,
  positionDropDownTags: PropTypes.array,
  user: PropTypes.object,
  users: PropTypes.array,
  setActiveDropDownFilterTagsAll: PropTypes.func,
  handleStateDropDownTagsByRow: PropTypes.func,
  setResetAllCheckboxes: PropTypes.func,
};

DropDownFilterTags.defaultProps = {
  positionDropDownTags: [],
  user: null,
  setActiveDropDownFilterTagsAll: () => {},
  handleStateDropDownTagsByRow: () => {},
  setResetAllCheckboxes: () => {},
};

export default DropDownFilterTags;
