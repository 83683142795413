const useAdminDevelopmentInterventionKnowCommunityTypes = () => {
  const UPDATE_KNOW_COMMUNITY_INTERVENTION =
    'UPDATE_KNOW_COMMUNITY_INTERVENTION';
  const DELETE_KNOW_COMMUNITY_INTERVENTION =
    'DELETE_KNOW_COMMUNITY_INTERVENTION';
  const KNOW_COMMUNITY_INTERVENTION = 'KNOW_COMMUNITY_INTERVENTION';
  const UPDATE_KNOW_COMMUNITY_FILE_INTERVENTION =
    'UPDATE_KNOW_COMMUNITY_FILE_INTERVENTION';
  const DELETE_KNOW_COMMUNITY_FILE_INTERVENTION =
    'DELETE_KNOW_COMMUNITY_FILE_INTERVENTION';
  const KNOW_COMMUNITY_FILE_INTERVENTION = 'KNOW_COMMUNITY_FILE_INTERVENTION';
  const GET_DEEPEN_KNOWLEDGE_COMMUNITY_INTERVENTION =
    'GET_DEEPEN_KNOWLEDGE_COMMUNITY_INTERVENTION';
  const STORE_DEEPEN_KNOWLEDGE_COMMUNITY_INTERVENTION =
    'STORE_DEEPEN_KNOWLEDGE_COMMUNITY_INTERVENTION';
  const ASSIGN_CHANGE_AGENTS_TO_DEEPEN_KNOWLEDGE_COMMUNITY_INTERVENTION =
    'ASSIGN_CHANGE_AGENTS_TO_DEEPEN_KNOWLEDGE_COMMUNITY_INTERVENTION';
  const GET_FILE_KNOWLEDGE_COMMUNITY_INTERVENTION =
    'GET_FILE_KNOWLEDGE_COMMUNITY_INTERVENTION';
  const UPDATE_ACTIVITY_KNOWLEDGE_COMMUNITY_INTERVENTION =
    'UPDATE_ACTIVITY_KNOWLEDGE_COMMUNITY_INTERVENTION';
  const GET_DEEPEN_KNOWLEDGE_SCHEDULE_INFO =
    'GET_DEEPEN_KNOWLEDGE_SCHEDULE_INFO';
  const UPDATE_DEEPEN_KNOWLEDGE_SCHEDULE_INFO =
    'UPDATE_DEEPEN_KNOWLEDGE_SCHEDULE_INFO';
  const UPDATE_MESSAGE_DEEPEN_KNOWLEDGE_INTERVENTION =
    'UPDATE_MESSAGE_DEEPEN_KNOWLEDGE_INTERVENTION';
  const SET_TO_SEND_MESSAGE_DEEPEN_KNOWLEDGE_INTERVENTION =
    'SET_TO_SEND_MESSAGE_DEEPEN_KNOWLEDGE_INTERVENTION';

  return {
    UPDATE_KNOW_COMMUNITY_INTERVENTION,
    DELETE_KNOW_COMMUNITY_INTERVENTION,
    KNOW_COMMUNITY_INTERVENTION,
    UPDATE_KNOW_COMMUNITY_FILE_INTERVENTION,
    DELETE_KNOW_COMMUNITY_FILE_INTERVENTION,
    KNOW_COMMUNITY_FILE_INTERVENTION,
    GET_DEEPEN_KNOWLEDGE_COMMUNITY_INTERVENTION,
    STORE_DEEPEN_KNOWLEDGE_COMMUNITY_INTERVENTION,
    ASSIGN_CHANGE_AGENTS_TO_DEEPEN_KNOWLEDGE_COMMUNITY_INTERVENTION,
    GET_FILE_KNOWLEDGE_COMMUNITY_INTERVENTION,
    UPDATE_ACTIVITY_KNOWLEDGE_COMMUNITY_INTERVENTION,
    GET_DEEPEN_KNOWLEDGE_SCHEDULE_INFO,
    UPDATE_DEEPEN_KNOWLEDGE_SCHEDULE_INFO,
    UPDATE_MESSAGE_DEEPEN_KNOWLEDGE_INTERVENTION,
    SET_TO_SEND_MESSAGE_DEEPEN_KNOWLEDGE_INTERVENTION,
  };
};

export default useAdminDevelopmentInterventionKnowCommunityTypes;
