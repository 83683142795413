//Packages
import React, { lazy } from 'react';

//Components
import List from '../List';
import Create from '../Create';
import Detail from '../Detail';

//Hooks
import useControllers from 'controllers';

//Styles
const StyledBodyTab = lazy(() =>
  import('styles/tabs/tabs.styles').then((mod) => ({
    default: mod.StyledBodyTab,
  }))
);

const CollectionInformation = () => {
  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsCommunication } =
    useAdminViewInterventionsControllers();
  const { useAdminViewInterventionsCollectionInformationControllers } =
    useAdminViewInterventionsCommunication();
  const { useAdminViewInterventionsGetCollectionInformation } =
    useAdminViewInterventionsCollectionInformationControllers();
  const {
    orderBy,
    activeItem,
    setActiveItem,
    collectionMessagesList,
    dataForPagination,
    handleChangePage,
    handleDataToOrder,
    handleFilter,
    handleResetFilter,
    handleDownloadFileFromCollectionInformation,
    formFilterCollectionInformation,
    optionsCommunities,
    optionsContactLists,
    showFilterDropDown,
    handleVisibilityFilterDropDown,
    dataCollectionInformationSelected,
    dataCollectionInformationAnswersSelected,
  } = useAdminViewInterventionsGetCollectionInformation();

  return (
    <div className="px-4">
      {activeItem.active === 0 && (
        <StyledBodyTab active={activeItem.active === 0}>
          <List
            setActiveItem={setActiveItem}
            collectionMessagesList={collectionMessagesList}
            dataForPagination={dataForPagination}
            handleChangePage={handleChangePage}
            orderBy={orderBy}
            handleDataToOrder={handleDataToOrder}
            formFilterCollectionInformation={formFilterCollectionInformation}
            optionsCommunities={optionsCommunities}
            optionsContactLists={optionsContactLists}
            handleFilter={handleFilter}
            showFilterDropDown={showFilterDropDown}
            handleVisibilityFilterDropDown={handleVisibilityFilterDropDown}
            handleResetFilter={handleResetFilter}
          />
        </StyledBodyTab>
      )}
      {activeItem.active === 1 && (
        <StyledBodyTab active={activeItem.active === 1}>
          <Create
            setActiveItem={setActiveItem}
            optionsCommunities={optionsCommunities}
            optionsContactLists={optionsContactLists}
          />
        </StyledBodyTab>
      )}
      {activeItem.active === 2 && (
        <StyledBodyTab active={activeItem.active === 2}>
          <Detail
            setActiveItem={setActiveItem}
            activeItem={activeItem}
            dataCollectionInformationSelected={
              dataCollectionInformationSelected
            }
            dataCollectionInformationAnswersSelected={
              dataCollectionInformationAnswersSelected
            }
            handleDownloadFileFromCollectionInformation={
              handleDownloadFileFromCollectionInformation
            }
          />
        </StyledBodyTab>
      )}
    </div>
  );
};

export default CollectionInformation;
