//Hooks
import useControllers from 'controllers';
import useStrings from '../../../../../../../strings';
import useProviders from '../../../../../../providers';

const useAdminViewInterventionsCommunicationActions = () => {
  const { useAdminProviders } = useProviders();
  const { useAdminProjectsProviders } = useAdminProviders();
  const { useAdminInterventionsProviders } = useAdminProjectsProviders();
  const { useAdminViewInterventionProviders } =
    useAdminInterventionsProviders();
  const { useAdminViewInterventionCommunicationProviders } =
    useAdminViewInterventionProviders();

  const {
    getMessagesInterventionProvider,
    getMessageInterventionProvider,
    sendMessagesInterventionProvider,
    deleteMessageInterventionProvider,
    rejectAcceptRequestMessageAsAdminProvider,
    downloadFileOfCollectionMessagesInterventionProvider,
  } = useAdminViewInterventionCommunicationProviders();

  const { useGeneralHooks } = useControllers();
  const { useConsole } = useGeneralHooks();
  const { viewConsole } = useConsole();

  const { useAdminTypes } = useStrings();
  const { useAdminProjectsTypes } = useAdminTypes();
  const { useAdminInterventionsTypes } = useAdminProjectsTypes();
  const { useAdminViewInterventionsTypes } = useAdminInterventionsTypes();
  const { useAdminViewInterventionsCommunicationTypes } =
    useAdminViewInterventionsTypes();
  const {
    GET_LIST_OF_MESSAGES_INTERVENTION,
    GET_SELECTED_MESSAGE_INTERVENTION,
    SEND_MESSAGES_INTERVENTION,
    DELETE_MESSAGE_INTERVENTION,
    SET_FILTER_LIST_OF_MESSAGES_INTERVENTION,
    RESET_FILTER_LIST_OF_MESSAGES_INTERVENTION,
    REJECTED_REQUEST_MESSAGE_BY_ARTIST,
    RESET_SELECTED_MESSAGE_INTERVENTION,
    DOWNLOAD_FILE_OF_COLLECTION_MESSAGE,
  } = useAdminViewInterventionsCommunicationTypes();

  const actGetMessagesIntervention =
    (
      {
        intervention_id,
        draftOrNoDraft,
        state,
        search,
        orderByKey,
        orderByValue,
        created_at,
        request_sended_date,
        sended_date,
        isMessagesOfArtist,
        communityReceivers,
        contactListReceivers,
        type,
        page,
        channel,
        gi_type,
      },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const response = await getMessagesInterventionProvider({
          intervention_id,
          draftOrNoDraft,
          state,
          search,
          orderByKey,
          orderByValue,
          created_at,
          request_sended_date,
          sended_date,
          communityReceivers,
          contactListReceivers,
          type,
          page,
          channel,
          gi_type,
          isMessagesOfArtist,
        });
        dispatch({
          type: GET_LIST_OF_MESSAGES_INTERVENTION,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actGetMessageIntervention =
    (
      { intervention_id, message_id, executeInRedux = true },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const response = await getMessageInterventionProvider({
          intervention_id,
          message_id,
        });
        executeInRedux &&
          dispatch({
            type: GET_SELECTED_MESSAGE_INTERVENTION,
            payload: response.data,
          });
        onSuccess && onSuccess(response);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actSendMessagesIntervention =
    ({ intervention_id, data }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await sendMessagesInterventionProvider({
          intervention_id,
          data,
        });
        dispatch({
          type: SEND_MESSAGES_INTERVENTION,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actDeleteMessageIntervention =
    ({ intervention_id, message_id }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await deleteMessageInterventionProvider({
          intervention_id,
          message_id,
        });
        dispatch({ type: DELETE_MESSAGE_INTERVENTION });
        onSuccess && onSuccess(response);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actSetFilterListOfMessages =
    ({ key, value }) =>
    (dispatch) => {
      dispatch({
        type: SET_FILTER_LIST_OF_MESSAGES_INTERVENTION,
        payload: { key, value },
      });
    };

  const actResetFilterListOfMessages = () => (dispatch) => {
    dispatch({
      type: RESET_FILTER_LIST_OF_MESSAGES_INTERVENTION,
    });
  };

  const actRejectAcceptRequestMessageAsAdmin =
    (
      {
        interventionId,
        messageId,
        rejected_cause,
        state,
        channel,
        type,
        communities,
        contactLists,
        send_date,
        recommendations,
        objective,
        message,
        options,
        gi_type,
        type_information,
      },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const response = await rejectAcceptRequestMessageAsAdminProvider({
          interventionId,
          messageId,
          rejected_cause,
          state,
          channel,
          type,
          communities,
          contactLists,
          send_date,
          recommendations,
          objective,
          message,
          options,
          gi_type,
          type_information,
        });
        dispatch({ type: REJECTED_REQUEST_MESSAGE_BY_ARTIST });
        onSuccess && onSuccess(response);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actResetSelectedMessageIntervention = () => (dispatch) => {
    dispatch({
      type: RESET_SELECTED_MESSAGE_INTERVENTION,
    });
  };

  const actDownloadFileOfCollectionMessagesIntervention =
    ({ message_request_id, fileId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response =
          await downloadFileOfCollectionMessagesInterventionProvider({
            message_request_id,
            fileId,
          });
        dispatch({
          type: DOWNLOAD_FILE_OF_COLLECTION_MESSAGE,
        });
        onSuccess && onSuccess(response);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  return {
    actGetMessagesIntervention,
    actSendMessagesIntervention,
    actGetMessageIntervention,
    actDeleteMessageIntervention,
    actSetFilterListOfMessages,
    actResetFilterListOfMessages,
    actRejectAcceptRequestMessageAsAdmin,
    actResetSelectedMessageIntervention,
    actDownloadFileOfCollectionMessagesIntervention,
  };
};

export default useAdminViewInterventionsCommunicationActions;
