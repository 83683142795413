//Hooks
import useAdminCreateInterventionsDashboard from './create';
import useAdminEditInterventionsDashboard from './edit';
import useAdminRootInterventionsDashboard from './root';
import useAdminSelectArtistInterventionsDashboard from './selectArtist';
import useAdminCreativePlanIntervention from './creativePlan';
import useAdminKnowProjectIntervention from './knowProject';
import useAdminKnowProjectDetailIntervention from './knowProjectDetail';
import useAdminKnowCommunityInterventions from './knowCommunity';
import useAdminViewInterventionsControllers from './view';
import useDeepenKnowledgeCommunity from './deepenKnowledgeCommunity';
import useAdminKnowCommunityInterventionsSteep from './knowCommunitySteep';

const useAdminInterventionsDashboardControllers = () => {
  return {
    useAdminCreateInterventionsDashboard,
    useAdminEditInterventionsDashboard,
    useAdminRootInterventionsDashboard,
    useAdminSelectArtistInterventionsDashboard,
    useAdminCreativePlanIntervention,
    useAdminKnowProjectIntervention,
    useAdminKnowProjectDetailIntervention,
    useAdminKnowCommunityInterventions,
    useAdminViewInterventionsControllers,
    useDeepenKnowledgeCommunity,
    useAdminKnowCommunityInterventionsSteep,
  };
};

export default useAdminInterventionsDashboardControllers;
