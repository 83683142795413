import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';

//Assets
import infoIcon from 'assets/icons/info.svg';

//Hooks
import useViews from 'views';
import useStrings from 'strings';

const StyledGroup = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledContentButtons = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const FormRequest = (props) => {
  const {
    control,
    errors,
    register,
    optionsCommunities,
    watch,
    setValidateOptionsRules,
    handleAddOptions,
    numberOptions,
    handleDeleteOption,
    handleOnChangeOptions,
  } = props;
  const { useComponents } = useViews();

  const { useTypographies, useImages, useInputs, useLabels, useButtons } =
    useComponents();
  const { ButtonComponent } = useButtons();
  const { TypographyWithTooltip, TypographyComponent } = useTypographies();
  const { Image } = useImages();
  const { InputForm, Select, TextArea } = useInputs();
  const { DefaultLabel } = useLabels();

  const { useConstants } = useStrings();
  const {
    LIST_OF_CHANNELS_FOR_SEND_MESSAGES,
    LIST_OF_TYPE_OF_COLLECTION,
    LIST_OF_TYPE_OF_INFORMATION_COLLECTION,
  } = useConstants();

  return (
    <>
      <div className="flex items-center mb-2 mt-4">
        <DefaultLabel>Objetivo de la recolección de información *</DefaultLabel>
        <a
          data-tip="Objetivo de la recolección de información"
          data-for="Grupo protagonista"
        >
          <Image
            className="w-6 hidden lg:block ml-2"
            srcImage={infoIcon}
            altImage="info"
            titleImage="info"
          />
        </a>
        <ReactTooltip id="Grupo protagonista" />
      </div>
      <StyledGroup className="items-center mb-2" col2>
        <InputForm
          type="text"
          name="objective"
          placeholder="Ingresa el objetivo de la recolección de información"
          label=""
          className="w-full"
          {...register('objective')}
          errors={errors}
        />
      </StyledGroup>
      <StyledGroup noSpace className="mt-4">
        <div>
          <TypographyWithTooltip
            text="Tipo de recolección*"
            variant="p"
            textTooltip="Tipo de recolección*"
            idTooltip="gi_type"
            className="text-neutral-950"
          />
          <Select
            name="gi_type"
            id="gi_type"
            placeholder="Seleccionar"
            options={LIST_OF_TYPE_OF_COLLECTION}
            label=""
            control={control}
            errors={errors}
          />
        </div>
        {watch('gi_type')?.value === 'a' && (
          <div>
            <TypographyWithTooltip
              text="Tipo de información a recolectar*"
              variant="p"
              textTooltip="Tipo de información a recolectar*"
              idTooltip="type_information"
              className="text-neutral-950"
            />
            <Select
              name="type_information"
              id="type_information"
              placeholder="Seleccionar"
              options={LIST_OF_TYPE_OF_INFORMATION_COLLECTION}
              label=""
              control={control}
              errors={errors}
            />
          </div>
        )}
      </StyledGroup>
      <StyledGroup noSpace>
        <div>
          <div className="flex items-center mb-2 mt-4">
            <DefaultLabel>Canal de comunicación recomendado*</DefaultLabel>
            <a
              data-tip="Canal de comunicación recomendado"
              data-for="Grupo protagonista"
            >
              <Image
                className="w-6 hidden lg:block ml-2"
                srcImage={infoIcon}
                altImage="info"
                titleImage="info"
              />
            </a>
            <ReactTooltip id="Grupo protagonista" />
          </div>

          <Select
            name="channel"
            id="channel"
            placeholder="Selecciona el canal de comunicación"
            control={control}
            options={
              watch('gi_type')?.value === 'a'
                ? LIST_OF_CHANNELS_FOR_SEND_MESSAGES
                : [{ label: 'WhatsApp', value: 'ws' }]
            }
            label=""
            errors={errors}
          />
        </div>
        <div>
          <div className="flex items-center mb-2 mt-4">
            <DefaultLabel>Destino</DefaultLabel>
            <a data-tip="Destino" data-for="Grupo protagonista">
              <Image
                className="w-6 hidden lg:block ml-2"
                srcImage={infoIcon}
                altImage="info"
                titleImage="info"
              />
            </a>
            <ReactTooltip id="Grupo protagonista" />
          </div>

          <Select
            name="communities"
            id="communities"
            placeholder="Selecciona el destino"
            control={control}
            options={optionsCommunities}
            label=""
            errors={errors}
            multiple
          />
        </div>
      </StyledGroup>

      <div className="flex items-center mb-2 mt-4">
        <DefaultLabel>
          Recomendaciones al realizar la recolección de información
        </DefaultLabel>
        <a
          data-tip="Recomendaciones al realizar la recolección de información"
          data-for="Grupo protagonista"
        >
          <Image
            className="w-6 hidden lg:block ml-2"
            srcImage={infoIcon}
            altImage="info"
            titleImage="info"
          />
        </a>
        <ReactTooltip id="Grupo protagonista" />
      </div>
      <StyledGroup className="items-center mb-2" col2>
        <InputForm
          type="text"
          name="recommendations"
          placeholder="Ingresa las recomendaciones..."
          label=""
          className="w-full"
          {...register('recommendations')}
          errors={errors}
        />
      </StyledGroup>

      <StyledGroup>
        {watch('gi_type')?.value === 'a' ? (
          <>
            <TypographyWithTooltip
              text="Pregunta"
              variant="h3"
              textTooltip="pregunta"
              idTooltip="message"
              className="font-bold text-primary mt-6"
            />
            <InputForm
              name="message"
              label=""
              type="text"
              placeholder="Ingresa la pregunta"
              className="w-full col-span-2"
              errors={errors}
              control={control}
            />
          </>
        ) : (
          <>
            <TypographyWithTooltip
              text="Pregunta o mensaje"
              variant="h3"
              textTooltip="pregunta"
              idTooltip="message"
              className="font-bold text-primary mt-6"
            />

            <TextArea
              control={control}
              name="message"
              placeholder="Mensaje"
              className="w-full col-span-2"
              label=""
              errors={errors}
            />
          </>
        )}
      </StyledGroup>
      {watch('type_information')?.value === 'qn' &&
        watch('gi_type')?.value === 'a' && (
          <div className="flex items-end justify-between space-x-6 ">
            <div className="flex-1">
              {_.map(numberOptions, (option, index) => {
                return (
                  <div className="flex items-center space-x-6" key={option.id}>
                    <TypographyComponent
                      variant="p"
                      text={`Opción ${index + 1}`}
                      className="text-primary font-bold flex-none"
                    />
                    <InputForm
                      name={`options[${index}]`}
                      label=""
                      type="text"
                      placeholder={`Ingresa la opción ${index + 1}`}
                      className="flex-1"
                      errors={errors}
                      control={control}
                      value={option.value}
                      onChange={(event) => {
                        handleOnChangeOptions({
                          optionIndex: option.id,
                          optionValue: event.target.value,
                        });
                        setValidateOptionsRules((prevValue) => !prevValue);
                      }}
                    />
                    <StyledContentButtons>
                      <ButtonComponent
                        text=""
                        className="bg-transparent text-tertiary"
                        onPress={(event) => {
                          handleDeleteOption({
                            event,
                            optionId: index,
                          });
                        }}
                        icon={<div className={`icon-delete `}></div>}
                      />
                    </StyledContentButtons>
                  </div>
                );
              })}
            </div>
            <ButtonComponent
              variant="primary"
              mode="outline"
              text="Agregar otra opción"
              className="mb-2"
              icon={<div className="icon-close transform rotate-45" />}
              disabled={numberOptions.length === 10}
              onPress={handleAddOptions}
            />
          </div>
        )}

      {errors['options'] && (
        <p className="text-sm mt-2 text-error">{errors['options'].message}</p>
      )}
    </>
  );
};

FormRequest.propTypes = {
  optionsCommunities: PropTypes.array.isRequired,
  control: PropTypes.object,
  errors: PropTypes.object,
  register: PropTypes.func,
  watch: PropTypes.func,
  setValidateOptionsRules: PropTypes.func,
  handleAddOptions: PropTypes.func,
  numberOptions: PropTypes.array,
  handleDeleteOption: PropTypes.func,
  handleOnChangeOptions: PropTypes.func,
};

FormRequest.defaultProps = {
  control: {},
  errors: {},
  register: () => {},
  watch: () => {},
  setValidateOptionsRules: () => {},
  handleAddOptions: () => {},
  numberOptions: [],
  handleDeleteOption: () => {},
  handleOnChangeOptions: () => {},
};

export default FormRequest;
