//Packages
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useAdminDevelopmentInterventionCreateSketchProviders = () => {
  const getCreateSketchInterventionProvider = ({ interventionId }) => {
    const response = axios({
      method: 'GET',
      url: `/interventions/${interventionId}/sketch`,
    });
    return trackPromise(response);
  };

  const updateCreateSketchInterventionProvider = ({
    interventionId,
    sketchId,
    formData,
  }) => {
    const response = axios({
      method: formData.state === 'ip' ? 'PATCH' : 'POST',
      url: `/interventions/${interventionId}/sketch/${sketchId}`,
      data: formData,
    });
    return trackPromise(response);
  };

  const downloadSketchForInterventionProvider = ({
    interventionId,
    sketchId,
    fileId,
  }) => {
    const response = axios({
      method: 'GET',
      responseType: 'arraybuffer',
      url: `/interventions/${interventionId}/sketch/${sketchId}/file/${fileId}`,
    });
    return trackPromise(response);
  };

  const getPilotPlanInterventionProvider = ({ interventionId }) => {
    const response = axios({
      method: 'GET',
      url: `/interventions/${interventionId}/pilot-plan`,
    });
    return trackPromise(response);
  };

  const updatePilotPlanInterventionProvider = ({
    interventionId,
    pilotPlanId,
    data,
  }) => {
    const response = axios({
      method: 'POST',
      url: `/interventions/${interventionId}/pilot-plan/${pilotPlanId}`,
      data,
    });
    return trackPromise(response);
  };

  const downloadPilotPlanCriteriaFileInterventionProvider = ({
    interventionId,
    pilotPlanId,
    fileId,
  }) => {
    const response = axios({
      method: 'GET',
      responseType: 'arraybuffer',
      url: `/interventions/${interventionId}/pilot-plan/${pilotPlanId}/file/${fileId}`,
    });
    return trackPromise(response);
  };

  const createPilotPlanActivityInterventionProvider = ({
    interventionId,
    pilotPlanId,
    data,
  }) => {
    const response = axios({
      method: 'POST',
      url: `/interventions/${interventionId}/pilot-plan/${pilotPlanId}/activity`,
      data,
    });
    return trackPromise(response);
  };

  const updatePilotPlanActivityInterventionProvider = ({
    interventionId,
    pilotPlanId,
    activityId,
    data,
  }) => {
    const response = axios({
      method: 'PATCH',
      url: `/interventions/${interventionId}/pilot-plan/${pilotPlanId}/activity/${activityId}`,
      data,
    });
    return trackPromise(response);
  };

  const deletePilotPlanActivityInterventionProvider = ({
    interventionId,
    pilotPlanId,
    activityId,
  }) => {
    const response = axios({
      method: 'DELETE',
      url: `/interventions/${interventionId}/pilot-plan/${pilotPlanId}/activity/${activityId}`,
    });
    return trackPromise(response);
  };

  const getProgramPilotInterventionProvider = ({ interventionId }) => {
    const response = axios({
      method: 'GET',
      url: `/interventions/${interventionId}/program-pilot`,
    });
    return trackPromise(response);
  };

  const updateProgramPilotInterventionProvider = ({
    interventionId,
    programPilotId,
    data,
  }) => {
    const response = axios({
      method: 'PATCH',
      url: `/interventions/${interventionId}/program-pilot/${programPilotId}`,
      data,
    });
    return trackPromise(response);
  };

  const callAgentProgramPilotInterventionProvider = ({
    interventionId,
    programPilotId,
    data,
  }) => {
    const response = axios({
      method: 'POST',
      url: `/interventions/${interventionId}/program-pilot/${programPilotId}/agent`,
      data,
    });
    return trackPromise(response);
  };

  const getEvaluatePilotInterventionProvider = ({ interventionId }) => {
    const response = axios({
      method: 'GET',
      url: `/interventions/${interventionId}/evaluate-pilot`,
    });
    return trackPromise(response);
  };

  const updateEvaluatePilotInterventionProvider = ({
    interventionId,
    evaluatePilotId,
    data,
  }) => {
    const response = axios({
      method: 'POST',
      url: `/interventions/${interventionId}/evaluate-pilot/${evaluatePilotId}`,
      data,
    });
    return trackPromise(response);
  };

  const uploadFileEvaluatePilotInterventionProvider = ({
    interventionId,
    evaluatePilotId,
    data,
  }) => {
    const response = axios({
      method: 'POST',
      url: `/interventions/${interventionId}/evaluate-pilot/${evaluatePilotId}/file`,
      data,
    });
    return trackPromise(response);
  };

  const downloadFileEvaluatePilotInterventionProvider = ({
    interventionId,
    evaluatePilotId,
    fileId,
  }) => {
    const response = axios({
      method: 'GET',
      responseType: 'arraybuffer',
      url: `/interventions/${interventionId}/evaluate-pilot/${evaluatePilotId}/file/${fileId}`,
    });
    return trackPromise(response);
  };

  const deleteFileEvaluatePilotInterventionProvider = ({
    interventionId,
    evaluatePilotId,
    fileId,
  }) => {
    const response = axios({
      method: 'DELETE',
      url: `/interventions/${interventionId}/evaluate-pilot/${evaluatePilotId}/file/${fileId}`,
    });
    return trackPromise(response);
  };

  return {
    getCreateSketchInterventionProvider,
    updateCreateSketchInterventionProvider,
    downloadSketchForInterventionProvider,
    getPilotPlanInterventionProvider,
    updatePilotPlanInterventionProvider,
    downloadPilotPlanCriteriaFileInterventionProvider,
    createPilotPlanActivityInterventionProvider,
    updatePilotPlanActivityInterventionProvider,
    deletePilotPlanActivityInterventionProvider,
    getProgramPilotInterventionProvider,
    updateProgramPilotInterventionProvider,
    callAgentProgramPilotInterventionProvider,
    getEvaluatePilotInterventionProvider,
    updateEvaluatePilotInterventionProvider,
    uploadFileEvaluatePilotInterventionProvider,
    downloadFileEvaluatePilotInterventionProvider,
    deleteFileEvaluatePilotInterventionProvider,
  };
};

export default useAdminDevelopmentInterventionCreateSketchProviders;
