//Packages
import _ from 'lodash';

//Hooks
import useHelpers from 'helpers';
import useInitialStates from '../../../initialStates';
import useStrings from 'strings';

const useContactListReducer = () => {
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  const { useAdminInitialStates } = useInitialStates();
  const { useContacListStates } = useAdminInitialStates();
  const { initialStateContactList } = useContacListStates();

  const { useAdminTypes } = useStrings();
  const { useContactListTypes } = useAdminTypes();
  const {
    DELETE_CONTACT_LIST,
    UPDATE_CONTACT_LIST,
    STORE_CONTACT_LIST,
    GET_CONTACT_LIST,
    GET_CONTACT_LISTS,
    REMOVE_CONTACT_TO_CONTACT_LIST,
    SEARCH_CONTACTS_IN_LIST,
    RESET_DATA_CONTACT_LIST_SELECTED,
  } = useContactListTypes();
  const contactList = createReducer(initialStateContactList, {
    [GET_CONTACT_LISTS](state, action) {
      return { ...state, contactsLists: action.payload };
    },
    [GET_CONTACT_LIST](state, action) {
      return { ...state, contactList: action.payload.data };
    },
    [UPDATE_CONTACT_LIST](state, action) {
      return {
        ...state,
        contactsLists: {
          ...state.contactsLists,
          data: _.map(state.contactsLists.data, (contactList) => {
            if (contactList.id == action.payload.id) {
              return {
                ...contactList,
                name: action.payload.name,
              };
            } else {
              return contactList;
            }
          }),
        },
      };
    },
    [SEARCH_CONTACTS_IN_LIST](state, action) {
      return {
        ...state,
        contactList: {
          ...state.contactList,
          users: action.payload.data,
        },
      };
    },
    [STORE_CONTACT_LIST](state, action) {
      if (state.contactsLists.data.length < 16) {
        return {
          ...state,
          contactsLists: {
            ...state.contactsLists,
            data: [...state.contactsLists.data, action.payload.data],
          },
        };
      } else {
        return state;
      }
    },
    [DELETE_CONTACT_LIST](state, action) {
      return {
        ...state,
        contactsLists: {
          ...state.contactsLists,
          data: _.filter(
            state.contactsLists.data,
            (contactList) => contactList.id !== action.payload
          ),
        },
      };
    },
    [REMOVE_CONTACT_TO_CONTACT_LIST](state, action) {
      return {
        ...state,
        contactList: {
          ...state.contactList,
          users: _.filter(
            state.contactList.users,
            (user) => user.id != action.payload[0]
          ),
        },
      };
    },
    [RESET_DATA_CONTACT_LIST_SELECTED](state) {
      return {
        ...state,
        contactList: {
          id: '',
          name: '',
          user: {},
          users: [],
        },
      };
    },
  });

  return contactList;
};
export default useContactListReducer;
