import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useLegalDataProviders = () => {
  const legalDataProvider = () => {
    const response = axios({
      method: 'GET',
      url: '/commons/legal-data',
    });
    return trackPromise(response);
  };
  return { legalDataProvider };
};
export default useLegalDataProviders;
