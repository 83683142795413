// Packages
import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const StyledWrapperInputFile = styled.div`
  ${tw` relative py-3 px-4 text-center bg-neutral-300 border rounded flex items-center justify-center`}
`;

export const StyledInput = styled.input`
  ${tw` absolute top-0 left-0 w-full h-full opacity-0 z-20 cursor-pointer`}
`;

export const StyledTitleInput = styled.div`
  ${tw` relative flex text-neutral-700 pointer-events-none z-10`}
`;
