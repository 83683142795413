import useAdminCommunitiesInitialStates from './communities';
import useAdminProjectsRootInitialStates from './projectsRoot';
import useAdminInterventionsInitialStates from './interventions';

const useAdminProjectsInitialStates = () => {
  return {
    useAdminProjectsRootInitialStates,
    useAdminCommunitiesInitialStates,
    useAdminInterventionsInitialStates,
  };
};

export default useAdminProjectsInitialStates;
