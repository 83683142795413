//Hooks
import useAdminProviders from './admin';
import useAuthProviders from './auth';
import useGeneralProviders from './general';
import useUserProviders from './user';
import useContactListProviders from './contactList';
import useDashboardProviders from './dashboard';

const useProviders = () => {
  return {
    useAuthProviders,
    useUserProviders,
    useGeneralProviders,
    useAdminProviders,
    useContactListProviders,
    useDashboardProviders,
  };
};

export default useProviders;
