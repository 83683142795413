import React from 'react';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';
import useHelpers from 'helpers';

// Styles
import { StyledGroup } from 'styles/formViews/formViews.styles';
import { StyledButtonsActions } from 'styles/buttons/buttons.styles';
import { StyledContentButtonsModal } from 'styles/tableViews/tableViews.styles';

const FinishIntervention = () => {
  const { useComponents } = useViews();
  const {
    useTypographies,
    useButtons,
    useInputs,
    useImages,
    useModals,
    useCards,
    useLabels,
  } = useComponents();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { UploadImagePreview } = useImages();
  const { DefaultModal } = useModals();
  const { ButtonComponent } = useButtons();
  const { InputForm, TextArea, CheckBox } = useInputs();
  const { CardNotification } = useCards();
  const { DefaultLabel } = useLabels();

  const { useQuickFunctions } = useHelpers();
  const { getMimeTypeFromMedia } = useQuickFunctions();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsDevelopmentIntervention } =
    useAdminViewInterventionsControllers();
  const { useViewDevelopmentInterventionSpreadIntervention } =
    useAdminViewInterventionsDevelopmentIntervention();
  const { useAdminSpreadIntervention } =
    useViewDevelopmentInterventionSpreadIntervention();
  const {
    spreadIntervention,
    formGoals,
    formSpreadIntervention,
    goals,
    editingGoal,
    showModalDeleteGoal,
    toggleModalDeleteGoal,
    openModalApprove,
    toggleModalApprove,
    stepCompleted,
    saveGoal,
    editGoal,
    deleteGoal,
    deleteFile,
    updateSpreadIntervention,
  } = useAdminSpreadIntervention();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = formSpreadIntervention;

  const {
    handleSubmit: handleSubmitGoal,
    register: registerGoal,
    formState: { errors: errorsGoal },
  } = formGoals;

  return (
    <>
      {stepCompleted ||
        (_.includes(['ip'], spreadIntervention.state.id) && (
          <CardNotification
            className="w-5/12 mx-auto py-9 px-4 mt-16"
            title={
              <TypographyComponent
                text={spreadIntervention.state?.name}
                variant="h2"
                className="font-bold text-primary icon-watch text-center"
              />
            }
          />
        ))}
      <TypographyComponent
        text="Formulario de finalización de intervención"
        variant="h2"
        className="text-primary mt-2 font-bold"
      />
      <TypographyComponent
        text="Ha llegado la hora de recopilar todos los aprendizajes y observaciones sobre esta experiencia."
        variant="p"
        className="text-neutral-950 mt-4 mb-4"
      />

      <TypographyWithTooltip
        text="Visión general"
        variant="h3"
        textTooltip="Visión general"
        className="text-primary mt-4"
        idTooltip="general_vision"
        applyFontBold
      />

      <form>
        <DefaultLabel>
          {'¿Cuáles fueron los logros de esta intervención?*'}
        </DefaultLabel>
        {goals?.length > 0 &&
          _.map(goals, (goal, idx) => (
            <div key={`goal-${goal.id ?? idx}`}>
              {editingGoal && editingGoal.id === goal.id && (
                <StyledGroup col-2 className="mb-4 mt-4" noSpace>
                  <div className="flex items-end w-full col-span-2">
                    <InputForm
                      name="goal"
                      label=""
                      type="text"
                      placeholder="Ingresa el logro alcanzado"
                      className="flex-1"
                      {...registerGoal('goal')}
                      errors={{ ...errorsGoal, ...errors }}
                    />
                    <ButtonComponent
                      variant="primary"
                      mode="outline"
                      text="Guardar logro"
                      className="mb-1 ml-8"
                      onPress={handleSubmitGoal(saveGoal)}
                    />
                  </div>
                </StyledGroup>
              )}
              {(!editingGoal || editingGoal.id !== goal.id) && (
                <div
                  className="bg-white py-2 px-4 flex justify-between rounded-sm items-center mt-4"
                  key={`goal-${goal.id ?? idx}`}
                >
                  <TypographyComponent
                    variant="p"
                    text={goal.text ?? goal}
                    className="flex-grow"
                  />
                  {!stepCompleted && (
                    <StyledButtonsActions className="flex items-center">
                      <ButtonComponent
                        text=""
                        className="icon-edit text-primary-100"
                        onPress={(e) => editGoal(e, goal)}
                      />
                      <ButtonComponent
                        text=""
                        className="icon-delete"
                        onPress={(e) => deleteGoal(e, goal)}
                      />
                    </StyledButtonsActions>
                  )}
                </div>
              )}
            </div>
          ))}
        {!stepCompleted && !editingGoal && (
          <StyledGroup col-2 className="mb-4 mt-4" noSpace>
            <div className="flex items-end w-full col-span-2">
              <InputForm
                name="goal"
                label=""
                type="text"
                placeholder="Ingresa el logro alcanzado"
                className="flex-1 mr-8"
                {...registerGoal('goal')}
                errors={{ ...errorsGoal, ...errors }}
              />
              <ButtonComponent
                variant="primary"
                mode="outline"
                text="Agregar nuevo logro"
                className="mb-2"
                icon={
                  editingGoal ? (
                    <></>
                  ) : (
                    <div className="icon-close transform rotate-45" />
                  )
                }
                onPress={handleSubmitGoal(saveGoal)}
              />
            </div>
          </StyledGroup>
        )}
      </form>

      <StyledGroup col-2 className="mb-4 mt-4" noSpace>
        <TextArea
          name="suggestions"
          placeholder="Escribe las recomendaciones que le darías a otros artistas"
          className="w-full col-span-2"
          label="¿Qué recomendaciones le darías a otros artistas que vayan a realizar una intervención similar?*"
          {...register('suggestions')}
          errors={errors}
          disabled={stepCompleted}
        />
      </StyledGroup>

      <TypographyWithTooltip
        text="Visión de la comunidad"
        variant="h3"
        textTooltip="Visión de la comunidad"
        className="text-primary mt-4"
        idTooltip="communitie_vision"
        applyFontBold
      />

      <StyledGroup col-2 className="mb-4 mt-4" noSpace>
        <TextArea
          name="communityLiked"
          placeholder="Escribe las opiniones de la comunidad"
          className="w-full col-span-2"
          label="¿Qué fue lo que más le gustó a la comunidad?*"
          {...register('communityLiked')}
          errors={errors}
          disabled={stepCompleted}
        />
      </StyledGroup>

      <StyledGroup col-2 className="mb-4 mt-4" noSpace>
        <TextArea
          name="communitySuggestions"
          placeholder="Escribe las sugerencias de la comunidad"
          className="w-full col-span-2"
          label="Sugerencias de la comunidad"
          {...register('communitySuggestions')}
          errors={errors}
          disabled={stepCompleted}
        />
      </StyledGroup>

      {(stepCompleted ? spreadIntervention.files.length > 0 : true) && (
        <TypographyWithTooltip
          text="Información complementaria"
          variant="h3"
          textTooltip="Información complementaria"
          className="text-primary mt-4"
          idTooltip="complementary_vision"
          applyFontBold
        />
      )}

      <StyledGroup col-2 className="mb-4 mt-4" noSpace>
        <TextArea
          name="additionalInfo"
          placeholder="Escribe la información complementaria de la intervención"
          className="w-full col-span-2"
          label=""
          {...register('additionalInfo')}
          errors={errors}
          disabled={stepCompleted}
        />
      </StyledGroup>

      <TypographyWithTooltip
        text="Contenido multimedia"
        variant="h3"
        textTooltip="Contenido multimedia"
        className="text-primary mt-4"
        idTooltip="multimedia"
        applyFontBold
      />

      <div className="border px-8 py-4 mt-4">
        <TypographyComponent
          variant="p"
          text="Agregar imágenes o videos"
          className="flex-grow text-neutral-950 font-bold mb-1"
        />

        <StyledGroup flex>
          {spreadIntervention.files.length > 0 &&
            _.map(spreadIntervention.files, (file) => (
              <div
                className="flex items-start relative space-x-4 w-72"
                key={`media-${file.id}`}
              >
                <Controller
                  control={control}
                  name={`media-${file.id}`}
                  render={({ field: { onChange, name } }) => {
                    return (
                      <UploadImagePreview
                        name={name}
                        onChange={onChange}
                        errors={errors}
                        srcDefaultImage={file.url}
                        resetInput={() => deleteFile(file)}
                        disabled={stepCompleted}
                        isAVideo={getMimeTypeFromMedia({ urlFile: file.url })}
                      />
                    );
                  }}
                />
              </div>
            ))}

          {!stepCompleted &&
            _.map(
              new Array(Math.max(0, 4 - spreadIntervention.files.length)),
              (a, idx) => {
                return (
                  <div
                    className="flex items-start relative space-x-4 w-72"
                    key={`media-${Date.now() + idx}`}
                  >
                    <Controller
                      control={control}
                      name="media"
                      render={({ field: { onChange, name } }) => {
                        return (
                          <UploadImagePreview
                            name={name}
                            onChange={onChange}
                            errors={{}}
                            keepPlaceholder
                          />
                        );
                      }}
                    />
                  </div>
                );
              }
            )}
        </StyledGroup>
        <br />
        <TypographyComponent
          text={errors['media']?.message ?? ''}
          variant="p"
          className={`text-primary ${errors['media'] && 'text-error'}`}
        />
      </div>

      {!stepCompleted && (
        <>
          <div className="flex items-center mt-12 justify-center">
            <ButtonComponent
              text="Finalizar intervención"
              onPress={handleSubmit(toggleModalApprove)}
            />
          </div>

          <DefaultModal
            isActive={showModalDeleteGoal}
            handleClose={toggleModalDeleteGoal}
            hasCloseButton={false}
            isHideAutomatic
          >
            <TypographyComponent
              text={'Logro eliminado correctamente'}
              variant="h3"
              className="text-primary font-bold whitespace-pre-wrap"
            />
            <StyledContentButtonsModal>
              <ButtonComponent
                variant="primary"
                text="Aceptar"
                className="mx-auto w-full lg:w-auto lg:mx-2"
                onPress={toggleModalDeleteGoal}
              />
            </StyledContentButtonsModal>
          </DefaultModal>

          <DefaultModal
            isActive={openModalApprove}
            classNameContent="max-w-screen-md w-11/12"
            handleClose={toggleModalApprove}
          >
            <TypographyComponent
              text="Finalizar intervención"
              variant="h3"
              className="font-bold text-primary mb-1"
            />

            <div className="border px-4 py-4 rounded mt-8">
              <Controller
                control={control}
                name="publishIntervention"
                render={({ field: { name, onChange } }) => {
                  return (
                    <CheckBox
                      name={name}
                      label="Publicar la intervención en el home principal de Lazos de Agua (opcional)"
                      className="mt-6"
                      onChange={onChange}
                      errors={errors}
                    />
                  );
                }}
              />
            </div>

            <StyledContentButtonsModal>
              <ButtonComponent
                variant="primary"
                text="Cancelar"
                className="mx-auto w-full lg:w-auto lg:mx-2 "
                mode="outline"
                onPress={toggleModalApprove}
              />
              <ButtonComponent
                variant="primary"
                text="Aceptar"
                className="mx-auto w-full lg:w-auto lg:mx-2"
                onPress={handleSubmit(updateSpreadIntervention)}
              />
            </StyledContentButtonsModal>
          </DefaultModal>
        </>
      )}
    </>
  );
};

export default FinishIntervention;
