//Packages
import React from 'react';
import PropTypes from 'prop-types';

//Hooks
import useControllers from 'controllers';
import useViews from 'views';

//Styles
import {
  StyledMainImagePreview,
  StyledImagePreview,
  StyledPreviewVideo,
  StyledInputFile,
} from './UploadImagePreview.styles';

//Assets
import imagePlaceholderVideoImage from 'assets/img/preview-imagen.jpg';
import imagePlaceholderImage from 'assets/img/img-placeholder-image.png';
import imagePlaceholderOnlyImage from 'assets/img/placeholder-only-image.png';
import iconDelete from 'assets/icons/delete.svg';
import iconEdit from 'assets/icons/edit-image.svg';

const UploadImagePreview = (props) => {
  const {
    onChange,
    name,
    errors,
    resetInput,
    cleanFileInputs,
    srcDefaultImage,
    isAVideo,
    srcPlaceholderOnlyImage,
    onlyImagesAllowed,
    keepPlaceholder,
    disabled,
    showEditButton,
    removeMedia,
    putMedia,
  } = props;

  const { useComponents } = useViews();
  const { useImages, useTypographies } = useComponents();
  const { TypographyComponent } = useTypographies();
  const { Image } = useImages();

  const { useComponentHooks } = useControllers();
  const { useImagesControllers } = useComponentHooks();
  const { useUploadImagePreview } = useImagesControllers();
  const {
    inputRef,
    handleOpenSelectFile,
    blobFile,
    handleFileToBlobFile,
    isVideo,
    handleRemoveFile,
    videoRef,
  } = useUploadImagePreview({
    onChange,
    resetInput,
    cleanFileInputs,
    srcDefaultImage,
    isAVideo,
    removeMedia,
    putMedia,
  });

  const placeholderImage = onlyImagesAllowed
    ? imagePlaceholderOnlyImage
    : imagePlaceholderImage;
  const placeholderVideo = onlyImagesAllowed
    ? imagePlaceholderOnlyImage
    : imagePlaceholderVideoImage;

  return (
    <>
      <StyledMainImagePreview>
        <StyledImagePreview
          onClick={() => {
            (!blobFile.blobUrl || keepPlaceholder) &&
              !disabled &&
              handleOpenSelectFile();
          }}
          className={`relative ${blobFile.blobUrl && 'mb-5'}`}
        >
          {isVideo && !keepPlaceholder ? (
            <StyledPreviewVideo
              className="w-full h-full border border-neutral-600 rounded-md object-cover object-center"
              controls
              ref={videoRef}
            >
              <source src={blobFile.blobUrl} type={blobFile.mimeType} />
            </StyledPreviewVideo>
          ) : (
            <Image
              srcImage={
                srcPlaceholderOnlyImage
                  ? keepPlaceholder
                    ? placeholderImage
                    : blobFile.blobUrl || placeholderImage
                  : keepPlaceholder
                  ? placeholderVideo
                  : blobFile.blobUrl || placeholderVideo
              }
              className="w-full h-full border border-neutral-600 rounded-md object-cover object-center"
              altImage="imagenes para comunidad"
              titleImage="imagenes para comunidad"
            />
          )}
          {blobFile.blobUrl &&
            !keepPlaceholder &&
            !disabled &&
            showEditButton && (
              <Image
                srcImage={iconEdit}
                altImage="editar imagen"
                titleImage="editar imagen"
                className="absolute w-10 top-2 right-2 cursor-pointer"
                onClick={handleOpenSelectFile}
              />
            )}
          {blobFile.blobUrl && !keepPlaceholder && !disabled && (
            <Image
              onClick={handleRemoveFile}
              srcImage={iconDelete}
              altImage="borrar imagen"
              titleImage="borrar imagen"
              className="block mx-auto w-8 mt-2 cursor-pointer"
            />
          )}
        </StyledImagePreview>
        {errors[name] && (
          <TypographyComponent
            variant="h5"
            className={`${
              blobFile.blobUrl && 'mt-10'
            } error-image-preview text-sm mt-2 text-error`}
            text={errors[name].message}
          />
        )}
      </StyledMainImagePreview>

      <StyledInputFile
        type="file"
        ref={inputRef}
        name={name}
        onChange={handleFileToBlobFile}
      />
    </>
  );
};

UploadImagePreview.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  errors: PropTypes.object,
  resetInput: PropTypes.func,
  cleanFileInputs: PropTypes.bool,
  srcDefaultImage: PropTypes.string,
  isAVideo: PropTypes.string,
  srcPlaceholderOnlyImage: PropTypes.bool,
  keepPlaceholder: PropTypes.bool,
  disabled: PropTypes.bool,
  showEditButton: PropTypes.bool,
  removeMedia: PropTypes.bool,
  putMedia: PropTypes.bool,
  onlyImagesAllowed: PropTypes.bool,
};

UploadImagePreview.defaultProps = {
  name: '',
  errors: {},
  resetInput: () => {},
  cleanFileInputs: false,
  srcDefaultImage: '',
  isAVideo: '',
  srcPlaceholderOnlyImage: false,
  keepPlaceholder: false,
  disabled: false,
  showEditButton: true,
  removeMedia: true,
  putMedia: true,
  onlyImagesAllowed: false,
};

export default UploadImagePreview;
