//Hooks
import useApi from '../../../..';
import useControllers from '../../../../../controllers';
import useStrings from '../../../../../strings';

const useAdminDocumentationActions = () => {
  const { useAdminTypes } = useStrings();
  const { useAdminProjectsTypes } = useAdminTypes();
  const { useAdminDocumentationTypes } = useAdminProjectsTypes();
  const {
    GET_DOCUMENTATION_BY_PROJECT,
    CREATE_DOCUMENTATION_BY_PROJECT,
    DELETE_DOCUMENTATION_BY_PROJECT,
    DOWNLOAD_DOCUMENTATION_BY_PROJECT,
  } = useAdminDocumentationTypes();

  const { useProviders } = useApi();
  const { useAdminProviders } = useProviders();
  const { useAdminProjectsProviders } = useAdminProviders();
  const { useAdminProjectsDocumentationProviders } =
    useAdminProjectsProviders();
  const {
    getDocumentationByProject,
    createDocumentationByProject,
    deleteDocumentationByProject,
    downloadDocumentationByProject,
  } = useAdminProjectsDocumentationProviders();

  const { useGeneralHooks } = useControllers();
  const { useConsole } = useGeneralHooks();
  const { viewConsole } = useConsole();

  const actGetDocumentationByProject =
    ({ projectId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await getDocumentationByProject({
          projectId,
        });
        dispatch({
          type: GET_DOCUMENTATION_BY_PROJECT,
          payload: res.data?.data?.annexes,
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actCreateDocumentationByProject =
    ({ projectId, file }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const bodyFormData = new FormData();
        bodyFormData.append('file', file);

        const res = await createDocumentationByProject({
          projectId,
          formData: bodyFormData,
        });
        dispatch({
          type: CREATE_DOCUMENTATION_BY_PROJECT,
          payload: res.data?.data?.annexes,
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actDeleteDocumentationByProject =
    ({ projectId, documentationId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await deleteDocumentationByProject({
          projectId,
          documentationId,
        });
        dispatch({
          type: DELETE_DOCUMENTATION_BY_PROJECT,
          payload: { documentationId },
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actDownloadDocumentationByProject =
    ({ projectId, documentationId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await downloadDocumentationByProject({
          projectId,
          documentationId,
        });
        dispatch({
          type: DOWNLOAD_DOCUMENTATION_BY_PROJECT,
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  return {
    actGetDocumentationByProject,
    actCreateDocumentationByProject,
    actDeleteDocumentationByProject,
    actDownloadDocumentationByProject,
  };
};

export default useAdminDocumentationActions;
