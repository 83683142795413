//Packages
import React, { lazy } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemState,
  AccordionItemPanel,
} from 'react-accessible-accordion';

//Hooks
import useViews from 'views';

//Styles
const StyledGroup = lazy(() =>
  import(
    '../../../../../../../../../../../../../styles/formViews/formViews.styles'
  ).then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledDivider = lazy(() =>
  import(
    '../../../../../../../../../../../../../styles/formViews/formViews.styles'
  ).then((mod) => ({
    default: mod.StyledDivider,
  }))
);

const StyledButtonsActions = lazy(() =>
  import(
    '../../../../../../../../../../../../../styles/buttons/buttons.styles'
  ).then((mod) => ({
    default: mod.StyledButtonsActions,
  }))
);

const SpreadIntervention = (props) => {
  const {
    checkboxesConvocationMethod,
    description,
    checkboxesDynamics,
    fileUrl,
    listOfTasks,
    descriptionDiffusion,
    fileUrlDynamic,
    objetive,
    openFile,
    downloadFile,
  } = props;
  const { useComponents } = useViews();

  const { useTypographies, useInputs, useButtons } = useComponents();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { TextArea, CheckBox, InputForm } = useInputs();
  const { ButtonComponent } = useButtons();

  return (
    <>
      <StyledDivider />
      <TypographyComponent
        text={
          objetive === 'Inspirar'
            ? 'Definir convocatoria'
            : 'Definir convocatoria y difusión'
        }
        variant="h2"
        className="text-primary mt-8 font-bold"
      />
      <TypographyComponent
        text="En este paso te invitamos a identificar el proceso de convocatoria donde invitas a los participantes a ser parte de esta intervención y difusión.        "
        variant="p"
        className="text-neutral-950 mt-2 mb-4"
      />

      <form className="">
        <StyledGroup col2 flex className="mb-4 flex-col">
          <TypographyWithTooltip
            text="Métodos para convocar a la presentación*"
            variant="h3"
            textTooltip="Métodos para convocar a la presentación"
            className="text-primary mb-4 mt-4"
            idTooltip="aspects_project"
            applyFontBold
          />
          <StyledGroup flex noSpace className="mb-4 flex-col space-y-4">
            {_.map(checkboxesConvocationMethod, (checkbox, index) => (
              <div key={index}>
                <CheckBox
                  name="all_contacts"
                  label={checkbox.label}
                  withDefaultChecked={false}
                  checked={checkbox.checked}
                  disabled
                  className="mb-4"
                />
              </div>
            ))}
          </StyledGroup>
        </StyledGroup>
        <StyledGroup col2 className="mb-4">
          <TextArea
            name="description"
            placeholder="Que actividades realizare"
            className="w-full col-span-2"
            label="Descripción de la convocatoria*"
            defaultValue={description}
            disabled
          />
        </StyledGroup>
        <TypographyComponent
          text="Metodología de la convocatoria*"
          variant="h3"
          className="text-primary mt-12 font-bold"
        />
        <TypographyComponent
          text="Te invitamos a adjuntar la información detallada sobre la metodología que utilizaras para convocar los participantes."
          variant="p"
          className="text-neutral-950 mt-2 mb-4"
        />
        <div className="bg-white py-2 px-4 flex justify-between rounded-sm items-center">
          <TypographyComponent
            variant="p"
            text={fileUrl?.url ?? ''}
            className="flex-grow text-primary font-bold"
          />
          <StyledButtonsActions className="flex items-center">
            <ButtonComponent
              text=""
              className="icon-view"
              onPress={(event) => openFile({ event, urlFile: fileUrl.url })}
            />
            <ButtonComponent
              text=""
              className="icon-download"
              onPress={(event) =>
                downloadFile({
                  event,
                  idFile: fileUrl.id,
                  nameFile: 'Metodologia-convocatoria',
                })
              }
            />
          </StyledButtonsActions>
        </div>

        <TypographyComponent
          text="Asignación de tareas para la materialización de la convocatoria*"
          variant="h3"
          className="text-primary mt-12 font-bold"
        />
        <TypographyComponent
          text="Enumera aquí las tareas claves para llevar a cabo la convocatoria, e identifica los responsables para realizar cada una de estas tareas."
          variant="p"
          className="text-neutral-950 mt-2 mb-4"
        />

        <Accordion allowZeroExpanded>
          {listOfTasks.length > 0 &&
            _.map(listOfTasks, (task, index) => {
              return (
                <div className="mt-2 bg-white" key={`itemIntnternv-${index}`}>
                  <AccordionItem>
                    <div className="flex items-center justify-between px-6 py-2">
                      <TypographyComponent
                        variant="p"
                        text={task.name}
                        className="flex-grow text-primary font-bold"
                      />
                      <>
                        <AccordionItemState>
                          {({ expanded }) =>
                            expanded ? (
                              <AccordionItemButton>
                                <div className="icon-arrow-down text-xxs text-neutral-900 transform rotate-180" />
                              </AccordionItemButton>
                            ) : (
                              <AccordionItemButton>
                                <div className="icon-arrow-down text-xxs text-neutral-900" />
                              </AccordionItemButton>
                            )
                          }
                        </AccordionItemState>
                      </>
                    </div>
                    <AccordionItemPanel>
                      <StyledGroup col2 flex className="mb-4 flex-col">
                        <InputForm
                          name="name"
                          label="Nombre de la tarea*"
                          type="text"
                          className="full"
                          placeholder="Ingresa el Nombre de la tarea*"
                          defaultValue={task.name}
                          disabled
                        />
                      </StyledGroup>
                      <StyledGroup col2 flex className="mb-4 flex-col">
                        <TextArea
                          name="target"
                          placeholder="Descripción de la tarea*"
                          className="w-full col-span-2"
                          label="Descripción de la tarea*"
                          defaultValue={task.description}
                          disabled
                        />
                      </StyledGroup>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              );
            })}
        </Accordion>

        {objetive !== 'Inspirar' && (
          <>
            <StyledDivider />
            <TypographyWithTooltip
              text="Dinámica de la difusión*"
              variant="h3"
              textTooltip="Dinámica de la difusión"
              className="text-primary mb-4 "
              idTooltip="dinamic_difition"
              applyFontBold
            />
            <TypographyComponent
              text="Recuerda que la intervención incluye una difusión al final del proceso. Te invitamos a identificar cuál(es) serán los medios utilizados para esta etapa."
              variant="p"
              className="text-neutral-950 mt-2 mb-4"
            />

            <StyledGroup flex noSpace className="mb-4 flex-col">
              {_.map(checkboxesDynamics, (dynamic, index) => (
                <CheckBox
                  key={index}
                  name={'dinamic'}
                  label={dynamic.label}
                  withDefaultChecked={false}
                  checked={dynamic.checked}
                  disabled
                  className=" pb-4"
                />
              ))}
            </StyledGroup>

            <StyledGroup col2 className="mb-4">
              <TextArea
                name="description"
                placeholder="Describe cómo se va a realizar la difusión"
                className="w-full col-span-2"
                label="Descripción de la difusión*"
                defaultValue={descriptionDiffusion}
                disabled
              />
            </StyledGroup>

            <TypographyComponent
              text="Metodología de la difusión*"
              variant="h3"
              className="text-primary mt-4 font-bold"
            />
            <TypographyComponent
              text="Adjunta un documento explicativo con la informacion detallada de la metodología incluyendo participantes claves y sus responsabilidades."
              variant="p"
              className="text-neutral-950 mt-2 mb-4"
            />

            <div className="bg-white py-2 px-4 flex justify-between rounded-sm items-center">
              <TypographyComponent
                variant="p"
                text={fileUrlDynamic?.url ?? ''}
                className="flex-grow text-primary font-bold"
              />
              <StyledButtonsActions className="flex items-center">
                <ButtonComponent
                  text=""
                  className="icon-view"
                  onPress={(event) =>
                    openFile({ event, urlFile: fileUrlDynamic.url })
                  }
                />
                <ButtonComponent
                  text=""
                  icon={<div className="icon-download text-primary text-lg" />}
                  onPress={(event) =>
                    downloadFile({
                      event,
                      idFile: fileUrlDynamic.id,
                      nameFile: 'Metodologia-dinámica',
                    })
                  }
                />
              </StyledButtonsActions>
            </div>
          </>
        )}
      </form>
    </>
  );
};

SpreadIntervention.propTypes = {
  checkboxesConvocationMethod: PropTypes.array,
  description: PropTypes.string,
  checkboxesDynamics: PropTypes.array,
  fileUrl: PropTypes.object,
  descriptionDiffusion: PropTypes.string,
  objetive: PropTypes.string,
  fileUrlDynamic: PropTypes.object,
  listOfTasks: PropTypes.array,
  openFile: PropTypes.func,
  downloadFile: PropTypes.func,
};

SpreadIntervention.defaultProps = {
  checkboxesConvocationMethod: [],
  description: '',
  checkboxesDynamics: [],
  fileUrl: {},
  fileUrlDynamic: {},
  descriptionDiffusion: '',
  objetive: '',
  listOfTasks: [],
  openFile: () => {},
  downloadFile: () => {},
};

export default SpreadIntervention;
