//Packages
import { useHistory } from 'react-router-dom';
import useApi from '../../../../api';

const useAdminSidebar = () => {
  const history = useHistory();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminUsersActions } = useAdminActions();
  const { actResetAllFilterDataUser } = useAdminUsersActions();

  const menuItems = [
    // { title: 'Bandeja de correo', icon: 'icon-mail' },
    {
      title: 'Proyectos',
      icon: 'icon-book',
      onClick: () => history.push('/admin/projects'),
      route: '/admin/projects',
    },
    {
      title: 'Contactos de las comunidades',
      icon: 'icon-comunities',
      onClick: () => {
        dispatch(actResetAllFilterDataUser());
        history.push('/admin/contacts');
      },
      route: '/admin/contacts',
    },
    {
      title: 'Administración de usuarios',
      icon: 'icon-settings',
      onClick: () => {
        dispatch(actResetAllFilterDataUser());
        history.push('/admin/users');
      },
      route: '/admin/users',
    },
    {
      title: 'Gestión de contenidos',
      icon: 'icon-gestion',
      items: [
        {
          title: 'Home',
          icon: 'icon-home cursor-pointer',
          onClick: () => history.push('/admin/home'),
          route: '/admin/home',
        },
        {
          title: 'Intervenciones',
          icon: 'icon-interventions cursor-pointer',
          onClick: () => history.push('/admin/interventions'),
          route: '/admin/interventions',
        },
        {
          title: 'Etiquetas',
          icon: 'icon-tag cursor-pointer',
          onClick: () => history.push('/admin/labels'),
          route: '/admin/labels',
        },
      ],
    },
  ];

  return { menuItems };
};

export default useAdminSidebar;
