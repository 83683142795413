//Packages
import React from 'react';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';

//Styles
import { StyledGroup } from 'styles/formViews/formViews.styles';

const FormActivity = (props) => {
  const {
    control,
    errors,
    handleSubmit,
    cancelActivity,
    saveActivity,
    canEditForm,
  } = props;

  const { useComponents } = useViews();
  const { useInputs, useButtons } = useComponents();
  const { InputHoursMinutes, InputForm } = useInputs();
  const { ButtonComponent } = useButtons();

  return (
    <div className="my-6">
      <form className="bg-neutral-200 px-10 border pt-6">
        <StyledGroup col2 className="mb-4" noSpace>
          <InputForm
            name="name"
            label="Nombre de la actividad*"
            placeholder="Ingresa el nombre de la actividad"
            type="text"
            className="w-full"
            control={control}
            errors={errors}
            disabled={!canEditForm}
          />
          <InputHoursMinutes
            name="duration"
            className="w-full"
            label="Duración*"
            control={control}
            errors={errors}
            disabled={!canEditForm}
          />
        </StyledGroup>
        {canEditForm && (
          <div className="flex items-center justify-end space-x-4">
            <ButtonComponent
              mode="outline"
              text="Cancelar"
              onPress={cancelActivity}
            />
            <ButtonComponent
              text="Guardar actividad"
              onPress={handleSubmit(saveActivity)}
            />
          </div>
        )}
      </form>
    </div>
  );
};

FormActivity.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  cancelActivity: PropTypes.func.isRequired,
  saveActivity: PropTypes.func.isRequired,
  canEditForm: PropTypes.bool,
};

export default FormActivity;
