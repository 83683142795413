//Packages
import { useEffect, useMemo, useState } from 'react';

//Hooks
import useModels from 'models';
import useControllers from 'controllers';

const useArtistDefineInterventionRoot = () => {
  const [viewDefine, setViewDefine] = useState();

  const { useSelectors } = useModels();
  const { useAdminSelectors, useSelector } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionsSelector } = useAdminInterventionsSelectors();
  const { define_intervention } = useSelector(getInterventionsSelector);

  const { useGeneralHooks } = useControllers();
  const { useGetDate } = useGeneralHooks();
  const { setTextExpiredDate } = useGetDate();

  useEffect(() => {
    define_intervention.key_factors &&
    define_intervention.key_factors.length > 0
      ? setViewDefine(2)
      : setViewDefine(1);
  }, [define_intervention.key_factors]);

  const textForLimitDate = useMemo(() => {
    if (define_intervention?.date_limit) {
      const { isExpired, text } = setTextExpiredDate({
        expiredDate: define_intervention?.date_limit,
      });

      return {
        text,
        colorBadge: isExpired ? 'error' : 'warning',
      };
    }

    return {
      text: 'No hay fecha limite',
      colorBadge: 'warning',
    };
  }, [define_intervention.date_limit]);

  return { textForLimitDate, define_intervention, viewDefine, setViewDefine };
};

export default useArtistDefineInterventionRoot;
