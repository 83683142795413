//Hooks
import useControllers from 'controllers';
import useStrings from 'strings';
import useApi from 'api';

const useAdminDevelopmentInterventionDefineInterventionActions = () => {
  const { useProviders } = useApi()
  const { useAdminProviders } = useProviders();
  const { useAdminProjectsProviders } = useAdminProviders();
  const { useAdminInterventionsProviders } = useAdminProjectsProviders();
  const { useAdminViewInterventionProviders } =
    useAdminInterventionsProviders();
  const { useAdminViewInterventionDevelopmentInterventionProviders } =
    useAdminViewInterventionProviders();
  const { useAdminDevelopmentInterventionDefineInterventionProviders } =
    useAdminViewInterventionDevelopmentInterventionProviders();
  const {
    defineInterventionProvider,
    updateDefineInterventionProvider,
    updateDefineInterventionArtistProvider,
    addInterventionDefinitionProvider,
    deleteInterventionByIdProvider,
    createActivityInInterventionProvider,
    deleteActivityInInterventionProvider,
    editActivityInInterventionProvider,
    createLogisticMeanIntoInterventionProvider,
    deleteLogisticMeanIntoInterventionProvider,
    editLogisticMeanIntoInterventionProvider,
    createAssignedTasksForInterventionProvider,
    deleteDiffusionActivitiesForInterventionProvider,
    editDiffusionActivitiesForInterventionProvider,
    downloadDifusionFileInterventionProvider,
  } = useAdminDevelopmentInterventionDefineInterventionProviders();

  const { useAdminTypes } = useStrings();
  const { useAdminProjectsTypes } = useAdminTypes();
  const { useAdminInterventionsTypes } = useAdminProjectsTypes();
  const { useAdminViewInterventionsTypes } = useAdminInterventionsTypes();
  const { useAdminViewInterventionsDevelopmentInterventionTypes } =
    useAdminViewInterventionsTypes();
  const { useAdminDevelopmentInterventionDefineInterventionTypes } =
    useAdminViewInterventionsDevelopmentInterventionTypes();
  const {
    GET_DEFINE_PROJECT_INTERVENTION,
    UPDATE_DEFINE_PROJECT_INTERVENTION,
    UPDATE_DEFINE_PROJECT_INTERVENTION_ARTIST,
    ADD_INTERVENTION_DEFINITION_ARTIST,
    DELETE_INTERVENTION_DEFINITION_ARTIST,
    CREATE_ACTIVITY_FOR_INTERVENTION_ARTIST,
    DELETE_ACTIVITY_FOR_INTERVENTION_ARTIST,
    EDIT_ACTIVITY_FOR_INTERVENTION_ARTIST,
    ADD_LOGISTIC_MEAN_INTO_INTERVENTION_ARTIST,
    DELETE_LOGISTIC_MEAN_INTO_INTERVENTION_ARTIST,
    EDIT_LOGISTIC_MEAN_INTO_INTERVENTION_ARTIST,
    SET_DATA_DIFFUSION_FORM_INTO_INTERVENTION_ARTIST,
    RESET_DATA_DIFFUSION_FORM_INTO_INTERVENTION_ARTIST,
    CREATE_ASSIGNED_TASKS_FOR_INTERVENTION_ARTIST,
    DELETE_ASSIGNED_TASKS_FOR_INTERVENTION_ARTIST,
    EDIT_ASSIGNED_TASKS_FOR_INTERVENTION_ARTIST,
    DOWNLOAD_DIFUSION_FILE_INTERVENTION,
  } = useAdminDevelopmentInterventionDefineInterventionTypes();

  const { useGeneralHooks } = useControllers();
  const { useConsole } = useGeneralHooks();
  const { viewConsole } = useConsole();

  const actGetDefineProjectIntervention =
    ({ intervention_id }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await defineInterventionProvider({ intervention_id });
        dispatch({
          type: GET_DEFINE_PROJECT_INTERVENTION,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (e) {
        onError && onError(e);
      }
    };

  const actUpdateDefineProjectIntervention =
    ({ intervention_id, phase_id, data }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await updateDefineInterventionProvider({
          intervention_id,
          phase_id,
          data,
        });
        dispatch({
          type: UPDATE_DEFINE_PROJECT_INTERVENTION,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (e) {
        onError && onError(e);
      }
    };

  const actUpdateDefineProjectInterventionArtist =
    (
      { intervention_id, phase_id, key_factors, formData },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const response = await updateDefineInterventionArtistProvider({
          intervention_id,
          phase_id,
          key_factors,
          formData,
        });
        dispatch({
          type: UPDATE_DEFINE_PROJECT_INTERVENTION_ARTIST,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (e) {
        onError && onError(e);
      }
    };

  const actAddInterventionDefinitionArtist =
    (
      { intervention_id, definition_id, formData, definitionIdToEdit },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const response = await addInterventionDefinitionProvider({
          intervention_id,
          definition_id,
          formData,
          definitionIdToEdit,
        });
        dispatch({
          type: ADD_INTERVENTION_DEFINITION_ARTIST,
          payload: { dataToAdd: response.data.data, definitionIdToEdit },
        });
        onSuccess && onSuccess(response);
      } catch (e) {
        onError && onError(e);
      }
    };

  const actDeleteInterventionById =
    (
      { intervention_id, definition_id, definitionIdToDelete },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const response = await deleteInterventionByIdProvider({
          intervention_id,
          definition_id,
          definitionIdToDelete,
        });
        dispatch({
          type: DELETE_INTERVENTION_DEFINITION_ARTIST,
          payload: { definitionIdToDelete },
        });
        onSuccess && onSuccess(response);
      } catch (e) {
        onError && onError(e);
      }
    };

  const actCreateActivityForIntervention =
    ({ definition, definition_intervention, formData }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await createActivityInInterventionProvider({
          definition,
          definition_intervention,
          formData,
        });
        dispatch({
          type: CREATE_ACTIVITY_FOR_INTERVENTION_ARTIST,
          payload: {
            activityCreated: response.data.data,
            idDefinitionIntervention: definition_intervention,
          },
        });
        onSuccess && onSuccess(response);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actDeleteActivityForIntervention =
    ({ definition, definition_intervention, activityId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await deleteActivityInInterventionProvider({
          definition,
          definition_intervention,
          activityId,
        });
        dispatch({
          type: DELETE_ACTIVITY_FOR_INTERVENTION_ARTIST,
          payload: {
            activityIdForDelete: activityId,
            idDefinitionIntervention: definition_intervention,
          },
        });
        onSuccess && onSuccess(response);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actEditActivityForIntervention =
    (
      { definition, definition_intervention, formData, activityId },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const response = await editActivityInInterventionProvider({
          definition,
          definition_intervention,
          formData,
          activityId,
        });
        dispatch({
          type: EDIT_ACTIVITY_FOR_INTERVENTION_ARTIST,
          payload: {
            activityIdForEdit: activityId,
            idDefinitionIntervention: definition_intervention,
            activityEdited: response.data.data,
          },
        });
        onSuccess && onSuccess(response);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actCreateLogisticMeanIntoIntervention =
    (
      { definition, definition_intervention, describe, type },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const response = await createLogisticMeanIntoInterventionProvider({
          definition,
          definition_intervention,
          describe,
          type,
        });

        dispatch({
          type: ADD_LOGISTIC_MEAN_INTO_INTERVENTION_ARTIST,
          payload: {
            idDefinitionIntervention: definition_intervention,
            logisticMeanCreated: response.data.data,
          },
        });
        onSuccess && onSuccess(response);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actDeleteLogisticMeanIntoIntervention =
    (
      { definition, definition_intervention, logisticMeanId },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const response = await deleteLogisticMeanIntoInterventionProvider({
          definition,
          definition_intervention,
          logisticMeanId,
        });
        dispatch({
          type: DELETE_LOGISTIC_MEAN_INTO_INTERVENTION_ARTIST,
          payload: {
            logisticMeanIdForDelete: logisticMeanId,
            idDefinitionIntervention: definition_intervention,
          },
        });
        onSuccess && onSuccess(response);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actEditLogisticMeanIntoIntervention =
    (
      { definition, definition_intervention, describe, type, logisticMeanId },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const response = await editLogisticMeanIntoInterventionProvider({
          definition,
          definition_intervention,
          describe,
          type,
          logisticMeanId,
        });
        dispatch({
          type: EDIT_LOGISTIC_MEAN_INTO_INTERVENTION_ARTIST,
          payload: {
            logisticMeanIdForEdit: logisticMeanId,
            idDefinitionIntervention: definition_intervention,
            logisticMeanEdited: response.data.data,
          },
        });
        onSuccess && onSuccess(response);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actSetDataDiffusionForm =
    ({
      convocation_method,
      convocation_description,
      convocation_file,
      diffusion_dynamic,
      diffusion_description,
      diffusion_file,
    }) =>
    (dispatch) => {
      dispatch({
        type: SET_DATA_DIFFUSION_FORM_INTO_INTERVENTION_ARTIST,
        payload: {
          convocation_method,
          convocation_description,
          convocation_file,
          diffusion_dynamic,
          diffusion_description,
          diffusion_file,
        },
      });
    };

  const actResetDataDiffusionForm = () => (dispatch) => {
    dispatch({
      type: RESET_DATA_DIFFUSION_FORM_INTO_INTERVENTION_ARTIST,
    });
  };

  const actCreateAssignedTasksForIntervention =
    ({ interventionId, definitionId, name, description }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await createAssignedTasksForInterventionProvider({
          interventionId,
          definitionId,
          name,
          description,
        });
        dispatch({
          type: CREATE_ASSIGNED_TASKS_FOR_INTERVENTION_ARTIST,
          payload: response.data.data,
        });
        onSuccess && onSuccess(response);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actDeleteAssignedTasksForIntervention =
    ({ interventionId, definitionId, taskId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await deleteDiffusionActivitiesForInterventionProvider(
          {
            interventionId,
            definitionId,
            taskId,
          }
        );
        dispatch({
          type: DELETE_ASSIGNED_TASKS_FOR_INTERVENTION_ARTIST,
          payload: { taskId },
        });
        onSuccess && onSuccess(response);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actEditAssignedTasksForIntervention =
    (
      { interventionId, definitionId, taskId, name, description },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const response = await editDiffusionActivitiesForInterventionProvider({
          interventionId,
          definitionId,
          taskId,
          name,
          description,
        });
        dispatch({
          type: EDIT_ASSIGNED_TASKS_FOR_INTERVENTION_ARTIST,
          payload: { taskId, dataTaskEdited: response.data.data },
        });
        onSuccess && onSuccess(response);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actDownloadDifusionFileForIntervention =
    ({ intervention, definition, file }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await downloadDifusionFileInterventionProvider({
          intervention,
          definition,
          file,
        });
        dispatch({
          type: DOWNLOAD_DIFUSION_FILE_INTERVENTION,
        });
        onSuccess && onSuccess(response);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  return {
    actGetDefineProjectIntervention,
    actUpdateDefineProjectIntervention,
    actUpdateDefineProjectInterventionArtist,
    actAddInterventionDefinitionArtist,
    actDeleteInterventionById,
    actCreateActivityForIntervention,
    actDeleteActivityForIntervention,
    actEditActivityForIntervention,
    actCreateLogisticMeanIntoIntervention,
    actDeleteLogisticMeanIntoIntervention,
    actEditLogisticMeanIntoIntervention,
    actSetDataDiffusionForm,
    actResetDataDiffusionForm,
    actCreateAssignedTasksForIntervention,
    actDeleteAssignedTasksForIntervention,
    actEditAssignedTasksForIntervention,
    actDownloadDifusionFileForIntervention,
  };
};
export default useAdminDevelopmentInterventionDefineInterventionActions;
