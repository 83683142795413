//Packages
import _ from 'lodash';
import { useState, useMemo, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

//Hooks
import useControllers from 'controllers';
import useApi from 'api';
import useModels from 'models';
import useStrings from 'strings';
import useHelpers from 'helpers';

const useAdminHomeContent = () => {
  const [rawContentRichText, setRawContentRichText] = useState(null);
  const [showModal, setShowModal] = useState({
    active: false,
    mode: '',
    maxLength: 0,
  });
  const [indexArray, setIndexArray] = useState(null);
  const [showModalImageCropperAux, setShowModalImageCropperAux] =
    useState(false);
  const [imageSelectedFromImageCropped, setImageSelectedFromImageCropped] =
    useState('');
  const [errorSliders, setErrorSliders] = useState({
    status: false,
    message: '',
  });
  const [sectionBelieveHasErrors, setSectionBelieveHasErrors] = useState(false);

  const dataProfileImageSelected = useRef({});
  const selectDefaultValues = useRef({
    artista: 'artist',
    'socio ejecutor': 'executing_partner',
    título: 'bt_title',
    subtítulo: 'bt_subtitle',
    párrafo: 'bt_text',
  });

  const data3Secction = useRef([
    {
      title: 'Título',
      section: 'título',
      maxLength: 60,
    },
    {
      title: 'Subtítulo',
      section: 'subtítulo',
      maxLength: 60,
    },
    {
      title: 'Párrafo',
      section: 'párrafo',
      maxLength: 500,
    },
    {
      title: 'Socio ejecutor',
      section: 'socio ejecutor',
    },
    {
      title: 'Artista',
      section: 'artista',
    },
  ]);

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { FILE_SIZE, FILE_TYPE, MAX_LENGTH_255 } = useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const { getFileValidations } = useQuickFunctions();
  const { MAX_SIZE, SUPPORTED_FORMATS_ONLY_IMAGES } = getFileValidations();

  const schemaArtistPartner = yup.object({
    name: yup.string().max(255, MAX_LENGTH_255).nullable(),
    image: yup
      .mixed()
      .nullable()
      .test('fileType', FILE_TYPE, (value) => {
        if (value) {
          return SUPPORTED_FORMATS_ONLY_IMAGES.includes(value.type);
        }
        return true;
      })
      .test('fileSize', FILE_SIZE, (value) => {
        if (value) {
          return value.size <= MAX_SIZE;
        }
        return true;
      }),
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schemaArtistPartner),
    mode: 'onChange',
  });

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useHomeActions } = useAdminActions();
  const { actUpdateHomeConfig } = useHomeActions();

  const { useGeneralHooks } = useControllers();
  const { useToggleModal, useUploadProfilePhoto, useModalUploadProfilePhoto } =
    useGeneralHooks();
  const [showModalDelete, setShowModalDelete] = useToggleModal();

  const handleProfilePhotoSelected = ({ file, croppedImageBlob }) => {
    setShowModalImageCropperAux(false);
    setImageSelectedFromImageCropped(croppedImageBlob);
    setValue('image', file);
    setZoom(1);
    setRotation(0);
    setCrop({ x: 0, y: 0 });
  };

  const {
    setZoom,
    setCrop,
    setRotation,
    onCropComplete,
    crop,
    rotation,
    zoom,
    imageForCropper,
    handleUpdateAvatarCropped,
    showModal: showModalImageCropper,
    handleCloseCropperModal,
  } = useUploadProfilePhoto({ onSuccess: handleProfilePhotoSelected });

  const { handleOpenModal: handleShowCropperModal } =
    useModalUploadProfilePhoto();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminHomeSelectors } = useAdminSelectors();
  const { getHomeContentSelector } = useAdminHomeSelectors();
  const dataHome = useSelector(getHomeContentSelector);

  const showUploadProfilePhoto =
    selectDefaultValues.current[showModal.mode] === 'artist' ||
    selectDefaultValues.current[showModal.mode] === 'executing_partner';

  const slidersAux = useMemo(
    () => _.map(dataHome.sliders, (slider) => slider.text),
    [dataHome]
  );

  const defaultValueSlider = useMemo(() => {
    if (indexArray !== null) {
      const sliderLikeString = slidersAux[indexArray];
      const sliderLikeObject = JSON.parse(sliderLikeString);

      delete sliderLikeObject.plainText;
      delete sliderLikeObject.htmlContent;

      return sliderLikeObject;
    } else {
      return {};
    }
  }, [indexArray, showModal.mode]);

  const defaultValues = useMemo(() => {
    const propertyInDataHome = selectDefaultValues.current[showModal.mode];

    if (
      propertyInDataHome &&
      (propertyInDataHome === 'artist' ||
        propertyInDataHome === 'executing_partner')
    ) {
      return dataHome[propertyInDataHome];
    } else if (propertyInDataHome && dataHome[propertyInDataHome]) {
      const dataLikeObject = JSON.parse(dataHome[propertyInDataHome]);
      delete dataLikeObject.plainText;
      delete dataLikeObject.htmlContent;

      return dataLikeObject;
    }
    return {};
  }, [showModal.mode, dataHome]);

  useEffect(() => {
    setTimeout(() => {
      if (showModalImageCropperAux && !errors?.image?.message) {
        const { blobImage, name, type } = dataProfileImageSelected.current;
        handleShowCropperModal({ blobImage, name, type: type });
      }
    }, 500);
  }, [showModalImageCropperAux, errors]);

  const handleCreateSlide = () => {
    dispatch(
      actUpdateHomeConfig(
        { slider: [...slidersAux, JSON.stringify(rawContentRichText)] },
        () => {
          handleCancelClose();
        }
      )
    );
  };

  const handleEditSlide = () => {
    const slidersEdited = _.map(slidersAux, (slider, index) => {
      if (index === indexArray) {
        return JSON.stringify(rawContentRichText);
      } else {
        return slider;
      }
    });

    dispatch(
      actUpdateHomeConfig({ slider: slidersEdited }, () => {
        handleCancelClose();
      })
    );
  };

  const handleDeleteSlide = () => {
    const slidersEdited = _.filter(slidersAux, (slider, index) => {
      return index !== indexArray && slider;
    });

    dispatch(
      actUpdateHomeConfig({ slider: slidersEdited }, () => {
        setShowModalDelete(false);
      })
    );
  };

  const setPlainTextForPreview = ({ sliderLikeString }) => {
    const sliderLikeObject = JSON.parse(sliderLikeString);

    return sliderLikeObject.plainText;
  };

  const handleSetDataFromRichText = (data) => setRawContentRichText(data);

  const handleCancelClose = (e) => {
    e && e.preventDefault();
    setShowModal((prevValue) => ({
      ...prevValue,
      active: false,
      mode: showModal.mode,
    }));
    setIndexArray(null);
    setImageSelectedFromImageCropped('');
    reset({});
  };

  const handleUpdateDescription = (e) => {
    e.preventDefault();
    const selectPtroperty = selectDefaultValues.current[showModal.mode];
    dispatch(
      actUpdateHomeConfig(
        {
          [selectPtroperty]: JSON.stringify(rawContentRichText),
        },
        () => {
          handleCancelClose();
        }
      )
    );
  };

  const handleUpdateArtistPartner = (data) => {
    const { name, image } = data;
    const property = selectDefaultValues.current[showModal.mode];

    dispatch(
      actUpdateHomeConfig(
        {
          [property === 'artist' ? 'artist_name' : 'executing_partner_name']:
            name,
          [property === 'artist' ? 'artist_image' : 'executing_partner_image']:
            image,
        },
        () => {
          setShowModal((prevValue) => ({
            ...prevValue,
            active: false,
            mode: showModal.mode,
          }));
          setImageSelectedFromImageCropped('');
          reset();
        }
      )
    );
  };

  const handleUpdateAvatar = (e) => {
    const file = e.target.files[0];
    const blobImage = URL.createObjectURL(file);
    dataProfileImageSelected.current = {
      blobImage,
      name: file.name,
      type: file.type,
    };

    if (SUPPORTED_FORMATS_ONLY_IMAGES.includes(file.type)) {
      setShowModalImageCropperAux(true);
    }
  };

  const showPreviewElementInHome = ({ section, slideToShow = '' }) => {
    slideToShow.toString()
      ? window.open(`/?scroll=${section}&slideToShow=${slideToShow}`)
      : window.open(`/?scroll=${section}`);
  };

  return {
    //Modals
    showModalDelete,
    setShowModalDelete,
    showModal,
    setShowModal,
    handleCancelClose,

    //General
    dataHome,
    handleSetDataFromRichText,

    //Section1
    setPlainTextForPreview,
    handleCreateSlide,
    handleEditSlide,
    handleDeleteSlide,
    defaultValueSlider,
    setIndexArray,
    errorSliders,
    setErrorSliders,

    //Section3
    selectDefaultValues,
    defaultValues,
    handleUpdateDescription,
    handleUpdateArtistPartner,
    data3Secction,
    showUploadProfilePhoto,
    imageSelectedFromImageCropped,
    sectionBelieveHasErrors,
    setSectionBelieveHasErrors,

    //Form
    control,
    handleSubmit,
    errors,
    register,

    //Upload photo
    handleUpdateAvatar,

    //ImageCropper
    setZoom,
    setCrop,
    setRotation,
    onCropComplete,
    crop,
    rotation,
    zoom,
    imageForCropper,
    handleUpdateAvatarCropped,
    showModalImageCropper,
    handleCloseCropperModal,
    setShowModalImageCropperAux,
    showPreviewElementInHome,
  };
};

export default useAdminHomeContent;
