//Hooks
import useApi from '../../../..';
import useControllers from '../../../../../controllers';
import useStrings from '../../../../../strings';

const useAdminFestivitiesAndTraditionsActions = () => {
  const { useAdminTypes } = useStrings();
  const { useAdminProjectsTypes } = useAdminTypes();
  const { useAdminFestivitiesAndTraditionsTypes } = useAdminProjectsTypes();
  const {
    GET_FESTIVITIES_AND_TRADITIONS,
    CREATE_FESTIVITIES_AND_TRADITIONS,
    DELETE_FESTIVITIES_AND_TRADITIONS,
    UPDATE_FESTIVITIES_AND_TRADITIONS,
  } = useAdminFestivitiesAndTraditionsTypes();

  const { useProviders } = useApi();
  const { useAdminProviders } = useProviders();
  const { useAdminProjectsProviders } = useAdminProviders();
  const { useAdminFestivitiesAndTraditionsProviders } =
    useAdminProjectsProviders();
  const {
    getFestivityAndTraditions,
    createFestivityAndTraditions,
    deleteFestivityAndTraditions,
    updateFestivityAndTraditions,
  } = useAdminFestivitiesAndTraditionsProviders();

  const { useGeneralHooks } = useControllers();
  const { useConsole } = useGeneralHooks();
  const { viewConsole } = useConsole();

  const actGetFestivityAndTraditions =
    ({ projectId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await getFestivityAndTraditions({
          projectId,
        });
        dispatch({
          type: GET_FESTIVITIES_AND_TRADITIONS,
          payload: res.data?.data?.festivities,
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actCreateCommunityFestivity =
    (
      { name, description, category, main, first, second, projectId },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const bodyFormData = new FormData();
        bodyFormData.append('name', name);
        bodyFormData.append('description', description);
        bodyFormData.append('category', category.value);
        main && bodyFormData.append('multimedias[main]', main);
        first && bodyFormData.append('multimedias[first]', first);
        second && bodyFormData.append('multimedias[second]', second);

        const res = await createFestivityAndTraditions({
          formData: bodyFormData,
          projectId,
        });
        dispatch({
          type: CREATE_FESTIVITIES_AND_TRADITIONS,
          payload: res.data?.data,
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actUpdateCommunityFestivity =
    (
      {
        projectId,
        festivityId,
        name,
        description,
        category,
        main,
        first,
        second,
      },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const bodyFormData = new FormData();
        bodyFormData.append('name', name);
        bodyFormData.append('description', description);
        bodyFormData.append('category', category.value);
        (main || main === '') && bodyFormData.append('multimedias[main]', main);
        (first || first === '') &&
          bodyFormData.append('multimedias[first]', first);
        (second || second === '') &&
          bodyFormData.append('multimedias[second]', second);
        bodyFormData.append('_method', 'put');

        const res = await updateFestivityAndTraditions({
          formData: bodyFormData,
          projectId,
          festivityId,
        });
        dispatch({
          type: UPDATE_FESTIVITIES_AND_TRADITIONS,
          payload: { festivityId, data: res.data?.data },
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actDeleteCommunityFestivity =
    ({ festivityId, projectId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await deleteFestivityAndTraditions({
          projectId,
          festivityId,
        });
        dispatch({
          type: DELETE_FESTIVITIES_AND_TRADITIONS,
          payload: { festivityId },
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };
  return {
    actGetFestivityAndTraditions,
    actCreateCommunityFestivity,
    actUpdateCommunityFestivity,
    actDeleteCommunityFestivity,
  };
};

export default useAdminFestivitiesAndTraditionsActions;
