//Packages
import _ from 'lodash';
import React, { lazy } from 'react';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
const StyledSectionActives = lazy(() =>
  import('../../Dashboard.styles').then((mod) => ({
    default: mod.StyledSectionActives,
  }))
);

const TabMyInterventions = (props) => {
  const { tabValue, textSearchValue } = props;
  const { useComponents } = useViews();
  const { useCards, useSliders, usePagers, useTypographies } = useComponents();
  const { TypographyComponent } = useTypographies();

  const { CardIntervention, CardNotification } = useCards();
  const { SlickSlider } = useSliders();
  const { Pager } = usePagers();

  const { useScreenHooks, useGeneralHooks } = useControllers();
  const { useGetScreenSize } = useGeneralHooks();
  const { sizeScreen } = useGetScreenSize();
  const { useDashboardControllers } = useScreenHooks();
  const { useDashboardTabInterventions } = useDashboardControllers();
  const {
    interventionsList,
    imageFakeCard,
    handleGoToDetailIntervention,
    showCityDepartmentAndCountry,
    metaArtist,
    linksArtist,
    handleChangePage,
    currentPage,
    currentPageSearch,
  } = useDashboardTabInterventions({ tabValue, textSearchValue });

  return (
    <>
      {interventionsList.length > 0 ? (
        <div>
          <StyledSectionActives>
            <SlickSlider
              init={sizeScreen.x < 1024}
              customSettings={{ arrows: true, dots: false }}
            >
              {_.map(interventionsList, (item) => (
                <CardIntervention
                  key={`project-se-${item.id}`}
                  className="w-full cursor-pointer "
                  image={item.image?.url || imageFakeCard}
                  title={item.name}
                  location={showCityDepartmentAndCountry({
                    city: item.project.city,
                  })}
                  projectName={`Proyecto: ${item.project.name}`}
                  typeList={[
                    { name: item.objetive, type: 'primary' },
                    { name: item.type.name, type: 'secondary' },
                  ]}
                  onClick={() => {
                    handleGoToDetailIntervention({
                      projectId: item.project.id,
                      interventionId: item.id,
                    });
                  }}
                />
              ))}
            </SlickSlider>
          </StyledSectionActives>
          <Pager
            pageCount={metaArtist.last_page || 1}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            nextLabel={Boolean(linksArtist.next)}
            previousLabel={Boolean(linksArtist.prev)}
            className="flex items-center lg:justify-end justify-center my-6"
            onPageChange={handleChangePage}
            forcePage={
              textSearchValue ? currentPageSearch - 1 : currentPage - 1
            }
          />
        </div>
      ) : (
        <CardNotification
          className="w-5/12 mx-auto py-9 px-4 mt-16"
          title={
            <TypographyComponent
              text="Intervenciones en proceso"
              variant="h2"
              className="font-bold text-primary icon-watch text-center"
            />
          }
        />
      )}
    </>
  );
};

TabMyInterventions.propTypes = {
  tabValue: PropTypes.number.isRequired,
  textSearchValue: PropTypes.string,
};
TabMyInterventions.defaultProps = {
  textSearchValue: '',
};

export default TabMyInterventions;
