import React, { lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
//Hooks
import useViews from '../../../../../../../../../../../../../index';

import IconAlarm from '../../../../../../../../../../../../../../assets/img/alarm.svg';
import useScreenHooks from 'controllers/screenHooks';

const StyledGroup = lazy(() =>
  import(
    '../../../../../../../../../../../../../../styles/formViews/formViews.styles'
  ).then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const FormActivity = (props) => {
  const { activity } = props;
  const {
    name,
    date,
    community_support,
    duration,
    community_place,
    platform,
    description,
    resources,
    modality_type,
  } = activity;

  const { useComponents } = useViews();
  const { useInputs } = useComponents();
  const { InputForm, TextArea, Select } = useInputs();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useDeepenKnowledgeCommunity } =
    useAdminInterventionsDashboardControllers();
  const { useAdminDeepenKnowledgeActivityCommunityInterventions } =
    useDeepenKnowledgeCommunity();
  const {
    formActivity: {
      control,
      register,
      formState: { errors },
      setValue,
    },
    communitiesOption,
    setDefaultValueInputDateTimeWithTimeLocalZone,
  } = useAdminDeepenKnowledgeActivityCommunityInterventions({
    activities: [],
    setActivities: () => {},
  });

  useEffect(() => {
    date &&
      setValue(
        'date',
        setDefaultValueInputDateTimeWithTimeLocalZone({
          dateInUTC: date,
        }).split('.')[0]
      );
    setValue('modality_type', {
      label: modality_type?.name ?? '',
      value: modality_type?.id ?? '',
    });
  }, [date]);

  return (
    <>
      <StyledGroup>
        {modality_type && (
          <Select
            name="modality_type"
            id="modality_type"
            placeholder="Tipo de profundización*"
            control={control}
            options={[
              {
                label: 'Presencial',
                value: 'f',
              },
              {
                label: 'Remota',
                value: 'r',
              },
            ]}
            onChange={() => {}}
            errors={errors}
            label="Modalidad de la actividad*"
            disabled
          />
        )}
      </StyledGroup>
      <StyledGroup className="mb-4" noSpace>
        <InputForm
          name="name"
          label="Nombre de la actividad*"
          type="text"
          placeholder="Ingresa el nombre de la actividad"
          className="w-full"
          defaultValue={name}
          errors={errors}
          disabled
          {...register('name')}
        />
        <InputForm
          name="date"
          label="Fecha y Hora*"
          type="datetime-local"
          placeholder="DD/MM/AAAA"
          className="w-full"
          errors={errors}
          {...register('date')}
          disabled
        />
      </StyledGroup>

      <StyledGroup className="mb-4" noSpace>
        <InputForm
          name="duration"
          label="Duración*"
          type="text"
          icon={IconAlarm}
          placeholder="Selecciona la duración"
          className="w-full"
          defaultValue={duration}
          errors={errors}
          disabled
          {...register('duration')}
        />
      </StyledGroup>

      <StyledGroup col2 flex className="mb-4 flex-col">
        {community_place && (
          <Select
            name="community_place_id"
            id="community_place_id"
            placeholder="Comunidad donde se realizará la actividad"
            control={control}
            defaultValue={{
              label: community_place.name,
              value: community_place.id,
            }}
            options={communitiesOption}
            disabled
            label="Comunidad donde se realizará la actividad"
          />
        )}
        {platform && (
          <InputForm
            type={'text'}
            name="platform"
            id="platform"
            placeholder="Ej. Google meet "
            control={control}
            defaultValue={platform}
            label="Plataforma*"
            disabled
          />
        )}
      </StyledGroup>

      <StyledGroup className="mb-4" noSpace>
        <TextArea
          control={control}
          name="activity"
          placeholder="Describe la actividad..."
          className="w-full "
          label="Descripción de la actividad* "
          errors={errors}
          hasController
          defaultValue={description}
          disabled
        />
        <TextArea
          control={control}
          name="resources"
          placeholder="Define los recursos requeridos..."
          className="w-full "
          label="Recursos requeridos*"
          errors={errors}
          defaultValue={resources}
          disabled
        />
      </StyledGroup>
      <StyledGroup col2 className="mb-4">
        <TextArea
          control={control}
          name="acompanamiento"
          placeholder="Describe el apoyo que requieres..."
          className="w-full col-span-2"
          label="¿Necesito del apoyo de una o más personas de la comunidad?"
          errors={errors}
          defaultValue={community_support}
          disabled
        />
      </StyledGroup>
    </>
  );
};

FormActivity.propTypes = {
  activity: PropTypes.object.isRequired,
  handleRejectedActivity: PropTypes.func.isRequired,
};

export default FormActivity;
