import React from 'react';
import PropTypes from 'prop-types';

//Component
import GeneralRoute from '../General';

//Hooks
import useControllers from '../../../controllers';

const ProtectedRoute = (props) => {
  const { component, redirect, message, role, ...rest } = props;

  const { useRoutesHooks } = useControllers();
  const { useProtectedRoute } = useRoutesHooks();
  const { props: properties, permission } = useProtectedRoute(role);

  return (
    <GeneralRoute
      component={component}
      redirect={redirect}
      message={message}
      properties={properties}
      permission={permission}
      {...rest}
    />
  );
};

ProtectedRoute.defaultProps = {
  redirect: null,
  message: null,
  role: 'ad',
};
ProtectedRoute.propTypes = {
  redirect: PropTypes.string,
  message: PropTypes.element,
  component: PropTypes.elementType.isRequired,
  role: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};
export default ProtectedRoute;
