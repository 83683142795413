import { useSelector } from 'react-redux';
import useGeneralSelectors from './general';
import useAuthSelectors from './auth';
import useUserSelectors from './user';
import useAdminSelectors from './admin';
import useDashboardSelectors from './dashboard';

const useSelectors = () => {
  return {
    useSelector,
    useGeneralSelectors,
    useAuthSelectors,
    useUserSelectors,
    useAdminSelectors,
    useDashboardSelectors,
  };
};

export default useSelectors;
