//Packages
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useAdminDevelopmentInterventionKnowProjectProviders = () => {
  const knowProjectStateInterventionProvider = ({ intervention_id }) => {
    const response = axios({
      method: 'GET',
      url: `/interventions/${intervention_id}/know-project/`,
    });
    return trackPromise(response);
  };
  const createKnowProjectInterventionProvider = ({
    intervention_id,
    know_project,
    data,
  }) => {
    const response = axios({
      method: 'PATCH',
      url: `/interventions/${intervention_id}/know-project/${know_project}`,
      data,
    });
    return trackPromise(response);
  };
  const knowProjectInterventionProvider = ({ intervention_id }) => {
    const response = axios({
      method: 'GET',
      url: `/interventions/${intervention_id}/project/`,
    });
    return trackPromise(response);
  };

  return {
    knowProjectStateInterventionProvider,
    knowProjectInterventionProvider,
    createKnowProjectInterventionProvider,
  };
};

export default useAdminDevelopmentInterventionKnowProjectProviders;
