//Packages
import React, { lazy } from 'react';
import { useHistory } from 'react-router';

//Assets
import imagePin from '../../../../../assets/icons/map-pin-white.png';
import imageFakeCard from '../../../../../assets/img/fake-card-example.jpg';

//Hooks
import useViews from '../../../..';
import useControllers from '../../../../../controllers';
import useModels from '../../../../../models';
import useHelpers from '../../../../../helpers';

const StyledInformationCollapse = lazy(() =>
  import('../componentsProfile.styles').then((mod) => ({
    default: mod.StyledInformationCollapse,
  }))
);

const StyledContentGroup = lazy(() =>
  import('../componentsProfile.styles').then((mod) => ({
    default: mod.StyledContentGroup,
  }))
);

const StyledCollapsedInfo = lazy(() =>
  import('../componentsProfile.styles').then((mod) => ({
    default: mod.StyledCollapsedInfo,
  }))
);
const StyledCollapsed = lazy(() =>
  import('../componentsProfile.styles').then((mod) => ({
    default: mod.StyledCollapsed,
  }))
);

const Partner = () => {
  const { push } = useHistory();

  const { useComponents } = useViews();
  const { useImages, useTypographies, useBadges, useButtons } = useComponents();

  const { Image } = useImages();
  const { TypographyComponent } = useTypographies();
  const { DefaultBadge } = useBadges();
  const { ButtonComponent } = useButtons();

  const { useGeneralHooks } = useControllers();
  const { useGetUserProfile, useCollapseAccordion } = useGeneralHooks();
  const {
    avatar,
    name,
    last_name,
    role: { name: roleName },
    city,
    email,
    phone,
    gender,
    language,
    organization,
    position,
    about_me_body,
    website,
  } = useGetUserProfile();
  const { refCollapsed, collapsed, clienteHei, handleOpenCollapse } =
    useCollapseAccordion();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminProjectsRootSelectors } = useAdminProjectsSelectors();
  const { projectsListSelector } = useAdminProjectsRootSelectors();
  const { numberProjects } = useSelector(projectsListSelector);

  const { useQuickFunctions } = useHelpers();
  const { showCityDepartmentAndCountry } = useQuickFunctions();

  return (
    <>
      <Image
        variant="rounded"
        srcImage={avatar ?? imageFakeCard}
        altImage={'Alt'}
        titleImage={'Title'}
        className="w-22 h-22 lg:-mt-20 -mt-8 mx-auto relative"
      />
      <TypographyComponent
        className="text-white font-bold flex items-center justify-center lg:justify-start mt-4"
        text={
          <>
            {name} {last_name}
            <DefaultBadge
              className="text-success-950 ml-2"
              variant="success"
              label={numberProjects}
              colorLevel="300"
            />
          </>
        }
        variant="h3"
      />
      <TypographyComponent
        className="text-white flex items-center justify-center lg:justify-start my-1 "
        text={roleName}
        variant="p"
      />
      <TypographyComponent
        className="text-white flex items-center justify-center lg:justify-start mt-2 lg:mt-0 font-bold"
        variant="h5"
        text={
          <>
            <Image
              srcImage={imagePin}
              altImage="location"
              titleImage="location"
              className="location-icon"
            />
            {showCityDepartmentAndCountry({ city, format: 'countryCity' })}
          </>
        }
      />

      <StyledCollapsedInfo isCollapsed={collapsed} onClick={handleOpenCollapse}>
        Ver perfil completo
      </StyledCollapsedInfo>

      <StyledCollapsed isCollapsed={collapsed} clientHei={clienteHei}>
        <StyledInformationCollapse ref={refCollapsed}>
          <StyledContentGroup>
            <TypographyComponent
              className="text-primary-100 flex items-center mb-1"
              text={'Email'}
              variant="h5"
            />
            <TypographyComponent
              className="text-white flex items-center"
              text={email}
              variant="p"
            />
          </StyledContentGroup>

          <StyledContentGroup>
            <TypographyComponent
              className="text-primary-100 flex items-center mb-1"
              text={'Celular'}
              variant="h5"
            />
            <TypographyComponent
              className="text-white flex items-center"
              text={`${phone.code.code} ${phone.number}`}
              variant="p"
            />
          </StyledContentGroup>

          {gender && (
            <StyledContentGroup>
              <TypographyComponent
                className="text-primary-100 flex items-center mb-1"
                text={'Género'}
                variant="h5"
              />
              <TypographyComponent
                className="text-white flex items-center"
                text={gender}
                variant="p"
              />
            </StyledContentGroup>
          )}

          <StyledContentGroup>
            <TypographyComponent
              className="text-primary-100 flex items-center mb-1"
              text={'Idioma'}
              variant="h5"
            />
            <TypographyComponent
              className="text-white flex items-center"
              text={language}
              variant="p"
            />
          </StyledContentGroup>

          <StyledContentGroup>
            <TypographyComponent
              className="text-primary-100 flex items-center mb-1"
              text={'Organización'}
              variant="h5"
            />
            <TypographyComponent
              className="text-white flex items-center"
              text={
                <>
                  {organization}
                  <a
                    className="ml-2 underline text-info-400"
                    href={website}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Ir al sitio web
                  </a>
                </>
              }
              variant="p"
            />
          </StyledContentGroup>

          <StyledContentGroup>
            <TypographyComponent
              className="text-primary-100 flex items-center mb-1"
              text={'Cargo'}
              variant="h5"
            />
            <TypographyComponent
              className="text-white flex items-center"
              text={position}
              variant="p"
            />
          </StyledContentGroup>

          <StyledContentGroup>
            <TypographyComponent
              className="text-primary-100 flex items-center mb-1"
              text={'Mi trabajo'}
              variant="h5"
            />
            <TypographyComponent
              className="text-white flex items-center"
              text={about_me_body}
              variant="p"
            />
          </StyledContentGroup>
        </StyledInformationCollapse>
      </StyledCollapsed>

      <ButtonComponent
        mode="outline"
        className="mx-auto mt-10 mb-4"
        variant="white"
        text="Editar Perfil"
        onPress={() => push('/dashboard/profile')}
      />
    </>
  );
};

export default Partner;
