//Packages
import React from 'react';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
import {
  StyledDropDownFilter,
  StyledWrapperFilter,
  StyledWarpperButtonFilters,
} from 'styles/filters/filters.styles';

const DropDownFilter = (props) => {
  const { className, open, isOnFocusTextSearch, handleOpenMenu, community_id } =
    props;

  const { useComponents } = useViews();
  const { useInputs, useButtons } = useComponents();
  const { Select } = useInputs();
  const { ButtonComponent } = useButtons();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useUserAdmin } = useAdmin();
  const { useFilterUser } = useUserAdmin();
  const {
    control,
    handleResetFilter,
    handleSubmit,
    applyFilter,
    LIST_OF_OPTIONS_HAS_WHATSAPP,
    OPTIONS_ROLES_WITHOUT_LOGIN,
  } = useFilterUser({
    activeDropDown: open,
    isOnFocusTextSearch,
    handleOpenMenu,
    contact: 'contacts',
    community_id,
  });

  return (
    <StyledDropDownFilter className={className} open={open}>
      <StyledWrapperFilter>
        <Select
          name="roles"
          id="role"
          placeholder="Seleccionar"
          control={control}
          options={OPTIONS_ROLES_WITHOUT_LOGIN}
          label="Rol"
        />
        <Select
          name="has_whatsapp"
          id="has_whatsapp"
          placeholder="Seleccionar"
          control={control}
          options={LIST_OF_OPTIONS_HAS_WHATSAPP}
          label="WhastApp"
        />
      </StyledWrapperFilter>

      <StyledWarpperButtonFilters>
        <ButtonComponent
          variant="primary"
          mode="outline"
          className="mt-4 border-none shadow-none"
          text="Limpiar filtros"
          onPress={handleResetFilter}
        />
        <ButtonComponent
          variant="primary"
          className="mt-4"
          text="Aplicar filtros"
          onPress={handleSubmit(applyFilter)}
        />
      </StyledWarpperButtonFilters>
    </StyledDropDownFilter>
  );
};

DropDownFilter.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  isOnFocusTextSearch: PropTypes.bool,
  handleOpenMenu: PropTypes.func,
  community_id: PropTypes.string.isRequired,
};

DropDownFilter.defaultProps = {
  className: '',
  open: false,
  isOnFocusTextSearch: false,
  handleOpenMenu: () => {},
};

export default DropDownFilter;
