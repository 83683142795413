import PilotEvaluate from './PilotEvaluate';
import PilotIntervention from './PilotIntervention';
import PilotProgram from './PilotProgram';
import Sketchinterventions from './SketchIntervention';

const useStepsSketchInterventionAdmin = () => {
  return {
    Sketchinterventions,
    PilotProgram,
    PilotIntervention,
    PilotEvaluate,
  };
};

export default useStepsSketchInterventionAdmin;
