import React, { Suspense, lazy, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

//Hooks
import useViews from 'views';
import useApi from 'api';
import useControllers from 'controllers';

//Components
import Contacts from './components/Contacts';
import ContactList from './components/ContactList';

//Styles
const StyledBackButton = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);

const StyledBodyTab = lazy(() =>
  import('styles/tabs/tabs.styles').then((mod) => ({
    default: mod.StyledBodyTab,
  }))
);

const AdminDashboardCommunitiesContactsRoot = () => {
  const { useComponents } = useViews();
  const { useTabs, useLoaders } = useComponents();
  const { DefaultLoader } = useLoaders();
  const history = useHistory();
  const { project_id, community_id } = useParams();
  const { useGeneralHooks } = useControllers();
  const { useSelectLayoutByRole } = useGeneralHooks();
  const { LayoutSelected, props } = useSelectLayoutByRole();

  const { Tabs, TabLink } = useTabs();

  const [tabValue, setTabValue] = useState(0);

  const handleSelectTab = (tabIdx) => setTabValue(tabIdx);

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminUsersActions } = useAdminActions();
  const { actResetAllFilterDataUser } = useAdminUsersActions();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <LayoutSelected {...props}>
        <>
          <StyledBackButton
            className="mt-3 mb-8"
            onClick={() => {
              history.push(
                `/admin/projects/dashboard/${project_id}/communities/${community_id}`
              );
            }}
          >
            Regresar
          </StyledBackButton>

          <Tabs
            selected={tabValue}
            onSelect={(idx) => {
              dispatch(actResetAllFilterDataUser());
              handleSelectTab(idx);
            }}
            className="w-full lg:w-9/12 mb-5 lg:mb-0 whitespace-nowrap overflow-auto lg:whitespace-normal"
          >
            <TabLink label="Contactos de la comunidad" tab={0} />
            <TabLink label="Listas de contactos" tab={1} />
          </Tabs>

          {tabValue === 0 ? (
            <StyledBodyTab active={tabValue === 0}>
              <Contacts tabValue={0} />
            </StyledBodyTab>
          ) : (
            <StyledBodyTab active={tabValue === 1}>
              <ContactList tabValue={1} />
            </StyledBodyTab>
          )}
        </>
      </LayoutSelected>
    </Suspense>
  );
};

export default AdminDashboardCommunitiesContactsRoot;
