import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';

// Hooks
import useApi from 'api';
import useModels from 'models';
import useStrings from 'strings';
import useHelpers from 'helpers';
import useControllers from 'controllers';

const useArtistExecuteMaterializeIntervention = () => {
  const { intervention_id: interventionId } = useParams();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();
  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const { useAdminDevelopmentExecuteInterventionActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const {
    actGetMaterializeIntervention,
    actUpdateMaterializeIntervention,
    actDownloadFileMaterializeIntervention,
    actUploadFileMaterializeIntervention,
    actDeleteFileMaterializeIntervention
  } = useAdminDevelopmentExecuteInterventionActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionsSelector } = useAdminInterventionsSelectors();
  const { executeMaterialize } = useSelector(getInterventionsSelector);
  const { id: materializeId } = executeMaterialize;

  const { useMessagesTypes, useConstants } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { REQUIRED_FIELD, FILE_SIZE_5MB } = useFormsTypes();
  const { CONVOCATION_METHODS } = useConstants();

  const { useQuickFunctions } = useHelpers();
  const {
    downloadFileFromBackend,
    getDocumentName,
    getFileValidations
  } = useQuickFunctions();
  const { MAX_SIZE_5MB } = getFileValidations();

  const { useGeneralHooks } = useControllers();
  const { useGetDate } = useGeneralHooks();
  const { setTextExpiredDate } = useGetDate();

  const [openModalDeny, setOpenModalDeny] = useState(false);
  const [openModalApprove, setOpenModalApprove] = useState(false);

  const schemaExecuteMaterializeIntervention = yup.object({
    file: (executeMaterialize.files?.length > 0)
      ? yup.mixed()
        .test('fileSize', FILE_SIZE_5MB, (value) => {
          if (value) {
            return value.size <= MAX_SIZE_5MB;
          }
          return true;
        })
        .notRequired()
      : yup.mixed()
      .required(REQUIRED_FIELD)
      .test('fileSize', FILE_SIZE_5MB, (value) => {
        if (value) {
          return value.size <= MAX_SIZE_5MB;
        }
        return true;
      }),
  });

  const formExecuteMaterialize = useForm({
    resolver: yupResolver(schemaExecuteMaterializeIntervention),
    mode: 'onChange',
  });

  const textForLimitDate = useMemo(() => {
    if (executeMaterialize.date_limit) {
      const { isExpired, text } = setTextExpiredDate({
        expiredDate: executeMaterialize?.date_limit,
        format: 'dmyText'
      });
      return {
        text,
        colorBadge: isExpired ? 'error' : 'warning',
      };
    }
    return {
      text: 'No hay fecha limite',
      colorBadge: 'warning',
    };
  }, [executeMaterialize.date_limit]);

  useEffect(() => {
    if (executeMaterialize.state?.id !== 'c') {
      dispatch(actGetMaterializeIntervention({
        interventionId,
      }));
    }
  }, [])

  useEffect(() => {
    const file = formExecuteMaterialize.watch('file')
    if (file?.size <= MAX_SIZE_5MB) {
      uploadFile()
    }
  }, [formExecuteMaterialize.watch('file')]);

  const openFile = ({ e , file, watchFile }) => {
    e && e.preventDefault();
    if (watchFile) {
      const blobUrl = URL.createObjectURL(watchFile);
      window.open(blobUrl);
    } else {
      window.open(file.url);
    }
  }

  const downloadFile = ({ e , file }) => {
    e && e.preventDefault();
    dispatch(
      actDownloadFileMaterializeIntervention(
        {
          interventionId,
          materializeId,
          fileId: file.id,
        },
        (response) => {
          downloadFileFromBackend({ response, nameFile: getDocumentName({ file, withExtension: false }) });
        }
      )
    );
  }

  const uploadFile = () => {
    const data = new FormData();
    data.append('file', formExecuteMaterialize.watch('file'));
    dispatch(
      actUploadFileMaterializeIntervention({
        interventionId,
        materializeId,
        data
      })
    );
  }

  const deleteFile = ({ e, file }) => {
    e && e.preventDefault();
    dispatch(actDeleteFileMaterializeIntervention({
      interventionId,
      materializeId,
      fileId: file.id,
    }));
  }

  const updateEvaluatePilotIntervention = () => {
    setOpenModalApprove(false)
    dispatch(actUpdateMaterializeIntervention({
      interventionId,
      materializeId,
      data: { state: 'iv' }
    }))
  }

  return {
    CONVOCATION_METHODS,
    executeMaterialize,
    textForLimitDate,
    formExecuteMaterialize,
    openModalDeny,
    openModalApprove,
    setOpenModalDeny,
    setOpenModalApprove,
    canEditForm: !_.includes(['c', 'p', 'iv'], executeMaterialize.state.id),
    openFile,
    downloadFile,
    deleteFile,
    updateEvaluatePilotIntervention
  }
}

export default useArtistExecuteMaterializeIntervention