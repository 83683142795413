const useTabSelectedTypes = () => {
  const SET_TAB_SELECTED = 'SET_TAB_SELECTED';
  const RESET_TAB_SELECTED = 'RESET_TAB_SELECTED';
  const SET_SUB_TAB_SELECTED = 'SET_SUB_TAB_SELECTED';
  const RESET_SUB_TAB_SELECTED = 'RESET_SUB_TAB_SELECTED';

  return {
    SET_TAB_SELECTED,
    RESET_TAB_SELECTED,
    SET_SUB_TAB_SELECTED,
    RESET_SUB_TAB_SELECTED,
  };
};

export default useTabSelectedTypes;
