import useAdminProjectsInitialStates from './projects';
import useAdminUsersInitialStates from './users';
import useAdminLabelsInitialStates from './labels';
import useContacListStates from './contactList';
import useAdminHomeInitialStates from './home';
import useAdminContentInterventionsInitialStates from './interventions';

const useAdminInitialStates = () => {
  return {
    useAdminUsersInitialStates,
    useAdminProjectsInitialStates,
    useContacListStates,
    useAdminLabelsInitialStates,
    useAdminHomeInitialStates,
    useAdminContentInterventionsInitialStates,
  };
};

export default useAdminInitialStates;
