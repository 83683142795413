//Packages
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const usePreRegisterProviders = () => {
  const preRegisterProvider = ({ user }) => {
    const response = axios({
      method: 'POST',
      url: '/postulations',
      data: user,
    });
    return trackPromise(response);
  };
  return { preRegisterProvider };
};

export default usePreRegisterProviders;
