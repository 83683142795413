import useProviders from '../../../providers';
import useStrings from 'strings';
import useControllers from 'controllers';

const useContactListActions = () => {
  const { useContactListProviders } = useProviders();
  const {
    getContactListsProvider,
    getContactListProvider,
    deleteContactListProvider,
    storeContactListProvider,
    updateContactListProvider,
    addContactsToContactListProvider,
    removeContactToContactListProvider,
  } = useContactListProviders();

  const { useAdminTypes } = useStrings();
  const { useContactListTypes } = useAdminTypes();
  const {
    GET_CONTACT_LISTS,
    GET_CONTACT_LIST,
    DELETE_CONTACT_LIST,
    STORE_CONTACT_LIST,
    UPDATE_CONTACT_LIST,
    REMOVE_CONTACT_TO_CONTACT_LIST,
    ADD_CONTACT_TO_CONTACT_LIST,
    RESET_DATA_CONTACT_LIST_SELECTED,
  } = useContactListTypes();

  const { useGeneralHooks } = useControllers();
  const { useConsole } = useGeneralHooks();
  const { viewConsole } = useConsole();
  const actGetContactLists =
    (
      { page, paginate = 1, name, orderByKey, orderByValue },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const response = await getContactListsProvider({
          page,
          paginate,
          name,
          orderByKey,
          orderByValue,
        });
        dispatch({
          type: GET_CONTACT_LISTS,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actGetContactList = (id, onSuccess, onError) => async (dispatch) => {
    try {
      const response = await getContactListProvider(id);
      dispatch({
        type: GET_CONTACT_LIST,
        payload: response.data,
      });
      onSuccess && onSuccess(response.data);
    } catch (e) {
      viewConsole([e], 'error');
      onError && onError(e);
    }
  };

  const actStoreContactList =
    (data, onSuccess, onError) => async (dispatch) => {
      try {
        const response = await storeContactListProvider(data);
        dispatch({
          type: STORE_CONTACT_LIST,
          payload: response.data,
        });
        onSuccess && onSuccess();
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actUpdateContactList =
    (data, onSuccess, onError) => async (dispatch) => {
      try {
        const response = await updateContactListProvider(data);
        dispatch({
          type: UPDATE_CONTACT_LIST,
          payload: response.data.data,
        });
        onSuccess && onSuccess();
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actDeleteContactList = (id, onSuccess, onError) => async (dispatch) => {
    try {
      await deleteContactListProvider(id);
      dispatch({
        type: DELETE_CONTACT_LIST,
        payload: id,
      });
      onSuccess && onSuccess();
    } catch (e) {
      viewConsole([e], 'error');
      onError && onError(e);
    }
  };

  const actRemoveContactToContactList =
    ({ id, users }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        await removeContactToContactListProvider({ id, users });
        dispatch({
          type: REMOVE_CONTACT_TO_CONTACT_LIST,
          payload: users,
        });
        onSuccess && onSuccess();
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actAddContactToContactList =
    ({ id, users }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await addContactsToContactListProvider({ id, users });
        dispatch({
          type: ADD_CONTACT_TO_CONTACT_LIST,
          payload: response.data,
        });
        onSuccess && onSuccess();
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actResetDataContactListSelected = () => async (dispatch) => {
    dispatch({
      type: RESET_DATA_CONTACT_LIST_SELECTED,
    });
  };

  return {
    actGetContactLists,
    actGetContactList,
    actStoreContactList,
    actUpdateContactList,
    actDeleteContactList,
    actRemoveContactToContactList,
    actAddContactToContactList,
    actResetDataContactListSelected,
  };
};
export default useContactListActions;
