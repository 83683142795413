//Packages
import _ from 'lodash';
import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

// Hooks
import useViews from 'views';
import useControllers from 'controllers';
import { StyledContentButtonsModal } from 'styles/tableViews/tableViews.styles';

//Styles
const StyledForm = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledForm,
  }))
);

const StyledGroup = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledContentButtons = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const Create = (props) => {
  const { setActiveItem, optionsCommunities, optionsContactLists } = props;

  const { useComponents } = useViews();
  const { useTypographies, useInputs, useButtons, useModals } = useComponents();
  const { DefaultModal } = useModals();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { Select, CheckBox, InputForm, TextArea } = useInputs();
  const { ButtonComponent } = useButtons();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsCommunication } =
    useAdminViewInterventionsControllers();
  const { useAdminViewInterventionsCollectionInformationControllers } =
    useAdminViewInterventionsCommunication();
  const { useAdminViewInterventionsCreateCollectionInformation } =
    useAdminViewInterventionsCollectionInformationControllers();
  const {
    LIST_OF_OPTIONS_TO_DESTINATIONS,
    LIST_OF_TYPE_OF_COLLECTION,
    LIST_OF_TYPE_OF_INFORMATION_COLLECTION,
    LIST_OF_CHANNELS_FOR_SEND_MESSAGES,
    fromInterventionMessage,
    confirmationModal,
    handleVisibilityConfirmationModal,
    activeAtLeastItemDraft,
    setActiveAtLeastItemDraft,
    toSendMessage,
    handleCancelSendMessage,
    sendMessage,
    handleSendMessageDraft,
    formCreateCollectionInformation,
    handleAddOptions,
    numberOptions,
    setValidateOptionsRules,
    handleDeleteOption,
    handleOnChangeOptions,
  } = useAdminViewInterventionsCreateCollectionInformation({ setActiveItem });

  const {
    control,
    formState: { errors },
    register,
    watch,
    getValues,
    handleSubmit,
  } = formCreateCollectionInformation;

  return (
    <>
      <TypographyComponent
        text="Recolección de información"
        variant="h2"
        className="font-bold text-primary mt-4"
      />

      <TypographyComponent
        text="Las respuestas a este mensaje te permitán entender mejor algunos aspectos de la comunidad del proyecto. Te invitamos a ser muy específico con las preguntas para que recibas buena información."
        variant="p"
        className="font-book text-neutral-950 mb-10"
      />

      <StyledForm className="pb-20">
        <StyledGroup noSpace>
          <div>
            <TypographyWithTooltip
              text="Tipo de recolección"
              variant="p"
              textTooltip="Tipo de recolección"
              idTooltip="communication_Type"
              className="text-neutral-950"
              applyFontBold
            />

            <Select
              name="gi_type" //typeRecol
              id="canal"
              placeholder="Selecciona el tipo de recolección"
              className="mt-2"
              options={LIST_OF_TYPE_OF_COLLECTION}
              label=""
              control={control}
              errors={errors}
              disabled={fromInterventionMessage}
            />
          </div>
          {watch('gi_type')?.value === 'a' && (
            <div>
              <TypographyWithTooltip
                text="Tipo de información a recolectar*"
                variant="p"
                textTooltip="Tipo de información a recolectar*"
                idTooltip="type_information"
                className="text-neutral-950"
                applyFontBold
              />

              <Select
                name="type_information" //typeInfo
                id="type_information"
                placeholder="Selecciona el tipo de información"
                className="mt-2"
                options={LIST_OF_TYPE_OF_INFORMATION_COLLECTION}
                label=""
                control={control}
                errors={errors}
                disabled={fromInterventionMessage}
              />
            </div>
          )}
        </StyledGroup>

        <StyledGroup noSpace>
          <div>
            <TypographyWithTooltip
              text="Canal de comunicación*"
              variant="p"
              textTooltip="Canal de comunicación*"
              idTooltip="communication_channel"
              className="text-neutral-950"
              applyFontBold
            />

            <Select
              name="channel"
              id="canal"
              placeholder="Selecciona el Canal de comunicación"
              className="mt-2"
              options={
                watch('gi_type')?.value === 'a'
                  ? LIST_OF_CHANNELS_FOR_SEND_MESSAGES
                  : [{ label: 'WhatsApp', value: 'ws' }]
              }
              label=""
              control={control}
              errors={errors}
              disabled={fromInterventionMessage}
            />
          </div>

          <div>
            <TypographyWithTooltip
              text="Tipo de destino*"
              variant="p"
              textTooltip="Tipo de destino*"
              idTooltip="type_destin"
              className="text-neutral-950"
              applyFontBold
            />

            <Select
              name="typeDest"
              id="typeDest"
              placeholder="Selecciona el tipo de destino"
              className="mt-2"
              options={LIST_OF_OPTIONS_TO_DESTINATIONS}
              label=""
              control={control}
              errors={errors}
            />
          </div>
        </StyledGroup>

        <StyledGroup noSpace>
          <Select
            name="communities"
            id="commun"
            placeholder="Selecciona el destino del mensaje"
            className="w-full mt-2 col-span-2"
            options={
              watch('typeDest')?.value === 'comunidad'
                ? optionsCommunities
                : optionsContactLists
            }
            label="Destino"
            control={control}
            errors={errors}
            disabled={!watch('typeDest')?.value}
            multiple
          />
        </StyledGroup>

        {!fromInterventionMessage && (
          <StyledGroup>
            <Controller
              control={control}
              name="date_checkbox"
              render={({ field: { name, onChange } }) => {
                return (
                  <CheckBox
                    name={name}
                    label="Establecer una fecha para envío de mensaje"
                    className="mt-6"
                    onChange={onChange}
                    errors={errors}
                  />
                );
              }}
            />
          </StyledGroup>
        )}

        {watch('date_checkbox') && (
          <StyledGroup flex className="flex-col pt-4">
            <TypographyWithTooltip
              text="Fecha de envío"
              variant="p"
              textTooltip="Fecha de envío"
              idTooltip="date"
              className="text-neutral-950"
              applyFontBold
            />
            <InputForm
              name="send_date"
              label=""
              type="datetime-local"
              className="w-4/12 mt-2"
              placeholder="Selecciona la fecha de inicio"
              {...register('send_date')}
              errors={errors}
            />
          </StyledGroup>
        )}
        {watch('gi_type')?.value === 'a' ? (
          <StyledGroup flex className="flex-col pt-4">
            <TypographyWithTooltip
              text="Pregunta"
              variant="h3"
              textTooltip="Pregunta"
              idTooltip="question"
              className="text-primary"
              applyFontBold
            />
            <InputForm
              name="message" //body
              label=""
              type="text"
              placeholder="Ingresa la pregunta"
              className="w-full col-span-2"
              errors={errors}
              control={control}
              disabled={fromInterventionMessage}
            />
          </StyledGroup>
        ) : (
          <StyledGroup>
            <TypographyComponent
              text="Pregunta o mensaje"
              variant="h3"
              className="font-bold text-primary mt-4"
            />

            <TextArea
              control={control}
              name="message"
              placeholder="Mensaje"
              className="w-full col-span-2"
              label=""
              errors={errors}
              disabled={fromInterventionMessage}
            />
          </StyledGroup>
        )}

        {watch('gi_type')?.value === 'a' &&
          watch('type_information')?.value === 'qn' && (
            <div className="flex flex-col justify-between space-x-6">
              {fromInterventionMessage && (
                <>
                  {_.map(toSendMessage.options, (o, idx) => (
                    <>
                      <TypographyComponent
                        variant="p"
                        text={`Opción ${idx + 1}`}
                        className="text-primary font-bold flex-none"
                      />
                      <InputForm
                        name={`answer${idx + 1}`}
                        label=""
                        type="text"
                        placeholder={`Ingresa la opción ${idx + 1}`}
                        defaultValue={o}
                        className="flex-1"
                        errors={errors}
                        disabled
                      />
                    </>
                  ))}
                </>
              )}
              {!fromInterventionMessage && (
                <div className="flex items-end justify-between space-x-6">
                  <div className="flex-1">
                    {_.map(numberOptions, (option, index) => {
                      return (
                        <div
                          className="flex items-center space-x-6"
                          key={option.id}
                        >
                          <TypographyComponent
                            variant="p"
                            text={`Opción ${index + 1}`}
                            className="text-primary font-bold flex-none"
                          />
                          <InputForm
                            name={`options[${index}]`}
                            label=""
                            type="text"
                            placeholder={`Ingresa la opción ${index + 1}`}
                            className="flex-1"
                            errors={errors}
                            control={control}
                            value={option.value}
                            onChange={(event) => {
                              handleOnChangeOptions({
                                optionIndex: option.id,
                                optionValue: event.target.value,
                              });
                              setValidateOptionsRules(
                                (prevValue) => !prevValue
                              );
                            }}
                          />
                          <StyledContentButtons>
                            <ButtonComponent
                              text=""
                              className="bg-transparent text-tertiary"
                              onPress={(event) => {
                                handleDeleteOption({
                                  event,
                                  optionId: index,
                                });
                              }}
                              icon={<div className="icon-delete" />}
                            />
                          </StyledContentButtons>
                        </div>
                      );
                    })}
                  </div>
                  <ButtonComponent
                    variant="primary"
                    mode="outline"
                    text="Agregar otra opción"
                    className="mb-2"
                    icon={<div className="icon-close transform rotate-45" />}
                    disabled={numberOptions.length === 10}
                    onPress={handleAddOptions}
                  />
                </div>
              )}
            </div>
          )}

        {errors['options'] && (
          <p className="text-sm mt-2 text-error">{errors['options'].message}</p>
        )}

        <div className="flex justify-between pt-12">
          <ButtonComponent
            text="Cancelar"
            className="border-none shadow-none"
            onPress={handleCancelSendMessage}
            mode="outline"
            variant="primary"
          />
          <div className="flex items-center space-x-2">
            {!fromInterventionMessage && (
              <ButtonComponent
                text="Guardar como borrador"
                mode="outline"
                onPress={(e) => {
                  e.preventDefault();
                  handleSendMessageDraft({ data: getValues(), setActiveItem });
                }}
              />
            )}
            <ButtonComponent
              text="Iniciar recolección"
              icon={<div className="icon-send text-lg  h-6 flex" />}
              onPress={handleSubmit((data) => {
                sendMessage({ data });
              })}
            />
          </div>
        </div>
      </StyledForm>

      <DefaultModal
        isActive={confirmationModal}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={handleVisibilityConfirmationModal}
      >
        <TypographyComponent
          text={'¿Iniciar la recolección de información?'}
          variant="h3"
          className="font-bold text-primary mb-1"
        />

        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto lg:mx-2"
            mode="outline"
            onPress={handleVisibilityConfirmationModal}
          />
          <ButtonComponent
            variant="primary"
            text="Aceptar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={handleSubmit((data) => {
              sendMessage({ data, fromModalConfirmation: true });
            })}
          />
        </StyledContentButtonsModal>
      </DefaultModal>
      <DefaultModal
        isActive={activeAtLeastItemDraft}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={setActiveAtLeastItemDraft}
      >
        <TypographyComponent
          text="Debe existir al menos un campo diligenciado"
          variant="h3"
          className="font-bold text-primary mb-1 text-center"
        />
        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Aceptar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={setActiveAtLeastItemDraft}
          />
        </StyledContentButtonsModal>
      </DefaultModal>
    </>
  );
};

Create.propTypes = {
  setActiveItem: PropTypes.func,
  optionsCommunities: PropTypes.array,
  optionsContactLists: PropTypes.array,
};

Create.defaultProps = {
  setActiveItem: () => {},
  optionsCommunities: [],
  optionsContactLists: [],
};

export default Create;
