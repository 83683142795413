import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';

// Hooks
import useStrings from 'strings';

const useAdminDeepenKnowledgeMessagesCommunityInterventions = ({
  typeForm,
  deepenType,
  messages,
  setMessages,
}) => {
  const [validateDiffusion, setValidateDiffusion] = useState(
    typeForm === 'r' && deepenType === 'async'
  );
  const [showModal, setShowModal] = useState({
    show: false,
    e: {},
    index: 0,
    message: '',
  });

  useEffect(() => {
    setValidateDiffusion(typeForm === 'r' && deepenType === 'async');
  }, [typeForm, deepenType]);

  useEffect(() => {
    setMessages([]);
  }, [typeForm]);

  const { useMessagesTypes, useConstants } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const {
    SEND_MESSAGE_CHANNEL_OPTIONS,
    LIST_OF_TYPE_OF_INFORMATION_COLLECTION,
  } = useConstants();

  const { REQUIRED_FIELD, MAX_LENGTH_150, MAX_LENGTH_100 } = useFormsTypes();

  const initialStateAnswerOptions = [{ id: Date.now(), value: '' }];
  const [answerOptions, setAnswerOptions] = useState(initialStateAnswerOptions);
  const [viewButton, setViewButton] = useState(true);
  const [editViewButton, setEditViewButton] = useState(true);
  const [validateOptionsRules, setValidateOptionsRules] = useState(false);
  const editingMessage = useRef(false);

  const schemaMessage = yup.object({
    channel: yup.object().required(REQUIRED_FIELD).nullable(),
    description: yup.string().max(100, MAX_LENGTH_100).required(REQUIRED_FIELD),
    body: yup.string().max(150, MAX_LENGTH_150).required(REQUIRED_FIELD),
    type_information: validateDiffusion
      ? yup.object().required(REQUIRED_FIELD)
      : yup.object(),
    options: yup.array().when('type_information', {
      is: (type_information) => type_information?.value === 'qn',
      then: yup
        .array()
        .required(REQUIRED_FIELD)
        .nullable()
        .min(2, 'Debe existir al menos dos opciones de respuesta')
        .test(
          'emptyValues',
          'Las respuestas no deben estar vacías',
          (optionsArray) => {
            if (optionsArray?.length) {
              const nonEmptyOptions = _.filter(optionsArray, (option) => {
                return Boolean(option);
              });
              return !(answerOptions.length > nonEmptyOptions.length);
            }
            return true;
          }
        ),
      otherwise: yup.array().notRequired(),
    }),
  });

  const formMessage = useForm({
    resolver: yupResolver(schemaMessage),
    mode: 'onChange',
  });

  const watchOptions = formMessage.watch('options');

  useEffect(() => {
    if (watchOptions) {
      const nonEmptyOptions = _.filter(watchOptions, (option) => {
        return Boolean(option);
      });
      answerOptions.length === nonEmptyOptions.length
        ? formMessage.clearErrors('options')
        : formMessage.setError('options', {
            type: 'manual',
            message: 'Las respuestas no deben estar vacías',
          });
    }
  }, [validateOptionsRules]);

  const handleAddMessage = () => {
    editingMessage.current = false;
    setViewButton(false);
    setMessages([
      ...messages,
      {
        expanded: true,
      },
    ]);
  };

  const saveMessage = (data, index) => {
    const msgData = { ...data };
    msgData.channel = data.channel.value;
    msgData.expanded = false;
    msgData.options = answerOptions;
    setMessages(
      _.map(messages, (message, idx) => {
        if (idx === index) {
          return msgData;
        } else {
          return message;
        }
      }),
      editingMessage.current ? 'update' : 'add'
    );
    setAnswerOptions(initialStateAnswerOptions);
    setViewButton(true);
    setEditViewButton(true);
    resetForm();
  };

  const deleteMessage = (index, isCancel = false) => {
    setMessages(
      _.filter(messages, (message, idx) => index !== idx),
      isCancel ? '' : 'delete'
    );
  };

  const editMessage = (index) => {
    editingMessage.current = true;
    setViewButton(false);
    setEditViewButton(false);
    const message = messages[index];
    setAnswerOptions(message.options);
    setMessages(
      _.map(messages, (msg, idx) => {
        if (idx === index) {
          return {
            ...message,
            expanded: true,
          };
        } else {
          return msg;
        }
      })
    );
    formMessage.setValue(
      'channel',
      SEND_MESSAGE_CHANNEL_OPTIONS.find((o) => o.value === message.channel)
    );
    formMessage.setValue('description', message.description);
    formMessage.setValue('body', message.body);
    formMessage.setValue(
      'type_information',
      message.type_information?.value
        ? message.type_information
        : LIST_OF_TYPE_OF_INFORMATION_COLLECTION.find(
            (t) => t.value === message.type_information
          )
    );
    formMessage.setValue('options', message.options);
  };

  const resetForm = () => {
    formMessage.reset({
      channel: undefined,
      description: '',
      body: '',
      options: [],
    });
  };

  const cancelForm = (index) => {
    editingMessage.current = false;
    if (editViewButton) {
      deleteMessage(index, true);
    } else {
      const message = messages[index];
      setMessages(
        _.map(messages, (msg, idx) => {
          if (idx === index) {
            return {
              ...message,
              expanded: false,
            };
          } else {
            return msg;
          }
        })
      );
    }
    setViewButton(true);
    setEditViewButton(true);
    resetForm();
  };

  const handleAddOptions = (e) => {
    e && e.preventDefault();
    setAnswerOptions((prevValue) => [
      ...prevValue,
      {
        id: Date.now(),
        value: '',
      },
    ]);
  };

  const handleOnChangeOptions = ({ optionValue, optionIndex }) => {
    const newArrayOptions = _.map(answerOptions, (option) => {
      if (option.id === optionIndex) {
        return { ...option, value: optionValue };
      }
      return option;
    });
    setAnswerOptions(newArrayOptions);
    setValidateOptionsRules((prevValue) => !prevValue);
    setValidateOptionsRules((prevValue) => !prevValue);
  };

  const deleteOption = ({ e, optionId }) => {
    e && e.preventDefault();
    const newArrayOptions = _.filter(answerOptions, (option, index) => {
      return index !== optionId;
    });
    setAnswerOptions(newArrayOptions);
    setValidateOptionsRules((prevValue) => !prevValue);
  };

  return {
    SEND_MESSAGE_CHANNEL_OPTIONS,
    LIST_OF_TYPE_OF_INFORMATION_COLLECTION,
    formMessage,
    saveMessage,
    deleteMessage,
    messages,
    handleAddMessage,
    answerOptions,
    viewButton,
    setViewButton,
    editMessage,
    editViewButton,
    cancelForm,
    validateDiffusion,
    showModal,
    setShowModal,
    handleAddOptions,
    handleOnChangeOptions,
    deleteOption,
    setValidateOptionsRules,
  };
};
export default useAdminDeepenKnowledgeMessagesCommunityInterventions;
