//Packages
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useAdminDevelopmentExecuteInterventionProviders = () => {
  const getMaterializeInterventionProvider = ({ interventionId }) => {
    const response = axios({
      method: 'GET',
      url: `/interventions/${interventionId}/execute-materialize`,
    });
    return trackPromise(response);
  };

  const updateMaterializeInterventionProvider = ({
    interventionId,
    materializeId,
    data,
  }) => {
    const response = axios({
      method: 'PATCH',
      url: `/interventions/${interventionId}/execute-materialize/${materializeId}`,
      data,
    });
    return trackPromise(response);
  };

  const uploadFileMaterializeInterventionProvider = ({
    interventionId,
    materializeId,
    data,
  }) => {
    const response = axios({
      method: 'POST',
      url: `/interventions/${interventionId}/execute-materialize/${materializeId}/file`,
      data
    });
    return trackPromise(response);
  };

  const downloadFileMaterializeInterventionProvider = ({
    interventionId,
    materializeId,
    fileId,
  }) => {
    const response = axios({
      method: 'GET',
      responseType: 'arraybuffer',
      url: `/interventions/${interventionId}/execute-materialize/${materializeId}/file/${fileId}`,
    });
    return trackPromise(response);
  };

  const deleteFileMaterializeInterventionProvider = ({
    interventionId,
    materializeId,
    fileId,
  }) => {
    const response = axios({
      method: 'DELETE',
      url: `/interventions/${interventionId}/execute-materialize/${materializeId}/file/${fileId}`,
    });
    return trackPromise(response);
  };

  const getExecuteInterventionProvider = ({ interventionId }) => {
    const response = axios({
      method: 'GET',
      url: `/interventions/${interventionId}/execute-intervention`,
    });
    return trackPromise(response);
  };

  const updateExecuteInterventionProvider = ({
    interventionId,
    executeId,
    data,
  }) => {
    const response = axios({
      method: 'PATCH',
      url: `/interventions/${interventionId}/execute-intervention/${executeId}`,
      data,
    });
    return trackPromise(response);
  };

  const createEventExecuteInterventionProvider = ({
    interventionId,
    executeId,
    data,
  }) => {
    const response = axios({
      method: 'POST',
      url: `/interventions/${interventionId}/execute-intervention/${executeId}/events`,
      data
    });
    return trackPromise(response);
  };

  const updateEventExecuteInterventionProvider = ({
    interventionId,
    executeId,
    eventId,
    data,
  }) => {
    const response = axios({
      method: 'PATCH',
      url: `/interventions/${interventionId}/execute-intervention/${executeId}/events/${eventId}`,
      data
    });
    return trackPromise(response);
  };

  const deleteEventExecuteInterventionProvider = ({
    interventionId,
    executeId,
    eventId,
  }) => {
    const response = axios({
      method: 'DELETE',
      url: `/interventions/${interventionId}/execute-intervention/${executeId}/events/${eventId}`,
    });
    return trackPromise(response);
  };

  const completeMilestoneEventExecuteInterventionProvider = ({
    executeId,
    eventId,
    milestoneId,
    data
  }) => {
    const response = axios({
      method: 'PATCH',
      url: `/execute-intervention/${executeId}/events/${eventId}/milestone/${milestoneId}`,
      data
    });
    return trackPromise(response);
  };

  const getEvaluateInterventionProvider = ({ interventionId }) => {
    const response = axios({
      method: 'GET',
      url: `/interventions/${interventionId}/execute-evaluate`,
    });
    return trackPromise(response);
  };

  const updateEvaluateInterventionProvider = ({
    interventionId,
    evaluateId,
    data,
  }) => {
    const response = axios({
      method: 'PATCH',
      url: `/interventions/${interventionId}/execute-evaluate/${evaluateId}`,
      data,
    });
    return trackPromise(response);
  };

  const uploadFileEvaluateInterventionProvider = ({
    interventionId,
    evaluateId,
    data,
  }) => {
    const response = axios({
      method: 'POST',
      url: `/interventions/${interventionId}/execute-evaluate/${evaluateId}/file`,
      data
    });
    return trackPromise(response);
  };

  const downloadFileEvaluateInterventionProvider = ({
    interventionId,
    evaluateId,
    fileId,
  }) => {
    const response = axios({
      method: 'GET',
      responseType: 'arraybuffer',
      url: `/interventions/${interventionId}/execute-evaluate/${evaluateId}/file/${fileId}`,
    });
    return trackPromise(response);
  };

  const deleteFileEvaluateInterventionProvider = ({
    interventionId,
    evaluateId,
    fileId,
  }) => {
    const response = axios({
      method: 'DELETE',
      url: `/interventions/${interventionId}/execute-evaluate/${evaluateId}/file/${fileId}`,
    });
    return trackPromise(response);
  };

  return {
    getMaterializeInterventionProvider,
    updateMaterializeInterventionProvider,
    uploadFileMaterializeInterventionProvider,
    downloadFileMaterializeInterventionProvider,
    deleteFileMaterializeInterventionProvider,
    getExecuteInterventionProvider,
    updateExecuteInterventionProvider,
    createEventExecuteInterventionProvider,
    updateEventExecuteInterventionProvider,
    deleteEventExecuteInterventionProvider,
    completeMilestoneEventExecuteInterventionProvider,
    getEvaluateInterventionProvider,
    updateEvaluateInterventionProvider,
    uploadFileEvaluateInterventionProvider,
    downloadFileEvaluateInterventionProvider,
    deleteFileEvaluateInterventionProvider,
  };
};

export default useAdminDevelopmentExecuteInterventionProviders;
