import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useAdminAbcTrajectoryProviders = () => {
  const getAbcTrajectory = ({ projectId }) => {
    const response = axios({
      method: 'GET',
      url: `/projects/${projectId}/trajectories`,
    });
    return trackPromise(response);
  };

  const createAbcTrajectory = ({ formData, projectId }) => {
    const response = axios({
      method: 'POST',
      headers: {
        'content-type': 'multipart/form-data',
      },
      url: `/projects/${projectId}/trajectories`,
      data: formData,
    });
    return trackPromise(response);
  };

  const updateAbcTrajectory = ({ formData, projectId, abcTrajectoryId }) => {
    const response = axios({
      method: 'POST',
      url: `/projects/${projectId}/trajectories/${abcTrajectoryId}`,
      data: formData,
    });
    return trackPromise(response);
  };

  const deleteAbcTrajectory = ({ projectId, abcTrajectoryId }) => {
    const response = axios({
      method: 'DELETE',
      url: `/projects/${projectId}/trajectories/${abcTrajectoryId}`,
    });
    return trackPromise(response);
  };

  return {
    getAbcTrajectory,
    createAbcTrajectory,
    updateAbcTrajectory,
    deleteAbcTrajectory,
  };
};

export default useAdminAbcTrajectoryProviders;
