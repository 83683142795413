//Packages
import _ from 'lodash';
import React, { lazy } from 'react';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
const StyledSectionActives = lazy(() =>
  import('../../Dashboard.styles').then((mod) => ({
    default: mod.StyledSectionActives,
  }))
);
// const StyledSectionHistory = lazy(() =>
//   import('../../Dashboard.styles').then((mod) => ({
//     default: mod.StyledSectionHistory,
//   }))
// );

const TabMyProjects = (props) => {
  const { textSearchValueAux } = props;

  const { useComponents } = useViews();
  const { useCards, useTypographies, /*useBadges*/ useSliders, usePagers } =
    useComponents();

  const { TypographyComponent } = useTypographies();
  const { CardIntervention, CardNotification /*CardHistory*/ } = useCards();
  //   const { DefaultBadge } = useBadges();
  const { SlickSlider } = useSliders();
  const { Pager } = usePagers();

  const { useScreenHooks, useGeneralHooks } = useControllers();
  const { useGetScreenSize } = useGeneralHooks();
  const { sizeScreen } = useGetScreenSize();
  const { useDashboardControllers } = useScreenHooks();
  const { useDashboard } = useDashboardControllers();
  const {
    projectsList,
    imageFakeCard,
    handleGoToDashboardById,
    handleChangePage,
    currentPage,
    currentPageSearch,
  } = useDashboard({ callEndpointProjects: true, textSearchValueAux });

  return (
    <>
      {/* <TypographyComponent
        className="text-primary font-bold flex items-center justify-start my-5"
        text={
          <>
            Activas
            <DefaultBadge
              className="text-success-950 ml-2"
              variant="success"
              label={`3`}
              colorLevel="300"
            />
          </>
        }
        variant="h2"
      /> */}

      {projectsList.data.length ? (
        <>
          <StyledSectionActives>
            <SlickSlider
              init={sizeScreen.x < 1024}
              customSettings={{ arrows: true, dots: false }}
            >
              {projectsList.data.length > 0 &&
                _.map(projectsList.data, (item) => (
                  <CardIntervention
                    key={`project-se-${item.id}`}
                    className="w-full cursor-pointer "
                    image={item.image || imageFakeCard}
                    title={item.name}
                    location={`${item.city.name} (${item.city.department.country.name})`}
                    // typeList={item.typeList}
                    //description={item.description}
                    onClick={() => {
                      handleGoToDashboardById({ projectId: item.id });
                    }}
                  />
                ))}
            </SlickSlider>
          </StyledSectionActives>
          <Pager
            pageCount={projectsList.meta.last_page}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            nextLabel={Boolean(projectsList.links.next)}
            previousLabel={Boolean(projectsList.links.prev)}
            className="flex items-center lg:justify-end justify-center my-6"
            onPageChange={handleChangePage}
            forcePage={
              textSearchValueAux ? currentPageSearch - 1 : currentPage - 1
            }
          />
        </>
      ) : (
        <CardNotification
          className="w-5/12 mx-auto py-9 px-4 mt-16"
          title={
            <TypographyComponent
              text="Proyectos en proceso"
              variant="h2"
              className="font-bold text-primary icon-watch text-center"
            />
          }
        />
      )}
      {/* <StyledSectionHistory>
        <TypographyComponent
          className="text-primary font-bold my-10"
          text="Historial de mis intervenciones"
          variant="h3"
        />

         {_.map(fakeDataCard, (item) => (
          <CardHistory
            className="w-full lg:w-7/12 lg:mb-14 pb-8 lg:pb-0 mb-8 border-b lg:border-0 "
            image={item.image}
            title={item.title}
            location={item.location}
            typeList={['Presencial']}
            description={item.description}
          />
        ))}
      </StyledSectionHistory> */}
    </>
  );
};

TabMyProjects.propTypes = {
  textSearchValueAux: PropTypes.string,
};
TabMyProjects.defaultProps = {
  textSearchValueAux: '',
};

export default TabMyProjects;
