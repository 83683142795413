import axios from 'axios';
import _ from 'lodash';
import { trackPromise } from 'react-promise-tracker';
import qs from 'qs';

const useUserProviders = () => {
  const getProfile = () => {
    const response = axios({
      method: 'GET',
      url: '/user/profile',
    });

    return trackPromise(response);
  };

  const updateProfile = (data) => {
    const response = axios({
      method: 'PATCH',
      url: '/user/profile',
      data,
    });
    return trackPromise(response);
  };

  const updateAvatar = (formData) => {
    const response = axios({
      method: 'POST',
      headers: {
        'content-type': 'multipart/form-data',
      },
      url: '/user/profile/avatar',
      data: formData,
    });
    return trackPromise(response);
  };
  const editUserProvider = ({ user }) => {
    const response = axios({
      method: 'PATCH',
      headers: {
        'alert-dialog': 'hidden',
      },
      url: `/users/${user.id}`,
      data: user,
    });
    return trackPromise(response);
  };
  const createUserProvider = ({ user }) => {
    const response = axios({
      method: 'POST',
      headers: {
        'alert-dialog': 'hidden',
      },
      url: `/users`,
      data: user,
    });
    return trackPromise(response);
  };
  const changePassword = (changePassword) => {
    const response = axios({
      method: 'PATCH',
      url: '/user/profile/change-password',
      data: changePassword,
    });
    return trackPromise(response);
  };

  const deleteUserProviders = (user) => {
    const response = axios({
      method: 'DELETE',
      url: `/users/${user}`,
    });
    return trackPromise(response);
  };

  const searchUserProviders = (
    {
      search,
      name,
      full_name,
      paginate = true,
      country_id,
      department_id,
      city_id,
      roles,
      page,
      community_id,
      has_whatsapp,
      language,
      orderByKey,
      orderByValue,
      contact_list_id,
      communities,
      favorite,
    },
    type
  ) => {
    const response = axios({
      method: 'GET',
      url: `/users/list/${type}`,
      params: {
        search: search || undefined,
        name: name || undefined,
        full_name: full_name || undefined,
        paginate,
        country_id: country_id || undefined,
        department_id: department_id || undefined,
        city_id: city_id || undefined,
        roles: roles
          ? Array.isArray(roles) && roles.length > 0
            ? _.map(roles, (role) => role?.value || role)
            : [roles?.value || roles]
          : undefined,
        page,
        contact_list_id: contact_list_id || undefined,
        community_id: community_id?.value
          ? community_id?.value
          : community_id || undefined,
        has_whatsapp:
          has_whatsapp === 1 || has_whatsapp === 0 ? has_whatsapp : undefined,
        language: language || undefined,
        orderBy: orderByKey ? { [orderByKey]: orderByValue } : undefined,
        communities: communities || undefined,
        favorite,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return trackPromise(response);
  };

  const getUserProviders = (id) => {
    const response = axios({
      method: 'GET',
      url: `/users/${id}`,
    });
    return trackPromise(response);
  };

  const getFavoriteUsersProviders = () => {
    const response = axios({
      method: 'GET',
      url: `/favorite-artists`,
    });
    return trackPromise(response);
  };

  const saveUserAsFavoriteProviders = ({ userId }) => {
    const response = axios({
      method: 'POST',
      url: `/favorite-artists`,
      data: { user_artist_id: userId },
    });
    return trackPromise(response);
  };

  const saveUserAsNotFavoriteProviders = ({ userId }) => {
    const response = axios({
      method: 'DELETE',
      url: `/favorite-artists/user/${userId}`,
    });
    return trackPromise(response);
  };

  return {
    updateProfile,
    getProfile,
    updateAvatar,
    editUserProvider,
    createUserProvider,
    changePassword,
    deleteUserProviders,
    searchUserProviders,
    getUserProviders,
    getFavoriteUsersProviders,
    saveUserAsFavoriteProviders,
    saveUserAsNotFavoriteProviders,
  };
};

export default useUserProviders;
