import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const StyledWrapperCommunity = styled.div.attrs(() => ({
  className: 'StyledWrapperCommunity',
}))`
  ${tw`space-y-4 mt-8 mb-12`}
  ${(props) => props.nonemb && tw`mb-0`}
`;

export const StyledContentBorder = styled.div.attrs(() => ({
  className: 'StyledContentBorder',
}))`
  ${tw` border rounded p-4`}
`;

export const StyledCardCommunity = styled.div.attrs(() => ({
  className: 'StyledCardCommunity',
}))`
  ${tw`bg-white py-2 px-4 flex justify-between rounded-sm items-center`}
  ${(props) => props.py0 && tw`py-0`}
  ${(props) => props.darkBg && tw`bg-neutral`}
`;

export const StyledTopFilters = styled.div.attrs(() => ({
  className: 'StyledTopFilters',
}))`
  ${tw` flex items-center justify-between`}
`;

export const StyledFilters = styled.div.attrs(() => ({
  className: 'StyledFilters',
}))`
  ${tw` flex items-start justify-end space-x-4`}
  .icon-filter {
    &:before {
      ${tw` mr-3`}
    }
  }
`;

export const StyledInputSearchContent = styled.div.attrs(() => ({
  className: 'StyledInputSearchContent',
}))`
  ${tw` relative `}
  .icon-search {
    &:before {
      ${tw` absolute top-0 left-0 z-10 text-neutral-700 text-lg mt-3 ml-4`}
    }
    input {
      ${tw` pl-12 w-80`}
    }
    .inputFormstyles__StyledWrapperInputIcon-sc-1kaxq5a-2 {
      &:before {
        ${tw` hidden`}
      }
    }
  }
`;

export const StyledContentTable = styled.div.attrs(() => ({
  className: 'StyledContentTable',
}))`
  ${tw` w-full mt-5`}
`;

export const StyledContentButtons = styled.div.attrs(() => ({
  className: 'StyledContentButtons',
}))`
  ${tw` flex items-center`}
  button {
    ${tw` bg-transparent text-tertiary shadow-none border-none hover:bg-transparent px-0 py-0`}
    font-size:1.7142857142857142rem;
    &.icon-edit {
      font-size: 2.5rem;
      height: 28px;
      ${tw`  text-primary w-8`}
      &:before {
        ${tw`  h-full`}
      }
    }
  }
`;

export const StyledContentButtonsModal = styled.div.attrs(() => ({
  className: 'StyledContentButtonsModal',
}))`
  ${tw` flex justify-center items-center mt-8`}
`;

export const StyledSocialNetworks = styled.div.attrs(() => ({
  className: 'StyledSocialNetworks',
}))`
  ${tw`  space-x-4 flex  `}
`;

export const StyledForm = styled.form.attrs(() => ({
  className: 'StyledForm',
}))`
  ${tw` space-y-4`}

  ${(props) => props.border && tw`border border-neutral-600 p-6 rounded-md`}
`;

export const StyledGroup = styled.section.attrs(() => ({
  className: 'StyledGroup',
}))`
  ${tw` space-y-3 lg:space-y-4 lg:grid lg:grid-cols-2 lg:gap-x-12 w-full`}

  ${(props) => props.col2 && tw`lg:gap-x-4 lg:flex lg:!mt-0`}
    ${(props) => props.noSpace && tw`lg:space-y-0`}
    ${(props) => props.flex && tw`lg:flex lg:gap-x-0 lg:space-y-0`}
`;

export const StyledBackButton = styled.button.attrs(() => ({
  className: 'StyledBackButton icon-arrow',
}))`
  ${tw` font-bold text-primary`}
  &:before {
    ${tw` text-secondary lg:mr-4 mr-2`}
    transform: rotate(180deg);
    font-weight: 700;
  }
`;

export const StyledDivider = styled.div.attrs(() => ({
  className: 'StyledDivider',
}))`
  ${tw`w-full my-12 border border-neutral-600 block`}
`;
