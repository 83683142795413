//Packages
import PropTypes from 'prop-types';
import React from 'react';

//Hooks
import useControllers from '../../../controllers';
import GeneralRoute from '../General';

const RecoverPasswordRoute = (props) => {
  const { component, redirect, message, ...rest } = props;

  const { useRoutesHooks } = useControllers();
  const { useRecoverPasswordRoutesController } = useRoutesHooks();
  const { props: properties, permission } =
    useRecoverPasswordRoutesController();

  return (
    <GeneralRoute
      component={component}
      redirect={redirect}
      message={message}
      properties={properties}
      permission={permission}
      {...rest}
    />
  );
};
RecoverPasswordRoute.defaultProps = {
  redirect: null,
  message: null,
};
RecoverPasswordRoute.propTypes = {
  redirect: PropTypes.string,
  message: PropTypes.element,
  component: PropTypes.elementType.isRequired,
};
export default RecoverPasswordRoute;
