//Packages
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

// Hooks
import useApi from 'api';
import useModels from 'models';
import useStrings from 'strings';
import useHelpers from 'helpers';
import useControllers from 'controllers';

const useAdminViewInterventionsCreateCollectionInformation = ({
  setActiveItem,
}) => {
  const initialStateNumberOptions = [{ id: Date.now(), value: '' }];
  const [numberOptions, setNumberOptions] = useState(initialStateNumberOptions);
  const [validateOptionsRules, setValidateOptionsRules] = useState(false);
  const { intervention_id } = useParams();

  const { useGeneralHooks } = useControllers();
  const { useToggleModal } = useGeneralHooks();
  const [confirmationModal, handleVisibilityConfirmationModal] =
    useToggleModal();
  const [activeAtLeastItemDraft, setActiveAtLeastItemDraft] = useToggleModal();

  const { useActions } = useApi();
  const { dispatch, useAdminActions, useGeneralActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useTabSelectedActions } = useGeneralActions();
  const { actSetTabSelected } = useTabSelectedActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();
  const {
    useAdminViewInterventionDevelopmentInterventionActions,
    useAdminViewInterventionsCommunicationActions,
  } = useAdminViewInterventionsActions();
  const { useAdminDevelopmentInterventionKnowCommunityActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const { actSendMessageDeepenKnowledgeIntervention } =
    useAdminDevelopmentInterventionKnowCommunityActions();
  const { actSendMessagesIntervention, actGetMessagesIntervention } =
    useAdminViewInterventionsCommunicationActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors, useUserSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getToSendMessageInterventionSelector } =
    useAdminInterventionsSelectors();
  const { userSelector } = useUserSelectors();

  const toSendMessage = useSelector(getToSendMessageInterventionSelector);
  const selectedLoggedInUser = useSelector(userSelector);

  const { useConstants, useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const {
    REQUIRED_FIELD,
    DATE_GREATER_THAN_TODAY,
    MAX_LENGTH_320,
    MAX_LENGTH_150,
  } = useFormsTypes();
  const {
    LIST_OF_OPTIONS_TO_DESTINATIONS,
    LIST_OF_TYPE_OF_COLLECTION,
    LIST_OF_TYPE_OF_INFORMATION_COLLECTION,
    LIST_OF_CHANNELS_FOR_SEND_MESSAGES,
  } = useConstants();

  const { useQuickFunctions } = useHelpers();
  const { convertDateToISO, isSelectedDateGreaterThanTodayDate } =
    useQuickFunctions();

  const schemaCreateCollectionInformation = yup.object({
    gi_type: yup.object().nullable().required(REQUIRED_FIELD),
    type_information: yup
      .object()
      .nullable()
      .when('gi_type', {
        is: (gi_type) => gi_type && gi_type?.value === 'a',
        then: yup.object().required(REQUIRED_FIELD),
        otherwise: yup.object().notRequired().nullable(),
      }),
    channel: yup.object().required(REQUIRED_FIELD).nullable(),
    typeDest: yup.object().required(REQUIRED_FIELD).nullable(),
    communities: yup
      .array()
      .required(REQUIRED_FIELD)
      .min(1, 'Debe existir al menos un destino')
      .nullable(),
    date_checkbox: yup.boolean(),
    send_date: yup.date().when('date_checkbox', {
      is: true,
      then: yup
        .date()
        .required(REQUIRED_FIELD)
        .test('dateValidation', DATE_GREATER_THAN_TODAY, (value) => {
          return value
            ? isSelectedDateGreaterThanTodayDate({ selectedDate: value })
            : true;
        })
        .typeError(REQUIRED_FIELD),
      otherwise: yup
        .date()
        .nullable()
        .default(null)
        .transform((curr, orig) => (orig === '' ? null : curr)),
    }),
    message: yup
      .string()
      .required(REQUIRED_FIELD)
      .nullable()
      .when('gi_type', {
        is: (gi_type) => gi_type?.value === 'a',
        then: yup.string().max(150, MAX_LENGTH_150),
        otherwise: yup.string().max(320, MAX_LENGTH_320),
      }),
    options: yup.array().when('type_information', {
      is: (type_information) => type_information?.value === 'qn',
      then: yup
        .array()
        .required(REQUIRED_FIELD)
        .nullable()
        .min(2, 'Debe existir al menos dos opciones de respuesta')
        .test(
          'emptyValues',
          'Las respuestas no deben estar vacías',
          (optionsArray) => {
            if (optionsArray?.length) {
              const optionsNoEmptys = _.filter(optionsArray, (option) => {
                return Boolean(option);
              });
              return !(numberOptions.length > optionsNoEmptys.length);
            }
            return true;
          }
        ),
      otherwise: yup.array().notRequired(),
    }),
    type: yup.object().nullable(),
  });

  const formCreateCollectionInformation = useForm({
    resolver: yupResolver(schemaCreateCollectionInformation),
    mode: 'onChange',
  });

  const watchTypeCollection =
    formCreateCollectionInformation.watch('gi_type')?.value;
  const watchTypeInformation =
    formCreateCollectionInformation.watch('type_information')?.value;
  const watchOptions = formCreateCollectionInformation.watch('options');

  useEffect(() => {
    if (watchOptions) {
      const optionsNoEmptys = _.filter(watchOptions, (option) => {
        return Boolean(option);
      });
      numberOptions.length === optionsNoEmptys.length
        ? formCreateCollectionInformation.clearErrors('options')
        : formCreateCollectionInformation.setError('options', {
            type: 'manual',
            message: 'Las respuestas no deben estar vacías',
          });
    }
  }, [validateOptionsRules]);

  useEffect(() => {
    if (
      watchTypeCollection === 'm' &&
      formCreateCollectionInformation.watch('channel')?.value === 'sms'
    ) {
      formCreateCollectionInformation.setValue('channel', null);
    }
  }, [watchTypeCollection]);

  useEffect(() => {
    if (watchTypeCollection === 'm' && watchTypeInformation) {
      formCreateCollectionInformation.setValue('type_information', undefined);
      formCreateCollectionInformation.setValue('options', []);
    }
  }, [watchTypeCollection]);

  useEffect(() => {
    if (
      watchTypeCollection === 'a' &&
      watchTypeInformation === 'ql' &&
      watchOptions &&
      watchOptions.length
    ) {
      formCreateCollectionInformation.setValue('options', []);
    }
  }, [watchTypeInformation]);

  useEffect(() => {
    if (toSendMessage.id) {
      formCreateCollectionInformation.reset({
        gi_type: { label: 'Alfanumérica', value: 'a' },
        type_information: {
          label: toSendMessage.type_information.name,
          value: toSendMessage.type_information.id,
        },
        channel: {
          label: toSendMessage.channel.name,
          value: toSendMessage.channel.id,
        },
        options: toSendMessage.options,
        message: toSendMessage.body,
      });
    }
  }, [toSendMessage]);

  useEffect(() => {
    if (!formCreateCollectionInformation.watch('date_checkbox')) {
      formCreateCollectionInformation.setValue('send_date', undefined);
    }
  }, [formCreateCollectionInformation.watch('date_checkbox')]);

  useEffect(() => {
    if (
      formCreateCollectionInformation.watch('typeDest')?.value &&
      !toSendMessage.id
    ) {
      formCreateCollectionInformation.setValue('communities', []);
    }
  }, [formCreateCollectionInformation.watch('typeDest')?.value]);

  const handleCancelSendMessage = (e) => {
    e.preventDefault();
    if (toSendMessage.id) return dispatch(actSetTabSelected({ tabValue: 0 }));
    setActiveItem &&
      setActiveItem((prevValue) => ({ ...prevValue, active: 0 }));
  };

  const sendMessage = ({ data, fromModalConfirmation = false }) => {
    if (
      !Object.keys(formCreateCollectionInformation.formState.errors).length &&
      fromModalConfirmation
    ) {
      data.state = selectedLoggedInUser.role.id !== 'ar' ? 'ip' : 'p';
      data.channel = data.channel.value;

      if (data.type_information) {
        data.type_information = data.type_information.value;
      }
      data.gi_type = data.gi_type.value;
      data.type = data?.type?.value || 'gi';

      if (data?.typeDest?.value === 'lista') {
        data.contactLists = _.map(
          data.communities,
          (community) => community.value
        );
        delete data.communities;
      } else {
        data.communities = _.map(
          data.communities,
          (community) => community.value
        );
      }

      if (toSendMessage.id) {
        delete data.type_information;
        delete data.gi_type;
      }

      if (!data.options?.length) {
        delete data.options;
      }

      delete data.typeDest;
      delete data.date_checkbox;

      data.send_date = data.send_date
        ? convertDateToISO({ date: data.send_date })
        : undefined;

      if (toSendMessage.id) {
        dispatch(
          actSendMessageDeepenKnowledgeIntervention(
            {
              deepen_knowledge_id: toSendMessage.deepen_knowledge_id,
              message_id: toSendMessage.id,
              data,
            },
            () => {
              formCreateCollectionInformation.reset();
              dispatch(actSetTabSelected({ tabValue: 0 }));
              handleVisibilityConfirmationModal();
            }
          )
        );
      } else {
        dispatch(
          actSendMessagesIntervention({ intervention_id, data }, () => {
            dispatch(
              actGetMessagesIntervention(
                {
                  intervention_id,
                  draftOrNoDraft: 'nd',
                  // ...filter,
                  type: 'gi',
                },
                () => {
                  handleVisibilityConfirmationModal();
                  setActiveItem((prevValue) => ({ ...prevValue, active: 0 }));
                  formCreateCollectionInformation.reset();
                  setNumberOptions(initialStateNumberOptions);
                }
              )
            );
          })
        );
      }
    } else {
      handleVisibilityConfirmationModal();
    }
  };

  const handleSendMessageDraft = ({ data, setActiveItem }) => {
    const anyFieldFilled = Object.values(data).some((value) => Boolean(value));

    if (
      !anyFieldFilled ||
      formCreateCollectionInformation.formState.errors.send_date
    ) {
      setActiveAtLeastItemDraft();
    } else {
      if (data?.typeDest?.value && !data.communities) {
        formCreateCollectionInformation.setError('communities', {
          type: 'manual',
          message: REQUIRED_FIELD,
        });
      } else {
        data.state = 'd';
        data.channel = data?.channel?.value;
        data.type = 'gi';
        data.gi_type = data?.gi_type?.value;
        if (data.type_information) {
          data.type_information = data?.type_information?.value;
        }
        if (data?.typeDest?.value === 'lista') {
          const contactLists = _.map(
            data.communities,
            (community) => community.value
          );
          data.contactLists =
            contactLists.length > 0 ? contactLists : undefined;
          delete data.communities;
        } else {
          const communities = _.map(
            data.communities,
            (community) => community.value
          );
          data.communities = communities.length > 0 ? communities : undefined;
        }
        delete data.typeDest;
        delete data.date_checkbox;

        if (
          !data.options?.length ||
          (data.options?.length === 1 && !data.options?.[0])
        ) {
          delete data.options;
        }

        data.send_date =
          data.send_date && convertDateToISO({ date: data.send_date });

        dispatch(
          actSendMessagesIntervention({ intervention_id, data }, () => {
            setActiveItem((prevValue) => ({ ...prevValue, active: 0 }));
            formCreateCollectionInformation.reset();
          })
        );
      }
    }
  };

  const handleAddOptions = (event) => {
    event && event.preventDefault();
    setNumberOptions((prevValue) => [
      ...prevValue,
      {
        id: Date.now(),
        value: '',
      },
    ]);
  };

  const handleDeleteOption = ({ event, optionId }) => {
    event && event.preventDefault();
    const newArrayOptions = _.filter(numberOptions, (option, index) => {
      return index !== optionId;
    });
    setNumberOptions(newArrayOptions);
    if (watchOptions.length > 0) {
      if (watchOptions[optionId] !== undefined) {
        const newArrayOptionsForm = _.filter(
          watchOptions,
          (option, index) => index !== optionId
        );
        formCreateCollectionInformation.setValue(
          'options',
          newArrayOptionsForm
        );
      }
    }
  };

  const handleOnChangeOptions = ({ optionValue, optionIndex }) => {
    const newArrayOptions = _.map(numberOptions, (option) => {
      if (option.id === optionIndex) {
        return { ...option, value: optionValue };
      }
      return option;
    });
    setNumberOptions(newArrayOptions);
  };

  return {
    //Options/constants
    LIST_OF_OPTIONS_TO_DESTINATIONS,
    LIST_OF_TYPE_OF_COLLECTION,
    LIST_OF_TYPE_OF_INFORMATION_COLLECTION,
    LIST_OF_CHANNELS_FOR_SEND_MESSAGES,
    numberOptions,
    watchOptions,

    //rhf
    formCreateCollectionInformation,

    //Modals
    confirmationModal,
    handleVisibilityConfirmationModal,
    activeAtLeastItemDraft,
    setActiveAtLeastItemDraft,

    toSendMessage,
    fromInterventionMessage: Boolean(toSendMessage.id),
    handleCancelSendMessage,
    sendMessage,
    handleSendMessageDraft,
    handleAddOptions,
    setValidateOptionsRules,
    handleDeleteOption,
    handleOnChangeOptions,
  };
};

export default useAdminViewInterventionsCreateCollectionInformation;
