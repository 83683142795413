import { useEffect, useState } from 'react';
import useApi from '../../../../../../../api';
import { useParams } from 'react-router-dom';
import useModels from '../../../../../../../models';

const useAdminKnowCommunityInterventionsSteep = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [actualStep, setActualStep] = useState(0);
  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();
  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const { useAdminDevelopmentInterventionKnowCommunityActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const {
    actKnowCommunityIntervention,
    actGetDeepenKnowledgeCommunityIntervention,
  } = useAdminDevelopmentInterventionKnowCommunityActions();

  const { intervention_id } = useParams();

  const { useSelectors } = useModels();
  const { useAdminSelectors, useSelector } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionsSelector, getInterventionDetailToEditSelector } =
    useAdminInterventionsSelectors();
  const { know_community, deepen_knowledge, deepen_execution } = useSelector(
    getInterventionsSelector
  );
  const { actual_phase } = useSelector(getInterventionDetailToEditSelector);

  useEffect(() => {
    getActualPhase();
  }, [actual_phase]);

  useEffect(() => {
    if (
      !['p'].includes(know_community.state?.id) &&
      know_community.com_deepen
    ) {
      dispatch(actGetDeepenKnowledgeCommunityIntervention({ intervention_id }));
    }
  }, [know_community]);

  useEffect(() => {
    if (know_community.state?.id !== 'c') {
      dispatch(actKnowCommunityIntervention({ intervention_id }));
    }
  }, [know_community.state?.id]);

  const getActualPhase = () => {
    const phaseActual = actual_phase?.id ?? '0';
    const phase = phaseActual.split('.');
    setActualStep(phase[1] ? parseInt(phase[1]) : 0);
    setCurrentPage(phase[1] ? parseInt(phase[1]) : 0);
  };
  return {
    currentPage,
    setCurrentPage,
    deepen_knowledge,
    deepen_execution,
    know_community,
    actualStep,
    actualPhase: Number(actual_phase?.id),
  };
};
export default useAdminKnowCommunityInterventionsSteep;
