//Hooks
import useModels from '../../../models';

const useProtectedRoute = (role_validation) => {
  //Models
  const { useSelectors } = useModels();
  const { useSelector, useUserSelectors } = useSelectors();
  const { userSelector } = useUserSelectors();
  const { role } = useSelector(userSelector);
  const permission = role_validation.includes(role.id);

  return { permission, props: {} };
};

export default useProtectedRoute;
