import { useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { useForm } from 'react-hook-form';

// Hooks
import useApi from 'api';
import useModels from 'models';
import useStrings from 'strings';
import useHelpers from 'helpers';

const useAdminExecuteMaterializeIntervention = () => {
  const { intervention_id: interventionId } = useParams();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();
  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const { useAdminDevelopmentExecuteInterventionActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const {
    actUpdateMaterializeIntervention
  } = useAdminDevelopmentExecuteInterventionActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionsSelector } = useAdminInterventionsSelectors();
  const { executeMaterialize } = useSelector(getInterventionsSelector);
  const { id: materializeId } = executeMaterialize;

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { REQUIRED_FIELD, DATE_GREATER_THAN_TODAY, MAX_LENGTH_500 } = useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const {
    isSelectedDateGreaterThanTodayDate,
    getYearMonthDayFromDate
  } = useQuickFunctions();

  const [openModalDeny, setOpenModalDeny] = useState(false);
  const [openModalApprove, setOpenModalApprove] = useState(false);

  const schemaExecuteMaterializeIntervention = yup.object({
    dateLimitCheckbox: yup.boolean(),
    dateLimit: yup.date().when('dateLimitCheckbox', {
      is: true,
      then: yup
        .date()
        .required(REQUIRED_FIELD)
        .typeError(REQUIRED_FIELD)
        .test('dateValidation', DATE_GREATER_THAN_TODAY, (value) => {
          return value
            ? isSelectedDateGreaterThanTodayDate({ selectedDate: value })
            : true;
        }),
      otherwise: yup
        .date()
        .notRequired()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr)),
    }),
    rejectedReason: openModalDeny
      ? yup.string().max(500, MAX_LENGTH_500).required(REQUIRED_FIELD)
      : yup.string().notRequired(),
    dateLimitRejectCheckbox: yup.boolean(),
    dateLimitReject: yup.date().when('dateLimitRejectCheckbox', {
      is: true,
      then: yup
        .date()
        .required(REQUIRED_FIELD)
        .typeError(REQUIRED_FIELD)
        .test('dateValidation', DATE_GREATER_THAN_TODAY, (value) => {
          return value
            ? isSelectedDateGreaterThanTodayDate({ selectedDate: value })
            : true;
        }),
      otherwise: yup
        .date()
        .notRequired()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr)),
    }),
  });

  const formExecuteMaterialize = useForm({
    resolver: yupResolver(schemaExecuteMaterializeIntervention),
    mode: 'onChange',
  });

  const updateEvaluatePilotIntervention = (data) => {
    let dataToSend = {}
    if (data.dateLimitCheckbox && data.dateLimit) {
      dataToSend.date_limit = getYearMonthDayFromDate({
        dateInString: data.dateLimit,
        format: 'ymd',
      })
    }
    dataToSend.state = executeMaterialize.date_ip ? 'c' : 'ip'
    if (data.rejectedReason) {
      dataToSend.rejected_reason = data.rejectedReason
      dataToSend.state = 'r'
      if (data.dateLimitRejectCheckbox) {
        dataToSend.date_limit = getYearMonthDayFromDate({
          dateInString: data.dateLimitReject,
          format: 'ymd',
        });
      }
    }
    dispatch(actUpdateMaterializeIntervention({
      interventionId,
      materializeId,
      data: dataToSend
    }))
  }

  return {
    formExecuteMaterialize,
    openModalDeny,
    openModalApprove,
    setOpenModalDeny,
    setOpenModalApprove,
    canEditForm: !_.includes(['ip', 'r', 'c'], executeMaterialize.state.id),
    updateEvaluatePilotIntervention
  }
}

export default useAdminExecuteMaterializeIntervention