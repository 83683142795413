import React from 'react';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';

// Assets
import imagePin from 'assets/icons/MapPin-black.svg';

const DetailUser = (props) => {
  const { user } = props;
  const { name, last_name, role, city, phone, gender, has_whatsapp } = user;

  const { useComponents } = useViews();
  const { useTypographies, useImages } = useComponents();
  const { TypographyComponent } = useTypographies();
  const { Image } = useImages();

  return (
    <>
      <TypographyComponent
        text={`${name} ${last_name}`}
        variant="h3"
        className="font-bold text-primary mb-1 "
      />

      <TypographyComponent
        text={role.name}
        variant="p"
        className="text-neutral-950 mt-0 font-primary"
      />

      <TypographyComponent
        className="text-neutral-950 flex items-center justify-start pl-20 lg:pl-0 mt-2 lg:mt-0 font-bold mb-4"
        variant="p"
        text={
          <>
            <Image
              srcImage={imagePin}
              altImage="location"
              titleImage="location"
              className="location-icon"
            />
            {city.name}, {city?.department?.country?.name}
          </>
        }
      />

      <TypographyComponent
        text={
          <>
            <span className="text-primary">Teléfono</span>
            <br />
            {phone.code?.code} {phone.number}
          </>
        }
        variant="p"
        className="text-neutral-950  mb-4 font-primary"
      />
      <TypographyComponent
        text={
          <>
            <span className="text-primary">Género</span>
            <br />
            {gender}
          </>
        }
        variant="p"
        className="text-neutral-950  mb-4 font-primary"
      />
      <TypographyComponent
        text={
          <>
            <span className="text-primary">Cuenta con Whatsapp</span>
            <br />
            {has_whatsapp ? 'Sí' : 'No'}
          </>
        }
        variant="p"
        className="text-neutral-950  mb-0 font-primary"
      />
    </>
  );
};

DetailUser.propTypes = {
  user: PropTypes.object,
};

export default DetailUser;
