import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useAdminSocioCulturalInfoProviders = () => {
  const getSocioCulturalInfo = ({ projectId }) => {
    const response = axios({
      method: 'GET',
      url: `/projects/${projectId}/social-cultural`,
    });
    return trackPromise(response);
  };

  const createSocialCulturalInfo = ({ formData, projectId }) => {
    const response = axios({
      method: 'POST',
      headers: {
        'content-type': 'multipart/form-data',
      },
      url: `/projects/${projectId}/social-cultural`,
      data: formData,
    });
    return trackPromise(response);
  };

  const updateSocialCulturalInfo = ({
    formData,
    socioCulturalId,
    projectId,
  }) => {
    const response = axios({
      method: 'POST',
      url: `/projects/${projectId}/social-cultural/${socioCulturalId}`,
      data: formData,
    });
    return trackPromise(response);
  };

  const deleteSocialCulturalInfo = ({ projectId, socioCulturalId }) => {
    const response = axios({
      method: 'DELETE',
      url: `/projects/${projectId}/social-cultural/${socioCulturalId}`,
    });
    return trackPromise(response);
  };

  return {
    getSocioCulturalInfo,
    createSocialCulturalInfo,
    updateSocialCulturalInfo,
    deleteSocialCulturalInfo,
  };
};

export default useAdminSocioCulturalInfoProviders;
