import useAdminCreateProject from './create';
import useAdminDashboardProjectsHooks from './dashboard';
import useAdminEditProject from './edit';
import useAdminFilterProject from './filter';
import useAdminRootProject from './root';

const useAdminProjects = () => {
  return {
    useAdminEditProject,
    useAdminCreateProject,
    useAdminRootProject,
    useAdminDashboardProjectsHooks,
    useAdminFilterProject,
  };
};

export default useAdminProjects;
