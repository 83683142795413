import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import qs from 'qs';

const useAdminLabelsProviders = () => {
  const createLabels = ({ name, color }) => {
    const response = axios({
      method: 'POST',
      url: '/tags',
      data: { name, color },
    });

    return trackPromise(response);
  };

  const getLabelsList = ({ paginate, name, orderByKey, orderByValue }) => {
    const response = axios({
      method: 'GET',
      url: '/tags',
      params: {
        paginate,
        name,
        orderBy: orderByKey ? { [orderByKey]: orderByValue } : undefined,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return trackPromise(response);
  };

  const deleteLabels = ({ labelId }) => {
    const response = axios({
      method: 'DELETE',
      url: `/tags/${labelId}`,
    });

    return trackPromise(response);
  };

  const updateLabels = ({ labelId, name, color }) => {
    const response = axios({
      method: 'PATCH',
      url: `/tags/${labelId}`,
      data: { name, color },
    });

    return trackPromise(response);
  };
  const assignLabelToContactsProvider = ({ tag, users }) => {
    const response = axios({
      method: 'POST',
      url: `/tags/${tag}/users`,
      data: { users },
    });

    return trackPromise(response);
  };
  const assignLabelsToContactProvider = ({ user, tags }) => {
    const response = axios({
      method: 'POST',
      url: `/users/${user}/tags`,
      data: { tags },
    });

    return trackPromise(response);
  };

  const unassignLabelToContactProvider = ({ user, tag }) => {
    const response = axios({
      method: 'DELETE',
      url: `/users/${user}/tags/${tag}`,
    });

    return trackPromise(response);
  };
  return {
    createLabels,
    getLabelsList,
    deleteLabels,
    updateLabels,
    assignLabelToContactsProvider,
    assignLabelsToContactProvider,
    unassignLabelToContactProvider,
  };
};

export default useAdminLabelsProviders;
