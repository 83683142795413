//Packages
import { useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

//Hooks
import useApi from '../../../../../../api';
import useHelpers from '../../../../../../helpers';
import useModels from '../../../../../../models';

const useAdminRootDashboard = () => {
  const { project_id: projectId } = useParams();
  const history = useHistory();
  const { pathname } = useLocation();

  const { useQuickFunctions } = useHelpers();
  const {
    convertThounsandsSeparator,
    convertArrayToString,
    showCityDepartmentAndCountry,
  } = useQuickFunctions();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminProjectsRootActions } = useAdminProjectsActions();
  const { actGetProjectById } = useAdminProjectsRootActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors, useUserSelectors } = useSelectors();
  const { userSelector } = useUserSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminProjectsRootSelectors } = useAdminProjectsSelectors();
  const { projectSelectedSelector, projectSelectedTotalStatisticsSelector } =
    useAdminProjectsRootSelectors();
  const projectData = useSelector(projectSelectedSelector);
  const projecDataStatistics = useSelector(
    projectSelectedTotalStatisticsSelector
  );
  const userData = useSelector(userSelector);

  useEffect(() => {
    dispatch(actGetProjectById({ projectId }, null, onErrorActGetProjectById));
  }, []);

  const onErrorActGetProjectById = () => {
    history.replace('/admin/projects');
  };

  const handleGoBack = () =>
    userData.role.id !== 'ad'
      ? history.push('/dashboard')
      : history.push('/admin/projects');

  const handleGoToCommunities = () => history.push(`${pathname}/communities`);
  const handleGoToSocioCulturalInfo = () =>
    history.push(`${pathname}/socio-cultural-info`);
  const handleGoToFestivitiesAndTraditions = () =>
    history.push(`${pathname}/festivities`);
  const handleGoToAbcTrajectory = () =>
    history.push(`${pathname}/abc-trajectory`);
  const handleGoRecommendations = () =>
    history.push(`${pathname}/recommendations`);
  const handleGoAdditionalInfo = () =>
    history.push(`${pathname}/additional-info`);
  const handleGoToDocumentation = () =>
    history.push(`${pathname}/documentation`);
  const handleGoToInterventions = () =>
    history.push(`${pathname}/interventions`);

  return {
    handleGoBack,
    projectData,
    projecDataStatistics,
    handleGoToCommunities,
    handleGoToSocioCulturalInfo,
    handleGoToFestivitiesAndTraditions,
    handleGoToAbcTrajectory,
    handleGoRecommendations,
    handleGoAdditionalInfo,
    handleGoToDocumentation,
    convertThounsandsSeparator,
    handleGoToInterventions,
    convertArrayToString,
    showCityDepartmentAndCountry,
  };
};

export default useAdminRootDashboard;
