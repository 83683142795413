//Packages
import React from 'react';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';

//Styles
import { StyledCardEmptyState } from './CardEmptyState.styles';

const CardEmptyState = (props) => {
  const { title, body, button, className } = props;

  const { useComponents } = useViews();
  const { useTypographies } = useComponents();
  const { TypographyComponent } = useTypographies();

  return (
    <StyledCardEmptyState className={className}>
      <TypographyComponent
        text={title}
        variant="h3"
        className={`font-bold text-primary ${body && 'mb-6'} `}
      />
      {body && <TypographyComponent text={body} variant="p" className="mb-8" />}
      {button && button}
    </StyledCardEmptyState>
  );
};

CardEmptyState.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  className: PropTypes.string,
  button: PropTypes.element,
};

CardEmptyState.defaultProps = {
  title: '',
  body: '',
  textButton: '',
  onClick: () => {},
  className: '',
};

export default CardEmptyState;
