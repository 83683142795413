const useAdminViewInterventionsCommunicationTypes = () => {
  const GET_LIST_OF_MESSAGES_INTERVENTION = 'GET_LIST_OF_MESSAGES_INTERVENTION';
  const GET_SELECTED_MESSAGE_INTERVENTION = 'GET_SELECTED_MESSAGE_INTERVENTION';
  const SEND_MESSAGES_INTERVENTION = 'SEND_MESSAGES_INTERVENTION';
  const DELETE_MESSAGE_INTERVENTION = 'DELETE_MESSAGE_INTERVENTION';
  const SET_FILTER_LIST_OF_MESSAGES_INTERVENTION =
    'SET_FILTER_LIST_OF_MESSAGES_INTERVENTION';
  const RESET_FILTER_LIST_OF_MESSAGES_INTERVENTION =
    'RESET_FILTER_LIST_OF_MESSAGES_INTERVENTION';
  const REJECTED_REQUEST_MESSAGE_BY_ARTIST =
    'REJECTED_REQUEST_MESSAGE_BY_ARTIST';
  const RESET_SELECTED_MESSAGE_INTERVENTION =
    'RESET_SELECTED_MESSAGE_INTERVENTION';
  const DOWNLOAD_FILE_OF_COLLECTION_MESSAGE =
    'DOWNLOAD_FILE_OF_COLLECTION_MESSAGE';

  return {
    GET_LIST_OF_MESSAGES_INTERVENTION,
    GET_SELECTED_MESSAGE_INTERVENTION,
    SEND_MESSAGES_INTERVENTION,
    DELETE_MESSAGE_INTERVENTION,
    SET_FILTER_LIST_OF_MESSAGES_INTERVENTION,
    RESET_FILTER_LIST_OF_MESSAGES_INTERVENTION,
    REJECTED_REQUEST_MESSAGE_BY_ARTIST,
    RESET_SELECTED_MESSAGE_INTERVENTION,
    DOWNLOAD_FILE_OF_COLLECTION_MESSAGE,
  };
};

export default useAdminViewInterventionsCommunicationTypes;
