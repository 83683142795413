//Hooks
import useControllers from '../..';

const usePrivateRoute = () => {
  //Controllers
  const { useGeneralHooks } = useControllers();
  const { useIsAuth } = useGeneralHooks();
  const { isAuth } = useIsAuth();

  const permission = isAuth;

  return { permission, props: {} };
};

export default usePrivateRoute;
