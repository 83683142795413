//Hooks
import useCountriesActions from './countries';
import useSpecialitiesActions from './especialities';
import useLanguagesActions from './languages';
import useLegalDataActions from './legalData';
import useModalsActions from './modals';
import useColorsActions from './colors';
import useAccessibilityActions from './accessibility';
import useSidebarActions from './sidebar';
import useNotificationActions from './notifications';
import useTabSelectedActions from './tabSelected';
import useIsFilterEmptyActions from './isFilterEmpty';

const useGeneralActions = () => {
  return {
    useCountriesActions,
    useLegalDataActions,
    useLanguagesActions,
    useModalsActions,
    useSpecialitiesActions,
    useColorsActions,
    useAccessibilityActions,
    useSidebarActions,
    useNotificationActions,
    useTabSelectedActions,
    useIsFilterEmptyActions,
  };
};

export default useGeneralActions;
