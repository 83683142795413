import { useState } from 'react';

const useToggleModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const handleIsVisible = () => setIsVisible(!isVisible);

  return [isVisible, handleIsVisible];
};

export default useToggleModal;
