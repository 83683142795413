//Packages
import { useEffect, useState } from 'react';

//Hooks
import useModels from 'models';

const useExecuteInterventionStepper = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const { useSelectors } = useModels();
  const { useAdminSelectors, useSelector } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionsSelector, getInterventionDetailToEditSelector } =
    useAdminInterventionsSelectors();
  const { actual_phase } = useSelector(getInterventionDetailToEditSelector);
  const { executeMaterialize, executeIntervention, executeEvaluate } =
    useSelector(getInterventionsSelector);

  useEffect(() => {
    getActualPhase();
  }, [actual_phase]);

  const getActualPhase = () => {
    const actualPhase = actual_phase.id ?? '0';
    const phase = actualPhase.split('.');
    const step = phase[1] ? parseInt(phase[1]) : 0;
    setCurrentStep(step);
  };

  return {
    currentStep,
    setCurrentStep,
    executeMaterialize,
    executeIntervention,
    executeEvaluate,
    actualPhase: Number(actual_phase?.id),
  };
};

export default useExecuteInterventionStepper;
