const useConstants = () => {
  const ONE_DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;
  const MONTHS_OF_THE_YEAR = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  const MESSAGES_LIST_STATUS = [
    { label: 'En progreso', value: 'ip' },
    { label: 'Pendiente', value: 'p' },
    { label: 'Rechazado', value: 'r' },
    { label: 'Enviado', value: 's' },
  ];

  const LIST_OF_TYPES_OF_MESSAGES = [
    {
      label: 'Selecciona el tipo de solicitud',
      value: '',
    },
    {
      label: 'Mensaje a la comunidad',
      value: 'd',
    },
    {
      label: 'Recolección de información',
      value: 'gi',
    },
  ];

  const LIST_OF_CHANNELS_FOR_SEND_MESSAGES = [
    {
      label: 'WhatsApp',
      value: 'ws',
    },
    {
      label: 'SMS',
      value: 'sms',
    },
  ];

  const SEND_MESSAGE_CHANNEL_OPTIONS = [
    {
      label: 'Mensaje de texto',
      value: 'sms',
    },
    {
      label: 'Whatsapp',
      value: 'ws',
    },
  ];

  const LIST_OF_OPTIONS_TO_DESTINATIONS = [
    { label: 'Comunidades', value: 'comunidad' },
    { label: 'Lista de contactos', value: 'lista' },
  ];

  const LIST_OF_TYPE_OF_COLLECTION = [
    { label: 'Alfanumérica', value: 'a' },
    { label: 'Multimedia', value: 'm' },
  ];

  const LIST_OF_TYPE_OF_INFORMATION_COLLECTION = [
    { label: 'Cualitativa', value: 'ql' },
    { label: 'Cuantitativa', value: 'qn' },
  ];

  const LIST_OF_OPTIONS_HAS_WHATSAPP = [
    {
      label: 'Sí',
      value: 1,
    },
    {
      label: 'No',
      value: 0,
    },
  ];

  const OPTIONS_MODALITY = [
    {
      label: 'Presencial',
      value: 'f',
    },
    {
      label: 'Remota',
      value: 'r',
    },
  ];

  const MOMENT_VALUES = [
    {
      label: 'Antes de la intervención',
      value: 'b',
    },
    {
      label: 'Durante la intervención',
      value: 'd',
    },
    {
      label: 'Después de la intervención',
      value: 'a',
    },
  ];

  const OPTIOS_LOGISTICS_MEANS_TYPES = [
    {
      label: 'Tecnológico',
      value: 't',
    },
    {
      label: 'De oficina y papeleria',
      value: 'o',
    },
    {
      label: 'Otro',
      value: 'a',
    },
  ];

  const COLORS_BADGE_STATUS_MESSAGE = {
    p: 'warning',
    r: 'error',
    s: 'success',
    ip: 'primary',
  };

  const DATA_CHART_COLORS = [
    '#64458E',
    '#91D0D0',
    '#2E4361',
    '#9e1d75',
    '#041e42',
    '#E6EAF0',
    '#2C2D2E',
    '#b2a2c7',
    '#304545',
    '#350a27',
  ];

  const CONVOCATION_METHODS = {
    p: 'Impresos',
    o: 'Objetos',
    c: 'Celular',
  };

  const OPTIONS_ROLES_WITH_LOGIN = [
    {
      label: 'Administrador',
      value: 'ad',
    },
    {
      label: 'Artista',
      value: 'ar',
    },
    {
      label: 'Socio Ejecutor',
      value: 'ep',
    },
  ];

  const OPTIONS_ROLES_WITHOUT_LOGIN = [
    {
      value: 'co',
      label: 'Comunidad',
    },
    {
      value: 'ca',
      label: 'Agente de cambio',
    },
  ];

  return {
    ONE_DAY_IN_MILLISECONDS,
    MONTHS_OF_THE_YEAR,
    MESSAGES_LIST_STATUS,
    LIST_OF_TYPES_OF_MESSAGES,
    LIST_OF_CHANNELS_FOR_SEND_MESSAGES,
    SEND_MESSAGE_CHANNEL_OPTIONS,
    LIST_OF_OPTIONS_TO_DESTINATIONS,
    LIST_OF_OPTIONS_HAS_WHATSAPP,
    OPTIONS_MODALITY,
    MOMENT_VALUES,
    OPTIOS_LOGISTICS_MEANS_TYPES,
    LIST_OF_TYPE_OF_COLLECTION,
    LIST_OF_TYPE_OF_INFORMATION_COLLECTION,
    COLORS_BADGE_STATUS_MESSAGE,
    DATA_CHART_COLORS,
    CONVOCATION_METHODS,
    OPTIONS_ROLES_WITH_LOGIN,
    OPTIONS_ROLES_WITHOUT_LOGIN,
  };
};

export default useConstants;
