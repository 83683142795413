import useAdminViewInterventionCommunicationProviders from './communication';
import useAdminViewInterventionDevelopmentInterventionProviders from './developmentIntervention';

const useAdminViewInterventionProviders = () => {
  return {
    useAdminViewInterventionCommunicationProviders,
    useAdminViewInterventionDevelopmentInterventionProviders,
  };
};

export default useAdminViewInterventionProviders;
