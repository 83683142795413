import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const StyledTopButtonsFilters = styled.div.attrs(() => ({
  className: 'StyledTopButtonsFilters',
}))`
  ${tw` flex justify-between items-center`}
`;

//DropDown
export const StyledDropDownFilter = styled.div.attrs(() => ({
  className: 'StyledDropDownFilter',
}))`
  ${tw` absolute top-16 right-0 bg-white shadow-md py-6 px-8 w-full z-max rounded-xl transition-all lg:w-screen max-w-3xl`}

  ${(props) =>
    props.open
      ? props.notification
        ? tw` fixed lg:absolute pointer-events-auto`
        : tw` opacity-100 pointer-events-auto`
      : props.notification
      ? tw`opacity-0 lg:opacity-0 pointer-events-none`
      : tw`opacity-0 lg:opacity-0 pointer-events-auto lg:pointer-events-none`}
`;

export const StyledWrapperFilter = styled.div.attrs(() => ({
  className: 'StyledWrapperFilter',
}))`
  ${tw`grid grid-cols-2 gap-4 mb-2`}
`;

export const StyledWarpperButtonFilters = styled.div.attrs(() => ({
  className: 'StyledWarpperButtonFilters',
}))`
  ${tw` flex justify-between`}
  button.shadow-none {
    ${tw` shadow-none`}
  }
`;
