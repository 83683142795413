//Hooks
import useHelpers from '../../../helpers';
import useStrings from '../../../strings';

const useGetDate = () => {
  const { useQuickFunctions } = useHelpers();
  const { formatDayWithTwoDigits, getYearMonthDayFromDate } =
    useQuickFunctions();

  const { useConstants } = useStrings();
  const { ONE_DAY_IN_MILLISECONDS, MONTHS_OF_THE_YEAR } = useConstants();

  const date_now = new Date();

  const getDate = (date) => {
    const date_aft = new Date(date);
    if (
      date_now.getDate() === date_aft.getDate() &&
      date_now.getMonth() === date_aft.getMonth() &&
      date_now.getFullYear() === date_aft.getFullYear() &&
      date_now.getHours() === date_aft.getHours() &&
      date_now.getMinutes() === date_aft.getMinutes()
    ) {
      return 'ahora';
    }
    if (
      date_now.getDate() === date_aft.getDate() &&
      date_now.getMonth() === date_aft.getMonth() &&
      date_now.getFullYear() === date_aft.getFullYear()
    ) {
      return `${date_aft.getHours()}:${formatDayWithTwoDigits({
        day: date_aft.getMinutes(),
      })}`;
    }
    if (
      date_now.getDate() - 1 == date_aft.getDate() &&
      date_now.getMonth() === date_aft.getMonth() &&
      date_now.getFullYear() === date_aft.getFullYear()
    ) {
      return 'ayer';
    }
    return `${MONTHS_OF_THE_YEAR[date_aft.getMonth()]} ${date_aft.getDate()}`;
  };

  const setTextExpiredDate = ({ expiredDate, format = 'dmy' }) => {
    const currentDateInMilliseconds = date_now.getTime();
    const expiredDateInMilliseconds = Date.parse(expiredDate);

    const expiredDays =
      (expiredDateInMilliseconds - currentDateInMilliseconds) /
      ONE_DAY_IN_MILLISECONDS;
    const isExpired = expiredDays < 0;

    if (isExpired) {
      const expiredDaysAux = Math.abs(expiredDays);
      if (expiredDaysAux > 0 && expiredDaysAux < 1) {
        return {
          isExpired,
          text: 'hace 1 día',
        };
      } else {
        const expiredDaysAux = Math.abs(Math.floor(expiredDays));
        return {
          isExpired,
          text: `hace ${expiredDaysAux} ${
            expiredDaysAux === 1 ? 'día' : 'días'
          }`,
        };
      }
    } else {
      return {
        isExpired,
        text: getYearMonthDayFromDate({
          dateInString: expiredDate,
          format
        }),
      };
    }
  };

  const getMonthAndDay = ({ date }) => {
    const dateYear = new Date(date).getUTCFullYear();
    const currentYear = new Date().getFullYear();

    const monthOfDate = new Date(date).getUTCMonth();
    const dayOfDate = new Date(date).getUTCDate();

    return dateYear < currentYear
      ? getYearMonthDayFromDate({ dateInString: date })
      : `${dayOfDate} de ${MONTHS_OF_THE_YEAR[monthOfDate]?.toLowerCase()}`;
  };

  return { getDate, setTextExpiredDate, getMonthAndDay };
};
export default useGetDate;
