//Packages
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';

//Hooks
import useStrings from 'strings';
import useHelpers from 'helpers';
import useApi from 'api';
import useModels from 'models';
import useControllers from 'controllers';

const useAdminSocioCulturalInfoDashboard = () => {
  const { project_id: projectId } = useParams();
  const history = useHistory();

  const [show, setShow] = useState(false);
  const [socioCulturalInfoId, setSocioCulturalInfoId] = useState(null);
  const [socialDynamicData, setSocialDynamicData] = useState(null);
  const [socialViewDynamicData, setSocialViewDynamicData] = useState(null);
  const [cleanFileInputs, setCleanFileInputs] = useState(false);

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { REQUIRED_FIELD, FILE_TYPE, FILE_SIZE, MAX_LENGTH } = useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const { getFileValidations, getMimeTypeFromMedia } = useQuickFunctions();
  const { MAX_SIZE, SUPPORTED_FORMATS } = getFileValidations();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminSocioCulturalInfoActions } = useAdminProjectsActions();
  const {
    actGetSocioCulturalInfo,
    actCreateSocioCulturalInfo,
    actDeleteSocioCulturalInfo,
    actUpdateSocioCulturalInfo,
  } = useAdminSocioCulturalInfoActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminSocioCulturalInfoSelectors } = useAdminProjectsSelectors();
  const { listSocioCulturalInfoByProjectSelector } =
    useAdminSocioCulturalInfoSelectors();
  const listSocioCulturalInfo = useSelector(
    listSocioCulturalInfoByProjectSelector
  );

  const { useGeneralHooks } = useControllers();
  const { useToggleModal } = useGeneralHooks();
  const [showDeleteSocialDynamicModal, handleDeleteSocialDynamicModal] =
    useToggleModal();
  const [showViewSocialDynamicModal, handleViewSocialDynamicModal] =
    useToggleModal();

  const schemaCreateCommunity = yup.object({
    name: yup.string().required(REQUIRED_FIELD),
    description: yup.string().required(REQUIRED_FIELD).max(40000, MAX_LENGTH),
    main: yup
      .mixed()
      .test('fileType', FILE_TYPE, (value) => {
        if (value) {
          return SUPPORTED_FORMATS.includes(value.type);
        }
        return true;
      })
      .test('fileSize', FILE_SIZE, (value) => {
        if (value) {
          return value.size <= MAX_SIZE;
        }
        return true;
      })
      .nullable()
      .notRequired(),
    first: yup
      .mixed()

      .test('fileType', FILE_TYPE, (value) => {
        if (value) {
          return SUPPORTED_FORMATS.includes(value.type);
        }
        return true;
      })
      .test('fileSize', FILE_SIZE, (value) => {
        if (value) {
          return value.size <= MAX_SIZE;
        }
        return true;
      })
      .nullable()
      .notRequired(),
    second: yup
      .mixed()
      .test('fileType', FILE_TYPE, (value) => {
        if (value) {
          return SUPPORTED_FORMATS.includes(value.type);
        }
        return true;
      })
      .test('fileSize', FILE_SIZE, (value) => {
        if (value) {
          return value.size <= MAX_SIZE;
        }
        return true;
      })
      .nullable()
      .notRequired(),
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schemaCreateCommunity),
    mode: 'onChange',
  });

  useEffect(() => {
    dispatch(actGetSocioCulturalInfo({ projectId }));
  }, []);

  const onSuccessCreateCommunityDynamic = () => {
    setValue('name', '');
    setValue('description', '');
    setCleanFileInputs((prevState) => !prevState);
    setSocialDynamicData(null);
    setShow((prevState) => !prevState);
  };

  const handleRegisterSocialDynamic = (data) => {
    dispatch(
      actCreateSocioCulturalInfo(
        { ...data, projectId },
        onSuccessCreateCommunityDynamic
      )
    );
  };

  const handleUpdateSocialDynamic = (data) => {
    dispatch(
      actUpdateSocioCulturalInfo(
        { ...data, socioCulturalId: socioCulturalInfoId, projectId },
        onSuccessCreateCommunityDynamic
      )
    );
  };

  const handleSelectDefaultImage = (typeImage) => {
    const defaultImage = _.find(
      socialDynamicData?.multimedias,
      (multimedia) => multimedia.type === typeImage
    );

    return defaultImage?.url ?? '';
  };

  const handleviewSocialDynamicInfo = (culturalInfoId) => {
    setSocialViewDynamicData(culturalInfoId);
  };

  const handleSelectTypeFile = (typeMultimedia) => {
    const defaultImage = _.find(
      socialDynamicData?.multimedias,
      (multimedia) => multimedia.type === typeMultimedia
    );

    return getMimeTypeFromMedia({ urlFile: defaultImage?.url }) || 'image';
  };

  const handleAddSocialDynamic = () => {
    setValue('name', '');
    setValue('description', '');
    setCleanFileInputs((prevState) => !prevState);
    setSocialDynamicData(null);
    setShow((prevState) => !prevState);
  };

  const handleEditSocioCulturalInfo = ({ socioCulturalInfo }) => {
    setValue('name', socioCulturalInfo.name);
    setValue('description', socioCulturalInfo.description);
    setSocialDynamicData(socioCulturalInfo);
    setShow(true);
    setCleanFileInputs((prevState) => !prevState);
  };

  const handleCancelSocialDynamic = (e) => {
    e.preventDefault();
    reset({});
    setSocialDynamicData(null);
    setCleanFileInputs((prevState) => !prevState);
    setShow((prevState) => !prevState);
  };

  const handleResetInputFile = ({ typeMultimedia }) => {
    setValue(typeMultimedia, '');
    clearErrors(typeMultimedia);
  };

  const handleDeleteSocialDynamic = () => {
    dispatch(
      actDeleteSocioCulturalInfo(
        { socioCulturalId: socioCulturalInfoId, projectId },
        () => {
          handleDeleteSocialDynamicModal();
        }
      )
    );
  };

  const handleGoBack = () =>
    history.push(`/admin/projects/dashboard/${projectId}`);

  return {
    control,
    errors,
    register,
    handleSubmit,

    handleRegisterSocialDynamic,
    handleResetInputFile,
    handleAddSocialDynamic,
    handleCancelSocialDynamic,
    show,
    cleanFileInputs,

    socialDynamicData,
    handleEditSocioCulturalInfo,
    handleUpdateSocialDynamic,
    handleSelectDefaultImage,

    showDeleteSocialDynamicModal,
    handleDeleteSocialDynamicModal,
    setSocioCulturalInfoId,

    showViewSocialDynamicModal,
    handleViewSocialDynamicModal,

    listSocioCulturalInfo,
    handleDeleteSocialDynamic,
    handleSelectTypeFile,
    handleGoBack,

    handleviewSocialDynamicInfo,
    socialViewDynamicData,
  };
};

export default useAdminSocioCulturalInfoDashboard;
