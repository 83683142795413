import useAuthInitialStates from './auth';
import useGeneralInitialStates from './general';
import useUserInitialStates from './user';
import useTestInitialStates from './test';
import useAdminInitialStates from './admin';
import useContactsInitialState from './contacts';
import useDashboardInitialStates from './dashboard';

const useInitialStates = () => {
  return {
    useAuthInitialStates,
    useUserInitialStates,
    useGeneralInitialStates,
    useTestInitialStates,
    useAdminInitialStates,
    useContactsInitialState,
    useDashboardInitialStates,
  };
};

export default useInitialStates;
