import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

//Hooks
import useViews from 'views';
import useHelpers from 'helpers';
import useStrings from 'strings';

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import IconAlarm from 'assets/img/alarm.svg';
import MapPin from 'assets/icons/MapPin.svg';

// Styles
import { StyledGroup } from 'styles/formViews/formViews.styles';

const FormPlanActivity = (props) => {
  const {
    activity,
    disabledForm,
    handleConfirmActivity,
    handleRejectActivity,
    isAdmin,
    deepenType,
  } = props;

  const {
    date,
    duration,
    place,
    community_place,
    modality_type,
    description,
    resources,
    community_support,
    state,
  } = activity;

  const { useComponents } = useViews();
  const { useInputs, useButtons, useTypographies } = useComponents();
  const { InputForm, Select } = useInputs();
  const { ButtonComponent } = useButtons();
  const { TypographyComponent } = useTypographies();

  const { useQuickFunctions } = useHelpers();
  const { setDefaultValueInputDateTimeWithTimeLocalZone } = useQuickFunctions();

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { REQUIRED_FIELD, MAX_LENGTH_100 } = useFormsTypes();

  const schemaActivity = yup.object({
    place:
      community_place?.id || deepenType === 'sync' || modality_type?.id === 'r'
        ? yup.string().max(100, MAX_LENGTH_100).required(REQUIRED_FIELD)
        : yup.string().nullable().notRequired(),
  });

  const {
    control,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schemaActivity),
    mode: 'onChange',
  });

  useEffect(() => {
    date &&
      reset({
        date: setDefaultValueInputDateTimeWithTimeLocalZone({
          dateInUTC: date,
        }).split('.')[0],
        place,
        duration,
        modality_type: {
          label: modality_type?.name ?? '',
          value: modality_type?.id ?? '',
        },
      });
  }, []);

  return (
    <>
      <StyledGroup>
        {modality_type && (
          <Select
            name="modality_type"
            id="modality_type"
            placeholder="Tipo de profundización*"
            control={control}
            options={[
              {
                label: 'Presencial',
                value: 'f',
              },
              {
                label: 'Remota',
                value: 'r',
              },
            ]}
            onChange={() => {}}
            errors={errors}
            label="Modalidad de la actividad*"
            disabled={disabledForm}
          />
        )}
      </StyledGroup>
      <div className="flex items-center space-x-4">
        <InputForm
          name="date"
          label="Fecha y hora*"
          type="datetime-local"
          placeholder="DD/MM/AAAA"
          className="w-full col-span-2"
          control={control}
          errors={errors}
          disabled={disabledForm}
        />
        <InputForm
          name="duration"
          label="Duración*"
          type="text"
          icon={IconAlarm}
          placeholder="Selecciona la duración"
          className="w-full"
          control={control}
          errors={errors}
          disabled
        />
      </div>

      <StyledGroup col2 flex className="mb-4 flex-col">
        {community_place?.id && (
          <Select
            name="community_place"
            id="community_place"
            placeholder="Comunidad donde se realizará la actividad"
            control={control}
            defaultValue={{
              label: community_place.name,
              value: community_place.id,
            }}
            label="Comunidad donde se realizará la actividad*"
            disabled={disabledForm}
          />
        )}
        {(community_place?.id ||
          deepenType === 'sync' ||
          modality_type?.id === 'r') &&
          (disabledForm ? isAdmin : true) &&
          state.id !== 'r' && (
            <InputForm
              name="place"
              id="place"
              placeholder={
                modality_type?.id === 'r'
                  ? 'Ingresa la plataforma'
                  : 'Ingresa un lugar'
              }
              control={control}
              icon={MapPin}
              label={modality_type?.id === 'r' ? 'Plataforma*' : 'Lugar*'}
              errors={errors}
              disabled={state.id === 'c'}
            />
          )}
      </StyledGroup>

      {state.id === 'p' && isAdmin && (
        <div className="flex items-center space-x-2 mt-10 justify-end">
          <ButtonComponent
            text="Rechazar"
            mode="outline"
            onPress={(e) => handleRejectActivity({ e, activity })}
          />
          <ButtonComponent
            text="Confirmar actividad"
            onPress={(e) =>
              handleConfirmActivity({ e, activity, place: getValues().place })
            }
            disabled={!isValid}
          />
        </div>
      )}

      <Accordion allowZeroExpanded>
        <AccordionItem>
          <div className="flex items-center">
            <TypographyComponent
              variant="p"
              text="Detalles de la actividad"
              className="text-primary font-bold"
            />

            <AccordionItemState>
              {({ expanded }) => (
                <AccordionItemButton>
                  <div
                    className={`icon-arrow-down text-primary text-xxs ml-2 ${
                      expanded && 'transform rotate-180'
                    }`}
                  />
                </AccordionItemButton>
              )}
            </AccordionItemState>
          </div>

          <AccordionItemPanel>
            <div>
              <label className="font-bold text-primary">
                Descripción de la actividad:
              </label>{' '}
              <span>{description}</span>
            </div>

            <div>
              <label className="font-bold text-primary">
                Recursos requeridos:
              </label>{' '}
              <span>{resources}</span>
            </div>

            <div>
              <label className="font-bold text-primary">
                ¿Necesito el apoyo de una o más personas de la comunidad?
              </label>{' '}
              <span>{community_support}</span>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};

FormPlanActivity.propTypes = {
  activity: PropTypes.object.isRequired,
  disabledForm: PropTypes.bool,
  isAdmin: PropTypes.bool,
  deepenType: PropTypes.string,
  handleConfirmActivity: PropTypes.func,
  handleRejectActivity: PropTypes.func,
};

export default FormPlanActivity;
