//Packages
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

//Hooks
import useModels from '../../../../models';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useControllers from '../../../index';
import useApi from '../../../../api';

const useFilterHome = () => {
  const [search, setSearch] = useState(undefined);
  const optionsObjectives = useMemo(
    () => [
      {
        label: 'Activar',
        value: 'activate',
      },
      {
        label: 'Inspirar',
        value: 'inspire',
      },
      {
        label: 'Sostener',
        value: 'hold',
      },
    ],
    []
  );
  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminProjectsRootSelectors } = useAdminProjectsSelectors();
  const { projectsListSelector } = useAdminProjectsRootSelectors();
  const { projectsList: optionsProjects } = useSelector(projectsListSelector);
  const schemaFilterHome = yup.object({
    country_id: yup.object().nullable(),
    department_id: yup.object().nullable(),
    project: yup.object().nullable(),
    objective: yup.object().nullable(),
    type: yup.string().required(),
  });
  const { control, watch, setValue, handleSubmit, getValues, register } =
    useForm({
      mode: 'onChange',
      resolver: yupResolver(schemaFilterHome),
    });
  const countrySelected = watch('country_id');
  const departmentSelected = watch('department_id');

  const { useGeneralHooks } = useControllers();
  const { useWhereLive } = useGeneralHooks();
  const { optionsCountries, optionsDepartments } = useWhereLive({
    countrySelected,
    departmentSelected,
  });

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useHomeActions } = useAdminActions();
  const { actGetHomeInterventions, actGetProjectsHome } = useHomeActions();

  const handleResetFilter = () => {
    setValue('country_id', '');
    setValue('department_id', '');
    setValue('project', '');
    setValue('objective', '');
    setValue('type', '');
    dispatch(actGetHomeInterventions({}));
  };
  useEffect(() => {
    dispatch(actGetProjectsHome({ paginate: 0, page: 0 }));
  }, []);
  const onChangeFilter = (type, text) => {
    const { country_id, department_id, project, objective } = getValues();
    if (text || text === '') {
      setSearch(text);
    }
    dispatch(
      actGetHomeInterventions({
        country_id: country_id?.value,
        department_id: department_id?.value,
        project_id: project?.value,
        objective: objective?.value,
        type,
        name: text || text === '' ? text : search,
      })
    );
  };
  return {
    optionsCountries,
    optionsDepartments,
    control,
    register,
    countrySelected,
    departmentSelected,
    handleResetFilter,
    optionsProjects,
    handleSubmit,
    onChangeFilter,
    optionsObjectives,
  };
};

export default useFilterHome;
