//Packages
import React from 'react';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
import {
  StyledWrapperInputFile,
  StyledInput,
  StyledTitleInput,
} from './InputFile.styles';

const InputFile = (props) => {
  const {
    title,
    className,
    errors,
    name,
    onChange,
    cleanFileInputs,
    cleanFileOnSave,
    accept,
    requiredName,
    disabled,
  } = props;

  const { useComponents } = useViews();
  const { useTypographies } = useComponents();
  const { TypographyComponent } = useTypographies();

  const { useComponentHooks } = useControllers();
  const { useInputs } = useComponentHooks();
  const { useInputFile } = useInputs();
  const { documentName, handleInputChange, inputRef } = useInputFile({
    onChange,
    cleanFileInputs,
    cleanFileOnSave,
  });

  return (
    <>
      <StyledWrapperInputFile className={className}>
        <StyledInput
          onChange={handleInputChange}
          type="file"
          ref={inputRef}
          accept={accept}
          disabled={disabled}
        />
        {requiredName ? (
          <StyledTitleInput>{requiredName}</StyledTitleInput>
        ) : (
          <StyledTitleInput>
            {documentName === '' ? title : documentName}
          </StyledTitleInput>
        )}
      </StyledWrapperInputFile>
      {errors[name] && (
        <TypographyComponent
          variant="h5"
          className="text-sm mt-2 text-error w-[200px]"
          text={errors[name].message}
        />
      )}
    </>
  );
};

InputFile.propTypes = {
  title: PropTypes.node,
  className: PropTypes.string,
  errors: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func,
  cleanFileInputs: PropTypes.bool,
  cleanFileOnSave: PropTypes.bool,
  accept: PropTypes.string,
  requiredName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
};

InputFile.defaultProps = {
  title: (
    <div className="flex">
      <div className="icon-close transform rotate-45 mr-3 text-sm"></div>Agregar
      archivo
    </div>
  ),
  errors: {},
  name: '',
  accept: '*',
  onChange: () => {},
  cleanFileInputs: false,
  cleanFileOnSave: false,
  requiredName: null,
  disabled: false,
};

export default InputFile;
