import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useLogoutProviders = () => {
  const logout = () => {
    const response = axios({
      method: 'POST',
      url: '/auth/logout',
    });

    return trackPromise(response);
  };

  return { logout };
};

export default useLogoutProviders;
