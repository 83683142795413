import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useColorsProviders = () => {
  const getColorsProvider = () => {
    const response = axios({
      method: 'GET',
      url: '/colors',
    });
    return trackPromise(response);
  };

  return { getColorsProvider };
};

export default useColorsProviders;
