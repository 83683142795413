import React from 'react';

import Image from './Image';
import UploadImagePreview from './UploadImagePreview';

const UploadImage = React.lazy(() => import('./UploadImage'));
const ImageCropper = React.lazy(() => import('./ImageCropper'));

const useImages = () => {
  return { Image, UploadImage, ImageCropper, UploadImagePreview };
};
export default useImages;
