//Packages
import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Components
import DropDownFilter from './components/DropDownFilter/DropDownFilter';

//Styles
const StyledTopFilters = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledTopFilters,
  }))
);

const StyledFilters = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledFilters,
  }))
);

const StyledContentTable = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentTable,
  }))
);

const StyledTableTitle = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledTableTitle,
  }))
);

//Assets
import arrowDown from 'assets/icons/arrow-down-table.svg';

const List = (props) => {
  const {
    setActiveItem,
    dataTableHeaderAdminRequestMessagesArtistRef,
    messagesList,
    dataForPagination,
    handleChangePage,
    handleDataToOrder,
    activeDropDown,
    handleOpenMenu,
    orderBy,
    formFilterMessages,
    optionsCommunities,
    handleResetFilter,
    handleFilter,
    handleGetDetailMessages,
  } = props;
  const { useComponents } = useViews();

  const {
    useButtons,
    useTypographies,
    useImages,
    useTables,
    usePagers,
    useBadges,
    useCards,
  } = useComponents();

  const { CardEmptyState } = useCards();
  const { ButtonComponent } = useButtons();
  const { Table, TableCell, TableHead, TableBody, TableRow, TableHeadCell } =
    useTables();
  const { TypographyComponent } = useTypographies();
  const { Image } = useImages();
  const { Pager } = usePagers();
  const { DefaultBadge } = useBadges();

  const { useGeneralHooks } = useControllers();
  const { useIsFilterEmpty } = useGeneralHooks();
  const { isFilterEmpty } = useIsFilterEmpty();

  // const requests = [
  //   {
  //     type: 'Mensaje a la comunidad',
  //     canal: 'Canal de comunicación',
  //     dest: 'Comunidad de brisas del mar...',
  //     requestDate: '18 de Febrero',
  //     state: 'pending',
  //     unread: true,
  //   },
  //   {
  //     type: 'Mensaje a la comunidad',
  //     canal: 'Canal de comunicación',
  //     dest: 'Comunidad de brisas del mar...',
  //     requestDate: '18 de Febrero',
  //     state: 'in_procces',
  //     unread: false,
  //   },
  //   {
  //     type: 'Mensaje a la comunidad',
  //     canal: 'Canal de comunicación',
  //     dest: 'Comunidad de brisas del mar...',
  //     requestDate: '18 de Febrero',
  //     state: 'deny',
  //     unread: false,
  //   },
  // ];

  return (
    <>
      {!(!isFilterEmpty && messagesList?.length === 0) && (
        <StyledTopFilters justifyEnd>
          <StyledFilters className="relative">
            <ButtonComponent
              text="Filtros"
              mode="outline"
              className="icon-filter flex items-center space-x-2"
              onPress={handleOpenMenu}
            />

            <DropDownFilter
              open={activeDropDown}
              formFilterMessages={formFilterMessages}
              optionsCommunities={optionsCommunities}
              handleResetFilter={handleResetFilter}
              handleFilter={handleFilter}
            />
          </StyledFilters>
        </StyledTopFilters>
      )}

      <StyledContentTable minHeight="32">
        <Table className="w-full">
          <TableHead>
            <TableRow>
              {_.map(
                dataTableHeaderAdminRequestMessagesArtistRef.current,
                (item, idx) => (
                  <TableHeadCell
                    className={`text-left py-4 px-2 ${
                      !item.noneOrder && messagesList.length
                        ? 'cursor-pointer'
                        : 'cursor-not-allowed'
                    }`}
                    key={`headList-${idx}`}
                    onClick={() => {
                      item.orderByKey &&
                        messagesList.length &&
                        handleDataToOrder({ orderByKey: item.orderByKey });
                    }}
                  >
                    {item.header !== '' && (
                      <StyledTableTitle>
                        <TypographyComponent
                          text={item.header}
                          variant="small"
                        />
                        {!item.noneOrder && (
                          <Image
                            srcImage={arrowDown}
                            className={`ml-2 ${
                              !orderBy[item.orderByKey] &&
                              messagesList.length &&
                              'order-arrow cursor-pointer'
                            }`}
                          />
                        )}
                      </StyledTableTitle>
                    )}
                  </TableHeadCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody className="relative">
            <>
              {messagesList.length > 0 &&
                _.map(messagesList, (request, idx) => (
                  <TableRow
                    key={`dataColumn-${idx}`}
                    unread={request.unread}
                    className="cursor-pointer"
                    onClick={() => {
                      handleGetDetailMessages(
                        {
                          messageId: request.id,
                          isCollectionMessage: request.typeMessage === 'gi',
                        },
                        () => {
                          setActiveItem((prevValue) => ({
                            ...prevValue,
                            active: 2,
                          }));
                        }
                      );
                    }}
                  >
                    <TableCell className="py-2 pl-2">
                      <p>{request.type}</p>
                    </TableCell>
                    <TableCell className="py-2">
                      <p>{request.channel}</p>
                    </TableCell>
                    <TableCell className="py-2">
                      <p className="truncate 2xl:w-96 xl:w-60">{request.to}</p>
                    </TableCell>
                    <TableCell className="py-2">{request.date}</TableCell>
                    <TableCell className="py-2">
                      <DefaultBadge
                        label={request.state.name}
                        type="light"
                        className={`bg-${request.colorBadgeStatusMessage}-50`}
                        variant={request.colorBadgeStatusMessage}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </>
          </TableBody>
        </Table>
        {messagesList.length === 0 && (
          <CardEmptyState
            className="w-8/12 mx-auto mt-16"
            title={
              isFilterEmpty
                ? 'No hay resultados, utiliza nuevos criterios de búsqueda'
                : 'No se han encontrado solicitudes del Artista'
            }
            body="Por el momento no tienes ninguna solicitud. Si estas a la espera de un mensaje, asegurate de confirmar con el artista de que no tenga este mensaje en sus borradores."
          />
        )}
        <Pager
          pageCount={Math.ceil(
            dataForPagination?.total / dataForPagination?.per_page
          )}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          previousLabel={Boolean(dataForPagination?.prev)}
          nextLabel={Boolean(dataForPagination?.next)}
          className="flex items-center lg:justify-end justify-center my-6"
          onPageChange={({ selected }) => handleChangePage(selected)}
          forcePage={dataForPagination.current_page - 1}
        />
      </StyledContentTable>
    </>
  );
};

List.propTypes = {
  setActiveItem: PropTypes.func.isRequired,
  dataTableHeaderAdminRequestMessagesArtistRef: PropTypes.object,
  messagesList: PropTypes.array,
  dataForPagination: PropTypes.object,
  handleChangePage: PropTypes.func,
  handleDataToOrder: PropTypes.func,
  activeDropDown: PropTypes.bool,
  handleOpenMenu: PropTypes.func,
  orderBy: PropTypes.object,
  formFilterMessages: PropTypes.object,
  handleResetFilter: PropTypes.func,
  handleFilter: PropTypes.func,
  optionsCommunities: PropTypes.array,
  handleGetDetailMessages: PropTypes.func,
};

List.defaultProps = {
  dataTableHeaderAdminRequestMessagesArtistRef: {},
  messagesList: [],
  dataForPagination: {},
  handleChangePage: () => {},
  handleDataToOrder: () => {},
  activeDropDown: false,
  handleOpenMenu: () => {},
  orderBy: {},
  formFilterMessages: {},
  handleResetFilter: () => {},
  handleFilter: () => {},
  optionsCommunities: [],
  handleGetDetailMessages: () => {},
};

export default List;
