import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
//Hooks
import useViews from 'views';
import useHelpers from '../../../../../../../../../../../../../helpers';

const FormActivity = (props) => {
  const { activities } = props;
  const { useComponents } = useViews();
  const { useTypographies } = useComponents();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();

  const { useQuickFunctions } = useHelpers();
  const { getYearMonthDayFromDate } = useQuickFunctions();

  return (
    <>
      <TypographyWithTooltip
        text="Actividades"
        variant="h3"
        textTooltip="Actividades "
        className="text-primary"
        idTooltip="actvities"
        applyFontBold
      />

      <div className="space-y-4 mt-4">
        {_.map(activities, (activity, idx) => (
          <div className="border bg-neutral-400 px-8 py-6 rounded-lg" key={idx}>
            <TypographyComponent
              text={activity.name}
              variant="h3"
              className="text-primary font-bold mb-8"
            />
            <TypographyComponent
              text={
                <>
                  <label className="text-primary">
                    <strong>Fecha:</strong>
                    <span className="text-neutral-950 ml-1 ">
                      {`${getYearMonthDayFromDate({
                        dateInString: activity.date,
                        format: 'dmyText',
                      })}`.toLowerCase()}
                    </span>
                  </label>
                </>
              }
              variant="p"
              className="text-neutral-950 mt-2 mb-1"
            />
            <TypographyComponent
              text={
                <>
                  <label className="text-primary">
                    <strong>Hora:</strong>
                    <span className="text-neutral-950 ml-1">
                      {' '}
                      {getYearMonthDayFromDate({
                        dateInString: activity.date,
                        format: 'time',
                      })}
                    </span>
                  </label>
                </>
              }
              variant="p"
              className="text-neutral-950 mt-2 mb-1"
            />
            <TypographyComponent
              text={
                <>
                  <label className="text-primary">
                    <strong>Duracion:</strong>
                    <span className="text-neutral-950 ml-1">
                      {' '}
                      {activity.duration} horas
                    </span>
                  </label>
                </>
              }
              variant="p"
              className="text-neutral-950 mt-2 mb-1"
            />
            {activity.modality_type?.id !== 'r' && (
              <TypographyComponent
                text={
                  <>
                    <label className="text-primary">
                      <strong>Lugar:</strong>
                      <span className="text-neutral-950 ml-1">
                        {activity.place}
                      </span>
                    </label>
                  </>
                }
                variant="p"
                className="text-neutral-950 mt-2 mb-1"
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
};

FormActivity.propTypes = {
  activities: PropTypes.array.isRequired,
};

export default FormActivity;
