import useAdminAbcTrajectoryActions from './abcTrajectory';
import useAdminAdditionalInfoActions from './additionalInfo';
import useAdminCommunitiesActions from './communities';
import useAdminDocumentationActions from './documentation';
import useAdminFestivitiesAndTraditionsActions from './festivitiesAndTraditions';
import useAdminProjectsRootActions from './projectsRoot';
import useAdminRecommendationsActions from './recommendations';
import useAdminSocioCulturalInfoActions from './socioCulturalInfo';
import useAdminInterventionsActions from './interventions';
const useAdminProjectsActions = () => {
  return {
    useAdminProjectsRootActions,
    useAdminCommunitiesActions,
    useAdminSocioCulturalInfoActions,
    useAdminFestivitiesAndTraditionsActions,
    useAdminRecommendationsActions,
    useAdminAbcTrajectoryActions,
    useAdminAdditionalInfoActions,
    useAdminInterventionsActions,
    useAdminDocumentationActions,
  };
};

export default useAdminProjectsActions;
