import ContactRoot from './Root';
import EditContactScreen from './Edit';
import CreateContactScreen from './Create';

const useContactsScreen = () => {
  return {
    ContactRoot,
    EditContactScreen,
    CreateContactScreen,
  };
};
export default useContactsScreen;
