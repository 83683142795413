const useFormsTypes = () => {
  const REQUIRED_FIELD = 'Campo requerido';
  const EMAIL_NOT_VALID = 'Email no válido';
  const NUMBER_NOT_VALID = 'Número no válido';
  const URL_NOT_VALID = 'Url no válida';
  const PASSWORD_NOT_EQUAL = 'La contraseña no es igual';
  const PASSWORD_VALIDATION =
    'Minimo 8 caracteres y al menos debe contener una mayúscula, una minúscula, un número y un carácter especial';
  const MAX_LENGTH_PHONE = 'Máximo 15 dígitos';
  const MAX_10M_POPULATION_NUMBER = 'Máximo 10.000.000 de población';
  const MAX_1M_POPULATION_NUMBER = 'Máximo 1.000.000 de población';
  const ACCEPTS_ONLY_TEXT = 'Solo se permiten caracteres alfabéticos';
  const MIN_LENGTH_PHONE = 'Mínimo 8 dígitos';
  const POSITIVE_NUMBER = 'Debe ser un número positivo';
  const INTEGER_NUMBER = 'Debe ser un número entero';
  const ARRAY_MIN_1_LENGTH = 'Marcar mínimo 1 respuesta';
  const ARRAY_MAX_3_LENGTH = 'Máximo 3';
  const FILE_SIZE = 'Archivo muy pesado, debe ser menor o igual 5MB';
  const FILE_SIZE_5MB = 'Archivo muy pesado, debe ser menor o igual 5MB';
  const FILE_SIZE_10MB = 'Archivo muy pesado, debe ser menor o igual 10MB';
  const FILE_SIZE_20MB = 'Archivo muy pesado, debe ser menor o igual 20MB';
  const FILE_SIZE_30MB = 'Archivo muy pesado, debe ser menor o igual 30MB';
  const FILE_TYPE = 'Tipo de archivo no permitido';
  const MIN_DATE_EQUALS = 'La fecha final debe ser mayor o igual a la inicial';
  const END_TIME_LATER_THAN_START_TIME =
    'La hora final debe ser mayor a la inicial';

  const MAX_LENGTH = 'Máximo 40.000 caracteres';
  const MAX_LENGTH_2000 = 'Máximo 2.000 caracteres';
  const MAX_LENGTH_255 = 'Máximo 255 caracteres';
  const MAX_LENGTH_250 = 'Máximo 250 caracteres';
  const MAX_LENGTH_320 = 'Máximo 320 caracteres';
  const MAX_LENGTH_500 = 'Máximo 500 caracteres';
  const MAX_LENGTH_150 = 'Máximo 150 caracteres';
  const MAX_LENGTH_100 = 'Máximo 100 caracteres';

  const DATE_GREATER_THAN_TODAY =
    'La fecha seleccionada debe ser mayor a la fecha de hoy';
  const MIN_GOALS =
    'Debe seleccionar la opción "Agregar nuevo logro" para que su logro quede guardado';
  const MIN_ONE_IMAGE_OR_VIDEO = 'Debes cargar al menos una imagen o video';

  return {
    REQUIRED_FIELD,
    EMAIL_NOT_VALID,
    NUMBER_NOT_VALID,
    URL_NOT_VALID,
    PASSWORD_NOT_EQUAL,
    PASSWORD_VALIDATION,
    MAX_LENGTH_PHONE,
    ACCEPTS_ONLY_TEXT,
    MIN_LENGTH_PHONE,
    POSITIVE_NUMBER,
    INTEGER_NUMBER,
    ARRAY_MIN_1_LENGTH,
    FILE_SIZE,
    FILE_TYPE,
    ARRAY_MAX_3_LENGTH,
    MAX_LENGTH,
    MIN_DATE_EQUALS,
    END_TIME_LATER_THAN_START_TIME,
    MAX_LENGTH_255,
    DATE_GREATER_THAN_TODAY,
    MAX_LENGTH_250,
    MAX_LENGTH_2000,
    MAX_LENGTH_320,
    MAX_LENGTH_500,
    MAX_LENGTH_150,
    MAX_LENGTH_100,
    FILE_SIZE_5MB,
    FILE_SIZE_10MB,
    FILE_SIZE_20MB,
    FILE_SIZE_30MB,
    MAX_10M_POPULATION_NUMBER,
    MAX_1M_POPULATION_NUMBER,
    MIN_GOALS,
    MIN_ONE_IMAGE_OR_VIDEO,
  };
};

export default useFormsTypes;
