//Packages
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

//Hooks
import useControllers from 'controllers';
import useApi from 'api';
import useModels from 'models';
import useStrings from 'strings';

const useFilterUser = ({
  handleOpenMenu,
  contact = 'users',
  community_id = undefined,
  list_id = undefined,
  callEndpoints = true,
}) => {
  const { useConstants } = useStrings();
  const {
    LIST_OF_OPTIONS_HAS_WHATSAPP,
    OPTIONS_ROLES_WITHOUT_LOGIN,
    OPTIONS_ROLES_WITH_LOGIN,
  } = useConstants();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminUsersSelectors, useAdminProjectsSelectors } =
    useAdminSelectors();
  const { filterDataAdminUserSelector } = useAdminUsersSelectors();
  const { useAdminCommunitiesSelectors } = useAdminProjectsSelectors();
  const { communitiesListSelector } = useAdminCommunitiesSelectors();
  const communitiesList = useSelector(communitiesListSelector);
  const filterData = useSelector(filterDataAdminUserSelector);

  const { useGeneralHooks } = useControllers();
  const { useWhereLive, useFilter } = useGeneralHooks();

  const schemaFilterContacts = yup.object({
    roles: yup.object().nullable(),
    has_whatsapp: yup.object().nullable(),
  });

  const {
    setValue,
    dirtyFields,
    control,
    watch,
    handleSubmit,
    reset,
    transformData,
    handleCleanFilter,
  } = useFilter({
    yupSchema: yupResolver(schemaFilterContacts),
    propsForm: { defaultValues: filterData },
  });

  const countrySelected = watch('country_id');
  const departmentSelected = watch('department_id');

  const {
    optionsCountries,
    optionsCities,
    optionsDepartments,
    optionsLanguages,
  } = useWhereLive({
    countrySelected,
    departmentSelected,
    callEndpointCountries: callEndpoints,
    callEndpointLanguagues: callEndpoints,
  });

  const { useActions } = useApi();
  const { dispatch, useAdminActions, useUserActions } = useActions();
  const { actSearchUser } = useUserActions();
  const { useAdminUsersActions, useAdminProjectsActions } = useAdminActions();
  const {
    actSetFilterDataUser,
    actSaveFilterDataUser,
    actResetFilterDataUser,
  } = useAdminUsersActions();
  const { useAdminCommunitiesActions } = useAdminProjectsActions();
  const { actGetCommunities } = useAdminCommunitiesActions();

  const handleResetFilter = ({ changeStatusModalFilter = true } = {}) => {
    const propertiesToResetFilter = {
      roles: '',
      country_id: '',
      department_id: '',
      city_id: '',
      has_whatsapp: '',
      language: '',
    };

    reset(propertiesToResetFilter);

    if (community_id) {
      dispatch(actResetFilterDataUser({ community_id }));
    } else if (list_id) {
      dispatch(actResetFilterDataUser({ contact_list_id: list_id }));
    } else {
      dispatch(actResetFilterDataUser());
    }
    dispatch(
      actSearchUser(
        {
          ...filterData,
          ...propertiesToResetFilter,
        },
        contact,
        () => {
          handleOpenMenu && changeStatusModalFilter && handleOpenMenu();
        }
      )
    );

    handleCleanFilter();
  };

  useEffect(() => {
    if (dirtyFields.country_id || !countrySelected) {
      setValue('department_id', null);
      setValue('city_id', null);
    }
  }, [countrySelected]);

  useEffect(() => {
    if (dirtyFields.department_id || !departmentSelected) {
      setValue('city_id', null);
    }
  }, [departmentSelected]);

  useEffect(() => {
    dispatch(actGetCommunities());
    if (community_id) {
      dispatch(
        actSetFilterDataUser({ key: 'community_id', value: community_id })
      );
    }
    if (list_id) {
      handleResetFilter({ changeStatusModalFilter: false });
      actSetFilterDataUser({ key: 'contact_list_id', value: list_id });
    }
  }, [list_id]);

  const applyFilter = (data) => {
    const dataAux = transformData(data);
    dispatch(actSaveFilterDataUser({ data: { ...data, community_id } }));

    dispatch(
      actSearchUser(
        {
          ...filterData,
          ...dataAux,
        },
        contact,
        () => {
          handleOpenMenu && handleOpenMenu();
        }
      )
    );
  };

  return {
    //Options-constants
    optionsCountries,
    optionsCities,
    optionsDepartments,
    optionsLanguages,
    communitiesList,
    LIST_OF_OPTIONS_HAS_WHATSAPP,
    OPTIONS_ROLES_WITHOUT_LOGIN,
    OPTIONS_ROLES_WITH_LOGIN,

    //rhf
    control,
    handleSubmit,

    countrySelected,
    departmentSelected,
    handleResetFilter,
    applyFilter,
  };
};

export default useFilterUser;
