//Hooks
import useApi from '../../../..';
import useControllers from '../../../../../controllers';
import useStrings from '../../../../../strings';

const useAdminCommunitiesActions = () => {
  const { useAdminTypes } = useStrings();
  const { useAdminProjectsTypes } = useAdminTypes();
  const { useAdminCommunitiesTypes } = useAdminProjectsTypes();
  const {
    GET_COMMUNITIES,
    COMMUNITY_CREATED,
    SELECTED_COMMUNITY_DATA,
    DELETE_COMMUNITY_BY_ID,
    UPDATE_COMMUNITY,
  } = useAdminCommunitiesTypes();

  const { useGeneralHooks } = useControllers();
  const { useConsole } = useGeneralHooks();
  const { viewConsole } = useConsole();

  const { useProviders } = useApi();
  const { useAdminProviders } = useProviders();
  const { useAdminProjectsProviders } = useAdminProviders();
  const { useAdminCommunitiesProviders } = useAdminProjectsProviders();
  const {
    getCommunitiesListByProject,
    getCommunityDetailById,
    createCommunityById,
    updateCommunityById,
    deleteCommunityById,
    getCommunitiesList,
  } = useAdminCommunitiesProviders();

  const actGetCommunitiesByProject =
    ({ paginate, projectId, name } = {}, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await getCommunitiesListByProject({
          projectId,
          paginate,
          name,
        });
        dispatch({ type: GET_COMMUNITIES, payload: res.data?.data });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };
  const actGetCommunities = (onSuccess, onError) => async (dispatch) => {
    try {
      const res = await getCommunitiesList();
      dispatch({ type: GET_COMMUNITIES, payload: res.data?.data });
      onSuccess && onSuccess(res);
    } catch (error) {
      viewConsole([error], 'error');
      onError && onError(error);
    }
  };
  const actGetCommunityDetailById =
    ({ communityId, projectId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await getCommunityDetailById({ communityId, projectId });
        dispatch({ type: SELECTED_COMMUNITY_DATA, payload: res.data?.data });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actCreateCommunityByProject =
    (
      {
        name,
        general_comments,
        image,
        population_number,
        language,
        reading_level,
        has_internet,
        communication_medias,
        device_access,
        main,
        first,
        second,
        projectId,
      },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const bodyFormData = new FormData();
        bodyFormData.append('name', name);
        general_comments &&
          bodyFormData.append('general_comments', general_comments);
        image && bodyFormData.append('image', image);
        population_number &&
          bodyFormData.append('population_number', population_number);
        bodyFormData.append('language', language.label);
        bodyFormData.append('reading_level', reading_level);
        bodyFormData.append('has_internet', has_internet);
        bodyFormData.append('device_access', device_access);
        communication_medias.forEach((communication) => {
          return bodyFormData.append('communication_medias[]', communication);
        });
        main && bodyFormData.append('multimedias[main]', main);
        first && bodyFormData.append('multimedias[first]', first);
        second && bodyFormData.append('multimedias[second]', second);

        const res = await createCommunityById({
          formData: bodyFormData,
          projectId,
        });
        dispatch({ type: COMMUNITY_CREATED });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actUpdateCommunityById =
    (
      {
        name,
        general_comments,
        image,
        population_number,
        language,
        reading_level,
        has_internet,
        communication_medias,
        device_access,
        main,
        first,
        second,
        projectId,
        communityId,
      },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const bodyFormData = new FormData();
        bodyFormData.append('name', name);
        general_comments &&
          bodyFormData.append('general_comments', general_comments);
        image !== undefined && bodyFormData.append('image', image);
        bodyFormData.append('population_number', population_number);
        bodyFormData.append('language', language.label);
        bodyFormData.append('reading_level', reading_level);
        bodyFormData.append('has_internet', has_internet);
        bodyFormData.append('device_access', device_access);
        communication_medias.forEach((communication) => {
          return bodyFormData.append('communication_medias[]', communication);
        });
        main !== undefined && bodyFormData.append('multimedias[main]', main);
        first !== undefined && bodyFormData.append('multimedias[first]', first);
        second !== undefined &&
          bodyFormData.append('multimedias[second]', second);
        bodyFormData.append('_method', 'put');

        const res = await updateCommunityById({
          formData: bodyFormData,
          projectId,
          communityId,
        });
        dispatch({ type: UPDATE_COMMUNITY });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actDeleteCommunityById =
    ({ communityId, projectId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await deleteCommunityById({ communityId, projectId });
        dispatch({ type: DELETE_COMMUNITY_BY_ID, payload: { communityId } });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  return {
    actGetCommunitiesByProject,
    actGetCommunityDetailById,
    actCreateCommunityByProject,
    actUpdateCommunityById,
    actDeleteCommunityById,
    actGetCommunities,
  };
};

export default useAdminCommunitiesActions;
