import useHelpers from 'helpers';

const useIsFilterEmptySelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const isFilterEmptySelector = createSelector(
    (state) => state.isFilterEmpty,
    (isFilterEmpty) => isFilterEmpty
  );
  return { isFilterEmptySelector };
};

export default useIsFilterEmptySelectors;
