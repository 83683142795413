import useAdminDevelopmentInterventionCreateSketchActions from './createSketch';
import useAdminDevelopmentInterventionCreativePlanActions from './creativePlan';
import useAdminDevelopmentInterventionDefineInterventionActions from './defineIntervention';
import useAdminDevelopmentInterventionKnowCommunityActions from './knowCommunity';
import useAdminDevelopmentInterventionKnowProjectActions from './knowProject';
import useAdminDevelopmentExecuteInterventionActions from './executeIntervention';
import useAdminSpreadInterventionActions from './spreadIntervention';

const useAdminViewInterventionDevelopmentInterventionActions = () => {
  return {
    useAdminDevelopmentInterventionCreativePlanActions,
    useAdminDevelopmentInterventionKnowProjectActions,
    useAdminDevelopmentInterventionKnowCommunityActions,
    useAdminDevelopmentInterventionDefineInterventionActions,
    useAdminDevelopmentInterventionCreateSketchActions,
    useAdminDevelopmentExecuteInterventionActions,
    useAdminSpreadInterventionActions
  };
};

export default useAdminViewInterventionDevelopmentInterventionActions;
