//Packages
import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Components
import DropDownFilter from './components/DropDownFilter/DropDownFilter';

//Assets
import arrowDown from 'assets/icons/arrow-down-table.svg';

//Styles
const StyledTopFilters = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledTopFilters,
  }))
);

const StyledFilters = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledFilters,
  }))
);

const StyledContentTable = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentTable,
  }))
);

const StyledTableTitle = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledTableTitle,
  }))
);

const List = (props) => {
  const {
    setActiveItem,
    dataTableHeaderAdminMessagesRef,
    handleOpenMenu,
    activeDropDown,
    handleDataToOrder,
    orderBy,
    dataForPagination,
    handleChangePage,
    messagesList,
    dateExists,
    formFilterMessages,
    optionsCommunities,
    optionsContactLists,
    handleFilter,
    handleResetFilter,
    handleGetDetailMessages,
  } = props;
  const { useComponents } = useViews();

  const { useGeneralHooks } = useControllers();
  const { useIsFilterEmpty } = useGeneralHooks();
  const { isFilterEmpty } = useIsFilterEmpty();

  const {
    useButtons,
    useTypographies,
    useImages,
    useTables,
    usePagers,
    useBadges,
    useCards,
  } = useComponents();
  const { DefaultBadge } = useBadges();
  const { CardEmptyState } = useCards();

  const { ButtonComponent } = useButtons();
  const { Table, TableCell, TableHead, TableBody, TableRow, TableHeadCell } =
    useTables();
  const { TypographyComponent } = useTypographies();
  const { Image } = useImages();
  const { Pager } = usePagers();

  return (
    <>
      <StyledTopFilters>
        <ButtonComponent
          text="Crear nuevo mensaje"
          icon={<div className="icon-close transform rotate-45 text-sm" />}
          onPress={() => {
            setActiveItem((prevValue) => ({
              ...prevValue,
              active: 1,
            }));
          }}
        />
        {!(!isFilterEmpty && messagesList?.length === 0) && (
          <StyledFilters className="relative">
            <ButtonComponent
              text="Filtros"
              mode="outline"
              className="icon-filter flex items-center space-x-2"
              onPress={handleOpenMenu}
            />

            <DropDownFilter
              open={activeDropDown}
              handleOpenMenu={handleOpenMenu}
              formFilterMessages={formFilterMessages}
              optionsCommunities={optionsCommunities}
              optionsContactLists={optionsContactLists}
              handleFilter={handleFilter}
              handleResetFilter={handleResetFilter}
            />
          </StyledFilters>
        )}
      </StyledTopFilters>

      <StyledContentTable minHeight="19">
        <Table className="w-full">
          <TableHead>
            <TableRow>
              {_.map(dataTableHeaderAdminMessagesRef.current, (item, idx) => (
                <TableHeadCell
                  className={`text-left py-4 px-2 ${
                    !item.noneOrder && messagesList.length
                      ? 'cursor-pointer'
                      : 'cursor-not-allowed'
                  }`}
                  key={`headList-${idx}`}
                  onClick={() => {
                    {
                      !item.noneOrder &&
                        messagesList.length &&
                        handleDataToOrder({ orderByKey: item.orderByKey });
                    }
                  }}
                >
                  {item.header !== '' && (
                    <StyledTableTitle>
                      <TypographyComponent text={item.header} variant="small" />
                      {!item.noneOrder && (
                        <Image
                          srcImage={arrowDown}
                          className={`ml-2 ${
                            !orderBy[item.orderByKey] &&
                            messagesList.length &&
                            'order-arrow cursor-pointer'
                          }`}
                        />
                      )}
                    </StyledTableTitle>
                  )}
                </TableHeadCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody className="relative">
            <>
              {messagesList.length > 0 &&
                _.map(messagesList, (message, idx) => (
                  <TableRow
                    key={`tableRowSendedMessage-${idx}`}
                    onClick={() => {
                      handleGetDetailMessages({ messageId: message.id }, () => {
                        setActiveItem((prevValue) => ({
                          ...prevValue,
                          active: 2,
                        }));
                      });
                    }}
                    className="cursor-pointer"
                  >
                    <TableCell className="py-2">
                      <p>{message.channel}</p>
                    </TableCell>
                    <TableCell className="py-2">
                      <p>{message.to}</p>
                    </TableCell>
                    <TableCell className="py-2">
                      <p className="truncate 2xl:w-96 xl:w-60">
                        {message.message}
                      </p>
                    </TableCell>
                    <TableCell className="py-2">
                      <DefaultBadge
                        label={message.state.name}
                        type="light"
                        variant={message.colorBadgeStatusMessage}
                      />
                    </TableCell>
                    <TableCell className="py-2">
                      {dateExists({
                        sendedDate: message.sendedDate,
                        suggestedDate: message.suggestedDate,
                        statusIdMessage: message.state.id,
                      })}
                    </TableCell>
                  </TableRow>
                ))}
            </>
          </TableBody>
        </Table>
        {messagesList.length === 0 && (
          <CardEmptyState
            className="w-8/12 mx-auto mt-16"
            title={
              isFilterEmpty
                ? 'No hay resultados, utiliza nuevos criterios de búsqueda'
                : 'No se han encontrado mensajes de difusión ¡Crea el primero!'
            }
            body="Este espacio es para mantener la comunicacion con la comunidad, para recolectar información, realizar recordatorios o enviar invitaciones!"
            button={
              !isFilterEmpty ? (
                <ButtonComponent
                  className="mx-auto"
                  text="Crear nuevo mensaje"
                  icon={
                    <div className="icon-close transform rotate-45 text-sm" />
                  }
                  onPress={() => {
                    setActiveItem((prevValue) => ({
                      ...prevValue,
                      active: 1,
                    }));
                  }}
                />
              ) : (
                <></>
              )
            }
          />
        )}
        <Pager
          pageCount={Math.ceil(
            dataForPagination?.total / dataForPagination?.per_page
          )}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          previousLabel={Boolean(dataForPagination?.prev)}
          nextLabel={Boolean(dataForPagination?.next)}
          className="flex items-center lg:justify-end justify-center my-6"
          onPageChange={({ selected }) => handleChangePage(selected)}
          forcePage={dataForPagination.current_page - 1}
        />
      </StyledContentTable>
    </>
  );
};

List.propTypes = {
  setActiveItem: PropTypes.func.isRequired,
  dataTableHeaderAdminMessagesRef: PropTypes.object,
  handleOpenMenu: PropTypes.func,
  activeDropDown: PropTypes.bool,
  handleDataToOrder: PropTypes.func,
  orderBy: PropTypes.object,
  dataForPagination: PropTypes.object,
  handleChangePage: PropTypes.func,
  messagesList: PropTypes.array,
  dateExists: PropTypes.func,
  formFilterMessages: PropTypes.object,
  optionsCommunities: PropTypes.array,
  optionsContactLists: PropTypes.array,
  handleFilter: PropTypes.func,
  handleResetFilter: PropTypes.func,
  handleGetDetailMessages: PropTypes.func,
};

List.defaultProps = {
  dataTableHeaderAdminMessagesRef: {},
  handleOpenMenu: () => {},
  activeDropDown: false,
  handleDataToOrder: () => {},
  orderBy: {},
  dataForPagination: {},
  handleChangePage: () => {},
  messagesList: [],
  dateExists: () => {},
  formFilterMessages: {},
  optionsCommunities: [],
  optionsContactLists: [],
  handleFilter: () => {},
  handleResetFilter: () => {},
  handleGetDetailMessages: () => {},
};

export default List;
