//Packages
import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

//Hooks
import useApi from 'api';
import useModels from 'models';
import useControllers from 'controllers';

const useAdminViewInterventionsGeneralStepperAndTabsInterventions = ({
  isDevelopmentIntervention = false,
} = {}) => {
  const history = useHistory();
  const { project_id: projectId, intervention_id } = useParams();
  const { state } = useLocation();

  const { useActions } = useApi();
  const { dispatch, useAdminActions, useGeneralActions } = useActions();
  const { useTabSelectedActions } = useGeneralActions();
  const { actSetTabSelected, actResetTabSelected } = useTabSelectedActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminInterventionsRootActions } = useAdminInterventionsActions();
  const {
    actGetIntervention,
    actGetInterventionDetailByProject,
    actResetInterventionSelected,
    actSetCustomInterventionStep,
  } = useAdminInterventionsRootActions();

  const { useGeneralHooks } = useControllers();
  const { useGetUserProfile } = useGeneralHooks();
  const { role } = useGetUserProfile();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors, useGeneralSelectors } =
    useSelectors();
  const { useTabSelectedSelectors } = useGeneralSelectors();
  const { tabSelectedSelector } = useTabSelectedSelectors();
  const tabValue = useSelector(tabSelectedSelector);

  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const {
    getInterventionDetailToEditSelector,
    getActualPhaseOfInterventionsSelector,
    getInterventionsSelector,
  } = useAdminInterventionsSelectors();
  const actualPhaseIntervention = useSelector(
    getActualPhaseOfInterventionsSelector
  );

  const [currentStep, setCurrentStep] = useState({
    current: actualPhaseIntervention,
  });
  const [permissions] = useState(role.id === 'ad' || role.id === 'ep');

  const dataIntervention = useSelector(getInterventionDetailToEditSelector);
  const {
    plan_creative,
    know_project,
    know_community,
    define_intervention,
    deepen_knowledge,
    deepen_execution,
    createSketch,
    pilotPlan,
    programPilot,
    evaluatePilot,
    executeMaterialize,
    executeIntervention,
    executeEvaluate,
    spreadIntervention,
  } = useSelector(getInterventionsSelector);

  const handleGoBack = () => {
    dispatch(actResetInterventionSelected());
    if (role.id === 'ar') {
      history.goBack();
    } else {
      history.push(`/admin/projects/dashboard/${projectId}/interventions`, {
        pageFromIntervention: state?.pageFromIntervention,
      });
    }
  };

  useEffect(() => {
    const { customActualPhase } = dataIntervention;
    if (!customActualPhase) {
      if (!isDevelopmentIntervention) {
        dispatch(actResetInterventionSelected());
        if (role.id === 'ad') {
          dispatch(
            actGetInterventionDetailByProject({
              projectId,
              interventionId: intervention_id,
            })
          );
        } else {
          dispatch(actGetIntervention({ intervention_id }));
        }
      }
    } else {
      setCurrentStep({
        current: customActualPhase - 1,
      });
      dispatch(actSetCustomInterventionStep({ step: '' }));
    }
  }, []);

  useEffect(() => {
    const { customActualPhase } = dataIntervention;
    if (actualPhaseIntervention > 0 && !customActualPhase) {
      setCurrentStep({
        current: actualPhaseIntervention,
      });
    }
  }, [actualPhaseIntervention]);

  useEffect(() => {
    return () => !isDevelopmentIntervention && dispatch(actResetTabSelected());
  }, []);

  const handleGoToStepperSelected = (step) => {
    setCurrentStep((prevValue) => ({ ...prevValue, current: step }));
  };

  const handleSelectTab = (tabIdx) =>
    dispatch(actSetTabSelected({ tabValue: tabIdx }));

  const handleSetStatusNameOrColorStepper = ({ stepNumber }) => {
    if (stepNumber < actualPhaseIntervention) {
      return {
        statusName: 'Completado',
        statusColor: 'c',
      };
    } else {
      if (stepNumber === 0) {
        return {
          statusName: plan_creative.state.name,
          statusColor: plan_creative.state.id,
        };
      }
      if (stepNumber === 1) {
        return {
          statusName: know_project?.state?.name,
          statusColor: know_project?.state?.id,
        };
      }
      if (stepNumber === 2) {
        if (
          !know_community?.state?.id &&
          !deepen_knowledge?.state?.id &&
          !deepen_execution?.state?.id
        ) {
          return {
            statusName: '',
            statusColor: '',
          };
        }
        if (
          !know_community.com_deepen ||
          _.some([deepen_knowledge.state.id, deepen_execution.state.id], (id) =>
            _.some(['cl', 'r'], (s) => s === id)
          )
        ) {
          return {
            statusName: know_community.state.name,
            statusColor: know_community.state.id,
          };
        } else {
          if (
            _.some(
              [deepen_knowledge.state.id, deepen_execution.state.id],
              (id) => _.some(['p', 'ip', 'sc'], (s) => s === id)
            )
          ) {
            return {
              statusName: 'En progreso',
              statusColor: 'ip',
            };
          } else if (
            know_community.state.id === 'ip' &&
            _.some(
              [deepen_knowledge.state.id, deepen_execution.state.id],
              (id) => id === 'p'
            )
          ) {
            return {
              statusName: know_community.state.name,
              statusColor: know_community.state.id,
            };
          } else {
            return {
              statusName: deepen_knowledge.state.name,
              statusColor: deepen_knowledge.state.id,
            };
          }
        }
      }
      if (stepNumber === 3) {
        return {
          statusName: define_intervention.state.name,
          statusColor: define_intervention.state.id,
        };
      }
      if (stepNumber === 4) {
        if (
          !createSketch?.state?.id &&
          !pilotPlan?.state?.id &&
          !programPilot?.state?.id &&
          !evaluatePilot?.state?.id
        ) {
          return {
            statusName: '',
            statusColor: '',
          };
        }
        if (
          _.some(
            [
              createSketch?.state?.id,
              pilotPlan?.state?.id,
              programPilot?.state?.id,
              evaluatePilot?.state?.id,
            ],
            (id) => _.some(['iv', 'r', 'ip', 'ir', 'p'], (s) => s === id)
          )
        ) {
          return {
            statusName: 'Etapa en proceso',
            statusColor: 'ip',
          };
        } else {
          return {
            statusName: 'Completado',
            statusColor: 'c',
          };
        }
      }
      if (stepNumber === 5) {
        if (
          !executeMaterialize?.state?.id &&
          !executeEvaluate?.state?.id &&
          !executeIntervention?.state?.id
        ) {
          return {
            statusName: '',
            statusColor: '',
          };
        }
        if (
          _.some(
            [
              executeMaterialize?.state?.id,
              executeEvaluate?.state?.id,
              executeIntervention?.state?.id,
            ],
            (id) => _.some(['p', 'ip', 'iv', 'r'], (s) => s === id)
          )
        ) {
          return {
            statusName: 'Etapa en proceso',
            statusColor: 'ip',
          };
        } else {
          return {
            statusName: 'Completado',
            statusColor: 'c',
          };
        }
      }
      if (stepNumber === 6) {
        return {
          statusName: spreadIntervention?.state?.name,
          statusColor: spreadIntervention?.state?.id,
        };
      }
    }
  };

  return {
    handleGoBack,
    dataIntervention,
    currentStep,
    permissions,
    handleGoToStepperSelected,
    actualPhaseIntervention,
    setCurrentStep,
    tabValue,
    handleSelectTab,
    handleSetStatusNameOrColorStepper,
  };
};

export default useAdminViewInterventionsGeneralStepperAndTabsInterventions;
