import useScreenHooks from './screenHooks';
import useGeneralHooks from './generalHooks';
import useRoutesHooks from './routesHooks';
import useComponentHooks from "./componentHooks";

const useControllers = () => {
  return {
    useScreenHooks,
    useGeneralHooks,
    useRoutesHooks,
    useComponentHooks
  };
};

export default useControllers;
