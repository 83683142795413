//Packages
import { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

//Hooks
import useApi from 'api';
import useHelpers from 'helpers';
import useModels from 'models';
import useControllers from 'controllers';

const useAdminRootCommunitiesDashboard = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { project_id: projectId } = useParams();

  const textSearchRef = useRef(null);

  const [textSearch, setTextSearch] = useState('');
  const [communityId, setCommunityId] = useState('');

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminCommunitiesActions, useAdminProjectsRootActions } =
    useAdminProjectsActions();
  const {
    actGetCommunitiesByProject,
    actDeleteCommunityById,
    actGetCommunityDetailById,
  } = useAdminCommunitiesActions();

  const { actGetProjectById } = useAdminProjectsRootActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminCommunitiesSelectors } = useAdminProjectsSelectors();
  const { communitiesListSelector, communitySelectedSelector } =
    useAdminCommunitiesSelectors();
  const communitiesList = useSelector(communitiesListSelector);
  const communitySelected = useSelector(communitySelectedSelector);

  const { useGeneralHooks } = useControllers();
  const { useToggleModal, useIsFilterEmpty } = useGeneralHooks();
  const { isFilterEmpty } = useIsFilterEmpty();
  const [isVisibleDeleteCommunityModal, handleStateDeleteCommunityModal] =
    useToggleModal();
  const [openModalDetailCommunitie, setOpenModalDetailCommunitie] =
    useToggleModal();

  useEffect(() => {
    dispatch(actGetProjectById({ projectId }, null));
  }, []);

  const { useQuickFunctions } = useHelpers();
  const { convertThounsandsSeparator, showCityDepartmentAndCountry } =
    useQuickFunctions();

  const handleDetailCommunitie = ({ communityId }) => {
    dispatch(
      actGetCommunityDetailById(
        { projectId: projectId, communityId },
        onSuccessGetCommunityDetailById
      )
    );
  };

  const onSuccessGetCommunityDetailById = () => setOpenModalDetailCommunitie();

  useEffect(() => {
    dispatch(actGetProjectById({ projectId }, null));
  }, []);

  useEffect(() => {
    dispatch(actGetCommunitiesByProject({ projectId }, null));
  }, []);

  useEffect(() => {
    if (textSearch.length >= 3) {
      dispatch(
        actGetCommunitiesByProject({ projectId, name: textSearch }, null)
      );
      textSearchRef.current = true;
    } else {
      if (textSearchRef.current) {
        dispatch(actGetCommunitiesByProject({ projectId }, null));
        textSearchRef.current = false;
      }
    }
  }, [textSearch]);

  const handleGoToCreateCommunity = () => history.push(`${pathname}/create`);

  const handleGoBack = () =>
    history.push(`/admin/projects/dashboard/${projectId}`);

  const handleGoToContacts = ({ communityId }) =>
    history.push(`${pathname}/${communityId}/contacts`);

  const handleGoToEditCommunity = ({ communityId }) =>
    history.push(`${pathname}/edit/${communityId}`);

  const onSuccessHandleDeleteCommunityById = () => {
    handleStateDeleteCommunityModal();
  };

  const handleDeleteCommunityById = () => {
    dispatch(
      actDeleteCommunityById(
        { communityId, projectId },
        onSuccessHandleDeleteCommunityById,
        onSuccessHandleDeleteCommunityById
      )
    );
  };

  return {
    isFilterEmpty,
    handleGoToCreateCommunity,
    textSearch,
    setTextSearch,
    isVisibleDeleteCommunityModal,
    handleStateDeleteCommunityModal,
    handleGoBack,
    communitiesList,
    handleGoToContacts,
    convertThounsandsSeparator,
    handleGoToEditCommunity,
    handleDeleteCommunityById,
    setCommunityId,
    handleDetailCommunitie,
    openModalDetailCommunitie,
    setOpenModalDetailCommunitie,
    communitySelected,
    showCityDepartmentAndCountry,
  };
};

export default useAdminRootCommunitiesDashboard;
