//Hooks
import useAuthTypes from './auth';
import useUserTypes from './user';
import useTestTypes from './test';
import useGeneralTypes from './general';
import useMessagesTypes from './messages';
import useAdminTypes from './admin';
import useContactsTypes from './contacts';
import useDashboardTypes from './dashboard';
import useConstants from './constants';

const useStrings = () => {
  return {
    useAuthTypes,
    useUserTypes,
    useTestTypes,
    useGeneralTypes,
    useMessagesTypes,
    useAdminTypes,
    useContactsTypes,
    useDashboardTypes,
    useConstants,
  };
};

export default useStrings;
