import React from 'react';

const Header = React.lazy(() => import('./Header'));
const HeaderBrand = React.lazy(() => import('./HeaderBrand'));
const HeaderButtons = React.lazy(() => import('./HeaderButtons'));
const HeaderProfile = React.lazy(() => import('./HeaderProfile'));
const HeaderProfileNav = React.lazy(() => import('./HeaderProfileNav'));
const HeaderProfileInformation = React.lazy(() =>
  import('./HeaderProfileInformation')
);
const HeaderProfileLinks = React.lazy(() => import('./HeaderProfileLinks'));
const HeaderMenuButton = React.lazy(() => import('./HeaderMenuButton'));
const HeaderPanelNotifications = React.lazy(() =>
  import('./HeaderPanelNotifications')
);

const useHeaders = () => {
  return {
    Header,
    HeaderBrand,
    HeaderButtons,
    HeaderProfile,
    HeaderProfileInformation,
    HeaderProfileNav,
    HeaderProfileLinks,
    HeaderMenuButton,
    HeaderPanelNotifications,
  };
};
export default useHeaders;
