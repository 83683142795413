import React, { lazy } from 'react';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

const StyledContentButtons = lazy(() =>
  import(
    '../../../../../../../../../../../../../../../styles/tableViews/tableViews.styles'
  ).then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const StyledGroup = lazy(() =>
  import(
    '../../../../../../../../../../../../../../../styles/formViews/formViews.styles'
  ).then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const Means = (props) => {
  const { resources } = props;
  const { useComponents } = useViews();

  const { useTypographies, useInputs } = useComponents();

  const { TypographyComponent } = useTypographies();
  const { Select, InputForm } = useInputs();

  const { control } = useForm({
    mode: 'onChange',
  });

  return (
    <>
      <Accordion allowZeroExpanded>
        {resources.length > 0 ? (
          _.map(resources, (resource, index) => {
            return (
              <div className="rounded mt-2" key={index}>
                <AccordionItem>
                  <div className="flex items-center justify-between bg-white py-2 px-6">
                    <TypographyComponent
                      variant="p"
                      text={resource.describe}
                      className="flex-grow text-primary font-bold"
                    />
                    <StyledContentButtons>
                      <AccordionItemState>
                        {({ expanded }) =>
                          expanded ? (
                            <AccordionItemButton>
                              <div className="icon-arrow-down text-xxs text-neutral-900 transform rotate-180"/>
                            </AccordionItemButton>
                          ) : (
                            <AccordionItemButton>
                              <div className="icon-arrow-down text-xxs text-neutral-900"/>
                            </AccordionItemButton>
                          )
                        }
                      </AccordionItemState>
                    </StyledContentButtons>
                  </div>
                  <AccordionItemPanel className="py-4 border rounded px-4">
                    <form>
                      <StyledGroup noSpace className="mb-4 flex-col">
                        <InputForm
                          name="name"
                          label="Recurso requerido*"
                          type="text"
                          className="full"
                          placeholder="Ingresa el recurso requerido*"
                          defaultValue={resource.describe}
                          disabled
                        />
                        <Select
                          name={`type-${index}`}
                          id="type"
                          placeholder="Tipo de recurso*"
                          control={control}
                          defaultValue={{
                            label: resource.type.name,
                            value: resource.type.id,
                          }}
                          label="Tipo de recurso*"
                          disabled
                        />
                      </StyledGroup>
                    </form>
                  </AccordionItemPanel>
                </AccordionItem>
              </div>
            );
          })
        ) : (
          <TypographyComponent
            className="message-no-match py-2 px-6 text-center"
            variant="p"
            text="No hay medios logísticos creados"
          />
        )}
      </Accordion>
    </>
  );
};

Means.propTypes = {
  resources: PropTypes.array,
};

export default Means;
