import useAdminInterventionsRootReducers from './root';
import useAdminViewInterventionsReducers from './view';

const useAdminInterventionsReducers = () => {
  return {
    useAdminInterventionsRootReducers,
    useAdminViewInterventionsReducers,
  };
};

export default useAdminInterventionsReducers;
