//Packages
import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Hooks
import useViews from '../../..';
import useHelpers from '../../../../helpers';

//Styles
const StyledSocialNetworks = lazy(() =>
  import('./UserInfoModal.styles').then((mod) => ({
    default: mod.StyledSocialNetworks,
  }))
);

//Assets
import imageInstagram from '../../../../assets/icons/instagram-purple.svg';
import imageFacebook from '../../../../assets/icons/facebook-purple.svg';
import imageTwitter from '../../../../assets/icons/twitter-purple.svg';
import imageLinkedin from '../../../../assets/icons/linkedin-purple.svg';
import imageYoutube from '../../../../assets/icons/youtube-purple.svg';
import imageWebSite from '../../../../assets/icons/web-site.svg';
import imagePin from '../../../../assets/icons/user-location.svg';
import imageUser from '../../../../assets/img/fakeUserPhotoSociio.jpg';

const UserInfoModal = (props) => {
  const { viewUser, setViewUser, user } = props;

  const { useComponents } = useViews();
  const { useImages, useModals, useTypographies, useBadges } = useComponents();
  const { Image } = useImages();
  const { DefaultModal } = useModals();
  const { TypographyComponent } = useTypographies();
  const { DefaultBadge } = useBadges();

  const { useQuickFunctions } = useHelpers();
  const { showCityDepartmentAndCountry } = useQuickFunctions();

  return (
    <DefaultModal
      isActive={viewUser}
      classNameContent="max-w-screen-md w-11/12"
      handleClose={() => {
        setViewUser(false);
      }}
    >
      <Image
        variant="rounded"
        srcImage={user?.avatar || imageUser}
        altImage={user?.name}
        titleImage={user?.name}
        className="w-28 h-28 mt-0 lg:-mt-24 mx-auto relative object-cover"
      />
      <TypographyComponent
        className="text-primary font-bold flex items-center justify-center lg:justify-start mt-4 leading-tight"
        text={user?.name || ''}
        variant="h3"
      />
      <TypographyComponent
        className="text-neutral-950 flex items-center justify-center lg:justify-start my-3"
        text={user?.role?.name || ''}
        variant="p"
      />
      <TypographyComponent
        className="text-neutral-950 flex items-center justify-center lg:justify-start mt-2 lg:mt-0 font-bold"
        variant="h5"
        text={
          <>
            <Image
              srcImage={imagePin}
              altImage="location"
              titleImage="location"
              className="location-icon w-6 mr-1"
            />
            {showCityDepartmentAndCountry({ city: user?.city })}
          </>
        }
      />
      <TypographyComponent
        className="text-primary flex items-center mt-4"
        text={'Email'}
        variant="h5"
      />
      <TypographyComponent
        className="text-neutral-950 flex items-center"
        text={user?.email || ''}
        variant="p"
      />
      <TypographyComponent
        className="text-primary flex items-center mt-4"
        text={'Celular'}
        variant="h5"
      />
      <TypographyComponent
        className="text-neutral-950 flex items-center"
        text={`${user?.phone?.code?.code} ${user?.phone?.number}`}
        variant="p"
      />

      {user?.gender && (
        <>
          <TypographyComponent
            className="text-primary flex items-center mt-4"
            text={'Género'}
            variant="h5"
          />
          <TypographyComponent
            className="text-neutral-950 flex items-center"
            text={user.gender}
            variant="p"
          />
        </>
      )}
      {user?.language && (
        <>
          <TypographyComponent
            className="text-primary flex items-center mt-4"
            text={'Idioma'}
            variant="h5"
          />
          <TypographyComponent
            className="text-neutral-950 flex items-center"
            text={user.language}
            variant="p"
          />
        </>
      )}
      {user?.organization && user?.role.id === 'ep' && (
        <>
          <TypographyComponent
            className="text-primary flex items-center mt-4"
            text={'Organización'}
            variant="h5"
          />
          <TypographyComponent
            className="text-neutral-950 flex items-center"
            text={
              <>
                {user?.organization}
                <a
                  className="ml-2 underline text-info-400"
                  href={user?.website}
                  target="_blank"
                  rel="noreferrer"
                >
                  Ir al sitio web
                </a>
              </>
            }
            variant="p"
          />
        </>
      )}
      {user?.position && user?.role.id === 'ep' && (
        <>
          <TypographyComponent
            className="text-primary flex items-center mt-4"
            text={'Cargo'}
            variant="h5"
          />
          <TypographyComponent
            className="text-neutral-950 flex items-center"
            text={user.position}
            variant="p"
          />
        </>
      )}
      {user?.networks && user?.networks.length > 0 && (
        <>
          <TypographyComponent
            className="text-primary flex items-center mt-4"
            text={'Redes sociales'}
            variant="h5"
          />
          <StyledSocialNetworks>
            {_.map(user?.networks, (network, idx) => {
              switch (network.type.id) {
                case 'tw':
                  return (
                    <Image
                      srcImage={imageTwitter}
                      onClick={() => window.open(network.url)}
                      className="w-8"
                      key={`socialMedia-${idx}`}
                    />
                  );
                case 'int':
                  return (
                    <Image
                      srcImage={imageInstagram}
                      onClick={() => window.open(network.url)}
                      className="w-8"
                      key={`socialMedia-${idx}`}
                    />
                  );
                case 'fcb':
                  return (
                    <Image
                      srcImage={imageFacebook}
                      onClick={() => window.open(network.url)}
                      className="w-8"
                      key={`socialMedia-${idx}`}
                    />
                  );
                case 'lin':
                  return (
                    <Image
                      srcImage={imageLinkedin}
                      onClick={() => window.open(network.url)}
                      className="w-8"
                      key={`socialMedia-${idx}`}
                    />
                  );
                case 'you':
                  return (
                    <Image
                      srcImage={imageYoutube}
                      onClick={() => window.open(network.url)}
                      className="w-8"
                      key={`socialMedia-${idx}`}
                    />
                  );
                default:
                  return (
                    <Image
                      srcImage={imageWebSite}
                      onClick={() => window.open(network.url)}
                      className="w-8"
                      key={`socialMedia-${idx}`}
                    />
                  );
              }
            })}
          </StyledSocialNetworks>
        </>
      )}

      {user?.specialties && user?.specialties.length > 0 && (
        <>
          <TypographyComponent
            className="text-primary flex items-center mt-4"
            text={'Experiencia artística'}
            variant="h5"
          />
          {_.map(user?.specialties, (speciality, idx) => (
            <DefaultBadge
              key={`specialities-${idx}`}
              className="text-white mr-1 mb-1"
              variant="primary"
              type="light"
              label={speciality.name}
            />
          ))}
        </>
      )}
      {user?.website && user?.role.id === 'ar' && (
        <TypographyComponent
          className="text-primary mb-1 flex items-center mt-4"
          text={
            <>
              <Image
                srcImage={imageWebSite}
                altImage="web site"
                titleImage="web site"
              />
              <a
                className="ml-1 text-info-400 text-base leading-none"
                href={user.website}
                target="_blank"
                rel="noreferrer"
              >
                Visitar mi sitio web
              </a>
            </>
          }
          variant="h5"
        />
      )}
      {user?.intervention_type?.name && (
        <>
          <TypographyComponent
            className="text-primary flex items-center mt-4"
            text={'Tipo de intervención preferida'}
            variant="h5"
          />
          <TypographyComponent
            className="text-neutral-950 flex items-center"
            text={user.intervention_type.name}
            variant="p"
          />
        </>
      )}
      {user?.about_me_body && (
        <>
          <TypographyComponent
            className="text-primary flex items-center mt-4"
            text={'Mi trabajo'}
            variant="h5"
          />
          <TypographyComponent
            className="text-neural-950 flex items-center"
            text={user.about_me_body}
            variant="p"
          />
        </>
      )}
    </DefaultModal>
  );
};

UserInfoModal.propTypes = {
  viewUser: PropTypes.bool.isRequired,
  setViewUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default UserInfoModal;
