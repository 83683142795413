import useHelpers from '../../../../helpers';

const useTabSelectedSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const tabSelectedSelector = createSelector(
    (state) => state.tabSelected,
    (tabSelected) => tabSelected
  );

  const subTabSelectedSelector = createSelector(
    (state) => state.subTabSelected,
    (subTabSelected) => subTabSelected
  );

  return { tabSelectedSelector, subTabSelectedSelector };
};

export default useTabSelectedSelectors;
