import useViewDevelopmentInterventionCreateSketchAdmin from './admin';
import useViewDevelopmentInterventionCreateSketchArtist from './artist';
import useSketchStepper from './sketchStepper';

const useViewDevelopmentInterventionCreateSketch = () => {
  return {
    useViewDevelopmentInterventionCreateSketchArtist,
    useViewDevelopmentInterventionCreateSketchAdmin,
    useSketchStepper,
  };
};

export default useViewDevelopmentInterventionCreateSketch;
