import React from 'react';
import styled from 'styled-components/macro';
import tw from 'twin.macro';

const srtledTag = styled(({ variant, children, ...props }) =>
  React.createElement(variant, props, children)
);

export const StyledTypography = srtledTag`
${tw``}
${(props) => {
  switch (props.variant) {
    case 'h1':
      return tw` text-2xl `;
    case 'h2':
      return tw`text-xl`;
    case 'h3':
      return tw` text-lg`;
    case 'h4':
      return tw` text-base`;
    case 'h5':
      return tw` text-sm`;
    case 'h6':
      return tw` text-xs `;
    case 'span':
      return tw``;
    case 'label':
      return tw` text-baselg`;
    case 'small':
      return tw` text-sm 2xl:text-base`;
    default:
      return tw`text-base`;
  }
}}
`;
