import React, { Suspense, lazy } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

// Hooks
import useViews from 'views';
import useControllers from 'controllers';

const StyledWrapperPrivacy = lazy(() =>
  import('./PrivacyPolicies.styles').then((mod) => ({
    default: mod.StyledWrapperPrivacy,
  }))
);

const StyledBackButton = lazy(() =>
  import('./PrivacyPolicies.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);

const PrivacyPolicies = () => {
  const { push } = useHistory();

  const { useLayouts, useComponents } = useViews();
  const { useLoaders } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { MainLayout } = useLayouts();

  const { useScreenHooks } = useControllers();
  const { useLegalData } = useScreenHooks();
  const { privacyPolicies } = useLegalData();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <MainLayout>
        <StyledWrapperPrivacy start={'true'}>
          <StyledBackButton onClick={() => push('/')}>
            Regresar al home
          </StyledBackButton>
        </StyledWrapperPrivacy>
        <StyledWrapperPrivacy
          dangerouslySetInnerHTML={{ __html: privacyPolicies }}
        />
      </MainLayout>
    </Suspense>
  );
};

export default PrivacyPolicies;
