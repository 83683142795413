//Hooks
import useHelpers from 'helpers';
import useInitialStates from '../../../initialStates';
import useStrings from 'strings';
import _ from 'lodash';

const useAdminHomeReducer = () => {
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  const { useAdminInitialStates } = useInitialStates();
  const { useAdminHomeInitialStates } = useAdminInitialStates();
  const { initialStateAdminHome } = useAdminHomeInitialStates();

  const { useAdminTypes } = useStrings();
  const { useAdminHomeType, useAdminProjectsTypes } = useAdminTypes();
  const {
    GET_CONFIG_HOME,
    UPDATE_CONFIG_HOME,
    STORE_CONFIG_HOME,
    GET_INTERVENTIONS_HOME,
    GET_INTERVENTIONS_INFO_FOR_EDIT,
    RESET_INTERVENTION_INFO_FOR_EDIT,
  } = useAdminHomeType();
  const { useAdminInterventionsTypes } = useAdminProjectsTypes();
  const { useAdminViewInterventionsTypes } = useAdminInterventionsTypes();
  const { useAdminViewInterventionsDevelopmentInterventionTypes } =
    useAdminViewInterventionsTypes();
  const { useAdminSpreadInterventionTypes } =
    useAdminViewInterventionsDevelopmentInterventionTypes();
  const { UPLOAD_FILE_SPREAD_INTERVENTION, DELETE_FILE_SPREAD_INTERVENTION } =
    useAdminSpreadInterventionTypes();

  const homeReducer = createReducer(initialStateAdminHome, {
    [GET_CONFIG_HOME](state, action) {
      const {
        id,
        sliders,
        bt_title,
        bt_subtitle,
        bt_text,
        artist,
        executing_partner,
      } = action.payload;
      return {
        ...state,
        id,
        sliders,
        bt_title,
        bt_subtitle,
        bt_text,
        artist,
        executing_partner,
      };
    },
    [UPDATE_CONFIG_HOME](state, action) {
      const {
        id,
        sliders,
        bt_title,
        bt_subtitle,
        bt_text,
        artist,
        executing_partner,
      } = action.payload;
      return {
        ...state,
        id,
        sliders,
        bt_title,
        bt_subtitle,
        bt_text,
        artist,
        executing_partner,
      };
    },
    [STORE_CONFIG_HOME](state) {
      return state;
    },
    [GET_INTERVENTIONS_HOME](state, { payload }) {
      return { ...state, interventions: payload };
    },
    [GET_INTERVENTIONS_INFO_FOR_EDIT](state, { payload }) {
      const { id, final_process } = payload.data;
      return { ...state, interventionSelectedToEdit: { id, final_process } };
    },
    [RESET_INTERVENTION_INFO_FOR_EDIT](state) {
      return {
        ...state,
        interventionSelectedToEdit: {
          id: '',
          final_process: {
            files: [],
            goals: [],
            id: '',
            recommendations: '',
            suggestions: '',
            tastes: '',
          },
        },
      };
    },
    [UPLOAD_FILE_SPREAD_INTERVENTION](state, action) {
      const { files } = action.payload.data;
      return {
        ...state,
        interventionSelectedToEdit: {
          ...state.interventionSelectedToEdit,
          final_process: {
            ...state.interventionSelectedToEdit.final_process,
            files,
          },
        },
      };
    },
    [DELETE_FILE_SPREAD_INTERVENTION](state, action) {
      const fileIDToDelete = action.payload;
      return {
        ...state,
        interventionSelectedToEdit: {
          ...state.interventionSelectedToEdit,
          final_process: {
            ...state.interventionSelectedToEdit.final_process,
            files: _.filter(
              state.interventionSelectedToEdit.final_process.files,
              (file) => file.id !== fileIDToDelete
            ),
          },
        },
      };
    },
  });
  return { homeReducer };
};
export default useAdminHomeReducer;
