//Hooks

import useArtistDefineInterventionActivity from './activity';
import useArtistDefineInterventionInterventions from './intervention';
import useArtistDefineInterventionDiffusion from './interventionDiffusion';
import useArtistDefineInterventionKeyFactors from './keyFactors';
import useArtistDefineInterventionLogisticsMeans from './logisticsMeans';
import useArtistDefineInterventionRoot from './root';

const useArtistDefineInterventionInterventionsControllers = () => {
  return {
    useArtistDefineInterventionInterventions,
    useArtistDefineInterventionActivity,
    useArtistDefineInterventionLogisticsMeans,
    useArtistDefineInterventionRoot,
    useArtistDefineInterventionKeyFactors,
    useArtistDefineInterventionDiffusion,
  };
};

export default useArtistDefineInterventionInterventionsControllers;
