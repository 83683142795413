//Hooks
import useApi from '../../../..';
import useControllers from '../../../../../controllers';
import useStrings from '../../../../../strings';

const useAdminSocioCulturalInfoActions = () => {
  const { useAdminTypes } = useStrings();
  const { useAdminProjectsTypes } = useAdminTypes();
  const { useAdminSocioCulturalInfoTypes } = useAdminProjectsTypes();
  const {
    GET_SOCIO_CULTURAL_INFO,
    CREATE_SOCIO_CULTURAL_INFO,
    UPDATE_SOCIO_CULTURAL_INFO,
    DELETE_SOCIO_CULTURAL_INFO,
  } = useAdminSocioCulturalInfoTypes();

  const { useProviders } = useApi();
  const { useAdminProviders } = useProviders();
  const { useAdminProjectsProviders } = useAdminProviders();
  const { useAdminSocioCulturalInfoProviders } = useAdminProjectsProviders();
  const {
    getSocioCulturalInfo,
    createSocialCulturalInfo,
    updateSocialCulturalInfo,
    deleteSocialCulturalInfo,
  } = useAdminSocioCulturalInfoProviders();

  const { useGeneralHooks } = useControllers();
  const { useConsole } = useGeneralHooks();
  const { viewConsole } = useConsole();

  const actGetSocioCulturalInfo =
    ({ projectId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await getSocioCulturalInfo({
          projectId,
        });
        dispatch({
          type: GET_SOCIO_CULTURAL_INFO,
          payload: res.data?.data?.social_cultural_info,
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actCreateSocioCulturalInfo =
    (
      { name, description, main, first, second, projectId },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const bodyFormData = new FormData();
        bodyFormData.append('name', name);
        description && bodyFormData.append('description', description);
        main && bodyFormData.append('multimedias[main]', main);
        first && bodyFormData.append('multimedias[first]', first);
        second && bodyFormData.append('multimedias[second]', second);

        const res = await createSocialCulturalInfo({
          formData: bodyFormData,
          projectId,
        });
        dispatch({
          type: CREATE_SOCIO_CULTURAL_INFO,
          payload: res.data?.data,
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actUpdateSocioCulturalInfo =
    (
      { projectId, socioCulturalId, name, description, main, first, second },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const bodyFormData = new FormData();
        bodyFormData.append('name', name);
        description && bodyFormData.append('description', description);
        (main || main === '') && bodyFormData.append('multimedias[main]', main);
        (first || first === '') &&
          bodyFormData.append('multimedias[first]', first);
        (second || second === '') &&
          bodyFormData.append('multimedias[second]', second);
        bodyFormData.append('_method', 'put');

        const res = await updateSocialCulturalInfo({
          formData: bodyFormData,
          socioCulturalId,
          projectId,
        });
        dispatch({
          type: UPDATE_SOCIO_CULTURAL_INFO,
          payload: { socioCulturalId, data: res.data?.data },
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actDeleteSocioCulturalInfo =
    ({ socioCulturalId, projectId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await deleteSocialCulturalInfo({
          projectId,
          socioCulturalId,
        });
        dispatch({
          type: DELETE_SOCIO_CULTURAL_INFO,
          payload: { socioCulturalId },
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };
  return {
    actGetSocioCulturalInfo,
    actCreateSocioCulturalInfo,
    actUpdateSocioCulturalInfo,
    actDeleteSocioCulturalInfo,
  };
};

export default useAdminSocioCulturalInfoActions;
