import React from 'react';

const Create = React.lazy(() => import('./Create'));
const Edit = React.lazy(() => import('./Edit'));

const useLabelsComponents = () => {
  return { Create, Edit };
};

export default useLabelsComponents;
