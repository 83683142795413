import useHeaders from './headers';
import useImagesControllers from './Images';
import useInputs from './inputs';
import useModalsControllers from './modals';
import useSideBarsControllers from './sideBars';
import useSlidersControllers from './sliders';
import useTablesControllers from './tables';

const useComponentHooks = () => {
  return {
    useInputs,
    useHeaders,
    useImagesControllers,
    useSideBarsControllers,
    useTablesControllers,
    useModalsControllers,
    useSlidersControllers,
  };
};

export default useComponentHooks;
