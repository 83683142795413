import React, { lazy } from 'react';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Components
import FormPilot from '../FormPilot';
import Activities from './components/Activities';

//Styles
const StyledButtonsActions = lazy(() =>
  import(
    '../../../../../../../../../../../../styles/buttons/buttons.styles'
  ).then((mod) => ({
    default: mod.StyledButtonsActions,
  }))
);

const StyledWrapperCommunity = lazy(() =>
  import('../../../../../../../Dashboard.styles').then((mod) => ({
    default: mod.StyledWrapperCommunity,
  }))
);

const StyledCardCommunity = lazy(() =>
  import('../../../../../../../Dashboard.styles').then((mod) => ({
    default: mod.StyledCardCommunity,
  }))
);

const StyledContentButtonsModal = lazy(() =>
  import(
    '../../../../../../../../../../../../styles/tableViews/tableViews.styles'
  ).then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const StyledGroup = lazy(() =>
  import(
    '../../../../../../../../../../../../styles/formViews/formViews.styles'
  ).then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledDivider = lazy(() =>
  import(
    '../../../../../../../../../../../../styles/formViews/formViews.styles'
  ).then((mod) => ({
    default: mod.StyledDivider,
  }))
);

const PilotIntervention = () => {
  const { useComponents } = useViews();
  const {
    useTypographies,
    useButtons,
    useModals,
    useInputs,
    useCards,
    useBadges,
  } = useComponents();

  const { TypographyComponent } = useTypographies();
  const { ButtonComponent } = useButtons();
  const { DefaultModal } = useModals();
  const { TextArea } = useInputs();
  const { CardNotification } = useCards();
  const { DefaultBadge } = useBadges();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsDevelopmentIntervention } =
    useAdminViewInterventionsControllers();
  const { useViewDevelopmentInterventionCreateSketch } =
    useAdminViewInterventionsDevelopmentIntervention();
  const { useViewDevelopmentInterventionCreateSketchAdmin } =
    useViewDevelopmentInterventionCreateSketch();
  const { useAdminPlanPilotIntervention } =
    useViewDevelopmentInterventionCreateSketchAdmin();
  const {
    pilotPlan,
    planPilotActivities,
    formRejectPilot,
    control,
    showModalReject,
    setShowModalReject,
    showModalApprove,
    setShowModalApprove,
    totalPilotDuration,
    openFile,
    downloadFile,
    updatePilotPlanIntervention,
  } = useAdminPlanPilotIntervention();

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = formRejectPilot;

  const closeRejectModalAndResetReason = (e) => {
    e.preventDefault();
    setShowModalReject(false);
    formRejectPilot.reset({});
  };

  return (
    <>
      {_.includes(['c', 'p', 'ip', 'r', 'iv'], pilotPlan.state?.id) && (
        <CardNotification
          className="w-5/12 mx-auto py-9 px-4 mt-16"
          title={
            <TypographyComponent
              text={pilotPlan.state?.name}
              variant="h2"
              className="font-bold text-primary icon-watch text-center"
            />
          }
        />
      )}
      <TypographyComponent
        text="Piloto de la intervención"
        variant="h2"
        className="font-bold text-primary mt-6"
      />
      <TypographyComponent
        text="Es el momento de que el artista piloteee en el terreno una parte de su intervención. Así podremos ajustar el diseño basado en nuestros aprendizajes."
        variant="p"
        className="text-neutral-950 mt-2"
      />

      {_.includes(['c', 'iv'], pilotPlan.state?.id) && (
        <>
          <TypographyComponent
            text="Criterios de evaluación*"
            variant="h3"
            className="font-bold text-primary mt-8"
          />

          <TypographyComponent
            text="Comparte con el artista los criterios que ayudarán a la evaluación del piloto. Cuáles son los elementos clave que el artista debe tener en cuenta?"
            variant="p"
            className="text-neutral-950 mt-2"
          />

          <div className="border rounded mt-8 px-6 pb-8 bg-neutral-300">
            <StyledWrapperCommunity nonemb>
              <StyledCardCommunity>
                <TypographyComponent
                  variant="p"
                  text="Criterios de evaluación del piloto.pdf"
                  className="flex-grow text-primary font-bold cursor-pointer"
                />
                <StyledButtonsActions className="flex items-center">
                  <ButtonComponent
                    text=""
                    className="icon-view"
                    onPress={(e) =>
                      openFile({ e, file: pilotPlan.criteria_file })
                    }
                  />
                  <ButtonComponent
                    text=""
                    icon={
                      <div className="icon-download text-primary text-lg" />
                    }
                    onPress={(e) =>
                      downloadFile({
                        e,
                        file: pilotPlan.criteria_file,
                        nameFile: 'Criterios de evaluación del piloto',
                      })
                    }
                  />
                </StyledButtonsActions>
              </StyledCardCommunity>
            </StyledWrapperCommunity>
          </div>

          <StyledDivider />

          <FormPilot control={control} />

          <StyledDivider />

          <div className="flex items-center">
            <TypographyComponent
              text="Actividades del piloto*"
              variant="h3"
              className="font-bold text-primary mt-8"
            />

            <DefaultBadge
              label={`Duración total del piloto: ${totalPilotDuration} minutos`}
              type="light"
              className="ml-4"
            />
          </div>

          <Activities activities={planPilotActivities} />

          <StyledDivider />

          <TypographyComponent
            text="Herramientas para el piloto*"
            variant="h3"
            className="font-bold text-primary mt-8"
          />

          <div className="border rounded mt-8 px-6 pb-8 bg-neutral-300">
            <StyledWrapperCommunity nonemb>
              <StyledCardCommunity>
                <TypographyComponent
                  variant="p"
                  text="Herramientas.pdf"
                  className="flex-grow text-primary font-bold cursor-pointer"
                />
                <StyledButtonsActions className="flex items-center">
                  <ButtonComponent
                    text=""
                    className="icon-view"
                    onPress={(e) => openFile({ e, file: pilotPlan.tool_file })}
                  />
                  <ButtonComponent
                    text=""
                    icon={
                      <div className="icon-download text-primary text-lg" />
                    }
                    onPress={(e) =>
                      downloadFile({
                        e,
                        file: pilotPlan.tool_file,
                        nameFile: 'Herramientas',
                      })
                    }
                  />
                </StyledButtonsActions>
              </StyledCardCommunity>
            </StyledWrapperCommunity>
          </div>

          {!_.includes(['c', 'p', 'r'], pilotPlan.state?.id) && (
            <div className="flex items-center space-x-2 mt-10 mb-6 justify-center">
              <ButtonComponent
                text="Rechazar piloto"
                mode="outline"
                onPress={() => {
                  setShowModalReject(true);
                }}
              />
              <ButtonComponent
                text="Aprobar piloto"
                onPress={() => {
                  setShowModalApprove(true);
                }}
              />
            </div>
          )}

          <DefaultModal
            isActive={showModalReject}
            classNameContent="max-w-screen-md w-11/12"
            handleClose={closeRejectModalAndResetReason}
          >
            <TypographyComponent
              text={'Rechazar piloto de la intervención'}
              variant="h3"
              className="font-bold text-primary mb-1"
            />

            <form>
              <StyledGroup col2 className="mb-4 ">
                <TextArea
                  name="rejected_reason"
                  placeholder="Ajustes a realizar"
                  className="w-full col-span-2"
                  label="Escribe los ajustes que debe realizar el artista al piloto de la intervención"
                  {...register('rejected_reason')}
                  errors={errors}
                />
              </StyledGroup>
            </form>
            <StyledContentButtonsModal>
              <ButtonComponent
                variant="primary"
                text="Cancelar"
                className="mx-auto w-full lg:w-auto lg:mx-2 "
                mode="outline"
                onPress={closeRejectModalAndResetReason}
              />
              <ButtonComponent
                variant="primary"
                text="Enviar respuesta al artista"
                className="mx-auto w-full lg:w-auto lg:mx-2"
                onPress={handleSubmit((data) =>
                  updatePilotPlanIntervention({
                    ...data,
                    state: 'r',
                  })
                )}
              />
            </StyledContentButtonsModal>
          </DefaultModal>

          <DefaultModal
            isActive={showModalApprove}
            classNameContent="max-w-screen-md w-11/12"
            handleClose={() => {
              setShowModalApprove(false);
            }}
          >
            <TypographyComponent
              text={'Aprobar piloto de la intervención'}
              variant="h3"
              className="font-bold text-primary mb-1"
            />

            <StyledContentButtonsModal>
              <ButtonComponent
                variant="primary"
                text="Cancelar"
                className="mx-auto w-full lg:w-auto lg:mx-2 "
                mode="outline"
                onPress={() => setShowModalApprove(false)}
              />
              <ButtonComponent
                variant="primary"
                text="Aceptar"
                className="mx-auto w-full lg:w-auto  lg:mx-2"
                onPress={() => updatePilotPlanIntervention({ state: 'c' })}
              />
            </StyledContentButtonsModal>
          </DefaultModal>
        </>
      )}
    </>
  );
};

export default PilotIntervention;
