import useHelpers from '../../../helpers';
import useStrings from '../../../strings';
import useInitialStates from '../../initialStates';

const useTestReducers = () => {
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  const { useTestTypes } = useStrings();
  const { TEST } = useTestTypes();

  const { useTestInitialStates } = useInitialStates();
  const { initialStateTest } = useTestInitialStates();

  const test = createReducer(initialStateTest, {
    [TEST](state, action) {
      const { testName } = action.payload;
      return { ...state, testName };
    },
  });

  return { test };
};

export default useTestReducers;
