import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useAdminAdditionalInfoProviders = () => {
  const getAdditionalInfo = ({ projectId }) => {
    const response = axios({
      method: 'GET',
      url: `/projects/${projectId}/additional-info`,
    });
    return trackPromise(response);
  };

  const createAdditionalInfo = ({ formData, projectId }) => {
    const response = axios({
      method: 'POST',
      headers: {
        'content-type': 'multipart/form-data',
      },
      url: `/projects/${projectId}/additional-info`,
      data: formData,
    });
    return trackPromise(response);
  };

  const updateAdditionalInfo = ({ formData, additionalInfoId, projectId }) => {
    const response = axios({
      method: 'POST',
      url: `/projects/${projectId}/additional-info/${additionalInfoId}`,
      data: formData,
    });
    return trackPromise(response);
  };

  const deleteAdditionalInfo = ({ projectId, additionalInfoId }) => {
    const response = axios({
      method: 'DELETE',
      url: `/projects/${projectId}/additional-info/${additionalInfoId}`,
    });
    return trackPromise(response);
  };

  return {
    getAdditionalInfo,
    createAdditionalInfo,
    updateAdditionalInfo,
    deleteAdditionalInfo,
  };
};

export default useAdminAdditionalInfoProviders;
