//Hooks
import useApi from '../..';
import useStrings from '../../../strings';
import useControllers from '../../../controllers';

const useDashboardActions = () => {
  const { useProviders } = useApi();
  const { useDashboardProviders } = useProviders();
  const { getDashboardInterventionsProvider } = useDashboardProviders();

  const { useDashboardTypes } = useStrings();
  const { GET_INTERVENTIONS_AS_ARTIST, SET_PAGE_INTERVENTIONS_AS_ARTIST } =
    useDashboardTypes();

  const { useGeneralHooks } = useControllers();
  const { useConsole } = useGeneralHooks();
  const { viewConsole } = useConsole();

  const actGetInterventionsAsArtist =
    ({ paginate = 0, page, name }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await getDashboardInterventionsProvider({
          paginate,
          page,
          name,
        });
        dispatch({
          type: GET_INTERVENTIONS_AS_ARTIST,
          payload: res.data,
        });
        onSuccess && onSuccess();
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actSetCurrentPageInterventionsAsArtist =
    ({ page }) =>
    async (dispatch) => {
      dispatch({
        type: SET_PAGE_INTERVENTIONS_AS_ARTIST,
        payload: { page },
      });
    };

  return {
    actGetInterventionsAsArtist,
    actSetCurrentPageInterventionsAsArtist,
  };
};

export default useDashboardActions;
