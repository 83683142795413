//Packages
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//Hooks
import useApi from '../../../../../../../api';
import useModels from '../../../../../../../models';
import useStrings from '../../../../../../../strings';
import useHelpers from '../../../../../../../helpers';
import useControllers from '../../../../../..';
import { convertToFormData } from 'buildformdata-rmonterrosa';

const useAdminKnowCommunityInterventions = () => {
  const [showProfCommunitie, setShowProfCommunitie] = useState(false);
  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();
  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const { useAdminDevelopmentInterventionKnowCommunityActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const {
    actGetKnowCommunityFileIntervention,
    actUpdateKnowCommunityIntervention,
    actDeleteKnowCommunityFileIntervention,
    actUpdateKnowCommunityFileIntervention,
    actStoreDeepenKnowledgeCommunityIntervention,
    actKnowCommunityIntervention,
  } = useAdminDevelopmentInterventionKnowCommunityActions();

  const { pathname } = useLocation();
  const { intervention_id } = useParams();
  const history = useHistory();

  const { useSelectors } = useModels();
  const { useAdminSelectors, useSelector } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionsSelector } = useAdminInterventionsSelectors();
  const { know_community, deepen_knowledge } = useSelector(
    getInterventionsSelector
  );

  const { useGeneralHooks } = useControllers();
  const { useGetDate } = useGeneralHooks();
  const { setTextExpiredDate } = useGetDate();

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { REQUIRED_FIELD, DATE_GREATER_THAN_TODAY, FILE_SIZE_5MB } =
    useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const {
    formatMonthWithTwoDigits,
    formatDayWithTwoDigits,
    isSelectedDateGreaterThanTodayDate,
    getYearMonthDayFromDate,
    convertThounsandsSeparator,
    convertArrayToString,
    showCityDepartmentAndCountry,
    downloadFileFromBackend,
    getFileValidations,
  } = useQuickFunctions();
  const { MAX_SIZE_5MB } = getFileValidations();

  useEffect(() => {
    if (know_community.state?.id !== 'c') {
      dispatch(actKnowCommunityIntervention({ intervention_id }));
    }
  }, []);

  const schemaCreateKnowCommunityIntervention = yup.object({
    file:
      know_community?.files?.length > 0
        ? yup.mixed().test('fileSize', FILE_SIZE_5MB, (value) => {
            if (value) {
              return value.size <= MAX_SIZE_5MB;
            }
            return true;
          })
        : yup
            .mixed()
            .required(REQUIRED_FIELD)
            .test('fileSize', FILE_SIZE_5MB, (value) => {
              if (value) {
                return value.size <= MAX_SIZE_5MB;
              }
              return true;
            }),
    date_limit_checkbox: yup.boolean(),
    com_deepen: yup.boolean(),
    date_limit: yup.date().when('date_limit_checkbox', {
      is: true,
      then: yup
        .date()
        .required(REQUIRED_FIELD)
        .test('dateValidation', DATE_GREATER_THAN_TODAY, (value) => {
          return value
            ? isSelectedDateGreaterThanTodayDate({ selectedDate: value })
            : true;
        }),
      otherwise: yup
        .date()
        .notRequired()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr)),
    }),
  });
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    resolver: yupResolver(schemaCreateKnowCommunityIntervention),
    mode: 'onChange',
  });

  useEffect(() => {
    const file = watch('file');
    if (file?.size <= MAX_SIZE_5MB) {
      const data = new FormData();
      data.append('file', watch('file'));
      dispatch(
        actUpdateKnowCommunityFileIntervention({
          intervention_id,
          community_id: know_community.id,
          data,
        })
      );
    }
  }, [watch('file')]);

  const deleteFileArrayInForm = (file_id) => {
    dispatch(
      actDeleteKnowCommunityFileIntervention({
        intervention_id,
        community_id: know_community.id,
        file_id,
      })
    );
  };

  const updateKnowCommunities = (data) => {
    delete data.files;
    if (data.date_limit && watch('date_limit_checkbox')) {
      const date_limit = `${data.date_limit.getUTCFullYear()}-${formatMonthWithTwoDigits(
        {
          month: data.date_limit.getUTCMonth(),
        }
      )}-${formatDayWithTwoDigits({ day: data.date_limit.getUTCDate() })}`;
      data.date_limit = date_limit;
    } else {
      delete data.date_limit;
      delete data.date_limit_checkbox;
    }
    data.state = 'ip';
    dispatch(
      actUpdateKnowCommunityIntervention({
        intervention_id,
        community_id: know_community.id,
        data,
      })
    );
  };

  const handleGoToPath = ({ path }) => history.push(`${pathname}/${path}`);

  const updateStateKnowProject = () => {
    const updateStateDeepenKnoeledge = () => {
      const data = convertToFormData({
        state: 'cl',
        _method: 'PATCH',
      });
      dispatch(
        actStoreDeepenKnowledgeCommunityIntervention({
          data,
          intervention_id,
          deepen_knowledge_id: deepen_knowledge.id,
        })
      );
    };
    if (know_community.state.id === 'c') {
      updateStateDeepenKnoeledge();
    } else {
      dispatch(
        actUpdateKnowCommunityIntervention(
          {
            intervention_id,
            community_id: know_community.id,
            data: { state: 'c' },
          },
          () => know_community.community_id && updateStateDeepenKnoeledge()
        )
      );
    }
  };
  const deepenKnowledge = () => {
    if (know_community.state.id !== 'c') {
      dispatch(
        actUpdateKnowCommunityIntervention({
          intervention_id,
          community_id: know_community.id,
          data: { state: 'c' },
        })
      );
    }
  };
  const preViewPDFKnowCommunity = (url) => {
    window.open(url, '_blank');
  };
  const downloadFileKnowCommunity = (file_id) => {
    dispatch(
      actGetKnowCommunityFileIntervention(
        {
          intervention_id,
          community_id: know_community.id,
          file_id,
        },
        (response) => {
          downloadFileFromBackend({
            response,
            nameFile: `Community`,
          });
        }
      )
    );
  };

  const textForLimitDate = useMemo(() => {
    if (know_community?.date_limit) {
      const { isExpired, text } = setTextExpiredDate({
        expiredDate: know_community?.date_limit,
      });

      return {
        text,
        colorBadge: isExpired ? 'error' : 'warning',
      };
    }

    return {
      text: 'No hay fecha limite',
      colorBadge: 'warning',
    };
  }, [know_community.date_limit]);

  return {
    control,
    register,
    handleSubmit,
    watch,
    errors,
    isValid,
    updateKnowCommunities,
    handleGoToPath,
    updateStateKnowProject,
    getYearMonthDayFromDate,
    convertThounsandsSeparator,
    convertArrayToString,
    showCityDepartmentAndCountry,
    know_community,
    deepen_knowledge,
    deleteFileArrayInForm,
    preViewPDFKnowCommunity,
    downloadFileKnowCommunity,
    textForLimitDate,
    showProfCommunitie,
    setShowProfCommunitie,
    deepenKnowledge,
  };
};
export default useAdminKnowCommunityInterventions;
