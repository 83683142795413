//Packages
import React, { Suspense, lazy } from 'react';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
const StyledForm = lazy(() =>
  import('../Users.styles').then((mod) => ({
    default: mod.StyledForm,
  }))
);

const StyledGroup = lazy(() =>
  import('../Users.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledBackButton = lazy(() =>
  import('../Users.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);

const StyledDivider = lazy(() =>
  import('../Users.styles').then((mod) => ({
    default: mod.StyledDivider,
  }))
);

const AdminEditUsers = () => {
  const { useLayouts, useComponents } = useViews();
  const { AdminLayout } = useLayouts();
  const { useButtons, useInputs, useTypographies, useBoxes, useLoaders } =
    useComponents();
  const { DefaultLoader } = useLoaders();
  const { ButtonComponent } = useButtons();
  const { InputForm, Select } = useInputs();
  const { TypographyComponent } = useTypographies();
  const { Box } = useBoxes();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useUserAdmin } = useAdmin();
  const { useEditUser } = useUserAdmin();
  const {
    history,
    editUserForm,
    optionsCountries,
    optionsIndicatives,
    optionsCities,
    optionsDepartments,
    editUser,
  } = useEditUser();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = editUserForm;

  return (
    <Suspense fallback={<DefaultLoader />}>
      <AdminLayout>
        <StyledBackButton onClick={history.goBack} className="relative z-50">
          Regresar
        </StyledBackButton>
        <Box className="bg-neutral-200 w-11/12 mx-auto max-w-screen-lg lg:px-16 lg:py-12 px-6 py-8 lg:my-8">
          <>
            <TypographyComponent
              text="Editar usuario"
              variant="h2"
              className="font-bold text-primary mb-6"
            />

            <StyledForm onSubmit={handleSubmit(editUser)}>
              <StyledGroup className="items-center">
                <Select
                  name="role"
                  control={control}
                  options={[{ label: 'Seleccione', value: '' }]}
                  label="Rol*"
                  placeholder="Seleccione"
                  errors={errors}
                  disabled
                />
              </StyledGroup>

              <StyledDivider />

              <StyledGroup noSpace>
                <InputForm
                  control={control}
                  type="text"
                  name="name"
                  placeholder="Ingresa tu nombre "
                  label="Nombre*"
                  className="w-full"
                  errors={errors}
                />
                <InputForm
                  control={control}
                  type="text"
                  name="last_name"
                  placeholder="Ingresa tu apellido"
                  label="Apellido*"
                  className="w-full"
                  errors={errors}
                />
              </StyledGroup>

              <StyledGroup noSpace>
                <InputForm
                  control={control}
                  type="text"
                  name="email"
                  placeholder="Ingresa tu correo electrónico "
                  label="Correo electrónico*"
                  className="w-full"
                  errors={errors}
                  disabled
                />
                <Select
                  name="country"
                  id="country"
                  control={control}
                  options={optionsCountries}
                  label="País*"
                  placeholder="Selecciona tu país"
                  errors={errors}
                  onChange={() => {
                    editUserForm.setValue('department', '');
                    editUserForm.setValue('city', '');
                  }}
                />
              </StyledGroup>

              <StyledGroup noSpace>
                <Select
                  name="department"
                  id="department"
                  control={control}
                  options={optionsDepartments}
                  label="Departamento o Estado*"
                  placeholder="Selecciona el Dpto./Estado"
                  errors={errors}
                  className="space-y-0"
                  onChange={() => {
                    editUserForm.setValue('city', '');
                  }}
                />
                <Select
                  name="city"
                  id="city"
                  control={control}
                  options={optionsCities}
                  label="Ciudad o Municipio*"
                  placeholder="Selecciona tu ciudad"
                  errors={errors}
                />
              </StyledGroup>

              <StyledGroup noSpace>
                <StyledGroup
                  col2
                  className="items-end flex space-x-4 lg:space-x-3 "
                >
                  <Select
                    name="code"
                    id="code"
                    className="lg:w-7/12"
                    control={control}
                    options={optionsIndicatives}
                    label="Teléfono*"
                    placeholder="Seleccione"
                    errors={errors}
                  />
                  <InputForm
                    type="number"
                    name="number_phone"
                    placeholder="Número de teléfono"
                    className="w-full"
                    errors={errors}
                    control={control}
                  />
                </StyledGroup>
              </StyledGroup>

              <div>
                <ButtonComponent
                  variant="primary"
                  className="mx-auto mt-10"
                  text="Guardar cambios"
                  onPress={handleSubmit(editUser)}
                />
              </div>
            </StyledForm>
          </>
        </Box>
      </AdminLayout>
    </Suspense>
  );
};

export default AdminEditUsers;
