//Packages
import React from 'react';
import { Switch, useRouteMatch, Redirect } from 'react-router-dom';

//Hooks
import useNavigation from '../../..';
import useViews from '../../../../views';
import useAdminProjectsRouters from './routers';

const AdminCommunitiesRouter = () => {
  const { path } = useRouteMatch();

  const { useRoutes } = useNavigation();
  const { PrivateRoute, ProtectedRoute } = useRoutes();

  //Screens
  const { useScreens } = useViews();
  const { AdminCreateProjects, AdminEditProjects, AdminRootProjects } =
    useScreens();

  //Subrouters
  const { AdminProjectsRouterDashboard } = useAdminProjectsRouters();

  return (
    <Switch>
      <ProtectedRoute
        exact
        path={`${path}/create`}
        component={AdminCreateProjects}
        redirect="/"
      />
      <ProtectedRoute
        exact
        path={`${path}/edit/:project_id`}
        component={AdminEditProjects}
        redirect="/"
      />
      <PrivateRoute
        path={`${path}/dashboard`}
        component={AdminProjectsRouterDashboard}
        redirect="/"
      />
      <ProtectedRoute
        exact
        path={`${path}`}
        component={AdminRootProjects}
        redirect="/"
      />
      <Redirect to={`${path}`} />
    </Switch>
  );
};

export default AdminCommunitiesRouter;
