//Packages
import { useEffect, useRef, useState } from 'react';

//Hooks
import useApi from '../../../api';
import useModels from '../../../models';

const useCollapseAccordion = ({ withUseState = false } = {}) => {
  const refCollapsed = useRef(null);
  const [showAccordeon, setShowAccordeon] = useState(true);
  const [clientHeiAccordeon, setClientHeiAccordeon] = useState(0);

  const { useSelectors } = useModels();
  const { useSelector, useGeneralSelectors } = useSelectors();
  const { useSidebarSelectors } = useGeneralSelectors();
  const { sidebarSelector } = useSidebarSelectors();
  const showMenuCollapsed = useSelector(sidebarSelector);

  const collapsed = withUseState
    ? showAccordeon
    : showMenuCollapsed.showMenuCollapsed;
  const clienteHei = withUseState
    ? clientHeiAccordeon
    : showMenuCollapsed.clientHeight;

  const { useActions } = useApi();
  const { dispatch, useGeneralActions } = useActions();
  const { useSidebarActions } = useGeneralActions();
  const { actShowMenuCollapsed } = useSidebarActions();

  useEffect(() => {
    refCollapsed?.current?.clientHeight &&
      setClientHeiAccordeon(refCollapsed.current.clientHeight);
  }, [refCollapsed?.current?.clientHeight]);

  const handleOpenCollapse = () => {
    if (withUseState) {
      setClientHeiAccordeon(refCollapsed.current.clientHeight);
      setShowAccordeon(!showAccordeon);
    } else {
      dispatch(
        actShowMenuCollapsed({
          collapsed: !showMenuCollapsed.showMenuCollapsed,
          clienteHei: refCollapsed.current.clientHeight,
        })
      );
    }
  };

  return {
    refCollapsed,
    collapsed,
    clienteHei,
    handleOpenCollapse,
  };
};

export default useCollapseAccordion;
