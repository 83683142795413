//Packages
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useParams, useHistory } from 'react-router-dom';

//Hooks
import useStrings from '../../../../../../strings';
import useControllers from '../../../../..';
import useModels from '../../../../../../models';
import useApi from '../../../../../../api';
import useHelpers from '../../../../../../helpers';

const useAdminDocumentationDashboard = () => {
  const { project_id: projectId } = useParams();
  const history = useHistory();

  const [show, setShow] = useState(false);
  const [cleanFileInputs, setCleanFileInputs] = useState(false);
  const [documentationId, setDocumentationId] = useState(null);

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { FILE_SIZE, FILE_TYPE, REQUIRED_FIELD } = useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const { getFileValidations } = useQuickFunctions();
  const { MAX_SIZE, ALL_SUPPORTED_FORMATS, SUPPORTED_FORMATS_WITH_PREVIEW } =
    getFileValidations();

  const { useGeneralHooks } = useControllers();
  const { useToggleModal } = useGeneralHooks();
  const [showDeleteDocumentationModal, handleDeleteDocumentationModal] =
    useToggleModal();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminDocumentationSelectors } = useAdminProjectsSelectors();
  const { listDocumentationByProjectSelector } =
    useAdminDocumentationSelectors();
  const listDocumentationByProject = useSelector(
    listDocumentationByProjectSelector
  );

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminDocumentationActions } = useAdminProjectsActions();
  const {
    actCreateDocumentationByProject,
    actDeleteDocumentationByProject,
    actGetDocumentationByProject,
    actDownloadDocumentationByProject,
  } = useAdminDocumentationActions();

  const schemaDocumentation = yup.object({
    file: yup
      .mixed()
      .required(REQUIRED_FIELD)
      .test('fileType', FILE_TYPE, (value) => {
        if (value) {
          return ALL_SUPPORTED_FORMATS.includes(value.type);
        }
        return true;
      })
      .test('fileSize', FILE_SIZE, (value) => {
        if (value) {
          return value.size <= MAX_SIZE;
        }
        return true;
      }),
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schemaDocumentation),
    mode: 'onChange',
  });

  useEffect(() => {
    dispatch(actGetDocumentationByProject({ projectId }));
  }, []);

  const handleAddDocumentation = () => {
    setValue('file', null);
    setCleanFileInputs((prevState) => !prevState);
    setShow((prevState) => !prevState);
  };

  const onSuccessHandleCreateDocumentation = () => {
    setShow((prevState) => !prevState);
  };

  const handleCreateDocumentation = (data) => {
    const { file } = data;
    dispatch(
      actCreateDocumentationByProject(
        { projectId, file },
        onSuccessHandleCreateDocumentation
      )
    );
  };

  const onSuccessHandleDeleteDocumentation = () => {
    handleDeleteDocumentationModal();
  };

  const handleDeleteDocumentation = () => {
    dispatch(
      actDeleteDocumentationByProject(
        { projectId, documentationId },
        onSuccessHandleDeleteDocumentation
      )
    );
  };

  const handleCancelDocumentation = (e) => {
    e.preventDefault();
    reset({});
    setShow((prevState) => !prevState);
    setCleanFileInputs((prevState) => !prevState);
  };

  const onSuccessDownloadDocumentation =
    ({ fileName }) =>
    (res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    };

  const handlePreviewOrDownloadFile = ({
    fileType,
    urlToPreview,
    documentationId,
    fileName,
  }) => {
    if (SUPPORTED_FORMATS_WITH_PREVIEW.includes(fileType)) {
      window.open(urlToPreview);
    } else {
      dispatch(
        actDownloadDocumentationByProject(
          { projectId, documentationId },
          onSuccessDownloadDocumentation({ fileName })
        )
      );
    }
  };

  const handleGoBack = () =>
    history.push(`/admin/projects/dashboard/${projectId}`);

  return {
    handleSubmit,
    errors,
    control,
    show,
    showDeleteDocumentationModal,
    handleDeleteDocumentationModal,
    handleGoBack,
    handleCancelDocumentation,
    handleAddDocumentation,
    listDocumentationByProject,
    cleanFileInputs,
    handleCreateDocumentation,
    handleDeleteDocumentation,
    setDocumentationId,
    handlePreviewOrDownloadFile,
  };
};

export default useAdminDocumentationDashboard;
