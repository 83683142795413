import useHelpers from '../../../../helpers';
import useStrings from '../../../../strings';
import useInitialStates from '../../../initialStates';

const useLanguagesReducers = () => {
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  //Initial States
  const { useGeneralInitialStates } = useInitialStates();
  const { useLanguagesInitialStates } = useGeneralInitialStates();
  const { initialStateLanguages } = useLanguagesInitialStates();

  //Strings
  const { useGeneralTypes } = useStrings();
  const { useLanguagesTypes } = useGeneralTypes();
  const { GET_LANGUAGES } = useLanguagesTypes();

  const languages = createReducer(initialStateLanguages, {
    [GET_LANGUAGES](state, action) {
      return [...action.payload];
    },
  });

  return { languages };
};

export default useLanguagesReducers;
