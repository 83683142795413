//Packages
import _ from 'lodash';

//Hooks
import useModels from '../../../..';
import useHelpers from '../../../../../helpers';
import useStrings from '../../../../../strings';

const useAdminCommunitiesReducers = () => {
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  const { useAdminTypes, useAuthTypes } = useStrings();
  const { LOGOUT } = useAuthTypes();
  const { useAdminProjectsTypes } = useAdminTypes();
  const { useAdminCommunitiesTypes } = useAdminProjectsTypes();
  const { GET_COMMUNITIES, SELECTED_COMMUNITY_DATA, DELETE_COMMUNITY_BY_ID } =
    useAdminCommunitiesTypes();

  const { useInitialStates } = useModels();
  const { useAdminInitialStates } = useInitialStates();
  const { useAdminProjectsInitialStates } = useAdminInitialStates();
  const { useAdminCommunitiesInitialStates } = useAdminProjectsInitialStates();
  const {
    inititalStateAdminCommunitiesList,
    initialStateAdminCommunitySelected,
  } = useAdminCommunitiesInitialStates();

  const communitiesList = createReducer(inititalStateAdminCommunitiesList, {
    [GET_COMMUNITIES](state, action) {
      return [...action.payload];
    },
    [DELETE_COMMUNITY_BY_ID](state, action) {
      const { communityId } = action.payload;
      const communitiesListAux = _.filter(
        state,
        (community) => community.id !== parseInt(communityId)
      );
      return [...communitiesListAux];
    },
    [LOGOUT]() {
      return inititalStateAdminCommunitiesList;
    },
  });

  const communitySelected = createReducer(initialStateAdminCommunitySelected, {
    [SELECTED_COMMUNITY_DATA](state, action) {
      const {
        name,
        population_number,
        city,
        language,
        general_comments,
        reading_level,
        has_internet,
        device_access,
        communication_medias,
        community_image,
        multimedias,
        project,
      } = action.payload;
      return {
        name,
        population_number,
        city,
        language,
        general_comments,
        reading_level,
        has_internet,
        device_access,
        communication_medias,
        community_image,
        multimedias,
        projectName: project.name,
      };
    },
    [LOGOUT]() {
      return initialStateAdminCommunitySelected;
    },
  });

  return { communitiesList, communitySelected };
};

export default useAdminCommunitiesReducers;
