//Packages
import React, { Suspense, lazy } from 'react';
import { useHistory } from 'react-router';

//Hooks
import useControllers from 'controllers';
import useViews from 'views';

const StyledForm = lazy(() =>
  import('../Aut.styles').then((mod) => ({
    default: mod.StyledForm,
  }))
);

const Login = () => {
  const history = useHistory();
  //layout- components
  const { useLayouts, useComponents } = useViews();
  const { MainLayout } = useLayouts();
  const {
    useBoxes,
    useTypographies,
    useInputs,
    useButtons,
    useLinks,
    useLoaders,
  } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { Box } = useBoxes();
  const { TypographyComponent } = useTypographies();
  const { InputForm } = useInputs();
  const { ButtonComponent } = useButtons();
  const { Link } = useLinks();

  //Controllers
  const { useScreenHooks } = useControllers();
  const { useAuth } = useScreenHooks();
  const { useLogin } = useAuth();
  const {
    handleSubmit,
    register,
    errors,
    isValid,
    handleLogin,
    showWithErrorTextPassword,
  } = useLogin();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <MainLayout hasDecorationMap>
        <Box className="bg-neutral-200 w-11/12 mx-auto max-w-3xl lg:px-16 lg:py-12 px-6 py-8 lg:my-8">
          <>
            <TypographyComponent
              text="Iniciar Sesión"
              variant="h2"
              className="font-bold text-primary mb-4"
            />
            <StyledForm>
              <InputForm
                type="email"
                name="email"
                placeholder="Ingresa tu correo electrónico"
                label="Correo electrónico"
                className="w-full"
                errors={errors}
                {...register('email')}
              />
              <InputForm
                type="password"
                name="password"
                placeholder="*******"
                label="Contraseña"
                className="w-full"
                isPassword
                errors={errors}
                showWithErrorTextPassword={showWithErrorTextPassword}
                {...register('password')}
              />
              <Link
                text="Olvidé mi contraseña"
                onClick={() => history.push('/recover-password')}
                className="cursor-pointer"
              />
              <TypographyComponent
                text={
                  <>
                    ¿Usuario nuevo? <Link text="Regístrate" to={'/register'} />
                  </>
                }
                variant="p"
                className="font-bold text-primary"
              />
              <ButtonComponent
                variant="primary"
                text="Iniciar Sesión"
                onPress={handleSubmit(handleLogin)}
                disabled={!isValid}
              />
            </StyledForm>
          </>
        </Box>
      </MainLayout>
    </Suspense>
  );
};

export default Login;
