//Packages
import React from 'react';
import PropTypes from 'prop-types';

//Hooks
import useConfig from 'config';
import useViews from 'views';
import useControllers from 'controllers';

const GeneralModal = (props) => {
  const { store } = props;

  const { useInterceptor } = useConfig();
  const { dataGeneralModal } = useInterceptor(store);

  const { useComponents } = useViews();
  const { useModals, useTypographies, useButtons } = useComponents();
  const { DefaultModal } = useModals();
  const { TypographyComponent } = useTypographies();
  const { ButtonComponent } = useButtons();

  const { useComponentHooks } = useControllers();
  const { useModalsControllers } = useComponentHooks();
  const { useGeneralModal } = useModalsControllers();
  const { currentMessage, handleClose, messageMobileScreen, forceHide } =
    useGeneralModal({ dataGeneralModal });

  return (
    <>
      {!forceHide && (
        <DefaultModal
          isHideAutomatic={!messageMobileScreen && Boolean(currentMessage)}
          isActive={Boolean(messageMobileScreen) || dataGeneralModal.show}
          handleClose={handleClose}
          isMobileScreenModal={Boolean(messageMobileScreen)}
          hasCloseButton={false}
          isGeneralModal
        >
          <TypographyComponent
            variant={messageMobileScreen ? 'h4' : 'h3'}
            className={`${
              !messageMobileScreen && 'font-bold text-primary'
            } whitespace-pre-wrap`}
            text={
              messageMobileScreen ||
              dataGeneralModal.message[currentMessage - 1] ||
              ''
            }
          />
          <ButtonComponent
            text={messageMobileScreen ? 'Volver al home' : 'Aceptar'}
            className="mx-auto mt-10"
            onPress={handleClose}
          />
        </DefaultModal>
      )}
    </>
  );
};

GeneralModal.propTypes = {
  store: PropTypes.object.isRequired,
};

export default GeneralModal;
