//Hooks
import useControllers from 'controllers';
import useStrings from 'strings';
import useApi from 'api';

const useAdminDevelopmentInterventionCreateSketchActions = () => {
  const { useProviders } = useApi();
  const { useAdminProviders } = useProviders();
  const { useAdminProjectsProviders } = useAdminProviders();
  const { useAdminInterventionsProviders } = useAdminProjectsProviders();
  const { useAdminViewInterventionProviders } =
    useAdminInterventionsProviders();
  const { useAdminViewInterventionDevelopmentInterventionProviders } =
    useAdminViewInterventionProviders();
  const { useAdminDevelopmentInterventionCreateSketchProviders } =
    useAdminViewInterventionDevelopmentInterventionProviders();
  const {
    getCreateSketchInterventionProvider,
    updateCreateSketchInterventionProvider,
    downloadSketchForInterventionProvider,
    getPilotPlanInterventionProvider,
    updatePilotPlanInterventionProvider,
    downloadPilotPlanCriteriaFileInterventionProvider,
    createPilotPlanActivityInterventionProvider,
    updatePilotPlanActivityInterventionProvider,
    deletePilotPlanActivityInterventionProvider,
    getProgramPilotInterventionProvider,
    updateProgramPilotInterventionProvider,
    callAgentProgramPilotInterventionProvider,
    getEvaluatePilotInterventionProvider,
    updateEvaluatePilotInterventionProvider,
    uploadFileEvaluatePilotInterventionProvider,
    downloadFileEvaluatePilotInterventionProvider,
    deleteFileEvaluatePilotInterventionProvider,
  } = useAdminDevelopmentInterventionCreateSketchProviders();

  const { useAdminTypes } = useStrings();
  const { useAdminProjectsTypes } = useAdminTypes();
  const { useAdminInterventionsTypes } = useAdminProjectsTypes();
  const { useAdminViewInterventionsTypes } = useAdminInterventionsTypes();
  const { useAdminViewInterventionsDevelopmentInterventionTypes } =
    useAdminViewInterventionsTypes();
  const { useAdminDevelopmentInterventionCreateSketchTypes } =
    useAdminViewInterventionsDevelopmentInterventionTypes();
  const {
    GET_CREATE_SKETCH_FOR_INTERVENTION,
    UPDATE_CREATE_SKETCH_FOR_INTERVENTION,
    GET_PILOT_PLAN_INTERVENTION,
    UPDATE_PILOT_PLAN_INTERVENTION,
    CREATE_PILOT_PLAN_ACTIVITY_INTERVENTION,
    DELETE_PILOT_PLAN_ACTIVITY_INTERVENTION,
    GET_PROGRAM_PILOT_INTERVENTION,
    UPDATE_PROGRAM_PILOT_INTERVENTION,
    CALL_AGENT_PROGRAM_PILOT_INTERVENTION,
    GET_EVALUATE_PILOT_INTERVENTION,
    UPDATE_EVALUATE_PILOT_INTERVENTION,
    UPLOAD_FILE_EVALUATE_PILOT_INTERVENTION,
    DELETE_FILE_EVALUATE_PILOT_INTERVENTION,
  } = useAdminDevelopmentInterventionCreateSketchTypes();

  const { useGeneralHooks } = useControllers();
  const { useConsole } = useGeneralHooks();
  const { viewConsole } = useConsole();

  const actGetCreateSketchProjectIntervention =
    ({ intervention_id }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await getCreateSketchInterventionProvider({
          interventionId: intervention_id,
        });

        dispatch({
          type: GET_CREATE_SKETCH_FOR_INTERVENTION,
          payload: res.data,
        });
        onSuccess && onSuccess(res);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actUpdateCreateSketchProjectIntervention =
    ({ interventionId, sketchId, data }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await updateCreateSketchInterventionProvider({
          interventionId,
          sketchId,
          formData: data,
        });
        dispatch({
          type: UPDATE_CREATE_SKETCH_FOR_INTERVENTION,
          payload: res.data,
        });
        onSuccess && onSuccess(res);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actDownloadCreateSketchProjectIntervention =
    ({ interventionId, sketchId, fileId }, onSuccess, onError) =>
    async () => {
      try {
        const res = await downloadSketchForInterventionProvider({
          interventionId,
          sketchId,
          fileId,
        });
        onSuccess && onSuccess(res);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actGetPilotPlanIntervention =
    ({ interventionId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await getPilotPlanInterventionProvider({
          interventionId,
        });
        dispatch({
          type: GET_PILOT_PLAN_INTERVENTION,
          payload: res.data,
        });
        onSuccess && onSuccess(res.data);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actUpdatePilotPlanIntervention =
    ({ interventionId, pilotPlanId, data }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await updatePilotPlanInterventionProvider({
          interventionId,
          pilotPlanId,
          data,
        });
        dispatch({
          type: UPDATE_PILOT_PLAN_INTERVENTION,
          payload: res.data,
        });
        onSuccess && onSuccess(res.data);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actDownloadPilotPlanCriteriaFileIntervention =
    ({ interventionId, pilotPlanId, fileId }, onSuccess, onError) =>
    async () => {
      try {
        const res = await downloadPilotPlanCriteriaFileInterventionProvider({
          interventionId,
          pilotPlanId,
          fileId,
        });
        onSuccess && onSuccess(res);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actCreatePilotPlanActivityIntervention =
    ({ interventionId, pilotPlanId, data }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await createPilotPlanActivityInterventionProvider({
          interventionId,
          pilotPlanId,
          data,
        });
        dispatch({
          type: CREATE_PILOT_PLAN_ACTIVITY_INTERVENTION,
          payload: res.data,
        });
        onSuccess && onSuccess(res.data);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actUpdatePilotPlanActivityIntervention =
    ({ interventionId, pilotPlanId, activityId, data }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await updatePilotPlanActivityInterventionProvider({
          interventionId,
          pilotPlanId,
          activityId,
          data,
        });
        dispatch({
          type: CREATE_PILOT_PLAN_ACTIVITY_INTERVENTION,
          payload: res.data,
        });
        onSuccess && onSuccess(res.data);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actDeletePilotPlanActivityIntervention =
    ({ interventionId, pilotPlanId, activityId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await deletePilotPlanActivityInterventionProvider({
          interventionId,
          pilotPlanId,
          activityId,
        });
        dispatch({
          type: DELETE_PILOT_PLAN_ACTIVITY_INTERVENTION,
          payload: activityId,
        });
        onSuccess && onSuccess(res.data);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actGetProgramPilotIntervention =
    ({ interventionId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await getProgramPilotInterventionProvider({
          interventionId,
        });
        dispatch({
          type: GET_PROGRAM_PILOT_INTERVENTION,
          payload: res.data,
        });
        onSuccess && onSuccess(res.data);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actUpdateProgramPilotIntervention =
    ({ interventionId, programPilotId, data }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await updateProgramPilotInterventionProvider({
          interventionId,
          programPilotId,
          data,
        });
        dispatch({
          type: UPDATE_PROGRAM_PILOT_INTERVENTION,
          payload: res.data,
        });
        onSuccess && onSuccess(res.data);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actCallAgentProgramPilotIntervention =
    ({ interventionId, programPilotId, data }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await callAgentProgramPilotInterventionProvider({
          interventionId,
          programPilotId,
          data,
        });
        dispatch({
          type: CALL_AGENT_PROGRAM_PILOT_INTERVENTION,
          payload: res.data,
        });
        onSuccess && onSuccess(res.data);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actGetEvaluatePilotIntervention =
    ({ interventionId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await getEvaluatePilotInterventionProvider({
          interventionId,
        });
        dispatch({
          type: GET_EVALUATE_PILOT_INTERVENTION,
          payload: res.data,
        });
        onSuccess && onSuccess(res.data);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actUpdateEvaluatePilotIntervention =
    ({ interventionId, evaluatePilotId, data }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await updateEvaluatePilotInterventionProvider({
          interventionId,
          evaluatePilotId,
          data,
        });
        dispatch({
          type: UPDATE_EVALUATE_PILOT_INTERVENTION,
          payload: res.data,
        });
        onSuccess && onSuccess(res.data);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actUploadFileEvaluatePilotIntervention =
    ({ interventionId, evaluatePilotId, data }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await uploadFileEvaluatePilotInterventionProvider({
          interventionId,
          evaluatePilotId,
          data,
        });
        dispatch({
          type: UPLOAD_FILE_EVALUATE_PILOT_INTERVENTION,
          payload: res.data,
        });
        onSuccess && onSuccess(res.data);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actDownloadFileEvaluatePilotIntervention =
    ({ interventionId, evaluatePilotId, fileId }, onSuccess, onError) =>
    async () => {
      try {
        const res = await downloadFileEvaluatePilotInterventionProvider({
          interventionId,
          evaluatePilotId,
          fileId,
        });
        onSuccess && onSuccess(res);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  const actDeleteFileEvaluatePilotIntervention =
    ({ interventionId, evaluatePilotId, fileId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await deleteFileEvaluatePilotInterventionProvider({
          interventionId,
          evaluatePilotId,
          fileId,
        });
        dispatch({
          type: DELETE_FILE_EVALUATE_PILOT_INTERVENTION,
          payload: fileId,
        });
        onSuccess && onSuccess(res);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };

  return {
    actGetCreateSketchProjectIntervention,
    actUpdateCreateSketchProjectIntervention,
    actDownloadCreateSketchProjectIntervention,
    actGetPilotPlanIntervention,
    actUpdatePilotPlanIntervention,
    actDownloadPilotPlanCriteriaFileIntervention,
    actCreatePilotPlanActivityIntervention,
    actUpdatePilotPlanActivityIntervention,
    actDeletePilotPlanActivityIntervention,
    actGetProgramPilotIntervention,
    actUpdateProgramPilotIntervention,
    actCallAgentProgramPilotIntervention,
    actGetEvaluatePilotIntervention,
    actUpdateEvaluatePilotIntervention,
    actUploadFileEvaluatePilotIntervention,
    actDownloadFileEvaluatePilotIntervention,
    actDeleteFileEvaluatePilotIntervention,
  };
};

export default useAdminDevelopmentInterventionCreateSketchActions;
