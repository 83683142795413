import useAdminCreateSketchInterventions from './createSketchRoot';
import useAdminPlanPilotIntervention from './planPilot';
import useAdminProgramPilotIntervention from './programPilot';
import useAdminAndArtistEvaluatePilotIntervention from './evaluatePilot';

const useViewDevelopmentInterventionCreateSketchAdmin = () => {
  return {
    useAdminCreateSketchInterventions,
    useAdminPlanPilotIntervention,
    useAdminProgramPilotIntervention,
    useAdminAndArtistEvaluatePilotIntervention
  };
};

export default useViewDevelopmentInterventionCreateSketchAdmin;
