import useAdminAbcTrajectoryDashboardHooks from './abcTrajectory';
import useAdminAdditionalInfoDashboard from './additionalInfo';
import useAdminCommunitiesDashboardHooks from './communities';
import useAdminFestivitiesAndTraditionsDashboard from './festivitiesAndTraditions';
import useAdminRecommendationsDashboard from './recommendations';
import useAdminRootDashboard from './root';
import useAdminSocioCulturalInfoDashboard from './socioCulturalInfo';
import useAdminDocumentationDashboard from './documentation';
import useAdminInterventionsDashboardControllers from './interventions';

const useAdminDashboardProjectsHooks = () => {
  return {
    useAdminAbcTrajectoryDashboardHooks,
    useAdminAdditionalInfoDashboard,
    useAdminCommunitiesDashboardHooks,
    useAdminFestivitiesAndTraditionsDashboard,
    useAdminRecommendationsDashboard,
    useAdminRootDashboard,
    useAdminSocioCulturalInfoDashboard,
    useAdminDocumentationDashboard,
    useAdminInterventionsDashboardControllers,
  };
};

export default useAdminDashboardProjectsHooks;
