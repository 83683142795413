import _ from 'lodash';
import { useEffect, useState } from 'react';

// Hooks
import useApi from 'api';
import useStrings from 'strings';
import useHelpers from 'helpers';

const useAdminDeepenKnowledgeMessagesResults = ({ messages }) => {
  const { useActions } = useApi()
  const { dispatch, useAdminActions, } = useActions()
  const { useAdminProjectsActions } = useAdminActions()
  const { useAdminInterventionsActions } = useAdminProjectsActions()
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions()
  const { useAdminViewInterventionDevelopmentInterventionActions } = useAdminViewInterventionsActions()
  const { useAdminDevelopmentInterventionKnowCommunityActions } = useAdminViewInterventionDevelopmentInterventionActions()
  const {
    actGetMessageAnswersDeepenKnowledgeScheduleInfo
  } = useAdminDevelopmentInterventionKnowCommunityActions()

  const { useConstants } = useStrings()
  const { DATA_CHART_COLORS } = useConstants()

  const { useQuickFunctions } = useHelpers()
  const { getYearMonthDayFromDate, updateItemInArray } = useQuickFunctions()

  const [messagesData, setMessagesData] = useState(messages);

  useEffect(() => {
    _.forEach(messages, (message) => {
      getAnswersPage({
        message,
        page: 1
      })
    })
  }, [])

  const getAnswersPage = ({ message, page }) => {
    const { messageRequest } = message
    const { id: mrId } = messageRequest

    dispatch(
      actGetMessageAnswersDeepenKnowledgeScheduleInfo(
        { message_request_id: mrId, page },
        (answers) => {
          let messageUpdated = { ...message, answers }
          if (message.type_information.id === 'qn') {
            messageUpdated = {
              ...message,
              answers: {
                ...answers,
                totalAnswers: _.reduce(answers.data.options, (sum, option) =>(sum + option.responses), 0)
              }
            }
          }
          setMessagesData(prevMessagesData =>
            updateItemInArray({
              arrayData: prevMessagesData,
              itemToUpdate: messageUpdated
            })
          );
        })
    )
  }

  return {
    DATA_CHART_COLORS,
    messagesData,
    getYearMonthDayFromDate,
    getAnswersPage,
  }
}

export default useAdminDeepenKnowledgeMessagesResults