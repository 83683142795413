import useAdminAbcTrajectoryDashboardRoot from './abcTrajectoryRoot';
import useAdminAbcTrajectoryDashboardTabs from './abcTrajectoryTabs';

const useAdminAbcTrajectoryDashboardHooks = () => {
  return {
    useAdminAbcTrajectoryDashboardTabs,
    useAdminAbcTrajectoryDashboardRoot,
  };
};

export default useAdminAbcTrajectoryDashboardHooks;
