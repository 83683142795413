import useHelpers from '../../../../helpers';

const useSidebarSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const sidebarSelector = createSelector(
    (state) => state.showMenuCollapsed,
    (showMenuCollapsed) => showMenuCollapsed
  );
  return { sidebarSelector };
};

export default useSidebarSelectors;
