//Packages
import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Hooks
import useViews from '../../../../..';

const StyledWarpperCategories = lazy(() =>
  import('./AddExperience.styles').then((mod) => ({
    default: mod.StyledWarpperCategories,
  }))
);

const StyledContentButtons = lazy(() =>
  import('./AddExperience.styles').then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const AddExperience = (props) => {
  const {
    optionsSpecialities,
    handleToogleSpeciality,
    openModalDiscardChanges,
    handleCloseModalDiscardChanges,
    handleDiscardChanges,
    handleSaveNewSpecialities,
  } = props;
  const { useComponents } = useViews();
  const { useTypographies, useButtons, useBadges, useModals } = useComponents();
  const { TypographyComponent } = useTypographies();
  const { ButtonComponent } = useButtons();
  const { DefaultModal } = useModals();

  const { DefaultBadge } = useBadges();

  return (
    <>
      <TypographyComponent
        text="Seleccionar tipo de experiencia artística"
        variant="h3"
        className="font-bold text-primary mb-1"
      />
      <TypographyComponent
        text="Te invitamos a identificar tu perfil artístico seleccionando todas las opciones que te apliquen de esta lista"
        variant="p"
        className="text-neutral-950  mb-1"
      />
      <StyledWarpperCategories>
        {_.map(optionsSpecialities, (speciality) => {
          return (
            <DefaultBadge
              key={`specialityItem-${speciality.id}`}
              label={speciality.name}
              type="light"
              className="StyledBadge cursor-pointer"
              variant={speciality.selected ? 'primary' : 'secondary'}
              onClick={() => handleToogleSpeciality({ id: speciality.id })}
            />
          );
        })}

        <ButtonComponent
          variant="primary"
          text="Guardar cambios"
          className="mx-auto mt-8"
          onPress={handleSaveNewSpecialities}
        />
      </StyledWarpperCategories>

      <DefaultModal
        isActive={openModalDiscardChanges}
        classNameContent="max-w-md w-11/12"
        handleClose={handleCloseModalDiscardChanges}
      >
        <TypographyComponent
          text="Descartar cambios        "
          variant="h3"
          className="font-bold text-primary mb-1"
        />
        <TypographyComponent
          text="¿Estas seguro que deseas descartar los cambios que has realizado?"
          variant="p"
          className="text-neutral-950  mb-1"
        />
        <StyledContentButtons>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto "
            mode="outline"
            onPress={handleCloseModalDiscardChanges}
          />
          <ButtonComponent
            variant="primary"
            text="Descartar cambios"
            className="mx-auto w-full lg:w-auto "
            onPress={handleDiscardChanges}
          />
        </StyledContentButtons>
      </DefaultModal>
    </>
  );
};

AddExperience.propTypes = {
  optionsSpecialities: PropTypes.array,
  handleToogleSpeciality: PropTypes.func,
  openModalDiscardChanges: PropTypes.bool,
  handleCloseModalDiscardChanges: PropTypes.func,
  handleDiscardChanges: PropTypes.func,
  handleSaveNewSpecialities: PropTypes.func,
};

AddExperience.defaultProps = {
  optionsSpecialities: [],
  openModalDiscardChanges: false,
  handleToogleSpeciality: () => {},
  handleCloseModalDiscardChanges: () => {},
  handleDiscardChanges: () => {},
  handleSaveNewSpecialities: () => {},
};

export default AddExperience;
