import React, { lazy } from 'react';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';

//Styles
const StyledList = lazy(() =>
  import('./CustomLegend.styles').then((mod) => ({
    default: mod.StyledList,
  }))
);
const StyledIcon = lazy(() =>
  import('./CustomLegend.styles').then((mod) => ({
    default: mod.StyledIcon,
  }))
);

const CustomLegend = (props) => {
  const { payload } = props;

  const { useComponents } = useViews();
  const { useBadges } = useComponents();
  const { DefaultBadge } = useBadges();

  return (
    <ul className="grid gap-1 grid-cols-1 2xl:gap-4 2xl:grid-cols-2">
      {payload.map((entry, index) => (
        <StyledList color={entry.color} key={`item-${index}`}>
          <StyledIcon />
          <p>{entry.value}</p>
          <DefaultBadge
            type="light"
            className="ml-6"
            label={`${entry.payload.answers} ${
              entry.payload.answers === 1 ? 'respuesta' : 'respuestas'
            }`}
          />
        </StyledList>
      ))}
    </ul>
  );
};

CustomLegend.defaultProps = {
  payload: 'default',
};
CustomLegend.propTypes = {
  payload: PropTypes.array,
};

export default CustomLegend;
