import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useAdminCommunitiesProviders = () => {
  const getCommunitiesListByProject = ({ projectId, paginate = 0, name }) => {
    const response = axios({
      method: 'GET',
      url: `/projects/${projectId}/communities`,
      params: {
        paginate,
        name,
      },
    });
    return trackPromise(response);
  };
  const getCommunitiesList = () => {
    const response = axios({
      method: 'GET',
      url: `/communities`,
    });
    return trackPromise(response);
  };

  const getCommunityDetailById = ({ communityId, projectId }) => {
    const response = axios({
      method: 'GET',
      url: `/projects/${projectId}/communities/${communityId}`,
    });
    return trackPromise(response);
  };

  const createCommunityById = ({ formData, projectId }) => {
    const response = axios({
      method: 'POST',
      headers: {
        'content-type': 'multipart/form-data',
        'alert-dialog': 'hidden',
      },
      url: `/projects/${projectId}/communities`,
      data: formData,
    });
    return trackPromise(response);
  };

  const updateCommunityById = ({ formData, projectId, communityId }) => {
    const response = axios({
      method: 'POST',
      headers: {
        'content-type': 'multipart/form-data',
        'alert-dialog': 'hidden',
      },
      url: `/projects/${projectId}/communities/${communityId}`,
      data: formData,
    });
    return trackPromise(response);
  };

  const deleteCommunityById = ({ projectId, communityId }) => {
    const response = axios({
      method: 'DELETE',
      url: `/projects/${projectId}/communities/${communityId}`,
    });
    return trackPromise(response);
  };

  return {
    getCommunitiesListByProject,
    getCommunityDetailById,
    createCommunityById,
    updateCommunityById,
    deleteCommunityById,
    getCommunitiesList,
  };
};

export default useAdminCommunitiesProviders;
