//Packages
import _ from 'lodash';

//Hooks
import useHelpers from '../../../../helpers';
import useStrings from '../../../../strings';
import useInitialStates from '../../../initialStates';

const useAdminLabelsReducers = () => {
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  const { useAdminInitialStates } = useInitialStates();
  const { useAdminLabelsInitialStates } = useAdminInitialStates();
  const { initialStateAdminLabels } = useAdminLabelsInitialStates();

  const { useAdminTypes, useAuthTypes } = useStrings();
  const { useAdminLabelsTypes } = useAdminTypes();
  const { GET_LABELS, DELETE_LABELS, UPDATE_LABELS } = useAdminLabelsTypes();
  const { LOGOUT } = useAuthTypes();

  const labels = createReducer(initialStateAdminLabels, {
    [GET_LABELS](state, action) {
      return [...action.payload];
    },
    [DELETE_LABELS](state, action) {
      const { labelId } = action.payload;
      const listLabelsUpdated = _.filter(
        state,
        (labels) => labels.id !== labelId
      );
      return [...listLabelsUpdated];
    },
    [UPDATE_LABELS](state, action) {
      const { labelId, data } = action.payload;
      const listLabelsUpdated = _.map(state, (labels) => {
        if (labels.id === labelId) {
          return { id: labelId, ...data };
        } else {
          return labels;
        }
      });
      return [...listLabelsUpdated];
    },

    [LOGOUT]() {
      return initialStateAdminLabels;
    },
  });

  return { labels };
};

export default useAdminLabelsReducers;
