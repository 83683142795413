import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { useForm } from 'react-hook-form';

// Hooks
import useApi from 'api';
import useModels from 'models';
import useStrings from 'strings';
import useHelpers from 'helpers';

const useAdminExecuteIntervention = () => {
  const { intervention_id: interventionId } = useParams();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();
  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const {
    useAdminDevelopmentExecuteInterventionActions,
    useAdminDevelopmentInterventionDefineInterventionActions,
  } = useAdminViewInterventionDevelopmentInterventionActions();
  const {
    actGetExecuteIntervention,
    actUpdateExecuteIntervention,
    actCreateEventExecuteIntervention,
    actUpdateEventExecuteIntervention,
    actDeleteEventExecuteIntervention,
  } = useAdminDevelopmentExecuteInterventionActions();
  const { actGetDefineProjectIntervention } =
    useAdminDevelopmentInterventionDefineInterventionActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionDetailToEditSelector, getInterventionsSelector } =
    useAdminInterventionsSelectors();
  const { communities } = useSelector(getInterventionDetailToEditSelector);
  const { executeIntervention, define_intervention: defineIntervention } =
    useSelector(getInterventionsSelector);
  const { id: executeId } = executeIntervention;

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { REQUIRED_FIELD, DATE_GREATER_THAN_TODAY, MAX_LENGTH_100 } =
    useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const { isSelectedDateGreaterThanTodayDate } = useQuickFunctions();

  const [showModalNoEvents, setShowModalNoEvents] = useState(false);
  const [openModalApprove, setOpenModalApprove] = useState(false);

  const schemaExecuteInterventionEvent = yup.object({
    id: yup.number().nullable().notRequired(),
    intervention: yup.object().required(REQUIRED_FIELD),
    modality: yup.object().required(REQUIRED_FIELD),
    date: yup
      .date()
      .typeError(REQUIRED_FIELD)
      .required(REQUIRED_FIELD)
      .test('dateValidation', DATE_GREATER_THAN_TODAY, (value) => {
        return value
          ? isSelectedDateGreaterThanTodayDate({ selectedDate: value })
          : true;
      }),
    duration: yup.string().required(REQUIRED_FIELD),
    community: yup.object().required(REQUIRED_FIELD),
    place: yup
      .string()
      .typeError(REQUIRED_FIELD)
      .max(100, MAX_LENGTH_100)
      .required(REQUIRED_FIELD),
  });

  const formEvent = useForm({
    resolver: yupResolver(schemaExecuteInterventionEvent),
    mode: 'onChange',
  });

  const interventionOptions = _.map(
    defineIntervention.definition_interventions,
    (di) => ({
      label: di.name,
      value: di.id,
    })
  );

  const communityOptions = useMemo(() => {
    return _.map(communities, ({ name, id }) => ({
      label: name,
      value: id,
    }));
  }, [communities]);

  useEffect(() => {
    if (executeIntervention.state?.id !== 'c') {
      dispatch(
        actGetDefineProjectIntervention({
          intervention_id: interventionId,
        })
      );
      dispatch(
        actGetExecuteIntervention({
          interventionId,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (formEvent.watch('intervention')?.value) {
      const interventionSelected =
        defineIntervention.definition_interventions.find(
          (di) => di.id === formEvent.watch('intervention')?.value
        );
      formEvent.setValue('modality', {
        label: interventionSelected.modality.name,
        value: interventionSelected.modality.id,
      });
      formEvent.setValue('duration', interventionSelected.duration.slice(0, 5));
      formEvent.clearErrors('modality');
      formEvent.clearErrors('duration');
    }
  }, [formEvent.watch('intervention')]);

  const saveEvent = (event) => {
    if (event.id) {
      dispatch(
        actUpdateEventExecuteIntervention({
          interventionId,
          executeId,
          eventId: event.id,
          data: event,
        })
      );
    } else {
      dispatch(
        actCreateEventExecuteIntervention({
          interventionId,
          executeId,
          data: event,
        })
      );
    }
  };

  const deleteEvent = (event) => {
    dispatch(
      actDeleteEventExecuteIntervention({
        interventionId,
        executeId,
        eventId: event.id,
      })
    );
  };

  const updateExecuteIntervention = () => {
    setOpenModalApprove(false);
    dispatch(
      actUpdateExecuteIntervention({
        interventionId,
        executeId,
        data: { state: 'ip' },
      })
    );
  };

  return {
    executeIntervention,
    formEvent,
    showModalNoEvents,
    setShowModalNoEvents,
    openModalApprove,
    setOpenModalApprove,
    interventionOptions,
    communityOptions,
    canEdit: !_.includes(['ip', 'c'], executeIntervention.state.id),
    saveEvent,
    deleteEvent,
    updateExecuteIntervention,
  };
};

export default useAdminExecuteIntervention;
