//Hooks
import useHelpers from 'helpers';
import useStrings from 'strings';
import useInitialStates from '../../../initialStates';

const useAdminUsersReducers = () => {
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  const { useAdminInitialStates } = useInitialStates();
  const { useAdminUsersInitialStates } = useAdminInitialStates();
  const { inititalStateAdminUsersFilter } = useAdminUsersInitialStates();

  const { useAdminTypes, useAuthTypes } = useStrings();
  const { useAdminUsersTypes } = useAdminTypes();
  const { LOGOUT } = useAuthTypes();
  const {
    ADD_DATA_FILTER_ADMIN_USERS,
    SAVE_DATA_FILTER_ADMIN_USERS,
    RESET_DATA_FILTER_ADMIN_USERS,
    RESET_ALL_DATA_FILTER_ADMIN_USERS_CONTACTS,
  } = useAdminUsersTypes();

  const filterDataAdminUsers = createReducer(inititalStateAdminUsersFilter, {
    [ADD_DATA_FILTER_ADMIN_USERS](state, action) {
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    },
    [SAVE_DATA_FILTER_ADMIN_USERS](state, action) {
      return { ...state, ...action.payload };
    },
    [RESET_DATA_FILTER_ADMIN_USERS](state, action) {
      return {
        ...inititalStateAdminUsersFilter,
        ...action.payload,
        search: state.search,
        orderByKey: state.orderByKey,
        orderByValue: state.orderByValue,
      };
    },
    [RESET_ALL_DATA_FILTER_ADMIN_USERS_CONTACTS]() {
      return inititalStateAdminUsersFilter;
    },
    [LOGOUT]() {
      return inititalStateAdminUsersFilter;
    },
  });

  return { filterDataAdminUsers };
};

export default useAdminUsersReducers;
