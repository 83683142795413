//Packages
import { useEffect, useState, useMemo, useRef } from 'react';
import _ from 'lodash';

//Hooks
import useApi from 'api';
import useModels from 'models';
import useControllers from 'controllers';

const useAdminRootLabels = ({ setResetAllCheckboxes } = {}) => {
  const [activeModalDelete, setActiveModalDelete] = useState({ active: false });
  const [activeModalCreate, setActiveModalCreate] = useState({ active: false });
  const [activeModalEdit, setActiveModalEdit] = useState({ active: false });
  const [idLabel, setIdLabel] = useState(null);
  const [textSearch, setTextSearch] = useState('');
  const textSearchRef = useRef(null);
  const [orderBy, setOrderBy] = useState({
    name: true,
    color: true,
  });
  const [orderByKeyValue, setorderByKeyValue] = useState(null);

  const { useActions } = useApi();
  const { dispatch, useGeneralActions, useAdminActions } = useActions();
  const { useAdminLabelsActions } = useAdminActions();
  const { actGetLabels, actDeleteLabels, actAssignLabelToContacts } =
    useAdminLabelsActions();
  const { useColorsActions } = useGeneralActions();
  const { actGetColors } = useColorsActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminLabelsSelectors } = useAdminSelectors();
  const { getLabelsListSelector } = useAdminLabelsSelectors();
  const labels = useSelector(getLabelsListSelector);
  useEffect(() => {
    dispatch(actGetColors());
    dispatch(actGetLabels({ paginate: 0 }));
  }, []);

  const { useGeneralHooks } = useControllers();
  const { useIsFilterEmpty } = useGeneralHooks();
  const { isFilterEmpty } = useIsFilterEmpty();

  useEffect(() => {
    if (textSearch.length >= 3) {
      textSearchRef.current = true;
      dispatch(
        actGetLabels({
          name: textSearch,
          orderByKey: orderByKeyValue,
          orderByValue: orderBy[orderByKeyValue] ? 'asc' : 'desc',
        })
      );
    } else if (textSearchRef.current) {
      textSearchRef.current = false;
      dispatch(actGetLabels({ paginate: 0 }));
    }
  }, [textSearch]);

  const labelslist = useMemo(() =>
    _.map(labels, (labels) => {
      return {
        labels,
      };
    })
  );

  const handleDeleteLabels = (id) => {
    dispatch(actDeleteLabels({ labelId: id }));
  };

  const handleOrderBy = ({ orderByKey }) => {
    dispatch(
      actGetLabels({
        paginate: 0,
        orderByKey,
        name: textSearch.length >= 3 ? textSearch : undefined,
        orderByValue: orderBy[orderByKey] ? 'desc' : 'asc',
      })
    );
    setOrderBy((prevState) => ({
      ...prevState,
      [orderByKey]: !prevState[orderByKey],
    }));
    setorderByKeyValue(orderByKey);
  };

  const resetCheckboxesOnRequestResponse = () => {
    setResetAllCheckboxes(false);
    setTimeout(() => {
      setResetAllCheckboxes(true);
    }, 500);
  };

  const assignLabelToContacts = (
    { tag, users },
    setActiveDropDownFilterTagsAll,
    handleStateDropDownTagsByRow
  ) => {
    dispatch(
      actAssignLabelToContacts(
        { tag, users },
        () => {
          setActiveDropDownFilterTagsAll({ active: false });
          handleStateDropDownTagsByRow((prevValue) => {
            return {
              ...prevValue,
              active: false,
            };
          });
          resetCheckboxesOnRequestResponse();
        },
        resetCheckboxesOnRequestResponse
      )
    );
  };

  return {
    isFilterEmpty,
    activeModalDelete,
    setActiveModalDelete,
    activeModalCreate,
    setActiveModalCreate,
    activeModalEdit,
    setActiveModalEdit,
    handleDeleteLabels,
    labelslist,
    idLabel,
    setIdLabel,
    textSearch,
    setTextSearch,
    handleOrderBy,
    assignLabelToContacts,
    orderBy,
  };
};

export default useAdminRootLabels;
