import React, { lazy } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';

// Hooks
import useViews from 'views';
import CustomLegend from './components/CustomLegend';
import useControllers from 'controllers';

const StyledDivider = lazy(() =>
  import(
    '../../../../../../../../../../../../../styles/formViews/formViews.styles'
  ).then((mod) => ({
    default: mod.StyledDivider,
  }))
);

const StyledContentChart = lazy(() =>
  import('./Quantitative.styles').then((mod) => ({
    default: mod.StyledContentChart,
  }))
);

const wrapperStyle = {
  top: '35%',
  left: '100%',
  transform: 'translate(0, -50%)',
  lineHeight: '24px',
  width: '200%',
};

const MessageAnswers = (props) => {
  const { messages, communities } = props;

  const { useComponents } = useViews();
  const { useTypographies, usePagers } = useComponents();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { Pager } = usePagers();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useDeepenKnowledgeCommunity } =
    useAdminInterventionsDashboardControllers();
  const { useAdminDeepenKnowledgeMessagesResults } =
    useDeepenKnowledgeCommunity();
  const {
    DATA_CHART_COLORS,
    messagesData,
    getYearMonthDayFromDate,
    getAnswersPage,
  } = useAdminDeepenKnowledgeMessagesResults({ messages });

  return (
    <>
      {_.map(messagesData, (message) => {
        const {
          id,
          body,
          channel,
          description,
          messageRequest,
          type_information,
          answers,
        } = message;
        const { result, created_at } = messageRequest;
        const { data: answersData, meta, totalAnswers } = answers ?? {};

        return (
          <div key={id}>
            <TypographyWithTooltip
              text={`${
                !type_information || type_information?.id === 'ql'
                  ? 'Mensajes de difusión'
                  : 'Recolección de información'
              }`}
              variant="h3"
              textTooltip={`${
                !type_information || type_information?.id === 'ql'
                  ? 'Mensajes de difusión'
                  : 'Recolección de información'
              }`}
              className="text-primary mt-6"
              idTooltip="information_collection"
              applyFontBold
            />
            <div className="border rounded bg-neutral-400 px-6 py-8 mt-4">
              <TypographyComponent
                text={description}
                variant="h3"
                className="text-primary font-bold mb-2"
              />
              <p className="text-neutral-800 mb-8 mr-1">
                Fecha de {type_information ? 'inicio' : 'envío'}:{' '}
                <strong>
                  {`${getYearMonthDayFromDate({
                    dateInString: created_at,
                    format: 'dmyText',
                  })}`.toLowerCase()}
                </strong>
              </p>

              {type_information?.name && (
                <TypographyComponent
                  text={
                    <label className="text-primary">
                      <strong>Tipo de información a recolectar: </strong>
                      <span className="text-neutral-950 ml-1  ">
                        {type_information.name}
                      </span>
                    </label>
                  }
                  variant="p"
                  className="text-neutral-950 mt-2 mb-2 font-bold"
                />
              )}

              {type_information && (
                <TypographyComponent
                  text={
                    <div>
                      <h4 className="font-bold text-primary block mr-1">
                        Pregunta
                      </h4>
                      <p className="font-bold text-lg">{body}</p>
                    </div>
                  }
                  variant="label"
                  className="mb-10"
                />
              )}
              {!type_information && (
                <div className="rounded bg-neutral px-6 py-4">
                  <TypographyComponent
                    text={body}
                    variant="p"
                    className="text-neutral-950 mt-0 "
                  />
                </div>
              )}

              <TypographyComponent
                text={
                  <label className="text-primary">
                    <strong>Destino:</strong>
                    <span className="text-neutral-950 ml-1 ">
                      {' '}
                      {_.map(communities, (c) => c.name).join(', ')}
                    </span>
                  </label>
                }
                variant="p"
                className="text-neutral-950 mt-2 mb-1"
              />

              <StyledDivider />

              {(!type_information || type_information?.id === 'ql') && (
                <>
                  <div className="flex justify-start items-start mt-4 mb-8">
                    <div className="border-r-2 px-4">
                      <TypographyComponent
                        text="Mensajes enviados"
                        variant="p"
                        className="text-neutral-950 mt-0 "
                      />
                      <TypographyComponent
                        text={`${result.sended}`}
                        variant="h2"
                        className="text-primary font-bold "
                      />
                    </div>
                    {type_information?.id === 'ql' && (
                      <div className="border-r-2 px-4">
                        <TypographyComponent
                          text="Respuestas recibidas"
                          variant="p"
                          className="text-neutral-950 mt-0 "
                        />
                        <TypographyComponent
                          text={`${result.replied}`}
                          variant="h2"
                          className="text-primary font-bold"
                        />
                      </div>
                    )}
                    <div className="px-4">
                      <TypographyComponent
                        text="Canal de comunicación"
                        variant="p"
                        className="text-neutral-950 mt-0 "
                      />
                      <TypographyComponent
                        text={channel.name}
                        variant="h2"
                        className="text-primary font-bold"
                      />
                    </div>
                  </div>

                  <ul className="mt-8 space-y-2">
                    {_.map(answersData, (answer, idx) => (
                      <li key={idx} className="rounded bg-neutral px-6 py-4">
                        <TypographyComponent
                          text={answer.response}
                          variant="p"
                          className="text-neutral-950 mt-0 "
                        />
                        <TypographyComponent
                          text={`
                          ${getYearMonthDayFromDate({
                            dateInString: answer.reply_date,
                            format: 'dmyShort',
                          })} -
                          ${getYearMonthDayFromDate({
                            dateInString: answer.reply_date,
                            format: 'time',
                          })}
                        `}
                          variant="h5"
                          className="text-neutral-800 mt-0 "
                        />
                      </li>
                    ))}
                  </ul>

                  {meta?.last_page > 1 && (
                    <Pager
                      pageCount={meta?.last_page}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      className="flex items-center lg:justify-end justify-center my-6"
                      onPageChange={({ selected }) =>
                        getAnswersPage({ message, page: selected + 1 })
                      }
                      nextLabel
                      previousLabel
                    />
                  )}
                </>
              )}
              {type_information?.id === 'qn' && result.replied > 0 && (
                <StyledContentChart hidden={channel.id === 'sms'}>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        dataKey="value"
                        isAnimationActive
                        data={_.map(
                          answersData?.options?.length > 0
                            ? answersData.options
                            : [],
                          ({ option, responses }, idx) => ({
                            name: option.text,
                            value: (responses / totalAnswers) * 1000,
                            fill: DATA_CHART_COLORS[
                              idx % DATA_CHART_COLORS.length
                            ],
                            answers: responses,
                          })
                        )}
                        cx="159"
                        cy="159"
                        outerRadius={100}
                        fill="#8884d8"
                      />
                      <Legend
                        wrapperStyle={wrapperStyle}
                        content={CustomLegend}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </StyledContentChart>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

MessageAnswers.propTypes = {
  messages: PropTypes.array.isRequired,
  communities: PropTypes.array,
};

export default MessageAnswers;
