//Packages
import React, { Suspense, useState } from 'react';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Components
import DropDownFilter from './components/DropDownFilter/DropDownFilter';

//Assets
import imageFakeHistory from 'assets/img/fake-card-example.jpg';

//Styles
import { StyledBackButton } from 'styles/formViews/formViews.styles';
import {
  StyledTopFilters,
  StyledFilters,
  StyledInputSearchContent,
  StyledContentButtonsModal,
} from 'styles/tableViews/tableViews.styles';
import { StyledOtherConfigs } from 'styles/projects/dashboard.styles';
import { StyledDivider } from 'styles/formViews/formViews.styles';

const AdminRootInterventions = () => {
  const { useLayouts, useComponents } = useViews();

  const { AdminLayout } = useLayouts();
  const {
    useButtons,
    useTypographies,
    useInputs,
    useCards,
    usePagers,
    useModals,
    useLoaders,
  } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { ButtonComponent } = useButtons();
  const { TypographyComponent } = useTypographies();
  const { InputSearch, DefaultSwitch } = useInputs();
  const { CardIntervention, CardEmptyState } = useCards();
  const { Pager } = usePagers();
  const { DefaultModal } = useModals();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminHome, useAdminContentManagementInterventionsControllers } =
    useAdmin();
  const { useAdminHomeRoot } = useAdminHome();
  const { handleGoBack } = useAdminHomeRoot();
  const { useAdminContentManagementInterventionsRoot } =
    useAdminContentManagementInterventionsControllers();
  const {
    interventionsCompleted,
    handleChangePage,
    handleGoToEditIntervention,
    updateInterventionEnableVisibility,
    textSearch,
    setTextSearch,
    setInterventionsCompleted,
    isFilterEmpty,
  } = useAdminContentManagementInterventionsRoot();

  const [activeDropDown, setActiveDropDown] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [isOnFocusTextSearch, setIsOnFocusTextSearch] = useState(false);

  const handleOpenMenu = () => {
    setActiveDropDown(!activeDropDown);
  };

  return (
    <Suspense fallback={<DefaultLoader />}>
      <AdminLayout>
        <>
          <StyledBackButton className="mt-3" onClick={handleGoBack}>
            Regresar
          </StyledBackButton>

          {!(!isFilterEmpty && interventionsCompleted.data?.length === 0) && (
            <StyledTopFilters className="mt-6" justifyEnd>
              <StyledFilters className="relative">
                <ButtonComponent
                  text="Filtros"
                  mode="outline"
                  className="icon-filter flex items-center space-x-2"
                  onPress={handleOpenMenu}
                />
                <StyledInputSearchContent>
                  <InputSearch
                    text={textSearch}
                    onDebounce={setTextSearch}
                    handlerIsOnFocus={(isOnFocus) =>
                      setIsOnFocusTextSearch(isOnFocus)
                    }
                  />
                </StyledInputSearchContent>
                <DropDownFilter
                  open={activeDropDown}
                  isOnFocusTextSearch={isOnFocusTextSearch}
                  handleOpenMenu={handleOpenMenu}
                  setInterventionsCompleted={setInterventionsCompleted}
                  setActiveDropDown={setActiveDropDown}
                />
              </StyledFilters>
            </StyledTopFilters>
          )}

          {interventionsCompleted.data?.length ? (
            <StyledOtherConfigs>
              {_.map(interventionsCompleted?.data, (item, idx) => (
                <CardIntervention
                  key={`project-se-${idx}`}
                  className="w-full  "
                  classNameContentTop="flex-col"
                  image={item.image?.url || imageFakeHistory}
                  title={item.name}
                  location={`${item?.project?.city?.department?.name}, ${item?.project?.city?.department?.country?.name}`}
                  projectName={`Proyecto: ${item?.project?.name}`}
                  typeList={[
                    { name: item.objetive, type: 'primary' },
                    { name: item.type.name, type: 'secondary' },
                  ]}
                  onClick={() => {}}
                  editMode
                  handleEdit={(e) => {
                    e.stopPropagation();
                    handleGoToEditIntervention({ interventionId: item?.id });
                  }}
                  handleDelete={null}
                >
                  <>
                    <StyledDivider spacexs />
                    <div className="flex items-center">
                      <DefaultSwitch
                        defaultChecked={item?.visible_in_front}
                        onChange={(isActive) =>
                          updateInterventionEnableVisibility({
                            data: isActive,
                            interventionId: item?.id,
                          })
                        }
                      />
                      <TypographyComponent
                        className="ml-2"
                        variant="p"
                        text="Habilitar vista en el home"
                      />
                    </div>
                  </>
                </CardIntervention>
              ))}
            </StyledOtherConfigs>
          ) : (
            <CardEmptyState
              className="w-8/12 mx-auto mt-16"
              title={
                isFilterEmpty
                  ? 'No hay resultados, utiliza nuevos criterios de búsqueda'
                  : 'No se han encontrado intervenciones completadas'
              }
            />
          )}

          {interventionsCompleted.data?.length > 0 && (
            <Pager
              pageCount={interventionsCompleted?.meta?.last_page || 1}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              className="flex items-center lg:justify-end justify-center my-6"
              onPageChange={({ selected }) => handleChangePage(selected)}
              previousLabel={Boolean(interventionsCompleted?.links?.prev)}
              nextLabel={Boolean(interventionsCompleted?.links?.next)}
            />
          )}
        </>
      </AdminLayout>

      <DefaultModal
        isActive={showModalDelete}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={() => setShowModalDelete(false)}
      >
        <TypographyComponent
          text="Eliminar Intervención"
          variant="h3"
          className="font-bold text-primary mb-1"
        />
        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto lg:mx-2 "
            mode="outline"
            onPress={() => {}}
          />
          <ButtonComponent
            variant="primary"
            text="Aceptar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={() => {}}
          />
        </StyledContentButtonsModal>
      </DefaultModal>
    </Suspense>
  );
};

export default AdminRootInterventions;
