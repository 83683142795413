//Hooks
import useStrings from '../../../../../../../../strings';
import useProviders from '../../../../../../../providers';

const useAdminDevelopmentInterventionCreativePlanActions = () => {
  const { useAdminProviders } = useProviders();
  const { useAdminProjectsProviders } = useAdminProviders();
  const { useAdminInterventionsProviders } = useAdminProjectsProviders();
  const { useAdminViewInterventionProviders } =
    useAdminInterventionsProviders();
  const { useAdminViewInterventionDevelopmentInterventionProviders } =
    useAdminViewInterventionProviders();
  const { useAdminDevelopmentInterventionCreativePlanProviders } =
    useAdminViewInterventionDevelopmentInterventionProviders();
  const {
    deleteCreativePlanInterventionProvider,
    downloadFileCreativePlanInterventionProvider,
    getCreativePlanInterventionProvider,
    updateCreativePlanInterventionProvider,
  } = useAdminDevelopmentInterventionCreativePlanProviders();

  const { useAdminTypes } = useStrings();
  const { useAdminProjectsTypes } = useAdminTypes();
  const { useAdminInterventionsTypes } = useAdminProjectsTypes();
  const { useAdminViewInterventionsTypes } = useAdminInterventionsTypes();
  const { useAdminViewInterventionsDevelopmentInterventionTypes } =
    useAdminViewInterventionsTypes();
  const { useAdminDevelopmentInterventionCreativePlanTypes } =
    useAdminViewInterventionsDevelopmentInterventionTypes();
  const {
    GET_CREATIVE_PLAN_INTERVENTION,
    UPDATE_CREATIVE_PLAN_INTERVENTION,
    DELETE_CREATIVE_PLAN_INTERVENTION,
    DOWNLOAD_FILE_CREATIVE_PLAN_INTERVENTION,
  } = useAdminDevelopmentInterventionCreativePlanTypes();

  const actGetCreativePlanIntervention =
    ({ intervention_id }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await getCreativePlanInterventionProvider({
          intervention_id,
        });
        dispatch({
          type: GET_CREATIVE_PLAN_INTERVENTION,
          payload: response.data,
        });
        onSuccess && onSuccess(response.data);
      } catch (e) {
        onError && onError(e);
      }
    };
  const actUpdateCreativePlanIntervention =
    ({ intervention_id, creative_plan_id, data }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await updateCreativePlanInterventionProvider({
          intervention_id,
          creative_plan_id,
          data,
        });
        dispatch({
          type: UPDATE_CREATIVE_PLAN_INTERVENTION,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (e) {
        onError && onError(e);
      }
    };
  const actDeleteCreativePlanIntervention =
    ({ intervention_id, creative_plan_id }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await deleteCreativePlanInterventionProvider({
          intervention_id,
          creative_plan_id,
        });
        dispatch({
          type: DELETE_CREATIVE_PLAN_INTERVENTION,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (e) {
        onError && onError(e);
      }
    };
  const actDownloadFileCreativePlanIntervention =
    ({ intervention_id, creative_plan_id, file }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await downloadFileCreativePlanInterventionProvider({
          intervention_id,
          creative_plan_id,
          file,
        });
        dispatch({
          type: DOWNLOAD_FILE_CREATIVE_PLAN_INTERVENTION,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (e) {
        onError && onError(e);
      }
    };

  return {
    actGetCreativePlanIntervention,
    actUpdateCreativePlanIntervention,
    actDeleteCreativePlanIntervention,
    actDownloadFileCreativePlanIntervention,
  };
};

export default useAdminDevelopmentInterventionCreativePlanActions;
