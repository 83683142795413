//Packages
import React from 'react';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';

//Styles
import { StyledGroup } from 'styles/formViews/formViews.styles';

const Create = (props) => {
  const {
    formCreateTask,
    handleTaksAssignationForInterention,
    handleCancelCreateEditTask,
    handleEditTaskAssignationForIntervention,
    taskIdForIntervention,
    isEditButtonActiveForTask,
  } = props;

  const { useComponents } = useViews();
  const { useInputs, useButtons } = useComponents();

  const { TextArea, InputForm } = useInputs();
  const { ButtonComponent } = useButtons();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formCreateTask;

  return (
    <div className="border rounded py-8 px-6 mt-4 bg-white">
      <StyledGroup col2 flex className="mb-4 flex-col">
        <InputForm
          name="name"
          label="Nombre de la tarea*"
          type="text"
          className="full"
          placeholder="Ingresa el Nombre de la tarea*"
          errors={errors}
          {...register('name')}
        />
      </StyledGroup>
      <StyledGroup col2 flex className="mb-4 flex-col">
        <TextArea
          name="description"
          placeholder="Descripción de la tarea*"
          className="w-full col-span-2"
          label="Descripción de la tarea*"
          errors={errors}
          {...register('description')}
        />
      </StyledGroup>

      <div className="flex items-center space-x-2 mt-10 justify-end">
        <ButtonComponent
          text="Cancelar"
          mode="outline"
          onPress={handleCancelCreateEditTask}
        />
        <ButtonComponent
          text={'Guardar tarea'}
          onPress={handleSubmit(
            taskIdForIntervention
              ? handleEditTaskAssignationForIntervention
              : handleTaksAssignationForInterention
          )}
          disabled={taskIdForIntervention ? isEditButtonActiveForTask : false}
        />
      </div>
    </div>
  );
};

Create.propTypes = {
  handleTaksAssignationForInterention: PropTypes.func.isRequired,
  handleCancelCreateEditTask: PropTypes.func.isRequired,
  handleEditTaskAssignationForIntervention: PropTypes.func.isRequired,
  formCreateTask: PropTypes.object.isRequired,
  taskIdForIntervention: PropTypes.number,
  isEditButtonActiveForTask: PropTypes.bool,
};

Create.defaultProps = {
  taskIdForIntervention: 0,
  isEditButtonActiveForTask: false,
};

export default Create;
