//Packages
import React, { Suspense, lazy, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Assets
import arrowDown from 'assets/icons/arrow-down-table.svg';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
const StyledTopFilters = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledTopFilters,
  }))
);

const StyledFilters = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledFilters,
  }))
);

const StyledInputSearchContent = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledInputSearchContent,
  }))
);

const StyledContentTable = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentTable,
  }))
);

const StyledContentButtons = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const StyledTableTitle = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledTableTitle,
  }))
);

const StyledContentButtonsModal = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const List = (props) => {
  const { setActiveTab, tabValue } = props;
  const { useComponents } = useViews();

  const {
    useButtons,
    useImages,
    useTables,
    usePagers,
    useModals,
    useTypographies,
    useInputs,
    useCards,
    useLoaders,
  } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { CardEmptyState } = useCards();
  const { InputSearch, InputForm } = useInputs();
  const { ButtonComponent } = useButtons();
  const { Image } = useImages();
  const { Pager } = usePagers();
  const { DefaultModal } = useModals();
  const { TypographyComponent } = useTypographies();
  const { Table, TableHead, TableRow, TableHeadCell, TableCell, TableBody } =
    useTables();

  const [activeModal, setActiveModal] = useState({ active: false });
  const [activeEditModal, setActiveEditModal] = useState({ active: false });

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminCommunitiesDashboardHooks } =
    useAdminDashboardProjectsHooks();
  const { useAdminContactsCommunitiesDashboardHooks } =
    useAdminCommunitiesDashboardHooks();
  const { useAdminRootContactsListCommunitiesDashboard } =
    useAdminContactsCommunitiesDashboardHooks();
  const {
    isFilterEmpty,
    contactsLists,
    getContactList,
    register,
    errors,
    updateContactList,
    deleteContactList,
    setValue,
    onChangePage,
    setTextSearch,
    textSearch,
    handleOrderBy,
    orderBy,
  } = useAdminRootContactsListCommunitiesDashboard({
    tabValue,
    isScreenContactList: true,
  });

  const allLists = useMemo(() => {
    return _.map(contactsLists?.data, (contactList) => ({
      title: contactList.name,
      id: contactList.id,
    }));
  }, [contactsLists]);

  const tableHeader = [
    { header: 'Nombre lista ', orderByKey: 'name' },
    { header: '' },
  ];

  return (
    <Suspense fallback={<DefaultLoader />}>
      <>
        <StyledTopFilters>
          <div />

          {!(!isFilterEmpty && allLists?.length === 0) && (
            <StyledFilters className="relative">
              <StyledInputSearchContent>
                <InputSearch text={textSearch} onDebounce={setTextSearch} />
              </StyledInputSearchContent>
            </StyledFilters>
          )}
        </StyledTopFilters>

        <StyledContentTable>
          <Table className="w-full">
            <TableHead>
              <TableRow>
                {_.map(tableHeader, (item, idx) => (
                  <TableHeadCell
                    className={`text-left py-4 px-5 ${
                      !item.noneOrder && allLists.length
                        ? 'cursor-pointer'
                        : 'cursor-not-allowed'
                    }`}
                    key={`tableHeadCell-${idx}`}
                    onClick={() => {
                      !item.noneOrder &&
                        allLists.length &&
                        handleOrderBy({ orderByKey: item.orderByKey });
                    }}
                  >
                    {item.header !== '' && (
                      <StyledTableTitle>
                        <TypographyComponent
                          text={item.header}
                          variant="small"
                        />
                        {!item.noneOrder && (
                          <Image
                            srcImage={arrowDown}
                            className={`ml-2 ${
                              !orderBy[item.orderByKey] &&
                              allLists.length &&
                              'order-arrow cursor-pointer'
                            }`}
                          />
                        )}
                      </StyledTableTitle>
                    )}
                  </TableHeadCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {allLists.length ? (
                _.map(allLists, (list, idx) => (
                  <TableRow key={`tableRow-${idx}`}>
                    <TableCell
                      handleClick={() => {
                        getContactList(
                          { contactListId: list.id },
                          setActiveTab({ contactList: list, active: 2 })
                        );
                      }}
                      className="py-2 px-5 w-full cursor-pointer"
                    >
                      {list.title}
                    </TableCell>

                    <TableCell className="py-2 px-5 ">
                      <StyledContentButtons>
                        <ButtonComponent
                          text=""
                          className="icon-edit"
                          onPress={() => {
                            setValue('name', list.title);
                            setActiveEditModal({
                              active: true,
                              id: list.id,
                              name: list.title,
                            });
                          }}
                        />
                        <ButtonComponent
                          text=""
                          className="icon-delete"
                          onPress={() => {
                            setActiveModal({ active: true, id: list.id });
                          }}
                        />
                      </StyledContentButtons>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <CardEmptyState
                  className="w-8/12 mx-auto mt-16"
                  title={
                    isFilterEmpty
                      ? 'No hay resultados, utiliza nuevos criterios de búsqueda'
                      : 'No se han encontrado listas de contactos'
                  }
                />
              )}
            </TableBody>
          </Table>
          <Pager
            pageCount={
              Math.ceil(
                contactsLists?.meta?.total / contactsLists?.meta?.per_page
              ) || 1
            }
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            previousLabel={Boolean(contactsLists?.meta?.prev)}
            nextLabel={Boolean(contactsLists?.meta?.next)}
            className="flex items-center lg:justify-end justify-center my-6"
            onPageChange={({ selected }) => onChangePage(selected)}
            // forcePage={forcePage}
          />
        </StyledContentTable>
      </>

      <DefaultModal
        isActive={activeModal.active}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={() => {
          setActiveModal({ active: false });
        }}
      >
        <TypographyComponent
          text="Eliminar lista de contactos"
          variant="h3"
          className="font-bold text-primary mb-1"
        />
        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto lg:mx-2 "
            mode="outline"
            onPress={() => {
              setActiveModal({ active: false });
            }}
          />
          <ButtonComponent
            variant="primary"
            text="Aceptar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={() => {
              deleteContactList(activeModal.id, setActiveModal);
            }}
          />
        </StyledContentButtonsModal>
      </DefaultModal>

      <DefaultModal
        isActive={activeEditModal.active}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={() => {
          setActiveEditModal({ active: false });
        }}
      >
        <TypographyComponent
          text="Editar lista de contactos"
          variant="h3"
          className="font-bold text-primary mb-1"
        />

        <InputForm
          type="text"
          name="name"
          placeholder="Ingresa el nombre de la lista"
          label="Nombre de la lista*"
          className="w-full mt-6"
          errors={errors}
          defaultValue={activeEditModal.name}
          {...register('name')}
        />
        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto lg:mx-2 "
            mode="outline"
            onPress={() => {
              setActiveEditModal({ active: false });
            }}
          />
          <ButtonComponent
            variant="primary"
            text="Aceptar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={() =>
              updateContactList(activeEditModal.id, setActiveEditModal)
            }
          />
        </StyledContentButtonsModal>
      </DefaultModal>
    </Suspense>
  );
};

List.propTypes = {
  setActiveTab: PropTypes.func,
  tabValue: PropTypes.number.isRequired,
};

List.defaultProps = {
  setActiveTab: () => {},
};

export default List;
