//Packages
import React, { lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useHelpers from 'helpers';

//Components
import FormMessage from './components/FormMessage';
import FormRequest from './components/FormRequest';

//Styles
const StyledContentButtonsModal = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const Detail = (props) => {
  const {
    setActiveItem,
    selectedMessage,
    formAcceptMessage,
    onFormatDefaultValueCommunities,
    handleResetSelectedDetailMessage,
    optionsCommunities,
    optionsContactLists,
    handleAcceptMessageAdmin,
    activeConfirmModal,
    setActiveConfirmModal,
    setValidateOptionsRules,
    handleAddOptions,
    setNumberOptions,
    numberOptions,
    handleDeleteOption,
    handleOnChangeOptions,
  } = props;

  const { useComponents } = useViews();

  const { useTypographies, useButtons, useModals } = useComponents();

  const { TypographyComponent } = useTypographies();

  const { ButtonComponent } = useButtons();
  const { DefaultModal } = useModals();

  const { useQuickFunctions } = useHelpers();
  const { setDefaultValueInputDateTimeWithTimeLocalZone } = useQuickFunctions();

  const {
    control,
    formState: { errors },
    watch,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
  } = formAcceptMessage;

  useEffect(() => {
    if (selectedMessage?.type?.id) {
      setValue('type', {
        label: selectedMessage.type.name,
        value: selectedMessage.type.id,
      });
      selectedMessage.channel.name &&
        setValue('channel', {
          label: selectedMessage.channel.name,
          value: selectedMessage.channel.id,
        });
      if (
        selectedMessage.communities?.length !== 0 ||
        selectedMessage.contactLists?.length !== 0
      ) {
        setValue('typeDest', {
          label:
            selectedMessage.communities.length > 0
              ? 'Comunidades'
              : 'Lista de contactos',
          value: selectedMessage.communities.length > 0 ? 'comunidad' : 'lista',
        });
        setTimeout(() => {
          setValue(
            'communities',
            onFormatDefaultValueCommunities({
              listOfCommunities:
                selectedMessage.communities?.length > 0
                  ? selectedMessage.communities
                  : selectedMessage.contactLists,
            })
          );
        }, 200);
      }
      setValue('message', _.split(selectedMessage.message, '\n1')?.[0]);
      if (selectedMessage.send_date) {
        setValue('date_checkbox', true);
        setValue(
          'send_date',
          setDefaultValueInputDateTimeWithTimeLocalZone({
            dateInUTC: selectedMessage.send_date,
          }).split('.')[0]
        );
      }
      selectedMessage.gi_type?.id &&
        setValue('gi_type', {
          label: selectedMessage.gi_type.name,
          value: selectedMessage.gi_type.id,
        });
      selectedMessage.type_information?.id &&
        setValue('type_information', {
          label: selectedMessage.type_information.name,
          value: selectedMessage.type_information.id,
        });
      if (selectedMessage.options?.length > 0) {
        const draftOptions =
          selectedMessage.options?.length > 0
            ? _.map(selectedMessage.options, (option, index) => {
                return { id: Date.now() + index, value: option.text };
              })
            : [{ id: Date.now(), value: '' }];
        const draftOptionsAux =
          selectedMessage.options?.length > 0
            ? _.map(selectedMessage.options, (option) => option.text)
            : [''];
        setNumberOptions(draftOptions);
        setValue('options', draftOptionsAux);
      } else {
        setNumberOptions([{ id: Date.now(), value: '' }]);
      }
    }
  }, [selectedMessage?.id]);

  useEffect(() => {
    return () => {
      reset();
      setNumberOptions([]);
      handleResetSelectedDetailMessage();
    };
  }, []);

  useEffect(() => {
    if (!watch('date_checkbox')) {
      setValue('send_date', undefined);
    }
  }, [watch('date_checkbox')]);

  return (
    <>
      {watch('type')?.value === 'd' && (
        <FormMessage
          setActiveItem={setActiveItem}
          control={control}
          errors={errors}
          register={register}
          optionsCommunities={optionsCommunities}
          optionsContactLists={optionsContactLists}
          watch={watch}
          handleSubmit={handleSubmit}
          handleAcceptMessageAdmin={handleAcceptMessageAdmin}
          getValues={getValues}
          setError={setError}
          formAcceptMessage={formAcceptMessage}
        />
      )}

      {watch('type')?.value === 'gi' && (
        <FormRequest
          setActiveItem={setActiveItem}
          control={control}
          errors={errors}
          register={register}
          optionsCommunities={optionsCommunities}
          optionsContactLists={optionsContactLists}
          watch={watch}
          setValidateOptionsRules={setValidateOptionsRules}
          handleAddOptions={handleAddOptions}
          setError={setError}
          handleAcceptMessageAdmin={handleAcceptMessageAdmin}
          getValues={getValues}
          formAcceptMessage={formAcceptMessage}
          handleSubmit={handleSubmit}
          numberOptions={numberOptions}
          handleDeleteOption={handleDeleteOption}
          handleOnChangeOptions={handleOnChangeOptions}
        />
      )}

      <DefaultModal
        isActive={activeConfirmModal}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={setActiveConfirmModal}
      >
        <TypographyComponent
          text="¿Enviar mensaje?"
          variant="h3"
          className="font-bold text-primary mb-1"
        />
        <TypographyComponent
          text="Haz clic en aceptar para enviarlo de inmediato!"
          variant="p"
          className="text-neutral-950  mb-1"
        />
        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto lg:mx-2 "
            mode="outline"
            onPress={setActiveConfirmModal}
          />
          <ButtonComponent
            variant="primary"
            text="Aceptar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={handleSubmit((data) =>
              handleAcceptMessageAdmin({
                data,
                state: 'ip',
                formToUse: formAcceptMessage,
                fromModalConfirmation: true,
              })
            )}
          />
        </StyledContentButtonsModal>
      </DefaultModal>
    </>
  );
};

Detail.propTypes = {
  setActiveItem: PropTypes.func.isRequired,
  selectedMessage: PropTypes.object,
  formAcceptMessage: PropTypes.object,
  onFormatDefaultValueCommunities: PropTypes.func,
  handleResetSelectedDetailMessage: PropTypes.func,
  optionsCommunities: PropTypes.array,
  optionsContactLists: PropTypes.array,
  handleAcceptMessageAdmin: PropTypes.func,
  activeConfirmModal: PropTypes.bool,
  setActiveConfirmModal: PropTypes.func,
  setValidateOptionsRules: PropTypes.func,
  handleAddOptions: PropTypes.func,
  setNumberOptions: PropTypes.func,
  numberOptions: PropTypes.array,
  handleDeleteOption: PropTypes.func,
  handleOnChangeOptions: PropTypes.func,
};

Detail.defaultProps = {
  selectedMessage: {},
  formAcceptMessage: {},
  onFormatDefaultValueCommunities: () => {},
  handleResetSelectedDetailMessage: () => {},
  optionsCommunities: [],
  optionsContactLists: [],
  handleAcceptMessageAdmin: () => {},
  activeConfirmModal: false,
  setActiveConfirmModal: () => {},
  setValidateOptionsRules: () => {},
  handleAddOptions: () => {},
  setNumberOptions: () => {},
  numberOptions: [],
  handleDeleteOption: () => {},
  handleOnChangeOptions: () => {},
};

export default Detail;
