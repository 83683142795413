//Packages
//Hooks
import useApi from '../../..';
import useStrings from '../../../../strings';

const useLanguagesActions = () => {
  const { useProviders } = useApi();
  const { useGeneralProviders } = useProviders();
  const { useLanguagesProviders } = useGeneralProviders();
  const { getLanguages } = useLanguagesProviders();

  //Strings
  const { useGeneralTypes } = useStrings();
  const { useLanguagesTypes } = useGeneralTypes();
  const { GET_LANGUAGES } = useLanguagesTypes();

  const actGetLanguages =
    ({ paginate = 0 }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await getLanguages({ paginate });

        dispatch({ type: GET_LANGUAGES, payload: res.data?.data });
        onSuccess && onSuccess(res);
      } catch (error) {
        onError && onError(error);
      }
    };

  return { actGetLanguages };
};

export default useLanguagesActions;
