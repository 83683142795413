const useAdminAbcTrajectoryTypes = () => {
  const CREATE_ABC_TRAJECTORY = 'CREATE_ABC_TRAJECTORY';
  const UPDATE_ABC_TRAJECTORY = 'UPDATE_ABC_TRAJECTORY';
  const DELETE_ABC_TRAJECTORY = 'DELETE_ABC_TRAJECTORY';
  const GET_ABC_TRAJECTORIES = 'GET_ABC_TRAJECTORIES';

  return {
    GET_ABC_TRAJECTORIES,
    CREATE_ABC_TRAJECTORY,
    UPDATE_ABC_TRAJECTORY,
    DELETE_ABC_TRAJECTORY,
  };
};

export default useAdminAbcTrajectoryTypes;
