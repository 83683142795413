import useAdminAbcTrajectoryTypes from './abcTrajectory';
import useAdminAdditionalInfoTypes from './additionalInfo';
import useAdminCommunitiesTypes from './communities';
import useAdminDocumentationTypes from './documentation';
import useAdminFestivitiesAndTraditionsTypes from './festivitiesAndTraditions';
import useAdminProjectsRootTypes from './projectsRoot';
import useAdminRecommendationsTypes from './recommendations';
import useAdminSocioCulturalInfoTypes from './socioCulturalInfo';
import useAdminInterventionsTypes from './interventions';
const useAdminProjectsTypes = () => {
  return {
    useAdminProjectsRootTypes,
    useAdminCommunitiesTypes,
    useAdminRecommendationsTypes,
    useAdminFestivitiesAndTraditionsTypes,
    useAdminAbcTrajectoryTypes,
    useAdminSocioCulturalInfoTypes,
    useAdminAdditionalInfoTypes,
    useAdminDocumentationTypes,
    useAdminInterventionsTypes,
  };
};

export default useAdminProjectsTypes;
