//Packages
import React from 'react';

//Hooks
import useViews from 'views/index';

import PropTypes from 'prop-types';
import useEjecutionSteps from '../../Admin/EjecuteIntervention';
import useEjecutionStepsArtist from '../../Artist/EjecuteIntervention';
import useControllers from '../../../../../../../../../../../controllers';

const StepperExecutionIntervention = (props) => {
  const { permissions } = props;
  const { useComponents } = useViews();

  const { useStepper } = useComponents();
  const { Stepper, Step } = useStepper();

  const { MaterializeConvocatory, EjecuteIntervention, /*Evaluate*/ } =
    useEjecutionSteps();

  const {
    MaterializeConvocatoryArtist,
    EjecuteInterventionArtist,
    // EvaluateArtist,
  } = useEjecutionStepsArtist();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsDevelopmentIntervention } =
    useAdminViewInterventionsControllers();
  const { useViewDevelopmentInterventionExecuteIntervention } =
    useAdminViewInterventionsDevelopmentIntervention();
  const { useExecuteInterventionStepper } = useViewDevelopmentInterventionExecuteIntervention();
  const {
    currentStep,
    setCurrentStep,
    executeMaterialize,
    executeIntervention,
    // executeEvaluate,
    actualPhase
  } = useExecuteInterventionStepper();

  return (
    <>
      <Stepper
        currentStep={currentStep}
        conetor={true}
        setCurrentStep={setCurrentStep}
        variant="line"
        isSubStepper
      >
        <Step
          label={'Materializar convocatoria'}
          stepStatusIdForColor={executeMaterialize.state.id}
          stepStatusName={executeMaterialize.state.name}
          actualPhase={actualPhase}
        >
          {currentStep === 0 && (
            <>
              {!permissions && <MaterializeConvocatoryArtist />}
              {permissions && <MaterializeConvocatory />}
            </>
          )}
        </Step>
        <Step
          label={'Ejecutar la intervención'}
          stepStatusIdForColor={
            actualPhase >= 7
              ? executeMaterialize.state.id
              : executeIntervention.state.id
          }
          stepStatusName={
            actualPhase >= 7
              ? executeMaterialize.state.name
              : executeIntervention.state.name
          }
          actualPhase={actualPhase}
        >
          {currentStep === 1 && (
            <>
              {!permissions && <EjecuteInterventionArtist />}
              {permissions && <EjecuteIntervention />}
            </>
          )}
        </Step>
        {/*<Step
          label={'Evaluar intervención'}
          stepStatusIdForColor={
            actualPhase >= 7
              ? executeMaterialize.state.id
              : executeEvaluate.state.id
          }
          stepStatusName={
            actualPhase >= 7
              ? executeMaterialize.state.name
              : executeEvaluate.state.name
          }
          actualPhase={actualPhase}
        >
          {currentStep === 2 && (
            <>
              {!permissions && <EvaluateArtist />}
              {permissions && <Evaluate />}
            </>
          )}
        </Step>*/}
      </Stepper>
    </>
  );
};

StepperExecutionIntervention.propTypes = {
  permissions: PropTypes.bool.isRequired,
};

export default StepperExecutionIntervention;
