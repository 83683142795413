//Packages
import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Components
import Aspects from './components/Aspects';
import Sessions from './components/Sessions';
import SpreadIntervention from './components/SpreadIntervention';

//Styles
const StyledContentButtonsModal = lazy(() =>
  import(
    '../../../../../../../../../../../styles/tableViews/tableViews.styles'
  ).then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const StyledGroup = lazy(() =>
  import(
    '../../../../../../../../../../../styles/formViews/formViews.styles'
  ).then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const DefineIntervention = () => {
  const { useComponents } = useViews();

  const { useTypographies, useButtons, useModals, useInputs, useCards } =
    useComponents();
  const { TypographyComponent } = useTypographies();
  const { ButtonComponent } = useButtons();
  const { DefaultModal } = useModals();
  const { TextArea, CheckBox, InputForm } = useInputs();
  const { CardNotification } = useCards();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsDevelopmentIntervention } =
    useAdminViewInterventionsControllers();
  const { useDefineInterventionInterventions } =
    useAdminViewInterventionsDevelopmentIntervention();
  const { useAdminDefineInterventionInterventions } =
    useDefineInterventionInterventions();
  const {
    errors,
    isValid,
    define_intervention,
    handleSubmit,
    register,
    watch,
    updateDefineIntervention,
    openModalDeny,
    handleOpenCloseModalDeny,
    openModal,
    handleOpenCloseModal,
    updateDefineInterventionState,
    registerReject,
    handleSubmitReject,
    errorsReject,
    watchRejected,
    controlReject,
    objetive,
    openFile,
    downloadFile,
    checkboxesConvocationMethod,
    checkboxesDynamics,
  } = useAdminDefineInterventionInterventions();

  return (
    <>
      {_.includes(['c', 'ip', 'r', 'iv'], define_intervention.state.id) && (
        <CardNotification
          className="w-5/12 mx-auto py-9 px-4 mt-16"
          title={
            <TypographyComponent
              text={define_intervention.state.name}
              variant="h2"
              className="font-bold text-primary icon-watch text-center"
            />
          }
        />
      )}
      <TypographyComponent
        text="Definición de la intervención"
        variant="h2"
        className="text-primary mt-2 font-bold"
      />
      <TypographyComponent
        text="Al dar inicio a esta etapa, el artista podrá empezar su diseño y planificación."
        variant="p"
        className="text-neutral-950 mt-2 mb-4"
      />
      <TypographyComponent
        text="Este paso cuenta con cuatro sub etapas"
        variant="p"
        className="text-neutral-950 mt-2 mb-4"
      />
      {_.includes(['c', 'iv', 'r'], define_intervention.state.id) && (
        <>
          <Aspects keyFactors={define_intervention.key_factors} />

          <Sessions activities={define_intervention.definition_interventions} />

          <SpreadIntervention
            checkboxesConvocationMethod={checkboxesConvocationMethod}
            description={define_intervention.convocation_description}
            checkboxesDynamics={checkboxesDynamics}
            fileUrl={define_intervention.convocation_file}
            fileUrlDynamic={define_intervention.diffusion_file}
            listOfTasks={define_intervention.convocation_tasks}
            descriptionDiffusion={define_intervention.diffusion_description}
            openFile={openFile}
            downloadFile={downloadFile}
            objetive={objetive}
          />

          {define_intervention.state.id === 'iv' && (
            <>
              <div className="flex items-center space-x-2 mt-10 justify-center">
                <ButtonComponent
                  text="Rechazar definición"
                  mode="outline"
                  onPress={handleOpenCloseModalDeny}
                />
                <ButtonComponent
                  text="Aprobar y avanzar a la siguiente etapa"
                  onPress={handleOpenCloseModal}
                />
              </div>

              <DefaultModal
                isActive={openModalDeny}
                classNameContent="max-w-screen-md w-11/12"
                handleClose={handleOpenCloseModalDeny}
              >
                <TypographyComponent
                  text="Rechazar definición de la intervención"
                  variant="h3"
                  className="font-bold text-primary mb-1"
                />

                <StyledGroup col2 className="mb-4">
                  <TextArea
                    name="rejectedReason"
                    placeholder="Que actividades realizare"
                    className="w-full col-span-2"
                    label="Escribe los ajustes que debe realizar el artista a la definición de la intervención"
                    errors={errorsReject}
                    {...registerReject('rejectedReason')}
                  />
                </StyledGroup>
                <StyledGroup flex noSpace className="mb-4 flex-col">
                  <Controller
                    control={controlReject}
                    name="dateLimitCheckbox"
                    render={({ field: { name, onChange } }) => {
                      return (
                        <CheckBox
                          name={name}
                          label="Asignar fecha limite para recibir los ajustes en la definición por parte del artista"
                          className="mt-6"
                          onChange={onChange}
                          errors={errorsReject}
                        />
                      );
                    }}
                  />
                  {watchRejected('dateLimitCheckbox') && (
                    <InputForm
                      name="dateLimit"
                      label=""
                      type="date"
                      placeholder="Selecciona la fecha limite"
                      className="pt-2 "
                      errors={errorsReject}
                      {...registerReject('dateLimit')}
                    />
                  )}
                </StyledGroup>
                <StyledContentButtonsModal>
                  <ButtonComponent
                    variant="primary"
                    text="Cancelar"
                    className="mx-auto w-full lg:w-auto lg:mx-2 "
                    mode="outline"
                    onPress={handleOpenCloseModalDeny}
                  />
                  <ButtonComponent
                    variant="primary"
                    text="Enviar respuesta al artista"
                    className="mx-auto w-full lg:w-auto  lg:mx-2"
                    onPress={handleSubmitReject((data) =>
                      updateDefineInterventionState({ state: 'r', data })
                    )}
                  />
                </StyledContentButtonsModal>
              </DefaultModal>

              <DefaultModal
                isActive={openModal}
                classNameContent="max-w-screen-md w-11/12"
                handleClose={handleOpenCloseModal}
              >
                <TypographyComponent
                  text="Aprobar definición de la intervención"
                  variant="h3"
                  className="font-bold text-primary mb-1"
                />

                <StyledContentButtonsModal>
                  <ButtonComponent
                    variant="primary"
                    text="Cancelar"
                    className="mx-auto w-full lg:w-auto lg:mx-2 "
                    mode="outline"
                    onPress={handleOpenCloseModal}
                  />
                  <ButtonComponent
                    variant="primary"
                    text="Aceptar"
                    className="mx-auto w-full lg:w-auto  lg:mx-2"
                    onPress={(event) =>
                      updateDefineInterventionState({ event, state: 'c' })
                    }
                  />
                </StyledContentButtonsModal>
              </DefaultModal>
            </>
          )}
        </>
      )}

      {define_intervention.state.id === 'p' && (
        <>
          <CheckBox
            name={'date_asing'}
            label="Asignar fecha limite para recibir definición de la intervención por parte del artista"
            className="mx-auto mt-6"
            {...register('date_asing')}
          />
          {watch('date_asing') && (
            <InputForm
              name="date_limit"
              label=""
              type="date"
              placeholder="Selecciona la fecha limite"
              className="mt-8 w-2/12 "
              errors={errors}
              {...register('date_limit')}
            />
          )}

          <ButtonComponent
            text="Iniciar etapa"
            className="mx-auto mt-12"
            onPress={handleSubmit(updateDefineIntervention)}
            disabled={!isValid}
          />
        </>
      )}
    </>
  );
};

DefineIntervention.propTypes = {
  setViewActive: PropTypes.func,
};

export default DefineIntervention;
