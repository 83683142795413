import useGeneralHooks from '../../generalHooks';
import useApi from '../../../api';
import { useEffect, useState } from 'react';

const useRecoverPasswordRoutesController = () => {
  const [recoverPasswordRoutes, setRecoverPasswordRoutes] = useState({
    permission: true,
    token: null,
  });
  const { useQueryParams } = useGeneralHooks();
  const { useActions } = useApi();
  const { useAuthActions, dispatch } = useActions();
  const { useRecoverPasswordActions } = useAuthActions();
  const { actValidateToken } = useRecoverPasswordActions();
  const query = useQueryParams();
  useEffect(() => {
    validateToken();
  }, []);

  const validateToken = () => {
    const token = query.get('token');
    if (token) {
      // setRecoverPasswordRoutes({
      //   ...recoverPasswordRoutes,
      //   token,
      // });
      dispatch(actValidateToken({ token }, setRecoverPasswordRoutes));
    }
  };
  return {
    props: { token: recoverPasswordRoutes.token },
    permission: recoverPasswordRoutes.permission,
  };
};
export default useRecoverPasswordRoutesController;
