//Packages
import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

// Styles
import { StyledTextArea } from './TextArea.styles';
import { StyledInputWrapper } from '../Inputs.styles';

// eslint-disable-next-line react/display-name
const TextArea = React.forwardRef((props, ref) => {
  const {
    name,
    errors,
    onChange,
    placeholder,
    label,
    className,
    hintLabel,
    maxLength,
    minlength,
    disabled,
    control,
    defaultValue,
  } = props;

  const { useComponents } = useViews();
  const { useLabels } = useComponents();
  const { DefaultLabel, HelperLabel } = useLabels();

  const { useComponentHooks } = useControllers();
  const { useInputs } = useComponentHooks();
  const { useInputForm } = useInputs();
  const {
    handleIsBlur,
    handleIsFocus,
    handleFilled,
    errorInput,
    successInput,
  } = useInputForm({
    errors,
    name,
  });

  return (
    <div className={`${className}`}>
      <StyledInputWrapper errors={errorInput} success={successInput}>
        {label && <DefaultLabel>{label}</DefaultLabel>}
        {control ? (
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { onChange: onChangeInput, name, value } }) => {
              return (
                <StyledTextArea
                  name={name}
                  onChange={(e) => {
                    onChangeInput(e.target.value);
                    onChange(e);
                  }}
                  onBlur={handleIsBlur}
                  onFocus={handleIsFocus}
                  placeholder={placeholder}
                  maxLength={maxLength}
                  minlength={minlength}
                  disabled={disabled}
                  onInput={handleFilled}
                  errors={errorInput}
                  success={successInput}
                  defaultValue={value}
                />
              );
            }}
          />
        ) : (
          <StyledTextArea
            name={name}
            onChange={onChange}
            ref={ref}
            onBlur={handleIsBlur}
            onFocus={handleIsFocus}
            placeholder={placeholder}
            maxLength={maxLength}
            minlength={minlength}
            disabled={disabled}
            onInput={handleFilled}
            errors={errorInput}
            success={successInput}
            defaultValue={defaultValue}
          />
        )}
        {hintLabel && errorInput ? (
          <HelperLabel>{errors?.[name]?.message}</HelperLabel>
        ) : errorInput ? (
          <HelperLabel>{errors?.[name]?.message}</HelperLabel>
        ) : (
          <HelperLabel>{hintLabel}</HelperLabel>
        )}
      </StyledInputWrapper>
    </div>
  );
});

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  hintLabel: PropTypes.string,
  maxLength: PropTypes.string,
  minlength: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  errors: PropTypes.object,
  control: PropTypes.any,
  defaultValue: PropTypes.string,
};

TextArea.defaultProps = {
  placeholder: '',
  onChange: () => {},
  label: '',
  className: '',
  hintLabel: '',
  maxLength: '',
  minlength: '0',
  disabled: false,
  required: false,
  control: '',
  defaultValue: '',
};

export default TextArea;
