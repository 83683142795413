//Packages
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useAdminDevelopmentInterventionCreativePlanProviders = () => {
  const getCreativePlanInterventionProvider = ({ intervention_id }) => {
    const response = axios({
      method: 'GET',
      url: `/interventions/${intervention_id}/creative-plan`,
    });
    return trackPromise(response);
  };

  const updateCreativePlanInterventionProvider = ({
    intervention_id,
    creative_plan_id,
    data,
  }) => {
    const response = axios({
      method: 'POST',
      url: `/interventions/${intervention_id}/creative-plan/${creative_plan_id}`,
      data,
    });
    return trackPromise(response);
  };

  const deleteCreativePlanInterventionProvider = ({
    intervention_id,
    creative_plan_id,
  }) => {
    const response = axios({
      method: 'DELETE',
      url: `/interventions/${intervention_id}/creative-plan/${creative_plan_id}`,
    });
    return trackPromise(response);
  };

  const downloadFileCreativePlanInterventionProvider = ({
    intervention_id,
    creative_plan_id,
    file,
  }) => {
    const response = axios({
      method: 'GET',
      responseType: 'arraybuffer',
      url: `/interventions/${intervention_id}/creative-plan/${creative_plan_id}/file/${file}`,
    });
    return trackPromise(response);
  };

  return {
    getCreativePlanInterventionProvider,
    updateCreativePlanInterventionProvider,
    deleteCreativePlanInterventionProvider,
    downloadFileCreativePlanInterventionProvider,
  };
};

export default useAdminDevelopmentInterventionCreativePlanProviders;
