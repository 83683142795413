import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

// Hooks
import useViews from 'views';
import useHelpers from 'helpers';

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

// Components
import Form from '../Form';

// Styles
import { StyledContentButtons } from 'styles/tableViews/tableViews.styles';

const ProgramEvents = (props) => {
  const {
    events,
    formEvent,
    interventionOptions,
    communityOptions,
    saveEvent,
    deleteEvent,
    canEdit,
  } = props;
  const { reset } = formEvent;

  const { useComponents } = useViews();
  const { useTypographies, useButtons } = useComponents();
  const { TypographyComponent } = useTypographies();
  const { ButtonComponent } = useButtons();

  const [activeNewEvent, setActiveNewEvent] = useState(false);
  const [eventExpanded, setEventExpanded] = useState({});

  const { useQuickFunctions } = useHelpers();
  const { convertDateToISO, setDefaultValueInputDateTimeWithTimeLocalZone } =
    useQuickFunctions();

  const openFormNewEvent = (e) => {
    e.preventDefault();
    reset({
      place: '',
      date: '',
    });
    setEventExpanded({});
    setActiveNewEvent(true);
  };

  const cancelEvent = (e) => {
    e && e.preventDefault();
    reset({
      place: '',
      date: '',
    });
    setActiveNewEvent(false);
    setEventExpanded({});
  };

  const formatDataAndSaveEvent = (data) => {
    reset({});
    saveEvent({
      id: data.id,
      definition_intervention_id: data.intervention.value,
      date: convertDateToISO({ date: data.date }),
      community_id: data.community.value,
      place: data.place,
    });
    setEventExpanded({});
    setActiveNewEvent(false);
  };

  const editEvent = (e, event) => {
    e && e.preventDefault();
    reset({
      place: '',
      date: '',
    });
    setActiveNewEvent(false);
    setEventExpanded(event);
    reset({
      id: event.id,
      intervention: {
        value: event.definition_intervention.id,
        label: event.definition_intervention.name,
      },
      modality: {
        value: event.definition_intervention.modality.id,
        label: event.definition_intervention.modality.name,
      },
      date: setDefaultValueInputDateTimeWithTimeLocalZone({
        dateInUTC: event.date,
      }).split('.')[0],
      duration: event.definition_intervention.duration.slice(0, 5),
      community: {
        value: event.community.id,
        label: event.community.name,
      },
      place: event.place,
    });
  };

  return (
    <>
      <TypographyComponent
        text="Programar intervención"
        variant="h3"
        className="text-primary mt-6 font-bold"
      />
      <TypographyComponent
        text="Completa la información clave para la programación de la intervención. Estos detalles son necesarios para que el artista pueda programarse."
        variant="p"
        className="text-neutral-950 mt-4 mb-4"
      />

      <Accordion allowZeroExpanded>
        {events.length > 0 &&
          _.map(events, (event) => {
            return (
              <div className="rounded mt-2" key={event.id}>
                <AccordionItem
                  dangerouslySetExpanded={eventExpanded?.id === event.id}
                >
                  <div className="flex items-center justify-between bg-white py-2 px-6">
                    <TypographyComponent
                      variant="p"
                      text={event.definition_intervention?.name}
                      className="flex-grow text-primary font-bold"
                    />
                    <StyledContentButtons>
                      {canEdit && (
                        <>
                          <AccordionItemButton>
                            <ButtonComponent
                              text=""
                              className={`icon-edit`}
                              variant="white"
                              onPress={(e) => editEvent(e, event)}
                            />
                          </AccordionItemButton>
                          <ButtonComponent
                            text=""
                            className={`icon-delete `}
                            onPress={() => deleteEvent(event)}
                          />
                        </>
                      )}
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton>
                            {!canEdit && (
                              <ButtonComponent
                                text=""
                                className=""
                                variant="white"
                                icon={
                                  <div
                                    className={`icon-arrow-down text-xxs text-neutral-950 ml-4 ${
                                      expanded && 'transform rotate-180'
                                    }`}
                                  />
                                }
                                onPress={(e) => editEvent(e, event)}
                              />
                            )}
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </StyledContentButtons>
                  </div>
                  <AccordionItemPanel className="py-4 border rounded px-4">
                    {eventExpanded?.id === event.id && (
                      <Form
                        formEvent={formEvent}
                        cancelEvent={cancelEvent}
                        formatDataAndSaveEvent={formatDataAndSaveEvent}
                        interventionOptions={interventionOptions}
                        communityOptions={communityOptions}
                        canEdit={canEdit}
                      />
                    )}
                  </AccordionItemPanel>
                </AccordionItem>
              </div>
            );
          })}
      </Accordion>

      {!activeNewEvent && (
        <ButtonComponent
          text="Crear nuevo evento"
          icon={<div className="icon-close transform rotate-45 text-sm" />}
          onPress={openFormNewEvent}
          variant="primary"
          mode="outline"
          className={`mt-4 border-none shadow-none px-0 `}
        />
      )}
      {activeNewEvent && (
        <Form
          formEvent={formEvent}
          cancelEvent={cancelEvent}
          formatDataAndSaveEvent={formatDataAndSaveEvent}
          interventionOptions={interventionOptions}
          communityOptions={communityOptions}
          canEdit={canEdit}
        />
      )}
    </>
  );
};

ProgramEvents.propTypes = {
  events: PropTypes.array,
  formEvent: PropTypes.object.isRequired,
  interventionOptions: PropTypes.array.isRequired,
  communityOptions: PropTypes.array.isRequired,
  saveEvent: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
};

export default ProgramEvents;
