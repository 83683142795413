const useAdminHomeInitialStates = () => {
  const initialStateAdminHome = {
    id: '',
    sliders: [],
    bt_title: '',
    bt_subtitle: '',
    bt_text: '',
    artist: {
      name: '',
      image: {
        id: '',
        url: '',
      },
    },
    executing_partner: {
      name: '',
      image: {
        id: '',
        url: '',
      },
    },
    interventions: {
      data: [],
      links: {},
      meta: {},
    },
    interventionSelectedToEdit: {
      id: '',
      final_process: {
        files: [],
        goals: [],
        id: '',
        recommendations: '',
        suggestions: '',
        tastes: '',
      },
    },
  };

  return {
    initialStateAdminHome,
  };
};

export default useAdminHomeInitialStates;
