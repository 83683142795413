//Packages
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';

//Hooks
import useApi from 'api';
import useModels from 'models';
import useStrings from 'strings';
import useHelpers from 'helpers';
import useControllers from 'controllers';

const useAdminDefineInterventionInterventions = () => {
  const [valueCheckBoxes] = useState({
    convocation: [
      { value: 'p', label: 'Impresos', checked: false },
      { value: 'o', label: 'Objetos', checked: false },
      { value: 'c', label: 'Celular', checked: false },
    ],
    dynamics: [
      { value: 'e', label: 'Evento presencial', checked: false },
      { value: 's', label: 'Streaming', checked: false },
      { value: 'd', label: 'Difusión por redes sociales', checked: false },
      { value: 'o', label: 'Otro', checked: false },
    ],
  });

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();
  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const { useAdminDevelopmentInterventionDefineInterventionActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const {
    actGetDefineProjectIntervention,
    actUpdateDefineProjectIntervention,
    actUpdateDefineProjectInterventionArtist,
    actDownloadDifusionFileForIntervention,
  } = useAdminDevelopmentInterventionDefineInterventionActions();

  const { intervention_id } = useParams();

  const { useSelectors } = useModels();
  const { useAdminSelectors, useSelector } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionsSelector, getInterventionDetailToEditSelector } =
    useAdminInterventionsSelectors();
  const { define_intervention } = useSelector(getInterventionsSelector);
  const { objetive } = useSelector(getInterventionDetailToEditSelector);

  const { useGeneralHooks } = useControllers();
  const { useToggleModal } = useGeneralHooks();
  const [openModalDeny, handleOpenCloseModalDeny] = useToggleModal();
  const [openModal, handleOpenCloseModal] = useToggleModal();
  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { REQUIRED_FIELD, DATE_GREATER_THAN_TODAY } = useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const {
    isSelectedDateGreaterThanTodayDate,
    formatDayWithTwoDigits,
    formatMonthWithTwoDigits,
    downloadFileFromBackend,
    getYearMonthDayFromDate,
  } = useQuickFunctions();

  const handleDefineIntervention = () => {
    if (define_intervention.state?.id !== 'c') {
      dispatch(
        actGetDefineProjectIntervention({
          intervention_id,
        })
      );
    }
  };

  useEffect(() => {
    handleDefineIntervention();
  }, []);

  const checkboxesConvocationMethod = _.map(
    valueCheckBoxes.convocation,
    (checkbox) =>
      define_intervention.convocation_method.includes(checkbox.value)
        ? { ...checkbox, checked: true }
        : checkbox
  );

  const checkboxesDynamics = _.map(valueCheckBoxes.dynamics, (checkbox) =>
    define_intervention.diffusion_dynamic.includes(checkbox.value)
      ? { ...checkbox, checked: true }
      : checkbox
  );

  const updateDefineIntervention = (data) => {
    delete data.files;
    delete data.date_asing;
    if (data.date_limit) {
      const date_limit = `${data.date_limit.getUTCFullYear()}-${formatMonthWithTwoDigits(
        {
          month: data.date_limit.getUTCMonth(),
        }
      )}-${formatDayWithTwoDigits({ day: data.date_limit.getUTCDate() })}`;
      data.date_limit = date_limit;
    } else {
      delete data.date_limit;
    }
    data.state = 'ip';
    dispatch(
      actUpdateDefineProjectIntervention({
        intervention_id,
        phase_id: define_intervention.id,
        data,
      })
    );
  };

  const schemaDefineProjectIntervention = yup.object({
    date_asing: yup.boolean(),
    date_limit: yup.date().when('date_asing', {
      is: true,
      then: yup
        .date()
        .required(REQUIRED_FIELD)
        .test('dateValidation', DATE_GREATER_THAN_TODAY, (value) => {
          return value
            ? isSelectedDateGreaterThanTodayDate({ selectedDate: value })
            : true;
        }),
      otherwise: yup
        .date()
        .notRequired()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr)),
    }),
  });

  const schemaDefineKeyFactors = yup.object({
    key_factor_one: yup.string().required(REQUIRED_FIELD),
    key_factor_two: yup.string().required(REQUIRED_FIELD),
    key_factor_three: yup.string().required(REQUIRED_FIELD),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    resolver: yupResolver(schemaDefineProjectIntervention),
    mode: 'onChange',
  });

  const updateKeyFactors = (data) => {
    dispatch(
      actUpdateDefineProjectInterventionArtist({
        intervention_id,
        phase_id: define_intervention.id,
        key_factors: [data.opt1, data.opt2, data.opt3],
      })
    );
  };

  const openFile = ({ event, urlFile }) => {
    event && event.preventDefault();
    window.open(urlFile);
  };

  const downloadFile = ({ event, idFile, nameFile }) => {
    event && event.preventDefault();
    dispatch(
      actDownloadDifusionFileForIntervention(
        {
          intervention: intervention_id,
          definition: define_intervention.id,
          file: idFile,
        },
        (response) => {
          downloadFileFromBackend({ response, nameFile });
        }
      )
    );
  };

  const schemaRejectDefineIntervention = yup.object({
    rejectedReason: yup.string().required(REQUIRED_FIELD),
    dateLimitCheckbox: yup.boolean(),
    dateLimit: yup.date().when('dateLimitCheckbox', {
      is: true,
      then: yup
        .date()
        .required(REQUIRED_FIELD)
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .test('dateValidation', DATE_GREATER_THAN_TODAY, (value) => {
          return value
            ? isSelectedDateGreaterThanTodayDate({ selectedDate: value })
            : true;
        }),
      otherwise: yup
        .date()
        .notRequired()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr)),
    }),
  });

  const {
    register: registerReject,
    handleSubmit: handleSubmitReject,
    formState: { errors: errorsReject, isValid: isValidReject },
    watch: watchRejected,
    control: controlReject,
  } = useForm({
    resolver: yupResolver(schemaRejectDefineIntervention),
    mode: 'onChange',
  });

  const updateDefineInterventionState = ({ event, state, data }) => {
    event && event.preventDefault();
    const dataAux = new FormData();
    dataAux.append('state', state);
    dataAux.append('_method', 'PATCH');

    if (state === 'r') {
      const dateConverted = getYearMonthDayFromDate({
        dateInString: data.dateLimit,
        format: 'ymd',
      });
      dataAux.append('rejected_reason', data.rejectedReason);
      data.dateLimitCheckbox && dataAux.append('date_limit', dateConverted);
    }

    dispatch(
      actUpdateDefineProjectInterventionArtist(
        {
          intervention_id,
          phase_id: define_intervention.id,
          formData: dataAux,
        },
        () => {
          handleOpenCloseModalDeny();
        }
      )
    );
  };

  return {
    register,
    handleSubmit,
    errors,
    isValid,
    handleDefineIntervention,
    watch,
    updateDefineIntervention,
    define_intervention,
    schemaDefineKeyFactors,
    updateKeyFactors,
    openModalDeny,
    handleOpenCloseModalDeny,
    getYearMonthDayFromDate,
    openFile,
    downloadFile,
    updateDefineInterventionState,
    openModal,
    handleOpenCloseModal,
    registerReject,
    handleSubmitReject,
    errorsReject,
    isValidReject,
    watchRejected,
    controlReject,
    objetive,
    checkboxesConvocationMethod,
    checkboxesDynamics,
  };
};

export default useAdminDefineInterventionInterventions;
