import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import qs from 'qs';

const useContactListProviders = () => {
  const getContactListsProvider = ({
    page,
    paginate,
    name,
    orderByKey,
    orderByValue,
  }) => {
    const response = axios({
      method: 'GET',
      url: '/contact-lists',
      params: {
        page,
        paginate,
        name: name || undefined,
        orderBy: orderByKey ? { [orderByKey]: orderByValue } : undefined,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return trackPromise(response);
  };
  const storeContactListProvider = (data) => {
    const response = axios({
      method: 'POST',
      url: '/contact-lists',
      data,
    });
    return trackPromise(response);
  };
  const updateContactListProvider = (data) => {
    const response = axios({
      method: 'PATCH',
      url: `/contact-lists/${data.id}`,
      data,
    });
    return trackPromise(response);
  };
  const deleteContactListProvider = (id) => {
    const response = axios({
      method: 'DELETE',
      url: `/contact-lists/${id}`,
    });
    return trackPromise(response);
  };
  const getContactListProvider = (id) => {
    const response = axios({
      method: 'GET',
      url: `/contact-lists/${id}`,
    });
    return trackPromise(response);
  };
  const addContactsToContactListProvider = ({ id, users }) => {
    const response = axios({
      method: 'POST',
      url: `/contact-lists/${id}/users`,
      data: { users },
    });
    return trackPromise(response);
  };
  const removeContactToContactListProvider = ({ id, users }) => {
    const response = axios({
      method: 'DELETE',
      url: `/contact-lists/${id}/users`,
      data: { users },
    });
    return trackPromise(response);
  };
  return {
    getContactListsProvider,
    storeContactListProvider,
    updateContactListProvider,
    deleteContactListProvider,
    getContactListProvider,
    addContactsToContactListProvider,
    removeContactToContactListProvider,
  };
};
export default useContactListProviders;
