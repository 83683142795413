//Packages
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { useForm } from 'react-hook-form';

//Hooks
import useModels from 'models';
import useApi from 'api';
import useHelpers from 'helpers';
import useStrings from 'strings';
import useControllers from 'controllers';

const useAdminViewInterventionsGetCollectionInformation = () => {
  const [activeItem, setActiveItem] = useState({
    active: 0,
    idCollectionInformation: '',
  });
  const [orderBy, setOrderBy] = useState({
    sended_date: true,
    gi_type: true,
    channel: true,
  });
  const [
    dataCollectionInformationSelected,
    setDataCollectionInformationSelected,
  ] = useState({});

  const { intervention_id } = useParams();

  const { useConstants } = useStrings();
  const { COLORS_BADGE_STATUS_MESSAGE } = useConstants();

  const { useGeneralHooks } = useControllers();
  const { useGetDate, useToggleModal, useCollectInformationMessages } =
    useGeneralHooks();
  const { getMonthAndDay } = useGetDate();
  const [showFilterDropDown, handleVisibilityFilterDropDown] = useToggleModal();
  const {
    handleChangePageAnswers,
    dataCollectionInformationAnswersSelected,
    handleGetDetailResponseCollectionMessage,
    setDataCollectionInformationAnswersSelected,
  } = useCollectInformationMessages({
    selectedMessage: dataCollectionInformationSelected,
  });

  const { useQuickFunctions } = useHelpers();
  const { onFormatMessageAboutCommunities, downloadFileFromBackend } =
    useQuickFunctions();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();
  const { useAdminViewInterventionsCommunicationActions } =
    useAdminViewInterventionsActions();
  const {
    actGetMessagesIntervention,
    actSetFilterListOfMessages,
    actGetMessageIntervention,
    actDownloadFileOfCollectionMessagesIntervention,
    actResetFilterListOfMessages,
  } = useAdminViewInterventionsCommunicationActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminProjectsSelectors, useAdminContactListSelectors } =
    useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const {
    getInterventionsCommunicationMessagesSelector,
    getToSendMessageInterventionSelector,
    getInterventionDetailToEditSelector,
  } = useAdminInterventionsSelectors();
  const toSendMessage = useSelector(getToSendMessageInterventionSelector);
  const { contactListSelector } = useAdminContactListSelectors();
  const { contactsLists } = useSelector(contactListSelector);
  const selectedIntervention = useSelector(getInterventionDetailToEditSelector);
  const { listOfMessages, filter } = useSelector(
    getInterventionsCommunicationMessagesSelector
  );

  const formFilterCollectionInformation = useForm({});

  const collectionMessagesList = useMemo(() => {
    return _.map(listOfMessages.data, (message) => {
      const communityMessage = onFormatMessageAboutCommunities({
        message,
        contactsLists,
        selectedIntervention,
      });
      return {
        id: message.id,
        to: communityMessage,
        suggestedDate:
          message.send_date && getMonthAndDay({ date: message.send_date }),
        sendedDate:
          message.sended_date &&
          getMonthAndDay({
            date: message.sended_date,
          }),
        state: message.state,
        channel: message?.channel?.name,
        colorBadgeStatusMessage: COLORS_BADGE_STATUS_MESSAGE[message.state.id],
        typeRecol: message.gi_type?.name,
      };
    });
  }, [listOfMessages.data]);

  useEffect(() => {
    if (toSendMessage.body) {
      setActiveItem({ ...activeItem, active: 1 });
    }
  }, [toSendMessage]);

  useEffect(() => {
    return () => {
      dispatch(actResetFilterListOfMessages());
    };
  }, []);

  useEffect(() => {
    dispatch(
      actGetMessagesIntervention({
        intervention_id,
        draftOrNoDraft: 'nd',
        // ...filter,
        type: 'gi',
        runDispatch: false,
      })
    );
  }, []);

  useEffect(() => {
    if (activeItem.idCollectionInformation?.id) {
      dispatch(
        actGetMessageIntervention(
          {
            intervention_id,
            message_id: activeItem.idCollectionInformation?.id,
            executeInRedux: false,
          },
          (response) => {
            const { data: dataCollectionInformation } = response.data;
            setDataCollectionInformationSelected(dataCollectionInformation);
            handleGetDetailResponseCollectionMessage({
              messageId: activeItem.idCollectionInformation?.id,
              selectedMessage: dataCollectionInformation,
            });
          }
        )
      );
    } else {
      setDataCollectionInformationSelected({});
      setDataCollectionInformationAnswersSelected({});
    }
  }, [activeItem.idCollectionInformation?.id]);

  const optionsCommunities = useMemo(
    () =>
      _.map(selectedIntervention.communities, (community) => ({
        value: community.id,
        label: community.name,
        type: 'community',
      })),
    [selectedIntervention.communities]
  );

  const optionsContactLists = useMemo(
    () =>
      _.map(contactsLists.data, (contactList) => ({
        value: contactList.id,
        label: contactList.name,
        type: 'contactList',
      })),
    [contactsLists]
  );

  const handleChangePage = (selected) => {
    dispatch(
      actGetMessagesIntervention({
        intervention_id,
        draftOrNoDraft: 'nd',
        page: selected + 1,
        ...filter,
        type: 'gi',
      })
    );
  };

  const handleDataToOrder = ({ orderByKey }) => {
    dispatch(
      actGetMessagesIntervention({
        intervention_id,
        draftOrNoDraft: 'nd',
        ...filter,
        type: 'gi',
        orderByKey,
        orderByValue: orderBy[orderByKey] ? 'desc' : 'asc',
      })
    );
    dispatch(
      actSetFilterListOfMessages({ key: 'orderByKey', value: orderByKey })
    );
    dispatch(
      actSetFilterListOfMessages({
        key: 'orderByValue',
        value: orderBy[orderByKey] ? 'desc' : 'asc',
      })
    );
    setOrderBy((prevState) => ({
      ...prevState,
      [orderByKey]: !prevState[orderByKey],
    }));
  };

  const handleFilter = ({ data }) => {
    const arrayOfKeyFilters = Object.keys(data);

    const arrayCommunitiesAux = _.map(
      _.filter(
        data?.communityReceivers,
        (community) => community.type === 'community'
      ),
      (community) => community.value
    );

    const arrayContactListAux = _.map(
      _.filter(
        data?.communityReceivers,
        (community) => community.type === 'contactList'
      ),
      (community) => community.value
    );

    arrayOfKeyFilters.forEach((objectkey) => {
      const objectValue = data[objectkey];
      if (objectValue) {
        if (objectkey !== 'communityReceivers') {
          dispatch(
            actSetFilterListOfMessages({
              key: objectkey,
              value:
                typeof objectValue === 'object'
                  ? objectValue.value
                  : objectValue,
            })
          );
        } else {
          arrayCommunitiesAux?.length > 0 &&
            dispatch(
              actSetFilterListOfMessages({
                key: 'communityReceivers',
                value: arrayCommunitiesAux,
              })
            );

          arrayContactListAux?.length > 0 &&
            dispatch(
              actSetFilterListOfMessages({
                key: 'contactListReceivers',
                value: arrayContactListAux,
              })
            );
        }
      }
    });

    dispatch(
      actGetMessagesIntervention(
        {
          intervention_id,
          draftOrNoDraft: 'nd',
          ...filter,
          type: 'gi',
          communityReceivers:
            arrayCommunitiesAux.length > 0 && arrayCommunitiesAux,
          contactListReceivers:
            arrayContactListAux.length > 0 && arrayContactListAux,
          channel: data?.channel?.value && data?.channel?.value,
          sended_date: data?.sended_date && data.sended_date,
          gi_type: data?.gi_type && data?.gi_type?.value,
        },
        () => {
          handleVisibilityFilterDropDown();
        }
      )
    );
  };

  const handleResetFilter = () => {
    const data = {
      communityReceivers: '',
      gi_type: '',
      channel: '',
      sended_date: '',
    };

    const arrayOfKeyFilters = Object.keys(data);

    const arrayCommunitiesAux = _.map(
      _.filter(
        data?.communityReceivers,
        (community) => community.type === 'community'
      ),
      (community) => community.value
    );

    const arrayContactListAux = _.map(
      _.filter(
        data?.communityReceivers,
        (community) => community.type === 'contactList'
      ),
      (community) => community.value
    );

    arrayOfKeyFilters.forEach((objectkey) => {
      if (objectkey !== 'communityReceivers') {
        dispatch(
          actSetFilterListOfMessages({
            key: objectkey,
            value: '',
          })
        );
      } else {
        dispatch(
          actSetFilterListOfMessages({
            key: 'communityReceivers',
            value: '',
          })
        );

        dispatch(
          actSetFilterListOfMessages({
            key: 'contactListReceivers',
            value: '',
          })
        );
      }
    });

    dispatch(
      actGetMessagesIntervention(
        {
          intervention_id,
          draftOrNoDraft: 'nd',
          ...filter,
          type: 'gi',
          state: data?.state?.value && data?.state?.value,
          communityReceivers:
            arrayCommunitiesAux.length > 0 && arrayCommunitiesAux,
          contactListReceivers:
            arrayContactListAux.length > 0 && arrayContactListAux,
          channel: data?.channel?.value && data?.channel?.value,
          sended_date: data?.sended_date && data?.sended_date,
        },
        () => {
          handleVisibilityFilterDropDown();
        }
      )
    );

    formFilterCollectionInformation.reset(data);
  };

  const handleDownloadFileFromCollectionInformation = ({
    fileId,
    nameFile,
    extension,
  }) => {
    dispatch(
      actDownloadFileOfCollectionMessagesIntervention(
        {
          message_request_id: dataCollectionInformationSelected.id,
          fileId,
        },
        (response) => {
          downloadFileFromBackend({
            response,
            nameFile: `Multimedia-${nameFile}`,
            extension,
          });
        }
      )
    );
  };

  return {
    //data
    orderBy,
    activeItem,
    collectionMessagesList,
    dataForPagination: {
      meta: listOfMessages.meta,
      links: listOfMessages.links,
    },
    optionsCommunities,
    optionsContactLists,
    dataCollectionInformationSelected,
    dataCollectionInformationAnswersSelected,

    //Functions
    setActiveItem,
    handleChangePage,
    handleDataToOrder,
    handleFilter,
    handleResetFilter,
    handleChangePageAnswers,
    handleDownloadFileFromCollectionInformation,

    //toggles/modals
    showFilterDropDown,
    handleVisibilityFilterDropDown,

    //rhf
    formFilterCollectionInformation,
  };
};

export default useAdminViewInterventionsGetCollectionInformation;
