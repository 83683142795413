import useHelpers from '../../../helpers';

const useUserSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const userSelector = createSelector(
    (state) => state.user,
    (user) => user
  );

  const dataUpdateUserSelector = createSelector(
    (state) => state.updateUser,
    (updateUser) => updateUser
  );
  const usersSelector = createSelector(
    (state) => state.users,
    (user) => user
  );

  return { userSelector, dataUpdateUserSelector, usersSelector };
};

export default useUserSelectors;
