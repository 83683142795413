import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Components
import Create from './components/Create';
import List from './components/List';

//Styles
import { StyledContentButtonsModal } from 'styles/tableViews/tableViews.styles';

const Sessions = (props) => {
  const { setFirstOrLastView, setViewDefine } = props;
  const { useComponents } = useViews();

  const { useTypographies, useButtons, useModals } = useComponents();
  const { DefaultModal } = useModals();
  const { TypographyComponent } = useTypographies();

  const { ButtonComponent } = useButtons();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsDevelopmentIntervention } =
    useAdminViewInterventionsControllers();
  const { useDefineInterventionInterventions } =
    useAdminViewInterventionsDevelopmentIntervention();
  const { useArtistDefineInterventionInterventionsControllers } =
    useDefineInterventionInterventions();
  const { useArtistDefineInterventionInterventions } =
    useArtistDefineInterventionInterventionsControllers();
  const {
    formCreateIntervention,
    handleAddIntervention,
    define_intervention,
    showCreateIntervenForm,
    handleVisiblityIntervenForm,
    handleCancelEditCreateIntervention,
    handleSelectAnInterventionToDelete,
    handleSelectAnInterventionToEdit,
    showDeleteIntervenModal,
    handleToDeleteAnIntervention,
    defaultInternventionValuesEditMode,
    handleDeleteInterventionFile,
    interventionIdToDeleteEdit,
    deleteInterventionFileActive,
    isInterventionEditButtonDisabled,
    handleSelectAnInterventionActivityToCreate,
    handleResetInterventionIdToDeleteEdit,
    isNextButtonActive,
    handleLogicToOpenLastInterCreated,
    showAtLestOneActivityModal,
    handleVisiblityAtLestOneActivityModal,
  } = useArtistDefineInterventionInterventions();

  const listOfInterventionsCreated =
    define_intervention.definition_interventions;

  const disabledEditMode = _.includes(
    ['iv', 'c'],
    define_intervention.state.id
  );

  return (
    <>
      <TypographyComponent
        text="Crear intervención*"
        variant="h3"
        className="font-bold text-primary"
      />

      <TypographyComponent
        text="Dale un nombre creativo a cada una de las intervenciones y adjunta una descripción detallada de las actividades y los medios logísticos necesarios para llevar a cabo cada una de tus intervenciones"
        variant="p"
        className="text-neutral-950 mt-2 mb-6"
      />
      {listOfInterventionsCreated.length > 0 && !showCreateIntervenForm && (
        <List
          listOfInterventionsCreated={listOfInterventionsCreated}
          handleOpenDeleteModal={handleSelectAnInterventionToDelete}
          handleOpenFormEditInterven={handleSelectAnInterventionToEdit}
          isOpenDeleteModal={showDeleteIntervenModal}
          handleDeleteIntervention={handleToDeleteAnIntervention}
          handleSelectAnInterventionActivityToCreate={
            handleSelectAnInterventionActivityToCreate
          }
          interventionIdToDeleteEdit={`${interventionIdToDeleteEdit}`}
          handleResetInterventionIdToDeleteEdit={
            handleResetInterventionIdToDeleteEdit
          }
          isAllDisabled={disabledEditMode}
        />
      )}
      {showCreateIntervenForm && (
        <Create
          form={formCreateIntervention}
          handleSendForm={handleAddIntervention}
          handleVisiblityIntervenForm={handleCancelEditCreateIntervention}
          dataToEdit={defaultInternventionValuesEditMode}
          handleDeleteInterventionFile={handleDeleteInterventionFile}
          interventionIdToDeleteEdit={`${interventionIdToDeleteEdit}`}
          deleteInterventionFileActive={Boolean(deleteInterventionFileActive)}
          isInterventionEditButtonDisabled={isInterventionEditButtonDisabled}
          handleLogicToOpenLastInterCreated={handleLogicToOpenLastInterCreated}
        />
      )}
      {!showCreateIntervenForm && listOfInterventionsCreated.length < 3 && (
        <ButtonComponent
          text="Crear nueva intervención"
          icon={<div className="icon-close transform rotate-45 text-sm" />}
          onPress={() => {
            handleResetInterventionIdToDeleteEdit();
            handleVisiblityIntervenForm();
          }}
          variant="primary"
          mode="outline"
          className="mt-4 border-none shadow-none px-0"
          disabled={disabledEditMode}
        />
      )}

      <div className="flex items-center space-x-2 mt-10 justify-center">
        <ButtonComponent
          text="Anterior"
          onPress={() => {
            setViewDefine(1);
          }}
          mode="outline"
        />
        <ButtonComponent
          text="Siguiente"
          onPress={() => {
            if (
              !isNextButtonActive ||
              define_intervention.definition_interventions.length === 0
            ) {
              handleVisiblityAtLestOneActivityModal();
            } else {
              setFirstOrLastView(2);
            }
          }}
        />
      </div>

      <DefaultModal
        isActive={showAtLestOneActivityModal}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={handleVisiblityAtLestOneActivityModal}
      >
        <TypographyComponent
          text={`${
            define_intervention.definition_interventions.length === 0
              ? 'Debe existir al menos una intervencion creada'
              : 'Debe existir al menos una actividad creada por cada intervención'
          }`}
          variant="h3"
          className="font-bold text-primary mb-1 text-center"
        />

        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Aceptar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={handleVisiblityAtLestOneActivityModal}
          />
        </StyledContentButtonsModal>
      </DefaultModal>
    </>
  );
};

Sessions.propTypes = {
  setFirstOrLastView: PropTypes.func,
  setViewDefine: PropTypes.func,
};

Sessions.defaultProps = {
  setFirstOrLastView: () => {},
  setViewDefine: () => {},
};

export default Sessions;
