import useHelpers from '../../../../helpers';

const useAdminLabelsSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();
  const getLabelsListSelector = createSelector(
    (state) => state.labels,
    (labels) => labels
  );
  return { getLabelsListSelector };
};
export default useAdminLabelsSelectors;
