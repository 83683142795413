//Packages
import { normalize, schema } from 'normalizr';

//Hooks
import useApi from '../../..';
import useStrings from '../../../../strings';

const useCountriesActions = () => {
  const { useProviders } = useApi();
  const { useGeneralProviders } = useProviders();
  const { useCountriesProviders } = useGeneralProviders();
  const { getCountries } = useCountriesProviders();

  //Strings
  const { useGeneralTypes } = useStrings();
  const { useCountriesTypes } = useGeneralTypes();
  const { GET_COUNTRIES } = useCountriesTypes();

  const actGetCountries =
    ({ paginate = 0, append = [] }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await getCountries({ paginate, append });

        const city = new schema.Entity('cities', {});

        const department = new schema.Entity('departments', {
          cities: [city],
        });

        const country = new schema.Entity('countries', {
          departments: [department],
        });

        const schemaNormalized = [country];

        const normalizedData = normalize(res.data?.data, schemaNormalized);

        dispatch({ type: GET_COUNTRIES, payload: normalizedData });
        onSuccess && onSuccess(res);
      } catch (error) {
        onError && onError(error);
      }
    };

  return { actGetCountries };
};

export default useCountriesActions;
