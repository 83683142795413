//packages
import { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

//Hooks
import useApi from 'api';
import useHelpers from 'helpers';
import useModels from 'models';
import useStrings from 'strings';
import useControllers from 'controllers';

const useAdminCreateInterventionsDashboard = () => {
  const history = useHistory();
  const { project_id: projectId } = useParams();

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const {
    REQUIRED_FIELD,
    FILE_TYPE,
    FILE_SIZE,
    MAX_LENGTH,
    MAX_LENGTH_2000,
    MIN_DATE_EQUALS,
    ARRAY_MIN_1_LENGTH,
    DATE_GREATER_THAN_TODAY,
  } = useFormsTypes();

  const [optionsObjetives] = useState([
    { label: 'Virtual', value: 'v' },
    { label: 'Presencial', value: 'p' },
    { label: 'Virtual y Presencial', value: 'pv' },
  ]);

  const [optionsTypes] = useState([
    { label: 'Activar', value: 'activate' },
    { label: 'Inspirar', value: 'inspire' },
    { label: 'Sostener', value: 'hold' },
  ]);

  const [showTextArea, setShowTextArea] = useState(false);
  const [showModalUser, setShowModalUser] = useState(false);

  const { useActions } = useApi();
  const { dispatch, useAdminActions, useUserActions } = useActions();
  const { actResetArtistListInfiniteScroll, actGetUser } = useUserActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions, useAdminCommunitiesActions } =
    useAdminProjectsActions();
  const { actGetCommunitiesByProject } = useAdminCommunitiesActions();
  const { useAdminInterventionsRootActions } = useAdminInterventionsActions();
  const { actGetDefinitionBehaviors, actCreateInterventionsById } =
    useAdminInterventionsRootActions();

  const { useGeneralHooks } = useControllers();
  const { useToggleModal } = useGeneralHooks();
  const [showAssignArtistModal, handleShowAssignArtistModal] = useToggleModal();

  const { useQuickFunctions } = useHelpers();
  const {
    getFileValidations,
    formatMonthWithTwoDigits,
    formatDayWithTwoDigits,
    isSelectedDateGreaterThanTodayDate,
  } = useQuickFunctions();
  const { SUPPORTED_FORMATS_ONLY_IMAGES, MAX_SIZE } = getFileValidations();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors, useUserSelectors } = useSelectors();
  const { usersSelector } = useUserSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors, useAdminCommunitiesSelectors } =
    useAdminProjectsSelectors();
  const { communitiesListSelector } = useAdminCommunitiesSelectors();
  const { getInterventionsSelector } = useAdminInterventionsSelectors();
  const interventions = useSelector(getInterventionsSelector);
  const communitiesListAux = useSelector(communitiesListSelector);
  const definition_behaviors = interventions.definition_behavior;
  const { user: userInfo } = useSelector(usersSelector);

  const schemaCreateIntervention = yup.object({
    name: yup.string().required(REQUIRED_FIELD),
    image: yup
      .mixed()
      .nullable()
      .test('fileType', FILE_TYPE, (value) => {
        if (value) {
          return SUPPORTED_FORMATS_ONLY_IMAGES.includes(value.type);
        }
        return true;
      })
      .test('fileSize', FILE_SIZE, (value) => {
        if (value) {
          return value.size <= MAX_SIZE;
        }
        return true;
      }),
    description: yup.string().required(REQUIRED_FIELD).max(40000, MAX_LENGTH),
    type: yup.object().required(REQUIRED_FIELD).nullable(),
    objetive: yup.object().required(REQUIRED_FIELD).nullable(),
    leading_group: yup
      .string()
      .required(REQUIRED_FIELD)
      .max(2000, MAX_LENGTH_2000),
    allied_group: yup
      .string()
      .required(REQUIRED_FIELD)
      .max(2000, MAX_LENGTH_2000),
    definition_behavior: yup
      .array()
      .required(REQUIRED_FIELD)
      .nullable()
      .min(1, ARRAY_MIN_1_LENGTH),
    other_definition: showTextArea
      ? yup.string().required(REQUIRED_FIELD)
      : yup.string(),
    start_date: yup
      .date()
      .nullable()
      .default(null)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .test('dateValidation', DATE_GREATER_THAN_TODAY, (value) => {
        return value
          ? isSelectedDateGreaterThanTodayDate({ selectedDate: value })
          : true;
      }),
    end_date: yup
      .date()
      .nullable()
      .default(null)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when('start_date', {
        is: (start_date) => {
          if (start_date) {
            return true;
          }
          return false;
        },
        then: yup.date().required(REQUIRED_FIELD).nullable(),
      })
      .when('start_date', (start_date, yup) => {
        if (start_date) {
          return yup.min(start_date, MIN_DATE_EQUALS);
        }
      }),
    user_id: yup.object().required(REQUIRED_FIELD).nullable(),
    communities: yup.array().required(REQUIRED_FIELD).nullable(),
  });

  const {
    control,
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    clearErrors,
    trigger,
  } = useForm({
    resolver: yupResolver(schemaCreateIntervention),
    mode: 'onChange',
  });
  const selectedArtist = watch('user_id');
  const startDateSelected = watch('start_date');

  useEffect(() => {
    if (startDateSelected) {
      trigger('end_date');
    }
  }, [startDateSelected]);

  const communitiesList = useMemo(() => {
    return _.map(communitiesListAux, (community) => {
      return { label: community.name, value: community.id };
    });
  }, [communitiesListAux]);

  const handleOnChange = (e) => {
    const { onChange } = register('definition_behavior');
    onChange(e);
    if (e.target.value === '4') {
      setShowTextArea(!showTextArea);
      !e.target.checked && setValue('other_definition', '');
    }
  };

  useEffect(() => {
    return () => {
      dispatch(actResetArtistListInfiniteScroll());
    };
  }, []);

  useEffect(() => {
    dispatch(actGetCommunitiesByProject({ projectId }));
    dispatch(actGetDefinitionBehaviors());
  }, []);

  const handleRegisterIntervention = (data) => {
    const { communities, start_date, end_date, ...resData } = data;
    const communitiesAux = _.map(communities, (community) => community.value);
    const startDateAux =
      start_date &&
      `${start_date.getUTCFullYear()}-${formatMonthWithTwoDigits({
        month: start_date.getUTCMonth(),
      })}-${formatDayWithTwoDigits({ day: start_date.getUTCDate() })}`;
    const endDateAux = end_date
      ? `${end_date.getUTCFullYear()}-${formatMonthWithTwoDigits({
          month: end_date.getUTCMonth(),
        })}-${formatDayWithTwoDigits({ day: end_date.getUTCDate() })}`
      : undefined;
    dispatch(
      actCreateInterventionsById(
        {
          ...resData,
          projectId,
          communities: communitiesAux,
          start_date: startDateAux,
          end_date: endDateAux,
        },
        onSuccessHandleRegisterIntervention
      )
    );
  };

  const onSuccessHandleRegisterIntervention = () => {
    history.goBack();
    reset();
  };

  const handleResetInputFile = ({ inputFileName }) => {
    setValue(inputFileName, null);
    clearErrors(inputFileName);
  };

  const handleGoBack = () => history.goBack();

  const handleOpenModalSelectArtist = (e) => {
    e.preventDefault();
    handleShowAssignArtistModal();
  };

  const handleSetSelectedArtist = (artistId) => {
    setValue('user_id', artistId);
    trigger('user_id');
  };

  const handleViewProfileArtist = (e) => {
    e.preventDefault();
    dispatch(
      actGetUser(selectedArtist.id, () => {
        setShowModalUser(true);
      })
    );
  };

  return {
    control,
    register,
    handleSubmit,
    errors,
    handleRegisterIntervention,
    handleResetInputFile,
    handleGoBack,
    optionsTypes,
    optionsObjetives,
    interventions,
    definition_behaviors,
    showTextArea,
    handleOnChange,
    showAssignArtistModal,
    handleShowAssignArtistModal,
    handleOpenModalSelectArtist,
    handleSetSelectedArtist,
    selectedArtist,
    communitiesList,
    handleViewProfileArtist,
    showModalUser,
    setShowModalUser,
    userInfo,
    watch,
  };
};

export default useAdminCreateInterventionsDashboard;
