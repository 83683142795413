import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

//Components
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import ActivityForm from '../ActivitieForm';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
import { StyledContentButtons } from 'styles/tableViews/tableViews.styles';

const ActivitieList = () => {
  const { useComponents } = useViews();

  const { useButtons, useTypographies } = useComponents();

  const { ButtonComponent } = useButtons();
  const { TypographyComponent } = useTypographies();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsDevelopmentIntervention } =
    useAdminViewInterventionsControllers();
  const { useDefineInterventionInterventions } =
    useAdminViewInterventionsDevelopmentIntervention();
  const { useArtistDefineInterventionInterventionsControllers } =
    useDefineInterventionInterventions();
  const { useArtistDefineInterventionActivity } =
    useArtistDefineInterventionInterventionsControllers();
  const {
    define_intervention,
    listOfActivities,
    formActivity,
    handleCreateActivity,
    handleDeleteActivity,
    showCreateAndEditActivity,
    handleVisiblityCreateAndEditActivity,
    onSelectActivityForEdit,
    isEditModeInActivity,
    handleResetActivityDataToEdit,
    isActivityEditButtonDisabled,
  } = useArtistDefineInterventionActivity();

  const disabledEditMode = _.includes(
    ['iv', 'c'],
    define_intervention.state.id
  );

  return (
    <>
      {!showCreateAndEditActivity && (
        <>
          <Accordion allowZeroExpanded>
            {listOfActivities.length > 0 &&
              _.map(listOfActivities, (activity, index) => {
                return (
                  <div
                    className="border rounded py-2 px-6 mt-2 bg-white"
                    key={`activity-${index}`}
                  >
                    <AccordionItem>
                      <div className="flex items-center justify-between">
                        <TypographyComponent
                          variant="p"
                          text={activity.name}
                          className="flex-grow text-primary font-bold"
                        />
                        <StyledContentButtons>
                          <ButtonComponent
                            text=""
                            className={`icon-edit text-primary-100 ${
                              disabledEditMode && 'icon-disabled'
                            }`}
                            variant="white"
                            onPress={() => {
                              !disabledEditMode &&
                                onSelectActivityForEdit({
                                  data: activity,
                                });
                            }}
                          />
                          <ButtonComponent
                            text=""
                            className={`icon-delete ${
                              disabledEditMode && 'icon-disabled'
                            }`}
                            onPress={() => {
                              !disabledEditMode &&
                                handleDeleteActivity({
                                  activityId: activity.id,
                                });
                            }}
                          />
                          <AccordionItemState>
                            {({ expanded }) => (
                              <AccordionItemButton>
                                <ButtonComponent
                                  text=""
                                  className=""
                                  variant="white"
                                  icon={
                                    <div
                                      className={`icon-arrow-down text-xxs text-neutral-950 ml-4 ${
                                        expanded && 'transform rotate-180'
                                      }`}
                                    />
                                  }
                                  onPress={() => {
                                    if (!expanded) {
                                      onSelectActivityForEdit({
                                        data: activity,
                                      });
                                    } else {
                                      handleVisiblityCreateAndEditActivity();
                                    }
                                  }}
                                />
                              </AccordionItemButton>
                            )}
                          </AccordionItemState>
                        </StyledContentButtons>
                      </div>
                      <AccordionItemPanel className="py-4" />
                    </AccordionItem>
                  </div>
                );
              })}
          </Accordion>
          <ButtonComponent
            text="Crear nueva actividad"
            icon={<div className="icon-close transform rotate-45 text-sm" />}
            onPress={handleVisiblityCreateAndEditActivity}
            variant="primary"
            mode="outline"
            className={'mt-4 border-none shadow-none px-0'}
            disabled={disabledEditMode}
          />
        </>
      )}

      {showCreateAndEditActivity && (
        <div className="border rounded py-8 px-6 mt-4 bg-white">
          <ActivityForm
            formActivity={formActivity}
            handleCreateActivity={handleCreateActivity}
            handleVisiblityCreateAndEditActivity={
              handleVisiblityCreateAndEditActivity
            }
            isEditModeInActivity={isEditModeInActivity}
            handleResetActivityDataToEdit={handleResetActivityDataToEdit}
            isActivityEditButtonDisabled={isActivityEditButtonDisabled}
            disabledEditMode={disabledEditMode}
          />
        </div>
      )}
    </>
  );
};

ActivitieList.propTypes = {
  handleId: PropTypes.func,
};

export default ActivitieList;
