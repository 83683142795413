//Packages
import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from 'lodash';

//Hooks
import useControllers from 'controllers';
import useModels from 'models';
import useStrings from 'strings';
import useHelpers from 'helpers';
import useApi from 'api';

const useAdminAdditionalInfoDashboard = () => {
  const { project_id: projectId } = useParams();
  const history = useHistory();

  const [show, setShow] = useState(false);
  const [cleanFileInputs, setCleanFileInputs] = useState(false);
  const [additionalInfoData, setAdditionalInfoData] = useState(null);

  const { useGeneralHooks } = useControllers();
  const { useToggleModal } = useGeneralHooks();
  const [showDeleteAdditionalInfoModal, handleDeleteAdditionalInfoModal] =
    useToggleModal();
  const [showViewAditionalInfoModal, handleViewAditionalInfoModal] =
    useToggleModal();

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { REQUIRED_FIELD, FILE_TYPE, FILE_SIZE, MAX_LENGTH } = useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const { getFileValidations, getMimeTypeFromMedia } = useQuickFunctions();
  const { MAX_SIZE, SUPPORTED_FORMATS } = getFileValidations();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminAdditionalInfoActions } = useAdminProjectsActions();
  const {
    actCreateAdditionalInfo,
    actGetAdditionalInfo,
    actDeleteAdditionalInfo,
    actUpdateAdditionalInfo,
  } = useAdminAdditionalInfoActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminAdditionalInfoSelectors } = useAdminProjectsSelectors();
  const { listAdditionalInfoSelector } = useAdminAdditionalInfoSelectors();
  const additionalInfo = useSelector(listAdditionalInfoSelector);

  const schemaCreateCommunity = yup.object({
    text: yup
      .string()
      .required(REQUIRED_FIELD)
      .max(40000, MAX_LENGTH)
      .nullable(),
    main: yup
      .mixed()
      .test('fileType', FILE_TYPE, (value) => {
        if (value) {
          return SUPPORTED_FORMATS.includes(value.type);
        }
        return true;
      })
      .test('fileSize', FILE_SIZE, (value) => {
        if (value) {
          return value.size <= MAX_SIZE;
        }
        return true;
      })
      .nullable()
      .notRequired(),
    first: yup
      .mixed()
      .nullable()
      .notRequired()
      .test('fileType', FILE_TYPE, (value) => {
        if (value) {
          return SUPPORTED_FORMATS.includes(value.type);
        }
        return true;
      })
      .test('fileSize', FILE_SIZE, (value) => {
        if (value) {
          return value.size <= MAX_SIZE;
        }
        return true;
      }),
    second: yup
      .mixed()
      .nullable()
      .notRequired()
      .test('fileType', FILE_TYPE, (value) => {
        if (value) {
          return SUPPORTED_FORMATS.includes(value.type);
        }
        return true;
      })
      .test('fileSize', FILE_SIZE, (value) => {
        if (value) {
          return value.size <= MAX_SIZE;
        }
        return true;
      }),
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schemaCreateCommunity),
    mode: 'onChange',
  });

  useEffect(() => {
    dispatch(actGetAdditionalInfo({ projectId }));
  }, []);

  const onSuccessCreateAdditionalInfo = () => {
    reset({
      text: null,
      main: null,
      first: null,
      second: null,
    });
    setCleanFileInputs((prevState) => !prevState);
    setAdditionalInfoData(null);
    setShow((prevState) => !prevState);
  };

  const handleRegisterAdditionalInfo = (data) => {
    dispatch(
      actCreateAdditionalInfo(
        { ...data, projectId },
        onSuccessCreateAdditionalInfo
      )
    );
  };

  const handleDeleteAdditionalInfo = () => {
    dispatch(
      actDeleteAdditionalInfo(
        { additionalInfoId: additionalInfo.id, projectId },
        () => {
          handleDeleteAdditionalInfoModal();
        }
      )
    );
  };

  const handleUpdateAdditionalInfo = (data) => {
    dispatch(
      actUpdateAdditionalInfo(
        { ...data, additionalInfoId: additionalInfo.id, projectId },
        onSuccessCreateAdditionalInfo
      )
    );
  };

  const handleAddAdditionalInfo = () => {
    reset({
      text: null,
      main: null,
      first: null,
      second: null,
    });
    setCleanFileInputs((prevState) => !prevState);
    setAdditionalInfoData(null);
    setShow((prevState) => !prevState);
  };

  const handleCancelAdditionalInfo = (e) => {
    e.preventDefault();
    reset({});
    setAdditionalInfoData(null);
    setCleanFileInputs((prevState) => !prevState);
    setShow((prevState) => !prevState);
  };

  const handleEditAdditionalInfo = () => {
    setValue('text', additionalInfo.text);
    setAdditionalInfoData(additionalInfo);
    setShow(true);
    setCleanFileInputs((prevState) => !prevState);
  };

  const handleResetInputFile = ({ typeMultimedia }) => {
    setValue(typeMultimedia, '');
    clearErrors(typeMultimedia);
  };

  const handleSelectTypeFile = (typeMultimedia) => {
    const defaultImage = _.find(
      additionalInfoData?.multimedias,
      (multimedia) => multimedia.type === typeMultimedia
    );

    return getMimeTypeFromMedia({ urlFile: defaultImage?.url }) || 'image';
  };

  const handleSelectDefaultImage = (typeImage) => {
    const defaultImage = _.find(
      additionalInfoData?.multimedias,
      (multimedia) => multimedia.type === typeImage
    );

    return defaultImage?.url ?? '';
  };

  const handleGoBack = () =>
    history.push(`/admin/projects/dashboard/${projectId}`);

  return {
    handleGoBack,
    showDeleteAdditionalInfoModal,
    handleDeleteAdditionalInfoModal,
    show,
    additionalInfo,
    handleAddAdditionalInfo,
    errors,
    register,
    control,
    handleSubmit,
    handleRegisterAdditionalInfo,
    handleCancelAdditionalInfo,
    handleDeleteAdditionalInfo,
    additionalInfoData,
    cleanFileInputs,
    handleResetInputFile,
    handleSelectTypeFile,
    handleSelectDefaultImage,
    handleUpdateAdditionalInfo,
    handleEditAdditionalInfo,

    showViewAditionalInfoModal,
    handleViewAditionalInfoModal,
  };
};

export default useAdminAdditionalInfoDashboard;
