const useAdminInterventionsRootInitialStates = () => {
  const initialStateInterventions = {
    definition_behaviors: [],
    interventionsList: {
      data: [],
      meta: {
        current_page: '',
        last_page: '',
        total: '',
        per_page: '',
      },
      links: {
        next: '',
        prev: '',
      },
    },
    interventionSelectedToEdit: {
      id: '',
      name: '',
      communities: [],
      image: {
        id: '',
        url: '',
      },
      artist: {
        id: '',
        name: '',
        last_name: '',
      },
      description: '',
      objetive: '',
      type: {
        id: '',
        name: '',
      },
      start_date: '',
      end_date: '',
      definition_behaviors: [],
      leading_group: '',
      allied_group: '',
      other_definition: '',
      state: { id: '', name: '' },
      actual_phase: { id: '1', name: '' },
      customActualPhase: '',
    },
    filter: {
      page: 1,
      search: '',
    },
    know_project: {
      date_c: '',
      date_ip: '',
      date_limit: '',
      id: '',
      state: {
        id: '',
        name: '',
      },
      project: {},
    },
    plan_creative: {
      date_c: '',
      date_ip: '',
      date_limit: '',
      file: {
        id: '',
        url: '',
      },
      state: { id: '', name: '' },
    },
    know_community: {
      com_deepen: false,
      date_c: '',
      date_ip: '',
      date_limit: '',
      id: '',
      files: [],
      state: { id: '', name: '' },
    },
    define_intervention: {
      id: '',
      date_limit: '',
      date_ip: '',
      date_c: '',
      date_r: '',
      date_iv: '',
      key_factors: [],
      definition_interventions: [],
      state: { id: '', name: '' },
      convocation_method: [],
      diffusion_dynamic: [],
      convocation_tasks: [],
      rejected_reasons: [],
      convocation_file: '',
      diffusion_file: '',
      convocation_description: '',
      diffusion_description: '',
    },
    deepen_knowledge: {
      id: '',
      communities: [],
      what_to_discover: null,
      methodology_file: null,
      deepening_modality: { id: '' },
      deepen_type: { id: '' },
      state: { id: '', name: '' },
      activities: [],
      messages: [],
      edit_form_activity: { open: false, index: 0 },
      open: false,
    },
    deepen_execution: {
      id: '',
      communities: [],
      change_agents: [],
      what_to_discover: null,
      methodology_file: null,
      deepening_modality: { id: '' },
      deepen_type: { id: '' },
      state: { id: '', name: '' },
      activities: [],
      messages: [],
    },
    toSendMessage: {
      body: '',
      channel: { id: '', name: '' },
      description: '',
      id: '',
      messageRequest: null,
      state: { id: '', state: '' },
      type: { id: '', name: '' },
      typeInformation: { id: '', name: '' },
    },
    createSketch: {
      id: '',
      date_limit: '',
      state: { id: '', name: '' },
      sketch_file: '',
      rejected_reasons: [],
      rejected_reason_file: '',
      date_r: '',
    },
    pilotPlan: {
      id: '',
      state: { id: '', name: '' },
      file: { id: '', url: '' },
      activities: [],
      rejected_reasons: [],
    },
    programPilot: {
      id: '',
      state: { id: '', name: '' },
      pilotPlan: {},
      changeAgents: [],
      place: '',
    },
    evaluatePilot: {
      id: '',
      state: { id: '', name: '' },
      programPilot: {},
      documents: [],
      document_partner: { id: '', url: '' },
      artist: '',
      partner: '',
      community: '',
    },
    executeMaterialize: {
      id: '',
      state: { id: '', name: '' },
      definition: {},
      files: [],
      rejected_reasons: [],
    },
    executeIntervention: {
      id: '',
      intervention: {},
      state: { id: '', name: '' },
      events: [],
    },
    executeEvaluate: {
      id: '',
      intervention: {},
      state: { id: '', name: '' },
      files: [],
      goals: [],
    },
    spreadIntervention: {
      id: '',
      intervention: {},
      state: { id: '', name: '' },
      recommendations: { id: '', name: '' },
      files: [],
      goals: [],
      tastes: '',
      suggestions: '',
    },
  };

  const initialStateInterventionDefinitionSelected = {
    id: '',
  };

  const inititalStateDiffusionDefinitionForm = {
    convocation_method: [],
    convocation_description: null,
    convocation_file: {
      blobUrl: null,
      fileName: '',
      mimeType: '',
    },
    diffusion_file: {
      blobUrl: null,
      fileName: '',
      mimeType: '',
    },
    diffusion_dynamic: [],
    diffusion_description: null,
  };

  return {
    initialStateInterventions,
    initialStateInterventionDefinitionSelected,
    inititalStateDiffusionDefinitionForm,
  };
};

export default useAdminInterventionsRootInitialStates;
