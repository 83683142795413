import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';

// Hooks
import useModels from 'models';
import useApi from 'api';
import useStrings from 'strings';
import useHelpers from 'helpers';
import useControllers from 'controllers';

const useAdminAndArtistEvaluatePilotIntervention = () => {
  const { intervention_id: interventionId } = useParams();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();
  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const { useAdminDevelopmentInterventionCreateSketchActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const {
    actGetEvaluatePilotIntervention,
    actUpdateEvaluatePilotIntervention,
    actUploadFileEvaluatePilotIntervention,
    actDownloadFileEvaluatePilotIntervention,
    actDeleteFileEvaluatePilotIntervention,
  } = useAdminDevelopmentInterventionCreateSketchActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionsSelector } = useAdminInterventionsSelectors();
  const { evaluatePilot } = useSelector(getInterventionsSelector);
  const { id: evaluatePilotId } = evaluatePilot;

  const { useGeneralHooks } = useControllers();
  const { useGetUserProfile } = useGeneralHooks();
  const {
    role: { id: roleId },
  } = useGetUserProfile();

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { REQUIRED_FIELD, MAX_LENGTH_500, FILE_SIZE_5MB, FILE_SIZE_10MB } =
    useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const { downloadFileFromBackend, getDocumentName, getFileValidations } =
    useQuickFunctions();
  const { MAX_SIZE_5MB, MAX_SIZE_10MB } = getFileValidations();

  const [showModalApprove, setShowModalApprove] = useState(false);
  const [isAdmin] = useState(roleId === 'ad' || roleId === 'ep');

  const schemaFormEvaluatePilot = yup.object({
    fileArtist:
      evaluatePilot.documents?.length > 0 || isAdmin
        ? yup
            .mixed()
            .test('fileSize', FILE_SIZE_10MB, (value) => {
              if (value) {
                return value.size <= MAX_SIZE_10MB;
              }
              return true;
            })
            .notRequired()
        : yup
            .mixed()
            .required(REQUIRED_FIELD)
            .test('fileSize', FILE_SIZE_10MB, (value) => {
              if (value) {
                return value.size <= MAX_SIZE_10MB;
              }
              return true;
            }),
    fileAdmin:
      !evaluatePilot.document_partner?.id && isAdmin
        ? yup
            .mixed()
            .test('fileSize', FILE_SIZE_5MB, (value) => {
              if (value) {
                return value.size <= MAX_SIZE_5MB;
              }
              return true;
            })
            .required(REQUIRED_FIELD)
        : yup
            .mixed()
            .test('fileSize', FILE_SIZE_5MB, (value) => {
              if (value) {
                return value.size <= MAX_SIZE_5MB;
              }
              return true;
            })
            .notRequired(),
    artist: isAdmin
      ? yup.string().notRequired()
      : yup.string().max(500, MAX_LENGTH_500).required(REQUIRED_FIELD),
    community: isAdmin
      ? yup.string().notRequired()
      : yup.string().max(500, MAX_LENGTH_500).required(REQUIRED_FIELD),
    partner: isAdmin
      ? yup.string().max(500, MAX_LENGTH_500).required(REQUIRED_FIELD)
      : yup.string().notRequired(),
  });

  const formEvaluatePilot = useForm({
    resolver: yupResolver(schemaFormEvaluatePilot),
    mode: 'onChange',
  });

  useEffect(() => {
    if (evaluatePilot.state?.id !== 'c') {
      dispatch(
        actGetEvaluatePilotIntervention({
          interventionId,
        })
      );
    }
  }, []);

  useEffect(() => {
    const file = formEvaluatePilot.watch('fileArtist');
    if (file?.size <= MAX_SIZE_10MB) {
      uploadFileEvaluatePilotIntervention();
    }
  }, [formEvaluatePilot.watch('fileArtist')]);

  useEffect(() => {
    if (_.includes(['ir', 'c'], evaluatePilot.state?.id)) {
      formEvaluatePilot.reset({
        artist: evaluatePilot.artist,
        community: evaluatePilot.community,
        partner: evaluatePilot.partner || '',
      });
    }
  }, [evaluatePilot.id]);

  const uploadFileEvaluatePilotIntervention = () => {
    const data = new FormData();
    data.append('file', formEvaluatePilot.watch('fileArtist'));
    dispatch(
      actUploadFileEvaluatePilotIntervention({
        interventionId,
        evaluatePilotId,
        data,
      })
    );
  };

  const openFileEvaluatePilotIntervention = ({ e, file, watchFile }) => {
    e && e.preventDefault();
    if (watchFile) {
      const blobUrl = URL.createObjectURL(watchFile);
      window.open(blobUrl);
    } else {
      window.open(file.url);
    }
  };

  const downloadFileEvaluatePilotIntervention = ({ e, file }) => {
    e && e.preventDefault();
    dispatch(
      actDownloadFileEvaluatePilotIntervention(
        {
          interventionId,
          evaluatePilotId,
          fileId: file.id,
        },
        (response) => {
          downloadFileFromBackend({
            response,
            nameFile: getDocumentName({ file, withExtension: false }),
          });
        }
      )
    );
  };

  const deleteFileEvaluatePilotIntervention = ({
    e,
    file = {},
    fieldToDelete,
  }) => {
    e && e.preventDefault();
    if (fieldToDelete) {
      formEvaluatePilot.reset({ [fieldToDelete]: undefined });
    } else {
      dispatch(
        actDeleteFileEvaluatePilotIntervention({
          interventionId,
          evaluatePilotId,
          fileId: file.id,
        })
      );
    }
  };

  const updateEvaluatePilotIntervention = (data) => {
    setShowModalApprove(false);
    if (isAdmin) {
      const dataAdmin = new FormData();
      dataAdmin.append('partner_file', data.fileAdmin);
      dataAdmin.append('partner', data.partner);
      dataAdmin.append('_method', 'PATCH');
      dataAdmin.append('state', 'c');
      dispatch(
        actUpdateEvaluatePilotIntervention({
          interventionId,
          evaluatePilotId,
          data: dataAdmin,
        })
      );
    } else {
      dispatch(
        actUpdateEvaluatePilotIntervention({
          interventionId,
          evaluatePilotId,
          data: {
            ...data,
            _method: 'PATCH',
            state: 'ir',
          },
        })
      );
    }
  };

  return {
    evaluatePilot,
    formEvaluatePilot,
    showModalApprove,
    setShowModalApprove,
    stepCompleted: isAdmin
      ? _.includes(['p', 'ip', 'c'], evaluatePilot.state?.id)
      : _.includes(['ir', 'c'], evaluatePilot.state?.id),
    openFileEvaluatePilotIntervention,
    downloadFileEvaluatePilotIntervention,
    deleteFileEvaluatePilotIntervention,
    updateEvaluatePilotIntervention,
  };
};

export default useAdminAndArtistEvaluatePilotIntervention;
