//Packages
import _ from 'lodash';

//Hooks
import useModels from '../../../..';
import useHelpers from '../../../../../helpers';
import useStrings from '../../../../../strings';

const useAdminProjectsRootReducers = () => {
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  const { useInitialStates } = useModels();
  const { useAdminInitialStates } = useInitialStates();
  const { useAdminProjectsInitialStates } = useAdminInitialStates();
  const { useAdminProjectsRootInitialStates } = useAdminProjectsInitialStates();
  const {
    initialStateProjectsList,
    initialStateProjectFilter,
    initialStateProjectSelected,
  } = useAdminProjectsRootInitialStates();

  const { useAdminTypes, useAuthTypes } = useStrings();
  const { useAdminProjectsTypes } = useAdminTypes();
  const {
    useAdminSocioCulturalInfoTypes,
    useAdminFestivitiesAndTraditionsTypes,
    useAdminAbcTrajectoryTypes,
    useAdminProjectsRootTypes,
    useAdminRecommendationsTypes,
    useAdminAdditionalInfoTypes,
    useAdminDocumentationTypes,
  } = useAdminProjectsTypes();
  const {
    GET_PROJECTS,
    SET_DATA_PROJECT_FILTER,
    RESET_DATA_PROJECT_FILTER,
    RESET_DATA_PROJECT_FILTER_WITHOUT_NAME,
    GET_PROJECT_ID,
    SET_PAGE_GET_PROJECTS,
  } = useAdminProjectsRootTypes();
  const {
    GET_SOCIO_CULTURAL_INFO,
    CREATE_SOCIO_CULTURAL_INFO,
    UPDATE_SOCIO_CULTURAL_INFO,
    DELETE_SOCIO_CULTURAL_INFO,
  } = useAdminSocioCulturalInfoTypes();
  const {
    GET_FESTIVITIES_AND_TRADITIONS,
    CREATE_FESTIVITIES_AND_TRADITIONS,
    UPDATE_FESTIVITIES_AND_TRADITIONS,
    DELETE_FESTIVITIES_AND_TRADITIONS,
  } = useAdminFestivitiesAndTraditionsTypes();
  const {
    GET_ABC_TRAJECTORIES,
    CREATE_ABC_TRAJECTORY,
    UPDATE_ABC_TRAJECTORY,
    DELETE_ABC_TRAJECTORY,
  } = useAdminAbcTrajectoryTypes();
  const {
    GET_RECOMMENDATIONS,
    CREATE_RECOMMENDATIONS,
    UPDATE_RECOMMENDATIONS,
    DELETE_RECOMMENDATION,
  } = useAdminRecommendationsTypes();
  const {
    CREATE_ADDITIONAL_INFO,
    UPDATE_ADDITIONAL_INFO,
    GET_ADDITIONAL_INFO,
    DELETE_ADDITIONAL_INFO,
  } = useAdminAdditionalInfoTypes();
  const {
    GET_DOCUMENTATION_BY_PROJECT,
    CREATE_DOCUMENTATION_BY_PROJECT,
    DELETE_DOCUMENTATION_BY_PROJECT,
  } = useAdminDocumentationTypes();

  const { LOGOUT } = useAuthTypes();

  const projectsList = createReducer(initialStateProjectsList, {
    [GET_PROJECTS](state, action) {
      const { data } = action.payload;

      return {
        ...state,
        data,
        meta: action.payload?.meta
          ? {
              current_page: action.payload?.meta.current_page,
              last_page: action.payload?.meta.last_page,
              total: action.payload?.meta.total,
              per_page: action.payload?.meta.per_page,
            }
          : {
              current_page: '',
              last_page: '',
              total: '',
              per_page: '',
            },
        links: {
          prev: action.payload?.links?.prev,
          next: action.payload?.links?.next,
        },
      };
    },
    [SET_PAGE_GET_PROJECTS](state, action) {
      const { page } = action.payload;
      return {
        ...state,
        page,
      };
    },
    [LOGOUT]() {
      return initialStateProjectsList;
    },
  });

  const projectFilter = createReducer(initialStateProjectFilter, {
    [SET_DATA_PROJECT_FILTER](state, action) {
      const { key, value } = action.payload;

      return {
        ...state,
        [key]: value,
      };
    },
    [RESET_DATA_PROJECT_FILTER](state) {
      const orderByKey = state.orderByKey;
      const orderByValue = state.orderByValue;
      const page = state.page;
      return { ...initialStateProjectFilter, orderByKey, orderByValue, page };
    },
    [RESET_DATA_PROJECT_FILTER_WITHOUT_NAME](state) {
      const name = state.name;
      const orderByKey = state.orderByKey;
      const orderByValue = state.orderByValue;
      const page = state.page;
      return {
        ...initialStateProjectFilter,
        name,
        orderByKey,
        orderByValue,
        page,
      };
    },
    [LOGOUT]() {
      return initialStateProjectFilter;
    },
  });

  const projectSelected = createReducer(initialStateProjectSelected, {
    [GET_PROJECT_ID](state, action) {
      const {
        name,
        population_number,
        image,
        city,
        executing_partner,
        project_manager,
        description,
        social_expert,
        languages,
        dashboard,
      } = action.payload;

      return {
        ...state,
        name,
        population_number,
        image,
        city,
        executing_partner,
        project_manager,
        description,
        social_expert,
        languages,
        dashboard,
      };
    },
    [GET_SOCIO_CULTURAL_INFO](state, action) {
      return { ...state, social_cultural_info: [...action.payload] };
    },
    [CREATE_SOCIO_CULTURAL_INFO](state, action) {
      const { id, name, description, multimedias } = action.payload;
      return {
        ...state,
        social_cultural_info: [
          ...state.social_cultural_info,
          { id, name, description, multimedias },
        ],
      };
    },
    [UPDATE_SOCIO_CULTURAL_INFO](state, action) {
      const { socioCulturalId, data } = action.payload;
      const { id, name, description, multimedias } = data;
      const listSocialDynamicsUpdated = _.map(
        state.social_cultural_info,
        (socioCulturalInfo) => {
          if (socioCulturalInfo.id === socioCulturalId) {
            return { id, name, description, multimedias };
          } else {
            return socioCulturalInfo;
          }
        }
      );
      return {
        ...state,
        social_cultural_info: [...listSocialDynamicsUpdated],
      };
    },
    [DELETE_SOCIO_CULTURAL_INFO](state, action) {
      const { socioCulturalId } = action.payload;
      const listSocialDynamicsUpdated = _.filter(
        state.social_cultural_info,
        (socioCulturalInfo) => socioCulturalInfo.id !== socioCulturalId
      );
      return {
        ...state,
        social_cultural_info: [...listSocialDynamicsUpdated],
      };
    },
    [GET_FESTIVITIES_AND_TRADITIONS](state, action) {
      return { ...state, festivities: [...action.payload] };
    },
    [CREATE_FESTIVITIES_AND_TRADITIONS](state, action) {
      const { id, name, category, description, multimedias } = action.payload;
      return {
        ...state,
        festivities: [
          ...state.festivities,
          { id, name, category, description, multimedias },
        ],
      };
    },
    [UPDATE_FESTIVITIES_AND_TRADITIONS](state, action) {
      const { festivityId, data } = action.payload;
      const { id, name, category, description, multimedias } = data;
      const listFestivitiesUpdated = _.map(state.festivities, (festivity) => {
        if (festivity.id === festivityId) {
          return { id, name, category, description, multimedias };
        } else {
          return festivity;
        }
      });
      return {
        ...state,
        festivities: [...listFestivitiesUpdated],
      };
    },
    [DELETE_FESTIVITIES_AND_TRADITIONS](state, action) {
      const { festivityId } = action.payload;
      const listFestivitiesUpdated = _.filter(
        state.festivities,
        (festivity) => festivity.id !== festivityId
      );
      return {
        ...state,
        festivities: [...listFestivitiesUpdated],
      };
    },
    [GET_ABC_TRAJECTORIES](state, action) {
      return { ...state, trajectories: [...action.payload] };
    },
    [CREATE_ABC_TRAJECTORY](state, action) {
      const { id, name, description, type, image } = action.payload;
      return {
        ...state,
        trajectories: [
          ...state.trajectories,
          { id, name, description, type, image },
        ],
      };
    },
    [UPDATE_ABC_TRAJECTORY](state, action) {
      const { abcTrajectoryId, data } = action.payload;
      const { id, name, description, type, image } = data;
      const listAbcTrajectoryUpdated = _.map(
        state.trajectories,
        (abcTrajectory) => {
          if (abcTrajectory.id === abcTrajectoryId) {
            return { id, name, description, type, image };
          } else {
            return abcTrajectory;
          }
        }
      );
      return {
        ...state,
        trajectories: [...listAbcTrajectoryUpdated],
      };
    },
    [DELETE_ABC_TRAJECTORY](state, action) {
      const { abcTrajectoryId } = action.payload;
      const listAbcTrajectoryUpdated = _.filter(
        state.trajectories,
        (abcTrajectory) => abcTrajectory.id !== abcTrajectoryId
      );
      return {
        ...state,
        trajectories: [...listAbcTrajectoryUpdated],
      };
    },
    [GET_RECOMMENDATIONS](state, action) {
      return { ...state, recommendations: [...action.payload] };
    },
    [CREATE_RECOMMENDATIONS](state, action) {
      const { id, recommendation } = action.payload;
      return {
        ...state,
        recommendations: [...state.recommendations, { id, recommendation }],
      };
    },
    [UPDATE_RECOMMENDATIONS](state, action) {
      const { recommendationId, data } = action.payload;
      const { id, recommendation } = data;
      const listRecommendationsUpdated = _.map(
        state.recommendations,
        (recommendationInfo) => {
          if (recommendationInfo.id === recommendationId) {
            return { id, recommendation };
          } else {
            return recommendationInfo;
          }
        }
      );
      return {
        ...state,
        recommendations: [...listRecommendationsUpdated],
      };
    },
    [DELETE_RECOMMENDATION](state, action) {
      const { recommendationId } = action.payload;
      const listRecommendationsUpdated = _.filter(
        state.recommendations,
        (recommendationInfo) => recommendationInfo.id !== recommendationId
      );
      return {
        ...state,
        recommendations: [...listRecommendationsUpdated],
      };
    },
    [GET_ADDITIONAL_INFO](state, action) {
      if (action.payload) {
        const { id, text, multimedias } = action.payload;
        return { ...state, additional_info: { id, text, multimedias } };
      } else {
        return state;
      }
    },
    [CREATE_ADDITIONAL_INFO](state, action) {
      const { id, text, multimedias } = action.payload;
      return {
        ...state,
        additional_info: { id, text, multimedias },
      };
    },
    [UPDATE_ADDITIONAL_INFO](state, action) {
      const { id, text, multimedias } = action.payload;
      return {
        ...state,
        additional_info: { id, text, multimedias },
      };
    },
    [DELETE_ADDITIONAL_INFO](state) {
      return {
        ...state,
        additional_info: { id: '', text: '', multimedias: [] },
      };
    },
    [GET_DOCUMENTATION_BY_PROJECT](state, action) {
      return { ...state, annexes: [...action.payload] };
    },
    [CREATE_DOCUMENTATION_BY_PROJECT](state, action) {
      return { ...state, annexes: [...action.payload] };
    },
    [DELETE_DOCUMENTATION_BY_PROJECT](state, action) {
      const { documentationId } = action.payload;
      const listDocumentationUpdated = _.filter(
        state.annexes,
        (documentationInfo) => documentationInfo.id !== documentationId
      );
      return {
        ...state,
        annexes: [...listDocumentationUpdated],
      };
    },
    [LOGOUT]() {
      return initialStateProjectSelected;
    },
  });

  return { projectsList, projectFilter, projectSelected };
};

export default useAdminProjectsRootReducers;
