import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useHomeProviders = () => {
  const storeConfigHomeProvider = ({ object }) => {
    const response = axios({
      method: 'POST',
      url: '/home',
      data: object,
    });

    return trackPromise(response);
  };
  const getConfigHomeProvider = () => {
    const response = axios({
      method: 'GET',
      url: '/home',
    });

    return trackPromise(response);
  };
  const updateConfigHomeProvider = ({ formData }) => {
    const response = axios({
      method: 'POST',
      url: '/home',
      data: formData,
    });

    return trackPromise(response);
  };
  const getInterventionsProvider = ({
    country_id,
    department_id,
    objective,
    type,
    paginate,
    page,
    project_id,
    name,
  }) => {
    const response = axios({
      method: 'GET',
      url: `/home/interventions`,
      params: {
        country_id,
        department_id,
        objective,
        type,
        paginate,
        page,
        project_id,
        name,
      },
    });
    return trackPromise(response);
  };
  const getProjectsHomeProvider = ({ paginate, page }) => {
    const response = axios({
      method: 'GET',
      url: '/home/projects',
      params: { paginate, page },
    });
    return trackPromise(response);
  };

  const getInterventionInfoForEditProvider = ({ interventionId }) => {
    const response = axios({
      method: 'GET',
      url: `/home/interventions/${interventionId}`,
    });
    return trackPromise(response);
  };
  return {
    storeConfigHomeProvider,
    getConfigHomeProvider,
    updateConfigHomeProvider,
    getInterventionsProvider,
    getProjectsHomeProvider,
    getInterventionInfoForEditProvider,
  };
};
export default useHomeProviders;
