//Hooks
import useApi from '../../../..';
import useControllers from '../../../../../controllers';
import useStrings from '../../../../../strings';

const useAdminAdditionalInfoActions = () => {
  const { useAdminTypes } = useStrings();
  const { useAdminProjectsTypes } = useAdminTypes();
  const { useAdminAdditionalInfoTypes } = useAdminProjectsTypes();
  const {
    CREATE_ADDITIONAL_INFO,
    DELETE_ADDITIONAL_INFO,
    GET_ADDITIONAL_INFO,
    UPDATE_ADDITIONAL_INFO,
  } = useAdminAdditionalInfoTypes();

  const { useProviders } = useApi();
  const { useAdminProviders } = useProviders();
  const { useAdminProjectsProviders } = useAdminProviders();
  const { useAdminAdditionalInfoProviders } = useAdminProjectsProviders();
  const {
    createAdditionalInfo,
    deleteAdditionalInfo,
    getAdditionalInfo,
    updateAdditionalInfo,
  } = useAdminAdditionalInfoProviders();

  const { useGeneralHooks } = useControllers();
  const { useConsole } = useGeneralHooks();
  const { viewConsole } = useConsole();

  const actGetAdditionalInfo =
    ({ projectId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await getAdditionalInfo({
          projectId,
        });
        dispatch({
          type: GET_ADDITIONAL_INFO,
          payload: res.data?.data?.additional_info,
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actCreateAdditionalInfo =
    ({ text, main, first, second, projectId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const bodyFormData = new FormData();
        bodyFormData.append('text', text);
        main && bodyFormData.append('multimedias[main]', main);
        first && bodyFormData.append('multimedias[first]', first);
        second && bodyFormData.append('multimedias[second]', second);

        const res = await createAdditionalInfo({
          formData: bodyFormData,
          projectId,
        });
        dispatch({
          type: CREATE_ADDITIONAL_INFO,
          payload: res.data?.data,
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actUpdateAdditionalInfo =
    (
      { projectId, additionalInfoId, text, main, first, second },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const bodyFormData = new FormData();
        bodyFormData.append('text', text);
        (main || main === '') && bodyFormData.append('multimedias[main]', main);
        (first || first === '') &&
          bodyFormData.append('multimedias[first]', first);
        (second || second === '') &&
          bodyFormData.append('multimedias[second]', second);
        bodyFormData.append('_method', 'put');

        const res = await updateAdditionalInfo({
          formData: bodyFormData,
          additionalInfoId,
          projectId,
        });
        dispatch({
          type: UPDATE_ADDITIONAL_INFO,
          payload: res.data?.data,
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actDeleteAdditionalInfo =
    ({ additionalInfoId, projectId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await deleteAdditionalInfo({
          projectId,
          additionalInfoId,
        });
        dispatch({
          type: DELETE_ADDITIONAL_INFO,
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };
  return {
    actGetAdditionalInfo,
    actCreateAdditionalInfo,
    actUpdateAdditionalInfo,
    actDeleteAdditionalInfo,
  };
};

export default useAdminAdditionalInfoActions;
