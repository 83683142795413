import React, { lazy } from 'react';

//Hooks
import useViews from '../../../../../../../../../../..';
import imageExample from '../../../../../../../../../../../../assets/img/img-about-role-socio.png';

//Styles
const StyledList = lazy(() =>
  import('./Evaluate.styles').then((mod) => ({
    default: mod.StyledList,
  }))
);
const StyledListItem = lazy(() =>
  import('./Evaluate.styles').then((mod) => ({
    default: mod.StyledListItem,
  }))
);

const StyledBoxImages2col1 = lazy(() =>
  import('../../../../../../../../../../../../styles/boxes/boxes.styles').then(
    (mod) => ({
      default: mod.StyledBoxImages2col1,
    })
  )
);

const Evaluate = () => {
  const { useComponents } = useViews();

  const { useTypographies, useImages, useButtons } = useComponents();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { Image } = useImages();
  const { ButtonComponent } = useButtons();

  return (
    <>
      <TypographyComponent
        text="Evaluar intervención"
        variant="h2"
        className="text-primary mt-2 font-bold"
      />
      <TypographyComponent
        text="Cras bibendum nulla eu mollis tempus. Vestibulum in dolor et arcu malesuada maximus ac at justo. Ut dignissim maximus ornare. Integer tincidunt erat sed sapien dictum, a malesuada nunc lobortis. Vivamus volutpat, elit sed placerat bibendum, elit dui tempor odio, ac condimentum purus massa sit amet quam. Nam ut metus non libero rutrum efficitur eu nec tellus. Vestibulum viverra, dolor ac gravida scelerisque, velit dui tincidunt ex, non tincidunt velit libero sit amet orci."
        variant="p"
        className="text-neutral-950 mt-4 mb-4"
      />

      <TypographyWithTooltip
        text="Visión general"
        variant="h3"
        textTooltip="Modalidad de profundización"
        className="text-primary mt-4"
        idTooltip="general_vision"
      />
      <TypographyComponent
        text="¿Cuáles fueron los logros de esta intervención?"
        variant="p"
        className="text-neutral-950 mt-4 mb-1 font-bold"
      />
      <StyledList>
        <StyledListItem>
          <TypographyComponent
            text="Cras bibendum nulla eu mollis tempus. Vestibulum in dolor et arcu malesuada maximus ac at justo."
            variant="p"
            className="text-neutral-950 "
          />
        </StyledListItem>
      </StyledList>
      <TypographyComponent
        text="¿Qué recomendaciones le darías a otros artistas que vayan a realizar una intervención similar?"
        variant="p"
        className="text-neutral-950 mt-4 mb-1 font-bold"
      />
      <TypographyComponent
        text="Cras bibendum nulla eu mollis tempus. Vestibulum in dolor et arcu malesuada maximus ac at justo. Ut dignissim maximus ornare. Integer tincidunt erat sed sapien dictum, a malesuada nunc lobortis. Vivamus volutpat, elit sed placerat bibendum, elit dui tempor odio, ac condimentum purus massa sit amet quam. Nam ut metus non libero rutrum efficitur eu nec tellus. Vestibulum viverra, dolor ac gravida scelerisque, velit dui tincidunt ex."
        variant="p"
        className="text-neutral-950 "
      />
      <TypographyWithTooltip
        text="Visión de la comunidad"
        variant="h3"
        textTooltip="Visión de la comunidad"
        className="text-primary mt-4"
        idTooltip="communitie_vision"
      />
      <TypographyComponent
        text="¿Qué fue lo que más le gustó a la comunidad?"
        variant="p"
        className="text-neutral-950 mt-4 mb-1 font-bold"
      />
      <TypographyComponent
        text="Cras bibendum nulla eu mollis tempus. Vestibulum in dolor et arcu malesuada maximus ac at justo. Ut dignissim maximus ornare. Integer tincidunt erat sed sapien dictum, a malesuada nunc lobortis. Vivamus volutpat, elit sed placerat bibendum, elit dui tempor odio, ac condimentum purus massa sit amet quam. Nam ut metus non libero rutrum efficitur eu nec tellus. Vestibulum viverra, dolor ac gravida scelerisque, velit dui tincidunt ex."
        variant="p"
        className="text-neutral-950 "
      />
      <TypographyComponent
        text="¿Qué fue lo que menos le gustó a la comunidad?"
        variant="p"
        className="text-neutral-950 mt-4 mb-1 font-bold"
      />
      <TypographyComponent
        text="Cras bibendum nulla eu mollis tempus. Vestibulum in dolor et arcu malesuada maximus ac at justo. Ut dignissim maximus ornare. Integer tincidunt erat sed sapien dictum, a malesuada nunc lobortis. Vivamus volutpat, elit sed placerat bibendum, elit dui tempor odio, ac condimentum purus massa sit amet quam. Nam ut metus non libero rutrum efficitur eu nec tellus. Vestibulum viverra, dolor ac gravida scelerisque, velit dui tincidunt ex."
        variant="p"
        className="text-neutral-950 "
      />

      <TypographyWithTooltip
        text="Información complementaria"
        variant="h3"
        textTooltip="Información complementaria"
        className="text-primary mt-4"
        idTooltip="complementary_vision"
      />

      <StyledBoxImages2col1>
        <Image
          srcImage={imageExample}
          altImage="imageport"
          titleImage="imageport"
        />
        <Image
          srcImage={imageExample}
          altImage="imageport"
          titleImage="imageport"
        />
        <Image
          srcImage={imageExample}
          altImage="imageport"
          titleImage="imageport"
        />
      </StyledBoxImages2col1>

      <div className="flex items-center mt-12 justify-center">
        <ButtonComponent
          text="Finalizar y avanzar a la siguiente etapa"
          onPress={() => {}}
        />
      </div>
    </>
  );
};

export default Evaluate;
