import useHelpers from '../../../../helpers';
import useStrings from '../../../../strings';
import useInitialStates from '../../../initialStates';

const useCountriesReducers = () => {
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  //Initial States
  const { useGeneralInitialStates } = useInitialStates();
  const { useCountriesInitialStates } = useGeneralInitialStates();
  const { initialStateCountries } = useCountriesInitialStates();

  //Strings
  const { useGeneralTypes } = useStrings();
  const { useCountriesTypes } = useGeneralTypes();
  const { GET_COUNTRIES } = useCountriesTypes();

  const countries = createReducer(initialStateCountries, {
    [GET_COUNTRIES](state, action) {
      const { result, entities } = action.payload;
      return { ...state, result, entities };
    },
  });

  return { countries };
};

export default useCountriesReducers;
