//Packages
import { useEffect, useRef } from 'react';
import axios from 'axios';
import _ from 'lodash';

//Hooks
import useControllers from 'controllers';
import useStrings from 'strings';
import useModels from 'models';

const useInterceptor = (store) => {
  const { useGeneralHooks } = useControllers();
  const { useConsole, useToggleModal, useIsFilterEmpty } = useGeneralHooks();
  const { viewConsole } = useConsole();
  const { handleSetFilterEmpty, handleResetFilterEmpty } = useIsFilterEmpty();
  const [showGeneralModal, setShowGeneralModal] = useToggleModal();
  const messageRef = useRef([]);

  const { useMessagesTypes, useAuthTypes } = useStrings();
  const { LOGOUT } = useAuthTypes();
  const { useHttpTypes } = useMessagesTypes();
  const { MESSAGE_HTTP_500, MESSAGE_HTTP_401 } = useHttpTypes();

  const { useSelectors } = useModels();
  const { useSelector, useUserSelectors } = useSelectors();
  const { userSelector } = useUserSelectors();
  const userData = useSelector(userSelector);

  const handleRequestSuccess = (request) => {
    const state = store.getState();
    const { auth_token } = state.auth;

    if (auth_token) {
      request.headers.authorization = `Bearer ${auth_token}`;
    }
    request.headers['Content-Type'] = 'application/json';
    request.headers['accept'] = 'application/json';
    return request;
  };

  const handleResponseSuccess = (response) => {
    const valuesParams = Object.values(response.config?.params);
    const valuesParamsAllValuesExist = valuesParams.filter((paramValue) =>
      !Array.isArray(paramValue)
        ? paramValue
        : paramValue.length > 0 && !Array.isArray(paramValue[0])
    );
    const valuesKeys = Object.keys(response.config?.params);
    const valuesKeysAllValuesExist = valuesKeys.filter((paramKey) => {
      const value = response.config?.params[paramKey];
      return !Array.isArray(value)
        ? value
        : value.length > 0 && !Array.isArray(value[0]);
    });

    if (
      response.data.message &&
      (response.status === 201 ||
        response.status === 206 ||
        response.status === 203)
    ) {
      messageRef.current = [];
      messageRef.current.push(response.data.message);
      setShowGeneralModal();
    }

    //LOGIC FOR SHOW MODAL WHEN FILTER IS EMPTY
    if (
      Array.isArray(response.data?.data) &&
      response.data?.data.length === 0 &&
      valuesParamsAllValuesExist.length > 0 &&
      !_.includes(['/notifications', '/countries'], response.config.url)
    ) {
      if (
        !(
          valuesParamsAllValuesExist.length === 2 &&
          _.includes(valuesParamsAllValuesExist, 'page') &&
          _.includes(valuesParamsAllValuesExist, 'paginate')
        )
      ) {
        if (
          (valuesParamsAllValuesExist.length === 1 &&
            !_.includes(
              userData.role.id === 'ar' ? ['page'] : ['page', 'type'],
              valuesKeysAllValuesExist[0]
            )) ||
          valuesParamsAllValuesExist.length > 1
        ) {
          handleSetFilterEmpty();
        }
      }
    } else {
      handleResetFilterEmpty();
    }
    return response;
  };

  const handleResponseError = (error) => {
    messageRef.current = [];
    const { data, status } = error.response;
    const requestHeaders = error.response.config.headers;
    if (data.message && status !== 401) {
      switch (status) {
        case 404:
          window.location.replace('/');
          break;
        case 422: {
          const { errors } = data;
          if (errors) {
            const errorsValues = Object.values(errors);
            // I loop through the "error" properties
            _.forEach(errorsValues, (values) => {
              let message = '';
              // If the property has multiple messages, I add them to the "message" variable
              if (Array.isArray(values)) {
                _.forEach(values, (value, iteratorValues) => {
                  message +=
                    iteratorValues === 0 ? `• ${value}` : `\n• ${value}`;
                });
              } else {
                message = values;
              }
              messageRef.current.push(message);
            });
          } else {
            messageRef.current.push(data.message);
          }
          break;
        }
        case 500:
          messageRef.current.push(MESSAGE_HTTP_500);
          break;
        default:
          messageRef.current.push(data.message);
          break;
      }
      if (requestHeaders['alert-dialog'] !== 'hidden') setShowGeneralModal();
    } else if (status === 401) {
      store.dispatch({ type: LOGOUT });
      localStorage.clear();
      setTimeout(() => {
        messageRef.current.push(MESSAGE_HTTP_401);
        if (requestHeaders['alert-dialog'] !== 'hidden') setShowGeneralModal();
      }, 1000);
    }
    viewConsole([`RESPONSE ERROR! => ${error}`], 'error');
    return Promise.reject(error);
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.params = {};
    axios.interceptors.request.use(handleRequestSuccess);
    axios.interceptors.response.use(handleResponseSuccess, handleResponseError);
  }, []);

  return {
    dataGeneralModal: {
      show: showGeneralModal,
      message: messageRef.current,
      onHideModal: setShowGeneralModal,
    },
  };
};

export default useInterceptor;
