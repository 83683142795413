import useProviders from '../../../providers';
import useStrings from '../../../../strings';
const useRecoverPasswordActions = () => {
  //providers
  const { useAuthProviders } = useProviders();
  const { useRecoverPasswordProvider } = useAuthProviders();
  const {
    resetPasswordProvider,
    forgotPasswordProvider,
    validateTokenProvider,
  } = useRecoverPasswordProvider();
  //strings
  const { useAuthTypes } = useStrings();
  //types
  const { FORGOT_PASSWORD, RESET_PASSWORD, VALIDATE_TOKEN } = useAuthTypes();
  const actResetPassword =
    ({ password, password_confirmation, token }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const {
          data: { message },
        } = await resetPasswordProvider({
          password,
          password_confirmation,
          token,
        });
        dispatch({
          type: RESET_PASSWORD,
        });
        onSuccess && onSuccess(message);
      } catch (e) {
        onError && onError(e);
      }
    };
  const actValidateToken =
    ({ token }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await validateTokenProvider({ token });
        onSuccess && onSuccess({ permission: response.status === 200, token });
        dispatch({
          type: VALIDATE_TOKEN,
        });
      } catch (e) {
        onError && onError(e.data?.message);
      }
    };
  const actForgotPassword =
    ({ email }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const {
          data: { message },
        } = await forgotPasswordProvider({ email });
        onSuccess && onSuccess(message);
        dispatch({
          type: FORGOT_PASSWORD,
        });
      } catch (e) {
        onError && onError(e);
      }
    };
  return {
    actForgotPassword,
    actResetPassword,
    actValidateToken,
  };
};
export default useRecoverPasswordActions;
