//Packages
import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

//Hooks
import useViews from 'views';
import useStrings from 'strings';

//Styles
const StyledGroup = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const FormMessage = (props) => {
  const {
    control,
    errors,
    setError,
    register,
    optionsCommunities,
    optionsContactLists,
    setActiveItem,
    watch,
    handleAcceptMessageAdmin,
    handleSubmit,
    getValues,
    formAcceptMessage,
  } = props;
  const { useComponents } = useViews();

  const { useTypographies, useInputs, useButtons } = useComponents();

  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { InputForm, Select, CheckBox, TextArea } = useInputs();
  const { ButtonComponent } = useButtons();

  const { useConstants } = useStrings();
  const {
    LIST_OF_CHANNELS_FOR_SEND_MESSAGES,
    LIST_OF_OPTIONS_TO_DESTINATIONS,
  } = useConstants();

  return (
    <>
      <TypographyComponent
        text="Crear nuevo mensaje"
        variant="h2"
        className="font-bold text-primary mt-4"
      />

      <TypographyComponent
        text="Para comunicarte con la comunidad asegurate de llenar la siguiente información. Recuerda que cada caracter cuenta, se lo mas conciso posible!"
        variant="p"
        className="font-book text-neutral-950 mb-10"
      />

      <StyledGroup noSpace>
        <div>
          <div className="flex items-center mb-2 mt-4">
            <TypographyWithTooltip
              text="Canal de comunicación*"
              variant="p"
              textTooltip="Canal de comunicación*"
              idTooltip="prot"
              className="text-neutral-950"
            />
          </div>

          <Select
            name="channel"
            id="channel"
            placeholder="Selecciona el canal de comunicación"
            control={control}
            options={LIST_OF_CHANNELS_FOR_SEND_MESSAGES}
            label=""
            errors={errors}
          />
        </div>
        <div>
          <TypographyWithTooltip
            text="Tipo de destino*"
            variant="p"
            textTooltip="Tipo de destino"
            idTooltip="dest"
            className="text-neutral-950"
          />

          <Select
            name="typeDest"
            id="typeDest"
            placeholder="Selecciona el tipo de Destino"
            className="mt-2"
            options={LIST_OF_OPTIONS_TO_DESTINATIONS}
            label=""
            control={control}
            errors={errors}
          />
        </div>
      </StyledGroup>

      <Select
        name="communities"
        id="commun"
        placeholder="Selecciona el destino del mensaje"
        className="mt-2"
        options={
          watch('typeDest')?.value === 'comunidad'
            ? optionsCommunities
            : optionsContactLists
        }
        label="Destino"
        control={control}
        errors={errors}
        multiple
        disabled={!watch('typeDest')?.value}
      />

      <StyledGroup>
        <Controller
          control={control}
          name="date_checkbox"
          render={({ field: { name, onChange } }) => {
            return (
              <CheckBox
                name={name}
                label="Establecer una fecha para envío de mensaje"
                className="mx-auto mt-6"
                onChange={onChange}
                errors={errors}
                checked={watch('date_checkbox')}
              />
            );
          }}
        />
      </StyledGroup>

      {watch('date_checkbox') && (
        <StyledGroup flex className="flex-col pb-4 ">
          <div className="flex items-center mb-2 mt-4">
            <TypographyWithTooltip
              text="Fecha de envío"
              variant="p"
              textTooltip="Fecha de envío"
              idTooltip="date"
              className="text-neutral-950"
              applyFontBold
            />
          </div>
          <InputForm
            name="send_date"
            label=""
            type="datetime-local"
            className="w-4/12"
            placeholder="Selecciona la fecha de inicio"
            {...register('send_date')}
            errors={errors}
          />
        </StyledGroup>
      )}

      <StyledGroup>
        <TypographyComponent
          text="Redactar mensaje"
          variant="h3"
          className="font-bold text-primary mt-6"
        />

        <TextArea
          control={control}
          name="message"
          placeholder="Mensaje"
          className="w-full col-span-2"
          label=""
          errors={errors}
        />
      </StyledGroup>
      <div className="flex justify-between pt-12">
        <ButtonComponent
          text="Cancelar"
          className="border-none shadow-none"
          onPress={(e) => {
            e.preventDefault();
            setActiveItem((prevValue) => ({ ...prevValue, active: 0 }));
          }}
          mode="outline"
          variant="primary"
        />
        <div className="flex items-center space-x-2">
          <ButtonComponent
            text="Guardar cambios"
            mode="outline"
            onPress={(e) => {
              e.preventDefault();
              if (watch('typeDest')?.value && !watch('communities')?.length) {
                setError('communities', {
                  type: 'manual',
                  message: 'Campo requerido',
                });
              } else {
                handleAcceptMessageAdmin({
                  data: getValues(),
                  state: 'd',
                  isSaveDraftMessage: true,
                  formToUse: formAcceptMessage,
                });
              }
            }}
          />
          <ButtonComponent
            text="Enviar mensaje"
            icon={<div className="icon-send text-lg  h-6 flex" />}
            onPress={handleSubmit((data) =>
              handleAcceptMessageAdmin({
                data,
                state: 'ip',
                formToUse: formAcceptMessage,
              })
            )}
          />
        </div>
      </div>
    </>
  );
};

FormMessage.propTypes = {
  control: {},
  errors: PropTypes.object,
  register: PropTypes.func,
  setActiveItem: PropTypes.func,
  optionsCommunities: PropTypes.array,
  optionsContactLists: PropTypes.array,
  watch: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleAcceptMessageAdmin: PropTypes.func,
  getValues: PropTypes.func,
  setError: PropTypes.func,
  formAcceptMessage: PropTypes.object,
};

FormMessage.defaultProps = {
  control: {},
  errors: {},
  register: () => {},
  setActiveItem: () => {},
  optionsCommunities: [],
  optionsContactLists: [],
  watch: () => {},
  handleSubmit: () => {},
  handleAcceptMessageAdmin: () => {},
  getValues: () => {},
  setError: () => {},
  formAcceptMessage: {},
};

export default FormMessage;
