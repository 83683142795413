import React from 'react';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';
import useHelpers from 'helpers';

// Components
import FormPilot from './components/FormPilot';
import Activities from './components/Activities';
import CriteriaEvaluation from './components/CriteriaEvaluation';

//Styles
import { StyledContentButtonsModal } from 'styles/tableViews/tableViews.styles';
import { StyledDivider } from 'styles/formViews/formViews.styles';

const PilotInterventionArtist = () => {
  const { useComponents } = useViews();
  const { useTypographies, useButtons, useModals, useCollapses, useCards } =
    useComponents();

  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { ButtonComponent } = useButtons();
  const { DefaultModal } = useModals();
  const { DefaultCollapse } = useCollapses();
  const { CardNotification } = useCards();

  const { useQuickFunctions } = useHelpers();
  const { getYearMonthDayFromDate } = useQuickFunctions();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsDevelopmentIntervention } =
    useAdminViewInterventionsControllers();
  const { useViewDevelopmentInterventionCreateSketch } =
    useAdminViewInterventionsDevelopmentIntervention();
  const { useViewDevelopmentInterventionCreateSketchArtist } =
    useViewDevelopmentInterventionCreateSketch();
  const { useArtistPlanPilotIntervention } =
    useViewDevelopmentInterventionCreateSketchArtist();
  const {
    OPTIONS_MODALITY,
    pilotPlan,
    activeStep,
    setActiveStep,
    planPilotForm,
    optionsCommunities,
    showModalApprove,
    setShowModalApprove,
    planPilotActivities,
    canEditForm,
    openFile,
    showModalNoActivities,
    setShowModalNoActivities,
    downloadFile,
    deleteFile,
    savePlanPilotActivity,
    deletePlanPilotActivity,
    updatePilotPlanIntervention,
  } = useArtistPlanPilotIntervention();
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = planPilotForm;

  return (
    <>
      {_.includes(['iv', 'c', 'ip'], pilotPlan.state?.id) && (
        <CardNotification
          className="w-5/12 mx-auto py-9 px-4 mt-16"
          title={
            <TypographyComponent
              text={pilotPlan.state.name}
              variant="h2"
              className="font-bold text-primary icon-watch text-center"
            />
          }
        />
      )}
      {pilotPlan.rejected_reasons?.length > 0 && (
        <>
          <br />
          <DefaultCollapse
            title={
              <TypographyWithTooltip
                text="Recomendaciones del Socio Ejecutor"
                variant="h3"
                textTooltip="Recomendaciones del Socio Ejecutor"
                className="text-primary"
                idTooltip="recomendations"
                applyFontBold
              />
            }
            titleTitleCollapsed=""
            elementCollapsed={
              <div className="icon-arrow-down text-xxs text-neutral-950 cursor-pointer" />
            }
            elementUncollapsed={
              <div className="icon-arrow-down text-xxs text-neutral-950 transform rotate-180 cursor-pointer" />
            }
            className="border border-error py-6 rounded pl-8 pr-10 mb-8 bg-neutral-300"
            classNameTitle="w-full flex justify-between"
            withUseState
          >
            {_.map(pilotPlan.rejected_reasons, (reason) => {
              const date = getYearMonthDayFromDate({
                dateInString: reason.created_at,
                format: 'dmyShort',
              });
              const time = getYearMonthDayFromDate({
                dateInString: reason.created_at,
                format: 'dmyTime',
              }).split('-')[1];
              return (
                <div
                  className="bg-white rounded-lg border p-6 mb-4"
                  key={`reason-${reason.id}`}
                >
                  <TypographyComponent
                    variant="p"
                    className="text-neutral-800 mb-4"
                    text={`${date} -${time}`}
                  />
                  <TypographyComponent
                    variant="p"
                    className="text-neutral-950"
                    text={reason.rejected_reason}
                  />
                </div>
              );
            })}
          </DefaultCollapse>
        </>
      )}

      <TypographyComponent
        text="Planear el piloto de la intervención"
        variant="h2"
        className="font-bold text-primary mt-6"
      />
      <TypographyComponent
        text="Para un buen diseño final, es necesario pilotear una parte de la intervención propuesta con la comunidad."
        variant="p"
        className="text-neutral-950 mt-2"
      />

      {activeStep === 1 && (
        <>
          <CriteriaEvaluation
            control={control}
            errors={errors}
            deleteCriteriaFile={(e) => deleteFile({ e, file: 'file' })}
            canEditForm={canEditForm}
            watchForm={watch}
            currentFile={pilotPlan.criteria_file}
            openCriteriaFile={({ e, watchFile }) =>
              openFile({ e, file: pilotPlan.criteria_file, watchFile })
            }
            downloadCriteriaFile={(e) =>
              downloadFile({
                e,
                file: pilotPlan.criteria_file,
                nameFile: 'Criterios de evaluación del piloto',
              })
            }
          />
          <StyledDivider />
          <FormPilot
            handleNext={() => setActiveStep(2)}
            handleSubmit={handleSubmit}
            control={control}
            errors={errors}
            optionsCommunities={optionsCommunities}
            optionsModality={OPTIONS_MODALITY}
            canEditForm={canEditForm}
          />
        </>
      )}

      {activeStep === 2 && (
        <Activities
          setActiveStep={setActiveStep}
          activities={planPilotActivities}
          savePlanPilotActivity={savePlanPilotActivity}
          deletePlanPilotActivity={deletePlanPilotActivity}
          openConfirmationModal={handleSubmit(() => {
            planPilotActivities.length > 0
              ? setShowModalApprove(true)
              : setShowModalNoActivities(true);
          })}
          controlForm={control}
          errorsForm={errors}
          canEditForm={canEditForm}
          watchForm={watch}
          deleteToolsFile={(e) => deleteFile({ e, file: 'toolFile' })}
          openToolsFile={({ e, watchFile }) =>
            openFile({ e, file: pilotPlan.tool_file, watchFile })
          }
          downloadToolsFile={(e) =>
            downloadFile({
              e,
              file: pilotPlan.tool_file,
              nameFile: 'Herramientas',
            })
          }
          currentFile={pilotPlan.tool_file}
        />
      )}

      <DefaultModal
        isActive={showModalNoActivities}
        handleClose={() => setShowModalNoActivities(false)}
      >
        <TypographyComponent
          variant="h3"
          className="text-primary font-bold whitespace-pre-wrap"
          text={'Debe existir al menos una actividad'}
        />

        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Aceptar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={() => setShowModalNoActivities(false)}
          />
        </StyledContentButtonsModal>
      </DefaultModal>

      <DefaultModal
        isActive={showModalApprove}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={() => setShowModalApprove(false)}
      >
        <TypographyComponent
          text={'Validar piloto de la intervención'}
          variant="h3"
          className="font-bold text-primary mb-1"
        />

        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto lg:mx-2 "
            mode="outline"
            onPress={() => setShowModalApprove(false)}
          />
          <ButtonComponent
            variant="primary"
            text="Aceptar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={handleSubmit(updatePilotPlanIntervention)}
          />
        </StyledContentButtonsModal>
      </DefaultModal>
    </>
  );
};

export default PilotInterventionArtist;
