import React, { lazy } from 'react';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';
import useHelpers from 'helpers';

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

const StyledContentButtons = lazy(() =>
  import(
    '../../../../../../../../../../../../styles/tableViews/tableViews.styles'
  ).then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const StyledButtonsActions = lazy(() =>
  import(
    '../../../../../../../../../../../../styles/buttons/buttons.styles'
  ).then((mod) => ({
    default: mod.StyledButtonsActions,
  }))
);

const StyledDivider = lazy(() =>
  import(
    '../../../../../../../../../../../../styles/formViews/formViews.styles'
  ).then((mod) => ({
    default: mod.StyledDivider,
  }))
);

const StyledGroup = lazy(() =>
  import(
    '../../../../../../../../../../../../styles/formViews/formViews.styles'
  ).then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledContentButtonsModal = lazy(() =>
  import(
    '../../../../../../../../../../../../styles/tableViews/tableViews.styles'
  ).then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const MaterializeConvocatory = () => {
  const { useComponents } = useViews();

  const { useTypographies, useButtons, useInputs, useModals, useCards } =
    useComponents();
  const { TypographyComponent } = useTypographies();
  const { ButtonComponent } = useButtons();
  const { CheckBox, InputForm, TextArea } = useInputs();
  const { DefaultModal } = useModals();
  const { CardNotification } = useCards();

  const { useQuickFunctions } = useHelpers();
  const { getDocumentName } = useQuickFunctions();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsDevelopmentIntervention } =
    useAdminViewInterventionsControllers();
  const { useViewDevelopmentInterventionExecuteIntervention } =
    useAdminViewInterventionsDevelopmentIntervention();
  const {
    useViewDevelopmentExecuteInterventionAdmin,
    useViewDevelopmentExecuteInterventionArtist,
  } = useViewDevelopmentInterventionExecuteIntervention();
  const { useAdminExecuteMaterializeIntervention } =
    useViewDevelopmentExecuteInterventionAdmin();
  const { useArtistExecuteMaterializeIntervention } =
    useViewDevelopmentExecuteInterventionArtist();
  const { CONVOCATION_METHODS, executeMaterialize, openFile, downloadFile } =
    useArtistExecuteMaterializeIntervention();
  const {
    formExecuteMaterialize,
    openModalDeny,
    openModalApprove,
    setOpenModalDeny,
    setOpenModalApprove,
    canEditForm,
    updateEvaluatePilotIntervention,
  } = useAdminExecuteMaterializeIntervention();

  const {
    handleSubmit,
    control,
    register,
    reset,
    watch,
    formState: { errors },
  } = formExecuteMaterialize;

  return (
    <>
      {_.includes(['ip', 'r', 'c', 'iv'], executeMaterialize.state.id) && (
        <CardNotification
          className="w-5/12 mx-auto py-9 px-4 mt-16"
          title={
            <TypographyComponent
              text={executeMaterialize?.state?.name}
              variant="h2"
              className="font-bold text-primary icon-watch text-center"
            />
          }
        />
      )}
      <TypographyComponent
        text="Materializar convocatoria"
        variant="h2"
        className="text-primary mt-2 font-bold"
      />

      {_.includes(['ip', 'r', 'c'], executeMaterialize.state.id) && (
        <TypographyComponent
          text="En este momento todo esta en manos del artista, quién debe enviarte pronto la información necesaria para la convocatoria."
          variant="p"
          className="text-neutral-950 mt-4 mb-4"
        />
      )}

      {_.includes(['iv'], executeMaterialize.state.id) && (
        <TypographyComponent
          text=" Aquí tienes la información detallada de la actividad de convocatoria diseñada por el artista. Esta información ya fue validada por ti durante la etapa del boceto"
          variant="p"
          className="text-neutral-950 mt-4 mb-4"
        />
      )}

      {executeMaterialize.state.id === 'p' ? (
        <>
          <TypographyComponent
            text="Depués de revisar lo que el artista compartió para la convocatoria, has clikc en iniciar etapa, para entrar en acción."
            variant="p"
            className="text-neutral-950 mt-4 mb-4"
          />
          <TypographyComponent
            text="Recuerda que puedes dar una fecha límite para este paso haciendo click en 'Asignar fecha límite para recibir material de la convocatoria por parte del artista'"
            variant="p"
            className="text-neutral-950 mt-4 mb-4"
          />

          <TypographyComponent
            text="En este momento todo esta en manos del artista, quién debe enviarte pronto la información necesaria para la convocatoria."
            variant="p"
            className="text-neutral-950 mt-4 mb-4"
          />

          <Controller
            control={control}
            name="dateLimitCheckbox"
            render={({ field: { name, onChange } }) => {
              return (
                <CheckBox
                  name={name}
                  label="Asignar fecha limite para recibir material de la convocatoria por parte del artista"
                  className="mx-auto mt-6"
                  onChange={onChange}
                />
              );
            }}
          />
          {watch('dateLimitCheckbox') && (
            <InputForm
              name="dateLimit"
              label=""
              type="date"
              placeholder="Selecciona la fecha limite"
              className="pt-2 w-2/12 mt-8"
              errors={errors}
              {...register('dateLimit')}
            />
          )}
          <ButtonComponent
            text="Iniciar etapa"
            className="mx-auto mt-12"
            onPress={handleSubmit(updateEvaluatePilotIntervention)}
          />
        </>
      ) : (
        <>
          {_.includes(['iv', 'c'], executeMaterialize.state.id) && (
            <>
              <Accordion allowZeroExpanded preExpanded={['define']}>
                <div className="rounded mt-2 bg-neutral-400 border-2">
                  <AccordionItem uuid="define">
                    <div className="flex items-center justify-between  py-2 px-6">
                      <TypographyComponent
                        variant="h3"
                        text="Definición de la convocatoria"
                        className="flex-grow text-primary font-bold mt-4"
                      />
                      <StyledContentButtons>
                        <AccordionItemState>
                          {({ expanded }) => (
                            <AccordionItemButton>
                              <div
                                className={`icon-arrow-down text-xxs text-neutral-900 ${
                                  expanded && 'transform rotate-180'
                                }`}
                              />
                            </AccordionItemButton>
                          )}
                        </AccordionItemState>
                      </StyledContentButtons>
                    </div>
                    <AccordionItemPanel className="py-4  px-6">
                      <TypographyComponent
                        text={
                          <label>
                            <span className="text-primary font-bold">
                              Métodos definidos:{' '}
                            </span>
                            {_.map(
                              executeMaterialize.definition.convocation_method,
                              (m) => CONVOCATION_METHODS[m]
                            ).join(', ')}
                          </label>
                        }
                        variant="p"
                        className="text-neutral-950 mt-2"
                      />
                      <TypographyComponent
                        text={
                          <label>
                            <span className="text-primary font-bold">
                              Descripción de la convocatoria:{' '}
                            </span>
                            {
                              executeMaterialize.definition
                                .convocation_description
                            }
                          </label>
                        }
                        variant="p"
                        className="text-neutral-950 mt-2 rounded "
                      />
                      <div className="border px-4 py-4 mt-4">
                        <div className="bg-white py-2 px-4 flex justify-between rounded-sm items-center border">
                          <TypographyComponent
                            variant="p"
                            text={getDocumentName({
                              file: executeMaterialize.definition
                                .convocation_file,
                              withExtension: true,
                            })}
                            className="flex-grow text-primary font-bold"
                          />
                          <StyledButtonsActions className="flex items-center">
                            <ButtonComponent
                              text=""
                              className="icon-view"
                              onPress={(e) =>
                                openFile({
                                  e,
                                  file: executeMaterialize.definition
                                    .convocation_file,
                                })
                              }
                            />
                            <ButtonComponent
                              text=""
                              icon={
                                <div className="icon-download text-primary text-lg" />
                              }
                              onPress={(e) =>
                                downloadFile({
                                  e,
                                  file: executeMaterialize.definition
                                    .convocation_file,
                                })
                              }
                            />
                          </StyledButtonsActions>
                        </div>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </Accordion>

              <StyledDivider />

              <TypographyComponent
                text="Material de apoyo"
                variant="h3"
                className="text-primary mt-2 font-bold"
              />
              <TypographyComponent
                text="Aquí tienes toda la información compartida por el artista para producir el material necesario para realizar la convocatoria."
                variant="p"
                className="text-neutral-950 mt-2 mb-4"
              />
              <div>
                {executeMaterialize.files?.length > 0 &&
                  _.map(executeMaterialize.files, (file) => (
                    <div
                      className="bg-white py-2 px-4 flex justify-between rounded-sm items-center"
                      key={`file-${file.id}`}
                    >
                      <TypographyComponent
                        variant="p"
                        text={getDocumentName({ file, withExtension: true })}
                        className="flex-grow text-primary font-bold"
                      />
                      <StyledButtonsActions className="flex items-center">
                        <ButtonComponent
                          text=""
                          className="icon-view"
                          onPress={(e) => openFile({ e, file })}
                        />
                        <ButtonComponent
                          text=""
                          icon={
                            <div className="icon-download text-primary text-lg" />
                          }
                          onPress={(e) => downloadFile({ e, file })}
                        />
                      </StyledButtonsActions>
                    </div>
                  ))}
              </div>
              {canEditForm && (
                <>
                  <TypographyComponent
                    variant="p"
                    text={'Tienes dos opciones para continuar con el proceso.'}
                    className="flex-grow text-neutral-950 mt-6"
                  />

                  <TypographyComponent
                    variant="p"
                    text={'1. Aprobar este paso haciendo click en validar'}
                    className="flex-grow text-neutral-950 "
                  />
                  <TypographyComponent
                    variant="p"
                    text={'2. Regresar al artista con algunos ajustes'}
                    className="flex-grow text-neutral-950 "
                  />

                  <div className="flex items-center space-x-2 mt-10 justify-center">
                    <ButtonComponent
                      text="Rechazar"
                      mode="outline"
                      onPress={() => {
                        setOpenModalDeny(true);
                      }}
                    />
                    <ButtonComponent
                      text="Aprobar y avanzar al siguiente paso"
                      onPress={() => setOpenModalApprove(true)}
                    />
                  </div>
                  <DefaultModal
                    isActive={openModalDeny}
                    classNameContent="max-w-screen-md w-11/12"
                    handleClose={() => {
                      setOpenModalDeny(false);
                    }}
                  >
                    <TypographyComponent
                      text="Rechazar material de la convocatoria"
                      variant="h3"
                      className="font-bold text-primary mb-1"
                    />

                    <StyledGroup col2 className="mb-4">
                      <TextArea
                        name="rejectedReason"
                        placeholder="Ajustes a realizar"
                        className="w-full col-span-2"
                        label="Escribe los ajustes que debe realizar el artista al material de la convocatoria"
                        errors={errors}
                        {...register('rejectedReason')}
                      />
                    </StyledGroup>

                    <StyledGroup flex noSpace className="mb-4 flex-col">
                      <Controller
                        control={control}
                        name="dateLimitRejectCheckbox"
                        render={({ field: { name, onChange } }) => {
                          return (
                            <CheckBox
                              name={name}
                              className="pb-4"
                              label="Asignar fecha limite para recibir los ajustes en el material de la convocatoria"
                              onChange={onChange}
                              errors={errors}
                            />
                          );
                        }}
                      />
                      {watch('dateLimitRejectCheckbox') && (
                        <InputForm
                          name="dateLimitReject"
                          label=""
                          type="date"
                          placeholder="Selecciona la fecha limite"
                          className="pt-2 "
                          errors={errors}
                          {...register('dateLimitReject')}
                        />
                      )}
                    </StyledGroup>

                    <StyledContentButtonsModal>
                      <ButtonComponent
                        variant="primary"
                        text="Cancelar"
                        className="mx-auto w-full lg:w-auto lg:mx-2 "
                        mode="outline"
                        onPress={() => {
                          reset();
                          setOpenModalDeny(false);
                        }}
                      />
                      <ButtonComponent
                        variant="primary"
                        text="Enviar respuesta al artista"
                        className="mx-auto w-full lg:w-auto  lg:mx-2"
                        onPress={handleSubmit(updateEvaluatePilotIntervention)}
                      />
                    </StyledContentButtonsModal>
                  </DefaultModal>

                  <DefaultModal
                    isActive={openModalApprove}
                    classNameContent="max-w-screen-md w-11/12"
                    handleClose={() => {
                      setOpenModalApprove(false);
                    }}
                  >
                    <TypographyComponent
                      text="Aprobar material de la convocatoria"
                      variant="h3"
                      className="font-bold text-primary mb-1"
                    />

                    <StyledContentButtonsModal>
                      <ButtonComponent
                        variant="primary"
                        text="Cancelar"
                        className="mx-auto w-full lg:w-auto lg:mx-2 "
                        mode="outline"
                        onPress={() => setOpenModalApprove(false)}
                      />
                      <ButtonComponent
                        variant="primary"
                        text="Aceptar"
                        className="mx-auto w-full lg:w-auto  lg:mx-2"
                        onPress={handleSubmit(updateEvaluatePilotIntervention)}
                      />
                    </StyledContentButtonsModal>
                  </DefaultModal>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default MaterializeConvocatory;
