import React from 'react';

const TabLink = React.lazy(() => import('./TabLink'));
const Tabs = React.lazy(() => import('./Tabs'));

const useTabs = () => {
  return {
    TabLink,
    Tabs,
  };
};
export default useTabs;
