import React from 'react';

const Step = React.lazy(() => import('./Step'));
const Stepper = React.lazy(() => import('./Stepper'));

const useStepper = () => {
  return {
    Step,
    Stepper,
  };
};
export default useStepper;
