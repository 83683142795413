import React from 'react';
import PropTypes from 'prop-types';

// Styles
import { StyledHelperLabel } from './HelperLabel.styles';

const HelperLabel = (props) => {
  const { children, className, name } = props;
  return (
    <StyledHelperLabel name={name} className={className}>
      {children}
    </StyledHelperLabel>
  );
};
HelperLabel.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
};
HelperLabel.defaultProps = {
  children: '',
  className: '',
  name: '',
};
export default React.memo(HelperLabel);
