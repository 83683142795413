import useHelpers from '../../../../helpers';
import useStrings from '../../../../strings';
import useInitialStates from '../../../initialStates';

const useColorsReducers = () => {
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  //Initial States
  const { useGeneralInitialStates } = useInitialStates();
  const { useColorsInitialStates } = useGeneralInitialStates();
  const { initialStateColors } = useColorsInitialStates();
  //Strings
  const { useGeneralTypes } = useStrings();
  const { useColorsTypes } = useGeneralTypes();
  const { GET_COLORS } = useColorsTypes();

  const colors = createReducer(initialStateColors, {
    [GET_COLORS](state, action) {
      return [...action.payload];
    },
  });

  return { colors };
};

export default useColorsReducers;
