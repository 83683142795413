import useHelpers from '../../../../helpers';

const useAdminContactListSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();
  const contactListSelector = createSelector(
    (state) => state.contactList,
    (labels) => labels
  );
  return { contactListSelector };
};
export default useAdminContactListSelectors;
