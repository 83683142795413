import React, { lazy } from 'react';

//Hooks
import useViews from '../../../../../../../../../../..';

import { useForm, Controller } from 'react-hook-form';

const StyledGroup = lazy(() =>
  import(
    '../../../../../../../../../../../../styles/formViews/formViews.styles'
  ).then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledButtonsActions = lazy(() =>
  import(
    '../../../../../../../../../../../../styles/buttons/buttons.styles'
  ).then((mod) => ({
    default: mod.StyledButtonsActions,
  }))
);

const EvaluateArtist = () => {
  const { useComponents } = useViews();

  const { useTypographies, useButtons, useInputs, useImages } = useComponents();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { UploadImagePreview } = useImages();

  const { ButtonComponent } = useButtons();
  const { InputForm, TextArea } = useInputs();

  const {
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  return (
    <>
      <TypographyComponent
        text="Evaluar intervención"
        variant="h2"
        className="text-primary mt-2 font-bold"
      />
      <TypographyComponent
        text="Cras bibendum nulla eu mollis tempus. Vestibulum in dolor et arcu malesuada maximus ac at justo. Ut dignissim maximus ornare. Integer tincidunt erat sed sapien dictum, a malesuada nunc lobortis. Vivamus volutpat, elit sed placerat bibendum, elit dui tempor odio, ac condimentum purus massa sit amet quam. Nam ut metus non libero rutrum efficitur eu nec tellus. Vestibulum viverra, dolor ac gravida scelerisque, velit dui tincidunt ex, non tincidunt velit libero sit amet orci."
        variant="p"
        className="text-neutral-950 mt-4 mb-4"
      />

      <TypographyWithTooltip
        text="Visión general"
        variant="h3"
        textTooltip="Modalidad de profundización"
        className="text-primary mt-4"
        idTooltip="general_vision"
      />

      <StyledGroup col-2 className="mb-4 mt-4" noSpace>
        <div className="flex items-end w-full col-span-2">
          <InputForm
            name="end-date"
            label="¿Cuáles fueron los logros de esta intervención?*"
            type="text"
            placeholder="Ingresa el logro alcanzado"
            className="flex-1"
          />
          <ButtonComponent
            variant="primary"
            mode="outline"
            text="Agregar nuevo logro"
            className="mb-1 ml-8"
            icon={<div className="icon-close transform  rotate-45"></div>}
            onPress={() => {}}
          />
        </div>
      </StyledGroup>

      <StyledGroup col-2 className="mb-4 mt-4" noSpace>
        <TextArea
          name="description"
          placeholder="Textarea placeholder"
          className="w-full col-span-2"
          label="¿Qué recomendaciones le darías a otros artistas que vayan a realizar una intervención similar?*"
        />
      </StyledGroup>

      <TypographyWithTooltip
        text="Visión de la comunidad"
        variant="h3"
        textTooltip="Visión de la comunidad"
        className="text-primary mt-4"
        idTooltip="communitie_vision"
      />

      <StyledGroup col-2 className="mb-4 mt-4" noSpace>
        <TextArea
          name="description"
          placeholder="Textarea placeholder"
          className="w-full col-span-2"
          label="¿Qué fue lo que más le gustó a la comunidad?*"
        />
      </StyledGroup>

      <StyledGroup col-2 className="mb-4 mt-4" noSpace>
        <TextArea
          name="description"
          placeholder="Textarea placeholder"
          className="w-full col-span-2"
          label="Sugerencias de la comunidad "
        />
      </StyledGroup>

      <TypographyWithTooltip
        text="Información complementaria"
        variant="h3"
        textTooltip="Información complementaria"
        className="text-primary mt-4"
        idTooltip="complementary_vision"
      />

      <div className="bg-white py-2 px-4 flex justify-between rounded-sm items-center mt-4">
        <TypographyComponent
          variant="p"
          text="Metodología convocatoria.pdf"
          className="flex-grow text-primary font-bold"
        />
        <StyledButtonsActions className="flex items-center">
          <ButtonComponent text="" className="icon-edit" onPress={() => {}} />
          <ButtonComponent text="" className="icon-delete" onPress={() => {}} />
        </StyledButtonsActions>
      </div>

      <div className="border px-8 py-4 mt-4">
        <StyledGroup col-2 className="mb-4 mt-4" noSpace>
          <TextArea
            name="description"
            placeholder="Textarea placeholder"
            className="w-full col-span-2"
            label=""
          />
        </StyledGroup>

        <TypographyComponent
          text="Agregar imágenes o videos"
          variant="p"
          className="text-neutral-950 font-bold mt-4 mb-4"
        />
        <StyledGroup flex>
          <div className="flex items-center relative space-x-4 w-full">
            <Controller
              control={control}
              name="image"
              render={({ field: { onChange, name } }) => {
                return (
                  <UploadImagePreview
                    name={name}
                    onChange={onChange}
                    errors={errors}
                    resetInput={() => {}}
                    srcPlaceholderOnlyImage
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="image"
              render={({ field: { onChange, name } }) => {
                return (
                  <UploadImagePreview
                    name={name}
                    onChange={onChange}
                    errors={errors}
                    resetInput={() => {}}
                    srcPlaceholderOnlyImage
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="image"
              render={({ field: { onChange, name } }) => {
                return (
                  <UploadImagePreview
                    name={name}
                    onChange={onChange}
                    errors={errors}
                    resetInput={() => {}}
                    srcPlaceholderOnlyImage
                  />
                );
              }}
            />
          </div>
        </StyledGroup>
        <div className="flex items-center justify-end space-x-2">
          <ButtonComponent
            variant="primary"
            mode="outline"
            text="Cancelar"
            className="mt-4"
            onPress={() => {}}
          />
          <ButtonComponent
            variant="primary"
            text="Agregar contenido multimedia"
            className="mt-4 "
            onPress={() => {}}
          />
        </div>
      </div>

      <div className="flex items-center mt-12 justify-center">
        <ButtonComponent text="Enviar al Socio Ejecutor" onPress={() => {}} />
      </div>
    </>
  );
};

export default EvaluateArtist;
