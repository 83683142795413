//Packages
import React, { lazy } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';
import useStrings from 'strings';

//Styles
const StyledForm = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledForm,
  }))
);

const StyledGroup = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledContentButtonsModal = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const Create = (props) => {
  const {
    formAcceptMessage,
    fromInterventionMessage,
    optionsCommunities,
    optionsContactLists,
    minDateToSelect,
    activeConfirmModal,
    setActiveConfirmModal,
    sendMessage,
    activeAtLeastItemDraft,
    setActiveAtLeastItemDraft,
    sendMessageDraft,
    handleCancelSendMessage,
  } = props;

  const { useComponents } = useViews();

  const { useTypographies, useInputs, useButtons, useModals } = useComponents();

  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { Select, CheckBox, InputForm, TextArea } = useInputs();
  const { ButtonComponent } = useButtons();
  const { DefaultModal } = useModals();

  const { useConstants } = useStrings();
  const {
    LIST_OF_CHANNELS_FOR_SEND_MESSAGES,
    LIST_OF_OPTIONS_TO_DESTINATIONS,
  } = useConstants();

  const {
    control,
    formState: { errors },
    watch,
    register,
    handleSubmit,
    getValues,
  } = formAcceptMessage;

  return (
    <>
      <TypographyComponent
        text="Crear nuevo mensaje "
        variant="h2"
        className="font-bold text-primary mt-4"
      />

      <TypographyComponent
        text="Para comunicarte con la comunidad asegurate de llenar la siguiente información. Recuerda que cada caracter cuenta, se lo mas conciso posible!"
        variant="p"
        className="font-book text-neutral-950 mb-10"
      />

      <StyledForm className="pb-20">
        <StyledGroup noSpace>
          <div>
            <TypographyWithTooltip
              text="Canal de comunicación*"
              variant="p"
              textTooltip="Canal de comunicación"
              idTooltip="canal"
              className="text-neutral-950"
              applyFontBold
            />

            <Select
              name="channel"
              id="canal"
              placeholder="Selecciona el canal de comunicación"
              className="mt-2"
              options={LIST_OF_CHANNELS_FOR_SEND_MESSAGES}
              label=""
              control={control}
              errors={errors}
              disabled={fromInterventionMessage}
            />
          </div>
          <div>
            <TypographyWithTooltip
              text="Tipo de destino*"
              variant="p"
              textTooltip="Tipo de destino"
              idTooltip="dest"
              className="text-neutral-950"
              applyFontBold
            />

            <Select
              name="typeDest"
              id="typeDest"
              placeholder="Selecciona el tipo de Destino"
              className="mt-2"
              options={LIST_OF_OPTIONS_TO_DESTINATIONS}
              label=""
              control={control}
              errors={errors}
            />
          </div>
        </StyledGroup>

        <Select
          name="communities"
          id="commun"
          placeholder="Selecciona el destino del mensaje"
          className="mt-2"
          options={
            watch('typeDest')?.value === 'comunidad'
              ? optionsCommunities
              : optionsContactLists
          }
          label="Destino"
          control={control}
          errors={errors}
          multiple
          disabled={!watch('typeDest')?.value}
        />

        {!fromInterventionMessage && (
          <StyledGroup>
            <Controller
              control={control}
              name="date_checkbox"
              render={({ field: { name, onChange } }) => {
                return (
                  <CheckBox
                    name={name}
                    label="Establecer una fecha para envío de mensaje"
                    className="mx-auto mt-6"
                    onChange={onChange}
                    errors={errors}
                  />
                );
              }}
            />
          </StyledGroup>
        )}
        {watch('date_checkbox') && (
          <StyledGroup flex className="flex-col pt-4 ">
            <TypographyWithTooltip
              text="Fecha de envío"
              variant="p"
              textTooltip="Fecha de envío"
              idTooltip="date"
              className="text-neutral-950"
              applyFontBold
            />
            <InputForm
              name="send_date"
              label=""
              type="datetime-local"
              min={minDateToSelect}
              className="w-4/12 mt-2"
              placeholder="Selecciona la fecha de inicio"
              {...register('send_date')}
              errors={errors}
            />
          </StyledGroup>
        )}

        <StyledGroup>
          <TypographyComponent
            text="Redactar mensaje"
            variant="h3"
            className="font-bold text-primary mt-4"
          />

          <TextArea
            control={control}
            name="message"
            placeholder="Mensaje"
            className="w-full col-span-2"
            label=""
            errors={errors}
            disabled={fromInterventionMessage}
          />
        </StyledGroup>

        <div className="flex justify-between pt-12">
          <ButtonComponent
            text="Cancelar"
            className="border-none shadow-none"
            onPress={(event) => {
              handleCancelSendMessage({
                e: event,
                formToUse: formAcceptMessage,
              });
            }}
            mode="outline"
            variant="primary"
          />
          <div className="flex items-center space-x-2">
            {!fromInterventionMessage && (
              <ButtonComponent
                text="Guardar como borrador "
                mode="outline"
                onPress={(e) => {
                  e.preventDefault();
                  sendMessageDraft({
                    data: getValues(),
                  });
                }}
              />
            )}
            <ButtonComponent
              text="Enviar mensaje"
              icon={<div className="icon-send text-lg  h-6 flex" />}
              onPress={handleSubmit((data) =>
                sendMessage({
                  data,
                  formToUse: formAcceptMessage,
                })
              )}
            />
          </div>
        </div>
      </StyledForm>
      <DefaultModal
        isActive={activeConfirmModal}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={setActiveConfirmModal}
      >
        <TypographyComponent
          text="¿Enviar mensaje?"
          variant="h3"
          className="font-bold text-primary mb-1"
        />
        <TypographyComponent
          text="Haz clic en aceptar para enviarlo de inmediato!"
          variant="p"
          className="text-neutral-950  mb-1"
        />
        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto lg:mx-2 "
            mode="outline"
            onPress={setActiveConfirmModal}
          />
          <ButtonComponent
            variant="primary"
            text="Aceptar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={handleSubmit((data) =>
              sendMessage({
                data,
                formToUse: formAcceptMessage,
                fromModalConfirmation: true,
              })
            )}
          />
        </StyledContentButtonsModal>
      </DefaultModal>

      <DefaultModal
        isActive={activeAtLeastItemDraft}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={setActiveAtLeastItemDraft}
      >
        <TypographyComponent
          text="Debe existir al menos un campo diligenciado"
          variant="h3"
          className="font-bold text-primary mb-1 text-center"
        />
        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Aceptar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={setActiveAtLeastItemDraft}
          />
        </StyledContentButtonsModal>
      </DefaultModal>
    </>
  );
};

Create.propTypes = {
  formAcceptMessage: PropTypes.object,
  fromInterventionMessage: PropTypes.bool,
  optionsCommunities: PropTypes.array,
  optionsContactLists: PropTypes.array,
  minDateToSelect: PropTypes.string,
  activeConfirmModal: PropTypes.bool,
  setActiveConfirmModal: PropTypes.func,
  sendMessage: PropTypes.func,
  activeAtLeastItemDraft: PropTypes.bool,
  setActiveAtLeastItemDraft: PropTypes.func,
  sendMessageDraft: PropTypes.func,
  handleCancelSendMessage: PropTypes.func,
};

Create.defaultProps = {
  formAcceptMessage: {},
  fromInterventionMessage: false,
  optionsCommunities: [],
  optionsContactLists: [],
  minDateToSelect: '',
  activeConfirmModal: false,
  setActiveConfirmModal: () => {},
  sendMessage: () => {},
  activeAtLeastItemDraft: false,
  setActiveAtLeastItemDraft: () => {},
  sendMessageDraft: () => {},
  handleCancelSendMessage: () => {},
};

export default Create;
