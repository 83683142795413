import useAdminAbcTrajectorySelectors from './abcTrajectory';
import useAdminAdditionalInfoSelectors from './additionalInfo';
import useAdminCommunitiesSelectors from './communities';
import useAdminDocumentationSelectors from './documentation';
import useAdminFestivitiesAndTraditionsSelectors from './festivitiesAndTraditions';
import useAdminProjectsRootSelectors from './projectsRoot';
import useAdminRecommendationsSelectors from './recommendations';
import useAdminSocioCulturalInfoSelectors from './socioCulturalInfo';
import useAdminInterventionsSelectors from './interventions';

const useAdminProjectsSelectors = () => {
  return {
    useAdminProjectsRootSelectors,
    useAdminCommunitiesSelectors,
    useAdminSocioCulturalInfoSelectors,
    useAdminFestivitiesAndTraditionsSelectors,
    useAdminAbcTrajectorySelectors,
    useAdminRecommendationsSelectors,
    useAdminAdditionalInfoSelectors,
    useAdminDocumentationSelectors,
    useAdminInterventionsSelectors,
  };
};

export default useAdminProjectsSelectors;
