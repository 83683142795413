const useAdminViewInterventionsCommunicationInitialStates = () => {
  const initialStateCommunicationMessages = {
    listOfMessages: {
      data: [],
      meta: {
        current_page: '',
        last_page: '',
        total: '',
        per_page: '',
      },
      links: {
        next: '',
        prev: '',
      },
    },
    selectedMessage: {},
    filter: {
      state: '',
      orderByKey: '',
      orderByValue: '',
      created_at: '',
      communityReceivers: '',
      contactListReceivers: '',
      type: '',
      channel: '',
      request_sended_date: '',
      sended_date: '',
    },
  };

  return { initialStateCommunicationMessages };
};

export default useAdminViewInterventionsCommunicationInitialStates;
