import React, { lazy } from 'react';
import PropTypes from 'prop-types';

const StyledActionBadge = lazy(() =>
  import('./ActionBadge.styles').then((mod) => ({
    default: mod.StyledActionBadge,
  }))
);

const StyledDeleteBadge = lazy(() =>
  import('./ActionBadge.styles').then((mod) => ({
    default: mod.StyledDeleteBadge,
  }))
);

const ActionBadge = (props) => {
  const { onDelete, label, type, variant } = props;
  return (
    <StyledActionBadge
      label={
        <>
          {label}
          <StyledDeleteBadge onClick={onDelete} />
        </>
      }
      type={type}
      variant={variant}
    />
  );
};

ActionBadge.propTypes = {
  onDelete: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'error',
    'warnning',
    'success',
  ]),
  type: PropTypes.oneOf(['light', 'solid']),
};

ActionBadge.defaultProps = {
  variant: 'primary',
  type: 'light',
};

export default ActionBadge;
