//Packages
import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';
import useStrings from 'strings';

//Styles
import { StyledGroup } from 'styles/formViews/formViews.styles';
import { StyledButtonsActions } from 'styles/buttons/buttons.styles';

const Create = (props) => {
  const {
    form,
    handleSendForm,
    handleVisiblityIntervenForm,
    dataToEdit,
    handleDeleteInterventionFile,
    interventionIdToDeleteEdit,
    deleteInterventionFileActive,
    isInterventionEditButtonDisabled,
    handleLogicToOpenLastInterCreated,
  } = props;

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
  } = form;

  const { useComponents } = useViews();
  const { useTypographies, useInputs, useButtons } = useComponents();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { TextArea, Select, InputFile, InputForm, InputHoursMinutes } =
    useInputs();
  const { ButtonComponent } = useButtons();

  const { useConstants } = useStrings();
  const { OPTIONS_MODALITY } = useConstants();

  return (
    <form className="border rounded py-8 px-6 mt-4">
      <StyledGroup col2 flex className="mb-4 flex-col">
        <InputForm
          name="name"
          label="Nombre de la intervención*"
          type="text"
          className="full"
          placeholder="Ingresa el nombre de la intervención"
          {...register('name')}
          errors={errors}
        />
      </StyledGroup>
      <StyledGroup col2 flex className="mb-4 flex-col">
        <TextArea
          control={control}
          name="target"
          placeholder="Objetivo de la intervención"
          className="w-full col-span-2"
          label="Objetivo de la intervención*"
          errors={errors}
        />
      </StyledGroup>

      <TypographyWithTooltip
        text="Metodología de la intervención*"
        variant="h3"
        textTooltip="Metodología de la intervención*"
        className="text-primary"
        idTooltip="method_session"
        applyFontBold
      />

      <TypographyComponent
        text="Comparte la información necesaria para facilitar el desarrollo de tu intervención incluyendo: Título, objetivo, descripción, tiempo, materiales necesarios, etc."
        variant="p"
        className="text-neutral-950 mt-2 mb-0"
      />

      <TypographyComponent
        text="Recuerda que el peso máximo del archivo es 5MB"
        variant="p"
        className="text-neutral-950 mt-0 mb-6"
      />

      {(!interventionIdToDeleteEdit || deleteInterventionFileActive) && (
        <Controller
          control={control}
          name="methodology_file"
          render={({ field: { onChange, name } }) => {
            return (
              <InputFile
                name={name}
                accept={'.pdf'}
                title={
                  <div className="flex items-center">
                    <div
                      className={`icon-paper-clip text-primary text-xl font-bold h-10 ${
                        errors['methodology_file'] && 'text-error'
                      }`}
                    />
                    <TypographyComponent
                      text="Adjuntar archivo"
                      variant="p"
                      className={`text-primary font-bold ${
                        errors['methodology_file'] && 'text-error'
                      }`}
                    />
                  </div>
                }
                onChange={onChange}
                className={`w-full mt-2 bg-none mb-4 ${
                  errors['methodology_file'] && 'border-error'
                }`}
                errors={errors}
              />
            );
          }}
        />
      )}

      {interventionIdToDeleteEdit && !deleteInterventionFileActive && (
        <div className="bg-white py-2 px-4 flex justify-between rounded-sm items-center">
          <TypographyComponent
            variant="p"
            text={dataToEdit.methodology_file?.url}
            className="flex-grow text-primary font-bold"
          />
          <StyledButtonsActions className="flex items-center">
            <ButtonComponent
              text=""
              className="icon-view"
              onPress={(e) => {
                e.preventDefault();
                window.open(dataToEdit.methodology_file?.url);
              }}
            />
            <ButtonComponent
              text=""
              className="icon-delete"
              onPress={handleDeleteInterventionFile}
            />
          </StyledButtonsActions>
        </div>
      )}

      <StyledGroup noSpace className="pt-5">
        <Select
          name="modality"
          id="modality"
          placeholder="Selecciona la modalidad de la intervención"
          control={control}
          options={OPTIONS_MODALITY}
          label="Modalidad de la intervención*			"
          errors={errors}
        />
        <Controller
          name={'duration'}
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <InputHoursMinutes
              name={name}
              label="Duracion*"
              type="time"
              placeholder="hh:mm"
              className="w-full"
              errors={errors}
              onChange={onChange}
              defaultValue={value}
            />
          )}
        />
      </StyledGroup>

      <div className="flex items-center space-x-2 mt-10 justify-end">
        <ButtonComponent
          text="Cancelar"
          mode="outline"
          onPress={(e) => {
            e.preventDefault();
            handleLogicToOpenLastInterCreated();
            handleVisiblityIntervenForm();
          }}
        />
        <ButtonComponent
          text={`${dataToEdit.name ? 'Editar' : 'Crear'} sesión`}
          onPress={handleSubmit(handleSendForm)}
          disabled={dataToEdit.name ? isInterventionEditButtonDisabled : false}
        />
      </div>
    </form>
  );
};

Create.propTypes = {
  form: PropTypes.object.isRequired,
  handleSendForm: PropTypes.func.isRequired,
  handleVisiblityIntervenForm: PropTypes.func,
  dataToEdit: PropTypes.object,
  handleDeleteInterventionFile: PropTypes.func,
  handleLogicToOpenLastInterCreated: PropTypes.func,
  interventionIdToDeleteEdit: PropTypes.string,
  deleteInterventionFileActive: PropTypes.bool,
  isInterventionEditButtonDisabled: PropTypes.bool,
};

Create.defaultProps = {
  handleVisiblityIntervenForm: () => {},
  dataToEdit: {},
  handleDeleteInterventionFile: () => {},
  handleLogicToOpenLastInterCreated: () => {},
  interventionIdToDeleteEdit: '',
  deleteInterventionFileActive: false,
  isInterventionEditButtonDisabled: false,
};
export default Create;
