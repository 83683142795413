import useAdminViewInterventionsCommunicationTypes from './communication';
import useAdminViewInterventionsDevelopmentInterventionTypes from './developmentIntervention';

const useAdminViewInterventionsTypes = () => {
  return {
    useAdminViewInterventionsCommunicationTypes,
    useAdminViewInterventionsDevelopmentInterventionTypes,
  };
};

export default useAdminViewInterventionsTypes;
