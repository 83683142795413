//Packages
import React, { Suspense, lazy } from 'react';
import _ from 'lodash';

//Assets
import plusIcon from 'assets/icons/plus.svg';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

//Styles
const StyledBackButton = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);

const StyledGroup = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledForm = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledForm,
  }))
);

const StyledContentButtons = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const StyledContentButtonsModal = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const StyledCollapseDetailContent = lazy(() =>
  import('styles/collapse/collapse.styles').then((mod) => ({
    default: mod.StyledCollapseDetailContent,
  }))
);

const SttledContentInformation = lazy(() =>
  import('styles/collapse/collapse.styles').then((mod) => ({
    default: mod.SttledContentInformation,
  }))
);

const StyledContentCols = lazy(() =>
  import('styles/collapse/collapse.styles').then((mod) => ({
    default: mod.StyledContentCols,
  }))
);

const StyledWrapperCommunity = lazy(() =>
  import('../Dashboard.styles').then((mod) => ({
    default: mod.StyledWrapperCommunity,
  }))
);

const StyledCardCommunity = lazy(() =>
  import('../Dashboard.styles').then((mod) => ({
    default: mod.StyledCardCommunity,
  }))
);

const AdminCreateRecommendations = () => {
  const { useComponents } = useViews();
  const {
    useInputs,
    useButtons,
    useTypographies,
    useImages,
    useBoxes,
    useModals,
    useLoaders,
  } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { TextArea } = useInputs();
  const { ButtonComponent, ButtonAddActivity } = useButtons();
  const { TypographyComponent } = useTypographies();
  const { Image } = useImages();
  const { Box } = useBoxes();
  const { DefaultModal } = useModals();

  const { useScreenHooks, useGeneralHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminRecommendationsDashboard } = useAdminDashboardProjectsHooks();
  const {
    register,
    handleSubmit,
    errors,
    handleRegisterRecommendations,
    show,
    showDeleteRecommendationModal,
    handleDeleteRecommendationModal,
    handleAddRecommendation,
    listRecommendations,
    handleGoBack,
    handleCancelRecommendation,
    handleUpdateRecommendation,
    recommendationData,
    handleEditRecommendation,
    handleDeleteRecommendation,
    setRecommendationId,

    //showViewRecomendationModal,
    //handleViewRecomendationModal,
    //handleViewRecomendation,
    //recommendation,
  } = useAdminRecommendationsDashboard();
  const { useSelectLayoutByRole } = useGeneralHooks();
  const { LayoutSelected, props } = useSelectLayoutByRole();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <LayoutSelected {...props}>
        <StyledBackButton className="mt-3" onClick={handleGoBack}>
          Regresar
        </StyledBackButton>
        <Box className="bg-neutral-200 w-11/12 mx-auto max-w-screen-lg lg:px-16 lg:py-12 px-6 py-8 lg:my-8">
          <>
            <TypographyComponent
              text="Recomendaciones para colaboraciones exitosas"
              variant="h2"
              className="font-bold text-primary"
              applyFontBold
            />

            <TypographyComponent
              text="Algo más que se deba tener en cuenta sobre esta comunidad para una colaboración exitosa?              "
              variant="p"
              className="font-book text-neutral-950 mb-10"
            />
            {!show && (
              <StyledWrapperCommunity
                className={
                  !show
                    ? 'visible opacity-100 h-auto'
                    : 'invisible opacity-0 transition-all duration-100 h-0'
                }
              >
                <Accordion allowZeroExpanded>
                  {listRecommendations.length > 0 &&
                    _.map(listRecommendations, (recomendation, index) => {
                      return (
                        <AccordionItem key={`recommendationItem-${index}`}>
                          <StyledCardCommunity
                            key={`festivity- ${recomendation.id}`}
                          >
                            <TypographyComponent
                              variant="p"
                              text={recomendation.recommendation}
                              className="flex-grow text-primary font-bold truncate"
                            />
                            <StyledContentButtons>
                              <AccordionItemState>
                                {({ expanded }) =>
                                  expanded ? (
                                    ''
                                  ) : (
                                    <AccordionItemButton>
                                      <ButtonComponent
                                        text=""
                                        className="text-primary-100"
                                        variant="white"
                                        icon={
                                          <div className="icon-view text-primary h-10" />
                                        }
                                        onPress={() => {}}
                                      />
                                    </AccordionItemButton>
                                  )
                                }
                              </AccordionItemState>
                              <ButtonComponent
                                text=""
                                className="icon-edit text-primary-100"
                                variant="white"
                                onPress={() => {
                                  handleEditRecommendation({
                                    recommendationInfo: recomendation,
                                  });
                                  setRecommendationId(recomendation.id);
                                }}
                              />
                              <ButtonComponent
                                text=""
                                className="icon-delete"
                                onPress={() => {
                                  handleDeleteRecommendationModal();
                                  setRecommendationId(recomendation.id);
                                }}
                              />
                            </StyledContentButtons>
                          </StyledCardCommunity>
                          <AccordionItemPanel>
                            <StyledCollapseDetailContent>
                              <StyledContentCols>
                                <SttledContentInformation fullWidth>
                                  <TypographyComponent
                                    variant="p"
                                    text={recomendation.recommendation}
                                    className="mt-4"
                                  />
                                </SttledContentInformation>
                              </StyledContentCols>

                              <AccordionItemButton>
                                <ButtonComponent
                                  text="Cerrar vista previa"
                                  className="mt-6"
                                  variant="primary"
                                  onPress={() => {}}
                                />
                              </AccordionItemButton>
                            </StyledCollapseDetailContent>
                          </AccordionItemPanel>
                        </AccordionItem>
                      );
                    })}
                </Accordion>
              </StyledWrapperCommunity>
            )}

            <ButtonAddActivity
              open={show}
              text="Agregar nueva recomendación"
              icon={
                <Image
                  srcImage={plusIcon}
                  altImage="icon-plus"
                  titleImage="icon-plus"
                  className="w-6 mr-2"
                />
              }
              onPress={handleAddRecommendation}
            />

            <StyledForm
              className={`border p-6 rounded   ${
                show
                  ? 'visible opacity-100 h-auto'
                  : 'invisible opacity-0 transition-all duration-100 h-0'
              }`}
            >
              <TextArea
                name="recommendation"
                placeholder="Escribe la recomendación"
                className="w-full col-span-2"
                errors={errors}
                {...register('recommendation')}
              />
              <StyledGroup flex className="justify-end space-x-4">
                <ButtonComponent
                  text="Cancelar"
                  mode="outline"
                  onPress={handleCancelRecommendation}
                />
                <ButtonComponent
                  text={
                    recommendationData
                      ? 'Guardar cambios'
                      : 'Agregar recomendación '
                  }
                  className="mr-0 ml-auto mt-16"
                  onPress={handleSubmit(
                    recommendationData
                      ? handleUpdateRecommendation
                      : handleRegisterRecommendations
                  )}
                />
              </StyledGroup>
            </StyledForm>
          </>
        </Box>
        <DefaultModal
          isActive={showDeleteRecommendationModal}
          classNameContent="max-w-screen-md w-11/12"
          handleClose={handleDeleteRecommendationModal}
        >
          <TypographyComponent
            text="Eliminar recomendación"
            variant="h3"
            className="font-bold text-primary mb-1"
          />
          <StyledContentButtonsModal>
            <ButtonComponent
              variant="primary"
              text="Cancelar"
              className="mx-auto w-full lg:w-auto lg:mx-2 "
              mode="outline"
              onPress={handleDeleteRecommendationModal}
            />
            <ButtonComponent
              variant="primary"
              text="Aceptar"
              className="mx-auto w-full lg:w-auto  lg:mx-2"
              onPress={handleDeleteRecommendation}
            />
          </StyledContentButtonsModal>
        </DefaultModal>
      </LayoutSelected>
    </Suspense>
  );
};

export default AdminCreateRecommendations;
