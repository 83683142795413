//Packages
import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useStrings from 'strings';

//Styles
const StyledGroup = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledContentButtons = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const FormRequest = (props) => {
  const {
    control,
    errors,
    watch,
    setActiveItem,
    optionsCommunities,
    optionsContactLists,
    numberOptions,
    setValidateOptionsRules,
    handleAddOptions,
    setError,
    handleAcceptMessageAdmin,
    getValues,
    formAcceptMessage,
    handleSubmit,
    handleDeleteOption,
    handleOnChangeOptions,
  } = props;
  const { useComponents } = useViews();

  const { useTypographies, useInputs, useButtons } = useComponents();

  const { TypographyComponent, TypographyWithTooltip } = useTypographies();

  const { InputForm, Select, CheckBox, TextArea } = useInputs();
  const { ButtonComponent } = useButtons();

  const { useConstants } = useStrings();
  const {
    LIST_OF_TYPE_OF_COLLECTION,
    LIST_OF_TYPE_OF_INFORMATION_COLLECTION,
    LIST_OF_CHANNELS_FOR_SEND_MESSAGES,
    LIST_OF_OPTIONS_TO_DESTINATIONS,
  } = useConstants();

  return (
    <>
      <TypographyComponent
        text="Recolección de información"
        variant="h2"
        className="font-bold text-primary mt-4"
      />

      <TypographyComponent
        text="Las respuestas a este mensaje te permitán entender mejor algunos aspectos de la comunidad del proyecto. Te invitamos a ser muy específico con las preguntas para que recibas buena información."
        variant="p"
        className="font-book text-neutral-950 mb-10"
      />

      <StyledGroup noSpace className="mt-4">
        <div>
          <TypographyWithTooltip
            text="Tipo de recolección*"
            variant="p"
            textTooltip="Tipo de recolección*"
            idTooltip="gi_type"
            className="text-neutral-950"
            applyFontBold
          />
          <Select
            name="gi_type"
            id="gi_type"
            placeholder="Seleccionar"
            options={LIST_OF_TYPE_OF_COLLECTION}
            label=""
            control={control}
            errors={errors}
          />
        </div>
        {watch('gi_type')?.value === 'a' && (
          <div>
            <TypographyWithTooltip
              text="Tipo de información a recolectar*"
              variant="p"
              textTooltip="Tipo de información a recolectar*"
              idTooltip="type_information"
              className="text-neutral-950"
              applyFontBold
            />
            <Select
              name="type_information"
              id="type_information"
              placeholder="Seleccionar"
              options={LIST_OF_TYPE_OF_INFORMATION_COLLECTION}
              label=""
              control={control}
              errors={errors}
            />
          </div>
        )}
      </StyledGroup>

      <StyledGroup noSpace>
        <div>
          <TypographyWithTooltip
            text="Canal de comunicación*"
            variant="p"
            textTooltip="Canal de comunicación*"
            idTooltip="communication_channel"
            className="text-neutral-950"
            applyFontBold
          />

          <Select
            name="channel"
            id="canal"
            placeholder="Selecciona el Canal de comunicación"
            className="mt-2"
            options={
              watch('gi_type')?.value === 'a'
                ? LIST_OF_CHANNELS_FOR_SEND_MESSAGES
                : [{ label: 'WhatsApp', value: 'ws' }]
            }
            label=""
            control={control}
            errors={errors}
          />
        </div>

        <div>
          <TypographyWithTooltip
            text="Tipo de destino*"
            variant="p"
            textTooltip="Tipo de destino*"
            idTooltip="type_destin"
            className="text-neutral-950"
            applyFontBold
          />

          <Select
            name="typeDest"
            id="typeDest"
            placeholder="Selecciona el tipo de destino"
            className="mt-2"
            options={LIST_OF_OPTIONS_TO_DESTINATIONS}
            label=""
            control={control}
            errors={errors}
          />
        </div>
      </StyledGroup>

      <StyledGroup noSpace>
        <Select
          name="communities"
          id="commun"
          placeholder="Selecciona el destino del mensaje"
          className="w-full mt-2 col-span-2"
          options={
            watch('typeDest')?.value === 'comunidad'
              ? optionsCommunities
              : optionsContactLists
          }
          label="Destino"
          control={control}
          errors={errors}
          disabled={!watch('typeDest')?.value}
          multiple
        />
      </StyledGroup>

      <StyledGroup>
        <Controller
          control={control}
          name="date_checkbox"
          render={({ field: { name, onChange } }) => {
            return (
              <CheckBox
                name={name}
                label="Establecer una fecha para envío de mensaje"
                className="mt-6"
                onChange={onChange}
                errors={errors}
                checked={watch('date_checkbox')}
              />
            );
          }}
        />
      </StyledGroup>

      {watch('date_checkbox') && (
        <StyledGroup flex className="flex-col pt-4 ">
          <TypographyWithTooltip
            text="Fecha de envío"
            variant="p"
            textTooltip="Fecha de envío"
            idTooltip="date"
            className="text-neutral-950"
            applyFontBold
          />
          <InputForm
            name="send_date"
            label=""
            type="datetime-local"
            className="w-4/12 mt-2"
            placeholder="Selecciona la fecha de inicio"
            control={control}
            errors={errors}
          />
        </StyledGroup>
      )}

      {watch('gi_type')?.value === 'a' ? (
        <StyledGroup flex className="flex-col pt-4 ">
          <TypographyWithTooltip
            text="Pregunta"
            variant="h3"
            textTooltip="Pregunta"
            idTooltip="question"
            className="text-primary"
            applyFontBold
          />
          <InputForm
            name="message" //body
            label=""
            type="text"
            placeholder="Ingresa la pregunta"
            className="w-full col-span-2"
            errors={errors}
            control={control}
          />
        </StyledGroup>
      ) : (
        <StyledGroup>
          <TypographyComponent
            text="Pregunta o mensaje"
            variant="h3"
            className="font-bold text-primary mt-4"
          />

          <TextArea
            control={control}
            name="message"
            placeholder="Mensaje"
            className="w-full col-span-2"
            label=""
            errors={errors}
          />
        </StyledGroup>
      )}

      {watch('gi_type')?.value === 'a' &&
        watch('type_information')?.value === 'qn' && (
          <div className="flex items-end justify-between space-x-6 ">
            <div className="flex-1">
              {_.map(numberOptions, (option, index) => {
                return (
                  <div className="flex items-center space-x-6" key={option.id}>
                    <TypographyComponent
                      variant="p"
                      text={`Opción ${index + 1}`}
                      className="text-primary font-bold flex-none"
                    />
                    <InputForm
                      name={`options[${index}]`}
                      label=""
                      type="text"
                      placeholder={`Ingresa la opción ${index + 1}`}
                      className="flex-1"
                      errors={errors}
                      control={control}
                      value={option.value}
                      onChange={(event) => {
                        handleOnChangeOptions({
                          optionIndex: option.id,
                          optionValue: event.target.value,
                        });
                        setValidateOptionsRules((prevValue) => !prevValue);
                      }}
                    />
                    <StyledContentButtons>
                      <ButtonComponent
                        text=""
                        className="bg-transparent text-tertiary"
                        onPress={(event) => {
                          handleDeleteOption({
                            event,
                            optionId: index,
                          });
                        }}
                        icon={<div className="icon-delete" />}
                      />
                    </StyledContentButtons>
                  </div>
                );
              })}
            </div>
            <ButtonComponent
              variant="primary"
              mode="outline"
              text="Agregar otra opción"
              className="mb-2"
              icon={<div className="icon-close transform rotate-45" />}
              disabled={numberOptions.length === 10}
              onPress={handleAddOptions}
            />
          </div>
        )}

      {errors['options'] && (
        <p className="text-sm mt-2 text-error">{errors['options'].message}</p>
      )}

      <div className="flex justify-between pt-12">
        <ButtonComponent
          text="Cancelar"
          className="border-none shadow-none"
          onPress={(e) => {
            e.preventDefault();
            setActiveItem({ active: 0 });
          }}
          mode="outline"
          variant="primary"
        />
        <div className="flex items-center space-x-2">
          <ButtonComponent
            text="Guardar cambios"
            mode="outline"
            onPress={(e) => {
              e.preventDefault();
              if (watch('typeDest')?.value && !watch('communities')?.length) {
                setError('communities', {
                  type: 'manual',
                  message: 'Campo requerido',
                });
              } else {
                handleAcceptMessageAdmin({
                  data: getValues(),
                  state: 'd',
                  isSaveDraftMessage: true,
                  formToUse: formAcceptMessage,
                });
              }
            }}
          />
          <ButtonComponent
            text="Iniciar recolección"
            icon={<div className="icon-send text-lg  h-6 flex" />}
            onPress={handleSubmit((data) =>
              handleAcceptMessageAdmin({
                data,
                state: 'ip',
                formToUse: formAcceptMessage,
              })
            )}
          />
        </div>
      </div>
    </>
  );
};

FormRequest.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  setActiveItem: PropTypes.func,
  watch: PropTypes.func,
  optionsCommunities: PropTypes.array,
  optionsContactLists: PropTypes.array,
  numberOptions: PropTypes.array,
  setValidateOptionsRules: PropTypes.func,
  handleAddOptions: PropTypes.func,
  setError: PropTypes.func,
  handleAcceptMessageAdmin: PropTypes.func,
  getValues: PropTypes.func,
  formAcceptMessage: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleDeleteOption: PropTypes.func,
  handleOnChangeOptions: PropTypes.func,
};

FormRequest.defaultProps = {
  control: {},
  errors: {},
  setActiveItem: () => {},
  watch: () => {},
  optionsCommunities: [],
  optionsContactLists: [],
  numberOptions: [],
  setValidateOptionsRules: () => {},
  handleAddOptions: () => {},
  setError: () => {},
  handleAcceptMessageAdmin: () => {},
  getValues: () => {},
  formAcceptMessage: {},
  handleSubmit: () => {},
  handleDeleteOption: () => {},
  handleOnChangeOptions: () => {},
};

export default FormRequest;
