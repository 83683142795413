//Packages
import React, { Suspense, useState } from 'react';

//Hooks
import useControllers from 'controllers';
import useViews from 'views';

//Components
import CommunicationArtist from './Artist';
import CommunicationAdmin from './Admin';

const Communication = () => {
  const { useGeneralHooks } = useControllers();
  const { useGetUserProfile } = useGeneralHooks();
  const { role } = useGetUserProfile();

  const { useComponents } = useViews();
  const { useLoaders } = useComponents();
  const { DefaultLoader } = useLoaders();
  const [permissions] = useState(role.id === 'ad' || role.id === 'ep');

  return (
    <Suspense fallback={<DefaultLoader />}>
      {permissions ? <CommunicationAdmin /> : <CommunicationArtist />}
    </Suspense>
  );
};

export default Communication;
