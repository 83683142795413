import React, { lazy } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

// Components
import ModalDeny from './components/ModalDeny/ModalDeny';
import ModalAccept from './components/ModalAccept/ModalAccept';
import Methodology from './components/Methodology/Methodology';
import Activities from './components/ActivitiesSteep2/Activities';
import FormMessages from './components/FormMessages/FormMessages';

const StyledGroup = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledDivider = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledDivider,
  }))
);

const StyledContentButtons = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const StyledWrapperCommunity = lazy(() =>
  import('../../../../../../Dashboard.styles').then((mod) => ({
    default: mod.StyledWrapperCommunity,
  }))
);

const StyledCardCommunity = lazy(() =>
  import('../../../../../../Dashboard.styles').then((mod) => ({
    default: mod.StyledCardCommunity,
  }))
);
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

const DeepenKnowCommunities = (props) => {
  const { setViewActive } = props;

  const { useComponents } = useViews();
  const {
    useTypographies,
    useInputs,
    useButtons,
    useModals,
    useCards,
    useBadges,
  } = useComponents();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { TextArea, Select } = useInputs();
  const { ButtonComponent } = useButtons();
  const { DefaultModal } = useModals();
  const { CardNotification } = useCards();
  const { DefaultBadge } = useBadges();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useDeepenKnowledgeCommunity } =
    useAdminInterventionsDashboardControllers();
  const { useAdminDeepenKnowledgeCommunityInterventions } =
    useDeepenKnowledgeCommunity();
  const {
    formDisplayDataDeepenKnowledge: {
      control,
      formState: { errors },
    },
    deepen_knowledge,
    preViewPDFDeepenKnowCommunity,
    downloadPDFDeepenKnowCommunity,
    denyApproveKnowledge,
    setDenyApproveKnowledge,
    approveRejectDeepenKnowledge,
    formRejectKnowledge,
    actualPhase,
    validateDiffusion,
  } = useAdminDeepenKnowledgeCommunityInterventions();

  return (
    <>
      {!(
        !_.includes(
          ['a', 'r', 'cl', 'c', 'sc', 'p'],
          deepen_knowledge.state.id
        ) && actualPhase === 3.1
      ) && (
        <CardNotification
          className="w-5/12 mx-auto py-9 px-4 mt-16"
          title={
            <TypographyComponent
              text={
                actualPhase > 3.1 ? 'Completado' : deepen_knowledge?.state.name
              }
              variant="h2"
              className="font-bold text-primary icon-watch text-center"
            />
          }
        />
      )}
      {deepen_knowledge.state.id !== 'p' && (
        <>
          <TypographyComponent
            text="Profundizar en el conocimiento de la comunidad"
            variant="h2"
            className="text-primary mt-2 font-bold"
          />
          <TypographyComponent
            text="Partiendo de los archivos compartidos sobre el proyecto y las comunidades. Sientes que te falta saber algo importante sobre la comunidad para diseñar intervenciones exitosas?
            Responde las siguientes preguntas!"
            variant="p"
            className="text-neutral-950 mt-2 mb-4"
          />
          <form className="">
            <StyledGroup col2 className="mb-4">
              <Select
                name="communities"
                id="communities"
                control={control}
                label="¿En cuál o cuáles de las comunidades quieres profundizar?*"
                className="w-full"
                multiple
                disabled
                isClearable={false}
              />
            </StyledGroup>

            <StyledGroup col2 className="mb-4">
              <div className="w-full col-span-2">
                <TextArea
                  control={control}
                  name="what_to_discover"
                  placeholder="Me gustaría descubrir..."
                  className="w-full col-span-2"
                  label="¿Qué quieres descubrir de la o las comunidades seleccionadas?*"
                  errors={errors}
                  disabled
                  hasController
                />
              </div>
            </StyledGroup>

            <Methodology
              preViewPDFDeepenKnowCommunity={preViewPDFDeepenKnowCommunity}
              downloadPDFDeepenKnowCommunity={downloadPDFDeepenKnowCommunity}
            />

            <StyledGroup col2 flex className="mb-4 flex-col">
              <TypographyWithTooltip
                text="Modalidad de profundización*"
                variant="p"
                textTooltip="Modalidad de profundización"
                className="text-neutral-900"
                idTooltip="know-cpmmunitie"
                applyFontBold
              />
              <Select
                name="deepening_modality"
                id="deepening_modality"
                placeholder="Modalidad de profundización* "
                control={control}
                disabled
                label=""
              />
            </StyledGroup>
          </form>

          <StyledDivider spacexs />

          {deepen_knowledge.deepening_modality.id === 'r' && (
            <StyledGroup col2 flex className="mb-4 flex-col">
              <TypographyWithTooltip
                text="Tipo de profundización remota"
                variant="p"
                textTooltip="Tipo de profundización remota"
                className="text-neutral-900"
                idTooltip="know-typePrf"
                applyFontBold
              />
              <Select
                name="deepen_type"
                id="deepen_type"
                placeholder="Tipo de profundización* "
                control={control}
                disabled
                label=""
              />
            </StyledGroup>
          )}

          {deepen_knowledge.deepening_modality.id === 'r' &&
          deepen_knowledge.deepen_type?.id === 'async' ? (
            <>
              <StyledGroup col2 className="mb-4">
                <TextArea
                  name="description"
                  placeholder="Me gustaría descubrir..."
                  className="w-full col-span-2"
                  label="Descripción de la actividad*"
                  control={control}
                  errors={errors}
                  disabled
                />
              </StyledGroup>
              <StyledDivider spacexs />
            </>
          ) : (
            <Activities activities={deepen_knowledge.activities} />
          )}
          <>
            <TypographyComponent
              text={
                !validateDiffusion
                  ? 'Mensajes de difusión'
                  : 'Mensaje de recolección de información'
              }
              variant="h3"
              className="text-primary mt-6 font-bold"
            />
            <TypographyComponent
              text={
                !validateDiffusion
                  ? 'Si escogiste el método presencial, remota asincrónica o hibrida, tendrás que escribir un mensaje de difusión. Ten en cuenta que los mensajes se cobran por caracteres, es importante ser conciso en la comunicación. Este mensaje debe ser aprobado por el socio ejecutor antes de enviarse a la comunidad.'
                  : 'Si escogiste el método remota asincrónica, tendrás que escribir un mensaje de recolección. Ten en cuenta que los mensajes se cobran por caracteres, es importante ser conciso en la comunicación. Este mensaje debe ser aprobado por el socio ejecutor antes de enviarse a la comunidad.'
              }
              variant="p"
              className="text-neutral-950 mt-2 mb-4"
            />

            <StyledWrapperCommunity
              className={
                deepen_knowledge.messages.length !== 0
                  ? 'visible opacity-100 h-auto'
                  : 'invisible opacity-0 transition-all duration-100 h-0'
              }
            >
              <Accordion allowZeroExpanded>
                {deepen_knowledge.messages.length > 0 &&
                  _.map(deepen_knowledge.messages, (message, index) => {
                    return (
                      <AccordionItem
                        key={`message${index}`}
                        uuid={`message${index}`}
                        dangerouslySetExpanded={message.expanded}
                      >
                        <StyledCardCommunity key={`message-${index}`}>
                          <div className="flex items-center">
                            <TypographyComponent
                              variant="p"
                              text={message.description}
                              className="flex-grow text-primary font-bold"
                            />
                            {(actualPhase > 3.1 ||
                              _.includes(
                                ['c', 'sc'],
                                deepen_knowledge.state.id
                              )) && (
                              <DefaultBadge
                                type="light"
                                variant={`${
                                  message.state.id === 's'
                                    ? 'success'
                                    : message.state.id === 'r'
                                    ? 'error'
                                    : 'warning'
                                }`}
                                label={
                                  message.state.state || message.state.name
                                }
                                className="ml-2"
                              />
                            )}
                          </div>
                          <StyledContentButtons>
                            <AccordionItemState>
                              {({ expanded }) => (
                                <AccordionItemButton>
                                  <ButtonComponent
                                    text=""
                                    icon={
                                      <div
                                        className={`icon-arrow-down text-neutral-900 text-xxs ${
                                          expanded && 'transform rotate-180'
                                        }`}
                                      />
                                    }
                                    className=""
                                    variant="white"
                                    onPress={() => {}}
                                  />
                                </AccordionItemButton>
                              )}
                            </AccordionItemState>
                          </StyledContentButtons>
                        </StyledCardCommunity>
                        <AccordionItemPanel>
                          <div className="border rounded py-8 px-6 mt-4">
                            <form className="px-4">
                              <FormMessages
                                defaultValueTypeProfundization={
                                  deepen_knowledge.deepen_type
                                }
                                message={message}
                              />
                            </form>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    );
                  })}
              </Accordion>
            </StyledWrapperCommunity>
          </>

          <DefaultModal
            isActive={denyApproveKnowledge.deny}
            classNameContent="max-w-screen-md w-11/12"
            handleClose={() =>
              setDenyApproveKnowledge({
                ...denyApproveKnowledge,
                deny: false,
              })
            }
          >
            <ModalDeny
              setViewActive={setViewActive}
              setDeny={() => {
                approveRejectDeepenKnowledge('r');
                setDenyApproveKnowledge({
                  ...denyApproveKnowledge,
                  deny: false,
                });
              }}
              formRejectKnowledge={formRejectKnowledge}
              handleClose={() =>
                setDenyApproveKnowledge({
                  ...denyApproveKnowledge,
                  deny: false,
                })
              }
            />
          </DefaultModal>

          <DefaultModal
            isActive={denyApproveKnowledge.approve}
            classNameContent="max-w-screen-md w-11/12"
            handleClose={() =>
              setDenyApproveKnowledge({
                ...denyApproveKnowledge,
                approve: false,
              })
            }
          >
            <ModalAccept
              setViewActive={setViewActive}
              setApprove={() => {
                approveRejectDeepenKnowledge('c');
                setDenyApproveKnowledge({
                  ...denyApproveKnowledge,
                  approve: false,
                });
              }}
              handleClose={() =>
                setDenyApproveKnowledge({
                  ...denyApproveKnowledge,
                  approve: false,
                })
              }
            />
          </DefaultModal>
        </>
      )}

      <div className="flex items-center space-x-2 mt-10 justify-center">
        {!_.includes(
          ['a', 'r', 'cl', 'c', 'sc', 'p'],
          deepen_knowledge.state.id
        ) &&
          actualPhase === 3.1 && (
            <>
              <ButtonComponent
                text="Rechazar profundización"
                mode="outline"
                onPress={() => {
                  setDenyApproveKnowledge({
                    ...denyApproveKnowledge,
                    deny: true,
                  });
                }}
              />
              <ButtonComponent
                text="Aprobar"
                onPress={() => {
                  setDenyApproveKnowledge({
                    ...denyApproveKnowledge,
                    approve: true,
                  });
                }}
              />
            </>
          )}
      </div>
    </>
  );
};

DeepenKnowCommunities.propTypes = {
  setViewActive: PropTypes.func,
  defaultValueModalidad: PropTypes.object,
  defaultValueTypeProfundization: PropTypes.object,
  knowCommunity: PropTypes.object,
};

export default DeepenKnowCommunities;
