import useProviders from '../../../providers';
import useStrings from '../../../../strings';
import useGeneralHooks from '../../../../controllers/generalHooks';

const useLegalDataActions = () => {
  //providers
  const { useGeneralProviders } = useProviders();
  const { useLegalDataProviders } = useGeneralProviders();
  const { legalDataProvider } = useLegalDataProviders();
  // console
  const { useConsole } = useGeneralHooks();
  const { viewConsole } = useConsole();
  //strings
  const { useGeneralTypes } = useStrings();
  const { useLegalDataTypes } = useGeneralTypes();
  const { LEGAL_DATA } = useLegalDataTypes();
  const actLegalData = () => async (dispatch) => {
    try {
      const response = await legalDataProvider();
      dispatch({
        type: LEGAL_DATA,
        payload: response.data,
      });
    } catch (e) {
      viewConsole([e], 'warn');
    }
  };
  return {
    actLegalData,
  };
};
export default useLegalDataActions;
