import useHelpers from '../../../../helpers';

const useColorsSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const colorsSelector = createSelector(
    (state) => state.colors,
    (colors) => colors
  );
  return { colorsSelector };
};

export default useColorsSelectors;
