const useAdminDevelopmentInterventionDefineInterventionTypes = () => {
  const GET_DEFINE_PROJECT_INTERVENTION = 'GET_DEFINE_PROJECT_INTERVENTION';
  const UPDATE_DEFINE_PROJECT_INTERVENTION =
    'UPDATE_DEFINE_PROJECT_INTERVENTION';
  const UPDATE_DEFINE_PROJECT_INTERVENTION_ARTIST =
    'UPDATE_DEFINE_PROJECT_INTERVENTION_ARTIST';
  const ADD_INTERVENTION_DEFINITION_ARTIST =
    'ADD_INTERVENTION_DEFINITION_ARTIST';

  const DELETE_INTERVENTION_DEFINITION_ARTIST =
    'DELETE_INTERVENTION_DEFINITION_ARTIST';

  const CREATE_ACTIVITY_FOR_INTERVENTION_ARTIST =
    'CREATE_ACTIVITY_FOR_INTERVENTION_ARTIST';
  const DELETE_ACTIVITY_FOR_INTERVENTION_ARTIST =
    'DELETE_ACTIVITY_FOR_INTERVENTION_ARTIST';
  const EDIT_ACTIVITY_FOR_INTERVENTION_ARTIST =
    'EDIT_ACTIVITY_FOR_INTERVENTION_ARTIST';
  const ADD_LOGISTIC_MEAN_INTO_INTERVENTION_ARTIST =
    'ADD_LOGISTIC_MEAN_INTO_INTERVENTION_ARTIST';
  const DELETE_LOGISTIC_MEAN_INTO_INTERVENTION_ARTIST =
    'DELETE_LOGISTIC_MEAN_INTO_INTERVENTION_ARTIST';
  const EDIT_LOGISTIC_MEAN_INTO_INTERVENTION_ARTIST =
    'EDIT_LOGISTIC_MEAN_INTO_INTERVENTION_ARTIST';
  const SET_DATA_DIFFUSION_FORM_INTO_INTERVENTION_ARTIST =
    'SET_DATA_DIFFUSION_FORM_INTO_INTERVENTION_ARTIST';
  const RESET_DATA_DIFFUSION_FORM_INTO_INTERVENTION_ARTIST =
    'RESET_DATA_DIFFUSION_FORM_INTO_INTERVENTION_ARTIST';
  const CREATE_ASSIGNED_TASKS_FOR_INTERVENTION_ARTIST =
    'CREATE_ASSIGNED_TASKS_FOR_INTERVENTION_ARTIST';
  const DELETE_ASSIGNED_TASKS_FOR_INTERVENTION_ARTIST =
    'DELETE_ASSIGNED_TASKS_FOR_INTERVENTION_ARTIST';
  const EDIT_ASSIGNED_TASKS_FOR_INTERVENTION_ARTIST =
    'EDIT_ASSIGNED_TASKS_FOR_INTERVENTION_ARTIST';
  const DOWNLOAD_DIFUSION_FILE_INTERVENTION = 'DOWNLOAD_DIFUSION_FILE_INTERVENTION'

  return {
    GET_DEFINE_PROJECT_INTERVENTION,
    UPDATE_DEFINE_PROJECT_INTERVENTION,
    UPDATE_DEFINE_PROJECT_INTERVENTION_ARTIST,
    ADD_INTERVENTION_DEFINITION_ARTIST,
    DELETE_INTERVENTION_DEFINITION_ARTIST,
    CREATE_ACTIVITY_FOR_INTERVENTION_ARTIST,
    DELETE_ACTIVITY_FOR_INTERVENTION_ARTIST,
    EDIT_ACTIVITY_FOR_INTERVENTION_ARTIST,
    ADD_LOGISTIC_MEAN_INTO_INTERVENTION_ARTIST,
    DELETE_LOGISTIC_MEAN_INTO_INTERVENTION_ARTIST,
    EDIT_LOGISTIC_MEAN_INTO_INTERVENTION_ARTIST,
    SET_DATA_DIFFUSION_FORM_INTO_INTERVENTION_ARTIST,
    RESET_DATA_DIFFUSION_FORM_INTO_INTERVENTION_ARTIST,
    CREATE_ASSIGNED_TASKS_FOR_INTERVENTION_ARTIST,
    DELETE_ASSIGNED_TASKS_FOR_INTERVENTION_ARTIST,
    EDIT_ASSIGNED_TASKS_FOR_INTERVENTION_ARTIST,
    DOWNLOAD_DIFUSION_FILE_INTERVENTION
  };
};

export default useAdminDevelopmentInterventionDefineInterventionTypes;
