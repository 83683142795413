//Packages
import React, { Suspense } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Assets
import plusIcon from 'assets/icons/plus.svg';

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

//Styles
import { StyledForm, StyledGroup } from 'styles/formViews/formViews.styles';
import {
  StyledContentButtonsModal,
  StyledContentButtons,
} from 'styles/tableViews/tableViews.styles';
import {
  StyledCollapseDetailContent,
  StyledMultimedia,
  SttledContentInformation,
  StyledContentCols,
} from 'styles/collapse/collapse.styles';
import {
  StyledWrapperCommunity,
  StyledCardCommunity,
} from '../../../Dashboard.styles';

const Capital = (props) => {
  const { type } = props;

  const { useComponents } = useViews();
  const {
    useInputs,
    useButtons,
    useTypographies,
    useImages,
    useModals,
    useLoaders,
  } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { TextArea, InputForm } = useInputs();
  const { ButtonComponent, ButtonAddActivity } = useButtons();
  const { TypographyComponent } = useTypographies();
  const { Image, UploadImagePreview } = useImages();
  const { DefaultModal } = useModals();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminAbcTrajectoryDashboardHooks } =
    useAdminDashboardProjectsHooks();
  const { useAdminAbcTrajectoryDashboardTabs } =
    useAdminAbcTrajectoryDashboardHooks();
  const {
    control,
    register,
    handleSubmit,
    errors,
    handleRegisterABCproject,
    handleAddCommunity,
    handleCancelAbcProject,
    handleDeleteCommunity,

    show,
    abcProjectSelectedData,

    cleanFileInputs,

    handleResetInputFile,
    showDeleteAbcProjectModal,
    handleDeleteAbcProjectModal,
    handleUpdateABCproject,
    handleSelectTypeFile,
    listAbcProjects,
    handleEditABCproject,
    setAbcProjectId,

    showViewAbcModal,
    handleViewAbcModal,
    viewAbcProject,
  } = useAdminAbcTrajectoryDashboardTabs({ type });

  return (
    <Suspense fallback={<DefaultLoader />}>
      {!show && (
        <StyledWrapperCommunity
          className={
            !show
              ? 'visible opacity-100 h-auto'
              : 'invisible opacity-0 transition-all duration-100 h-0'
          }
        >
          <Accordion allowZeroExpanded>
            {listAbcProjects.length > 0 &&
              _.map(listAbcProjects, (abcProject, index) => {
                return (
                  <AccordionItem key={`capital-${index}`}>
                    <StyledCardCommunity>
                      <TypographyComponent
                        variant="p"
                        text={abcProject.name}
                        className="flex-grow text-primary font-bold"
                      />
                      <StyledContentButtons>
                        <AccordionItemState>
                          {({ expanded }) =>
                            expanded ? (
                              ''
                            ) : (
                              <AccordionItemButton>
                                <ButtonComponent
                                  text=""
                                  className="text-primary-100"
                                  variant="white"
                                  icon={
                                    <div className="icon-view text-primary h-10" />
                                  }
                                  onPress={() => {}}
                                />
                              </AccordionItemButton>
                            )
                          }
                        </AccordionItemState>
                        <ButtonComponent
                          text=""
                          className="icon-edit text-primary-100"
                          variant="white"
                          onPress={() => {
                            handleEditABCproject({ abcProject });
                            setAbcProjectId(abcProject.id);
                          }}
                        />
                        <ButtonComponent
                          text=""
                          className="icon-delete"
                          onPress={() => {
                            handleDeleteAbcProjectModal();
                            setAbcProjectId(abcProject.id);
                          }}
                        />
                      </StyledContentButtons>
                    </StyledCardCommunity>
                    <AccordionItemPanel>
                      <StyledCollapseDetailContent>
                        <StyledContentCols>
                          <StyledMultimedia>
                            <Image
                              key={index}
                              srcImage={abcProject.image.url}
                              altImage="multimedia"
                              titleImage="multimedia"
                            />
                          </StyledMultimedia>
                          <SttledContentInformation>
                            <TypographyComponent
                              variant="h3"
                              text={abcProject.name}
                              className="flex-grow text-primary font-bold mt-4"
                            />
                            <TypographyComponent
                              variant="p"
                              text={abcProject.description}
                              className="mt-4"
                            />
                          </SttledContentInformation>
                        </StyledContentCols>

                        <AccordionItemButton>
                          <ButtonComponent
                            text="Cerrar vista previa"
                            className="mt-6"
                            variant="primary"
                            onPress={() => {}}
                          />
                        </AccordionItemButton>
                      </StyledCollapseDetailContent>
                    </AccordionItemPanel>
                  </AccordionItem>
                );
              })}
          </Accordion>
        </StyledWrapperCommunity>
      )}

      <ButtonAddActivity
        open={show}
        text="Agregar evidencia asociada al componente"
        icon={
          <Image
            srcImage={plusIcon}
            altImage="icon-plus"
            titleImage="icon-plus"
            className="w-6 mr-2"
          />
        }
        className="mx-auto mb-10"
        onPress={handleAddCommunity}
      />
      <StyledForm
        className={
          show
            ? 'visible opacity-100 h-auto'
            : 'invisible opacity-0 transition-all duration-100 h-0'
        }
      >
        <InputForm
          name="name"
          label="Título"
          placeholder="Ingresar título"
          errors={errors}
          {...register('name')}
        />

        <TextArea
          name="description"
          placeholder="Ingresar la descripción del capital"
          className="w-full col-span-2"
          label="Descripción"
          errors={errors}
          {...register('description')}
        />
        <TypographyComponent
          text={
            <label>
              <span className="font-bold">Agregar imágenes del proyecto</span>
              <span className="text-neutral-800 ml-2 font-light">
                Formato de archivo permitido JPG, PNG, JPEG
              </span>
            </label>
          }
          variant="p"
          className="text-neutral-950 mb-10 w-full"
        />
        <StyledGroup flex className="space-x-4">
          <div>
            <Controller
              control={control}
              name="image"
              render={({ field: { onChange, name } }) => {
                return (
                  <UploadImagePreview
                    name={name}
                    onChange={onChange}
                    errors={errors}
                    resetInput={() => {
                      handleResetInputFile({ typeMultimedia: 'image' });
                    }}
                    srcDefaultImage={abcProjectSelectedData?.image?.url}
                    cleanFileInputs={cleanFileInputs}
                    isAVideo={handleSelectTypeFile('image')}
                    onlyImagesAllowed
                  />
                );
              }}
            />
          </div>
        </StyledGroup>
        <StyledGroup flex className="justify-end space-x-4">
          <ButtonComponent
            text="Cancelar"
            mode="outline"
            onPress={handleCancelAbcProject}
          />
          <ButtonComponent
            text={
              abcProjectSelectedData ? 'Guardar Cambios' : 'Agregar evidencia'
            }
            className="mx-auto mt-8"
            onPress={handleSubmit(
              abcProjectSelectedData
                ? handleUpdateABCproject
                : handleRegisterABCproject
            )}
          />
        </StyledGroup>
      </StyledForm>
      <DefaultModal
        isActive={showDeleteAbcProjectModal}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={handleDeleteAbcProjectModal}
      >
        <TypographyComponent
          text="Eliminar evidencia asociada al componente"
          variant="h3"
          className="font-bold text-primary mb-1"
        />
        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto lg:mx-2 "
            mode="outline"
            onPress={handleDeleteAbcProjectModal}
          />
          <ButtonComponent
            variant="primary"
            text="Aceptar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={handleDeleteCommunity}
          />
        </StyledContentButtonsModal>
      </DefaultModal>
      {showViewAbcModal && (
        <DefaultModal
          isActive={showViewAbcModal}
          classNameContent="max-w-xl w-11/12"
          handleClose={handleViewAbcModal}
        >
          <TypographyComponent
            text={'Capital (C)'}
            variant="h4"
            className="font-bold text-primary mb-2"
          />
          <TypographyComponent
            text={viewAbcProject?.name}
            variant="h3"
            className="font-bold text-primary mb-1"
          />
          <TypographyComponent
            text={viewAbcProject?.description}
            variant="p"
            className="text-neutral-950  mb-1"
          />
          <StyledContentButtonsModal>
            <Image
              srcImage={viewAbcProject?.image.url}
              altImage={viewAbcProject?.name}
              titleImage={viewAbcProject?.name}
              className="w-36 h-22 object-cover mr-4 rounded-md"
            />
          </StyledContentButtonsModal>
        </DefaultModal>
      )}
    </Suspense>
  );
};

Capital.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Capital;
