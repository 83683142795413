//Packages
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams } from 'react-router-dom';

//Hooks

import useApi from 'api';
import useHelpers from 'helpers';
import useStrings from 'strings';
import useControllers from 'controllers';

const useAdminCreateContactsCommunitiesDashboard = () => {
  const history = useHistory();
  const { community_id } = useParams();
  const { useActions } = useApi();
  const { useUserActions, dispatch } = useActions();
  const { actUserCreate } = useUserActions();
  const { useMessagesTypes, useConstants } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const {
    EMAIL_NOT_VALID,
    REQUIRED_FIELD,
    NUMBER_NOT_VALID,
    MAX_LENGTH_PHONE,
    MIN_LENGTH_PHONE,
    ACCEPTS_ONLY_TEXT,
  } = useFormsTypes();
  const { OPTIONS_ROLES_WITHOUT_LOGIN } = useConstants();
  const { useQuickFunctions } = useHelpers();
  const { regexValidations } = useQuickFunctions();
  const regexNumber = regexValidations({ regex: 'number' });
  const regexText = regexValidations({ regex: 'text' });

  const schemaCreateContact = Yup.object({
    role: Yup.object().required(REQUIRED_FIELD),
    name: Yup.string()
      .required(REQUIRED_FIELD)
      .matches(regexText, ACCEPTS_ONLY_TEXT),
    last_name: Yup.string()
      .required(REQUIRED_FIELD)
      .matches(regexText, ACCEPTS_ONLY_TEXT),
    email: Yup.string().email(EMAIL_NOT_VALID).nullable(),
    code: Yup.object().required(REQUIRED_FIELD),
    number_phone: Yup.string()
      .required(REQUIRED_FIELD)
      .matches(regexNumber, NUMBER_NOT_VALID)
      .max(15, MAX_LENGTH_PHONE)
      .min(8, MIN_LENGTH_PHONE),
    has_whatsapp: Yup.boolean().required(REQUIRED_FIELD),
    gender: Yup.string(),
  });
  const createUserForm = useForm({
    resolver: yupResolver(schemaCreateContact),
    mode: 'onChange',
  });
  const { useGeneralHooks } = useControllers();
  const { useWhereLive } = useGeneralHooks();
  const { optionsIndicatives } = useWhereLive({ callEndpointCountries: false });

  const createUser = (user, e) => {
    e.preventDefault();

    user.role = user.role.value;
    user.phone_code = user.code.value;
    user.phone = user.number_phone;
    user.community_id = community_id;

    delete user.number_phone;
    delete user.code;
    dispatch(
      actUserCreate(
        { user },
        () => {
          createUserForm.reset();
          history.goBack();
        },
        (e) => {
          if (e.response.data?.errors) {
            const { email, phone, community_id, name, last_name } =
              e.response.data?.errors;
            name &&
              createUserForm.setError('name', {
                type: 'manual',
                message: name,
              });
            last_name &&
              createUserForm.setError('last_name', {
                type: 'manual',
                message: last_name,
              });
            email &&
              createUserForm.setError('email', {
                type: 'manual',
                message: email,
              });
            phone &&
              createUserForm.setError('number_phone', {
                type: 'manual',
                message: phone,
              });
            community_id &&
              createUserForm.setError('community_id', {
                type: 'manual',
                message: community_id,
              });
          }
        }
      )
    );
  };

  return {
    createUserForm,
    createUser,
    history,
    optionsIndicatives,
    OPTIONS_ROLES_WITHOUT_LOGIN,
  };
};

export default useAdminCreateContactsCommunitiesDashboard;
