const useAdminDevelopmentInterventionCreativePlanTypes = () => {
  const GET_CREATIVE_PLAN_INTERVENTION = 'GET_CREATIVE_PLAN_INTERVENTION';
  const UPDATE_CREATIVE_PLAN_INTERVENTION = 'UPDATE_CREATIVE_PLAN_INTERVENTION';
  const DELETE_CREATIVE_PLAN_INTERVENTION = 'DELETE_CREATIVE_PLAN_INTERVENTION';
  const DOWNLOAD_FILE_CREATIVE_PLAN_INTERVENTION =
    'DOWNLOAD_FILE_CREATIVE_PLAN_INTERVENTION';
  return {
    GET_CREATIVE_PLAN_INTERVENTION,
    UPDATE_CREATIVE_PLAN_INTERVENTION,
    DELETE_CREATIVE_PLAN_INTERVENTION,
    DOWNLOAD_FILE_CREATIVE_PLAN_INTERVENTION,
  };
};

export default useAdminDevelopmentInterventionCreativePlanTypes;
