//Packages
import React from 'react';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';
import useStrings from 'strings';

//Styles
import { StyledGroup } from 'styles/formViews/formViews.styles';

const MeansForm = (props) => {
  const {
    formLogisticsMeans,
    handleCancelCreateOrEditLogistic,
    handleCreateOrEditLogisticMean,
    logisticMeanDataToEdit,
    isLogisticMeanEditButtonDisabled,
    disabledEditMode,
  } = props;

  const { useComponents } = useViews();
  const { useInputs, useButtons } = useComponents();
  const { Select, InputForm } = useInputs();
  const { ButtonComponent } = useButtons();
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = formLogisticsMeans;

  const { useConstants } = useStrings();
  const { OPTIOS_LOGISTICS_MEANS_TYPES } = useConstants();

  return (
    <form>
      <StyledGroup noSpace className="mb-4 flex-col">
        <Select
          name="type"
          id="type"
          placeholder="Selecciona el tipo de recurso"
          control={control}
          options={OPTIOS_LOGISTICS_MEANS_TYPES}
          label="Tipo de recurso*"
          errors={errors}
          disabled={disabledEditMode}
        />
        <InputForm
          name="describe"
          label="Recurso requerido*"
          type="text"
          className="full"
          placeholder="Describe el recurso"
          {...register('describe')}
          errors={errors}
          disabled={disabledEditMode}
        />
      </StyledGroup>

      <div className="flex items-center space-x-2 mt-10 justify-end">
        <ButtonComponent
          text="Cancelar"
          mode="outline"
          onPress={handleCancelCreateOrEditLogistic}
        />
        <ButtonComponent
          text={`${logisticMeanDataToEdit.id ? 'Editar' : 'Guardar'} recurso`}
          onPress={handleSubmit(handleCreateOrEditLogisticMean)}
          disabled={Boolean(
            logisticMeanDataToEdit.id && isLogisticMeanEditButtonDisabled
          )}
        />
      </div>
    </form>
  );
};

MeansForm.propTypes = {
  formLogisticsMeans: PropTypes.object.isRequired,
  handleCancelCreateOrEditLogistic: PropTypes.func,
  handleCreateOrEditLogisticMean: PropTypes.func,
  logisticMeanDataToEdit: PropTypes.object,
  isLogisticMeanEditButtonDisabled: PropTypes.bool,
  disabledEditMode: PropTypes.bool,
};

MeansForm.defaultProps = {
  handleCancelCreateOrEditLogistic: () => {},
  handleCreateOrEditLogisticMean: () => {},
  logisticMeanDataToEdit: {},
  isLogisticMeanEditButtonDisabled: false,
  disabledEditMode: false,
};

export default MeansForm;
