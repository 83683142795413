//Packages
import _ from 'lodash';
import { useMemo } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';

//Hooks
import useStrings from 'strings';
import useControllers from 'controllers';
import useApi from 'api';
import useModels from 'models';
import useHelpers from 'helpers';

const useEditUser = (contact = false) => {
  const history = useHistory();
  const { id } = useParams();

  const { useActions } = useApi();
  const { dispatch, useUserActions } = useActions();
  const { actUserEdit } = useUserActions();

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const {
    EMAIL_NOT_VALID,
    REQUIRED_FIELD,
    NUMBER_NOT_VALID,
    ACCEPTS_ONLY_TEXT,
    MAX_LENGTH_PHONE,
    MIN_LENGTH_PHONE,
  } = useFormsTypes();

  const { useSelectors } = useModels();
  const { useSelector, useUserSelectors } = useSelectors();
  const { usersSelector } = useUserSelectors();
  const users = useSelector(usersSelector);

  const { useQuickFunctions } = useHelpers();
  const { regexValidations } = useQuickFunctions();
  const regexText = regexValidations({ regex: 'text' });
  const regexNumber = regexValidations({ regex: 'number' });

  const schemaEditUser = yup.object({
    name: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(regexText, ACCEPTS_ONLY_TEXT),
    last_name: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(regexText, ACCEPTS_ONLY_TEXT),
    email: yup.string().email(EMAIL_NOT_VALID).required(REQUIRED_FIELD),
    city: yup.object().required(REQUIRED_FIELD).typeError(REQUIRED_FIELD),
    country: yup.object().required(REQUIRED_FIELD),
    department: yup.object().required(REQUIRED_FIELD).typeError(REQUIRED_FIELD),
    code: yup.object().required(REQUIRED_FIELD),
    gender: yup.string().notRequired(),
    number_phone: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(regexNumber, NUMBER_NOT_VALID)
      .max(15, MAX_LENGTH_PHONE)
      .min(8, MIN_LENGTH_PHONE),
  });

  const schemaEditContact = yup.object({
    name: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(regexText, ACCEPTS_ONLY_TEXT),
    last_name: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(regexText, ACCEPTS_ONLY_TEXT),
    email: yup.string().email(EMAIL_NOT_VALID).nullable(),
    code: yup.object().required(REQUIRED_FIELD),
    gender: yup.string().notRequired(),
    number_phone: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(regexNumber, NUMBER_NOT_VALID)
      .max(15, MAX_LENGTH_PHONE)
      .min(8, MIN_LENGTH_PHONE),
  });

  const selectUser = (id) => {
    return _.filter(users.data, (u) => `${u.id}` === `${id}`)[0];
  };

  const defaultValues = useMemo(() => {
    const user = selectUser(id);
    return {
      name: user?.name,
      last_name: user?.last_name,
      number_phone: user?.phone?.number,
      gender: user?.gender ?? '',
      email: user?.email,
      country: {
        label: user?.city?.department?.country?.name,
        value: user?.city?.department?.country?.id,
      },
      department: {
        label: user?.city?.department?.name,
        value: user?.city?.department?.id,
      },
      city: {
        label: user?.city?.name,
        value: user?.city?.id,
      },
      code: {
        label: user?.phone?.code?.code,
        value: user?.phone?.code?.id,
      },
      role: {
        label: user?.role?.name,
        value: user?.role?.id,
      },
      has_whatsapp: user?.has_whatsapp,
    };
  }, []);

  const editUserForm = useForm({
    resolver: yupResolver(contact ? schemaEditContact : schemaEditUser),
    mode: 'onChange',
    defaultValues,
  });

  const countrySelected = editUserForm.watch('country');
  const departmentSelected = editUserForm.watch('department');

  const { useGeneralHooks } = useControllers();
  const { useWhereLive } = useGeneralHooks();
  const {
    optionsCities,
    optionsCountries,
    optionsDepartments,
    optionsIndicatives,
  } = useWhereLive({ countrySelected, departmentSelected });

  const editUser = (user, e) => {
    e.preventDefault();
    user.id = id;
    user.role = user.role.value;
    user.phone_code = user.code.value;

    user.phone = user.number_phone;
    if (user.role !== 'co' && user.role !== 'ca') {
      user.country = user.country.value;
      user.city_id = user.city.value;
      user.department = user.department.value;
    } else {
      user.has_whatsapp = user.has_whatsapp === 'true';
    }
    delete user.city;
    delete user.code;
    delete user.department;
    delete user.country;

    dispatch(
      actUserEdit(
        { user },
        () => {
          editUserForm.reset();
          history.goBack();
        },
        (e) => {
          const { email, phone, name, gender } = e.response.data.errors;
          email &&
            editUserForm.setError('email', {
              type: 'manual',
              message: email[0],
            });
          phone &&
            editUserForm.setError('number_phone', {
              type: 'manual',
              message: phone[0],
            });
          name &&
            editUserForm.setError('name', {
              type: 'manual',
              message: name[0],
            });
          gender &&
            editUserForm.setError('gender', {
              type: 'manual',
              message: `${gender[0]}`.replaceAll('gender', 'género'),
            });
        }
      )
    );
  };

  return {
    history,
    selectUser,
    optionsDepartments,
    optionsCities,
    optionsIndicatives,
    optionsCountries,
    editUserForm,
    editUser,
  };
};

export default useEditUser;
