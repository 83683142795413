//Packages
import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

//Hooks
import useApi from '../../../../../../../api';

const useAdminAbcTrajectoryDashboardRoot = () => {
  const { project_id: projectId } = useParams();
  const history = useHistory();

  const [tabValue, setTabValue] = useState(0);

  const handleSelectTab = (tabIdx) => setTabValue(tabIdx);

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminAbcTrajectoryActions } = useAdminProjectsActions();
  const { actGetAbcTrajectory } = useAdminAbcTrajectoryActions();

  useEffect(() => {
    dispatch(actGetAbcTrajectory({ projectId }));
  }, []);

  const handleGoBack = () =>
    history.push(`/admin/projects/dashboard/${projectId}`);

  return { handleGoBack, tabValue, handleSelectTab };
};

export default useAdminAbcTrajectoryDashboardRoot;
