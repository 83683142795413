//Packages
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

//Hooks
import useControllers from '../../..';
import useApi from '../../../../api';
import useModels from '../../../../models';

//Assets
import imageFakeCard from '../../../../assets/img/fake-card-example.jpg';

const useDashboard = ({
  callEndpointProjects = false,
  textSearchValueAux,
} = {}) => {
  const history = useHistory();
  const [tabValue, setTabValue] = useState(0);
  const [textSearchValue, setTextSearchValue] = useState('');
  const [currentPageSearch, setCurrentPageSearch] = useState(1);

  const { useGeneralHooks } = useControllers();
  const { useGetUserProfile } = useGeneralHooks();
  const {
    role: { id: roleId },
  } = useGetUserProfile();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminProjectsRootActions } = useAdminProjectsActions();
  const { actGetProjects, actSetPageGetProjects } =
    useAdminProjectsRootActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminProjectsRootSelectors } = useAdminProjectsSelectors();
  const { projectsListSelector } = useAdminProjectsRootSelectors();
  const { projectsList } = useSelector(projectsListSelector);

  useEffect(() => {
    if (tabValue === 0 && roleId === 'ep' && callEndpointProjects) {
      if (textSearchValueAux) {
        dispatch(
          actGetProjects({
            paginate: 1,
            page: currentPageSearch,
            name: textSearchValueAux,
          })
        );
      } else {
        dispatch(
          actGetProjects({
            paginate: 1,
            page: projectsList.page,
            name: textSearchValueAux,
          })
        );
        setCurrentPageSearch(1);
      }
    }
  }, [tabValue, textSearchValueAux]);

  const handleSelectTab = (tabIdx) => setTabValue(tabIdx);

  const handleGoToDashboardById = ({ projectId }) =>
    history.push(`/admin/projects/dashboard/${projectId}`);

  const handleChangePage = ({ selected }) => {
    dispatch(
      actGetProjects({
        paginate: 1,
        page: selected + 1,
        name: textSearchValueAux,
      })
    );
    if (textSearchValueAux) {
      setCurrentPageSearch(selected + 1);
    } else {
      dispatch(
        actSetPageGetProjects({
          page: selected + 1,
        })
      );
      setCurrentPageSearch(1);
    }
  };

  const handleGetValueTextSearch = (text) => {
    setTextSearchValue(text);
  };

  return {
    roleId,
    projectsList,
    imageFakeCard,
    tabValue,
    handleSelectTab,
    handleGoToDashboardById,
    handleChangePage,
    currentPage: projectsList.page,
    handleGetValueTextSearch,
    textSearchValue,
    currentPageSearch,
  };
};

export default useDashboard;
