import React from 'react';

const BottomHome = React.lazy(() => import('./BottomHome'));
const Filters = React.lazy(() => import('./Filters'));
const Slider = React.lazy(() => import('./Slider'));

const useHomeComponents = () => {
  return { BottomHome, Filters, Slider };
};

export default useHomeComponents;
