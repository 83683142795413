//Packages
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';

//Hooks
import useApi from 'api';
import useModels from 'models';
import useControllers from 'controllers';
import useStrings from 'strings';
import useHelpers from 'helpers';

const useArtistDefineInterventionInterventions = () => {
  const [deleteInterventionFileActive, setDeleteInterventionFileActive] =
    useState('');

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions, useAdminInterventionsRootActions } =
    useAdminInterventionsActions();
  const { actResetInterventionDefinitionId, actSetInterventionDefinitionId } =
    useAdminInterventionsRootActions();
  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const { useAdminDevelopmentInterventionDefineInterventionActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const { actAddInterventionDefinitionArtist, actDeleteInterventionById } =
    useAdminDevelopmentInterventionDefineInterventionActions();

  const { intervention_id } = useParams();

  const { useSelectors } = useModels();
  const { useAdminSelectors, useSelector } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionsSelector, interventionDefinitionIdSelectedSelector } =
    useAdminInterventionsSelectors();
  const { define_intervention } = useSelector(getInterventionsSelector);
  const { id: interventionIdToDeleteEdit } = useSelector(
    interventionDefinitionIdSelectedSelector
  );

  const { useGeneralHooks } = useControllers();
  const { useToggleModal } = useGeneralHooks();
  const [showCreateIntervenForm, handleVisiblityIntervenForm] =
    useToggleModal();
  const [showDeleteIntervenModal, handleVisiblityIntervenDeleteModal] =
    useToggleModal();
  const [showAtLestOneActivityModal, handleVisiblityAtLestOneActivityModal] =
    useToggleModal();

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const {
    REQUIRED_FIELD,
    FILE_SIZE_5MB,
    FILE_TYPE,
    MAX_LENGTH_500,
    MAX_LENGTH_100,
  } = useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const { getFileValidations } = useQuickFunctions();
  const { MAX_SIZE_5MB } = getFileValidations();

  const schemaDefineInterventionArtist = yup.object({
    name: yup.string().required(REQUIRED_FIELD).max(100, MAX_LENGTH_100),
    target: yup.string().required(REQUIRED_FIELD).max(500, MAX_LENGTH_500),
    modality: yup.object().required(REQUIRED_FIELD).nullable(),
    duration: yup
      .string()
      .required(REQUIRED_FIELD)
      .test('maxHour', 'El tiempo maximo permitido es 04:59', (value) => {
        return value && parseInt(value.split(':')[0]) < 5;
      }),
    methodology_file: yup
      .mixed()
      .test('fileType', FILE_TYPE, (value) => {
        if (value) {
          return 'application/pdf' === value.type;
        }
        return true;
      })
      .test('fileSize', FILE_SIZE_5MB, (value) => {
        if (value.size && value) {
          return value.size <= MAX_SIZE_5MB;
        }
        return true;
      })
      .required(REQUIRED_FIELD),
  });

  const defaultInternventionValuesEditMode = useMemo(() => {
    if (interventionIdToDeleteEdit) {
      const interventionToEdit = _.find(
        define_intervention.definition_interventions,
        (intervention) =>
          parseInt(intervention.id) === parseInt(interventionIdToDeleteEdit)
      );

      return interventionToEdit;
    }
    return {};
  }, [interventionIdToDeleteEdit, showCreateIntervenForm]);

  const formCreateIntervention = useForm({
    resolver: yupResolver(schemaDefineInterventionArtist),
    mode: 'onChange',
  });

  useEffect(() => {
    if (interventionIdToDeleteEdit && showCreateIntervenForm) {
      formCreateIntervention.setValue(
        'name',
        defaultInternventionValuesEditMode?.name
      );
      formCreateIntervention.setValue(
        'target',
        defaultInternventionValuesEditMode?.target
      );
      formCreateIntervention.setValue(
        'duration',
        defaultInternventionValuesEditMode?.duration.slice(0, 5)
      );
      formCreateIntervention.setValue('modality', {
        label: defaultInternventionValuesEditMode?.modality?.name,
        value: defaultInternventionValuesEditMode?.modality?.id,
      });
      formCreateIntervention.setValue('methodology_file', {
        file: defaultInternventionValuesEditMode?.methodology_file?.url,
        type: 'application/pdf',
      });
    }
  }, [showCreateIntervenForm, interventionIdToDeleteEdit]);

  useEffect(() => {
    handleLogicToOpenLastInterCreated();
  }, [define_intervention.definition_interventions.length]);

  const isInterventionEditButtonDisabled = useMemo(() => {
    if (
      interventionIdToDeleteEdit &&
      defaultInternventionValuesEditMode?.name &&
      formCreateIntervention.getValues()?.name
    ) {
      const objectInternventionEditMode = {
        name: defaultInternventionValuesEditMode.name,
        target: defaultInternventionValuesEditMode.target,
        duration: defaultInternventionValuesEditMode.duration.slice(0, 5),
        modality: defaultInternventionValuesEditMode.modality.id,
        methodology_file:
          defaultInternventionValuesEditMode.methodology_file.url,
      };

      const objectCurrentInterventEditMode = {
        ...formCreateIntervention.getValues(),
        modality: formCreateIntervention.watch('modality').value,
        methodology_file: formCreateIntervention.formState.dirtyFields
          .methodology_file
          ? formCreateIntervention.watch('methodology_file')
          : formCreateIntervention.watch('methodology_file')?.file,
      };

      return _.isEqual(
        objectInternventionEditMode,
        objectCurrentInterventEditMode
      );
    }
    return false;
  }, [
    formCreateIntervention.watch('name'),
    formCreateIntervention.watch('target'),
    formCreateIntervention.watch('duration'),
    formCreateIntervention.watch('modality'),
    formCreateIntervention.watch('methodology_file'),
    defaultInternventionValuesEditMode?.name,
  ]);

  const handleResetInterventionIdToDeleteEdit = () => {
    dispatch(actResetInterventionDefinitionId());
  };

  useEffect(() => {
    return () => {
      handleResetInterventionIdToDeleteEdit();
    };
  }, []);

  const handleCancelEditCreateIntervention = () => {
    handleVisiblityIntervenForm();
    formCreateIntervention.setValue('name', '');
    formCreateIntervention.setValue('target', '');
    formCreateIntervention.setValue('duration', '');
    formCreateIntervention.setValue('modality', '');
    formCreateIntervention.setValue('methodology_file', '');
    setDeleteInterventionFileActive(false);
  };

  const handleAddIntervention = (dataAux) => {
    const data = interventionIdToDeleteEdit
      ? formCreateIntervention.formState.dirtyFields
      : dataAux;

    const bodyFormData = new FormData();
    data.name && bodyFormData.append('name', dataAux.name);
    data.target && bodyFormData.append('target', dataAux.target);
    data.modality && bodyFormData.append('modality', dataAux.modality.value);
    data.duration && bodyFormData.append('duration', dataAux.duration);
    data.methodology_file &&
      bodyFormData.append('methodology_file', dataAux.methodology_file);
    bodyFormData.append(
      '_method',
      interventionIdToDeleteEdit ? 'PATCH' : 'POST'
    );

    dispatch(
      actAddInterventionDefinitionArtist(
        {
          intervention_id,
          definition_id: define_intervention.id,
          formData: bodyFormData,
          definitionIdToEdit: interventionIdToDeleteEdit || null,
        },
        () => {
          handleCancelEditCreateIntervention();
        }
      )
    );
  };

  const handleToDeleteAnIntervention = () => {
    dispatch(
      actDeleteInterventionById(
        {
          intervention_id,
          definition_id: define_intervention.id,
          definitionIdToDelete: interventionIdToDeleteEdit,
        },
        () => {
          handleVisiblityIntervenDeleteModal();
        }
      )
    );
  };

  const handleSelectAnInterventionToDelete = ({ interventionId } = {}) => {
    interventionId &&
      dispatch(actSetInterventionDefinitionId({ id: interventionId }));
    handleVisiblityIntervenDeleteModal();
  };

  const handleSelectAnInterventionToEdit = ({ interventionId } = {}) => {
    interventionId &&
      dispatch(actSetInterventionDefinitionId({ id: interventionId }));
    handleVisiblityIntervenForm();
  };

  const handleDeleteInterventionFile = (e) => {
    e.preventDefault();
    formCreateIntervention.setValue('methodology_file', null);
    setDeleteInterventionFileActive(true);
  };

  const handleSelectAnInterventionActivityToCreate = ({ interventionId }) => {
    dispatch(actSetInterventionDefinitionId({ id: interventionId }));
  };

  const isNextButtonActive = useMemo(() => {
    const lengthDefineInterventions =
      define_intervention.definition_interventions.length;
    const defineInterventionsWithActivies = _.filter(
      define_intervention.definition_interventions,
      (intervention) => intervention.activities.length > 0
    );

    return lengthDefineInterventions === 0
      ? false
      : defineInterventionsWithActivies.length === lengthDefineInterventions;
  }, [define_intervention.definition_interventions]);

  const handleLogicToOpenLastInterCreated = () => {
    define_intervention.definition_interventions.length > 0 &&
      dispatch(
        actSetInterventionDefinitionId({
          id: define_intervention.definition_interventions[
            define_intervention.definition_interventions.length - 1
          ].id,
        })
      );
  };

  return {
    //Data step define interventions
    define_intervention,

    //Create intervention
    handleAddIntervention,
    handleToDeleteAnIntervention,
    handleSelectAnInterventionToDelete,
    handleSelectAnInterventionToEdit,
    formCreateIntervention,
    defaultInternventionValuesEditMode,
    interventionIdToDeleteEdit,
    deleteInterventionFileActive,
    isInterventionEditButtonDisabled,

    showCreateIntervenForm,
    handleVisiblityIntervenForm,
    showDeleteIntervenModal,
    handleVisiblityIntervenDeleteModal,
    handleCancelEditCreateIntervention,
    handleDeleteInterventionFile,
    showAtLestOneActivityModal,
    handleVisiblityAtLestOneActivityModal,

    handleSelectAnInterventionActivityToCreate,
    handleResetInterventionIdToDeleteEdit,
    isNextButtonActive,
    handleLogicToOpenLastInterCreated,
  };
};

export default useArtistDefineInterventionInterventions;
