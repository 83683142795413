//Packages
import { useHistory, useLocation } from 'react-router';
import { useState } from 'react';

//Hooks
import useControllers from '../../..';

const useHeaderButtons = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { useGeneralHooks } = useControllers();
  const { useIsAuth } = useGeneralHooks();
  const { isAuth } = useIsAuth();
  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };

  const goToHome = () => history.push('/');

  const showLoginButton = !isAuth && pathname !== '/login';

  return { isAuth, showLoginButton, handleOpenMenu, openMenu, goToHome };
};

export default useHeaderButtons;
