// Packages
import tw from 'twin.macro';

const useInputsStyles = () => {
  const stateFormStylesTexts = (states) => {
    if (states.success) {
      return tw`text-success`;
    } else if (states.errors) {
      return tw`text-error`;
    }
  };

  const stateFormStylesBg = (states) => {
    if (states.success) {
      return tw`bg-success`;
    } else if (states.errors) {
      return tw`bg-error`;
    } else if (states.disabled) {
      return tw`bg-neutral`;
    }
  };

  const stateFormStylesBorder = (states) => {
    if (states.success) {
      return tw`border-success`;
    } else if (states.errors) {
      return tw`border-error`;
    } else if (states.disabled) {
      return tw`border-neutral`;
    }
  };

  const stateFormStylesOutline = (states) => {
    if (states.success) {
      return `outline: 3px solid var(--success-100);`;
    } else if (states.errors) {
      return `outline: 3px solid var(--error-100);`;
    } else if (states.disabled) {
      return `outline: 3px solid var(--primary-100);`;
    }
  };

  return {
    text: stateFormStylesTexts,
    bg: stateFormStylesBg,
    border: stateFormStylesBorder,
    outline: stateFormStylesOutline,
  };
};

export default useInputsStyles;
