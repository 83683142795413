import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Hooks
import useViews from 'views';

//Styles
const StyledList = lazy(() =>
  import('./CustomLegend.styles').then((mod) => ({
    default: mod.StyledList,
  }))
);
const StyledIcon = lazy(() =>
  import('./CustomLegend.styles').then((mod) => ({
    default: mod.StyledIcon,
  }))
);

const CustomLegend = (props) => {
  const { payload, typeOfMessage } = props;

  const { useComponents } = useViews();
  const { useBadges } = useComponents();
  const { DefaultBadge } = useBadges();

  return (
    <ul
      className={
        typeOfMessage === 'd'
          ? 'grid gap-4 grid-cols-1'
          : 'grid gap-1 grid-cols-1 2xl:gap-4 2xl:grid-cols-2 '
      }
    >
      {_.map(payload?.payload, (entry, index) => (
        <StyledList color={entry.color} key={`item-${index}`}>
          <StyledIcon />
          <p>{entry.value}</p>
          <DefaultBadge
            type="light"
            className="ml-6"
            label={`${entry.payload.answers} ${
              entry.payload.answers === 1
                ? typeOfMessage === 'd'
                  ? 'mensaje'
                  : 'respuesta'
                : typeOfMessage === 'd'
                ? 'mensajes'
                : 'respuestas'
            } `}
          />
        </StyledList>
      ))}
    </ul>
  );
};

CustomLegend.defaultProps = {
  payload: {},
  typeOfMessage: 'd',
};
CustomLegend.propTypes = {
  payload: PropTypes.object,
  typeOfMessage: PropTypes.oneOf(['gi', 'd']),
};

export default CustomLegend;
