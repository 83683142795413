//Packages
import React, { lazy } from 'react';
import { useHistory } from 'react-router';
import _ from 'lodash';

//Assets
import imagePin from '../../../../../assets/icons/map-pin-white.png';
import imageFacebook from '../../../../../assets/icons/facebook-white.svg';
import imageTwiter from '../../../../../assets/icons/twitter-white.svg';
import imageInstagram from '../../../../../assets/icons/Instagram-white.svg';
import imageLinkedin from '../../../../../assets/icons/linkedin-white.svg';
import imageYoutube from '../../../../../assets/icons/youtube-white.svg';
import imageWebSite from '../../../../../assets/icons/web-site.svg';
import imageFakeCard from '../../../../../assets/img/fake-card-example.jpg';

//Hooks
import useViews from '../../../..';
import useControllers from '../../../../../controllers';
import useModels from '../../../../../models';
import useHelpers from '../../../../../helpers';

//Switch social media icons
const selectIcon = {
  fcb: imageFacebook,
  int: imageInstagram,
  tw: imageTwiter,
  lin: imageLinkedin,
  you: imageYoutube,
};

const StyledInformationCollapse = lazy(() =>
  import('../componentsProfile.styles').then((mod) => ({
    default: mod.StyledInformationCollapse,
  }))
);

const StyledContentGroup = lazy(() =>
  import('../componentsProfile.styles').then((mod) => ({
    default: mod.StyledContentGroup,
  }))
);

const StyledCollapsedInfo = lazy(() =>
  import('../componentsProfile.styles').then((mod) => ({
    default: mod.StyledCollapsedInfo,
  }))
);
const StyledCollapsed = lazy(() =>
  import('../componentsProfile.styles').then((mod) => ({
    default: mod.StyledCollapsed,
  }))
);

const StyledContentTypes = lazy(() =>
  import('./Artist.styles').then((mod) => ({
    default: mod.StyledContentTypes,
  }))
);

const StyledSocialNetworks = lazy(() =>
  import('./Artist.styles').then((mod) => ({
    default: mod.StyledSocialNetworks,
  }))
);

const Artist = () => {
  const { push } = useHistory();

  const { useComponents } = useViews();
  const { useImages, useTypographies, useBadges, useButtons } = useComponents();

  const { Image } = useImages();
  const { TypographyComponent } = useTypographies();
  const { DefaultBadge } = useBadges();
  const { ButtonComponent } = useButtons();

  const { useGeneralHooks } = useControllers();
  const { useGetUserProfile, useCollapseAccordion } = useGeneralHooks();
  const {
    avatar,
    name,
    last_name,
    role,
    city,
    email,
    phone,
    language,
    intervention_type,
    about_me_body,
    specialties,
    networks,
    gender,
    website,
  } = useGetUserProfile();
  const { refCollapsed, collapsed, clienteHei, handleOpenCollapse } =
    useCollapseAccordion();

  const { useSelectors } = useModels();
  const { useSelector, useDashboardSelectors } = useSelectors();
  const { artistInterventionsSelector } = useDashboardSelectors();
  const { numberInterventions } = useSelector(artistInterventionsSelector);

  const { useQuickFunctions } = useHelpers();
  const { showCityDepartmentAndCountry } = useQuickFunctions();

  return (
    <>
      <Image
        variant="rounded"
        srcImage={avatar ?? imageFakeCard}
        altImage={'Alt'}
        titleImage={'Title'}
        className="w-22 h-22 lg:-mt-20 -mt-8 mx-auto relative"
      />
      <TypographyComponent
        className="text-white font-bold flex items-center justify-center lg:justify-start mt-4"
        text={
          <>
            {name} {last_name}
            <DefaultBadge
              className="text-success-950 ml-2 bg-secondary-50"
              variant="success"
              label={`${numberInterventions}`}
              colorLevel="300"
            />
          </>
        }
        variant="h3"
      />
      <TypographyComponent
        className="text-white flex items-center justify-center lg:justify-start my-1 "
        text={role?.name}
        variant="p"
      />
      <TypographyComponent
        className="text-white flex items-center justify-center lg:justify-start mt-2 lg:mt-0 font-bold"
        variant="h5"
        text={
          <>
            <Image
              srcImage={imagePin}
              altImage="location"
              titleImage="location"
              className="location-icon"
            />
            {showCityDepartmentAndCountry({ city, format: 'countryCity' })}
          </>
        }
      />

      <StyledCollapsedInfo isCollapsed={collapsed} onClick={handleOpenCollapse}>
        Ver perfil completo
      </StyledCollapsedInfo>

      <StyledCollapsed isCollapsed={collapsed} clientHei={clienteHei}>
        <StyledInformationCollapse ref={refCollapsed}>
          <StyledContentGroup>
            <TypographyComponent
              className="text-primary-100 flex items-center mb-1"
              text={'Email'}
              variant="h5"
            />
            <TypographyComponent
              className="text-white flex items-center"
              text={email}
              variant="p"
            />
          </StyledContentGroup>

          <StyledContentGroup>
            <TypographyComponent
              className="text-primary-100 flex items-center mb-1"
              text={'Celular'}
              variant="h5"
            />
            <TypographyComponent
              className="text-white flex items-center"
              text={`${phone.code.code} ${phone.number}`}
              variant="p"
            />
          </StyledContentGroup>

          {gender && (
            <StyledContentGroup>
              <TypographyComponent
                className="text-primary-100 flex items-center mb-1"
                text={'Género'}
                variant="h5"
              />
              <TypographyComponent
                className="text-white flex items-center"
                text={gender}
                variant="p"
              />
            </StyledContentGroup>
          )}

          <StyledContentGroup>
            <TypographyComponent
              className="text-primary-100 flex items-center mb-1"
              text={'Idioma'}
              variant="h5"
            />
            <TypographyComponent
              className="text-white flex items-center"
              text={language}
              variant="p"
            />
          </StyledContentGroup>

          <StyledContentGroup>
            <TypographyComponent
              className="text-primary-100 flex items-center mb-1"
              text={'Redes sociales'}
              variant="h5"
            />
            {networks.length > 0 && (
              <StyledSocialNetworks>
                {_.map(networks, (socialMedia) => {
                  const icon = selectIcon[socialMedia.type.id.toLowerCase()];
                  if (icon) {
                    return (
                      <Image
                        key={`socialMedia-${socialMedia.id}`}
                        srcImage={icon}
                        onClick={() => window.open(socialMedia.url)}
                      />
                    );
                  }
                })}
              </StyledSocialNetworks>
            )}
          </StyledContentGroup>

          <StyledContentGroup>
            <TypographyComponent
              className="text-primary-100 flex items-center mb-1"
              text={'Experiencia artística'}
              variant="h5"
            />
            <StyledContentTypes>
              {_.map(specialties, (item) => (
                <DefaultBadge
                  key={item.id}
                  className="text-success-950 mr-1 "
                  variant="primary"
                  type="light"
                  label={item.name}
                />
              ))}
            </StyledContentTypes>
          </StyledContentGroup>

          <StyledContentGroup>
            <TypographyComponent
              className="text-primary-100  mb-1 flex items-center"
              text={
                <>
                  <Image
                    srcImage={imageWebSite}
                    altImage="web site"
                    titleImage="web site"
                  />
                  <a
                    className="ml-1 text-info-400 text-base "
                    href={website}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Visitar mi sitio web
                  </a>
                </>
              }
              variant="h5"
            />
          </StyledContentGroup>

          {intervention_type?.name && (
            <StyledContentGroup>
              <TypographyComponent
                className="text-primary-100 flex items-center mb-1"
                text={'Tipo de intervención preferida'}
                variant="h5"
              />
              <TypographyComponent
                className="text-white flex items-center"
                text={intervention_type.name}
                variant="p"
              />
            </StyledContentGroup>
          )}
          <StyledContentGroup>
            <TypographyComponent
              className="text-primary-100 flex items-center mb-1"
              text={'Mi trabajo'}
              variant="h5"
            />
            <TypographyComponent
              className="text-white flex items-center"
              text={about_me_body}
              variant="p"
            />
          </StyledContentGroup>
        </StyledInformationCollapse>
      </StyledCollapsed>

      <ButtonComponent
        mode="outline"
        className="mx-auto mt-10 mb-4"
        variant="white"
        text="Editar Perfil"
        onPress={() => push('/dashboard/profile')}
      />
    </>
  );
};

export default Artist;
