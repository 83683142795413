import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

//Hooks
import useViews from 'views';

//Styles
const StyledGroup = lazy(() =>
  import(
    '../../../../../../../../../../../../../../styles/formViews/formViews.styles'
  ).then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const FormPilot = (props) => {
  const {
    handleNext,
    optionsCommunities,
    optionsModality,
    handleSubmit,
    control,
    errors,
    canEditForm,
  } = props;

  const { useComponents } = useViews();
  const { useInputs, useLabels, useButtons } = useComponents();
  const { Select, InputForm, InputHoursMinutes } = useInputs();
  const { DefaultLabel } = useLabels();
  const { ButtonComponent } = useButtons();

  return (
    <form>
      <StyledGroup col2 className="mb-4">
        <Select
          name="community"
          id="communities"
          placeholder="Selecciona una comunidad"
          control={control}
          options={optionsCommunities}
          errors={errors}
          label="¿En qué comunidad vas a realizar el piloto?*"
          className="w-full"
          disabled={!canEditForm}
        />
      </StyledGroup>
      <StyledGroup col2 className="mb-4" noSpace>
        <Select
          name="modality"
          id="communities"
          placeholder="Selecciona la modalidaad"
          control={control}
          options={optionsModality}
          errors={errors}
          label="Modalidad*"
          className="w-full"
          disabled={!canEditForm}
        />
        <InputForm
          name="date"
          label="Fecha*"
          type="date"
          placeholder="Selecciona la fecha"
          className="w-full"
          control={control}
          errors={errors}
          disabled={!canEditForm}
        />
      </StyledGroup>
      <StyledGroup col2 className="mb-4" noSpace>
        <div className="w-full">
          <DefaultLabel>Horario*</DefaultLabel>
          <div className="flex space-x-8">
            <Controller
              name={'start_time'}
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <InputHoursMinutes
                  name={name}
                  type="time"
                  placeholder="Hora inicio"
                  className="w-full"
                  errors={errors}
                  onChange={onChange}
                  defaultValue={value}
                  disabled={!canEditForm}
                />
              )}
            />
            <Controller
              name={'end_time'}
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <InputHoursMinutes
                  name={name}
                  type="time"
                  placeholder="Hora fin"
                  className="w-full"
                  errors={errors}
                  onChange={onChange}
                  defaultValue={value}
                  disabled={!canEditForm}
                />
              )}
            />
          </div>
        </div>
        <InputForm
          name="participants"
          label="Número de participantes*"
          placeholder="Ingresa el número de participantes"
          type="number"
          className="w-full"
          control={control}
          errors={errors}
          disabled={!canEditForm}
        />
      </StyledGroup>
      <div className="flex items-center space-x-2 mt-10 mb-6 justify-center">
        <ButtonComponent text="Siguiente" onPress={handleSubmit(handleNext)} />
      </div>
    </form>
  );
};

FormPilot.propTypes = {
  handleNext: PropTypes.func,
  optionsCommunities: PropTypes.array,
  optionsModality: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  canEditForm: PropTypes.bool,
};

export default FormPilot;
