//Hooks
import useHelpers from '../../../../helpers';
import useStrings from '../../../../strings';
import useInitialStates from '../../../initialStates';

const useAccessibilityReducers = () => {
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  //Initial States
  const { useGeneralInitialStates } = useInitialStates();
  const { useAccessibilityInitialStates } = useGeneralInitialStates();
  const { initialStateAccessibilities } = useAccessibilityInitialStates();

  //Strings
  const { useGeneralTypes } = useStrings();
  const { useAccessibilityTypes } = useGeneralTypes();
  const { GET_ACCESSIBILITIES } = useAccessibilityTypes();

  const accessibilities = createReducer(initialStateAccessibilities, {
    [GET_ACCESSIBILITIES](state, action) {
      return [...action.payload];
    },
  });
  return { accessibilities };
};

export default useAccessibilityReducers;
