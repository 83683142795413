const useAdminProjectsRootInitialStates = () => {
  const initialStateProjectsList = {
    data: [],
    meta: {
      current_page: '',
      last_page: '',
      total: '',
      per_page: '',
    },
    links: {
      next: '',
      prev: '',
    },
    page: 1,
  };

  const initialStateProjectFilter = {
    country_id: '',
    department_id: '',
    city_id: '',
    project_manager_id: '',
    social_expert_id: '',
    executing_partner_id: '',
    owner_id: '',
    name: '',
    orderByKey: '',
    orderByValue: '',
    page: 1,
  };

  const initialStateProjectSelected = {
    name: '',
    population_number: '',
    image: { url: '' },
    city: { name: '', department: { name: '', country: { name: '' } } },
    executing_partner: {
      city: { name: '', department: { name: '', country: { name: '' } } },
    },
    project_manager: {
      city: { name: '', department: { name: '', country: { name: '' } } },
    },
    description: '',
    social_expert: {
      city: { name: '', department: { name: '', country: { name: '' } } },
    },
    languages: [],
    social_cultural_info: [],
    festivities: [],
    trajectories: [],
    recommendations: [],
    additional_info: { id: '', text: '', multimedias: [] },
    annexes: [],
    dashboard: {
      interventions: {
        active: {
          inspire: 0,
          activate: 0,
          hold: 0,
        },
        completed: {
          inspire: 0,
          activate: 0,
          hold: 0,
        },
      },
      communities_members: {
        community: 0,
        changed_agent: 0,
      },
      communities: 0,
    },
  };

  return {
    initialStateProjectsList,
    initialStateProjectFilter,
    initialStateProjectSelected,
  };
};

export default useAdminProjectsRootInitialStates;
