//Packages
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

//Hooks
import useControllers from 'controllers';
import useApi from 'api';
import useModels from 'models';

const useAdminFilterProject = ({ handleChangeStateMenu, setCurrentPage }) => {
  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminProjectsRootActions } = useAdminProjectsActions();
  const {
    actGetProjects,
    actSetDataProjectFilter,
    actResetDataProjectFilterWithoutName,
  } = useAdminProjectsRootActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminProjectsRootSelectors } = useAdminProjectsSelectors();
  const { projectFilterSelector } = useAdminProjectsRootSelectors();
  const dataFilter = useSelector(projectFilterSelector);

  const schemaFilterProjects = yup.object({
    country_id: yup.object().nullable(),
    department_id: yup.object().nullable(),
    city_id: yup.object().nullable(),
    project_manager_id: yup.object().nullable(),
    social_expert_id: yup.object().nullable(),
    executing_partner_id: yup.object().nullable(),
    owner_id: yup.object().nullable(),
  });

  const {
    control,
    formState: { errors, dirtyFields },
    watch,
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schemaFilterProjects),
    defaultValues: dataFilter,
  });

  const countrySelected = watch('country_id');
  const departmentSelected = watch('department_id');

  const { useGeneralHooks } = useControllers();
  const { useWhereLive, useGetOptionsList } = useGeneralHooks();
  const { optionsCities, optionsCountries, optionsDepartments } = useWhereLive({
    countrySelected,
    departmentSelected,
  });
  const { optionsPartnerUsers, optionsAdminUsers } = useGetOptionsList({
    callEndpointUsers: true,
  });

  useEffect(() => {
    if (dirtyFields.country_id || !countrySelected) {
      setValue('department_id', null);
      setValue('city_id', null);
    }
  }, [countrySelected]);

  useEffect(() => {
    if (dirtyFields.department_id || !departmentSelected) {
      setValue('city_id', null);
    }
  }, [departmentSelected]);

  const handleResetFilterProject = (e) => {
    e.preventDefault();
    reset({
      country_id: '',
      department_id: '',
      city_id: '',
      project_manager_id: '',
      social_expert_id: '',
      executing_partner_id: '',
      owner_id: '',
    });
    dispatch(
      actGetProjects({
        name: dataFilter.name,
        orderByKey: dataFilter.orderByKey,
        orderByValue: dataFilter.orderByValue,
        page: dataFilter.page,
      })
    );
    setCurrentPage && setCurrentPage({ page: dataFilter.page });
    dispatch(actResetDataProjectFilterWithoutName());
    handleChangeStateMenu();
  };

  const onSuccessHandleFilterProject = () => {
    handleChangeStateMenu();
    setCurrentPage && setCurrentPage({ page: 1 });
  };

  const handleFilterProject = (data) => {
    const {
      country_id,
      department_id,
      city_id,
      project_manager_id,
      social_expert_id,
      executing_partner_id,
      owner_id,
    } = data;
    dispatch(
      actGetProjects(
        {
          country_id: country_id?.value,
          department_id: department_id?.value,
          city_id: city_id?.value,
          project_manager_id: project_manager_id?.value,
          social_expert_id: social_expert_id?.value,
          executing_partner_id: executing_partner_id?.value,
          owner_id: owner_id?.value,
          name: dataFilter.name,
          orderByKey: dataFilter.orderByKey,
          orderByValue: dataFilter.orderByValue,
        },
        onSuccessHandleFilterProject
      )
    );
  };

  const handleSetValueFilter = (data) => {
    const { data: dataSelect, inputName } = data;
    dispatch(actSetDataProjectFilter({ key: inputName, value: dataSelect }));
  };

  return {
    control,
    errors,
    handleSubmit,
    optionsCities,
    optionsCountries,
    optionsDepartments,
    optionsAdminUsers,
    optionsPartnerUsers,
    handleFilterProject,
    handleResetFilterProject,
    countrySelected,
    departmentSelected,
    handleSetValueFilter,
  };
};

export default useAdminFilterProject;
