//Packages
import React, { Suspense, lazy } from 'react';

//hooks
import useViews from '../..';

import imageTopAbputRoles from '../../../assets/img/img-about-roles.png';
import imageArtist from '../../../assets/img/img-about-role-artist.png';
import imageSocio from '../../../assets/img/img-about-role-socio.png';

const StyledContentAbout = lazy(() =>
  import('./AboutRoles.styles').then((mod) => ({
    default: mod.StyledContentAbout,
  }))
);
const StyledSectionTop = lazy(() =>
  import('./AboutRoles.styles').then((mod) => ({
    default: mod.StyledSectionTop,
  }))
);

const StyledCardContet = lazy(() =>
  import('./AboutRoles.styles').then((mod) => ({
    default: mod.StyledCardContet,
  }))
);

const AboutRoles = () => {
  const { useLayouts, useComponents } = useViews();
  const { MainLayout } = useLayouts();

  const { useCards, useTypographies, useImages, useButtons, useLoaders } =
    useComponents();
  const { DefaultLoader } = useLoaders();

  const { TypographyComponent } = useTypographies();
  const { CardInformation } = useCards();
  const { Image } = useImages();
  const { ButtonComponent } = useButtons();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <MainLayout hasDecorationMap>
        <StyledContentAbout>
          <StyledSectionTop>
            <Image
              titleImage="¿Cómo puedes hacer parte de este programa?"
              altImage="¿Cómo puedes hacer parte de este programa?"
              srcImage={imageTopAbputRoles}
              className="lg:absolute lg:right-0 lg:w-96 lg:bottom-0 lg:-mb-16 -mt-20 mb-10 w-8/12 mx-auto"
            />
            <TypographyComponent
              className="font-bold lg:pr-80 lg:w-8/12 text-xl"
              variant="h1"
              text="Creémos juntos"
            />
            <TypographyComponent
              variant="h2"
              className="font-bold lg:pr-44 lg:w-8/12 text-lg"
              text={
                <>
                  ¿Cómo puedes{' '}
                  <span className="text-primary ">
                    hacer parte de este programa?
                  </span>
                </>
              }
            />
            <TypographyComponent
              className="lg:pr-96 lg:w-8/12 mt-3"
              variant="p"
              text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
            />
          </StyledSectionTop>
          <StyledCardContet>
            <CardInformation
              image={imageArtist}
              title="Artista"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel iaculis purus, id pretium tortor. Praesent dapibus ligula quam, a bibendum purus consequat in. Suspendisse non nunc sit amet orci dictum sollicitudin. Pellentesque in arcu nec nibh aliquam mattis et vitae ligula. Praesent sollicitudin libero viverra ullamcorper mattis. Quisque tristique nisi eu tristique lobortis. Donec aliquam auctor iaculis. Sed ac justo lectus. Maecenas ac blandit ligula. Curabitur lacus tellus, congue nec finibus non, gravida at arcu. Ut vel facilisis felis."
            />
            <CardInformation
              image={imageSocio}
              title="Socio Ejecutor"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel iaculis purus, id pretium tortor. Praesent dapibus ligula quam, a bibendum purus consequat in. Suspendisse non nunc sit amet orci dictum sollicitudin. Pellentesque in arcu nec nibh aliquam mattis et vitae ligula. Praesent sollicitudin libero viverra ullamcorper mattis. Quisque tristique nisi eu tristique lobortis. Donec aliquam auctor iaculis. Sed ac justo lectus. Maecenas ac blandit ligula. Curabitur lacus tellus, congue nec finibus non, gravida at arcu. Ut vel facilisis felis."
            />
          </StyledCardContet>
        </StyledContentAbout>
        <ButtonComponent
          className="mx-auto mt-14 "
          text="¡Quiero hacer parte del programa!"
          onPress={() => {}}
        />
      </MainLayout>
    </Suspense>
  );
};

export default AboutRoles;
