//Packages
import { useEffect } from 'react';

//Hooks
import useApi from 'api';
import useModels from 'models';

const useIsFilterEmpty = () => {
  const { useSelectors } = useModels();
  const { useSelector, useGeneralSelectors } = useSelectors();
  const { useIsFilterEmptySelectors } = useGeneralSelectors();
  const { isFilterEmptySelector } = useIsFilterEmptySelectors();
  const isFilterEmpty = useSelector(isFilterEmptySelector);

  const { useActions } = useApi();
  const { dispatch, useGeneralActions } = useActions();
  const { useIsFilterEmptyActions } = useGeneralActions();
  const { actResetFilterEmpty, actSetFilterEmpty } = useIsFilterEmptyActions();

  useEffect(() => {
    return () => {
      handleResetFilterEmpty();
    };
  }, []);

  const handleSetFilterEmpty = () => {
    dispatch(actSetFilterEmpty());
  };

  const handleResetFilterEmpty = () => {
    dispatch(actResetFilterEmpty());
  };

  return { isFilterEmpty, handleSetFilterEmpty, handleResetFilterEmpty };
};

export default useIsFilterEmpty;
