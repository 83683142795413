import useAdminViewInterventionsCreateCollectionInformation from './createCollectionInformation';
import useAdminViewInterventionsGetCollectionInformation from './getCollectionInformation';

const useAdminViewInterventionsCollectionInformationControllers = () => {
  return {
    useAdminViewInterventionsCreateCollectionInformation,
    useAdminViewInterventionsGetCollectionInformation,
  };
};

export default useAdminViewInterventionsCollectionInformationControllers;
