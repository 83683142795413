import React from 'react';
import PropTypes from 'prop-types';

// Hooks
import useViews from 'views';

// Assets
import MapPin from 'assets/icons/MapPin.svg';

// Styles
import { StyledGroup } from 'styles/formViews/formViews.styles';

const Form = (props) => {
  const {
    formEvent,
    interventionOptions,
    communityOptions,
    cancelEvent,
    formatDataAndSaveEvent,
    canEdit,
  } = props;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = formEvent;

  const { useComponents } = useViews();
  const { useTypographies, useButtons, useInputs } = useComponents();
  const { TypographyWithTooltip } = useTypographies();
  const { ButtonComponent } = useButtons();
  const { InputForm, Select, InputHoursMinutes } = useInputs();

  return (
    <form className="border px-6 pt-4 pb-8 rounded ">
      <StyledGroup col-2 className="mb-4 mt-4" noSpace>
        <div>
          <TypographyWithTooltip
            text="Intervención a realizar*"
            textTooltip="Intervención a realizar"
            className={`text-neutral-950 mb-1 ${
              errors['intervention'] && 'text-error'
            }`}
            idTooltip="int-reali"
            variant="p"
            applyFontBold
          />
          <Select
            name="intervention"
            id="communities"
            control={control}
            placeholder="Selecciona una intervención"
            options={interventionOptions}
            label=""
            className="w-full"
            errors={errors}
            disabled={!canEdit}
          />
        </div>
        <Select
          name="modality"
          id="communities"
          control={control}
          placeholder="Selecciona la modalidad"
          label="Modalidad*"
          className="w-full"
          errors={errors}
          disabled
        />
      </StyledGroup>

      <StyledGroup col-2 className="mb-4 mt-4" noSpace>
        <InputForm
          name="date"
          label="Fecha y hora*"
          type="datetime-local"
          placeholder="DD/MM/AAAA"
          className="w-full"
          control={control}
          errors={errors}
          disabled={!canEdit}
        />
        <InputHoursMinutes
          name="duration"
          control={control}
          label="Duración*"
          type="time"
          placeholder="--:--"
          className="w-full"
          errors={errors}
          disabled
        />
      </StyledGroup>

      <StyledGroup col-2 className="mb-4 mt-4" noSpace>
        <Select
          name="community"
          id="communities"
          control={control}
          placeholder="Selecciona una comunidad"
          options={communityOptions}
          label="Comunidad donde se realizará la actividad*"
          className="w-full col-span-2"
          errors={errors}
          disabled={!canEdit}
        />
      </StyledGroup>

      <StyledGroup col-2 className="mb-4 mt-4" noSpace>
        <InputForm
          name="place"
          label="Lugar*"
          type="text"
          icon={MapPin}
          placeholder="Ingresa un lugar"
          className="w-full col-span-2"
          control={control}
          errors={errors}
          disabled={!canEdit}
        />
      </StyledGroup>

      {canEdit && (
        <div className="flex items-center space-x-2 mt-10 justify-end">
          <ButtonComponent
            text="Cancelar"
            mode="outline"
            onPress={cancelEvent}
          />
          <ButtonComponent
            text="Guardar evento"
            onPress={handleSubmit(formatDataAndSaveEvent)}
          />
        </div>
      )}
    </form>
  );
};

Form.propTypes = {
  formEvent: PropTypes.object.isRequired,
  cancelEvent: PropTypes.func.isRequired,
  formatDataAndSaveEvent: PropTypes.func.isRequired,
  interventionOptions: PropTypes.array.isRequired,
  communityOptions: PropTypes.array.isRequired,
  canEdit: PropTypes.bool,
};

export default Form;
