//Packages
import { useEffect } from 'react';

//Hooks
import useModels from '../../../models';
import useApi from '../../../api';

const useLegalData = () => {
  const { useActions } = useApi();
  const { dispatch, useGeneralActions } = useActions();
  const { useLegalDataActions } = useGeneralActions();
  const { actLegalData } = useLegalDataActions();
  const { useSelectors } = useModels();
  const { useGeneralSelectors, useSelector } = useSelectors();
  const { useLegalDataSelectors } = useGeneralSelectors();
  const { privacyPoliciesSelector, termsAndConditionsSelector } =
    useLegalDataSelectors();
  const privacyPolicies = useSelector(privacyPoliciesSelector);
  const termsAndConditions = useSelector(termsAndConditionsSelector);

  useEffect(() => {
    if (!privacyPolicies || !termsAndConditions) {
      dispatch(actLegalData());
    }
  }, [privacyPolicies, termsAndConditions]);
  return { privacyPolicies, termsAndConditions };
};

export default useLegalData;
