//Hooks

import useApi from '../../..';
import useControllers from '../../../../controllers';
import useStrings from '../../../../strings';

const useAdminLabelsActions = () => {
  const { useAdminTypes } = useStrings();
  const { useAdminLabelsTypes } = useAdminTypes();
  const {
    CREATE_LABELS,
    GET_LABELS,
    DELETE_LABELS,
    UPDATE_LABELS,
    ASSING_LABEL_TO_CONTACTS,
    ASSING_LABELS_TO_CONTACT,
    UNASSING_LABEL_TO_CONTACTS,
  } = useAdminLabelsTypes();

  const { useGeneralHooks } = useControllers();
  const { useConsole } = useGeneralHooks();
  const { viewConsole } = useConsole();

  const { useProviders } = useApi();
  const { useAdminProviders } = useProviders();
  const { useAdminLabelsProviders } = useAdminProviders();

  const {
    createLabels,
    getLabelsList,
    deleteLabels,
    updateLabels,
    assignLabelsToContactProvider,
    assignLabelToContactsProvider,
    unassignLabelToContactProvider,
  } = useAdminLabelsProviders();

  const actCreateLabels =
    ({ name, color }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await createLabels({ name, color });
        dispatch({ type: CREATE_LABELS, payload: res.data?.data });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actGetLabels =
    ({ paginate, name, orderByKey, orderByValue }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await getLabelsList({
          paginate,
          name,
          orderByKey,
          orderByValue,
        });
        dispatch({ type: GET_LABELS, payload: res.data?.data });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actDeleteLabels =
    ({ labelId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await deleteLabels({ labelId });
        dispatch({ type: DELETE_LABELS, payload: { labelId } });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actUpdateLabels =
    ({ labelId, name, color }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await updateLabels({ labelId, name, color });
        dispatch({
          type: UPDATE_LABELS,
          payload: { labelId, data: { name, color } },
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };
  const actAssignLabelToContacts =
    ({ tag, users }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await assignLabelToContactsProvider({ tag, users });

        dispatch({
          type: ASSING_LABEL_TO_CONTACTS,
          payload: { users, tag: response.data },
        });
        onSuccess && onSuccess(response);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };
  const actAssignLabelsToContact =
    ({ user, tags }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await assignLabelsToContactProvider({ user, tags });
        dispatch({
          type: ASSING_LABELS_TO_CONTACT,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actUnassignLabelToContact =
    ({ user, tag }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await unassignLabelToContactProvider({ user, tag });
        dispatch({
          type: UNASSING_LABEL_TO_CONTACTS,
          payload: { user, tag },
        });
        onSuccess && onSuccess(response);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  return {
    actCreateLabels,
    actGetLabels,
    actDeleteLabels,
    actUpdateLabels,
    actAssignLabelToContacts,
    actAssignLabelsToContact,
    actUnassignLabelToContact,
  };
};
export default useAdminLabelsActions;
