import React, { lazy } from 'react';
import PropTypes from 'prop-types';

// Hooks
import useViews from 'views';

// Assets
import imagePin from 'assets/icons/map-pin-purple.svg';

const StyledCardUser = lazy(() =>
  import('./CardUser.styles').then((mod) => ({
    default: mod.StyledCardUser,
  }))
);

const StyledInformation = lazy(() =>
  import('./CardUser.styles').then((mod) => ({
    default: mod.StyledInformation,
  }))
);

const CardUser = (props) => {
  const { image, name, rol, location, children, className } = props;

  const { useComponents } = useViews()
  const { useImages, useTypographies } = useComponents();
  const { Image } = useImages();
  const { TypographyComponent } = useTypographies();

  return (
    <StyledCardUser className={className}>
      {image && (
        <Image
          className="rounded-full w-20 h-20"
          srcImage={image}
          altImage={name}
        />
      )}
      <StyledInformation>
        {name && (
          <TypographyComponent
            variant="label"
            text={name}
            className="text-primary font-bold leading-7"
          />
        )}
        {rol && (
          <TypographyComponent
            variant="h5"
            text={rol}
            className="text-neutral-950"
          />
        )}
        {location && (
          <TypographyComponent
            className="text-primary flex"
            variant="h5"
            text={
              <>
                <Image
                  srcImage={imagePin}
                  altImage="location"
                  className="location-icon w-4 h-auto mr-1"
                />
                {location}
              </>
            }
          />
        )}

        {children && children}
      </StyledInformation>
    </StyledCardUser>
  );
};

CardUser.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  rol: PropTypes.string,
  location: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

CardUser.defaultProps = {
  image: '',
  name: '',
  rol: '',
  location: '',
  className: '',
  children: null,
};

export default CardUser;
