//Packages
import { normalize, schema } from 'normalizr';

//Hooks
import useApi from '../../..';
import useStrings from '../../../../strings';

const useSpecialitiesActions = () => {
  const { useProviders } = useApi();
  const { useGeneralProviders } = useProviders();
  const { useSpecialitiesProviders } = useGeneralProviders();
  const { getSpecialities } = useSpecialitiesProviders();

  //Strings
  const { useGeneralTypes } = useStrings();
  const { useSpecialitiesTypes } = useGeneralTypes();
  const { GET_SPECIALITIES } = useSpecialitiesTypes();

  const actGetSpecialities =
    ({ paginate = 0 }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await getSpecialities({ paginate });

        const speciality = new schema.Entity('specialities');

        const specialities = [speciality];

        const normalizedData = normalize(res.data?.data, specialities);

        dispatch({ type: GET_SPECIALITIES, payload: normalizedData });
        onSuccess && onSuccess(res);
      } catch (error) {
        onError && onError(error);
      }
    };

  return { actGetSpecialities };
};

export default useSpecialitiesActions;
