//Packages
import { normalize, schema } from 'normalizr';

//Hooks
import useApi from '../..';
import useStrings from '../../../strings';
import useControllers from '../../../controllers';

const useUserActions = () => {
  const { useProviders } = useApi();
  const { useUserProviders } = useProviders();
  const {
    updateProfile,
    getProfile,
    updateAvatar,
    editUserProvider,
    createUserProvider,
    changePassword,
    deleteUserProviders,
    searchUserProviders,
    getUserProviders,
    getFavoriteUsersProviders,
    saveUserAsFavoriteProviders,
    saveUserAsNotFavoriteProviders,
  } = useUserProviders();
  const { useUserTypes, useAdminTypes } = useStrings();
  const { useContactListTypes } = useAdminTypes();
  const { SEARCH_CONTACTS_IN_LIST } = useContactListTypes();
  const {
    DATA_USER,
    DATA_UPDATE_PROFILE_USER,
    UPDATE_USER,
    DELETE_DATA_UPDATE_PROFILE_USER,
    UPDATE_AVATAR_USER,
    RESET_DATA_UPDATE_PROFILE_USER,
    EDIT_USER,
    CREATE_USER,
    CHANGE_PASSWORD,
    DELETE_USER,
    SEARCH_USER,
    GET_USER,
    GET_ARTISTS_INFINITE_SCROLL,
    RESET_ARTISTS_INFINITE_SCROLL,
    GET_FAVORITE_USERS,
    ADD_USER_FAVORITE,
    DELETE_USER_FAVORITE,
  } = useUserTypes();
  const { useGeneralHooks } = useControllers();
  const { useConsole } = useGeneralHooks();
  const { viewConsole } = useConsole();
  const actUserEdit =
    ({ user }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const { data } = await editUserProvider({ user });
        dispatch({ type: EDIT_USER, payload: data });
        onSuccess && onSuccess();
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };
  const actUserCreate =
    ({ user }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const { data } = await createUserProvider({ user });
        dispatch({ type: CREATE_USER, payload: data });
        onSuccess && onSuccess(data);
      } catch (e) {
        viewConsole([e], 'error');
        onError && onError(e);
      }
    };
  const actGetProfile = (onSuccess, onError) => async (dispatch) => {
    try {
      const res = await getProfile();

      //Specialities indexed
      const speciality = new schema.Entity('specialities');

      const specialities = [speciality];

      const normalizedData = normalize(
        res.data?.data?.specialties,
        specialities
      );

      //Networks indexed
      const network = new schema.Entity('networks', undefined, {
        idAttribute: (value) => (value.type.id ? `${value.type.id}` : value.id),
      });

      const networks = [network];

      const normalizeDataNetworks = normalize(
        res.data?.data?.networks,
        networks
      );

      dispatch({
        type: DATA_USER,
        payload: {
          ...res.data?.data,
          specialitiesIndexed: normalizedData.entities?.specialities,
          networksIndexed: normalizeDataNetworks.entities?.networks,
        },
      });
      onSuccess && onSuccess(res);
    } catch (error) {
      onError && onError(error);
    }
  };

  const actUpdateProfile = (data, onSuccess, onError) => async (dispatch) => {
    try {
      const res = await updateProfile(data);
      dispatch({ type: UPDATE_USER });

      //Specialities indexed
      const speciality = new schema.Entity('specialities');

      const specialities = [speciality];

      const normalizedData = normalize(
        res.data?.data?.specialties || [],
        specialities
      );

      //Networks indexed
      const network = new schema.Entity('networks', undefined, {
        idAttribute: (value) => (value.type.id ? `${value.type.id}` : value.id),
      });

      const networks = [network];

      const normalizeDataNetworks = normalize(
        res.data?.data?.networks || [],
        networks
      );

      dispatch({
        type: DATA_USER,
        payload: {
          ...res.data?.data,
          specialitiesIndexed: normalizedData.entities?.specialities,
          networksIndexed: normalizeDataNetworks.entities?.networks,
        },
      });
      onSuccess && onSuccess(res);
    } catch (error) {
      onError && onError(error);
    }
  };

  const actUpdateAvatar = (data, onSuccess, onError) => async (dispatch) => {
    try {
      const res = await updateAvatar(data);
      dispatch({ type: UPDATE_AVATAR_USER, payload: res.data });
      onSuccess && onSuccess(res);
    } catch (error) {
      onError && onError(error);
    }
  };

  const actAddDataUpdateProfileUser = (data) => (dispatch) => {
    dispatch({ type: DATA_UPDATE_PROFILE_USER, payload: data });
  };
  const actDeleteDataUpdateProfileUser = (data) => (dispatch) => {
    dispatch({ type: DELETE_DATA_UPDATE_PROFILE_USER, payload: data });
  };
  const actResetDataUpdateProfileUser = (data) => (dispatch) => {
    dispatch({ type: RESET_DATA_UPDATE_PROFILE_USER, payload: data });
  };
  const actChangePassword = (data, onSuccess, onError) => async (dispatch) => {
    try {
      const res = await changePassword(data);
      dispatch({ type: CHANGE_PASSWORD });
      onSuccess && onSuccess(res.data.message);
    } catch (error) {
      onError && onError(error);
    }
  };

  const actDeleteUser = (user, onSuccess, onError) => async (dispatch) => {
    try {
      const res = await deleteUserProviders(user);
      dispatch({ type: DELETE_USER, payload: user });
      onSuccess && onSuccess(res.data.message);
    } catch (error) {
      onError && onError(error);
    }
  };
  const actSearchUser =
    (
      {
        search,
        name,
        full_name,
        paginate,
        country_id,
        department_id,
        city_id,
        roles,
        page,
        community_id,
        has_whatsapp,
        language,
        orderByKey,
        orderByValue,
        infiniteScroll = false,
        contact_list_id,
        communities,
        isArtistSelection = false,
      } = {},
      type = 'users',
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const res = await searchUserProviders(
          {
            search,
            name,
            full_name,
            paginate,
            country_id,
            department_id,
            city_id,
            roles,
            page,
            community_id,
            has_whatsapp,
            language,
            orderByKey,
            orderByValue,
            contact_list_id,
            communities,
          },
          type
        );
        if (contact_list_id) {
          dispatch({ type: SEARCH_CONTACTS_IN_LIST, payload: res.data });
        } else {
          dispatch({
            type: infiniteScroll ? GET_ARTISTS_INFINITE_SCROLL : SEARCH_USER,
            payload: { ...res.data, isArtistSelection },
          });
        }

        onSuccess && onSuccess(res.data.message, res.data);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };
  const actGetUser = (id, onSuccess, onError) => async (dispatch) => {
    try {
      const res = await getUserProviders(id);
      dispatch({ type: GET_USER, payload: res.data });
      onSuccess && onSuccess(res.data.message);
    } catch (error) {
      onError && onError(error);
    }
  };

  const actResetArtistListInfiniteScroll = () => (dispatch) => {
    dispatch({ type: RESET_ARTISTS_INFINITE_SCROLL });
  };

  const actGetFavoriteUsers = (onSuccess, onError) => async (dispatch) => {
    try {
      const res = await getFavoriteUsersProviders();
      dispatch({ type: GET_FAVORITE_USERS, payload: res.data });
      onSuccess && onSuccess(res.data);
    } catch (error) {
      onError && onError(error);
    }
  };

  const actSaveUserAsFavorite =
    ({ userId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await saveUserAsFavoriteProviders({ userId });
        dispatch({ type: ADD_USER_FAVORITE, payload: userId });
        onSuccess && onSuccess(res.data);
      } catch (error) {
        onError && onError(error);
      }
    };

  const actSaveUserAsNotFavorite =
    ({ userId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await saveUserAsNotFavoriteProviders({ userId });
        dispatch({ type: DELETE_USER_FAVORITE, payload: userId });
        onSuccess && onSuccess(res.data);
      } catch (error) {
        onError && onError(error);
      }
    };

  return {
    actUserEdit,
    actUpdateProfile,
    actGetProfile,
    actUpdateAvatar,
    actAddDataUpdateProfileUser,
    actDeleteDataUpdateProfileUser,
    actResetDataUpdateProfileUser,
    actChangePassword,
    actUserCreate,
    actDeleteUser,
    actSearchUser,
    actGetUser,
    actResetArtistListInfiniteScroll,
    actGetFavoriteUsers,
    actSaveUserAsFavorite,
    actSaveUserAsNotFavorite,
  };
};

export default useUserActions;
