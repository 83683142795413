//Packages
import React, { Suspense, lazy } from 'react';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

//Assets
import plusIcon from 'assets/icons/plus.svg';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

//Styles
const StyledBackButton = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);

const StyledGroup = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const StyledForm = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledForm,
  }))
);

const StyledContentButtonsModal = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const StyledContentButtons = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const StyledCollapseDetailContent = lazy(() =>
  import('styles/collapse/collapse.styles').then((mod) => ({
    default: mod.StyledCollapseDetailContent,
  }))
);

const StyledMultimedia = lazy(() =>
  import('styles/collapse/collapse.styles').then((mod) => ({
    default: mod.StyledMultimedia,
  }))
);

const SttledContentInformation = lazy(() =>
  import('styles/collapse/collapse.styles').then((mod) => ({
    default: mod.SttledContentInformation,
  }))
);

const StyledContentCols = lazy(() =>
  import('styles/collapse/collapse.styles').then((mod) => ({
    default: mod.StyledContentCols,
  }))
);

const StyledWrapperCommunity = lazy(() =>
  import('../Dashboard.styles').then((mod) => ({
    default: mod.StyledWrapperCommunity,
  }))
);

const StyledCardCommunity = lazy(() =>
  import('../Dashboard.styles').then((mod) => ({
    default: mod.StyledCardCommunity,
  }))
);

const AdminDashboardFestivitiesAndTraditions = () => {
  const { useComponents } = useViews();
  const {
    useInputs,
    useButtons,
    useTypographies,
    useImages,
    useModals,
    useBoxes,
    useLoaders,
  } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { InputForm, TextArea, Select } = useInputs();
  const { ButtonComponent, ButtonAddActivity } = useButtons();
  const { TypographyComponent } = useTypographies();
  const { Image, UploadImagePreview } = useImages();
  const { DefaultModal } = useModals();
  const { Box } = useBoxes();

  const { useScreenHooks, useGeneralHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminFestivitiesAndTraditionsDashboard } =
    useAdminDashboardProjectsHooks();
  const {
    control,
    register,
    handleSubmit,
    errors,
    handleRegisterCommunityFestivity,
    handleUpdateCommunityFestivity,
    listProjectFestivity,
    optionsCategories,
    show,
    showDeleteFestivityModal,
    handleDeleteFestivityModal,
    festivitySelectedData,
    setFestivityId,
    handleResetInputFile,
    cleanFileInputs,
    handleSelectDefaultImage,
    handleAddCommunityFestivity,
    handleCancelCommunityFestivity,
    handleDeleteCommunityFestivity,
    handleEditCommunityFestivity,
    handleSelectTypeFile,
    handleGoBack,
  } = useAdminFestivitiesAndTraditionsDashboard();

  const { useSelectLayoutByRole } = useGeneralHooks();
  const { LayoutSelected, props } = useSelectLayoutByRole();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <LayoutSelected {...props}>
        <StyledBackButton className="mt-3" onClick={handleGoBack}>
          Regresar
        </StyledBackButton>
        <Box className="bg-neutral-200 w-11/12 mx-auto max-w-screen-lg lg:px-16 lg:py-12 px-6 py-8 lg:my-8">
          <>
            <TypographyComponent
              text="Fiestas y tradiciones"
              variant="h2"
              className="font-bold text-primary"
              applyFontBold
            />

            <TypographyComponent
              text="Qué celebraciones son importantes para esta comunidad?            "
              variant="p"
              className="font-book text-neutral-950 mb-10"
            />
            <StyledWrapperCommunity
              className={
                !show
                  ? 'visible opacity-100 h-auto'
                  : 'invisible opacity-0 transition-all duration-100 h-0'
              }
            >
              <Accordion allowZeroExpanded>
                {listProjectFestivity.length > 0 &&
                  _.map(listProjectFestivity, (communityFestivity, index) => {
                    return (
                      <AccordionItem key={`festivityItem-${index}`}>
                        <StyledCardCommunity
                          key={`festivity- ${communityFestivity.id}`}
                        >
                          <TypographyComponent
                            variant="p"
                            text={communityFestivity.name}
                            className="flex-grow text-primary font-bold"
                          />
                          <StyledContentButtons>
                            <AccordionItemState>
                              {({ expanded }) =>
                                expanded ? (
                                  ''
                                ) : (
                                  <AccordionItemButton>
                                    <ButtonComponent
                                      text=""
                                      className="text-primary-100"
                                      variant="white"
                                      icon={
                                        <div className="icon-view text-primary h-10" />
                                      }
                                      onPress={() => {}}
                                    />
                                  </AccordionItemButton>
                                )
                              }
                            </AccordionItemState>

                            <ButtonComponent
                              text=""
                              className="icon-edit text-primary-100"
                              variant="white"
                              onPress={() => {
                                handleEditCommunityFestivity({
                                  communityFestivity,
                                });
                                setFestivityId(communityFestivity.id);
                              }}
                            />
                            <ButtonComponent
                              text=""
                              className="icon-delete"
                              onPress={() => {
                                handleDeleteFestivityModal();
                                setFestivityId(communityFestivity.id);
                              }}
                            />
                          </StyledContentButtons>
                        </StyledCardCommunity>
                        <AccordionItemPanel>
                          <StyledCollapseDetailContent>
                            <StyledContentCols>
                              {communityFestivity.multimedias.length > 0 && (
                                <StyledMultimedia>
                                  {_.map(
                                    communityFestivity.multimedias,
                                    (multimedia, index) => (
                                      <Image
                                        key={index}
                                        srcImage={multimedia.url}
                                        altImage="multimedia"
                                        titleImage="multimedia"
                                      />
                                    )
                                  )}
                                </StyledMultimedia>
                              )}

                              <SttledContentInformation>
                                <TypographyComponent
                                  variant="h3"
                                  text={communityFestivity.name}
                                  className="flex-grow text-primary font-bold mt-4"
                                />
                                <TypographyComponent
                                  variant="p"
                                  text={communityFestivity.description}
                                  className="mt-4"
                                />
                              </SttledContentInformation>
                            </StyledContentCols>

                            <AccordionItemButton>
                              <ButtonComponent
                                text="Cerrar vista previa"
                                className="mt-6"
                                variant="primary"
                                onPress={() => {}}
                              />
                            </AccordionItemButton>
                          </StyledCollapseDetailContent>
                        </AccordionItemPanel>
                      </AccordionItem>
                    );
                  })}
              </Accordion>
            </StyledWrapperCommunity>
            <ButtonAddActivity
              open={show}
              text="Agregar nueva fiesta o tradición"
              icon={
                <Image
                  srcImage={plusIcon}
                  altImage="icon-plus"
                  titleImage="icon-plus"
                  className="w-6 mr-2"
                />
              }
              onPress={handleAddCommunityFestivity}
            />
            {show && (
              <StyledForm border className="h-auto">
                <StyledGroup noSpace>
                  <Select
                    name="category"
                    id="category"
                    control={control}
                    options={optionsCategories}
                    label="Categoría"
                    placeholder="Selecciona una categoría"
                    errors={errors}
                  />
                  <InputForm
                    name="name"
                    label="Título"
                    placeholder="Ingresa el nombre de la festividad"
                    errors={errors}
                    {...register('name')}
                    defaultValue={festivitySelectedData?.name}
                  />
                </StyledGroup>
                <TextArea
                  name="description"
                  placeholder="Ingresar descripción de la festividad o tradición"
                  className="w-full col-span-2"
                  label="Descripción"
                  errors={errors}
                  {...register('description')}
                  defaultValue={festivitySelectedData?.description}
                />

                <TypographyComponent
                  text={
                    <label>
                      <span className="font-bold">
                        Agregar imágenes de la fiesta o tradición
                      </span>
                      <span className="text-neutral-800 ml-2 font-light">
                        Formato de archivo permitido JPG,PNG,JPEG,MP4,MOV
                      </span>
                    </label>
                  }
                  variant="p"
                  className="text-neutral-950 mb-10 w-full"
                />

                <StyledGroup flex className="space-x-4">
                  <div>
                    <Controller
                      control={control}
                      name="main"
                      render={({ field: { onChange, name } }) => {
                        return (
                          <UploadImagePreview
                            name={name}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            errors={errors}
                            resetInput={() => {
                              handleResetInputFile({ typeMultimedia: 'main' });
                            }}
                            srcDefaultImage={handleSelectDefaultImage({
                              typeMultimedia: 'main',
                            })}
                            cleanFileInputs={cleanFileInputs}
                            isAVideo={handleSelectTypeFile('main')}
                          />
                        );
                      }}
                    />
                  </div>
                  <div>
                    <Controller
                      control={control}
                      name="first"
                      render={({ field: { onChange, name } }) => {
                        return (
                          <UploadImagePreview
                            name={name}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            errors={errors}
                            resetInput={() => {
                              handleResetInputFile({ typeMultimedia: 'first' });
                            }}
                            srcDefaultImage={handleSelectDefaultImage({
                              typeMultimedia: 'first',
                            })}
                            cleanFileInputs={cleanFileInputs}
                            isAVideo={handleSelectTypeFile('first')}
                          />
                        );
                      }}
                    />
                  </div>
                  <div>
                    <Controller
                      control={control}
                      name="second"
                      render={({ field: { onChange, name } }) => {
                        return (
                          <UploadImagePreview
                            name={name}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            errors={errors}
                            resetInput={() => {
                              handleResetInputFile({
                                typeMultimedia: 'second',
                              });
                            }}
                            srcDefaultImage={handleSelectDefaultImage({
                              typeMultimedia: 'second',
                            })}
                            cleanFileInputs={cleanFileInputs}
                            isAVideo={handleSelectTypeFile('second')}
                          />
                        );
                      }}
                    />
                  </div>
                </StyledGroup>

                <StyledGroup flex className="justify-end space-x-4 pt-6">
                  <ButtonComponent
                    text="Cancelar"
                    mode="outline"
                    onPress={handleCancelCommunityFestivity}
                  />
                  <ButtonComponent
                    text={
                      festivitySelectedData
                        ? 'Guardar Cambios'
                        : 'Agregar fiesta o tradición'
                    }
                    className="mx-auto mt-8"
                    onPress={handleSubmit(
                      festivitySelectedData
                        ? handleUpdateCommunityFestivity
                        : handleRegisterCommunityFestivity
                    )}
                  />
                </StyledGroup>
              </StyledForm>
            )}
          </>
        </Box>

        <DefaultModal
          isActive={showDeleteFestivityModal}
          classNameContent="max-w-screen-md w-11/12"
          handleClose={handleDeleteFestivityModal}
        >
          <TypographyComponent
            text="Eliminar tradición"
            variant="h3"
            className="font-bold text-primary mb-1"
          />
          <StyledContentButtonsModal>
            <ButtonComponent
              variant="primary"
              text="Cancelar"
              className="mx-auto w-full lg:w-auto lg:mx-2 "
              mode="outline"
              onPress={handleDeleteFestivityModal}
            />
            <ButtonComponent
              variant="primary"
              text="Eliminar"
              className="mx-auto w-full lg:w-auto  lg:mx-2"
              onPress={handleDeleteCommunityFestivity}
            />
          </StyledContentButtonsModal>
        </DefaultModal>
      </LayoutSelected>
    </Suspense>
  );
};

export default AdminDashboardFestivitiesAndTraditions;
