import AdminProfile from './Admin';
import ArtistProfile from './Artist';
import PartnerProfile from './Partner';
import TabMyInterventions from './TabMyInterventions';
import TabMyProjects from './TabMyProjects';

const useProfileComponents = () => {
  return {
    AdminProfile,
    ArtistProfile,
    PartnerProfile,
    TabMyProjects,
    TabMyInterventions,
  };
};

export default useProfileComponents;
