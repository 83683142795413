import React from 'react';

const MainLayout = React.lazy(() => import('./MainLayout'));
const ColumTwoLayout = React.lazy(() => import('./ColumTwoLayout'));
const AdminLayout = React.lazy(() => import('./AdminLayout'));

const useLayouts = () => {
  return { MainLayout, ColumTwoLayout, AdminLayout };
};

export default useLayouts;
