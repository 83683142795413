import useHelpers from '../../../../helpers';

const useCountriesSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const countriesSelector = createSelector(
    (state) => state.countries,
    (countries) => countries
  );
  return { countriesSelector };
};

export default useCountriesSelectors;
