import AdminDashboardCreateCommunities from './Create';
import AdminDashboardEditCommunities from './Edit';
import AdminDashboardRootCommunities from './Root';
import useAdminProjectsDashboardCommunitiesContactsScreens from './Contacts';

const useAdminProjectsDashboardCommunitiesScreens = () => {
  const adminDashboardProjectsCommunitiesContactsScreens =
    useAdminProjectsDashboardCommunitiesContactsScreens();
  return {
    AdminDashboardRootCommunities,
    AdminDashboardEditCommunities,
    AdminDashboardCreateCommunities,
    ...adminDashboardProjectsCommunitiesContactsScreens,
  };
};

export default useAdminProjectsDashboardCommunitiesScreens;
