//Packages
import _ from 'lodash';

//Hooks
import useHelpers from 'helpers';
import useStrings from 'strings';
import useModels from 'models';

const useAdminInterventionsRootReducers = () => {
  const { useCreateReducer, useQuickFunctions } = useHelpers();
  const { createReducer } = useCreateReducer();
  const { updateItemInArray } = useQuickFunctions();

  const { useAdminTypes } = useStrings();
  const { useAdminProjectsTypes } = useAdminTypes();
  const { useAdminInterventionsTypes } = useAdminProjectsTypes();
  const { useAdminInterventionsRootTypes, useAdminViewInterventionsTypes } =
    useAdminInterventionsTypes();
  const {
    GET_DEFINITION_BEHAVIORS,
    GET_INTERVENTIONS_BY_PROJECT,
    GET_INTERVENTION_DETAIL_BY_PROJECT,
    RESET_INTERVENTION_DETAIL_BY_PROJECT,
    SET_FILTER_INTERVENTION_DETAIL_BY_PROJECT,
    RESET_FILTER_INTERVENTION_DETAIL_BY_PROJECT,
    RESET_INTERVENTION_SELECTED,
    SET_INTERVEN_DEFINITION_ID,
    RESET_INTERVEN_DEFINITION_ID,
    SET_CUSTOM_INTERVENTION_STEP,
  } = useAdminInterventionsRootTypes();
  const { useAdminViewInterventionsDevelopmentInterventionTypes } =
    useAdminViewInterventionsTypes();
  const {
    useAdminDevelopmentInterventionCreativePlanTypes,
    useAdminDevelopmentInterventionKnowProjectTypes,
    useAdminDevelopmentInterventionKnowCommunityTypes,
    useAdminDevelopmentInterventionDefineInterventionTypes,
    useAdminDevelopmentInterventionCreateSketchTypes,
    useAdminDevelopmentExecuteInterventionTypes,
    useAdminSpreadInterventionTypes,
  } = useAdminViewInterventionsDevelopmentInterventionTypes();
  const {
    GET_DEFINE_PROJECT_INTERVENTION,
    UPDATE_DEFINE_PROJECT_INTERVENTION,
    UPDATE_DEFINE_PROJECT_INTERVENTION_ARTIST,
    ADD_INTERVENTION_DEFINITION_ARTIST,
    DELETE_INTERVENTION_DEFINITION_ARTIST,
    CREATE_ACTIVITY_FOR_INTERVENTION_ARTIST,
    DELETE_ACTIVITY_FOR_INTERVENTION_ARTIST,
    EDIT_ACTIVITY_FOR_INTERVENTION_ARTIST,
    ADD_LOGISTIC_MEAN_INTO_INTERVENTION_ARTIST,
    DELETE_LOGISTIC_MEAN_INTO_INTERVENTION_ARTIST,
    EDIT_LOGISTIC_MEAN_INTO_INTERVENTION_ARTIST,
    SET_DATA_DIFFUSION_FORM_INTO_INTERVENTION_ARTIST,
    RESET_DATA_DIFFUSION_FORM_INTO_INTERVENTION_ARTIST,
    CREATE_ASSIGNED_TASKS_FOR_INTERVENTION_ARTIST,
    DELETE_ASSIGNED_TASKS_FOR_INTERVENTION_ARTIST,
    EDIT_ASSIGNED_TASKS_FOR_INTERVENTION_ARTIST,
  } = useAdminDevelopmentInterventionDefineInterventionTypes();
  const {
    DOWNLOAD_FILE_CREATIVE_PLAN_INTERVENTION,
    GET_CREATIVE_PLAN_INTERVENTION,
    UPDATE_CREATIVE_PLAN_INTERVENTION,
    DELETE_CREATIVE_PLAN_INTERVENTION,
  } = useAdminDevelopmentInterventionCreativePlanTypes();
  const {
    CREATE_KNOW_PROJECT_INTERVENTION,
    KNOW_PROJECT_INTERVENTION,
    KNOW_PROJECT_STATE_INTERVENTION,
  } = useAdminDevelopmentInterventionKnowProjectTypes();
  const {
    UPDATE_KNOW_COMMUNITY_INTERVENTION,
    DELETE_KNOW_COMMUNITY_INTERVENTION,
    KNOW_COMMUNITY_INTERVENTION,
    UPDATE_KNOW_COMMUNITY_FILE_INTERVENTION,
    DELETE_KNOW_COMMUNITY_FILE_INTERVENTION,
    KNOW_COMMUNITY_FILE_INTERVENTION,
    GET_DEEPEN_KNOWLEDGE_COMMUNITY_INTERVENTION,
    STORE_DEEPEN_KNOWLEDGE_COMMUNITY_INTERVENTION,
    ASSIGN_CHANGE_AGENTS_TO_DEEPEN_KNOWLEDGE_COMMUNITY_INTERVENTION,
    UPDATE_ACTIVITY_KNOWLEDGE_COMMUNITY_INTERVENTION,
    GET_DEEPEN_KNOWLEDGE_SCHEDULE_INFO,
    UPDATE_DEEPEN_KNOWLEDGE_SCHEDULE_INFO,
    UPDATE_MESSAGE_DEEPEN_KNOWLEDGE_INTERVENTION,
    SET_TO_SEND_MESSAGE_DEEPEN_KNOWLEDGE_INTERVENTION,
  } = useAdminDevelopmentInterventionKnowCommunityTypes();
  const {
    GET_CREATE_SKETCH_FOR_INTERVENTION,
    UPDATE_CREATE_SKETCH_FOR_INTERVENTION,
    GET_PILOT_PLAN_INTERVENTION,
    CREATE_PILOT_PLAN_ACTIVITY_INTERVENTION,
    DELETE_PILOT_PLAN_ACTIVITY_INTERVENTION,
    UPDATE_PILOT_PLAN_INTERVENTION,
    GET_PROGRAM_PILOT_INTERVENTION,
    UPDATE_PROGRAM_PILOT_INTERVENTION,
    CALL_AGENT_PROGRAM_PILOT_INTERVENTION,
    GET_EVALUATE_PILOT_INTERVENTION,
    UPLOAD_FILE_EVALUATE_PILOT_INTERVENTION,
    DELETE_FILE_EVALUATE_PILOT_INTERVENTION,
    UPDATE_EVALUATE_PILOT_INTERVENTION,
  } = useAdminDevelopmentInterventionCreateSketchTypes();
  const {
    GET_EXECUTE_MATERIALIZE_INTERVENTION,
    UPDATE_EXECUTE_MATERIALIZE_INTERVENTION,
    UPLOAD_FILE_EXECUTE_MATERIALIZE_INTERVENTION,
    DELETE_FILE_EXECUTE_MATERIALIZE_INTERVENTION,
    GET_EXECUTE_INTERVENTION,
    UPDATE_EXECUTE_INTERVENTION,
    CREATE_EVENT_EXECUTE_INTERVENTION,
    DELETE_EVENT_EXECUTE_INTERVENTION,
    START_EVENT_EXECUTE_INTERVENTION,
    COMPLETE_MILESTONE_EVENT_EXECUTE_INTERVENTION,
    GET_EXECUTE_EVALUATE_INTERVENTION,
    UPDATE_EXECUTE_EVALUATE_INTERVENTION,
    UPLOAD_FILE_EXECUTE_EVALUATE_INTERVENTION,
    DELETE_FILE_EXECUTE_EVALUATE_INTERVENTION,
  } = useAdminDevelopmentExecuteInterventionTypes();
  const {
    GET_SPREAD_INTERVENTION,
    UPDATE_SPREAD_INTERVENTION,
    UPLOAD_FILE_SPREAD_INTERVENTION,
    DELETE_FILE_SPREAD_INTERVENTION,
  } = useAdminSpreadInterventionTypes();

  const { useInitialStates } = useModels();
  const { useAdminInitialStates } = useInitialStates();
  const { useAdminProjectsInitialStates } = useAdminInitialStates();
  const { useAdminInterventionsInitialStates } =
    useAdminProjectsInitialStates();
  const { useAdminInterventionsRootInitialStates } =
    useAdminInterventionsInitialStates();
  const {
    initialStateInterventions,
    initialStateInterventionDefinitionSelected,
    inititalStateDiffusionDefinitionForm,
  } = useAdminInterventionsRootInitialStates();

  const interventions = createReducer(initialStateInterventions, {
    [GET_DEFINITION_BEHAVIORS](state, action) {
      return { ...state, definition_behavior: action.payload };
    },
    [GET_INTERVENTIONS_BY_PROJECT](state, action) {
      const { data, meta, links } = action.payload;
      return {
        ...state,
        interventionsList: {
          data: [...data],
          meta: {
            current_page: meta.current_page,
            last_page: meta.last_page,
            total: meta.total,
            per_page: meta.per_page,
          },
          links: {
            prev: links.prev,
            next: links.next,
          },
        },
      };
    },
    [GET_INTERVENTION_DETAIL_BY_PROJECT](state, action) {
      return {
        ...state,
        interventionSelectedToEdit: {
          ...action.payload,
        },
      };
    },
    [RESET_INTERVENTION_DETAIL_BY_PROJECT](state) {
      return {
        ...state,
        interventionSelectedToEdit: {
          ...initialStateInterventions.interventionSelectedToEdit,
        },
      };
    },
    [RESET_INTERVENTION_SELECTED]() {
      return initialStateInterventions;
    },
    [SET_CUSTOM_INTERVENTION_STEP](state, action) {
      return {
        ...state,
        interventionSelectedToEdit: {
          ...state.interventionSelectedToEdit,
          customActualPhase: action.payload,
        },
      };
    },
    [SET_FILTER_INTERVENTION_DETAIL_BY_PROJECT](state, action) {
      const { key, value } = action.payload;
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    },
    [RESET_FILTER_INTERVENTION_DETAIL_BY_PROJECT](state) {
      return {
        ...state,
        filter: {
          page: 1,
          search: '',
        },
      };
    },
    [GET_CREATIVE_PLAN_INTERVENTION](state, action) {
      const { data } = action.payload;

      return {
        ...state,
        plan_creative: data,
        interventionSelectedToEdit: {
          ...state.interventionSelectedToEdit,
          actual_phase: data.intervention.actual_phase,
        },
      };
    },
    [UPDATE_CREATIVE_PLAN_INTERVENTION](state, action) {
      const { data } = action.payload;
      return {
        ...state,
        plan_creative: data,
        interventionSelectedToEdit: {
          ...state.interventionSelectedToEdit,
          actual_phase: data.intervention.actual_phase,
        },
      };
    },
    [DELETE_CREATIVE_PLAN_INTERVENTION](state) {
      return state;
    },
    [DOWNLOAD_FILE_CREATIVE_PLAN_INTERVENTION](state) {
      return state;
    },
    [KNOW_PROJECT_STATE_INTERVENTION](state, action) {
      const {
        date_c,
        date_ip,
        date_limit,
        id,
        state: stateKnowProjectStep,
        intervention,
      } = action.payload.data;
      return {
        ...state,
        know_project: {
          ...state.know_project,
          date_c,
          date_ip,
          date_limit,
          id,
          state: stateKnowProjectStep,
        },
        interventionSelectedToEdit: {
          ...state.interventionSelectedToEdit,
          actual_phase: intervention.actual_phase,
        },
      };
    },
    [KNOW_PROJECT_INTERVENTION](state, action) {
      return {
        ...state,
        know_project: {
          ...state.know_project,
          project: action.payload.data,
        },
      };
    },
    [CREATE_KNOW_PROJECT_INTERVENTION](state, action) {
      return {
        ...state,
        know_project: { ...state.know_project, ...action.payload.data },
        interventionSelectedToEdit: {
          ...state.interventionSelectedToEdit,
          actual_phase: action.payload.data.intervention.actual_phase,
        },
      };
    },
    [UPDATE_KNOW_COMMUNITY_INTERVENTION](state, action) {
      const { data } = action.payload;
      return {
        ...state,
        know_community: data,
        interventionSelectedToEdit: {
          ...state.interventionSelectedToEdit,
          actual_phase: data.intervention.actual_phase,
        },
      };
    },
    [DELETE_KNOW_COMMUNITY_INTERVENTION](state) {
      return state;
    },
    [KNOW_COMMUNITY_INTERVENTION](state, action) {
      const {
        intervention,
        com_deepen,
        date_c,
        date_ip,
        date_limit,
        id,
        files,
        state: stateKnowCommunity,
      } = action.payload.data;
      return {
        ...state,
        know_community: {
          com_deepen,
          date_c,
          date_ip,
          date_limit,
          id,
          files,
          state: stateKnowCommunity,
        },
        interventionSelectedToEdit: {
          ...state.interventionSelectedToEdit,
          actual_phase: intervention.actual_phase,
        },
      };
    },
    [UPDATE_KNOW_COMMUNITY_FILE_INTERVENTION](state, action) {
      return { ...state, know_community: action.payload.data };
    },
    [DELETE_KNOW_COMMUNITY_FILE_INTERVENTION](state, action) {
      return {
        ...state,
        know_community: {
          ...state.know_community,
          files: _.filter(
            state.know_community.files,
            (file) => file.id !== action.payload
          ),
        },
      };
    },
    [KNOW_COMMUNITY_FILE_INTERVENTION](state) {
      return state;
    },
    [GET_DEFINE_PROJECT_INTERVENTION](state, action) {
      const {
        date_limit,
        date_ip,
        date_c,
        date_r,
        date_iv,
        id,
        state: stateDefineIntervention,
        key_factors,
        definition_interventions,
        convocation_method,
        rejected_reasons,
        diffusion_dynamic,
        convocation_file,
        diffusion_file,
        convocation_description,
        convocation_tasks,
        diffusion_description,
      } = action.payload.data;
      return {
        ...state,
        define_intervention: {
          ...state.define_intervention,
          definition_interventions: definition_interventions
            ? definition_interventions
            : [],
          key_factors: key_factors ? key_factors : [],
          date_limit,
          date_ip,
          date_c,
          date_r,
          date_iv,
          id,
          state: stateDefineIntervention,
          convocation_method: convocation_method ? convocation_method : [],
          rejected_reasons,
          diffusion_dynamic: diffusion_dynamic ? diffusion_dynamic : [],
          convocation_file,
          diffusion_file,
          convocation_description,
          convocation_tasks,
          diffusion_description,
        },
      };
    },
    [UPDATE_DEFINE_PROJECT_INTERVENTION](state, action) {
      const { state: stateDefineIntervention, intervention } =
        action.payload.data;
      return {
        ...state,
        define_intervention: {
          ...state.define_intervention,
          state: stateDefineIntervention,
        },
        interventionSelectedToEdit: {
          ...state.interventionSelectedToEdit,
          actual_phase: intervention.actual_phase,
        },
      };
    },
    [UPDATE_DEFINE_PROJECT_INTERVENTION_ARTIST](state, action) {
      const {
        state: stateDefineIntervention,
        intervention,
        key_factors,
        convocation_method,
        convocation_description,
        diffusion_description,
        diffusion_dynamic,
        convocation_file,
        diffusion_file,
      } = action.payload.data;
      return {
        ...state,
        define_intervention: {
          ...state.define_intervention,
          state: stateDefineIntervention,
          key_factors: key_factors ? key_factors : [],
          convocation_method: convocation_method ? convocation_method : [],
          convocation_description,
          diffusion_description,
          diffusion_dynamic: diffusion_dynamic ? diffusion_dynamic : [],
          convocation_file,
          diffusion_file,
        },
        interventionSelectedToEdit: {
          ...state.interventionSelectedToEdit,
          actual_phase: intervention.actual_phase,
        },
      };
    },
    [ADD_INTERVENTION_DEFINITION_ARTIST](state, action) {
      const { dataToAdd, definitionIdToEdit } = action.payload;
      const definitionInterventionsAux = _.map(
        state.define_intervention.definition_interventions,
        (intervention) => {
          if (parseInt(intervention.id) === parseInt(definitionIdToEdit)) {
            return dataToAdd;
          }
          return intervention;
        }
      );
      return {
        ...state,
        define_intervention: {
          ...state.define_intervention,
          definition_interventions: definitionIdToEdit
            ? definitionInterventionsAux
            : [
                ...state.define_intervention.definition_interventions,
                dataToAdd,
              ],
        },
      };
    },
    [DELETE_INTERVENTION_DEFINITION_ARTIST](state, action) {
      const { definitionIdToDelete } = action.payload;
      const definitionInterventionsAux = _.filter(
        state.define_intervention.definition_interventions,
        (intervention) =>
          parseInt(intervention.id) !== parseInt(definitionIdToDelete)
      );
      return {
        ...state,
        define_intervention: {
          ...state.define_intervention,
          definition_interventions: definitionInterventionsAux,
        },
      };
    },
    [CREATE_ACTIVITY_FOR_INTERVENTION_ARTIST](state, action) {
      const { idDefinitionIntervention, activityCreated } = action.payload;
      const selectDefinitionIntervention = _.find(
        state.define_intervention.definition_interventions,
        (definition) =>
          parseInt(definition.id) === parseInt(idDefinitionIntervention)
      );
      const definitionInterventionsAux = _.map(
        state.define_intervention.definition_interventions,
        (definition) => {
          if (parseInt(definition.id) === parseInt(idDefinitionIntervention)) {
            return {
              ...selectDefinitionIntervention,
              activities: [
                ...selectDefinitionIntervention.activities,
                activityCreated,
              ],
            };
          } else {
            return definition;
          }
        }
      );
      return {
        ...state,
        define_intervention: {
          ...state.define_intervention,
          definition_interventions: definitionInterventionsAux,
        },
      };
    },
    [DELETE_ACTIVITY_FOR_INTERVENTION_ARTIST](state, action) {
      const { activityIdForDelete, idDefinitionIntervention } = action.payload;
      const selectDefinitionIntervention = _.find(
        state.define_intervention.definition_interventions,
        (definition) =>
          parseInt(definition.id) === parseInt(idDefinitionIntervention)
      );
      const definitionInterventionsAux = _.map(
        state.define_intervention.definition_interventions,
        (definition) => {
          if (parseInt(definition.id) === parseInt(idDefinitionIntervention)) {
            const activitiesAux = _.filter(
              selectDefinitionIntervention.activities,
              (activity) =>
                parseInt(activity.id) !== parseInt(activityIdForDelete)
            );
            return {
              ...selectDefinitionIntervention,
              activities: activitiesAux,
            };
          } else {
            return definition;
          }
        }
      );
      return {
        ...state,
        define_intervention: {
          ...state.define_intervention,
          definition_interventions: definitionInterventionsAux,
        },
      };
    },
    [EDIT_ACTIVITY_FOR_INTERVENTION_ARTIST](state, action) {
      const { activityIdForEdit, idDefinitionIntervention, activityEdited } =
        action.payload;
      const selectDefinitionIntervention = _.find(
        state.define_intervention.definition_interventions,
        (definition) =>
          parseInt(definition.id) === parseInt(idDefinitionIntervention)
      );
      const definitionInterventionsAux = _.map(
        state.define_intervention.definition_interventions,
        (definition) => {
          if (parseInt(definition.id) === parseInt(idDefinitionIntervention)) {
            const activitiesAux = _.map(
              selectDefinitionIntervention.activities,
              (activity) => {
                if (parseInt(activity.id) === parseInt(activityIdForEdit)) {
                  return activityEdited;
                } else {
                  return activity;
                }
              }
            );
            return {
              ...selectDefinitionIntervention,
              activities: activitiesAux,
            };
          } else {
            return definition;
          }
        }
      );
      return {
        ...state,
        define_intervention: {
          ...state.define_intervention,
          definition_interventions: definitionInterventionsAux,
        },
      };
    },
    [ADD_LOGISTIC_MEAN_INTO_INTERVENTION_ARTIST](state, action) {
      const { idDefinitionIntervention, logisticMeanCreated } = action.payload;
      const selectDefinitionIntervention = _.find(
        state.define_intervention.definition_interventions,
        (definition) =>
          parseInt(definition.id) === parseInt(idDefinitionIntervention)
      );
      const definitionInterventionsAux = _.map(
        state.define_intervention.definition_interventions,
        (definition) => {
          if (parseInt(definition.id) === parseInt(idDefinitionIntervention)) {
            return {
              ...selectDefinitionIntervention,
              resources: [
                ...selectDefinitionIntervention.resources,
                logisticMeanCreated,
              ],
            };
          } else {
            return definition;
          }
        }
      );
      return {
        ...state,
        define_intervention: {
          ...state.define_intervention,
          definition_interventions: definitionInterventionsAux,
        },
      };
    },
    [DELETE_LOGISTIC_MEAN_INTO_INTERVENTION_ARTIST](state, action) {
      const { logisticMeanIdForDelete, idDefinitionIntervention } =
        action.payload;
      const selectDefinitionIntervention = _.find(
        state.define_intervention.definition_interventions,
        (definition) =>
          parseInt(definition.id) === parseInt(idDefinitionIntervention)
      );
      const definitionInterventionsAux = _.map(
        state.define_intervention.definition_interventions,
        (definition) => {
          if (parseInt(definition.id) === parseInt(idDefinitionIntervention)) {
            const resourcesAux = _.filter(
              selectDefinitionIntervention.resources,
              (resource) =>
                parseInt(resource.id) !== parseInt(logisticMeanIdForDelete)
            );
            return {
              ...selectDefinitionIntervention,
              resources: resourcesAux,
            };
          } else {
            return definition;
          }
        }
      );
      return {
        ...state,
        define_intervention: {
          ...state.define_intervention,
          definition_interventions: definitionInterventionsAux,
        },
      };
    },
    [EDIT_LOGISTIC_MEAN_INTO_INTERVENTION_ARTIST](state, action) {
      const {
        logisticMeanIdForEdit,
        idDefinitionIntervention,
        logisticMeanEdited,
      } = action.payload;
      const selectDefinitionIntervention = _.find(
        state.define_intervention.definition_interventions,
        (definition) =>
          parseInt(definition.id) === parseInt(idDefinitionIntervention)
      );
      const definitionInterventionsAux = _.map(
        state.define_intervention.definition_interventions,
        (definition) => {
          if (parseInt(definition.id) === parseInt(idDefinitionIntervention)) {
            const resourcesAux = _.map(
              selectDefinitionIntervention.resources,
              (resource) => {
                if (parseInt(resource.id) === parseInt(logisticMeanIdForEdit)) {
                  return logisticMeanEdited;
                } else {
                  return resource;
                }
              }
            );
            return {
              ...selectDefinitionIntervention,
              resources: resourcesAux,
            };
          } else {
            return definition;
          }
        }
      );
      return {
        ...state,
        define_intervention: {
          ...state.define_intervention,
          definition_interventions: definitionInterventionsAux,
        },
      };
    },
    [CREATE_ASSIGNED_TASKS_FOR_INTERVENTION_ARTIST](state, action) {
      const { id, name, description } = action.payload;
      return {
        ...state,
        define_intervention: {
          ...state.define_intervention,
          convocation_tasks: [
            ...state.define_intervention.convocation_tasks,
            { id, name, description },
          ],
        },
      };
    },
    [DELETE_ASSIGNED_TASKS_FOR_INTERVENTION_ARTIST](state, action) {
      const { taskId } = action.payload;
      const defineInterventionTasks = _.filter(
        state.define_intervention.convocation_tasks,
        (task) => task.id !== taskId
      );

      return {
        ...state,
        define_intervention: {
          ...state.define_intervention,
          convocation_tasks: defineInterventionTasks,
        },
      };
    },
    [EDIT_ASSIGNED_TASKS_FOR_INTERVENTION_ARTIST](state, action) {
      const { taskId, dataTaskEdited } = action.payload;
      const defineInterventionTasks = _.map(
        state.define_intervention.convocation_tasks,
        (task) => {
          if (task.id === taskId) {
            return {
              id: dataTaskEdited.id,
              name: dataTaskEdited.name,
              description: dataTaskEdited.description,
            };
          }

          return task;
        }
      );
      return {
        ...state,
        define_intervention: {
          ...state.define_intervention,
          convocation_tasks: defineInterventionTasks,
        },
      };
    },
    [GET_DEEPEN_KNOWLEDGE_COMMUNITY_INTERVENTION](state, action) {
      return {
        ...state,
        deepen_knowledge: {
          ...state.deepen_knowledge,
          ...action.payload.data,
        },
      };
    },
    [STORE_DEEPEN_KNOWLEDGE_COMMUNITY_INTERVENTION](state, action) {
      const { intervention } = action.payload.data;
      delete action.payload.data.intervention;
      return {
        ...state,
        deepen_knowledge: { ...state.deepen_knowledge, ...action.payload.data },
        interventionSelectedToEdit: {
          ...state.interventionSelectedToEdit,
          actual_phase: intervention.actual_phase,
        },
      };
    },
    [ASSIGN_CHANGE_AGENTS_TO_DEEPEN_KNOWLEDGE_COMMUNITY_INTERVENTION](
      state,
      action
    ) {
      return {
        ...state,
        deepen_execution: {
          ...state.deepen_execution,
          change_agents: [
            ...state.deepen_execution.change_agents,
            action.payload.data,
          ],
        },
      };
    },
    [UPDATE_ACTIVITY_KNOWLEDGE_COMMUNITY_INTERVENTION](state, action) {
      return {
        ...state,
        deepen_execution: {
          ...state.deepen_execution,
          activities: updateItemInArray({
            arrayData: state.deepen_execution.activities,
            itemToUpdate: action.payload.data,
          }),
        },
      };
    },
    [GET_DEEPEN_KNOWLEDGE_SCHEDULE_INFO](state, action) {
      const {
        activities,
        communities,
        deepen_type,
        deepening_modality,
        messages,
        methodology_file,
        what_to_discover,
      } = action.payload.data.deepen_knowledge;
      return {
        ...state,
        deepen_execution: {
          ...state.deepen_execution,
          ...action.payload.data,
          activities,
          communities,
          deepen_type,
          deepening_modality,
          messages,
          methodology_file,
          what_to_discover,
        },
      };
    },
    [UPDATE_DEEPEN_KNOWLEDGE_SCHEDULE_INFO](state, action) {
      const {
        deepen_knowledge: { intervention },
      } = action.payload.data;
      return {
        ...state,
        deepen_execution: {
          ...state.deepen_execution,
          ...action.payload.data,
        },
        interventionSelectedToEdit: {
          ...state.interventionSelectedToEdit,
          actual_phase: intervention.actual_phase,
        },
      };
    },
    [UPDATE_MESSAGE_DEEPEN_KNOWLEDGE_INTERVENTION](state, action) {
      return {
        ...state,
        deepen_execution: {
          ...state.deepen_execution,
          messages: updateItemInArray({
            arrayData: state.deepen_execution.messages,
            itemToUpdate: action.payload.data,
          }),
        },
      };
    },
    [SET_TO_SEND_MESSAGE_DEEPEN_KNOWLEDGE_INTERVENTION](state, action) {
      return {
        ...state,
        toSendMessage: action.payload,
      };
    },
    [GET_CREATE_SKETCH_FOR_INTERVENTION](state, action) {
      const {
        id,
        date_limit,
        state: stateCreateSketch,
        sketch_file,
        rejected_reasons,
        rejected_reason_file,
      } = action.payload.data;
      return {
        ...state,
        createSketch: {
          ...state.createSketch,
          id,
          date_limit,
          state: stateCreateSketch,
          sketch_file,
          rejected_reasons,
          rejected_reason_file,
        },
      };
    },
    [UPDATE_CREATE_SKETCH_FOR_INTERVENTION](state, action) {
      const {
        state: stateCreateSketch,
        sketch_file: sketchFile,
        rejected_reasons: rejectedReason,
        rejected_reason_file: rejectedReasonsFile,
        intervention,
      } = action.payload.data;
      return {
        ...state,
        createSketch: {
          ...state.createSketch,
          state: stateCreateSketch,
          sketch_file: sketchFile,
          rejected_reasons: rejectedReason,
          rejected_reason_file: rejectedReasonsFile,
        },
        interventionSelectedToEdit: {
          ...state.interventionSelectedToEdit,
          actual_phase: intervention.actual_phase,
        },
      };
    },
    [GET_PILOT_PLAN_INTERVENTION](state, action) {
      return {
        ...state,
        pilotPlan: {
          ...state.pilotPlan,
          ...action.payload.data,
        },
      };
    },
    [UPDATE_PILOT_PLAN_INTERVENTION](state, action) {
      const { intervention } = action.payload.data;
      return {
        ...state,
        pilotPlan: {
          ...state.pilotPlan,
          ...action.payload.data,
          currentForm: undefined,
        },
        interventionSelectedToEdit: {
          ...state.interventionSelectedToEdit,
          actual_phase: intervention.actual_phase,
        },
      };
    },
    [CREATE_PILOT_PLAN_ACTIVITY_INTERVENTION](state, action) {
      const { id, name, duration } = action.payload.data;
      const activity = { id, name, duration };
      return {
        ...state,
        pilotPlan: {
          ...state.pilotPlan,
          activities: updateItemInArray({
            arrayData: state.pilotPlan.activities,
            itemToUpdate: activity,
          }),
        },
      };
    },
    [DELETE_PILOT_PLAN_ACTIVITY_INTERVENTION](state, action) {
      return {
        ...state,
        pilotPlan: {
          ...state.pilotPlan,
          activities: [...state.pilotPlan.activities].filter(
            (a) => a.id !== action.payload
          ),
        },
      };
    },
    [GET_PROGRAM_PILOT_INTERVENTION](state, action) {
      const {
        id,
        change_agents,
        place,
        state: stateProgramPilot,
        pilot_plan,
      } = action.payload.data;
      return {
        ...state,
        programPilot: {
          ...state.programPilot,
          id,
          pilotPlan: pilot_plan,
          changeAgents: change_agents,
          place,
          state: stateProgramPilot,
        },
      };
    },
    [UPDATE_PROGRAM_PILOT_INTERVENTION](state, action) {
      const {
        state: stateProgramPilot,
        pilot_plan: { intervention },
      } = action.payload.data;
      return {
        ...state,
        programPilot: {
          ...state.programPilot,
          state: stateProgramPilot,
        },
        interventionSelectedToEdit: {
          ...state.interventionSelectedToEdit,
          actual_phase: intervention.actual_phase,
        },
      };
    },
    [CALL_AGENT_PROGRAM_PILOT_INTERVENTION](state, action) {
      return {
        ...state,
        programPilot: {
          ...state.programPilot,
          changeAgents: [
            ...state.programPilot.changeAgents,
            action.payload.data,
          ],
        },
      };
    },
    [GET_EVALUATE_PILOT_INTERVENTION](state, action) {
      return {
        ...state,
        evaluatePilot: {
          ...state.evaluatePilot,
          ...action.payload.data,
        },
      };
    },
    [UPLOAD_FILE_EVALUATE_PILOT_INTERVENTION](state, action) {
      const { documents } = action.payload.data;
      return {
        ...state,
        evaluatePilot: {
          ...state.evaluatePilot,
          documents,
        },
      };
    },
    [DELETE_FILE_EVALUATE_PILOT_INTERVENTION](state, action) {
      return {
        ...state,
        evaluatePilot: {
          ...state.evaluatePilot,
          documents: [...state.evaluatePilot.documents].filter(
            (a) => a.id !== action.payload
          ),
        },
      };
    },
    [UPDATE_EVALUATE_PILOT_INTERVENTION](state, action) {
      const {
        program_pilot: {
          pilot_plan: { intervention },
        },
      } = action.payload.data;
      return {
        ...state,
        evaluatePilot: {
          ...state.evaluatePilot,
          ...action.payload.data,
        },
        interventionSelectedToEdit: {
          ...state.interventionSelectedToEdit,
          actual_phase: intervention.actual_phase,
        },
      };
    },
    [GET_EXECUTE_MATERIALIZE_INTERVENTION](state, action) {
      return {
        ...state,
        executeMaterialize: {
          ...state.executeMaterialize,
          ...action.payload.data,
        },
      };
    },
    [UPDATE_EXECUTE_MATERIALIZE_INTERVENTION](state, action) {
      const {
        state: stateExecuteMaterialize,
        definition: { intervention },
      } = action.payload.data;
      return {
        ...state,
        executeMaterialize: {
          ...state.executeMaterialize,
          state: stateExecuteMaterialize,
        },
        interventionSelectedToEdit: {
          ...state.interventionSelectedToEdit,
          actual_phase: intervention.actual_phase,
        },
      };
    },
    [UPLOAD_FILE_EXECUTE_MATERIALIZE_INTERVENTION](state, action) {
      const { files } = action.payload.data;
      return {
        ...state,
        executeMaterialize: {
          ...state.executeMaterialize,
          files,
        },
      };
    },
    [DELETE_FILE_EXECUTE_MATERIALIZE_INTERVENTION](state, action) {
      return {
        ...state,
        executeMaterialize: {
          ...state.executeMaterialize,
          files: [...state.executeMaterialize.files].filter(
            (a) => a.id !== action.payload
          ),
        },
      };
    },
    [GET_EXECUTE_INTERVENTION](state, action) {
      return {
        ...state,
        executeIntervention: {
          ...state.executeIntervention,
          ...action.payload.data,
        },
      };
    },
    [UPDATE_EXECUTE_INTERVENTION](state, action) {
      const { state: stateExecuteIntervention, intervention } =
        action.payload.data;
      return {
        ...state,
        executeIntervention: {
          ...state.executeIntervention,
          state: stateExecuteIntervention,
        },
        interventionSelectedToEdit: {
          ...state.interventionSelectedToEdit,
          actual_phase: intervention.actual_phase,
        },
      };
    },
    [CREATE_EVENT_EXECUTE_INTERVENTION](state, action) {
      const {
        id,
        community,
        date,
        definition_intervention,
        place,
        milestones,
        state: eventState,
      } = action.payload.data;
      const event = {
        id,
        community,
        date,
        definition_intervention,
        place,
        milestones,
        state: eventState,
      };
      return {
        ...state,
        executeIntervention: {
          ...state.executeIntervention,
          events: updateItemInArray({
            arrayData: state.executeIntervention.events,
            itemToUpdate: event,
          }),
        },
      };
    },
    [DELETE_EVENT_EXECUTE_INTERVENTION](state, action) {
      return {
        ...state,
        executeIntervention: {
          ...state.executeIntervention,
          events: [...state.executeIntervention.events].filter(
            (a) => a.id !== action.payload
          ),
        },
      };
    },
    [START_EVENT_EXECUTE_INTERVENTION](state, action) {
      const { state: eventState, id } = action.payload.data;
      return {
        ...state,
        executeIntervention: {
          ...state.executeIntervention,
          events: _.map(state.executeIntervention.events, (event) => {
            if (event.id === id) {
              return {
                ...event,
                state: eventState,
              };
            }
            return event;
          }),
        },
      };
    },
    [COMPLETE_MILESTONE_EVENT_EXECUTE_INTERVENTION](state, action) {
      const {
        state: milestoneState,
        event: eventData,
        id,
      } = action.payload.data;
      const eventToUpdate = [...state.executeIntervention.events].find(
        (e) => e.id === eventData.id
      );
      const milestoneToUpdate = [...eventToUpdate.milestones].find(
        (m) => m.id === id
      );
      return {
        ...state,
        executeIntervention: {
          ...state.executeIntervention,
          events: _.map(state.executeIntervention.events, (event) => {
            if (event.id === eventToUpdate.id) {
              return {
                ...event,
                state: eventData.state,
                milestones: updateItemInArray({
                  arrayData: eventToUpdate.milestones,
                  itemToUpdate: { ...milestoneToUpdate, state: milestoneState },
                }),
              };
            }
            return event;
          }),
        },
      };
    },
    [GET_EXECUTE_EVALUATE_INTERVENTION](state, action) {
      return {
        ...state,
        executeEvaluate: {
          ...state.executeEvaluate,
          ...action.payload.data,
        },
      };
    },
    [UPDATE_EXECUTE_EVALUATE_INTERVENTION](state, action) {
      const {
        state: stateExecuteEvaluate,
        definition: { intervention },
      } = action.payload.data;
      return {
        ...state,
        executeEvaluate: {
          ...state.executeEvaluate,
          state: stateExecuteEvaluate,
        },
        interventionSelectedToEdit: {
          ...state.interventionSelectedToEdit,
          actual_phase: intervention.actual_phase,
        },
      };
    },
    [UPLOAD_FILE_EXECUTE_EVALUATE_INTERVENTION](state, action) {
      const { files } = action.payload.data;
      return {
        ...state,
        executeEvaluate: {
          ...state.executeEvaluate,
          files,
        },
      };
    },
    [DELETE_FILE_EXECUTE_EVALUATE_INTERVENTION](state, action) {
      return {
        ...state,
        executeEvaluate: {
          ...state.executeEvaluate,
          files: [...state.executeEvaluate.files].filter(
            (a) => a.id !== action.payload
          ),
        },
      };
    },
    [GET_SPREAD_INTERVENTION](state, action) {
      return {
        ...state,
        spreadIntervention: {
          ...state.spreadIntervention,
          ...action.payload.data,
        },
      };
    },
    [UPDATE_SPREAD_INTERVENTION](state, action) {
      const { state: stateSpreadIntervention, intervention } =
        action.payload.data;
      return {
        ...state,
        spreadIntervention: {
          ...state.spreadIntervention,
          state: stateSpreadIntervention,
        },
        interventionSelectedToEdit: {
          ...state.interventionSelectedToEdit,
          actual_phase: intervention.actual_phase,
        },
      };
    },
    [UPLOAD_FILE_SPREAD_INTERVENTION](state, action) {
      const { files } = action.payload.data;
      return {
        ...state,
        spreadIntervention: {
          ...state.spreadIntervention,
          files,
        },
      };
    },
    [DELETE_FILE_SPREAD_INTERVENTION](state, action) {
      return {
        ...state,
        spreadIntervention: {
          ...state.spreadIntervention,
          files: [...state.spreadIntervention.files].filter(
            (a) => a.id !== action.payload
          ),
        },
      };
    },
  });

  const interventionDefinitionIdSelected = createReducer(
    initialStateInterventionDefinitionSelected,
    {
      [SET_INTERVEN_DEFINITION_ID](state, action) {
        return {
          ...state,
          id: action.payload,
        };
      },
      [RESET_INTERVEN_DEFINITION_ID](state) {
        return {
          ...state,
          id: '',
        };
      },
    }
  );

  const formDiffusionDefinitionIntoIntervention = createReducer(
    inititalStateDiffusionDefinitionForm,
    {
      [SET_DATA_DIFFUSION_FORM_INTO_INTERVENTION_ARTIST](state, action) {
        const {
          convocation_method,
          convocation_description,
          convocation_file,
          diffusion_file,
          diffusion_dynamic,
          diffusion_description,
        } = action.payload;
        return {
          convocation_method: convocation_method
            ? convocation_method
            : state.convocation_method,
          convocation_description,
          convocation_file: convocation_file
            ? convocation_file
            : state.convocation_file,
          diffusion_dynamic: diffusion_dynamic
            ? diffusion_dynamic
            : state.diffusion_dynamic,
          diffusion_description,
          diffusion_file: diffusion_file
            ? diffusion_file
            : state.diffusion_file,
        };
      },
      [RESET_DATA_DIFFUSION_FORM_INTO_INTERVENTION_ARTIST]() {
        return inititalStateDiffusionDefinitionForm;
      },
    }
  );

  return {
    interventions,
    interventionDefinitionIdSelected,
    formDiffusionDefinitionIntoIntervention,
  };
};
export default useAdminInterventionsRootReducers;
