//Packages
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useAccessibilityProviders = () => {
  const getAccessibilities = () => {
    const response = axios({
      method: 'GET',
      url: '/commons/communication-media',
    });
    return trackPromise(response);
  };
  return { getAccessibilities };
};

export default useAccessibilityProviders;
