//Packages
import React, { Suspense, lazy } from 'react';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Assets
import imageFakeHistory from 'assets/img/fake-card-example.jpg';

//Styles
const StyledBackButton = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);

const StyledTopFilters = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledTopFilters,
  }))
);

const StyledFilters = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledFilters,
  }))
);

const StyledInputSearchContent = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledInputSearchContent,
  }))
);

const StyledContentButtonsModal = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const StyledContentCards = lazy(() =>
  import('./Root.styles').then((mod) => ({
    default: mod.StyledContentCards,
  }))
);

const AdminDashboardRootInterventions = () => {
  const { useComponents } = useViews();

  const {
    useButtons,
    useTypographies,
    useBadges,
    useInputs,
    useCards,
    usePagers,
    useModals,
    useLoaders,
  } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { ButtonComponent } = useButtons();
  const { TypographyComponent } = useTypographies();
  const { DefaultBadge } = useBadges();
  const { InputSearch } = useInputs();
  const { /*CardHistory, */ CardIntervention, CardEmptyState } = useCards();
  const { Pager } = usePagers();
  const { DefaultModal } = useModals();

  const { useScreenHooks, useGeneralHooks } = useControllers();
  const { useSelectLayoutByRole } = useGeneralHooks();
  const { LayoutSelected, props } = useSelectLayoutByRole();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminRootInterventionsDashboard } =
    useAdminInterventionsDashboardControllers();
  const {
    isFilterEmpty,
    handleGoToCreateIntervention,
    textSearch,
    setTextSearch,
    showDeleteModal,
    handleDeleteModal,
    handleGoBack,
    interventionsList,
    interventionsMeta,
    interventionsLinks,
    handleDeleteInterventionByProject,
    handleChangePage,
    currentPage,
    setInterventionSelected,
    handleGoToEditIntervention,
    handleGoToDashboardIntervention,
  } = useAdminRootInterventionsDashboard();

  /*const fakeDataCard = [
    {
      image: imageFakeHistory,
      title: 'Manitos creativas',
      location: 'Mompiche (Ecuador)',
      description:
        'Credibly reintermediate client focused growth strategies with functional initiatives.',
    },
    {
      image: imageFakeHistory,
      title: 'Manitos creativas',
      location: 'Mompiche (Ecuador)',
      description:
        'Credibly reintermediate client focused growth strategies with functional initiatives.',
    },
  ];*/

  // const fakeDataCardInterventions = [
  //   {
  //     image: imageFakeHistory,
  //     name: 'Manitos creativas',
  //     typeList: [
  //       { name: 'Remota', type: 'secondary' },
  //       { name: 'Pendiente por iniciar', type: 'warning' },
  //     ],
  //   },
  //   {
  //     image: imageFakeHistory,
  //     name: 'Manitos creativas',
  //     typeList: [
  //       { name: 'Presencial', type: 'secondary' },
  //       { name: 'En proceso', type: 'primary' },
  //     ],
  //   },
  // ];

  return (
    <Suspense fallback={<DefaultLoader />}>
      <LayoutSelected {...props}>
        <>
          <StyledBackButton className="mt-3" onClick={handleGoBack}>
            Regresar
          </StyledBackButton>

          <StyledTopFilters className="mt-6">
            <ButtonComponent
              text="Crear nueva intervención"
              icon={<div className="icon-close transform rotate-45 text-xs" />}
              onPress={handleGoToCreateIntervention}
            />
            {!(!isFilterEmpty && interventionsList?.length === 0) && (
              <StyledFilters className="relative">
                <StyledInputSearchContent>
                  <InputSearch text={textSearch} onDebounce={setTextSearch} />
                </StyledInputSearchContent>
              </StyledFilters>
            )}
          </StyledTopFilters>

          <TypographyComponent
            className="text-primary font-bold flex items-center justify-start my-5"
            text={
              <>
                Activas
                <DefaultBadge
                  className="text-success-950 ml-2"
                  variant="success"
                  label={`${interventionsMeta.total}`}
                  colorLevel="300"
                />
              </>
            }
            variant="h2"
          />
          {interventionsList.length > 0 ? (
            <>
              <StyledContentCards>
                {_.map(interventionsList, (item, idx) => (
                  <CardIntervention
                    key={`project-se-${idx}`}
                    className="w-full cursor-pointer "
                    classNameContentTop="flex-col"
                    image={item.image?.url || imageFakeHistory}
                    title={item.name}
                    typeList={[
                      { name: item.objetive, type: 'primary' },
                      { name: item.type.name, type: 'secondary' },
                    ]}
                    onClick={() => {
                      handleGoToDashboardIntervention({
                        interventionId: item.id,
                      });
                    }}
                    editMode
                    handleEdit={(e) => {
                      e.stopPropagation();
                      handleGoToEditIntervention({ interventionId: item.id });
                    }}
                    handleDelete={(e) => {
                      e.stopPropagation();
                      handleDeleteModal();
                      setInterventionSelected(item.id);
                    }}
                    status={item.state.name.toLowerCase()}
                  />
                ))}
              </StyledContentCards>
              <Pager
                pageCount={interventionsMeta.last_page}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                nextLabel={Boolean(interventionsLinks.next)}
                previousLabel={Boolean(interventionsLinks.prev)}
                className="flex items-center lg:justify-end justify-center my-6"
                onPageChange={handleChangePage}
                forcePage={currentPage - 1}
              />
            </>
          ) : (
            <CardEmptyState
              className="w-8/12 mx-auto mt-16"
              title={
                isFilterEmpty
                  ? 'No hay resultados, utiliza nuevos criterios de búsqueda'
                  : 'No se han encontrado intervenciones ¡Crea la primera!'
              }
              button={
                !isFilterEmpty ? (
                  <ButtonComponent
                    className="mx-auto"
                    text="Crear nueva intervención"
                    icon={
                      <div className="icon-close transform rotate-45 text-xs" />
                    }
                    onPress={handleGoToCreateIntervention}
                  />
                ) : (
                  <></>
                )
              }
            />
          )}

          {/*<div>
            <TypographyComponent
              className="text-primary font-bold my-10"
              text="Historial de intervenciones"
              variant="h3"
            />

            {_.map(fakeDataCard, (item, idx) => (
              <CardHistory
                key={`abi-card-h${idx}`}
                className="w-full lg:w-7/12 lg:mb-14 pb-8 lg:pb-0 mb-8 border-b lg:border-0 "
                image={item.image}
                title={item.title}
                location={item.location}
                description={item.description}
                typeList={['Presencial']}
              />
            ))}
          </div>*/}
        </>
      </LayoutSelected>

      <DefaultModal
        isActive={showDeleteModal}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={handleDeleteModal}
      >
        <TypographyComponent
          text="Eliminar Intervención"
          variant="h3"
          className="font-bold text-primary mb-1"
        />
        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto lg:mx-2 "
            mode="outline"
            onPress={handleDeleteModal}
          />
          <ButtonComponent
            variant="primary"
            text="Aceptar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={handleDeleteInterventionByProject}
          />
        </StyledContentButtonsModal>
      </DefaultModal>
    </Suspense>
  );
};

export default AdminDashboardRootInterventions;
