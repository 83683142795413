import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const StyledBoxWhite = styled.div.attrs(() => ({
  className: 'StyledBoxWhite',
}))`
  ${tw` bg-white border rounded-lg p-4`}
`;

export const StyledBoxContent = styled.div.attrs(() => ({
  className: 'StyledBoxContent',
}))`
  ${tw` `}
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
`;

export const StyledBoxGray = styled.div.attrs(() => ({
  className: 'StyledBoxGray',
}))`
  ${tw` bg-neutral-300 rounded p-2`}
`;

export const StyledBoxGrid3 = styled.div.attrs(() => ({
  className: 'StyledBoxGrid3',
}))`
  ${tw` grid grid-cols-3 2xl:grid-cols-3 gap-x-10  gap-y-6`}
`;

export const StyledBoxImages2col1 = styled.div.attrs(() => ({
  className: 'StyledBoxImages2col1',
}))`
  ${tw` grid grid-rows-2 grid-flow-col gap-4 h-96`}
  img, video {
    ${tw` w-full  object-cover object-center row-span-1 h-full rounded-lg col-span-1`}
    &:first-child {
      ${tw` row-span-2 col-span-3 h-full`}
      grid-column: span 1 / span 3;
    }
  }
`;
