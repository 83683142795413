//Packages
import React, { Suspense, lazy, useState } from 'react';
import PropTypes from 'prop-types';

//Components
import List from './components/List';
import ListDetail from './components/ListDetail';

//Hooks
import useViews from 'views';

//Styles
const StyledContentSection = lazy(() =>
  import('./ContactList.styles').then((mod) => ({
    default: mod.StyledContentSection,
  }))
);

const ContactsList = (props) => {
  const { tabValue } = props;
  const [activeTab, setActiveTab] = useState({ active: 1, contactList: {} });

  const { useComponents } = useViews();
  const { useLoaders } = useComponents();
  const { DefaultLoader } = useLoaders();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <>
        {activeTab.active === 1 && (
          <StyledContentSection active={activeTab.active === 1}>
            <List setActiveTab={setActiveTab} tabValue={tabValue} />
          </StyledContentSection>
        )}
        {activeTab.active === 2 && (
          <StyledContentSection active={activeTab.active === 2}>
            <ListDetail
              setActiveTab={setActiveTab}
              contactList={activeTab.contactList}
            />
          </StyledContentSection>
        )}
      </>
    </Suspense>
  );
};

ContactsList.propTypes = {
  tabValue: PropTypes.number.isRequired,
};

export default ContactsList;
