import React, { lazy } from 'react';
import _ from 'lodash';

// Hooks
import useViews from 'views';
import useControllers from 'controllers';
import useHelpers from 'helpers';

// Components
import DetailUser from '../../../Admin/KnowCommunities/components/Agents/components/DetailUser/DetailUser';

//Styles
const StyledContentButtonsModal = lazy(() =>
  import(
    '../../../../../../../../../../../../styles/tableViews/tableViews.styles'
  ).then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const StyledDivider = lazy(() =>
  import(
    '../../../../../../../../../../../../styles/formViews/formViews.styles'
  ).then((mod) => ({
    default: mod.StyledDivider,
  }))
);

const StyledListSelectedAgents = lazy(() =>
  import('../../../Admin/KnowCommunities/components/Agents/Agents.styles').then(
    (mod) => ({
      default: mod.StyledListSelectedAgents,
    })
  )
);

const StyledListSelectedAgentsItem = lazy(() =>
  import('../../../Admin/KnowCommunities/components/Agents/Agents.styles').then(
    (mod) => ({
      default: mod.StyledListSelectedAgentsItem,
    })
  )
);

const StyledDetailInformation = lazy(() =>
  import('../../../Admin/KnowCommunities/components/Agents/Agents.styles').then(
    (mod) => ({
      default: mod.StyledDetailInformation,
    })
  )
);

const PilotProgramArtist = () => {
  const { useComponents } = useViews();
  const { useTypographies, useButtons, useModals, useBadges, useCards } =
    useComponents();

  const { TypographyComponent } = useTypographies();
  const { ButtonComponent } = useButtons();
  const { DefaultModal } = useModals();
  const { DefaultBadge } = useBadges();
  const { CardNotification } = useCards();

  const { useQuickFunctions } = useHelpers();
  const { getYearMonthDayFromDate, getLocalZoneTime } = useQuickFunctions();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsDevelopmentIntervention } =
    useAdminViewInterventionsControllers();
  const { useViewDevelopmentInterventionCreateSketch } =
    useAdminViewInterventionsDevelopmentIntervention();
  const { useViewDevelopmentInterventionCreateSketchArtist } =
    useViewDevelopmentInterventionCreateSketch();
  const { useArtistProgramPilotIntervention } =
    useViewDevelopmentInterventionCreateSketchArtist();
  const {
    programPilot,
    pilotPlan,
    showModalApprove,
    setShowModalApprove,
    updateProgramPilotIntervention,
  } = useArtistProgramPilotIntervention();

  if (programPilot.state?.id === 'p') {
    return (
      <CardNotification
        className="w-5/12 mx-auto py-9 px-4 mt-16"
        title={
          <TypographyComponent
            text={programPilot.state?.name}
            variant="h2"
            className="font-bold text-primary icon-watch text-center"
          />
        }
      />
    );
  }

  return (
    <>
      {_.includes(['c', 'p', 'ip'], programPilot.state?.id) && (
        <CardNotification
          className="w-5/12 mx-auto py-9 px-4 mt-16"
          title={
            <TypographyComponent
              text={programPilot.state?.name}
              variant="h2"
              className="font-bold text-primary icon-watch text-center"
            />
          }
        />
      )}
      <TypographyComponent
        text="Ejecución del piloto de la intervención"
        variant="h2"
        className="font-bold text-primary mt-6"
      />
      <TypographyComponent
        text="Manos a la obra!"
        variant="p"
        className="text-neutral-950 mt-2"
      />
      <TypographyComponent
        text="Estamos listos para entrar en acción con tus planes. Aquí encontrarás los detalles del piloto."
        variant="p"
        className="text-neutral-950 mt-2"
      />

      {programPilot.state?.id !== 'p' && (
        <>
          <TypographyComponent
            text="Agentes de cambio"
            variant="h3"
            className="font-bold text-primary mt-6"
          />

          <TypographyComponent
            text="El socio ejecutor identificó algunos agentes de cambio para que te apoyen en el transcurso de tu piloto"
            variant="p"
            className="text-neutral-950 mt-2 mb-4"
          />

          <StyledListSelectedAgents>
            {_.map(programPilot.changeAgents, (agent, idx) => {
              const { state, user } = agent;
              return (
                <StyledListSelectedAgentsItem key={idx}>
                  {user.name} {user.last_name}
                  <DefaultBadge
                    type="light"
                    variant={
                      state.id === 'nc'
                        ? 'warning'
                        : state.id === 'c'
                        ? 'success'
                        : 'error'
                    }
                    label={state.state}
                    className="ml-2"
                  />
                  <StyledDetailInformation>
                    <DetailUser user={user} />
                  </StyledDetailInformation>
                </StyledListSelectedAgentsItem>
              );
            })}
          </StyledListSelectedAgents>

          <StyledDivider />

          <div className="bg-neutral-400 rounded-lg border border-neutral-700 p-6">
            <TypographyComponent
              text="Detalles del piloto"
              variant="h3"
              className="text-primary font-bold mt-2 mb-4"
            />
            <TypographyComponent
              text={
                <label>
                  <span className="text-primary font-bold mr-1">
                    Comunidad donde se realizará:
                  </span>
                  {pilotPlan.community?.name}
                </label>
              }
              variant="p"
              className="text-neutral-950 mt-2 mb-2"
            />
            <TypographyComponent
              text={
                <label>
                  <span className="text-primary font-bold mr-1">
                    Modalidad:
                  </span>
                  {pilotPlan.modality?.name}
                </label>
              }
              variant="p"
              className="text-neutral-950 mt-2 mb-2"
            />
            <TypographyComponent
              text={
                <label>
                  <span className="text-primary font-bold mr-1">Fecha:</span>
                  {getYearMonthDayFromDate({
                    dateInString: pilotPlan.date,
                    format: 'dmyText',
                  })}
                </label>
              }
              variant="p"
              className="text-neutral-950 mt-2 mb-2"
            />
            <TypographyComponent
              text={
                <label>
                  <span className="text-primary font-bold mr-1">Horario</span>
                  {pilotPlan.start_time &&
                    getLocalZoneTime({ timeInUTC: pilotPlan.start_time })}
                  {' - '}
                  {pilotPlan.end_time &&
                    getLocalZoneTime({ timeInUTC: pilotPlan.end_time })}
                </label>
              }
              variant="p"
              className="text-neutral-950 mt-2 mb-2"
            />
            <TypographyComponent
              text={
                <label>
                  <span className="text-primary font-bold mr-1">
                    Número de participantes:
                  </span>
                  {pilotPlan.participants} adultos
                </label>
              }
              variant="p"
              className="text-neutral-950 mt-2 mb-2"
            />
            <TypographyComponent
              text={
                <label>
                  <span className="text-primary font-bold mr-1">Lugar:</span>
                  {programPilot.place}
                </label>
              }
              variant="p"
              className="text-neutral-950 mt-2 mb-2"
            />
          </div>

          {programPilot.state?.id !== 'c' && (
            <>
              <div className="flex items-center space-x-2 mt-10 mb-6 justify-center">
                <ButtonComponent
                  text="Finalizar piloto y avanzar al  siguiente paso"
                  onPress={() => {
                    setShowModalApprove(true);
                  }}
                />
              </div>

              <DefaultModal
                isActive={showModalApprove}
                classNameContent="max-w-screen-md w-11/12"
                handleClose={() => {
                  setShowModalApprove(false);
                }}
              >
                <TypographyComponent
                  text={'Finalizar piloto'}
                  variant="h3"
                  className="font-bold text-primary mb-1"
                />

                <StyledContentButtonsModal>
                  <ButtonComponent
                    variant="primary"
                    text="Cancelar"
                    className="mx-auto w-full lg:w-auto lg:mx-2 "
                    mode="outline"
                    onPress={() => setShowModalApprove(false)}
                  />
                  <ButtonComponent
                    variant="primary"
                    text="Aceptar"
                    className="mx-auto w-full lg:w-auto  lg:mx-2"
                    onPress={updateProgramPilotIntervention}
                  />
                </StyledContentButtonsModal>
              </DefaultModal>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PilotProgramArtist;
