//Hooks
import useApi from '../../../..';
import useControllers from '../../../../../controllers';
import useStrings from '../../../../../strings';

const useAdminAbcTrajectoryActions = () => {
  const { useAdminTypes } = useStrings();
  const { useAdminProjectsTypes } = useAdminTypes();
  const { useAdminAbcTrajectoryTypes } = useAdminProjectsTypes();
  const {
    GET_ABC_TRAJECTORIES,
    CREATE_ABC_TRAJECTORY,
    DELETE_ABC_TRAJECTORY,
    UPDATE_ABC_TRAJECTORY,
  } = useAdminAbcTrajectoryTypes();

  const { useProviders } = useApi();
  const { useAdminProviders } = useProviders();
  const { useAdminProjectsProviders } = useAdminProviders();
  const { useAdminAbcTrajectoryProviders } = useAdminProjectsProviders();
  const {
    createAbcTrajectory,
    deleteAbcTrajectory,
    updateAbcTrajectory,
    getAbcTrajectory,
  } = useAdminAbcTrajectoryProviders();

  const { useGeneralHooks } = useControllers();
  const { useConsole } = useGeneralHooks();
  const { viewConsole } = useConsole();

  const actGetAbcTrajectory =
    ({ projectId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await getAbcTrajectory({
          projectId,
        });
        dispatch({
          type: GET_ABC_TRAJECTORIES,
          payload: res.data?.data?.trajectories,
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actCreateCommunityProjects =
    ({ name, description, type, image, projectId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const bodyFormData = new FormData();
        bodyFormData.append('name', name);
        bodyFormData.append('description', description);
        bodyFormData.append('type', type);
        bodyFormData.append('image', image);

        const res = await createAbcTrajectory({
          formData: bodyFormData,
          projectId,
        });
        dispatch({
          type: CREATE_ABC_TRAJECTORY,
          payload: res.data?.data,
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actUpdateCommunityProjects =
    (
      { projectId, abcTrajectoryId, name, description, type, image },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const bodyFormData = new FormData();
        bodyFormData.append('name', name);
        bodyFormData.append('description', description);
        bodyFormData.append('type', type);
        (image || image === '') &&
          (typeof image !== 'string' || image === '') &&
          bodyFormData.append('image', image);
        bodyFormData.append('_method', 'put');

        const res = await updateAbcTrajectory({
          formData: bodyFormData,
          abcTrajectoryId,
          projectId,
        });
        dispatch({
          type: UPDATE_ABC_TRAJECTORY,
          payload: { abcTrajectoryId, data: res.data?.data },
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  const actDeleteCommunityProjects =
    ({ projectId, abcTrajectoryId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const res = await deleteAbcTrajectory({
          abcTrajectoryId,
          projectId,
        });
        dispatch({
          type: DELETE_ABC_TRAJECTORY,
          payload: { abcTrajectoryId },
        });
        onSuccess && onSuccess(res);
      } catch (error) {
        viewConsole([error], 'error');
        onError && onError(error);
      }
    };

  return {
    actGetAbcTrajectory,
    actCreateCommunityProjects,
    actUpdateCommunityProjects,
    actDeleteCommunityProjects,
  };
};

export default useAdminAbcTrajectoryActions;
