//Packages
import React, { useEffect, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

const StyledForm = lazy(() =>
  import('../Aut.styles').then((mod) => ({
    default: mod.StyledForm,
  }))
);

const RecoverPassword = (props) => {
  //layout- components
  const { useLayouts, useComponents } = useViews();
  const { MainLayout } = useLayouts();
  const { useBoxes, useTypographies, useInputs, useButtons, useLoaders } =
    useComponents();
  const { DefaultLoader } = useLoaders();
  const { Box } = useBoxes();
  const { TypographyComponent } = useTypographies();
  const { InputForm } = useInputs();
  const { ButtonComponent } = useButtons();

  const { token } = props;
  const { useScreenHooks } = useControllers();
  const { useAuth } = useScreenHooks();
  const { useRecoverPasswordScreenController } = useAuth();
  const {
    forgotPassword,
    resetPassword,
    setToken,
    resetPasswordForm,
    forgotPasswordForm,
  } = useRecoverPasswordScreenController();

  useEffect(() => {
    setToken(token);
  }, [token]);
  return (
    <div>
      <Suspense fallback={<DefaultLoader />}>
        <MainLayout hasDecorationMap className="lg:min-h-80vh">
          <Box className="bg-neutral-200 w-11/12 mx-auto max-w-3xl lg:px-16 lg:py-12 px-6 py-8 lg:my-8">
            <>
              {token ? (
                <>
                  <TypographyComponent
                    text="Crear nueva contraseña"
                    variant="h2"
                    className="font-bold text-primary mb-4 text-lg"
                  />
                  <TypographyComponent
                    text="Ingresa una nueva contraseña a continuación. Recuerda que debe tener al menos 7 caracteres, mayúsculas, minúsculas y al menos un carácter que no sea una letra."
                    variant="p"
                    className="font-book text-neutral-950 mb-10"
                  />
                  <StyledForm
                    onSubmit={resetPasswordForm.handleSubmit(resetPassword)}
                  >
                    <InputForm
                      type="password"
                      name="password"
                      isPassword
                      placeholder="*****"
                      label="Contraseña"
                      className="w-full"
                      errors={resetPasswordForm.formState.errors}
                      {...resetPasswordForm.register('password')}
                    />

                    <InputForm
                      type="password"
                      name="password_confirmation"
                      isPassword
                      placeholder="******"
                      label="Confirmar contraseña"
                      className="w-full"
                      errors={resetPasswordForm.formState.errors}
                      {...resetPasswordForm.register('password_confirmation')}
                    />

                    <ButtonComponent
                      variant="primary"
                      className="button-form"
                      text="Restablecer contraseña"
                      onPress={() => {}}
                    />
                  </StyledForm>
                </>
              ) : (
                <>
                  <TypographyComponent
                    text="Recuperar contraseña"
                    variant="h2"
                    className="font-bold text-primary mb-4 text-lg"
                  />
                  <TypographyComponent
                    text="A todos nos pasa, ingresa tu correo electrónico y te mandaremos un link para que la cambies. Nos vemos pronto¡                    "
                    variant="p"
                    className="font-book text-neutral-950 mb-10"
                  />
                  <StyledForm
                    onSubmit={forgotPasswordForm.handleSubmit(forgotPassword)}
                  >
                    <InputForm
                      type="email"
                      name="email"
                      placeholder="Ingresa tu correo electrónico"
                      label="Correo electrónico"
                      className="w-full"
                      errors={forgotPasswordForm.formState.errors}
                      {...forgotPasswordForm.register('email')}
                    />

                    <ButtonComponent
                      variant="primary"
                      text="Enviar correo de recuperación"
                      onPress={() => {}}
                    />
                  </StyledForm>
                </>
              )}
            </>
          </Box>
        </MainLayout>
      </Suspense>
    </div>
  );
};
RecoverPassword.defaultProps = {
  token: null,
};
RecoverPassword.propTypes = {
  token: PropTypes.string,
};
export default RecoverPassword;
