import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useLanguagesProviders = () => {
  const getLanguages = ({ paginate } = {}) => {
    const response = axios({
      type: 'GET',
      url: '/languages',
      params: {
        paginate,
      },
    });
    return trackPromise(response);
  };
  return { getLanguages };
};

export default useLanguagesProviders;
