//Packages
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

//Hooks
import useStrings from 'strings';
import useHelpers from 'helpers';
import useApi from 'api';
import useModels from 'models';
import useControllers from 'controllers';

const useArtistDefineInterventionDiffusion = ({ setFirstOrLastView } = {}) => {
  const { intervention_id } = useParams();
  const [taskIdForIntervention, setTaskIdForIntervention] = useState(null);
  const [validateChecboxesConvocation, setValidateChecboxesConvocation] =
    useState(false);
  const [validateChecboxesDynamics, setValidateChecboxesDynamics] =
    useState(false);
  const [valueCheckBoxes] = useState({
    convocation: [
      { value: 'p', label: 'Impresos' },
      { value: 'o', label: 'Objetos' },
      { value: 'c', label: 'Celular' },
    ],
    dynamics: [
      { value: 'e', label: 'Evento presencial' },
      { value: 's', label: 'Streaming' },
      { value: 'd', label: 'Difusión por redes sociales' },
      { value: 'o', label: 'Otro' },
    ],
  });

  const { useGeneralHooks } = useControllers();
  const { useToggleModal } = useGeneralHooks();
  const [activeNewTask, setActiveNewTask] = useToggleModal();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();
  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const { useAdminDevelopmentInterventionDefineInterventionActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const {
    actUpdateDefineProjectInterventionArtist,
    actSetDataDiffusionForm,
    actResetDataDiffusionForm,
    actCreateAssignedTasksForIntervention,
    actDeleteAssignedTasksForIntervention,
    actEditAssignedTasksForIntervention,
  } = useAdminDevelopmentInterventionDefineInterventionActions();

  const { useSelectors } = useModels();
  const { useAdminSelectors, useSelector } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const {
    getInterventionsSelector,
    formDiffusionDefinitionIntoInterventionSelector,
    getInterventionDetailToEditSelector,
  } = useAdminInterventionsSelectors();
  const { define_intervention } = useSelector(getInterventionsSelector);
  const dataToBePreloaded = useSelector(
    formDiffusionDefinitionIntoInterventionSelector
  );
  const { objetive } = useSelector(getInterventionDetailToEditSelector);

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const {
    REQUIRED_FIELD,
    FILE_TYPE,
    FILE_SIZE_5MB,
    MAX_LENGTH_500,
    MAX_LENGTH_100,
  } = useFormsTypes();

  const { useQuickFunctions } = useHelpers();
  const { getFileValidations, urltoFile } = useQuickFunctions();
  const { MAX_SIZE_5MB } = getFileValidations();

  const schemaDiffusionForIntervention = yup.object({
    convocation_method: yup
      .array()
      .required(REQUIRED_FIELD)
      .nullable()
      .test('values', 'Debe existir al menos una convocatoria', (values) => {
        const res = values.every((convocation) => !convocation);
        return !res;
      }),
    convocation_description: yup
      .string()
      .required(REQUIRED_FIELD)
      .max(500, MAX_LENGTH_500),
    diffusion_description:
      objetive !== 'Inspirar'
        ? yup.string().required(REQUIRED_FIELD).max(500, MAX_LENGTH_500)
        : yup.string().notRequired(),
    convocation_file: yup
      .mixed()
      .test('fileType', FILE_TYPE, (value) => {
        if (value) {
          return 'application/pdf' === value.type;
        }
        return true;
      })
      .test('fileSize', FILE_SIZE_5MB, (value) => {
        if (value && value.size) {
          return value.size <= MAX_SIZE_5MB;
        }
        return true;
      })
      .required(REQUIRED_FIELD),
    diffusion_file:
      objetive !== 'Inspirar'
        ? yup
            .mixed()
            .test('fileType', FILE_TYPE, (value) => {
              if (value) {
                return 'application/pdf' === value.type;
              }
              return true;
            })
            .test('fileSize', FILE_SIZE_5MB, (value) => {
              if (value && value.size) {
                return value.size <= MAX_SIZE_5MB;
              }
              return true;
            })
            .required(REQUIRED_FIELD)
        : yup.mixed().notRequired(),
    diffusion_dynamic:
      objetive !== 'Inspirar' &&
      yup
        .array()
        .required(REQUIRED_FIELD)
        .nullable()
        .test('values', 'Debe existir al menos una dinámica', (values) => {
          const res = values.every((dynamics) => !dynamics);
          return !res;
        }),
  });

  const formIntervenDiffusion = useForm({
    resolver: yupResolver(schemaDiffusionForIntervention),
    mode: 'onChange',
  });

  useLayoutEffect(() => {
    handlePreloadData();
  }, [
    define_intervention.convocation_method,
    define_intervention.diffusion_dynamic,
    define_intervention.convocation_file,
    define_intervention.diffusion_file,
    define_intervention.convocation_description,
    define_intervention.diffusion_description,
  ]);

  const watchConvocation = formIntervenDiffusion.watch('convocation_method');
  const watchDescription = formIntervenDiffusion.watch(
    'convocation_description'
  );
  const watchDiffusionFile = formIntervenDiffusion.watch('convocation_file');
  const watchDiffusionFileDynamic =
    formIntervenDiffusion.watch('diffusion_file');
  const watchDynamics = formIntervenDiffusion.watch('diffusion_dynamic');
  const watchDescriptionDynamics = formIntervenDiffusion.watch(
    'diffusion_description'
  );

  useEffect(() => {
    const res = watchConvocation?.every((communication) => !communication);
    if (res) {
      formIntervenDiffusion.setError('convocation_method', {
        type: 'manual',
        message: 'Debe existir al menos una convocatoria',
      });
    } else {
      formIntervenDiffusion.clearErrors('convocation_method');
    }
  }, [validateChecboxesConvocation]);

  useEffect(() => {
    const res = watchDynamics?.every((communication) => !communication);
    if (res) {
      formIntervenDiffusion.setError('diffusion_dynamic', {
        type: 'manual',
        message: 'Debe existir al menos una dinámica',
      });
    } else {
      formIntervenDiffusion.clearErrors('diffusion_dynamic');
    }
  }, [validateChecboxesDynamics]);

  const handleCreateDiffusionIntervention = (data) => {
    const {
      convocation_method,
      convocation_description,
      convocation_file,
      diffusion_dynamic,
      diffusion_description,
      diffusion_file,
    } = data;

    const bodyFormData = new FormData();
    convocation_method.length > 0 &&
      !convocation_method.every((convocation_method) => !convocation_method) &&
      convocation_method.forEach((convocati, index) => {
        if (convocati) {
          if (index === 0) {
            return bodyFormData.append('convocation_method[]', 'p');
          }

          if (index === 1) {
            return bodyFormData.append('convocation_method[]', 'o');
          }

          if (index === 2) {
            return bodyFormData.append('convocation_method[]', 'c');
          }
        }
      });
    bodyFormData.append('convocation_description', convocation_description);
    convocation_file instanceof File &&
      bodyFormData.append('convocation_file', convocation_file);
    diffusion_dynamic &&
      diffusion_dynamic.length > 0 &&
      !diffusion_dynamic.every((dynamic) => !dynamic) &&
      diffusion_dynamic.forEach((dynamic, index) => {
        if (dynamic) {
          if (index === 0) {
            return bodyFormData.append('diffusion_dynamic[]', 'e');
          }

          if (index === 1) {
            return bodyFormData.append('diffusion_dynamic[]', 's');
          }

          if (index === 2) {
            return bodyFormData.append('diffusion_dynamic[]', 'd');
          }

          if (index === 3) {
            return bodyFormData.append('diffusion_dynamic[]', 'o');
          }
        }
      });

    diffusion_description &&
      bodyFormData.append('diffusion_description', diffusion_description);
    diffusion_file instanceof File &&
      bodyFormData.append('diffusion_file', diffusion_file);

    bodyFormData.append('state', 'iv');
    bodyFormData.append('_method', 'PATCH');

    dispatch(
      actUpdateDefineProjectInterventionArtist(
        {
          intervention_id,
          phase_id: define_intervention.id,
          formData: bodyFormData,
        },
        () => {
          dispatch(actResetDataDiffusionForm());
        }
      )
    );
  };

  const handleOpenPreviewFileInNewTab = ({ event, property }) => {
    event && event.preventDefault();
    if (dataToBePreloaded?.[property]?.blobUrl) {
      window.open(dataToBePreloaded?.[property]?.blobUrl);
    } else if (
      define_intervention?.[property]?.url &&
      !watchDiffusionFile?.size
    ) {
      window.open(define_intervention?.[property]?.url);
    } else {
      const blobUrl = URL.createObjectURL(
        formIntervenDiffusion.watch(property)
      );
      window.open(blobUrl);
    }
  };

  const handleDeleteFileSelected = ({ event, property }) => {
    event && event.preventDefault();
    if (define_intervention.state.id !== 'iv') {
      formIntervenDiffusion.setValue(property, null);
      dispatch(
        actSetDataDiffusionForm({
          convocation_method: watchConvocation && watchConvocation,
          convocation_description: watchDescription && watchDescription,
          convocation_file: property === 'convocation_file' && {
            blobUrl: '',
            fileName: '',
            mimeType: '',
          },
          diffusion_file: property === 'diffusion_file' && {
            blobUrl: '',
            fileName: '',
            mimeType: '',
          },
          diffusion_dynamic: watchDynamics && watchDynamics,
          diffusion_description:
            watchDescriptionDynamics && watchDescriptionDynamics,
        })
      );
    }
  };

  const handleBackButton = () => {
    setFirstOrLastView(1);
    let convocation_file;
    let diffusion_file;

    if (watchDiffusionFile) {
      if (watchDiffusionFile?.size) {
        convocation_file = {
          blobUrl: URL.createObjectURL(watchDiffusionFile),
          fileName: watchDiffusionFile.name,
          mimeType: watchDiffusionFile.type,
        };
      } else if (watchDiffusionFile.name.includes('blob:')) {
        convocation_file = watchDiffusionFile;
      } else if (dataToBePreloaded.convocation_file?.blobUrl === null) {
        convocation_file = {
          blobUrl: null,
          fileName: '',
          mimeType: '',
        };
      } else {
        convocation_file = {
          blobUrl: watchDiffusionFile?.name ? watchDiffusionFile?.name : '',
          fileName: watchDiffusionFile?.name ? watchDiffusionFile?.name : '',
          mimeType: watchDiffusionFile.name ? 'application/pdf' : '',
        };
      }
    }

    if (watchDiffusionFileDynamic) {
      if (watchDiffusionFileDynamic?.size) {
        diffusion_file = {
          blobUrl: URL.createObjectURL(watchDiffusionFileDynamic),
          fileName: watchDiffusionFileDynamic.name,
          mimeType: watchDiffusionFileDynamic.type,
        };
      } else if (watchDiffusionFileDynamic.name.includes('blob:')) {
        diffusion_file = watchDiffusionFileDynamic;
      } else if (dataToBePreloaded.diffusion_file?.blobUrl === null) {
        diffusion_file = {
          blobUrl: null,
          fileName: '',
          mimeType: '',
        };
      } else {
        diffusion_file = {
          blobUrl: watchDiffusionFileDynamic?.name
            ? watchDiffusionFileDynamic?.name
            : '',
          fileName: watchDiffusionFileDynamic?.name
            ? watchDiffusionFileDynamic?.name
            : '',
          mimeType: watchDiffusionFileDynamic.name ? 'application/pdf' : '',
        };
      }
    }

    dispatch(
      actSetDataDiffusionForm({
        convocation_method: watchConvocation && watchConvocation,
        convocation_description: watchDescription ? watchDescription : '',
        convocation_file,
        diffusion_file,
        diffusion_dynamic: watchDynamics && watchDynamics,
        diffusion_description:
          watchDescriptionDynamics && watchDescriptionDynamics,
      })
    );
  };

  const handlePreloadData = async () => {
    if (
      dataToBePreloaded.convocation_method.length > 0 &&
      define_intervention.convocation_method.length > 0
    ) {
      const convocationAux = _.map(
        dataToBePreloaded.convocation_method,
        (convocation, index) => {
          if (
            index === 0 &&
            !convocation &&
            define_intervention.convocation_method.includes('p')
          ) {
            return false;
          } else if (
            index === 0 &&
            !convocation &&
            !define_intervention.convocation_method.includes('p')
          ) {
            return false;
          }

          if (
            index === 1 &&
            !convocation &&
            define_intervention.convocation_method.includes('o')
          ) {
            return false;
          } else if (
            index === 1 &&
            !convocation &&
            !define_intervention.convocation_method.includes('o')
          ) {
            return false;
          }

          if (
            index === 2 &&
            !convocation &&
            define_intervention.convocation_method.includes('c')
          ) {
            return false;
          } else if (
            index === 2 &&
            !convocation &&
            !define_intervention.convocation_method.includes('c')
          ) {
            return false;
          }

          return convocation;
        }
      );
      formIntervenDiffusion.setValue('convocation_method', convocationAux);
    } else if (
      dataToBePreloaded.convocation_method.length > 0 &&
      define_intervention.convocation_method.length === 0
    ) {
      formIntervenDiffusion.setValue(
        'convocation_method',
        dataToBePreloaded.convocation_method
      );
    } else if (
      dataToBePreloaded.convocation_method.length === 0 &&
      define_intervention.convocation_method.length > 0
    ) {
      formIntervenDiffusion.setValue('convocation_method', [
        define_intervention.convocation_method.indexOf('p') !== -1,
        define_intervention.convocation_method.indexOf('o') !== -1,
        define_intervention.convocation_method.indexOf('c') !== -1,
      ]);
    }

    if (
      dataToBePreloaded.convocation_description === null &&
      define_intervention.convocation_description
    ) {
      formIntervenDiffusion.setValue(
        'convocation_description',
        define_intervention.convocation_description
      );
    } else if (
      !define_intervention.convocation_description &&
      dataToBePreloaded.convocation_description
    ) {
      formIntervenDiffusion.setValue(
        'convocation_description',
        dataToBePreloaded.convocation_description
      );
    } else if (
      define_intervention.convocation_description &&
      dataToBePreloaded.convocation_description
    ) {
      formIntervenDiffusion.setValue(
        'convocation_description',
        dataToBePreloaded.convocation_description
      );
    }

    if (
      dataToBePreloaded.diffusion_description === null &&
      define_intervention.diffusion_description
    ) {
      formIntervenDiffusion.setValue(
        'diffusion_description',
        define_intervention.diffusion_description
      );
    } else if (
      !define_intervention.diffusion_description &&
      dataToBePreloaded.diffusion_description
    ) {
      formIntervenDiffusion.setValue(
        'diffusion_description',
        dataToBePreloaded.diffusion_description
      );
    } else if (
      define_intervention.diffusion_description &&
      dataToBePreloaded.diffusion_description
    ) {
      formIntervenDiffusion.setValue(
        'diffusion_description',
        dataToBePreloaded.diffusion_description
      );
    }

    if (
      dataToBePreloaded.convocation_file?.blobUrl !== null &&
      dataToBePreloaded.convocation_file?.blobUrl.includes('blob:')
    ) {
      formIntervenDiffusion.setValue(
        'convocation_file',
        await urltoFile({
          url: dataToBePreloaded.convocation_file.blobUrl,
          fileName: dataToBePreloaded.convocation_file.fileName,
          mimeType: dataToBePreloaded.convocation_file.mimeType,
        })
      );
    } else if (
      dataToBePreloaded.convocation_file?.blobUrl === null &&
      define_intervention.convocation_file?.url
    ) {
      formIntervenDiffusion.setValue('convocation_file', {
        name: define_intervention.convocation_file?.url,
        type: 'application/pdf',
      });
    } else {
      formIntervenDiffusion.setValue('convocation_file', null);
    }

    if (
      dataToBePreloaded.diffusion_file?.blobUrl !== null &&
      dataToBePreloaded.diffusion_file?.blobUrl.includes('blob:')
    ) {
      formIntervenDiffusion.setValue(
        'diffusion_file',
        await urltoFile({
          url: dataToBePreloaded.diffusion_file.blobUrl,
          fileName: dataToBePreloaded.diffusion_file.fileName,
          mimeType: dataToBePreloaded.diffusion_file.mimeType,
        })
      );
    } else if (
      dataToBePreloaded.diffusion_file?.blobUrl === null &&
      define_intervention.diffusion_file?.url
    ) {
      formIntervenDiffusion.setValue('diffusion_file', {
        name: define_intervention.diffusion_file?.url,
        type: 'application/pdf',
      });
    } else {
      formIntervenDiffusion.setValue('diffusion_file', null);
    }

    if (
      dataToBePreloaded.diffusion_dynamic.length > 0 &&
      define_intervention.diffusion_dynamic.length > 0
    ) {
      const dynamicsAux = _.map(
        dataToBePreloaded.diffusion_dynamic,
        (dynamics, index) => {
          if (
            index === 0 &&
            !dynamics &&
            define_intervention.diffusion_dynamic.includes('e')
          ) {
            return false;
          } else if (
            index === 0 &&
            !dynamics &&
            !define_intervention.diffusion_dynamic.includes('e')
          ) {
            return false;
          }

          if (
            index === 1 &&
            !dynamics &&
            define_intervention.diffusion_dynamic.includes('s')
          ) {
            return false;
          } else if (
            index === 1 &&
            !dynamics &&
            !define_intervention.diffusion_dynamic.includes('s')
          ) {
            return false;
          }

          if (
            index === 2 &&
            !dynamics &&
            define_intervention.diffusion_dynamic.includes('d')
          ) {
            return false;
          } else if (
            index === 2 &&
            !dynamics &&
            !define_intervention.diffusion_dynamic.includes('d')
          ) {
            return false;
          }

          if (
            index === 3 &&
            !dynamics &&
            define_intervention.diffusion_dynamic.includes('o')
          ) {
            return false;
          } else if (
            index === 2 &&
            !dynamics &&
            !define_intervention.diffusion_dynamic.includes('o')
          ) {
            return false;
          }

          return dynamics;
        }
      );
      formIntervenDiffusion.setValue('diffusion_dynamic', dynamicsAux);
    } else if (
      dataToBePreloaded.diffusion_dynamic.length > 0 &&
      define_intervention.diffusion_dynamic.length === 0
    ) {
      formIntervenDiffusion.setValue(
        'diffusion_dynamic',
        dataToBePreloaded.diffusion_dynamic
      );
    } else if (
      dataToBePreloaded.diffusion_dynamic.length === 0 &&
      define_intervention.diffusion_dynamic.length > 0
    ) {
      formIntervenDiffusion.setValue('diffusion_dynamic', [
        define_intervention.diffusion_dynamic.indexOf('e') !== -1,
        define_intervention.diffusion_dynamic.indexOf('s') !== -1,
        define_intervention.diffusion_dynamic.indexOf('d') !== -1,
        define_intervention.diffusion_dynamic.indexOf('o') !== -1,
      ]);
    }
  };

  const schemaTaskAssignationForIntervention = yup.object({
    name: yup.string().required(REQUIRED_FIELD).max(100, MAX_LENGTH_100),
    description: yup.string().required(REQUIRED_FIELD).max(500, MAX_LENGTH_500),
  });

  const formCreateTask = useForm({
    resolver: yupResolver(schemaTaskAssignationForIntervention),
    mode: 'onChange',
  });

  const handleCancelCreateEditTask = (event) => {
    event && event.preventDefault();
    setActiveNewTask();
    formCreateTask.reset({ name: '', description: '' });
  };

  const handleTaksAssignationForInterention = (formData) => {
    const { name, description } = formData;
    dispatch(
      actCreateAssignedTasksForIntervention(
        {
          interventionId: intervention_id,
          definitionId: define_intervention.id,
          name,
          description,
        },
        () => {
          handleCancelCreateEditTask();
        }
      )
    );
  };

  const handleDeleteTaskAssignationForIntervention = ({ event, taskId }) => {
    event && event.preventDefault();
    dispatch(
      actDeleteAssignedTasksForIntervention({
        interventionId: intervention_id,
        definitionId: define_intervention.id,
        taskId,
      })
    );
  };

  const handleSetTaskIdForEdit = ({ event, taskData }) => {
    event && event.preventDefault();
    setActiveNewTask();
    setTaskIdForIntervention(taskData.id);
    formCreateTask.setValue('name', taskData.name);
    formCreateTask.setValue('description', taskData.description);
  };

  const handleEditTaskAssignationForIntervention = (data) => {
    const { name, description } = data;
    dispatch(
      actEditAssignedTasksForIntervention(
        {
          interventionId: intervention_id,
          definitionId: define_intervention.id,
          taskId: taskIdForIntervention,
          name,
          description,
        },
        () => {
          handleCancelCreateEditTask();
          setTaskIdForIntervention(null);
        }
      )
    );
  };
  const isEditButtonActiveForTask = useMemo(() => {
    if (taskIdForIntervention) {
      const dataInRedux = _.find(
        define_intervention.convocation_tasks,
        (task) => task.id === taskIdForIntervention
      );

      return _.isEqual(
        {
          name: dataInRedux.name,
          description: dataInRedux.description,
        },
        {
          name: formCreateTask.watch('name').trim(),
          description: formCreateTask.watch('description').trim(),
        }
      );
    }

    return false;
  }, [
    taskIdForIntervention,
    formCreateTask.watch('name'),
    formCreateTask.watch('description'),
  ]);

  return {
    define_intervention,
    formIntervenDiffusion,
    valueCheckBoxes,
    handleCreateDiffusionIntervention,
    handleOpenPreviewFileInNewTab,
    handleDeleteFileSelected,
    handleBackButton,
    setValidateChecboxesConvocation,
    setValidateChecboxesDynamics,
    objetive,

    //Tasks
    activeNewTask,
    setActiveNewTask,
    formCreateTask,
    handleTaksAssignationForInterention,
    handleCancelCreateEditTask,
    handleDeleteTaskAssignationForIntervention,
    handleSetTaskIdForEdit,
    handleEditTaskAssignationForIntervention,
    taskIdForIntervention,
    isEditButtonActiveForTask,
    canEditForm: !_.includes(['iv', 'c'], define_intervention.state?.id),
  };
};

export default useArtistDefineInterventionDiffusion;
