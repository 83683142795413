import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import _ from 'lodash';

//Hooks
import useViews from 'views';

// Styles
import { StyledGroup } from 'styles/formViews/formViews.styles';

const Messages = (props) => {
  const {
    program,
    defaultValueTypeProfundization,
    message,
    handleConfirmMessage,
    handleRejectMessage,
  } = props;
  const { useComponents } = useViews();

  const { useInputs, useTypographies, useButtons } = useComponents();
  const { InputForm, Select, TextArea } = useInputs();
  const { ButtonComponent } = useButtons();

  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const {
    control,
    register,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  return (
    <>
      <StyledGroup col2 noSpace className="mb-4">
        {defaultValueTypeProfundization.id === 'async' && (
          <Select
            name="information"
            id="communities"
            placeholder="Tipo de información a recolectar*"
            control={control}
            defaultValue={{
              label: message.type_information.name,
              value: message.type_information.id,
            }}
            options={[
              {
                label: message.type_information.name,
                value: message.type_information.id,
              },
            ]}
            disabled
            label="Tipo de información a recolectar* "
            className="w-full"
          />
        )}

        <Select
          name="communities"
          id="communities"
          placeholder="Canal de comunicación recomendado"
          control={control}
          defaultValue={{
            label: message.channel.name,
            value: message.channel.value,
          }}
          disabled
          options={[
            {
              label: message.channel.name,
              value: message.channel.value,
            },
          ]}
          label="Canal de comunicación recomendado*"
          className="w-full"
        />
      </StyledGroup>
      {defaultValueTypeProfundization.id !== 'async' && (
        <StyledGroup col2 className="mb-4">
          <TextArea
            control={control}
            name="message"
            placeholder="Me gustaría descubrir..."
            className="w-full col-span-2"
            label="Escribe el mensaje que recibirá la comunidad"
            errors={errors}
            defaultValue={message.body}
            disabled
            hasController
          />
        </StyledGroup>
      )}

      {defaultValueTypeProfundization.id === 'async' && (
        <>
          <TypographyWithTooltip
            text="Pregunta"
            variant="p"
            textTooltip="Pregunta"
            idTooltip="know-ask"
            className="text-neutral-900"
            applyFontBold
          />
          <StyledGroup col2 className="mb-4">
            <InputForm
              name="title"
              label=""
              type="text"
              placeholder="Ingresa la pregunta"
              className="w-full col-span-2"
              errors={errors}
              defaultValue={message.body}
              disabled
              {...register('title')}
            />
          </StyledGroup>
          {message.options &&
            _.map(message.options, (option, index) => (
              <div
                className="flex items-center justify-between space-x-6"
                key={`options-${index + 1}`}
              >
                <TypographyComponent
                  variant="p"
                  text={`Opción ${index + 1}`}
                  className="text-primary font-bold flex-none"
                />
                <InputForm
                  name="title"
                  label=""
                  type="text"
                  placeholder="ingresa la opción 1"
                  className="flex-1"
                  errors={errors}
                  defaultValue={option}
                  disabled
                  {...register('title')}
                />
              </div>
            ))}
        </>
      )}

      {program && (
        <div className="flex items-center space-x-2 mt-10 justify-end">
          <ButtonComponent
            text="Rechazar"
            mode="outline"
            onPress={(e) => handleRejectMessage(e, message)}
          />
          <ButtonComponent
            text="Enviar mensaje"
            onPress={(e) => handleConfirmMessage(e, message)}
          />
        </div>
      )}
    </>
  );
};

Messages.propTypes = {
  program: PropTypes.bool,
  defaultValueTypeProfundization: PropTypes.object,
  message: PropTypes.object.isRequired,
  handleRejectMessage: PropTypes.func,
  handleConfirmMessage: PropTypes.func,
};

export default Messages;
