import useAdminViewInterventionsCommunicationDiffusion from './diffusion';
import useAdminViewInterventionsCollectionInformationControllers from './collectionInformation';

const useAdminViewInterventionsCommunication = () => {
  return {
    useAdminViewInterventionsCommunicationDiffusion,
    useAdminViewInterventionsCollectionInformationControllers,
  };
};
export default useAdminViewInterventionsCommunication;
