//Hooks
import useHelpers from '../../../../../helpers';

const useAdminFestivitiesAndTraditionsSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const listFestivitiesAndTraditionsSelector = createSelector(
    (state) => state.projectSelected,
    (projectSelected) => projectSelected.festivities
  );
  return { listFestivitiesAndTraditionsSelector };
};

export default useAdminFestivitiesAndTraditionsSelectors;
