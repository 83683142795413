import React, { Suspense, lazy, useRef } from 'react';

//Hook
import useViews from 'views';
import useControllers from 'controllers';
import useTabsTrayectoria from './components';

//Styles
const StyledBackButton = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);

const StyledBodyTab = lazy(() =>
  import('styles/tabs/tabs.styles').then((mod) => ({
    default: mod.StyledBodyTab,
  }))
);

const StyledBodyTabsTrayectoria = lazy(() =>
  import('styles/tabs/tabs.styles').then((mod) => ({
    default: mod.StyledBodyTabsTrayectoria,
  }))
);

const AdminDashboardViewInterventionsTrayectory = () => {
  const { useComponents } = useViews();

  const { useTypographies, useBoxes, useTabs, useLoaders } = useComponents();
  const { DefaultLoader } = useLoaders();
  const { TypographyComponent } = useTypographies();
  const { Box } = useBoxes();
  const { Tabs, TabLink } = useTabs();

  const { useGeneralHooks, useScreenHooks } = useControllers();
  const { useSelectLayoutByRole } = useGeneralHooks();
  const { LayoutSelected, props } = useSelectLayoutByRole();

  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const {
    useAdminAbcTrajectoryDashboardHooks,
    useAdminInterventionsDashboardControllers,
  } = useAdminDashboardProjectsHooks();
  const { useAdminAbcTrajectoryDashboardRoot } =
    useAdminAbcTrajectoryDashboardHooks();
  const { tabValue, handleSelectTab } = useAdminAbcTrajectoryDashboardRoot();

  const { InfraestructureA, BehaviorChangeB, Capital } = useTabsTrayectoria();

  const abcTrajectorySelected = useRef({
    0: { componente: <InfraestructureA type="A" />, tabValue: 0 },
    1: { componente: <BehaviorChangeB type="B" />, tabValue: 1 },
    2: { componente: <Capital type="C" />, tabValue: 2 },
  });
  const { useAdminKnowProjectDetailIntervention } =
    useAdminInterventionsDashboardControllers();
  const { goBack } = useAdminKnowProjectDetailIntervention();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <LayoutSelected {...props}>
        <StyledBackButton
          className="mt-3"
          onClick={() => {
            goBack();
          }}
        >
          Regresar
        </StyledBackButton>
        <Box className="bg-neutral-200 w-12/12 mx-auto max-w-screen-2xl lg:px-16 lg:py-12 px-6 py-8 lg:my-8">
          <>
            <TypographyComponent
              text={'Trayectoria ABC'}
              variant="h2"
              className="font-bold text-primary mb-5"
            />
            <TypographyComponent
              text={
                'Para lograr una mejor sinergía entre los componentes A,B y C, utiliza este espacio para contar que se ha hecho en cada uno ellos hasta el momento. '
              }
              variant="p"
              className="mb-12"
            />

            <Tabs
              selected={tabValue}
              onSelect={handleSelectTab}
              className="w-full lg:w-12/12 mb-5 lg:mb-0 whitespace-nowrap overflow-auto lg:whitespace-normal"
            >
              <TabLink label="Infraestructura (A)" tab={0} />
              <TabLink label="Cambio de comportamiento (B)" tab={1} />
              <TabLink label="Capital (C)" tab={2} />
            </Tabs>

            <StyledBodyTabsTrayectoria noneBorder>
              <StyledBodyTab
                active={
                  tabValue === abcTrajectorySelected.current[tabValue].tabValue
                }
              >
                {abcTrajectorySelected.current[tabValue].componente}
              </StyledBodyTab>
            </StyledBodyTabsTrayectoria>
          </>
        </Box>
      </LayoutSelected>
    </Suspense>
  );
};

export default AdminDashboardViewInterventionsTrayectory;
