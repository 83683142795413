import React from 'react';
import PropTypes from 'prop-types';

// Styles
import { StyledDefaultLabel } from './DefaultLabel.styles';

const DefaultLabel = (props) => {
  const { children, className } = props;
  return (
    <StyledDefaultLabel className={className}>{children}</StyledDefaultLabel>
  );
};
DefaultLabel.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
};
export default React.memo(DefaultLabel);
