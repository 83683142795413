//Packages
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

//Hooks
import useStrings from '../../../../../../../strings';
import useApi from '../../../../../../../api';
import useHelpers from '../../../../../../../helpers';
import useModels from '../../../../../../../models';
import useControllers from '../../../../../..';

const useAdminAbcTrajectoryDashboardTabs = ({ type = 'A' }) => {
  const { project_id: projectId } = useParams();

  const [show, setShow] = useState(false);
  const [abcProjectId, setAbcProjectId] = useState(null);
  const [abcProjectSelectedData, setAbcProjectSelectedData] = useState(null);
  const [cleanFileInputs, setCleanFileInputs] = useState(false);
  const [viewAbcProject, setViewAbcProject] = useState(null);

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { REQUIRED_FIELD, FILE_TYPE, FILE_SIZE, MAX_LENGTH } = useFormsTypes();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminAbcTrajectoryActions } = useAdminProjectsActions();
  const {
    actCreateCommunityProjects,
    actDeleteCommunityProjects,
    actUpdateCommunityProjects,
  } = useAdminAbcTrajectoryActions();

  const { useQuickFunctions } = useHelpers();
  const { getFileValidations, getMimeTypeFromMedia } = useQuickFunctions();
  const { MAX_SIZE, SUPPORTED_FORMATS_ONLY_IMAGES } = getFileValidations();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminAbcTrajectorySelectors } = useAdminProjectsSelectors();
  const { abcTrajectoriesListSelector } = useAdminAbcTrajectorySelectors();
  const listAbcProjectsAux = useSelector(abcTrajectoriesListSelector);

  const listAbcProjects = useMemo(() => {
    return _.filter(
      listAbcProjectsAux,
      (abcProject) => abcProject.type === type
    );
  }, [listAbcProjectsAux]);

  const { useGeneralHooks } = useControllers();
  const { useToggleModal } = useGeneralHooks();
  const [showDeleteAbcProjectModal, handleDeleteAbcProjectModal] =
    useToggleModal();
  const [showViewAbcModal, handleViewAbcModal] = useToggleModal();

  const schemaCreateAbcTrajectory = yup.object({
    name: yup.string().required(REQUIRED_FIELD),
    description: yup.string().required(REQUIRED_FIELD).max(40000, MAX_LENGTH),
    image: yup
      .mixed()
      .test('fileType', FILE_TYPE, (value) => {
        if (value && typeof value !== 'string') {
          return SUPPORTED_FORMATS_ONLY_IMAGES.includes(value.type);
        }
        return true;
      })
      .test('fileSize', FILE_SIZE, (value) => {
        if (value && typeof value !== 'string') {
          return value.size <= MAX_SIZE;
        }
        return true;
      })
      .required(REQUIRED_FIELD),
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(schemaCreateAbcTrajectory),
    mode: 'onChange',
  });

  const handleAddCommunity = () => {
    setValue('name', '');
    setValue('description', '');
    setValue('image', null);
    setCleanFileInputs((prevState) => !prevState);
    setShow((prevState) => !prevState);
    setAbcProjectSelectedData(null);
  };

  const handleCancelAbcProject = (e) => {
    e.preventDefault();
    reset({});
    setAbcProjectSelectedData(null);
    setCleanFileInputs((prevState) => !prevState);
    setShow((prevState) => !prevState);
  };

  const handleDeleteCommunity = () => {
    dispatch(
      actDeleteCommunityProjects(
        { projectId, abcTrajectoryId: abcProjectId },
        () => {
          handleDeleteAbcProjectModal();
        }
      )
    );
  };

  const onSuccessHandleRegisterABCproject = () => {
    setValue('name', '');
    setValue('description', '');
    setValue('image', null);
    setCleanFileInputs((prevState) => !prevState);
    setShow((prevState) => !prevState);
  };

  const handleRegisterABCproject = (data) => {
    dispatch(
      actCreateCommunityProjects(
        { ...data, type, projectId },
        onSuccessHandleRegisterABCproject
      )
    );
  };

  const handleUpdateABCproject = (data) => {
    dispatch(
      actUpdateCommunityProjects(
        {
          ...data,
          projectId,
          abcTrajectoryId: abcProjectId,
          type,
        },
        onSuccessHandleRegisterABCproject
      )
    );
  };

  const handleEditABCproject = ({ abcProject }) => {
    setValue('name', abcProject.name);
    setValue('description', abcProject.description);
    setValue('image', abcProject.image.url);

    setAbcProjectSelectedData(abcProject);
    setShow(true);
    setCleanFileInputs((prevState) => !prevState);
  };

  const handleResetInputFile = ({ typeMultimedia }) => {
    setValue(typeMultimedia, null);
    clearErrors(typeMultimedia);
  };

  const handleSelectTypeFile = () => {
    const defaultImage = abcProjectSelectedData?.image?.url;

    return getMimeTypeFromMedia({ urlFile: defaultImage }) || 'image';
  };

  const handleViewAbcProject = (abcProject) => {
    setViewAbcProject(abcProject);
  };

  return {
    control,
    register,
    handleSubmit,
    errors,
    handleRegisterABCproject,
    listAbcProjects,
    handleAddCommunity,
    handleCancelAbcProject,
    handleDeleteCommunity,
    handleEditABCproject,
    show,
    abcProjectSelectedData,
    cleanFileInputs,
    setAbcProjectId,
    handleResetInputFile,
    showDeleteAbcProjectModal,
    handleDeleteAbcProjectModal,
    handleUpdateABCproject,
    handleSelectTypeFile,

    showViewAbcModal,
    handleViewAbcModal,
    handleViewAbcProject,
    viewAbcProject,
  };
};

export default useAdminAbcTrajectoryDashboardTabs;
