import useHelpers from '../../../../helpers';
import useStrings from '../../../../strings';
import useInitialStates from '../../../initialStates';

const useSidebarReducers = () => {
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  const { useGeneralInitialStates } = useInitialStates();
  const { useSidebarInitialStates } = useGeneralInitialStates();
  const { initialStateSidebar } = useSidebarInitialStates();

  const { useGeneralTypes } = useStrings();
  const { useSidebarTypes } = useGeneralTypes();
  const { OPEN_MENU_COLLAPSED } = useSidebarTypes();

  const showMenuCollapsed = createReducer(initialStateSidebar, {
    [OPEN_MENU_COLLAPSED](state, action) {
      const { collapsed, clienteHei } = action.payload;
      return { ...state, showMenuCollapsed: collapsed, clienteHei: clienteHei };
    },
  });

  return { showMenuCollapsed };
};

export default useSidebarReducers;
