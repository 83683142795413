import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const StylesAgentsConten = styled.section.attrs(() => ({
  className: 'StylesAgentsConten',
}))`
  ${tw` mb-6 flex`}
}
`;

export const StyledAgentsList = styled.ul.attrs(() => ({
  className: 'StyledAgentsList',
}))`
  ${tw` bg-white px-2 py-2 overflow-auto rounded-lg bg-white mt-4`}
  max-height:20rem;
`;
export const StyledItem = styled.li.attrs(() => ({
  className: 'StyledItem',
}))`
  ${tw` border-b`}
  .StyledCardUser {
    ${tw` border-none`}
    .StyledInformation {
      ${tw` ml-8`}
    }
    h3 {
      ${tw` text-base leading-none`}
    }
    h5 {
      ${tw` text-neutral-800`}
    }
  }
  .StyledContentButtons {
    ${tw` absolute right-0 mt-4`}
  }
`;
export const StyledListSelectedAgents = styled.ul.attrs(() => ({
  className: 'StyledListSelectedAgents',
}))`
  ${tw` flex items-center space-x-4 `}
`;
export const StyledListSelectedAgentsItem = styled.li.attrs(() => ({
  className: 'StyledListSelectedAgentsItem',
}))`
  ${tw` bg-white rounded border px-4 py-2 font-bold text-primary relative`}
  &:hover {
    > .StyledDetailInformation {
      ${tw` opacity-100`}
    }
  }
`;
export const StyledDetailInformation = styled.div.attrs(() => ({
  className: 'StyledDetailInformation',
}))`
  ${tw` bg-white rounded-lg  px-10 py-6 absolute top-0 left-full z-10 mt-4 -ml-4 opacity-0 transition-all duration-200 pointer-events-none`}
  width:375px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
`;
