//Hooks
import useApi from '../../..';
import useStrings from '../../../../strings';

const usePreRegisterActions = () => {
  const { useProviders } = useApi();
  const { useAuthProviders } = useProviders();
  const { usePreRegisterProviders } = useAuthProviders();
  const { preRegisterProvider } = usePreRegisterProviders();

  //Strings
  const { useAuthTypes } = useStrings();
  const { PRE_REGISTER } = useAuthTypes();

  const actPreRegister = (user, onSuccess, onError) => async (dispatch) => {
    try {
      const res = await preRegisterProvider({ user });
      dispatch({ type: PRE_REGISTER });
      onSuccess && onSuccess(res);
    } catch (error) {
      onError && onError(error);
    }
  };
  return { actPreRegister };
};

export default usePreRegisterActions;
