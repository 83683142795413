//Packages
import { useEffect, useRef, useState } from 'react';

const useUploadImagePreview = ({
  onChange,
  resetInput,
  cleanFileInputs,
  srcDefaultImage,
  isAVideo,
  removeMedia,
  putMedia,
}) => {
  const inputRef = useRef(null);
  const videoRef = useRef(null);

  const [blobFile, setBlobFile] = useState({
    blobUrl: '',
    mimeType: 'image',
  });
  const [isAVideoFromInput, setIsAVideoFromInput] = useState(false);

  const handleOpenSelectFile = () => inputRef.current.click();

  useEffect(() => {
    if (cleanFileInputs) {
      if (inputRef.current?.value) {
        inputRef.current.value = null;
      }
      setBlobFile({ blobUrl: '', mimeType: 'image' });
    }
  }, [cleanFileInputs]);

  useEffect(() => {
    if (srcDefaultImage) {
      setBlobFile((prevState) => ({ ...prevState, blobUrl: srcDefaultImage }));
    }
  }, [srcDefaultImage]);

  useEffect(() => {
    if (isAVideo) {
      setBlobFile((prevState) => ({ ...prevState, mimeType: isAVideo }));
    }
  }, [isAVideo]);

  useEffect(() => {
    if (isAVideoFromInput && videoRef.current) {
      videoRef.current.src = blobFile.blobUrl;
      videoRef.current.type = blobFile.mimeType;
      setIsAVideoFromInput(false);
    }
  }, [isAVideoFromInput, videoRef.current]);

  const handleFileToBlobFile = (event) => {
    const file = event.target.files[0];
    onChange && onChange(file);
    if (file && putMedia) {
      const blobUrl = URL.createObjectURL(file);
      const mimeType = file.type;
      setBlobFile({ blobUrl, mimeType });

      if (mimeType.includes('video')) {
        setIsAVideoFromInput(true);
      }
    } else {
      setBlobFile({ blobUrl: '', mimeType: 'image' });
    }
  };

  const handleRemoveFile = () => {
    resetInput && resetInput();
    if (removeMedia) {
      inputRef.current.value = null;
      setTimeout(() => {
        setBlobFile({ blobUrl: '', mimeType: 'image' });
      }, 500);
    }
  };

  return {
    inputRef,
    handleOpenSelectFile,
    blobFile,
    handleFileToBlobFile,
    isVideo: blobFile.mimeType.includes('video'),
    handleRemoveFile,
    videoRef,
  };
};

export default useUploadImagePreview;
