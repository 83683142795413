import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const StyledContetCollapse = styled.div.attrs(() => ({
  className: 'StyledContetCollapse',
}))`
  ${tw`  overflow-hidden order-6 lg:order-none`}
  transition: all 1s;
  ${(props) => (props.isCollapsed ? tw`h-0` : `height:${props.clientHei}px`)}
`;

export const StyledCollapseDetailContent = styled.div.attrs(() => ({
  className: 'StyledCollapseDetailContent',
}))`
  ${tw` rounded-b-lg border px-6 py-5  flex flex-col items-end `}
`;

export const StyledMultimedia = styled.div.attrs(() => ({
  className: 'StyledMultimedia',
}))`
  ${tw` w-5/12 grid grid-cols-2 gap-3 `}
  img {
    ${tw` rounded-lg object-center object-cover h-28 w-full`}
    &:first-child {
      ${tw` col-span-2 h-40 w-full`}
    }
  }
`;

export const SttledContentInformation = styled.div.attrs(() => ({
  className: 'SttledContentInformation',
}))`
  ${tw`  w-7/12`}
  ${(props) => props.fullWidth && tw`w-full `}
`;

export const StyledContentCols = styled.div.attrs(() => ({
  className: 'StyledContentCols',
}))`
  ${tw`  flex space-x-10 w-full`}
`;
