import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';

// Hooks
import useModels from 'models';
import useApi from 'api';
import useStrings from 'strings';
import useHelpers from 'helpers'

const useAdminProgramPilotIntervention = () => {
  const { intervention_id: interventionId } = useParams();

  const { useActions } = useApi();
  const { dispatch, useAdminActions, useUserActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { actSearchUser } = useUserActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();
  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const { useAdminDevelopmentInterventionCreateSketchActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const {
    actGetProgramPilotIntervention,
    actUpdateProgramPilotIntervention,
    actCallAgentProgramPilotIntervention
  } = useAdminDevelopmentInterventionCreateSketchActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors, useUserSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionsSelector } = useAdminInterventionsSelectors();
  const { usersSelector } = useUserSelectors();
  const { programPilot } = useSelector(getInterventionsSelector);
  const { id: programPilotId, pilotPlan } = programPilot;
  const { data: agents } = useSelector(usersSelector);

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { REQUIRED_FIELD, MAX_LENGTH_100 } = useFormsTypes();

  const { useQuickFunctions } = useHelpers()
  const { setDefaultValueInputDateTimeWithTimeLocalZone } = useQuickFunctions()

  const [showModalApprove, setShowModalApprove] = useState(false);

  const schemaFormProgramPilot = yup.object({
    place: yup.string().typeError(REQUIRED_FIELD).max(100, MAX_LENGTH_100).required(REQUIRED_FIELD)
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schemaFormProgramPilot),
    mode: 'onChange',
  });

  useEffect(() => {
    if (programPilot.state?.id !== 'c') {
      dispatch(actGetProgramPilotIntervention({
        interventionId,
      }));
    }
  }, [])

  useEffect(() => {
    reset({
      community: { value: pilotPlan.community?.id, label: pilotPlan.community?.name },
      modality: { value: pilotPlan.modality?.id, label: pilotPlan.modality?.name },
      date: pilotPlan.date,
      start_time: pilotPlan.start_time
        ? setDefaultValueInputDateTimeWithTimeLocalZone({
          dateInUTC: `2022-05-05T${pilotPlan.start_time}`
        })
          .split('.')[0]
          .split('T')[1]
          .slice(0, 5)
        : '',
      end_time: pilotPlan.end_time
        ? setDefaultValueInputDateTimeWithTimeLocalZone({
          dateInUTC: `2022-05-05T${pilotPlan.end_time}`
        })
          .split('.')[0]
          .split('T')[1]
          .slice(0, 5)
        : '',
      participants: pilotPlan.participants,
      place: programPilot.place
    })
  }, [pilotPlan.id])

  const getChangeAgents = (filter) => {
    dispatch(
      actSearchUser({
          ...filter,
          roles: [{ value: 'ca' }],
        },
        'contacts')
    )
  }

  const assignChangeAgent = (agent) => {
    dispatch(
      actCallAgentProgramPilotIntervention({
        interventionId,
        programPilotId,
        data: { user_id: agent.id }
      })
    )
  }

  const updateProgramPilotIntervention = ({ place }) => {
    setShowModalApprove(false)
    dispatch(actUpdateProgramPilotIntervention({
      interventionId,
      programPilotId,
      data: {
        place,
        state: 'ip'
      }
    }))
  }

  return {
    programPilot,
    pilotPlan,
    agents,
    handleSubmit,
    control,
    errors,
    showModalApprove,
    setShowModalApprove,
    stepCompleted: _.includes(['ip', 'c'], programPilot.state?.id),
    getChangeAgents,
    assignChangeAgent,
    updateProgramPilotIntervention
  }
}

export default useAdminProgramPilotIntervention