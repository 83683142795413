//Packages
import React from 'react';
import _ from 'lodash';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
} from 'react-accessible-accordion';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';

//Styles
import { StyledContentButtons } from 'styles/tableViews/tableViews.styles';

const List = (props) => {
  const {
    listTasks,
    handleDeleteTaskAssignationForIntervention,
    handleSetTaskIdForEdit,
    disabled,
  } = props;

  const { useComponents } = useViews();
  const { useTypographies, useButtons } = useComponents();
  const { TypographyComponent } = useTypographies();
  const { ButtonComponent } = useButtons();

  return (
    <>
      <Accordion allowZeroExpanded>
        {listTasks.length > 0 &&
          _.map(listTasks, (task, index) => {
            return (
              <div className="mt-2 bg-white" key={`itemIntnternv-${index}`}>
                <AccordionItem>
                  <div className="flex items-center justify-between px-6 py-2">
                    <TypographyComponent
                      variant="p"
                      text={task.name}
                      className="flex-grow text-primary font-bold"
                    />
                    <StyledContentButtons>
                      <AccordionItemButton>
                        <ButtonComponent
                          text=""
                          className={`icon-edit ${disabled && 'icon-disabled'}`}
                          variant="white"
                          onPress={(event) => {
                            handleSetTaskIdForEdit({ event, taskData: task });
                          }}
                          disabled={disabled}
                        />
                      </AccordionItemButton>
                      <ButtonComponent
                        text=""
                        className={`icon-delete ${disabled && 'icon-disabled'}`}
                        onPress={(event) => {
                          handleDeleteTaskAssignationForIntervention({
                            event: event,
                            taskId: task.id,
                          });
                        }}
                        disabled={disabled}
                      />
                    </StyledContentButtons>
                  </div>
                </AccordionItem>
              </div>
            );
          })}
      </Accordion>
    </>
  );
};

List.propTypes = {
  listTasks: PropTypes.array,
  handleDeleteTaskAssignationForIntervention: PropTypes.func,
  handleSetTaskIdForEdit: PropTypes.func,
  disabled: PropTypes.bool,
};

List.defaultProps = {
  listTasks: [],
  handleDeleteTaskAssignationForIntervention: () => {},
  handleSetTaskIdForEdit: () => {},
  disabled: false,
};

export default List;
