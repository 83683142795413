import React, { lazy } from 'react';
import PropTypes from 'prop-types';

//Styles
const StyledBadge = lazy(() =>
  import('./DefaultBadge.styles').then((mod) => ({
    default: mod.StyledBadge,
  }))
);

const DefaultBadge = (props) => {
  const { label, type, variant, disabled, className, colorLevel, onClick } =
    props;

  return (
    <StyledBadge
      className={className}
      disabled={disabled}
      variant={variant}
      type={type}
      colorLevel={colorLevel}
      onClick={onClick}
    >
      {label}
    </StyledBadge>
  );
};

DefaultBadge.defaultProps = {
  label: '',
  type: 'solid',
  variant: 'primary',
  disabled: false,
  className: '',
  colorLevel: '500',
  onClick: () => {},
};

DefaultBadge.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'error',
    'warning',
    'success',
  ]),
  type: PropTypes.oneOf(['light', 'solid']),
  colorLevel: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default DefaultBadge;
