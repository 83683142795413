import useAdminProjects from './projects';
import useUserAdmin from './user';
import useAdminLabels from './Labels';
import useAdminHome from './home';
import useAdminContentManagementInterventionsControllers from './interventions';

const useAdmin = () => {
  return {
    useUserAdmin,
    useAdminLabels,
    useAdminProjects,
    useAdminHome,
    useAdminContentManagementInterventionsControllers,
  };
};
export default useAdmin;
