//Hooks
import useLoginActions from './login';
import useLogoutActions from './logout';
import useRecoverPasswordActions from './recoverPassword';
import usePreRegisterActions from './pre-register';

const useAuthActions = () => {
  return {
    useLoginActions,
    useLogoutActions,
    useRecoverPasswordActions,
    usePreRegisterActions,
  };
};

export default useAuthActions;
