import useHelpers from '../../../../helpers';
import useStrings from '../../../../strings';
import useInitialStates from '../../../initialStates';

const useTabSelectedReducers = () => {
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  //Initial States
  const { useGeneralInitialStates } = useInitialStates();
  const { useTabSelectedInitialStates } = useGeneralInitialStates();
  const { initialStateTabSelected, initialStateSubTabSelected } =
    useTabSelectedInitialStates();

  //Strings
  const { useGeneralTypes } = useStrings();
  const { useTabSelectedTypes } = useGeneralTypes();
  const {
    SET_TAB_SELECTED,
    RESET_TAB_SELECTED,
    SET_SUB_TAB_SELECTED,
    RESET_SUB_TAB_SELECTED,
  } = useTabSelectedTypes();

  const tabSelected = createReducer(initialStateTabSelected, {
    [SET_TAB_SELECTED](state, action) {
      return action.payload;
    },
    [RESET_TAB_SELECTED]() {
      return initialStateTabSelected;
    },
  });

  const subTabSelected = createReducer(initialStateSubTabSelected, {
    [SET_SUB_TAB_SELECTED](state, action) {
      return action.payload;
    },
    [RESET_SUB_TAB_SELECTED]() {
      return initialStateTabSelected;
    },
  });

  return { tabSelected, subTabSelected };
};

export default useTabSelectedReducers;
