const useAdminDocumentationTypes = () => {
  const GET_DOCUMENTATION_BY_PROJECT = 'GET_DOCUMENTATION_BY_PROJECT';
  const CREATE_DOCUMENTATION_BY_PROJECT = 'CREATE_DOCUMENTATION_BY_PROJECT';
  const DELETE_DOCUMENTATION_BY_PROJECT = 'DELETE_DOCUMENTATION_BY_PROJECT';
  const DOWNLOAD_DOCUMENTATION_BY_PROJECT = 'DOWNLOAD_DOCUMENTATION_BY_PROJECT';

  return {
    GET_DOCUMENTATION_BY_PROJECT,
    CREATE_DOCUMENTATION_BY_PROJECT,
    DELETE_DOCUMENTATION_BY_PROJECT,
    DOWNLOAD_DOCUMENTATION_BY_PROJECT,
  };
};

export default useAdminDocumentationTypes;
