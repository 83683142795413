import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useApi from '../../../api';

const useInterventionDetail = () => {
  const { useActions } = useApi();
  const history = useHistory();

  const { dispatch, useAdminActions } = useActions();
  const { useHomeInterventionsDetailActions } = useAdminActions();
  const { actGetIntervention } = useHomeInterventionsDetailActions();
  const [intervention, setIntervention] = useState();

  const { interventionId } = useParams();

  const getDataIntervention = (data) => {
    setIntervention(data.data.data);
  };

  useEffect(() => {
    dispatch(
      actGetIntervention(
        { intervention_id: interventionId },
        getDataIntervention
      )
    );
  }, []);

  const handleGoHome = () => {
    history.push('./');
  };

  return { intervention, handleGoHome };
};
export default useInterventionDetail;
