//Packages
import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Components
import Create from './components/tasks/Create';
import List from './components/tasks/List';

//Styles
import { StyledGroup } from 'styles/formViews/formViews.styles';
import { StyledButtonsActions } from 'styles/buttons/buttons.styles';
import { StyledDivider } from 'styles/formViews/formViews.styles';

const SpreadIntervention = (props) => {
  const { setFirstOrLastView } = props;
  const { useComponents } = useViews();

  const { useTypographies, useInputs, useButtons, useCards } = useComponents();
  const { TypographyComponent, TypographyWithTooltip } = useTypographies();
  const { TextArea, InputFile, CheckBox } = useInputs();
  const { ButtonComponent } = useButtons();
  const { CardNotification } = useCards();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsDevelopmentIntervention } =
    useAdminViewInterventionsControllers();
  const { useDefineInterventionInterventions } =
    useAdminViewInterventionsDevelopmentIntervention();
  const { useArtistDefineInterventionInterventionsControllers } =
    useDefineInterventionInterventions();
  const { useArtistDefineInterventionDiffusion } =
    useArtistDefineInterventionInterventionsControllers();
  const {
    define_intervention,
    formIntervenDiffusion,
    valueCheckBoxes,
    objetive,
    activeNewTask,
    setActiveNewTask,
    handleCreateDiffusionIntervention,
    handleOpenPreviewFileInNewTab,
    handleDeleteFileSelected,
    handleBackButton,
    setValidateChecboxesConvocation,
    setValidateChecboxesDynamics,
    formCreateTask,
    handleTaksAssignationForInterention,
    handleCancelCreateEditTask,
    handleDeleteTaskAssignationForIntervention,
    handleSetTaskIdForEdit,
    handleEditTaskAssignationForIntervention,
    taskIdForIntervention,
    isEditButtonActiveForTask,
    canEditForm,
  } = useArtistDefineInterventionDiffusion({ setFirstOrLastView });

  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = formIntervenDiffusion;

  return (
    <>
      {!canEditForm && (
        <CardNotification
          className="w-5/12 mx-auto py-9 px-4 mt-16"
          title={
            <TypographyComponent
              text={define_intervention.state.name}
              variant="h2"
              className="font-bold text-primary icon-watch text-center"
            />
          }
        />
      )}
      <TypographyComponent
        text={
          objetive === 'Inspirar'
            ? 'Definir convocatoria'
            : 'Definir convocatoria y difusión'
        }
        variant="h2"
        className="text-primary mt-2 font-bold"
      />
      <TypographyComponent
        text="En este paso te invitamos a identificar el proceso de convocatoria donde invitas a los participantes a ser parte de esta intervención y difusión."
        variant="p"
        className="text-neutral-950 mt-2 mb-6"
      />

      <form className="">
        <StyledGroup col2 flex className="mb-4 flex-col">
          <TypographyWithTooltip
            text="Métodos para convocar a la intervención*"
            variant="h3"
            textTooltip="Métodos para convocar a la presentación"
            className="text-primary mb-4"
            idTooltip="aspects_project"
            applyFontBold
          />
          <StyledGroup flex noSpace className="mb-4 flex-col">
            {_.map(valueCheckBoxes.convocation, (convocation, index) => {
              const watchConvocation = watch('convocation_method');
              return (
                <Controller
                  key={`convocation_method[${index}]`}
                  control={control}
                  name={`convocation_method[${index}]`}
                  render={({ field: { onChange, name } }) => {
                    return (
                      <CheckBox
                        className="pb-4"
                        name={name}
                        label={convocation.label}
                        value={convocation.value}
                        checked={!!watchConvocation?.[index]}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          setValidateChecboxesConvocation(
                            (prevState) => !prevState
                          );
                        }}
                        withDefaultChecked={false}
                        disabled={!canEditForm}
                        errors={errors}
                        errorsCheckbox={!!errors['convocation_method']}
                        dontShowErrorMessage
                      />
                    );
                  }}
                />
              );
            })}
            {errors['convocation_method'] && (
              <p className="text-sm mt-2 text-error">
                {errors['convocation_method'].message}
              </p>
            )}
          </StyledGroup>
        </StyledGroup>

        <StyledGroup col2 className="mb-4">
          <TextArea
            name="convocation_description"
            placeholder="Qué actividades realizaré"
            className="w-full col-span-2"
            label="Descripción de la convocatoria*"
            errors={errors}
            {...register('convocation_description')}
            disabled={!canEditForm}
          />
        </StyledGroup>

        <TypographyComponent
          text="Metodología de la convocatoria*"
          variant="h3"
          className="text-primary mt-12 font-bold"
        />
        <TypographyComponent
          text="Te invitamos a adjuntar la información detallada sobre la metodología que utilizaras para convocar los participantes."
          variant="p"
          className="text-neutral-950 mt-2 mb-4"
        />
        {(!watch('convocation_file')?.name || errors['convocation_file']) && (
          <Controller
            name="convocation_file"
            control={control}
            render={({ field: { onChange, name } }) => {
              return (
                <InputFile
                  name={name}
                  accept={'.pdf'}
                  title={
                    <div className="flex items-center">
                      <div
                        className={`icon-paper-clip text-primary text-xl font-bold h-10 ${
                          errors[name] && 'text-error'
                        }`}
                      />
                      <TypographyComponent
                        text="Adjuntar archivo"
                        variant="p"
                        className={`text-primary font-bold ${
                          errors[name] && 'text-error'
                        }`}
                      />
                    </div>
                  }
                  onChange={onChange}
                  className={` w-full mt-2 bg-none mb-4 ${
                    errors[name] && 'border-error'
                  }`}
                  errors={errors}
                />
              );
            }}
          />
        )}

        {watch('convocation_file')?.name && !errors['convocation_file'] && (
          <div className="bg-white py-2 px-4 flex justify-between rounded-sm items-center">
            <TypographyComponent
              variant="p"
              text={watch('convocation_file')?.name}
              className="flex-grow text-primary font-bold"
            />
            <StyledButtonsActions className="flex items-center">
              <ButtonComponent
                text=""
                className="icon-view"
                onPress={(event) => {
                  handleOpenPreviewFileInNewTab({
                    event,
                    property: 'convocation_file',
                  });
                }}
              />
              {canEditForm && (
                <ButtonComponent
                  text=""
                  className={'icon-delete'}
                  onPress={(event) => {
                    handleDeleteFileSelected({
                      event,
                      property: 'convocation_file',
                    });
                  }}
                />
              )}
            </StyledButtonsActions>
          </div>
        )}

        <TypographyComponent
          text="Asignación de tareas para la materialización de la convocatoria*"
          variant="h3"
          className="text-primary mt-12 font-bold"
        />
        <TypographyComponent
          text="Enumera aquí las tareas claves para llevar a cabo la convocatoria, e identifica los responsables para realizar cada una de estas tareas."
          variant="p"
          className="text-neutral-950 mt-2 mb-4"
        />

        {define_intervention.convocation_tasks.length > 0 && !activeNewTask && (
          <List
            listTasks={define_intervention.convocation_tasks}
            handleDeleteTaskAssignationForIntervention={
              handleDeleteTaskAssignationForIntervention
            }
            handleSetTaskIdForEdit={handleSetTaskIdForEdit}
            disabled={!canEditForm}
          />
        )}

        {!activeNewTask && canEditForm && (
          <ButtonComponent
            text="Crear nueva tarea"
            icon={<div className="icon-close transform rotate-45 text-sm" />}
            onPress={setActiveNewTask}
            variant="primary"
            mode="outline"
            className={`mt-4 border-none shadow-none px-0 `}
            disabled={define_intervention.state.id === 'iv'}
          />
        )}

        {activeNewTask && (
          <Create
            setActiveNewTask={setActiveNewTask}
            formCreateTask={formCreateTask}
            handleTaksAssignationForInterention={
              handleTaksAssignationForInterention
            }
            handleCancelCreateEditTask={handleCancelCreateEditTask}
            handleEditTaskAssignationForIntervention={
              handleEditTaskAssignationForIntervention
            }
            taskIdForIntervention={taskIdForIntervention}
            isEditButtonActiveForTask={isEditButtonActiveForTask}
          />
        )}

        {objetive !== 'Inspirar' && (
          <>
            <StyledDivider spacexs />

            <TypographyWithTooltip
              text="Dinámica de la difusión*"
              variant="h3"
              textTooltip="Dinámica de la difusión"
              className="text-primary mb-4 mt-6"
              idTooltip="dinamic_difition"
              applyFontBold
            />

            <TypographyComponent
              text="Recuerda que la intervención incluye una difusión al final del proceso. Te invitamos a identificar cuál(es) serán los medios utilizados para esta etapa."
              variant="p"
              className="text-neutral-950 mt-2 mb-4"
            />

            <StyledGroup flex noSpace className="mb-4 flex-col">
              {_.map(valueCheckBoxes.dynamics, (dynamic, index) => {
                const watchDynamics = watch('diffusion_dynamic');
                return (
                  <Controller
                    key={`diffusion_dynamic[${index}]`}
                    control={control}
                    name={`diffusion_dynamic[${index}]`}
                    render={({ field: { onChange, name } }) => {
                      return (
                        <CheckBox
                          className="pb-4"
                          name={name}
                          label={dynamic.label}
                          value={dynamic.value}
                          checked={!!watchDynamics?.[index]}
                          onChange={(e) => {
                            onChange(e.target.checked);
                            setValidateChecboxesDynamics(
                              (prevState) => !prevState
                            );
                          }}
                          withDefaultChecked={false}
                          disabled={!canEditForm}
                          errors={errors}
                          errorsCheckbox={!!errors['diffusion_dynamic']}
                          dontShowErrorMessage
                        />
                      );
                    }}
                  />
                );
              })}
              {errors['diffusion_dynamic'] && (
                <p className="text-sm mt-2 text-error">
                  {errors['diffusion_dynamic'].message}
                </p>
              )}
            </StyledGroup>

            <StyledGroup col2 className="mb-4">
              <TextArea
                name="diffusion_description"
                placeholder="Describe cómo se va a realizar la difusión"
                className="w-full col-span-2"
                label="Descripción de la difusión*"
                errors={errors}
                {...register('diffusion_description')}
                disabled={!canEditForm}
              />
            </StyledGroup>

            <TypographyComponent
              text="Metodología de la difusión*"
              variant="h3"
              className="text-primary mt-4 font-bold"
            />
            <TypographyComponent
              text="Adjunta un documento explicativo con la informacion detallada de la metodología incluyendo participantes claves y sus responsabilidades."
              variant="p"
              className="text-neutral-950 mt-2 mb-4"
            />

            {(!watch('diffusion_file')?.name || errors['diffusion_file']) && (
              <Controller
                control={control}
                name="diffusion_file"
                render={({ field: { onChange, name } }) => {
                  return (
                    <InputFile
                      name={name}
                      accept={'.pdf'}
                      title={
                        <div className="flex items-center">
                          <div
                            className={`icon-paper-clip text-primary text-xl font-bold h-10 ${
                              errors[name] && 'text-error'
                            }`}
                          />
                          <TypographyComponent
                            text="Adjuntar archivo"
                            variant="p"
                            className={`text-primary font-bold ${
                              errors[name] && 'text-error'
                            }`}
                          />
                        </div>
                      }
                      onChange={onChange}
                      className={`w-full mt-2 bg-none mb-4 ${
                        errors[name] && 'border-error'
                      }`}
                      errors={errors}
                    />
                  );
                }}
              />
            )}

            {watch('diffusion_file')?.name && !errors['diffusion_file'] && (
              <div className="bg-white py-2 px-4 flex justify-between rounded-sm items-center">
                <TypographyComponent
                  variant="p"
                  text={watch('diffusion_file')?.name}
                  className="flex-grow text-primary font-bold"
                />
                <StyledButtonsActions className="flex items-center">
                  <ButtonComponent
                    text=""
                    className="icon-view"
                    onPress={(event) => {
                      handleOpenPreviewFileInNewTab({
                        event,
                        property: 'diffusion_file',
                      });
                    }}
                  />
                  {canEditForm && (
                    <ButtonComponent
                      text=""
                      className={'icon-delete'}
                      onPress={(event) => {
                        handleDeleteFileSelected({
                          event,
                          property: 'diffusion_file',
                        });
                      }}
                    />
                  )}
                </StyledButtonsActions>
              </div>
            )}
          </>
        )}
      </form>

      {/* {define_intervention.state.id !== 'c' && ( */}
      <div className="flex items-center space-x-2 mt-10 justify-center">
        <ButtonComponent
          text="Regresar"
          mode="outline"
          onPress={handleBackButton}
        />

        <ButtonComponent
          text="Enviar al socio ejecutor"
          onPress={handleSubmit(handleCreateDiffusionIntervention)}
          disabled={!canEditForm}
        />
      </div>
      {/* )} */}
    </>
  );
};

SpreadIntervention.propTypes = {
  setFirstOrLastView: PropTypes.func,
};

SpreadIntervention.defaultProps = {
  setFirstOrLastView: () => {},
};

export default SpreadIntervention;
