//Packages
import { useMemo, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';

//Hooks
import useControllers from 'controllers';
import useStrings from 'strings';
import useModels from 'models';
import useApi from 'api';

const useArtistDefineInterventionLogisticsMeans = () => {
  const [logisticMeanDataToEdit, setLogisticMeanDataToEdit] = useState({
    id: '',
    describe: '',
    type: '',
  });

  const { useGeneralHooks } = useControllers();
  const { useToggleModal } = useGeneralHooks();
  const [
    showCreateAndEditLogisticsMeans,
    handleVisiblityCreateAndEditLogisticsMeans,
  ] = useToggleModal();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();
  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const { useAdminDevelopmentInterventionDefineInterventionActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const {
    actCreateLogisticMeanIntoIntervention,
    actDeleteLogisticMeanIntoIntervention,
    actEditLogisticMeanIntoIntervention,
  } = useAdminDevelopmentInterventionDefineInterventionActions();

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { REQUIRED_FIELD, MAX_LENGTH_100 } = useFormsTypes();

  const { useSelectors } = useModels();
  const { useAdminSelectors, useSelector } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionsSelector, interventionDefinitionIdSelectedSelector } =
    useAdminInterventionsSelectors();
  const { define_intervention } = useSelector(getInterventionsSelector);
  const { id: interventionIdToDeleteEdit } = useSelector(
    interventionDefinitionIdSelectedSelector
  );

  const schemaDefineLogisticsMeansForIntervention = yup.object({
    describe: yup.string().required(REQUIRED_FIELD).max(100, MAX_LENGTH_100),
    type: yup.object().required(REQUIRED_FIELD).nullable(),
  });

  const formLogisticsMeans = useForm({
    resolver: yupResolver(schemaDefineLogisticsMeansForIntervention),
    mode: 'onChange',
  });

  const listOfLogisticsMeans = useMemo(() => {
    const response = _.find(
      define_intervention.definition_interventions,
      (definition) =>
        parseInt(definition.id) === parseInt(interventionIdToDeleteEdit)
    );
    return response?.resources || [];
  }, [
    define_intervention.definition_interventions,
    interventionIdToDeleteEdit,
  ]);

  const isLogisticMeanEditButtonDisabled = useMemo(() => {
    if (logisticMeanDataToEdit.id && formLogisticsMeans.getValues()?.describe) {
      const objectLogisticMeanEditMode = {
        describe: logisticMeanDataToEdit.describe.trim(),
        type: logisticMeanDataToEdit.type.id,
      };
      const objectCurrentLogisticMeanEditMode = {
        describe: formLogisticsMeans.getValues().describe.trim(),
        type: formLogisticsMeans.getValues().type.value,
      };

      return _.isEqual(
        objectLogisticMeanEditMode,
        objectCurrentLogisticMeanEditMode
      );
    }
    return false;
  }, [
    formLogisticsMeans.watch('describe'),
    formLogisticsMeans.watch('type'),
    logisticMeanDataToEdit.id,
  ]);

  const handleResetLogisticMeanDataToEdit = () =>
    setLogisticMeanDataToEdit({
      id: '',
      describe: '',
      type: '',
    });

  const handleCancelCreateOrEditLogistic = (event) => {
    event && event.preventDefault();
    formLogisticsMeans.reset({
      describe: '',
      type: '',
    });
    handleVisiblityCreateAndEditLogisticsMeans();
    logisticMeanDataToEdit.id && handleResetLogisticMeanDataToEdit();
  };

  const handleCreateOrEditLogisticMean = (data) => {
    const { describe, type } = data;
    if (logisticMeanDataToEdit.id) {
      dispatch(
        actEditLogisticMeanIntoIntervention(
          {
            definition: define_intervention.id,
            definition_intervention: interventionIdToDeleteEdit,
            logisticMeanId: logisticMeanDataToEdit.id,
            describe,
            type: type.value,
          },
          () => {
            handleCancelCreateOrEditLogistic();
          }
        )
      );
    } else {
      dispatch(
        actCreateLogisticMeanIntoIntervention(
          {
            definition: define_intervention.id,
            definition_intervention: interventionIdToDeleteEdit,
            describe,
            type: type.value,
          },
          () => {
            handleCancelCreateOrEditLogistic();
          }
        )
      );
    }
  };

  const handleDeleteLogisticMean = ({ logisticMeanId }) => {
    dispatch(
      actDeleteLogisticMeanIntoIntervention({
        definition: define_intervention.id,
        definition_intervention: interventionIdToDeleteEdit,
        logisticMeanId,
      })
    );
  };

  const onSelectLogisticMeanForEdit = ({ data }) => {
    handleVisiblityCreateAndEditLogisticsMeans();
    setLogisticMeanDataToEdit(data);
    formLogisticsMeans.setValue('describe', data.describe);
    formLogisticsMeans.setValue('type', {
      value: data.type.id,
      label: data.type.name,
    });
  };

  return {
    define_intervention,
    showCreateAndEditLogisticsMeans,
    handleVisiblityCreateAndEditLogisticsMeans,
    formLogisticsMeans,
    listOfLogisticsMeans,
    logisticMeanDataToEdit,
    isLogisticMeanEditButtonDisabled,
    handleCancelCreateOrEditLogistic,
    handleCreateOrEditLogisticMean,
    handleDeleteLogisticMean,
    onSelectLogisticMeanForEdit,
  };
};

export default useArtistDefineInterventionLogisticsMeans;
