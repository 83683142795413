import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const StyledMainImagePreview = styled.div.attrs(() => ({
  className: 'StyledMainImagePreview',
}))`
  .error-image-preview {
    ${tw` w-[200px]`}
  }
`;

export const StyledImagePreview = styled.div.attrs(() => ({
  className: 'StyledImagePreview',
}))`
  ${tw` w-[200px] h-[200px] cursor-pointer `}
`;

export const StyledInputFile = styled.input.attrs(() => ({
  className: 'StyledInputFile',
}))`
  ${tw` absolute left-0 w-full h-full opacity-0 z-last top-0`}
`;

export const StyledPreviewVideo = styled.video.attrs(() => ({
  className: 'StyledPreviewVideo',
}))`
  ${tw``}
`;
