import React, { Suspense, lazy } from 'react';
import ReactTooltip from 'react-tooltip';
//Hooks
import useRegisterComponents from './components';
import useControllers from 'controllers';
import useViews from 'views';

//Assets
import infoIcon from 'assets/icons/info.svg';

//Styles
const StyledGroup = lazy(() =>
  import('./Register.styles').then((mod) => ({
    default: mod.StyledGroup,
  }))
);

const Register = () => {
  const { useLayouts, useComponents } = useViews();
  const { MainLayout } = useLayouts();
  const { useBoxes, useTypographies, useInputs, useImages, useLoaders } =
    useComponents();
  const { DefaultLoader } = useLoaders();
  const { TypographyComponent } = useTypographies();
  const { Box } = useBoxes();
  const { Image } = useImages();

  const { Select } = useInputs();

  const { Artist, Partner } = useRegisterComponents();

  const { useScreenHooks } = useControllers();
  const { useAuth } = useScreenHooks();
  const { useRegister } = useAuth();
  const { formRegister } = useRegister();

  const {
    watch,
    control,
    formState: { errors },
  } = formRegister;
  const role = watch('role');

  return (
    <Suspense fallback={<DefaultLoader />}>
      <MainLayout hasDecorationMap>
        <Box className="bg-neutral-200 w-11/12 mx-auto max-w-screen-lg lg:px-16 lg:py-12 px-6 py-8 lg:my-8 relative">
          <>
            <TypographyComponent
              text="Formulario de registro"
              variant="h2"
              className="font-bold text-primary mb-4 text-lg"
            />
            <TypographyComponent
              text="Estamos felices de tenerte como parte de esta comunidad. Ayúdanos a llenar este formulario para que podemos empezar a colaborar. Bienvenido a el Laboratorio Digital de ASCC!"
              variant="p"
              className="text-neutral-950 mb-6 col-span-2"
            />
            <StyledGroup className="border-b pb-8 mb-6 items-center">
              <Select
                name="role"
                id="role"
                className="w-full"
                control={control}
                label="*Rol"
                placeholder="Seleccionar"
                options={[
                  { label: 'Seleccione', value: '' },
                  { label: 'Artista', value: 'ar' },
                  { label: 'Socio ejecutor', value: 'ep' },
                ]}
                errors={errors}
              />
              <a data-tip="New user">
                <Image
                  className="w-6 hidden lg:block ml-4"
                  srcImage={infoIcon}
                  altImage="info"
                  titleImage="info"
                />
              </a>
              <ReactTooltip />
            </StyledGroup>

            {role?.value === 'ar' ? (
              <Artist role={role} />
            ) : role?.value === 'ep' ? (
              <Partner role={role} />
            ) : null}
          </>
        </Box>
      </MainLayout>
    </Suspense>
  );
};

export default Register;
