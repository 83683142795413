import React, { lazy } from 'react';
import PropTypes from 'prop-types';

// Hooks
import useViews from 'views';
import useControllers from 'controllers';

// Components
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import _ from 'lodash';
import FormActivity from '../FormActivity';

const StyledDivider = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledDivider,
  }))
);

const StyledContentButtons = lazy(() =>
  import('styles/tableViews/tableViews.styles').then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const StyledWrapperCommunity = lazy(() =>
  import('../../../../../../../../../Dashboard.styles').then((mod) => ({
    default: mod.StyledWrapperCommunity,
  }))
);

const StyledCardCommunity = lazy(() =>
  import('../../../../../../../../../Dashboard.styles').then((mod) => ({
    default: mod.StyledCardCommunity,
  }))
);
const Activities = (props) => {
  const { activities } = props;
  const { useComponents } = useViews();

  const { useTypographies, useButtons } = useComponents();
  const { TypographyComponent } = useTypographies();
  const { ButtonComponent } = useButtons();

  const { useScreenHooks } = useControllers();

  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useDeepenKnowledgeCommunity } =
    useAdminInterventionsDashboardControllers();
  const { useAdminDeepenKnowledgeActivityCommunityInterventions } =
    useDeepenKnowledgeCommunity();
  const { handleRejectedActivity } =
    useAdminDeepenKnowledgeActivityCommunityInterventions({
      activities,
      setActivities: () => {},
    });
  return (
    <>
      <TypographyComponent
        text="Actividades"
        variant="h3"
        className="text-primary mt-6 font-bold"
      />

      <TypographyComponent
        text="Cuántas y cuáles actividaades se harán para esta profundización? Agraga una actividad por linea."
        variant="p"
        className="text-neutral-950 mt-2 mb-4"
      />

      <StyledWrapperCommunity
        className={
          activities.length !== 0
            ? 'visible opacity-100 h-auto'
            : 'invisible opacity-0 transition-all duration-100 h-0'
        }
      >
        <Accordion allowZeroExpanded>
          {activities.length > 0 &&
            _.map(activities, (activity, index) => {
              return (
                <AccordionItem
                  key={`activity-${index}`}
                  uuid={`activity-${index}`}
                  dangerouslySetExpanded={activity.expanded}
                >
                  <StyledCardCommunity key={`activity- ${index}`}>
                    <TypographyComponent
                      variant="p"
                      text={activity.name}
                      className="flex-grow text-primary font-bold"
                    />
                    <StyledContentButtons>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemButton>
                            <ButtonComponent
                              text=""
                              icon={
                                <div
                                  className={`icon-arrow-down text-neutral-900 text-xxs ${
                                    expanded && 'transform rotate-180'
                                  }`}
                                />
                              }
                              className=""
                              variant="blue"
                              onPress={() => {}}
                            />
                          </AccordionItemButton>
                        )}
                      </AccordionItemState>
                    </StyledContentButtons>
                  </StyledCardCommunity>
                  <AccordionItemPanel>
                    <div className="border rounded py-8 px-6 mt-4">
                      <form className="px-4">
                        <FormActivity
                          activity={activity}
                          handleRejectedActivity={handleRejectedActivity}
                        />
                      </form>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              );
            })}
        </Accordion>
      </StyledWrapperCommunity>
      <StyledDivider spacexs />
    </>
  );
};
Activities.propTypes = {
  activities: PropTypes.array.isRequired,
};

export default Activities;
