import useAdminAbcTrajectoryProviders from './abcTrajectory';
import useAdminAdditionalInfoProviders from './additionalInfo';
import useAdminCommunitiesProviders from './communities';
import useAdminProjectsDocumentationProviders from './documentation';
import useAdminFestivitiesAndTraditionsProviders from './festivitiesAndTraditions';
import useAdminProjectsRootProviders from './projectsRoot';
import useAdminRecommendationsProviders from './recommendations';
import useAdminSocioCulturalInfoProviders from './socioCulturalInfo';
import useAdminInterventionsProviders from './interventions';
const useAdminProjectsProviders = () => {
  return {
    useAdminProjectsRootProviders,
    useAdminCommunitiesProviders,
    useAdminSocioCulturalInfoProviders,
    useAdminFestivitiesAndTraditionsProviders,
    useAdminRecommendationsProviders,
    useAdminAbcTrajectoryProviders,
    useAdminAdditionalInfoProviders,
    useAdminProjectsDocumentationProviders,
    useAdminInterventionsProviders,
  };
};

export default useAdminProjectsProviders;
