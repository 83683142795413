import React, { lazy } from 'react';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

// Components
import Advance from './components/Advance';

const StyledContentButtonsModal = lazy(() =>
  import(
    '../../../../../../../../../../../../styles/tableViews/tableViews.styles'
  ).then((mod) => ({
    default: mod.StyledContentButtonsModal,
  }))
);

const ExecuteInterventionArtist = () => {
  const { useComponents } = useViews();

  const { useTypographies, useCards, useModals, useButtons } = useComponents();
  const { TypographyComponent } = useTypographies();
  const { CardNotification } = useCards();
  const { DefaultModal } = useModals();
  const { ButtonComponent } = useButtons();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsDevelopmentIntervention } =
    useAdminViewInterventionsControllers();
  const { useViewDevelopmentInterventionExecuteIntervention } =
    useAdminViewInterventionsDevelopmentIntervention();
  const { useViewDevelopmentExecuteInterventionArtist } =
    useViewDevelopmentInterventionExecuteIntervention();
  const { useArtistExecuteIntervention } =
    useViewDevelopmentExecuteInterventionArtist();
  const {
    executeIntervention,
    openModalCompleteMilestone,
    setOpenModalCompleteMilestone,
    openModalApprove,
    setOpenModalApprove,
    canFinishStep,
    startEvent,
    openCompleteMilestoneConfirmation,
    completeMilestoneEvent,
    updateExecuteIntervention,
  } = useArtistExecuteIntervention();

  if (executeIntervention.state.id === 'p') {
    return (
      <CardNotification
        className="w-6/12 mx-auto py-9 px-4 mt-4"
        title={
          <TypographyComponent
            text={executeIntervention.state.name}
            variant="h2"
            className="font-bold text-primary icon-watch text-center"
          />
        }
      />
    );
  }

  return (
    <>
      {executeIntervention.state.id === 'c' ||
        (executeIntervention.state.id === 'ip' && (
          <CardNotification
            className="w-6/12 mx-auto py-9 px-4 mt-4"
            title={
              <TypographyComponent
                text={executeIntervention.state.name}
                variant="h2"
                className="font-bold text-primary icon-watch text-center"
              />
            }
          />
        ))}
      <TypographyComponent
        text="Ejecutar intervención"
        variant="h2"
        className="text-primary mt-2 font-bold"
      />
      <TypographyComponent
        text="Llegó la hora de ejecutar tu intervención tal como fue validada!"
        variant="p"
        className="text-neutral-950 mt-4 mb-4"
      />

      <Advance
        events={executeIntervention.events}
        startEvent={startEvent}
        completeMilestoneEvent={openCompleteMilestoneConfirmation}
      />

      {executeIntervention.state.id !== 'c' && (
        <>
          <div className="flex items-center space-x-2 mt-10 justify-center">
            <ButtonComponent
              text="Finalizar ejecución"
              onPress={() => setOpenModalApprove(true)}
              disabled={!canFinishStep}
            />
          </div>

          <DefaultModal
            isActive={openModalCompleteMilestone}
            classNameContent="max-w-screen-md w-11/12"
            handleClose={() => {
              setOpenModalCompleteMilestone(false);
            }}
          >
            <TypographyComponent
              text="Marcar como completado"
              variant="h3"
              className="font-bold text-primary mb-1"
            />

            <StyledContentButtonsModal>
              <ButtonComponent
                variant="primary"
                text="Cancelar"
                className="mx-auto w-full lg:w-auto lg:mx-2 "
                mode="outline"
                onPress={() => setOpenModalCompleteMilestone(false)}
              />
              <ButtonComponent
                variant="primary"
                text="Aceptar"
                className="mx-auto w-full lg:w-auto lg:mx-2"
                onPress={completeMilestoneEvent}
              />
            </StyledContentButtonsModal>
          </DefaultModal>

          <DefaultModal
            isActive={openModalApprove}
            classNameContent="max-w-screen-md w-11/12"
            handleClose={() => {
              setOpenModalApprove(false);
            }}
          >
            <TypographyComponent
              text="Finalizar ejecución"
              variant="h3"
              className="font-bold text-primary mb-1"
            />

            <StyledContentButtonsModal>
              <ButtonComponent
                variant="primary"
                text="Cancelar"
                className="mx-auto w-full lg:w-auto lg:mx-2"
                mode="outline"
                onPress={() => setOpenModalApprove(false)}
              />
              <ButtonComponent
                variant="primary"
                text="Aceptar"
                className="mx-auto w-full lg:w-auto lg:mx-2"
                onPress={updateExecuteIntervention}
              />
            </StyledContentButtonsModal>
          </DefaultModal>
        </>
      )}
    </>
  );
};

export default ExecuteInterventionArtist;
