//Packages
import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Hooks
import useControllers from 'controllers';

const StyledDropDownFilterTag = lazy(() =>
  import('./DropDownLists.styles').then((mod) => ({
    default: mod.StyledDropDownFilterTag,
  }))
);

const StyledListTags = lazy(() =>
  import('./DropDownLists.styles').then((mod) => ({
    default: mod.StyledListTags,
  }))
);

const StyledListTag = lazy(() =>
  import('./DropDownLists.styles').then((mod) => ({
    default: mod.StyledListTag,
  }))
);

const StyledAddList = lazy(() =>
  import('./DropDownLists.styles').then((mod) => ({
    default: mod.StyledAddList,
  }))
);

const DropDownLists = (props) => {
  const {
    open,
    onHandleOpenModalCreate,
    setOpen,
    tabValue,
    setResetAllCheckboxes,
    setDataSelected,
    dataSelected,
  } = props;
  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminCommunitiesDashboardHooks } =
    useAdminDashboardProjectsHooks();
  const { useAdminContactsCommunitiesDashboardHooks } =
    useAdminCommunitiesDashboardHooks();
  const { useAdminRootContactsListCommunitiesDashboard } =
    useAdminContactsCommunitiesDashboardHooks();
  const { contactsLists, addContacts } =
    useAdminRootContactsListCommunitiesDashboard({
      tabValue,
      setResetAllCheckboxes,
    });

  const tags = _.map(contactsLists?.data, (contactList) => ({
    title: contactList.name,
    id: contactList.id,
    color: '64458E',
  }));
  return (
    <StyledDropDownFilterTag open={open}>
      <StyledListTags>
        {_.map(tags, (tag, idx) => {
          return (
            <StyledListTag
              key={`tags-${idx}`}
              color={tag.color}
              onClick={() => {
                addContacts({
                  contact_list: dataSelected,
                  id: tag.id,
                  setOpen,
                  setDataSelected,
                });
              }}
            >
              {tag.title}
            </StyledListTag>
          );
        })}
      </StyledListTags>

      <StyledAddList onClick={onHandleOpenModalCreate}>
        <div className="text-xs mr-2 icon-close transform rotate-45"></div>
        Crear nueva lista de contactos
      </StyledAddList>
    </StyledDropDownFilterTag>
  );
};

DropDownLists.propTypes = {
  open: PropTypes.bool.isRequired,
  onHandleOpenModalCreate: PropTypes.func,
  contacts: PropTypes.object,
  setOpen: PropTypes.func,
  tabValue: PropTypes.number.isRequired,
  setResetAllCheckboxes: PropTypes.func,
  setDataSelected: PropTypes.func,
  dataSelected: PropTypes.array,
};

DropDownLists.defaultProps = {
  onHandleOpenModalCreate: () => {},
  contacts: {},
  setOpen: () => {},
  setResetAllCheckboxes: () => {},
  setDataSelected: () => {},
  dataSelected: [],
};

export default DropDownLists;
