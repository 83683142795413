//Packages
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useParams, useHistory } from 'react-router-dom';

//Hooks
import useApi from '../../../../../../api';
import useModels from '../../../../../../models';
import useStrings from '../../../../../../strings';
import useControllers from '../../../../..';

const useAdminRecommendationsDashboard = () => {
  const { project_id: projectId } = useParams();
  const history = useHistory();

  const [show, setShow] = useState(false);
  const [recommendationData, setRecommendationData] = useState(null);
  const [recommendationId, setRecommendationId] = useState(null);
  const [recommendation, setRecomendation] = useState(null);

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { REQUIRED_FIELD, MAX_LENGTH } = useFormsTypes();

  const { useGeneralHooks } = useControllers();
  const { useToggleModal } = useGeneralHooks();
  const [showDeleteRecommendationModal, handleDeleteRecommendationModal] =
    useToggleModal();
  const [showViewRecomendationModal, handleViewRecomendationModal] =
    useToggleModal();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminRecommendationsActions } = useAdminProjectsActions();
  const {
    actCreateRecommendations,
    actGetRecommendations,
    actUpdateRecommendations,
    actDeleteRecommendations,
  } = useAdminRecommendationsActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminRecommendationsSelectors } = useAdminProjectsSelectors();
  const { listRecommendationsByProjectSelector } =
    useAdminRecommendationsSelectors();
  const listRecommendations = useSelector(listRecommendationsByProjectSelector);

  const schemaCreateRecommendations = yup.object({
    recommendation: yup
      .string()
      .required(REQUIRED_FIELD)
      .max(40000, MAX_LENGTH),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schemaCreateRecommendations),
    mode: 'onChange',
  });

  useEffect(() => {
    dispatch(actGetRecommendations({ projectId }));
  }, []);

  const onSuccessHandleRecommendations = () => {
    setValue('recommendation', '');
    setRecommendationData(null);
    setShow((prevState) => !prevState);
  };

  const handleRegisterRecommendations = (data) => {
    dispatch(
      actCreateRecommendations(
        { ...data, projectId },
        onSuccessHandleRecommendations
      )
    );
  };

  const handleAddRecommendation = () => {
    setValue('recommendation', '');
    setRecommendationData(null);
    setShow((prevState) => !prevState);
  };

  const handleCancelRecommendation = (e) => {
    e.preventDefault();
    reset({});
    setRecommendationData(null);
    setShow((prevState) => !prevState);
  };

  const handleEditRecommendation = ({ recommendationInfo }) => {
    setValue('recommendation', recommendationInfo.recommendation);
    setRecommendationData(recommendationInfo);
    setShow(true);
  };

  const handleUpdateRecommendation = (data) => {
    dispatch(
      actUpdateRecommendations(
        {
          ...data,
          projectId,
          recommendationId,
        },
        onSuccessHandleRecommendations
      )
    );
  };

  const handleDeleteRecommendation = () => {
    dispatch(
      actDeleteRecommendations({ recommendationId, projectId }, () => {
        handleDeleteRecommendationModal();
      })
    );
  };

  const handleGoBack = () =>
    history.push(`/admin/projects/dashboard/${projectId}`);

  const handleViewRecomendation = (recomendation) => {
    setRecomendation(recomendation);
  };
  return {
    register,
    handleSubmit,
    errors,
    handleRegisterRecommendations,
    show,
    showDeleteRecommendationModal,
    handleDeleteRecommendationModal,
    handleGoBack,
    listRecommendations,
    handleCancelRecommendation,
    recommendationData,
    handleEditRecommendation,
    handleAddRecommendation,
    handleUpdateRecommendation,
    handleDeleteRecommendation,
    setRecommendationId,

    showViewRecomendationModal,
    handleViewRecomendationModal,
    handleViewRecomendation,
    recommendation,
  };
};

export default useAdminRecommendationsDashboard;
