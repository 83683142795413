import useAdminDevelopmentInterventionCreativePlanTypes from './creativePlan';
import useAdminDevelopmentInterventionKnowCommunityTypes from './knowCommunity';
import useAdminDevelopmentInterventionKnowProjectTypes from './knowProject';
import useAdminDevelopmentInterventionDefineInterventionTypes from './defineIntervention';
import useAdminDevelopmentInterventionCreateSketchTypes from './createSketch';
import useAdminDevelopmentExecuteInterventionTypes from './executeIntervention';
import useAdminSpreadInterventionTypes from './spreadIntervention';

const useAdminViewInterventionsDevelopmentInterventionTypes = () => {
  return {
    useAdminDevelopmentInterventionCreativePlanTypes,
    useAdminDevelopmentInterventionKnowProjectTypes,
    useAdminDevelopmentInterventionKnowCommunityTypes,
    useAdminDevelopmentInterventionDefineInterventionTypes,
    useAdminDevelopmentInterventionCreateSketchTypes,
    useAdminDevelopmentExecuteInterventionTypes,
    useAdminSpreadInterventionTypes
  };
};

export default useAdminViewInterventionsDevelopmentInterventionTypes;
