//Packages
import React from 'react';
import PropTypes from 'prop-types';

//Hooks
import useControllers from 'controllers';

//Styles
import {
  StyledModal,
  StyledModalContent,
  StyledBackDrop,
  StyledButtonClose,
} from './DefaultModal.styles';

const DefaultModal = (props) => {
  const {
    children,
    isActive,
    classNameContent,
    handleClose,
    hasCloseButton,
    isHideAutomatic,
    time,
    isMobileScreenModal,
    isGeneralModal,
  } = props;

  const { useComponentHooks } = useControllers();
  const { useModalsControllers } = useComponentHooks();
  const { useDefaultModal } = useModalsControllers();
  useDefaultModal({ isHideAutomatic, isActive, handleClose, time });

  return (
    <>
      <StyledBackDrop isActive={isActive} />
      <StyledModal
        isActive={isActive}
        isMobileScreenModal={isMobileScreenModal}
        isGeneralModal={isGeneralModal}
      >
        <StyledModalContent
          isMobileScreenModal={isMobileScreenModal}
          className={classNameContent}
        >
          {hasCloseButton && <StyledButtonClose onClick={handleClose} />}
          {children}
        </StyledModalContent>
      </StyledModal>
    </>
  );
};

DefaultModal.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  className: PropTypes.string,
  classNameContent: PropTypes.string,
  handleClose: PropTypes.func,
  hasCloseButton: PropTypes.bool,
  isHideAutomatic: PropTypes.bool,
  isMobileScreenModal: PropTypes.bool,
  isGeneralModal: PropTypes.bool,
  time: PropTypes.number,
};

DefaultModal.defaultProps = {
  isActive: false,
  className: '',
  classNameContent: '',
  handleClose: () => {},
  hasCloseButton: true,
  isHideAutomatic: false,
  isMobileScreenModal: false,
  isGeneralModal: false,
  time: 10000,
};

export default DefaultModal;
