//Hooks
import useHelpers from '../../../../../helpers';

const useAdminRecommendationsSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const listRecommendationsByProjectSelector = createSelector(
    (state) => state.projectSelected,
    (projectSelected) => projectSelected.recommendations
  );

  return { listRecommendationsByProjectSelector };
};

export default useAdminRecommendationsSelectors;
