import { useEffect } from 'react';

const useDefaultModal = ({
  isHideAutomatic,
  handleClose,
  time = 10000,
  isActive,
} = {}) => {
  useEffect(() => {
    let timeout;
    if (isHideAutomatic) {
      if (isActive) {
        timeout = setTimeout(() => {
          handleClose();
        }, time);
      } else if (timeout !== null) {
        clearTimeout(timeout);
      }
    }

    return () => {
      timeout !== null && clearTimeout(timeout);
    };
  }, [isActive, isHideAutomatic]);
};

export default useDefaultModal;
