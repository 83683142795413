import React, { Suspense, lazy } from 'react';
import useControllers from '../../../../controllers';

import useViews from '../../..';

const StyledForm = lazy(() =>
  import('../Aut.styles').then((mod) => ({
    default: mod.StyledForm,
  }))
);

const ChangePassword = () => {
  const { useScreenHooks } = useControllers();
  const { useAuth } = useScreenHooks();
  const { useRecoverPasswordScreenController } = useAuth();
  const { chagePasswordForm, changePassword } =
    useRecoverPasswordScreenController();

  const { useLayouts, useComponents } = useViews();
  const { MainLayout } = useLayouts();
  const { useBoxes, useTypographies, useInputs, useButtons, useLoaders } =
    useComponents();
  const { DefaultLoader } = useLoaders();
  const { Box } = useBoxes();
  const { TypographyComponent } = useTypographies();
  const { InputForm } = useInputs();
  const { ButtonComponent } = useButtons();

  return (
    <div>
      <Suspense fallback={<DefaultLoader />}>
        <MainLayout hasDecorationMap className="lg:min-h-80vh">
          <Box className="bg-neutral-200 w-11/12 mx-auto max-w-3xl lg:px-16 lg:py-12 px-6 py-8 lg:my-8">
            <>
              <TypographyComponent
                text="Ajustes de la cuenta"
                variant="h3"
                className="font-bold text-primary text-base lg:pb-6 pb-5 border-b lg:mb-6 mb-5"
              />
              <TypographyComponent
                text="Cambiar contraseña"
                variant="h2"
                className="font-bold text-primary mb-4 text-lg"
              />
              <TypographyComponent
                text="Para mejor seguridad, es hora de que cambies tu contraseña!"
                variant="p"
                className="font-book text-neutral-950 mb-10"
              />
              <StyledForm
                onSubmit={chagePasswordForm.handleSubmit(changePassword)}
              >
                <InputForm
                  type="password"
                  name="current_password"
                  isPassword
                  placeholder="*****"
                  label="Contraseña actual"
                  className="w-full"
                  errors={chagePasswordForm.formState.errors}
                  {...chagePasswordForm.register('current_password')}
                />

                <InputForm
                  type="password"
                  name="password"
                  isPassword
                  placeholder="******"
                  label="Nueva contraseña"
                  className="w-full"
                  errors={chagePasswordForm.formState.errors}
                  {...chagePasswordForm.register('password')}
                />

                <InputForm
                  type="password"
                  name="password_confirmation"
                  isPassword
                  placeholder="******"
                  label="Confirmar nueva contraseña"
                  className="w-full"
                  errors={chagePasswordForm.formState.errors}
                  {...chagePasswordForm.register('password_confirmation')}
                />

                <ButtonComponent
                  variant="primary"
                  className="button-form"
                  text="Guardar cambios"
                  onPress={() => {
                    chagePasswordForm.handleSubmit(changePassword);
                  }}
                />
              </StyledForm>
            </>
          </Box>
        </MainLayout>
      </Suspense>
      {/*  <form onSubmit={chagePasswordForm.handleSubmit(changePassword)}>
        <input
          type={'password'}
          placeholder={'password actual'}
          errors={chagePasswordForm.formState.errors}
          {...chagePasswordForm.pre-register('current_password')}
        />
        <input
          type={'password'}
          placeholder={'cambia tu password'}
          errors={chagePasswordForm.formState.errors}
          {...chagePasswordForm.pre-register('password')}
        />
        <input
          type={'password'}
          placeholder={'repitela'}
          errors={chagePasswordForm.formState.errors}
          {...chagePasswordForm.pre-register('password_confirmation')}
        />
        <input type={'submit'} />
      </form> */}
    </div>
  );
};
export default ChangePassword;
