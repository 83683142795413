import React, { lazy, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

// Hooks
import useViews from 'views';
import useHelpers from 'helpers';

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

// Components
import ExecutionInterventionComments from '../../../../../Shared/EjecutionInterventionComments';

const StyledContentButtons = lazy(() =>
  import(
    '../../../../../../../../../../../../../../styles/tableViews/tableViews.styles'
  ).then((mod) => ({
    default: mod.StyledContentButtons,
  }))
);

const StyledDivider = lazy(() =>
  import(
    '../../../../../../../../../../../../../../styles/formViews/formViews.styles'
  ).then((mod) => ({
    default: mod.StyledDivider,
  }))
);

const Advance = (props) => {
  const { events, startEvent, completeMilestoneEvent } = props;

  const { useComponents } = useViews();
  const { useTypographies, useButtons, useBadges } = useComponents();
  const { TypographyComponent } = useTypographies();
  const { ButtonComponent } = useButtons();
  const { DefaultBadge } = useBadges();

  const { useQuickFunctions } = useHelpers();
  const { getYearMonthDayFromDate } = useQuickFunctions();

  const [eventExpanded, setEventExpanded] = useState();

  const handleStartEvent = ({ event }) => {
    setEventExpanded(event);
    startEvent({ event });
  };

  return (
    <>
      <TypographyComponent
        text="Programación"
        variant="h3"
        className="text-primary mt-6 font-bold"
      />
      <TypographyComponent
        text="Has click en la flecha al lado del nombre de la intervención para ver todos los detalles."
        variant="p"
        className="text-neutral-950 mt-4 mb-4"
      />

      <TypographyComponent
        text="Has click en INICIAR INTERVENCION para ver las etapas claves."
        variant="p"
        className="text-neutral-950 mt-4 mb-4"
      />

      <TypographyComponent
        text="Recuerda hacer click para darle check a cada una de ellas en el momento en que sean completadas."
        variant="p"
        className="text-neutral-950 mt-4 mb-4"
      />

      <Accordion allowZeroExpanded>
        {events.length > 0 &&
          _.map(events, (event, idx) => {
            const {
              id,
              definition_intervention,
              community,
              state,
              milestones,
              date,
              place,
            } = event;
            return (
              <div
                className="rounded mt-2 bg-neutral-400 border-2 py-4"
                key={`event-${id}`}
              >
                <AccordionItem
                  dangerouslySetExpanded={eventExpanded?.id === event.id}
                >
                  <div className="flex items-center justify-between  py-2 px-6">
                    <div className="flex items-center">
                      <TypographyComponent
                        variant="h3"
                        text={definition_intervention?.name}
                        className="flex-grow text-primary font-bold"
                      />

                      <StyledContentButtons>
                        <AccordionItemState>
                          {({ expanded }) => (
                            <AccordionItemButton>
                              <ButtonComponent
                                text=""
                                className=""
                                variant="white"
                                icon={
                                  <div
                                    className={`icon-arrow-down text-xxs text-neutral-950 ml-4 ${
                                      expanded && 'transform rotate-180'
                                    }`}
                                  />
                                }
                                onPress={() =>
                                  setEventExpanded(
                                    eventExpanded?.id === event.id
                                      ? null
                                      : event
                                  )
                                }
                              />
                            </AccordionItemButton>
                          )}
                        </AccordionItemState>
                      </StyledContentButtons>
                    </div>
                    {state.id === 'c' && (
                      <DefaultBadge
                        type="light"
                        label="Intervención completado"
                        variant="success"
                      />
                    )}
                    {state.id === 'p' && (
                      <ButtonComponent
                        text="Iniciar intervención"
                        className=""
                        onPress={() => handleStartEvent({ event })}
                        disabled={idx > 0 && events[idx - 1].state.id !== 'c'}
                      />
                    )}
                  </div>
                  <AccordionItemPanel className="py-4  px-6">
                    {state.id === 'p' && (
                      <>
                        <TypographyComponent
                          variant="p"
                          text={
                            <label>
                              <span className="text-primary font-bold">
                                Modalidad:{' '}
                              </span>
                              {definition_intervention.modality.name}
                            </label>
                          }
                        />
                        <TypographyComponent
                          variant="p"
                          text={
                            <label>
                              <span className="text-primary font-bold">
                                Fecha:{' '}
                              </span>
                              {getYearMonthDayFromDate({
                                dateInString: date,
                                format: 'dmyText',
                              }).toLowerCase()}
                            </label>
                          }
                        />
                        <TypographyComponent
                          variant="p"
                          text={
                            <label>
                              <span className="text-primary font-bold">
                                Hora:{' '}
                              </span>
                              {getYearMonthDayFromDate({
                                dateInString: date,
                                format: 'time',
                              })}
                            </label>
                          }
                        />
                        <TypographyComponent
                          variant="p"
                          text={
                            <label>
                              <span className="text-primary font-bold">
                                Duración:{' '}
                              </span>
                              {definition_intervention.duration.slice(0, 5)}{' '}
                              horas
                            </label>
                          }
                        />
                        <TypographyComponent
                          variant="p"
                          text={
                            <label>
                              <span className="text-primary font-bold">
                                Comunidad donde se realizará la intervención:{' '}
                              </span>
                              {community.name}
                            </label>
                          }
                        />
                        <TypographyComponent
                          variant="p"
                          text={
                            <label>
                              <span className="text-primary font-bold">
                                Lugar:{' '}
                              </span>
                              {place}
                            </label>
                          }
                        />
                      </>
                    )}
                    {state.id !== 'p' &&
                      _.map(milestones, (milestone, idx) => (
                        <div
                          className="flex items-center justify-between bg-white px-6 py-2 mb-2 rounded border"
                          key={`milestone-${milestone.id}`}
                        >
                          <TypographyComponent
                            variant="h3"
                            text={milestone.type.name}
                            className="flex-grow text-primary font-bold"
                          />
                          {milestone.state.id === 'c' ? (
                            <div className="icon-check-circle text-success text-2xl" />
                          ) : (
                            <ButtonComponent
                              text="Marcar como completado"
                              className=""
                              onPress={() =>
                                completeMilestoneEvent({ event, milestone })
                              }
                              disabled={
                                idx > 0 && milestones[idx - 1].state.id !== 'c'
                              }
                            />
                          )}
                        </div>
                      ))}
                  </AccordionItemPanel>
                </AccordionItem>
              </div>
            );
          })}
      </Accordion>
      {1 > 2 && (
        <>
          <StyledDivider />
          <ExecutionInterventionComments />
        </>
      )}
    </>
  );
};

Advance.propTypes = {
  events: PropTypes.array,
  startEvent: PropTypes.func.isRequired,
  completeMilestoneEvent: PropTypes.func.isRequired,
};

export default Advance;
