import useAdminProjectsSelectors from './projects';
import useAdminUsersSelectors from './users';
import useAdminLabelsSelectors from './labels';
import useAdminContactListSelectors from './contactList';
import useAdminHomeSelectors from './home';
import useAdminContentInterventionsSelectors from './interventions';

const useAdminSelectors = () => {
  return {
    useAdminUsersSelectors,
    useAdminLabelsSelectors,
    useAdminProjectsSelectors,
    useAdminContactListSelectors,
    useAdminHomeSelectors,
    useAdminContentInterventionsSelectors,
  };
};

export default useAdminSelectors;
