//Packages
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

//Components
import DropDownFilter from './components/DropDownFilter/DropDownFilter';
import DropDownFilterTags from './components/DropDownFilterTags';
import DropDownLists from './components/DropDownLists';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Styles
import {
  StyledTopFilters,
  StyledFilters,
  StyledInputSearchContent,
  StyledContentButtons,
  StyledContentButtonsModal,
} from 'styles/tableViews/tableViews.styles';
import {
  StyledWrapperContactTable,
  StyledWrapperTags,
  StyledBadgeTag,
  StyledButtonAddTag,
  StyledWrapperButtonList,
  StyledWrapperListDropDown,
} from './Contacts.styles';

const Contacts = (props) => {
  const { tabValue } = props;
  const { useComponents } = useViews();

  const {
    useButtons,
    useInputs,
    useBadges,
    useModals,
    useTypographies,
    useTables,
  } = useComponents();

  const { ButtonComponent } = useButtons();
  const { InputSearch, InputForm } = useInputs();
  const { ActionBadge } = useBadges();
  const { DefaultModal } = useModals();
  const { TypographyComponent } = useTypographies();

  const [isOnFocusTextSearch, setIsOnFocusTextSearch] = useState(false);
  const [activeDropDown, setActiveDropDown] = useState(false);
  const [activeDropDownFilterTags, setActiveDropDownFilterTags] = useState({
    active: false,
  });
  const [activeDropDownFilterTagsAll, setActiveDropDownFilterTagsAll] =
    useState({
      active: false,
    });
  const [activeDropDownLists, setActiveDropDownLists] = useState(false);
  const [activeModalDelete, setActiveModalDelete] = useState({ active: false });

  const [activeCreateListModal, setActiveCreateListModal] = useState({
    active: false,
  });

  const [positionDropDownTags, setPositionDropDownTags] = useState([]);
  const handleOpenMenu = () => {
    setActiveDropDown(!activeDropDown);
  };

  const handleOpenFilterTags = (e, user) => {
    activeDropDownFilterTags;

    setActiveDropDownFilterTags((state) => ({
      ...state,
      active: !state.active,
      user: user,
    }));

    const ButtonTagElement = e.target.getBoundingClientRect();

    const positionXButtonTagElement = ButtonTagElement.right;
    const positionYButtonTagElement = ButtonTagElement.top;

    setPositionDropDownTags([
      {
        posX: positionXButtonTagElement - ButtonTagElement.width,
        posY: positionYButtonTagElement,
      },
    ]);
  };

  const handleOpenDropdownList = () => {
    setActiveDropDownLists(!activeDropDownLists);
  };

  const handleOnFocus = (isOnFocus) => {
    setIsOnFocusTextSearch(isOnFocus);
  };

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminCommunitiesDashboardHooks } =
    useAdminDashboardProjectsHooks();
  const { useAdminContactsCommunitiesDashboardHooks } =
    useAdminCommunitiesDashboardHooks();
  const { useAdminRootContactsCommunitiesDashboard } =
    useAdminContactsCommunitiesDashboardHooks();
  const {
    isFilterEmpty,
    history,
    users,
    deleteUser,
    setTextSearch,
    getUsersPage,
    defaultValueTextSearch,
    createContactListForm,
    current_page,
    getUserContactList,
    handleOrderBy,
    unAssignLabelToContacts,
    resetAllCheckboxes,
    setResetAllCheckboxes,
    setDataSelected,
    dataSelected,
  } = useAdminRootContactsCommunitiesDashboard();

  const { register } = createContactListForm;

  const data = React.useMemo(() => {
    return [
      ..._.map(users.data, (user) => {
        return {
          id: user.id,
          name: user.name,
          last_name: user.last_name,
          email: user.email,
          role: user.role_name,
          gender: user.gender,
          phone_complete: user.phone_complete,
          whatsapp: user.has_whatsapp ? 'Sí' : 'No',
          tags: user.tags,
          col14: (
            <StyledContentButtons>
              <ButtonComponent
                text=""
                className="icon-edit"
                onPress={() => {
                  history.push(
                    `/admin/projects/dashboard/${project_id}/communities/${community_id}/contacts/edit/${user.id}`
                  );
                }}
              />
              <ButtonComponent
                text=""
                className="icon-delete"
                onPress={() => {
                  setActiveModalDelete({ active: true, user: user.id });
                }}
              />
            </StyledContentButtons>
          ),
        };
      }),
    ];
  }, [current_page.current, users]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nombre',
        accessor: 'name',
        orderByKey: 'name',
        sticky: 'left',
        width: 150,
      },
      {
        Header: 'Apellido',
        accessor: 'last_name',
        orderByKey: 'last_name',
        sticky: 'left',
        width: 120,
      },
      {
        Header: 'Correo electrónico',
        accessor: 'email',
        orderByKey: 'email',
        width: 320,
      },
      {
        Header: 'Rol',
        accessor: 'role',
        orderByKey: 'role',
      },
      {
        Header: 'Género',
        accessor: 'gender',
        orderByKey: 'gender',
      },
      {
        Header: 'Teléfono',
        accessor: 'phone_complete',
        width: 160,
        orderByKey: 'phone',
      },

      {
        Header: 'WhatsApp',
        accessor: 'whatsapp',
        orderByKey: 'whatsapp',
        width: 140,
        noneOrder: true,
      },
      {
        Header: 'Etiquetas',
        width: 400,
        noneOrder: true,
        accessor: (data) => (
          <StyledWrapperTags>
            <StyledButtonAddTag
              className="icon-tag text-primary rounded-full"
              onClick={(e) => handleOpenFilterTags(e, data.id)}
            />
            {_.map(data.tags, (item, key) => {
              return (
                <StyledBadgeTag key={`tag-${key}`} bgColor={item.color}>
                  <ActionBadge
                    label={item.name}
                    type="solid"
                    onDelete={() =>
                      unAssignLabelToContacts({
                        tag: item.id,
                        user: data.id,
                      })
                    }
                  />
                </StyledBadgeTag>
              );
            })}
          </StyledWrapperTags>
        ),
      },
      {
        Header: '',
        accessor: 'col14',
        sticky: 'right',
        width: 100,
      },
    ],
    []
  );

  const { project_id, community_id } = useParams();
  const { TableCustom } = useTables();

  useEffect(() => {
    if (dataSelected?.length < 1)
      setActiveDropDownFilterTagsAll({ active: false });
  }, [dataSelected]);

  return (
    <StyledWrapperContactTable>
      <StyledTopFilters>
        <StyledWrapperButtonList>
          <ButtonComponent
            text="Crear nuevo contacto"
            icon={<div className="icon-close transform rotate-45 text-sm" />}
            onPress={() => {
              history.push(
                `/admin/projects/dashboard/${project_id}/communities/${community_id}/contacts/create`
              );
            }}
          />

          <StyledWrapperListDropDown
            style={{ display: dataSelected?.length > 0 ? 'block' : 'none' }}
          >
            <ButtonComponent
              text="Listas"
              className="button-none"
              icon={<div className="icon-data-base text-lg " />}
              onPress={handleOpenDropdownList}
            />

            <DropDownLists
              open={activeDropDownLists}
              onHandleOpenModalCreate={() => {
                setActiveCreateListModal({ active: true });
              }}
              setOpen={setActiveDropDownLists}
              tabValue={tabValue}
              setResetAllCheckboxes={setResetAllCheckboxes}
              setDataSelected={setDataSelected}
              dataSelected={dataSelected}
            />
          </StyledWrapperListDropDown>
          <div>
            {dataSelected?.length > 0 && (
              <ButtonComponent
                text="Asignar etiqueta"
                className={`button-none hidden `}
                icon={<div className="icon-tag text-lg " />}
                onPress={() => {
                  setActiveDropDownFilterTagsAll({
                    active: !activeDropDownFilterTagsAll.active,
                  });
                }}
              />
            )}

            <DropDownFilterTags
              open={activeDropDownFilterTagsAll.active}
              setActiveDropDownFilterTagsAll={setActiveDropDownFilterTagsAll}
              users={_.map(dataSelected, (contact) => contact?.id)}
              setResetAllCheckboxes={setResetAllCheckboxes}
            />
          </div>
        </StyledWrapperButtonList>

        {!(!isFilterEmpty && data?.length === 0) && (
          <StyledFilters className="relative">
            <ButtonComponent
              text="Filtros"
              mode="outline"
              className="icon-filter flex items-center space-x-2"
              onPress={handleOpenMenu}
            />
            <StyledInputSearchContent>
              <InputSearch
                text={defaultValueTextSearch}
                onDebounce={setTextSearch}
                handlerIsOnFocus={handleOnFocus}
              />
            </StyledInputSearchContent>
            <DropDownFilter
              open={activeDropDown}
              isOnFocusTextSearch={isOnFocusTextSearch}
              handleOpenMenu={handleOpenMenu}
              community_id={community_id}
            />
          </StyledFilters>
        )}
      </StyledTopFilters>

      <TableCustom
        columns={columns}
        data={data}
        meta={users.meta}
        pagination={getUsersPage}
        setDataSelected={(data) => setDataSelected(data)}
        setOrderData={handleOrderBy}
        forcePage={current_page.current - 1}
        resetAllCheckboxes={resetAllCheckboxes}
        buttonEmptyState={
          !isFilterEmpty ? (
            <ButtonComponent
              text="Crear nuevo contacto"
              icon={<div className="icon-close transform rotate-45 text-sm" />}
              onPress={() => {
                history.push(
                  `/admin/projects/dashboard/${project_id}/communities/${community_id}/contacts/create`
                );
              }}
            />
          ) : (
            <></>
          )
        }
        titleEmptyState="No se han encontrado contactos ¡Crea el primero!"
        showCheckboxes
      />
      <DropDownFilterTags
        open={activeDropDownFilterTags.active}
        handleStateDropDownTagsByRow={setActiveDropDownFilterTags}
        positionDropDownTags={positionDropDownTags}
        user={activeDropDownFilterTags.user}
      />

      <DefaultModal
        isActive={activeCreateListModal.active}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={() => {
          setActiveCreateListModal({ active: false });
        }}
      >
        <TypographyComponent
          text="Crear lista de contactos"
          variant="h3"
          className="font-bold text-primary mb-1"
        />

        <InputForm
          type="text"
          name="name"
          placeholder="Ingresa el nombre de la lista"
          label="Nombre de la lista*"
          className="w-full mt-6"
          {...register('name')}
        />
        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Crear lista"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={() => {
              getUserContactList();
              setActiveCreateListModal({ active: false });
            }}
          />
        </StyledContentButtonsModal>
      </DefaultModal>

      <DefaultModal
        isActive={activeModalDelete.active}
        classNameContent="max-w-screen-md w-11/12"
        handleClose={() => {
          setActiveModalDelete({ active: false });
        }}
      >
        <TypographyComponent
          text="Eliminar Contacto"
          variant="h3"
          className="font-bold text-primary mb-1"
        />
        <StyledContentButtonsModal>
          <ButtonComponent
            variant="primary"
            text="Cancelar"
            className="mx-auto w-full lg:w-auto lg:mx-2 "
            mode="outline"
            onPress={() => {
              setActiveModalDelete({ active: false });
            }}
          />
          <ButtonComponent
            variant="primary"
            text="Aceptar"
            className="mx-auto w-full lg:w-auto  lg:mx-2"
            onPress={() => {
              deleteUser(activeModalDelete.user);
              setActiveModalDelete({ active: false });
            }}
          />
        </StyledContentButtonsModal>
      </DefaultModal>
    </StyledWrapperContactTable>
  );
};

Contacts.propTypes = {
  tabValue: PropTypes.number.isRequired,
};

export default Contacts;
