//Packages
import React, { Suspense, lazy } from 'react';
import _ from 'lodash';

//Hooks
import useViews from 'views';
import useControllers from 'controllers';

//Assets
import imageFakeCard from 'assets/img/fake-card-example.jpg';

//Styles
const StyledBackButton = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledBackButton,
  }))
);

const StyledDivider = lazy(() =>
  import('styles/formViews/formViews.styles').then((mod) => ({
    default: mod.StyledDivider,
  }))
);

const StyledBoxImages2col1 = lazy(() =>
  import('styles/boxes/boxes.styles').then((mod) => ({
    default: mod.StyledBoxImages2col1,
  }))
);

const AdminDashboardViewInterventionsFestivities = () => {
  const { useComponents } = useViews();

  const { useTypographies, useBoxes, useImages, useBadges, useLoaders } =
    useComponents();
  const { DefaultLoader } = useLoaders();
  const { TypographyComponent } = useTypographies();
  const { Box } = useBoxes();
  const { Image } = useImages();
  const { DefaultBadge } = useBadges();

  const { useGeneralHooks, useScreenHooks } = useControllers();
  const { useSelectLayoutByRole } = useGeneralHooks();
  const { LayoutSelected, props } = useSelectLayoutByRole();

  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminKnowProjectDetailIntervention } =
    useAdminInterventionsDashboardControllers();
  const { festivities, goBack } = useAdminKnowProjectDetailIntervention();
  return (
    <Suspense fallback={<DefaultLoader />}>
      <LayoutSelected {...props}>
        <StyledBackButton
          className="mt-3"
          onClick={() => {
            goBack();
          }}
        >
          Regresar
        </StyledBackButton>
        <Box className="bg-neutral-200 w-12/12 mx-auto max-w-screen-2xl lg:px-16 lg:py-12 px-6 py-8 lg:my-8">
          <>
            <TypographyComponent
              text={'Fiestas y tradiciones'}
              variant="h2"
              className="font-bold text-primary mb-5"
            />
            <TypographyComponent
              text={
                'Presenta la información más relevante que salió durante el diagnóstico socio cultural sobre las fiestas y tradiciones de la zona de este proyecto de forma general. Si tienes detalles sobre algunas comunidades en específico no dudes en agregarlos.'
              }
              variant="p"
              className="mb-12"
            />
            {_.map(festivities, (festivity, idx) => {
              return (
                <section key={`festivityItem-${idx}`}>
                  <div className="flex items-center mb-2">
                    <TypographyComponent
                      text={festivity.name}
                      variant="h3"
                      className="font-bold text-primary mr-2"
                    />

                    <DefaultBadge
                      label={festivity.category.name}
                      type="light"
                    />
                  </div>

                  <TypographyComponent
                    text={festivity.description}
                    variant="p"
                    className="mb-12"
                  />
                  {festivity.multimedias.length !== 0 && (
                    <StyledBoxImages2col1>
                      {_.map(festivity.multimedias, (multimedia, idx) => {
                        return (
                          <Image
                            key={`festivityMultimedia-${idx}`}
                            srcImage={multimedia.url || imageFakeCard}
                            altImage="imageport"
                            titleImage="imageport"
                          />
                        );
                      })}
                    </StyledBoxImages2col1>
                  )}

                  <StyledDivider />
                </section>
              );
            })}
          </>
        </Box>
      </LayoutSelected>
    </Suspense>
  );
};

export default AdminDashboardViewInterventionsFestivities;
