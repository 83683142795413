//Packages
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

//Hooks
import useApi from 'api';
import useModels from 'models';
import useControllers from 'controllers';
import useSelectFormValidations from './selectFormValidations';

//Data
const globalNetworks = ['fcb', 'tw', 'lin', 'you', 'oth', 'int'];
const optionsInterventions = [
  { label: 'Virtual', value: 'v' },
  { label: 'Presencial', value: 'p' },
  { label: 'Virtual y Presencial', value: 'pv' },
];

const useProfile = () => {
  const history = useHistory();
  const [newSpecialities, setNewSpecialities] = useState([]);
  //Form validations
  const { defaultValues, validationSchema } = useSelectFormValidations();

  //Form -react-hook-form
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues,
  });

  const countrySelected = watch('country');
  const departmentSelected = watch('department');

  //Controllers
  const { useGeneralHooks } = useControllers();
  const {
    useGetUserProfile,
    useModalUploadProfilePhoto,
    useToggleModal,
    useWhereLive,
  } = useGeneralHooks();

  const [openModalAddExperience, toogleModalAddExperience] = useToggleModal();
  const [openModalDiscardChanges, toogleModalDiscardChanges] = useToggleModal();

  const userInfo = useGetUserProfile();
  const [specialitiesForShow, setSpecialitiesForShow] = useState(
    userInfo.specialties
  );

  const { handleOpenModal: handleShowCropperModal } =
    useModalUploadProfilePhoto();

  const {
    optionsCountries,
    optionsDepartments,
    optionsCities,
    optionsIndicatives,
    optionsLanguages,
  } = useWhereLive({ countrySelected, departmentSelected });

  //Actions
  const { useActions } = useApi();
  const { dispatch, useUserActions, useGeneralActions } = useActions();
  const {
    actGetProfile,
    actUpdateProfile,
    actAddDataUpdateProfileUser,
    actDeleteDataUpdateProfileUser,
    actResetDataUpdateProfileUser,
  } = useUserActions();
  const { useSpecialitiesActions } = useGeneralActions();
  const { actGetSpecialities } = useSpecialitiesActions();

  //Selectors
  const { useSelectors } = useModels();
  const { useSelector, useUserSelectors, useGeneralSelectors } = useSelectors();
  const { useSpecialitiesSelectors } = useGeneralSelectors();
  const { specialitiesSelector } = useSpecialitiesSelectors();
  const { dataUpdateUserSelector } = useUserSelectors();
  const { result: resultSpecialities, entities: entitiesSpecialities } =
    useSelector(specialitiesSelector);
  const dataUpdateUser = useSelector(dataUpdateUserSelector);

  const optionsSpecialitiesModal = useMemo(() => {
    if (entitiesSpecialities?.specialities) {
      return _.map(resultSpecialities, (speciality) => {
        return {
          name: entitiesSpecialities.specialities[speciality].name,
          id: speciality,
          selected: !!newSpecialities.includes(speciality),
        };
      });
    }
  }, [entitiesSpecialities, newSpecialities]);

  const optionsSpecialities = useMemo(
    () =>
      _.map(specialitiesForShow, (speciality) => {
        return {
          name: speciality.name,
          id: speciality.id,
        };
      }),
    [specialitiesForShow]
  );

  useEffect(() => {
    dispatch(actGetProfile());
    if (userInfo.role.id === 'ar') {
      dispatch(actGetSpecialities({ paginate: 0 }));
    }
  }, []);

  useEffect(() => {
    dispatch(actResetDataUpdateProfileUser());

    return () => {
      dispatch(actResetDataUpdateProfileUser());
    };
  }, []);

  useEffect(() => {
    if (userInfo.specialitiesIndexed) {
      const specialitiesIds = _.map(
        Object.keys(userInfo.specialitiesIndexed),
        (speciality) => parseInt(speciality)
      );
      setNewSpecialities([...specialitiesIds]);
    }
  }, [userInfo]);

  useEffect(() => {
    if (newSpecialities.length === 0) {
      setValue('specialties', [...newSpecialities]);
    } else {
      clearErrors('specialties');
      setValue('specialties', [...newSpecialities]);
    }
  }, [newSpecialities]);

  const handleUpdateDataUser = (event) => {
    const { name, value } = event.target;
    if (value !== userInfo[name]) {
      dispatch(actAddDataUpdateProfileUser({ [name]: value }));
    } else {
      // eslint-disable-next-line no-unused-vars
      const { [name]: deletedProp, ...rest } = dataUpdateUser;
      dispatch(actDeleteDataUpdateProfileUser(rest));
    }
  };

  const handleUpdateDataUserOnSelect = ({ data, inputName }) => {
    const { value } = data;
    if (inputName === 'country') {
      setValue('department', null);
      setValue('city_id', null);
    } else if (inputName === 'department') {
      setValue('city_id', null);
    }
    if (value !== userInfo[inputName]) {
      dispatch(actAddDataUpdateProfileUser({ [inputName]: value }));
    } else {
      // eslint-disable-next-line no-unused-vars
      const { [inputName]: deletedProp, ...rest } = dataUpdateUser;
      dispatch(actDeleteDataUpdateProfileUser(rest));
    }
  };

  const onSuccessUpdateProfile = () => {
    dispatch(actResetDataUpdateProfileUser());
    handleGoBack();
  };

  const handleUpdateProfile = (data) => {
    if (userInfo.role.id !== 'ar') {
      // eslint-disable-next-line no-unused-vars
      const { country, department, ...restData } = dataUpdateUser;
      dispatch(actUpdateProfile(restData, onSuccessUpdateProfile));
    } else {
      const isTheSameSpecialities =
        userInfo.specialties.length > 0
          ? userInfo.specialties.every((speciality) =>
              newSpecialities.includes(speciality.id)
            ) &&
            newSpecialities.every(
              (speciality) => userInfo.specialitiesIndexed[speciality]
            )
          : false;

      const networksForBackend = _.map(globalNetworks, (network) => {
        return { type: network, url: data[network] };
      }).filter((globalNetworks) => globalNetworks.url);

      if (!isTheSameSpecialities) {
        // eslint-disable-next-line no-unused-vars
        const { country, department, ...restData } = dataUpdateUser;

        dispatch(
          actUpdateProfile(
            {
              ...restData,
              specialties: [...newSpecialities],
              networks: [...networksForBackend],
            },
            onSuccessUpdateProfile
          )
        );
      } else {
        // eslint-disable-next-line no-unused-vars
        const { country, department, ...restData } = dataUpdateUser;

        dispatch(
          actUpdateProfile(
            {
              ...restData,
              networks: [...networksForBackend],
            },
            onSuccessUpdateProfile
          )
        );
      }
    }
  };

  const handleUpdateAvatar = (e) => {
    const file = e.target.files[0];
    const blobImage = URL.createObjectURL(file);
    handleShowCropperModal({ blobImage, name: file.name, type: file.type });
  };

  const handleToogleSpeciality = ({ id }) => {
    if (!newSpecialities.includes(id)) {
      setNewSpecialities((prevState) => [...prevState, id]);
    } else {
      const newSpecialitiesAux = _.filter(
        newSpecialities,
        (speciality) => speciality !== id
      );
      setNewSpecialities([...newSpecialitiesAux]);
    }
  };

  const handleSaveNewSpecialities = () => {
    const specialitiesForShow = _.map(newSpecialities, (speciality) => {
      return {
        id: speciality,
        name: entitiesSpecialities.specialities[speciality].name,
      };
    });
    setSpecialitiesForShow([...specialitiesForShow]);
    toogleModalAddExperience();
  };

  const handleCloseNewSpecialities = () => {
    if (specialitiesForShow.length === newSpecialities.length) {
      toogleModalAddExperience();
    } else {
      toogleModalDiscardChanges();
    }
  };

  const handleDiscardChanges = () => {
    const specialitiesIds = _.map(
      specialitiesForShow,
      (speciality) => speciality.id
    );
    setNewSpecialities([...specialitiesIds]);
    toogleModalDiscardChanges();
    toogleModalAddExperience();
  };

  const handleDeleteSpecificSpeciality = ({ idSpeciality }) => {
    const newSpecialitiesAux = _.filter(
      newSpecialities,
      (speciality) => speciality !== idSpeciality
    );
    setNewSpecialities([...newSpecialitiesAux]);
    const specialitiesForShowAux = _.filter(
      specialitiesForShow,
      (speciality) => {
        return idSpeciality !== speciality.id;
      }
    );
    setSpecialitiesForShow([...specialitiesForShowAux]);
  };
  const handleGoBack = () => history.goBack();

  return {
    userInfo,
    optionsCountries,
    optionsDepartments,
    optionsCities,
    optionsIndicatives,
    optionsLanguages,
    optionsSpecialitiesModal,
    optionsSpecialities,
    optionsInterventions,
    countrySelected,

    openModalAddExperience,
    toogleModalAddExperience,
    handleToogleSpeciality,
    handleCloseNewSpecialities,
    openModalDiscardChanges,
    toogleModalDiscardChanges,
    handleDiscardChanges,
    handleSaveNewSpecialities,
    handleDeleteSpecificSpeciality,

    errors,
    control,
    handleSubmit,
    handleUpdateProfile,
    handleUpdateDataUser,
    handleUpdateDataUserOnSelect,
    handleUpdateAvatar,
  };
};

export default useProfile;
