//Packages
import React from 'react';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';
import useStrings from 'strings';

//Styles
import { StyledGroup } from 'styles/formViews/formViews.styles';

const ActivitieForm = (props) => {
  const {
    formActivity,
    handleCreateActivity,
    handleVisiblityCreateAndEditActivity,
    isEditModeInActivity,
    handleResetActivityDataToEdit,
    isActivityEditButtonDisabled,
    disabledEditMode,
  } = props;
  const { useComponents } = useViews();

  const { useInputs, useButtons } = useComponents();

  const { TextArea, Select, InputForm } = useInputs();
  const { ButtonComponent } = useButtons();

  const { useConstants } = useStrings();
  const { MOMENT_VALUES } = useConstants();

  const {
    register: registerActivity,
    handleSubmit: handleSubmitActivity,
    control: controlActivity,
    formState: { errors: errorsActivity },
    reset,
  } = formActivity;

  return (
    <form>
      <StyledGroup noSpace className="mb-4 flex-col">
        <InputForm
          name="name"
          label="Nombre de la actividad*"
          type="text"
          className="full"
          placeholder="Ingresa el nombre de la actividad"
          {...registerActivity('name')}
          errors={errorsActivity}
          disabled={disabledEditMode}
        />
        <Select
          name="moment"
          id="moment"
          placeholder="Selecciona el momento"
          control={controlActivity}
          options={MOMENT_VALUES}
          label="Momento*"
          errors={errorsActivity}
          disabled={disabledEditMode}
        />
      </StyledGroup>
      <StyledGroup col2 flex className="mb-4 flex-col">
        <TextArea
          control={controlActivity}
          name="justification"
          placeholder="Ingresa la justificación de la actividad..."
          className="w-full col-span-2"
          label="Justificación de la actividad*"
          errors={errorsActivity}
          hasController
          disabled={disabledEditMode}
        />
      </StyledGroup>

      <div className="flex items-center space-x-2 mt-10 justify-end">
        <ButtonComponent
          text="Cancelar"
          mode="outline"
          onPress={(event) => {
            event.preventDefault();
            reset({});
            handleVisiblityCreateAndEditActivity();
            handleResetActivityDataToEdit();
          }}
        />
        <ButtonComponent
          text={`${isEditModeInActivity.id ? 'Editar' : 'Guardar'} actividad`}
          onPress={handleSubmitActivity(handleCreateActivity)}
          disabled={isEditModeInActivity && isActivityEditButtonDisabled}
        />
      </div>
    </form>
  );
};

ActivitieForm.propTypes = {
  handleVisiblityCreateAndEditActivity: PropTypes.func,
  formActivity: PropTypes.object.isRequired,
  handleCreateActivity: PropTypes.func.isRequired,
  isEditModeInActivity: PropTypes.object,
  handleResetActivityDataToEdit: PropTypes.func,
  isActivityEditButtonDisabled: PropTypes.bool,
  disabledEditMode: PropTypes.bool,
};
ActivitieForm.defaultProps = {
  handleVisiblityCreateAndEditActivity: () => {},
  handleResetActivityDataToEdit: () => {},
  isEditModeInActivity: {},
  isActivityEditButtonDisabled: false,
  disabledEditMode: false,
};

export default ActivitieForm;
