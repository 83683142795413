//Hooks
import useHelpers from '../../../../../helpers';

const useAdminSocioCulturalInfoSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const listSocioCulturalInfoByProjectSelector = createSelector(
    (state) => state.projectSelected,
    (projectSelected) => projectSelected.social_cultural_info
  );

  return { listSocioCulturalInfoByProjectSelector };
};

export default useAdminSocioCulturalInfoSelectors;
