import useHelpers from '../../../../helpers';

const useModalsSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const showModalUpdatePhotoSelector = createSelector(
    (state) => state.showModalUpdateProfilePhoto,
    (showModalUpdateProfilePhoto) => showModalUpdateProfilePhoto
  );

  return { showModalUpdatePhotoSelector };
};

export default useModalsSelectors;
