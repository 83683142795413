//Packages
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//Hooks
import useApi from 'api';
import useModels from 'models';
import useControllers from 'controllers';
import useStrings from 'strings';

const useAdminRootContactsListCommunitiesDashboard = ({
  list_id = null,
  tabValue = 1,
  setResetAllCheckboxes,
  isScreenContactList = false,
} = {}) => {
  const history = useHistory();

  const { useActions } = useApi();
  const { dispatch, useAdminActions, useUserActions } = useActions();
  const { actSearchUser } = useUserActions();

  const { useContactListActions, useAdminUsersActions } = useAdminActions();
  const { actSetFilterDataUser } = useAdminUsersActions();

  const {
    actGetContactLists,
    actGetContactList,
    actRemoveContactToContactList,
    actAddContactToContactList,
    actUpdateContactList,
    actDeleteContactList,
    actResetDataContactListSelected,
  } = useContactListActions();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors } = useSelectors();
  const { useAdminContactListSelectors, useAdminUsersSelectors } =
    useAdminSelectors();
  const { contactListSelector } = useAdminContactListSelectors();
  const { filterDataAdminUserSelector } = useAdminUsersSelectors();
  const dataFilter = useSelector(filterDataAdminUserSelector);
  const { contactsLists, contactList } = useSelector(contactListSelector);

  const { useGeneralHooks } = useControllers();
  const { useIsFilterEmpty } = useGeneralHooks();
  const { isFilterEmpty } = useIsFilterEmpty();

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { REQUIRED_FIELD } = useFormsTypes();

  const schema = yup.object({
    name: yup.string().required(REQUIRED_FIELD),
  });

  const {
    handleSubmit,
    clearErrors,
    register,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({ resolver: yupResolver(schema), mode: 'onChange' });

  const [textSearch, setTextSearch] = useState(dataFilter.search);
  const [allUsers, setAllUsers] = useState(true);
  const firstRender = useRef(true);
  const [dataUser, setDataUser] = useState(contactList.users);
  const [orderBy, setOrderBy] = useState({
    name: true,
    last_name: true,
    country: true,
    role: true,
    email: true,
    phone: true,
    gender: true,
  });

  useEffect(() => {
    setDataUser((prevState) => {
      if (_.isEqual(prevState, contactList.users)) {
        return prevState;
      } else {
        return contactList.users;
      }
    });
  }, [contactList.users]);

  useEffect(() => {
    isScreenContactList && dispatch(actResetDataContactListSelected());
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && list_id && !isScreenContactList) {
      if (textSearch.length >= 3) {
        setAllUsers(true);
        dispatch(actSetFilterDataUser({ key: 'search', value: textSearch }));
        dispatch(
          actSearchUser(
            {
              ...dataFilter,
              search: textSearch,
              community_id: undefined,
              contact_list_id: list_id,
            },
            'contacts'
          )
        );
      } else {
        if (allUsers || firstRender.current) {
          setAllUsers(false);
          firstRender.current = false;
          dispatch(actSetFilterDataUser({ key: 'search', value: '' }));
          dispatch(
            actSearchUser(
              {
                ...dataFilter,
                community_id: undefined,
                search: undefined,
                full_name: undefined,
                contact_list_id: list_id,
              },
              'contacts'
            )
          );
        }
      }
    }
    return () => {
      isMounted = false;
    };
  }, [textSearch, list_id]);

  useEffect(() => {
    if (isScreenContactList) {
      if (textSearch.length >= 3) {
        dispatch(
          actGetContactLists(
            { ...dataFilter, paginate: 1, name: textSearch },
            () => {
              dispatch(
                actSetFilterDataUser({ key: 'name', value: textSearch })
              );
            }
          )
        );
        firstRender.current = true;
      } else {
        if (firstRender.current) {
          firstRender.current = false;
          dispatch(
            actGetContactLists(
              { ...dataFilter, paginate: 1, name: undefined },
              () => {
                dispatch(actSetFilterDataUser({ key: 'name', value: '' }));
              }
            )
          );
        }
      }
    }
  }, [textSearch]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      tabValue === 0 && dispatch(actGetContactLists({ page: 0, paginate: 0 }));
    }

    return () => {
      isMounted = false;
    };
  }, [tabValue]);

  const getContactList = ({ contactListId }, callback = () => {}) => {
    dispatch(
      actGetContactList(contactListId, (data) => {
        callback && callback(data);
        dispatch(
          actSetFilterDataUser({ key: 'contact_list_id', value: list_id })
        );
      })
    );
  };

  const addContacts = ({ contact_list, id, setOpen, setDataSelected }) => {
    getContactList({ contactListId: id }, ({ data: { users } }) => {
      const pre_users = _.map(users, (user) => user.id);
      const contact_list_id = _.map(contact_list, (contact) => contact.id);
      const new_list = _.filter(
        contact_list_id,
        (contact) => !pre_users.includes(parseInt(contact))
      );

      dispatch(
        actAddContactToContactList({ id, users: new_list }, () => {
          setOpen(false);
          setDataSelected([]);
          setResetAllCheckboxes(false);
          setTimeout(() => {
            setResetAllCheckboxes(true);
          }, 500);
        })
      );
    });
  };

  const deleteContact = (id, user) => {
    dispatch(actRemoveContactToContactList({ id, users: [user] }));
  };

  const updateContactList = (id, callback) => {
    const name = getValues('name');
    dispatch(
      actUpdateContactList({ id, name }, () => {
        callback({ active: false });
        setValue('name', null);
      })
    );
  };

  const deleteContactList = (id, callback) => {
    dispatch(
      actDeleteContactList(id, () => {
        callback({ active: false });
      })
    );
  };

  const onChangePage = (page) => {
    dispatch(
      actGetContactLists({
        page: page + 1,
        name: textSearch >= 3 ? textSearch : undefined,
      })
    );
  };

  const handleOrderBy = ({ orderByKey, isContactList = true }) => {
    if (isContactList) {
      dispatch(
        actGetContactLists({
          ...dataFilter,
          orderByKey,
          orderByValue: orderBy[orderByKey] ? 'desc' : 'asc',
        })
      );
    } else {
      dispatch(
        actSearchUser(
          {
            ...dataFilter,
            community_id: undefined,
            contact_list_id: list_id,
            orderByKey,
            orderByValue: orderBy[orderByKey] ? 'desc' : 'asc',
          },
          'contacts'
        )
      );
    }

    setOrderBy((prevState) => ({
      ...prevState,
      [orderByKey]: !prevState[orderByKey],
    }));

    dispatch(actSetFilterDataUser({ key: 'orderByKey', value: orderByKey }));
    dispatch(
      actSetFilterDataUser({
        key: 'orderByValue',
        value: orderBy[orderByKey] ? 'desc' : 'asc',
      })
    );
  };

  return {
    isFilterEmpty,
    history,
    contactsLists,
    getContactList,
    contactList,
    addContacts,
    deleteContact,
    updateContactList,
    deleteContactList,
    onChangePage,
    defaultValueTextSearch: dataFilter.search,
    users: dataUser,
    handleOrderBy,
    orderBy,

    //Search Input
    textSearch,
    setTextSearch,

    //rhf
    handleSubmit,
    clearErrors,
    register,
    errors,
    setValue,
  };
};
export default useAdminRootContactsListCommunitiesDashboard;
