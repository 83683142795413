//Packages
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//Hooks
import useApi from 'api';
import useModels from 'models';
import useControllers from 'controllers';

const useAdminRootContactsCommunitiesDashboard = () => {
  const history = useHistory();
  const { community_id } = useParams();

  const { useActions } = useApi();
  const { useUserActions, dispatch, useAdminActions } = useActions();
  const { useAdminLabelsActions } = useAdminActions();
  const { actUnassignLabelToContact } = useAdminLabelsActions();
  const { actDeleteUser, actSearchUser, actGetUser } = useUserActions();
  const { useAdminUsersActions, useContactListActions } = useAdminActions();
  const { actStoreContactList } = useContactListActions();
  const { actSetFilterDataUser, actResetAllFilterDataUser } =
    useAdminUsersActions();

  const { useSelectors } = useModels();
  const { useSelector, useUserSelectors, useAdminSelectors } = useSelectors();
  const { useAdminUsersSelectors } = useAdminSelectors();
  const { filterDataAdminUserSelector } = useAdminUsersSelectors();
  const dataFilter = useSelector(filterDataAdminUserSelector);
  const { usersSelector } = useUserSelectors();
  const users = useSelector(usersSelector);

  const { useGeneralHooks } = useControllers();
  const { useIsFilterEmpty } = useGeneralHooks();
  const { isFilterEmpty } = useIsFilterEmpty();

  const [textSearch, setTextSearch] = useState(dataFilter.search);
  const [allUsers, setAllUsers] = useState(false);
  const firstRender = useRef(true);
  const current_page = useRef(1);

  const schemaCreateContactList = Yup.object({
    name: Yup.string(),
    contacts: Yup.array(),
  });
  const [dataUser, setDataUser] = useState(users);
  const [checked, setChecked] = useState(false);
  const [resetAllCheckboxes, setResetAllCheckboxes] = useState(true);
  const [dataSelected, setDataSelected] = useState([]);

  const createContactListForm = useForm({
    resolver: yupResolver(schemaCreateContactList),
    mode: 'onChange',
  });

  useEffect(() => {
    setDataUser((prevState) => {
      if (_.isEqual(prevState, users)) {
        return prevState;
      } else {
        return users;
      }
    });
  }, [users]);

  useEffect(() => {
    return () => {
      dispatch(actResetAllFilterDataUser());
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (textSearch.length >= 3) {
        setAllUsers(true);
        dispatch(actSetFilterDataUser({ key: 'search', value: textSearch }));
        dispatch(
          actSearchUser(
            {
              ...dataFilter,
              search: textSearch,
              community_id,
            },
            'contacts'
          )
        );
      } else {
        if (allUsers || firstRender.current) {
          setAllUsers(false);
          firstRender.current = false;
          dispatch(actSetFilterDataUser({ key: 'search', value: '' }));
          dispatch(
            actSetFilterDataUser({ key: 'community_id', value: community_id })
          );
          dispatch(
            actSearchUser(
              {
                ...dataFilter,
                search: undefined,
                community_id,
              },
              'contacts'
            )
          );
        }
      }
    }
    return () => {
      isMounted = false;
    };
  }, [textSearch]);

  useEffect(() => {
    if (createContactListForm.watch('all_contacts')) {
      setChecked(
        _.map(users.data, (user) => {
          return { [user.id]: true };
        })
      );
    } else {
      setDataSelected([]);
      setChecked(
        ..._.map(users.data, (user) => {
          return { [user.id]: false };
        })
      );
    }
  }, [createContactListForm.watch('all_contacts')]);

  const deleteUser = (id) => {
    dispatch(actDeleteUser(id));
  };

  const getUsersPage = (page) => {
    current_page.current = page + 1;
    dispatch(
      actSearchUser({ ...dataFilter, page: current_page.current }, 'contacts')
    );
    dispatch(
      actSetFilterDataUser({ key: 'page', value: current_page.current })
    );
  };

  const getUser = (id) => {
    dispatch(actGetUser(id));
  };

  const getUserContactList = () => {
    const name = createContactListForm.getValues('name');
    const data = {
      users: _.map(dataSelected, (contact) => contact.id),
      name,
    };
    dispatch(
      actStoreContactList(data, () => {
        setDataSelected([]);
        createContactListForm.setValue('name', null);
        setResetAllCheckboxes(false);
        setTimeout(() => {
          setResetAllCheckboxes(true);
        }, 500);
      })
    );
  };

  const handleOrderBy = ({ orderByKey, orderByValue }) => {
    dispatch(
      actSearchUser({ ...dataFilter, orderByKey, orderByValue }, 'contacts')
    );
    dispatch(actSetFilterDataUser({ key: 'orderByKey', value: orderByKey }));
    dispatch(
      actSetFilterDataUser({ key: 'orderByValue', value: orderByValue })
    );
  };

  const unAssignLabelToContacts = ({ tag, user }) => {
    dispatch(actUnassignLabelToContact({ user, tag }));
  };

  return {
    isFilterEmpty,
    history,
    users: dataUser,
    deleteUser,
    setTextSearch,
    defaultValueTextSearch: dataFilter.search,
    getUsersPage,
    getUser,
    createContactListForm,
    getUserContactList,
    checked,
    setChecked,
    current_page,
    handleOrderBy,
    unAssignLabelToContacts,
    resetAllCheckboxes,
    setResetAllCheckboxes,
    setDataSelected,
    dataSelected,
  };
};
export default useAdminRootContactsCommunitiesDashboard;
