//Packages
import { useMemo } from 'react';

//Hooks
import useModels from '../../../models';
import useViews from '../../../views';

const useSelectLayoutByRole = () => {
  const { useLayouts } = useViews();
  const { AdminLayout, MainLayout } = useLayouts();

  const { useSelectors } = useModels();
  const { useSelector, useUserSelectors } = useSelectors();
  const { userSelector } = useUserSelectors();
  const userData = useSelector(userSelector);

  const LayoutSelected = useMemo(() => {
    switch (userData.role.id) {
      case 'ad':
        return AdminLayout;

      default:
        return MainLayout;
    }
  }, []);

  const props = {
    hasDecorationMap: userData.role.id !== 'ad',
    className: `${
      userData.role.id !== 'ad' && 'lg:px-48 xl:px-48 2xl:px-72 mx-auto'
    }`,
  };

  return { LayoutSelected, props, userData };
};

export default useSelectLayoutByRole;
