import useAdminProjectsProviders from './projects';
import useAdminLabelsProviders from './labels';
import useHomeProviders from './home';
import useInterventionsDetailHomeProvider from './interventionDetail';

const useAdminProviders = () => {
  return {
    useAdminLabelsProviders,
    useAdminProjectsProviders,
    useHomeProviders,
    useInterventionsDetailHomeProvider,
  };
};

export default useAdminProviders;
