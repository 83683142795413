//Packages
import React, { lazy } from 'react';
import PropTypes from 'prop-types';

//Hooks
import useViews from 'views';
import useStrings from 'strings';

//Styles
const StyledDropDownFilter = lazy(() =>
  import('styles/filters/filters.styles').then((mod) => ({
    default: mod.StyledDropDownFilter,
  }))
);

const StyledWrapperFilter = lazy(() =>
  import('styles/filters/filters.styles').then((mod) => ({
    default: mod.StyledWrapperFilter,
  }))
);

const StyledWarpperButtonFilters = lazy(() =>
  import('styles/filters/filters.styles').then((mod) => ({
    default: mod.StyledWarpperButtonFilters,
  }))
);

const DropDownFilter = (props) => {
  const {
    className,
    open,
    handleFilter,
    handleResetFilter,
    optionsCommunities,
    optionsContactLists,
    formFilterMessages,
  } = props;

  const { useComponents } = useViews();
  const { useInputs, useButtons } = useComponents();
  const { Select, InputForm } = useInputs();
  const { ButtonComponent } = useButtons();

  const { useConstants } = useStrings();
  const { LIST_OF_TYPES_OF_MESSAGES, LIST_OF_CHANNELS_FOR_SEND_MESSAGES } =
    useConstants();

  const { control, register, handleSubmit } = formFilterMessages;

  return (
    <StyledDropDownFilter className={className} open={open}>
      <StyledWrapperFilter>
        <Select
          name="type"
          id="type"
          placeholder="Seleccionar"
          options={LIST_OF_TYPES_OF_MESSAGES}
          label="Tipo de comunicación"
          control={control}
        />
        <Select
          name="channel"
          id="canal"
          placeholder="Seleccionar"
          control={control}
          options={LIST_OF_CHANNELS_FOR_SEND_MESSAGES}
          label="Canal de comunicación"
        />
        <Select
          name="communityReceivers"
          id="dest"
          placeholder="Seleccionar"
          control={control}
          options={[...optionsCommunities, ...optionsContactLists]}
          label="Destino"
          multiple
        />
        <InputForm
          name="created_at"
          label="Fecha de creación"
          type="date"
          className="w-full"
          placeholder="Selecciona la fecha de inicio"
          {...register('created_at')}
        />
      </StyledWrapperFilter>

      <StyledWarpperButtonFilters>
        <ButtonComponent
          variant="primary"
          mode="outline"
          className="mt-4 border-none shadow-none"
          text="Limpiar filtros"
          onPress={handleResetFilter}
        />
        <ButtonComponent
          variant="primary"
          className="mt-4"
          text="Aplicar filtros"
          onPress={handleSubmit((data) => handleFilter({ data }))}
        />
      </StyledWarpperButtonFilters>
    </StyledDropDownFilter>
  );
};

DropDownFilter.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  handleOpenMenu: PropTypes.func,
  handleFilter: PropTypes.func,
  handleResetFilter: PropTypes.func,
  optionsCommunities: PropTypes.array,
  optionsContactLists: PropTypes.array,
  formFilterMessages: PropTypes.object,
};

DropDownFilter.defaultProps = {
  className: '',
  open: false,
  handleOpenMenu: () => {},
  handleFilter: () => {},
  handleResetFilter: () => {},
  optionsCommunities: [],
  optionsContactLists: [],
  formFilterMessages: {},
};

export default DropDownFilter;
