//Hooks
import useHelpers from 'helpers';
import useModels from 'models';
import useStrings from 'strings';

const useAdminContentInterventions = () => {
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  const { useInitialStates } = useModels();
  const { useAdminInitialStates } = useInitialStates();
  const { useAdminContentInterventionsInitialStates } = useAdminInitialStates();
  const { initialStateFilterAdminInterventions } =
    useAdminContentInterventionsInitialStates();

  const { useAdminTypes } = useStrings();
  const { useAdminContentInterventionsTypes } = useAdminTypes();
  const {
    SET_FILTER_ABOUT_HOME_INTERVENTIONS,
    RESET_FILTER_ABOUT_HOME_INTERVENTIONS,
  } = useAdminContentInterventionsTypes();

  const filterAdminInterventions = createReducer(
    initialStateFilterAdminInterventions,
    {
      [SET_FILTER_ABOUT_HOME_INTERVENTIONS](state, action) {
        const { objectKey, objectValue } = action.payload;
        return {
          ...state,
          [objectKey]: objectValue,
        };
      },
      [RESET_FILTER_ABOUT_HOME_INTERVENTIONS]() {
        return initialStateFilterAdminInterventions;
      },
    }
  );

  return { filterAdminInterventions };
};

export default useAdminContentInterventions;
