//Packages
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useLoginProviders = () => {
  const login = ({ email, password }) => {
    const response = axios({
      method: 'POST',
      url: '/auth/login',
      data: { email, password },
      headers: {
        'alert-dialog': 'hidden',
      },
    });
    return trackPromise(response);
  };
  return { login };
};

export default useLoginProviders;
