//Packages
import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';

//Hooks
import useActions from '../../../../api/actions';
import useModels from '../../../../models';
import useControllers from '../../..';

//Assets
import imageBrand from '../../../../assets/img/Logomark.png';

const useHeaderNotification = () => {
  const [itemsListRef, setItemsListRef] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { dispatch, useGeneralActions } = useActions();
  const { useNotificationActions } = useGeneralActions();
  const {
    actGetNotification,
    actDeleteAllNotification,
    actDeleteNotification,
    actUpdateNotification,
  } = useNotificationActions();

  const { useSelectors } = useModels();
  const { useSelector, useGeneralSelectors } = useSelectors();
  const { useNotificationSelectors } = useGeneralSelectors();
  const { notificationsSelector } = useNotificationSelectors();
  const notifications = useSelector(notificationsSelector);

  const { useGeneralHooks } = useControllers();
  const { useInfiniteScroll, useGetDate } = useGeneralHooks();
  const { getDate } = useGetDate();
  const { isLastElementVisible } = useInfiniteScroll({
    refElement: itemsListRef,
    visibleElementFrom: '100%',
  });

  const notificationsMemo = useMemo(() => {
    return _.map(notifications.data, (notification) => ({
      id: notification.id,
      title: notification.text,
      date: getDate(notification.created_at),
      image: notification.sender?.avatar || imageBrand,
      unread: !notification.readed,
    }));
  }, [notifications.data]);

  useEffect(() => {
    dispatch(actGetNotification({ page: 1 }));
  }, []);

  useEffect(() => {
    if (isLastElementVisible && notifications.meta.last_page !== currentPage) {
      dispatch(
        actGetNotification(
          {
            page: currentPage + 1,
            infinity_scroll: true,
          },
          () => {
            setCurrentPage(currentPage + 1);
          }
        )
      );
    }
  }, [isLastElementVisible]);

  const deleteAllNotifications = (setOpenModalDeleteAll) => {
    dispatch(
      actDeleteAllNotification(() => {
        setOpenModalDeleteAll(false);
      })
    );
  };
  const deleteNotification = (id) => {
    dispatch(actDeleteNotification({ id }));
  };
  const updateNotificacion = (id) => {
    dispatch(actUpdateNotification({ id }));
  };
  return {
    notifications: notificationsMemo,
    deleteAllNotifications,
    deleteNotification,
    updateNotificacion,
    setItemsListRef,
  };
};
export default useHeaderNotification;
