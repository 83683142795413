import useAdminInterventionsRootProviders from './root';
import useAdminViewInterventionProviders from './view';

const useAdminInterventionsProviders = () => {
  return {
    useAdminViewInterventionProviders,
    useAdminInterventionsRootProviders,
  };
};

export default useAdminInterventionsProviders;
