import AdminDashboardAbcTrajectory from './AbcTrajectory';
import AdminDashboardAdditionalInfo from './AdditionalInfo';
import AdminDashboardFestivitiesAndTraditions from './FestivitiesAndTraditions';
import AdminDashboardRecommendations from './Recommendations';
import AdminDashboardProject from './Root';
import AdminDashboardSocioCulturalInfo from './SocioCulturalInfo';
import useAdminProjectsDashboardCommunitiesScreens from './Communities';
import AdminProjectsDashboardDocumentation from './Documentation';
import useAdminProjectsDashboardInterventionsScreens from './Interventions';

const useAdminProjectsDashboardScreens = () => {
  const adminDashboardProjectsCommunitiesScreens =
    useAdminProjectsDashboardCommunitiesScreens();
  const adminDashboardProjectsInterventionsScreens =
    useAdminProjectsDashboardInterventionsScreens();
  return {
    AdminDashboardProject,
    AdminDashboardAbcTrajectory,
    AdminDashboardAdditionalInfo,
    AdminDashboardFestivitiesAndTraditions,
    AdminDashboardRecommendations,
    AdminDashboardSocioCulturalInfo,
    AdminProjectsDashboardDocumentation,
    ...adminDashboardProjectsCommunitiesScreens,
    ...adminDashboardProjectsInterventionsScreens,
  };
};

export default useAdminProjectsDashboardScreens;
