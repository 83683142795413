//Packages
import { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
//Hooks
import useModels from 'models';
import useHelpers from 'helpers';
import useStrings from 'strings';
import useActions from 'api/actions';

const useAdminDeepenKnowledgeActivityCommunityInterventions = ({
  activities,
  setActivities,
  deepenType,
  deepeingModality = null,
}) => {
  const [showModal, setShowModal] = useState({
    show: false,
    index: 0,
  });
  const [editButton, setEditButton] = useState(false);
  const [cancelForm, setCancelForm] = useState(false);
  const [hibridValidation, setHibridValidation] = useState({
    remote: false,
    face_to_face: false,
  });
  const [typeFormActive, setTypeFormActive] = useState(null);
  const editingActivity = useRef(false);

  useEffect(() => {
    setActivities({ activities: [] });
  }, [deepeingModality]);

  const { useQuickFunctions } = useHelpers();
  const {
    getUTCDateOfTomorrow,
    regexValidations,
    isSelectedDateGreaterThanTodayDate,
    setDefaultValueInputDateTimeWithTimeLocalZone,
    convertDateToISO,
  } = useQuickFunctions();

  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();

  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const { useAdminDevelopmentInterventionKnowCommunityActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const { actUpdateActivityDeepenKnowledgeCommunityIntervention } =
    useAdminDevelopmentInterventionKnowCommunityActions();

  const { intervention_id } = useParams();

  const { useSelectors } = useModels();
  const { useAdminSelectors, useSelector } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionsSelector } = useAdminInterventionsSelectors();
  const { deepen_knowledge } = useSelector(getInterventionsSelector);
  const { getInterventionDetailToEditSelector } =
    useAdminInterventionsSelectors();
  const { communities } = useSelector(getInterventionDetailToEditSelector);

  const minDateToSelect = getUTCDateOfTomorrow();

  const communitiesOption = useMemo(() => {
    return _.map(communities, (community) => {
      return {
        label: community.name,
        value: community.id,
      };
    });
  }, []);

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const {
    REQUIRED_FIELD,
    NUMBER_NOT_VALID,
    MAX_LENGTH_500,
    MAX_LENGTH_100,
    DATE_GREATER_THAN_TODAY,
  } = useFormsTypes();
  const duration = regexValidations({ regex: 'duration' });

  const schemaActivity = yup.object({
    modality_type:
      deepeingModality === 'h' &&
      yup.object().required(REQUIRED_FIELD).nullable(),
    name: deepeingModality
      ? yup.string().max(100, MAX_LENGTH_100).required(REQUIRED_FIELD)
      : yup.string(),
    platform:
      deepeingModality === 'r' || hibridValidation.remote
        ? yup.string().required(REQUIRED_FIELD)
        : yup.string(),
    duration: deepeingModality
      ? yup
          .string()
          .required(REQUIRED_FIELD)
          .matches(duration, NUMBER_NOT_VALID)
          .test('maxHour', 'El tiempo maximo permitido es 04:59', (value) => {
            return value && parseInt(value.split(':')[0]) < 5;
          })
      : yup.string(),
    community_place_id:
      deepeingModality === 'f' || hibridValidation.face_to_face
        ? yup.object().required(REQUIRED_FIELD).nullable()
        : yup.object().nullable(),
    description: deepeingModality
      ? yup.string().max(500, MAX_LENGTH_500).required(REQUIRED_FIELD)
      : yup.string(),
    resources: deepeingModality
      ? yup.string().max(500, MAX_LENGTH_500).required(REQUIRED_FIELD)
      : yup.string(),
    community_support: deepeingModality
      ? yup.string().max(500, MAX_LENGTH_500).required(REQUIRED_FIELD)
      : yup.string(),
    date: deepeingModality
      ? yup
          .date()
          .required(REQUIRED_FIELD)
          .test('dateValidation', DATE_GREATER_THAN_TODAY, (value) => {
            return value
              ? isSelectedDateGreaterThanTodayDate({ selectedDate: value })
              : true;
          })
          .typeError(REQUIRED_FIELD)
      : yup.date().nullable(),
  });

  const formActivity = useForm({
    resolver: yupResolver(schemaActivity),
    mode: 'onChange',
  });

  useEffect(() => {
    if (deepenType) {
      setEditButton(false);
    }
  }, [deepenType]);

  useEffect(() => {
    if (deepeingModality && editButton) {
      setEditButton(false);
    }
  }, [deepeingModality]);

  useEffect(() => {
    if (formActivity.watch('modality_type')?.value === 'f') {
      setHibridValidation({
        remote: false,
        face_to_face: true,
      });
    } else if (formActivity.watch('modality_type')?.value === 'r') {
      setHibridValidation({
        remote: true,
        face_to_face: false,
      });
    } else {
      setHibridValidation({
        remote: false,
        face_to_face: false,
      });
    }
  }, [formActivity.watch('modality_type')]);

  const resetFormActivity = () => {
    formActivity.reset({
      modality_type: undefined,
      name: '',
      date: '',
      hour: '',
      platform: '',
      duration: '',
      community_place_id: undefined,
      description: '',
      resources: '',
      community_support: '',
    });
  };

  const handleAddActivity = (e) => {
    editingActivity.current = false;
    e.preventDefault();
    setEditButton(true);
    setCancelForm(true);
    setActivities({ activities: [...activities, { expanded: true }] });
  };

  const handleDeleteActivity = (e, index) => {
    e.preventDefault();
    setActivities({
      activities: _.filter(activities, (activity, i) => i !== index),
      crudStatus: 'delete',
    });
  };

  const handleChangeTypeForm = (data) => {
    setTypeFormActive(data.data.value);
  };

  const editActivity = (index) => {
    editingActivity.current = true;
    setEditButton(true);
    setActivities({
      activities: _.map(activities, (activity, i) => {
        if (index === i) {
          activity.expanded = true;
        }
        return activity;
      }),
    });
    const activity = activities[index];
    setTypeFormActive(activity.modality_type?.value);
    if (activity.community_place_id?.value) {
      formActivity.setValue('community_place_id', activity.community_place_id);
    } else {
      formActivity.setValue(
        'community_place_id',
        communitiesOption.find((o) => o.value === activity.community_place_id)
      );
    }
    formActivity.setValue('modality_type', {
      label: activity.modality_type?.label,
      value: activity.modality_type?.value,
    });
    formActivity.setValue('platform', activity.platform);
    formActivity.setValue('name', activity.name);
    formActivity.setValue('duration', activity.duration);
    formActivity.setValue('description', activity.description);
    formActivity.setValue('resources', activity.resources);
    formActivity.setValue('community_support', activity.community_support);
    formActivity.setValue(
      'date',
      setDefaultValueInputDateTimeWithTimeLocalZone({
        dateInUTC: activity.date,
      }).split('.')[0]
    );
  };

  const saveActivities = (data, index) => {
    setActivities({
      activities: _.map(activities, (activity, i) => {
        if (index === i) {
          data.expanded = false;
          return { ...data, date: convertDateToISO({ date: data.date }) };
        } else {
          return activity;
        }
      }),
      crudStatus: editingActivity.current ? 'update' : 'add',
    });
    setCancelForm(false);
    setEditButton(false);
    resetFormActivity();
  };

  const closeForm = (index, e = null) => {
    editingActivity.current = false;
    if (e) {
      e.preventDefault();
    }
    if (editButton && !cancelForm) {
      setActivities({
        activities: _.map(activities, (activity, i) => {
          if (index === i) {
            activity.expanded = false;
          }
          return activity;
        }),
      });
    } else {
      setCancelForm(false);
      setActivities({
        activities: _.filter(activities, (activity, i) => i !== index),
      });
    }
    setEditButton(false);
    resetFormActivity();
  };

  const handleRejectedActivity = (data, activity_id) => {
    dispatch(
      actUpdateActivityDeepenKnowledgeCommunityIntervention({
        intervention_id,
        deepen_knowledge_id: deepen_knowledge.id,
        activity_id,
        data,
      })
    );
  };

  return {
    deepen_knowledge,
    typeFormActive,
    formActivity,
    handleAddActivity,
    handleDeleteActivity,
    handleChangeTypeForm,
    editActivity,
    communitiesOption,
    minDateToSelect,
    showModal,
    saveActivities,
    setShowModal,
    closeForm,
    editButton,
    handleRejectedActivity,
    setDefaultValueInputDateTimeWithTimeLocalZone,
  };
};

export default useAdminDeepenKnowledgeActivityCommunityInterventions;
