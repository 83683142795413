//Packages
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useEffect, useState } from 'react';
import _ from 'lodash';

//Hooks
import useControllers from 'controllers';

const useGeneralModal = ({ dataGeneralModal }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [currentMessage, setCurrenMessage] = useState(1);
  const [messageMobileScreen, setMessageMobileScreen] = useState('');
  const [forceHide, setForceHide] = useState(false);

  const { useGeneralHooks } = useControllers();
  const { useGetScreenSize } = useGeneralHooks();
  const { sizeScreen } = useGetScreenSize();

  const handleForceHide = () => {
    setForceHide(true);
    setTimeout(() => {
      setForceHide(false);
    }, 1000);
  };

  const handleClose = () => {
    if (!messageMobileScreen) {
      if (dataGeneralModal.message.length > currentMessage) {
        setCurrenMessage(currentMessage + 1);
      } else {
        dataGeneralModal.onHideModal();
      }
    } else {
      handleForceHide();
      history.replace('/');
      setMessageMobileScreen('');
    }
  };

  useEffect(() => {
    if (currentMessage !== 1) {
      setCurrenMessage(1);
    }
  }, [dataGeneralModal.message]);

  useEffect(() => {
    if (
      sizeScreen.x > 0 &&
      sizeScreen.x < 768 &&
      _.some(['/admin', '/intervention'], (route) =>
        _.includes(pathname, route)
      )
    ) {
      setMessageMobileScreen(
        'Esta función no está disponible para dispositivos móviles'
      );
      document.getElementById('root').classList.add('mobile-none-func');
    } else {
      handleForceHide();
      setMessageMobileScreen('');
      document.getElementById('root').classList.remove('mobile-none-func');
    }
  }, [sizeScreen.x, pathname]);

  return { handleClose, currentMessage, messageMobileScreen, forceHide };
};

export default useGeneralModal;
