import React from 'react';
import PropTypes from 'prop-types';

//Hooks
import useControllers from 'controllers';
import useViews from 'views';

//Components
import FormAspects from './components/FormAspects';
import Sessions from './components/Sessions';

const DefineIntervention = (props) => {
  const { setFirstOrLastView } = props;
  const { useComponents } = useViews();
  const { useTypographies, useBadges, useCards } = useComponents();
  const { TypographyComponent } = useTypographies();
  const { CardNotification } = useCards();
  const { DefaultBadge } = useBadges();

  const { useScreenHooks } = useControllers();
  const { useAdmin } = useScreenHooks();
  const { useAdminProjects } = useAdmin();
  const { useAdminDashboardProjectsHooks } = useAdminProjects();
  const { useAdminInterventionsDashboardControllers } =
    useAdminDashboardProjectsHooks();
  const { useAdminViewInterventionsControllers } =
    useAdminInterventionsDashboardControllers();
  const { useAdminViewInterventionsDevelopmentIntervention } =
    useAdminViewInterventionsControllers();
  const { useDefineInterventionInterventions } =
    useAdminViewInterventionsDevelopmentIntervention();
  const { useArtistDefineInterventionInterventionsControllers } =
    useDefineInterventionInterventions();
  const { useArtistDefineInterventionRoot } =
    useArtistDefineInterventionInterventionsControllers();
  const { textForLimitDate, define_intervention, viewDefine, setViewDefine } =
    useArtistDefineInterventionRoot();

  return (
    <>
      {(define_intervention.state.id === 'p' ||
        define_intervention.state.id === 'c') && (
        <CardNotification
          className="w-5/12 mx-auto py-9 px-4 mt-16"
          title={
            <TypographyComponent
              text={define_intervention.state.name}
              variant="h2"
              className="font-bold text-primary icon-watch text-center"
            />
          }
        />
      )}
      <TypographyComponent
        text="Definir la intervención"
        variant="h2"
        className="text-primary mt-2 font-bold"
      />
      <TypographyComponent
        text="Aquí entramos en la parte creativa! Tu primera versión de la intervención ASCC."
        variant="p"
        className="text-neutral-950 mt-2 mb-4"
      />
      <TypographyComponent
        text="Te pedimos ser lo mas espécifico posible en cada punto.
        Si tienes dudas recuerda que siempre puedes comunicarte con tu socio de ejecución."
        variant="p"
        className="text-neutral-950 mt-2 mb-4"
      />
      {define_intervention.state.id === 'ip' && (
        <>
          {textForLimitDate.text !== 'No hay fecha limite' && (
            <TypographyComponent
              text="OJO! Recuerda que hay una fecha límite para completar este proceso"
              variant="p"
              className="text-neutral-950 mt-2"
            />
          )}

          <DefaultBadge
            label={`Fecha limite para enviar definición de la intervención: ${textForLimitDate.text}`}
            variant={textForLimitDate.colorBadge}
            type="light"
            className="mt-2"
          />
        </>
      )}

      {define_intervention.state.id !== 'p' && (
        <div className="mt-10">
          {viewDefine === 1 && <FormAspects setViewDefine={setViewDefine} />}
          {viewDefine === 2 && (
            <Sessions
              setFirstOrLastView={setFirstOrLastView}
              setViewDefine={setViewDefine}
            />
          )}
        </div>
      )}
    </>
  );
};

DefineIntervention.propTypes = {
  setFirstOrLastView: PropTypes.func,
};

DefineIntervention.defaultProps = {
  setFirstOrLastView: () => {},
};

export default DefineIntervention;
