//Packages
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

//Hooks
import useModels from 'models';
import useApi from 'api';
import useStrings from 'strings';

const SELECT_OPTION_DEFINITION = {
  0: 'one',
  1: 'two',
  2: 'three',
};

const useArtistDefineInterventionKeyFactors = () => {
  const { intervention_id } = useParams();

  const { useActions } = useApi();
  const { dispatch, useAdminActions } = useActions();
  const { useAdminProjectsActions } = useAdminActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();
  const { useAdminViewInterventionDevelopmentInterventionActions } =
    useAdminViewInterventionsActions();
  const { useAdminDevelopmentInterventionDefineInterventionActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const { actUpdateDefineProjectInterventionArtist } =
    useAdminDevelopmentInterventionDefineInterventionActions();

  const { useSelectors } = useModels();
  const { useAdminSelectors, useSelector } = useSelectors();
  const { useAdminProjectsSelectors } = useAdminSelectors();
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const { getInterventionsSelector } = useAdminInterventionsSelectors();
  const { define_intervention } = useSelector(getInterventionsSelector);

  const { useMessagesTypes } = useStrings();
  const { useFormsTypes } = useMessagesTypes();
  const { MAX_LENGTH_150 } = useFormsTypes();

  const schemaDefineKeyFactors = yup.object({
    key_factor_one: yup.string().max(150, MAX_LENGTH_150),
    key_factor_two: yup.string().max(150, MAX_LENGTH_150),
    key_factor_three: yup.string().max(150, MAX_LENGTH_150),
    minTwoKeyFactors: yup
      .bool()
      .when(['key_factor_one', 'key_factor_two', 'key_factor_three'], {
        is: (...keyFactorsArray) => {
          const keyFactorsArrayAux = _.filter(
            keyFactorsArray,
            (keyFactor) => keyFactor
          );

          return keyFactorsArrayAux.length < 2;
        },
        then: yup
          .bool()
          .required(
            '*Debes asignar como mínimo dos aspectos claves para continuar con la definición'
          ),
        otherwise: yup.bool().notRequired(),
      }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    resolver: yupResolver(schemaDefineKeyFactors),
    mode: 'onChange',
    defaultValues: {
      key_factor_one: define_intervention.key_factors?.[0],
      key_factor_two: define_intervention.key_factors?.[1],
      key_factor_three: define_intervention.key_factors?.[2],
    },
  });

  const updateKeyFactors = (data, setViewDefine) => {
    !data.key_factor_one && delete data.key_factor_one;
    !data.key_factor_two && delete data.key_factor_two;
    !data.key_factor_three && delete data.key_factor_three;

    let keyFactorsOfBackend = {};
    _.forEach(define_intervention.key_factors, (keyFactor, index) => {
      return (keyFactorsOfBackend = {
        ...keyFactorsOfBackend,
        [`key_factor_${SELECT_OPTION_DEFINITION[index]}`]: keyFactor,
      });
    });

    const keyFactorsOfBackendArray = _.filter(
      [data.key_factor_one, data.key_factor_two, data.key_factor_three],
      (keyFactor) => keyFactor
    );
    if (
      define_intervention.key_factors?.length > 0 &&
      _.isEqual(data, keyFactorsOfBackend)
    ) {
      setViewDefine(2);
    } else {
      dispatch(
        actUpdateDefineProjectInterventionArtist(
          {
            intervention_id,
            phase_id: define_intervention.id,
            key_factors: keyFactorsOfBackendArray,
          },
          () => {
            setViewDefine(2);
          }
        )
      );
    }
  };

  return {
    define_intervention,
    updateKeyFactors,
    register,
    handleSubmit,
    errors,
    isValid,
    watch,
  };
};

export default useArtistDefineInterventionKeyFactors;
