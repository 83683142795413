import Home from './Home';
import Login from './Auth/Login';
import Register from './Auth/Register';
import Profile from './Profile';
import RecoverPassword from './Auth/RecoverPassword';
import ChangePassword from './Auth/ChangePassword';
import Dashboard from './Dashboard';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicies from './PrivacyPolicies';
import AboutRoles from './AboutRoles';
import useAdminScreens from './Admin';
import useInterventionsAdminScreens from './Admin/Interventions';
import InterventionDetail from './InterventionDetail';

const useScreens = () => {
  const {
    useUsersAdminScreens,
    useProjectsAdminScreens,
    useContactsScreen,
    useLabelsAdminScreens,
    useAdminHomeScreens,
  } = useAdminScreens();
  const adminUsersScreens = useUsersAdminScreens();
  const adminProjectsScreens = useProjectsAdminScreens();
  const adminContactScreens = useContactsScreen();
  const adminLabelScreens = useLabelsAdminScreens();
  const adminHomeScreens = useAdminHomeScreens();
  const adminInterventionScreens = useInterventionsAdminScreens();

  return {
    Home,
    Login,
    Register,
    Profile,
    RecoverPassword,
    Dashboard,
    TermsAndConditions,
    PrivacyPolicies,
    AboutRoles,
    ChangePassword,
    ...adminUsersScreens,
    ...adminProjectsScreens,
    ...adminContactScreens,
    ...adminLabelScreens,
    ...adminHomeScreens,
    ...adminInterventionScreens,
    InterventionDetail,
  };
};

export default useScreens;
