import useAdminProjectsReducers from './projects';
import useAdminUsersReducers from './users';
import useContactListReducer from './contactList';
import useAdminLabelsReducers from './labels';
import useAdminHomeReducer from './home';
import useAdminContentInterventions from './interventions';

const useAdminReducers = () => {
  return {
    useAdminUsersReducers,
    useContactListReducer,
    useAdminProjectsReducers,
    useAdminLabelsReducers,
    useAdminHomeReducer,
    useAdminContentInterventions,
  };
};

export default useAdminReducers;
