const useAdminUsersTypes = () => {
  const ADD_DATA_FILTER_ADMIN_USERS = 'ADD_DATA_FILTER_ADMIN_USERS';
  const SAVE_DATA_FILTER_ADMIN_USERS = 'SAVE_DATA_FILTER_ADMIN_USERS';
  const RESET_DATA_FILTER_ADMIN_USERS = 'RESET_DATA_FILTER_ADMIN_USERS';
  const RESET_ALL_DATA_FILTER_ADMIN_USERS_CONTACTS =
    'RESET_ALL_DATA_FILTER_ADMIN_USERS_CONTACTS';

  return {
    ADD_DATA_FILTER_ADMIN_USERS,
    SAVE_DATA_FILTER_ADMIN_USERS,
    RESET_DATA_FILTER_ADMIN_USERS,
    RESET_ALL_DATA_FILTER_ADMIN_USERS_CONTACTS,
  };
};

export default useAdminUsersTypes;
