//Packages
import { useEffect, useMemo, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';

//Hooks
import useApi from 'api';
import useStrings from 'strings';
import useModels from 'models';
import useHelpers from 'helpers';
import useControllers from 'controllers';

const useAdminViewInterventionsCommunicationDiffusion = ({
  difussionOrGetInformation,
  isMessagesOfArtist = '',
  draftOrNoDraft = 'nd',
  formToValidateOptions = 'formDiffusionMessage',
} = {}) => {
  const { intervention_id } = useParams();

  const firstRenderRef = useRef(false);
  const dataTableHeaderArtistMessagesRef = useRef([
    { header: 'Tipo de solicitud', noneOrder: true },
    { header: 'Destino ', noneOrder: true },
    { header: 'Fecha solicitud', orderByKey: 'request_sended_date' },
    { header: 'Estado', orderByKey: 'state' },
    { header: '' },
  ]);
  const dataTableHeaderArtistDraftMessagesRef = useRef([
    { header: 'Tipo de comunicación', orderByKey: 'type' },
    { header: 'Canal de comunicación ', orderByKey: 'channel' },
    { header: 'Destino', noneOrder: true },
    { header: 'Fecha de creación', orderByKey: 'created_at' },
    { header: '' },
  ]);
  const dataTableHeaderAdminMessagesRef = useRef([
    { header: 'Canal de comunicación', orderByKey: 'channel' },
    { header: 'Destino ', orderByKey: 'communityReceivers', noneOrder: true },
    { header: 'Mensaje', orderByKey: 'message', noneOrder: true },
    { header: 'Estado', orderByKey: 'message', noneOrder: true },
    { header: 'Fecha de envío', orderByKey: 'sended_date' },
  ]);
  const dataTableHeaderAdminRequestMessagesArtistRef = useRef([
    { header: 'Tipo de solicitud', orderByKey: 'type', noneOrder: true },
    { header: 'Canal de comunicación ', orderByKey: 'to', noneOrder: true },
    { header: 'Destino', orderByKey: 'message', noneOrder: true },
    { header: 'Fecha de solicitud', orderByKey: 'created_at' },
    { header: 'Estado', orderByKey: 'state' },
  ]);

  const [activeItem, setActiveItem] = useState({
    active: 0,
    action: '',
    idSelectedMessage: '',
  });
  const [orderBy, setOrderBy] = useState({
    created_at: true,
    state: true,
    type: true,
    channel: true,
  });
  const initialStateNumberOptions = [{ id: Date.now(), value: '' }];
  const [numberOptions, setNumberOptions] = useState(initialStateNumberOptions);
  const [validateOptionsRules, setValidateOptionsRules] = useState(false);

  const { useActions } = useApi();
  const { dispatch, useAdminActions, useGeneralActions } = useActions();
  const { useAdminProjectsActions, useContactListActions } = useAdminActions();
  const { useTabSelectedActions } = useGeneralActions();
  const { actSetTabSelected } = useTabSelectedActions();
  const { actGetContactLists } = useContactListActions();
  const { useAdminInterventionsActions } = useAdminProjectsActions();
  const { useAdminViewInterventionsActions } = useAdminInterventionsActions();
  const {
    useAdminViewInterventionsCommunicationActions,
    useAdminViewInterventionDevelopmentInterventionActions,
  } = useAdminViewInterventionsActions();
  const {
    actDeleteMessageIntervention,
    actGetMessageIntervention,
    actGetMessagesIntervention,
    actSendMessagesIntervention,
    actSetFilterListOfMessages,
    actResetFilterListOfMessages,
    actRejectAcceptRequestMessageAsAdmin,
    actResetSelectedMessageIntervention,
    actDownloadFileOfCollectionMessagesIntervention,
  } = useAdminViewInterventionsCommunicationActions();
  const { useAdminDevelopmentInterventionKnowCommunityActions } =
    useAdminViewInterventionDevelopmentInterventionActions();
  const { actSendMessageDeepenKnowledgeIntervention } =
    useAdminDevelopmentInterventionKnowCommunityActions();

  const { useMessagesTypes, useConstants } = useStrings();
  const { COLORS_BADGE_STATUS_MESSAGE } = useConstants();
  const { useFormsTypes } = useMessagesTypes();
  const {
    REQUIRED_FIELD,
    DATE_GREATER_THAN_TODAY,
    MAX_LENGTH_250,
    MAX_LENGTH_320,
    MAX_LENGTH_150,
  } = useFormsTypes();

  const { useSelectors } = useModels();
  const { useSelector, useAdminSelectors, useUserSelectors } = useSelectors();
  const { userSelector } = useUserSelectors();
  const { useAdminProjectsSelectors, useAdminContactListSelectors } =
    useAdminSelectors();
  const { contactListSelector } = useAdminContactListSelectors();
  const { contactsLists } = useSelector(contactListSelector);
  const { useAdminInterventionsSelectors } = useAdminProjectsSelectors();
  const {
    getInterventionsCommunicationMessagesSelector,
    getInterventionDetailToEditSelector,
    getToSendMessageInterventionSelector,
  } = useAdminInterventionsSelectors();
  const toSendMessage = useSelector(getToSendMessageInterventionSelector);
  const { listOfMessages, selectedMessage, filter } = useSelector(
    getInterventionsCommunicationMessagesSelector
  );
  const selectedIntervention = useSelector(getInterventionDetailToEditSelector);
  const selectedLoggedInUser = useSelector(userSelector);

  const { useGeneralHooks } = useControllers();
  const { useToggleModal, useGetDate, useCollectInformationMessages } =
    useGeneralHooks();
  const { getMonthAndDay } = useGetDate();
  const [activeConfirmModal, setActiveConfirmModal] = useToggleModal();
  const [activeAtLeastItemDraft, setActiveAtLeastItemDraft] = useToggleModal();
  const [activeDropDown, handleOpenMenu] = useToggleModal();
  const [activeDeleteModal, setActiveDeleteModal] = useToggleModal();
  const {
    handleChangePageAnswers,
    handleGetDetailResponseCollectionMessage,
    setDataCollectionInformationAnswersSelected,
    dataCollectionInformationAnswersSelected,
  } = useCollectInformationMessages({ selectedMessage });

  const { useQuickFunctions } = useHelpers();
  const {
    isSelectedDateGreaterThanTodayDate,
    getYearMonthDayFromDate,
    getUTCDateOfTomorrow,
    convertDateToISO,
    onFormatMessageAboutCommunities,
    dateExists,
    downloadFileFromBackend,
  } = useQuickFunctions();

  const minDateToSelect = getUTCDateOfTomorrow();

  const messagesList = useMemo(() => {
    return _.map(listOfMessages.data, (message) => {
      const communityMessage = onFormatMessageAboutCommunities({
        message,
        contactsLists,
        selectedIntervention,
      });
      return {
        id: message.id,
        type: message.type.name,
        typeMessage: message.type.id,
        to: communityMessage,
        message: message.message,
        date: getMonthAndDay({ date: message.created_at }),
        suggestedDate:
          message.send_date && getMonthAndDay({ date: message.send_date }),
        sendedDate:
          message.sended_date &&
          getMonthAndDay({
            date: message.sended_date,
          }),
        requestDate: getMonthAndDay({ date: message.request_sended_date }),
        state: message.state,
        channel: message?.channel?.name,
        colorBadgeStatusMessage: COLORS_BADGE_STATUS_MESSAGE[message.state.id],
      };
    });
  }, [listOfMessages]);

  useEffect(() => {
    return () => {
      dispatch(actResetFilterListOfMessages());
    };
  }, []);

  useEffect(() => {
    handleGetDiffusionMessages();
    selectedLoggedInUser.role.id !== 'ar' &&
      dispatch(actGetContactLists({ paginate: 0 }));
  }, []);

  useEffect(() => {
    if (toSendMessage.id) {
      formAcceptMessage.reset({
        type: toSendMessage.type,
        channel: {
          label: toSendMessage.channel.name,
          value: toSendMessage.channel.id,
        },
        message: toSendMessage.body,
      });
    }
  }, [toSendMessage]);

  const optionsCommunities = useMemo(
    () =>
      _.map(selectedIntervention.communities, (community) => ({
        value: community.id,
        label: community.name,
        type: 'community',
      })),
    [selectedIntervention.communities]
  );

  const optionsContactLists = useMemo(
    () =>
      _.map(contactsLists.data, (contactList) => ({
        value: contactList.id,
        label: contactList.name,
        type: 'contactList',
      })),
    [contactsLists]
  );

  const schemaCreateMessageIntervention = yup.object({
    recommendations: yup
      .string()
      .required(REQUIRED_FIELD)
      .max(250, MAX_LENGTH_250)
      .nullable(),
    message: yup
      .string()
      .required(REQUIRED_FIELD)
      .max(320, MAX_LENGTH_320)
      .when('gi_type', {
        is: (gi_type) => gi_type?.value === 'a',
        then: yup.string().nullable().max(150, MAX_LENGTH_150),
        otherwise: yup.string().nullable().max(320, MAX_LENGTH_320),
      }),
    objective: yup
      .string()
      .required(REQUIRED_FIELD)
      .nullable()
      .max(250, MAX_LENGTH_250),
    type: yup.object().required(REQUIRED_FIELD).nullable(),
    channel: yup.object().required(REQUIRED_FIELD).nullable(),
    communities: yup
      .array()
      .required(REQUIRED_FIELD)
      .min(1, 'Debe existir al menos un destino'),
    date_checkbox: yup.boolean(),
    send_date: yup.date().when('date_checkbox', {
      is: true,
      then: yup
        .date()
        .required(REQUIRED_FIELD)
        .test('dateValidation', DATE_GREATER_THAN_TODAY, (value) => {
          return value
            ? isSelectedDateGreaterThanTodayDate({ selectedDate: value })
            : true;
        })
        .typeError(REQUIRED_FIELD),
      otherwise: yup
        .date()
        .nullable()
        .default(null)
        .transform((curr, orig) => (orig === '' ? null : curr)),
    }),
    gi_type: yup.object().when('type', {
      is: (type) => type?.value === 'gi',
      then: yup.object().required(REQUIRED_FIELD),
      otherwise: yup.object().notRequired(),
    }),
    type_information: yup.object().when(['gi_type', 'type'], {
      is: (gi_type, type) =>
        gi_type && gi_type?.value === 'a' && type && type?.value === 'gi',
      then: yup.object().required(REQUIRED_FIELD),
      otherwise: yup.object().notRequired().nullable(),
    }),
    options: yup.array().when('type_information', {
      is: (type_information) => type_information?.value === 'qn',
      then: yup
        .array()
        .required(REQUIRED_FIELD)
        .nullable()
        .min(2, 'Debe existir al menos dos opciones de respuesta')
        .test(
          'emptyValues',
          'Las respuestas no deben estar vacías',
          (optionsArray) => {
            if (optionsArray?.length) {
              const optionsNoEmptys = _.filter(optionsArray, (option) => {
                return Boolean(option);
              });
              return !(numberOptions.length > optionsNoEmptys.length);
            }
            return true;
          }
        ),
      otherwise: yup.array().notRequired(),
    }),
  });

  const schemaDenyMessage = yup.object({
    rejected_cause: yup.string().required(REQUIRED_FIELD),
    // .max(250, MAX_LENGTH_250),
  });

  const schemaAcceptMessage = yup.object({
    message: yup
      .string()
      .required(REQUIRED_FIELD)
      .max(320, MAX_LENGTH_320)
      .nullable(),
    type: yup.object().nullable(),
    typeDest: yup.object().required(REQUIRED_FIELD).nullable(),
    communities: yup
      .array()
      .required(REQUIRED_FIELD)
      .min(1, 'Debe existir al menos un destino')
      .nullable(),
    channel: yup.object().required(REQUIRED_FIELD).nullable(),
    date_checkbox: yup.boolean(),
    send_date: yup.date().when('date_checkbox', {
      is: true,
      then: yup
        .date()
        .required(REQUIRED_FIELD)
        .test('dateValidation', DATE_GREATER_THAN_TODAY, (value) => {
          return value
            ? isSelectedDateGreaterThanTodayDate({ selectedDate: value })
            : true;
        })
        .typeError(REQUIRED_FIELD),
      otherwise: yup
        .date()
        .nullable()
        .default(null)
        .transform((curr, orig) => (orig === '' ? null : curr)),
    }),
    gi_type: yup.object().when('type', {
      is: (type) => type?.value === 'gi',
      then: yup.object().required(REQUIRED_FIELD),
      otherwise: yup.object().notRequired(),
    }),
    type_information: yup.object().when(['gi_type', 'type'], {
      is: (gi_type, type) =>
        gi_type && gi_type?.value === 'a' && type && type?.value === 'gi',
      then: yup.object().required(REQUIRED_FIELD),
      otherwise: yup.object().notRequired().nullable(),
    }),
    options: yup.array().when('type_information', {
      is: (type_information) => type_information?.value === 'qn',
      then: yup
        .array()
        .required(REQUIRED_FIELD)
        .nullable()
        .min(2, 'Debe existir al menos dos opciones de respuesta')
        .test(
          'emptyValues',
          'Las respuestas no deben estar vacías',
          (optionsArray) => {
            if (optionsArray?.length) {
              const optionsNoEmptys = _.filter(optionsArray, (option) => {
                return Boolean(option);
              });
              return !(numberOptions.length > optionsNoEmptys.length);
            }
            return true;
          }
        ),
      otherwise: yup.array().notRequired(),
    }),
  });

  const formDiffusionMessage = useForm({
    resolver: yupResolver(schemaCreateMessageIntervention),
    mode: 'onChange',
  });

  const formFilterMessages = useForm({});

  const formDenyMessage = useForm({
    resolver: yupResolver(schemaDenyMessage),
    mode: 'onChange',
  });

  const formAcceptMessage = useForm({
    resolver: yupResolver(schemaAcceptMessage),
    mode: 'onChange',
  });

  const formToValidateOptionsAux =
    formToValidateOptions === 'formDiffusionMessage'
      ? formDiffusionMessage
      : formAcceptMessage;

  const watchOptions = formToValidateOptionsAux.watch('options');
  const watchTypeCollection = formToValidateOptionsAux.watch('gi_type')?.value;
  const watchTypeInformation =
    formToValidateOptionsAux.watch('type_information')?.value;

  useEffect(() => {
    if (
      watchTypeCollection === 'm' &&
      formToValidateOptionsAux.watch('channel')?.value === 'sms'
    ) {
      formToValidateOptionsAux.setValue('channel', null);
    }
  }, [watchTypeCollection]);

  useEffect(() => {
    if (watchOptions) {
      const optionsNoEmptys = _.filter(watchOptions, (option) => {
        return Boolean(option);
      });
      numberOptions.length === optionsNoEmptys.length
        ? formToValidateOptionsAux.clearErrors('options')
        : formToValidateOptionsAux.setError('options', {
            type: 'manual',
            message: 'Las respuestas no deben estar vacías',
          });
    }
  }, [validateOptionsRules]);

  useEffect(() => {
    if (watchTypeCollection === 'm' && watchTypeInformation) {
      formToValidateOptionsAux.setValue('type_information', undefined);
      formToValidateOptionsAux.setValue('options', []);
    }
  }, [watchTypeCollection]);

  useEffect(() => {
    if (
      watchTypeCollection === 'a' &&
      watchTypeInformation === 'ql' &&
      watchOptions &&
      watchOptions.length
    ) {
      formToValidateOptionsAux.setValue('options', []);
    }
  }, [watchTypeInformation]);

  useEffect(() => {
    if (!formAcceptMessage.watch('date_checkbox')) {
      formAcceptMessage.setValue('send_date', undefined);
    }
  }, [formAcceptMessage.watch('date_checkbox')]);

  useEffect(() => {
    if (firstRenderRef.current) {
      formAcceptMessage.setValue('communities', null);
      formFilterMessages.setValue('communityReceivers', null);
    }
    firstRenderRef.current = true;
  }, [
    formAcceptMessage.watch('typeDest'),
    formFilterMessages.watch('typeDest'),
  ]);

  const handleGetDiffusionMessages = (onSuccess) => {
    dispatch(
      actGetMessagesIntervention(
        {
          intervention_id,
          draftOrNoDraft,
          isMessagesOfArtist,
          ...filter,
          type: difussionOrGetInformation || filter?.type,
        },
        () => {
          onSuccess && onSuccess();
        }
      )
    );
  };

  const handleGetDetailMessages = (
    { messageId, isCollectionMessage = false },
    onSuccess
  ) => {
    dispatch(
      actGetMessageIntervention(
        {
          intervention_id,
          message_id: messageId,
        },
        (res) => {
          const selectedMessage = res.data.data;
          isCollectionMessage
            ? handleGetDetailResponseCollectionMessage({
                messageId,
                onSuccess,
                selectedMessage,
              })
            : onSuccess && onSuccess();
        }
      )
    );
  };

  const sendMessage = ({
    data,
    fromModalConfirmation = false,
    formToUse = formDiffusionMessage,
  }) => {
    if (
      !Object.keys(formToUse.formState.errors).length &&
      fromModalConfirmation
    ) {
      data.state = selectedLoggedInUser.role.id !== 'ar' ? 'ip' : 'p';
      data.channel = data.channel.value;
      data.type = data?.type?.value || 'd';
      if (data.type === 'gi') {
        //about gi - collection information
        data.gi_type = data.gi_type?.value;
      } else {
        delete data.gi_type;
      }
      if (data.type_information) {
        //about gi - collection information
        data.type_information = data.type_information.value;
      }
      if (data?.typeDest?.value === 'lista') {
        data.contactLists = _.map(
          data.communities,
          (community) => community.value
        );
        delete data.communities;
      } else {
        data.communities = _.map(
          data.communities,
          (community) => community.value
        );
      }
      delete data.typeDest;

      if (!data.options?.length) {
        //about gi - collection information
        delete data.options;
      }

      data.send_date = data.send_date
        ? convertDateToISO({ date: data.send_date })
        : undefined;

      if (toSendMessage.id) {
        dispatch(
          actSendMessageDeepenKnowledgeIntervention(
            {
              deepen_knowledge_id: toSendMessage.deepen_knowledge_id,
              message_id: toSendMessage.id,
              data,
            },
            () => {
              formToUse.reset();
              dispatch(actSetTabSelected({ tabValue: 0 }));
              setActiveConfirmModal();
            }
          )
        );
      } else {
        dispatch(
          actSendMessagesIntervention({ intervention_id, data }, () => {
            handleGetDiffusionMessages(() => {
              setActiveItem((prevValue) => ({ ...prevValue, active: 0 }));
              formToUse.reset();
              setActiveConfirmModal();
              setNumberOptions(initialStateNumberOptions);
            });
          })
        );
      }
    } else {
      setActiveConfirmModal();
    }
  };

  const sendMessageDraft = ({ data }) => {
    const anyFieldFilled = Object.values(data).some((value) => Boolean(value));

    if (!anyFieldFilled) {
      setActiveAtLeastItemDraft();
    } else {
      if (data?.typeDest?.value && !data.communities) {
        formAcceptMessage.setError('communities', {
          type: 'manual',
          message: REQUIRED_FIELD,
        });
      } else {
        data.state = 'd';
        data.channel = data?.channel?.value;
        data.type = data?.type?.value || 'd';
        if (data.type === 'gi') {
          //about gi - collection information
          data.gi_type = data.gi_type?.value;
        } else {
          delete data.gi_type;
        }
        if (data.type_information) {
          //about gi - collection information
          data.type_information = data.type_information.value;
        }

        if (data?.typeDest?.value === 'lista') {
          const contactLists = _.map(
            data.communities,
            (community) => community.value
          );
          data.contactLists =
            contactLists.length > 0 ? contactLists : undefined;
          delete data.communities;
        } else {
          const communities = _.map(
            data.communities,
            (community) => community.value
          );
          data.communities = communities.length > 0 ? communities : undefined;
        }
        delete data.typeDest;

        if (
          !data.options?.length ||
          (data.options?.length === 1 && !data.options?.length[0])
        ) {
          delete data.options;
        }

        data.send_date =
          data.send_date && convertDateToISO({ date: data.send_date });

        dispatch(
          actSendMessagesIntervention({ intervention_id, data }, () => {
            setActiveItem((prevValue) => ({ ...prevValue, active: 0 }));
            formDiffusionMessage.reset();
          })
        );
      }
    }
  };

  const deleteMessage = ({ message_id }) => {
    dispatch(
      actDeleteMessageIntervention({ intervention_id, message_id }, () => {
        dispatch(
          actGetMessagesIntervention(
            {
              intervention_id,
              draftOrNoDraft,
              isMessagesOfArtist,
            },
            () => {
              setActiveDeleteModal();
            }
          )
        );
      })
    );
  };

  const handleChangePage = (selected) => {
    dispatch(
      actGetMessagesIntervention({
        intervention_id,
        draftOrNoDraft,
        page: selected + 1,
        isMessagesOfArtist,
        ...filter,
        type: difussionOrGetInformation || filter?.type,
      })
    );
  };

  const handleFilter = ({ data }) => {
    const arrayOfKeyFilters = Object.keys(data);

    const arrayCommunitiesAux = _.map(
      _.filter(
        data?.communityReceivers,
        (community) => community.type === 'community'
      ),
      (community) => community.value
    );

    const arrayContactListAux = _.map(
      _.filter(
        data?.communityReceivers,
        (community) => community.type === 'contactList'
      ),
      (community) => community.value
    );

    arrayOfKeyFilters.forEach((objectkey) => {
      const objectValue = data[objectkey];
      if (objectValue) {
        if (objectkey !== 'communityReceivers') {
          dispatch(
            actSetFilterListOfMessages({
              key: objectkey,
              value:
                typeof objectValue === 'object'
                  ? objectValue.value
                  : objectValue,
            })
          );
        } else {
          arrayCommunitiesAux?.length > 0 &&
            dispatch(
              actSetFilterListOfMessages({
                key: 'communityReceivers',
                value: arrayCommunitiesAux,
              })
            );

          arrayContactListAux?.length > 0 &&
            dispatch(
              actSetFilterListOfMessages({
                key: 'contactListReceivers',
                value: arrayContactListAux,
              })
            );
        }
      }
    });

    dispatch(
      actGetMessagesIntervention(
        {
          intervention_id,
          draftOrNoDraft,
          ...filter,
          state: data?.state?.value && data?.state?.value,
          created_at: data?.created_at && data?.created_at,
          communityReceivers:
            arrayCommunitiesAux.length > 0 && arrayCommunitiesAux,
          contactListReceivers:
            arrayContactListAux.length > 0 && arrayContactListAux,
          type: difussionOrGetInformation || data?.type?.value,
          channel: data?.channel?.value && data?.channel?.value,
          request_sended_date:
            data?.request_sended_date && data?.request_sended_date,
          isMessagesOfArtist,
          sended_date: data?.sended_date && data.sended_date,
        },
        () => {
          handleOpenMenu();
        }
      )
    );
  };

  const handleResetFilter = () => {
    const data = {
      state: '',
      created_at: '',
      communityReceivers: '',
      contactListReceivers: '',
      type: '',
      channel: '',
      request_sended_date: '',
      sended_date: '',
    };

    const arrayOfKeyFilters = Object.keys(data);

    const arrayCommunitiesAux = _.map(
      _.filter(
        data?.communityReceivers,
        (community) => community.type === 'community'
      ),
      (community) => community.value
    );

    const arrayContactListAux = _.map(
      _.filter(
        data?.communityReceivers,
        (community) => community.type === 'contactList'
      ),
      (community) => community.value
    );

    arrayOfKeyFilters.forEach((objectkey) => {
      if (objectkey !== 'communityReceivers') {
        dispatch(
          actSetFilterListOfMessages({
            key: objectkey,
            value: '',
          })
        );
      } else {
        dispatch(
          actSetFilterListOfMessages({
            key: 'communityReceivers',
            value: '',
          })
        );

        dispatch(
          actSetFilterListOfMessages({
            key: 'contactListReceivers',
            value: '',
          })
        );
      }
    });

    dispatch(
      actGetMessagesIntervention(
        {
          intervention_id,
          draftOrNoDraft,
          ...filter,
          state: data?.state?.value && data?.state?.value,
          created_at:
            data?.created_at && convertDateToISO({ date: data?.created_at }),
          communityReceivers:
            arrayCommunitiesAux.length > 0 && arrayCommunitiesAux,
          contactListReceivers:
            arrayContactListAux.length > 0 && arrayContactListAux,
          type: difussionOrGetInformation || data?.type?.value,
          channel: data?.channel?.value && data?.channel?.value,
          request_sended_date:
            data?.request_sended_date && data?.request_sended_date,
          isMessagesOfArtist,
          sended_date: data?.sended_date && data?.sended_date,
        },
        () => {
          handleOpenMenu();
        }
      )
    );

    formFilterMessages.reset(data);
  };

  const handleDataToOrder = ({ orderByKey }) => {
    dispatch(
      actGetMessagesIntervention({
        intervention_id,
        draftOrNoDraft,
        ...filter,
        orderByKey,
        orderByValue: orderBy[orderByKey] ? 'desc' : 'asc',
        isMessagesOfArtist,
        type: difussionOrGetInformation || filter?.type,
      })
    );
    dispatch(
      actSetFilterListOfMessages({ key: 'orderByKey', value: orderByKey })
    );
    dispatch(
      actSetFilterListOfMessages({
        key: 'orderByValue',
        value: orderBy[orderByKey] ? 'desc' : 'asc',
      })
    );
    setOrderBy((prevState) => ({
      ...prevState,
      [orderByKey]: !prevState[orderByKey],
    }));
  };

  const handleRejectMessageAsAdmin = (data) => {
    const { rejected_cause } = data;
    dispatch(
      actRejectAcceptRequestMessageAsAdmin(
        {
          rejected_cause,
          state: 'r',
          interventionId: intervention_id,
          messageId: selectedMessage.id,
        },
        () => {
          handleGetDiffusionMessages(() => {
            formDenyMessage.reset({ rejected_cause: '' });
            setActiveItem((prevValue) => ({ ...prevValue, active: 0 }));
          });
        }
      )
    );
  };

  const handleAcceptMessageAdmin = ({
    data,
    state,
    dontHaveModalConfirmation = false,
    formToUse = formDiffusionMessage,
    fromModalConfirmation = false,
    isSaveDraftMessage = false,
    isArtistRequest = false,
  }) => {
    if (
      (!Object.keys(formToUse.formState.errors).length &&
        fromModalConfirmation) ||
      isSaveDraftMessage ||
      dontHaveModalConfirmation
    ) {
      data.state = state;
      data.channel = data.channel?.value;
      data.type = selectedMessage?.type?.id;

      if (data.type === 'gi') {
        //about gi - collection information
        data.gi_type = data.gi_type?.value;
      } else {
        delete data.gi_type;
      }
      if (data.type_information) {
        //about gi - collection information
        data.type_information = data.type_information.value;
      }

      if (data?.typeDest?.value === 'lista') {
        const contactLists = _.map(
          data.communities,
          (community) => community.value
        );
        data.contactLists = contactLists.length > 0 ? contactLists : undefined;
        isArtistRequest ? delete data.communities : (data.communities = []);
      } else {
        const communities = _.map(
          data.communities,
          (community) => community.value
        );
        data.communities = communities.length > 0 ? communities : undefined;
        isArtistRequest ? delete data.contactLists : (data.contactLists = []);
      }
      delete data.typeDest;

      if (
        !data.options?.length ||
        (data.options?.length === 1 && !data.options?.length[0])
      ) {
        //about gi - collection information
        delete data.options;
      }

      data.send_date = data.send_date
        ? convertDateToISO({ date: data.send_date })
        : '';

      dispatch(
        actRejectAcceptRequestMessageAsAdmin(
          {
            interventionId: intervention_id,
            messageId: selectedMessage.id,
            ...data,
          },
          () => {
            handleGetDiffusionMessages(() => {
              setActiveItem((prevValue) => ({ ...prevValue, active: 0 }));
              formToUse.reset();
              setNumberOptions(initialStateNumberOptions);
            });
          }
        )
      );
    } else {
      setActiveConfirmModal();
    }
  };

  const onFormatDefaultValueCommunities = ({ listOfCommunities }) => {
    return _.map(listOfCommunities, (community) => ({
      value: community.id,
      label: community.name,
    }));
  };

  const handleResetSelectedDetailMessage = () => {
    dispatch(actResetSelectedMessageIntervention());
  };

  const handleCancelSendMessage = ({ e, formToUse }) => {
    formToUse && formToUse.reset();
    e.preventDefault();
    if (toSendMessage.id) return dispatch(actSetTabSelected({ tabValue: 0 }));
    setActiveItem((prevValue) => ({ ...prevValue, active: 0 }));
  };

  const handleAddOptions = (event) => {
    event && event.preventDefault();
    setNumberOptions((prevValue) => [
      ...prevValue,
      {
        id: Date.now(),
        value: '',
      },
    ]);
  };

  const handleDeleteOption = ({ event, optionId }) => {
    event && event.preventDefault();
    const newArrayOptions = _.filter(numberOptions, (option, index) => {
      return index !== optionId;
    });
    setNumberOptions(newArrayOptions);

    if (watchOptions.length > 0) {
      if (watchOptions[optionId] !== undefined) {
        const newArrayOptionsForm = _.filter(
          watchOptions,
          (option, index) => index !== optionId
        );
        formToValidateOptionsAux.setValue('options', newArrayOptionsForm);
      }
    }
  };

  const handleOnChangeOptions = ({ optionValue, optionIndex }) => {
    const newArrayOptions = _.map(numberOptions, (option) => {
      if (option.id === optionIndex) {
        return { ...option, value: optionValue };
      }
      return option;
    });
    setNumberOptions(newArrayOptions);
  };

  const handleDownloadFileFromCollectionInformation = ({
    messageRequestId,
    fileId,
    nameFile,
    extension,
  }) => {
    dispatch(
      actDownloadFileOfCollectionMessagesIntervention(
        {
          message_request_id: messageRequestId,
          fileId,
        },
        (response) => {
          downloadFileFromBackend({
            response,
            nameFile: `Multimedia-${nameFile}`,
            extension,
          });
        }
      )
    );
  };

  return {
    activeItem,
    setActiveItem,

    //functions
    sendMessage,
    sendMessageDraft,
    deleteMessage,
    handleChangePage,
    handleFilter,
    handleResetFilter,
    getYearMonthDayFromDate,
    handleDataToOrder,
    dateExists,
    getMonthAndDay,
    handleRejectMessageAsAdmin,
    handleAcceptMessageAdmin,
    handleResetSelectedDetailMessage,
    handleCancelSendMessage,
    handleGetDetailMessages,
    handleAddOptions,
    setValidateOptionsRules,
    setNumberOptions,
    setDataCollectionInformationAnswersSelected,
    handleChangePageAnswers,
    handleDownloadFileFromCollectionInformation,

    //Formatters
    onFormatDefaultValueCommunities,
    onFormatMessageAboutCommunities,
    handleDeleteOption,
    handleOnChangeOptions,

    //data
    messagesList,
    selectedMessage,
    dataForPagination: { ...listOfMessages.meta, ...listOfMessages.links },
    minDateToSelect,
    orderBy,
    numberOptions,
    dataCollectionInformationAnswersSelected,

    //Options/constants
    optionsCommunities,
    optionsContactLists,
    dataTableHeaderArtistMessagesRef,
    dataTableHeaderArtistDraftMessagesRef,
    dataTableHeaderAdminMessagesRef,
    dataTableHeaderAdminRequestMessagesArtistRef,

    //Modals/toogles
    activeDropDown,
    handleOpenMenu,
    activeConfirmModal,
    setActiveConfirmModal,
    activeDeleteModal,
    setActiveDeleteModal,
    activeAtLeastItemDraft,
    setActiveAtLeastItemDraft,

    //rhf - react hook form
    formDiffusionMessage,
    formFilterMessages,
    formAcceptMessage,
    formDenyMessage,

    fromInterventionMessage: Boolean(toSendMessage.id),
  };
};

export default useAdminViewInterventionsCommunicationDiffusion;
