import useHelpers from '../../../../helpers';

const useNotificationSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const notificationsSelector = createSelector(
    (state) => state.notifications,
    (auth) => auth
  );
  return { notificationsSelector };
};

export default useNotificationSelectors;
